
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';

export interface TwoColsProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const TwoCols: React.FunctionComponent<TwoColsProps> = (props: TwoColsProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div className='TwoCols-box'>
            <p>TwoCols Component</p>
            <Text
                field={fields && fields.heading as SitecoreGenericTextField}
            />
            <span>Raw value: {headingValue}</span>
        </div>
    );
}
