import { css, jsx } from "@emotion/react";

const lint = jsx; // linter hack

export const style = {
  container: css`
    background: linear-gradient(180deg, #1a2934 0%, #657f93 100%) !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;

    span {
      color: #22aa5f;
    }

    svg {
      margin-left: 4px;
      margin-right: 4px;
    }
  `,
  top: css`
    width: 100%;
    padding: 0 !important;
    padding-top: 24px !important;
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    background-color: transparent !important;
    justify-content: flex-start !important;
    color: #ffffff;

    span {
      color: #22aa5f;
    }
  `,
  bottom: css`
    width: 100% !important;
    padding: 0 !important;
    padding-top: 16px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 150% !important;
    letter-spacing: 0.01em !important;
    color: #ffffff !important;
    background-color: transparent !important;
    justify-content: flex-start !important;
  `,
};
