
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentParams, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {renderLink} from "../../utils/helper";

export interface HistogramWithPictureProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const row = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  &:nth-child(even) {
    background: #F0F5F5;
  }
`;
const rowContainer = css`
  width: 100%;
  display: flex;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
`;
const left = css`
  width: 40%;
  display: flex;
  padding-right: 40px;
  flex-direction: column;
`;
const right = css`
  width: 60%;
  padding-left: 40px;
`;
const dividerContainer = (index: number) => css`
  width: 10px;
`;
const divider = (index: number) => css`
  width: 1px;
  height: 100%;
  top: ${index === 0 ? '50%' : '0'};
  position: absolute;
  bottom: -80px;
  background-color: #D9E1E2;
  display: flex;
  align-items: ${index === 0 ? 'flex-start' : 'center'};
  justify-content: center;
`;
const bullet = css`
  border: solid 1px #D9E1E2;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  position: absolute;
  z-index: 10;
`;
const year = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  text-align: right;
  color: #CB1234;
  margin-bottom: 24px;
`;
const text = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: right;
  color: #354450;
`;
const imageContainer = (image: string) => css`
  width: 100%;
  max-width: 420px;
  border-radius: 16px;
  background-image: url(${image});
  background-size: cover;
  min-height: 220px;
`;

export const HistogramWithPicture: React.FunctionComponent<HistogramWithPictureProps> = (props: HistogramWithPictureProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    return (
        <div css={container}>
            {
                new Array(100).fill(0).map((arr: any, index: number) => {
                    if (fields && fields[`year ${index + 1}`] && (fields && fields[`year ${index + 1}`] as any).value) {
                        const img = renderLink((fields && fields[`image ${index + 1}`] as any).value?.src);
                        return (
                            <div css={row}>
                                <div css={rowContainer}>
                                    <div css={left}>
                                        <div css={year}>
                                            { (fields && fields[`year ${index + 1}`] as any).value }
                                        </div>
                                        <div css={text}>
                                            { (fields && fields[`text ${index + 1}`] as any).value }
                                        </div>
                                    </div>
                                    <div css={dividerContainer}>
                                        <div css={divider(index)}>
                                            <div css={bullet} />
                                        </div>
                                    </div>
                                    <div css={right}>
                                        <div css={imageContainer(img)} />
                                    </div>
                                </div>
                            </div>
                        )

                    }
                })
            }
            <Text
                field={fields && fields.heading as SitecoreGenericTextField} 
            />
        </div>
    );
}
    