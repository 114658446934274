/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from "react";
import { indexTypeLabel } from '../../../../../enums/index-type';
import { SearchSuggestionItemDto } from '../../../../../interfaces/search/search-suggestion';
import { indexDetailPageLink } from '../../../Index/Index-details';
import RoundTag from '../../../RoundTag/roundTag.component';
import { getSuggestionTitle } from '../../../Search/input/suggestion';
import { style } from '../../../Search/input/suggestion.style';

export const IndexSuggestion = ({ suggestion }: { suggestion: SearchSuggestionItemDto }) => {
    if( ! suggestion )
        return <></>;

    let title = getSuggestionTitle(suggestion, suggestion.id);

    return (
        <a css={style.card} title={title}
           href={indexDetailPageLink(suggestion.id)}>
            <div css={style.cardHeader}>
                <RoundTag small textColor={"#425563"}>I</RoundTag>
                {
                    suggestion?.properties?.type ?
                    <div css={style.cardTagType}>
                        {indexTypeLabel(suggestion.properties.type)}
                    </div>
                    : <></>
                }
            </div>
            <div css={style.cardBody}>
                <div css={style.cardTitle}>
                    {title}
                </div>
            </div>
        </a>
    );
};