/** @jsx jsx */

import * as React from 'react';

import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { SitecoreGenericTextField } from '../../interfaces';
import hexa from '../TwoColumnsWithCourse/hexa.svg';
import hexaRed from './hexaRed.svg';
import hexaLightGreen from './hexaLightGreen.svg';
import { renderLink } from '../../utils/helper';

export interface HowWeCanHelpProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;
const side = css`
  width: 50%;
`;
const title = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-size: 28px;
    line-height: 100%;
  }
`;
const description = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
  margin-top: 32px;
  @media (max-width: ${breakpointMax}) {
    font-size: 14px;
    line-height: 180%;
  }
`;
const row = css`
  width: 100%;
  flex-wrap: wrap;
`;

const blocs = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 60px;

  @media (max-width: ${breakpointMax}) {
    display: flex;
    flex-direction: column;
  }
`;
const blocTitle = css`
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 8px;
  color: #253645;
`;
const blocDescription = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.01em;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    text-align: center;
  }
`;
export const HowWeCanHelp: React.FunctionComponent<HowWeCanHelpProps> = (
  props: HowWeCanHelpProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const isRed: number = getFieldValue<number>(fields, 'red logo', 0);
  const twoCols: number = getFieldValue<number>(fields, 'display on 2 cols', 0);
  const iconColor = getFieldValue<any>(fields, "iconColor", null);
  const backgroundColor = getFieldValue<any>(fields, "backgroundColor", null);

  const getBackgroundColor = () => {
    if (backgroundColor === "apple100") {
      return `linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)`;
    }
    if (backgroundColor === "apple500") {
      return `linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)`;
    }
    if (backgroundColor === "gradientGreen") {
      return `linear-gradient(83deg, #166F3E 1.21%, rgba(46, 157, 71, 0.94) 41.77%, rgba(199, 214, 77, 0.75) 65.16%, rgba(199, 214, 77, 0.00) 90.12%)`;
    }
    if (backgroundColor === "peach100") {
      return `linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)`;
    }
    if (backgroundColor === "peach500") {
      return `linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)`;
    }
    if (backgroundColor === "gradientPeach") {
      return `linear-gradient(83deg, rgba(248, 159, 121, 0.60) 0.17%, rgba(248, 159, 121, 0.00) 100%)`;
    }
    else {
      return "#f0f5f5";
    }
  };

  const getIconColor = () => {
    if (iconColor === "lightGreen") {
      return hexaLightGreen;
    }
    if (iconColor === "red") {
      return hexaRed;
    }
    else
    {
      return hexa;
    }
  };

  const headerBigMenuItemLogo = css`
    background-image: url(${renderLink(getIconColor())});
    background-repeat: no-repeat;
    background-size: 100%;
    height: 64px;
    width: 58px;
    display: flex;
    justify-content: center;
    color: ${iconColor === "red" ? '#CA1234' : iconColor === "lightGreen" ? '#22AA5F' : '#22AA5F'};
    align-items: center;
    font-size: 18px;
    margin-bottom: 16px;
  `;

  const parentContainer = css`
    background: ${getBackgroundColor()};
    width: 100%;
    height: 100%;
    // background: #f0f5f5;
    padding: 80px;
    @media (max-width: ${breakpointMax}) {
      padding: 22px;
    }
  `;
  const bloc = css`
    display: flex;
    flex-direction: column;
    width: ${twoCols ? '50%' : '33%'};
    margin-bottom: 40px;
    padding-right: 24px;
    @media (max-width: ${breakpointMax}) {
      padding: 0px;
      align-items: center;
      width: 100%;
    }
  `;
  return (
    <div css={parentContainer}>
      <div css={container}>
        <div css={row}>
          <div css={title}>
            <Text field={fields && (fields.title as SitecoreGenericTextField)} />
          </div>
          <div css={description}>
            <Text field={fields && (fields.description as SitecoreGenericTextField)} />
          </div>
        </div>
        <div css={row}>
          <div css={blocs}>
            {new Array(10).fill(0).map((arr: any, index: number) => {
              if (
                fields &&
                fields[`title ${index + 1} card`] &&
                (fields && (fields[`title ${index + 1} card`] as any)).value
              ) {
                return (
                  <div css={bloc}>
                    <div css={headerBigMenuItemLogo}>
                      <Icon
                        icon={
                          fields &&
                          fields[`icon ${index + 1} card`] &&
                          (fields && (fields[`icon ${index + 1} card`] as any)).value
                        }
                      />
                    </div>
                    <div css={blocTitle}>
                      <Text field={fields[`title ${index + 1} card`] as SitecoreGenericTextField} />
                    </div>
                    <div css={blocDescription}>
                      <Text field={fields[`description ${index + 1} card`] as SitecoreGenericTextField} />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
