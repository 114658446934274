import React, { useEffect, useRef, useState } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from './conceptualBanner.style';
import hexagon from './HexaBold.svg';
import hexagonLight from './HexaLight.svg';
import hexagonLightBottom from './HexaLightBottom.svg';
import { renderLink } from '../../../utils/helper';

export interface ConceptualBannerProps {
  green?: boolean;
  children?: React.ReactNode;
  renderLeft: React.ReactNode;
  renderRight?: (scrolled: boolean) => React.ReactNode;
  reduceOnScroll?: boolean;
  lgxDisplay?: boolean;
}

const ConceptualBanner: React.FunctionComponent<ConceptualBannerProps> = ({
  green,
  children,
  renderLeft,
  renderRight,
  lgxDisplay,
  reduceOnScroll = true,
}: ConceptualBannerProps) => {

  const [scrolled, setScrolled] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!reduceOnScroll) {
      return;
    }

    const appBoxes = document.getElementsByClassName('app-box');

    if (!appBoxes) {
      return;
    }

    const node = appBoxes?.length ? appBoxes[appBoxes.length - 1] : null;
    const scrollCallback = () => {
      setScrolled(ref.current?.getBoundingClientRect().top <= 0);
    };

    node.addEventListener('scroll', scrollCallback);
    return () => node.removeEventListener('scroll', scrollCallback);
  }, [reduceOnScroll]);

  return (
    <div ref={ref} css={style.conceptualBanner}>
      <div css={style.conceptualBannerFull}>
        <div
          css={css`
            ${style.conceptualBannerContainer} ${lgxDisplay ? style.conceptualBannerContainerGreen : ''}
          `}
        >
          <img
            src={renderLink(hexagon)}
            alt={'image'}
            css={[
              style.hexaBackgroundImage,
              css`
                opacity: 0.1;
              `,
            ]}
          />
          <img
            src={renderLink(hexagonLight)}
            alt={'image'}
            css={[
              style.hexaBackgroundImageLight,
              css`
                opacity: 0.3;
              `,
            ]}
          />
          <img
            src={renderLink(hexagonLightBottom)}
            alt={'image'}
            css={[
              style.hexaBackgroundImageLightBottom,
              css`
                opacity: 0.3;
              `,
            ]}
          />
        </div>

        <div css={[style.bannerContent, scrolled && style.bannerContentSmall]}>
          <div css={style.bannerLeft}>{renderLeft}</div>
          {renderRight && <div css={style.bannerRight}>{renderRight(scrolled)}</div>}
        </div>
      </div>

      <div css={[style.metadataBar, scrolled && style.hidden]}>{children}</div>
    </div>
  );
};

export default ConceptualBanner;
