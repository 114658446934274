/** @jsx jsx */

import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { renderLink } from "../../utils/helper";

const lint = jsx; // linter hack

export interface BannerBrandmarksImagesProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
    data: any;
}

export const BannerBrandmarksImages: React.FunctionComponent<BannerBrandmarksImagesProps> = (
    props: BannerBrandmarksImagesProps
    ): React.ReactElement => {
    const { fields } = props;

  const largeImage = getFieldValue<boolean>(fields, "largeImage", false);
  const imageFilter = getFieldValue<any>(fields, "colouredFilterHexa", null);
  const image: any = getFieldValue<any>(fields, "image", null);

  const getImageBackground = () => {
    if (imageFilter === "apple100") {
      return `linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)`;
    }
    if (imageFilter === "apple500") {
      return `linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)`;
    }
    if (imageFilter === "gradientGreen") {
      return `linear-gradient(83deg, #166F3E 1.21%, rgba(46, 157, 71, 0.94) 41.77%, rgba(199, 214, 77, 0.75) 65.16%, rgba(199, 214, 77, 0.00) 90.12%)`;
    }
    if (imageFilter === "peach100") {
      return `linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)`;
    }
    if (imageFilter === "peach500") {
      return `linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)`;
    }
    if (imageFilter === "gradientPeach") {
      return `linear-gradient(83deg, rgba(248, 159, 121, 0.60) 0.17%, rgba(248, 159, 121, 0.00) 100%)`;
    }
  };

  const style = {
    image: css`
    max-width: ${largeImage ? "100%" : "380px"};
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: ${largeImage ? "10%" : "0"};
    // clip-path: polygon(
    //   46.12% 1.334%, 46.12% 1.334%, 47.103% 0.889%, 48.123% 0.542%, 49.169% 0.294%,
    //   50.234% 0.145%, 51.308% 0.095%, 52.383% 0.144%, 53.448% 0.292%, 54.496% 0.54%,
    //   55.518% 0.887%, 56.503% 1.334%, 96.045% 21.675%, 96.045% 21.675%, 96.922% 22.182%,
    //   97.738% 22.767%, 98.486% 23.424%, 99.158% 24.147%, 99.747% 24.933%, 100.245% 25.773%,
    //   100.645% 26.665%, 100.94% 27.601%, 101.123% 28.577%, 101.185% 29.588%, 101.185% 70.374%,
    //   101.185% 70.374%, 101.123% 71.381%, 100.941% 72.355%, 100.647% 73.291%, 100.248% 74.182%,
    //   99.751% 75.023%, 99.162% 75.809%, 98.49% 76.535%, 97.741% 77.193%, 96.923% 77.78%,
    //   96.042% 78.288%, 57.503% 98.628%, 57.503% 98.628%, 56.518% 99.074%, 55.498% 99.421%,
    //   54.45% 99.669%, 53.384% 99.818%, 52.31% 99.867%, 51.235% 99.818%, 50.17% 99.669%,
    //   49.122% 99.421%, 48.102% 99.073%, 47.117% 98.627%, 7.583% 78.289%, 7.582% 78.288%,
    //   7.582% 78.288%, 6.701% 77.78%, 5.882% 77.193%, 5.133% 76.535%, 4.461% 75.809%,
    //   3.873% 75.023%, 3.375% 74.182%, 2.976% 73.291%, 2.682% 72.355%, 2.5% 71.381%,
    //   2.438% 70.374%, 2.438% 29.588%, 2.438% 29.588%, 2.5% 28.58%, 2.682% 27.606%, 
    //   2.976% 26.67%, 3.375% 25.779%, 3.873% 24.938%, 4.461% 24.152%, 5.133% 23.426%,
    //   5.882% 22.768%, 6.701% 22.181%, 7.582% 21.673%, 47.117% 1.335%, 47.12% 1.334%
    // );
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      padding-right: 16px;
      padding-left: 16px;
      clip-path: none;
    }
  }
  `,
  
    overlay: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${getImageBackground()};
    pointer-events: none;
    @media (max-width: ${breakpointMax}) {
      background: none;
    }
    `, 
  };

  return (
    fields &&
      fields.image &&
      (fields && (fields.image as any)).value &&
      (fields && (fields.image as any)).value.src &&
      (fields && (fields.image as any)).value.src !== "" 
      // &&
      // (fields && (fields.colouredFilterHexa as any)).value
      ? 
      (
        // <div css={style.image}>
        //   <div css={style.overlay}></div>
          <img
            src={renderLink((fields && (fields.image as any)).value.src)}
            alt="image"
            css={style.image}
          />
        // </div>
      ) : null

  );
}