/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from "react";
import { SearchSuggestionItemDto } from '../../../../../interfaces/search/search-suggestion';
import { formatDate } from '../../../../../utils/date';
import { programmeDetailPageLink } from '../../../ProgrammeCard';
import RoundTag from '../../../RoundTag/roundTag.component';
import { getSuggestionComplement, getSuggestionNumber, getSuggestionTitle } from '../../../Search/input/suggestion';
import { style } from '../../../Search/input/suggestion.style';

export const ProgrammeSuggestion = ({ suggestion }: { suggestion: SearchSuggestionItemDto }) => {
    if( ! suggestion)
        return <></>;

    let title = getSuggestionTitle(suggestion, suggestion.id);

    return (
        <a css={style.card} 
           href={programmeDetailPageLink( getSuggestionComplement(suggestion, "url"), suggestion.id)}
           title={title}>
            <div css={style.cardHeader}>
                <RoundTag small textColor={"#425563"}>P</RoundTag>
            </div>
            <div css={style.cardBody}>
                <div css={style.cardTitle}>
                    {title}
                </div>
                <div css={style.cardSubTitle}>
                    {suggestion.properties?.subTitle}
                </div>
            </div>
            <div css={style.cardFooter}>
                <div css={[style.cardDate, style.cardFooterSeparator]}>
                    <span>
                        { suggestion?.properties?.startDate ?
                            formatDate(suggestion.properties.startDate)
                            : "N/A"
                        }
                        {" - "}
                        { suggestion?.properties?.endDate ?
                            formatDate(suggestion.properties.endDate)
                            : "N/A"
                        }
                    </span>
                </div>
                <div css={style.cardTagInfo}>
                    {getSuggestionNumber(suggestion, "nbSecurities", 0)} securities
                </div>
            </div>
        </a>
    );
};