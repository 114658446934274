import { css, jsx } from "@emotion/react";
import { breakpoint } from "../../../style";

const lint = jsx; // linter hack

export const style = {
  iframeContainer: css`
    display: flex;
    //justify-content: center;
    background: #f4f6f7;
    width: inherit;
    margin: 0;
    //padding: 50px 0px;
  `,
  errorText: css`
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  `,
  type: css`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #d9dee2;
    border-radius: 16px;
    @media (max-width: ${breakpoint}) {
      padding: 4px 8px;
    }
  `,
  pipe: css`
    width: 1px;
    height: 32px;
    border: 1px solid #a4b6c2;
  `,
  text2: css`
    font-family: "Mont";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    @media (max-width: ${breakpoint}) {
      font-size: 14px;
    }
  `,
  text1: css`
    font-family: "Mont";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    @media (max-width: ${breakpoint}) {
      font-size: 14px;
    }
  `,
  button: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    height: 45px;
  `,

  bannerContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    gap: 16px;
    width: 100%;
    height: 64px;
    background: #425563;
    flex: none;
    order: 0;
    flex-grow: 1;

    @media (max-width: ${breakpoint}) {
      min-height: 64px;
      height: inherit;
      gap: 10px;
      padding: 10px;
    }
  `,
  bannerInfo: css`
    position: relative;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 16px;

    @media (max-width: ${breakpoint}) {
      gap: 10px;
    }
  `,
  bannerDate: css`
    position: relative;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12%;

    @media (max-width: ${breakpoint}) {
      width: 15%;
    }
  `,
};
