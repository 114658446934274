import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {sharedStyle} from "../../../styles/shared";
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

export interface DonutChartProps {
    data: any[];
    chartName?: string;
    chartHeight?: number;
    graphColors?: any[];
    labelField: string;
    serieField: string;
    nbColumn?: number;
    labelValueFunction?: (value: string) => string;
    legend?: "graph" | "content" | "none";
    displayLabels?: boolean;
    totalDisplay?: boolean;
    totalLabelBefore?: string;
    totalLabelAfter?: string;
}

export const DonutChart = (
    { data, 
      chartName = (Math.random() * 1000).toString(), 
      chartHeight = 300,
      graphColors, 
      labelField, 
      serieField, 
      nbColumn = 2, 
      labelValueFunction, 
      displayLabels = false,
      totalDisplay = false,
      totalLabelBefore = "",
      totalLabelAfter = "",
      legend = "content",
    } : DonutChartProps ) =>  {

    const [ready, setReady] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [labels, setLabels] = useState<string[]>([]);
    const [series, setSeries] = useState<number[]>([]);

    useEffect(() => {
        if( data ){
            let totalTmp : number = 0;
            let labelsTmp : string[] = [];
            let seriesTmp : number[] = [];
            
            data.forEach(( elem: any ) => {
                totalTmp += (elem[serieField] as number);

                let label : string = labelValueFunction ?
                    labelValueFunction( elem[labelField] as string ) :
                    elem[labelField];
    
                labelsTmp.push(label);
                seriesTmp.push(elem[serieField] as number);
            });
            
            setTotal(totalTmp);
            setSeries(seriesTmp);
            setLabels(labelsTmp);
            
            setReady(true);
        }
    }, []);

    const consructLegendColumn = (start: number, end: number) => {
        if( ! data?.length )
            return <></>;
            
        if( end > data.length )
            end = data.length;

        return (
            <div css={sharedStyle.legendCol}>
                { data.slice(start, end)
                      .map( ( elem: any, index: number ) => (
                        <div css={sharedStyle.legendRow} key={`${chartName}-legend-${(index + start)}`}>
                            <div css={sharedStyle.bullet(graphColors[(index + start)])} />
                            {`${labels[(index + start)]}: ${series[(index + start)]}`}
                        </div>
                    ))
                }
            </div>
        );
    }

    const constructLegend = () => {
        if( ! data?.length )
            return <></>;
    
        let itemPerColumn = Math.floor(data.length / nbColumn);
        let rest = data.length % nbColumn;
        let start = 0, end = itemPerColumn;

        let divColumn = [];
        for(let i = 0; i < nbColumn; i++ ){
            if( rest > 0 ){
                end ++;
                rest --;
            }

            divColumn.push(consructLegendColumn(start, end));
            start = end;
            end = start + itemPerColumn;
        }
    
        return (
            <div css={sharedStyle.legendContainer}>
                {divColumn}
            </div>
        );
    }

    const constructChart = () => {
        if( ! labels?.length || ! series?.length ) 
            return <></>;

        let charOptions: any = {
            chart: {
                type: 'donut',
                animations: { enabled: true },
                parentHeightOffset: -15,
                toolbar: { show: false },
            },
            colors: graphColors,
            labels: labels,
            dataLabels: { enabled: displayLabels },
            crosshairs: { show: true },
            legend: { show: legend === "graph" },
            tooltip: { enabled: true }
        };

        if( displayLabels )
            charOptions.plotOptions = {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                        }
                    }
                }
            };

        return (
            <>
                <ReactApexChart
                    options={charOptions}
                    series={series}
                    type='donut'
                    height={chartHeight}
                />
            </>
        );
    }

    if ( ! data || ! data.length )
        return <></>;

        

    return (
        ready && series?.length && labels?.length ?
        <> 
            <div css={sharedStyle.chartContainer}>
                {constructChart()}
            </div>
            { totalDisplay ?
                <div css={sharedStyle.totalContainer}>
                    {`${totalLabelBefore ? totalLabelBefore + " " : ""}${total}${totalLabelAfter ? " " + totalLabelAfter : ""}`}
                </div>
                : <></>
            }
            {legend === "content" ?
                <div css={sharedStyle.legendContainer}>
                    {constructLegend()}
                </div>
                : <></>
            }
        </>
        : <></>
    );
};
