import { css } from "@emotion/react";

export const style = {
  noticeSummary: css`
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-bottom: 1.5em;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    column-gap: 1rem;
  `,
  title: css`
    h3 {
      font-size: 1em;
      font-weight: lighter;
      color: #354450;
      margin: 0;
    }
  `,
  name: css`
      font-size 1.5rem;
      color: #253645;
      font-weight: bold;
      text-decoration: none;
    }
  `,
  info: css`
    display:flex;
    flex-direction: row;
    color: #354450;
    gap: 0.5em;

    > *:not(:last-child)::after {
      content: "-";
      position: relative;
      right: -0.25rem;
    }
  `,
  roundedTag: css`
    > div {
      height: 39px;
      width: 39px;
      color: #253645;
      font-size: 1rem;
    }
  `,
  tag: css`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    border: solid 1px #e3e6e8;
    background: #ffffff;
    border-radius: 999em;
    padding: 8px 16px;
    font-weight: bold;
    color: #354450;
  `,
}