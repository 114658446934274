/** @jsx jsx */
import React, { Component, useState } from "react";
import { css, jsx } from "@emotion/react";



export class LuxSharesCard extends Component<any, any> {

    render() {
        const style = {
            parent: css`
                display:flex;
                flex-direction: column;
                //border:1px solid black;
                margin: 0px auto;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
                width:100%;
                height:100%;
            `,
            firstComponent: css`
                display:flex;
                justify-content: space-between;
                //gap:40%;
                width:100%;
                align-items:center;
                //background-color:red;
                text-align: center;
            `,

            insideFisrtCompoenent:css`
                text-align: center;
                width:209px;
                //align-items:center;
                margin:auto;
                height:148px;
            `,
            secondComponent: css`
                width:100%;
                //margin:auto;
                display:flex;
                flex-direction: column;
                justify-content: space-around;
            `,
            number: css`
                //display: inline-block;
                width:100%;
                text-align: center; 
                
                font-family: 'Mont';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                line-height: 61px;   
                color: #425563;
            `,
            text: css`
                //display: inline-block;
                width:100%;
                text-align: center; 
                
                font-family: 'Mont';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 31px;
                
                color: #A4B6C2;
                text-align: center; 
            
            `,
            marketStyle: css`
                width: 209px;
                height: 26px;
                color:#5E798D;
                line-height: 26px;
                font-weight: 650;
            `,

            borderAfter:css`
                //display: inline-block;
                height:10rem;
                width:2px;
                border-right: 1px solid #D9DEE2;
                text-align: center;
            `,

            borderBottom:css`
                display:flex;
                flex-direction: column;
                width:90%;
                border-top: 1px solid #D9DEE2;
            `,

        };
        const luxShares = this.props.data;

        return (
            <div css={style.parent}>
                <div css={style.firstComponent}>
                    <div css={[style.insideFisrtCompoenent,css`text-align: center;`]}>
                        <div css={style.number}>{luxShares.bdlMarket}</div>
                        <div css={style.text}>securities</div>
                        <div css={style.text}>on <span css={[style.marketStyle]}>BdL Market</span></div>
                    </div>
                    <div css={style.borderAfter}></div>
                    <div css={[style.insideFisrtCompoenent,css`text-align: center;`]}>
                        <div css={style.number}>{luxShares.euroMTF}</div>
                        <div css={style.text}>securities</div>
                        <div css={style.text}>on <span css={style.marketStyle}>Euro MTF</span></div>
                    </div>
                </div>
                <div css={style.borderBottom}></div>
                <div css={style.secondComponent}>
                    <div css={css`text-align: center;`}>
                        <div css={[style.number, css`width:100%;`]}>{luxShares.totalMarketCap} billion EUR</div>
                        <div css={[style.text, css`width:100%;`]}>Total market cap</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LuxSharesCard;