import * as React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

import { SitecoreContext } from "../../interfaces";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { OAMSearch } from "../../core/components/OAMSearch";

export interface Field {
  value?: string;
  editable?: string;
}
export interface OAMSearchContentProps {
  sitecoreContext: SitecoreContext;
}

export function OAMSearchContentCore({ sitecoreContext }: OAMSearchContentProps) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
      </HelmetProvider>
      <OAMSearch />
    </>
  );
}

export const OAMSearchContent = withSitecoreContext()(OAMSearchContentCore);
