import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Icon, Table, Td, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import { formatDate } from '../../../../../../utils/date';
import { style } from '../index.style';
import { goToSecurityDetailPage } from '../../../../SecurityCard';
import PriceColumn from '../../../../UI/list/security/price';

export const LuxXPrimeRow = (props: any) => {
  const row = props.luxXPrimeRowData;

  //TODO refactor LatestPrice used many place
  interface LatestPrice {
    amount?: number;
    currency?: string;
    priceDate?: number;
    marker?: string;
    previousDayPercentVariation?: number;
  }

  const latestPrice: LatestPrice = {
    amount: row.marketData?.lastPrice?.amount,
    marker: row.marketData?.lastPrice?.marker,
    previousDayPercentVariation: row.marketData?.dailyChange,
  };

  return (
    <Tr onRowClick={() => goToSecurityDetailPage(row.isinCode, row.id)}>
      <Td css={[style.title, css`font-weight: bold`]}>{row.name}</Td>
      <Td css={style.title}>{formatDate(row.finalMaturityDate)}</Td>
      <Td css={style.title}>
        {(row.marketData?.yieldToMaturity && row.marketData?.yieldToMaturity + row.marketData?.tradeIndicator) || ' - '}
      </Td>
      <Td css={style.title}>
        {(row.marketData?.dailyChange && <PriceColumn {...row.marketData?.lastPrice} showDate={false} showChange={true}/>) || ' - '}
      </Td>
    </Tr>
  );
};
export default LuxXPrimeRow;
