import gql from "graphql-tag";
import {documentSearchFullFragment, documentSearchItemFragment} from "./search/search-document.queries";


export const GET_OAM_SUBMISSIONS_SEARCH = gql`
    query GetOamSubmissionsSearch(
        $depositType: Int,
        $issuerName: String,
        $referenceYear: Int,
        $cssfCode: String,
        $isin: String,
        $publicationStartDate: Date,
        $publicationEndDate: Date,
        $countryCodeIso: String
    ) {
        oamSubmissionsSearch(
            depositType: $depositType,
            issuerName: $issuerName,
            referenceYear: $referenceYear,
            cssfCode: $cssfCode,
            isin: $isin,
            publicationStartDate: $publicationStartDate,
            publicationEndDate: $publicationEndDate,
            countryCodeIso: $countryCodeIso
        ) {
            totalHits
            submissions {
                submissionId
                submissionTypeLabel
                actionsList
                publicationDate
                referenceYear
                referenceStartDate
                referenceEndDate
                issuerName
            }
        }
    }
`;

export const GET_OAM_SUBMISSION_DETAIL = gql`
    query GetOamSubmissionDetail($submissionId: Float!) {
        oamSubmissionDetail(submissionId: $submissionId) {
            documents {
                fileName
                url
                size
                publicationDate
                category
                obsolete
            }
            events {
                eventCode
                oldStartValue
                oldEndValue
                newStartValue
                newEndValue
                publicationDate
                attachedDocuments {
                    fileName
                    url
                }
            }
        }
    }
`;

export const documentSummaryFragment = gql`
    fragment documentSummaryFragment on Document {
        id
        name
        description
        publishDate
        referenceDate
        documentTypeCode
        documentTypeTranslatableField {
            key
            value
            field
        }
        documentPublicTypeCode
        documentPublicTypeTranslatableField {
            key
            value
            field
        }
        downloadUrl
        complement
        format
        deleted
        obsolete
    }
`;

export const GET_SEARCH_DOCUMENTS_ONLY_RESULTS = gql`
    ${documentSearchFullFragment}

    query SearchDocuments(
        $searchTerm: String!
        $size: Int
        $page: Int
        $sort: String
        $fromDate: Date
        $toDate: Date
        $documentTypes: [String!]
        $documentSubTypes: [String!]
        $excludeDocumentTypes: [String!]
        $excludeDocumentSubTypes: [String!]
        $securityId: String
        $issuerId: String
        $programmeId: String
    ) {
        luxseDocumentsSearch(
            searchTerm: $searchTerm,
            size: $size
            page: $page
            sort: $sort
            fromDate: $fromDate,
            toDate: $toDate
            types: $documentTypes
            subTypes: $documentSubTypes
            excludeTypes: $excludeDocumentTypes
            excludeSubTypes: $excludeDocumentSubTypes
            securityId: $securityId
            issuerId: $issuerId
            programmeId: $programmeId
        ) {
            ...documentSearchFullFragment
        }
    }
`;

export const GET_DOCUMENT_BY_ID = gql`
    ${documentSearchItemFragment}

    query GetDocumentById(
        $id: String!
    ) {
        document(
            id: $id
        ) {
            ...documentSearchItemFragment
        }
    }
`;