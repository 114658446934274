import * as React from "react";
import {css} from "@emotion/react";
import { breakpoint } from "../../../../style";

export const style = {
  section: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 0px 80px;
    
    @media (max-width: ${breakpoint}) {
      flex-direction: column;
      padding: 10px;
    }
  `,
  sectionTop: css`
    width: 100%;
    display: flex;
    border-bottom: solid 1px #e3e6e8;

    &:last-child {
      border-bottom: none;
    }
    @media (max-width: 720px) {
      flex-direction: column;
    }
  `,
  blocTitle: css`
    color: #354450;
    font-weight: 800;
    font-size: 3em;
    //line-height: 61px;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;

    @media (max-width: 720px) {
      font-size: 1.5em;
      line-height: 2em;
      flex-direction: column;
    }

    @media screen and (min-width: 720px) and (max-width: 1080px) {
      font-size: 2em;
      line-height: 1.5em;
    }
  `,
  flexRow: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3em;
  `,

  blocBody: css`
    display: block;
  `,
  disclaimerSection: css`
    margin-bottom: 15px;
  `,
}