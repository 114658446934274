import { formatToThousandsSeparator, toFixedWithoutRounding } from "../../../../../utils";
import { InputDateRangePickerProps } from "../../../input-date-range-picker";
import { getStartOfDay, getEndOfDay } from "../../../../../utils/date";

export const rangeSelections = [
  {
    label: "5D",
    dateRange: {
      fromDate: getStartOfDay().subtract(5, "days"),
      toDate: getEndOfDay(),
    }
  },
  {
    label: "1M",
    dateRange: {
      fromDate: getStartOfDay().subtract(1, "month"),
      toDate: getEndOfDay(),
    }
  },
  {
    label: "6M",
    dateRange: {
      fromDate: getStartOfDay().subtract(6, "months"),
      toDate: getEndOfDay(),
    }
  },
  {
    label: "1Y",
    dateRange: {
      fromDate: getStartOfDay().subtract(1, "year"),
      toDate: getEndOfDay(),
    }
  },
  {
    label: "MAX",
    dateRange: {
      fromDate: null,
      toDate: null,
    }
  }
];

const YEAR_DATE_FORMAT = "yyyy";
const MONTH_DATE_FORMAT = "MMM yyyy";
const DAY_DATE_FORMAT = "dd MMM yyyy";

const NB_MS_IN_DAY = 1000 * 3600 * 24;
const NB_MS_IN_MONTH = NB_MS_IN_DAY * 30;
const NB_MS_IN_YEAR = NB_MS_IN_DAY * 364;

export type RangeDate = Pick<InputDateRangePickerProps, "fromDate" | "toDate">;

export function areDateRangesEqual(first: RangeDate, second: RangeDate): boolean {
    return (first.fromDate === second.fromDate && first.toDate === second.toDate) // especially useful  when there are null dates
      || (first.fromDate?.isSame(second.fromDate) && first.toDate?.isSame(second.toDate))
}

export function computeFormatFromRange({fromDate, toDate}: RangeDate) {
    if(!(fromDate && toDate)) {
        return YEAR_DATE_FORMAT;
    }
    const timeBetween = Math.abs(fromDate.diff(toDate));

    if(timeBetween >= NB_MS_IN_YEAR * 2) {
        return YEAR_DATE_FORMAT;
    } else if(timeBetween >= NB_MS_IN_MONTH * 5) {
        return MONTH_DATE_FORMAT;
    }
    return DAY_DATE_FORMAT;
}

export const chartOptions: any = {
  options: {
    chart: {
        type: "area",
        animations: { enabled: false },
        parentHeightOffset: -15,
        toolbar: { show: false },
        width: "100%",
        height: "100%",
    },
    stroke: { colors: ["#22AA5F"], width: 1.8 },
    dataLabels: { enabled: false },
    grid: { show: false },
    crosshairs: { show: false },
    legend: { show: false },
    tooltip: {
        x: { 
            show: true,
            format: 'dd MMM yyyy'
        },
        style: {
            fontSize: '12px',
            fontFamily: 'Roboto Regular'
        }
    },
    fill: {
        type: "gradient",
        colors: ["#22AA5F"],
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.1,
            opacityTo: 0.9,
            stops: [0, 90, 100],
        },
    },
    xaxis: {
        type: 'datetime',
        labels: {
            format:'dd MMM yyyy',
            show: true, 
            trim: true,
            style: {
                fontSize: '14px',
                fontFamily: 'Roboto Regular',
                colors: '#657F93'
            },
            showDuplicates: true,
        },
        axisBorder: { show: true },
        axisTicks: { show: false },
        categories: [ ]
    },
    
    yaxis: {
      forceNiceScale: true,
      labels: {
          style: {
              fontSize: '14px',
              fontFamily: 'Roboto Regular',
              color: '#657F93'
          },
          show: true,
          formatter: (value: number): string => formatToThousandsSeparator(toFixedWithoutRounding(value, 3))
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
    }
  },
  series: [ { name: 'price', data: [ 1000 ]} ] 
};