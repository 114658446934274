/** @jsx jsx */

import * as React from 'react';

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { Text, 
ComponentParams, 
ComponentRendering, 
Placeholder,
getFieldValue
} from '@sitecore-jss/sitecore-jss-react';
import { css, jsx } from "@emotion/react";
import { useRef } from "react";

import { SitecoreGenericTextField } from '../../interfaces';
import { canUseDOM } from "../../predicates";
import { genericStyle } from "../../core/components/UI/generic/generic.style";
// import hexa from "./hexa.svg";
import {renderLink} from "../../utils/helper";
import AnchorContext from '../../core/components/Anchor/AnchorContext';


export interface MeetOurExpertsCardProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const blocContent = css`
  color: #354450;
  font-size: 20px;
  font-weight: 600;
  height: auto;
  width: 100%;
  transition: all 0.2s ease-in-out;
  padding-top: 24px;
  padding-bottom: 24px;
  display: table-cell;
`;
const leftSide = css`
  width: 20%;
  display: flex;
  padding-right: 48px;
  justify-content: center;
`;

const leftSideImage = css`
  width: 100%;
  display: flex;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
const rightSide = css`
  width: 80%;
  display: flex;
  flex-direction: column;
`;
const text = css`
  color: #354450;
  font-size: 20px;
  font-weight: 600;
  height: auto;
  display: flex;
  width: 100%;
  flex-direction: row;
  transition: all 0.2s ease-in-out;
`;
const defaultblocStyle = {
  background: "#ffffff",
  padding: 42,
  border: "solid 1px #d9e1e2",
  borderRadius: 8,
  marginBottom: 40,
  transition: "all 0.2s ease-in-out",
  cursor: "pointer",
  width: "100%",
};
const rightSideTitle = css`
  font-weight: 800;
  font-size: 32px;
  line-height: 41px;
  color: #cb1234;
  margin-bottom: 8px;
`;
const rightSideSubTitle = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #425563;
  margin-bottom: 16px;
`;
const separator = css`
  border: 1px solid #d9e1e2;
  height: 1px;
  width: 100%;
  margin-top: 48px;
`;
const rightSideDescription = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
`;
const rightSideTags = css`
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  flex-wrap: wrap;
`;
const rightSideActions = css`
  display: flex;
  margin-top: 16px;
  > button {
    margin-right: 16px;
  }
`;

export const MeetOurExpertsCard: React.FunctionComponent<MeetOurExpertsCardProps> = (
    props: MeetOurExpertsCardProps
    ): React.ReactElement => {
    const { fields, rendering } = props;
    const tab = useRef(null);

    const anchorId: string = getFieldValue<string>(fields, "anchorId", "");
    const context = React.useContext(AnchorContext);

    React.useEffect(() => {
      if (context?.registerAnchorId) {
        context.registerAnchorId(anchorId);
    }
  }, [anchorId, context]);

  return (
    <>
        <> 
        <div css={genericStyle.desktopDisplay}>
          <div
            css={blocContent}
            id={anchorId}
          >
            <div
              css={text}
              ref={tab}
            >
              <div css={leftSide}>
                {fields &&
                  fields[
                  `image`
                  ] &&
                  (
                    fields &&
                    (fields[
                      `image`
                    ] as any)
                  ).value &&
                  (
                    fields &&
                    (fields[
                      `image`
                    ] as any)
                  ).value.src && (
                    <div
                      css={leftSideImage}
                      style={{
                        background: `url(${renderLink(
                          (
                            fields &&
                            (fields[
                              `image`
                            ] as any)
                          ).value.src
                        )})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  )}
              </div>
              <div css={rightSide}>
                <div css={rightSideTitle}>
                  <Text
                    field={
                      fields[
                      `name`
                      ] as SitecoreGenericTextField
                    }
                  />
                </div>
                <div css={rightSideSubTitle}>
                  <Text
                    field={
                      fields[
                      `status`
                      ] as SitecoreGenericTextField
                    }
                  />
                </div>
                <div css={rightSideDescription}>
                  <Text
                    field={
                      fields[
                      `description`
                      ] as SitecoreGenericTextField
                    }
                  />
                  </div>
                <div css={rightSideTags}>
    
                  <Placeholder name="jss-expert-cards" rendering={rendering} />
                  
                </div>
                <div css={rightSideActions}>
                  <Button
                    label={"Contact"}
                    iconLeft={"paper-plane"}
                    size={"medium"}
                    onClick={() =>
                      (
                        fields &&
                        (fields[
                          `email`
                        ] as any)
                      ).value
                        ? canUseDOM &&
                        window.open(
                          `mailto:${(
                            fields &&
                            (fields[
                              `email`
                            ] as any)
                          ).value
                          }`
                        )
                        : console.log("no email found")
                    }
                  />
                  <Button
                    label={
                      (
                        fields &&
                        (fields[
                          `phone`
                        ] as any)
                      ).value
                    }
                    iconLeft={"phone"}
                    wired={true}
                    size={"medium"}
                    onClick={() =>
                      canUseDOM &&
                      window.open(
                        `tel:${(
                          fields &&
                          (fields[
                            `phone`
                          ] as any)
                        ).value
                        }`
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div css={separator} />
          </div>
        </div>
          <div css={[genericStyle.mobileDisplay]}>
            <div
              css={css`
                                      display: flex;
                                      align-items: center;
                                      flex-direction: column;
                                    `}
            >
              {fields &&
                fields[
                `image`
                ] &&
                (
                  fields &&
                  (fields[
                    `image`
                  ] as any)
                ).value &&
                (
                  fields &&
                  (fields[
                    `image`
                  ] as any)
                ).value.src && (
                  <div
                    style={{
                      background: `url(${renderLink(
                        (
                          fields &&
                          (fields[
                            `image`
                          ] as any)
                        ).value.src
                      )})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      width: "250px",
                      height: "250px",
                      backgroundPositionX: "50%",
                    }}
                  />
                )}
              <div
                css={css`
                                        font-weight: 800;
                                        font-size: 32px;
                                        line-height: 41px;
                                        color: #cb1234;
                                        margin-top: 47px;
                                        text-align: center;
                                      `}
              >
                <Text
                  field={
                    fields[
                    `name`
                    ] as SitecoreGenericTextField
                  }
                />
              </div>
              <div
                css={css`
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 18px;
                                        letter-spacing: 0.1em;
                                        color: #a4bcc2;
                                        margin-top: 8px;
                                        text-align: center;
                                      `}
              >
                <Text
                  field={
                    fields[
                    `status`
                    ] as SitecoreGenericTextField
                  }
                />
              </div>
              <div
                css={css`
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 150%;
                                        letter-spacing: 0.01em;
                                        color: #425563;
                                        margin-top: 17px;
                                      `}
              >
                <Text
                  field={
                    fields[
                    `description`
                    ] as SitecoreGenericTextField
                  }
                />
              </div>
              <div
                css={css`
                                        display: flex;
                                        flex-direction: row;
                                        align-items: flex-start;
                                        padding: 0px;
                                        gap: 8px;
                                        margin-top: 16px;
                                        flex-wrap: wrap;
                                      `}
              >
                <Placeholder name="jss-expert-cards" rendering={rendering} />
              </div>
              <div
                css={css`
                                        margin-top: 32px;
                                        width: 100%;
                                        > button {
                                          width: 100%;
                                        }
                                      `}
              >
                <Button
                  label={"Contact"}
                  iconLeft={"paper-plane"}
                  size={"medium"}
                  onClick={() =>
                    (
                      fields &&
                      (fields[
                        `email`
                      ] as any)
                    ).value
                      ? canUseDOM &&
                      window.open(
                        `mailto:${(
                          fields &&
                          (fields[
                            `email`
                          ] as any)
                        ).value
                        }`
                      )
                      : console.log("no email found")
                  }
                />
              </div>
    
              <div
                css={css`
                                        margin-top: 16px;
                                        width: 100%;
                                        > button {
                                          width: 100%;
                                        }
                                      `}
              >
                <Button
                  label={
                    (
                      fields &&
                      (fields[
                        `phone`
                      ] as any)
                    ).value
                  }
                  iconLeft={"phone"}
                  wired={true}
                  size={"medium"}
                  onClick={() =>
                    canUseDOM &&
                    window.open(
                      `tel:${(
                        fields &&
                        (fields[
                          `phone`
                        ] as any)
                      ).value
                      }`
                    )
                  }
                />
              </div>
              <div
                css={css`
                                        border: 1px solid #d9e1e2;
                                        width: 100%;
                                        margin-top: 56px;
                                      `}
              />
            </div>
          </div>
          </>
    </>
  );   
};
