/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { useEffect, useLayoutEffect } from "react";

export interface AnchorComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const AnchorComponent: React.FunctionComponent<AnchorComponentProps> =
  (props: AnchorComponentProps): React.ReactElement => {
  const { fields } = props;
  const anchorRef = React.useRef<HTMLAnchorElement>(null);
  const anchorName: string = getFieldValue<string>(fields, "anchorId", "");

  const useThisEffect = useLayoutEffect ? useLayoutEffect : useEffect;

  useThisEffect(() => {
    const handleScroll = () => {
      if (window.location.hash === `#${anchorName}`) {
        const anchorElement = anchorRef.current;
        const nextElement = anchorElement?.nextElementSibling as HTMLElement;

        if (nextElement) {
          nextElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    };

    handleScroll();
    window.addEventListener('DOMContentLoaded', handleScroll);

    return () => {
      window.removeEventListener('DOMContentLoaded', handleScroll);
    };
  }, [anchorName]);

  return (
    <a id={anchorName} ref={anchorRef}></a>
  );
};