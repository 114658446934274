
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const SGP: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#C73b3C" d="M512,256H0V97.103c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828L512,256 L512,256z" />
            <g>
                <path fill="#FFFFFF" d="M0,256h512v158.897c0,4.875-3.953,8.828-8.828,8.828H8.828c-4.875,0-8.828-3.953-8.828-8.828V256z" />
                <path fill="#FFFFFF" d="M72.569,172.263c0-29.028,21.3-53.091,49.113-57.542c1.51-0.242,1.578-2.319,0.088-2.663 c-6.886-1.583-14.199-2.034-21.793-1c-29.998,4.086-53.186,30.046-53.61,60.318c-0.483,34.566,27.383,62.712,61.785,62.712 c4.683,0,9.23-0.547,13.606-1.554c1.489-0.342,1.414-2.424-0.094-2.666C93.862,225.42,72.569,201.404,72.569,172.263z" />
                <path fill="#FFFFFF" d="M122.14,187.795l-2.613,7.836l-8.259,0.064c-1.069,0.009-1.513,1.373-0.652,2.008l6.644,4.907 l-2.492,7.875c-0.322,1.02,0.839,1.863,1.708,1.241l6.72-4.803l6.72,4.803c0.87,0.622,2.03-0.222,1.708-1.241l-2.492-7.875 l6.644-4.907c0.861-0.636,0.417-1.999-0.652-2.008l-8.259-0.064l-2.613-7.836C123.913,186.781,122.478,186.781,122.14,187.795z" />
                <path fill="#FFFFFF" d="M162.967,187.795l-2.613,7.836l-8.259,0.064c-1.069,0.009-1.513,1.373-0.652,2.008l6.644,4.907 l-2.492,7.875c-0.322,1.02,0.839,1.863,1.708,1.241l6.72-4.803l6.72,4.803c0.87,0.622,2.03-0.222,1.708-1.241l-2.492-7.875 l6.644-4.907c0.861-0.636,0.417-1.999-0.652-2.008l-8.259-0.064l-2.613-7.836C164.74,186.781,163.306,186.781,162.967,187.795z" />
                <path fill="#FFFFFF" d="M142.738,124.79l-2.613,7.836l-8.259,0.064c-1.069,0.009-1.513,1.373-0.652,2.008l6.644,4.907 l-2.492,7.875c-0.322,1.02,0.839,1.863,1.708,1.241l6.72-4.803l6.72,4.803c0.87,0.622,2.03-0.222,1.708-1.241l-2.492-7.875 l6.644-4.907c0.861-0.636,0.417-1.999-0.652-2.008l-8.259-0.064l-2.613-7.836C144.511,123.776,143.076,123.776,142.738,124.79z" />
                <path fill="#FFFFFF" d="M110.186,148.928l-2.613,7.836l-8.259,0.064c-1.069,0.009-1.513,1.373-0.652,2.008l6.644,4.907 l-2.492,7.875c-0.322,1.02,0.839,1.863,1.708,1.241l6.72-4.803l6.72,4.803c0.87,0.622,2.03-0.222,1.708-1.241l-2.492-7.875 l6.644-4.907c0.861-0.636,0.417-1.999-0.652-2.008l-8.259-0.064l-2.613-7.836C111.959,147.914,110.525,147.914,110.186,148.928z" />
                <path fill="#FFFFFF" d="M175.014,148.928l-2.613,7.836l-8.259,0.064c-1.069,0.009-1.513,1.373-0.652,2.008l6.644,4.907 l-2.492,7.875c-0.322,1.02,0.839,1.863,1.708,1.241l6.72-4.803l6.72,4.803c0.87,0.622,2.03-0.222,1.708-1.241l-2.492-7.875 l6.644-4.907c0.861-0.636,0.417-1.999-0.652-2.008l-8.259-0.064l-2.613-7.836C176.787,147.914,175.352,147.914,175.014,148.928z" />
            </g>
        </svg>
    </React.Fragment>
);
