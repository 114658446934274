
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {canUseDOM} from "../../predicates";
import {fetchData, getLinkUrl, getTransformPdfLink} from "../../utils/helper";
import { useState } from "react";
import {breakpointMax} from "../../style";
import { formatDate } from "../../utils/date";

export interface LatestRegulationPublicationsProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  margin-bottom: 48px;

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const title = css`
  font-weight: bold;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-size: 26px;
    line-height: 150%;
  }
`;
const items = css`
  display: flex;
  flex-direction: column;
`;
const item = css`
  display: flex;
  border-bottom: 1px solid #E3E6E8;
  padding-bottom: 40px;
  padding-top: 40px;
  cursor: pointer;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const left = css`
  width: 200px;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const right = css`
  width: calc(100% - 200px);
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const itemDate = css`
  font-size: 16px;
  text-align: center;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;
  }
`;
const itemTitle = css`
  font-weight: bold;
  font-size: 24px;
  color: #22AA5F;
  margin-bottom: 16px;
`;
const itemMore = css`
  font-size: 16px;
  color: #22AA5F;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const itemContent = css`
  font-size: 16px;
  color: #354450;
  line-height: 150%;
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  white-space: normal;
`;
const link = css`
    margin-top: 24px;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #22AA5F;
    cursor: pointer;
`;

const getTargetLink = async (data: any) => {
    if( canUseDOM ) {
        if (data && data['linked file']) {
            const link = await getTransformPdfLink(data['linked file'].match(/{([^}]+)}/)[1]);
            window.open(link, '_self');
        } else {
            if (data && data['external link publication']){
                window.open(data['external link publication'], '_blank');
            } else {
                if (data && data['link url']) {
                    const link = await getLinkUrl(data['link url']);
                    if (link) {
                        window.open(link, '_self');
                    }
                }
            }
        }
    }
};

export const LatestRegulationPublications: React.FunctionComponent<LatestRegulationPublicationsProps> = (props: LatestRegulationPublicationsProps): React.ReactElement => {
    const { fields } = props;
    const linkLabel: string = getFieldValue<string>(fields, 'link label', '');
    const linkUrl: string = getFieldValue<string>(fields, 'link url', '');

    const [expend, setExpend] = useState<boolean>(false);
    const [publications, setPublications] = useState<any[]>([])

    const getDataFromItemId = async (id: string) => {
        try {
            const data: any = await fetchData(id);
            //const { root } = data;
            setPublications(data);
            //setMenu(root);
        } catch (err) {
            console.error(err);
        }
    };

    React.useEffect(() => {
        getDataFromItemId('2DFE7ECA-172A-4AAF-86F7-3EFCA8B20F37');
    }, []);

    const expendList = () => {
        setExpend(!expend);
    }

    return (
        <div css={container}>
            <div css={title}>
                <Text
                    field={fields && fields.title as SitecoreGenericTextField}
                />
            </div>
            <div css={items}>
                {
                    (publications || []).slice(0, expend ? publications.length : 3).map((arr: any, index: number) => (
                        <div css={item} onClick={() => getTargetLink(arr)}>
                            <div css={left}>
                                <div css={itemDate}>
                                    { formatDate(arr['date publication'], 'YYYY') }
                                </div>
                            </div>
                            <div css={right}>
                                <div css={itemTitle}>
                                    { arr.title }
                                </div>
                                <div css={itemContent} dangerouslySetInnerHTML={{ __html: arr['short content']}} />
                                <div css={itemMore}>
                                    Find out more
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    linkLabel && publications.length > 3 && (
                        <div css={link}>
                            <div onClick={expendList}>{ expend ? 'Reduce list' : linkLabel}</div>
                        </div>
                    )
                }

            </div>
        </div>
    );
}
