/** @jsx jsx */
import { jsx } from '@emotion/react';
import { SelectBox } from '@bdl-cmn-shared-packages-npm/design-system';

import React from 'react';
import { style } from './index.style';

export interface InputSortProps {
  sort?: any;
  baseValues?: any;
  onSortChange: (sort: any) => void;
}

export function InputSort({ sort, baseValues, onSortChange }: InputSortProps) {
  const options: any[] = React.useMemo(() => {
    const optionValues: any[] = [];

    if (baseValues)
      Object.entries(baseValues).map(([key, value]) => {
        const item = value as any;
        if (value)
          optionValues.push({
            name: item.name,
            value: key,
            default: item.default,
            field: item.field,
            dir: item.dir,
          });
      });

    return optionValues;
  }, [sort]);

  const getSortDefault = (sort: any): any => {
    let defaultOption: any = null;

    if (options)
      options.forEach((option: any) => {
        if (option.default === true && !defaultOption) defaultOption = option;

        if (sort && option.value === sort.value) defaultOption = option;
      });

    return defaultOption;
  };

  const handleSortChange = (event: any): void => {
    let newSort = sort;
    const selection = event.target.value;
    if (options)
      options.forEach((option: any) => {
        if (selection === option.value) newSort = option;
      });

    onSortChange(newSort);
  };

  return (
    <div css={style.inputSort}>
      <span>Sort by</span>
      <select name='filter' id='filter' onChange={handleSortChange}>
        {options.map((option, index) => (
          <option selected={option.default} value={option.value} key={`sort-${index}`}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}
