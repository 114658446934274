import { breakpoint, breakpointMax } from '../../style';

import { css } from '@emotion/react';
import hexaFade from './hexaFade.svg';

export const style = {
  mainContainer: css`
    display: none !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgb(164 188 194 / 0.5%) 0%, rgba(164, 188, 194, 0) 100%);
    border-top: solid 1px #e3e6e8;
    @media (max-width: ${breakpointMax}) {
      padding: 16px;
    }
  `,
  container: css`
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      padding-top: 32px;
    }
  `,
  heading: css`
    font-weight: 600;
    font-size: 14px;
    color: #425563;
    letter-spacing: 0.1em;
    line-height: 18px;
  `,
  title: css`
    color: #22AA5F;
    font-size: 32px;
    line-height: 41px;
    font-weight: 900;
    margin-top: 16px;
    margin-bottom: 8px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 32px;
      line-height: 41px;
      text-align: center;
      margin-top: 16px;
    }
  `,
  author: css`
    display: flex;
    align-items: center;
    color: #8daeb7;
    line-height: 150%;
    font-size: 12px;
    @media (max-width: ${breakpointMax}) {
      flex-direction: row;
    }
  `,
  mobile: css`
    margin-left: 3px;
    @media (max-width: ${breakpointMax}) {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
      margin-top: 8px;
    }
  `,
  avatar: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  relatedSubject: css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    color: #354450;
  `,
  separator: css`
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};
