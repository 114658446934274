import {
  Button,
  Icon,
  Pagination,
  Tab,
  Tabs,
} from "@bdl-cmn-shared-packages-npm/design-system";
import React, { useEffect, useState } from "react";
import { breakpointMax } from "../../../style";
import { css, jsx } from "@emotion/react";

import { EMPTY_DATA_PLACEHOLDER } from "../../constants";
import Loader from "../Loader/loader.component";
import OfficialListTableView from "./official-list-table.component";
import RoundTag from "../RoundTag/roundTag.component";
import { formatDate } from "../../../utils/date";
import { styleOfficialList } from "./official-list.styles";
import {GET_LUXSE_REPORTS} from "../../../graphql/queries/luxse-reports.queries";
import {luxseGatewayGraphqlClient} from "../../../luxse-gateway-graphql-client";

/** @jsx jsx */

export enum OfficialListTypeEnum {
  BDL_MARKET = "BDL_MARKET",
  EURO_MTF = "EURO_MTF",
  SECURITY_OFFICIAL_LIST = "SECURITY_OFFICIAL_LIST",
}

export class OfficialListModel {
  allData?: any;
  splitData?: any;
  type?: OfficialListTypeEnum;
  totalHits?: number;
}

const cardStyle = {
  dateSection: css`
    padding: 16px;
    background: #ffffff;
    border-top: 1px solid #d9dee2;
    border-bottom: 1px solid #d9dee2;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;
  `,
  dateLabel: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #425563;
  `,
  dateValue: css`
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #54666f;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0 24px 0;
    width: 100%;
    border: 1px solid #d9dee2;
    border-radius: 8px;
    margin-bottom: 16px;
  `,
  top: css`
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;
  `,
  left: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 32px;
    max-width: 32px;
    height: 32px;
    border: 2px solid #cb1231;
    border-radius: 20px;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #354450;
  `,
  right: css`
    color: #354450;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    border: solid 1px #e3e6e8;
    background: #ffffff;
    border-radius: 999em;
    padding: 8px 16px;
    font-weight: bold;
    width: fit-content !important;
  `,
  title: css`
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 12px;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #354450;
    width: 100%;
  `,
  label: css`
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #54666f;
  `,
  date: css`
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: rgba(66, 55, 99, 0.7);
    margin-bottom: 16px;

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.01em;
      color: #354450;
    }
  `,
  table: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px #d9dee2;
    &:last-of-type {
      border-bottom: none;
    }
    &:first-of-type {
      border-top: solid 1px #d9dee2;
    }
  `,
  rowPlain: css`
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f6f8f9;
    border-radius: 0px 0px 8px 8px;
  `,
  col: css`
    padding: 4px 16px 4px 16px;
    border-right: solid 1px #d9dee2;
    &:last-of-type {
      border-right: none;
    }
  `,
  colPlain: css`
    padding: 0 16px 0 16px;
    border-right: solid 1px #d9dee2;
    &:last-of-type {
      border-right: none;
    }
  `,
  colTop: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  `,
  colTopRight: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #354450;
    opacity: 0.7;
    margin-bottom: 8px;
    margin-top: 8px;
  `,
  colBottomRight: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #354450;
    margin-bottom: 8px;
  `,
  colTopLeft: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #354450;
    opacity: 0.7;
    margin-bottom: 8px;
    margin-top: 8px;
  `,
  colBottomLeft: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #354450;
    margin-bottom: 8px;
  `,
  colBottom: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  `,
  indice: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #22aa5f;
    svg {
      margin-right: 8px;
      font-size: 18px;
    }
  `,
  value: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  `,
};

export function OfficialListView(props: any) {


  const [officialList, setOfficialList] = useState<OfficialListModel>();
  const [bdlMarket, setBdlMarket] = useState<OfficialListModel>();
  const [euroMtf, setEuroMtf] = useState<OfficialListModel>();
  const [securityOFL, setSecurityOFL] = useState<OfficialListModel>({ splitData:[] });
  const [officialListLoader, setOfficialListLoader] = useState<boolean>(true);

  const fetchOfficialList = async () => {
    const bdlMarketResponse = await luxseGatewayGraphqlClient.query({
      query: GET_LUXSE_REPORTS,
      variables: {
        query: null,
        ids: [],
        types: ["OFFICIAL_LIST"],
        subTypes: ["BDL_MARKET"],
        fromDate: null,
        toDate: null,
        usePageable: false,
        page: 0,
        size: 0,
        sorts: ["date,DESC"]
      },
    });

    const euroMtfResponse = await luxseGatewayGraphqlClient.query({
      query: GET_LUXSE_REPORTS,
      variables: {
        query: null,
        ids: [],
        types: ["OFFICIAL_LIST"],
        subTypes: ["EURO_MTF"],
        fromDate: null,
        toDate: null,
        usePageable: false,
        page: 0,
        size: 0,
        sorts: ["date,DESC"]
      },
    });

    const luxsolResponse = await luxseGatewayGraphqlClient.query({
      query: GET_LUXSE_REPORTS,
      variables: {
        query: null,
        ids: [],
        types: ["OFFICIAL_LIST"],
        subTypes: ["SOL"],
        fromDate: null,
        toDate: null,
        usePageable: false,
        page: 0,
        size: 0,
        sorts: ["date,DESC"]
      },
    });

    const bdlMarket: OfficialListModel = new OfficialListModel();
    bdlMarket.type = OfficialListTypeEnum.BDL_MARKET;
    bdlMarket.allData = bdlMarketResponse.data.luxseReports.resultList;
    bdlMarket.totalHits = bdlMarket.allData.length;
    bdlMarket.splitData = createPage(bdlMarket.allData, 0);
    setBdlMarket(bdlMarket);

    const euroMtf: OfficialListModel = new OfficialListModel();
    euroMtf.type = OfficialListTypeEnum.EURO_MTF;
    euroMtf.allData = euroMtfResponse.data.luxseReports.resultList;
    euroMtf.totalHits = euroMtf.allData.length;
    euroMtf.splitData = createPage(euroMtf.allData, 0);
    setEuroMtf(euroMtf);

    const luxsol: OfficialListModel = new OfficialListModel();
    luxsol.type = OfficialListTypeEnum.SECURITY_OFFICIAL_LIST;
    luxsol.allData = luxsolResponse.data.luxseReports.resultList;
    luxsol.totalHits = luxsol.allData?.length;
    luxsol.splitData = createPage(luxsol.allData, 0);
    setSecurityOFL(luxsol);

    const combinedList = new OfficialListModel();
    combinedList.allData = [
      ...bdlMarketResponse.data.luxseReports.resultList,
      ...euroMtfResponse.data.luxseReports.resultList,
      ...luxsolResponse.data.luxseReports.resultList
    ];
    combinedList.splitData = createPage(combinedList.allData, 0);
    combinedList.totalHits = combinedList.allData.length;

    setOfficialList(combinedList);
    setOfficialListLoader(false);
  };


  const onPaginationUpdate = (data: any, pageNumber: number) => {
    switch (data?.type) {
      case OfficialListTypeEnum.BDL_MARKET:
        bdlMarket.splitData = createPage(data?.allData, pageNumber);
        setBdlMarket(bdlMarket);
        break;

      case OfficialListTypeEnum.EURO_MTF:
        euroMtf.splitData = createPage(data?.allData, pageNumber);
        setEuroMtf(euroMtf);
        break;

      case OfficialListTypeEnum.SECURITY_OFFICIAL_LIST:
        securityOFL.splitData = createPage(data?.allData, pageNumber);
        setSecurityOFL(securityOFL);
        break;
    }

    setOfficialList(createPage(data?.allData, pageNumber));
  };

  function createPage(data: any, currentPage: number): any {
    let startIndex = 0;
    let endIndex = 10;

    if (currentPage > 0) {
      endIndex = (currentPage + 1) * 10;
      startIndex = endIndex - 10;
    }
    return data?.slice(startIndex, endIndex);
  }

  useEffect(() => {
    fetchOfficialList()
  }, []);

  return (
    <div css={styleOfficialList.parentContainer}>
      <div css={styleOfficialList.container}>
        <div css={styleOfficialList.containerButton}>
          <h1>Daily official lists</h1>
          <div css={styleOfficialList.button}>
            <Button
              css={styleOfficialList.button_1}
              iconLeft="file-pdf"
              iconRight=""
              label="Bourse de Luxembourg"
              onClick={() =>
                  window.open(bdlMarket?.splitData[0]?.downloadUrl, '_blank')
              }
              variant="primary"
            />
            <Button
              css={styleOfficialList.button_1}
              iconLeft="file-pdf"
              iconRight=""
              label="Euro MTF"
              onClick={() =>
                  window.open(euroMtf?.splitData[0]?.downloadUrl, '_blank')
              }
              variant="primary"
            />
            <Button
              css={styleOfficialList.button_1}
              iconLeft="file-pdf"
              iconRight=""
              label="LuxSE SOL"
              onClick={() =>
                  window.open(securityOFL?.splitData[0]?.downloadUrl, '_blank')
              }
              variant="primary"
            />
          </div>
        </div>
        <div css={styleOfficialList.containerTab}>
          <Tabs tabPosition={"left"}>
            <Tab label="Bourse de Luxembourg" selected>
              <div css={styleOfficialList.tableContainer}>
                <h1 css={styleOfficialList.titleTab1}>Bourse de Luxembourg</h1>
                <Loader loading={officialListLoader}>
                  <div
                    css={css`
                      display: block;
                      @media (max-width: ${breakpointMax}) {
                        display: none;
                      }
                    `}
                  >
                    <OfficialListTableView
                      officialListData={bdlMarket?.splitData}
                    />
                  </div>
                  <div
                    css={css`
                      display: none;
                      @media (max-width: ${breakpointMax}) {
                        display: block;
                        margin-left: 16px;
                        margin-right: 16px;
                        width: 92%;
                      }
                    `}
                  >
                    {bdlMarket?.splitData.map((item: any) => {
                      return (
                        <div css={cardStyle.container}>
                          <div
                            css={css`
                              display: flex;
                              align-self: flex-start;
                              padding: 8px;
                              > div {
                                font-size: 19px !important;
                              }
                            `}
                          >
                            <RoundTag
                              borderSize={2}
                              textColor="#425563"
                              small={true}
                              padding={true}
                              css
                            >
                              <Icon icon={"file-pdf"} />
                            </RoundTag>
                          </div>
                          <div css={cardStyle.dateSection}>
                            <span css={cardStyle.dateLabel}>
                              Publication date
                            </span>
                            <span css={cardStyle.dateValue}>{formatDate(item?.date)}</span>
                          </div>
                          <div
                            css={css`
                              padding-left: 16px;
                              padding-right: 16px;
                              margin-top: 12px;
                              width: 100%;
                              button {
                                width: 100%;
                              }
                            `}
                          >

                            <Button
                              css={{
                                "align-items": "center",
                                "justify-content": "center",
                                height: "35px",
                              }}
                              iconLeft="download"
                              iconRight=""
                              label="Download"
                              onClick={() =>
                                  window.open(item?.downloadUrl, '_blank')
                              }
                              variant="primary"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Loader>
                <Pagination
                  initialPage={0}
                  onPageChange={(d: any) => {
                    onPaginationUpdate(bdlMarket, d.selected);
                  }}
                  total={
                    bdlMarket?.totalHits / 10 > 200
                      ? 200
                      : bdlMarket?.totalHits / 10
                  }
                />
              </div>
            </Tab>
            <Tab label="Euro MTF" onTabChange={null}>
              <div css={styleOfficialList.tableContainer}>
                <h1 css={styleOfficialList.titleTab1}>Euro MTF</h1>
                <Loader loading={officialListLoader}>
                  <div
                    css={css`
                      display: block;
                      @media (max-width: ${breakpointMax}) {
                        display: none;
                      }
                    `}
                  >
                    <OfficialListTableView
                      officialListData={euroMtf?.splitData}
                    />
                  </div>
                  <div
                    css={css`
                      display: none;
                      @media (max-width: ${breakpointMax}) {
                        display: block;
                        margin-left: 16px;
                        margin-right: 16px;
                        width: 92%;
                      }
                    `}
                  >
                    {euroMtf?.splitData.map((item: any) => {
                      return (
                        <div css={cardStyle.container}>
                          <div css={cardStyle.title}>
                            {item?.label || EMPTY_DATA_PLACEHOLDER}
                          </div>
                          <div css={cardStyle.dateSection}>
                            <span css={cardStyle.dateLabel}>
                              Publication date
                            </span>
                            <span css={cardStyle.dateValue}>
                              {formatDate(item?.date) ||
                                EMPTY_DATA_PLACEHOLDER}
                            </span>
                          </div>
                          <div
                            css={css`
                              padding-left: 16px;
                              padding-right: 16px;
                              margin-top: 12px;
                              width: 100%;
                              button {
                                width: 100%;
                              }
                            `}
                          >
                            <Button
                              css={{
                                "align-items": "center",
                                "justify-content": "center",
                                height: "35px",
                              }}
                              iconLeft="download"
                              iconRight=""
                              label="Download"
                              onClick={() =>
                                  window.open(item?.downloadUrl, '_blank')
                              }
                              variant="primary"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Loader>
                <Pagination
                  initialPage={0}
                  onPageChange={(d: any) => {
                    onPaginationUpdate(euroMtf, d.selected);
                  }}
                  total={
                    euroMtf?.totalHits / 10 > 200
                      ? 200
                      : euroMtf?.totalHits / 10
                  }
                />
              </div>
            </Tab>
            <Tab label="LuxSE SOL" onTabChange={null}>
              <div css={styleOfficialList.tableContainer}>
                <h1 css={styleOfficialList.titleTab1}>LuxSE SOL</h1>
                {securityOFL?.splitData && <Loader loading={officialListLoader}>
                  <div
                    css={css`
                      display: block;
                      @media (max-width: ${breakpointMax}) {
                        display: none;
                      }
                    `}
                  >
                    <OfficialListTableView
                      officialListData={securityOFL?.splitData}
                    />
                  </div>
                  <div
                    css={css`
                      display: none;
                      @media (max-width: ${breakpointMax}) {
                        display: block;
                        margin-left: 16px;
                        margin-right: 16px;
                        width: 92%;
                      }
                    `}
                  >
                    {securityOFL?.splitData.map((item: any) => {
                      return (
                        <div css={cardStyle.container}>
                          <div css={cardStyle.title}>
                            {item?.label || EMPTY_DATA_PLACEHOLDER}
                          </div>
                          <div css={cardStyle.dateSection}>
                            <span css={cardStyle.dateLabel}>
                              Publication date
                            </span>
                            <span css={cardStyle.dateValue}>
                              {formatDate(item?.date) ||
                                EMPTY_DATA_PLACEHOLDER}
                            </span>
                          </div>
                          <div
                            css={css`
                              padding-left: 16px;
                              padding-right: 16px;
                              margin-top: 12px;
                              width: 100%;
                              button {
                                width: 100%;
                              }
                            `}
                          >
                            <Button
                              css={{
                                "align-items": "center",
                                "justify-content": "center",
                                height: "35px",
                              }}
                              iconLeft="download"
                              iconRight=""
                              label="Download"
                              onClick={() =>
                                  window.open(item?.downloadUrl, '_blank')
                              }
                              variant="primary"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Loader>
                }
                <Pagination
                  initialPage={0}
                  onPageChange={(d: any) => {
                    onPaginationUpdate(securityOFL, d.selected);
                  }}
                  total={
                    securityOFL?.totalHits / 10 > 200
                      ? 200
                      : securityOFL?.totalHits / 10
                  }
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default OfficialListView;
