/** @jsx jsx */
import * as React from "react";
import { css, jsx } from "@emotion/react";
import { style } from "./columnGraph.style";
import ReactApexChart from "react-apexcharts";

export interface ColumnGraphProps {
  data?: any;
  label?: string;
}

const canUseDOM: boolean = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);
export const ColumnGraph: React.FunctionComponent<ColumnGraphProps> = ({
  data,
  label,
}: ColumnGraphProps) => {
  const graphData: any = {
    series: [
      {
        name: "Variations",
        data: [
          1.45, 5.42, 5.9, -0.42, -12.6, -18.1, -18.2, -14.16, -11.1, -6.09,
        ],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 400,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
          },
        },
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: -10000000,
                to: 0,
                color: "#F18700",
              },
              {
                from: 0,
                to: 1000000000000,
                color: "#22AA5F",
              },
            ],
          },
          columnWidth: "48px",
        },
      },
      dataLabels: {
        enabled: true,
      },
      yaxis: {
        title: {
          text: "Variations",
        },
        labels: {
          formatter: (y: any) => {
            return y.toFixed(0) + "%";
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2011-02-01",
          "2011-02-01",
          "2011-03-01",
          "2011-04-01",
          "2011-05-01",
          "2011-06-01",
          "2011-07-01",
          "2011-08-01",
          "2011-09-01",
          "2011-10-01",
        ],
        labels: {
          rotate: -90,
        },
      },
    },
  };

  return (
    <div css={style.container}>
      {canUseDOM && data && data.marketData.pricesHistory.length > 0 ? (
        <ReactApexChart
          options={graphData.options}
          series={graphData.series}
          type="bar"
          height={600}
          width={800}
        />
      ) : (
        <div>no Data</div>
      )}
    </div>
  );
};

export default ColumnGraph;
