
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const VCT: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <rect x="114.76" y="88.277" fill="#FCD116" width="282.48" height="335.45" />
            <path fill="#009E60" d="M503.172,423.721h-105.93V88.273h105.931c4.875,0,8.828,3.953,8.828,8.828v317.792 C512,419.769,508.047,423.721,503.172,423.721z" />
            <path fill="#0072C6" d="M8.828,88.273h105.931v335.448H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.101 C0,92.226,3.953,88.273,8.828,88.273z" />
            <g>
                <polygon fill="#009E60" points="191.886,251.475 220.521,308.746 249.157,251.475 220.521,194.204 " />
                <polygon fill="#009E60" points="262.843,251.475 291.479,308.746 320.114,251.475 291.479,194.204 " />
                <polygon fill="#009E60" points="227.364,320.327 255.999,377.597 284.635,320.327 255.999,263.056 " />
            </g>
        </svg>
    </React.Fragment>
);
