import * as React from "react";
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/react";
export interface DiagonalLineTopLeftProps {
  className?: string;
  size?: any;
  // theme?: 'green' | 'red' | undefined;
  style?: React.CSSProperties;
}

export const DiagonalLineTopLeft: React.FunctionComponent<DiagonalLineTopLeftProps> = (
  props: DiagonalLineTopLeftProps
): React.ReactElement => {
  const { className, size, style } = props;
  // const renderColor = () => {
  //   if (theme === 'green') {
  //     return '#005E2E'
  //   } else if (theme === 'red') {
  //     return '#CA1234'
  //   } else {
  //     return '#A4BCC2';
  //   }
  // }

  return (
    <svg
      {...size}
      viewBox="0 0 501 571"
      height="571" 
      width="501"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <mask height="571" id="mask0_401_8432" style={{ "maskType": "luminance" }} width="502" x="-1" y="0" maskUnits="userSpaceOnUse">
        <path d="M500.357 0H-1V571H500.357V0Z" fill="white" opacity="0.5" />
      </mask>
      <g mask="url(#mask0_401_8432)" opacity="0.5">
        <path d="M282.731 45.1272L318.965 66.2289" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.9286" />
        <path d="M87.5342 385.751L135.83 413.904" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.9286" />
        <mask height="571" id="mask1_401_8432" style={{ "maskType": "luminance" }} width="502" x="-1" y="0" maskUnits="userSpaceOnUse">
          <path d="M500.381 0H-1V571H500.381V0Z" fill="white" opacity="0.5" />
        </mask>
        <g mask="url(#mask1_401_8432)" opacity="0.5">
          <path d="M-95.0122 -174.906L258.583 31.0758" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.9286" />
          <path d="M-95.0122 -110.005L242.763 86.7648" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.9286" />
          <path d="M-95.0122 -45.1021L154.499 100.252" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.9286" />
          <path d="M-95.0122 149.604L82.9401 253.27" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.9286" />
          <path d="M-95.0122 279.433L63.386 371.701" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.9286" />
          <path d="M-95.0122 344.31L47.5658 427.391" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.9286" />
          <path d="M-95.0122 52.251L64.3686 145.108" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="13.9286" />
        </g>
      </g>
      {/* <defs>
        <clipPath id="clip0_276_2732">
          <rect
            width="1350"
            height="556"
            fill="white"
            transform="translate(0, 0)"
          />
        </clipPath>
      </defs> */}
    </svg>
  );
};
