import React, {useEffect, useState} from "react";

import {BACKBOURSE_ENDPOINT} from "../../../../../../backbourse";
import Loader from "../../../../Loader/loader.component";
import  {PreciousMetalsDetailsTable} from "./table";
import axios from 'axios';
import {cssfApprovalsStyle} from "../../../../CssfApprovals/cssf-approvals-view.style";
import {jsx} from "@emotion/react";
import {marketNewsStyle} from "../../../MarketNews/index.style";
import { PreciousMetal } from "../../../../../../interfaces/precious-metal";
import { luxseGatewayGraphqlClient } from "../../../../../../luxse-gateway-graphql-client";
import { GET_PRECIOUS_METALS_QUERY } from "../../../../../../graphql/queries/securities/precious-metal.queries";

/** @jsx jsx */

export function PreciousMetalsDetailsView(props: any) {

    const client = luxseGatewayGraphqlClient;

    const [preciousMetals, setPreciousMetals] = useState<PreciousMetal[]>();
    const [loader, setLoader] = useState<boolean>(true);

    const fetchPreciousMetals = async () => {
        let preciousMetalsTmp : PreciousMetal[] = [];
        try {
            const result: any = await client.query({
                query: GET_PRECIOUS_METALS_QUERY
            });

            preciousMetalsTmp = result?.data?.preciousMetals || [];
        } catch (e) {
            console.error("Failed to get Precious metals", e);
        }
        setPreciousMetals(preciousMetalsTmp);
        setLoader(false);
    };

    useEffect(() => {
        fetchPreciousMetals();
    }, []);

    return (
        <>
            <div css={cssfApprovalsStyle.container}>
                <Loader loading={loader}>
                    <div css={cssfApprovalsStyle.resultContainer}>
                        <p css={cssfApprovalsStyle.textPosition}>
                            <span css={cssfApprovalsStyle.resultText}>{preciousMetals?.length}&nbsp;</span><span>Precious Metals</span>
                        </p>
                    </div>
                    <div css={marketNewsStyle.container}>
                        <PreciousMetalsDetailsTable data={preciousMetals}/>
                    </div>
                </Loader>
            </div>
        </>
    )
}

export default PreciousMetalsDetailsView