/** @jsx jsx */

import { css, jsx } from "@emotion/react";

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import DocumentHeader from "../../UI/list/document/header";
import DocumentRow from "../../UI/list/document/row";
import DocumentSearchCard from "../../UI/list/document/card-mobile";
import {
  IssuerSummaryCard,
  IssuerSummaryCards,
} from "../../IssuerCard/issuer-summary";
import { ProgrammeCardTab } from "..";
import React from "react";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import { SearchTable } from "../../UI/list/base";
import SecurityHeader from "../../UI/list/security/header";
import { SecurityRow } from "../../UI/list/security/row";
import SecuritySearchCard from "../../UI/list/security/card-mobile";
import { genericStyle } from "../../UI/generic/generic.style";
import { style } from "./overview.style";
import { ProgrammeDetail } from "../../../../graphql/queries/programmes.queries";
import {
  DocumentSearchResultDto,
  SecuritySearchResultDto,
} from "../../../../interfaces/search/search";
import Loader from "../../Loader/loader.component";

export interface Row {
  label: string;
  action: () => void;
}

export interface ProgrammeOverviewProps {
  programme: ProgrammeDetail;
  onTabChange: (tab: ProgrammeCardTab) => void;
  onNoticeDocument?: () => void;
  notices?: DocumentSearchResultDto;
  loadingNotices?: boolean;
  onProspectusDocument?: () => void;
  prospectuses?: DocumentSearchResultDto;
  loadingProspectuses?: boolean;
  securities?: SecuritySearchResultDto;
  loadingSecurities?: boolean;
}

const buttonStyle = css`
  box-shadow: none !important;
  background-color: transparent !important;
  color: #22aa5f !important;
  border: solid 1px #22aa5f !important;
  margin-top: 30px !important;

  :hover {
    background-color: #f4f6f7 !important;
  }
`;

const ProgrammeOverview = ({
  programme,
  onTabChange,
  onNoticeDocument,
  notices,
  loadingNotices = false,
  onProspectusDocument,
  prospectuses,
  loadingProspectuses = false,
  securities,
  loadingSecurities = false,
}: ProgrammeOverviewProps) => {
  const issuers = [programme.mainIssuer];
  if (programme.issuers?.length) {
    programme.issuers.map((issuer) => {
      if (issuer && issuer.id !== programme.mainIssuer?.id)
        issuers.push(issuer);
    });
  }

  return (
    <div css={[style.programmeOverview, { maxWidth: "100%" }]}>
      <div css={style.bottomContainer}>
        <div css={[style.type, { marginTop: 0 }]}>
          <h2>Issuer</h2>
          <IssuerSummaryCards
            isGreen={false} //old condition : programme.mainIssuer.statistics.nbGreens > 0
            issuers={issuers}
            loading={false}
          />
        </div>
      </div>
      <div css={style.bottomContainer}>
        <div css={style.type}>
          <h2>Prospectus</h2>
          <div>
            <Loader
              loading={loadingProspectuses}
              padding={"0 !important"}
              margin={"0 !important"}
            >
              <div css={genericStyle.desktopDisplay}>
                <SearchTable
                  onPaginationUpdate={() => {}}
                  pageNumber={0}
                  data={prospectuses?.documents}
                  totalResultSize={0}
                  Header={() => <DocumentHeader />}
                  Row={(row) => (
                    <DocumentRow
                      key={`row-o-documents-prospectuses-${row?.id}`}
                      row={row}
                    />
                  )}
                />
              </div>
              <div css={[genericStyle.mobileDisplay, style.mobileContainer]}>
                <SearchMobile
                  onPaginationUpdate={() => {}}
                  pageNumber={0}
                  data={prospectuses?.documents}
                  totalResultSize={0}
                  Card={(card) => (
                    <DocumentSearchCard
                      key={`card-o-documents-prospectuses-${card?.id}`}
                      card={card}
                    />
                  )}
                />
              </div>
              {prospectuses?.totalHits > 5 && (
                <div css={style.overviewButton}>
                  <Button
                    css={buttonStyle}
                    onClick={() => {
                      onProspectusDocument();
                      onTabChange(ProgrammeCardTab.DOCUMENTS);
                    }}
                    wired={false}
                    iconLeft="plus-circle"
                    label={`See all ${prospectuses.totalHits} prospectus`}
                  />
                </div>
              )}
            </Loader>
          </div>
        </div>
        <div css={style.type}>
          <h2>Notices</h2>
          <div>
            <Loader
              loading={loadingNotices}
              padding={"0 !important"}
              margin={"0 !important"}
            >
              <div css={genericStyle.desktopDisplay}>
                <SearchTable
                  onPaginationUpdate={() => {}}
                  pageNumber={0}
                  data={notices?.documents}
                  totalResultSize={0}
                  Header={() => <DocumentHeader />}
                  Row={(row) => (
                    <DocumentRow
                      key={`row-o-documents-notices-${row?.id}`}
                      row={row}
                    />
                  )}
                />
              </div>
              <div css={[genericStyle.mobileDisplay, style.mobileContainer]}>
                <SearchMobile
                  onPaginationUpdate={() => {}}
                  pageNumber={0}
                  data={notices?.documents}
                  totalResultSize={0}
                  Card={(card) => (
                    <DocumentSearchCard
                      key={`card-o-documents-notices-${card?.id}`}
                      card={card}
                    />
                  )}
                />
              </div>
              {notices?.totalHits > 5 && (
                <div css={style.overviewButton}>
                  <Button
                    css={buttonStyle}
                    onClick={() => {
                      onNoticeDocument();
                      onTabChange(ProgrammeCardTab.DOCUMENTS);
                    }}
                    wired={false}
                    iconLeft="plus-circle"
                    label={`See all ${notices.totalHits} notices`}
                  />
                </div>
              )}
            </Loader>
          </div>
        </div>
        <div css={style.type}>
          <h2>Securities</h2>
          <div>
            <Loader
              loading={loadingSecurities}
              padding={"0 !important"}
              margin={"0 !important"}
            >
              <div css={genericStyle.desktopDisplay}>
                <SearchTable
                  onPaginationUpdate={() => {}}
                  pageNumber={0}
                  data={securities?.securities}
                  totalResultSize={0}
                  Header={() => <SecurityHeader />}
                  Row={(row) => (
                    <SecurityRow key={`row-o-security-${row?.id}`} row={row} />
                  )}
                />
              </div>
              <div css={[genericStyle.mobileDisplay, style.mobileContainer]}>
                <SearchMobile
                  onPaginationUpdate={() => {}}
                  pageNumber={0}
                  data={securities?.securities}
                  totalResultSize={0}
                  Card={(card) => (
                    <SecuritySearchCard
                      key={`card-o-security-${card?.id}`}
                      card={card}
                    />
                  )}
                />
              </div>
              {programme?.activeSecurities > 5 && (
                <div css={style.overviewButton}>
                  <Button
                    css={buttonStyle}
                    onClick={() => onTabChange(ProgrammeCardTab.SECURITIES)}
                    wired={false}
                    iconLeft="plus-circle"
                    label={`See all ${programme?.activeSecurities} securities`}
                  />
                </div>
              )}
            </Loader>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgrammeOverview;
