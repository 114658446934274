import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import { Table} from "@bdl-cmn-shared-packages-npm/design-system";
import {MarketdataModel} from "../../market-data-view.component";
import CssfApprovalsRow from "./cssf-approvals-row.component";

export const CssfApprovalsView = (props: any) => {

    const cssfApprovals = props.cssfApprovalsData;
    return (

        <Table>
            <tbody>
            {cssfApprovals?.totalHits > 0 ? cssfApprovals?.currentData?.map((cssfApprovalData: MarketdataModel) =>
                    (<CssfApprovalsRow cssfApprovalRow={cssfApprovalData}/>))
                : "no data"}
            </tbody>
        </Table>

    )

}
export default CssfApprovalsView;