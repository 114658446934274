/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React from 'react';
import { style } from "./index.style";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";

export interface ResultsIndicatorProps {
    resultsNumber: number;
    category?: string;
    filters?: any;
}

export const ResultsIndicator = (props: ResultsIndicatorProps): JSX.Element => {
    const {
        resultsNumber,
        category,
        filters
    } = props;

    return (
        <div css={style.container}>
            <div css={style.top}>
                <span>{resultsNumber}</span>
                {` result${resultsNumber > 1 ? 's' : ''}`}
                { filters?.q ?
                    <>
                        {` for "`}<span>{filters?.q}</span>{`"`}
                    </>
                    :<></>
                }
                { category ?
                  <>
                    {` in `}<span>{category}</span>
                  </>
                  : <></>
                }
            </div>
            <div css={style.bottom}>
                Filter data with the <Icon icon={'filter'} /> button
            </div>
        </div>
    );
}


export default ResultsIndicator;
