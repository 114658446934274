/**@jsx jsx */
import { css, jsx } from '@emotion/react';
import { useMemo } from 'react';

export const useStyle = (density: string) => {
  return useMemo(() => {
    return {
      table: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      position: relative;
      width: 100%;
      th,
      td {
        padding-top: ${density === 'low' ? '24px' : density === 'medium' ? '16px' : '12px'};
        padding-bottom: ${density === 'low' ? '24px' : density === 'medium' ? '16px' : '12px'};
        border-bottom: solid 1px #d9dee2;
      }
      table {
        width: 100%;
        border: none;
        color: #354450 !important;
        table-layout: fixed;
      }
     
    }
    `,
      slider: css`
        width: calc(100% - 360px);
        background: red;
        height: 30px;
        position: fixed;
        bottom: 20px;
      `,
      paginationContainer: css`
        display: flex;
        align-items: center;
      `,
      navigateToPage: css`
        display: -webkit-inline-box;
        margin-left: 3%;
      `,
      spanText: css`
        color: #425563;
        font-weight: 500;
        font-size: 14px;
        margin-right: 8px;
        line-height: normal;
      `,
      button: css`
        width: 32px;
        height: 32px;
        border-radius: 0 4px 4px 0;
        line-height: 0.15;
      `,
      inputBox: css`
        width: 70px;
        height: 34.5px;
        border-radius: 4px 0 0 4px;
        border: solid 1px #E3E6E8;
        background: #ffffff;
        color: #5E798D;
        font-size: 14px;
        line-height: 14px;
        padding: 0px 12px;
        border-width: inherit;
      `,
      pagination: css`
        :aria-disabled {
          background-color: #fff;
        }
      `,


    };
  }, [density]);
};
