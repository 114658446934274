import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
  ComponentFields,
  Text,
  RichText
} from "@sitecore-jss/sitecore-jss-react";
import { SitecoreGenericTextField } from "../../interfaces";
import { Flex, Box } from "@bdl-cmn-shared-packages-npm/design-system";

export interface ContentBlockProps {
  fields: any;
}

const testStyle = css`
  background-color: #22AA5F;
  color: white;
  padding: 5px;
`;

const testStyleOnJSSElement = css`
  color: #22AA5F;
`;

//TODO: to delete, component created to show that emotion is working
export const ContentBlock: React.FunctionComponent<ContentBlockProps> = ({
  fields
}: ContentBlockProps) => (
  <Box margin={20}>
    <Flex flexDirection="column">
      <Text
        tag="h2"
        css={testStyleOnJSSElement}
        field={fields && fields.heading as SitecoreGenericTextField}
      />
      <RichText
        css={testStyle}
        field={fields && fields.content as SitecoreGenericTextField}
      />
    </Flex>
  </Box>
);
