
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const ISR: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#005EB8" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <rect y="150.071" fill="#FFFFFF" width="512" height="211.86" />
            <path fill="#005EB8" d="M301.869,256.001l22.933-39.721h-45.865L256,176.553l-22.936,39.727h-45.865l22.933,39.721 l-22.933,39.721h45.865L256,335.449l22.936-39.727h45.865L301.869,256.001z M302.302,229.271l-7.933,13.74l-7.933-13.74H302.302z M286.869,256.001l-15.433,26.73h-30.873l-15.432-26.73l15.433-26.73h30.873L286.869,256.001z M256,202.535l7.936,13.746h-15.872 L256,202.535z M209.698,229.271h15.865l-7.933,13.74L209.698,229.271z M209.698,282.731l7.933-13.74l7.933,13.74H209.698z M256,309.467l-7.936-13.746h15.872L256,309.467z M294.369,268.991l7.933,13.74h-15.865L294.369,268.991z" />
        </svg>
    </React.Fragment>
);
