import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { style } from "./securityRelated.style";
import { Security } from "../../../../interfaces";
import { SecuritySummary } from "../security-summary";

export interface Row {
  label: string;
  action: () => void;
}
export interface SecurityRelatedProps {
  relatedSecurities?: Security[];
}

const SecurityRelated: React.FunctionComponent<SecurityRelatedProps> = ({
  relatedSecurities,
}: SecurityRelatedProps) => {
  return (
    relatedSecurities?.length > 0 ?(
      <>
        <div css={style.securityRelated}>
          <div css={style.securityRelatedTop}>
            <div css={style.flexRow}>
              <div css={style.blocTitle}>Related securities</div>
              <div css={style.securityRelatedContainer}>
                { relatedSecurities.map((relatedSecurity: any, index: number) => (
                  <SecuritySummary security={relatedSecurity} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    ) : null
    
  );
};

export default SecurityRelated;