import * as React from "react";

import { css, jsx } from "@emotion/react";
import { fetchNewData, renderLink, transformLink } from "../../../utils/helper";
import { useEffect, useState } from "react";

import { BigMenuComponent } from "../../../sitecore-components/BigMenuComponent";
import Dropdown from "../Dropdown/dropdown.component";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { MobileMenuContext } from "../../app";
import SearchOverlay from "../SearchOverlay/searchOverlay";
import bourseLogo from "./BOURSE_LOGO_EN.png";
import { canUseDOM } from "../../../predicates";
import hexaGreen from "./hexaGreen.png";
import hexaRed from "./hexaRed.png";
import { style } from "./header.style";
import { withRouter } from "react-router-dom";
import { Link } from "@sitecore-jss/sitecore-jss-react";

/** @jsx jsx */

export const HeaderEmbed: React.FunctionComponent<any> = ({
  isGreen,
  iconType,
  topLabel,
  bottomLabel,
}): any => {
  const icon = css`
    background-image: url(${renderLink(isGreen ? hexaGreen : hexaRed)});
    background-repeat: no-repeat;
    background-size: cover;
    height: 53px;
    width: 48px;
    color: ${isGreen ? "#008C3D" : "#CA1234"};
    display: flex;
    justify-content: center;
    font-size: 18px;
    align-items: center;
  `;
  const headerContainer = css`
    padding: 8px;
    display: flex;
    max-width: 360px;
    align-items: flex-start;
    border-radius: 8px;
    // &:hover {
    //   background: #f6f8f9;
    //   transition: all 0.1s ease-in-out;
    // }
  `;
  const side = css`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  `;
  const sideRight = css`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-left: 16px;
  `;
  const top = css`
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: #354450;
  `;
  const bottom = css`
    font-size: 16px;
    color: #425563;
    margin-top: 4px;
    font-weight: lighter;
    width: 70%;
  `;
  const separator = css`
    border: 1px solid #e3e6e8;
    flex: none;
    order: 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 12px;
    margin-top: 8px;
  `;

  return (
    <React.Fragment>
      <div css={headerContainer}>
        <div css={side}>
          <div css={icon}>
            <Icon icon={iconType} />
          </div>
        </div>
        <div css={sideRight}>
          <div css={top}>{topLabel}</div>
          <div css={bottom}>{bottomLabel}</div>
        </div>
      </div>
      <div css={separator} />
    </React.Fragment>
  );
};

export interface OfferingMenu {
  background: "green" | "gray" | "red" | "white";
  color: string;
  icon: string;
  title: string;
  subTitle: string;
  showLGXad: boolean;
  link: string;
  menu: Array<{
    title: string;
    link: string;
  }>;
}

export const offeringMenu: Array<OfferingMenu> = [
  {
    background: "gray",
    color: "#425563",
    icon: "file",
    title: "Stops on bearer securities",
    subTitle: "",
    showLGXad: true,
    link: "/#",
    menu: [
      {
        title: "Stops on bearer securities",
        link: "/stops-on-bearer-securities",
      },
      {
        title: "How to place a stop ",
        link: "/stops-on-bearer-securities/place-a-stop",
      },
      {
        title: "No stop and no forfeiture confirmation",
        link: "/stops-on-bearer-securities/no-stop-and-non-forfeiture",
      },
    ],
  },
];

export const HeaderContent = ({
  routerRouteHistory,
  onSearchInputUpdate,
  routeTextInput = "",
  history,
  rendering,
  isReadOnly,
  hideBreadcrumb,
  isReadOnlyBreadCrumb,
}: any) => {
  const [isSearch, setIsSearch] = useState(false);
  const [visible, setVisible] = useState("");
  const [menuData, setMenuData] = useState<any>([]);
  const [textInput, setTextInput] = useState(routeTextInput);
  const [activeSection, setActiveSection] = useState("*");

  React.useEffect(() => {
    setTextInput(routeTextInput);
  }, [routeTextInput]);

  useEffect(() => {
    setActiveSection(canUseDOM && window.location.pathname);
    return () => void 0;
  }, []);

  const getDataFromItemId = async (id: string) => {
    try {
      const data = await fetchNewData(id);
      const { root } = data;
      setMenuData(root);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    getDataFromItemId("951D2AF5-4E80-4C86-A12F-ECFDD70503F2");
  }, []);

  const isActive = (links: any[]) => {
    if (canUseDOM) {
      if (window.location.pathname.length > 2) {
        return links.find((link: any) =>
          link?.pageLink?.href
            ?.replace("/en/", "/")
            ?.includes(canUseDOM && window.location.pathname)
        );
      }
    } else {
      return false;
    }
  };

  // When not in the search page, the search handler consists in going to the search url
  const searchHandlerWhenNotOnSearchPage = React.useCallback((): void => {
    if (textInput.length > 0 && canUseDOM) {
      window.location.href = `/search?q=${textInput}`;
    }
  }, [routerRouteHistory, textInput]);

  // In case we are already on the search page, we use the onSearchInputUpdate provided by the Search
  // component that will trigger a search taking into account the text input but also the selected
  // filters, sorts, etc
  const searchHandler = () => {
    return onSearchInputUpdate
      ? onSearchInputUpdate(textInput)
      : searchHandlerWhenNotOnSearchPage();
  };

  /*
  const getLinks = (menu: any, label: string, link: string) => {
    const items: any[] = [];
    new Array(30).fill(0).forEach((item, index) => {
      if (menu[`item ${index + 1} label`] && menu[`item ${index + 1} link`]) {
        items.push({
          [label]: menu[`item ${index + 1} label`],
          [link]: menu[`item ${index + 1} link`],
          action: () => history.push(menu[`item ${index + 1} link`]),
        });
      }
    });
    return items;
  };
  */

  const mobileMenuContext = React.useContext(MobileMenuContext);

  return (
    <div css={style.headerRow}>
      <div css={style.header}>
        {isSearch && (
          <SearchOverlay
            onClose={() => setIsSearch(false)}
            onSearch={searchHandler}
            textInput={textInput}
            onTextInputChange={setTextInput}
          />
        )}
        <a css={style.headerLeft} href="/">
          <img src={renderLink(bourseLogo)} alt="logo bourse" />
        </a>
        <div
          css={style.headerRightMobile}
          onClick={mobileMenuContext.actionDispatch}
        >
          <Icon icon={"bars"} /> Menu
        </div>
        <div css={style.headerCenter}>
          {menuData &&
            menuData.items &&
            !!menuData.items.length &&
            menuData.items.map((menu: any, index: number) => {
              if (menu && menu.rows && menu.rows.length > 0) {
                return (
                  <div css={style.headerCenterMenu} key={`map-${index}`}>
                    <Dropdown label={menu.mainLabel} nude bold={false}>
                      <BigMenuComponent
                        onClickOutside={() => setVisible("hide")}
                        fields={menu.rows}
                        rendering={rendering}
                      />
                    </Dropdown>
                  </div>
                );
              } else if (menu && menu.links && menu.links.length > 0) {
                return (
                  <div css={style.headerCenterMenu} key={`map-2-${index}`}>
                    <Dropdown
                      header={
                        <HeaderEmbed
                          isGreen={
                            menu.cardTitle === "Luxembourg Green Exchange"
                          }
                          iconType={menu.hexagonIcon}
                          topLabel={menu.mainLabel}
                          bottomLabel={menu.cardSubTitle}
                          link={{ value: menu.directPageLink }}
                        />
                      }
                      bold={isActive(menu.links)}
                      label={menu.mainLabel}
                      rows={menu.links}
                    />
                  </div>
                );
              } else if (menu.mainLabel && menu.directPageLink) {
                return (
                  <React.Fragment key={`map-3-${index}`}>
                    <Link
                      target={"_self"}
                      title={menu.mainLabel}
                      field={transformLink(menu.directPageLink)}
                      css={style.headerCenterMenu}
                    >
                      {menu.mainLabel}
                    </Link>
                  </React.Fragment>
                );
              }
            })}
        </div>
        <div css={style.headerRight}>
          <div onClick={() => setIsSearch(true)}>
            <Icon icon={"search"} />
          </div>
          <div onClick={() => (window.location.href = "https://my.luxse.com/")}>
            <Icon icon={"user"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(HeaderContent);
