
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const ABW: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#418FDE" d="M503.172,423.724H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.792C512,419.772,508.047,423.724,503.172,423.724z" />
            <g>
                <rect y="317.79" fill="#FBE122" width="512" height="17.655" />
                <rect y="353.1" fill="#FBE122" width="512" height="17.655" />
            </g>
            <polygon fill="#C8102E" points="65.232,175.942 32.384,164.725 65.232,153.509 76.449,120.665 87.665,153.509 120.513,164.725 87.665,175.942 76.449,208.787 " />
            <path fill="#FFFFFF" d="M76.449,126.567l9.714,28.445l28.445,9.714l-28.445,9.714l-9.714,28.445l-9.714-28.445l-28.445-9.714 l28.445-9.714L76.449,126.567 M76.449,114.759l-3.612,10.574l-9.108,26.673l-26.672,9.108l-10.574,3.612l10.574,3.61l26.672,9.108 l9.108,26.672l3.612,10.574l3.612-10.574l9.108-26.672l26.672-9.108l10.574-3.61l-10.574-3.61l-26.672-9.108l-9.108-26.672 L76.449,114.759L76.449,114.759z" />
        </svg>
    </React.Fragment>
);
