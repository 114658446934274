import { breakpoint, breakpointMax } from "../../style";

import { css } from "@emotion/react";
import stock from "./iStock-6173573461.png";

export const style = {
  container: css`
    width: 100%;
    min-height: 500px;
    .slick-dots {
      position: absolute;
      bottom: 215px;
      left: 0;
      padding-left: 20px;
      display: flex !important;
      @media (max-width: ${breakpointMax}) {
        position: absolute;
        bottom: 215px;
        left: 0;
        padding-left: 20px;
        display: flex !important;
      }
    }
    .slick-dots li.slick-active button:before {
      opacity: 0.75;
      color: black;
      background: #22aa5f;
      height: 3px;
      width: 48px;
    }
    .slick-dots li button:before {
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      height: 3px;
      width: 48px;
    }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 50px;
      height: 3px;
      padding: 0 !important;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
    }
    .slick-dots li {
      position: relative;
      display: inline-block;
      width: 42px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
    }
    .slick-dots li button:before {
      opacity: 1;
    }
    .slick-dots li button:before {
      font-size: 0;
    }
  `,
  right: css`
    width: 950px;
    padding: 64px;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      padding: 0;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 125px;
    }
  `,
  headTitle: css`
    line-height: 23px;
    font-size: 18px;
    font-weight: 400;
    color: #f6f9f9;
    @media (max-width: ${breakpointMax}) {
      font-size: 18px;
      font-weight: 400;
      line-height: 15px;
    }
  `,
  title: css`
    line-height: 46px;
    font-size: 36px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 16px;
    @media (max-width: ${breakpointMax}) {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
    }
  `,
  // description: css`
  //   line-height: 23px;
  //   font-size: 18px;
  //   font-weight: 600;
  //   color: ${backgroundFilter ? "#425563" : "#ffffff"};
  //   display: flex;
  //   flex-wrap: wrap;
  //   margin-top: 12px;
  //   @media (max-width: ${breakpointMax}) {
  //     margin-top: 14px !important;
  //     font-size: 14px;
  //     font-weight: 400;
  //     line-height: 180%;
  //   }
  // `,
  image: css`
    position: absolute;
    width: 100px;
    margin-left: -200px;
    img {
      position: absolute;
    }
  `,
  img: css`
    position: relative;
    margin-top: 67px;
    z-index: 2;
    max-width: 100px;
    margin-left: 35px;
  `,
  // sign: css`
  //   line-height: 23px;
  //   font-size: 18px;
  //   font-weight: 800;
  //   color: #ffffff;
  //   margin-top: 16px;
  //   margin-bottom: 16px;
  //   @media (max-width: ${breakpointMax}) {
  //     font-size: 16px;
  //     font-weight: 600;
  //     line-height: 20px;
  //   }
  // `,
  hexa: css`
    position: absolute;
    overflow: hidden;
    height: 125px;
    right: 10%;
    bottom: 0%;
    img {
      width: 255px;
    }
    @media (max-width: ${breakpointMax}) {
    }
  `,
  action: css`
    margin-top: 24px;
  `,
};
