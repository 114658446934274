import gql from "graphql-tag";

export const GET_LUXXPRIME_PP_REPORTS =  gql`
    query GetLuxXPrimePLPPerformance($limit: Int) {
        luxXPrimePLPPerformance(limit: $limit) {
            id
            name
            publishDate
            referenceDate
            documentTypeCode
            documentTypeTranslatableField {
                field 
                key  
                value
                __typename
            }
            downloadUrl 
            categories 
            language
            __typename 
        }
    }
`;