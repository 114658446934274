import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Table } from '@bdl-cmn-shared-packages-npm/design-system';
import { sharedStyle } from '../../../../../../styles/shared';
import { MarketdataModel } from '../../market-data-view.component';
import { LuxXPrimeRow } from './luxx-prime-row.component';
import LuxXPrimeHeader from './luxx-prime-header.component';

export const LuxXPrimeView = (props: any) => {
  const luxXPrime = props.luxXPrimeData;

  return (
    <Table>
      <LuxXPrimeHeader />
      <tbody>
        {luxXPrime?.totalHits > 0
          ? luxXPrime?.currentData.map((luxXPrimeRow: MarketdataModel) => (
              <LuxXPrimeRow luxXPrimeRowData={luxXPrimeRow} />
            ))
          : 'no data'}
      </tbody>
    </Table>
  );
};
export default LuxXPrimeView;
