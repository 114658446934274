import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import Props from "../UI/list/base/props";
import {TrGroup, Tr, Td, Button, Icon} from "@bdl-cmn-shared-packages-npm/design-system";
import {formatDate} from "../../../utils/date";
import {cssfApprovalsStyle} from "./cssf-approvals-view.style";
import {useState, Fragment} from "react";
import {documentTypeLabel} from "../../../enums/document-type";
import {DOWNLOAD_ENDPOINT} from "../../../backbourse";
import { documentSubTypeLabel } from "../../../enums/document-sub-type";
import { CountryFlagIcon } from "../CountryFlagIcon/countryFlagIcon.component";

export const RenderChevron = ({toggle}: any) => {
    if (!toggle) {
        return <Icon icon={'chevron-down'}/>
    } else {
        return <Icon icon={'chevron-up'}/>
    }
}

export const CssfApprovalsRow = ({row}: Props<any>) => {
    const [toggleChevron, setToggleChevron] = useState<boolean>(false);
    // const downloadPath = 'https://dl.bourse.lu/dl?v=';
    const downloadPath = DOWNLOAD_ENDPOINT;
    return (
        <TrGroup>
            <Tr trigger onRowClick={() => setToggleChevron(!toggleChevron)}>
                <Td>
                    { row?.categories && row?.categories[0] ?
                        <div css={cssfApprovalsStyle.status}>
                            <div css={cssfApprovalsStyle.tag}>{documentTypeLabel(row.categories[0])}</div>
                        </div>
                        : <></>
                    }
                </Td>
                <Td>
                    <div css={cssfApprovalsStyle.title1}>
                        {documentSubTypeLabel(row?.documentTypeCode)}
                        {row?.description ? ` - ${row.description}` : ''}
                    </div>
                    <div css={cssfApprovalsStyle.title2}>
                        {` ${formatDate(row?.publishDate, "DD/MM/YYYY")}`}
                        {row?.complement ? ` - ${row.complement}` : ''}
                    </div>
                </Td>
                <Td>
                    <div css={cssfApprovalsStyle.listCountry}>
                        { row?.hostMemberStates?.map((x: any) => 
                            <CountryFlagIcon code={x} size={{ height: 20, width: 30 }} />
                        )}
                    </div>
                </Td>
                <Td>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            (window.location.href = downloadPath + row?.downloadUrl)
                        }}
                        iconRight='download'
                        label={"Download"}/>
                </Td>
                <Td>
                    {row?.incorporedByReferenceDocuments.length > 0 ? (
                        <div css={cssfApprovalsStyle.chevron}>
                            <RenderChevron toggle={toggleChevron}/>
                        </div>
                    ) : (<></>)

                    }
                </Td>

            </Tr>
            {
                row?.incorporedByReferenceDocuments !== undefined ? (
                    row?.incorporedByReferenceDocuments.map((x: any, index:number) => {
                        return <Fragment>
                            {index == 0 ? (
                                <Tr >
                                    <Td colSpan={5} css={cssfApprovalsStyle.docIncorporatedContainer2}>
                                        <p css={cssfApprovalsStyle.docIncorporatedText}>{row?.incorporedByReferenceDocuments?.length} document(s) incorporated by reference</p>
                                    </Td>
                                </Tr>
                                ):(<></>)
                            }

                            <Tr css={cssfApprovalsStyle.underList}>
                                <Td css={cssfApprovalsStyle.underList}>
                                    <div css={cssfApprovalsStyle.status}>
                                        <div css={cssfApprovalsStyle.tag}>Doc. Inc. Ref.</div>
                                    </div>
                                </Td>
                                <Td colSpan={2} css={cssfApprovalsStyle.underList}>
                                    <p css={cssfApprovalsStyle.title1}>{x?.description} </p>
                                    <p css={cssfApprovalsStyle.title2}>{formatDate(x?.referenceDate, "DD/MM/YYYY")} - {x?.complement} </p>
                                </Td>
                                <Td colSpan={2} css={cssfApprovalsStyle.underList}>
                                    <Button css={cssfApprovalsStyle.buttonUnderList}
                                            onClick={() => (window.location.href = downloadPath + x?.downloadUrl)}
                                            label={"Download"}
                                            iconRight='download'/>
                                </Td>
                            </Tr>
                        </Fragment>

                    })) : (<></>)
            }
        </TrGroup>

    );
};

export default CssfApprovalsRow;