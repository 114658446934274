
export enum LgxEligibleCategory {
	RENEWABLEENERGY = 'Renewable energy',
    POLLUTIONPREVENTION = 'Pollution prevention and control',
    SUSTAINABLEMANAGEMENT = 'Natural resources & land use',
    ECOEFFICIENTPRODUCTS = 'Eco-efficient products and processes',
    BIODIVERSITYCONSERVATION = 'Biodiversity conservation',
    ENERGYEFFICIENCY = 'Energy efficiency',
    CLIMATECHANGEADAPTATION = 'Climate change adaptation',
    SUSTAINABLEWATERMANAGEMENT = 'Water & wastewater',
    CLEANTRANSPORTATION = 'Clean transportation',
    GREENBUILDINGS = 'Green buildings',
    CLIMATEANDENERGY = 'Climate change & Renewable energy',
    WATER = 'Water',
    ENVIRONMENTALECOLOGICAL = 'Environmental & Ecological',
    FORESTRY = 'Forestry',
    CARBON = 'Carbon', 
    MICROFINANCE = 'Microfinance',
    SOCIALSOLIDARITY = 'Social entrepreneurship & solidarity',
    SOCIALIMPACT = 'Social impact',
    SOCIOECONOMICADV = 'Socio-economic advancement',
    CLIMATECHANGEMITIGATION = 'Climate change mitigation',
    USEFULANDAFFORDABLEINFRASTRUCTURE = 'Access to useful and affordable financial products and services',
    FINANCIALSECTORDEV = 'Financial sector development',
    ENERGYSAVING = 'Energy Saving',
    POLLUTIONPREVENTIONANDCONTROL = 'Pollution prevention and control',
    CLEANTRANSPORT = 'Clean Transportation',
    RESOURCECONSERVATIONANDRECYCLING = 'Resource Conservation and Recycling',
    CLEANENERGY = 'Clean Energy',
    ECOPROTECTIONANDCLIMATEADAPTATION = 'Ecological Protection and Climate Change Adaptation',
    CLIMATESMARTAGRICULTURE = 'Climate smart agriculture',
    ESSENTIALSERVICES = 'Access to essential services',
    AFFORDABLEHOUSING = 'Affordable housing',
    EMPLOYMENTGENERATION = 'Employment Generation',
    AFFORDABLEINFRASTRUCTURE = 'Affordable Infrastructure',
    FOODSECURITY = 'Food Security',

}

export function lgxEligibleCategoryLabel(value: string): string {
    if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(LgxEligibleCategory).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(LgxEligibleCategory)[index] : value;
	}catch (e){
		console.error("Failed to get LgxEligibleCategory label for '" + value + "'", e);
	}
		
	return value;
}

export function lgxEligibleCategorySort( first: string, second: string): number {
    if( first == null )
        return - 1;
    else if ( second == null )
        return 1;

    let firstLabel = lgxEligibleCategoryLabel(first);
    let secondLabel = lgxEligibleCategoryLabel(second);
    
    return firstLabel > secondLabel ? 1 : -1;
}