import * as React from 'react';

import { breakpoint, breakpointMax } from '../../../../style';

import { css } from '@emotion/react';

export const genericStyle = {
  desktopDisplay: css`
    width: 100%;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  mobileDisplay: css`
    width: 100%;
    @media (min-width: ${breakpoint}) {
      display: none;
    }
  `,
};
