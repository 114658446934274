/** @jsx jsx */

import * as React from 'react';

import { ComponentParams, ComponentRendering, RichText, Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from '../../interfaces';
import {renderLink} from "../../utils/helper";

export interface InternshipOffersProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
`;
const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
  }
`;
const left = css`
  width: 40%;
  padding-right: 40px;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const right = css`
  width: 60%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const title = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #354450;
  }
`;
const description = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
  margin-top: 12px;
  margin-bottom: 12px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  }
`;
const offerBloc = css`
  width: 60%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const jobTitle = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #425563;
  margin-top: 16px;
  margin-bottom: 16px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #425563;
  }
`;
const jobInfo = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  margin-top: 12px;
  margin-bottom: 12px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  }
`;

export const InternshipOffers: React.FunctionComponent<InternshipOffersProps> = (props: InternshipOffersProps): React.ReactElement => {
    const { fields } = props;

    const imageLeft: any = getFieldValue<any>(fields, 'image left', null);
    const image = css`
      background-image: url(${renderLink(imageLeft && imageLeft.src)});
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      max-width: 400px;
    `;

    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={left}>
                    <div css={image} />
                </div>
                <div css={right}>
                    <div css={title}>
                        <Text
                            field={fields && fields.title as SitecoreGenericTextField}
                        />
                    </div>
                    <div css={description}>
                        <RichText
                            field={fields && fields.description as SitecoreGenericTextField}
                        />
                    </div>
                    {
                        new Array(10).fill(0).map((arr: any, index: number) => {
                            if (fields && fields[`job ${index + 1} title`] && (fields && fields[`job ${index + 1} title`] as any).value) {
                                return (
                                    <div css={offerBloc} key={`offer-${index}`}>
                                        <div css={jobTitle}>
                                            { getFieldValue<string>(fields, `job ${index + 1} title`, '') }
                                        </div>
                                        <div css={jobInfo}>
                                            { getFieldValue<string>(fields, `job ${index + 1} info`, '') }
                                        </div>
                                        <div css={right}>
                                            <Button
                                                variant={"secondary"}
                                                wired={true}
                                                label={"Find out more"}
                                                iconRight={'arrow-right'}
                                                onClick={() => ( getFieldValue<any>(fields, `job ${index + 1} link`, null) ? (window.location.href = getFieldValue<any>(fields, `job ${index + 1} link`, null)) : console.log)}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <Text
                    field={fields && fields.heading as SitecoreGenericTextField}
                />
            </div>
        </div>
    );
}
