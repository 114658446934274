
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const GGY: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M503.172,423.724H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.792C512,419.772,508.047,423.724,503.172,423.724z" />
            <polygon fill="#E8112D" points="512,220.69 291.31,220.69 291.31,88.276 220.689,88.276 220.689,220.69 0,220.69 0,291.31 220.689,291.31 220.689,423.724 291.31,423.724 291.31,291.31 512,291.31 " />
            <g>
                <polygon fill="#F9DD16" points="361.93,282.483 361.93,229.517 348.689,242.759 163.31,242.759 150.069,229.517 150.069,282.483 163.31,269.241 348.689,269.241 " />
                <polygon fill="#F9DD16" points="229.517,361.931 282.482,361.931 269.241,348.689 269.241,163.311 282.482,150.069 229.517,150.069 242.758,163.311 242.758,348.689 " />
            </g>
        </svg>
    </React.Fragment>
);
