/** @jsx jsx */
/* eslint-disable */
import { Grid, Pagination, Table, Tbody, Th, Thead, ToggleButtonGroup, ToggleItem, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import React, { Component, useEffect, useState } from "react";
import { style } from "../market-statistics.style";
import Loader from "../../Loader/loader.component";
import GenericMarketCapDataRow from "../Base/GenericMarketCapDataRow";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import MarketCapCard from "../Cards/MarketCapCard";
import EmptyResults from "../../UI/list/base/empty";
import { MarketCapPaginationModel } from "../market-capitalisation-view.component";
import { css, jsx } from "@emotion/react";
import SortIcon from "../Base/SortIcon";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { GET_MARKET_CAPITALISATION_LUX_SHARES } from "../../../../graphql/queries/market.queries";

export function LuxSharesView(props: any) {

    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const limit = 10;


    const [luxShares, setLuxShares] = useState<MarketCapPaginationModel>({
        allData: [],
        displayData: [],
        totalHits: 0
    });

    let [totalIssuedShares,setTotalIssuedShares]=useState<Number>(0);
    let [totalMarketCap,setTotalMarketCap]=useState<Number>(0);

    const columns = [
        { label: "Type", accessor: "type," },
        { label: "ISIN", accessor: "isinCode," },
        { label: "Name", accessor: "description," },
        { label: "Currency", accessor: "currency," },
        { label: "Market", accessor: "market," },
        { label: "Price", accessor: "price," },
        { label: "Issued shares", accessor: "nbSecuritiesIssued," },
        { label: "Market cap", accessor: "marketCap," },
    ];
    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("asc");

    const client = luxseGatewayGraphqlClient;

    const handleSortingChange = (accessor:any) => {
        const sortOrder =
        accessor === sortField && order === "asc" ? "desc" : "asc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };

    const handleSorting = (sortField:any, sortOrder:any) => {
        if(sortField){
            const sortName=sortField.replace(',','');
            if (sortName === "price" || sortName === "nbSecuritiesIssued" || sortName === "marketCap") {
                luxShares.allData.sort((a:any, b:any) => {
                 return (
                  (Number(a[sortName])-Number(b[sortName])) * (sortOrder === "asc" ? 1 : -1)
                 );
                });
            }else{
                luxShares.allData.sort((a:any, b:any) => {
                    return (
                     a[sortName].toString().localeCompare(b[sortName].toString(), "en", {
                      numeric: true,
                     }) * (sortOrder === "asc" ? 1 : -1)
                    );
                   });
            }
            luxShares.displayData = createPage(0);
            setLuxShares({...luxShares});
        }
    };

    const fetchLuxSharesData = async () => {
        try {
          if( !dataLoading)
            setDataLoading(true);
            luxShares.allData = [];
            luxShares.displayData = [];
          const results: any = await client.query({
            query: GET_MARKET_CAPITALISATION_LUX_SHARES,
          });
    
          results?.data?.marketCapitalisationForLuxShares?.forEach((element: any) => {
            luxShares.allData.push(element);
            totalIssuedShares=totalIssuedShares+element.nbSecuritiesIssued;
            totalMarketCap=totalMarketCap+element.marketCap;
            });
            luxShares.totalHits = luxShares.allData.length;
            luxShares.displayData = createPage(0);
            setLuxShares(luxShares);
            setTotalIssuedShares(totalIssuedShares);
            setTotalMarketCap(totalMarketCap);
            setDataLoading(false);
        } catch (e) {
            console.error('Failed to get lux components data', e);
            setDataLoading(false);
        }
      }

    useEffect(() => {
        fetchLuxSharesData();
    }, []);

    const onPaginationUpdate = (pageNumber: number) => {
        luxShares.displayData = createPage(pageNumber);
        setLuxShares({...luxShares});
    };

    function createPage(currentPage: number): any {
        let startIndex = 0;
        let endIndex = 10;

        if (currentPage > 0) {
            endIndex = (currentPage + 1) * 10;
            startIndex = endIndex - 10;
        }

        return luxShares.allData.slice(startIndex, endIndex);
    }


    return (
        <>
            <div css={style.header}>
                <h1 css={style.rowLabel}>Lux Shares</h1>
            </div>
            <div css={style.container}>
                <Loader loading={dataLoading}>
                    {luxShares && luxShares?.totalHits > 0 && !dataLoading ?
                        <>
                            <div css={[style.desktopDisplay]}>
                                <table css={style.table}>
                                        <thead css={style.thead}>
                                            <tr css={style.tr}>
                                            { columns.map(({ label, accessor }) => {
                                                return accessor.replace(',','')=="type"?
                                                <th key={accessor}>
                                                    {label}
                                                </th> :
                                                <th key={accessor} onClick={() => handleSortingChange(accessor)} style={{ textAlign: label === "Price" || label === "Issued shares" || label === "Market cap" ? 'right' : 'left' }}>
                                                    {label}
                                                    <SortIcon/>
                                                </th>;
                                            })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <GenericMarketCapDataRow rowsData={luxShares.displayData} columns={columns}/>
                                        </tbody>
                                        <tfoot>
                                            <tr css={[style.tableRow,css`background-color:#F0F5F5;`]}>
                                                <td>Total</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <p css={css`text-align:right;`}>{Number(totalIssuedShares).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, ' ')}</p>
                                                </td>
                                                <td>
                                                    <p css={css`font-weight:bold; text-align:right;`}>{Number(totalMarketCap).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, ' ')}</p>
                                                </td>
                                            </tr>
                                        </tfoot>
                                </table>
                            </div>
                            <div css={style.mobileDisplay}>
                                <SearchMobile
                                    onPaginationUpdate={(d: any) => {
                                        onPaginationUpdate(d.selected);
                                    }}
                                    pageNumber={0}
                                    data={luxShares?.displayData}
                                    totalResultSize={10}
                                    Card={(card) => <MarketCapCard card={card} />}
                                />
                            </div>
                            <div css={[style.pagination]}>
                                <Pagination
                                    initialPage={0}
                                    onPageChange={(d: any) => {
                                        onPaginationUpdate(d.selected);
                                    }}
                                        total={luxShares?.totalHits / limit > 200 ? 200 : Math.ceil(luxShares?.totalHits / limit)}
                                />
                            </div>
                        </>
                        :
                        <div>
                            <EmptyResults />
                        </div>
                    }
                </Loader>
            </div>
        </>
    );
}

export default LuxSharesView;