import { DataType, PageNumberOfItem } from "../../enums";

export const baseDateFormat: string = "DD/MM/YYYY";
export const baseTimeFormat: string = "HH:mm:ss";
export const defaultDataType: keyof typeof DataType = DataType.securities;
export const defaultPageNumberOfItem: keyof typeof PageNumberOfItem = "ONE";
export const defaultPageNumber: number = 0;
export const EMPTY_DATA_PLACEHOLDER: string = '-';
export const NUMBER_OF_ROW_IN_PAGE: number = 20;
export const WEBSITE_DOMAIN: string = "https://www.luxse.com";
export const WEBSITE_NAME: string = "LuxSE";
export const WEBSITE_TITLE: string = "Luxembourg Stock Exchange";
export const DOCUMENT_PREVIEW_LINK: string = "/pdf-viewer/";
export const PREVIEW_MODE = "&mode=preview";
export const DOWNLOAD_MODE = "&mode=download";
