import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { useRef, useState, useEffect } from "react";

import { SitecoreGenericTextField } from "../../interfaces";
import Tag from "../../core/components/Tag/tag.component";
import { canUseDOM } from "../../predicates";
import { genericStyle } from "../../core/components/UI/generic/generic.style";
import hexa from "./hexa.svg";
import {fetchData, renderLink} from "../../utils/helper";

/** @jsx jsx */

export interface MeetOurExpertsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  display: flex;
  position: relative;
  @media (max-width: ${breakpointMax}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const container = css`
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 40px;
  background-color: #f6f9f9;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (max-width: ${breakpointMax}) {
    padding: 16px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;
const top = css`
  display: flex;
  justify-content: space-between;
`;
const icon = css`
  color: #22aa5f;
  font-size: 36px;
  margin-top: -6px;
  line-height: 36px;
  @media (max-width: ${breakpointMax}) {
    font-size: 20px;
  }
`;
const title = css`
  color: #354450;
  font-size: 20px;
  font-weight: 800;
  @media (max-width: ${breakpointMax}) {
    color: #354450;
    font-size: 16px;
    font-weight: 700;
  }
`;
const mainTitle = css`
  color: #354450;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 32px;
`;
const blocContent = css`
  color: #354450;
  font-size: 20px;
  font-weight: 600;
  height: auto;
  width: 100%;
  transition: all 0.2s ease-in-out;
  padding-top: 24px;
  padding-bottom: 24px;
`;
const leftSide = css`
  width: 20%;
  display: flex;
  padding-right: 48px;
  justify-content: center;
`;

const leftSideImage = css`
  width: 100%;
  display: flex;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
const rightSide = css`
  width: 80%;
  display: flex;
  flex-direction: column;
`;
const text = css`
  color: #354450;
  font-size: 20px;
  font-weight: 600;
  height: auto;
  display: flex;
  width: 100%;
  flex-direction: row;
  transition: all 0.2s ease-in-out;
`;
const defaultblocStyle = {
  background: "#ffffff",
  padding: 42,
  border: "solid 1px #d9e1e2",
  borderRadius: 8,
  marginBottom: 40,
  transition: "all 0.2s ease-in-out",
  cursor: "pointer",
  width: "100%",
};
const rightSideTitle = css`
  font-weight: 800;
  font-size: 32px;
  line-height: 41px;
  color: #cb1234;
  margin-bottom: 8px;
`;
const rightSideSubTitle = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #425563;
  margin-bottom: 16px;
`;
const separator = css`
  border: 1px solid #d9e1e2;
  height: 1px;
  width: 100%;
  margin-top: 48px;
`;
const hexaGb = css`
  background: url(${renderLink(hexa)});
  background-repeat: no-repeat;
  background-size: cover;
  width: 600px;
  position: absolute;
  z-index: -1;
  right: 75%;
  bottom: 0;
  display: flex;
  height: 300px;
`;
const rightSideDescription = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
`;
const rightSideTags = css`
  margin-top: 8px;
  margin-bottom: 16px;
  > div {
    margin-right: 8px;
  }
`;
const rightSideActions = css`
  display: flex;
  margin-top: 16px;
  > button {
    margin-right: 16px;
  }
`;

export const MeetOurExperts: React.FunctionComponent<MeetOurExpertsProps> = (
  props: MeetOurExpertsProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const [expend, setExpend] = useState(-1);
  const tab = useRef(null);

  const getWrapperStyle = (index: number) => {
    return index === expend ? { marginTop: 24 } : { height: 0 };
  };
  const getBlocStyle = (index: number) => {
    return index === expend
      ? {
          ...defaultblocStyle,
        }
      : {
          ...defaultblocStyle,
          filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2))",
        };
  };
  return (
    <div css={parentContainer}>
      <div css={hexaGb} />
      <div css={container}>
        {fields && fields.title && (fields.title as any).value && (
          <div css={mainTitle}>
            <Text
              field={fields && (fields.title as SitecoreGenericTextField)}
            />
          </div>
        )}
        {new Array(10).fill(0).map((arr: any, index: number) => {
          if (
            fields &&
            fields[`bloc ${index + 1} title`] &&
            (fields && (fields[`bloc ${index + 1} title`] as any)).value
          ) {
            return (
              <>
                <div
                  style={getBlocStyle(index)}
                  css={css`
                    @media (max-width: ${breakpointMax}) {
                      padding: 20px !important;
                      margin-bottom: 16px!important;
                    }
                  `}
                >
                  <div
                    css={top}
                    onClick={() => {
                      setExpend(index === expend ? -1 : index);
                    }}
                  >
                    <div css={title}>
                      <Text
                        field={
                          fields[
                            `bloc ${index + 1} title`
                          ] as SitecoreGenericTextField
                        }
                      />
                    </div>
                    <div css={icon}>
                      <Icon
                        icon={expend === index ? "chevron-up" : "chevron-down"}
                      />
                    </div>
                  </div>
                  <div>
                    {new Array(10).fill(0).map((arr: any, idx: number) => {
                      if (
                        fields &&
                        fields[`bloc ${index + 1} expert ${idx + 1} name`] &&
                        (
                          fields &&
                          (fields[
                            `bloc ${index + 1} expert ${idx + 1} name`
                          ] as any)
                        ).value &&
                        expend === index
                      ) {
                        return (
                          <>
                            <div css={genericStyle.desktopDisplay}>
                              <div
                                css={blocContent}
                                style={getWrapperStyle(index)}
                              >
                                <div
                                  css={text}
                                  ref={tab}
                                  style={{
                                    opacity: expend === index ? "1" : "0",
                                  }}
                                >
                                  <div css={leftSide}>
                                    {fields &&
                                      fields[
                                        `bloc ${index + 1} expert ${
                                          idx + 1
                                        } image`
                                      ] &&
                                      (
                                        fields &&
                                        (fields[
                                          `bloc ${index + 1} expert ${
                                            idx + 1
                                          } image`
                                        ] as any)
                                      ).value &&
                                      (
                                        fields &&
                                        (fields[
                                          `bloc ${index + 1} expert ${
                                            idx + 1
                                          } image`
                                        ] as any)
                                      ).value.src && (
                                        <div
                                          css={leftSideImage}
                                          style={{
                                            background: `url(${renderLink(
                                              (
                                                fields &&
                                                (fields[
                                                  `bloc ${index + 1} expert ${
                                                    idx + 1
                                                  } image`
                                                ] as any)
                                              ).value.src
                                            )})`,
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                          }}
                                        />
                                      )}
                                  </div>
                                  <div css={rightSide}>
                                    <div css={rightSideTitle}>
                                      <Text
                                        field={
                                          fields[
                                            `bloc ${index + 1} expert ${
                                              idx + 1
                                            } name`
                                          ] as SitecoreGenericTextField
                                        }
                                      />
                                    </div>
                                    <div css={rightSideSubTitle}>
                                      <Text
                                        field={
                                          fields[
                                            `bloc ${index + 1} expert ${
                                              idx + 1
                                            } status`
                                          ] as SitecoreGenericTextField
                                        }
                                      />
                                    </div>
                                    <div css={rightSideDescription}>
                                      <Text
                                        field={
                                          fields[
                                            `bloc ${index + 1} expert ${
                                              idx + 1
                                            } description`
                                          ] as SitecoreGenericTextField
                                        }
                                      />
                                    </div>
                                    <div css={rightSideTags}>
                                      {new Array(3)
                                        .fill(0)
                                        .map((arr: any, tagIndex: number) => {
                                          if (
                                            fields &&
                                            fields[
                                              `bloc ${index + 1} expert ${
                                                idx + 1
                                              } tag ${tagIndex + 1}`
                                            ] &&
                                            (
                                              fields &&
                                              (fields[
                                                `bloc ${index + 1} expert ${
                                                  idx + 1
                                                } tag ${tagIndex + 1}`
                                              ] as any)
                                            ).value &&
                                            expend === index
                                          ) {
                                            return (
                                              <Tag
                                                label={
                                                  (
                                                    fields &&
                                                    (fields[
                                                      `bloc ${
                                                        index + 1
                                                      } expert ${idx + 1} tag ${
                                                        tagIndex + 1
                                                      }`
                                                    ] as any)
                                                  ).value
                                                }
                                              />
                                            );
                                          }
                                        })}
                                    </div>
                                    <div css={rightSideActions}>
                                      <Button
                                        label={"Contact"}
                                        iconLeft={"paper-plane"}
                                        size={"medium"}
                                        onClick={() =>
                                          (
                                            fields &&
                                            (fields[
                                              `bloc ${index + 1} expert ${
                                                idx + 1
                                              } email`
                                            ] as any)
                                          ).value
                                            ? canUseDOM &&
                                              window.open(
                                                `mailto:${
                                                  (
                                                    fields &&
                                                    (fields[
                                                      `bloc ${
                                                        index + 1
                                                      } expert ${idx + 1} email`
                                                    ] as any)
                                                  ).value
                                                }`
                                              )
                                            : console.log("no email found")
                                        }
                                      />
                                      <Button
                                        label={
                                          (
                                            fields &&
                                            (fields[
                                              `bloc ${index + 1} expert ${
                                                idx + 1
                                              } phone`
                                            ] as any)
                                          ).value
                                        }
                                        iconLeft={"phone"}
                                        wired={true}
                                        size={"medium"}
                                        onClick={() =>
                                          canUseDOM &&
                                          window.open(
                                            `tel:${
                                              (
                                                fields &&
                                                (fields[
                                                  `bloc ${index + 1} expert ${
                                                    idx + 1
                                                  } phone`
                                                ] as any)
                                              ).value
                                            }`
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div css={separator} />
                              </div>
                            </div>
                            <div css={[genericStyle.mobileDisplay]}>
                              <div
                                css={css`
                                  display: flex;
                                  align-items: center;
                                  flex-direction: column;
                                `}
                              >
                                {fields &&
                                  fields[
                                    `bloc ${index + 1} expert ${idx + 1} image`
                                  ] &&
                                  (
                                    fields &&
                                    (fields[
                                      `bloc ${index + 1} expert ${
                                        idx + 1
                                      } image`
                                    ] as any)
                                  ).value &&
                                  (
                                    fields &&
                                    (fields[
                                      `bloc ${index + 1} expert ${
                                        idx + 1
                                      } image`
                                    ] as any)
                                  ).value.src && (
                                    <div
                                      style={{
                                        background: `url(${renderLink(
                                          (
                                            fields &&
                                            (fields[
                                              `bloc ${index + 1} expert ${
                                                idx + 1
                                              } image`
                                            ] as any)
                                          ).value.src
                                        )})`,
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        width: "250px",
                                        height: "250px",
                                        backgroundPositionX: "50%",
                                      }}
                                    />
                                  )}
                                <div
                                  css={css`
                                    font-weight: 800;
                                    font-size: 32px;
                                    line-height: 41px;
                                    color: #cb1234;
                                    margin-top: 47px;
                                    text-align: center;
                                  `}
                                >
                                  <Text
                                    field={
                                      fields[
                                        `bloc ${index + 1} expert ${
                                          idx + 1
                                        } name`
                                      ] as SitecoreGenericTextField
                                    }
                                  />
                                </div>
                                <div
                                  css={css`
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 18px;
                                    letter-spacing: 0.1em;
                                    color: #a4bcc2;
                                    margin-top: 8px;
                                    text-align: center;
                                  `}
                                >
                                  <Text
                                    field={
                                      fields[
                                        `bloc ${index + 1} expert ${
                                          idx + 1
                                        } status`
                                      ] as SitecoreGenericTextField
                                    }
                                  />
                                </div>
                                <div
                                  css={css`
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 150%;
                                    letter-spacing: 0.01em;
                                    color: #425563;
                                    margin-top: 17px;
                                  `}
                                >
                                  <Text
                                    field={
                                      fields[
                                        `bloc ${index + 1} expert ${
                                          idx + 1
                                        } description`
                                      ] as SitecoreGenericTextField
                                    }
                                  />
                                </div>
                                <div
                                  css={css`
                                    display: flex;
                                    flex-direction: row;
                                    align-items: flex-start;
                                    padding: 0px;
                                    gap: 8px;
                                    margin-top: 16px;
                                    flex-wrap: wrap;
                                  `}
                                >
                                  {new Array(3)
                                    .fill(0)
                                    .map((arr: any, tagIndex: number) => {
                                      if (
                                        fields &&
                                        fields[
                                          `bloc ${index + 1} expert ${
                                            idx + 1
                                          } tag ${tagIndex + 1}`
                                        ] &&
                                        (
                                          fields &&
                                          (fields[
                                            `bloc ${index + 1} expert ${
                                              idx + 1
                                            } tag ${tagIndex + 1}`
                                          ] as any)
                                        ).value &&
                                        expend === index
                                      ) {
                                        return (
                                          <Tag
                                            label={
                                              (
                                                fields &&
                                                (fields[
                                                  `bloc ${index + 1} expert ${
                                                    idx + 1
                                                  } tag ${tagIndex + 1}`
                                                ] as any)
                                              ).value
                                            }
                                          />
                                        );
                                      }
                                    })}
                                </div>
                                <div
                                  css={css`
                                    margin-top: 32px;
                                    width: 100%;
                                    > button {
                                      width: 100%;
                                    }
                                  `}
                                >
                                  <Button
                                    label={"Contact"}
                                    iconLeft={"paper-plane"}
                                    size={"medium"}
                                    onClick={() =>
                                      (
                                        fields &&
                                        (fields[
                                          `bloc ${index + 1} expert ${
                                            idx + 1
                                          } email`
                                        ] as any)
                                      ).value
                                        ? canUseDOM &&
                                          window.open(
                                            `mailto:${
                                              (
                                                fields &&
                                                (fields[
                                                  `bloc ${index + 1} expert ${
                                                    idx + 1
                                                  } email`
                                                ] as any)
                                              ).value
                                            }`
                                          )
                                        : console.log("no email found")
                                    }
                                  />
                                </div>

                                <div
                                  css={css`
                                    margin-top: 16px;
                                    width: 100%;
                                    > button {
                                      width: 100%;
                                    }
                                  `}
                                >
                                  <Button
                                    label={
                                      (
                                        fields &&
                                        (fields[
                                          `bloc ${index + 1} expert ${
                                            idx + 1
                                          } phone`
                                        ] as any)
                                      ).value
                                    }
                                    iconLeft={"phone"}
                                    wired={true}
                                    size={"medium"}
                                    onClick={() =>
                                      canUseDOM &&
                                      window.open(
                                        `tel:${
                                          (
                                            fields &&
                                            (fields[
                                              `bloc ${index + 1} expert ${
                                                idx + 1
                                              } phone`
                                            ] as any)
                                          ).value
                                        }`
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  css={css`
                                    border: 1px solid #d9e1e2;
                                    width: 100%;
                                    margin-top: 56px;
                                  `}
                                />
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                  </div>
                </div>
              </>
            );
          }
        })}
      </div>
    </div>
  );
};
