import * as React from "react";

import { breakpoint, breakpointMax } from "../../../../style";
import { css, jsx } from "@emotion/react";

import ConceptualBanner from "../../ConceptualBanner/conceptualBanner.component";
import RoundTag from "../../RoundTag/roundTag.component";
import { formatToThousandsSeparator } from "../../../../utils";
import { style } from "./banner.style";
import { ProgrammeDetail } from "../../../../graphql/queries/programmes.queries";

/** @jsx jsx */

export interface ProgrammeBannerProps {
  green?: boolean;
  programme: ProgrammeDetail;
}

const ProgrammeBanner = ({ green, programme }: ProgrammeBannerProps) => {
  return (
    <ConceptualBanner
      renderLeft={
        <>
          <div css={style.roundTag} style={{backgroundColor: green ? 'rgb(0 140 61 / 15%)' : 'inherit'}}>
            <RoundTag minSize={39}>P</RoundTag>
          </div>
          <div css={style.info}>
            <div css={style.programmeNameText}>{programme.name}</div>
            <div css={style.subtitle}>
              <div>{programme.activeSecurities} securities</div>
              <div
                css={css`
                  @media (max-width: ${breakpointMax}) {
                    text-align: center;
                    width: 50%;
                  }
                `}
              >
              { programme?.amountIssued ? 
                `Max. amount: ${formatToThousandsSeparator(programme.amountIssued)} ${programme.amountCurrency}`
                : ""
              }
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};

export default ProgrammeBanner;
