
import * as React from 'react';
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/react";
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { style } from './index.style';
import { SitecoreGenericTextField } from '../../interfaces';
import greenBg from './greenBg.svg';
import {renderLink} from "../../utils/helper";
export interface MemberCardHeaderProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const MemberCardHeader: React.FunctionComponent<MemberCardHeaderProps> = (props: MemberCardHeaderProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const logo: any = getFieldValue<any>(fields, 'logo', null);
    const isGreen: number = getFieldValue<number>(fields, 'green background', 0);

    const logoStyle = css`
      background: url(${renderLink(logo && logo.src)});
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    `;
    const logoParent = css`
      background: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 120px;
      min-height: 120px;
      border-radius: 8px;
    `;
    const container = css`
      width: 100%;
      min-height: 240px;
      //background: linear-gradient(to right, #425563 0%,#c4c4c4 20%,#425563 40%,#c4c4c4 72%,#425563 100%);
      background: ${isGreen ? `url(${greenBg})` : '#425563'};
      background-size: cover;
      background-position: left;
      //filter: blur(8px);
      display: flex;
      margin-bottom: 10px;
    `;

    return (
        <div css={container}>
            <div css={style.centeredContent}>
                <div css={logoParent}>
                    <div css={logoStyle} />
                </div>
                <div css={style.right}>
                    <div css={style.name}>
                        <Text
                            field={fields && fields.memberName as SitecoreGenericTextField}
                        />
                    </div>
                    <div css={style.inline}>
                        <div css={style.subTitle}>
                            <Text
                                field={fields && fields.subTitle as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.tag}>
                            <Text
                                field={fields && fields.tag as SitecoreGenericTextField}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
