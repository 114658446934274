import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from '../../SecurityOverview/securityOverview.style';
import { Security } from '../../../../../interfaces';
import { Country, marketRuleLabel } from '../../../../../enums';
import { setSuspensionReason } from '../../../../../graphql';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export interface SecurityMarketProps {
  securityData?: Security;
}

export const SecurityMarketTradingATT: React.FunctionComponent<SecurityMarketProps> =
   ({ securityData }: SecurityMarketProps) => {

  const hasTagAtt = securityData?.tags && securityData.tags.includes('ATT');

  return (
    <>
      { hasTagAtt ?
        <>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Unlisted</div>
            <div css={style.blocRowValue}>Admitted to trading without the consent of the issuer</div>
          </div>
          { securityData?.homeMarket?.name ?
            <>
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Home market</div>
                <div css={style.blocRowValue}>
                  {securityData?.homeMarket.name ? securityData?.homeMarket.name : 'N/A'}
                </div>
              </div>
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Home market MIC</div>
                <div css={style.blocRowValue}>
                  {securityData?.homeMarket.mic ? securityData?.homeMarket.mic : 'N/A'}
                </div>
              </div>
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Home market Country</div>
                <div css={style.blocRowValue}>
                  {securityData?.homeMarket.country ? (
                      securityData?.homeMarket.country == Country.TW ?
                      Country.TW :
                      countries.getName(securityData?.homeMarket.country, 'en', { select: 'official' })
                    ) : 'N/A'
                  }
                </div>
              </div>
            </> : <></>
          }
          { securityData?.tradingVenue?.name ?
            <>
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Admitted to trading venue</div>
                <div css={style.blocRowValue}>
                  {securityData?.tradingVenue.name ? securityData?.tradingVenue.name : 'N/A'}
                </div>
              </div>
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>MIC</div>
                <div css={style.blocRowValue}>
                  {securityData?.tradingVenue.mic ? securityData?.tradingVenue.mic : 'N/A'}
                </div>
              </div>
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Country</div>
                <div css={style.blocRowValue}>
                  { securityData?.tradingVenue.country ? (
                      securityData?.tradingVenue.country == Country.TW ?
                      Country.TW :
                      countries.getName(securityData?.tradingVenue.country, 'en', { select: 'official' })
                    ) : 'N/A'
                  }
                </div>
              </div>
            </> : <></>
          }
        </>
        : <></>
      }
      { !hasTagAtt && securityData?.suspensionReason ?
        <>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Unlisted</div>
            <div css={style.blocRowValue}>{setSuspensionReason(securityData.suspensionReason)}</div>
          </div>
        </>
        : <></>
      }
    </>
  );
};
