
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const CHL: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M512,256H0V97.103c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828L512,256 L512,256z" />
            <path fill="#D72B1F" d="M17.655,256v-8.828H0V256v8.828v150.069c0,4.875,3.953,8.828,8.828,8.828h494.345 c4.875,0,8.828-3.953,8.828-8.828V256H17.655z" />
            <path fill="#0039A6" d="M158.897,256H8.828C3.953,256,0,252.047,0,247.172V97.103c0-4.875,3.953-8.828,8.828-8.828h150.069 c4.875,0,8.828,3.953,8.828,8.828v150.069C167.724,252.047,163.772,256,158.897,256z" />
            <path fill="#FFFFFF" d="M87.262,134.487l8.416,25.234l26.6,0.206c3.444,0.026,4.872,4.422,2.101,6.467l-21.398,15.801 l8.023,25.362c1.038,3.284-2.7,5.999-5.502,3.997l-21.64-15.467l-21.641,15.468c-2.802,2.003-6.54-0.714-5.502-3.997l8.023-25.362 l-21.398-15.801c-2.771-2.046-1.343-6.441,2.101-6.467l26.6-0.206l8.416-25.234C81.551,131.22,86.173,131.22,87.262,134.487z" />
        </svg>
    </React.Fragment>
);
