/** @jsx jsx */
import { jsx } from "@emotion/react";
import { MenuWithChild, ToggleButton } from "@bdl-cmn-shared-packages-npm/design-system";
import { OAM_LANGUAGE } from "../../useFilters";
import { style } from "../index.style";

interface StatusFilterProps {
  lang?: OAM_LANGUAGE,
  selected: boolean
  onSelectedChange: (selected: boolean) => void
}

export default function StatusFilter({ lang = "EN", selected, onSelectedChange }: StatusFilterProps) {
  return (
    <MenuWithChild
      icon="file"
      label={lang === "EN" ? "Publication State" : "État de publication"}
      width="100%;"
      expanded
      arrows={true}
    >
      <div css={style.menuItem}>
        <ToggleButton
          isSelected={selected}
          label={lang === "EN" ? "Hide Canceled" : "Cacher si annulé"}
          onClick={() => onSelectedChange(!selected)}
        />
      </div>
    </MenuWithChild>
  );
}