import { css } from "@emotion/react";

export const style = {
  container: css`
    position: fixed;
    bottom: 50px;
    left: 16px;
    width: 52px;
    max-width: 52px;
    height: 52px;
    background: #1a2834;
    border: 1px solid #A4B6C2;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #22AA5F;
    z-index: 15;
    
    svg {
      font-size: 20px;
    }
  `
};
