import * as React from 'react';

import { Tab, Tabs } from '@bdl-cmn-shared-packages-npm/design-system';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState, useEffect } from "react";
import { style } from '../../index.style';
import { LuxXPrimeNoticeLatestFNS } from './latest-fns';
import { LuxXPrimeNoticeLatestSuspension } from './latest-suspensions';
import { LuxXPrimeNoticeUpcomingRemovals } from './upcoming-removals';
import { LuxXPrimeNoticeNewestSecurities } from './new-securities';


export const LuxXPrimeLatestNoticeTabsView = () => {
  const [tab, setTab] = useState<string>("fnsNotices");
  
  useEffect(() => {
  }, []);

  return (
    <div css={[style.container, style.containerTab]}>
      <Tabs hideBorderLine={true}>
        <Tab
          label={"FNS Notices"}
          onTabChange={() => setTab("fnsNotices")}
          selected={tab === "fnsNotices"}
        >
          <LuxXPrimeNoticeLatestFNS />
        </Tab>
        <Tab
          label={"Latest Suspensions"}
          onTabChange={() => setTab("suspensions")}
          selected={tab === "suspensions"}
        >
          <LuxXPrimeNoticeLatestSuspension />
        </Tab>
        <Tab
          label={"New Securities"}
          onTabChange={() => setTab("newSecurities")}
          selected={tab === "newSecurities"}
        >
          <LuxXPrimeNoticeNewestSecurities />
        </Tab>
        <Tab
          label={"Upcoming Removals"}
          onTabChange={() => setTab("upcomingRemovals")}
          selected={tab === "upcomingRemovals"}
        >
          <LuxXPrimeNoticeUpcomingRemovals />
        </Tab>
      </Tabs>
    </div>
  );
};
