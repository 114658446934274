/** @jsx jsx */

import * as React from "react";

import {
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
  Link,
} from "@sitecore-jss/sitecore-jss-react";
import { SitecoreGenericTextField } from "../../interfaces";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";

import { Icon, Button } from "@bdl-cmn-shared-packages-npm/design-system";
import Loader from "../../core/components/Loader/loader.component";
import { canUseDOM } from "../../predicates";
import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
import { renderLink, transformLink } from "../../utils/helper";
import { DOWNLOAD_ENDPOINT } from "../../backbourse";
import { GET_LUXSE_REPORTS } from "../../graphql/queries/luxse-reports.queries";
import DictionaryComponent from "../../core/components/Dictionary/DictionaryComponent";

export interface BackgroundWithItemsLinksComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const title = css`
  width: 100%;
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  text-align: center;
  color: #ffffff;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: left;
  }
`;
const action = css`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`;
const subTitle = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  color: #ffffff;

  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.01em;
    text-align: left;
  }
`;
const cards = css`
  width: 100%;
  display: flex;
  margin-top: 96px;
  flex-wrap: wrap;
  max-width: calc(1200px + 32px);
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    margin-top: 24px;
  }
`;
const card = css`
  background: #f0f5f5;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  margin: 16px;
  width: 376px;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 5px 0.625em rgb(66 85 99 / 25%);

  &:hover {
    background: #d4d4d4;
    transition: all ease 0.2s;
    box-shadow: 0 5px 0.625em rgb(66 85 99 / 25%);
  }

  @media (max-width: ${breakpointMax}) {
    margin-left: 0;
    margin-right: 0;
  }
`;
const cardPdf = css`
  font-size: 14px;
  margin-right: 16px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
  }
`;
const cardAction = css`
  font-weight: 600;
  font-size: 16px;
  color: #22aa5f;
`;
const cardTitle = css`
  font-size: 14px;
  font-weight: 600;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;
const linked = css`
  display: flex;
  align-items: center;
`;

export const BackgroundWithItemsLinksComponent: React.FunctionComponent<BackgroundWithItemsLinksComponentProps> =
  (props: BackgroundWithItemsLinksComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const isPLP: boolean = getFieldValue<boolean>(
      fields,
      "is PLP request",
      false
    );
    const downloadUrl: string = getFieldValue<string>(
      fields,
      "download url",
      DOWNLOAD_ENDPOINT
    );

    const client = luxseGatewayGraphqlClient;

    const [plpReportsLoading, setPlpReportsLoading] = useState<boolean>(false);
    const [plpReports, setPlpReports] = useState<any[]>([]);

    const getPlpReports = async (limit: number) => {
      try {
        const results: any = await client.query({
          query: GET_LUXSE_REPORTS,
          variables: {
            query: "",
            usePageable: true,
            page: 0,
            size: limit,
            types: ["PLP"],
            sorts: ["date,DESC"]
          },
        });

        let data = [];
        if(results?.data?.luxseReports?.resultSize > 0){
          data=results?.data?.luxseReports?.resultList;
        }

        setPlpReports(data);
        setPlpReportsLoading(false);
      } catch (e) {
        console.error("Failed to get PLP reports", e);
        setPlpReportsLoading(false);
      }
    };

    useEffect(() => {
      if (isPLP) {
        setPlpReportsLoading(true);
        getPlpReports(3);
      }
    }, [fields]);

    const background: any = getFieldValue<any>(fields, "background", {
      src: null,
    });
    const buttonLabel: string = getFieldValue<string>(
      fields,
      "navigation button label",
      ""
    );
    const row = css`
      width: 90%;
      margin-top: 10px;
      margin-bottom: 10px;
      color: #354450;
    `;
    const splitDocs: number = getFieldValue<number>(fields, "split documents", 0);
    const document = css`
      width: calc(33% - 20px);
      display: flex;
      border: 1px solid #22aa5f;
      box-sizing: border-box;
      border-radius: 8px;
      align-items: center;
      min-height: 46px;
      background: white;
      margin: 10px;
      margin-bottom: 16px; 
      padding: 10px 16px;
      cursor: pointer;
      position: relative;
      @media (max-width: ${breakpointMax}) {
        width: 100% !important;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      :hover {
        background: #22aa5f;

        > div {
          color: white;
        }
      }
    `;
    const documentIcon = css`
      padding-left: 8px;
      padding-right: 8px;
      @media (max-width: ${breakpointMax}) {
        display: none;
      }
    `;
    const documentIconRight = css`
      padding-left: 8px;
      padding-right: 8px;
      position: absolute;
      right: 0;
      color: #22aa5f;
    `;

    const container = css`
      width: 100%;
      display: flex;
      flex-direction: column;
      background: url(${renderLink(background && background.src)}) no-repeat;
      background-size: cover;
      background-position: center;
      padding-top: 64px;
      padding-bottom: 64px;
      @media (max-width: ${breakpointMax}) {
        padding: 16px;
        padding-top: 85px;
        padding-bottom: 40px;
        margin-left: ${fields &&
        fields[`link1`] &&
        (fields && (fields[`link1`] as any)).value
          ? "0"
          : "16px"};
        margin-right: ${fields &&
        fields[`link1`] &&
        (fields && (fields[`link1`] as any)).value
          ? "0"
          : "16px"};
        width: calc(
          100% -
            ${fields &&
            fields[`link1`] &&
            (fields && (fields[`link1`] as any)).value
              ? "0"
              : "32px"}
        );
        border-radius: ${fields &&
        fields[`link1`] &&
        (fields && (fields[`link1`] as any)).value
          ? "0"
          : "8px"};
      }
    `;

    return (
      <div css={container}>
        <div css={title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={subTitle}>
          <DictionaryComponent text={fields.subTitle.value}/>
        </div>

        {isPLP ? (
          <Loader loading={plpReportsLoading}>
            <div css={cards}>
              {plpReports &&
                plpReports.map((plpReport: any, index: number) => { 
                  return plpReport ? (
                    <div
                      css={document}
                      key={`document-${index}`}
                      onClick={() =>
                        canUseDOM &&
                        window.open(
                          `${plpReport?.downloadUrl}`,
                          "_blank"
                        )
                      }
                    >
                      <div css={documentIcon}>
                        <Icon icon={"file-pdf"} />
                      </div>
                      <div css={row}>{plpReport?.label}</div>
                      <div css={documentIconRight}>
                        <Icon icon={"file-export"} />
                      </div>
                    </div> ): <></>
                  }
                )}
            </div>
          </Loader>
        ) : (
          <div css={cards}>
            {new Array(10).fill(0).map((arr: any, index: number) => {
              if (
                fields &&
                fields[`link${index + 1}`] &&
                (fields && (fields[`link${index + 1}`] as any)).value
              ) {
                return (
                  <div
                    css={card}
                    key={`item-${index}`}
                    onClick={() =>
                      canUseDOM &&
                      fields &&
                      (fields[`link ${index + 1} url`] as any)?.value
                        ? window.open(
                            fields &&
                              (fields[`link ${index + 1} url`] as any)?.value,
                            "_blank"
                          )
                        : console.debug()
                    }
                  >
                    <div css={linked}>
                      <div css={cardTitle}>
                        <Text
                          field={
                            fields[
                              `link${index + 1}`
                            ] as SitecoreGenericTextField
                          }
                        />
                      </div>
                    </div>
                    <div css={cardAction}>
                      <Icon icon={"chevron-right"} />
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
        {buttonLabel && (
          <div css={action}>
            <Link
              target={
                fields["navigation button link"]?.value?.linktype === "external"
                  ? "_blank"
                  : "_self"
              }
              title={fields["button label"]}
              field={fields["navigation button link"]}
              css={{ textDecoration: "none" }}
            >
              <Button label={buttonLabel} iconRight={"chevron-right"} />
            </Link>
          </div>
        )}
      </div>
    );
  };
