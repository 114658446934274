import { css } from '@emotion/react';

export const genericTradingDataStyle = {
  title1: css`
    font-family: 'Mont';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 150%;
    color: #253845;
  `,
  title2: css`
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #354450;
  `,
};
