
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from "react-router-dom";
import {fetchData} from "../../utils/helper";
import Loader from "../../core/components/Loader/loader.component";
import { formatDate } from "../../utils/date";

export interface PublicationContentProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}
const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  margin-bottom: 48px;
`;
const title = css`
  font-weight: bold;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
`;
const items = css`
  display: flex;
  flex-direction: column;
`;
const item = css`
  display: flex;
  padding-bottom: 40px;
  padding-top: 40px;
  cursor: pointer;
`;
const left = css`
  width: 200px;
`;
const right = css`
  width: calc(100% - 200px);
`;
const itemDate = css`
  font-size: 16px;
  text-align: center;
  color: #354450;
`;
const itemTitle = css`
  font-weight: bold;
  font-size: 24px;
  color: #22AA5F;
  margin-bottom: 16px;
`;
const itemMore = css`
  font-size: 16px;
  color: #22AA5F;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const itemContent = css`
  font-size: 16px;
  color: #354450;
  line-height: 150%;
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  white-space: normal;
`;
const link = css`
  margin-top: 24px;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #22AA5F;
  cursor: pointer;
`;
export const PublicationContent: React.FunctionComponent<PublicationContentProps> = (props: PublicationContentProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const location = useLocation();
    const id = location.pathname.split('/').pop().split('___')[0].toUpperCase();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [data, setData] = React.useState<any>(null);

    const getDataFromItemId = async (id: string) => {
        try {
            const data: any = await fetchData(id, false);
            //const { root } = data;
            setData(data);
            setLoading(false);
        } catch (err) {
            console.error('publication error', err);
        }
    };

    React.useEffect(() => {
        getDataFromItemId(id);
    }, []);

    return (
        <div css={container}>
            <Loader loading={loading}>
                <div css={title}>
                    { data?.title }
                </div>
                <div css={items}>
                    <div css={item}>
                        <div css={left}>
                            <div css={itemDate}>
                                { data && formatDate(data['data publication'], 'YYYY') }
                            </div>
                        </div>
                        <div css={right}>
                            <div css={itemTitle}>
                                { data?.title }
                            </div>
                            <div css={itemContent} dangerouslySetInnerHTML={{ __html: data && data.content}} />
                        </div>
                    </div>
                </div>
            </Loader>
        </div>
    );
}
    //dangerouslySetInnerHTML={/*{ __html: 'arr['short content']'}*/}