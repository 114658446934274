import * as React from "react";

import { breakpointMax } from "../../../../style";
import { css, jsx } from "@emotion/react";

const lint = jsx; // linter hack

export const style = {
  roundTag: css`
    > div {
      width: 1.5em;
      height: 1.5em;
      font-size: 1.5em;
      margin-right: 0.5rem;
    }
  `,

  tag: css`
    background-color: #fff;
    white-space: nowrap;
    color: #354450;
    border-radius: 1em;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 17px;
    align-self: flex-start;
    padding: 0.5em 1em;
  `,

  tagMini: css`
    padding: 2px 8px;
  `,

  tagIcon: css`
    margin-right: 0.5em;

    span {
      margin-left: 8px;
    }
  `,

  genderTag: css`
    background-color: #fff;
    color: #f18700;
  `,

  lastPrice: css`
    font-size: 1.5em;
    @media (max-width: ${breakpointMax}) {
      > div {
        align-items: center;
        justify-content: center;
      }
    }
  `,
  info: css`
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1em;
    @media (max-width: ${breakpointMax}) {
      align-items: center;
      text-align: center;
    }
  `,
  country: css`
    display: flex;
    gap: 0.5em;
    align-content: center;
    font-size: 1.2em;
  `,
  securityNameText: css`
    font-size: 1.5em;
    font-weight: 700;
    line-height: 40px;
  `,
  subtitle: css`
    display: flex;
    gap: 1em;
    align-items: center;
  `,
  isin: css`
    padding-left: 15px;
    border-left: 2px solid #425563;
  `,
  metadata: css`
    display: flex;
    align-items: stretch;
    align-content: center;

    > * {
      padding: 1em 2em;
      border-right: 1px solid #253645;
      text-align: center;
      vertical-align: middle;
      line-height: 2.25em;
    }

    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      > * {
        border-right: none;
        border-bottom: 1px solid #253645;
      }
    }
  `,

  metadataLuxxprime: css`
    background-color: #fff;
    width: 13%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-right: 0px;
      padding: 0;
    }
  `,

  actions: css`
    padding: 0.5em;
    border-right: none;
    margin-left: auto;
    display: flex;
    gap: 1em;
  `,
  luxXPrimeBanner: css`
    background: #f0f5f5;
    width: 100%;
    max-width: 100%;
    padding: 20px;
  `,
  luxXPrimeContainer: css`
    display: flex;
    width: 100%;
    flex-direction: row;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin: auto;

    > * {
      margin: auto;
    }
  `,
  luxXPrimeLogo: css``,
  luxXPrimeContent: css`
    color: rgb(37, 54, 69);
  `,
  luxXPrimeTitle: css`
    font-weight: 800;
    font-size: 22px;
    line-height: 31px;
  `,
  luxXPrimeSubTitle: css`
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
  `,
  luxXPrimeButton: css``,
  success: css`
  background: #E8F5E9;
  color: #2B752F;
  `,
  warning: css`
  background: #FFF1E0;
  color: #824E0A;
  `,
  danger: css`
  background: #FECDD2;
  color: #850D23;
  `,
};
