import gql from "graphql-tag";
import { indiceNoBasketFragment, priceInformationFragment } from "../indices.queries";

export const indexSearchItemFragment = gql`
  ${priceInformationFragment}
  fragment indexSearchItemFragment on IndiceInfo {
    id
    indexName
    isin
    codeType
    currency
    indexPrice {
      lastPrice {
        ...priceInformationFragment
      }
      lastClosingPrice {
        ...priceInformationFragment
      }
      monthPriceMax {
        ...priceInformationFragment
      }
      monthPriceMin {
        ...priceInformationFragment
      }
      yearPriceMax {
        ...priceInformationFragment
      }
      yearPriceMin {
        ...priceInformationFragment
      }
      yearToDateAbsolute
      yearToDatePercent
    }
  }
`;

export const indexSearchFullFragment = gql`
  ${indexSearchItemFragment}
  fragment indexSearchFullFragment on LuxseSearchIndexesResults {
    totalHits,
    indexes {
      ...indexSearchItemFragment
    }
    filters{
        types{
            name
            count
        }
        currencies{
            name
            count
        }
        latestPriceDateRanges {
            name
            fromDate
            toDate
            count
        }
    } 
  }
`;

export const indexSearchEmptyFragment = gql`
  fragment indexSearchEmptyFragment on LuxseSearchIndexesResults {
    totalHits
  }
`;

export const indexSearchNoFilterFragment = gql`
  ${indexSearchItemFragment}
  fragment indexSearchNoFilterFragment on LuxseSearchIndexesResults {
    totalHits,
    indexes {
      ...indexSearchItemFragment
    }
  }
`;

export const indexSearchNoFilterNoBasketFragment = gql`
  ${indiceNoBasketFragment}
  fragment indexSearchNoFilterNoBasketFragment on LuxseSearchIndexesResults {
    totalHits,
    indexes {
      ...indiceNoBasketFragment
    }
  }
`;


export const SEARCH_INDEXES_WITHOUT_FILTERS =  gql`
  ${indexSearchNoFilterFragment}
  query GetLuxseSearchResults(
    $page : Int,
    $size : Int,
    $sort: String!
    $searchTerm: String!
    $ids: [String!]
    $idsOperator: String
    $excludeIds: [String!]
    $types: [String!]
    $typesOperator: String
    $excludeTypes: [String!]
    $currencies: [String!]
    $currenciesOperator: String
    $excludeCurrencies: [String!]
  ){
    luxseIndexesSearch(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort
      ids: $ids
      idsOperator: $idsOperator
      excludeIds: $excludeIds
      types: $types
      typesOperator: $typesOperator
      excludeTypes: $excludeTypes
      currencies: $currencies
      currenciesOperator: $currenciesOperator
      excludeCurrencies: $excludeCurrencies
    ){
      ...indexSearchNoFilterFragment
    }
  }
`;

export const SEARCH_INDEXES_PRICE_WITHOUT_FILTERS =  gql`
  ${indexSearchNoFilterNoBasketFragment}
  query GetLuxseSearchResults(
    $page : Int,
    $size : Int,
    $sort: String!
    $searchTerm: String!
    $ids: [String!]
    $idsOperator: String
    $excludeIds: [String!]
    $types: [String!]
    $typesOperator: String
    $excludeTypes: [String!]
    $currencies: [String!]
    $currenciesOperator: String
    $excludeCurrencies: [String!]
  ){
    luxseIndexesSearch(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort
      ids: $ids
      idsOperator: $idsOperator
      excludeIds: $excludeIds
      types: $types
      typesOperator: $typesOperator
      excludeTypes: $excludeTypes
      currencies: $currencies
      currenciesOperator: $currenciesOperator
      excludeCurrencies: $excludeCurrencies
    ){
      ...indexSearchNoFilterNoBasketFragment
    }
  }
`;