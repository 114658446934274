/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import * as React from 'react';
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import { GreyBannerText } from '../GreyBannerText';

export interface AssitanceComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
`;

export const AssitanceComponent: React.FunctionComponent<AssitanceComponentProps> = (
  props: AssitanceComponentProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const headingValue: string = getFieldValue<string>(fields, 'heading', '');

  return (
    <div css={container}>
      <div>
        <GreyBannerText
          fields={{
            title: {
              value: (fields && fields[`title`] && (fields && (fields[`title`] as any)).value) || null,
            },
            text: {
              value: (fields && fields[`description`] && (fields && (fields[`description`] as any)).value) || null,
            },
          }}
          rendering={rendering}
        />
      </div>
      <div></div>
    </div>
  );
};
