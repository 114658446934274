
import * as React from 'react';
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/react";
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import {
   Card,
    Icon,
} from "@bdl-cmn-shared-packages-npm/design-system";
import mac from './max.svg';

import { SitecoreGenericTextField } from '../../interfaces';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import {renderLink} from "../../utils/helper";

export interface ChangingTheWayProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}
const style = {
    container: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 3em;
      padding-left: 6em;
      padding-right: 6em;
    `,
    title: css`
      display: flex;
      width: 100%;
      color: #354450;
      font-size: 3em;
      font-weight: 800;
      margin-bottom: 2em;
    `,
    card: css`
      margin-bottom: 1em;
      padding-right: 2em;
    `,
    cardContent: css`
      display: flex;
      align-items: center;
    `,
    cardContentLeft: css`
      margin-bottom: 1em;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      width: 3em;
    `,
    cardContentRight: css`
      margin-bottom: 1em;
      width: calc(100% - 3em);
    `,
    cardContentRightTitle: css`
      margin-bottom: 1em;
    `,
    cardContentRightText: css`
      margin-bottom: 1em;
    `,
    cardContentRightLink: css`
      margin-bottom: 1em;
    `,
    containerDouble: css`
      display: flex;
      justify-content: center;
      width: 100%;
    `,
    containerDoubleLeft: css`
      display: flex;
      flex-direction: column;
      width: 50%;
    `,
    containerDoubleRight: css`
      display: flex;
      flex-direction: column;
      width: 50%;
      
      img {
        width: 100%;
        max-width: 600px;
      }
    `,
}
export const ChangingTheWay: React.FunctionComponent<ChangingTheWayProps> = (props: ChangingTheWayProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const mainCardText: string = getFieldValue<string>(fields, 'mainCardText', '');

    return (
        <div css={style.container}>
            <div css={style.title}>
                <Text
                    field={fields && fields.title as SitecoreGenericTextField}
                />
            </div>
            <div css={style.containerDouble}>
                <div css={style.containerDoubleLeft}>
                    <div css={style.card}>
                        <Card>
                            <div css={style.cardContent}>
                                <div css={style.cardContentLeft}>
                                    <Icon icon="times" />
                                </div>
                                <div css={style.cardContentRight}>
                                    <div css={style.cardContentRightTitle}>
                                        <Text
                                            field={fields && fields.mainCardTitle as SitecoreGenericTextField}
                                        />
                                    </div>
                                    <div css={style.cardContentRightText}>
                                        <Text
                                            field={fields && fields.mainCardText as SitecoreGenericTextField}
                                        />
                                    </div>
                                    <div css={style.cardContentRightLink}>
                                        <Text
                                            field={fields && fields.mainCardLink as SitecoreGenericTextField}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div css={style.card}>
                        <Card>
                            <div css={style.cardContent}>
                                <div css={style.cardContentLeft}>
                                    <Icon icon="times" />
                                </div>
                                <div css={style.cardContentRight}>
                                    <div css={style.cardContentRightTitle}>
                                        <Text
                                            field={fields && fields.mainCardTitle as SitecoreGenericTextField}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div css={style.card}>
                        <Card>
                            <div css={style.cardContent}>
                                <div css={style.cardContentLeft}>
                                    <Icon icon="times" />
                                </div>
                                <div css={style.cardContentRight}>
                                    <div css={style.cardContentRightTitle}>
                                        <Text
                                            field={fields && fields.mainCardTitle as SitecoreGenericTextField}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <div css={style.containerDoubleRight}>
                    <img src={renderLink(mac)} alt={"image"} />
                </div>
            </div>
            <Carousel showArrows={true}>
                <div>
                    <img src={renderLink(mac)} alt={"image"} />
                </div>
                <div>
                    <img src={renderLink(mac)} alt={"image"} />
                </div>
            </Carousel>
        </div>
    );
}
