
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const LBN: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <g>
                <path fill="#ED1C24" d="M512,158.898H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,158.898L512,158.898z" />
                <path fill="#ED1C24" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828v-61.793h512v61.793 C512,419.773,508.047,423.725,503.172,423.725z" />
            </g>
            <rect y="158.901" fill="#FFFFFF" width="512" height="194.21" />
            <path fill="#00A651" d="M274.862,315.38l81.581-16.317c5.888-1.177,8.516-8.107,4.89-12.894l0,0 c-1.273-1.681-3.149-2.802-5.233-3.127l-86.911-13.551c-2.402-0.375-4.104-2.631-3.7-5.124c0.391-2.414,2.838-3.953,5.262-3.633 l60.259,7.929c4.898,0.644,7.902-5.208,4.523-8.812l-4.838-5.16c-1.811-1.932-4.185-3.243-6.785-3.746l-50.339-9.749l42.921,3.092 c3.29,0.237,5.196-3.652,2.993-6.108l-2.201-2.453c-1.331-1.482-3.098-2.502-5.048-2.909l-42.613-8.905l30.284-0.876 c2.678-0.077,4.04-3.253,2.25-5.246l-1.358-1.513c-1.064-1.185-2.44-2.047-3.97-2.486l-27.01-7.755h21.226 c2.1,0,3.512-2.152,2.677-4.078l-0.716-1.652c-0.859-1.982-2.515-3.509-4.559-4.204l-19.629-6.678l9.654-0.031 c2.869-0.009,4.793-2.951,3.652-5.582l0,0c-0.919-2.121-2.541-3.86-4.593-4.924L256,167.725l-21.534,11.165 c-2.051,1.064-3.674,2.803-4.593,4.924l0,0c-1.141,2.633,0.783,5.574,3.652,5.582l9.654,0.032l-19.629,6.678 c-2.045,0.695-3.701,2.222-4.559,4.204l-0.716,1.652c-0.835,1.927,0.577,4.078,2.677,4.078h21.226l-27.01,7.755 c-1.53,0.439-2.906,1.301-3.97,2.486l-1.358,1.513c-1.789,1.994-0.427,5.169,2.25,5.246l30.284,0.876l-42.613,8.905 c-1.95,0.407-3.719,1.427-5.048,2.909l-2.201,2.453c-2.204,2.455-0.298,6.345,2.993,6.108l42.921-3.092l-50.339,9.749 c-2.6,0.503-4.974,1.814-6.785,3.746l-4.838,5.16c-3.379,3.604-0.374,9.457,4.523,8.812l60.259-7.929 c2.424-0.319,4.872,1.219,5.262,3.633c0.404,2.495-1.298,4.75-3.7,5.124l-86.911,13.551c-2.083,0.324-3.959,1.446-5.233,3.126l0,0 c-3.626,4.786-0.998,11.716,4.89,12.894l81.581,16.317v10.182c0,5.558-2.208,10.889-6.138,14.819l-12.721,12.723H256h37.725 l-12.724-12.724c-3.93-3.93-6.138-9.261-6.138-14.819V315.38H274.862z" />
        </svg>
    </React.Fragment>
);
