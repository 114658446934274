/** @jsx jsx */
import * as React from 'react';

import {Td, Tr} from '@bdl-cmn-shared-packages-npm/design-system';
import {css, jsx} from '@emotion/react';

import {EMPTY_DATA_PLACEHOLDER} from "../../../../../constants";
import {formatDate} from "../../../../../../utils/date";
import { style } from '../../index.style';
import {computePriceStyle} from "../../../../../utils/compute-price-style";
import {marketNewsStyle} from "../../../MarketNews/index.style";
import { PreciousMetal } from '../../../../../../interfaces/precious-metal';

export const PreciousMetalsDetailsRow = ({row}: {row: PreciousMetal}) => {
    if(!row)
        return <></>;

    return (
        <Tr>
            <Td>
                <div css={marketNewsStyle.status}>
                    <div css={marketNewsStyle.tag}>Precious metal</div>
                </div>
            </Td>
            <Td css={[style.title, style.titleBold, style.titleGrey]}>
                { row.name || EMPTY_DATA_PLACEHOLDER}
            </Td>
            <Td>
                <div css={[style.title, style.titleBold, style.titleMargin, style.titleGrey]}>
                    {row.marketData?.lastPrice?.amount > 0 ? 
                        `${row.marketData.lastPrice.amount} ${row.currency}`
                        : EMPTY_DATA_PLACEHOLDER
                    }
                </div>
                <div css={[style.title, style.titleMargin, style.titleGrey]}>
                    {formatDate(row.marketData?.lastPrice?.date, 'DD/MM/YYYY')}
                </div>
            </Td>
            <Td css={[style.title, style.titleGrey]}>
                {computePriceStyle(row.marketData?.lastPrice?.changePercent, '%')}
            </Td>
            <Td>
                <div css={[style.title, style.titleBold, style.titleMargin, style.titleGrey]}>
                    {row.marketData?.monthHighestPrice?.amount || EMPTY_DATA_PLACEHOLDER}
                </div>
                <div css={[style.title, style.titleMargin, style.titleGrey]}>
                    {row.marketData?.monthLowestPrice?.amount || EMPTY_DATA_PLACEHOLDER}
                </div>
            </Td>
            <Td>
                <div css={[style.title, style.titleBold, style.titleMargin, style.titleGrey]}>
                    {row.marketData?.yearHighestPrice?.amount || EMPTY_DATA_PLACEHOLDER}
                </div>
                <div css={[style.title, style.titleMargin, style.titleGrey]}>
                    {row.marketData?.yearLowestPrice?.amount || EMPTY_DATA_PLACEHOLDER}
                </div>
            </Td>
        </Tr>
    );
};

export default PreciousMetalsDetailsRow;
