
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from '../../interfaces';
import {Button} from "@bdl-cmn-shared-packages-npm/design-system";
import {renderLink} from "../../utils/helper";

export interface BackgroundTwoColumnsProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const action = css`
  margin-top: 24px;
  margin-bottom: 24px;
`;
const container = css`
  width: 100%;
  max-width: 1320px;
  margin: auto;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
`;
const left = css`
  width: 50%;
  display: flex;
  flex-direction: column;
`;
const right = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const heading = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
  margin-bottom: 16px;
`;
const title = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  color: #FFFFFF;
  margin-bottom: 32px;
`;
const description = css`
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 20px;
`;
const card = css`
  display: flex;
  flex-direction: column;
  background: rgba(37, 54, 69, .8);
  filter: drop-shadow(0px 4px 4px rgba(255, 0, 0, 0.25));
  border-radius: 8px;
  padding: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  max-width: 380px;

`;
const bloc = css`
  border-bottom: 1px solid #F6F9F9;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  &:last-of-type {
    border-bottom: none;
  }
`;
const cardData = css`
  font-weight: 800;
  font-size: 32px;
  line-height: 41px;
  color: #FFFFFF;
  text-align: center;
`;
const cardText = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #F6F9F9;
`;



export const BackgroundTwoColumns: React.FunctionComponent<BackgroundTwoColumnsProps> = (props: BackgroundTwoColumnsProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const background: any = getFieldValue<any>(fields, 'background', null);
    const btnLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
    const btnLink: string = getFieldValue<string>(fields, 'btnLink', '');

    const parentContainer = css`
      width: 100%;
      margin: auto;
      padding-top: 96px;
      padding-bottom: 96px;
      background-image: url(${renderLink(background && background.src)});
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
    `;
    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={left}>
                    <div css={heading}><Text field={fields && fields.heading as SitecoreGenericTextField}/></div>
                    <div css={title}><Text field={fields && fields.title as SitecoreGenericTextField}/></div>
                    <div css={description}><Text field={fields && fields.description as SitecoreGenericTextField}/></div>
                    <div css={action}>
                        <Button label={btnLabel} onClick={() => btnLink ? window.location.href = btnLink : console.debug} iconLeft={'box'}/>
                    </div>
                </div>
                <div css={right}>
                    <div css={card}>
                        <div css={bloc}>
                            <div css={cardData}><Text field={fields && fields.cardData1 as SitecoreGenericTextField}/></div>
                            <div css={cardText}><Text field={fields && fields.cardText1 as SitecoreGenericTextField}/></div>
                        </div>
                        <div css={bloc}>
                            <div css={cardData}><Text field={fields && fields.cardData2 as SitecoreGenericTextField}/></div>
                            <div css={cardText}><Text field={fields && fields.cardText2 as SitecoreGenericTextField}/></div>
                        </div>
                        <div css={bloc}>
                            <div css={cardData}><Text field={fields && fields.cardData3 as SitecoreGenericTextField}/></div>
                            <div css={cardText}><Text field={fields && fields.cardText3 as SitecoreGenericTextField}/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
