import * as React from 'react';

import { css, jsx, keyframes } from '@emotion/react';

import { DefaultSitecoreProps } from '../../interfaces/default-sitecore';
import { LuxXPrimeMostViewListView } from '../../core/components/LuxXPrime/Trade/MostViewed';

/** @jsx jsx */
export const LuxXPrimeMostViewList: React.FunctionComponent<DefaultSitecoreProps> = (
  props: DefaultSitecoreProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  
  return <LuxXPrimeMostViewListView />
};
