import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";

/** @jsx jsx */

export interface DisclaimerComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering?: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  margin: auto;
  padding-top: 48px;
  padding-bottom: 48px;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
  }
`;
const container = css`
  width: 100%;
  max-width: 1280px;
  margin: auto;
  display: flex;
  background: #f6f9f9;
  padding: 40px;
  border-radius: 24px;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    padding: 32px;
  }
`;
const title = css`
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 22px;
  color: #354450;
`;
const text = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  color: #354450;
`;

export const DisclaimerComponent: React.FunctionComponent<DisclaimerComponentProps> =
  (props: DisclaimerComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={title}>
            <Text
              field={fields && (fields.title as SitecoreGenericTextField)}
            />
          </div>
          <div css={text}>
            <RichText
              field={fields && (fields.text as SitecoreGenericTextField)}
            />
          </div>
        </div>
      </div>
    );
  };
