import * as React from 'react';
/** @jsx jsx */
import { css, jsx, keyframes, SerializedStyles } from '@emotion/react';
import Lottie from 'lottie-react';
import loaderAnimation from './loader.json';

export interface LoaderProps {
  loading: boolean;
  horizontal?: boolean;
  noPadding?: boolean;
  padding?: string;
  margin?: string;
  maxHeight?: string;
  error?: boolean;
  centered?: boolean;
  children: any;
  overflow?: string;
}

const Loader: React.FunctionComponent<LoaderProps> = ({
  loading,
  children,
  horizontal,
  padding,
  maxHeight,
  centered,
  error,
  margin,
  overflow,
  ...passThroughProps
}: LoaderProps) => {
  const loader = (centered: boolean) => css`
    width: 100%;
    height: ${centered ? '100%' : 'auto'};
    background-color: inherit !important;
    display: flex;
    padding: 0 !important;
    overflow: ${overflow};
    flex-direction: ${horizontal ? 'row' : 'column'};
    justify-content: ${loading || horizontal ? 'center' : 'flex-start'};
    align-items: ${loading ? 'center' : 'flex-start'};
    ${loading && !error && `color: #22AA5F`};
    ${loading && `font-size: 3em;`};
  `;

  const loaderStyle = (padding: string) => css`
    padding: ${padding};
    margin: ${margin || '4rem auto'};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100%;
      max-width: 180px;
      ${maxHeight && `max-height: ${maxHeight};`};
    }
  `;

  return (
    <div css={loader(centered)} {...passThroughProps}>
      {error ? (
        <div>Data error</div>
      ) : loading ? (
        <div css={loaderStyle(padding)}>{<Lottie animationData={loaderAnimation} loop={true} />}</div>
      ) : (
        children
      )}
    </div>
  );
};

export default Loader;
