
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const SUR: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#377E3F" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <g>
                <rect y="150.071" fill="#FFFFFF" width="512" height="35.31" />
                <rect y="326.621" fill="#FFFFFF" width="512" height="35.31" />
            </g>
            <rect y="185.381" fill="#B40A2D" width="512" height="141.24" />
            <path fill="#ECC81D" d="M253.801,201.615l-12.854,38.541l-40.627,0.314c-2.228,0.018-3.15,2.86-1.359,4.183l32.683,24.135 l-12.255,38.737c-0.672,2.124,1.746,3.881,3.559,2.585L256,286.485l33.053,23.625c1.812,1.295,4.23-0.461,3.559-2.585 l-12.255-38.737l32.683-24.135c1.792-1.323,0.868-4.166-1.359-4.183l-40.627-0.314L258.2,201.615 C257.494,199.502,254.506,199.502,253.801,201.615z" />
        </svg>
    </React.Fragment>
);
