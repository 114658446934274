/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { renderLink } from "../../utils/helper";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import hexa from "./hexa.svg";
import { TimeToReadIndicator } from "../../core/components/TimeToReadIndicator";
import Loader from "../../core/components/Loader/loader.component";
import { SitecoreGenericTextField } from "../../interfaces";
import { breakpointMax } from "../../style";
import { getArticles } from "../../utils/blog";

export interface BlogHomeArticlesFromCategoryProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpointMax}) {
    padding-top: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }
`;
const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const blocPartLarge = css`
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
`;
const blocPartCols = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  margin-bottom: 80px;
  cursor: pointer;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
  }
`;
const halfLeftBloc = css`
  width: 50%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }

  &:hover {
    .half-image {
      opacity: 0.6;
    }

    .half-title {
      color: #5e798d;
    }
  }

  transition: all 150ms ease-in-out;
`;
const half = css`
  width: 50%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const halfImage = (image?: string) => css`
  width: 100%;
  background: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  height: 100%;
  min-height: 250px;
  max-height: 300px;
  background-position: center;

  &:hover {
    opacity: 0.6;
  }

  transition: all 150ms ease-in-out;
`;
const halfLeft = css`
  width: 100%;
  padding-left: 120px;

  a {
    cursor: pointer;
    text-decoration: none;
  }

  @media (max-width: ${breakpointMax}) {
    padding-left: 0;
    margin-top: 32px;
  }
`;

const halfTitle = (withMargin?: boolean) => css`
  width: 100%;
  font-weight: 800;
  color: #425563;
  margin-top: ${withMargin ? "56px" : "0"};
  font-size: 24px;
  line-height: 31px;

  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #425563;
  }

  &:hover {
    color: #5e798d;
  }

  transition: all 150ms ease-in-out;
`;

const halfDescription = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #253845;
  margin-top: 24px;
  margin-bottom: 16px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #253845;
  }
`;

const mainTitle = css`
  font-weight: 800;
  font-size: 36px;
  line-height: 46px;
  color: #253845;
  margin-top: 32px;

  &:hover {
    color: #5e798d;
  }

  transition: all 150ms ease-in-out;
`;
const mainDescription = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #253845;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const header = css`
  display: flex;
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;

const headerLeft = (iconColor: string) => css`
  width: 100px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${hexa});
  background-repeat: no-repeat;
  background-size: cover;
  color: ${iconColor === "blue"
    ? "#009CC4"
    : iconColor === "green"
    ? "#22AA5F"
    : "#CB1234"};
  font-size: 24px;
  @media (max-width: ${breakpointMax}) {
    margin-bottom: 24px;
  }
`;

const headerRight = css`
  display: flex;
  flex-direction: row;
  width: calc(100% - 100px);
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    flex-direction: column;
  }
`;

const headerRightContent = css`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding-left: 0;
  }
`;

const headerRightContentTop = css`
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #425563;
  margin-bottom: 8px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #425563;
  }
`;

const headerRightContentBottom = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  color: #253845;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 32px;
    line-height: 41px;
    color: #253845;
  }

  &:hover {
    color: #5e798d;
  }

  transition: all 150ms ease-in-out;
`;

const headerRightMore = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #22aa5f;
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  @media (max-width: ${breakpointMax}) {
    margin-top: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #22aa5f;
  }

  a {
    text-decoration: none;
    color: #22aa5f;
  }
`;

export const BlogHomeArticlesFromCategory: React.FunctionComponent<BlogHomeArticlesFromCategoryProps> =
  (props: BlogHomeArticlesFromCategoryProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const [allArticles, setAllArticles] = useState<any[]>([]);
    const [data, setData] = useState<any>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const hideHeader: boolean = getFieldValue<boolean>(
      fields,
      "hide header",
      false
    );
    const category =
      fields &&
      fields["category to display"] &&
      fields["category to display"][0]?.name;
    const icon: string = getFieldValue<string>(fields, "icon", "");
    const showAll: boolean = getFieldValue<boolean>(
      fields,
      "show 3 latest articles",
      false
    );
    const iconColor: any = getFieldValue<any>(fields, "icon color", null);

    useEffect(() => {
      if (showAll) {
        getArticles(`?take=3`)
          .then((response) => {
            setData(response?.results);

            setLoaded(true);
          })
          .catch((err) => {
            console.log("error api", err);
          });
      } else {
        getArticles(`?categories=${category}&take=3`)
          .then((response) => {
            setData(response?.results);
            setLoaded(true);
          })
          .catch((err) => {
            console.log("error api", err);
          });
      }
    }, [category]);

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={blocPartLarge}>
            {!hideHeader && (
              <div css={header}>
                <div css={headerLeft(iconColor)}>
                  <Icon icon={icon as any} />
                </div>
                <div css={headerRight}>
                  <div css={headerRightContent}>
                    <div css={headerRightContentTop}>
                      <Text
                        field={
                          fields && (fields.heading as SitecoreGenericTextField)
                        }
                      />
                    </div>
                    <div css={headerRightContentBottom}>
                      <Text
                        field={
                          fields && (fields.title as SitecoreGenericTextField)
                        }
                      />
                    </div>
                  </div>
                  <div css={headerRightMore}>
                    <a
                      href={
                        showAll
                          ? "/blog"
                          : `${
                              fields &&
                              fields["category to display"][0] &&
                              fields["category to display"][0]
                                ?.url /*?.toLowerCase()*/
                            }`
                      }
                    >
                      <Text
                        field={
                          fields &&
                          (fields[
                            "read more label"
                          ] as SitecoreGenericTextField)
                        }
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            <Loader loading={!loaded}>
              <div css={blocPartCols}>
                <div css={halfLeftBloc}>
                  {data && data[0] && (
                    <a
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      href={`${
                        data[0]?.articleUrl.replace(
                          "/en/",
                          "/"
                        ) /*?.toLowerCase()*/
                      }`}
                    >
                      <div
                        css={halfImage(renderLink(data[0].imageUrl))}
                        className="half-image"
                      />
                      <a
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        href={`${
                          data[0]?.articleUrl.replace(
                            "/en/",
                            "/"
                          ) /*?.toLowerCase()*/
                        }`}
                      >
                        <div css={mainTitle} className="half-title">
                          {data[0]?.title}
                        </div>
                      </a>

                      <div
                        css={mainDescription}
                        dangerouslySetInnerHTML={{
                          __html: data[0]?.shortDescription,
                        }}
                      />
                      <TimeToReadIndicator
                        data={data[0]?.content}
                        date={data[0]?.creationDate}
                      />
                    </a>
                  )}
                </div>
                <div css={half}>
                  {(data || [])
                    .slice(1, 3)
                    .map((article: any, index: number) => (
                      <div css={halfLeft} key={`article-${index}`}>
                        <a
                          href={
                            article?.articleUrl.replace(
                              "/en/",
                              "/"
                            ) /*?.toLowerCase()*/
                          }
                        >
                          <div css={halfTitle(index === 1)}>
                            {article.title}
                          </div>
                          <div css={halfDescription}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: article?.shortDescription,
                              }}
                            />
                          </div>

                          {article && (
                            <TimeToReadIndicator
                              data={article.content}
                              date={article?.creationDate}
                            />
                          )}
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </Loader>
          </div>
        </div>
      </div>
    );
  };
