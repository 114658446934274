import React, { useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import {
  InputField,
  Pagination,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { formatDate } from "../../../utils/date";
import { genericStyle } from "../UI/generic/generic.style";
import { SecuritySearchResultDto } from "../../../interfaces/search/search";
import { GET_SEARCH_SECURITIES_ONLY_RESULTS } from "../../../graphql";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import SearchSecurity from "../../../interfaces/search/search-security";
import SecuritySearchCard from "../UI/list/security/card-mobile";
import Loader from "../Loader/loader.component";
import { style } from "./index.style";
import ChinaBondsTable from "./table";
import Utils from "../../utils/utils";

/** @jsx jsx */

export default function ChinaBondsView({
  tags,
  label,
}: {
  tags: string[];
  label: string;
}) {
  const client = luxseGatewayGraphqlClient;

  const [bonds, setBonds] = useState<SecuritySearchResultDto>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [filteredBonds, setFilteredBonds] = useState<SearchSecurity[]>([]);
  const [selectedBonds, setSelectedBonds] = useState<SearchSecurity[]>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(-1);
  const [totalPageNumber, setTotalPageNumber] = useState<number>(-1);
  const [filterTerm, setFilterTerm] = useState<string>("");
  const maxItemByPage = 10;
  const maxTotalPage = 200;

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value.toLowerCase();
    // get filtered data by name and date
    const filteredBondsTmp = bonds?.securities?.filter((data) => {
      return (
        data?.name?.toLowerCase()?.includes(searchString) ||
        data?.isinCode?.toLowerCase()?.includes(searchString) ||
        formatDate(data?.marketData?.lastPrice?.date).includes(
          event.target.value
        )
      );
    });

    setFilterTerm(searchString);
    setFilteredBonds(filteredBondsTmp);
    setCurrentPageNumber(0);
  };

  const onPaginationUpdate = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
  };

  const initBonds = async () => {
    let bondsTmp = null;
    try {
      setLoading(true);

      const results: any = await client.query({
        query: GET_SEARCH_SECURITIES_ONLY_RESULTS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: 1000,
          sort: "listingDate,desc",
          statuses: ["ADMI", "NEGO", "COTE"],
          statusesOperator: "OR",
          excludeRetr: true,
          tags: tags,
          tagsOperator: "OR",
        },
      });

      bondsTmp = results?.data?.luxseSecuritiesSearch || null;
    } catch (e) {
      console.error("Failed to get bonds", e);
      bondsTmp = null;
    }

    setBonds(bondsTmp);
    setFilteredBonds(bondsTmp?.securities);
    setCurrentPageNumber(0);
  };

  const buildPage = () => {
    if (bonds != null) {
      setLoading(true);

      const endIndex = (currentPageNumber + 1) * maxItemByPage;
      const startIndex = endIndex - maxItemByPage;

      const selectedBondsTmp = filteredBonds?.slice(startIndex, endIndex);
      setSelectedBonds(selectedBondsTmp || []);

      let nbPage = 0;
      if (filteredBonds?.length > maxItemByPage)
        nbPage =
          Math.trunc(filteredBonds?.length / maxItemByPage) +
          (filteredBonds?.length % maxItemByPage > 0 ? 1 : 0);
      if (nbPage > maxTotalPage) nbPage = maxTotalPage;
      setTotalPageNumber(nbPage);

      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (!bonds) initBonds();

    buildPage();
  }, [currentPageNumber, filterTerm]);

  return (
    <>
      <div css={style.container}>
        <div css={style.filterContainer}>
          <span css={style.textPosition}>Filter:</span>
          <div css={style.inputStyle}>
            <InputField
              helperText=""
              onChange={inputChangeHandler}
              placeholder="ISIN, Date, name"
              textError=""
              type="text"
              width="100%"
              disabled={loading}
            />
          </div>
        </div>

        <Loader loading={loading}>
          <div css={[style.resultContainer, { marginTop: "-10px" }]}>
            <p css={style.textPosition}>
              <span css={style.resultText}>{filteredBonds?.length || "0"}</span>
              <span>{` ${label}`}</span>
            </p>
          </div>
          <div css={genericStyle.desktopDisplay}>
            <div css={style.container2}>
              <ChinaBondsTable bonds={selectedBonds} />
            </div>
          </div>
          <div css={genericStyle.mobileDisplay}>
            <div css={{ padding: "2em", width: "100%" }}>
              {selectedBonds?.map((security) => (
                <SecuritySearchCard
                  key={`card-security-${security?.id}`}
                  card={security}
                />
              ))}
            </div>
          </div>
          {totalPageNumber > 0 && (
            <div css={style.pagination}>
              <Pagination
                initialPage={currentPageNumber}
                onPageChange={(d: any) => {
                  onPaginationUpdate(d.selected);
                }}
                total={totalPageNumber}
              />
            </div>
          )}
        </Loader>
      </div>
    </>
  );
}
