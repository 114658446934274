/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import hexa from "./hexaLarge.svg";
import { renderLink } from "../../utils/helper";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from "../../interfaces";
import { canUseDOM } from "../../predicates";
import { TimeToReadIndicator } from "../../core/components/TimeToReadIndicator";
import { breakpointMax } from "../../style";
import Select from "react-select";
import { getArticles } from "../../utils/blog";

export interface BlogHomeComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;

  a {
    text-decoration: none;
  }

  @media (max-width: ${breakpointMax}) {
    padding-bottom: 0;
    padding-top: 0;
  }
`;
const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding-top: 64px;
`;

const blocPart = css`
  width: 100%;
  max-width: 1220px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const blocPartHidden = css`
  width: 100%;
  max-width: 1220px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const blocPartLarge = css`
  width: 100%;
  background: url(${hexa});
  background-repeat: no-repeat;
  background-size: 100%;
  @media (max-width: ${breakpointMax}) {
    background: none;
  }
`;
const title = css`
  width: 100%;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
  color: #253845;
  text-align: center;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 32px;
    line-height: 41px;
    text-align: center;
    color: #253845;
  }
`;
const description = css`
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #425563;
  margin-top: 8px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #425563;
  }
`;
const categoriesPart = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 40px;
  justify-content: space-between;
  text-decoration: none;
  margin-left: auto;
  align-items: center;
  position: relative;
  margin-right: auto;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const categoriesPartLinks = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: flex-start;
  text-decoration: none;
  flex-wrap: wrap;
`;
const inputStyle = css`
  input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 189px;
    height: 40px;
    padding-left: 10px;
    padding-right: 30px;
    background: #ffffff;
    border: 1px solid #d9dee2;
    border-radius: 4px;
    position: relative;

    &:focus {
      outline: 1px solid #22aa5f;
    }
  }

  @media (max-width: ${breakpointMax}) {
  }
`;
const inputStyleIcon = css`
  position: absolute;
  right: 12px;
  margin-top: -27px;
  cursor: pointer;
  color: #22aa5f;
`;
const linkStyle = css`
  display: flex;
  width: 100%;

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const blocPartCols = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1220px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &:hover {
    .main-image {
      opacity: 0.6;
    }

    .main-title {
      color: #5e798d;
    }
  }
`;
const categoryBloc = css`
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #425563;

  &:hover {
    color: #5e798d;
  }
`;
const categoryBlocSearch = css`
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #22aa5f;
`;
const half = css`
  width: 50%;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .main-title {
      color: #5e798d;
    }
  }

  @media (max-width: ${breakpointMax}) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
const halfImage = (image?: string) => css`
  width: 100%;
  background: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  //height: 100%;
  min-height: 400px;
  max-height: 500px;
  background-position: center center;

  &:hover {
    opacity: 0.6;
  }

  transition: all 150ms ease-in-out;
  @media (max-width: ${breakpointMax}) {
    min-height: 300px;
    background-position: center;
  }
`;
const halfLeft = css`
  width: 100%;
  padding-left: 80px;
  @media (max-width: ${breakpointMax}) {
    padding-left: 0px;
  }
`;
const halfHeading = css`
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-decoration: none;
  position: relative;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #425563;
    margin-top: 40px;
  }
`;

const halfTitle = css`
  width: 100%;
  font-weight: 800;
  font-size: 36px;
  line-height: 46px;
  color: #425563;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    color: #425563;
  }

  &:hover {
    color: #5e798d;
  }

  transition: all 150ms ease-in-out;
`;

const halfTitleCenter = css`
  width: 100%;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: #425563;
  margin-bottom: 30px;

  text-align: center;
`;

const halfDescription = css`
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #425563;
  margin-bottom: 16px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #425563;
  }
`;
const mobileCategoriesPart = css`
  display: none;
  @media (max-width: ${breakpointMax}) {
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    margin-top: 64px;

    > div {
      width: 100%;
    }
  }
`;
const categoryTags = css`
  width: 100%;
  display: flex;
  gap: 16px;
  max-width: 1220px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  flex-wrap: wrap;
`;

const categoryTag = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  border: 1px solid #22aa5f;
  color: #22aa5f;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background: #22aa5f;
    border: 1px solid #22aa5f;
    color: #ffffff;
  }

  transition: all 150ms ease-in-out;
`;

const tempBanned = ["bdl-port-luxse-ssr", "Project"];

export const BlogHomeComponent: React.FunctionComponent<BlogHomeComponentProps> =
  (props: BlogHomeComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const [categories, setCategories] = useState<string[]>([]);
    const [article, setArticle] = useState<any>(null);
    const [searchInput, setSearchInput] = useState<string>("");
    const id = fields["linked article"]?.id;
    const displayTags: boolean = getFieldValue<boolean>(
      fields,
      "display tags container",
      false
    );

    const image =
      fields["linked article"] &&
      fields["linked article"].fields &&
      fields["linked article"].fields["main image"] &&
      fields["linked article"].fields["main image"].value;

    useEffect(() => {
      const data: any = fields?.["linked article"]?.fields;
      setArticle({
        title: data?.title?.value || "",
        author: data?.author,
        short: data?.["short description"]?.value || "",
        creationDate: data?.["Creation Date"]?.value || "",
        content: data?.content?.value || "",
        url: fields?.["linked article"]?.url
          ?.split("/")
          .slice(0, 4)
          .join("/") /*?.toLowerCase()*/,
      });
      getArticles().then((data: any) => {
        //console.clear();
        setCategories(data?.tags);
      });
    }, [id]);

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={blocPart}>
            <div css={title}>
              <Text
                field={fields && (fields.title as SitecoreGenericTextField)}
              />
            </div>
            <div css={description}>
              <Text
                field={
                  fields && (fields.description as SitecoreGenericTextField)
                }
              />
            </div>
            <div css={categoriesPart}>
              <div css={categoriesPartLinks}>
                {fields?.["displayed categories"]?.map(
                  (cat: any, id: number) => (
                    <a href={cat.url} css={categoryBloc} key={`id-${id}`}>
                      {cat?.name}
                    </a>
                  )
                )}
              </div>
              <div css={inputStyle}>
                <input
                  type="text"
                  placeholder="Search in blog"
                  value={searchInput}
                  onChange={(e: any) => setSearchInput(e?.target?.value)}
                  onKeyDown={(e) => {
                    if (e?.key === "Enter") {
                      canUseDOM &&
                        window.open(
                          `/blog/search${
                            searchInput ? `?q=${searchInput}` : ""
                          }`,
                          "_self"
                        );
                    }
                  }}
                />
                <div
                  css={inputStyleIcon}
                  onClick={() =>
                    canUseDOM &&
                    window.open(
                      `/blog/search${searchInput ? `?q=${searchInput}` : ""}`,
                      "_self"
                    )
                  }
                >
                  <Icon icon={"search"} />
                </div>
              </div>
              {/*<a href={'/blog/search'} css={categoryBlocSearch} key={`id-${id}`}>
                            search <Icon icon={'search'} />
                        </a>*/}
            </div>
          </div>
          <div css={mobileCategoriesPart}>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "#22AA60" : "#b3b3b3",
                  height: 52,
                  boxShadow: state.isFocused ? "0 0 0 1px #22AA60" : "none",
                  "&:hover": {
                    borderColor: "#22AA60",
                    boxShadow: "0 0 0 1px #22AA60",
                  },
                  "&:focus": {
                    borderColor: "#22AA60",
                    boxShadow: "0 0 0 1px #22AA60",
                  },
                }),
                indicatorSeparator: (baseStyles) => ({
                  ...baseStyles,
                  display: "none",
                }),
                dropdownIndicator: (baseStyles) => ({
                  ...baseStyles,
                  color: "#22AA60",
                  "&:hover": {
                    color: "#22AA60",
                  },
                }),
              }}
              isClearable={false}
              isSearchable={false}
              options={(fields?.["displayed categories"] || []).map(
                (cat: any) => ({
                  label: cat.name,
                  value: cat.url,
                })
              )}
              onChange={(d: any) => {
                canUseDOM && window.open(`${d.value}`, "_self");
              }}
            />
          </div>
          <div css={blocPartLarge}>
            <div css={blocPartCols}>
              <a href={article?.url} css={linkStyle}>
                <div css={half}>
                  <div
                    className={"main-image"}
                    css={halfImage(renderLink(image?.src))}
                  />
                </div>
                <div css={half}>
                  {article && (
                    <div css={halfLeft}>
                      <div css={halfHeading}>
                        {(
                          (fields["linked article"] &&
                            fields["linked article"].fields &&
                            fields["linked article"].fields.Categories) ||
                          []
                        )
                          .map((article: any) => article?.name)
                          .map((cat: string, index: number) => (
                            <span key={`span-index-${index}`}>{`${cat} ${
                              index <
                              fields["linked article"].fields.Categories
                                .length -
                                1
                                ? "/ "
                                : ""
                            }`}</span>
                          ))}
                        {article.title}
                      </div>
                      <a href={article?.url /*?.toLowerCase()*/}>
                        <div css={halfTitle} className={"main-title"}>
                          <div
                            dangerouslySetInnerHTML={{ __html: article.title }}
                          />
                        </div>
                      </a>
                      <div css={halfDescription}>
                        <div
                          dangerouslySetInnerHTML={{ __html: article.short }}
                        />
                      </div>
                      {article && (
                        <TimeToReadIndicator 
                        data={article?.content} 
                        date={article?.creationDate}
                        />
                      )}
                    </div>
                  )}
                </div>
              </a>
            </div>
          </div>
          {displayTags && (
            <div css={blocPartHidden}>
              <div css={halfTitleCenter}>
                <Text
                  field={
                    fields &&
                    (fields[
                      "quick access to categories title"
                    ] as SitecoreGenericTextField)
                  }
                />
              </div>
              <div css={categoryTags}>
                {categories
                  ?.filter((cat: any) => !tempBanned.includes(cat.label))
                  .map((cat: any, id: number) => (
                    <a
                      href={`${
                        canUseDOM && window.location.pathname
                      }/search?tag=${cat?.label}`}
                      css={categoryTag}
                      key={`id-${id}`}
                    >
                      {cat?.label}
                    </a>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
