import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from '../SecurityOverview/securityOverview.style';
import { GreenDoc } from '../../../../interfaces/green-card';
import { documentGreenTypeLabel } from '../../../../enums/document-green-type';
import { formatDate } from '../../../../utils/date';

const greenDocLabels: any = {
  ISSUER_DOCUMENTATION: {
    label: 'Use of Proceeds',
    items: {
      FRAMEWORK: { code: 'F', label: 'Framework' },
      FRAMEWORK_ANNEX: { code: 'FA', label: 'Framework annex' },
      INVESTOR_PRESENTATION: { code: 'IP', label: 'Investor presentation' },
      NEWS: { code: 'N', label: 'News' },
    },
  },
  PRE_ISSUANCE_EXTERNAL_REVIEW: {
    label: 'External Review',
    items: {
      OPINION: { code: 'SO', label: 'Second Opinion' },
    },
  },
  POST_ISSUANCE_REPORTING: {
    label: 'Post-issuance',
    items: {
      DEDICATED: { code: 'DR', label: 'Dedicated report' },
      REPORTING: { code: 'R', label: 'Reporting methodology' },
      ANNUAL: { code: 'A', label: 'Annual report' },
      SUSTAINABILITY: { code: 'SR', label: 'Sustainability Report' },
      NEWS: { code: 'N', label: 'News' },
      OTHER_REPORT_TYPE: { code: 'SR', label: 'Other' },
      VERIFICATION: { code: 'V', label: 'Verification' },
      CERTIFICATION: { code: 'C', label: 'Certification' },
    },
  },
};

const SecuritySustainableDoc = ({ doc }: { doc: GreenDoc }) => {
  if (!doc || !doc.type) return <></>;

  let label = documentGreenTypeLabel(doc.type);

  /*
  let code = doc.type.substr(0, 1);
  const section = greenDocLabels[doc.section];
  if (section && section.items) {
    const type = section.items[doc.type];
    if (type) {
      code = type.code;
      label = type.label;
    }
  }
  */

  return (
    <div css={style.blocRow}>
      <div css={[style.centerPart, { paddingLeft: '10px' }]}>
        <div css={style.rightPartMid}>{label}</div>
        <div css={style.rightPartBottom}>{doc.refUtcDt ? formatDate(doc.refUtcDt, 'DD/MM/YYYY') : 'N/A'}</div>
      </div>
    </div>
  );
};

export default SecuritySustainableDoc;
