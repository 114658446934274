
/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import * as React from 'react';
import { InputField, MenuWithChild, MenuWithChildProps } from "@bdl-cmn-shared-packages-npm/design-system";
import inputStyle from "../input.style";
import { useDebounce } from "use-debounce/lib";

export interface FromToValue {
  from: number | null;
  to: number | null;
}

export interface FromToValueProps {
  value: FromToValue;
  onValueChange: (values: FromToValue) => void;
}
export type FromToFilterProps = MenuWithChildProps & FromToValueProps

export function FromToFilter(props: FromToFilterProps) {
  const {
    value,
    onValueChange,
    ...passThrough
  } = props;

  const [from, setFrom] = React.useState<number>(value.from);
  const [to, setTo] = React.useState<number>(value.to);

  const [debouncedFrom] = useDebounce(from, 300);
  const [debouncedTo] = useDebounce(to, 300);

  React.useEffect(() => {
    setFrom(value.from);
    setTo(value.to);
  }, [value]);

  React.useEffect(() => {
    if (debouncedFrom !== value.from || debouncedTo !== value.to) {
      onValueChange({ from: debouncedFrom, to: debouncedTo });
    }
  }, [debouncedFrom, debouncedTo, value, onValueChange]);

  const style = {
    filter: css`
      display: flex;
      flex-direction: row;

      @media (max-width: 720px) {
        flex-direction: column;
      }
    `,
    leftFilter: css`
      width: 45%;
      margin-right: 2.5%;

      @media (max-width: 720px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
      }
    `,

    rightFilter: css`
      width: 45%;
      margin-left: 2.5%;

      @media (max-width: 720px) {
        width: 100%;
        margin-left: 0;
      }
    `,
  }

  return (
    <MenuWithChild
      width="100%;"
      expanded={false}
      arrows={false}
      {...passThrough}>
      <div css={style.filter}>
        <div css={[inputStyle.inputWrapper, style.leftFilter]}>
          <InputField
            type="number"
            placeholder="From"
            onChange={(e: any) => setFrom(Number.parseFloat(e.target.value) || null)}
            value={from === null ? "" : from.toString()}
          />
        </div>

        <div css={[inputStyle.inputWrapper, style.rightFilter]}>
          <InputField
            type="number"
            placeholder="To"
            onChange={(e: any) => setTo(Number.parseFloat(e.target.value) || null)}
            value={to === null ? "" : to.toString()}
          />
        </div>
      </div>
    </MenuWithChild>
  );
}