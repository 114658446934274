import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from './index.style';
import RoundTag from '../../../RoundTag/roundTag.component';
import ConceptualBanner from '../../../ConceptualBanner/conceptualBanner.component';
import PriceColumn, { PriceColumnProps } from '../../../UI/list/security/price';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { IndiceSummary } from '../../../../../interfaces/indice';

interface IndexCardProps {
  indice: IndiceSummary;
  priceColumn?: PriceColumnProps;
}

export function IndexBanner({indice, priceColumn}: IndexCardProps) {
  if( !indice )
    return <></>;

  let isGreen = indice.green && indice.green > 0;

  return (
    <ConceptualBanner
      renderLeft={
        <div css={style.bannerContainer}>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              > div {
                min-height: 40px;
                min-width: 40px;
                font-size: 28px !important;
                color: #ffffff;
                background-color: ${isGreen ? 'rgb(0 140 61 / 15%)' : 'rgb(203 18 52 / 15%)'};
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            `}
          >
            <RoundTag green={isGreen} borderSize={2} size={14}>
              <Icon icon={'chart-line'}></Icon>
            </RoundTag>
          </div>
          <h1 css={style.info}>
            <span css={style.securityNameText}>{indice?.indexName}</span>
            <span css={style.subtitle}>
              <span css={style.isin}>{indice?.isin}</span>
            </span>
          </h1>
        </div>
      }
      renderRight={() => (
        <div css={style.lastPrice}>
          {indice?.indexPrice?.lastPrice && <PriceColumn {...priceColumn} color={'white'} />}
        </div>
      )}
    ></ConceptualBanner>
  );
};
