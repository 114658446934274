/* eslint-disable */
import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useContext, useState, useEffect } from "react";
import { luxseGatewayGraphqlClient } from "../../../../../luxse-gateway-graphql-client";
import { Security } from "../../../../../interfaces";
import { GET_LUXXPRIME_MOST_VIEWED_QUERY } from "../../../../../graphql/queries/luxxprime.queries";
import Loader from "../../../Loader/loader.component";
import { genericStyle } from "../../../UI/generic/generic.style";
import { SearchTable } from "../../../UI/list/base";
import SecurityLuxXPrimeHeader from "../../../UI/list/luxXPrime/header";
import { SecurityLuxXPrimeRow } from "../../../UI/list/luxXPrime/row";
import { SearchMobile } from "../../../UI/list/base/index-mobile";
import SecuritySearchCard from "../../../UI/list/security/card-mobile";
import EmptyResults from "../../../UI/list/base/empty";
import { style } from "../../index.style";
import Utils from "../../../../utils/utils";

export const LuxXPrimeMostViewListView = () => {
  const client = luxseGatewayGraphqlClient;
  const limit = 10;

  const [data, setData] = useState<Security[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const getData = async () => {
    try {
      let results: any = await client.query({
        query: GET_LUXXPRIME_MOST_VIEWED_QUERY,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          limit: limit,
        },
      });

      setDataLoading(false);

      if (results?.data?.mostViewedLuxXPrimeSecurities) {
        let tmpData: Security[] = [];
        results.data.mostViewedLuxXPrimeSecurities.forEach((element: any) => {
          tmpData.push(element);
        });

        setData(tmpData);
      }
    } catch (e) {
      console.error("Failed to get top traded data", e);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div css={style.container}>
      <div css={style.title}>
        <Icon icon={"binoculars"} /> Most viewed securities
      </div>
      <div css={style.content}>
        <Loader loading={dataLoading}>
          {data && data?.length > 0 ? (
            <>
              <div css={[genericStyle.desktopDisplay, style.contentBody]}>
                <SearchTable
                  data={data}
                  density={"medium"}
                  onPaginationUpdate={() => {}}
                  pageNumber={0}
                  totalResultSize={limit}
                  Header={() => <SecurityLuxXPrimeHeader selectedCols={null} />}
                  Row={(row) => (
                    <SecurityLuxXPrimeRow
                      row={row}
                      key={`card-most-viewed-${row?.id}`}
                    />
                  )}
                />
              </div>
              <div css={genericStyle.mobileDisplay}>
                <SearchMobile
                  onPaginationUpdate={() => {}}
                  pageNumber={0}
                  data={data}
                  totalResultSize={limit}
                  Card={(card) => (
                    <SecuritySearchCard
                      key={`card-most-viewed-m-${card?.id}`}
                      card={card}
                    />
                  )}
                />
              </div>
            </>
          ) : (
            <div css={style.emptyResult}>
              <EmptyResults />
            </div>
          )}
        </Loader>
      </div>
    </div>
  );
};
