
import 'react-day-picker/lib/style.css';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import moment from "moment-timezone";
import DayPicker, { Modifier, RangeModifier } from 'react-day-picker';
import { baseDateFormat } from '../../constants';
import styles from "./stylesheet";
import { InputField } from '@bdl-cmn-shared-packages-npm/design-system';
import { formatDate, parseToMoment } from '../../../utils/date';

export type CalendarPosition = 'top' | 'bottom';

export interface InputDateRangePickerProps {
    fromDate: moment.Moment | null;
    toDate: moment.Moment | null;
    placeholder?: string;
    active?: boolean;
    calendarPosition?: CalendarPosition;
    selectorToRight?: boolean;
    authorizeFuture?: boolean;
    onSelect?: (fromDate: moment.Moment | null, toDate: moment.Moment | null) => void;
    style?: React.CSSProperties;
}

export interface InputDateRangePickerState {
    isDatePickerOpen: boolean;
}

export function InputDateRangePicker(props: InputDateRangePickerProps) {
    const {
        fromDate,
        toDate,
        placeholder,
        calendarPosition,
        authorizeFuture,
        onSelect,
    } = props;

    const [isDatePickerOpen, setIsDatePickerOpen] = React.useState<boolean>(false);

    const inputRef = React.useRef<HTMLInputElement>(null);
    const calendarsRef = React.useRef<HTMLDivElement>(null);

    const onDayClick = React.useCallback((day: Date): void => {
        const { from, to } = DayPicker.DateUtils.addDayToRange(day, { from: fromDate ? fromDate.toDate() : undefined, to: toDate ? toDate.toDate() : undefined } as RangeModifier);

        if (!!onSelect) {
            onSelect(
                from ? parseToMoment(from).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : null,
                to ? parseToMoment(to).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : null
            );
        }
    }, [onSelect, fromDate, toDate]);

    React.useEffect(() => {
        const onOutsideClick = (e: MouseEvent): void => {
            const predicate: boolean = (
                isDatePickerOpen &&
                inputRef.current !== null &&
                calendarsRef.current !== null &&
                !inputRef.current.contains(e.target as Node) &&
                !calendarsRef.current.contains(e.target as Node)
            );

            if (predicate) {
                setIsDatePickerOpen(false);
            }
        };

        document.addEventListener('click', onOutsideClick, false);

        return function cleanup() {
            document.removeEventListener('click', onOutsideClick, false);
        }
    }, [isDatePickerOpen]);

    const calPos: CalendarPosition = calendarPosition || 'bottom';

    return (
        <div
            ref={inputRef}
            css={styles.container}
        >
            <div
                css={styles.inputWrapper}
                onClick={(): void => {
                    setIsDatePickerOpen((isDatePickerOpen) => {
                        return !isDatePickerOpen;
                    });
                }}
            >
                <InputField value={fromDate && toDate ? `${formatDate(fromDate, baseDateFormat)} - ${formatDate(toDate, baseDateFormat)}` : placeholder || 'Pick a date range'} />
            </div>
            {
                isDatePickerOpen ? (
                    <div
                        css={styles.datePicker}
                    >
                        <div>
                            <div className='input-date-range-picker-box__calendars' ref={calendarsRef}>
                                <DayPicker
                                    className='Selectable'
                                    numberOfMonths={2}
                                    selectedDays={[fromDate ? fromDate.toDate() : undefined, { from: fromDate ? fromDate.toDate() : undefined, to: toDate ? toDate.toDate() : undefined } as Modifier]}
                                    modifiers={{ start: fromDate ? fromDate.toDate() : undefined, end: toDate ? toDate.toDate() : undefined }}
                                    toMonth={!authorizeFuture ? new Date() : undefined}
                                    onDayClick={onDayClick}
                                />
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    );
}