import * as React from "react";
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";

export interface SearchComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const SearchComponent: React.FunctionComponent<SearchComponentProps> = (
  props: SearchComponentProps
): React.ReactElement => {
  const { fields } = props;

  const headingValue: string = getFieldValue<string>(fields, "heading", "");

  return (
    <div className="SearchComponent-box">
      <p>SearchComponent Component</p>
      <Text field={fields && fields.heading as SitecoreGenericTextField} />
      <span>Raw value: {headingValue}</span>
    </div>
  );
};
