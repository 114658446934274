import 'isomorphic-fetch';

import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject, InMemoryCache } from 'apollo-cache-inmemory';

import { split, Operation } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';

let _isSSR: boolean = false;

export const setLuxSEGatewayGraphQLClientIsSSR = (isSSR: boolean): void => {
    _isSSR = isSSR;
}

export const LuxSEGatewayGraphQLClientFactory = (endpoint: string, headers?: any): ApolloClient<NormalizedCacheObject> => {
    const httpLink: HttpLink = new HttpLink({
        uri: endpoint,
        headers,
        credentials: 'include'
    });

    const bathHttpLink: BatchHttpLink = new BatchHttpLink({
        uri: endpoint,
        headers,
        credentials: 'include'
    });

    return new ApolloClient({
        ssrMode: _isSSR,
        ssrForceFetchDelay: 100,
        link: createPersistedQueryLink().concat(split(
            (operation: Operation): boolean => operation.getContext().mustBatchQueries === true,
            bathHttpLink,
            httpLink
        )),
        cache: new InMemoryCache()
    });
}