
/*
*   e-Listing - core - components - country flag icon - est
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const EST: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.337' width='512' height='341.326'/>
        <rect y='85.337' style={{fill: '#0052B4'}} width='512' height='113.775'/>
        <rect y='312.888' style={{fill: '#F0F0F0'}} width='512' height='113.775'/>
    </svg>
);
