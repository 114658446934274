import * as React from "react";

import {
  Route,
  Switch,
  BrowserRouterProps,
  RouteComponentProps,
} from "react-router-dom";
import { StaticRouterProps } from "react-router";
import { ApolloProvider } from "react-apollo";

import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";

import { SitecoreContext } from "@sitecore-jss/sitecore-jss-react";

import componentFactory from "../temp/componentFactory";

import { routePatterns } from "../routes";
import RouteHandler from "../routes/handler";
import { SitecoreRouteMatchParams } from "../interfaces";
import { App } from "../core/app";
import {useEffect, useState} from "react";
import {TradingDataView} from "../core/components/DataMarket/TradingData/trading-data-view.component";
import {OAMSearchContent} from "../core/components/OAMSearch";
import {DashboardTradingDataView} from "../core/components/DataMarket/TradingData/dashbord-trading-data-view.component";

export interface RootProps {
  ssrState?: any;
  path: string;
  Router:
  | React.ComponentClass<BrowserRouterProps, any>
  | React.ComponentClass<StaticRouterProps, any>;
  graphQLClient: ApolloClient<NormalizedCacheObject>;
}

export const Root: React.FunctionComponent<RootProps> = ({
  path,
  Router,
  graphQLClient,
    ssrState,
}: RootProps): JSX.Element => {
  const [ssrRenderComplete, setSsrRenderComplete ] = useState<boolean>(false);
  let sitecoreContext = null;
  if(ssrState){
    sitecoreContext = ssrState.sitecore && ssrState.sitecore.route
        ? {
          route: ssrState.sitecore.route,
          itemId: ssrState.sitecore.route.itemId,
          ...ssrState.sitecore.context,
        }
        : ssrState.sitecore.context;
  }

  useEffect(() => {
    setSsrRenderComplete(true);
  }, []);
  // const reload = () => canUseDOM && window.location.reload();
  return (
    <ApolloProvider client={graphQLClient}>
      <SitecoreContext
        componentFactory={(componentFactory as any)}
        context={sitecoreContext}
      >
        {/*<Route path="/white-labeling.html" onEnter={reload} />*/}
        <Router location={path} context={{}}>
          <App>
            <Switch>
              <Route
                key="search"
                path="/search/:query"
                render={(
                  props: RouteComponentProps<SitecoreRouteMatchParams>
                ): React.ReactNode => {
                  const securityRouteProps = { ...props };

                  securityRouteProps.match.params.sitecoreRoute = "/search";

                  return (
                    <App>
                      <RouteHandler route={securityRouteProps} />
                    </App>
                  );
                }}
              />
              <Route
                  key="trading-data"
                  path="/trading-data"
                  component={TradingDataView}
              />
              <Route
                  key="dashboard-trading-data"
                  path="/dashboard-trading-data"
                  component={DashboardTradingDataView}
              />
              <Route
                  key="oam-search-details"
                  path="/oam-search-details"
                  component={OAMSearchContent}
              />
              <Route
                key="oam-search"
                path="/oam-search"
                render={(
                  props: RouteComponentProps<SitecoreRouteMatchParams>
                ): React.ReactNode => {
                  const oamSearch = { ...props };

                  oamSearch.match.params.sitecoreRoute = "/oam-search";
                  return (
                    <App>
                      <RouteHandler route={oamSearch} />
                    </App>
                  );
                }}
              />
              {routePatterns.map(
                (pattern: string, index: number): React.ReactNode => (
                  <Route
                    key={`sitecore-route-${pattern}-${index}`}
                    path={pattern}
                    render={(
                      props: RouteComponentProps<any>
                    ): React.ReactNode => <RouteHandler route={props} />}
                  />
                )
              )}
            </Switch>
          </App>
        </Router>
      </SitecoreContext>
    </ApolloProvider>
  );
};
