import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
    Text,
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import { style } from "./index.style";
import { Icon, Button } from "@bdl-cmn-shared-packages-npm/design-system";
import {TradingItemCard} from "../TradingItemCard";

export interface TrustingUsPartnersProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const TrustingUsPartners: React.FunctionComponent<TrustingUsPartnersProps> =
    (props: TrustingUsPartnersProps): React.ReactElement => {
        const { fields, params, rendering } = props;
        const buttonText: string = getFieldValue<string>(fields, 'buttonLabel', '');
        const buttonLink: string = getFieldValue<string>(fields, 'buttonLink', '');


        return (
            <div css={style.container}>
                <div css={style.title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={style.partners}>
                    <div css={style.partner}>
                        {
                            new Array(10).fill(0).map((arr: any, index: number) => (
                                <div css={style.partnerLogo} key={`issuer-${index}`}><Icon icon={'leaf'} /> Issuer</div>
                            ))
                        }
                    </div>
                </div>
                <div css={style.actions}>
                    <Button label={buttonText} onClick={() => buttonLink ? window.location.href = buttonLink : console.log} variant={"thirdary"} />
                </div>
            </div>
        );
    };
