import React from "react";

/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { PriceInformation, Security } from "../../../../../interfaces";
import {
  InputDateRangePicker,
  InputDateRangePickerProps,
} from "../../../input-date-range-picker";
import {
  Tab,
  Tabs,
  ToggleButtonGroup,
  ToggleItem,
} from "@bdl-cmn-shared-packages-npm/design-system";
import style from "./prices-history.style";
import { BaseOptions } from "./base-options";
import ReactApexChart from "react-apexcharts";
import { InstrumentType, instrumentTypeCheck } from "../../../../../enums";
import { getCurrentMoment, parseToMoment } from "../../../../../utils/date";

export type GraphOptions = typeof BaseOptions;

const YEAR_DATE_FORMAT = "yyyy";
const MONTH_DATE_FORMAT = "MMM yyyy";
const DAY_DATE_FORMAT = "dd MMM yyyy";

const NB_MS_IN_DAY = 1000 * 3600 * 24;
const NB_MS_IN_MONTH = NB_MS_IN_DAY * 30;
const NB_MS_IN_YEAR = NB_MS_IN_DAY * 364;

function computeFormatFromRange({ fromDate, toDate }: RangeDate) {
  if (!(fromDate && toDate)) {
    return YEAR_DATE_FORMAT;
  }
  const timeBetween = Math.abs(fromDate.diff(toDate));

  if (timeBetween >= NB_MS_IN_YEAR * 2) {
    return YEAR_DATE_FORMAT;
  } else if (timeBetween >= NB_MS_IN_MONTH * 5) {
    return MONTH_DATE_FORMAT;
  }
  return DAY_DATE_FORMAT;
}

const startOfDay = () => getCurrentMoment().startOf("day");
const endOfDay = () => getCurrentMoment().endOf("day");

export interface PriceHistoryProps {
  pricesHistory?: PriceInformation[];
  security?: Security;
}

type RangeDate = Pick<InputDateRangePickerProps, "fromDate" | "toDate">;

function areDateRangesEqual(first: RangeDate, second: RangeDate): boolean {
  return (
    (first.fromDate === second.fromDate && first.toDate === second.toDate) || // especially useful  when there are null dates
    (first.fromDate?.isSame(second.fromDate) &&
      first.toDate?.isSame(second.toDate))
  );
}

export default function ({ pricesHistory, security }: PriceHistoryProps) {
  const [dateRange, setDateRange] = React.useState<RangeDate>(() => ({
    fromDate: startOfDay().subtract(1, "year"),
    toDate: endOfDay(),
  }));

  const [graphDisplayType, setGraphDisplayType] = React.useState<
    "price" | "yield"
  >("price");

  const defaultHistoryData = (): GraphOptions => {
    const graphOptions: GraphOptions = BaseOptions;

    graphOptions.series = [
      {
        name: "",
        data: [],
      },
    ];

    return graphOptions;
  };

  const rangeSelections = React.useMemo(() => {
    const start = startOfDay();
    const end = endOfDay();

    return [
      {
        label: "5D",
        dateRange: {
          fromDate: start.clone().subtract(5, "days"),
          toDate: end.clone(),
        },
      },
      {
        label: "1M",
        dateRange: {
          fromDate: start.clone().subtract(1, "month"),
          toDate: end.clone(),
        },
      },
      {
        label: "6M",
        dateRange: {
          fromDate: start.clone().subtract(6, "months"),
          toDate: end.clone(),
        },
      },
      {
        label: "1Y",
        dateRange: {
          fromDate: start.clone().subtract(1, "year"),
          toDate: end.clone(),
        },
      },
      {
        label: "MAX",
        dateRange: {
          fromDate: null,
          toDate: null,
        },
      },
    ];
  }, []);

  const displayedPriceHistory = React.useMemo(() => {
    const historyData = defaultHistoryData();

    const format = computeFormatFromRange(dateRange);

    const { fromDate, toDate } = dateRange;
    historyData.options.xaxis.labels.format = format;

    if (!pricesHistory) {
      return historyData;
    }

    const categories: any[] = [];
    const serieData: any[] = [];

    pricesHistory.forEach((data) => {
      const date = parseToMoment(data.date);
      if (
        !(fromDate && toDate) ||
        (date.isAfter(fromDate) && date.isBefore(toDate))
      ) {
        const value =
          graphDisplayType === "price" ? data.amount : data.yieldToMaturity;

        if (value != null && value != undefined) {
          categories.push(date.toISOString());
          serieData.push([date.toDate(), value]);
        }
      }
    });

    historyData.options.xaxis.categories = categories;
    historyData.series = [
      {
        name: graphDisplayType === "price" ? "Price" : "Yield",
        data: serieData,
      },
    ];

    return historyData;
  }, [pricesHistory, graphDisplayType, dateRange]);

  return (
    <div>
      <div css={style.displayChooser}>
        <div css={style.displayChooserDiv}>
          <span css={style.displayChooserText}>Display:</span>
          <ToggleButtonGroup
            onChange={(elem: any) => setGraphDisplayType(elem)}
          >
            <ToggleItem selected={graphDisplayType === "price"} value="price">
              Price
            </ToggleItem>
            {!instrumentTypeCheck(
              security?.instrumentType?.toString(),
              InstrumentType.ACT
            ) ? (
              <ToggleItem selected={graphDisplayType === "yield"} value="yield">
                Yield
              </ToggleItem>
            ) : (
              <></>
            )}
          </ToggleButtonGroup>
        </div>
      </div>

      <ReactApexChart
        options={displayedPriceHistory.options}
        series={displayedPriceHistory.series}
        type="area"
        height={400}
        width={"100%"}
      />

      <div css={style.selectorContainer}>
        <InputDateRangePicker
          fromDate={dateRange.fromDate}
          toDate={dateRange.toDate}
          onSelect={(fromDate, toDate) => setDateRange({ fromDate, toDate })}
          placeholder="Date selection"
        />

        <div css={style.tabSelector}>
          <Tabs>
            {rangeSelections.map(({ label, dateRange: range }) => (
              <div css={style.tab}>
                <Tab
                  label={label}
                  onTabChange={() => setDateRange(range)}
                  selected={areDateRangesEqual(dateRange, range)}
                />
              </div>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
}
