import * as React from "react";

import {
  Button,
  Col,
  Grid,
  Icon,
  Row,
  Td,
  ToggleButtonGroup,
  ToggleItem,
  Tr,
} from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentParams,
  ComponentRendering,
  Link,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import {
  GET_LATEST_LISTINGS,
  SEARCH_INDEXES_PRICE_WITHOUT_FILTERS,
  SEARCH_LGX_SECURITIES_WITHOUT_FILTERS,
} from "../../graphql";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";

import ChartOptions from "./chart-option-card";
import GraphCard from "../../core/components/GraphCard/graphCard.component";
import { IndiceSummary } from "../../interfaces/indice";
import Loader from "../../core/components/Loader/loader.component";
import { MarketdataModel } from "../../core/components/DataMarket/MarketData/market-data-view.component";
import { Query } from "react-apollo";
import ReactTooltip from "react-tooltip";
import RoundTag from "../../core/components/RoundTag/roundTag.component";
import { SecuritySearchResultDto } from "../../interfaces/search/search";
import axios from "axios";
import { computeCategoryLabel } from "../../core/components/UI/list/security/row";
import { formatDate } from "../../utils/date";
import { formatToThousandsSeparator } from "../../utils";
import { goToSecurityDetailPage } from "../../core/components/SecurityCard";
import { lgxExternalReportingLabel } from "../../enums/lgx-external-reporting";
import { lgxExternalReviewLabel } from "../../enums/lgx-external-review";
import { lgxStandardLabel } from "../../enums/lgx-standard";
import lgxlogo from "./LGX.svg";
import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
import luxxprimeLogo from "./luxXPrime.svg";
import { renderLink, withQueryParam } from "../../utils/helper";
import { sharedStyle } from "../../styles/shared";
import SearchSecurity from "../../interfaces/search/search-security";
import { computeDateValue } from "../../core/components/DataMarket/MarketNews";
import { goToIndexDetailPage } from "../../core/components/Index/Index-details";
import Utils from "../../core/utils/utils";
import { card, cardContainer, cellOverflow, cellStyle } from "./style";
import LuxXPrimeHomeData from "./LuxXPrimeHomeData";

/** @jsx jsx */

export interface HomeDataComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export interface GQLData {
  currency: string;
  id: string;
  instrumentName: string;
  interestRate: string | number;
  isin: string;
  listingDate: string;
  maturityDate: string;
}

export const getNewListingStatusLabel = (listing: any) => {
  if (!listing || !listing.status) return "";

  const report = listing.postponed ? "Report - " : "";
  let label = listing.status;
  try {
    switch (listing.status) {
      case "COTE":
      case "ADMI":
        label = "Listed as of";
        break;
      case "NEGO":
        label = "Traded as of";
        break;
      case "NCOT":
        label =
          "Intended " +
          (listing.tags?.length > 0 && listing?.tags.includes("ATT")
            ? "trading"
            : "listing") +
          " date";
        break;
      case "FONG":
        label = "Fungible";
        break;
      case "PUBL":
        label =
          listing.listingDate == listing.delistingDate
            ? "Fungible"
            : "Intended listing date";
        break;
      default:
        label = listing.status;
        break;
    }
  } catch (e) {
    console.error("Failed to get new listing status of ", listing);
  }

  return report + label;
};

export const HomeDataComponent: React.FunctionComponent<HomeDataComponentProps> =
  (props: HomeDataComponentProps): React.ReactElement => {
    const instance = axios.create();

    const { fields, params, rendering } = props;

    const [lastSecuritiesLgx, setLastSecuritiesLgx] =
      useState<SecuritySearchResultDto>();
    const [indexes, setIndexes] = useState<IndiceSummary[]>([]);
    const [newListings, setNewListings] = useState<MarketdataModel>();

    const [loaderGreen, setLoaderGreen] = useState(true);
    const [loaderIndexes, setLoaderIndexes] = useState(true);
    const [loaderNewListings, setLoaderNewListings] = useState(true);

    const button1Label: string = getFieldValue<string>(
      fields,
      "button 1 label",
      ""
    );

    const button2Label: string = getFieldValue<string>(
      fields,
      "button 2 label",
      ""
    );

    const client = luxseGatewayGraphqlClient;

    const fetchLatestSecuritiesLGX = async () => {
      try {
        const results: any = await client.query({
          query: SEARCH_LGX_SECURITIES_WITHOUT_FILTERS,
          variables: {
            token: Utils.getCookie("Gztoken-Dsm"),
            page: 0,
            size: 10,
            sort: "lgxPubDate,desc",
            statuses: ["ADMI", "NEGO", "COTE"],
            statusesOperator: "OR",
          },
        });

        if (results?.data?.luxseSecuritiesSearch)
          setLastSecuritiesLgx(results.data.luxseSecuritiesSearch);

        setLoaderGreen(false);
      } catch (e) {
        console.error("Failed to get last LGX securities", e);
        setLoaderGreen(false);
      }
    };

    const fetchIndexes = async () => {
      try {
        const result: any = await client.query({
          query: SEARCH_INDEXES_PRICE_WITHOUT_FILTERS,
          variables: {
            token: Utils.getCookie("Gztoken-Dsm"),
            searchTerm: "",
            size: 2,
            page: 0,
            sort: "latestPriceDate,asc",
            ids: ["45", "67"],
            idsOperator: "OR",
          },
        });

        if (result.data?.luxseIndexesSearch?.indexes) {
          setIndexes(result.data.luxseIndexesSearch.indexes);
        }
      } catch (e) {
        console.error("Failed to Get indices", e);
      }

      setLoaderIndexes(false);
    };

    const fetchNewListings = async () => {
      const latestListingsModel: MarketdataModel = new MarketdataModel();
      latestListingsModel.loader = true;
      latestListingsModel.label = "New listings";
      latestListingsModel.startIndex = 0;
      latestListingsModel.endIndex = 9;

      try {
        const dta = await client.query({
          query: GET_LATEST_LISTINGS,
          variables: {
            pageNumber: 0,
            pageSize: 0,
          },
        });

        latestListingsModel.loader = false;

        if (dta?.data?.securitiesLatestListing) {
          latestListingsModel.allData = dta.data.securitiesLatestListing;
          latestListingsModel.totalHits = dta.data.securitiesLatestListing.length;
          latestListingsModel.currentData = dta.data.securitiesLatestListing.slice(
              latestListingsModel.startIndex,
              latestListingsModel.endIndex
          );
        }


        latestListingsModel.loader = false;
        setNewListings(latestListingsModel);
        setLoaderNewListings(false);
      } catch (e) {
        console.error("Failed to get New listings", e);
        setLoaderNewListings(false);
      }
    };

    useEffect(() => {
      setLoaderGreen(true);
      fetchLatestSecuritiesLGX();

      setLoaderNewListings(true);
      fetchNewListings();

      setLoaderIndexes(true);
      fetchIndexes();
    }, []);

    return (
      <div css={sharedStyle.topContainer}>
        <div css={sharedStyle.greyContainer} />
        <div css={sharedStyle.container}>
          <Grid>
            <Row>
              <Col mobile={12} tablet={6} desktop={6}>
                <div css={card}>
                  <div css={cardContainer}>
                    <div css={sharedStyle.cardContainerTop}>
                      <div css={sharedStyle.cardContainerTitle}>
                        <Icon icon={"chart-area"} /> New Listings
                      </div>
                    </div>
                    <div css={sharedStyle.cardContainerBody}>
                      <Loader
                        overflow={"auto"}
                        centered
                        loading={loaderNewListings}
                      >
                        <>
                          {newListings?.totalHits > 0 &&
                          newListings?.currentData?.length > 0 ? (
                            <table css={sharedStyle.table}>
                              <thead>
                                <tr>
                                  <th css={sharedStyle.tableNewSecurityTh}>
                                    Security
                                  </th>
                                  <th css={sharedStyle.statusTh}>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {newListings.currentData.map(
                                  (security: any) => (
                                    <Tr key={security.id}
                                      css={
                                        ![
                                          "ADMI",
                                          "COTE",
                                          "NEGO",
                                          "FONG",
                                        ].includes(security?.status) &&
                                        sharedStyle.rowNoClickable
                                      }
                                      onRowClick={() => {
                                        if (
                                          [
                                            "ADMI",
                                            "COTE",
                                            "NEGO",
                                            "FONG",
                                          ].includes(security?.status)
                                        )
                                          goToSecurityDetailPage(
                                            security?.isin,
                                            security?.id
                                          );
                                      }}
                                      title={
                                        security?.status == "NCOT"
                                          ? "Listing to be confirmed"
                                          : ""
                                      }
                                    >
                                      <td css={sharedStyle.tableNewSecurity}>
                                        <div
                                          css={
                                            sharedStyle.cardContainerBodyDoubleRight
                                          }
                                        >
                                          <div
                                            css={[
                                              sharedStyle.rowBond,
                                              sharedStyle.bold,
                                            ]}
                                          >
                                            {security.instrumentName}
                                          </div>
                                          <div css={sharedStyle.span}>
                                            {security.isin}
                                            {security.issuedNominalAmount ? (
                                              ` ${
                                                security.currency
                                              } ${formatToThousandsSeparator(
                                                security.issuedNominalAmount
                                              )}`
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td css={sharedStyle.status}>
                                        <div
                                          css={
                                            sharedStyle.cardContainerBodyDouble
                                          }
                                        >
                                          <div css={sharedStyle.bold}>
                                            {getNewListingStatusLabel(security)}
                                          </div>
                                          <div css={sharedStyle.span}>
                                            {formatDate(
                                              security.listingDate
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </Tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          ) : (
                            <div> No result available</div>
                          )}
                        </>
                      </Loader>
                      <div css={sharedStyle.cardContainerFooter}>
                        <Link
                          target={"_self"}
                          title={"link"}
                          field={withQueryParam(
                            fields[`view all new listing link`],
                            "?type=newListing"
                          )}
                          css={{ textDecoration: "none" }}
                        >
                          <div css={sharedStyle.hideDesktop}>
                            <Button
                              label={`Access to ${newListings?.totalHits} new listings`}
                              variant={"primary"}
                              wired
                              iconRight={"chevron-right"}
                            />
                          </div>
                          <div css={sharedStyle.hideMobile}>
                            <span>View all new listings</span>{" "}
                            <Icon icon={"chevron-right"} />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col mobile={12} tablet={6} desktop={6}>
                <div css={card}>
                  <div css={cardContainer}>
                    <div css={sharedStyle.cardContainerTop}>
                      <div css={sharedStyle.cardContainerTitle}>
                        <img src={renderLink(lgxlogo)} alt={"image"} />
                      </div>
                    </div>
                    <div css={sharedStyle.cardContainerBody}>
                      <Loader overflow={"auto"} centered loading={loaderGreen}>
                        <>
                          {lastSecuritiesLgx?.totalHits > 0 &&
                          lastSecuritiesLgx?.securities?.length > 0 ? (
                            <table css={sharedStyle.table}>
                              <thead
                                style={{
                                  background: "#F8FAFC",
                                  color: "#253645",
                                  fontSize: "0.8rem",
                                  textAlign: "left",
                                }}
                              >
                                <tr>
                                  <th
                                    css={css`
                                      min-width: 65px !important;
                                    `}
                                  ></th>
                                  <th css={sharedStyle.tableSecurity}>
                                    Security
                                  </th>
                                  <th css={sharedStyle.tableDate}>Standards</th>
                                  <th css={sharedStyle.tableYield}>Ext.Rev</th>
                                  <th css={sharedStyle.tablePrice}>
                                    Reporting
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {lastSecuritiesLgx.securities.map(
                                  (security: SearchSecurity) => (
                                    <Tr
                                      key={security.id}
                                      onRowClick={() =>
                                        goToSecurityDetailPage(
                                          security.isinCode,
                                          security.id
                                        )
                                      }
                                    >
                                      <Td css={[cellStyle]}>
                                        <RoundTag
                                          green={security.lgxDisplay}
                                          small
                                          textColor={"#425563"}
                                        >
                                          {computeCategoryLabel(
                                            security.category
                                          )}
                                        </RoundTag>
                                      </Td>
                                      <Td
                                        css={[
                                          sharedStyle.tableSecurity,
                                          cellStyle,
                                        ]}
                                      >
                                        <div
                                          data-tip={security.name}
                                          css={cellOverflow}
                                        >
                                          {security.name}
                                        </div>
                                      </Td>
                                      <Td
                                        css={[sharedStyle.tableDate, cellStyle]}
                                      >
                                        <div
                                          data-tip={
                                            (security.lgxStandards &&
                                              security.lgxStandards[0] &&
                                              lgxStandardLabel(
                                                security.lgxStandards[0]
                                              )) ||
                                            "N/A"
                                          }
                                          css={cellOverflow}
                                        >
                                          {(security.lgxStandards &&
                                            security.lgxStandards[0] &&
                                            lgxStandardLabel(
                                              security.lgxStandards[0]
                                            )) ||
                                            "N/A"}
                                        </div>
                                      </Td>
                                      <Td
                                        css={[
                                          sharedStyle.tableYield,
                                          cellStyle,
                                        ]}
                                      >
                                        <div
                                          data-tip={
                                            (security.lgxExternalReviews &&
                                              security.lgxExternalReviews[0] &&
                                              lgxExternalReviewLabel(
                                                security.lgxExternalReviews[0]
                                              )) ||
                                            "N/A"
                                          }
                                          css={[cellOverflow]}
                                        >
                                          {(security.lgxExternalReviews &&
                                            security.lgxExternalReviews[0] &&
                                            lgxExternalReviewLabel(
                                              security.lgxExternalReviews[0]
                                            )) ||
                                            "N/A"}
                                        </div>
                                      </Td>
                                      <Td
                                        css={[
                                          sharedStyle.tablePrice,
                                          cellStyle,
                                        ]}
                                      >
                                        <div
                                          data-tip={
                                            (security.lgxExternalReportings &&
                                              security
                                                .lgxExternalReportings[0] &&
                                              lgxExternalReportingLabel(
                                                security
                                                  .lgxExternalReportings[0]
                                              )) ||
                                            "N/A"
                                          }
                                          css={cellOverflow}
                                        >
                                          {(security.lgxExternalReportings &&
                                            security.lgxExternalReportings[0] &&
                                            lgxExternalReportingLabel(
                                              security.lgxExternalReportings[0]
                                            )) ||
                                            "N/A"}
                                        </div>
                                      </Td>
                                      <ReactTooltip
                                        css={css`
                                          width: fit-content;
                                          max-width: 250px;
                                          min-width: 100px;
                                          word-break: break-all;
                                          overflow-wrap: break-word;
                                          display: block;
                                        `}
                                        data-html={true}
                                        insecure={true}
                                        multiline={true}
                                        effect="solid"
                                      />
                                    </Tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          ) : (
                            <div>No result available</div>
                          )}
                        </>
                      </Loader>
                      <div
                        css={sharedStyle.cardContainerFooter}
                        //onClick={() => (window.location.href = '/search?dataType=securities&lgxOnly=true')}
                      >
                        <Link
                          target={"_self"}
                          title={"link"}
                          field={withQueryParam(
                            fields[`view all LGX securities link`],
                            "?dataType=securities&lgxOnly=true"
                          )}
                          css={{ textDecoration: "none" }}
                        >
                          <div css={sharedStyle.hideDesktop}>
                            <Button
                              label={"View all LGX securities"}
                              variant={"primary"}
                              wired
                              iconRight={"chevron-right"}
                            />
                          </div>
                          <div css={sharedStyle.hideMobile}>
                            <span>View all LGX securities</span>{" "}
                            <Icon icon={"chevron-right"} />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col mobile={12} tablet={6} desktop={6}>
                <LuxXPrimeHomeData link={withQueryParam(
                            fields[`view all LuxXPrime securities link`],
                            "?dataType=luxXPrime"
                          )}/>
              </Col>

              <Col mobile={12} tablet={6} desktop={6}>
                <div css={card}>
                  <div css={cardContainer}>
                    <div css={sharedStyle.cardContainerTop}>
                      <div css={sharedStyle.cardContainerTitle}>
                        <Icon icon={"chart-line"} /> Indices
                      </div>
                    </div>

                    <div css={sharedStyle.cardContainerBody}>
                      <Loader
                        overflow={"auto"}
                        centered
                        loading={loaderIndexes}
                      >
                        <>
                          {indexes && indexes?.length > 0 ? (
                            <table
                              css={{ width: "100%", borderSpacing: "5px" }}
                            >
                              <tbody>
                                {indexes.map((index: IndiceSummary) => (
                                  <Tr
                                    key={index.id}
                                    style={{
                                      boxShadow: "none",
                                      marginTop: "4px",
                                    }}
                                    onRowClick={() =>
                                      goToIndexDetailPage(index.id)
                                    }
                                  >
                                    <GraphCard
                                      key={`graph-${index.id}`}
                                      heightChart={200}
                                      chartOptions={ChartOptions}
                                      data={index.indexPrice}
                                      label={index.indexName}
                                    />
                                  </Tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div>No data available</div>
                          )}
                        </>
                      </Loader>
                      <div
                        css={sharedStyle.cardContainerFooter}
                        //onClick={() => (window.location.href = '/search?dataType=indices')}
                      >
                        <Link
                          target={"_self"}
                          title={"link"}
                          field={withQueryParam(
                            fields[`view all indices link`],
                            "?dataType=indices"
                          )}
                          css={{ textDecoration: "none" }}
                        >
                          <div css={sharedStyle.hideDesktop}>
                            <Button
                              label={`View all indices`}
                              variant={"primary"}
                              wired
                              iconRight={"chevron-right"}
                            />
                          </div>
                          <div css={sharedStyle.hideMobile}>
                            <span>View all indices</span>{" "}
                            <Icon icon={"chevron-right"} />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Grid>
          <div css={sharedStyle.buttons}>
            <Link
              target={
                fields["button 1 link"]?.value?.linktype === "external"
                  ? "_blank"
                  : "_self"
              }
              title={fields && fields["button 1 label"]}
              field={fields && fields["button 1 link"]}
              css={{ textDecoration: "none" }}
            >
              <Button label={button1Label} iconRight={"chevron-right"} />
            </Link>
            <Link
              target={
                fields["button 2 link"]?.value?.linktype === "external"
                  ? "_blank"
                  : "_self"
              }
              title={fields && fields["button 1 label"]}
              field={fields && fields["button 2 link"]}
              css={{ textDecoration: "none" }}
            >
              <Button
                label={button2Label}
                iconRight={"chevron-right"}
                variant={"primary"}
                wired
              />
            </Link>
          </div>
        </div>
      </div>
    );
  };
