/** @jsx jsx */
import { css, jsx } from '@emotion/react';

export const style = {
  mobile: css``,
  actionsRow: css`
    color: inherit;
    text-decoration: none;
    &:hover {
      td button {
        visibility: visible;
        opacity: 1;
      }
    }
    td button {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s ease;
    }
    transition: background-color 0.5s ease;
  `,
  disableRow: css`
    background-color: #E8ECEE !important;
    &:hover {
      background-color: #E8ECEE;
    }
    > td {
      background-color: #E8ECEE;
      &:hover {
        background-color: #E8ECEE;
      }
    }
  `,
  clickableRow: css`
    cursor: pointer;
  `,
  openedRow: css`
    background-color: #d3f2e1;
    border-bottom: 1px solid #22aa5f;
    &:hover {
      background-color: #d3f2e1;
    }

    > td {
      background-color: #d3f2e1;
      border-bottom: 1px solid #22aa5f;
      &:hover {
        background-color: #d3f2e1;
      }
    }
  `,
  subRow: css`
    background-color: #e8faf0;
    border-bottom: 1px solid #22aa5f;
    &:hover {
      background-color: #e8faf0;
    }

    > td {
      background-color: #e8faf0;
      border-bottom: 1px solid #22aa5f;
      &:hover {
        background-color: #e8faf0;
      }
    }
  `,
  rowTag: css`
    position: absolute;
    width: 25px;
    height: 100%;
    left: 0;
    top: 0;
  `,
  rowTagGreen: css`
    background-color: #0eae56;
    border-right: 1px solid #0eae56;
    color: white;
  `,
  rowTagImg: css`
    // position: absolute;
    // top: 10px;
    // left: 5px;
    margin-right: 8px;
    width: 15px;
    height: 16px;
  `,
  rowTagText: css`
    display: block;
    position: absolute;
    left: 3px;
    bottom: 10px;

    font-size: 12px;
    font-weight: 500;
    line-height: 14px;

    transform: rotate(-90deg);
  `,
  align: css`
    align-items: center;
    display: flex;
  `,
  noIssuer: css `
    font-style: italic;
  `,
  multipleIssuer: css `
    &:hover{
      color: #22aa5f;
      svg {
        color: #22aa5f;
      }
    }
  `,
  tooltip: css `
    width: fit-content;
    max-width: 300px;
    min-width: 100px;
    word-break: break-all;
    overflow-wrap: break-word;
    display: block;
    padding: 5px 15px !important;
    border-radius:10px !important;
    background-color: #1a2834 !important;
  `,
  tooltipList: css `
    padding-inline-start: 20px;
    li {
      padding-top: 5px;
    }
  `,
  image: css`
    width: 150px;
    min-width: 150px;
    img {
      max-height: 24px;
      max-width: 150px;
    }
  `,
  imageFlag: css`
    width: 32px;
  `,
  actionButton: css`
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 30px;
  `,
  linkButton: css`
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 30px;
  `,
  market: css`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    border: solid 1px #e3e6e8;
    background: #ffffff;
    border-radius: 999em;
    padding: 8px 16px;
    font-weight: bold;
    color: #354450;
  `,
  bold: css`
    font-weight: 700;
    line-height: 1.7;
  `,
  subLabel: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #657f93;
  `,
  labelLink: css`
    a {
      color: #54666f;
      font-size: 1em;
      font-weight: 400;
      line-height: 20px;
      -webkit-text-decoration: underline;
      text-decoration: underline;
    }
  `,
  cellSplitTwo: css`
    display: flex;
  `,
  cellSplitLeft: css`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 5px;
    text-align: center;
  `,
  cellSplitRight: css`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 5px;
    text-align: center;
  `,
  cellSplitSeparator: css`
    border-left: solid 1px #e3e6e8; ;
  `,
  inputSort: css`
    display: flex;
    flex-direction: row;
    padding: 10px;
    float: right;
    align-items: center;
    justify-content: space-between;
    span {
      margin-right: 10px;
      font-size: 16px;
      color: #253845;
    }
    select {
      height: 45px;
      min-width: 180px;
      border: solid 1px #d9dee2;
      border-radius: 5px;
      color: #425563;
      font-size: 14px;
    }
    option {
      margin: 5px;
    }
  `,
  subRowSection: css`
    border: 1px solid #e3e6e8;
    border-radius: 10px;
    font-size: 14px;
  `,
  subRowSectionHead: css`
    display: block;
    width: 100%;
    cursor: pointer;
    position: relative;
    height: 50px;
    padding: 1em;
  `,
  subRowSectionHeadOpen: css`
    border-bottom: 1px solid #e3e6e8;
  `,
  subRowSectionTitle: css`
    color: rgb(66, 85, 99);
    font-size: 16px;
    font-weight: 700;
    float: left;
  `,
  subRowSectionIcon: css`
    float: right;
  `,
  subRowSectionContent: css`
    width: 100%;
    display: block;
    transition: max-height 1s ease;
    overflow: hidden;
    > div:not(:last-child) {
      border-bottom: 1px solid #e3e6e8;
    }
  `,
  subRowSectionContentClose: css`
    max-height: 0;
  `,
  subRowSectionContentOpen: css`
    max-height: inherit;
  `,
  subRowSectionItem: css`
    width: 100%;
    display: flex;
    border-bottom: 1px solid #e3e6e8;
    
    &:hover {
      > div.action button {
        visibility: visible;
        opacity: 1;
      }
    }
    > div.action button {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s ease;
    }
    transition: background-color 0.5s ease;
  `,
  subRowSectionItemIcon: css`
    padding: 1em;
    text-align: center;
    justify-content: center;
  `,
  subRowSectionItemValue: css`
    padding: 1em;
    justify-content: center;
  `,
  subRowSectionItemAction: css`
    padding: 1em;
    text-align: center;
    justify-content: center;
  `,
};
