/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";

import { useSnapCarousel } from "react-snap-carousel";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpointMax } from "../../../style";

const lint = jsx; // linter hack
interface CarouselProps<T> {
  readonly items: T[];
  readonly renderItem: (props: any) => React.ReactElement<CarouselItemProps>;
}

interface CarouselItemProps {
  readonly isSnapPoint: boolean;
  readonly children?: React.ReactNode;
}

interface CarouselRenderItemProps {
  readonly item: any;
  readonly isSnapPoint: boolean;
}

const item = css`
  width: 33.33%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  @media (max-width: ${breakpointMax}) {
    width: 90%;
    margin-left: 16px;
  }
`;
const scroll = css`
  position: relative;
  display: flex;
  scroll-snap-type: x mandatory;
  overflow: hidden;
  @media (max-width: ${breakpointMax}) {
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
      border-radius: 0;
    }
  }
`;
const root = css`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const styles: any = {
  itemSnapPoint: {
    scrollSnapAlign: "start",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nextPrevButton: {},
  nextPrevButtonDisabled: { opacity: 0.3 },
  pagination: {
    display: "flex",
  },
  paginationButton: {
    margin: "10px",
  },
  paginationButtonActive: { opacity: 0.3 },
  pageIndicator: {
    display: "flex",
    justifyContent: "center",
  },
};

const dot = (disabled = false) => css`
  width: 8px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 8px;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
  opacity: ${disabled ? "0.3" : "1"};
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;

const navigatorStyle = (position: string) => css`
  width: 54px;
  height: 54px;
  border-radius: 54px;
  background: #ffffff;
  position: absolute;
  ${position === "left" ? "left: 0;" : "right: 0;"};
  margin-left: ${position === "left" ? "-100px" : "0"};
  margin-right: ${position === "left" ? "0" : "-100px"};
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #22aa5f;
  cursor: pointer;
`;
export const Carousel = <T extends any>({
  items,
  renderItem,
}: CarouselProps<T>) => {
  const {
    scrollRef,
    pages,
    activePageIndex,
    prev,
    next,
    goTo,
    snapPointIndexes,
  } = useSnapCarousel();
  return (
    <div css={root}>
      {items?.length > 3 && (
        <div css={navigatorStyle("left")} onClick={() => prev()}>
          <Icon icon={"chevron-left"} />
        </div>
      )}

      <ul css={scroll} ref={scrollRef}>
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
          })
        )}
      </ul>
      {items?.length > 3 && (
        <div css={navigatorStyle("right")} onClick={() => next()}>
          <Icon icon={"chevron-right"} />
        </div>
      )}
      <div style={styles.controls} aria-hidden>
        {pages.map((_, i) => (
          <div
            css={dot(
              activePageIndex === i || activePageIndex === pages.length - 1
            )}
            onClick={() => goTo(i)}
          />
        ))}
      </div>
    </div>
  );
};
export const CarouselItem = ({ isSnapPoint, children }: CarouselItemProps) => (
  <li
    css={{
      ...item,
    }}
    style={isSnapPoint ? styles.itemSnapPoint : {}}
  >
    {children}
  </li>
);
