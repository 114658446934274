
export enum PeriodicityCode {
	JOUR = 'JOUR',
	HEBD = 'HEBD',
	MENS = 'MENS',
	TRIM = 'TRIM',
	SEME = 'SEME',
	ANNU = 'ANNU',
	MATU = 'MATU',
	INDE = 'INDE'
}