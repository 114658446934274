
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue, RichText } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {fetchData, fetchFullData} from "../../utils/helper";
import { useEffect, useState } from "react";

export interface BlogCategoriesProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  `;

const renderChild = (data: any, parent: string) => {
    return (
        data && data.map((d: any, index: number) => (
            <a href={`/blog/${parent}/${d?.ItemName}`} key={`link-${parent}-${index}`}>
                {d?.ItemName}
            </a>
        ))

    )
}

export const BlogCategories: React.FunctionComponent<BlogCategoriesProps> = (props: BlogCategoriesProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const [response, setResponse] = useState<any>([]);
    /* dev test */
    useEffect(() => {
        fetchFullData('DCA687E3-19BD-4D68-9D55-0DE7F212EBDE').then((response: any) => {
            setResponse(response);
        })
    }, [rendering])

    return (
        <div css={container}>
                {
                    response && response.map((data: any, index: number) => (
                        <div key={`loop_${index}`}>
                            <h3>{data.parent}</h3>
                            <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 40, marginBottom: 24}}>
                                {renderChild(data?.children, data?.parent)}
                            </div>
                        </div>
                    ))
                }
        </div>
    );
}
    