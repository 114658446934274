export enum Reviewer {
	CICERO = 'Cicero',
	VIGEO_EIRIS = 'V. E.',
	SUSTAINALYTICS = 'Sustainalytics',
	ISS_OEKOM = 'ISS ESG',
	DNV_GL = 'DNV GL',
	SP = 'S&P',
	MOODYS = 'Moodys',
	CBI_CERTIFIED = 'CBI',
	CBI_FACTSHEET = 'CBI (factsheet)',
	EY = 'EY',
	KPMG = 'KPMG',
	DELOITTE = 'Deloitte',
	PWC = 'PwC',
	JAPAN_CRA = 'Japan Credit Rating Agency',
	FITCH_RATINGS ='Fitch Ratings',
    RI ='R&I',
    NICE ='NICE',
    KOREA_RATINGS ='Korea Ratings',
    KOREA_INVESTORS_SERVICE ='Korea Investors Service',
    HKQAA ='HKQAA',
    CHINA_LIANHE ='China Lianhe',
    CHINA_CHENGXIN_CCX ='China Chengxin (CCX)',
    BEIJING_ZHONGCAI_GREEN_FINANCING ='Beijing Zhongcai Green Financing',
    CECEP ='CECEP',
    SYNTAO_GREEN_FINANCE ='SynTao Green Finance',
    RAM ='RAM',
    MALAYSIAN_RATING_CORPORATION ='Malaysian Rating Corporation',
    EMERGENT_VENTURES_INDIA ='Emergent Ventures India',
    AIFC ='AIFC',
    CARBON_TRUST ='Carbon Trust',
    MAZARS ='Mazars',
    IMUG ='Imug',
    SITAWI ='Sitawi',
    GRANT_THORNTON ='Grant Thornton',
    BUREAU_VERITAS ='Bureau Veritas',
    BDO ='BDO',
    HR_RATING ='HR Rating',
    MULTICONSULT ='Multiconsult',
    RFU ='Rfu',
    THE_GOVERNANCE_GROUP ='The Governance Group',
    IBIS_ESG_CONSULTING_AFRICA ='IBIS ESG Consulting Africa',
    KESTREL_VERIFIERS ='Kestrel Verifiers',
    SCOPE ='Scope',
	OTHER_REVIEWER = 'Other',
}

export function reviewerLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(Reviewer).indexOf( value );
  		if( index >= 0) 
			return Object.values(Reviewer)[index];
	}catch (e){
		console.error("Failed to get Reviewer label for '" + value + "'", e);
	}
	
	console.error("Reviewer label for '" + value  + "' doest exist");

	return value;
}
