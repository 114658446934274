import { css } from '@emotion/react';

export default {
    container: css`
        position: relative;
    `,
    panel: css`
        padding: 1rem;
        background-color: white;
        border-radius: 4px;
        max-height: 250px;
        overflow-y: auto;
        width: 100%;
        min-width: 285px;
        position: absolute;
        z-index: 1;
        box-shadow: 0 5px 10px rgba(66, 85, 99, 0.25);

        svg {
            margin-right: 0;
        }
    `,
    children: css`
        margin-left: 1rem;
    `,
    clickOutside: css`
        z-index: 10;
        width : 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    `,
}