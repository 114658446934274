
/** @jsx jsx */

import * as React from 'react';

import { ComponentFields, ComponentParams, ComponentRendering, RichText, Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { HowToListCard } from "../../core/components/HowToListCard";
import { HowToListResult } from "../../core/components/HowToListResult";
import { SitecoreGenericTextField } from '../../interfaces';
import { conditions } from "./condtionCards";
import { mergeConditions } from "./mergeConditions";
import { rules } from "./conditions";
import { useState } from "react";
import { canUseDOM } from '../../predicates';

export interface HowToListWizardProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const HowToListWizard: React.FunctionComponent<HowToListWizardProps> = (props: HowToListWizardProps): React.ReactElement => {
  const { fields } = props;

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [excluded, setExcluded] = useState([]);
  const [showWizard, setShowWizard] = useState<boolean>(true);
  const [details, setDetails] = useState<any>();
  const more1: string = getFieldValue<string>(fields, 'more info card 1', '');
  const more2: string = getFieldValue<string>(fields, 'more info card 2', '');
  const more3: string = getFieldValue<string>(fields, 'more info card 3', '');
  const isH1: boolean = getFieldValue<boolean>(fields, 'is H1', false);
  // tag={isH1 ? 'h1' : 'div'}
  const onSelectCol = (data: any, index: number) => {
    setExcluded([]);
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index] = data;
    const newData = newSelectedItems.slice(0, index + 1);
    const indexesData = newSelectedItems.slice(0, index + 1).map((item: any) => item.index);
    setSelectedItems(newData);
    /*
      0 debt
      1 asset abs
      2 certificate
      3 debt and derivative
      4 derivative structured sips
      5 warrant
      6 money market
      7 share global
      8 security token
     */
    if (indexesData && indexesData[0] && indexesData[1]) {
      const a = indexesData[0];
      const b = indexesData[1];
      if (a === 1 && b === 1) { setExcluded([8]); }
      if (a === 1 && b === 2) { setExcluded([1, 2, 3, 4, 5, 7, 8]); }
      if (a === 1 && b === 3) { setExcluded([1, 2, 3, 4, 5, 7, 8]); }
      if (a === 1 && b === 4) { setExcluded([2, 3, 4, 5, 6, 8]); }
      if (a === 2 && b === 1) { setExcluded([8]); }
      if (a === 2 && b === 2) { setExcluded([1, 2, 3, 4, 5, 7, 8]); }
      if (a === 2 && b === 3) { setExcluded([1, 2, 3, 4, 5, 7, 8]); }
      if (a === 2 && b === 4) { setExcluded([2, 3, 4, 5, 6, 8]); }
      if (a === 3 && b === 2) { setExcluded([1, 2, 3, 4, 5, 7]); }
      if (a === 3 && b === 3) { setExcluded([1, 2, 3, 4, 5, 7]); }
      if (a === 3 && b === 4) { setExcluded([2, 3, 4, 5, 6]); }
    }

  }

  const parentContainer = css`
      width: 100%;
      display: flex;
      @media (max-width: ${breakpointMax}) {
        padding-top:  ${showWizard ? '32px' : '0'};
        padding-bottom: 32px;
      }
    `;

  const titleStyle = css`
      font-weight: 700;
      font-size: 32px;
      line-height: 41px;
      display: flex;
      align-items: center;
      color: #354450;
      
      @media (max-width: ${breakpointMax}) {
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        display: flex;
      }
      
      h1 {
        font-weight: 700;
        font-size: 32px!important;
        line-height: 41px;
        display: flex;
        align-items: center;
        color: #354450;

        @media (max-width: ${breakpointMax}) {
          font-weight: 700;
          font-size: 32px;
          line-height: 31px;
          display: flex;
        }
      }
    `;

  const descriptionStyle = css`
      width: 70%;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #354450;
      margin-top: 40px;

      @media (max-width: ${breakpointMax}) {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.01em;
        margin-top: 16px;
      }
    `;

  const disclaimer = css`
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.01em;
      color: #354450;
      margin-top: 40px;

      @media (max-width: ${breakpointMax}) {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.01em;
        margin-top: 16px;
      }
    `;
  const subPart = css`
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.01em;
      color: #354450;
      margin-top: 40px;

      @media (max-width: ${breakpointMax}) {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.01em;
        margin-top: 16px;
      }
    `;

  const subPartTitle = css`
      margin-top: 16px;
      margin-bottom: 16px;
      line-height: 61px;
      color: #425563;

      @media (max-width: ${breakpointMax}) {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.01em;
        margin-top: 16px;
      }
    `;

  const subPartDescription = css`
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #354450;
      margin-top: 40px;
      a {
        color: #22AA5F;
        :hover {
          color: #1A844A;
          text-decoration: none;
        }
      }
    `;

  const cardsStyle = css`
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      padding-top: 80px;
      position: relative;

      @media (max-width: ${breakpointMax}) {
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 32px;
        padding-bottom: 32px;
      }
    `;
  const container = css`
      width: 100%;
      max-width: ${showWizard ? '1280px' : '100%'};
      padding-left: ${!showWizard ? '0' : '40px'};
      padding-right: ${!showWizard ? '0' : '40px'};
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      padding-top: ${!showWizard ? '0' : '80px'};
      @media (max-width: ${breakpointMax}) {
        padding: ${showWizard ? '0 16px' : '0'};
      }
    `;

  React.useEffect(() => {
    if (canUseDOM) {
      const handlePopstate = (event: any) => {
        const currentPath = window.location.pathname;
        if (window?.location?.pathname?.split('/')?.length < 3) {
          setSelectedItems([])
          setShowWizard(true)
        }
      };

      window.addEventListener('popstate', handlePopstate);

      // Nettoyez l'écouteur d'événements lors du démontage du composant
      return () => {
        window.removeEventListener('popstate', handlePopstate);
      };
    }

  }, [canUseDOM]);

  React.useEffect(() => {
    if (selectedItems.length === conditions.length) {
      const newUrl = `${window.location.href}/result`; // Remplacez par l'URL que vous souhaitez utiliser
      window.history.pushState({ path: newUrl }, '', newUrl);
      setDetails(mergeConditions(selectedItems.map((item: any) => item.index))?.rule);
      setShowWizard(false);
    }
  }, [selectedItems]);

  const [displayLgx, setDisplayLgx] = React.useState<boolean>(false);


  const getInfoLink = (index: number) => {
    if (index === 0) {
      return more1;
    }
    if (index === 1) {
      return more2;
    }
    if (index === 2) {
      return more3;
    }
    return '';
  }

  return (
    <div css={parentContainer}>
      <div css={container}>
        {showWizard ?
          <>
            <div css={titleStyle}>
              <Text
                tag={isH1 ? 'h1' : 'div'}
                field={fields && fields.title as SitecoreGenericTextField}
              />
            </div>
            <div css={descriptionStyle}>
              <RichText
                field={fields && fields.description as SitecoreGenericTextField}
              />
            </div>
            <div css={cardsStyle}>
              {
                conditions && conditions.map((child: any, index: number) => {
                  return (
                    <HowToListCard
                      key={`card-${index}`}
                      title={child.title}
                      label={child.title}
                      data={child.items}
                      index={index}
                      onSelect={(data: any, index: number) => onSelectCol(data, index)}
                      onSelectLgx={(data: any) => setDisplayLgx(data)}
                      selected={selectedItems[index] && selectedItems[index].index - 1}
                      disabled={index === 0 ? false : !selectedItems[index - 1]}
                      lgxBox={index === 2}
                      excluded={excluded}
                      infoLink={getInfoLink(index)}
                    />
                  )
                })
              }
            </div>
            <div css={disclaimer}>
              <Text
                field={fields && fields.disclaimer as SitecoreGenericTextField}
              />
            </div>
            <div css={subPart}>
              <div css={subPartTitle}>
                <Text
                  tag={"h2"}
                  field={fields && fields['sub title'] as SitecoreGenericTextField}
                />
              </div>
              <div css={subPartDescription}>
                <RichText
                  field={fields && fields['sub description'] as SitecoreGenericTextField}
                />
              </div>

            </div>
          </> : (
            <HowToListResult fields={fields} data={details} selectedItems={selectedItems} showLgx={displayLgx} />
          )
        }
      </div>
    </div>
  );
}
