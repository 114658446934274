import { breakpoint, breakpointMax } from "../../style";

import { css } from "@emotion/react";

export const style = {
  container: css`
    width: 100%;
    min-height: 100px;
    position: relative;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin: auto;
    margin-top: 96px;
    @media (max-width: ${breakpointMax}) {
      margin-top: 48px;
      padding: 16px;
    }
  `,
  title: css`
    width: 100%;
    font-weight: 800;
    font-size: 48px;
    line-height: 62px;
    text-align: center;
    max-width: 800px;
    margin: auto;
    color: #354450;

    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
    }
  `,
  cols: css`
    width: 100%;
    display: flex;
    margin: auto;
    margin-top: 48px;
    justify-content: space-evenly;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  col: css`
    max-width: 50%;
    display: flex;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      max-width: 100%;
    }
  `,
  colTitle: css`
    width: 50%;
    display: flex;
    flex-direction: column;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      width: 100%;
    }
  `,
  colValues: css`
    width: 100%;
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      margin-top: 0;
    }
  `,
  colValue: css`
    width: 100%;
    display: flex;
    margin-bottom: 32px;
    align-items: center;
  `,
  colValueIcon: css`
    margin-right: 16px;
    font-size: 24px;
    color: #cb1234;
  `,
  colValueText: css`
    font-size: 20px;
    color: #354450;
    font-weight: 600;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 16px;
      line-height: 180%;
      letter-spacing: 0.01em;
    }
  `,
};
