
import { DayCountConvention, PeriodicityCode } from '../../enums';

export const periodicityCodeLabel = (pc : string): string => {
    if( ! pc )
        return "";
    return setPeriodicityCodeLabelDataValue( pc as PeriodicityCode )
}

export const setPeriodicityCodeLabelDataValue = (pc: PeriodicityCode): string => {
    switch (pc) {
        case PeriodicityCode.JOUR: return 'Daily';
        case PeriodicityCode.HEBD: return 'Weekly';
        case PeriodicityCode.MENS: return 'Monthly';
        case PeriodicityCode.TRIM: return 'Quarterly';
        case PeriodicityCode.SEME: return 'Half-Yearly';
        case PeriodicityCode.ANNU: return 'Yearly';
        case PeriodicityCode.MATU: return 'To Maturity';
        case PeriodicityCode.INDE: return 'Other';
    }
}

export const setSecurityPeriodValue = (pc: PeriodicityCode): number => {
    switch (pc) {
        case PeriodicityCode.JOUR: return 360;
        case PeriodicityCode.HEBD: return 52;
        case PeriodicityCode.MENS: return 12;
        case PeriodicityCode.TRIM: return 4;
        case PeriodicityCode.SEME: return 6;
        case PeriodicityCode.ANNU: return 1;
        default: return -1;
    }
}


export const setDayConventionCodeValueToSend = (dc: DayCountConvention): string => {
    switch(dc) {
        case DayCountConvention.THIRTYRE: return 'THIRTYRE';
        case DayCountConvention.RERE: return 'RERE';
        case DayCountConvention.RE30: return 'RE30';
        case DayCountConvention.RE35: return 'RE35';
        case DayCountConvention.THIRTYTHIRTY: return 'THIRTYTHIRTY';
        case DayCountConvention.UNAPPLICABLE: return 'UNAPPLICABLE';
    }
}