
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const BOL: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#007934" d="M0,311.908h512v102.988c0,4.875-3.953,8.828-8.828,8.828H8.828c-4.875,0-8.828-3.953-8.828-8.828 V311.908z" />
            <path fill="#D52B1E" d="M8.828,88.276h494.345c4.875,0,8.828,3.953,8.828,8.828v102.988H0V97.104 C0,92.229,3.953,88.276,8.828,88.276z" />
            <rect y="200.09" fill="#F9E300" width="512" height="111.81" />
            <path fill="#007934" d="M244.598,205.957c0,0-0.227,0.284-0.653,0.817c-0.355,0.492-0.888,1.248-1.47,2.254 c-1.145,1.999-2.347,5.098-2.38,8.792c-0.012,1.801,0.36,3.988,0.974,5.727c0.666,1.92,1.771,3.756,3.168,5.396 c1.399,1.655,3.268,2.931,5.267,3.873c0.991,0.503,2.079,0.792,3.158,1.058c0.535,0.163,1.105,0.171,1.654,0.279 c0.276,0.052,0.562,0.029,0.841,0.051l0.422,0.019l0.211,0.011l0.105,0.006l0.053,0.003l0.026,0.001 c-0.085,0.003,0.227-0.004-0.184,0.007c0.684-0.04,1.514-0.045,2.078-0.114c0.492-0.115,0.906-0.095,1.47-0.262 c1.08-0.266,2.167-0.555,3.158-1.058c1.998-0.942,3.868-2.218,5.267-3.873c1.397-1.64,2.502-3.476,3.168-5.396 c0.638-1.953,0.979-3.822,0.974-5.736c-0.031-3.678-1.234-6.788-2.379-8.785c-0.578-1.002-1.113-1.763-1.494-2.276 c-0.396-0.505-0.607-0.776-0.607-0.776l-0.053-0.067c-0.381-0.486-0.295-1.187,0.191-1.568c0.384-0.3,0.904-0.309,1.291-0.061 c0,0,0.326,0.209,0.937,0.6c0.578,0.434,1.474,1.047,2.439,2.039c1.937,1.918,4.451,5.334,5.358,10.298 c0.449,2.376,0.498,5.25-0.121,8.075c-0.6,2.854-1.817,5.805-3.782,8.387c-1.93,2.594-4.523,4.888-7.606,6.435 c-1.521,0.811-3.176,1.4-4.874,1.81c-0.814,0.239-1.847,0.338-2.767,0.483c-0.862,0.095-1.475,0.097-2.226,0.15l-0.292,0.001 l-0.082-0.004l-0.164-0.008l-0.328-0.017l-0.657-0.035c-0.437-0.035-0.876-0.029-1.309-0.104c-0.863-0.152-1.741-0.223-2.583-0.466 c-1.698-0.409-3.354-0.999-4.874-1.81c-3.083-1.547-5.676-3.842-7.606-6.435c-1.964-2.583-3.181-5.534-3.782-8.387 c-0.642-3.04-0.562-5.596-0.12-8.084c0.905-4.949,3.421-8.377,5.357-10.291c0.961-0.988,1.86-1.607,2.463-2.061 c0.58-0.364,0.89-0.559,0.89-0.559c0.526-0.33,1.22-0.172,1.55,0.354C244.935,205.068,244.888,205.593,244.598,205.957z" />
            <path fill="#D52B1E" d="M253.782,293.866v13.971c0,1.394-1.685,2.092-2.67,1.107l-0.769-0.769 c-0.639-0.639-0.998-1.505-0.998-2.408v-11.901H253.782z" />
            <path fill="#F9E300" d="M247.126,291.648v10.141c0,0.609,0.242,1.194,0.673,1.625l1.545,1.545v-13.311H247.126z" />
            <path fill="#007934" d="M244.908,291.648v5.442c0,0.777,0.309,1.522,0.857,2.071l1.361,1.361v-8.874H244.908L244.908,291.648 z" />
            <path fill="#D52B1E" d="M258.218,293.866v13.971c0,1.394,1.685,2.092,2.67,1.107l0.769-0.769 c0.639-0.639,0.998-1.505,0.998-2.408v-11.901H258.218z" />
            <path fill="#F9E300" d="M264.873,291.648v10.141c0,0.609-0.242,1.194-0.673,1.625l-1.545,1.545v-13.311H264.873z" />
            <path fill="#007934" d="M267.092,291.648v5.442c0,0.777-0.309,1.522-0.857,2.071l-1.361,1.361v-8.874H267.092 L267.092,291.648z" />
            <path fill="#D52B1E" d="M267.092,251.715l24.403-17.748v36.498c0,10.474-8.491,18.964-18.964,18.964l0,0 c-3.554,0-7.037-0.999-10.051-2.882l-13.135-8.21v-26.93L267.092,251.715z" />
            <path fill="#F9E300" d="M282.621,240.422v25.607c0,10.474-8.491,18.964-18.964,18.964c-1.575,0-3.127-0.227-4.64-0.61 l-9.673-6.046v-26.93l17.748,0.308L282.621,240.422z" />
            <path fill="#007934" d="M275.966,245.261v16.531c0,10.474-8.491,18.964-18.964,18.964c-1.575,0-3.128-0.227-4.64-0.61 l-3.018-1.886v-26.853l17.748,0.308L275.966,245.261z" />
            <polygon fill="#FFFFFF" points="292.826,229.974 302.588,225.094 295.488,233.967 291.495,233.967 " />
            <path fill="#D52B1E" d="M280.403,262.807l24.403-17.748v36.498c0,10.474-8.491,18.964-18.964,18.964l0,0 c-3.554,0-7.037-0.999-10.051-2.882l-13.135-8.21V262.5L280.403,262.807z" />
            <path fill="#007934" d="M289.277,256.354v16.531c0,10.474-8.491,18.964-18.964,18.964c-1.575,0-3.127-0.227-4.64-0.61 l-3.018-1.886v-26.853l17.748,0.308L289.277,256.354z" />
            <polygon fill="#FFFFFF" points="306.137,241.066 315.899,236.185 308.799,245.059 304.806,245.059 " />
            <path fill="#D52B1E" d="M273.747,258.37l24.403-17.748v36.498c0,10.474-8.491,18.964-18.964,18.964l0,0 c-3.554,0-7.037-0.999-10.051-2.882L256,284.992v-26.93L273.747,258.37z" />
            <path fill="#F9E300" d="M289.277,247.076v25.607c0,10.474-8.491,18.964-18.964,18.964c-1.575,0-3.127-0.227-4.64-0.61 l-9.674-6.045v-26.93l17.748,0.308L289.277,247.076z" />
            <path fill="#007934" d="M282.621,251.917v16.531c0,10.474-8.491,18.964-18.964,18.964c-1.575,0-3.127-0.227-4.64-0.61 l-3.018-1.885v-26.853l17.748,0.308L282.621,251.917z" />
            <polygon fill="#FFFFFF" points="299.482,236.629 309.243,231.748 302.143,240.622 298.151,240.622 " />
            <path fill="#D52B1E" d="M244.908,251.715l-24.403-17.748v36.498c0,10.474,8.491,18.964,18.964,18.964l0,0 c3.554,0,7.037-0.999,10.051-2.882l13.135-8.21v-26.93L244.908,251.715z" />
            <path fill="#F9E300" d="M229.378,240.422v25.607c0,10.474,8.491,18.964,18.964,18.964c1.575,0,3.127-0.227,4.64-0.61 l9.673-6.046v-26.93l-17.748,0.308L229.378,240.422z" />
            <path fill="#007934" d="M236.034,245.261v16.531c0,10.474,8.491,18.964,18.964,18.964c1.575,0,3.128-0.227,4.64-0.61 l3.018-1.886v-26.853l-17.748,0.308L236.034,245.261z" />
            <polygon fill="#FFFFFF" points="219.173,229.974 209.412,225.094 216.512,233.967 220.504,233.967 " />
            <path fill="#D52B1E" d="M231.597,262.807l-24.403-17.748v36.498c0,10.474,8.491,18.964,18.964,18.964l0,0 c3.554,0,7.037-0.999,10.051-2.882l13.135-8.21V262.5L231.597,262.807z" />
            <path fill="#007934" d="M222.723,256.354v16.531c0,10.474,8.491,18.964,18.964,18.964c1.575,0,3.127-0.227,4.64-0.61 l3.018-1.886v-26.853l-17.748,0.308L222.723,256.354z" />
            <polygon fill="#FFFFFF" points="205.862,241.066 196.101,236.185 203.201,245.059 207.193,245.059 " />
            <path fill="#D52B1E" d="M238.252,258.37l-24.403-17.748v36.498c0,10.474,8.491,18.964,18.964,18.964l0,0 c3.554,0,7.037-0.999,10.051-2.882l13.135-8.21v-26.93L238.252,258.37z" />
            <path fill="#F9E300" d="M222.723,247.076v25.607c0,10.474,8.491,18.964,18.964,18.964c1.575,0,3.127-0.227,4.64-0.61 l9.673-6.046v-26.93l-17.748,0.308L222.723,247.076z" />
            <path fill="#007934" d="M229.378,251.917v16.531c0,10.474,8.491,18.964,18.964,18.964c1.575,0,3.127-0.227,4.64-0.61 l3.018-1.886v-26.853l-17.748,0.308L229.378,251.917z" />
            <g>
                <path fill="#FFBE5A" d="M226.947,291.354l-2.925-2.925c-1.773-1.773-1.726-4.664,0.106-6.378l48.417-45.496l6.275,6.275 l-45.496,48.417C231.611,293.08,228.721,293.129,226.947,291.354z" />
                <circle fill="#FFBE5A" cx="225.49" cy="289.891" r="2.218" />
            </g>
            <ellipse transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 301.1342 604.1237)" fill="#FFFFFF" cx="275.685" cy="239.695" rx="4.436" ry="2.218" />
            <g>
                <path fill="#FFBE5A" d="M285.052,291.354l2.925-2.925c1.773-1.773,1.726-4.664-0.106-6.378l-48.417-45.496l-6.275,6.275 l45.496,48.417C280.389,293.08,283.279,293.129,285.052,291.354z" />
                <circle fill="#FFBE5A" cx="286.51" cy="289.891" r="2.218" />
            </g>
            <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -100.2758 237.3154)" fill="#FFFFFF" cx="236.327" cy="239.701" rx="4.436" ry="2.218" />
            <ellipse fill="#FFBE5A" cx="256" cy="266.141" rx="19.616" ry="23.294" />
            <polygon fill="#D52B1E" points="255.999,245.059 257.041,249.579 259.428,245.602 259.021,250.222 262.52,247.178 260.706,251.446 264.974,249.632 261.929,253.131 266.549,252.724 262.573,255.111 267.092,256.152 262.573,257.193 266.549,259.58 261.929,259.174 264.974,262.671 260.706,260.858 262.52,265.126 259.021,262.082 259.428,266.701 257.041,262.725 255.999,267.244 254.959,262.725 252.572,266.701 252.978,262.082 249.48,265.126 251.293,260.858 247.026,262.671 250.07,259.174 245.451,259.58 249.426,257.193 244.908,256.152 249.426,255.111 245.451,252.724 250.07,253.131 247.026,249.632 251.293,251.446 249.48,247.178 252.978,250.222 252.572,245.602 254.959,249.579 " />
            <path fill="#007934" d="M280.541,265.719c0,0-7.349,1.941-12.132-2.08c-5.316-4.469-9.302-6.579-11.01-7.371 c-0.585-0.271-1.239-0.271-1.825-0.001c-1.224,0.565-3.476,1.736-5.468,3.49c-2.912,2.566-11.855,7.626-13.657,7.626v11.029 l14.073,12.196l16.639-0.486L280.541,265.719z" />
            <path fill="#3CC8FF" d="M255.999,294.975c-14.068,0-25.513-12.938-25.513-28.84s11.444-28.84,25.513-28.84 s25.513,12.938,25.513,28.84S270.067,294.975,255.999,294.975z M255.999,243.95c-10.398,0-18.857,9.953-18.857,22.185 c0,12.232,8.459,22.185,18.857,22.185c10.398,0,18.857-9.953,18.857-22.185C274.856,253.903,266.397,243.95,255.999,243.95z" />
            <g>
                <path fill="#FFBE5A" d="M252.177,288.947l0.489,1.465l1.545,0.012c0.2,0.001,0.282,0.257,0.122,0.375l-1.242,0.918 l0.466,1.473c0.061,0.191-0.157,0.349-0.32,0.232l-1.257-0.898l-1.257,0.898c-0.162,0.116-0.38-0.042-0.32-0.232l0.466-1.473 l-1.242-0.918c-0.161-0.119-0.078-0.374,0.122-0.375l1.545-0.012l0.489-1.465C251.846,288.757,252.114,288.757,252.177,288.947z" />
                <path fill="#FFBE5A" d="M245.242,285.965l0.489,1.465l1.545,0.012c0.2,0.001,0.282,0.257,0.122,0.375l-1.242,0.918 l0.466,1.473c0.061,0.191-0.157,0.349-0.32,0.232l-1.257-0.898l-1.257,0.898c-0.162,0.116-0.38-0.042-0.32-0.232l0.466-1.473 l-1.242-0.918c-0.161-0.119-0.078-0.374,0.122-0.375l1.545-0.012l0.489-1.465C244.911,285.775,245.179,285.775,245.242,285.965z" />
                <path fill="#FFBE5A" d="M239.673,280.904l0.489,1.465l1.545,0.012c0.2,0.001,0.282,0.257,0.122,0.375l-1.242,0.918 l0.466,1.473c0.061,0.191-0.157,0.349-0.32,0.232l-1.257-0.898l-1.257,0.898c-0.162,0.116-0.38-0.042-0.32-0.232l0.466-1.473 l-1.242-0.918c-0.161-0.119-0.078-0.374,0.122-0.375l1.545-0.012l0.489-1.465C239.342,280.714,239.61,280.714,239.673,280.904z" />
                <path fill="#FFBE5A" d="M235.875,274.167l0.489,1.465l1.545,0.012c0.2,0.001,0.282,0.257,0.122,0.375l-1.242,0.918 l0.466,1.473c0.061,0.191-0.157,0.349-0.32,0.232l-1.257-0.898l-1.257,0.898c-0.162,0.116-0.38-0.042-0.32-0.232l0.466-1.473 l-1.242-0.918c-0.161-0.119-0.078-0.374,0.122-0.375l1.545-0.012l0.489-1.465C235.544,273.977,235.812,273.977,235.875,274.167z" />
                <path fill="#FFBE5A" d="M234.199,266.335l0.489,1.465l1.545,0.012c0.2,0.001,0.282,0.257,0.122,0.375l-1.242,0.918 l0.466,1.473c0.061,0.191-0.157,0.349-0.32,0.232l-1.257-0.898l-1.257,0.898c-0.162,0.116-0.38-0.042-0.32-0.232l0.466-1.473 l-1.242-0.918c-0.161-0.119-0.078-0.374,0.122-0.375l1.545-0.012l0.489-1.465C233.867,266.145,234.136,266.145,234.199,266.335z" />
                <path fill="#FFBE5A" d="M259.812,288.947l-0.489,1.465l-1.545,0.012c-0.2,0.001-0.283,0.257-0.122,0.375l1.242,0.918 l-0.466,1.473c-0.061,0.191,0.157,0.349,0.32,0.232l1.257-0.898l1.257,0.898c0.162,0.116,0.38-0.042,0.32-0.232l-0.466-1.473 l1.242-0.918c0.161-0.119,0.078-0.374-0.122-0.375l-1.545-0.012l-0.489-1.465C260.143,288.757,259.875,288.757,259.812,288.947z" />
                <path fill="#FFBE5A" d="M266.747,285.965l-0.489,1.465l-1.545,0.012c-0.2,0.001-0.282,0.257-0.122,0.375l1.242,0.918 l-0.466,1.473c-0.061,0.191,0.157,0.349,0.32,0.232l1.257-0.898l1.257,0.898c0.162,0.116,0.38-0.042,0.32-0.232l-0.466-1.473 l1.242-0.918c0.161-0.119,0.078-0.374-0.122-0.375l-1.545-0.012l-0.489-1.465C267.078,285.775,266.81,285.775,266.747,285.965z" />
                <path fill="#FFBE5A" d="M272.316,280.904l-0.489,1.465l-1.545,0.012c-0.2,0.001-0.282,0.257-0.122,0.375l1.242,0.918 l-0.466,1.473c-0.061,0.191,0.157,0.349,0.32,0.232l1.257-0.898l1.257,0.898c0.162,0.116,0.38-0.042,0.32-0.232l-0.466-1.473 l1.242-0.918c0.161-0.119,0.078-0.374-0.122-0.375l-1.545-0.012l-0.489-1.465C272.648,280.714,272.379,280.714,272.316,280.904z" />
                <path fill="#FFBE5A" d="M276.114,274.167l-0.489,1.465l-1.545,0.012c-0.2,0.001-0.282,0.257-0.122,0.375l1.242,0.918 l-0.466,1.473c-0.061,0.191,0.157,0.349,0.32,0.232l1.257-0.898l1.257,0.898c0.162,0.116,0.38-0.042,0.32-0.232l-0.466-1.473 l1.242-0.918c0.161-0.119,0.078-0.374-0.122-0.375l-1.545-0.012l-0.489-1.465C276.445,273.977,276.177,273.977,276.114,274.167z" />
                <path fill="#FFBE5A" d="M277.79,266.335l-0.489,1.465l-1.545,0.012c-0.2,0.001-0.282,0.257-0.122,0.375l1.242,0.918 l-0.466,1.473c-0.061,0.191,0.157,0.349,0.32,0.232l1.257-0.898l1.257,0.898c0.162,0.116,0.38-0.042,0.32-0.232l-0.466-1.473 l1.242-0.918c0.161-0.119,0.078-0.374-0.122-0.375l-1.545-0.012l-0.489-1.465C278.122,266.145,277.854,266.145,277.79,266.335z" />
            </g>
            <path fill="#FFFFFF" d="M246.361,280.357l0.737,3.526h0.318v-2.406c0,0,0.524,1.678,1.266,2.406h0.6 c-0.287-0.369-0.803-1.134-0.803-1.927c0-1.091,0.868-1.24,0.868-2.407h0.744v4.333h0.435l0.806-3.861c0,0,0.664,2.415,1.914,3.861 h0.556c-0.401-0.589-1.234-2.109-1.081-4.407c0,0,1.191-0.596,1.191-2.208v-2.294c0-0.429,0.349-0.778,0.778-0.778h0.535 c0,0-0.796-1.544-1.647-1.768v-0.968l-0.641,0.969c-0.612,0.132-0.973,0.698-0.973,1.304v1.397c0,0.429-0.348,0.778-0.778,0.778 h-2.781c-0.823,0-2.142,0.823-2.142,1.878s0.791,1.747,0.791,1.747L246.361,280.357z" />
            <path fill="#FFBE5A" d="M258.819,276.844h-0.255c-2.016,0.11-3.142,1.436-3.142,1.436l2.045,2.045v3.848h2.447v-3.848 l2.045-2.045C261.96,278.279,260.835,276.954,258.819,276.844z" />
            <g>
                <rect x="263.12" y="270.55" fill="#FFFFFF" width="0.739" height="13.634" />
                <path fill="#FFFFFF" d="M263.511,270.445l-3.987,4.819c0,0-0.52-2.323,1.803-4.61l-5.13,0.936c0,0,1.56-2.912,5.65-2.6 l-4.194-3.501c0,0,3.605-0.207,5.442,2.496c0,0,1.039-3.119,3.951-3.258l-2.403,3.651c0,0,2.403-1.225,5.731-0.15l-4.957,1.318 c0,0,2.773,0.277,3.917,4.333l-4.298-2.946c0,0,1.318,1.56,0.832,4.61L263.511,270.445z" />
                <polygon fill="#FFFFFF" points="212.518,236.629 202.756,231.748 209.856,240.622 213.849,240.622 " />
            </g>
            <path fill="#825546" d="M262.94,230.978l26.337-8.104l-13.981-5.242c-2.49-0.935-5.129-1.412-7.789-1.412h-2.633 c-1.225,0-2.218,0.993-2.218,2.218v2.218h-6.657h-6.655v-2.218c0-1.225-0.993-2.218-2.218-2.218h-2.633 c-2.66,0-5.299,0.479-7.789,1.412l-13.981,5.242l26.337,8.104c1.31,0.403,1.944,1.886,1.332,3.113l-3.266,6.531h17.748l-3.266-6.531 C260.995,232.865,261.629,231.382,262.94,230.978z" />
            <circle fill="#FFFFFF" cx="256" cy="222.87" r="4.436" />
            <path fill="#FFBE5A" d="M258.218,222.875L258.218,222.875c0,1.225-0.993,2.218-2.218,2.218h-8.874l3.787-3.787 c0.416-0.416,0.98-0.65,1.569-0.65h3.517C257.225,220.657,258.218,221.65,258.218,222.875z" />
        </svg>
    </React.Fragment>
);
