import * as React from "react";
import { css, jsx } from "@emotion/react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { programmeDetailPageLink } from "..";
import { WEBSITE_DOMAIN, WEBSITE_NAME, WEBSITE_TITLE } from "../../../constants";
import { ProgrammeDetail } from "../../../../graphql/queries/programmes.queries";

/** @jsx jsx */

export interface ProgrammeCardProps {
  programme: ProgrammeDetail;
}

export function ProgrammeMeta({
  programme
}: ProgrammeCardProps) {

  if( ! programme ) return <></>;

  let name = programme.url;
  let baseUrl = "Programme-";
  let baseUrlIndex : number = name.indexOf(baseUrl);
  if( baseUrlIndex > -1 )
    name = name.slice( baseUrlIndex + baseUrl.length );

  let title = WEBSITE_TITLE + " - " + name + " - " + programme.name + " | " + WEBSITE_NAME;
  let url = WEBSITE_DOMAIN + programmeDetailPageLink(programme.url, programme.id);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href={url} />
        <title>{title}</title>
        <meta property="og:url" content={url}/>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
      </Helmet>
    </HelmetProvider>
  );
}
