/** @jsx jsx */

import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Placeholder,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { DiagonalLineTopLeft } from "../../core/components/Line/diagonalLineTopLeft.component";
import { VerticalLineBottomLeft } from "../../core/components/Line/verticalLineBottomLeft.component";
import { renderLink } from "../../utils/helper";

const lint = jsx; // linter hack

export interface BannerBrandmarksContainerProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}


export const BannerBrandmarksContainer: React.FunctionComponent<BannerBrandmarksContainerProps> = (
    props: BannerBrandmarksContainerProps
    ): React.ReactElement => {
    const { fields, rendering } = props;

    const gradient: any = Number(getFieldValue<number>(fields, "gradient", 0));
    const topGreyGradient: any = Number(
        getFieldValue<number>(fields, "topGreyGradient", 0)
    );
    const greyGradient: any = Number(
        getFieldValue<number>(fields, "greyGradient", 0)
    );
    const invert = Number(getFieldValue<number>(fields, "invert", 0));
    const isLargeVideo = Boolean(
        getFieldValue<boolean>(fields, "setLargeVideo", false)
    );
    const isNude: boolean = getFieldValue<boolean>(fields, "nude", false);
    const isHalf: boolean = getFieldValue<boolean>(
        fields,
        "halfColumnSize",
        false
    );
    const isVerticallyAligned = Boolean(getFieldValue<boolean>(fields, "alignVertically", false));
    const isDarkGreenBackground = Number(
        getFieldValue<number>(fields, "isDarkGreenBackground", 0)
    );
    const isGreenBackground = Number(
        getFieldValue<number>(fields, "isGreenBackground", 0)
    );
    const anchorId: string = getFieldValue<string>(fields, "anchorId", "");
    const gradientType = getFieldValue<any>(fields, "gradientColor", null);
    const rounded = getFieldValue<boolean>(fields, "roundedComponent", false);
    const largeImage = getFieldValue<boolean>(fields, "largeImage", false);

    const diagonalLinePattern: number = getFieldValue<number>(
      fields, "diagonalLinePattern", 0
    );

    const verticalLinePattern: number = getFieldValue<number>(
      fields, "verticalLinePattern", 0
    );

    const background: any = getFieldValue<any>(fields, "componentBackground", null);

    const bg =
    fields && fields.componentBackground && fields.componentBackground.value.src
      ? `, url(${renderLink(background && background.src)}) no-repeat`
      : "";

    const getBackground = () => {
        if (gradientType === "apple100") {
            return `linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)${bg}`;
        }
        if (gradientType === "apple500") {
            return `linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)${bg}`;
        }
        if (gradientType === "gradientGreen") {
          return `linear-gradient(83deg, #166F3E 1.21%, rgba(46, 157, 71, 0.94) 41.77%, rgba(199, 214, 77, 0.75) 65.16%, rgba(199, 214, 77, 0.00) 90.12%)${bg}`;
        }
        if (gradientType === "peach100") {
            return `linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)${bg}`;
        }
        if (gradientType === "peach500") {
            return `linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)${bg}`;
        }
        if (gradientType === "gradientPeach") {
          return `linear-gradient(83deg, rgba(248, 159, 121, 0.60) 0.17%, rgba(248, 159, 121, 0.00) 100%)${bg}`;
        }
        if (gradientType === "gradientGrey") {
          return `linear-gradient(90deg, #253645 11.63%, rgba(37, 54, 69, 0.00) 85.53%), url(${renderLink(background && background.src)}) lightgray 50% / cover no-repeat`;
        }
        if (isNude) {
            return "none";
        }
        if (topGreyGradient === 1) {
            return "linear-gradient(180deg, #D9E1E2 0%, #F6F9F9 100%)";
        }
        if (greyGradient === 1) {
          return "linear-gradient(180deg, #253645 0%, #425563 100%)";
        }

        return "linear-gradient(270deg, #FFC300 6.1%, #FF3056 121.75%)";
    };

    const getBackgroundMobile = () => {
      if (gradientType === "gradientGrey") {
        return `linear-gradient(90deg, #253645 11.63%, rgba(37, 54, 69, 0) 180.53%)${bg}`;
      }
    };

    const style = {
        parentContainer: css`
          width: 100%;
          max-width: ${rounded ? "1400px" : "100%"};
          padding-left: ${rounded ? "128px" : "0"};
          padding-right: ${rounded ? "128px" : "0"};
          margin-bottom: ${rounded ? "64px" : "0"};
          margin-top: ${rounded ? "64px" : "0"};
          margin-left: auto;
          margin-right: auto;
  
          @media (max-width: ${breakpointMax}) {
            padding-left: ${rounded ? "16px" : "0"};
            padding-right: ${rounded ? "16px" : "0"};
          }
        `,
        container: css`
          display: flex;
          flex-direction: column;
          border-radius: ${rounded ? "16px" : "0"};
          background: ${getBackground()};
          ${isGreenBackground && "background-color: #008C3D"};
          ${isDarkGreenBackground && "background-color: #005E2E"};
  
          a {
            color: #22aa5f;
            text-decoration: none;
          }
  
          background-size: cover;
          width: 100%;
          position: relative;
          padding-bottom: 40px;
  
          @media (max-width: ${breakpointMax}) {
            padding: 0;
            flex-direction: column;
            background: ${getBackgroundMobile()};
          }
        `,
        mainContent: css`
          width: 100%;
          padding: 80px;
          padding-bottom: 0;
          max-width: ${largeImage ? "" : "1280px"};
          margin: auto;
          @media (max-width: ${breakpointMax}) {
            padding: 0;
            flex-direction: column;
          }
        `,
        containerDouble: css`
          display: flex;
          width: 100%;
          flex-direction: ${invert === 1
                ? "row-reverse"
                : isLargeVideo
                    ? "column-reverse"
                    : "row"};
          @media (max-width: ${breakpointMax}) {
            padding: 0;
            flex-direction: column-reverse;
          }
        `,
        containerDoubleLeft: css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: ${isHalf ? "50%" : isLargeVideo ? "100%" : "50%"};
          padding-left: ${isLargeVideo ? "200px" : "40px"};
          padding-right: ${isLargeVideo ? "200px" : "40px"};
          @media (max-width: ${breakpointMax}) {
            width: 100%;
            padding-right: 16px;
            padding-left: 16px;
          }
        `,
        containerDoubleRight: css`
          display: flex;
          width: ${isHalf ? "50%" : isLargeVideo ? "100%" : "50%"};
          flex-direction: column;
          align-items: center;
          padding-left: 40px;
          padding-right: 40px;
          @media (max-width: ${breakpointMax}) {
            width: 100%;
            padding-top: 40px;
            padding-bottom: 40px;
            padding-left: 0;
            padding-right: 0;
          }
        `,
        blocs: css`
          display: flex;
          flex-direction: column;
        `,
        leftContainer: css`
          width: 100%;
          //max-width: 368px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 80px;
          position: relative;
          @media (max-width: ${breakpointMax}) {
            padding-bottom: 40px;
          }
        `,
        backgroundDiagonalPattern: css`
        position: absolute;
        margin-left: 2px;
        top: 1px;
        z-index: 0;
        @media (max-width: ${breakpointMax}) {
          display: none;
        }
        `,
        backgroundVerticalPattern: css`
        position: absolute;
        margin-left: -5px;
        // top: 1px;
        bottom: -4px;
        z-index: 0;
        @media (max-width: ${breakpointMax}) {
          display: none;
        }
        `,
    };

  const bannerRef = React.useRef(null);

  React.useEffect(() => {
    if (anchorId) {
      const element = bannerRef.current;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [anchorId]);

  return (
    <div css={style.parentContainer} id={anchorId} ref={bannerRef}>
      <div css={style.container} id={anchorId}>
        {diagonalLinePattern ? (
          <div css={style.backgroundDiagonalPattern}>
            <DiagonalLineTopLeft>
            </DiagonalLineTopLeft>
          </div>
        ) : verticalLinePattern ? (
          <div css={style.backgroundVerticalPattern}>
            <VerticalLineBottomLeft
            />
          </div>
        ): null}
        <div css={style.mainContent}>
          <div css={style.containerDouble}>
            <div css={style.containerDoubleLeft}>
              <div css={style.leftContainer}>
                <Placeholder name="jss-banner-brandmarks-text" rendering={rendering} />
                <div css={style.blocs}>
                  <Placeholder name="jss-banner-brandmarks-card" rendering={rendering} />
                </div>
              </div>
            </div>
            <div css={style.containerDoubleRight} style={{ justifyContent: isVerticallyAligned ? "center" : "flex-start" }}>
              <Placeholder name="jss-banner-brandmarks-video-image" rendering={rendering} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
