import * as React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { SitecoreContext } from "../../interfaces";
import { ProgrammeCard } from "../../core/components/ProgrammeCard";
import { canUseDOM } from "../../predicates";

export interface ProgrammeCardParams {
  id: string;
}

export interface ProgrammeCardContentProps
  extends RouteComponentProps<ProgrammeCardParams> {
  sitecoreContext: SitecoreContext;
}

export function ProgrammeCardContentCore(props: ProgrammeCardContentProps) {
  const {
    sitecoreContext: { route },
    match
  } = props;
  const path = props?.location?.pathname ? props?.location.pathname.split('/').filter((elem: string) => elem !== '') : [];
  const search = new URLSearchParams(props?.location?.search);
  const id = path?.length >= 2 && path[2] ? path[2] : search ? search.get("id") : "";
  
  return (
    <ProgrammeCard id={id} />
  );
}

export const ProgrammeCardContent = withRouter(
  withSitecoreContext()(ProgrammeCardContentCore)
);
