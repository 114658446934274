import * as React from 'react';

import { Col, Grid, Row, Tag } from '@bdl-cmn-shared-packages-npm/design-system';
import { breakpoint, breakpointMax } from '../../../../style';
import { css, jsx } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';

import { GreenCard } from '../../../../interfaces/green-card';
import { SDGsList } from '../../UI/list/luxXPrime/sdgs-list';
import { Security } from '../../../../interfaces';
import SecuritySustainableKeyDocuments from './keyDocuments';
import {lgxEligibleCategoriesLabel } from '../../../../enums/lgx-eligible-categories';
import { lgxSustainableDevelopmentGoalLabel } from '../../../../enums/lgx-sustainable-development-goal';
import { reviewerLabel } from '../../../../enums/reviewer';
import { standardAndPrincipleLabel } from '../../../../enums/standard-and-principle';
import { style } from '../SecurityOverview/securityOverview.style';
import { targetPopulationLabel } from '../../../../enums/target-population';
import PromotionBanner from './promotion-banner/promotion-banner.component';
import { canUseDOM } from '../../../../predicates';
import { DisclaimerComponent } from '../../../../sitecore-components/DisclaimerComponent';

/** @jsx jsx */

export interface Row {
  label: string;
  action: () => void;
}

export interface SecuritySustainableDataProps {
  securityData: Security;
  greenCard: GreenCard;
}

const SecuritySustainableDataGreen = ({ greenCard }: SecuritySustainableDataProps) => {
  const preIssuanceInfo = greenCard?.preIssuanceInfo;
  const useOfProceeds = preIssuanceInfo?.useOfProceeds;

  const [targetPopulations, setTargetPopulations] = useState<string[]>([]);

  useEffect(() => {
    const targetPopulationsTmp = [];

    if (useOfProceeds?.targetsPopulation)
      useOfProceeds.targetsPopulation.forEach((element: string) => {
        targetPopulationsTmp.push(targetPopulationLabel(element));
      });

    if (useOfProceeds?.otherTargetPopulation) targetPopulationsTmp.push(useOfProceeds.otherTargetPopulation);

    setTargetPopulations(targetPopulationsTmp);
  }, [greenCard]);


  return (
    <div css={style.securityOverview}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '18px' }}>
        <PromotionBanner
          title='LGX'
          subTitle='Datahub'
          description='LGX DataHub turns 3, Dashboard goes free'
          actionLabel='Find out more'
          onActionClick={() =>
            canUseDOM &&
            (window.location.href =
              '/discover-lgx/additional-lgx-services/lgx-datahub?utm_source=luxsewebsite&utm_medium=banner_sustainable_data&utm_campaign=lgx_datahub_turn_3')
          }
        ></PromotionBanner>
      </div>

      <div css={style.sustainableDataTop}>
        <div css={style.securityOverviewTopLeftStart}>
          <div css={style.flexRow}>
            <div css={style.blocTitle}>LGX information</div>
            <div css={style.blocRowSpace}>
              <div css={style.blocRowKey}>Standards & Principles</div>
              <div css={style.blocRowValue}>
                {greenCard?.alignmentWithStandardAndPrinciples?.length
                  ? greenCard?.alignmentWithStandardAndPrinciples.map((element) => {
                    if (!element) return <></>;

                    return <div css={style.tagStyle}>{standardAndPrincipleLabel(element)}</div>;
                  })
                  : 'None'}
              </div>
            </div>
            <div css={style.blocRowSpace}>
              <div css={style.blocRowKey}>Eligible project categories</div>
              <div css={style.blocRowValue}>
                {useOfProceeds.hasNaturalGas &&  <div css={[style.tagStyle, style.highlightTag]}>{'Natural gas'}</div>}
                {useOfProceeds.hasNuclearEnergy &&  <div css={[style.tagStyle, style.highlightTag]}>{'Nuclear energy'}</div>}
                {useOfProceeds?.eligibleCategories?.length || useOfProceeds?.hasNaturalGas || useOfProceeds?.hasNuclearEnergy ? (
                  useOfProceeds?.eligibleCategories?.map((element) => {
                    return <div css={style.tagStyle}>{lgxEligibleCategoriesLabel(element)}</div>;
                  })
                ) : (
                  <div>No project eligibility category</div>
                )}
              </div>
            </div>
            {targetPopulations?.length ? (
              <div css={style.blocRowSpace}>
                <div css={style.blocRowKey}>Target population</div>
                <div css={style.blocRowValue}>
                  {targetPopulations.map((element) => {
                    return (
                      <div css={{ marginBottom: '5px', fontSize: '18px' }}>
                        <div css={style.tagStyle}>{element}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div css={style.blocRowSpace}>
              <div css={style.blocRowKey}>Second opinion provider</div>
              <div css={style.blocRowValue}>
                {preIssuanceInfo?.externalReviews?.length ? (
                  preIssuanceInfo.externalReviews.map((element) => {
                    if (!element.reviewer) return <></>;

                    return (
                      <div css={{ marginBottom: '5px', fontSize: '18px' }}>
                        <div
                          css={[
                            style.tagStyle,
                            css`
                              font-weight: bold !important;
                            `,
                          ]}
                        >
                          {reviewerLabel(element.reviewer)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>None</div>
                )}
              </div>
            </div>
            <div css={style.blocRowSpace}>
              <div css={style.blocRowKey}>Sustainable development Goals</div>
              <div css={style.blocRowValue}>
                {useOfProceeds?.contributedSdgs?.length ? (
                  <SDGsList sdgs={useOfProceeds.contributedSdgs} />
                ) : (
                  <div>No sustainable development goals</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <SecuritySustainableKeyDocuments
          postIssuanceDocs={greenCard?.postIssuanceDocs}
          preIssuanceDocs={greenCard?.preIssuanceDocs}
        />
      </div>

      <div css={style.sustainableDataBottom}>
        <div css={style.sustainableDataCardContainer}>
          <div css={style.descriptionCard}>
            <div css={style.descriptionCardTitle}>Use of proceeds</div>
            <div css={style.descriptionCardText}>{useOfProceeds?.useOfProceedsDescription || '-'}</div>
          </div>

          <div css={style.descriptionCardGrey}>
            <div css={style.descriptionCardTitle}>Process for project evaluation and selection</div>
            <div css={style.descriptionCardText}>{preIssuanceInfo?.evaluationAndSelectProcessDescription || '-'}</div>
          </div>
        </div>
        <div
          css={[
            style.sustainableDataCardContainer,
            css`
              @media (max-width: ${breakpointMax}) {
                flex-direction: column-reverse;
              }
            `,
          ]}
        >
          <div css={style.descriptionCardGrey}>
            <div css={style.descriptionCardTitle}>Management of proceeds</div>
            <div css={style.descriptionCardText}>{preIssuanceInfo?.mgmtOfProceedsDescription || '-'}</div>
          </div>

          <div css={style.descriptionCard}>
            <div css={style.descriptionCardTitle}>Reporting</div>
            <div css={style.descriptionCardText}>{preIssuanceInfo?.reportingDescription || '-'}</div>
          </div>
        </div>
      </div>

      <DisclaimerComponent
        fields={{
          title: { value: 'Disclaimer', editable: false },
          text: {
            value: `The sustainable data displayed on this page (the “Data”) is the result of LGX’s understanding of the information presented in the issuer’s publicly available sustainable bond documentation (e.g., framework, external review, post-issuance report). Although whole or part of the Data may come from sources reasonably believed to be reliable, LuxSE does not give any representation or warranty, whether express or implied, and accepts no responsibility or liability of any kind in connection with the accuracy, correctness, fitness for a particular purpose, reliability or completeness of the Data. LuxSE does not accept any liability or responsibility of any kind for any loss or damages arising directly or indirectly from the use of, or any action refrained from or taken on any Data.<br><br>
          Users are encouraged to always refer to the relevant sustainable bond documentation when interpreting sustainable data. The Data is not tailored for specific investors’ situations and may therefore not be suitable for all users.`,
            editable: false,
          },
        }}
      ></DisclaimerComponent>
    </div>
  );
};

export default SecuritySustainableDataGreen;
