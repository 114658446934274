import * as React from "react";
import { css, jsx } from "@emotion/react";
/** @jsx jsx */

import {
  Button,
  Icon,
  ToggleButtonGroup,
  ToggleItem,
  Tr,
} from "@bdl-cmn-shared-packages-npm/design-system";
import {
  Link,
  LinkField,
} from "@sitecore-jss/sitecore-jss-react";

import Loader from "../../core/components/Loader/loader.component";
import { Query } from "react-apollo";
import RoundTag from "../../core/components/RoundTag/roundTag.component";
import { computeCategoryLabel } from "../../core/components/UI/list/security/row";
import { formatDate } from "../../utils/date";
import { goToSecurityDetailPage } from "../../core/components/SecurityCard";
import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
import luxxprimeLogo from "./luxXPrime.svg";
import { renderLink } from "../../utils/helper";
import { sharedStyle } from "../../styles/shared";
import Utils from "../../core/utils/utils";
import { card, cardContainer } from "./style";
import { GET_LUXXPRIME_FALLERS_QUERY, GET_LUXXPRIME_MOST_TRADED_QUERY, GET_LUXXPRIME_RISERS_QUERY } from "../../graphql/queries/luxxprime.queries";

export interface LuxXPrimeHomeDataProps {
    link: LinkField;
}

const LuxXPrimeHomeData = (props: LuxXPrimeHomeDataProps) => {
    const [scrolled, setScrolled] = React.useState(false);

    const [securityFilter, setSecurityFilter] = React.useState(
      GET_LUXXPRIME_MOST_TRADED_QUERY
    );

    const [objectTarget, setObjectTarget] = React.useState<string>(
      "mostTradedLuxXPrimeSecurities"
    );

    const setSwitchTab = React.useCallback((tab: string) => {
        switch (tab) {
          case "risers":
            {
              setSecurityFilter(GET_LUXXPRIME_RISERS_QUERY);
              setObjectTarget("risersLuxXPrimeSecurities");
            }
            break;
          case "fallers":
            {
              setSecurityFilter(GET_LUXXPRIME_FALLERS_QUERY);
              setObjectTarget("fallersLuxXPrimeSecurities");
            }
            break;
          default: {
            setSecurityFilter(GET_LUXXPRIME_MOST_TRADED_QUERY);
            setObjectTarget("mostTradedLuxXPrimeSecurities");
          }
        }
      }, [setSecurityFilter, setObjectTarget]);
    
    return (<div css={card}>
      <div css={cardContainer}>
        <div css={sharedStyle.cardContainerTop}>
          <div css={sharedStyle.cardContainerTitle}>
            <img src={renderLink(luxxprimeLogo)} alt={"image"} />
          </div>
          <div css={sharedStyle.cardContainerActions}>
            <ToggleButtonGroup
              onChange={(e: any) => setSwitchTab(e)}
            >
              <ToggleItem value="top" selected>
                Top traded
              </ToggleItem>
              <ToggleItem value="risers">Risers</ToggleItem>
              <ToggleItem value="fallers">Fallers</ToggleItem>
            </ToggleButtonGroup>
          </div>
        </div>
        <div css={sharedStyle.cardContainerBody}>
          <Query<any>
            client={luxseGatewayGraphqlClient}
            query={securityFilter}
            variables={{
              limit: 5,
              token: Utils.getCookie("Gztoken-Dsm"),
            }}
            //pollInterval={30000}
            fetchPolicy="no-cache"
            errorPolicy="all"
          >
            {({ loading, error, data }): React.ReactNode => {
              const targetData = (data && data[objectTarget]) || [];
              return (
                <Loader
                  overflow={"auto"}
                  centered
                  loading={loading}
                  error={!!error}
                >
                  <table css={sharedStyle.table}>
                    <thead
                      style={{
                        background: "#F8FAFC",
                        color: "#253645",
                        fontSize: "0.8rem",
                        textAlign: "left",
                      }}
                    >
                      <tr>
                        <th css={sharedStyle.tableSecurity}>
                          Security
                        </th>
                        <th css={sharedStyle.tableDate}>
                          Maturity
                        </th>
                        <th css={sharedStyle.tableYield}>Yield</th>
                        <th css={sharedStyle.tablePrice}>
                          Price/Vari. (24h)
                        </th>
                      </tr>
                    </thead>
                    {/* {scrolled && <div css={sharedStyle.gradientTop} />} */}
                    <tbody
                      onScroll={(e) => {
                        setScrolled(
                          e.currentTarget.scrollTop !== 0
                        );
                      }}
                    >
                      {targetData && targetData.length > 0 ? (
                        targetData.map((m: any) => (
                          <Tr
                            onRowClick={() =>
                              goToSecurityDetailPage(m.isin, m.id)
                            }
                          >
                            <td css={sharedStyle.tableSecurity}>
                              <div css={sharedStyle.rowBond}>
                                <div css={sharedStyle.hideMobile}>
                                  <RoundTag
                                    small
                                    textColor={"#425563"}
                                  >
                                    {computeCategoryLabel(
                                      m.category
                                    )}
                                  </RoundTag>
                                </div>
                                {m.name}
                              </div>
                            </td>
                            <td css={sharedStyle.tableDate}>
                              {m && m.finalMaturityDate && formatDate(m.finalMaturityDate)}
                            </td>
                            <td css={sharedStyle.tableYield}>
                              {m && m.interestRate
                                ? `${m.interestRate}%`
                                : "N/A"}
                            </td>
                            <td css={sharedStyle.tablePrice}>
                              <div css={sharedStyle.double}>
                                <div css={sharedStyle.doubleTop}>
                                  {m &&
                                    m.marketData &&
                                    m.marketData.lastPrice &&
                                    m.marketData.lastPrice
                                      .amount}{" "}
                                  {m &&
                                    m.marketData &&
                                    m.marketData.lastPrice &&
                                    m.marketData.lastPrice.marker}
                                </div>
                                <div
                                  css={sharedStyle.doubleBottom}
                                  style={{
                                    color:
                                      m &&
                                      m.marketData &&
                                      m.marketData.dailyChange &&
                                      m.marketData.dailyChange < 0 // we can't check for >= 0 because 0 is a falsy value
                                        ? "#AC1234"
                                        : "#22AA5F",
                                  }}
                                >
                                  {m &&
                                    m.marketData &&
                                    m.marketData &&
                                    `${m.marketData.dailyChange} %`}{" "}
                                  <Icon
                                    icon={`chevron-${
                                      m &&
                                      m.marketData &&
                                      m.marketData.dailyChange &&
                                      m.marketData.dailyChange < 0  // we can't check for >= 0 because 0 is a falsy value
                                        ? "down"
                                        : "up"
                                    }`}
                                  />
                                </div>
                              </div>
                            </td>
                          </Tr>
                        ))
                      ) : (
                        <tr>
                          <td css={sharedStyle.tableSecurity}>-</td>
                          <div css={sharedStyle.hideMobile}>
                            <td css={sharedStyle.tableDate}>-</td>
                          </div>
                          <div css={sharedStyle.hideMobile}>
                            <td css={sharedStyle.tableYield}>-</td>
                          </div>
                          <td css={sharedStyle.tablePrice}>
                            <div css={sharedStyle.double}>-</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {targetData && targetData.length > 7 && (
                      <div css={sharedStyle.gradientBottom} />
                    )}
                  </table>
                </Loader>
              );
            }}
          </Query>
          <div
            css={sharedStyle.cardContainerFooter}
            //onClick={() => (window.location.href = '/search?dataType=luxXPrime')}
          >
            <Link
              target={"_self"}
              title={"link"}
              field={props.link}
              css={{ textDecoration: "none" }}
            >
              <div css={sharedStyle.hideDesktop}>
                <Button
                  label={"View all LuxXPrime securities"}
                  variant={"primary"}
                  wired
                  iconRight={"chevron-right"}
                />
              </div>
              <div css={sharedStyle.hideMobile}>
                <span>View all LuxXPrime securities</span>{" "}
                <Icon icon={"chevron-right"} />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>);
}

export default LuxXPrimeHomeData;