import { css } from "@emotion/react";

export const style = {
  container: css`
    width : 2rem;
    height : 2rem;
    line-height: 2rem;
    text-align: center;
    color: #22AA5F;
    border: 1px solid #D9DEE2;
    border-radius: 4px;
  `,
  validated: css`
    color: #22AA5F;
    font-size: 1.5rem;
  `,
  cancelled: css`
    color: #cb1234;
    font-size: 1.5rem;
  `,
  refreshed: css`
    color: #1c7ed6;
    font-size: 1.5rem;
  `,

};
