import * as React from 'react';

import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';
import { Author } from '../../core/components/Author';
import { SitecoreGenericTextField } from '../../interfaces';
import placeholder from './placeholder.png';
import { renderLink } from '../../utils/helper';

/** @jsx jsx */

export interface HomeComingNextComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const style = {
  container: css`
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin: auto;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      margin-top: 0;
    }
  `,
  title: css`
    margin-bottom: 48px;
    width: 100%;
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    text-align: center;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 32px;
      line-height: 41px;
      text-align: center;
      margin-top: 40px;
    }
  `,
  content: css`
    width: 100%;
    display: flex;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  left: css`
    width: 55%;
    display: flex;
    margin-left: 120px;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      margin: 0;
      width: 100%;
      padding: 16px;
    }
  `,
  right: css`
    width: 45%;
    margin-left: 104px;
    @media (max-width: ${breakpointMax}) {
      margin: 0;
      width: 100%;
      padding: 16px;
    }
  `,
  picture: css`
    width: 100%;
    @media (max-width: ${breakpointMax}) {
      img {
        width: inherit;
      }
    }
  `,
  subTitle: css`
    width: 100%;
    font-weight: 800;
    font-size: 30px;
    line-height: 38px;
    margin-top: 32px;
    margin-bottom: 16px;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
    }
  `,
  description: css`
    width: 100%;
    color: #354450;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
    }
  `,
  calendar: css`
    background: #E8ECEE;
    border-radius: 8px;
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    padding: 48px 32px;
    @media (max-width: ${breakpointMax}) {
      max-width: 100%;
    }
  `,
  day: css`
    color: #ca1234;
    font-weight: 800;
    font-size: 72px;
    line-height: 64px;
    text-align: center;
  `,
  month: css`
    color: #253645;
    font-size: 24px;
    font-weight: 800;
    line-height: 31px;
    text-align: center;
    margin-bottom: 24px;
  `,
  eventTitle: css`
    color: #253645;
    font-size: 24px;
    text-align: center;
    font-weight: 800;
    margin-bottom: 16px;
  `,
  eventSubTitle: css`
    color: #253645;
    font-size: 16px;
    line-height: 31px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  `,
  event: css`
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
    svg {
      color: #22AA5F;
    }
  `,
  addAction: css`
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      font-size: 16px;
      font-weight: 600;
    }
  `,
};

export const HomeComingNextComponent: React.FunctionComponent<HomeComingNextComponentProps> = (
  props: HomeComingNextComponentProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  return (
    <div css={style.container}>
      <div css={style.title}>
        <Text field={fields && (fields.title as SitecoreGenericTextField)} />
      </div>
      <div css={style.content}>
        <div css={style.left}>
          <div css={style.picture}>
            <img src={renderLink(placeholder)} alt={"image"} />
          </div>
          <div css={style.subTitle}>LuxSE contributes to new whitepaper on Green Bonds</div>
          <div css={style.description}>
            The Luxembourg Stock Exchange (LuxSE) joins other players in the sustainable finance field in Luxembourg...
          </div>
          <Author />
        </div>
        <div css={style.right}>
          <div css={style.calendar}>
            <div css={style.day}>23</div>
            <div css={style.month}>March</div>
            <div css={style.eventTitle}>International Women's Day</div>
            <div css={style.eventSubTitle}>Ring the bell for Gender Equality</div>
            <div css={style.event}>
              <Icon icon={'map-marker-alt'} /> Virtual event
            </div>
            <div css={style.addAction}>
              {/*<ICalendarLink event={} >
                <Button label={'Add to my calendar'} iconLeft={'calendar-plus'} variant='secondary'/>
              </ICalendarLink>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
