import * as React from "react";

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
  Link,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx, keyframes } from "@emotion/react";

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from "../../interfaces";
import { fetchData, getDataWithChild } from "../../utils/helper";

/** @jsx jsx */

export interface GreyBannerTextProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const GreyBannerText: React.FunctionComponent<GreyBannerTextProps> = (
  props: GreyBannerTextProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const noMarginTop: number = getFieldValue<number>(fields, "noMarginTop", 0);
  const isSplit: number = getFieldValue<number>(fields, "splitCols", 0);
  const isGreen: number = getFieldValue<number>(fields, "isGreen", 0);
  const isWhite: number = getFieldValue<number>(fields, "isWhite", 0);
  const buttonIcon: string = getFieldValue<string>(fields, "button icon", "");
  const buttonLabel: string = getFieldValue<string>(fields, "button label", "");

  const style = {
    parentContainer: css`
      display: flex;
      flex-direction: column;
      background: ${isGreen ? "#008C3D" : isWhite ? "#ffffff" : "#F6F9F9"};
      justify-content: center;
      width: 100%;
      padding: 80px;

      a {
        text-decoration: none;
        color: #22aa5f;
      }

      @media (max-width: ${breakpointMax}) {
        padding: 16px;
        padding-top: 40px;
        padding-bottom: 40px;
      }
      ${noMarginTop && `padding-top: 0`};
    `,
    container: css`
      display: flex;
      max-width: 750px;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    `,
    headLine: css`
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.01em;
      color: #253645;
      margin-bottom: 8px;
    `,
    containerTexts: css`
      display: flex;
      flex-direction: column;
      max-width: 1000px;
      justify-content: center;
      //margin: auto;
    `,
    containerTextsTitle: css`
      color: ${isGreen ? "#FFFFFF" : "#253845"};
      font-weight: 800;
      font-size: 2em;
      @media (max-width: ${breakpointMax}) {
        font-size: 32px;
        line-height: 31px;
        font-weight: 700;
      }
    `,
    containerTextsContent: css`
      columns: ${isSplit ? "2" : "1"};
      font-weight: 500;
      color: ${isGreen ? "#FFFFFF" : "#253845"};
      margin-top: 32px;
      line-height: 150%;
      font-size: 16px;
      @media (max-width: ${breakpointMax}) {
        columns: 1;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
        letter-spacing: 0.01em;
      }
    `,
    author: css`
      color: #ffffff;
      font-weight: 800;
      font-size: 16px;
      margin-top: 32px;
    `,
    role: css`
      color: #ffffff;
      font-weight: 800;
      font-size: 16px;
      margin-top: 8px;
    `,
    actions: css`
      margin-top: 16px;
    `,
    link: css`
      text-decoration: none;
    `,
  };

  const [dictionaryItems, setDictionaryItems] = React.useState<{ [key: string]: string }>( {});
  const [isLoading, setIsLoading] = React.useState(true);

    const getDictionaryItems = async (id: string) => {
      try {
        const result = await fetchData(id);
        const dataReq = await getDataWithChild(result);
        const items: { [key: string]: string } = {};

        if (dataReq && dataReq.length > 0) {
          for (const d of dataReq) {
            if (d.children && d.children.length > 0) {
              for (const item of d.children) {
                const key = item.Key; 
                const phrase = item.Phrase;
                if (key && phrase) {
                  items[key] = phrase;
                }
              }
            }
          }
        } else {
          console.warn('No data found:', dataReq);
        }
        setDictionaryItems(items);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching dictionary items:', err);
      }
    };

    React.useEffect(() => {
      getDictionaryItems('504AE189-9F36-4C62-9767-66D73D6C3084');
    }, []);

    const replaceKeysWithPhrases = (text: string, dictionary: { [key: string]: string }) => {
      return text.replace(/\[([^\]]+)\]/g, (match, key) => dictionary[key] || key);
    };

  const transformedContent = replaceKeysWithPhrases(fields.text.value, dictionaryItems);

  return (
    <div css={style.parentContainer}>
      <div css={style.container}>
        <div css={style.containerTexts}>
          <div css={style.containerTextsTitle}>
            {fields && fields.headLine && (fields.headLine as any).value && (
              <div css={style.headLine}>
                <Text
                  field={
                    fields && (fields.headLine as SitecoreGenericTextField)
                  }
                />
              </div>
            )}
            <Text
              field={fields && (fields.title as SitecoreGenericTextField)}
            />
            {fields && fields.subTitle && (fields.subTitle as any).value && (
              <div css={style.headLine}>
                <Text
                  field={
                    fields && (fields.subTitle as SitecoreGenericTextField)
                  }
                />
              </div>
            )}
          </div>
          <div css={style.containerTextsContent}>
            <div
              dangerouslySetInnerHTML={{
                __html: transformedContent,
              }}
            />
          </div>
          {fields && fields.author && (fields.author as any).value && (
            <div css={style.author}>
              <Text
                field={fields && (fields.author as SitecoreGenericTextField)}
              />
            </div>
          )}
          {fields && fields.role && (fields.role as any).value && (
            <div css={style.role}>
              <Text
                field={fields && (fields.role as SitecoreGenericTextField)}
              />
            </div>
          )}
          {buttonLabel && (
            <div css={style.actions}>
              <Link
                target={
                  fields[`button link page`]?.value?.linktype === "external"
                    ? "_blank"
                    : "_self"
                }
                title={buttonLabel}
                field={fields[`button link page`]}
                css={style.link}
              >
                <Button label={buttonLabel} iconRight={buttonIcon as any} />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
