import * as React from 'react';
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Link,
} from '@sitecore-jss/sitecore-jss-react';
/** @jsx jsx */
import { style } from './index.style';
import caroussel from './caroussel.png';
import logo from './luxXPrime.svg';
import { css, jsx, keyframes } from '@emotion/react';
import { Icon, Button } from '@bdl-cmn-shared-packages-npm/design-system';
import { SitecoreGenericTextField } from '../../interfaces';
import {renderLink, transformLink} from '../../utils/helper';
import {canUseDOM} from "../../predicates";

export interface LuxXPrimeDiscoverProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const LuxXPrimeDiscover: React.FunctionComponent<LuxXPrimeDiscoverProps> = (
  props: LuxXPrimeDiscoverProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const btnLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
  const btnLink: string = getFieldValue<string>(fields, 'btnLink', '');
  const btnPageLink: any = getFieldValue<any>(fields, 'button link', null);
  const mainImage: any = getFieldValue<any>(fields, 'main image', null);


  return (
    <div css={style.background}>
      <div css={style.container}>
        <div css={style.caroussel}>
          <img src={renderLink(mainImage?.src)} alt={"image"} />
        </div>
        <div css={style.content}>
          <div css={style.logo}>
            <img src={renderLink(logo)} alt={"image"} />
          </div>
          <div css={style.title}>
            <Text field={fields && (fields.title as SitecoreGenericTextField)} />
          </div>
          <div css={style.description}>
            <Text field={fields && (fields.description as SitecoreGenericTextField)} />
          </div>
          {btnLabel && (
            <div css={style.action}>
              {
                btnPageLink && btnPageLink?.href ? (
                    <Link target={'_self'} title={'link'} field={btnPageLink} style={{textDecoration: 'none'}}>
                      <Button
                          label={btnLabel}
                          onClick={() => (btnLink ? (canUseDOM && window.open(btnLink, '_self')) : console.log)}
                          iconRight='chevron-right'
                      />
                    </Link>
                ) : (
                    <Button
                        label={btnLabel}
                        onClick={() => (btnLink ? (canUseDOM && window.open(btnLink, '_self')) : console.log)}
                        iconRight='chevron-right'
                    />
                )
              }

            </div>
          )}
        </div>
      </div>
    </div>
  );
};
