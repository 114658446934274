import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import ChartOptions from './chart-option';
import { style } from '../index.style';
import { IndiceSummary } from '../../../../../../interfaces/indice';
import GraphCard from '../../../../GraphCard/graphCard.component';
import { goToIndexDetailPage } from '../../../../Index/Index-details';


export const IndexTradingView = ({indexes}: {indexes : IndiceSummary[]}) => {

  return (
    <div css={style.tablePart}>
      {indexes && indexes?.length > 0 ? (
        <div css={{ width: '100%', borderSpacing: '5px' }}>
            {indexes.map((index: IndiceSummary) => (
              <div style={{ boxShadow: 'none', marginTop: '4px', cursor: "pointer" }}
                onClick={() => goToIndexDetailPage(index.id)}
                key={`graph-indice-div-${index.id}`}
              >
                <GraphCard
                  key={`graph-indice-${index.id}`}
                  heightChart={200}
                  chartOptions={ChartOptions}
                  data={index.indexPrice}
                  label={index.indexName}
                />
              </div>
            ))}
        </div>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};
export default IndexTradingView;
