
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface CleanWaterAndSanitationSvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const CleanWaterAndSanitationSvg: React.FunctionComponent<CleanWaterAndSanitationSvgProps> = ({ size: { width, height }, style }: CleanWaterAndSanitationSvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 46 51'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>6 - Clean Water and Sanitation</title>
            <path d='M3.07901 13.2857C5.23785 13.2857 6.07677 12.1075 6.07677 10.232V8.44602C6.07677 6.46616 5.29005 5.4669 3.82845 5.4669C2.97088 5.4669 2.45634 5.8584 2.1506 6.44751H2.13196V3.84125C2.13196 3.11045 2.36313 2.59218 3.09393 2.59218C3.82472 2.59218 4.04098 3.02096 4.04098 3.77041V4.57205H5.8978V3.93073C5.8978 2.14475 5.30869 0.899414 3.06037 0.899414C0.920176 0.899414 0.114807 2.27152 0.114807 4.16563V10.0568C0.114807 11.9285 0.935091 13.2857 3.07901 13.2857ZM2.13196 8.19994C2.16924 7.52134 2.45261 7.09255 3.09393 7.09255C3.79117 7.09255 4.05589 7.52134 4.05589 8.30434V10.411C4.05589 11.1791 3.77252 11.6228 3.07528 11.6228C2.39669 11.6228 2.12823 11.1604 2.12823 10.411V8.19994H2.13196Z' fill='white'/>
            <path d='M35.0776 20.2615C35.0254 20.2056 34.9583 20.1758 34.88 20.1758H13.1126C13.0381 20.1758 12.9672 20.2056 12.9188 20.2615C12.8703 20.3212 12.8516 20.3958 12.8628 20.4666L16.5205 42.5844C16.5392 42.7075 16.6473 42.797 16.7704 42.797H21.2595L21.5653 46.3988L19.6749 46.4025L24.0709 50.7985L28.478 46.395L26.5914 46.3988L26.8971 42.8007H31.4646C31.5914 42.8007 31.6958 42.7112 31.7144 42.5844L35.1372 20.4703C35.1484 20.392 35.1261 20.3212 35.0776 20.2615ZM26.666 37.9685C26.666 37.9685 25.6779 38.9864 24.0672 38.8745C22.4564 38.9864 21.4683 37.9685 21.4683 37.9685C19.839 36.3391 20.4206 34.3108 20.9874 33.263C21.3378 32.6068 23.8434 28.8447 24.0597 28.524V28.4979C24.0597 28.4979 24.0634 28.5017 24.0709 28.5091L24.0783 28.4979V28.524C24.2871 28.8447 26.7965 32.6068 27.1469 33.263C27.7137 34.3145 28.2953 36.3391 26.666 37.9685ZM33.5713 24.9148C32.9821 24.6538 32.2327 24.747 31.5168 25.0378C28.8173 26.134 26.9792 26.5255 24.138 25.6381C22.3483 25.0788 20.6742 23.9043 18.698 24.6202C17.3856 25.0938 16.1626 25.5449 14.6786 25.53L13.8211 21.134H34.1939L33.5713 24.9148Z' fill='white'/>
            <path d='M11.0656 13.1179L11.9903 8.21484H12.9299L13.862 13.1179H13.0157L12.8628 12.223H11.9866L11.8412 13.1179H11.0656ZM12.0984 11.5631H12.7547L12.4303 9.59814H12.4228L12.0984 11.5631Z' fill='white'/>
            <path d='M14.8986 10.0381V13.1179H14.2051V8.21484H15.0142L15.9016 11.0262V8.21484H16.5876V13.1179H15.8643L14.8986 10.0381Z' fill='white'/>
            <path d='M19.6525 9.53103V11.8017C19.6525 12.5549 19.343 13.1179 18.4594 13.1179H17.1879V8.21484H18.4594C19.3393 8.21484 19.6525 8.77413 19.6525 9.53103ZM18.3587 12.4468C18.698 12.4468 18.8285 12.2454 18.8285 11.9471V9.37816C18.8285 9.08733 18.698 8.88599 18.3587 8.88599H18.0119V12.443H18.3587V12.4468Z' fill='white'/>
            <path d='M21.4907 12.0625V11.4063H22.2587V12.0774C22.2587 12.3533 22.3818 12.5099 22.6652 12.5099C22.9262 12.5099 23.0417 12.3347 23.0417 12.0774V11.891C23.0417 11.6076 22.9262 11.4361 22.6726 11.1975L22.1879 10.7128C21.7181 10.2504 21.5018 9.96704 21.5018 9.40403V9.23625C21.5018 8.62849 21.8561 8.15869 22.6577 8.15869C23.4817 8.15869 23.8061 8.5651 23.8061 9.27353V9.67249H23.0529V9.24743C23.0529 8.96406 22.9224 8.82983 22.6614 8.82983C22.4303 8.82983 22.2699 8.96033 22.2699 9.23625V9.33692C22.2699 9.61283 22.4228 9.76943 22.6614 10.0006L23.1909 10.5226C23.6383 10.9775 23.8546 11.2534 23.8546 11.794V12.0327C23.8546 12.7038 23.4929 13.1811 22.654 13.1811C21.815 13.1773 21.4907 12.7075 21.4907 12.0625Z' fill='white'/>
            <path d='M24.0932 13.1179L25.0179 8.21484H25.9575L26.8896 13.1179H26.0432L25.8904 12.223H25.0142L24.8687 13.1179H24.0932ZM25.126 11.5631H25.7822L25.4579 9.59814H25.4504L25.126 11.5631Z' fill='white'/>
            <path d='M27.9858 10.0381V13.1179H27.2923V8.21484H28.1014L28.9888 11.0262V8.21484H29.6749V13.1179H28.9515L27.9858 10.0381Z' fill='white'/>
            <path d='M30.3348 8.21484H31.1589V13.1179H30.3348V8.21484Z' fill='white'/>
            <path d='M31.5578 8.21484H33.7502V8.88599H33.0716V13.1179H32.2401V8.88971H31.5615V8.21484H31.5578Z' fill='white'/>
            <path d='M33.5526 13.1179L34.4772 8.21484H35.4168L36.349 13.1179H35.5026L35.3497 12.223H34.4735L34.3281 13.1179H33.5526ZM34.5891 11.5631H35.2453L34.9209 9.59814H34.9135L34.5891 11.5631Z' fill='white'/>
            <path d='M36.0917 8.21484H38.2841V8.88599H37.6055V13.1179H36.7741V8.88971H36.0955V8.21484H36.0917Z' fill='white'/>
            <path d='M38.7166 8.21484H39.5406V13.1179H38.7166V8.21484Z' fill='white'/>
            <path d='M40.085 11.8537V9.48233C40.085 8.71425 40.4616 8.15869 41.3304 8.15869C42.2066 8.15869 42.5832 8.71425 42.5832 9.48233V11.8537C42.5832 12.6143 42.2066 13.1773 41.3304 13.1773C40.4616 13.1773 40.085 12.6106 40.085 11.8537ZM41.7554 11.9842V9.35183C41.7554 9.05355 41.6249 8.84475 41.3304 8.84475C41.0395 8.84475 40.9128 9.05355 40.9128 9.35183V11.9842C40.9128 12.2825 41.0433 12.4913 41.3304 12.4913C41.6249 12.4875 41.7554 12.2787 41.7554 11.9842Z' fill='white'/>
            <path d='M43.8173 10.0381V13.1179H43.1238V8.21484H43.9329L44.8203 11.0262V8.21484H45.5063V13.1179H44.783L43.8173 10.0381Z' fill='white'/>
            <path d='M11.1588 4.6203V2.24893C11.1588 1.48085 11.5205 0.925293 12.3892 0.925293C13.299 0.925293 13.5525 1.43238 13.5525 2.15572V2.73364H12.7994V2.0886C12.7994 1.78659 12.6987 1.61135 12.4004 1.61135C12.1059 1.61135 11.9828 1.82015 11.9828 2.11843V4.7508C11.9828 5.04908 12.1059 5.25788 12.4004 5.25788C12.6987 5.25788 12.7994 5.07145 12.7994 4.78062V3.87831H13.5525V4.7247C13.5525 5.42567 13.2617 5.94766 12.3892 5.94766C11.5205 5.94394 11.1588 5.38092 11.1588 4.6203Z' fill='white'/>
            <path d='M14.0708 0.984863H14.8948V5.21678H15.8791V5.88792H14.0708V0.984863Z' fill='white'/>
            <path d='M16.3266 0.984863H18.2953V1.656H17.1543V3.05794H17.9709V3.72163H17.1543V5.21305H18.2953V5.88419H16.3266V0.984863Z' fill='white'/>
            <path d='M18.6793 5.8845L19.604 0.981445H20.5436L21.4757 5.8845H20.6294L20.4765 4.98965H19.6003L19.4549 5.8845H18.6793ZM19.7121 4.32969H20.3684L20.044 2.36474H20.0365L19.7121 4.32969Z' fill='white'/>
            <path d='M22.5831 2.8044V5.88419H21.8896V0.984863H22.6987L23.5861 3.7962V0.984863H24.2721V5.88792H23.5488L22.5831 2.8044Z' fill='white'/>
            <path d='M27.8068 2.8566L27.3445 5.88792H26.5689L25.9127 0.984863H26.7069L27.0909 4.09448H27.0984L27.5123 0.984863H28.1834L28.6383 4.09448H28.6458L29.0223 0.984863H29.7159L29.0783 5.88792H28.299L27.8143 2.8566H27.8068Z' fill='white'/>
            <path d='M29.686 5.8845L30.6107 0.981445H31.5503L32.4824 5.8845H31.6361L31.4832 4.98965H30.6144L30.469 5.8845H29.686ZM30.7226 4.32969H31.3788L31.0544 2.36474H31.047L30.7226 4.32969Z' fill='white'/>
            <path d='M32.3855 0.984863H34.5779V1.656H33.8956V5.88792H33.0641V1.656H32.3855V0.984863Z' fill='white'/>
            <path d='M34.9731 0.984863H36.9418V1.656H35.8009V3.05794H36.6174V3.72163H35.8009V5.21305H36.9418V5.88419H34.9731V0.984863Z' fill='white'/>
            <path d='M38.2133 3.63587V5.88419H37.3893V0.984863H38.5899C39.4214 0.984863 39.7234 1.39873 39.7234 2.08479V2.71119C39.7234 3.24064 39.5369 3.55011 39.0671 3.65824L39.8539 5.88419H38.9851L38.2133 3.63587ZM38.2133 1.656V3.18099H38.4892C38.7726 3.18099 38.8956 3.03557 38.8956 2.77457V2.05123C38.8956 1.78278 38.7726 1.65228 38.4892 1.65228H38.2133V1.656Z' fill='white'/>

        </svg>
    );
};
