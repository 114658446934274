
/*
*   e-Listing - core - components - country flag icon - nld
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const AUS: JSX.Element = (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
        <path fill="#00008B" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
        <path fill="#FFFFFF" d="M247.172,88.277h-15.334l-81.77,53.574V88.277H105.93v53.574L24.16,88.277H8.828 C3.953,88.277,0,92.229,0,97.104v7.002l70.155,45.963H0v44.138h70.155L0,240.171v7.002c0,4.875,3.953,8.828,8.828,8.828h15.334 l81.77-53.574v53.574h44.138v-53.574l81.769,53.574h15.334c4.875,0,8.828-3.953,8.828-8.828v-7.002l-70.155-45.963H256V150.07 h-70.155L256,104.107v-7.002C256,92.229,252.047,88.277,247.172,88.277z" />
        <g>
            <polygon fill="#FF4B55" points="256,158.898 141.241,158.898 141.241,88.277 114.759,88.277 114.759,158.898 0,158.898 0,185.38 114.759,185.38 114.759,256.001 141.241,256.001 141.241,185.38 256,185.38 " />
            <path fill="#FF4B55" d="M97.103,150.07L4.207,89.769C1.735,91.333,0,93.962,0,97.104v0.458l80.889,52.508H97.103z" />
            <path fill="#FF4B55" d="M166.283,150.07l88.374-57.366c-1.536-2.599-4.246-4.427-7.485-4.427h-1.909l-95.194,61.793H166.283 z" />
            <path fill="#FF4B55" d="M89.474,194.208L1.269,251.464c1.521,2.662,4.273,4.537,7.559,4.537h1.665l95.196-61.793H89.474z" />
            <path fill="#FF4B55" d="M255.86,247.866l-82.666-53.658H156.98l93.874,60.935 C253.666,253.838,255.595,251.103,255.86,247.866z" />
        </g>
        <g>
            <path fill="#FFFFFF" d="M130.671,304.131l6.663,21.997l21.353-8.505c1.822-0.726,3.478,1.352,2.366,2.966l-13.044,18.924 l19.962,11.391c1.703,0.972,1.112,3.562-0.844,3.699l-22.929,1.601l3.541,22.71c0.302,1.938-2.092,3.091-3.418,1.646 l-15.548-16.928l-15.548,16.928c-1.326,1.444-3.72,0.291-3.418-1.646l3.541-22.71l-22.929-1.601 c-1.956-0.137-2.547-2.727-0.844-3.699l19.962-11.391l-13.044-18.924c-1.112-1.614,0.544-3.692,2.366-2.966l21.353,8.505 l6.663-21.997C127.446,302.254,130.103,302.254,130.671,304.131z" />
            <path fill="#FFFFFF" d="M393.279,147.73l3.255,10.745l10.43-4.154c0.889-0.354,1.699,0.66,1.155,1.449l-6.371,9.245 l9.751,5.565c0.832,0.474,0.543,1.74-0.413,1.806l-11.2,0.782l1.729,11.093c0.148,0.947-1.022,1.51-1.67,0.804l-7.595-8.269 l-7.595,8.269c-0.648,0.705-1.817,0.142-1.67-0.804l1.729-11.093l-11.2-0.782c-0.956-0.066-1.245-1.332-0.413-1.806l9.751-5.565 l-6.371-9.245c-0.544-0.789,0.266-1.803,1.155-1.449l10.43,4.154l3.255-10.745C391.703,146.813,393.002,146.813,393.279,147.73z" />
            <path fill="#FFFFFF" d="M320.702,230.206l3.255,10.745l10.43-4.154c0.889-0.354,1.699,0.66,1.155,1.449l-6.371,9.245 l9.751,5.565c0.832,0.474,0.543,1.74-0.413,1.806l-11.2,0.782l1.729,11.093c0.148,0.947-1.022,1.51-1.67,0.804l-7.595-8.269 l-7.595,8.269c-0.648,0.705-1.817,0.142-1.67-0.804l1.729-11.093l-11.2-0.782c-0.956-0.066-1.245-1.332-0.413-1.806l9.751-5.565 l-6.371-9.245c-0.544-0.789,0.266-1.803,1.155-1.449l10.43,4.154l3.255-10.745C319.126,229.289,320.424,229.289,320.702,230.206z" />
            <path fill="#FFFFFF" d="M461.943,194.895l3.255,10.745l10.43-4.154c0.889-0.354,1.699,0.66,1.155,1.449l-6.371,9.245 l9.751,5.565c0.832,0.474,0.543,1.74-0.413,1.806l-11.2,0.782l1.729,11.093c0.148,0.947-1.022,1.51-1.669,0.804l-7.595-8.269 l-7.595,8.269c-0.648,0.705-1.817,0.142-1.67-0.804l1.729-11.093l-11.2-0.782c-0.956-0.066-1.245-1.332-0.413-1.806l9.751-5.565 l-6.371-9.245c-0.544-0.789,0.266-1.803,1.155-1.449l10.43,4.154l3.255-10.745C460.367,193.978,461.665,193.978,461.943,194.895z" />
            <path fill="#FFFFFF" d="M393.279,336.137l3.255,10.745l10.43-4.155c0.889-0.354,1.699,0.66,1.155,1.449l-6.371,9.245 l9.751,5.565c0.832,0.474,0.543,1.74-0.413,1.806l-11.2,0.782l1.729,11.093c0.148,0.947-1.022,1.51-1.67,0.804l-7.595-8.269 l-7.595,8.269c-0.648,0.705-1.817,0.142-1.67-0.804l1.729-11.093l-11.2-0.782c-0.956-0.066-1.245-1.332-0.413-1.806l9.751-5.565 l-6.371-9.245c-0.544-0.789,0.266-1.803,1.155-1.449l10.43,4.155l3.255-10.745C391.703,335.22,393.002,335.22,393.279,336.137z" />
            <path fill="#FFFFFF" d="M418.461,249.514l-2.613,7.836l-8.259,0.064c-1.069,0.009-1.513,1.373-0.652,2.008l6.644,4.907 l-2.492,7.875c-0.322,1.02,0.839,1.863,1.708,1.241l6.72-4.803l6.72,4.803c0.87,0.622,2.03-0.222,1.708-1.241l-2.492-7.875 l6.644-4.907c0.861-0.636,0.417-1.999-0.652-2.008l-8.259-0.064l-2.613-7.836C420.234,248.499,418.799,248.499,418.461,249.514z" />
        </g>
    </svg>
);
