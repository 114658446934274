/** @jsx jsx */
import { jsx } from "@emotion/react";
import {MenuWithChild, Menu} from "@bdl-cmn-shared-packages-npm/design-system";
import React from "react";
import { marketNewsStyle } from "../MarketNews/index.style";

export function MarketTradingFilters({typeToDisplay, types, handleClick}: {typeToDisplay: String, types:any, handleClick :any}) {
    const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        let found = false;
        let target = event.target as Element;
        const parent = event.currentTarget;
        const head = parent.firstChild.firstChild;
    
        do {
          if (head == target) found = true;
    
          target = target.parentElement;
        } while (!found && parent != target && target != null);
    
        if (found) parent.classList.toggle('open');
    };

    return (
        <div>
            <div css={marketNewsStyle.title}>Filter your search result</div>
            <div css={marketNewsStyle.row} onClick={handleFilterMenuClick}>
                <MenuWithChild
                    icon="globe"
                    label="Type of News"
                    width="100%;"
                    expanded
                    arrows={false}
                >
                    <div css={[marketNewsStyle.menuItem, marketNewsStyle.menuItemRow]}>
                        {
                            types?.map((typeItem: any) => {
                                return (
                                    <div css={typeToDisplay === typeItem?.type && marketNewsStyle.childMenuSelected}  key={`filter-${typeItem?.type}`}>
                                        <Menu css={marketNewsStyle.childMenu}
                                            childLabel={typeItem?.label + ' (' + typeItem?.totalHits + ')'} 
                                            selected={typeToDisplay === typeItem?.type}
                                            onSelect={() => {handleClick(typeItem?.type)}}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </MenuWithChild>
            </div>
        </div>
    );
}

export default MarketTradingFilters;
