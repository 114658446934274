import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Row } from "@bdl-cmn-shared-packages-npm/design-system";
import { useState } from "react";
import { Query } from "react-apollo";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { DocumentSearchResultDto } from "../../../../interfaces/search/search";
import Loader from "../../../../core/components/Loader/loader.component";
import { GET_SEARCH_DOCUMENTS_ONLY_RESULTS } from "../../../../graphql";
import { searchSortData } from "../../Search/results/dataMapper";
import { DocumentsListView } from "../../UI/list/document";
import SearchFilterParams from "../../../../interfaces/search/search-filter-params";
import { Density } from "../../UI/list/customizer/customizer.component";
import { DocumentFilters } from "../../UI/list/document/document-filters";
import MobileFilterContainer from "../../Search/results/searchMobile/mobileFilterContainer";
import FilterButton from "../../Search/results/searchMobile/filterButton";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import { genericStyle } from "../../UI/generic/generic.style";
import Utils from "../../../utils/utils";
import DocumentSearchCard from "../../UI/list/document/card-mobile";
import { IssuerDetail } from "../../../../interfaces/search/search-issuer";

export interface DocumentsProps {
  label?: string;
  children?: any;
  issuerData?: IssuerDetail;
}

const defaultSearchFilters: SearchFilterParams = {
  documentTypes: [],
  documentSubTypes: [],
  excludeDocumentTypes: [],
  excludeDocumentSubTypes: ["D318"],
};

const IssuerDocuments: React.FunctionComponent<DocumentsProps> = ({
  issuerData,
}: DocumentsProps) => {
  const selectedCols: any = null;
  const density: Density = "medium";

  const [filters, setFilters] =
    React.useState<SearchFilterParams>(defaultSearchFilters);
  const [itemsNumber, setItemsNumber] = useState<number>(20);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [sort, setSort] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const switchFilter = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const onPaginationUpdate = (pageNumber: number) => {
    Utils.moveToTop();
    setCurrentPageNumber(pageNumber);
  };

  React.useEffect(() => {
    setCurrentPageNumber(0);
  }, [filters]);

  return (
    <Query<{ luxseDocumentsSearch: DocumentSearchResultDto }, any>
      client={luxseGatewayGraphqlClient}
      query={GET_SEARCH_DOCUMENTS_ONLY_RESULTS}
      variables={{
        ...filters,
        token: Utils.getCookie("Gztoken-Dsm"),
        searchTerm: searchTerm ? searchTerm : "",
        size: itemsNumber,
        page: currentPageNumber,
        sort: sort && sort.field ? `${sort.field},${sort.dir}` : "",
        issuerId: issuerData.id,
      }}
      fetchPolicy="network-only"
      errorPolicy="all"
    >
      {({ loading, error, data }): React.ReactNode => {
        return (
          <Loader
            loading={loading && !data?.luxseDocumentsSearch}
            padding={"5em 0 !important"}
          >
            <div css={genericStyle.desktopDisplay}>
              <DocumentsListView
                data={data.luxseDocumentsSearch}
                loadingPage={loading}
                density={density}
                onPaginationUpdate={onPaginationUpdate}
                currentPageNumber={currentPageNumber}
                totalResultSize={data?.luxseDocumentsSearch?.totalHits || 0}
                filters={filters}
                onFiltersChange={setFilters}
                sortData={searchSortData}
                sort={sort}
                onSortChange={setSort}
                selectedCols={selectedCols}
              />
            </div>
            <div
              css={[
                genericStyle.mobileDisplay,
                css`
                  margin-top: 20px;
                  width: 100%;

                  > div:first-of-type {
                    margin: 10px;
                  }
                `,
              ]}
            >
              <SearchMobile
                onPaginationUpdate={onPaginationUpdate}
                pageNumber={currentPageNumber}
                data={data?.luxseDocumentsSearch?.documents}
                totalResultSize={data?.luxseDocumentsSearch?.totalHits}
                Card={(card) => (
                  <DocumentSearchCard
                    key={`card-security-${card?.id}`}
                    card={card}
                  />
                )}
              />
              <FilterButton onSwitchFilter={switchFilter} open={menuIsOpen} />
              <MobileFilterContainer open={menuIsOpen}>
                <DocumentFilters
                  filterCount={data?.luxseDocumentsSearch?.filters}
                  filters={filters}
                  onFiltersChange={setFilters}
                  fullWidth
                />
              </MobileFilterContainer>
            </div>
          </Loader>
        );
      }}
    </Query>
  );
};

export default IssuerDocuments;
