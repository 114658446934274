import { breakpoint, breakpointMax } from '../../../style';

import { css } from '@emotion/react';

export const style = {
  header: css`
    background: #ffffff;
    display: flex;
    min-height: 95px;
    align-items: center;
    padding-left: 80px;
    padding-right: 80px;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;

    @media (max-width: ${breakpointMax}) {
      padding-left: 16px;
      padding-right: 16px;
      border-bottom: 1px solid #d9dee2;
    }
  `,
  headerRow: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  headerLeft: css`
    cursor: pointer;
    text-decoration: none;
    img {
      max-width: 180px;
    }
  `,
  headerCenter: css`
    display: flex;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  headerRight: css`
    color: #22AA5F;
    display: flex;
    svg {
      margin-right: 24px;
      cursor: pointer;
    }
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  headerRightMobile: css`
    color: #22AA5F;
    display: none;
    svg {
      margin-right: 24px;
      margin-right: 16px;
      cursor: pointer;
    }
    @media (max-width: ${breakpointMax}) {
      display: flex;
      > div {
        display: flex;
        margin-left: 2em;
        font-weight: 400;
      }
    }
  `,
  headerCenterMenu: css`
    cursor: pointer;
    padding: 12px 16px 12px 16px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 8px;
    text-align: center;
    color: #354450;
    text-decoration: none;
    &:hover {
      background: #f6f8f9;
      transition: all 0.1s ease-in-out;
    }
  `,
  headerCenterMenuActive: css`
    cursor: pointer;
    padding: 12px 16px 12px 16px;
    font-weight: bold;
    font-size: 16px;
    border-radius: 8px;
    text-align: center;
    color: #354450;
    &:hover {
      background: #f6f8f9;
      transition: all 0.1s ease-in-out;
    }
  `,
  headerBigMenuItem: css`
    display: flex;
    margin-top: 16px;
    align-items: center;
    padding: 8px;

    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 4px;
    }
  `,
  headerBigMenuItemLogo: css`
    width: 50px;
    margin-right: 2em;

    svg {
      position: absolute;
      margin-left: -33px;
      margin-top: 15px;
      color: #ca1234;
      font-size: 18px;
    }
  `,
  headerBigMenuItemLogoRight: css`
    width: 60px;
    margin-right: 2em;

    svg {
      position: absolute;
      margin-left: -33px;
      margin-top: 15px;
      color: #354450;
      font-size: 18px;
    }
  `,
  headerBigMenuItemLogoRightGreen: css`
    width: 60px;
    margin-right: 2em;

    svg {
      position: absolute;
      margin-left: -33px;
      margin-top: 15px;
      color: #22AA5F;
      font-size: 18px;
    }
  `,
  headerBigMenuItemLogoSvg: css`
    opacity: 0.2;
  `,
  headerBigMenuItemDetails: css`
    width: calc(100% - 50px);
  `,
  headerBigMenuItemDetailsTitle: css`
    color: #354450;
    font-size: 16px;
    line-height: 20px;
    font-weight: 800;
  `,
  headerBigMenuItemDetailsDescription: css`
    color: #425563;
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
  `,
  headerBigMenu: css`
    background: #ffffff;
    display: flex;
    min-width: 800px;
    max-width: 100%;
    min-height: 120px;
    border-radius: 4px;
  `,
  headerBigMenuLeft: css`
    width: 100%;
    min-width: 350px;
    padding: 2em;
  `,
  headerBigMenuRight: css`
    background: linear-gradient(90deg, #f6f9f9 2.58%, rgba(246, 249, 249, 0) 100%);
    width: 40%;
    padding: 2em;
  `,
  headerBigMenuTitle: css`
    color: #425563;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px;
    letter-spacing: 0.16px;
    text-transform: uppercase;
  `,
  headerBigMenuRightRow: css`
    color: #354450;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 2em;
    letter-spacing: 0.16px;
    margin-top: 2em;
    text-transform: uppercase;

    svg {
      margin-right: 16px;
      color: #a4bcc2;
      font-size: 12px;
    }
  `,
};
