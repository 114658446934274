import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from './roundTag.style';

export interface HexaBackgroundProps {
  children?: any;
  green?: boolean;
  padding?: boolean;
  small?: boolean;
  size?: number;
  borderSize?: number;
  minSize?: number;
  minWidth?: number;
  textColor?: string;
}

const RoundTag: React.FunctionComponent<HexaBackgroundProps> = ({
  children,
  green,
  small,
  textColor,
  size,
  padding,
  borderSize,
  minSize,
  minWidth
}: HexaBackgroundProps) => {
  const additionalStyle: any = {};
  if (green) {
    additionalStyle.border = 'solid 1px green';
    additionalStyle.backgroundColor = 'rgb(0 140 61 / 15%)';
  }
  if (padding) {
    additionalStyle.paddingLeft = '8px';
    additionalStyle.paddingRight = '8px';
    additionalStyle.paddingTop = '4px';
    additionalStyle.paddingBottom = '4px';
  }
  if (small) {
    //additionalStyle['minheight'] = '1em';
    additionalStyle['fontSize'] = '14px';
    additionalStyle['border'] = `solid 1px ${green ? 'green' : 'red'}`;
    //additionalStyle['padding'] = '.5em';
  }
  if (textColor) {
    additionalStyle['color'] = textColor;
  }
  if (size) {
    additionalStyle['fontSize'] = `${size}px`;
  }
  if (borderSize) {
    additionalStyle['borderWidth'] = `${borderSize}px`;
  }
  if (minSize) {
    additionalStyle['minHeight'] = `${minSize}px`;
    additionalStyle['minWidth'] = `${minSize}px`;
  }
  if(minWidth) {
    additionalStyle['minWidth'] = `${minWidth}px`;
  }
  return (
    <div css={style.roundTag} style={additionalStyle}>
      {children}
    </div>
  );
};

export default RoundTag;
