
export enum DayCountConvention {
    THIRTYRE = '30/360',
    RERE = 'Real/Real',
    RE30 = 'Real/360',
    RE35 = 'Real/365',
    THIRTYTHIRTY = '30/360',
    UNAPPLICABLE = 'n/a'
}

export function dayCountConventionLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(DayCountConvention).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(DayCountConvention)[index] : value;
	}catch (e){
		console.error("Failed to get DayCountConvention label for '" + value + "'", e);
	}
		
	return value;
}