import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import {Icon, Td, Tr} from "@bdl-cmn-shared-packages-npm/design-system";
import {cssfApprovalsStyle} from "./cssf-approvals.style";
import {documentSubTypeLabel} from "../../../../../../enums/document-sub-type";
import {formatDate} from "../../../../../../utils/date";
import {sharedStyle} from "../../../../../../styles/shared";
import {tradingStyle} from "../../trading-data.style";
import Utils from "../../../../../utils/utils";

export const CssfApprovalsRow = (props: any) => {

    const row = props.cssfApprovalRow;
    return (
        <Tr onRowClick={() => Utils.documentAction(row?.id, row?.downloadUrl, row?.categDoc)} css={sharedStyle.tableRow}>
            <Td>
                <div css={cssfApprovalsStyle.title2}>
                    {documentSubTypeLabel(row?.documentPublicTypeCode)}
                    {row?.description? `- ${row.description}` : ""}
                </div>
                <div css={cssfApprovalsStyle.title1}>
                    {formatDate(row?.publishDate)}
                    {row?.complement? `- ${row.complement}`: ""}
                </div>
            </Td>
            <Td>
                <div css={tradingStyle.downloadIcon}>
                    <Icon icon={'download'}/>
                </div>
            </Td>
        </Tr>
    )

}
export default CssfApprovalsRow;