
/** @jsx jsx */
import { jsx } from "@emotion/react"
import * as React from 'react';
import { Checkbox, InputField, Loader, MenuWithChild, OptionsData } from "@bdl-cmn-shared-packages-npm/design-system";
import { LuxXPrimeFilterResultsData } from "../../../../../../graphql";
import { Country } from "../../../../../../enums";
import { CountryFilter as CountryFilterModel } from "../../../../../../interfaces/search/search-filter-params";
import style from "./index.style";
import inputStyle from "../input.style";

type CountryKey = keyof typeof Country;

export interface CountryFilterProps {
  selection: CountryFilterModel;
  onSelectionChange: (values: any) => void;
  data: LuxXPrimeFilterResultsData | null
}

export function CountryFilter(props: CountryFilterProps) {
  const {
    selection,
    onSelectionChange,
    data
  } = props;

  return (
    <MenuWithChild
      icon="globe"
      label="Country"
      width="100%;"
      expanded={false}
      arrows={false}>
      {data?.securitiesByTag ?
        <CountryFilterValues
          selection={selection}
          onSelectionChange={onSelectionChange}
          data={data}
        /> : <Loader />}
    </MenuWithChild>
  );
}

interface CountryFilterValuesProps extends CountryFilterProps {
  data: LuxXPrimeFilterResultsData;
}

function CountryFilterValues({ selection, onSelectionChange, data }: CountryFilterValuesProps) {

  const [filter, setFilter] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const label = React.useMemo(() => {
    const number = selection? selection.length : 0;

    return number == 0 ?
      "Country" :
      number === 1 ?
        Country[selection[0]] :
        `${number} elements selected`;
  }, [selection]);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const clickOutside = (event: any) => {
        if (!ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    }
  }, [open]);

  const values: OptionsData[] = React.useMemo(() => {
    const countries = data.securitiesByTag.reduce((acc, { issuers }) => {

      const countriesCode2 = issuers?.map(({ countryCode2 }) => countryCode2) || [];
      return countriesCode2.reduce((acc, countryCode2) => {
        if (countryCode2) {
          acc[countryCode2] = countryCode2
        }
        return acc;
      }, acc);
    }, {} as any);

    const uniqueValues = Object.values(countries) as CountryKey[];
    return uniqueValues
      .sort((a, b) => Country[a].localeCompare(Country[b]))
      .map(key => ({
        name: Country[key],
        value: key,
      }))
      .filter((value: OptionsData) => value.name.toUpperCase().includes(filter.toUpperCase()));
  }, [data, filter]);

  const handleSelectionChange = (country: OptionsData) => {
    onSelectionChange(country.value);
  }

  return (
    <>
      <div ref={ref} css={style.container} onClick={(e) => e.stopPropagation()}>
        <div css={inputStyle.inputWrapper}>
          <InputField
            onFocus={() => setOpen(true)}
            value={open ? filter : null}
            onClick={(e) => e.stopPropagation()}
            onChange={(e: any) => setFilter(e.target.value)}
            placeholder={open ? "Country" : label}
          />
        </div>
        {
          open && (
            <>
              <div css={style.panel} onClick={(e) => e.stopPropagation()}>
                { values.map( (value : OptionsData) => {
                    if( !value )
                      return <></>;
                    
                    return (
                      <React.Fragment key={`country-${value.value}-${value.name}`}>
                        <Checkbox
                          label={value.name}
                          onClick={() => handleSelectionChange(value)}
                          checked={selection?.includes(value.value as CountryKey)}
                        />
                      </React.Fragment>
                    );
                  })
                }
              </div>
            </>
          )
        }
      </div>
    </>
  )
}
