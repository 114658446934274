
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const TTO: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#C8102E" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <g>
                <path fill="#FFFFFF" d="M8.828,88.277c-2.387,0-4.535,0.967-6.124,2.506l359.228,332.942h26.483L26.483,88.277H8.828z" />
                <path fill="#FFFFFF" d="M150.069,88.277h-26.483l361.931,335.448h17.655c2.387,0,4.535-0.967,6.124-2.506L150.069,88.277z" />
            </g>
            <polygon fill="#000000" points="26.483,88.277 388.414,423.725 485.517,423.725 123.586,88.277 " />
        </svg>
    </React.Fragment>
);
