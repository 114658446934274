
export enum SecurityTypeEnum {
	GREEN = 'Sustainable bonds',
	ESG = 'LGX Funds',
	CAI = 'Bonds from Climate-Aligned issuers',
}

function cleanName(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try{
		value = value.toString().toUpperCase();
		value = value.replace(/[^a-zA-Z ]/g, "");
		value = value.replace(/\s/g, "");
	}catch (e){
	}

	return value;
}

export function securityTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(SecurityTypeEnum).indexOf( cleanName(value) );
  		if( index >= 0) 
			return Object.values(SecurityTypeEnum)[index];
	}catch (e){
		console.error("Failed to get SecurityTypeEnum label for '" + value + "'", e);
	}
	
	console.error("SecurityTypeEnum label for '" + value + "' - '" + cleanName(value) + "' doest exist");

	return value;
}