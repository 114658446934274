/** @jsx jsx */
import React, { Component, useEffect, useState } from "react";
import { DonutChart } from "../Utils/DonutChart";
import { css, jsx } from "@emotion/react";
import { sharedStyle } from "../../../../styles/shared";
import { BrowserRouter } from "react-router-dom";
import { Grid, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import WidgetCard from "../../WidgetCard";
import ReactApexChart from "react-apexcharts";
import { ColumnChart } from "../Utils/ColumnChart";



export class TopTradedSecuritiesChart extends Component<any, any> {

    render() {
        const graphColors: string[] = [
            "#F18700",
            "#22AA5F",
        ];
        const data = this.props.data;
        const loading = this.props.loading;
        const type = this.props.type;



        return (
            <ColumnChart
                    data={data}
                    labelField={"libMar"}
                    serieField={"capiBoursDevRef"}
                    labelValueFunction={(value: string) => value}
                    type={type}
                    source="top traded securities"
                /> 
        )
    }
}

export default TopTradedSecuritiesChart;