/** @jsx jsx */

import * as React from 'react';

import {Button, Icon} from "@bdl-cmn-shared-packages-npm/design-system";
import {
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    RichText,
    Text,
    getFieldValue
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from '../../interfaces';
import {style} from "../LuxXPrimeDoubleTrading/index.style";

export interface TitleTicksAndActionComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    margin: 0;
  }
    `;
const items = css`
  width: 100%;
  display: flex;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;

const itemsColContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
    `;
const item = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  @media (max-width: ${breakpointMax}) {
    margin-bottom: 46px;
    &:last-of-type {
      margin-bottom: 16px;
    }
  }
`;
const icon = css`
  color: #cb1234;
  font-size: 16px;
  margin-right: 8px;
`;
const text = css`
  color: #354450;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
`;

const edgeText = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  `;

export const TitleTicksAndActionComponent: React.FunctionComponent<TitleTicksAndActionComponentProps> = (props: TitleTicksAndActionComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const btnLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
    const btnLink: string = getFieldValue<string>(fields, 'btnLink', '');
    const oneCol: number = getFieldValue<number>(fields, 'only one column', 0);
    const alignLeft: number = getFieldValue<number>(fields, 'align left', 0);

    const parentContainer = css`
      width: 100%;
      padding-left: 80px;
      padding-right: 80px;
      padding-top: 96px;
      padding-bottom: 96px;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: ${breakpointMax}) {
        padding: 16px;
        padding-top: 46px;
        padding-bottom: 46px;
      }    
    `;

    const title = css`
      line-height: 62px;
      width: 100%;
      text-align: ${alignLeft ? 'left' : 'center'};
      color: #354450;
      font-size: 32px;
      font-weight: 800;
      margin-bottom: 32px;
      @media (max-width: ${breakpointMax}) {
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
      }
    `;

    const itemsCol = css`
      width: ${oneCol ? '100%' : '50%'};
      display: flex;
      flex-direction: column;
      padding-right: 32px;
      align-items: ${alignLeft ? 'flex-start' : 'center'};
      @media (max-width: ${breakpointMax}) {
        width: 100%;
        align-items: flex-start;
      }
    `;
    const action = css`
      margin-top: 32px;
      display: flex;
      justify-content:  ${oneCol ? 'center' : 'space-between'};

      @media (max-width: ${breakpointMax}) {
        justify-content: center;
        button {
          width: calc(100% - 16px);
        }
      }
      `;
    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={items}>
                    <div css={itemsCol}>
                        <div css={itemsColContainer}>
                            {
                                new Array(10).fill(0).map((arr: any, index: number) => {
                                    if (fields && fields[`item${index + 1}`] && (fields && fields[`item${index + 1}`] as any).value) {
                                        return (
                                            <div css={item}>
                                                <div css={icon}>
                                                    <Icon icon={'check'} />
                                                </div>
                                                <div css={text}>
                                                    <Text
                                                        field={fields[`item${index + 1}`] as SitecoreGenericTextField}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    {
                        !oneCol && (
                            <div css={itemsCol}>
                                <div css={itemsColContainer}>
                                    {
                                        new Array(10).fill(0).map((arr: any, index: number) => {
                                            if (fields && fields[`itemRight${index + 1}`] && (fields && fields[`itemRight${index + 1}`] as any).value) {
                                                return (
                                                    <div css={item}>
                                                        <div css={icon}>
                                                            <Icon icon={'check'} />
                                                        </div>
                                                        <div css={text}>
                                                            <Text
                                                                field={fields[`itemRight${index + 1}`] as SitecoreGenericTextField}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }

                </div>
                {
                    btnLink && (
                        <div css={action}>
                            <Button label={btnLabel} onClick={() => btnLink ? window.location.href = btnLink : console.log} iconRight={'paper-plane'} />
                            {
                                fields && fields.edgeText && (
                                    <div css={edgeText}>
                                        <Text
                                            field={fields && fields.edgeText as SitecoreGenericTextField}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}
