/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React from "react";
import { renderLink } from "../../../utils/helper";
import hexa from "./Icon.svg";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";

const style = {
  container: css`
    display: flex;
    align-items: center;
  `,
  left: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px !important;
    height: 80px;
    color: #9f9f9f;
    font-size: 24px;
    background: url(${renderLink(hexa)});
    background-size: 100%;
    background-repeat: no-repeat;
  `,
  right: css`
    padding-left: 32px;
    font-weight: 700;
    color: #697075;
  `,
};

export const EmptyData = ({ icon, message }: any): JSX.Element => {
  return (
    <div css={style.container}>
      <div css={style.left}>{icon && <Icon icon={icon as any} />}</div>
      <div css={style.right}>{message}</div>
    </div>
  );
};

export default EmptyData;
