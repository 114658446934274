
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const MEX: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <path fill="rgb(0, 104, 71)" d="M170.667,423.721H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.1c0-4.875,3.953-8.828,8.828-8.828 h161.839V423.721z"></path>
            <rect x="170.67" y="88.277" fill="rgb(255, 255, 255)" width="170.67" height="335.45"></rect>
            <path fill="rgb(206, 17, 37)" d="M503.172,423.721H341.333V88.273h161.839c4.875,0,8.828,3.953,8.828,8.828v317.793 C512,419.77,508.047,423.721,503.172,423.721z" ></path>
            <polygon points="271.225,263.893 256,271.507 256,279.12 263.613,279.12 " fill="#FFD250"></polygon>
            <g>
                <path d="M256,298.151c-2.104,0-3.807-1.703-3.807-3.807v-15.225c0-2.104,1.703-3.807,3.807-3.807 s3.807,1.703,3.807,3.807v15.225C259.807,296.448,258.104,298.151,256,298.151z" fill="#00C3A0"></path>
                <path d="M256,282.926c-14.951,0-29.388-5.769-40.654-16.244c-1.539-1.431-1.628-3.84-0.198-5.378 c1.442-1.535,3.848-1.617,5.378-0.198c9.854,9.163,22.452,14.207,35.473,14.207c13.021,0,25.619-5.044,35.473-14.207 c1.535-1.423,3.94-1.345,5.378,0.198c1.431,1.539,1.342,3.948-0.198,5.378C285.388,277.158,270.951,282.926,256,282.926z" fill="#00C3A0"></path>
            </g>
            <path  d="M256,298.151c-9.758,0-19.21-2.851-27.336-8.248l4.215-6.342c13.739,9.129,32.503,9.129,46.241,0 l4.215,6.342C275.21,295.301,265.758,298.151,256,298.151z" fill="#00AADC"></path>
            <polygon  points="249.973,246.764 241.568,259.771 240.14,264.052 262.027,258.977 " fill="#732D37"></polygon>
            <path d="M232.704,268.236c-10.062,0-22.14-8.468-25.344-27.336l7.509-1.271 c2.662,15.69,12.314,21.005,18.11,20.991c1.777-0.049,3.631-0.662,3.777-1.918c0.171-1.502,0.212-1.858-3.769-3.204 c-2.164-0.733-4.617-1.561-6.338-3.531c-6.963-7.951,2.464-16.366,6.993-20.41c0.799-0.714,1.13-1.32,1.045-1.55 c-0.249-0.651-1.743-1.635-3.427-1.635c-4.836,0-8.505-2.732-9.571-7.126c-1.108-4.55,0.985-9.345,4.97-11.404l3.495,6.765 c-0.934,0.483-1.304,1.858-1.067,2.84c0.093,0.394,0.32,1.312,2.175,1.312c4.661,0,9.096,2.751,10.538,6.535 c0.617,1.613,1.546,5.806-3.085,9.943c-5,4.465-7.992,7.825-6.334,9.717c0.375,0.427,1.985,0.974,3.048,1.331 c3.427,1.16,9.806,3.316,8.892,11.289c-0.573,5.014-5.048,8.493-11.129,8.658C233.028,268.232,232.864,268.236,232.704,268.236z" fill="#AAD264"></path>
            <path d="M263.613,225.83c0,0,7.613-15.225-7.613-22.838c0,0,53.289-7.613,45.676,60.902l-0.003-0.003 c-4.996-3.997-12.414-11.302-15.063-17.126l-22.997-13.323V225.83L263.613,225.83z" fill="#874150"></path>
            <path d="M256,241.055l38.063,22.838c0,0,0,0-15.225,7.613L256,248.669V241.055z" fill="#732D37"></path>
            <path d="M240.775,218.217L240.775,218.217c4.204,0,7.613,3.409,7.613,7.613l-0.008,0.008 c-4.542,4.542-5.667,11.479-2.795,17.224l1.72,3.441c0.717,1.434,1.661,2.744,2.795,3.878l21.127,21.127v-10.521 c0-13.107-3.051-26.034-8.913-37.757l0,0c-3.869-7.737-11.777-12.625-20.427-12.625h-1.111v7.614h-0.001V218.217z" fill="#783C46"></path>
            <path d="M240.775,210.604l-7.613,7.613v7.613c0,0,9.056-4.591,12.108-6.054 c2.133-1.022,1.745-3.439,0.521-4.604C244.838,214.266,240.775,210.604,240.775,210.604z" fill="#FFD250"></path>
            <path d="M256,313.376c-35.681,0-64.708-29.027-64.708-64.708h7.613c0,31.485,25.611,57.096,57.096,57.096 s57.096-25.611,57.096-57.096h7.613C320.708,284.349,291.681,313.376,256,313.376z" fill="#AAD264"></path>
            <g>
                <circle cx="199.32" cy="271.258" r="5.166" fill="#FFE15A"></circle>
                <circle cx="210.74" cy="289.497" r="5.166" fill="#FFE15A"></circle>
                <circle cx="229.29" cy="303.288" r="5.166" fill="#FFE15A"></circle>
                <circle cx="313.23" cy="271.258" r="5.166" fill="#FFE15A"></circle>
                <circle cx="301.8" cy="289.497" r="5.166" fill="#FFE15A"></circle>
                <circle cx="283.25" cy="303.288" r="5.166" fill="#FFE15A"></circle>
            </g>
            <path d="M259.807,301.957h-7.613c-4.204,0-7.613-3.409-7.613-7.613l0,0c0-4.204,3.409-7.613,7.613-7.613 h7.613c4.204,0,7.613,3.409,7.613,7.613l0,0C267.42,298.549,264.011,301.957,259.807,301.957z" fill="#FFB478"></path>
            <path d="M286.61,246.764c2.538,7.454,7.93,14.274,15.063,17.126l0.003,0.003 C309.289,195.38,256,202.992,256,202.992C294.063,202.992,286.61,246.764,286.61,246.764z" fill="#9B4B5A"></path>
        </svg>
    </React.Fragment>
);