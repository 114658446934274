
/*
*   e-Listing - core - components - country flag icon - nld
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const ASM: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#000066" d="M503.172,423.724H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.792C512,419.772,508.047,423.724,503.172,423.724z" />
            <path fill="#BD1021" d="M512,414.896v-9.751L56.713,256L512,106.855v-9.751c0-3.192-1.783-5.861-4.321-7.412L0,256 l507.679,166.308C510.217,420.758,512,418.089,512,414.896z" />
            <polygon fill="#FFFFFF" points="56.713,256 512,405.145 512,106.855 " />
            <path fill="#FFF5DC" d="M458.102,212.556c0,0,30.229,1.281,33.047-1.025c2.818-2.305,3.33-17.676-6.66-19.981 s-37.145,12.553-37.145,12.553L458.102,212.556z" />
            <g>
                <path fill="#D27D69" d="M398.925,236.381c0,0-0.342,0.854-0.512,4.611c-0.171,3.757,0.512,13.492,12.297,17.761 l-2.732,11.443l12.467,7.514c0,0,9.223-13.663,9.735-25.106C430.691,241.163,398.925,236.381,398.925,236.381z" />
                <path fill="#D27D69" d="M361.524,228.695l4.867-4.867c0,0-9.964,6.079-13.748,0.598c-4.952-7.172,1.708-14.687,8.625-18.018 c6.916-3.33,20.75-0.768,20.75-0.768l8.71,22.287l-26.386,16.651L361.524,228.695z" />
                <path fill="#D27D69" d="M443.244,186.171l23.569-2.818c0,0-6.916,9.991-20.494,17.42c0,0,14.09,0,18.188,4.867 c4.099,4.867-4.099,9.479-7.172,11.016c-3.074,1.537-29.716,17.932-29.716,17.932l-23.824-2.818L443.244,186.171z" />
                <path fill="#D27D69" d="M460.749,168.068c0,0,5.123-11.955,4.952-20.323L448.452,159.7c0,0,3.928-1.878,2.562-8.71 l-56.358,30.57l-11.443,20.323l34.84,10.76L460.749,168.068z" />
            </g>
            <path fill="#FFE15A" d="M418.267,261.23l-5.892,9.351c0,0-10.504-2.305-14.09,0.256c-3.586,2.562-3.97,12.169,2.305,12.937 c0,0,4.355,2.69,6.916,0.512c0,0,5.251,2.049,6.148-1.281c0.897-3.33-0.256-6.148-0.256-6.148h3.714l6.532-11.656L418.267,261.23z" />
            <path fill="#B95546" d="M365.623,240.992c0,0,16.395-8.197,16.395-20.494s-15.2-12.98-27.496-8.881 c0,0-7.343-5.806-1.195-11.614c8.428-7.96,45.087-24.592,45.087-24.592l-4.099,20.494c0,0,14.602,7.685,20.494,4.099 c4.951-3.014,53.284-40.988,53.284-40.988s-4.099,20.494-32.79,40.988l-3.04,15.201c-0.668,3.34-2.84,6.184-5.887,7.706 l-11.567,5.783c0,0,32.79,4.099,32.79,8.197s-8.197,24.593-24.592,32.79l-8.198-8.197l4.099-12.297c0,0-12.297-4.099-20.494-8.198 c0,0-12.297,4.099-20.494,4.099S365.623,240.992,365.623,240.992z" />
            <path fill="#FFF5DC" d="M341.031,261.486v-8.516c0-2.42,0.961-4.742,2.673-6.453l17.822-17.822l4.099,8.197h16.395 l-4.099,4.099v4.099l-20.494,12.297L341.031,261.486z" />
            <g>
                <path fill="#FFE15A" d="M349.229,257.387h-8.198c0,0-8.197,12.297,0,12.297c0,0,12.297-8.198,16.395-12.297 C357.426,257.387,357.426,253.289,349.229,257.387z" />
                <path fill="#FFE15A" d="M449.21,275.57h-56.663c-0.467-0.503-1.12-0.828-1.86-0.828c0,0-9.925-4.114-11.337-4.114h-1.163 c-1.411,0-2.556,1.144-2.556,2.556v2.386h-12.953v-2.386c0-1.411-1.144-2.556-2.556-2.556h-1.163c-1.411,0-2.556,1.144-2.556,2.556 v0.043c-5.551-0.226-17.42-0.934-23.569-2.983l-32.79,8.198l32.79,8.197c6.147-2.049,18.016-2.758,23.569-2.984v0.043 c0,1.411,1.144,2.556,2.556,2.556h1.163c1.411,0,2.556-1.144,2.556-2.556v-2.386h12.953v2.386c0,1.411,1.144,2.556,2.556,2.556 h1.163c1.411,0,11.337-4.114,11.337-4.114c0.74,0,1.394-0.324,1.86-0.828h56.663c1.587,0,2.872-1.287,2.872-2.872 C452.082,276.855,450.796,275.57,449.21,275.57z" />
                <path fill="#FFE15A" d="M446.746,286.591c-3.416-0.683-42.525,6.148-42.525,6.148s-2.391-2.391,4.781-9.052l-3.415-0.854 c0,0-7.514,5.294-10.589,5.977c-2.647,0.588-8.562,3.464-9.833,7.303l-23.812,3.969l0.512,2.049l23.357-2.738 c1.114,3.588,4.786,4.32,7.384,3.25c0,0,3.416,1.366,6.49-1.025c0,0,6.148,1.025,6.489-4.611l42.867-6.49 C448.453,290.52,450.161,287.274,446.746,286.591z" />
                <path fill="#FFE15A" d="M422.494,315.454c0,0-3.245,4.269-11.272,0.512c-8.026-3.757-17.42,0.342-22.543-0.854 s-12.809-4.269-19.981-1.537c-7.172,2.732-11.101,2.562-9.564-0.854c1.537-3.415,2.391-5.977,0-7.172l2.732-3.416l-0.512-2.049 c0,0-13.663-5.294-22.884,2.049c-9.223,7.343-6.489,24.081,8.881,25.276c0,0,4.782,5.977,16.566,4.269 c0,0,7.685,5.294,20.835,2.903c0,0,0.512,1.537,9.052,0.854c8.54-0.683,18.103,0.342,16.395,6.148c0,0,6.319-1.195,2.562-7.002 c0,0,7.514-0.342,12.638,4.099c0,0,0.342-5.123-4.611-7.857c0,0,8.369-2.903,12.98,0c0,0-3.074-6.489-11.272-5.636 c0,0,5.977-4.782,8.71-3.586c0,0-2.562-3.074-10.077,0C421.128,321.602,425.91,318.699,422.494,315.454z" />
            </g>
        </svg>
    </React.Fragment>
);
