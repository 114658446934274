import * as React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

//TODO IMPLEMENT AND UNCOMMENT
// import { Query } from 'react-apollo';
import { withRouter, RouteComponentProps } from "react-router-dom";

import { SitecoreContext } from "../../interfaces";
import { SecurityCard } from "../../core/components/SecurityCard";
import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
import { GET_SECURITY_FROM_ISIN_QUERY } from "../../graphql";
import Utils from "../../core/utils/utils";

// import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
//TODO IMPLEMENT AND UNCOMMENT, SEE LUXXPRIME AS EXAMPLE
// import { GET_SECURITY_QUERY } from '../../graphql';

export interface SecurityCardParams {
  isin?: string;
  id: string;
}

export interface SecurityCardContentProps
  extends RouteComponentProps<SecurityCardParams> {
  sitecoreContext: SitecoreContext;
}

export function SecurityCardContentCore(props: SecurityCardContentProps) {
  const {
    sitecoreContext: { route },
    match,
  } = props;

  const [id, setId] = React.useState<string>("");
  const [isin, setIsin] = React.useState<string>("");
  const [canLoadCard, setCanLoadCard] = React.useState<boolean>(false);

  const client = luxseGatewayGraphqlClient;

  const fetchGetIDfromIsin = async (isin: string) => {
    try {
      const results: any = await client.query({
        query: GET_SECURITY_FROM_ISIN_QUERY,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          isin: isin,
        },
      });

      if (results?.data?.securityNotFungibleByIsin) {
        setIsin(isin);
        setId(results.data.securityNotFungibleByIsin.id);
      }
    } catch (e) {
      console.error("Failed to get security by ISIN", e);
      setIsin("");
      setId("");
    }
    setCanLoadCard(true);
  };

  React.useEffect(() => {
    const pathname = props?.location?.pathname;
    const path = pathname
      ? pathname.split("/").filter((elem: string) => elem !== "")
      : [];
    const search = new URLSearchParams(props?.location?.search);

    if (pathname.startsWith("/security/card/isin")) {
      fetchGetIDfromIsin(
        path?.length >= 4 && path[3]
          ? path[3]
          : search
          ? search.get("isin")
          : ""
      );
    } else {
      setCanLoadCard(true);
      setIsin(
        path?.length >= 1 && path[1]
          ? path[1]
          : search
          ? search.get("isin")
          : ""
      );
      setId(
        path?.length >= 2 && path[2] ? path[2] : search ? search.get("id") : ""
      );
    }
  }, []);

  return <>{canLoadCard ? <SecurityCard id={id} isin={isin} /> : <></>}</>;
}

export const SecurityCardContent = withRouter(
  withSitecoreContext()(SecurityCardContentCore)
);
