import { css } from '@emotion/react';

export const style = {
  dateSection: css`
    padding: 12px 16px;
    background: #ffffff;
    border-top: 1px solid #d9dee2;
    border-bottom: 1px solid #d9dee2;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 100%;
  `,
  dateLabel: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #425563;
  `,
  section: css`
    padding: 0 16px;
    background: #ffffff;
  `,
  sectionFullWidth: css`
    width: 100%
  `,
  dateValue: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #54666f;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0 0;
    width: 100%;
    border: 1px solid #d9dee2;
    border-radius: 8px;
    margin-bottom: 16px;
    
    box-shadow: 0px 5px 10px rgb(0 0 0 / 20%);
  `,
  top: css`
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    gap: 10px;
  `,
  left: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 32px;
    max-width: 32px;
    height: 32px;
    border: 2px solid #009cc4;
    border-radius: 20px;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #354450;
    background-color: #e6f6fa;
  `,
  right: css`
    color: #354450;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    border: solid 1px #e3e6e8;
    background: #ffffff;
    border-radius: 999em;
    padding: 8px 16px;
    font-weight: bold;
    width: fit-content !important;
  `,
  title: css`
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #354450;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  label: css`
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #54666f;
  `,
  date: css`
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: rgba(66, 55, 99, 0.7);
    margin-bottom: 16px;

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.01em;
      color: #354450;
    }
  `,
  table: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px #d9dee2;
    &:last-of-type {
      border-bottom: none;
    }
    &:first-of-type {
      border-top: solid 1px #d9dee2;
    }
  `,
  rowPlain: css`
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f6f8f9;
    border-radius: 0px 0px 8px 8px;
  `,
  col: css`
    padding: 4px 16px 4px 16px;
    border-right: solid 1px #d9dee2;
    &:last-of-type {
      border-right: none;
    }
  `,
  colPlain: css`
    padding: 0 16px 0 16px;
    border-right: solid 1px #d9dee2;
    &:last-of-type {
      border-right: none;
    }
  `,
  colTop: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  `,
  colTopRight: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #354450;
    opacity: 0.7;
    margin-bottom: 8px;
    margin-top: 8px;
  `,
  colBottomRight: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #354450;
    margin-bottom: 8px;
  `,
  colTopLeft: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #354450;
    opacity: 0.7;
    margin-bottom: 8px;
    margin-top: 8px;
  `,
  colBottomLeft: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #354450;
    margin-bottom: 8px;
  `,
  colBottom: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  `,
  indice: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #22aa5f;
    svg {
      margin-right: 8px;
      font-size: 18px;
    }
  `,
  value: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  `,
  noIssuer: css `
    font-style: italic;
  `,
  multipleIssuer: css `
    color: #22aa5f;
    svg {
      color: #22aa5f;
    }
  `,
  mutipleIssuerList: css `
    padding-inline-start: 30px;
  `,
  mutipleIssuerItem: css `
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #54666f;
    &:not(:first-of-type){
      padding-top: 7px;
    }
  `,
};
