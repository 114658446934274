import * as React from "react";
import {css} from "@emotion/react";

export const style = {
  documentList: css`
    width: 100%;
  `,
  extendedRow: css`
    position: relative;
  `,
  extendedRowOpen: css`
    background-color: #E8FAF0;
    border-bottom: solid 1px #F8FAFC;
  `,
  fastLaneRow: css`
    padding: 0 !important;
    font-size: inherit !important;

    background-color: #E8FAF0;
    border-bottom: solid 1px #F8FAFC;

    > td {
      background-color: #E8FAF0;
      border-bottom: solid 1px #F8FAFC;
    }
  `,
  fastLaneLink: css`
    width: 70%;
  `,
  fastLaneTitle: css`
    font-weight: 500;
    color: #54666F;
  `,
  fastLaneUrl: css`
    a {
      color: #54666F;
      font-size: 1em;
      font-weight: 400;
      line-height: 20px;
      -webkit-text-decoration: underline;
      text-decoration: underline;
    }
  `,
  chevron: css`
    position: absolute;
    right: 0.5em;
    top: 30%;
    font-size: 1.5rem;
    color: #22AA5F;

    > * {
      transition: transform 0.15s ease-out
    }
  `,
  openChevron: css`
    > * {
      transform: rotate(180deg)
    }
  `,
}