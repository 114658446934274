import * as React from 'react';

import { css, jsx, keyframes } from '@emotion/react';

import { LuxXPrimeLatestNoticeCardsView } from '../../core/components/LuxXPrime/Notices/LatestCards';
import { DefaultSitecoreProps } from '../../interfaces/default-sitecore';

/** @jsx jsx */
export const LuxXPrimeLatestNoticeCards: React.FunctionComponent<DefaultSitecoreProps> = (
  props: DefaultSitecoreProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  return <LuxXPrimeLatestNoticeCardsView />
};
