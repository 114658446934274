
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import bg from './articleBg.svg';
import { SitecoreGenericTextField } from '../../interfaces';
import {canUseDOM} from "../../predicates";
import {breakpointMax} from "../../style";

export interface BlogArticleContainerProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
    width: 100%;
  display: flex;
  min-height: 800px;
`;

const left = (alignTop = 0) => css`
  width: calc((100% - 780px) / 2);
  min-width: 220px;
  margin-top: ${alignTop - 95 - 58}px; // header - breadcrumb - padding
  height: 100%;
  padding: 16px;

  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;

const center = css`
  width: 780px;
  height: 100%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const right = (alignTop = 0) => css`
  width: calc((100% - 780px) / 2);
  height: 100%;
  min-width: 220px;
  margin-top: ${alignTop - 95 - 58}px; // header - breadcrumb - padding
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const rightContent = css`
  max-width: 280px;
  width: 100%;
`;
const background = css`
  width: 100%;
  height: 100%;
  max-width: 1400px;
  background: url(${bg});
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;

export const BlogArticleContainer: React.FunctionComponent<BlogArticleContainerProps> = (props: BlogArticleContainerProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const [alignTop, setAlignTop] = React.useState<number>(0)
    React.useEffect(() => {
        const imageStart = canUseDOM && document.getElementById('imageStart')?.offsetTop;
        setAlignTop(imageStart);
    }, [canUseDOM]);

    return (
        <div css={container} id="article-container">
            <div css={background} />
            <div css={left(alignTop)}>
                <Placeholder name='left-column-section' rendering={rendering} />
            </div>
            <div css={center} id="articleContainer">
                <Placeholder name='center-column-section' rendering={rendering} />
            </div>
            <div css={right(alignTop)}>
                <div css={rightContent}>
                    <Placeholder name='right-column-section' rendering={rendering} />
                </div>
            </div>
        </div>
    );
}
    