export enum MarketRule {
    EUR = 'EUR',
    LUX = 'LUX',
    SOL = 'SOL',
    PUBL = 'PUBL'
}

export function computeLabel(marketRule: MarketRule): string {
    switch (marketRule?.toUpperCase()) {
        case "LUX":
            return "Euro MTF";
        case "PUBL":
            return "Publication";
        case "EUR":
            return "Bourse de Luxembourg";
        case "SOL":
            return "LuxSE SOL";
        case "BOURSE DE LUXEMBOURG":
            return "XLUX";
        case "Suspension":
            return "Suspension";
        case "Delisting":
            return "Delisting";
        case "Transfer":
            return "Transfer";
        default:
            return marketRule;
    }
}

export enum MarketRuleEnum {
    EUR = "Bourse de Luxembourg",
    LUX = "Euro MTF",
    PUBL = "Publication",
    SOL = "LuxSE SOL",
    BOURSE_DE_LUXEMBOURG = "XLUX",
    SUSPENSION = "Suspension",
    DELISTING = "Delisting",
    TRANSFER = "Transfer",
}

function cleanName(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try{
		value = value.toString().toUpperCase();
		value = value.replace(/[^a-zA-Z ]/g, "");
		value = value.replace(/\s/g, "_");
	}catch (e){
	}

	return value;
}

export function marketRuleLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(MarketRuleEnum).indexOf( cleanName(value) );
  		if( index >= 0) 
			return Object.values(MarketRuleEnum)[index];
	}catch (e){
		console.error("Failed to get MarketRuleEnum label for '" + value + "'", e);
	}
	
	console.error("MarketRuleEnum label for '" + value + "' - '" + cleanName(value) + "' doest exist");

	return value;
}

export function marketRuleCheck( a : string, compare : string ): boolean {
	if( !a || !compare )
		return false;
	try {
		let aValue = marketRuleLabel(a);
		
		return aValue === compare;
	}catch (e){
		console.error("Failed to check MarketRuleEnum for '" + a + "'-'" + compare + "'", e);
	}

	return false;
}