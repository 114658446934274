/** @jsx jsx */

import React, { Component } from "react";
import { Td, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import { genericTradingDataStyle } from "../../DataMarket/TradingData/Shared/generic-trading-data.style";
import { sharedStyle } from "../../../../styles/shared";
import { style } from "../market-statistics.style";
import { Fragment } from 'react';
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { canUseDOM } from "../../../../predicates";
import RoundTag from "../../RoundTag/roundTag.component";


export class GenericTopTradedSecuritiesDataRow extends Component<any, any> {

    render() {

        const data =Object.keys(this.props.rowsData);
        const dt =this.props.rowsData;
        const cols = this.props.columns;
        const type=this.props.type;
        let compteur = 1;


        const goToSecurityDetailPage = (isin: string, id: string) => {
            if (canUseDOM) window.open(`/security/${isin}/${id}`, '_self');
        };


        return data.map((rd: any,i:any) => {

            compteur = compteur + 1;
            return (
                <tr
                    css={style.tableRow} key={compteur}
                    onClick={() => goToSecurityDetailPage(dt[rd]["isinCode"], dt[rd]["id"])}>
                    {
                        cols.map(({ accessor }: any) => {
                            const ac = accessor.replace(',', '');
                            const tData = dt[rd][ac];
                            return <td key={accessor}>
                                {
                                    ac === "turnover" ?
                                    <p css={css`font-weight:bold;`}>{tData?.toLocaleString('en-US').replace(/,/g, ' ')}</p> :
                                    ac === "type" ?
                                    <div css={sharedStyle.cardContainerBodyDoubleLeft}>
                                        <RoundTag small textColor={'#425563'}>
                                            {type}
                                        </RoundTag>
                                    </div>
                                    :
                                    <p>{tData}</p>
                                }
                            </td>
                        })
                    }
                </tr>

            );
        }
        )

    }
}

export default GenericTopTradedSecuritiesDataRow;