import { css, jsx } from "@emotion/react";

import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import React from "react";
import Props from '../base/props';
import SearchIndex from "../../../../../interfaces/search/search-index";
import { formatDate } from "../../../../../utils/date";
import { goToIndexDetailPage } from "../../../Index/Index-details";

const market = css`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  border: solid 1px #e3e6e8;
  background: #ffffff;
  border-radius: 999em;
  padding: 8px 16px;
  font-weight: bold;
  color: #354450;
`;
/** @jsx jsx */

export const IndexSearchCard = (props: Props<SearchIndex>): JSX.Element => {
  const { card } = props;

  if (!card) return <></>;

  return (
    <div css={{ width: "100%" }}
         onClick={() => goToIndexDetailPage( card.id )}>
      <div
        css={css`
          background: #ffffff;
          border: 1px solid #d9dee2;
          border-radius: 8px;
          filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
          margin-bottom: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-bottom: solid 1px #d9dee2;
            padding: 16px;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 8px;
            `}
          >
            <div
              css={css`
                width: 32px;
                height: 32px;
                background: #fae8eb;
                border: 2px solid #cb1231;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <Icon icon={"chart-area"} />
            </div>
            <div
              css={[
                market,
                css`
                  white-space: unset;
                `,
              ]}
            >
              Indice
            </div>
          </div>

          <div
            css={css`
              font-weight: 800;
              font-size: 20px;
              line-height: 26px;
              color: #253845;
            `}
          >
            {card.indexName}
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            ISIN
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            {card.isin}
          </div>
        </div>
        <div
          css={css`
            border-bottom: solid 1px #d9dee2;
            display: flex;
          `}
        >
          <div
            css={css`
              width: 50%;
              border-right: solid 1px #d9dee2;
              padding: 16px;
            `}
          >
            <div
              css={css`
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #425563;
              `}
            >
              Last
            </div>
            <div
              css={css`
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #54666f;
                margin-top: 8px;
              `}
            >
              <div>{card?.indexPrice?.lastPrice?.amount || "N/A"}</div>
              <div>
                {formatDate(card?.indexPrice?.lastPrice?.date)}
              </div>
            </div>
          </div>
          <div
            css={css`
              width: 50%;
              padding: 16px;
            `}
          >
            <div
              css={css`
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #425563;
              `}
            >
              Change (%)
            </div>
            <div
              css={css`
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #54666f;
                margin-top: 8px;
              `}
            >
              {card?.indexPrice?.lastPrice?.changePercent?.toFixed(4) + "%" || "N/A"}
            </div>
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            Change
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            {card?.indexPrice?.lastPrice?.changeAbsolute?.toFixed(4) || "N/A"}
          </div>
        </div>
        <div
          css={css`
            border-bottom: solid 1px #d9dee2;
            display: flex;
          `}
        >
          <div
            css={css`
              width: 50%;
              border-right: solid 1px #d9dee2;
              padding: 16px;
            `}
          >
            <div
              css={css`
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #425563;
              `}
            >
              Month Hi / Lo
            </div>
            <div
              css={css`
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #54666f;
                margin-top: 8px;
              `}
            >
              <div>{card?.indexPrice?.monthPriceMin?.amount || "N/A"}</div>
              <div>{card?.indexPrice?.monthPriceMax?.amount || "N/A"}</div>
            </div>
          </div>
          <div
            css={css`
              width: 50%;
              padding: 16px;
            `}
          >
            <div
              css={css`
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #425563;
              `}
            >
              Year Hi / Lo
            </div>
            <div
              css={css`
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #54666f;
                margin-top: 8px;
              `}
            >
              <div>{card?.indexPrice?.yearPriceMax?.amount || "N/A"}</div>
              <div>{card?.indexPrice?.yearPriceMin?.amount || "N/A"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default IndexSearchCard;
