import * as React from "react";
import { BrowserRouter } from "react-router-dom";

import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";

import { JSSState } from "./interfaces";
import ReactDOM from 'react-dom';
import { Render, render } from "./dom/render";
import { Hydrate, hydrate } from "./dom/hydrate";

import { GraphQLClientFactory } from "./lib/GraphQLClientFactory";
import { setLuxSEGatewayGraphQLClientIsSSR } from "./lib/LuxSEGatewayGraphQLClientFactory";

import config from "./temp/config";
import { i18nInit } from "./i18n";

import { Root } from "./root";

const DEV = process.env.NODE_ENV === "development";

const mountNodeId: string = "react-mount-node";
const ssrRawJsonId: string = "__JSS_STATE__";

let renderer: Render | Hydrate = render;
let initLanguage = config.defaultLanguage;
let __JSS_STATE__: JSSState | null = null;
const ssrRawJson: HTMLElement | null = document.getElementById(ssrRawJsonId) || null;

DEV && console.log("Welcome to development mode");
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}

if (__JSS_STATE__) {
  renderer = hydrate;
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

setLuxSEGatewayGraphQLClientIsSSR(false);

const initialGraphQLState: NormalizedCacheObject | null =
  __JSS_STATE__ && __JSS_STATE__.APOLLO_STATE
    ? __JSS_STATE__.APOLLO_STATE
    : null;
const graphQLClient: ApolloClient<NormalizedCacheObject> = GraphQLClientFactory(
  (config.graphQLEndpoint),
  false,
  initialGraphQLState
);

async function bootstrapApp(): Promise<void> {
  DEV && console.log("Bootstraping application");

  window.addEventListener("DOMContentLoaded", (): void => {
    document.getElementsByTagName("html")[0].classList.remove("fallback-font");
  });

  i18nInit()
    .then((): void => {
      renderer(
        <Root
          ssrState={__JSS_STATE__}
          path={window.location.pathname}
          Router={BrowserRouter}
          graphQLClient={graphQLClient}
        />,
        document.getElementById(mountNodeId)
      );
    })
    .catch((err: any): void => {
      DEV &&
        console.error(
          `An error has occured while bootstrapping client : ${err}`
        );
    });
}

bootstrapApp();
