import { jsx } from "@emotion/react";
import MarketNewsCSSFRow from "./row-cssf";
import { MarketNewsFilterEnum } from ".";
import MarketNewsGenericMobileRow from "./card-generic";
import MarketNewsGenericRow from "./row-generic";
import MarketNewsLatestFnsRow from "./row-fns";
import Props from "../../UI/list/base/props";
import React from "react";
import SearchDocument from "../../../../interfaces/search/search-document";
import DocumentSearchCard from "../../UI/list/document/card-mobile";

/** @jsx jsx */

const RenderRow = ({ data }: any) => {
  switch( data?.type){
    case MarketNewsFilterEnum.cssf:
      return <MarketNewsCSSFRow row={data} />;
    case MarketNewsFilterEnum.fns:
      return <MarketNewsLatestFnsRow row={data} />;
    //case MarketNewsFilterEnum.newListing:
    //  return <MarketNewsNewListingRow row={data} />;
    //case MarketNewsFilterEnum.suspensions:
    //  return <MarketNewsSuspensionRow row={data} />;
    default:
      return <MarketNewsGenericRow row={data} />;
  }
};

const RenderRowMobile = ({ data }: any) => {
  if (data?.type === MarketNewsFilterEnum.cssf || data?.type === MarketNewsFilterEnum.fns) {
    const card = {
      id: data.id,
      categories : data.categories,
      documentTypeCode: data.properties?.type,
      documentPublicTypeCode: data.properties?.typePublic,
      description: data.name,
      complement: data.label,
      publishDate: data.date,
      hostMemberStates: data.properties?.hostMemberStates,
      incorporedByReferenceDocuments:
        data?.properties?.docIncorporesParRef?.map((it: any) => ({
          ...it,
          downloadUrl: it.downloadUrl,
        })),
      downloadUrl: data.properties?.downloadUrl,
    };
    return <DocumentSearchCard card={card as SearchDocument} />;
  } else {
    return <MarketNewsGenericMobileRow row={data} />;
  }
};

const MarketNewsRow = ({ row, mobile }: Props<any> & { mobile?: boolean }) => {
  if (mobile) {
    return <RenderRowMobile data={row} />;
  }
  return <RenderRow data={row} />;
};

export default MarketNewsRow;
