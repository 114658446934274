
/** @jsx jsx */
import { jsx } from "@emotion/react"
import * as React from 'react';
import { FromToFilter, FromToValueProps } from "../from-to-filter";

export interface FromToValue {
  from: number | null;
  to: number | null;
}

export type CouponFilterProps = FromToValueProps;

export function CouponFilter(props: CouponFilterProps) {

  return (
    <FromToFilter icon="tag"
      label="Coupon"
      {...props}
    />
  );
}