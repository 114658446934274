/** @jsx jsx */

import * as React from 'react';

import {Button, Icon} from "@bdl-cmn-shared-packages-npm/design-system";
import {
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    RichText,
    Text,
    getFieldValue
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from '../../interfaces';
import {fetchData, getDataWithChild, renderLink} from "../../utils/helper";
import DictionaryComponent from '../../core/components/Dictionary/DictionaryComponent';

export interface GreyBackgroundDataComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
      width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
    `;
const title = css`
  line-height: 62px;
      width: 100%;
  text-align: center;
  color: #FFFFFF;
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 32px;
  @media (max-width: ${breakpointMax}) {  
    font-weight: 700;
    font-size: 24px;
    line-height: 21px;
  }
    `;
const items = css`
      width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
    `;
const itemText = css`
      width: 100%;
  color: #FFFFFF;
  line-height: 150%;
  font-size: 16px;
  font-weight: 600;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    margin-top: 8px;
  }
    `;
const value = css`
      color: #FFFFFF;
  font-weight: 800;
  font-size: 32px;
  line-height: 42px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }
    `;
const subtitle = css`
  width: 100%;
  text-align: center;
  margin-top: 24px;
  display: flex;
  font-weight: 600;
  margin-bottom: 48px;
  justify-content: center;
  color: #FFFFFF;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
    `;
const item = css`
  margin: 16px;
  display: flex;
  flex-direction: column;
  width: calc(33% - 32px);
  color: #FFFFFF;
  text-align: center;
  @media (max-width: ${breakpointMax}) {
   width: 100%;
  }
    `;

export const GreyBackgroundDataComponent: React.FunctionComponent<GreyBackgroundDataComponentProps> = (props: GreyBackgroundDataComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const bg: any = getFieldValue<any>(fields, 'background', false);

    const parentContainer = css`
      background: url(${renderLink(bg && bg.src)});
      width: 100%;
      padding-top: 96px;
      padding-bottom: 96px;
      @media (max-width: ${breakpointMax}) {
        padding-top: 46px;
        padding-bottom: 46px;
      }
    `;

    const [dictionaryItems, setDictionaryItems] = React.useState<{ [key: string]: string }>( {});
    const [isLoading, setIsLoading] = React.useState(true);

    const getDictionaryItems = async (id: string) => {
      try {
        const result = await fetchData(id);
        const dataReq = await getDataWithChild(result);
        const items: { [key: string]: string } = {};

        if (dataReq && dataReq.length > 0) {
          for (const d of dataReq) {
            if (d.children && d.children.length > 0) {
              for (const item of d.children) {
                const key = item.Key; 
                const phrase = item.Phrase;
                if (key && phrase) {
                  items[key] = phrase;
                }
              }
            } 
          }
        } else {
          console.warn('No data found:', dataReq);
        }

        setDictionaryItems(items);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching dictionary items:', err);
      }
    };

    React.useEffect(() => {
      getDictionaryItems('504AE189-9F36-4C62-9767-66D73D6C3084');
    }, []);

    const replaceKeysWithPhrases = (text: string, dictionary: { [key: string]: string }) => {
      return text.replace(/\[([^\]]+)\]/g, (match, key) => dictionary[key] || key);
    };

    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={subtitle}>
                    <Text
                        field={fields && fields.subTitle as SitecoreGenericTextField}
                    />
                </div>
                <div css={items}>
                    {
                        new Array(6).fill(0).map((arr: any, index: number) => {
                            if (fields && fields[`itemValue${index + 1}`] && (fields && fields[`itemValue${index + 1}`] as any).value){
                                const transformedContent = replaceKeysWithPhrases(
                                    (fields[`itemValue${index + 1}`] as any)?.value,
                                    dictionaryItems
                                  );
                                return (
                                    <div css={item} key={`items-${index}`}>
                                        <div css={value}>
                                           <DictionaryComponent text={transformedContent}/>
                                        </div>
                                        <div css={itemText}>
                                            <Text
                                                field={fields[`itemLabel${index + 1}`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    );
}
