/** @jsx jsx */

import * as React from 'react';

import {
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';
import { SitecoreGenericTextField } from '../../interfaces';
import {canUseDOM} from "../../predicates";
import ChinaIndexes from '../../core/components/Index/index-china';

export interface ChinaIndexSeriesProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
  background: #f0f5f5;
`;
const container = css`
  width: 90%;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
`;
const title = css`
  font-weight: bold;
  font-size: 48px;
  line-height: 61px;
  color: #253645;
  margin-bottom: 16px;
  @media (max-width: ${breakpointMax}) {
    font-size: 30px;
    line-height: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const description = css`
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  color: #253645;
  @media (max-width: ${breakpointMax}) {
    font-size: 20px;
    line-height: 100%;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const ChinaIndexSeries: React.FunctionComponent<ChinaIndexSeriesProps> = (
  props: ChinaIndexSeriesProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const isSee: number = getFieldValue<number>(fields, 'display SEE InternationalGreenBonds bond index series', 0);
  const currentPage: string = canUseDOM ? window?.location?.pathname?.split('/')?.pop() : 'noPath';

  function ChinaIndex() {
      let indexeIds : string[] = [];
      switch(currentPage?.toLowerCase()) {
          case 'sse-green-bond-index':
              indexeIds = ["950117", "950118"];
              break;
          case 'ccdc-index-series':
              indexeIds = ["1000005", "1000003", "1000004", "1000008"];
              break;
          case 'cufe-cni-index-series':
              indexeIds = ["1000000", "1000001", "1000002", "1000007"];
              break;
      }

      if( indexeIds?.length )
        return <ChinaIndexes indexesIds={indexeIds}/>
      
      return <></>;
    }

  return (
    <div css={parentContainer}>
      <div css={container}>
        <div css={title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={description}>
          <Text field={fields['sub title'] as SitecoreGenericTextField} />
        </div>
        <ChinaIndex/>
      </div>
    </div>
  );
};
