import { breakpoint, breakpointMax } from "../../../../style";

import { css } from "@emotion/react";

export const style = {
  wrapperCss: css`
    z-index: 30;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    position: absolute;
    top: 60px;
    left: 0;
    border: 1px solid #e3e6e8;
    box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
    background-color: white;
    border-radius: 4px;
  `,
  body: css`
    width: 100%;

    > div:first-child{
      margin-top: -2em;
    }
  `,
  itemCss: css`
    width: 100%;
    padding: 1em;
    text-decoration: none;
    color: initial;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #f8fafc;
    }
    border-top: 2px solid #E3E6E8;
  `,
  itemNoResult: css`
    border-top: 0px;
  `,
  itemDefault: css`
    padding: 1em;
    text-decoration: none;
    color: initial;
  `,
  overlayCss: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  `,
  closeBtnCss: css`
    position: sticky;
    padding: 4px;
    top: 0.5em;
    left: 96%;
    background: transparent;
    cursor: pointer;
  `,
  card: css`
    width: 100%;
    text-decoration: none;
    color: initial;
  `,
  cardHeader: css`
    width: 100%;
    display: flex;
    padding: 3px 0px;
  `,
  cardBody: css`
    width: 100%;
    display: flex;
    padding: 3px 0px;
  `,
  cardBodyFull: css`
    display: block;
  `,
  cardBodyLeft: css`
    width: 80%;
    padding-right: 15px;
  `,
  cardBodyRight: css`
    width: 20%;
    padding-left: 15px;
  `,
  cardFooter: css`
    width: 100%;
    display: flex;
    padding: 3px 0px;
  `,
  cardFooterSeparator: css`
    border-right: solid 2px #E3E6E8;
    padding-right : 5px;
    margin-right : 5px;
  `,
  cardTitle: css`
    font-weight: 700;
    line-height: 1.7;
    font-size: 16px;
  `,
  cardSubTitle: css`
    font-weight: 600;
    line-height: 1.5;
    font-size: 14px;
    color: #54666f;
  `,
  cardTagType: css`
    justify-content: center;
    border: solid 1px #E3E6E8;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 4px 8px;
    margin: 0px 3px;
    line-height: 1.2;
    font-size: 12px;
  `,
  cardTagCountry: css`
    text-transform: uppercase;
    line-height: 1.5;
    font-size: 14px;
    display:flex;
  `,
  cardTagCountryFlag: css`
    width: 32px;
  `,
  cardTagInfo: css`
    line-height: 1.5;
    font-size: 14px;
  `,
  cardFooterInfo: css`
    line-height: 1.5;
    font-size: 14px;
    color: #54666f;
  `,
  cardPercentage: css`
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
  `,
  cardPercentagePositive: css`
    color: #22AA5F;
  `,
  cardPercentageNegative: css`
    color: #cb1234;
  `,
  cardDate: css`
    font-size: 14px;
    line-height: 1.5;
  `,

};
