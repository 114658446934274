
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface ZeroHungerSvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const ZeroHungerSvg: React.FunctionComponent<ZeroHungerSvgProps> = ({ size: { width, height }, style }: ZeroHungerSvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 42 49'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>2 - Zero Hunger</title>
            <path d='M6.22295 11.4661H2.88588V10.8956C2.88588 9.94859 3.22518 9.59437 3.74345 9.03882L5.04472 7.66671C6.09617 6.54441 6.33107 5.82853 6.33107 4.61674V3.43852C6.33107 1.70847 5.49215 0.888184 3.67261 0.888184C1.89036 0.888184 0.976861 1.83524 0.976861 3.528V4.9896H2.82996V3.47208C2.82996 2.74128 3.1879 2.52502 3.61668 2.52502C4.00818 2.52502 4.34748 2.68535 4.34748 3.43479V4.43405C4.34748 5.4333 4.25799 5.8248 3.59804 6.52204L2.4571 7.73755C1.35345 8.91577 0.939575 9.7025 0.939575 10.8956V13.1253H6.22295V11.4661Z' fill='white'/>
            <path d='M19.7875 46.9842V47.8269C19.7875 47.8344 19.7837 47.8381 19.7837 47.8455C19.7837 47.853 19.7875 47.8605 19.7875 47.8642C19.7949 48.073 19.9664 48.2408 20.1752 48.2445C20.179 48.2445 20.179 48.2445 20.1827 48.2445C20.1864 48.2445 20.1902 48.2445 20.1939 48.2445H30.0932C30.097 48.2445 30.0969 48.2445 30.1007 48.2445C30.1044 48.2445 30.1081 48.2445 30.1081 48.2445C30.3244 48.2408 30.4959 48.0655 30.4996 47.8493C30.4996 47.8493 30.4996 47.8493 30.4996 47.8455C30.4996 47.8455 30.4996 47.8455 30.4996 47.8418V46.8873C36.0701 45.0603 40.2461 40.545 41.1633 35.0417C41.167 35.0305 41.167 35.0193 41.167 35.0081C41.1708 34.9895 41.1708 34.9708 41.1708 34.9522C41.1708 34.7322 40.9918 34.5532 40.7718 34.5532H9.41462H9.22446C9.00075 34.5532 8.82178 34.7322 8.82178 34.9522L8.84415 35.1311C9.81358 40.6904 14.1014 45.2318 19.7875 46.9842Z' fill='white'/>
            <path d='M30.4885 21.0933C30.358 21.2797 27.3118 25.6645 30.5556 29.2141C32.0433 30.8435 31.9165 32.279 31.4915 33.2484H33.572C33.9933 31.8166 33.8181 30.0195 32.1291 28.1701C29.739 25.5564 32.1589 22.0217 32.1962 21.9658C32.4833 21.5631 32.3304 21.0448 31.8569 20.8024C31.3871 20.5638 30.7756 20.6943 30.4885 21.0933Z' fill='white'/>
            <path d='M25.2163 33.2484H27.3005C27.7219 31.8166 27.5466 30.0195 25.8576 28.1701C23.4638 25.5564 25.8874 22.0217 25.9247 21.9658C26.2081 21.5631 26.0589 21.0448 25.5854 20.8025C25.1156 20.5638 24.5004 20.6906 24.217 21.0896C24.0865 21.2723 21.0365 25.6608 24.2841 29.2104C25.7718 30.8435 25.6413 32.279 25.2163 33.2484Z' fill='white'/>
            <path d='M17.9381 21.0936C17.8076 21.28 14.7577 25.6648 18.0053 29.2144C19.4929 30.8437 19.3662 32.2792 18.9411 33.2487H21.0217C21.443 31.8169 21.2677 30.0197 19.575 28.1704C17.185 25.5604 19.6085 22.0257 19.6458 21.9698C19.9292 21.5671 19.7763 21.0488 19.3065 20.8065C18.8367 20.5641 18.2215 20.6909 17.9381 21.0936Z' fill='white'/>
            <path d='M12.9419 5.26139H14.1947V5.93253H12.0843V5.26139L13.3781 1.70434H12.2148V1.0332H14.2469V1.70434L12.9419 5.26139Z' fill='white'/>
            <path d='M14.7502 1.02979H16.7189V1.70093H15.5779V3.10286H16.3945V3.76655H15.5779V5.25797H16.7189V5.92911H14.7502V1.02979Z' fill='white'/>
            <path d='M18.0574 3.68452V5.93284H17.2334V1.02979H18.434C19.2655 1.02979 19.5675 1.44366 19.5675 2.12971V2.75984C19.5675 3.28929 19.381 3.59876 18.9112 3.70689L19.698 5.93284H18.8292L18.0574 3.68452ZM18.0574 1.70093V3.22591H18.3333C18.6167 3.22591 18.7397 3.08049 18.7397 2.81949V2.09615C18.7397 1.8277 18.6167 1.6972 18.3333 1.6972H18.0574V1.70093Z' fill='white'/>
            <path d='M20.0895 4.66864V2.29727C20.0895 1.52919 20.4661 0.973633 21.3348 0.973633C22.211 0.973633 22.5876 1.52919 22.5876 2.29727V4.66864C22.5876 5.42927 22.211 5.99228 21.3348 5.99228C20.4661 5.98855 20.0895 5.42554 20.0895 4.66864ZM21.7599 4.79541V2.16304C21.7599 1.86476 21.6294 1.65596 21.3348 1.65596C21.044 1.65596 20.9172 1.86476 20.9172 2.16304V4.79541C20.9172 5.09369 21.0477 5.30249 21.3348 5.30249C21.6294 5.30249 21.7599 5.09369 21.7599 4.79541Z' fill='white'/>
            <path d='M13.7472 10.9548H12.953V13.1174H12.129V8.21436H12.953V10.2912H13.7472V8.21436H14.5787V13.1174H13.7472V10.9548Z' fill='white'/>
            <path d='M17.5168 8.21436V11.9243C17.5168 12.6849 17.1924 13.1771 16.3535 13.1771C15.4847 13.1771 15.123 12.6849 15.123 11.9243V8.21436H15.9471V12.0026C15.9471 12.2934 16.0626 12.4873 16.3535 12.4873C16.6443 12.4873 16.7599 12.2934 16.7599 12.0026V8.21436H17.5168Z' fill='white'/>
            <path d='M18.7547 10.0376V13.1174H18.0612V8.21436H18.8703L19.7577 11.0257V8.21436H20.4438V13.1174H19.7204L18.7547 10.0376Z' fill='white'/>
            <path d='M22.2334 10.5H23.4042V13.1175H22.8114V12.5806C22.7032 12.9497 22.4721 13.1734 22.0246 13.1734C21.3162 13.1734 20.9918 12.6104 20.9918 11.8498V9.47842C20.9918 8.71034 21.3609 8.15479 22.2297 8.15479C23.1395 8.15479 23.4079 8.66187 23.4079 9.38521V9.81772H22.6548V9.3181C22.6548 9.01608 22.5392 8.84084 22.2409 8.84084C21.9426 8.84084 21.8158 9.04964 21.8158 9.34792V11.9803C21.8158 12.2786 21.9389 12.4874 22.2222 12.4874C22.4907 12.4874 22.6287 12.3345 22.6287 12.0176V11.1488H22.2372V10.5H22.2334Z' fill='white'/>
            <path d='M23.9485 8.21436H25.9172V8.8855H24.7763V10.2874H25.5929V10.9511H24.7763V12.4425H25.9172V13.1137H23.9485V8.21436Z' fill='white'/>
            <path d='M27.2521 10.8691V13.1174H26.4281V8.21436H27.6287C28.4602 8.21436 28.7622 8.62823 28.7622 9.31428V9.94441C28.7622 10.4739 28.5758 10.7833 28.106 10.8915L28.8927 13.1174H28.0239L27.2521 10.8691ZM27.2521 8.88923V10.4142H27.528C27.8114 10.4142 27.9344 10.2688 27.9344 10.0078V9.28445C27.9344 9.016 27.8114 8.8855 27.528 8.8855H27.2521V8.88923Z' fill='white'/>

        </svg>
    );
};
