export enum OAMTypeDepo {
  AFAE = "O*10001",
  HYFR = "O*10002",
  PATG = "O*10003",
  QFRE = "O*10012",
  INIF = "N*10005",
  MSNO = "N*10006",
  ADIP = "N*10007",
  TNVC = "N*10008",
  CRAS = "N*10009",
  MATS = "N*10120",
  HOMS = "A*10004",
  BBPR = "O*10144",
  BBTR = "O*10145",
  SBMS = "O*10146",
  SBTR = "O*10147",
  SBOU = "O*10148"
}

export function OAMTypeDepoLabel(value: OAMTypeDepo, lang: string): string {
	if( lang?.toUpperCase() == "FR")
    return OAMTypeDepoLabelFr(value);
  else
    return OAMTypeDepoLabelEn(value);
}

export function OAMTypeDepoLabelEn(value: OAMTypeDepo): string {
	if( value == null)
    return "";

  switch (value) {
    case OAMTypeDepo.AFAE:
      return "Annual financial and audit reports";
    case OAMTypeDepo.HYFR:
      return "Half yearly fcial & audit reports / ltd reviews";
    case OAMTypeDepo.PATG:
      return "Payments to governments";
    case OAMTypeDepo.QFRE:
      return "Quarterly financial report";
    case OAMTypeDepo.INIF:
      return "Inside Information / Ad Hoc Information";
    case OAMTypeDepo.MSNO:
      return "Major shareholding notifications";
    case OAMTypeDepo.ADIP:
      return "Acquisition or disposal of the issuer's own shares";
    case OAMTypeDepo.TNVC:
      return "Total number of voting rights and capital";
    case OAMTypeDepo.CRAS:
      return "Changes in rights att. to classes of shares or sec";
    case OAMTypeDepo.MATS:
      return "Managers' transactions";
    case OAMTypeDepo.HOMS:
      return "Home Member State";
    case OAMTypeDepo.BBPR:
      return "Buy-back programmes​";
    case OAMTypeDepo.BBTR:
      return "Buy-back transactions​";
    case OAMTypeDepo.SBMS:
      return "Stabilisation measures​";
    case OAMTypeDepo.SBTR:
      return "Stabilisation transactions​";
    case OAMTypeDepo.SBOU:
      return "Stabilisation outcome​";
    default:
      return "default";
  }
}

export function OAMTypeDepoLabelFr(value: OAMTypeDepo): string {
  if( value == null)
    return "";

  switch (value) {
    case OAMTypeDepo.AFAE:
      return "Rapports financiers et rapports d'audit annuels​";
    case OAMTypeDepo.HYFR:
      return "Rapports financiers et d'audit semestriels / examens limités";
    case OAMTypeDepo.PATG:
      return "Sommes versées aux gouvernements​";
    case OAMTypeDepo.QFRE:
      return "Rapports financiers trimestriels​";
    case OAMTypeDepo.INIF:
      return "Informations privilégiées / ponctuelles​";
    case OAMTypeDepo.MSNO:
      return "Notifications de participation importante​";
    case OAMTypeDepo.ADIP:
      return "Acquisition ou cession des parts de l'émetteur​";
    case OAMTypeDepo.TNVC:
      return "Total du nombre de droits de vote et du capital​";
    case OAMTypeDepo.CRAS:
      return "Modification des droits des différentes catégories d’actions/valeurs​";
    case OAMTypeDepo.MATS:
      return "Transactions de responsables dirigeants​";
    case OAMTypeDepo.HOMS:
      return "État membre d'origine​";
    case OAMTypeDepo.BBPR:
      return "Programmes de rachat​";
    case OAMTypeDepo.BBTR:
      return "Opérations liées à un programme de rachat​";
    case OAMTypeDepo.SBMS:
      return "Mesures de stabilisation​";
    case OAMTypeDepo.SBTR:
      return "Opérations de stabilisation​";
    case OAMTypeDepo.SBOU:
      return "Résultat de stabilisation​";
    default:
      return "défaut";
  }
}