import { css } from "@emotion/react";

export const style = {
    title: css`
      font-family: 'Mont';
      font-style: normal;
      font-weight: 100;
      font-size: 13px;
      line-height: 150%;
      color: #253845;
    `,
    titleBold: css`
      font-weight: 700;
    `,
    titleMargin: css`
      margin-top: 1em;
      margin-bottom: 1em;
    `,
    titleGrey: css`
      color: #54666f;
    `,
    chartCard: css`
      box-sizing: border-box;
      //position: absolute;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 0%;
      background: #FFFFFF;
      border: 1px solid #D1D5D8;
      border-radius: 3px;
    `,

}
