import React, { Component } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Tr, Th } from '@bdl-cmn-shared-packages-npm/design-system';

export class OAMSearchHeaderFR extends Component {
  render() {
    return (
      <Tr>
        <Th>Publié</Th>
        <Th>Informations</Th>
        <Th>Émetteur</Th>
        <Th>ID</Th>
        <Th>Publication</Th>
        <Th>Date de référence/ période</Th>
        <Th></Th>
      </Tr>
    );
  }
}

export default OAMSearchHeaderFR;
