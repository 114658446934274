/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
  Icon,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { style } from "./index.style";

export interface PublishedProps {
  status: string;
}

export const Published = ({
  status
}: PublishedProps) => {
  return (
    <div css={style.container}>
      { ["IINI", "IRLI", "DINI"].includes(status) ? 
          <span css={style.validated}><Icon icon="check" /></span>
        : ["IANU", "IRPI", "DANU"].includes(status) ? 
          <span css={style.cancelled}><Icon icon="times" /></span>
        : ["IPRM", "DFSM", "DLCM", "DLGM", "DTDM"].includes(status) ?
          <span css={style.refreshed}><Icon icon="sync" /></span>
        :
          <span>-</span>
      }
    </div>
  )
}