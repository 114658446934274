
/** @jsx jsx */

import * as React from 'react';

import { ComponentFields, ComponentParams, ComponentRendering, Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from '../../interfaces';
import bg  from './bg.png';
import config from "../../temp/config";
import {renderLink} from "../../utils/helper";

export interface PeopleCardProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}
export interface CardProps {
    image?: any;
    downloadableImage?: any;
    fullName?: string;
    role?: string;
    biography?: string;
    description?: string;
}

const container = css`
  width: 100%;
  background: #E8ECEE;
  padding-top: 80px;
  padding-bottom: 80px;
`;
const childContainer = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: ${breakpointMax}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const card = css`
  position: relative;
  width: 100%;
  max-width: calc(50% - 10px);
  padding: 64px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #D9DEE2;
  box-shadow: 0 16px 24px rgba(37, 54, 69, 0.15);
  border-radius: 24px;
  overflow: hidden;
  justify-content: space-between;
  @media (max-width: ${breakpointMax}) {
    max-width: 100%;
    padding: 16px;
  }
`;
const cardImageBg = css`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: auto;
  z-index: 0;
  pointer-events: none;
  
  img {
    width: 100%;
  }
`;

const cardAvatar = css`
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 2;
  img {
    width: 100%;
    max-width: 290px;
  }
  @media (max-width: ${breakpointMax}) {
    padding: 32px;
  }
`;

const cardName = css`
  font-weight: 800;
  font-size: 36px;
  line-height: 46px;
  color: #CB1231;
  margin-top: 32px;

  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }
`;

const cardRole = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  margin-top: 16px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 18px;
    line-height: 31px;
  }
`;

const cardDescription = css`
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #354450;
  margin-top: 24px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 500;
    font-size: 14px;
  }
`;

const cardActions = css`
  display: flex;
  padding-top: 32px;
  gap: 16px;
`;
const cardTop = css`
  display: flex;
  flex-direction: column;
  
`;
const cardBottom = css`
  display: flex;
`;

const Card: React.FunctionComponent<CardProps> = (props: CardProps): React.ReactElement => {
    const { image, downloadableImage, fullName, role, biography, description } = props;
    return (
        <div css={card}>
            <div css={cardTop}>
                <div css={cardImageBg}>
                    <img src={renderLink(bg)} alt={fullName || 'bg'} />
                </div>
                <div css={cardAvatar}>
                    <img src={renderLink(image)} alt={fullName || 'people'} />
                </div>
                <div css={cardName}>
                    { fullName }
                </div>
                <div css={cardRole}>
                    { role }
                </div>
                <div css={cardDescription}>
                    <div
                        dangerouslySetInnerHTML={{__html: description}}
                    />
                </div>
            </div>
            <div css={cardBottom}>
                <div css={cardActions}>
                    <Button label={'Biography'} iconLeft={'file-pdf'} onClick={() => window.open(biography).focus()}/>
                    <Button label={'Picture'} iconLeft={'image'} wired variant={'secondary'} onClick={() => window.open(renderLink(downloadableImage?.src)).focus()}/>
                </div>
            </div>
        </div>
    );
}

export const PeopleCard: React.FunctionComponent<PeopleCardProps> = (props: PeopleCardProps): React.ReactElement => {
    const { fields  } = props;

    return (
        <div css={container}>
            <div css={childContainer}>
                {new Array(6).fill(0).map((arr: any, index: number) => {
                    if (fields && fields[`people ${index + 1} full name`] && (fields && fields[`people ${index + 1} full name`] as any).value) {
                        return (
                            <Card
                                key={`card-${index}`}
                                image={(fields[`people ${index + 1} presentation image`] as any)?.value?.src}
                                downloadableImage={(fields[`people ${index + 1} downloadable image`] as any)?.value}
                                fullName={(fields[`people ${index + 1} full name`] as any)?.value}
                                role={(fields[`people ${index + 1} role`] as any)?.value}
                                biography={(fields[`people ${index + 1} downloadable biography`] as any)?.value?.src}
                                description={(fields[`people ${index + 1} description`] as any)?.value}
                            />
                        )
                    }})
                }
            </div>
        </div>
    );
}
    