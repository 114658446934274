
/*
*   e-Listing - core - components - country flag icon - hrv
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const HRV: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.337' style={{fill: '#F0F0F0'}} width='512' height='341.326'/>
                <rect y='85.337' style={{fill: '#D80027'}} width='512' height='113.775'/>
                <rect y='312.888' style={{fill: '#0052B4'}} width='512' height='113.775'/>
                <g>
            <polygon style={{fill: '#338AF3'}} points='300.522,204.059 270.84,204.059 275.788,166.957 295.575,152.116 315.362,166.957    315.362,196.638  '/>
                    <polygon style={{fill: '#338AF3'}} points='211.478,204.059 241.16,204.059 236.212,166.957 216.425,152.116 196.638,166.957    196.638,196.638  '/>
        </g>
                <polygon style={{fill: '#0052B4'}} points='275.788,204.059 236.212,204.059 236.212,166.957 256,152.116 275.788,166.957 '/>
                <path style={{fill: '#F0F0F0'}} d='M196.638,196.638v81.623c0,19.436,9.389,36.719,23.868,47.555l10.388-2.6l13.109,13.188  c3.876,0.798,7.887,1.219,11.996,1.219c4.087,0,8.077-0.415,11.934-1.205l14.964-12.459l8.595,1.834  c14.474-10.834,23.868-28.101,23.868-47.531v-81.623L196.638,196.638L196.638,196.638z'/>
                <g>
            <rect x='196.641' y='196.641' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <rect x='244.124' y='196.641' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <rect x='291.617' y='196.641' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <rect x='220.383' y='220.383' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <rect x='267.876' y='220.383' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <rect x='196.641' y='244.124' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <rect x='220.383' y='267.865' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <rect x='244.124' y='244.124' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <rect x='291.617' y='244.124' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <rect x='267.876' y='267.865' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <rect x='244.124' y='291.617' style={{fill: '#D80027'}} width='23.745' height='23.745'/>
                    <path style={{fill: '#D80027'}} d='M220.383,291.613h-22.212c2.038,8.826,6.042,16.904,11.536,23.745h10.676V291.613z'/>
                    <path style={{fill: '#D80027'}} d='M291.617,315.357h10.677c5.493-6.842,9.498-14.919,11.536-23.745h-22.213V315.357z'/>
                    <path style={{fill: '#D80027'}} d='M220.383,315.357v10.361c6.906,5.196,14.976,8.923,23.745,10.71v-21.071H220.383z'/>
                    <path style={{fill: '#D80027'}} d='M267.873,315.357v21.071c8.769-1.786,16.839-5.514,23.745-10.71v-10.361H267.873z'/>
        </g>
    </svg>
);
