import { css } from "@emotion/react";

export const style = {
  search: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #F4F6F7;
  `,
  results: css`
    display: flex;
    flex-direction: row;
    width: 100%;
  `,
};
