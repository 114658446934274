
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {useEffect, useState} from "react";
import Loader from "../../core/components/Loader/loader.component";

export interface RtsSummaryComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const topContainer = css`
  width: 100%;
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
`;
const title = css`
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  color: #1A2934;
  margin-bottom: 8px;
`;
const container = css`
  max-width: 780px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 16px 24px rgba(37, 54, 69, 0.15);
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #D9E1E2;
  padding: 16px 32px 32px;
`;
const list = css`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const side = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`;
const row = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 150%;
  border-bottom: 1px solid #E3E6E8;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #22AA5F;
  cursor: pointer;
  padding-bottom: 4px;
`;

export const RtsSummaryComponent: React.FunctionComponent<RtsSummaryComponentProps> = (props: RtsSummaryComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const [summaryList, setSummaryList] = useState([])
    const [loading, setLoading] = useState(true);
    const [empty, setEmpty] = useState(false);
    useEffect(() => {
        if(document.getElementsByTagName('h2') && document.getElementsByTagName('h2').length > 0){
            const domSource = document.getElementsByTagName('h2');
            const domArray = [].slice.call(domSource);
            setSummaryList(domArray.map((elem: any) => elem.innerText));
        } else {
            setEmpty(true);
        }
        setLoading(false);
    }, []);

    return (
        <div css={topContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <Loader loading={loading}>
                    <div css={list}>
                        <div css={side}>
                            {
                                summaryList.slice(0,
                                    summaryList.length > 7 ?
                                        Math.floor(summaryList.length /2) :
                                        summaryList.length
                                ).map((opt: string, index: number) => (
                                    <div css={row} key={`first-row-${index}`} onClick={() => {
                                        document.getElementsByTagName('h2')[index].scrollIntoView({
                                            behavior: 'smooth'
                                        });
                                    }}>
                                        {opt}
                                    </div>
                                ))
                            }
                        </div>
                        {
                            summaryList.length > 7 && (
                                <div css={side}>
                                    {
                                        summaryList.slice(Math.floor((summaryList.length /2)), summaryList.length).map((opt: string, index: number) => (
                                            <div css={row} key={`second-row-${index}`} onClick={() => {
                                                document.getElementsByTagName('h2')[Math.floor((summaryList.length /2 + index))].scrollIntoView({
                                                    behavior: 'smooth'
                                                });
                                            }}>
                                                {opt}
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                    </div>
                </Loader>
            </div>
        </div>
    );
}
