import * as React from "react";

import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import { canUseDOM } from "../../predicates";
import hexa from "./hexa.svg";
import hexaGreen from "./hexaGreen.svg";
import { fetchData, getDataWithChild, renderLink } from "../../utils/helper";
import DictionaryComponent from "../../core/components/Dictionary/DictionaryComponent";

const lint = jsx; // linter hack
/** @jsx jsx */

export interface CardWithBackgroundAndTextComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const parentContainer = css`
  width: 100%;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 40px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
`;
const mainTitle = css`
  font-weight: 800;
  font-size: 48px;
  margin-bottom: 40px;
  text-align: center;
  color: #354450;
  width: 100%;

  text-align: left;
  @media (max-width: ${breakpointMax}) {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 24px;

    text-align: left;
  }
`;
const mainDescription = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: #354450;
  margin-bottom: 84px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.01em;
  }
`;

export const CardWithBackgroundAndTextComponent: React.FunctionComponent<CardWithBackgroundAndTextComponentProps> =
  (props: CardWithBackgroundAndTextComponentProps): React.ReactElement => {
    const { fields } = props;
    const pathname = canUseDOM && window.location.pathname;
    const isLgx =
      canUseDOM &&
      pathname &&
      !!pathname.split("/").length &&
      pathname.split("/")[1] === "discover-lgx";

    const isGradient: number = getFieldValue<number>(fields, "isGradient", 0);
    const isTriple: number = getFieldValue<number>(fields, "isTriple", 0);
    const iconColor = getFieldValue<any>(fields, "iconColor", null);

    const getIconColor = () => {
      if (iconColor === "green") {
        return hexaGreen;
      }
      else
      {
        return hexa;
      }
    };

    const hexabloc = css`
      height: 110px;
      width: 100px;
      margin-top: 81px;
      background: url(${renderLink(getIconColor())});
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      font-size: 32px;
      color: ${iconColor === "green" ? "#008C3D" : "#ca1234"};
    `;

    const card = css`
      //width: calc(50% - 32px);
      width: 100%;
      max-width: calc(${isTriple ? "33%" : "50%"} - 32px);
      margin: 16px;
      padding: 48px;
      border-radius: 8px;
      color: #354450;
      display: flex;
      flex-direction: column;
      background-position: top center;
      min-height: 440px;
      cursor: pointer;

      background-repeat: no-repeat !important;
      background-size: cover !important;
      @media (max-width: ${breakpointMax}) {
        max-width: 100%;
        margin: 0;
        margin-bottom: 16px;
        min-height: 220px;
        padding: 16px;
      }
    `;
    const cardNoLink = css`
      //width: calc(50% - 32px);
      width: 100%;
      max-width: calc(${isTriple ? "33%" : "50%"} - 32px);
      margin: 16px;
      padding: 48px;
      border-radius: 8px;
      color: #354450;
      display: flex;
      flex-direction: column;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: top center;
      min-height: 440px;
      @media (max-width: ${breakpointMax}) {
        max-width: 100%;
        margin: 0;
        margin-bottom: 16px;
        min-height: 220px;
        padding: 16px;
      }
    `;
    const headImg = css`
      margin-bottom: 8px;

      img {
        height: 40px;
      }

      @media (max-width: ${breakpointMax}) {
        img {
          height: 30px;
        }
      }
    `;
    const title = css`
      // color: ${isGradient ? "#425563" : "#FFFFFF"};
      color: #ffffff;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      margin-top: 220px;
      margin-bottom: 24px;
      @media (max-width: ${breakpointMax}) {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
      }
    `;
    const description = css`
        // color: ${isGradient ? "#425563" : "#FFFFFF"};
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      line-height: 23px;
      @media (max-width: ${breakpointMax}) {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        font-weight: 600;
        line-height: 23px;
        @media (max-width: ${breakpointMax}) {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
    `;

    const [dictionaryItems, setDictionaryItems] = React.useState<{ [key: string]: string }>( {});
    const [isLoading, setIsLoading] = React.useState(true);

    const getDictionaryItems = async (id: string) => {
      try {
        const result = await fetchData(id);
        const dataReq = await getDataWithChild(result);
        const items: { [key: string]: string } = {};

        if (dataReq && dataReq.length > 0) {
          for (const d of dataReq) {
            if (d.children && d.children.length > 0) {
              for (const item of d.children) {
                const key = item.Key;
                const phrase = item.Phrase;
                if (key && phrase) {
                  items[key] = phrase;
                }
              }
            }
          }
        } else {
          console.warn('No data found:', dataReq);
        }
        setDictionaryItems(items);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching dictionary items:', err);
      }
    };

    React.useEffect(() => {
      getDictionaryItems('504AE189-9F36-4C62-9767-66D73D6C3084');
    }, []);

    const replaceKeysWithPhrases = (text: string, dictionary: { [key: string]: string }) => {
      return text.replace(/\[([^\]]+)\]/g, (match, key) => dictionary[key] || key);
    };

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={mainTitle}>
            <Text
              field={fields && (fields.mainTitle as SitecoreGenericTextField)}
            />
          </div>
          <div css={mainDescription}>
            <Text
              field={
                fields && (fields.mainDescription as SitecoreGenericTextField)
              }
            />
          </div>
          {new Array(8).fill(0).map((arr: any, index: number) => {
            if (
              fields &&
              fields[`titleCard${index + 1}`] &&
              (fields && (fields[`titleCard${index + 1}`] as any)).value
            ) {
              const transformedContent = replaceKeysWithPhrases(
                (fields[`descriptionCard${index + 1}`] as any)?.value,dictionaryItems
              );

              return (
                <div
                  css={[
                    fields && fields[`cardLink${index + 1}`] === ""
                      ? cardNoLink
                      : card,
                    css`
                      ${isLgx
                        ? "background-image: linear-gradient(0deg, #028C45 0%, rgba(199, 214, 79, 0.80) 100%)"
                        : "background: linear-gradient(0deg, #425563 28.05%, rgba(66, 85, 99, 0) 100%)"}
                      ${fields &&
                      fields[`backgroundCard${index + 1}`]?.value?.src
                        ? `,url(${
                            fields &&
                            fields[`backgroundCard${index + 1}`]?.value?.src &&
                            renderLink(
                              fields &&
                                fields[`backgroundCard${index + 1}`]?.value?.src
                            )
                          })`
                        : ""};

                      :hover {
                        ${isLgx
                          ? "background-image: linear-gradient(0deg,rgba(0, 140, 61, 0.9),rgba(0, 140, 61, 0.9))"
                          : "background-image: linear-gradient(0deg, rgba(66, 85, 99, 0.9), rgba(66, 85, 99, 0.9))"}
                        ${fields &&
                        fields[`backgroundCard${index + 1}`]?.value?.src &&
                        fields &&
                        fields[`backgroundCard${index + 1}`]?.value?.src
                          ? `,url(${
                              fields &&
                              fields[`backgroundCard${index + 1}`]?.value
                                ?.src &&
                              renderLink(
                                fields &&
                                  fields[`backgroundCard${index + 1}`]?.value
                                    ?.src
                              )
                            })`
                          : ""};
                      }
                    `,
                  ]}
                  onClick={() =>
                    fields && fields[`cardLink${index + 1}`]?.value
                      ? canUseDOM &&
                        window.open(
                          fields && fields[`cardLink${index + 1}`]?.value,
                          (fields[`open link ${index + 1} in new tab`] as any)
                            ?.value
                            ? "_blank"
                            : "_self"
                        )
                      : console.debug
                  }
                  key={`issuer-${index}`}
                >
                  {fields &&
                    fields[`iconCard${index + 1}`] &&
                    (fields && (fields[`iconCard${index + 1}`] as any))
                      .value && (
                      <div css={hexabloc}>
                        <Icon
                          icon={
                            (fields && (fields[`iconCard${index + 1}`] as any))
                              .value
                          }
                        />
                      </div>
                    )}

                  <div
                    css={title}
                    style={{
                      marginTop:
                        fields &&
                        fields[`iconCard${index + 1}`] &&
                        (fields && (fields[`iconCard${index + 1}`] as any))
                          .value
                          ? "24px"
                          : "220px",
                    }}
                  >
                    {fields &&
                      fields[`small image card ${index + 1}`]?.value?.src && (
                        <div css={headImg}>
                          <img
                            alt=" "
                            src={renderLink(
                              fields[`small image card ${index + 1}`]?.value
                                ?.src
                            )}
                          />
                        </div>
                      )}
                    <Text
                      field={
                        fields[
                          `titleCard${index + 1}`
                        ] as SitecoreGenericTextField
                      }
                    />
                  </div>
                  <div css={description}>
                    <DictionaryComponent text={transformedContent}/>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };
