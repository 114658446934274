import * as React from 'react';
/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/react';
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import { style } from './index.style';
import issuer from './issuer.svg';
import { Tag } from '@bdl-cmn-shared-packages-npm/design-system';
import { renderLink } from '../../utils/helper';
import { withRouter, useHistory } from 'react-router-dom';
import {canUseDOM} from "../../predicates";

export interface TradingItemCardProps {
  member: any;
}

export const ItemCard = (
  props: TradingItemCardProps
) => {
  const { member } = props;
  const listing = member && member['Is listing member'] === "1";
  const trading = member && member['Is trading member'] === "1";
  const luxsePartner = member && member['Is LuxSE Partner'] === "1";
  const independentSoftwareVendor = member && member['Is independent software vendor'] === "1";

  const history = useHistory();

  const getLink = () => {
    if (member && member.ItemID && member.Name) {
      const encodedName = member?.Name?.replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-').replace(/--/g, '-');
      if (listing && !(independentSoftwareVendor || trading || luxsePartner)) {
        return `/listing/listing-members/${member?.ItemID}___${encodedName}`;
      } else if (listing && (independentSoftwareVendor || trading || luxsePartner)) {
        return `/listing/listing-members/${member?.ItemID}___${encodedName}`;
      } else if (independentSoftwareVendor) {
        return `/trading/independent-software-vendors/${member?.ItemID}___${encodedName}`;
      } else if (trading) {
        return `/trading/trading-members/${member?.ItemID}___${encodedName}`;
      } else if (luxsePartner) {
        return `/listing/luxse-partners/${member?.ItemID}___${encodedName}`;
      }
    }
    return null;
  };

  return (
    <div css={style.container} onClick={() => canUseDOM && window.open(getLink(), '_self')}>
      <div css={style.left}>
        <img src={renderLink(member?.logo)} alt={member?.Name} />
      </div>
      <div css={style.right}>
        <div css={style.label}>{member?.Name}</div>
        <div css={style.description}>{member['Sub name']}</div>
        {
            (listing || trading || luxsePartner || independentSoftwareVendor) && (
                <div css={style.tags}>
                    { listing && <Tag label='Listing member ' status={'active'} /> }
                    { trading && <Tag label='Trading member ' status={'active'} /> }
                    { luxsePartner && <Tag label='LuxSE Partner ' status={'active'} /> }
                    { independentSoftwareVendor && <Tag label='Independent software vendor ' status={'active'} /> }
                </div>
            )
        }
      </div>
    </div>
  );
};
// @ts-ignore
export const TradingItemCard = withRouter<IProps, any>(ItemCard)
