/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import * as React from 'react';
import ChinaBondsSSEView from "../../core/components/ChinaBonds/index-sse";

export const ChinaSSE: React.FunctionComponent = (): React.ReactElement => {

    return (
        <ChinaBondsSSEView/>
    );
}
    