import { breakpoint, breakpointMax } from '../../../style';

import { css } from '@emotion/react';

export const style = {
  container: css`
    max-width: 250px;
    width: 100%;
    position: absolute;
    top: 0;
    right: -250px;
    background: #ffffff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: solid 1px #d9e162;
    padding: 12px 24px;
    display: flex;
    transition: all 1s ease-in-out;
  `,
  embedContainer: css`
    width: 100%;
    display: flex;
  `,
  left: css`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
  `,
  leftIcon: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 14px;
    border-radius: 24px;
    background: #22AA5F;
    color: #ffffff;
    min-width: 24px;
    margin-right: 16px;
  `,
  leftIconClosed: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 14px;
    border-radius: 24px;
    background: #ca1234;
    color: #ffffff;
    margin-right: 16px;
    min-width: 24px;
  `,
  right: css`
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
  `,
  rightTop: css`
    width: 100%;
    color: #22AA5F;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
    }
  `,
  rightTopClosed: css`
    width: 100%;
    color: #ca1234;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
    }
  `,
  rightMiddle: css`
    font-weight: 600;
    color: #354450;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 4px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 10px;
      line-height: 13px;
    }
  `,
  rightBottom: css`
    font-weight: 600;
    color: #425563;
    font-size: 16px;
    line-height: 20px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }
  `,
};
