import * as React from 'react';

import { Bumper, Button, Checkbox, Icon, InputField } from '@bdl-cmn-shared-packages-npm/design-system';
import { breakpoint, breakpointMax } from '../../../style';
import { css, jsx } from '@emotion/react';

import { NotificationModel } from '../../../sitecore-components/RtsComponent';
import {canUseDOM} from "../../../predicates";

/** @jsx jsx */

export enum formBuilderType {
  checkbox = 'checkbox',
  inputText = 'inputText',
}

export interface RowInterface {
  label?: string;
  value?: any;
  mandatory: boolean;
  placeholder?: string;
  requiredLabel?: string;
  type?: formBuilderType;
}

export interface HomeComingNextComponentProps {
  onClickOutside: () => void;
  title: string;
  headerTitle: string;
  buttonLabel: string;
  buttonAction: (rows: RowInterface[]) => void;
  rows: RowInterface[];
  notification: NotificationModel;
  children?: any;
}

const style = {
  parentContainer: (visible: boolean) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: ${visible ? 'rgba(37, 54, 69, 0.6)' : 'transparent'};
    backdrop-filter: blur(16px);
    display: flex;
    justify-content: center;
    padding-top: 3%;
    z-index: 100;
    padding-left: 40px;
    padding-right: 40px;
    @media (max-width: ${breakpointMax}) {
      padding-top: 40px;
    }
    transition: all 0.3s ease-in-out;
    
    //Override Part
    
    input {
      border-radius: 4px;
      border: solid 1px #D9DEE2;
      min-height: 40px;
    }
    label {
      margin-bottom: 16px!important;
      display: flex!important;
      align-items: center!important;
    }
    input[type=submit] {
      box-shadow: 0 5px 0.625em rgba(66,85,99,.25)!important;
      border-radius: 6px!important;
      color: #ffffff!important;
      background-color: #22AA5F!important;
    }
  `,
  container: (visible: boolean) => css`
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    background: #ffffff;
    min-height: 400px;
    max-height: 80vh;
    width: 100%;
    max-width: 1280px;
    z-index: 101;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    opacity: ${visible ? '1' : '0'};
    transition: all 0.3s ease-in-out;
  `,
  header: css`
    display: flex;
    background: #253645;
    border-radius: 8px 8px 0 0;
    padding: 16px 32px;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    font-weight: 800;
    font-size: 32px;
    line-height: 41px;
    color: #ffffff;
  `,
  headerTitle: css`
    font-weight: 800;
    font-size: 32px;
    line-height: 41px;
    color: #ffffff;
    @media (max-width: ${breakpointMax}) {
      font-size: 16px;
      line-height: 150%;
    }
  `,
  close: css`
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
  `,
  body: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    padding: 40px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;

    @media (max-width: ${breakpointMax}) {
      padding: 16px;
    }
  `,
  title: css`
    display: flex;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #253645;
    @media (max-width: ${breakpointMax}) {
      font-size: 14px;
      line-height: 150%;
    }
  `,
  actions: css`
    display: flex;
    @media (max-width: ${breakpointMax}) {
      margin-top: -30px;
    }
  `,
  rows: css`
    display: flex;
    justify-content: flex-start;
    padding-top: 32px;
    padding-bottom: 32px;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin-bottom: 12px;
      padding-top: 16px;
    }
  `,
  row: css`
    display: flex;
    margin-bottom: 24px;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      margin-bottom: 6px;
    }
  `,
  rowField: css`
    min-width: 400px;
    @media (max-width: ${breakpointMax}) {
      min-width: 100%;
    }
  `,
  requiredLabel: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #354450;
    margin-top: 16px;
  `,
  checkboxIcon: css`
    height: 20px;
    width: 40px;
    margin-right: 1.5em;
  `,
  checkboxText: css`
    color: #425563;
    padding-bottom: inherit;
    font-weight: 600;
    font-size: 12px;
    > div > div:first-of-type {
      margin-right: 16px;
      width: 24px !important;
      height: 24px !important;
    }
    > div > div:last-of-type {
      width: 90%;
    }
    @media (max-width: ${breakpointMax}) {
      margin-bottom: 60px;
      > div > div:first-of-type {
        margin-right: 8px;
      }
    }
  `,
};

export const FormBuilder: React.FunctionComponent<any> = (props: HomeComingNextComponentProps): React.ReactElement => {
  const { onClickOutside, headerTitle, title, buttonLabel, buttonAction, rows = [], notification, children } = props;
  const [newRows, setNewRows] = React.useState<RowInterface[]>(rows);
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    setVisible(true);
    checkData();
  }, []);

  const stopPropagation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onEditField = (index: number, event: any) => {
    const tmpRows = [...newRows];
    if (tmpRows[index].type !== formBuilderType.checkbox) {
      tmpRows[index].value = event.target.value;
    } else {
      tmpRows[index].value = !tmpRows[index].value;
    }
    setNewRows(tmpRows);
    checkData();
  };

  const checkData = () => {
    const mandatoryCount = newRows.filter((field: RowInterface) => field.mandatory).length;
    const mandatoryCheckForInputType = newRows.filter(
      (field: RowInterface) => field.mandatory && field.type !== formBuilderType.checkbox && field.value !== ''
    ).length;
    const mandatoryCheckForCheckBoxType = newRows.filter(
      (field: RowInterface) => field.mandatory && field.type == formBuilderType.checkbox && field.value === true
    ).length;
    const mandatoryCheck = mandatoryCheckForInputType + mandatoryCheckForCheckBoxType;
    setDisabled(mandatoryCount !== mandatoryCheck);
  };

  const prepareClickOutside = () => {
    setVisible(false);
    setTimeout(() => {
      onClickOutside();
    }, 300);
  };

  React.useEffect(() => {
    if (children && canUseDOM) {
      const divTarget = document?.getElementById('childrenPart')?.getElementsByTagName('div')?.[1];
      if (divTarget) {
        divTarget.style.width = '100%';
      }
      const formElement = document?.getElementById('childrenPart')?.getElementsByTagName('form')?.[0];
      if (formElement && formElement.checkValidity()) {
        formElement.addEventListener('submit', (event: Event) => {
          event.preventDefault();
          prepareClickOutside();
          formElement.submit();
        });
      }
    }
  }, [children, canUseDOM]);

  return (
    <div css={style.parentContainer(visible)} /*onClick={prepareClickOutside}*/>
      <div css={style.container(visible)} /*onClick={stopPropagation}*/>
        <div css={style.header}>
          <div css={style.headerTitle}>{headerTitle}</div>
          <div css={style.close} onClick={prepareClickOutside}>
            <Icon icon={'times'} />
          </div>
        </div>
        <div css={style.body}>
          <div css={style.title}>{title}</div>
          {notification?.isResponseCatch && (
            <Bumper closable duration={3000} message={notification?.message} type={notification?.type} />
          )}
          {
            newRows?.length > 0 && (
              <div css={style.rows}>
                {newRows.map(
                    (row: RowInterface, index: number) =>
                        row?.type !== formBuilderType.checkbox && (
                            <div css={style.row} key={`row-${index}`}>
                              <div css={style.rowField}>
                                <InputField
                                    placeholder={row.placeholder}
                                    value={row.value}
                                    autoFocus={index === 0}
                                    onChange={(e: any) => onEditField(index, e)}
                                    width='100%'
                                />
                              </div>
                              {row.requiredLabel && <div css={style.requiredLabel}>{row.requiredLabel}</div>}
                            </div>
                        )
                )}
              </div>
              )
          }
          {newRows?.length > 0 && newRows.map(
            (row: RowInterface, index: number) =>
              row?.type === formBuilderType.checkbox && (
                <p css={style.checkboxText}>
                  <Checkbox
                    css={style.checkboxIcon}
                    key={`row-${index}`}
                    label={row?.label}
                    onClick={(e: any) => onEditField(index, e)}
                    checked={row?.value}
                  />
                </p>
              )
          )}
          {children}
          {
            buttonAction && buttonLabel && (
                <div css={style.actions}>
                  <Button disabled={disabled} label={buttonLabel} onClick={() => buttonAction(newRows)} />
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
};
