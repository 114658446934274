module.exports = {
  "sitecoreApiKey": "D5933D33-7A8C-4621-8CA1-50B285E49ACF",
  "sitecoreApiHost": "http://cd-luxse.grp-scor-prd.svc.cluster.local",
  "jssAppName": "bdl-port-luxse-ssr",
  "defaultLanguage": "en",
  "graphQLEndpointPath": "/api/bdl-port-luxse-ssr",
  "bourseLuGatewayGraphQLEndpoint": "",
  "backbourseEndpoint": "https://www.luxse.com/api",
  "downloadEndpoint": "https://dl.luxse.com/dl?v=",
  "documentsTypeToPreview": "fns,oam,luxse,cssf",
  "risersAndFallersReportLink": "https://app.powerbi.com/view?r=eyJrIjoiOWFiMGNlZmQtMjM2OC00NGYyLThkZjUtZDkyMTdjOWEwMmVjIiwidCI6IjAwYWRkN2ViLTAyYzAtNDM4NC05NDI4LWY2NzEwYTU2MjZlMyIsImMiOjl9",
  "topTradedSecuritiesReportLink": "https://app.powerbi.com/view?r=eyJrIjoiNjMyYzI0NDItM2FmNy00YzYzLWI1N2UtYjhhMzVlMDIwNGNiIiwidCI6IjAwYWRkN2ViLTAyYzAtNDM4NC05NDI4LWY2NzEwYTU2MjZlMyIsImMiOjl9",
  "marketCapitalisationReportLink": "https://app.powerbi.com/view?r=eyJrIjoiOThiODcxNTQtMmI3Ny00NTU0LTgyZDQtYzViY2M0ZTM5MjAxIiwidCI6IjAwYWRkN2ViLTAyYzAtNDM4NC05NDI4LWY2NzEwYTU2MjZlMyIsImMiOjl9",
  "luxSEGatewayGraphQLEndpoint": "https://graphqlaz.luxse.com/v1/graphql",
  "ecbCollateralEndpoint": "https://bdl-refe-ecb-collateral-userv-main-bdl-dms-dev.apps.dev.luxse.cloud/v1/",
  "graphQLEndpoint": "http://cd-luxse.grp-scor-prd.svc.cluster.local/api/bdl-port-luxse-ssr?sc_apikey=D5933D33-7A8C-4621-8CA1-50B285E49ACF"
};