export enum TargetPopulation {
	BELOW_POVERTY = 'Living below the poverty line',
	EXCLUDED_POPULATION = 'Excluded and/or marginalised populations and /or communities',
	VULNERABLE_GROUPS = 'Vulnerable groups including as a result of natural disasters',
	DISABILITIES = 'People with disabilities',
	MIGRANTS = 'Migrants and /or displaced persons',
	UNDERSERVED = 'Underserved (owing to a lack of quality access to essential goods and services',
	UNEMPLOYED = 'Unemployed',
	UNDEREDUCATED = 'Undereducated',
	MINORITIES = 'Women and/or sexual and gender minorities',
	AGING_YOUNG = 'Aging populations and vulnerable youth',
	OTHER_TARGET_POPULATION = 'Other',
}

export function targetPopulationLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(TargetPopulation).indexOf( value );
  		if( index >= 0) 
			return Object.values(TargetPopulation)[index];
	}catch (e){
		console.error("Failed to get TargetPopulation label for '" + value + "'", e);
	}
	
	console.error("TargetPopulation label for '" + value + "' doest exist");

	return value;
}
