import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
  ComponentParams,
  ComponentRendering,
} from "@sitecore-jss/sitecore-jss-react";

import { ResourcesHeader } from "../ResourcesHeader";
import Loader from "../../Loader/loader.component";
import { GlossaryItemRow } from "./GlossaryItemRow";
import {
  fetchData,
  getDataWithChild,
  getDataWithDynamicChild,
  getDataWithoutChild,
} from "../../../../utils/helper";
import { mobileCenterPage, resourceContent, rootPage } from "../shared.style";
import { GlossaryCategoryItemRow } from "./GlossaryCategoryItemRow";

export interface GlossaryProps {
  fields: any;
  isH1?: boolean;
  params?: ComponentParams;
  rendering: ComponentRendering;
  title?: string;
  description?: string;
}

export const GlossaryCategories: React.FunctionComponent<any> = (
  props: GlossaryProps
): React.ReactElement => {
  const [categories, setCategories] = React.useState<any>();
  const [allCategories, setAllCategories] = React.useState<any>();
  const [allLetters, setAllLetters] = React.useState<any[]>([]);
  const [letters, setLetters] = React.useState<any[]>([]);
  const [thematics, setThematics] = React.useState<any[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] =
    React.useState<boolean>(true);
  const [isLoadingLetter, setIsLoadingLetter] = React.useState<boolean>(true);
  const [selectedLetter, setSelectedLetter] = React.useState<string>("");
  const [selectedMode, setSelectedMode] = React.useState<string>("");
  const { title, description, isH1 } = props;

  const fetchGlossaryLetters = async (id: string) => {
    setIsLoadingLetter(true);
    try {
      const result: any = await fetchData(id, true);
      const data = await getDataWithChild(result, ["Categories", "Thematics"]);
      setAllLetters(data);
      setLetters(data);
      setIsLoadingLetter(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getDataByTheme = (theme: "categories" | "thematics") => {
    const newCategories: any = {};
    const newThematics: any = {};
    letters.forEach((letter: any) => {
      if (letter && letter.children && !!letter.children.length) {
        letter.children.forEach((child: any) => {
          if (child && child.Categories && !!child.Categories.length) {
            const categories: any = child.Categories.map(
              (cat: any) => cat.label
            );
            categories.forEach((cat: any) => {
              if (newCategories[cat]) {
                newCategories[cat].data.push(child);
              } else {
                newCategories[cat] = { data: [child] };
              }
            });
          }
          if (child && child.Thematics && !!child.Thematics.length) {
            const categories: any = child.Thematics.map(
              (cat: any) => cat.label
            );
            categories.forEach((cat: any) => {
              if (newThematics[cat]) {
                newThematics[cat].data.push(child);
              } else {
                newThematics[cat] = { data: [child] };
              }
            });
          }
        });
      }
    });
    return theme === "thematics" ? newThematics : newCategories;
  };

  const fetchGlossaryCategories = () => {
    setIsLoadingCategories(true);
    const allCats = getDataByTheme("categories");
    setAllCategories(allCats);
    setCategories(allCats);
    setIsLoadingCategories(false);
  };

  const fetchGlossaryThematics = async (id: string) => {
    try {
      const result: any = await fetchData(id);
      const data = await getDataWithChild(result, ["Categories", "Thematics"]);
      setThematics(data);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    fetchGlossaryThematics("10A98C29-05F9-4793-AEAF-CE6EDA97A370");
    fetchGlossaryLetters("FF92AFCB-10F5-45EE-9028-D66688C78BBA");
  }, []);

  React.useEffect(() => {
    if (letters.length) {
      fetchGlossaryCategories();
    }
  }, [letters]);

  const filterThematic = (theme: string) => {
    if (theme === "all") {
      setLetters(allLetters);
      setCategories(allCategories);
    } else {
      const newThematics = getDataByTheme("thematics");
      if (newThematics[theme]?.data?.length > 0) {
        setCategories({ [theme]: newThematics[theme as any] });
      } else {
        setCategories({ [theme]: { data: [] } });
      }
    }
  };

  return (
    <>
      <div css={rootPage}>
        <ResourcesHeader
          letters={letters.map((cat: any) => cat.label)}
          title={title}
          description={description}
          onChangeLetter={(letter: string) => setSelectedLetter(letter)}
          onChangeMode={(category: string) => setSelectedMode(category)}
          onChangeThematic={(thematic: string) => filterThematic(thematic)}
          thematics={thematics.map((t: any) => t && t.label)}
          mode={["Categories", "Letters"]}
          selectedMode={selectedMode}
          isH1={isH1}
        />
      </div>
      <div css={mobileCenterPage}>
        {selectedMode === "Categories" ? (
          <Loader
            loading={isLoadingCategories}
            centered
            css={{ minHeight: 400 }}
          >
            <div css={resourceContent("column")}>
              {categories &&
                Object.entries(categories).map((data: any, index: number) => (
                  <GlossaryCategoryItemRow
                    data={data}
                    keyData={`category-key-${index}`}
                    index={index}
                  />
                ))}
            </div>
          </Loader>
        ) : (
          <Loader loading={isLoadingLetter} centered css={{ minHeight: 400 }}>
            <div css={resourceContent("column")}>
              {letters
                .filter(
                  (cat: any) => cat.label.toLowerCase() === selectedLetter
                )
                .map((data: any, index: number) => (
                  <GlossaryItemRow
                    data={data}
                    keyData={`letter-key-${index}`}
                    index={index}
                  />
                ))}
            </div>
          </Loader>
        )}
      </div>
    </>
  );
};
