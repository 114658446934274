import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import {Th, Thead, Tr} from "@bdl-cmn-shared-packages-npm/design-system";

export const CssfApprovalsHeader = () => {
    return (
        <Thead>
            <Tr>
                <Th> Type </Th>
                <Th> Document name </Th>
                <Th> Host member states </Th>
                <Th></Th>
                <Th></Th>
            </Tr>
        </Thead>

    )
}
export default CssfApprovalsHeader;