import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
  GET_RELATED_SECURITIES_QUERY,
  GET_SEARCH_DOCUMENTS_ONLY_RESULTS,
  GET_SEARCH_ISSUERS_ONLY_RESULTS,
  GET_SEARCH_PROGRAMMES_ONLY_RESULTS,
  GET_SECURITY_INDICES_QUERY,
  GET_SECURITY_ISSUERS_QUERY,
  GET_SECURITY_PROGRAMME_QUERY,
  GET_SECURITY_QUERY,
  ProgrammeDetail,
} from "../../../graphql";
import { Tab, Tabs } from "@bdl-cmn-shared-packages-npm/design-system";
import { useEffect, useState } from "react";
import { GreenCard } from "../../../interfaces/green-card";
import Loader from "../../../core/components/Loader/loader.component";
import { Security, Document } from "../../../interfaces";
import SecurityBanner from "./banner/banner.component";
import SecurityDocuments from "./documents/index.component";
import SecurityIndices from "./SecurityIndices/securityIndices.component";
import SecurityOverview from "./SecurityOverview/securityOverview.component";
import SecurityRelated from "./SecurityRelated/securityRelated.component";
import SecuritySustainableData from "./SecuritySustainableData/index.component";
import YieldCalculator from "./yield-calculator/yield-calculator";
import { canUseDOM } from "../../../predicates";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import { style } from "./index.style";
import { withRouter } from "react-router-dom";
import { SecurityDisclaimer } from "./disclaimer/disclaimer";
import { SecurityMarket } from "./SecurityMarket";
import { goToNotFoundPage, NotFound } from "../404";
import { SecurityMeta } from "./meta";
import { InstrumentSubType, PeriodicityCode } from "../../../enums";
import { formatDate } from "../../../utils/date";
import { SearchInputContent } from "../Search/input";
import SearchIssuer from "../../../interfaces/search/search-issuer";
import Utils from "../../utils/utils";

export interface SecurityCardProps {
  fields?: any;
  match?: any;
  id?: string;
  isin?: string;
}

export enum SecurityCardTab {
  OVERVIEW = "OVERVIEW",
  MARKET = "MARKET",
  GREEN = "GREEN",
  DOCUMENTS = "DOCUMENTS",
  YIELD = "YIELD",
}

export const securityDetailPageLink = (isin: string, id: string) => {
  return `/security/${isin}/${id}`;
};

export const goToSecurityDetailPage = (isin: string, id: string) => {
  if (canUseDOM) window.open(securityDetailPageLink(isin, id), "_self");
};

export const isSecurityClickable = (status: string) => {
  return ["ADMI", "COTE", "NEGO", "FONG", "RETR"].includes(status);
};

export const SecurityCardContent: React.FunctionComponent<any> = ({
  id,
  isin,
}: SecurityCardProps): React.ReactElement => {
  let defaultTab = SecurityCardTab.OVERVIEW;
  if (canUseDOM) {
    const search = new URLSearchParams(window?.location?.search);
    const searchTab = search?.get("tab");
    if (searchTab && (searchTab as SecurityCardTab))
      defaultTab = searchTab as SecurityCardTab;
  }
  const [selectedTab, setSelectedTab] = useState<SecurityCardTab>(defaultTab);

  const updateTab = (tab: SecurityCardTab) => {
    setSelectedTab(tab);
  };

  const client = luxseGatewayGraphqlClient;

  const [security, setSecurity] = useState<Security>(null);
  const [securityLgx, setSecurityLgx] = useState<GreenCard>(null);
  const [showYieldCalculator, setShowYieldCalculator] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const getSecurity = async () => {
    let security = null;
    let securityLgx = null;
    let showYieldCalculator = false;
    try {
      if (!loading) setLoading(true);

      const results: any = await client.query({
        query: GET_SECURITY_QUERY,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          idSecurity: id,
        },
      });

      security = results?.data?.security || null;
      securityLgx = results?.data?.luxseLgx || null;

      if (
        !!security?.tags?.includes("LuxXPrime") &&
        !!security?.periodicityCode &&
        security?.periodicityCode !== "n/a" &&
        security?.periodicityCode !== PeriodicityCode.MATU &&
        security?.periodicityCode !== PeriodicityCode.INDE &&
        !!security.instrumentSubtype &&
        security.instrumentSubtype !== InstrumentSubType.FRN &&
        formatDate(security.maturityDate, "DD/MM/yyyy") !== "31/12/9999"
      )
        showYieldCalculator = true;
    } catch (e) {
      console.error("Failed to get security '" + id + "'", e);
    }

    if (!security || ["NNNI", "NCOT", "CLOT"].includes(security?.status))
      goToNotFoundPage();
    else {
      setSecurity(security);
      setSecurityLgx(securityLgx);
      setShowYieldCalculator(showYieldCalculator);
      setLoading(false);

      getIssuersData();
      getProgrammeData();
      getNoticesData();
      getRelatedSecuritiesData();
      getIndexesData();
    }
  };

  const [issuers, setIssuers] = useState<SearchIssuer[]>(null);
  const [issuersLoading, setIssuersLoading] = useState<boolean>(true);

  const getIssuersData = async () => {
    let issuersTmp = [];

    try {
      const results: any = await client.query({
        query: GET_SEARCH_ISSUERS_ONLY_RESULTS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          securityId: id,
          searchTerm: "",
          size: 50,
          page: 0,
        },
      });

      issuersTmp = results?.data?.luxseIssuersSearch?.issuers || [];
    } catch (e) {
      console.error("Failed to get issuers", e);
    }

    setIssuers(issuersTmp);
    setIssuersLoading(false);
  };

  const [programme, setProgramme] = useState<ProgrammeDetail>(null);
  const [programmeLoading, setProgrammeLoading] = useState<boolean>(true);

  const getProgrammeData = async () => {
    let programmeTmp = null;
    try {
      setProgrammeLoading(true);
      const results: any = await client.query({
        query: GET_SEARCH_PROGRAMMES_ONLY_RESULTS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          securityId: id,
          activeOnly: true,
          searchTerm: "",
          size: 1,
          page: 0,
        },
      });

      programmeTmp = results?.data?.luxseProgrammesSearch?.programmes
        ? results?.data?.luxseProgrammesSearch?.programmes[0]
        : null;
    } catch (e) {
      console.error("Failed to get programme", e);
    }

    setProgramme(programmeTmp);
    setProgrammeLoading(false);
  };

  const [relatedSecurities, setRelatedSecurities] = useState<Security[]>([]);

  const getRelatedSecuritiesData = async () => {
    try {
      const results: any = await client.query({
        query: GET_RELATED_SECURITIES_QUERY,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          idSecurity: id,
          relatedSecuritiesLimit: 3,
        },
      });

      if (results && results.data) {
        const data = results.data;
        setRelatedSecurities(data?.relatedSecurities || null);
      }
    } catch (e) {
      console.error("Failed to get related securities", e);
    }
  };

  const [notices, setNotices] = useState<Document[]>([]);
  const [noticesLoading, setNoticesLoading] = useState<boolean>(true);
  const [textInput, setTextInput] = React.useState<string>("");

  const getNoticesData = async () => {
    try {
      const results: any = await client.query({
        query: GET_SEARCH_DOCUMENTS_ONLY_RESULTS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: 5,
          page: 0,
          sort: `publishDate,desc`,
          securityId: id,
          documentTypes: ["FNS"],
          excludeDocumentSubTypes: ["D318"],
        },
      });

      setNoticesLoading(false);

      if (results && results.data) {
        const data = results.data;
        setNotices(data?.luxseDocumentsSearch?.documents || null);
      }
    } catch (e) {
      console.error("Failed to get notices", e);
      setNoticesLoading(false);
    }
  };

  const [indexes, setIndexes] = useState<any[]>([]);

  const getIndexesData = async () => {
    try {
      const results: any = await client.query({
        query: GET_SECURITY_INDICES_QUERY,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          idSecurity: id,
        },
      });

      if (results && results.data) {
        const data = results.data;
        setIndexes(data?.security?.indices || null);
      }
    } catch (e) {
      console.error("Failed to get indexes", e);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSecurity();
  }, []);

  return (
    <>
      <SearchInputContent onSearchInputUpdate={textInput} dataType="Security" />
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <div css={loading ? style.pageLoading : {}}>
            <Loader loading={loading} maxHeight={"100px"}>
              {!security ||
              ["NNNI", "NCOT", "CLOT"].includes(security?.status) ? (
                <NotFound
                  title="404"
                  description={`Oups! Page not found`}
                  goBack={true}
                />
              ) : (
                <>
                  <SecurityMeta security={security} greenCard={securityLgx} />
                  <SecurityBanner security={security} greenCard={securityLgx} />
                  <div css={style.tabsContainer}>
                    <Tabs tabPosition={"center"} padding={"1em 2em"}>
                      <Tab
                        label="Overview"
                        selected={selectedTab === SecurityCardTab.OVERVIEW}
                        onClickAction={() =>
                          updateTab(SecurityCardTab.OVERVIEW)
                        }
                        onTabChange={() => updateTab(SecurityCardTab.OVERVIEW)}
                      >
                        <SecurityOverview
                          securityData={security}
                          onTabChange={updateTab}
                          issuersData={issuers}
                          issuersLoading={issuersLoading}
                          programmeData={programme}
                          programmeLoading={programmeLoading}
                          noticesData={notices}
                          noticesLoading={noticesLoading}
                        />
                        <SecurityRelated
                          relatedSecurities={relatedSecurities}
                        />
                        <SecurityIndices indices={indexes} />

                        <SecurityDisclaimer security={security} />
                      </Tab>

                      <Tab
                        label="Market"
                        selected={selectedTab === SecurityCardTab.MARKET}
                        onClickAction={() => updateTab(SecurityCardTab.MARKET)}
                        onTabChange={() => updateTab(SecurityCardTab.MARKET)}
                      >
                        <SecurityMarket securityData={security} />
                      </Tab>

                      {securityLgx && security?.lgxDisplay ? (
                        <Tab
                          label="Sustainable data"
                          selected={selectedTab === SecurityCardTab.GREEN}
                          onClickAction={() => updateTab(SecurityCardTab.GREEN)}
                          onTabChange={() => updateTab(SecurityCardTab.GREEN)}
                        >
                          <SecuritySustainableData
                            securityData={security}
                            greenCard={securityLgx}
                          />
                        </Tab>
                      ) : (
                        <></>
                      )}

                      <Tab
                        label="Documents"
                        selected={selectedTab === SecurityCardTab.DOCUMENTS}
                        onClickAction={() =>
                          updateTab(SecurityCardTab.DOCUMENTS)
                        }
                        onTabChange={() => updateTab(SecurityCardTab.DOCUMENTS)}
                      >
                        <SecurityDocuments securityData={security} />
                      </Tab>

                      {showYieldCalculator ? (
                        <Tab
                          label="Yield Calculator"
                          selected={selectedTab === SecurityCardTab.YIELD}
                          onClickAction={() => updateTab(SecurityCardTab.YIELD)}
                          onTabChange={() => updateTab(SecurityCardTab.YIELD)}
                        >
                          <YieldCalculator securityData={security} />
                        </Tab>
                      ) : (
                        <></>
                      )}
                    </Tabs>
                  </div>
                </>
              )}
            </Loader>
          </div>
        </div>
      </div>
    </>
  );
};

export const SecurityCard = withRouter(SecurityCardContent);
