/** @jsx jsx */

import {
  DocumentDateEnum,
  documentDateLabel,
  findDocumentDateByName,
} from "../../../../../enums/document-date";
import {
  Icon,
  Menu,
  MenuWithChild,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";

import CountItem from "../../../Search/results/filters/count-item";
import DateRangeFilter from "../../../../../interfaces/search/search-filter-daterange";
import SeachDocumentFilters from "../../../../../interfaces/search/search-document-filters";
import SearchFilterParams from "../../../../../interfaces/search/search-filter-params";
import { breakpointMax } from "../../../../../style";
import { computeValues } from "../security/security-filters";
import { documentSubTypeLabel } from "../../../../../enums/document-sub-type";
import { documentTypeLabel } from "../../../../../enums/document-type";
import { style } from "../../../Search/results/filters/index.style";
import { useState } from "react";

const DATE_RANGE_SORT = [
  DocumentDateEnum.ONE_DAY_AGO,
  DocumentDateEnum.ONE_WEEK_AGO,
  DocumentDateEnum.TWO_WEEKS_AGO,
  DocumentDateEnum.MORE_THAN_TWO_WEEKS,
];

export interface DocumentFiltersProps {
  filterCount?: SeachDocumentFilters;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  fullWidth?: boolean;
}

function isEqualDateRange(
  filters: SearchFilterParams,
  dateRange: DateRangeFilter
): boolean {
  return (
    isEqual(filters.fromDate, dateRange.fromDate) &&
    isEqual(filters.toDate, dateRange.toDate)
  );
}

function isEqual(first: string, second: string) {
  // Handle special cases such as "filter is undefined so it should match de null date"
  return (!first && !second) || first === second;
}

function withoutDateFilters(filters: SearchFilterParams): SearchFilterParams {
  const { fromDate, toDate, ...newFilters } = filters;
  return newFilters;
}

export function DocumentFilters({
  filterCount,
  filters,
  onFiltersChange,
  fullWidth,
}: DocumentFiltersProps) {
  const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    let found = false;
    let parent = event.currentTarget;
    let head = parent.firstChild.firstChild;
    let target = event.target as Element;

    do {
      if (head === target) found = true;

      target = target.parentElement;
    } while (!found && parent !== target && target !== null);

    if (found) parent.classList.toggle("open");
  };

  const handleDateRangeChange = (dateRange: DateRangeFilter): void => {
    const newFilters = withoutDateFilters(filters);

    if (!isEqualDateRange(filters, dateRange)) {
      const { fromDate, toDate } = dateRange;
      if (fromDate) {
        newFilters.fromDate = fromDate;
      }
      if (toDate) {
        newFilters.toDate = toDate;
      }
    }

    onFiltersChange(newFilters);
  };

  const handleFilterChange = (
    key: keyof SearchFilterParams,
    value: any
  ): void => {
    let newFilters: SearchFilterParams = { ...filters };

    if (newFilters[key] instanceof Array && filters[key] instanceof Array) {
      try {
        // @ts-ignore
        let array: any[] =
          filters[key] != null ? Object.assign([], filters[key]) : [];
        if (array != null && array.includes(value))
          array = array.filter((v) => {
            return v !== value;
          });
        else array.push(value);
        array = array.filter((v) => v);
        if (array == null || !array.length) delete newFilters[key];
        else {
          // @ts-ignore
          newFilters[key] = array;
        }
      } catch (e) {
        console.error(
          "Failed to updated filter[" + key + "]",
          filters[key],
          " with value",
          value,
          e
        );
      }
    } else {
      if (filters[key] === value) {
        delete newFilters[key];
      } else {
        // @ts-ignore
        newFilters[key] = value;
      }
    }
    onFiltersChange(newFilters);
  };
  const closeBtn = css`
    width: 38px;
    height: 38px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    svg {
      color: #22aa5f;
      width: 25px !important;
      height: 25px !important;
      margin: 0px !important;
    }
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `;

  const [open, setOpen] = useState(true);

  return (
    <div
      css={[
        fullWidth
          ? style.searchResultsFiltersFullWidth
          : style.searchResultsFilters,
        css`
          position: relative;
          transition: all 0.1s ease-in-out;
          margin-left: ${open ? "0" : "-17%"};
          padding-right: ${open ? "16px" : "60px"};
        `,
      ]}
    >
      <div css={closeBtn} onClick={() => setOpen(!open)}>
        <Icon icon={open ? "arrow-left" : "arrow-right"}></Icon>
      </div>
      <div css={style.title}>Filter your search result</div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="user"
          label="Document Information"
          width="100%;"
          expanded={true}
          arrows={false}
        >
          <div
            css={[style.subRow, style.filterMenu]}
            onClick={handleFilterMenuClick}
          >
            <MenuWithChild
              icon="user"
              label="Types"
              width="100%;"
              expanded={true}
              arrows={false}
            >
              {computeValues(
                filterCount && filterCount.types,
                filters.documentTypes
              ) &&
                computeValues(
                  filterCount && filterCount.types,
                  filters.documentTypes
                )
                  .sort((first, second) => second.count - first.count)
                  .map((item) => {
                    if (item.count === -1)
                      return (
                        <div css={style.menuNoItem}>No filter available</div>
                      );

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={item.name}
                          width="100%;"
                          selected={
                            filters.documentTypes &&
                            filters.documentTypes.includes(item.name)
                          }
                          onSelect={() =>
                            handleFilterChange("documentTypes", item.name)
                          }
                          childLabel={
                            (
                              <CountItem
                                name={documentTypeLabel(item.name)}
                                count={item.count}
                              />
                            ) as unknown as string
                          }
                        />
                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>

          <div
            css={[style.subRow, style.filterMenu]}
            onClick={handleFilterMenuClick}
          >
            <MenuWithChild
              icon="user"
              label="Sub types"
              width="100%;"
              expanded={false}
              arrows={false}
            >
              {computeValues(
                filterCount && filterCount.subTypes,
                filters.documentSubTypes
              ) &&
                computeValues(
                  filterCount && filterCount.subTypes,
                  filters.documentSubTypes
                )
                  .sort((first, second) => second.count - first.count)
                  .map((item) => {
                    if (item.count === -1)
                      return (
                        <div css={style.menuNoItem}>No filter available</div>
                      );

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={item.name}
                          width="100%;"
                          selected={
                            filters.documentSubTypes &&
                            filters.documentSubTypes.includes(item.name)
                          }
                          onSelect={() =>
                            handleFilterChange("documentSubTypes", item.name)
                          }
                          childLabel={
                            (
                              <CountItem
                                name={documentSubTypeLabel(item.name)}
                                count={item.count}
                              />
                            ) as unknown as string
                          }
                        />
                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>
        </MenuWithChild>
      </div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="calendar"
          label="Range date"
          width="100%;"
          expanded={false}
          arrows={false}
        >
          {filterCount.dateRanges
            .filter(({ count }) => count > 0)
            .sort(
              (first, second) =>
                DATE_RANGE_SORT.indexOf(findDocumentDateByName(first.name)) -
                DATE_RANGE_SORT.indexOf(findDocumentDateByName(second.name))
            )
            .map((dateRange) => {
              return (
                <div css={[style.menuItem, style.menuItemRow]}>
                  <Menu
                    key={dateRange.name}
                    width="100%;"
                    selected={isEqualDateRange(filters, dateRange)}
                    onSelect={() => handleDateRangeChange(dateRange)}
                    childLabel={
                      (
                        <CountItem
                          name={documentDateLabel(dateRange.name)}
                          count={dateRange.count}
                        />
                      ) as unknown as string
                    }
                  />
                </div>
              );
            })}
        </MenuWithChild>
      </div>
    </div>
  );
}
