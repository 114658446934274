
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const COG: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <path fill="#FBDE4A" d="M503.172,88.276H361.931L2.703,421.218c1.589,1.539,3.737,2.506,6.124,2.506h141.241L509.297,90.782 C507.708,89.242,505.559,88.276,503.172,88.276z" />
            <path fill="#009543" d="M361.931,88.276H8.828C3.953,88.276,0,92.228,0,97.103v317.793c0,2.488,1.047,4.717,2.703,6.322 L361.931,88.276z" />
            <path fill="#DC241F" d="M509.297,90.782L150.069,423.724h353.103c4.875,0,8.828-3.953,8.828-8.828V97.103 C512,94.614,510.953,92.386,509.297,90.782z" />
        </svg>
    </React.Fragment>
);
