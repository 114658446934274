
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const KWT: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <rect y="85.337" fill="#FFFFFF" width="512" height="341.326" />
            <rect y="85.337" fill="#007A3D" width="512" height="113.775" />
            <rect y="312.888" fill="#CE1126" width="512" height="113.775" />
            <polygon points="166.957,312.889 0,426.663 0,85.337 166.957,199.111 " fill="#000000" />
        </svg>
    </React.Fragment>
);
