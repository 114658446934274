/** @jsx jsx */
import * as React from 'react';

import {Td, Tr} from '@bdl-cmn-shared-packages-npm/design-system';
import {css, jsx} from '@emotion/react';

import { style } from "../index.style";
import { sharedStyle } from '../../../../../styles/shared';
import SearchSecurity from '../../../../../interfaces/search/search-security';
import { goToSecurityDetailPage } from '../../../SecurityCard';
import PriceColumn from '../../../UI/list/security/price';

export const LGXSustainableBondsRow = ({row}: {row: SearchSecurity}) => {
    if(!row)
        return <></>;

    return (
        <Tr css={sharedStyle.tableRow}
            onRowClick={() => goToSecurityDetailPage(row.isinCode, row.id)}>
            <Td>
                <div css={[style.title, style.titleBold]}>
                    {row.name}
                </div>
                <div css={[style.title]}>
                    {`${row.isinCode} ${row.currency}`}
                </div>
            </Td>
            <Td>
                <PriceColumn {...row.marketData?.lastPrice} showChange={true} showDate={false}/>
            </Td>
        </Tr>
    );
}

export default LGXSustainableBondsRow;
