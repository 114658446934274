/** @jsx jsx */

import * as React from 'react';

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Placeholder,
  Text,
  getFieldValue,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import { Icon, Table, Tbody, Td, Th, Thead, Tr, TrGroup } from '@bdl-cmn-shared-packages-npm/design-system';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import ColumnGraph from '../../core/components/ColumnGraph/columnGraph';
import { GreyBannerText } from '../GreyBannerText';
import MixedChart from '../../core/components/MixedChart/mixedChart.component';
import { SitecoreGenericTextField } from '../../interfaces';
import { canUseDOM } from '../../predicates';
import greenHexa from './gradienthexa.svg';
import lineHexa from './linehexa.svg';
import redHexa from './redHexa.svg';
import redLineHexa from './redLineHexa.svg';
import { renderLink } from '../../utils/helper';
import { sharedStyle } from '../../styles/shared';

export interface TabsWithTimelinesProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .timeline-component-part {
    display: ${isExperienceEditorActive() ? 'flex' : 'none'};
  }
`;

const row = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TabsWithTimelines: React.FunctionComponent<TabsWithTimelinesProps> = (
  props: TabsWithTimelinesProps
): React.ReactElement => {
  const { fields, rendering } = props;
  const isRed: number = getFieldValue<number>(fields, 'red color', 0);

  const [visible, setVisible] = useState(0);
  const [hover, setHover] = useState<number>();

  const iconSelected = css`
    background: url(${renderLink(isRed ? redLineHexa : lineHexa)});
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 48px;
    background-repeat: no-repeat;
    background-size: cover;
  `;
  const tab = css`
    color: ${isRed ? '#CA1234' : '#22AA5F'};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    line-height: 20px;
    @media (max-width: ${breakpointMax}) {
      min-width: 50%;
      font-weight: 700;
      font-size: 14px;
      line-height: 180%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  `;
  const icon = css`
    color: ${isRed ? '#CA1234' : '#008C46'};
    background: url(${renderLink(isRed ? redHexa : greenHexa)});
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    margin-bottom: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: ${breakpointMax}) {
      height: 32px;
      width: 32px;
    }
  `;

  const titleSelected = css`
    width: 100%;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${isRed ? '#CA1234' : '#22AA5F'};
    border-bottom: solid 3px ${isRed ? '#CA1234' : '#22AA5F'};
    @media (max-width: ${breakpointMax}) {
      border-bottom: solid 2px ${isRed ? '#CA1234' : '#22AA5F'};
      min-width: 40%;
      font-weight: 700;
      font-size: 14px;
      line-height: 180%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  `;
  const title = css`
    width: 100%;
    color: #354450;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #d9e1e2;
    background-color: #ffffff;
    @media (max-width: ${breakpointMax}) {
      min-width: 40%;
      font-weight: 700;
      font-size: 14px;
      line-height: 180%;
      text-align: center;
      letter-spacing: 0.01em;
      margin-bottom: -13px;
    }
  `;

  const setRendering = () => {
    Array.from(document.getElementsByClassName('timeline-component-part')).forEach((el: any) => {
      el.style.display = 'none';
    });
    if (document.getElementsByClassName('timeline-component-part')[visible]) {
      (document.getElementsByClassName('timeline-component-part')[visible] as any).style.display = 'flex';
      (document.getElementsByClassName('timeline-component-part')[visible] as any).classList.add(
        'timeline-component-part--selected'
      );
    }
  };

  useEffect(() => {
    const params: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get('tab'),
    });
    setVisible(Number(params.tab));
  }, []);

  useEffect(() => {
    canUseDOM && setRendering();
  }, [visible]);

  return (
    <div css={container}>
      <div css={row}>
        <div css={sharedStyle.tabHeader}>
          {new Array(10).fill(0).map((arr: any, index: number) => {
            if (fields && fields[`tab${index + 1}Title`] && (fields && (fields[`tab${index + 1}Title`] as any)).value) {
              return (
                <>
                  <div
                    css={tab}
                    onClick={() => setVisible(index)}
                    onMouseEnter={() => visible !== index && setHover(index)}
                    onMouseLeave={() => visible !== index && setHover(undefined)}
                  >
                    <div css={visible === index ? iconSelected : hover === index ? iconSelected : icon}>
                      <Icon
                        icon={
                          fields &&
                          fields[`tab${index + 1}Icon`] &&
                          (fields && (fields[`tab${index + 1}Icon`] as any)).value
                        }
                      />
                    </div>
                    <div
                      css={visible === index ? titleSelected : hover === index ? titleSelected : title}
                      style={
                        hover === index
                          ? {
                              color: '#425563',
                              borderBottom: 'solid 3px #425563',
                            }
                          : {}
                      }
                    >
                      <Text field={fields[`tab${index + 1}Title`] as SitecoreGenericTextField} />
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
        {fields && fields[`title tab ${visible + 1}`] && (fields && (fields[`title tab ${visible + 1}`] as any)).value && (
          <div>
            <GreyBannerText
              fields={{
                title: {
                  value:
                    (fields &&
                      fields[`title tab ${visible + 1}`] &&
                      (fields && (fields[`title tab ${visible + 1}`] as any)).value) ||
                    null,
                },
                text: {
                  value:
                    (fields &&
                      fields[`description tab ${visible + 1}`] &&
                      (fields && (fields[`description tab ${visible + 1}`] as any)).value) ||
                    null,
                },
              }}
              rendering={rendering}
            />
          </div>
        )}
      </div>
      <div css={row}>
        <Placeholder name='timeline' rendering={rendering} props={{ visible }} />
      </div>
    </div>
  );
};
