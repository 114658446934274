
/*
*   e-Listing - core - components - country flag icon - svn
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const SVN: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.337' style={{fill: '#F0F0F0'}} width='512' height='341.326'/>
        <rect y='199.112' style={{fill: '#0052B4'}} width='512' height='113.775'/>
        <rect y='312.888' style={{fill: '#D80027'}} width='512' height='113.775'/>
        <path style={{fill: '#F0F0F0'}} d='M233.606,196.639v14.837c0,34.081-44.522,44.522-44.522,44.522s-44.522-10.44-44.522-44.522v-14.837  l-0.145-44.188l89.043-0.266L233.606,196.639z'/>
        <path style={{fill: '#0052B4'}} d='M233.606,196.639v14.837c0,34.081-44.522,44.522-44.522,44.522s-44.522-10.44-44.522-44.522v-14.837  l-0.145-44.188l89.043-0.266L233.606,196.639z'/>
        <path style={{fill: '#F0F0F0'}} d='M233.606,196.639v14.837c0,34.081-44.522,44.522-44.522,44.522s-44.522-10.44-44.522-44.522v-14.837  l14.848,14.837l29.674-22.261l29.685,22.261L233.606,196.639z'/>
    </svg>
);