/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { capitalizeFirstLetter, renderLink } from "../../utils/helper";
import { useHistory, useLocation } from "react-router-dom";
import { TimeToReadIndicator } from "../../core/components/TimeToReadIndicator";
import { breakpointMax } from "../../style";
import { getArticles } from "../../utils/blog";
import Loader from "../../core/components/Loader/loader.component";
import { Pagination } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  onBlogSearch,
  SearchBlogComponent,
} from "../../core/components/BlogSearch";
import { canUseDOM } from "../../predicates";

export interface BlogLatestArticlesProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = (isAuthor = false) => css`
  width: 100%;
  max-width: ${isAuthor ? "800px" : "100%"};
  padding-left: ${isAuthor ? "20px" : "0"};
  padding-right: ${isAuthor ? "20px" : "0"};
  display: flex;
  margin-left: auto;
  margin-right: auto;
  //padding-left: 40px;
  //padding-right: 40px;
  @media (max-width: ${breakpointMax}) {
    padding: 0;
  }
`;

const topContainer = css`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1264px;
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-top: 80px;
  @media (max-width: ${breakpointMax}) {
    padding: 0;
  }
`;

const leftPart = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  //padding-left: 40px;
  padding-right: 120px;
  @media (max-width: ${breakpointMax}) {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 24px;
  }
`;

const title = css`
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #5e798d;
  display: flex;
  //margin-left: 40px;
  margin-bottom: 32px;
  align-items: center;

  span {
    background: #a4b6c2;
    border-radius: 8px;
    padding: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #ffffff;
    margin-right: 8px;
  }

  @media (max-width: ${breakpointMax}) {
    margin-left: 0;
    display: none;
  }
`;

const rightPart = (full = false) => css`
  width: ${full ? "100%" : "30%"};
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;

const articleContainer = (small = false) => css`
  width: 100%;
  display: flex;
  margin-bottom: ${small ? "0" : "64px"};
  text-decoration: none;

  &:hover {
    .list-thumb {
      opacity: 0.6;
    }

    .list-title {
      color: #5e798d;
    }
  }

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    margin-bottom: 32px;
  }
  transition: 150ms ease-in-out;
`;

const articleLeft = (image?: string) => css`
  display: flex;
  background-image: url(${image});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e8ecee;
  background-position: center center;
  border-radius: 16px;
  height: 186px;
  max-height: 186px;
  width: 280px;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: ${breakpointMax}) {
    width: 100%;
    height: 180px;
    margin-bottom: 16px;
  }
  transition: 150ms ease-in-out;
`;

const articleRight = (small = false) => css`
  width: ${small ? "100%" : "calc(100% - 280px)"};
  min-height: ${small ? "auto" : "180px"};
  display: flex;
  flex-direction: column;
  padding-left: ${small ? "0" : "24px"};
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding-left: 0;
  }
  transition: 150ms ease-in-out;
`;

const articleTitle = (small = false) => css`
  font-weight: 800;
  font-size: ${small ? "20px" : "24px"};
  line-height: ${small ? "26px" : "31px"};
  color: #425563;

  &:hover {
    color: #5e798d;
  }

  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #425563;
  }
  transition: 150ms ease-in-out;
`;

const articleContent = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin-top: 24px;
  color: #253845;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #253845;
  }
`;

const articleTime = css`
  margin-top: 16px;
`;
const articlePart = css`
  display: flex;
  flex-direction: column;
  //padding-left: 40px;
  margin-bottom: 24px;
  @media (max-width: ${breakpointMax}) {
    padding-left: 0;
    margin-bottom: 16px;
  }
`;
const pagination = css`
  display: flex;
  width: 100%;

  > div {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: ${breakpointMax}) {
    padding-left: 0;
  }
`;

const RenderChild = ({ data, small }: any) => {
  return (
    <a
      href={data?.articleUrl.replace("/en/", "/") /*?.toLowerCase()*/}
      key={data?.title}
      css={articleContainer(small)}
    >
      {!small && (
        <div
          className={"list-thumb"}
          css={articleLeft(renderLink(data?.imageUrl || data?.imageSrc))}
        ></div>
      )}
      <div css={articleRight(small)}>
        <div css={articleTitle(small)} className={"list-title"}>
          {data?.title}
        </div>
        {!small && (
          <div css={articleContent}>
            <div dangerouslySetInnerHTML={{ __html: data?.shortDescription }} />
          </div>
        )}
        <div css={articleTime}>
          <TimeToReadIndicator
            data={data?.content}
            date={data?.creationDate}
            small={small}
          />
        </div>
      </div>
    </a>
  );
};

export const BlogLatestArticles: React.FunctionComponent<BlogLatestArticlesProps> =
  (props: BlogLatestArticlesProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const history = useHistory();

    const [articles, setArticles] = useState<any>([]);
    const [rootArticles, setRootArticles] = useState<any>([]);
    const [mostReads, setMostReads] = useState<any>([]);
    const [total, setTotal] = useState<number>(0);
    const [extra, setExtra] = useState<string>("");
    const [category, setCategory] = useState<string | null>(null);
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(true);
    const [hideSearch, setHideSearch] = useState<boolean>(false);
    const [mostLoader, setMostLoader] = useState<boolean>(true);

    const paginate: boolean = getFieldValue<boolean>(
      fields,
      "enable pagination",
      false
    );
    const searchable: boolean = getFieldValue<boolean>(
      fields,
      "enable searchbar",
      false
    );
    const displayMostRead: boolean = getFieldValue<boolean>(
      fields,
      "display most read",
      false
    );
    const hideLatestArticles: boolean = getFieldValue<boolean>(
      fields,
      "hide latest articles",
      false
    );
    const isAuthor: boolean = getFieldValue<boolean>(
      fields,
      "in author page",
      false
    );
    const maxItems: number = getFieldValue<number>(
      fields,
      "max items by page",
      10
    );
    const maxRead: number = getFieldValue<number>(
      fields,
      "max most read limit",
      5
    );
    const latestArticlesLabel: string = getFieldValue<string>(
      fields,
      "latest articles label",
      ""
    );
    const mostReadLabel: string = getFieldValue<string>(
      fields,
      "most read label",
      ""
    );
    const reservedWords = ["search", "blog"];
    const location = useLocation();

    const getCategory = () => {
      if (fields?.category?.name) {
        setCategory(capitalizeFirstLetter(fields?.category?.name));
        return capitalizeFirstLetter(fields?.category?.name);
      }
      if (canUseDOM) {
        if (
          !reservedWords.includes(window.location.pathname.split("/")?.pop()) &&
          !isAuthor &&
          window.location.pathname.split("/").length === 4
        ) {
          setCategory(
            capitalizeFirstLetter(window.location.pathname.split("/")[2])
          );
          return capitalizeFirstLetter(window.location.pathname.split("/")[2]);
        }
        if (
          reservedWords.includes(window.location.pathname.split("/")?.pop()) ||
          isAuthor ||
          window.location.pathname.split("/").length > 3
        ) {
          setCategory("");
          return "";
        }
        setCategory(
          capitalizeFirstLetter(
            window.location.pathname.split("/")?.pop()?.replace(/-/g, " ")
          )
        );
        return capitalizeFirstLetter(
          window.location.pathname.split("/")?.pop()?.replace(/-/g, " ")
        );
      }
    };

    useEffect(() => {
      if (canUseDOM) {
        getCategory();
        setTimeout(() => {
          document.getElementsByTagName("input") &&
            document.getElementsByTagName("input")[1] &&
            document
              .getElementsByTagName("input")[1]
              .setAttribute("autofocus", "true");
          document.getElementsByTagName("input") &&
            document.getElementsByTagName("input")[1] &&
            document.getElementsByTagName("input")[1].focus();
        }, 500);
      }
    }, [canUseDOM]);

    useEffect(() => {
      if (!loaded && (category !== null || category === "")) {
        if (getQueryParam("q")) {
          if (displayMostRead) {
            getArticles(`?skip=0&take=${maxRead}&sortby=view`).then(
              (articles: any) => {
                setMostReads(articles?.results);
                setMostLoader(false);
              }
            );
          }
          onBlogSearch(getQueryParam("q"))
            .then((response) => {
              formatArticles(response);
              setLoader(false);
            })
            .catch((err) => {
              setLoader(false);
              console.log("Error", err);
            });
        } else if (getQueryParam("tag")) {
          setHideSearch(true);
          fetchData(`&tags=${getQueryParam("tag")}`);
        } else {
          if (isAuthor) {
            const author = location.pathname
              .split("/")
              .pop()
              .split("-")
              .map((text: string) => text.replace(/ /g, ""))
              .filter((elem: string) => elem !== "")
              .join(" ");
            if (author) {
              const extraParam = `&authors=${author}`;
              setExtra(extraParam);
              fetchData(extraParam);
            } else {
              fetchData();
            }
          } else {
            fetchData();
          }
        }
        setLoaded(true);
      }
    }, [category]);

    const onPaginate = async (param: { selected: number }) => {
      setLoader(true);
      const articlesList = await getArticles(
        `?skip=${maxItems * param.selected}&take=${maxItems}${
          category ? `&categories=${category}` : ""
        }${extra}`
      );
      setArticles(articlesList?.results);
      setRootArticles(articlesList?.results);
      setLoader(false);
    };

    const getQueryParam = (param: string) => {
      const queryString = canUseDOM && window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const parameter = urlParams.get(param);
      if (parameter) {
        return parameter;
      }
      return null;
    };

    const fetchData = (extraParams = "") => {
      getArticles(
        `?skip=0&take=${maxItems}${
          category !== "" ? `&categories=${category}` : ""
        }${extraParams}`
      ).then((articles: any) => {
        setArticles(articles?.results);
        setRootArticles(articles?.results);
        setTotal(articles?.totalCount);
        setLoader(false);
      });
      if (displayMostRead) {
        getArticles(`?skip=0&take=${maxRead}&sortby=view`).then(
          (articles: any) => {
            setMostReads(articles?.results);
            setMostLoader(false);
          }
        );
      }
    };

    const formatArticles = (data: any) => {
      const results = data?.results.map((d: any) => ({
        articleUrl: d?.path,
        author: d?.author || "",
        authorPictureUrl: "",
        category: d?.categories?.[0],
        creationDate: d?.publicationDate,
        shortDescription: d?.description || "",
        title: d?.title,
        imageSrc: d?.imageSrc,
      }));
      setIsSearch(true);
      setArticles(results);
    };

    const resetSearch = (value: string | undefined) => {
      if (value.length === 0) {
        setArticles(rootArticles);
        setIsSearch(false);
        history.replace(`/blog/search`);
      } else {
        history.replace(`?q=${encodeURIComponent(value)}`);
      }
    };

    return (
      <div css={topContainer}>
        {searchable && !hideSearch && (
          <SearchBlogComponent
            onChange={(e: any) => formatArticles(e)}
            onSetValue={(value: string) => resetSearch(value)}
            value={getQueryParam("q")}
          />
        )}
        <div css={container(isAuthor)}>
          {!hideLatestArticles && (
            <div css={leftPart}>
              <div css={title}>
                {isSearch && <span>{articles?.length} </span>}
                {latestArticlesLabel}
                {getQueryParam("tag") ? ` in ${getQueryParam("tag")}` : ""}
                {getQueryParam("q") ? ` for ${getQueryParam("q")}` : ""}{" "}
                {isAuthor
                  ? `${location.pathname
                      .split("/")
                      .pop()
                      .split(" ")?.[0]
                      ?.replace("-", " ")}'s articles`
                  : ""}
              </div>
              <Loader loading={loader}>
                {articles?.length > 0 ? (
                  (articles || []).map((data: any, index: number) => (
                    <section key={`loop_${index}`}>
                      {data?.parent && <h3>{data.parent}</h3>}
                      <div css={articlePart}>
                        <RenderChild data={data} />
                      </div>
                    </section>
                  ))
                ) : (
                  <h3>No result found</h3>
                )}
              </Loader>
              {paginate && Number(maxItems) < Number(total) && (
                <div css={pagination}>
                  <Pagination
                    initialPage={0}
                    onPageChange={(d: any) => {
                      onPaginate(d);
                    }}
                    total={Math.ceil(total / maxItems)}
                  />
                </div>
              )}
            </div>
          )}
          {displayMostRead && (
            <div css={rightPart(hideLatestArticles)}>
              <div css={title}>{mostReadLabel}</div>
              <Loader loading={mostLoader}>
                {(mostReads || []).map((data: any, index: number) => (
                  <div key={`loop_${index}`}>
                    <h3>{data.parent}</h3>
                    <div css={articlePart}>
                      <RenderChild data={data} small />
                    </div>
                  </div>
                ))}
              </Loader>
            </div>
          )}
        </div>
      </div>
    );
  };
