import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Tr, Th, Thead } from '@bdl-cmn-shared-packages-npm/design-system';
import { data as dataMapper } from '../../../Search/results/dataMapper';

const thStyle = css`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px #d9dee2;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 2em;
  padding-right: 2em;
  text-align: left;
`;

const IndexHeader = ({ selectedCols }: any) => {
  return (
    <Thead>
      <Tr>
        <th css={[thStyle, css`width: 20%;`,]}>Type</th>
        <th css={[thStyle, { width: "300px" }]}>Name</th>
        <Th>Last</Th>
        <Th>Change (%)</Th>
        <Th>Change</Th>
        <Th>Month Hi/Lo</Th>
        <Th>Year Hi/Lo</Th>
        {selectedCols ? (
          selectedCols['indices'].map((col: string, index: number) => (
            <Th key={`col-${index}`}>{dataMapper['indices'][col].header}</Th>
          ))
        ) : (
          <></>
        )}
      </Tr>
    </Thead>
  );
};

export default IndexHeader;
