import { TWorldMap } from './worldmap';
import { CountryISOCodeAplha3 } from './iso-3.type';
import { countryISO2toContinentISO2 } from './mappers';

export const getIconSVGDraw = (code: CountryISOCodeAplha3, worldmap: TWorldMap): JSX.Element | null => (
    worldmap[code] !== undefined ? worldmap[code]! : null
);
/*
export const getIconSVGDrawIso2 = (code: string, worldmap: TWorldMap): string | null => (
    worldmap[code] !== undefined ? worldmap[countryISO2toContinentISO2(code)]! : null
);
*/