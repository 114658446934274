/** @jsx jsx */

import * as React from 'react';

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { SitecoreGenericTextField } from '../../interfaces';

export interface TitleDescriptionAndGreyCardsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
`;
const container = css`
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
  }
`;
const title = css`
  font-weight: 800;
  font-size: 32px;
  line-height: 41px;
  color: #008c3d;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }
`;
const description = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
  margin-top: 16px;
  margin-bottom: 24px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.01em;
  }
`;
const cards = css`
  display: flex;
  flex-wrap: wrap;
`;
const card = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  background: #f0f5f5;
  border-radius: 8px;
  width: calc(50% - 20px);
  margin-right: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #354450;
  cursor: pointer;
  justify-content: space-between;
  :hover {
    background: #E8ECEE;
  }
  svg {
    color: #22AA5F;
    min-width: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    margin: 0;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.01em;
  }
`;

export const TitleDescriptionAndGreyCards: React.FunctionComponent<TitleDescriptionAndGreyCardsProps> = (
  props: TitleDescriptionAndGreyCardsProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const headingValue: string = getFieldValue<string>(fields, 'heading', '');

  return (
    <div css={parentContainer}>
      <div css={container}>
        <div css={title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={description}>
          <Text field={fields && (fields.description as SitecoreGenericTextField)} />
        </div>
        <div css={cards}>
          {new Array(17).fill(0).map((arr: any, index: number) => {
            if (
              fields &&
              fields[`card ${index + 1} content`] &&
              (fields && (fields[`card ${index + 1} content`] as any)).value
            ) {
              return (
                <div
                  css={card}
                  key={`card-${index}`}
                  onClick={() =>
                    fields &&
                    fields[`card ${index + 1} link`] &&
                    (fields && (fields[`card ${index + 1} link`] as any)).value
                      ? (window.location.href =
                          fields &&
                          fields[`card ${index + 1} link`] &&
                          (fields && (fields[`card ${index + 1} link`] as any)).value)
                      : console.log
                  }
                >
                  <Text field={fields[`card ${index + 1} content`] as SitecoreGenericTextField} />
                  {/* <Icon icon={'up-right-from-square'} /> */}
                  <Icon icon={'external-link-alt'} />
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
