
/** @jsx jsx */
import { jsx } from "@emotion/react"
import * as React from 'react';
import { InputField, OptionsData, RadioButton } from "@bdl-cmn-shared-packages-npm/design-system";
import style from "./index.style";
import { OAMTypeDepo, OAMTypeDepoLabel } from "../../../../../enums/oam-type-depo";

export interface TypeDepoFilterProps {
  selection: any;
  onSelectionChange: (values: any) => void;
  data: any
  lang: string
}

export function TypeDepoFilter({ selection, onSelectionChange, data, lang }: TypeDepoFilterProps) {
  const [filter, setFilter] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);
  
  const getPlaceholder = () => {
    return lang?.toUpperCase() == "FR" ? 
      "Type d'information réglementé" : "Type of the regulated information";
  }

  const label = React.useMemo(() => {
    return selection ? OAMTypeDepoLabel(selection, lang) : getPlaceholder();
  }, [selection, lang]);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const clickOutside = (event: any) => {
        if (!ref.current.contains(event.target)) {
          setOpen(false);
          setFilter("");
        }
      }
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    }
  }, [open, lang]);

  const values: OptionsData[] = React.useMemo(() => {

    let valueTmp: OptionsData[] = [
      {
        name: lang === "EN" ? "All" : "Tous",
        value: null
      }
    ];

    Object.values(OAMTypeDepo).map((typeDepo: OAMTypeDepo) => (
      valueTmp.push({
        // @ts-ignore
        name: OAMTypeDepoLabel(typeDepo, lang),
        value: typeDepo
      })
    ));

    return valueTmp.filter((value: OptionsData) => value.name.toUpperCase().includes(filter.toUpperCase()));
  }, [data, filter, lang]);

  const handleSelectionChange = (value: OptionsData) => {
    onSelectionChange(value);
  }

  return (
    <>
      <div ref={ref} css={style.container} onClick={(e) => e.stopPropagation()}>
        <div css={style.inputWrapper}>
          <InputField
            onFocus={() => setOpen(true)}
            value={open ? filter : null}
            onClick={(e) => e.stopPropagation()}
            onChange={(e: any) => setFilter(e.target.value)}
            placeholder={open ? getPlaceholder() : label}
          />
        </div>
        {
          open && (
            <>
              <div css={style.panel} onClick={(e) => e.stopPropagation()}>
                { values.map( (value : OptionsData) => {
                    if( !value )
                      return <></>;
                    
                    return (
                      <React.Fragment key={`type-depo-${value.value}`}>
                        <RadioButton
                          label={value.name}
                          onClick={() => handleSelectionChange(value)}
                          checked={selection == value.value}
                        />
                      </React.Fragment>
                    );
                  })
                }
              </div>
            </>
          )
        }
      </div>
    </>
  )
}
