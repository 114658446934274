/** @jsx jsx */
import { Button, Loader, ToggleButtonGroup, ToggleItem } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import React from "react";
import { useEffect, useState } from "react";
import { GET_RISERS_FALLERS_STATISTICS } from "../../../graphql/queries/market.queries";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import { breakpointMax } from "../../../style";
import RisersAndFallersChart from "./Charts/RisersAndFallersChart";
import TopTradedSecuritiesHome from "./Home/top-traded-securities";
import RisersFallersTable, { RisersAndFallersDataModel } from "./View/risers-fallers-table";
import { GET_LUXSE_REPORTS } from "../../../graphql/queries/luxse-reports.queries";
import { LuxseReport } from "../../../interfaces/luxse-report";


export function RisersAndFallersView(props: any) {

  const [target1, setTarget1] = useState<string>('Graph');
  const [target2, setTarget2] = useState<string>('Graph');
  const [target, setTarget] = useState<string>('Yearly');

  let [risersAndFallers, setRisersAndFallers] = useState<RisersAndFallersDataModel[]>();
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const client = luxseGatewayGraphqlClient;

  const style = {
    displayViewMode: css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 32px 0;

    
        @media (max-width: ${breakpointMax}) {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 24px 8px;
          & > div {
            display: flex;
            flex-direction: column;
            width: 100%;
            & > div {
              border-left: 1px solid #e3e6e8;
              height: 50px;
              font-size: 16px;
            }
            & div {
              width: 100%;
            }
          }
        }
    
        > div:first-of-type {
          border-right: 1px solid #e3e6e8;
        }
    
        div {
          display: flex;
          align-items: center;
          padding-top: 2px;
          padding-bottom: 2px;
          height:37px;
          font-size: 14px;
        }
      `,
    title: css`
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #354450;
        flex: none;
        order: 0;
        flex-grow: 0;
        padding-right: 8px;
        @media (max-width: ${breakpointMax}) {
            margin-bottom: 14px;
        }
      `,
    container: css`
        width:85%;
        padding-left: 40px;
        padding-right: 40px;

        padding-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        border: solid 1px #d9dee2;
        border-radius: 8px;
        position: relative;
        font-family: 'Mont';
        color:#425563;
    
        h1 {
            font-weight: 700;
            font-size: 48px;
            line-height: 61px;
            
        }

        h3{
          font-weight: 700;
          font-size: 32px;
          line-height: 41px;
          border-bottom: solid 1px #d9dee2;
          width:100%;
          padding-bottom:7px;
          padding-top:7px;

          @media (max-width: ${breakpointMax}) {
            margin-bottom:4px;
          }

        }

          @media (max-width: ${breakpointMax}) {
            width: 95%;
            padding-left: 2px;
            padding-right: 2px;
            border:0;
            border-radius:0px;
          }

        .apexcharts-menu-item.exportPNG {
              display: none !important;
        }
      `,
      
    titleContainer: css`
  width: 100%;
  max-width: 1280px;
  //padding-left: 40px;
  //padding-right: 40px;
  margin-right: auto;
  margin-bottom: 0px;
  padding-bottom:0px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
`,
    parentContainer: css`
  width:85%;

  padding-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  margin: auto;
  padding-top:80px;

  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
`,
    mainTitle: css`
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 30px;
  text-align: center;
  line-height: 61px;
  color: #425563;
  width: 100%;

  text-align: left;
  @media (max-width: ${breakpointMax}) {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 24px;

    text-align: left;
  }
`,
    mainDescription: css`
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: #354450;
  margin-bottom: 34px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.01em;
  }
`,
  displayOtherReports:css`
  padding:160px 113px; 
  background: var(--ligt-grey, #F6F8F9);
  width:100%;
  @media (max-width: ${breakpointMax}) {
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 40px;
  }
`, 
  loaderWrapper: css`
  position:absolute;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
    `,
};

  const fetchRisersFallersData = async () => {
    try {
      if (!dataLoading)
        setDataLoading(true);
      risersAndFallers = [];
      const results: any = await client.query({
        query: GET_RISERS_FALLERS_STATISTICS,
      });

      results?.data?.risersAndFallers?.forEach((element: any) => {
        risersAndFallers.push(element);
      });
      setRisersAndFallers(risersAndFallers);
      setDataLoading(false);
    } catch (e) {
      console.error('Failed to get risers and fallers data', e);
      setDataLoading(false);
    }
  }

  const fetchPdfData = async () => {
    let report : LuxseReport = null;
    try {
      if (!dataLoading)
        setPdfLoading(true);

      const results: any = await client.query({
        query: GET_LUXSE_REPORTS,
        variables: {
          query: "",
          usePageable: true,
          page: 0,
          size: 1,
          types: ["MARKET_STATISTICS"],
          subTypes: ["TOP_PERFORMERS"],
          sorts: ["date,DESC"]
        },
      });
      if( results?.data?.luxseReports?.resultSize > 0){
        report=results?.data?.luxseReports?.resultList[0];
      }
      
      downloadFile(report);
      setPdfLoading(false);
    } catch (e) {
      console.error('Failed to get lux components data', e);
      setPdfLoading(false);
    }
  }

  function downloadFile(report: LuxseReport) {
    if(report != null){
      const downloadLink = document.createElement("a");

      downloadLink.href = report.downloadUrl;
      downloadLink.download = report.name;
      downloadLink.click();
    }
  }

  useEffect(() => {
    fetchRisersFallersData();
  }, []);

  return (
    <>
      <div css={style.parentContainer}>
        <div css={style.titleContainer}>
          <div css={style.mainTitle}>
            Risers and fallers statistics
          </div>
          <div css={style.mainDescription}>
            Consult the stocks’ risers and fallers at the Luxembourg Stock Exchange.
          </div>
        </div>
        <div css={[{ marginTop: "0px" },pdfLoading?style.loaderWrapper:'',]}>
        <Loader loading={pdfLoading}>
            {!pdfLoading ?
            <Button
              onClick={(e) => fetchPdfData()}
              wired={true}
              iconLeft="file-pdf"
              label={`Monthly and yearly reports`}
              css={css`margin-right:auto;`}
            />:<></>
          }
      </Loader>
        </div>
      </div>
      <div css={style.container}>
        <h1>Performances</h1>

        <div css={[style.displayViewMode, css`justify-content: start; padding: 32px 0;`]}>
          <span css={style.title}>Display</span>
          <ToggleButtonGroup onChange={(elem: any) => setTarget(elem)}>
            <ToggleItem value='Yearly' selected={target === 'Yearly'}>
              Yearly performances
            </ToggleItem>
            <ToggleItem selected={target === 'Monthly'} value='Monthly'>
              Monthly performances
            </ToggleItem>
          </ToggleButtonGroup>
        </div>

        {target === 'Monthly' &&
        <>
        <h3>Monthly</h3>
        <div css={style.displayViewMode}>
          <span css={style.title}>View mode </span>
          <ToggleButtonGroup onChange={(elem: any) => setTarget1(elem)}>
            <ToggleItem selected={target1 === 'Graph'} value='Graph'>
              Graph
            </ToggleItem>

            <ToggleItem value='Table' selected={target1 === 'Table'}>
              Data table
            </ToggleItem>
          </ToggleButtonGroup>
        </div>
        <div
          css={css`
                    margin-left: 80px;
                    margin-right: 80px;
                    @media (max-width: ${breakpointMax}) {
                        margin:0px;
                        margin-top:2.2rem;
                    }
                `}
        >
          {target1 === 'Graph' && <RisersAndFallersChart data={risersAndFallers} type="MTD" loading={dataLoading} />}
          {target1 === 'Table' && <RisersFallersTable type="Month" />}
        </div>
        </>}

        {target === 'Yearly' &&
        <>
        <h3>Yearly</h3>
        <div css={style.displayViewMode}>
          <span css={style.title}>View mode </span>
          <ToggleButtonGroup onChange={(elem: any) => setTarget2(elem)}>
            <ToggleItem selected={target2 === 'Graph'} value='Graph'>
              Graph
            </ToggleItem>

            <ToggleItem value='Table' selected={target2 === 'Table'}>
              Data table
            </ToggleItem>
          </ToggleButtonGroup>
        </div>
        <div
          css={css`
                    margin-left: 80px;
                    margin-right: 80px;
                    @media (max-width: ${breakpointMax}) {
                        margin:0px;
                        margin-top:2.2rem;
                    }
                `}
        >
          {target2 === 'Graph' && <RisersAndFallersChart data={risersAndFallers} type="YTD" loading={dataLoading} />}
          {target2 === 'Table' && <RisersFallersTable type="Year" />}
        </div>
        </>}
      </div>
      <div css={style.displayOtherReports}>
      <TopTradedSecuritiesHome isDisplayed={true} from="risers-fallers" />
      </div>
    </>
  )
}

export default RisersAndFallersView