/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { SitecoreGenericTextField } from '../../interfaces';
import { GeoMapDataComponent } from "../../core/components/GeoMap/GeoMapComponent";
import { breakpointMax } from "../../style";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";

export interface GeoMapDataProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-bottom: 70px;
    justify-content: center;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: ${breakpointMax}) {
        padding-left: 40px;
        padding-right: 40px;
    }
`;
const geoMap = css`
    @media (max-width: 321px) {
        padding-left: 0px;
        padding-right: 0px;
    }
`;
const text = css`
    @media (min-width: 320px) and (max-width: ${breakpointMax}) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
const titleStyle = css`
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: normal;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-size: 24px;
    line-height: 31px;
  }
`;
const subTitleStyle = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #425563;
  margin-top: 32px;

    @media (max-width: ${breakpointMax}) {
        font-size: 16px;
    }

    @media (min-width: 320px) and (max-width: ${breakpointMax}) {
        padding-bottom: 0px;
    }
`;
const button = css`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

export const GeoMapData: React.FunctionComponent<GeoMapDataProps> = (props: GeoMapDataProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const titleValue: string = getFieldValue<string>(fields, 'title', '');
    const subTitleValue: string = getFieldValue<string>(fields, 'subTitle', '');
    const buttonLabel: string = getFieldValue<string>(fields, 'buttonLabel', '');
    const buttonLink: string = getFieldValue<string>(fields, 'buttonLink', '');
    const colorRed = getFieldValue<boolean>(fields, "isRed", false);

    const [isRed, setIsRed] = React.useState(colorRed);

    return (
        <section css={container}>
            <div css={geoMap}>
                <GeoMapDataComponent data={fields?.data?.value as any} isRed={isRed} />
            </div>
            <div css={text}>
                {titleValue && (
                    <div css={titleStyle}>
                        <Text field={fields && (fields.title as SitecoreGenericTextField)} />
                    </div>
                )
                }
                {subTitleValue && (
                    <div css={subTitleStyle}>
                        <Text field={fields && (fields.subTitle as SitecoreGenericTextField)} />
                    </div>
                )
                }
                {buttonLabel && (
                    <div css={button}>
                        <Button 
                        label={buttonLabel} 
                        onClick={() => (buttonLink ? (window.location.href = buttonLink) : console.log())} 
                        iconRight="arrow-right"
                        />
                    </div>
                )} 
            </div>
        </section>
    );
}
