import * as React from 'react';
import { css } from '@emotion/react';

export const style = {
  roundTag: css`
    border: solid 3px red;
    border-radius: 2em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 28px;
    color: #ffffff;
    padding-top: 4px;
    min-height: 24px;
    min-width: 24px;
    text-align: center;

    @media (max-width: 720px) {
      border: solid 2px red;
      min-width: 2em;
      min-height: 2em;
      font-size: 1em;
    }
  `,
};
