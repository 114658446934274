import gql from "graphql-tag";
import { securityMarketRealtimeFragment, securityMarketSummaryFragment } from "../securities-market.queries";

export const securityBaseFragment = gql`
  fragment securityBaseFragment on Security {
    id
    name: instrumentName
    isinCode: isin
    status
    delistingDate
    currency
    category: instrumentType
    subCategory: instrumentSubtype
    listingDate
    finalMaturityDate: maturityDate
    tags
    issuedNominalAmount
    interestRate
    luxxprimeDate
    marketRule
    lgxDisplay 
    lgxEligibleCategories
    lgxExternalReportings
    lgxExternalReviews
    lgxPubDate
    lgxSdgs
    lgxSlbKpiThemes
    lgxStandards
    lgxSustainableBonds
    lgxSustainableFunds
  }
`;

export const securitySearchItemFragment = gql`
  ${securityMarketSummaryFragment}
  ${securityMarketRealtimeFragment}
  ${securityBaseFragment}
  fragment securitySearchItemFragment on Security {
    ...securityBaseFragment
    issuers {
      id
      name
      climateAligned
    }
    marketData {
      ...securityMarketSummaryFragment
    }
    marketRealtime{
      ...securityMarketRealtimeFragment
    }
  }
`;

export const securitySearchFilterFragment = gql`
  fragment securitySearchFilterFragment on LuxseSearchSecurityFilters {
    lgxOnly
    climateAlignedOnly
    luxXPrimeOnly
    chineseBondOnly
    genderBondOnly
    marketRules {
      name
      count
    }
    categories {
      name
      count
    }
    currencies {
      name
      count
    }
    types{
      name
      count
    }
    tokenOnly
    tokenTypes {
      name
      count
    }
    lgxSustainableBonds {
      name
      count
    }
    lgxSustainableFunds {
      name
      count
    }
    lgxStandards {
      name
      count
    }
    lgxExternalReviewOnly 
    lgxExternalReportingOnly
    lgxPostIssuanceOnly
    lgxSdgs {
      name
      count
    }
    lgxEligibleCategories {
      name
      count
    }
    lgxSlbKpiThemes {
      name
      count
    }
  }
`;

export const securitySearchFullFragment = gql`
  ${securitySearchItemFragment}
  ${securitySearchFilterFragment}
  fragment securitySearchFullFragment on LuxseSearchSecuritiesResults {
    totalHits
    securities {
      ...securitySearchItemFragment
    }
    filters {
      ...securitySearchFilterFragment
    }
  }
`;

export const securitySearchEmptyFragment = gql`
  fragment securitySearchEmptyFragment on LuxseSearchSecuritiesResults {
    totalHits
  }
`;

export const securitySearchNoFilterFragment = gql`
  ${securitySearchItemFragment}
  fragment securitySearchNoFilterFragment on LuxseSearchSecuritiesResults {
    totalHits
    securities {
      ...securitySearchItemFragment
    }
  }
`;

export const securityListFragment = gql`
  ${securitySearchItemFragment}
  fragment securityListFragment on SecuritiesList {
    totalSize: totalResultSize
    data: securities {
      ...securitySearchItemFragment
    }
  }
`;

export const SEARCH_SUSPENSIONS = gql`
  ${securitySearchNoFilterFragment}
  query SearchLatestSuspensions(
    $page : Int,
    $size : Int,
    $sort: String!,
    $searchTerm: String!,
  ){
    searchSuspensions(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort,
    ){
      ...securitySearchNoFilterFragment
    }
  }
`;

export const SEARCH_NEW_LISTINGS = gql`
  ${securitySearchNoFilterFragment}
  query SearchNewListings(
    $page : Int,
    $size : Int,
    $sort: String!,
    $searchTerm: String!,
  ){
    searchNewListings(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort,
    ){
      ...securitySearchNoFilterFragment
    }
  }
`;
