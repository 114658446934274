import * as React from 'react';

import { css, jsx, keyframes } from '@emotion/react';

import { DefaultSitecoreProps } from '../../interfaces/default-sitecore';
import { LuxXPrimeLatestNoticeTabsView } from '../../core/components/LuxXPrime/Notices/LatestTabs';

/** @jsx jsx */
export const LuxXPrimeLatestNoticeTabs: React.FunctionComponent<DefaultSitecoreProps> = (
  props: DefaultSitecoreProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  return <LuxXPrimeLatestNoticeTabsView />
};
