import React, {Component} from 'react';
/** @jsx jsx */
import {css,jsx} from '@emotion/react';
import { sharedStyle } from '../../../../../styles/shared';
import { Table, Tr, Th, Td } from '@bdl-cmn-shared-packages-npm/design-system';
import SearchSecurity from '../../../../../interfaces/search/search-security';
import SecuritiesBBORow from './row';

export const SecuritiesBBOTable = ({data, label}: {data: SearchSecurity[], label: String}) => {
  return (
    <Table css={sharedStyle.table}>
      <thead
        style={{
          color: "#253645",
          fontSize: "0.8rem",
          textAlign: "left",
        }}
      >
        <Tr css={css`background: #F8FAFC !important; `}>
          <Th>Security</Th>
          <Th>Listing date</Th>
          <Th>Bid</Th>
          <Th>Ask</Th>
          <Th>Last</Th>
        </Tr>
      </thead>
      <tbody>
        { data?.length > 0 ? 
          data.map((item: SearchSecurity) => 
            <SecuritiesBBORow row={item} key={`${label}-row-${item.id}`}/>
          )
          : 
          <Tr>
            <Td>-</Td>
            <Td>-</Td>
            <Td>-</Td>
            <Td>-</Td>
            <Td>-</Td>
          </Tr>
        }
      </tbody>
    </Table>
  );
};
export default SecuritiesBBOTable;
