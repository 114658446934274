import { formatToThousandsSeparator, toFixedWithoutRounding } from "../../../../../utils";

export const BaseOptions = {
    options: {
        chart: {
            id: 'market-graph',
            stacked: false,
            animations: { enabled: true },
            zoom: { enabled: true },
            parentHeightOffset: 0,
            toolbar: { show: false },
            type: 'area',
            width: 400,
            height: 400
        },
        legend: { show: false },
        dataLabels: { enabled: false },
        markers: {
            size: 0,
            style: 'full',
        },
        tooltip: {
            x: { 
                show: true,
                format: 'dd MMM yyyy'
            },
            style: {
                fontSize: '12px',
                fontFamily: 'Roboto Regular'
            }
        },
        grid: {
            borderColor: '#F5F6F7',
            row: { colors: ['transparent'] }
        },
        xaxis: {
            type: "datetime",
            categories: [ '2019-01-27T23:00:00.000Z' ],
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            },
            labels: {
                show: true, 
                trim: true,
                style: {
                    fontSize: '14px',
                    fontFamily: 'Roboto Regular',
                    colors: '#657F93'
                },
                showDuplicates: true,
                format: 'yyyy'
            }
        },
        yaxis: {
            forceNiceScale: true,
            labels: {
                style: {
                    fontSize: '14px',
                    fontFamily: 'Roboto Regular',
                    color: '#657F93'
                },
                formatter: (value: number): string => formatToThousandsSeparator(toFixedWithoutRounding(value, 3))
            }
        },
        stroke: {
            curve: 'straight',
            colors: ['#22AA5F'],
            width: 1.8
        },
        fill: {
            type: 'gradient',
            colors: ['#22AA5F'],
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [ 0, 98, 100 ]
            }
        }
    },
    series: [ { name: 'price', data: [ 1000 ]} ] 
}