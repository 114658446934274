import { css } from "@emotion/react";

export const style = {
  market: css`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    border: solid 1px #e3e6e8;
    background: #ffffff;
    border-radius: 999em;
    padding: 3px 11px;
    font-weight: bold;
    width: fit-content !important;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0 0;
    width: 100%;
    border: 1px solid #D9DEE2;
    border-radius: 8px;
    margin-bottom: 16px;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 20%);
  `,
  containerDisable: css`
    background: #E8ECEE;
  `,
  top: css`
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  `,
  left: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 32px;
    max-width: 32px;
    height: 32px;
    border-radius: 20px;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #354450;
  `,
  right: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    width: 115px;
    height: 24px;
    margin-left: 8px;
    flex-basis: content;
  `,
  title: css`
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #354450;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  label: css`
    padding-left: 16px;
    padding-right: 16px;
    
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #54666F;

  `,
  subCategories: css`
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  `,
  date: css`
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: rgba(66, 55 ,99, .7);
    margin-bottom: 16px;
    
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.01em;
      color: #354450;
    }
  `,
  
  dateValue: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #54666f;
  `,
  country: css`
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #425563;
    width: 100%;
    div {
      max-width: 33px;
    }
    svg {
      border-radius: 10px;
    }
  `,
  table: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: solid 1px #D9DEE2;
    &:last-of-type {
      border-bottom: none;
    }
    &:first-of-type {
      border-top: solid 1px #D9DEE2;
    }
  `,
  rowPlain: css`
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #F6F8F9;
    border-radius: 0px 0px 8px 8px;
  `,
  col: css`
    padding: 4px 16px 4px 16px;
    border-right: solid 1px #D9DEE2;
    &:last-of-type {
      border-right: none;
    }
  `,
  colTwo: css`
    width: 50%;
  `,
  colPlain: css`
    padding: 0 16px 0 16px;
    border-right: solid 1px #D9DEE2;
    &:last-of-type {
      border-right: none;
    }
  `,
  colTop: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  `,
  colTopRight: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #354450;
    opacity: 0.7;
    margin-bottom: 8px;
    margin-top: 8px;
  `,
  colBottomRight: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #354450;
    margin-bottom: 8px;
  `,
  colTopLeft: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #354450;
    opacity: 0.7;
    margin-bottom: 8px;
    margin-top: 8px;
  `,
  colBottomLeft: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #354450;
    margin-bottom: 8px;
  `,
  colBottom: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  `,
  indice: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #22AA5F;
    svg {
      margin-right: 8px;
      font-size: 18px;
    }
  `,
  value: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  `,
  
  noIssuer: css `
    font-style: italic;
  `,
  multipleIssuer: css `
    color: #22aa5f;
    svg {
      color: #22aa5f;
    }
  `,
  mutipleIssuerList: css `
    padding-inline-start: 30px;
  `,
  mutipleIssuerItem: css `
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #54666f;
    &:not(:first-of-type){
      padding-top: 7px;
    }
  `,
};
