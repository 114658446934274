import { css } from '@emotion/react';
import { breakpointMax } from '../../../../../../style';

export default {
  container: css`
      //position: relative;
  `,
  panel: css`
      background-color: white;
      border-radius: 4px;
      max-height: 290px;
      overflow-y: auto;
      //width: 350px;
      //min-width: 285px;
      width:fit-content;
      position: absolute;
      z-index: 2;
      box-shadow: 0 5px 10px rgba(66, 85, 99, 0.25);
      display: flex;
      flex-direction:column;
      justify-content: space-between;
      padding:1rem;
      svg {
          margin-right: 0;
      }
      > div {
        block-size: fit-content;
        padding:5px;
      }
      > div:nth-child(325) {
        padding-top:1rem;
        padding-bottom:1rem;
        margin-top:15px;
      }
  `,
  issuerChild:css`
    display: flex;
    align-items: center;
    height: auto; /* La hauteur sera ajustée en fonction du contenu */
    margin-bottom: 5px;
  `,
  issuerParent:css`
    width:100%;
  `,
  children: css`
      margin-left: 1rem;
  `,
  clickOutside: css`
      z-index: 10;
      width : 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
  `,
  issuerFilter: css`
      margin:0px;
      padding:0px;
  `,
  inputWrapper: css`
    background-color: white;
    border-radius: 4px;

    color: #253645;
    height: 40px;
    legend > span {
        display:none;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, 4px) scale(0.75);
    }

    .MuiOutlinedInput-input {
      //padding: 0px!important;
    }

    .MuiFormControl-root, .MuiOutlinedInput-root {
      & > div {
        min-height: 0!important;
        height: 40px;
      }
    }
    .MuiInputLabel-outlined {
      z-index: 1;
      transform: translate(14px, 13px) scale(1);
      pointer-events: none;
    }

    .inputWrapperFix {
      svg {
        margin-right: 0;
      }
      
      > div > div > div > div > div {
        .MuiOutlinedInput-input {
          padding: 18.5px 14px;
          padding-top: 30px;
        }
      }

      > div > div:nth-child(2) {
        background-color: white;
        z-index: 10;
      }
    }
  `,
  checkBoxWrapper: css`
    > div {
      @media (min-width: ${breakpointMax}) {
        width: 30em;
      }
    }

    > div > div:nth-child(1) {
      min-width: 1.25rem;
    }
    > div > div:nth-child(2) {
      overflow: hidden;    
      overflow-anchor: revert;
      max-height: 2.1em;
    }
  `,
}