
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import moment from "moment-timezone";
import { Checkbox, MenuWithChild } from "@bdl-cmn-shared-packages-npm/design-system";
import { InputDateRangePicker, InputDateRangePickerProps } from "../../../../input-date-range-picker";
import style from './style';

export interface MaturityDateFilterProps extends Pick<InputDateRangePickerProps, "fromDate" | "toDate"> {
  includePerpetualFilter: boolean;
  onIncludePerpetualFilterChange: (value: boolean) => void;
  onSelect: (fromDate: moment.Moment | null, toDate: moment.Moment | null, includePerpetualFilter: boolean) => void;
}

function isPerpetualImpossible(fromDate: moment.Moment | null): boolean {
  return fromDate === null;
}

export function MaturityDateFilter({ includePerpetualFilter, onIncludePerpetualFilterChange, onSelect, ...props }: MaturityDateFilterProps) {
  const { fromDate } = props;

  return (
    <MenuWithChild
      icon="calendar-alt"
      label="Maturity Date"
      width="100%;"
      expanded={false}
      arrows={false}>
      <div style={{ marginBottom: "1rem" }}>
        <InputDateRangePicker
          {...props}
          placeholder="Maturity Date"
          authorizeFuture={true}
          onSelect={(fromDate, toDate) => onSelect(fromDate, toDate, isPerpetualImpossible(fromDate) ? false : includePerpetualFilter)}
        />
      </div>

      <div css={style.checkbox}>
        <Checkbox
          disabled={isPerpetualImpossible(fromDate)}
          checked={!!includePerpetualFilter}
          onCheckedChange={onIncludePerpetualFilterChange}
          label="Include perpetual bonds" />
      </div>
    </MenuWithChild>
  );
}
