import * as React from "react";

import {Icon, Table, Td, Th, Thead, Tr} from "@bdl-cmn-shared-packages-npm/design-system";
import {css, jsx} from "@emotion/react";

import {formatDate} from "../../../../../../utils/date";
import {sharedStyle} from "../../../../../../styles/shared";
import { goToSecurityDetailPage, isSecurityClickable } from "../../../../SecurityCard";
import { formatToThousandsSeparator } from "../../../../../../utils";

/** @jsx jsx */

export const NewListingsRow = (props:any) => {

    function computeDateValue(x: any): any {
        let value = x?.quotationDate;
        if (!x?.quotationDate) {
            value = x?.quotationDateChanges[1]
        }
        return value;
    }

    const getNewListingStatusLabel = (listing: any) => {
        if( ! listing || ! listing.status )
          return "";
    
        const report = listing.postponed ? "Report - " : "";
        let label = listing.status; 
        try{
    
          switch( listing.status ){
            case "COTE":
            case "ADMI":
              label =  "Listed as of";
              break;
            case "NEGO":
              label =  "Traded as of";
              break;
            case "NCOT":
              label = "Intended " 
                  + ( listing.tags?.length > 0 && listing?.tags.includes("ATT") ? "trading": "listing" )
                  + " date";
              break;
            case "FONG":
              label = "Fungible";
              break;
            case "PUBL":
              label = listing.quotationDate == listing.delistingDate ? "Fungible" : "Intended listing date";
              break;
            default:
              label = listing.status; 
              break;
          }
        }catch(e){
          console.error("Failed to get new listing status of ", listing);
        }
    
        return report + label;
      }

    const row = props.newListingData;

    if ( !row )
      return <></>;
    
    const clickable = isSecurityClickable(row.status);

    return (
        <Tr onRowClick={() => { if(clickable) goToSecurityDetailPage(row.isin, row.id)}}
            css={[sharedStyle.tableRow, !clickable && sharedStyle.tableRowNoClickable]}
            title={row?.status == "NCOT"? "Listing to be confirmed": ""}>
            <Td>
                <div css={sharedStyle.rowBond}>{row?.instrumentName}</div>
                {row.issuedNominalAmount ?
                    <div css={sharedStyle.span}>
                        {row?.tradingCode} {row?.currency} {formatToThousandsSeparator(row?.issuedNominalAmount)}
                    </div>
                    :<></>
                }
            </Td>
            <Td>
                <div css={sharedStyle.rowBond}>{getNewListingStatusLabel(row)}</div>
                <div css={sharedStyle.span}>
                    {formatDate(row.listingDate)}
                </div>
            </Td>
        </Tr>
    )

}
export default NewListingsRow;