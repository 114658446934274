
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue, RichText } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {breakpointMax} from "../../style";

export interface BlogBlockQuoteProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  background: #F4F6F7;
  border-radius: 8px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 60px 32px;
`;
const quote = css`
  width: 100%;
  blockquote {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #1A2934;
    margin-block: 0;
    margin-inline: 0;

    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      letter-spacing: 0.01em;
    }
  }
`;
const subtext = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #1A2934;
  margin-top: 16px;
`;

export const BlogBlockQuote: React.FunctionComponent<BlogBlockQuoteProps> = (props: BlogBlockQuoteProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    return (
        <div css={container}>
            <div css={quote}>
                <RichText
                    tag={'blockquote'}
                    field={fields && fields.content as SitecoreGenericTextField}
                />
            </div>
            <div css={subtext}>
                <Text
                    field={fields && fields['sub text'] as SitecoreGenericTextField}
                />
            </div>
        </div>
    );
}
    