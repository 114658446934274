/** @jsx jsx */
import * as React from 'react';

import {Td, Tr} from '@bdl-cmn-shared-packages-npm/design-system';
import {css, jsx} from '@emotion/react';

import {EMPTY_DATA_PLACEHOLDER} from "../../../../constants";
import {computePriceStyle} from "../../../../utils/compute-price-style";
import {formatDate} from "../../../../../utils/date";
import { style } from "../index.style";
import { sharedStyle } from '../../../../../styles/shared';
import { PreciousMetal } from '../../../../../interfaces/precious-metal';

export const PreciousMetalsRow = ({row}: {row: PreciousMetal}) => {
    if(!row)
        return <></>;

    return (
        <Tr css={sharedStyle.tableRow}>
            <Td css={[style.title, style.titleBold]}>
                {row.name || EMPTY_DATA_PLACEHOLDER}
            </Td>
            <Td>
                <div css={[style.title, style.titleBold, style.titleMargin]}>
                    {row.marketData?.lastPrice?.amount > 0 ? 
                        `${row.marketData.lastPrice.amount} ${row.currency}`
                        : EMPTY_DATA_PLACEHOLDER
                    }
                </div>
                <div css={[style.title, style.titleMargin]}>
                    {formatDate(row.marketData?.lastPrice?.date, 'DD/MM/YYYY')}
                </div>
            </Td>
            <Td css={[style.title]}>
                {computePriceStyle(row.marketData?.lastPrice?.changePercent, '%')}
            </Td>
        </Tr>
    );
}

export default PreciousMetalsRow;
