
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const CRI: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <rect y="203.03" fill="#EF3340" width="512" height="105.93" />
            <path fill="#00205B" d="M8.828,88.276h494.345c4.875,0,8.828,3.953,8.828,8.828v48.552H0V97.104 C0,92.229,3.953,88.276,8.828,88.276z" />
            <rect y="145.66" fill="#FFFFFF" width="512" height="57.38" />
            <path fill="#00205B" d="M503.172,423.724H8.828c-4.875,0-8.828-3.953-8.828-8.828v-48.552h512v48.552 C512,419.772,508.047,423.724,503.172,423.724z" />
            <g>
                <rect y="308.96" fill="#FFFFFF" width="512" height="57.38" />
                <ellipse fill="#FFFFFF" cx="138.39" cy="256" rx="41.29" ry="44.14" />
            </g>
            <rect x="117.03" y="238.92" fill="#3CC8FF" width="42.714" height="21.357" />
            <polygon fill="#FFE15A" points="122.732,251.059 123.22,255.77 125.138,251.441 124.146,256.072 127.31,252.546 124.934,256.645 129.033,254.27 125.508,257.434 130.139,256.441 125.809,258.36 130.521,258.848 125.809,259.336 130.139,261.255 125.508,260.263 129.033,263.426 124.934,261.05 127.31,265.149 124.146,261.624 125.138,266.255 123.22,261.926 122.732,266.636 122.244,261.926 120.325,266.255 121.317,261.624 118.154,265.149 120.528,261.05 116.43,263.426 119.956,260.263 115.324,261.255 119.654,259.336 114.943,258.848 119.654,258.36 115.324,256.441 119.956,257.434 116.43,254.27 120.528,256.645 118.154,252.546 121.317,256.072 120.325,251.441 122.244,255.77 " />
            <polygon fill="#00205B" points="146.936,290.172 129.851,290.172 114.189,277.357 114.189,258.848 164.022,258.848 164.022,277.357 " />
            <path fill="#73AF00" d="M155.479,253.153h-0.001c-0.004-0.39-0.32-0.704-0.711-0.704s-0.705,0.314-0.711,0.704h-0.002 c0,2.471-1.432,5.889-3.188,8.337c-2.452-3.096-3.374-6.686-3.722-8.947c-0.166-1.078-1.331-1.676-2.307-1.188 c-0.47,0.235-0.787,0.694-0.867,1.213c-1.09,6.983-4.382,9.95-6.514,11.17c-3.191-3.085-4.243-8.078-4.589-11.03 c-0.135-1.148-1.363-1.806-2.397-1.29c-0.398,0.199-0.693,0.558-0.832,0.981c-4.466,13.613-16.875,14.993-16.875,14.993v4.271 h49.833v-1.423v-1.423v-1.423v-1.423v-1.423C156.903,263.119,155.479,253.153,155.479,253.153z" />
            <g>
                <path fill="#F0AA00" d="M162.598,240.339c-5.695-2.848-5.695-5.695-5.695-5.695s-7.119-2.848-12.814-2.848 c0,0,1.423-2.848-1.423-2.848c-2.848,0-4.271,0-4.271,0s-1.423,0-4.271,0s-1.423,2.848-1.423,2.848 c-5.695,0-12.814,2.848-12.814,2.848s0,2.848-5.695,5.695c-5.695,2.848-5.695,9.255-5.695,9.255c0,1.18,0.957,2.135,2.135,2.135 c1.18,0,2.135-0.957,2.135-2.135c0-0.244-0.05-0.473-0.125-0.691l0.125-0.021l14.238-9.966h11.391h11.391l14.238,9.966l0.125,0.021 c-0.075,0.217-0.125,0.448-0.125,0.691c0,1.18,0.957,2.135,2.135,2.135s2.135-0.957,2.135-2.135 C168.293,249.593,168.293,243.186,162.598,240.339z" />
                <path fill="#F0AA00" d="M166.87,257.425c-1.423,0-5.695,0-5.695-4.271s5.695-4.271,5.695-4.271l-8.543-2.848 c-2.848,9.966,2.848,9.966,2.848,22.781c0,11.479-12.814,18.509-17.086,18.509c-4.271,0-5.695,2.848-5.695,2.848 s-1.423-2.848-5.695-2.848c-4.271,0-17.086-7.03-17.086-18.509c0-12.814,5.695-12.814,2.848-22.781l-8.543,2.848 c0,0,5.695,0,5.695,4.271c0,4.271-4.271,4.271-5.695,4.271s-7.119,0-7.119,7.119s7.119,15.662,17.086,21.357 c9.966,5.695,5.695,11.391,18.509,11.391s8.543-5.695,18.509-11.391c9.966-5.695,17.086-14.238,17.086-21.357 C173.989,257.425,168.293,257.425,166.87,257.425z" />
            </g>
            <g>
                <path fill="#73AF00" d="M135.546,235.138l-3.444-1.548c-0.572-0.257-1.206-0.317-1.816-0.175 c-2.22,0.513-7.727,2.2-11.826,6.923l4.271,1.423L135.546,235.138z" />
                <path fill="#73AF00" d="M141.241,235.138l3.444-1.548c0.572-0.257,1.206-0.317,1.816-0.175 c2.22,0.513,7.727,2.2,11.826,6.923l-4.271,1.423L141.241,235.138z" />
                <path fill="#73AF00" d="M138.897,236.067l2.596-2.596c0.139-0.139,0.139-0.364,0-0.503s-0.364-0.139-0.503,0l-2.596,2.596 l-2.596-2.596c-0.139-0.139-0.364-0.139-0.503,0s-0.139,0.364,0,0.503l2.596,2.596l-2.596,2.596c-0.139,0.139-0.139,0.364,0,0.503 c0.07,0.07,0.161,0.105,0.252,0.105c0.09,0,0.182-0.035,0.252-0.105l2.596-2.596l2.596,2.596c0.07,0.07,0.161,0.105,0.252,0.105 c0.092,0,0.182-0.035,0.252-0.105c0.139-0.139,0.139-0.364,0-0.503L138.897,236.067z" />
            </g>
            <path fill="#FFFFFF" d="M166.87,248.882c0-2.422-2.289-3.469-4.271-3.924v-1.128c-2.68-0.252-4.177-1.258-6.775-3.029 c-1.001-0.683-2.136-1.455-3.507-2.289c-5.006-3.041-8.854-3.48-11.075-3.374v2.353h-5.695v-2.353 c-2.221-0.106-6.069,0.333-11.075,3.374c-1.37,0.833-2.506,1.606-3.507,2.289c-2.599,1.77-4.094,2.777-6.775,3.029v1.128 c-1.983,0.454-4.271,1.501-4.271,3.924l4.271-1.423v0.649c4.041-0.298,6.351-1.848,9.18-3.775c0.959-0.653,2.045-1.394,3.319-2.167 c3.252-1.976,5.779-2.588,7.434-2.732v2.33h8.543v-2.33c1.655,0.145,4.182,0.756,7.434,2.732c1.276,0.775,2.361,1.514,3.319,2.167 c2.828,1.927,5.139,3.478,9.18,3.775v-0.649L166.87,248.882z" />
            <path fill="#3CC8FF" d="M155.479,226.439v-1.762h-3.966c0.693-0.595,1.119-1.295,1.119-2.135 c0-3.934-8.95-4.983-14.238-4.983s-14.238,1.049-14.238,4.983c0,0.84,0.426,1.54,1.119,2.135h-3.966v1.762 c-3.486,0.778-6.335,2.648-6.485,2.749l1.579,2.369c0.039-0.026,3.957-2.609,7.753-2.609v-1.423h28.476v1.423 c3.797,0,7.714,2.582,7.753,2.609l1.579-2.369C161.815,229.087,158.965,227.217,155.479,226.439z M144.089,224.283v-1.081 c0-0.697-0.655-1.208-1.331-1.039l-4.364,1.091l-4.364-1.091c-0.676-0.169-1.331,0.342-1.331,1.039v1.081 c-3.199-0.47-5.09-1.252-5.606-1.741c0.77-0.732,4.543-2.135,11.3-2.135s10.53,1.405,11.3,2.135 C149.179,223.03,147.287,223.812,144.089,224.283z" />
            <g>
                <path fill="#FFFFFF" d="M138.572,242.3l0.361,1.081l1.14,0.009c0.148,0.001,0.209,0.19,0.09,0.277l-0.917,0.678l0.344,1.087 c0.044,0.141-0.116,0.257-0.236,0.171l-0.928-0.663l-0.928,0.663c-0.12,0.086-0.28-0.031-0.236-0.171l0.344-1.087l-0.917-0.678 c-0.119-0.087-0.057-0.276,0.09-0.277l1.14-0.009l0.361-1.081C138.327,242.159,138.524,242.159,138.572,242.3z" />
                <path fill="#FFFFFF" d="M132.99,242.665l0.472,1.038l1.135-0.11c0.147-0.014,0.227,0.167,0.119,0.266l-0.841,0.77 l0.456,1.045c0.06,0.136-0.088,0.268-0.216,0.195l-0.992-0.562l-0.853,0.757c-0.11,0.098-0.282-0.001-0.253-0.146l0.228-1.118 l-0.983-0.577c-0.127-0.075-0.086-0.268,0.061-0.285l1.133-0.128l0.245-1.113C132.731,242.551,132.929,242.53,132.99,242.665z" />
                <path fill="#FFFFFF" d="M127.258,244.191l0.686,0.911l1.084-0.354c0.14-0.046,0.258,0.114,0.173,0.234l-0.654,0.934 l0.672,0.921c0.087,0.119-0.028,0.28-0.169,0.237l-1.09-0.334l-0.669,0.924c-0.086,0.119-0.276,0.06-0.278-0.087l-0.02-1.14 l-1.085-0.351c-0.14-0.045-0.142-0.244-0.002-0.291l1.078-0.371l-0.002-1.14C126.981,244.137,127.17,244.073,127.258,244.191z" />
                <path fill="#FFFFFF" d="M121.514,246.613l0.8,0.812l1.027-0.494c0.134-0.064,0.271,0.078,0.203,0.21l-0.525,1.012 l0.788,0.824c0.102,0.107,0.01,0.281-0.136,0.258l-1.126-0.186l-0.541,1.004c-0.07,0.13-0.265,0.096-0.287-0.05l-0.17-1.128 l-1.122-0.204c-0.146-0.026-0.173-0.223-0.041-0.289l1.02-0.51l-0.152-1.13C121.232,246.595,121.41,246.508,121.514,246.613z" />
                <path fill="#FFFFFF" d="M143.862,242.665l-0.472,1.038l-1.135-0.11c-0.147-0.014-0.227,0.167-0.119,0.266l0.841,0.77 l-0.456,1.045c-0.06,0.136,0.088,0.268,0.216,0.195l0.992-0.562l0.853,0.757c0.11,0.098,0.282-0.001,0.253-0.146l-0.228-1.118 l0.983-0.577c0.127-0.075,0.086-0.268-0.061-0.285l-1.133-0.128l-0.245-1.113C144.12,242.551,143.922,242.53,143.862,242.665z" />
                <path fill="#FFFFFF" d="M149.593,244.191l-0.686,0.911l-1.084-0.354c-0.14-0.046-0.258,0.114-0.173,0.234l0.654,0.934 l-0.672,0.921c-0.087,0.119,0.028,0.28,0.169,0.237l1.09-0.334l0.669,0.924c0.086,0.119,0.276,0.06,0.278-0.087l0.02-1.14 l1.085-0.351c0.14-0.045,0.142-0.244,0.002-0.291l-1.078-0.371l0.002-1.14C149.87,244.137,149.681,244.073,149.593,244.191z" />
                <path fill="#FFFFFF" d="M155.338,246.613l-0.8,0.812l-1.027-0.494c-0.134-0.064-0.271,0.078-0.203,0.21l0.525,1.012 l-0.788,0.824c-0.102,0.107-0.01,0.281,0.136,0.258l1.126-0.186l0.541,1.004c0.07,0.13,0.265,0.096,0.287-0.05l0.17-1.128 l1.122-0.204c0.146-0.026,0.173-0.223,0.041-0.289l-1.02-0.51l0.152-1.13C155.619,246.595,155.441,246.508,155.338,246.613z" />
            </g>
            <polygon fill="#8C5A28" points="128.56,276.468 135.813,281.317 144.578,281.317 148.628,277.847 145.513,279.093 135.19,279.093 " />
            <path fill="#FFFFFF" d="M133.499,270.21l-0.7,7.06l-3.186-1.216C129.613,276.052,131.079,272.65,133.499,270.21z" />
            <g>
                <rect x="139.78" y="267.41" fill="#8C5A28" width="0.471" height="12.02" />
                <rect x="144.14" y="268.43" fill="#8C5A28" width="0.471" height="11.01" />
                <rect x="135.45" y="268.43" fill="#8C5A28" width="0.471" height="11.01" />
            </g>
            <g>
                <path fill="#FFFFFF" d="M141.462,278.292h-2.877c-0.299,0-0.542-0.243-0.542-0.542v-1.453c0-0.299,0.243-0.542,0.542-0.542 h2.877c0.299,0,0.542,0.243,0.542,0.542v1.453C142.004,278.049,141.761,278.292,141.462,278.292z" />
                <path fill="#FFFFFF" d="M141.462,274.91h-2.877c-0.299,0-0.542-0.243-0.542-0.542v-1.453c0-0.299,0.243-0.542,0.542-0.542 h2.877c0.299,0,0.542,0.243,0.542,0.542v1.453C142.004,274.668,141.761,274.91,141.462,274.91z" />
                <path fill="#FFFFFF" d="M141.462,271.796h-2.877c-0.299,0-0.542-0.243-0.542-0.542v-1.453c0-0.299,0.243-0.542,0.542-0.542 h2.877c0.299,0,0.542,0.243,0.542,0.542v1.453C142.004,271.553,141.761,271.796,141.462,271.796z" />
                <path fill="#FFFFFF" d="M145.551,278.292h-2.343c-0.299,0-0.542-0.243-0.542-0.542v-1.453c0-0.299,0.243-0.542,0.542-0.542 h2.343c0.299,0,0.542,0.243,0.542,0.542v1.453C146.093,278.049,145.85,278.292,145.551,278.292z" />
                <path fill="#FFFFFF" d="M145.551,275.179h-2.343c-0.299,0-0.542-0.243-0.542-0.542v-1.055c0-0.299,0.243-0.542,0.542-0.542 h2.343c0.299,0,0.542,0.243,0.542,0.542v1.055C146.093,274.936,145.85,275.179,145.551,275.179z" />
                <path fill="#FFFFFF" d="M145.551,272.093h-2.343c-0.299,0-0.542-0.243-0.542-0.542v-0.712c0-0.299,0.243-0.542,0.542-0.542 h2.343c0.299,0,0.542,0.243,0.542,0.542v0.712C146.093,271.85,145.85,272.093,145.551,272.093z" />
                <path fill="#FFFFFF" d="M136.852,278.292h-2.343c-0.299,0-0.542-0.243-0.542-0.542v-1.453c0-0.299,0.243-0.542,0.542-0.542 h2.343c0.299,0,0.542,0.243,0.542,0.542v1.453C137.393,278.049,137.151,278.292,136.852,278.292z" />
                <path fill="#FFFFFF" d="M136.852,275.179h-2.343c-0.299,0-0.542-0.243-0.542-0.542v-1.055c0-0.299,0.243-0.542,0.542-0.542 h2.343c0.299,0,0.542,0.243,0.542,0.542v1.055C137.393,274.936,137.151,275.179,136.852,275.179z" />
                <path fill="#FFFFFF" d="M136.852,272.093h-2.343c-0.299,0-0.542-0.243-0.542-0.542v-0.712c0-0.299,0.243-0.542,0.542-0.542 h2.343c0.299,0,0.542,0.243,0.542,0.542v0.712C137.393,271.85,137.151,272.093,136.852,272.093z" />
                <polygon fill="#FFFFFF" points="135.915,268.43 135.915,269.853 138.008,269.128 " />
                <polygon fill="#FFFFFF" points="144.609,268.43 144.609,269.853 146.702,269.128 " />
                <polygon fill="#FFFFFF" points="140.253,267.409 140.253,268.831 142.347,268.106 " />
            </g>
            <polygon fill="#8C5A28" points="134.693,257.801 137.212,259.486 140.258,259.486 141.665,258.28 140.582,258.712 136.996,258.712 " />
            <path fill="#FFFFFF" d="M136.409,255.626l-0.243,2.453l-1.107-0.423C135.058,257.656,135.568,256.474,136.409,255.626z" />
            <g>
                <rect x="138.59" y="254.65" fill="#8C5A28" width="0.163" height="4.177" />
                <rect x="140.1" y="255.01" fill="#8C5A28" width="0.163" height="3.823" />
                <rect x="137.08" y="255.01" fill="#8C5A28" width="0.163" height="3.823" />
            </g>
            <g>
                <path fill="#FFFFFF" d="M139.174,258.434h-1c-0.104,0-0.189-0.084-0.189-0.189v-0.504c0-0.104,0.084-0.189,0.189-0.189h1 c0.104,0,0.189,0.084,0.189,0.189v0.504C139.363,258.35,139.279,258.434,139.174,258.434z" />
                <path fill="#FFFFFF" d="M139.174,257.26h-1c-0.104,0-0.189-0.084-0.189-0.189v-0.504c0-0.104,0.084-0.189,0.189-0.189h1 c0.104,0,0.189,0.084,0.189,0.189v0.504C139.363,257.175,139.279,257.26,139.174,257.26z" />
                <path fill="#FFFFFF" d="M139.174,256.178h-1c-0.104,0-0.189-0.084-0.189-0.189v-0.504c0-0.104,0.084-0.189,0.189-0.189h1 c0.104,0,0.189,0.084,0.189,0.189v0.504C139.363,256.094,139.279,256.178,139.174,256.178z" />
                <path fill="#FFFFFF" d="M140.596,258.434h-0.814c-0.104,0-0.189-0.084-0.189-0.189v-0.504c0-0.104,0.084-0.189,0.189-0.189 h0.814c0.104,0,0.189,0.084,0.189,0.189v0.504C140.784,258.35,140.699,258.434,140.596,258.434z" />
                <path fill="#FFFFFF" d="M140.596,257.353h-0.814c-0.104,0-0.189-0.084-0.189-0.189v-0.366c0-0.104,0.084-0.189,0.189-0.189 h0.814c0.104,0,0.189,0.084,0.189,0.189v0.366C140.784,257.27,140.699,257.353,140.596,257.353z" />
                <path fill="#FFFFFF" d="M140.596,256.28h-0.814c-0.104,0-0.189-0.084-0.189-0.189v-0.247c0-0.104,0.084-0.189,0.189-0.189 h0.814c0.104,0,0.189,0.084,0.189,0.189v0.247C140.784,256.197,140.699,256.28,140.596,256.28z" />
                <path fill="#FFFFFF" d="M137.573,258.434h-0.814c-0.104,0-0.189-0.084-0.189-0.189v-0.504c0-0.104,0.084-0.189,0.189-0.189 h0.814c0.104,0,0.189,0.084,0.189,0.189v0.504C137.761,258.35,137.677,258.434,137.573,258.434z" />
                <path fill="#FFFFFF" d="M137.573,257.353h-0.814c-0.104,0-0.189-0.084-0.189-0.189v-0.366c0-0.104,0.084-0.189,0.189-0.189 h0.814c0.104,0,0.189,0.084,0.189,0.189v0.366C137.761,257.27,137.677,257.353,137.573,257.353z" />
                <path fill="#FFFFFF" d="M137.573,256.28h-0.814c-0.104,0-0.189-0.084-0.189-0.189v-0.247c0-0.104,0.084-0.189,0.189-0.189 h0.814c0.104,0,0.189,0.084,0.189,0.189v0.247C137.761,256.197,137.677,256.28,137.573,256.28z" />
                <polygon fill="#FFFFFF" points="137.248,255.008 137.248,255.502 137.975,255.251 " />
                <polygon fill="#FFFFFF" points="140.268,255.008 140.268,255.502 140.995,255.251 " />
                <polygon fill="#FFFFFF" points="138.755,254.654 138.755,255.147 139.482,254.895 " />
                <path fill="#FFFFFF" d="M132.91,250.305h-1.848c-0.666,0-1.212-0.545-1.212-1.212l0,0c0-0.666,0.545-1.212,1.212-1.212 h1.848c0.666,0,1.212,0.545,1.212,1.212l0,0C134.123,249.76,133.577,250.305,132.91,250.305z" />
                <path fill="#FFFFFF" d="M146.965,250.305h-1.219c-0.669,0-1.212-0.543-1.212-1.212l0,0c0-0.669,0.543-1.212,1.212-1.212 h1.219c0.669,0,1.212,0.543,1.212,1.212l0,0C148.176,249.762,147.633,250.305,146.965,250.305z" />
                <path fill="#FFFFFF" d="M155.621,252.129h-1.074c-0.383,0-0.693-0.31-0.693-0.693v-0.115c0-0.383,0.31-0.693,0.693-0.693 h1.074c0.383,0,0.693,0.31,0.693,0.693v0.115C156.314,251.819,156.004,252.129,155.621,252.129z" />
            </g>
        </svg>
    </React.Fragment>
);
