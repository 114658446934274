
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { style } from './index.style';

import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';

export interface SquareLinkProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const SquareLink: React.FunctionComponent<SquareLinkProps> = (props: SquareLinkProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div css={style.container}>
            <Text
                field={fields && fields.title as SitecoreGenericTextField}
            />
            <Text
                field={fields && fields.link as SitecoreGenericTextField}
            />
        </div>
    );
}
