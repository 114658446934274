
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface AffordableAndCleanEnergySvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const AffordableAndCleanEnergySvg: React.FunctionComponent<AffordableAndCleanEnergySvgProps> = ({ size: { width, height }, style }: AffordableAndCleanEnergySvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 47 50'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>7 - Affordable and Clean Energy</title>
            <path d='M0.155823 2.68899H3.29527L0.580879 13.1252H2.59803L5.2565 2.90152V1.02979H0.155823V2.68899Z' fill='white' />
            <path d='M11.0134 5.92552L11.9381 1.02246H12.8777L13.8098 5.92552H12.9635L12.8106 5.03066H11.9344L11.789 5.92552H11.0134ZM12.0462 4.37071H12.7025L12.3781 2.40576H12.3706L12.0462 4.37071Z' fill='white' />
            <path d='M14.1156 1.02246H16.047V1.6936H14.9396V3.09554H15.7636V3.75922H14.9396V5.92179H14.1156V1.02246Z' fill='white' />
            <path d='M16.3714 1.02246H18.3028V1.6936H17.1954V3.09554H18.0194V3.75922H17.1954V5.92179H16.3714V1.02246Z' fill='white' />
            <path d='M18.5563 4.66131V2.28995C18.5563 1.52186 18.9329 0.966309 19.8017 0.966309C20.6779 0.966309 21.0545 1.52186 21.0545 2.28995V4.66131C21.0545 5.42194 20.6779 5.98495 19.8017 5.98495C18.9329 5.98122 18.5563 5.41821 18.5563 4.66131ZM20.2267 4.78808V2.15572C20.2267 1.85743 20.0962 1.64864 19.8017 1.64864C19.5108 1.64864 19.3841 1.85743 19.3841 2.15572V4.78808C19.3841 5.08637 19.5146 5.29517 19.8017 5.29517C20.1 5.29517 20.2267 5.08637 20.2267 4.78808Z' fill='white' />
            <path d='M22.311 3.6772V5.92552H21.4869V1.02246H22.6875C23.519 1.02246 23.821 1.43633 23.821 2.12239V2.75251C23.821 3.28197 23.6346 3.59144 23.1648 3.69957L23.9515 5.92552H23.0828L22.311 3.6772ZM22.311 1.6936V3.21858H22.5869C22.8702 3.21858 22.9933 3.07317 22.9933 2.81217V2.08883C22.9933 1.82037 22.8702 1.68987 22.5869 1.68987H22.311V1.6936Z' fill='white' />
            <path d='M26.7032 2.33864V4.60934C26.7032 5.36251 26.3938 5.92552 25.5101 5.92552H24.2386V1.02246H25.5101C26.39 1.02246 26.7032 1.57802 26.7032 2.33864ZM25.4057 5.25438C25.745 5.25438 25.8755 5.05303 25.8755 4.75475V2.18577C25.8755 1.89494 25.745 1.6936 25.4057 1.6936H25.0589V5.25065H25.4057V5.25438Z' fill='white' />
            <path d='M26.8971 5.92552L27.8218 1.02246H28.7614L29.6935 5.92552H28.8471L28.6943 5.03066H27.818L27.6726 5.92552H26.8971ZM27.9336 4.37071H28.5899L28.2655 2.40576H28.258L27.9336 4.37071Z' fill='white' />
            <path d='M29.9993 1.02246H31.1924C32.0164 1.02246 32.2923 1.4065 32.2923 2.10002V2.59964C32.2923 3.01351 32.1395 3.27078 31.8002 3.37891C32.214 3.47213 32.4154 3.74804 32.4154 4.19547V4.84796C32.4154 5.54148 32.1059 5.92552 31.2819 5.92552H30.003V1.02246H29.9993ZM30.8233 3.09181H31.1141C31.3826 3.09181 31.5056 2.95386 31.5056 2.69286V2.09256C31.5056 1.8241 31.3826 1.6936 31.1066 1.6936H30.8233V3.09181ZM30.8233 3.71821V5.25065H31.1849C31.4609 5.25065 31.5914 5.11269 31.5914 4.85169V4.11344C31.5914 3.85244 31.4683 3.71448 31.1775 3.71448H30.8233V3.71821Z' fill='white' />
            <path d='M32.792 1.02246H33.616V5.25438H34.6003V5.92552H32.792V1.02246Z' fill='white' />
            <path d='M34.9396 1.02246H36.9083V1.6936H35.7674V3.09554H36.5839V3.75922H35.7674V5.25065H36.9083V5.92179H34.9396V1.02246Z' fill='white' />
            <path d='M37.9075 5.92552L38.8322 1.02246H39.7718L40.704 5.92552H39.8576L39.7047 5.03066H38.8285L38.6831 5.92552H37.9075ZM38.9403 4.37071H39.5966L39.2722 2.40576H39.2647L38.9403 4.37071Z' fill='white' />
            <path d='M41.7032 2.84573V5.92552H41.0097V1.02246H41.8188L42.7062 3.8338V1.02246H43.3923V5.92552H42.6689L41.7032 2.84573Z' fill='white' />
            <path d='M46.2968 2.33864V4.60934C46.2968 5.36251 45.9873 5.92552 45.1037 5.92552H43.8322V1.02246H45.1037C45.9873 1.02246 46.2968 1.57802 46.2968 2.33864ZM45.003 5.25438C45.3423 5.25438 45.4728 5.05303 45.4728 4.75475V2.18577C45.4728 1.89494 45.3423 1.6936 45.003 1.6936H44.6562V5.25065H45.003V5.25438Z' fill='white' />
            <path d='M11.1365 11.8614V9.50124C11.1365 8.73689 11.4944 8.18506 12.3594 8.18506C13.2655 8.18506 13.519 8.68841 13.519 9.40803V9.98223H12.7696V9.34091C12.7696 9.0389 12.6689 8.86739 12.3744 8.86739C12.0798 8.86739 11.9568 9.07619 11.9568 9.37074V11.9919C11.9568 12.2865 12.0798 12.4953 12.3744 12.4953C12.6689 12.4953 12.7696 12.3089 12.7696 12.0218V11.1232H13.519V11.9658C13.519 12.6631 13.2319 13.1813 12.3594 13.1813C11.4981 13.1776 11.1365 12.6183 11.1365 11.8614Z' fill='white' />
            <path d='M14.0373 8.24121H14.8576V12.4508H15.8382V13.1182H14.0373V8.24121Z' fill='white' />
            <path d='M16.2819 8.24121H18.2394V8.90862H17.1022V10.3031H17.915V10.9668H17.1022V12.4508H18.2394V13.1182H16.2819V8.24121Z' fill='white' />
            <path d='M18.6197 13.1219L19.5406 8.24121H20.4765L21.4049 13.1219H20.5623L20.4094 12.2308H19.5406L19.3952 13.1219H18.6197ZM19.6488 11.5745H20.305L19.9806 9.61705H19.9732L19.6488 11.5745Z' fill='white' />
            <path d='M22.5086 10.0533V13.1182H21.8151V8.24121H22.6204L23.5041 11.0414V8.24121H24.1864V13.1219H23.4668L22.5086 10.0533Z' fill='white' />
            <path d='M26.3863 8.24121H28.3438V8.90862H27.2066V10.3031H28.0194V10.9668H27.2066V12.4508H28.3438V13.1182H26.3863V8.24121Z' fill='white' />
            <path d='M29.522 10.0533V13.1182H28.8322V8.24121H29.6376L30.5212 11.0414V8.24121H31.2036V13.1219H30.484L29.522 10.0533Z' fill='white' />
            <path d='M31.7405 8.24121H33.698V8.90862H32.5608V10.3031H33.3736V10.9668H32.5608V12.4508H33.698V13.1182H31.7405V8.24121Z' fill='white' />
            <path d='M35.0142 10.881V13.1182H34.1939V8.24121H35.387C36.2148 8.24121 36.5168 8.65135 36.5168 9.33368V9.96008C36.5168 10.4858 36.3304 10.7953 35.8606 10.9034L36.6436 13.1219H35.7785L35.0142 10.881ZM35.0142 8.90862V10.4261H35.2864C35.566 10.4261 35.6891 10.2807 35.6891 10.0235V9.30385C35.6891 9.03912 35.566 8.90862 35.2864 8.90862H35.0142Z' fill='white' />
            <path d='M38.2729 10.5154H39.4399V13.1217H38.8508V12.5885C38.7427 12.9539 38.5115 13.1776 38.0678 13.1776C37.3631 13.1776 37.0388 12.6146 37.0388 11.8614V9.50124C37.0388 8.73689 37.4042 8.18506 38.2692 8.18506C39.1752 8.18506 39.4437 8.68841 39.4437 9.40803V9.84054H38.6942V9.34464C38.6942 9.04263 38.5786 8.87111 38.2841 8.87111C37.9895 8.87111 37.859 9.07991 37.859 9.37447V11.9956C37.859 12.2902 37.9821 12.499 38.2617 12.499C38.5264 12.499 38.6644 12.3461 38.6644 12.0329V11.1679H38.2766V10.5154H38.2729Z' fill='white' />
            <path d='M41.5615 11.2651V13.1219H40.7413V11.2651L39.8278 8.24121H40.6853L41.1812 10.0906H41.1887L41.6846 8.24121H42.4676L41.5615 11.2651Z' fill='white' />
            <path d='M24.0783 49.095C24.4773 49.095 24.7979 48.7408 24.7979 48.4202V44.5686C24.7979 44.2442 24.4735 43.8862 24.0783 43.8862C23.6794 43.8862 23.355 44.2442 23.355 44.5686V48.4202C23.355 48.7408 23.6794 49.095 24.0783 49.095Z' fill='white' />
            <path d='M24 25.0718C19.1454 25.0718 15.2081 29.0091 15.2081 33.8637C15.2081 38.722 19.1454 42.6594 24 42.6594C28.8546 42.6594 32.792 38.722 32.792 33.8637C32.792 29.0091 28.8546 25.0718 24 25.0718ZM23.3475 28.5468C23.3475 28.2522 23.6421 27.9316 24.0037 27.9316C24.3654 27.9316 24.6562 28.2522 24.6562 28.5468V31.5669C24.6562 31.8578 24.3654 32.1784 24.0037 32.1784C23.6421 32.1784 23.3475 31.8578 23.3475 31.5669V28.5468ZM24 38.3716C21.4758 38.3716 19.4251 36.3246 19.4251 33.7966C19.4251 31.7422 20.7748 30.0084 22.6391 29.4267V31.1307C21.6697 31.6266 21.006 32.6333 21.006 33.7966C21.006 35.4521 22.3445 36.7906 24 36.7906C25.6555 36.7906 26.994 35.4521 26.994 33.7966C26.994 32.6296 26.3266 31.6229 25.3498 31.127V29.4267C27.2178 30.0047 28.5712 31.7422 28.5712 33.7966C28.575 36.3246 26.528 38.3716 24 38.3716Z' fill='white' />
            <path d='M13.9031 33.7893C13.9031 33.3903 13.5489 33.0659 13.2282 33.0659H9.3766C9.05222 33.0659 8.69427 33.3903 8.69427 33.7893C8.69427 34.1882 9.05222 34.5126 9.3766 34.5126H13.2282C13.5526 34.5126 13.9031 34.1882 13.9031 33.7893Z' fill='white' />
            <path d='M39.3058 33.7893C39.3058 33.3903 38.9516 33.0659 38.6309 33.0659H34.7793C34.4549 33.0659 34.097 33.3903 34.097 33.7893C34.097 34.1882 34.4549 34.5126 34.7793 34.5126H38.6309C38.9516 34.5126 39.3058 34.1882 39.3058 33.7893Z' fill='white' />
            <path d='M24.0037 23.6922C24.4027 23.6922 24.7233 23.338 24.7233 23.0173V19.1657C24.7233 18.8413 24.3989 18.4834 24.0037 18.4834C23.6048 18.4834 23.2804 18.8413 23.2804 19.1657V23.0173C23.2766 23.338 23.601 23.6922 24.0037 23.6922Z' fill='white' />
            <path d='M15.2379 28.7742C15.4355 28.4275 15.2901 27.9726 15.0105 27.8123L11.6697 25.8995C11.3863 25.7392 10.9165 25.8398 10.7189 26.1866C10.5213 26.5334 10.6704 26.992 10.9501 27.1523L14.2908 29.065C14.5705 29.2254 15.0403 29.121 15.2379 28.7742Z' fill='white' />
            <path d='M37.2849 41.3919C37.4825 41.0451 37.3371 40.5903 37.0574 40.4299L33.7166 38.5172C33.4333 38.3568 32.9635 38.4575 32.7659 38.8043C32.5682 39.151 32.7137 39.6096 32.997 39.77L36.3378 41.6827C36.6175 41.843 37.0835 41.7386 37.2849 41.3919Z' fill='white' />
            <path d='M29.0186 25.0273C29.3654 25.2249 29.8203 25.0795 29.9806 24.7998L31.8934 21.459C32.0537 21.1757 31.953 20.7059 31.6063 20.5083C31.2595 20.3106 30.8009 20.4598 30.6406 20.7394L28.7278 24.0802C28.5638 24.3599 28.6682 24.8297 29.0186 25.0273Z' fill='white' />
            <path d='M16.4012 47.0741C16.748 47.2718 17.2029 47.1263 17.3632 46.8467L19.2759 43.5059C19.4363 43.2225 19.3356 42.7527 18.9888 42.5551C18.6421 42.3575 18.1835 42.5067 18.0231 42.7863L16.1104 46.1271C15.9463 46.4067 16.0545 46.8728 16.4012 47.0741Z' fill='white' />
            <path d='M18.9702 25.0348C19.317 24.8372 19.4214 24.3674 19.2573 24.0877L17.3371 20.7507C17.1731 20.471 16.7182 20.3219 16.3714 20.5195C16.0247 20.7171 15.9203 21.1906 16.0843 21.4703L18.0045 24.8073C18.1649 25.0907 18.6197 25.2361 18.9702 25.0348Z' fill='white' />
            <path d='M31.6324 47.0553C31.9791 46.8577 32.0835 46.3879 31.9232 46.1082L30.003 42.7712C29.839 42.4915 29.3841 42.3424 29.0373 42.54C28.6906 42.7376 28.5862 43.2111 28.7502 43.4908L30.6704 46.8278C30.827 47.1112 31.2856 47.2566 31.6324 47.0553Z' fill='white' />
            <path d='M32.7547 28.7558C32.9523 29.1026 33.4221 29.207 33.7017 29.0429L37.0388 27.1227C37.3222 26.9624 37.4676 26.5038 37.27 26.157C37.0686 25.8102 36.5988 25.7058 36.3192 25.8699L32.9821 27.7901C32.6988 27.9542 32.5533 28.4128 32.7547 28.7558Z' fill='white' />
            <path d='M10.7338 41.4218C10.9314 41.7686 11.4012 41.873 11.6809 41.7089L15.0179 39.7887C15.3013 39.6284 15.4467 39.1698 15.2491 38.823C15.0515 38.4763 14.5779 38.3719 14.2983 38.5359L10.9612 40.4561C10.6779 40.6165 10.5362 41.0751 10.7338 41.4218Z' fill='white' />

        </svg>
    );
};
