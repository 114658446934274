import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import RoundTag from "../../RoundTag/roundTag.component";
import Loader from "../../Loader/loader.component";
import { programmeDetailPageLink } from "..";
import { ProgrammeDetail } from "../../../../graphql";
import { cardStyle } from "../../IssuerCard/issuer-summary/index.style";
import EmptyData from "../../SecurityCard/emptyData";

export interface ProgrammeCardProps {
  programme?: ProgrammeDetail;
  loading?: boolean;
}

export function ProgrammeSummaryCard({
  programme,
  loading = false,
}: ProgrammeCardProps) {
  return (
    <div
      css={[
        cardStyle.loaderSummary,
        css`
          box-shadow: ${programme
            ? "0px 16px 24px rgba(37, 54, 69, 0.15)"
            : "none"};
          background-color: ${programme ? "#ffffff" : "#F4F6F7"};
        `,
        cardStyle.cardMinHeight,
      ]}
    >
      <Loader
        loading={loading}
        padding={"0 !important"}
        margin={"0 !important"}
      >
        {programme ? (
          <a
            css={cardStyle.linkContainer}
            href={programmeDetailPageLink(programme.url, programme.id)}
          >
            <div css={cardStyle.summary}>
              <div css={cardStyle.roundedTag}>
                <RoundTag size={24}>P</RoundTag>
              </div>
              <div css={cardStyle.container}>
                <div css={cardStyle.name}>{programme.name}</div>
                <div css={cardStyle.info}>
                  {programme.mainIssuer ? (
                    <span>{programme.mainIssuer.name}</span>
                  ) : (
                    <></>
                  )}
                  <span>
                    ({programme.activeSecuritiesCount || 0} listed securities)
                  </span>
                </div>
              </div>
            </div>
          </a>
        ) : (
          <div
            css={[
              cardStyle.summary,
              { height: "100%", backgroundColor: "#F4F6F7" },
            ]}
          >
            <div css={cardStyle.container}>
              <EmptyData icon={"ban"} message={"No Programme"} />
            </div>
          </div>
        )}
      </Loader>
    </div>
  );
}
