/** @jsx jsx */

import * as React from "react";

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { DonutGraph } from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from "../../interfaces";
import { canUseDOM } from "../../predicates";

export interface ShareholdersProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
`;
const container = css`
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
  }
`;
const title = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #253645;
  margin-bottom: 56px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #253645;
    padding: 16px;
  }
`;
const graph = css`
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    .apexcharts-legend {
      display: none !important;
    }
  }
`;
const tableHeader = css`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e3e6e8;
`;
const tableRow = css`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e3e6e8;
`;
const left = css`
  width: calc(100% - 160px);
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #54666f;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
`;
const right = css`
  width: 160px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #54666f;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
`;
const leftTotal = css`
  width: calc(100% - 160px);
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  color: #cb1234;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
`;
const rightTotal = css`
  width: 160px;
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  color: #cb1234;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
`;
const headerLeft = css`
  width: calc(100% - 160px);
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  color: #54666f;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
`;
const headerRight = css`
  width: 160px;
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  color: #54666f;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
`;

export const Shareholders: React.FunctionComponent<ShareholdersProps> = (
  props: ShareholdersProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const [labels, setLabels] = React.useState<any[]>([]);
  const [values, setValues] = React.useState<any[]>([]);

  React.useEffect(() => {
    const listLabel: any = [];
    const listvalues: any = [];
    new Array(10).fill(0).map((arr: any, index: number) => {
      if (
        fields &&
        fields[`line ${index + 1} label`] &&
        (fields && (fields[`line ${index + 1} label`] as any)).value
      ) {
        listLabel.push(
          (fields && (fields[`line ${index + 1} label`] as any)).value
        );
        listvalues.push(
          parseFloat(
            (
              fields && (fields[`line ${index + 1} value`] as any)
            ).value.replace(/,/g, ".")
          )
        );
      }
    });
    setLabels(listLabel);
    setValues(listvalues);
  }, [fields]);

  const isMobile = canUseDOM && window.innerWidth <= 768;
  return (
    <div css={parentContainer}>
      <div css={container}>
        <div css={title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={graph}>
          <DonutGraph
            graphColors={["#24353c", "#425563", "#a3bcc1", "#d9e1e2", "#ca1234"]}
            chartHeight={260}
            labels={labels}
            legend
            series={values}
          />
        </div>
        <div css={tableHeader}>
          <div css={headerLeft}>Shareholders</div>
          <div css={headerRight}>Number of shares</div>
        </div>
        {new Array(7).fill(0).map((arr: any, index: number) => {
          if (
            fields &&
            fields[`line ${index + 1} label`] &&
            (fields && (fields[`line ${index + 1} label`] as any)).value
          ) {
            return (
              <div css={tableRow}>
                <div css={left}>
                  {(fields && (fields[`line ${index + 1} label`] as any)).value}
                </div>
                <div css={right}>
                  {(fields && (fields[`line ${index + 1} value`] as any)).value}
                </div>
              </div>
            );
          }
        })}
        <div css={tableRow}>
          <div css={leftTotal}>Total</div>
          <div css={rightTotal}>
            {values.reduce((partialSum, a) => partialSum + a, 0)}
          </div>
        </div>
      </div>
    </div>
  );
};
