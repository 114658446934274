
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {Icon} from "@bdl-cmn-shared-packages-npm/design-system";

export interface DoubleColumnsWithHexaListProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}


const container = css`
  width: 100%;
  max-width: 1264px;
  padding-left: 32px;
  padding-right: 32px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`;
const double = css`
  width: 50%;
  display: flex;
  flex-direction: column;
`;
const doubleContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const title = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  text-align: center;
  color: #354450;
  margin-bottom: 32px;
`;
const item = css`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #354450;
`;
const icon = css`
  color: #CB1234;
  font-size: 20px;
  margin-right: 8px;
`;
export const DoubleColumnsWithHexaList: React.FunctionComponent<DoubleColumnsWithHexaListProps> = (props: DoubleColumnsWithHexaListProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div css={container}>
            <div css={title}>
                <Text
                    field={fields && fields.titleLeft as SitecoreGenericTextField}
                />
            </div>
            <div css={doubleContainer}>
                <div css={double}>
                    {
                        new Array(6).fill(0).map((arr: any, index: number) => {
                            if (fields && fields[`tickLeft${index + 1}`] && (fields && fields[`tickLeft${index + 1}`] as any).value){
                                return (
                                    <div css={item} key={`items-${index}`}>
                                        <div css={icon}>
                                            <Icon icon={'check'} />
                                        </div>
                                        <Text
                                            field={fields[`tickLeft${index + 1}`] as SitecoreGenericTextField}
                                        />
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <div css={double}>
                    <div css={title}>
                        <Text
                            field={fields && fields.titleLeft as SitecoreGenericTextField}
                        />
                    </div>
                    {
                        new Array(6).fill(0).map((arr: any, index: number) => {
                            if (fields && fields[`tickRight${index + 1}`] && (fields && fields[`tickRight${index + 1}`] as any).value){
                                return (
                                    <div css={item} key={`items-${index}`}>
                                        <div css={icon}>
                                            <Icon icon={'check'} />
                                        </div>
                                        <Text
                                            field={fields[`tickRight${index + 1}`] as SitecoreGenericTextField}
                                        />
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    );
}
