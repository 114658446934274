/*
 *   e-Listing - core - components - country flag icon - deu
 *   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
 */

import * as React from 'react';

export const DEU: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
            <rect y='85.337' style={{fill: '#DE0000'}} width='512' height='341.326'/>
            <rect y='85.337' style={{fill: '#1A2934'}} width='512' height='113.775'/>
            <rect y='312.888' style={{fill: '#FFCF00'}} width='512' height='113.775'/>
        </svg>
    </svg>
);
