import gql from "graphql-tag";
import { issuerSummaryFragment } from "./issuers.queries";
import { programmeSearchFullFragment } from "./search/search-programme.queries";
import { documentSearchItemFragment } from "./search/search-document.queries";
import { IssuerDetail } from "../../interfaces/search/search-issuer";

export interface ProgrammeDetail {
  id: string,
  type: string,
  name: string,
  url: string,
  tags: string[],
  startDate: string,
  endDate: string,

  amountIssued: number,
  amountCurrency: string,
  amountMaxIssuedType: string,
  
  mainIssuer: IssuerDetail,
  issuers: IssuerDetail[],

  activeSecurities: number,
  activeSecuritiesCount: number,
  managementCompanyName: string,

  fastLaneUrls?: string[],
}

export interface ProgrammeDetailResult {
  loading: boolean,
  error?: any,
  data?: { program: ProgrammeDetail }
}

export const programmeSummaryFragment = gql`
  ${issuerSummaryFragment}
  fragment programmeSummaryFragment on Programme {
    id
    type
    name
    url
    tags
    startDate
    endDate
    amountCurrency
    amountIssued
    amountMaxIssuedType
    mainIssuer{
      ...issuerSummaryFragment
    }
    activeSecurities
    #fastLaneUrls
  }
`;

export const programmeFullFragment = gql`
  ${issuerSummaryFragment}
  fragment programmeFullFragment on Programme {
    id
    type
    name
    url
    tags
    startDate
    endDate
    amountCurrency
    amountIssued
    amountMaxIssuedType
    mainIssuer{
      ...issuerSummaryFragment
    }
    issuers {
      ...issuerSummaryFragment
    }
    activeSecurities
    #fastLaneUrls
  }
`;

export const GET_PROGRAMME_DETAIL = gql`
  ${programmeFullFragment}
  query GetProgrammeDetails($programmeId: String!) {
    programme(id: $programmeId) {
      ...programmeFullFragment
    }
  }
`;

export const GET_PROGRAMME_NOTICES = gql`
  ${documentSearchItemFragment}
  query getProgrammeNotices($programmeId: String!, $limit: Int, $offset: Int) {
    programmeNotices(id: $programmeId, limit: $limit, offset: $offset) {
      totalHits: totalResultSize
      documents {
        ...documentSearchItemFragment
      }
    }
  }
`;

export const GET_PROGRAMME_PROSPECTUSES = gql`
  ${documentSearchItemFragment}
  query getProgrammeProspectuses($programmeId: String!, $limit: Int, $offset: Int) {
    programmeProspectuses(id: $programmeId, limit: $limit, offset: $offset) {
      totalHits: totalResultSize
      documents {
        ...documentSearchItemFragment
      }
    }
  }
`;

export const GET_SEARCH_PROGRAMMES_ONLY_RESULTS = gql`
  ${programmeSearchFullFragment}

  query SearchProgrammes(
    $searchTerm: String!
    $size: Int
    $page: Int
    $sort: String
    $programmeTypes: [String!]
    $programmeStartDate: String
    $programmeEndDate: String
    $securityId: String
    $issuerId: String
    $activeOnly: Boolean
  ) {
    luxseProgrammesSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      programmeTypes: $programmeTypes
      startDate: $programmeStartDate
      endDate: $programmeEndDate
      securityId: $securityId
      issuerId: $issuerId
      activeOnly: $activeOnly
    ) {
      ...programmeSearchFullFragment
    }
  }
`;