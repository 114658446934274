
export enum LgxSustainableDevelopmentGoal {
	GOAL_1 = '1 - No Poverty',
	GOAL_2 = '2 - Zero Hunger',
	GOAL_3 = '3 - Good Health and Well-being',
	GOAL_4 = '4 - Quality Education',
	GOAL_5 = '5 - Gender Equality',
	GOAL_6 = '6 - Clean Water and Sanitation',
	GOAL_7 = '7 - Affordable and Clean Energy',
	GOAL_8 = '8 - Decent Work and Economic Growth',
	GOAL_9 = '9 - Industry, Innovation and Infrastructure',
	GOAL_10 = '10 - Reduced Inequality',
	GOAL_11 = '11 - Sustainable Cities and Communities',
	GOAL_12 = '12 - Responsible Consumption and Production',
	GOAL_13 = '13 - Climate Action',
	GOAL_14 = '14 - Life Below Water',
	GOAL_15 = '15 - Life on Land',
	GOAL_16 = '16 - Peace and Justice Strong Institutions',
	GOAL_17 = '17 - Partnerships to achieve the Goal'
}

export function lgxSustainableDevelopmentGoalLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(LgxSustainableDevelopmentGoal).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(LgxSustainableDevelopmentGoal)[index] : value;
	}catch (e){
		console.error("Failed to get LgxSustainableDevelopmentGoal label for '" + value + "'", e);
	}
		
	return value;
}