import { breakpoint, breakpointMax } from '../../../style';

import { css } from '@emotion/react';

export const style = {
  container: css`
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    @media (max-width: ${breakpointMax}) {
      padding: 0em;
    }
  `,
  containerTab: css`
    > div:first-child > div:first-child {
      justify-content: center;
      @media (max-width: ${breakpointMax}) {
        display: flex;
        flex-direction: column;
      }
    }

    > div:first-child > div:first-child > div {
      padding: 15px;
      @media (max-width: ${breakpointMax}) {
        text-align: center;
      }
    }
  `,
  left: css`
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-self: center;
    @media (max-width: ${breakpointMax}) {
      align-items: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      padding-right: 0;
    }
  `,
  right: css`
    width: 50%;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
    }
  `,
  bloc: css`
    max-width: calc(50% - 32px);
    display: inline-flex;
    flex-direction: column;
    margin-right: 16px;
    margin-bottom: 32px;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      max-width: 100%;
      margin-left: 45px;
      margin-right: 45px;
      margin-bottom: 60px;
      align-items: center;
    }
  `,
  title: css`
    color: #253645;
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    width: 100%;
    svg {
      margin-right: 8px;
    }
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 16px;
      margin-top: 16px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  `,
  icon: css`
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    @media (max-width: ${breakpointMax}) {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      align-self: unset;
    }
  `,
  hexa: css`
    opacity: 0.3;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  hoverIcon: css`
    position: absolute;
    color: #cb1234;
    @media (max-width: ${breakpointMax}) {
      margin: 0px;
    }
  `,
  description: css`
    color: #354450;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.01em;
    margin-top: 4px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  `,
  top: css`
    margin-bottom: 32px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      margin-top: 5px;
    }
  `,
  bottom: css`
    color: #354450;
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    text-align: right;
    margin: 10px 100px 0px 32px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0px;
    }
  `,
  content: css`
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    @media (max-width: ${breakpointMax}) {
      margin-left: auto;
      margin-right: auto;
    }
  `,
  contentBody: css`
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    display: inline-flex;
    align-self: center;
    align-items: center;
    display: flex;
    justify-content: center;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }w
  `,
  contentBodyList: css`
    display: block;
  `,
  contentButton: css`
    width: 100%;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;

    button {
      margin: auto;
    }
  `,
  action: css`
    margin-top: 32px;
  `,
  card: css`
    width: 32%;
    padding: 1.5em;
    margin-left: 1%;
    margin-right: 1%;
    text-decoration: none !important;
    border: 1px solid transparent;
    box-shadow: 0 4px 4px transparent;
    cursor: pointer;
    transition: all 0.5s ease;

    position: relative;
    display: flex!important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:hover {
      border: 1px solid #d9dee2;
      box-shadow: 0 4px 4px rgb(101 127 147 / 20%);
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin: 0;
      margin-bottom: 15px;
    }
  `,
  cardContent: css`
    line-height: 26px;
    color: #354450;
    text-align: left;
    font-size: 16px;

    position: relative;
    display: flex!important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  `,
  cardTitle: css`    
    color: #22AA5F;
    font-weight: 800;
  `,
  cardDate: css`
    margin-bottom: 15px;
  `,
  cardName: css`
    font-weight: 800;
  `,
  cardSubName: css`
    font-size: 14px;
    line-height: 16px;
    color: #657f93;
  `,
  cardArrow: css`
    position: relative;
    display: flex!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;

    font-size: 25px;
    line-height: 25px;
    color: #657f93;
  `,

  emptyResult: css`
    width: 100%;

    > div {
      margin-left: auto;
      margin-right: auto;
      margin-top: 1em;
      margin-bottom: 1em;
      @media (max-width: ${breakpointMax}) {
        width: 100%;
      }
    }
  `,
  row: css`
    display: flex;
    width: 100%;
    padding: 1.5em 2em;
    text-align: left;
    font-size: 0.813em;
    border-bottom: 1px solid rgb(217, 222, 226);

    &:first-child {
      border-top: 1px solid rgb(217, 222, 226);
    }
    transition: all 0.5s ease;

    text-decoration: none;
    color: rgb(101, 127, 147);
    @media (max-width: ${breakpointMax}) {
      padding: 15px;
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  `,
  rowClickable: css`
    cursor: pointer;
    &:hover{
      background: rgb(248, 250, 252);
    }
  `,
  rowIcon: css`
    width: 10%;

    position: relative;
    display: flex!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  rowIconRound: css`
    padding: 2px;
    margin-top: -6px;

    width: 30px;
    height: 30px;
    align-items: center;
    display: flex;
    border-radius: 50px;
    border: solid 2px red;
    justify-content: center;
    border: 2px solid red;
    color: rgb(66, 85, 99);

    svg {
      font-size: 16px;
    }
  `,
  rowContent: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    @media (max-width: ${breakpointMax}) {
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }
  `,
  rowHeader: css`
    width: 65%;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin-bottom: 10px;
    }
  `,
  rowTitle: css`
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: rgb(101, 127, 147);
  `,
  rowSubTitle: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgb(101, 127, 147);
  `,
  rowDate: css`
    width: 35%;
    color: rgb(101, 127, 147);
    font-size: 14px;
    line-height: 20px;

    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
};
