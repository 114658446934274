import { css } from '@emotion/react';

export const cssfApprovalsStyle = {
    title1: css`
      font-family: 'Mont' ;
      font-style: normal;
      font-weight: 400 !important;
      font-size: 13px !important;
      line-height: 17px !important;
      color: #354450 !important;
    `,
    title2: css`
      font-family: 'Mont' !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 13px !important;
      line-height: 150% !important;
      color: #1a2834 !important;
    `,

}