import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {Row} from "@bdl-cmn-shared-packages-npm/design-system";
import {style} from './securityIndices.style';
import IndexHeader from "../../UI/list/index/header";
import IndexRow from "../../UI/list/index/row";
import { SearchTable } from "../../UI/list/base";
import { genericStyle } from "../../UI/generic/generic.style";
import IndexSearchCard from "../../UI/list/index/card-mobile";
import { SearchMobile } from "../../UI/list/base/index-mobile";

export interface Row {
    label: string,
    action: () => void;
}
export interface SecurityIndicesProps {
    label?: string;
    children?: any;
    indices?: any;
    rows?: Row[];
}

const SecurityIndices: React.FunctionComponent<SecurityIndicesProps> = ({
    indices
    }: SecurityIndicesProps) => {
    return (
        indices?.length > 0 ?(
            <>
              <div css={style.securityIndices}>
                <div css={style.securityIndicesTop}>
                  <div css={style.flexRow}>
                    <div css={style.blocTitle}>Index constituent</div>
                    <div css={style.securityIndicesContainer}>
                        <div css={genericStyle.desktopDisplay}>
                            <SearchTable
                                loadingPage={false}
                                onPaginationUpdate={null}
                                pageNumber={0}
                                data={indices}
                                totalResultSize={indices.length}
                                density={"medium"}
                                Header={() => <IndexHeader selectedCols={null} />}
                                Row={(row) => <IndexRow key={`index-${row?.id}`} row={row} selectedCols={null} />}
                            />
                        </div>
                        <div css={genericStyle.mobileDisplay}>
                            <SearchMobile
                                onPaginationUpdate={null}
                                pageNumber={0}
                                data={indices}
                                totalResultSize={indices.length}
                                Card={(card) => (
                                    <IndexSearchCard key={`card-index-${card?.id}`} card={card} />
                                )}
                            />
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null
    )
};

export default SecurityIndices;