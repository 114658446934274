 /** @jsx jsx */
 import { css, jsx } from "@emotion/react";
 import * as React from 'react';
 import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

 import { SitecoreGenericTextField } from '../../interfaces';
 import {Icon} from "@bdl-cmn-shared-packages-npm/design-system";
 import {renderLink} from "../../utils/helper";
 import {canUseDOM} from "../../predicates";
 import {breakpointMax} from "../../style";

 export interface UsefullPublicationsProps {
     fields: any;
     params?: ComponentParams;
     rendering: ComponentRendering;
 }

 const container = css`
  width: 100%;
   max-width: 1280px;
   padding-left: 40px;
   padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  margin-bottom: 48px;
`;
 const title = css`
  font-weight: bold;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  margin-bottom: 48px;

   @media (max-width: ${breakpointMax}) {
     font-size: 26px;
     line-height: 150%;
   }
`;
 const items = css`
  display: flex;
  flex-direction: row;
   flex-wrap: wrap;
   @media (max-width: ${breakpointMax}) {
     flex-direction: column;
   }
`;
 const item = css`
  display: flex;
   flex-direction: column;
  background: #F6F8F9;
  border-radius: 16px;
  padding: 32px;
  margin-right: 16px;
  margin-bottom: 16px;
   width: calc(25% - 16px);
   cursor: pointer;
   @media (max-width: ${breakpointMax}) {
     flex-direction: column;
     width: 100%;
   }
`;
 const image = css`
  img {
    width: 100%;
  }
   margin-bottom: 24px;
`;
 const itemTitle = css`
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  color: #354450;
   margin-bottom: 24px;
   @media (max-width: ${breakpointMax}) {
     font-size: 24px;
     line-height: 150%;
   }
`;
 const itemContent = css`
   font-size: 12px;
   line-height: 150%;
   text-align: center;
   letter-spacing: 0.01em;
   color: #354450;
`;

 export const UsefullPublications: React.FunctionComponent<UsefullPublicationsProps> = (props: UsefullPublicationsProps): React.ReactElement => {
     const { fields } = props;

     return (
         <div css={container}>
             <div css={title}>
                 <Text
                     field={fields && fields.title as SitecoreGenericTextField}
                 />
             </div>
             <div css={items}>
                 {
                     new Array(10).fill(0).map((arr: any, index: number) => {
                         if (fields && ((fields[`item ${index + 1} download link`] as any)?.value?.src) || (fields[`item ${index + 1} link`] && (fields[`item ${index + 1} link`] as any)?.value)) {
                             return (
                                 <div
                                     css={item}
                                     key={`document-${index}`}
                                     onClick={() => window.open((fields && fields[`item ${index + 1} download link`] as any).value?.src || (fields && fields[`item ${index + 1} link`] as any).value, '_blank').focus()}>
                                     <div css={itemTitle}>
                                         <Text
                                             field={fields[`item ${index + 1} title`] as SitecoreGenericTextField}
                                         />
                                     </div>
                                     <div css={image}>
                                         {
                                             (getFieldValue<any>(fields, `item ${index + 1} image`, "")) && (getFieldValue<any>(fields, `item ${index + 1} image`, "")).src && (
                                                 <img src={renderLink(getFieldValue<any>(fields, `item ${index + 1} image`, "").src)} alt={"image"} />
                                             )
                                         }
                                     </div>
                                     <div css={itemContent}>
                                         <Text
                                             field={fields[`item ${index + 1} description`] as SitecoreGenericTextField}
                                         />
                                     </div>
                                 </div>
                             )
                         }
                     })
                 }
             </div>
         </div>
     );
 }
