import { breakpoint, breakpointMax } from "../../../../style";

import { css } from "@emotion/react";

export const style = {
  searchInput: css`
    align-items: center;
    background-color: rgb(164 188 194 / 50%);
    display: flex;
    min-height: 70px;
    justify-content: center;
  `,
  searchInputForm: css`
    width:100%;
    @media (max-width: ${breakpointMax}) {
      background: #A4B6C2;
      padding: 16px 8px;
    }
  `,

  searchInputWrapper: css`
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
  `,

  searchInputField: css`
    max-width: 926px;
    border-radius: 4px;
    width: 50%;
    position: relative;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  searchInputButton: css`
    margin-left: 1em;
  `,
  input: (timer: boolean) => css`
    opacity: ${timer ? 1 : 0};
    transition: opacity .3s ease-in-out;
    background-color: ${timer ? "white": "rgb(164 188 194)"}; 
    border-radius: 4px;
  `
};
