/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import ProgrammeHeader from './header';
import { SearchTable } from '../base';
import ProgrammeRow from './row';
import SearchFilterParams from '../../../../../interfaces/search/search-filter-params';
import { ProgrammeSearchResultDto } from '../../../../../interfaces/search/search';
import { ProgrammeFilters } from './programme-filters';
import { containerCss, resultCss } from '../index.style';
import { InputSort } from '../base/sort';
import { Loader } from '../../Loader';
import SearchResults from '../../../../../interfaces/search/search-result';
import ProgrammeEmptyResults from './empty';

export interface ProgrammesListViewProps {
  selectedCols: any;
  history?: any;
  data: ProgrammeSearchResultDto;
  searchResults?: SearchResults;
  loadingPage?: boolean;
  target?: string;
  onPaginationUpdate: (pageNumber: number) => void;
  currentPageNumber?: number;
  density?: 'low' | 'medium' | 'high';
  itemsNumber?: number;
  totalResultSize?: number;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  sort: any;
  sortData: any;
  onSortChange: (sort: any) => void;
}

export const ProgrammeListView = ({
  data,
  searchResults,
  loadingPage,
  selectedCols,
  density,
  onPaginationUpdate,
  currentPageNumber,
  totalResultSize,
  filters,
  onFiltersChange,
  sort,
  sortData,
  onSortChange,
}: ProgrammesListViewProps) => {
  return (
    <div css={containerCss}>
      <ProgrammeFilters filterCount={data && data.filters} filters={filters} onFiltersChange={onFiltersChange} />
      <div css={resultCss}>
        {loadingPage && <Loader />}
        <InputSort sort={sort} baseValues={sortData ? sortData['programmes'] : {}} onSortChange={onSortChange} />
        <SearchTable
          data={data.programmes}
          density={density}
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          totalResultSize={totalResultSize}
          Header={() => <ProgrammeHeader selectedCols={selectedCols} />}
          Row={(row) => <ProgrammeRow row={row} selectedCols={selectedCols} key={`search-programme-r-${row?.id}`}/>}
          Empty={() => <div css={{marginTop: "70px"}}><ProgrammeEmptyResults searchResults={searchResults}/></div>}
        />
      </div>
    </div>
  );
};
