import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Table, Thead, Tr, Th, Td } from '@bdl-cmn-shared-packages-npm/design-system';
import { sharedStyle } from '../../../../../../styles/shared';
import { MarketdataModel } from '../../market-data-view.component';
import SearchSecurity from '../../../../../../interfaces/search/search-security';
import LgxSustainableBondsRow from './row';

export const LgxSustainableBondsView = ({data} : {data: MarketdataModel}) => {

  return (
    <Table>
      <Thead>
        <Tr>
          <Th css={sharedStyle.tableNewSecurity}>Security</Th>
          <Th css={sharedStyle.listingDateTh}>Maturity</Th>
          <Th css={sharedStyle.bidTh}>Yield</Th>
          <Th css={sharedStyle.askTh}>Price/Vari.(24h)</Th>
        </Tr>
      </Thead>
      <tbody>
        {data?.totalHits > 0 ? 
          data?.currentData?.map((row: SearchSecurity) => (
            <LgxSustainableBondsRow
              row={row}
              key={`lgx-sustainalble-bond-${row?.id}`}
            />
          ))
          : 
          <Tr>
            <Td>-</Td>
            <Td>-</Td>
            <Td>-</Td>
            <Td>-</Td>
          </Tr>
        }
      </tbody>
    </Table>
  );
};
export default LgxSustainableBondsView;
