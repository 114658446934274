import * as React from "react";

import { Td, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";

import { formatDate } from "../../../../../../utils/date";
import { sharedStyle } from "../../../../../../styles/shared";
import { style } from "../../../../Index/Index-details/index.style";
import { tradingStyle } from "../../trading-data.style";
import { goToIssuerDetailPage } from "../../../../IssuerCard";

/** @jsx jsx */

const RenderMarket = ({ label }: any) => {
  return label ? <div css={style.market}>{label}</div> : <div>"N/A"</div>;
};

export const NewIssuerRow = (props: any) => {
  const row = props?.newIssuerRowData;

  if( ! row )
    return <></>;
  
  return (
    <Tr
      onRowClick={() => goToIssuerDetailPage(row?.url, row?.id)}
      css={sharedStyle.tableRow}
    >
      <Td>
        <div>
          <div css={tradingStyle.fnsTitle}>
            {`${row?.name} ${formatDate(row?.firstListingDate, "DD/MM/YY HH:mm:ss")}`}
          </div>
          <div css={tradingStyle.fnsText}>
            {Boolean(row.statistics?.nbSecurities) && (
              <RenderMarket label={row.statistics?.nbSecurities + " Securities"} />
            )}
            {Boolean(row.statistics?.nbGreens) && (
              <RenderMarket label={row.statistics?.nbGreens + " Greens"} />
            )}
            {Boolean(row.statistics?.nbBonds) && (
              <RenderMarket label={row.statistics?.nbBonds + " Bonds"} />
            )}
            {Boolean(row.statistics?.nbEquities) && (
              <RenderMarket label={row.statistics?.nbEquities + " Equities"} />
            )}
            {Boolean(row.statistics?.nbCertificates) && (
              <RenderMarket label={row.statistics?.nbCertificates + " Certificates"} />
            )}
            {Boolean(row.statistics?.nbWarrants) && (
              <RenderMarket label={row.statistics?.nbWarrants + " Warrants"} />
            )}
          </div>
        </div>
      </Td>
    </Tr>
  );
};
export default NewIssuerRow;
