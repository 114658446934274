
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const IND: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FAB446" d="M512,200.093H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,200.093L512,200.093z" />
            <path fill="#73AF00" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V311.909h512v102.988 C512,419.773,508.047,423.725,503.172,423.725z" />
            <rect y="200.091" fill="#F5F5F5" width="512" height="111.81" />
            <g>
                <path fill="#41479B" d="M256,303.449c-26.164,0-47.448-21.284-47.448-47.448s21.284-47.448,47.448-47.448 s47.448,21.284,47.448,47.448S282.164,303.449,256,303.449z M256,217.38c-21.298,0-38.621,17.323-38.621,38.621 c0,21.298,17.323,38.621,38.621,38.621s38.621-17.323,38.621-38.621C294.621,234.703,277.298,217.38,256,217.38z" />
                <circle fill="#41479B" cx="256" cy="256.001" r="5.379" />
                <polygon fill="#41479B" points="256,256.808 242.33,258.188 212.966,256.808 212.966,255.194 242.33,253.814 256,255.194 " />
                <polygon fill="#41479B" points="256,256.808 269.67,258.188 299.034,256.808 299.034,255.194 269.67,253.814 256,255.194 " />
                <polygon fill="#41479B" points="255.193,256.001 253.813,242.331 255.193,212.967 256.807,212.967 258.187,242.331 256.807,256.001 " />
                <polygon fill="#41479B" points="255.193,256.001 253.813,269.671 255.193,299.035 256.807,299.035 258.187,269.671 256.807,256.001 " />
                <polygon fill="#41479B" points="255.43,256.571 244.788,247.882 225,226.142 226.141,225.001 247.881,244.789 256.57,255.431 " />
                <polygon fill="#41479B" points="255.43,256.571 264.119,267.213 285.859,287.001 287,285.86 267.212,264.12 256.57,255.431 " />
                <polygon fill="#41479B" points="255.43,255.431 264.119,244.789 285.859,225.001 287,226.142 267.212,247.882 256.57,256.571 " />
                <polygon fill="#41479B" points="255.43,255.431 244.788,264.12 225,285.86 226.141,287.001 247.881,267.213 256.57,256.571 " />
                <polygon fill="#41479B" points="256.309,256.747 244.207,263.253 216.551,273.215 215.933,271.724 242.534,259.212 255.691,255.255 " />
                <polygon fill="#41479B" points="256.309,256.747 269.466,252.79 296.067,240.278 295.449,238.787 267.793,248.749 255.691,255.255 " />
                <polygon fill="#41479B" points="255.254,256.31 248.748,244.208 238.786,216.552 240.277,215.934 252.789,242.535 256.746,255.692 " />
                <polygon fill="#41479B" points="255.254,256.31 259.211,269.467 271.723,296.068 273.214,295.45 263.252,267.794 256.746,255.692 " />
                <polygon fill="#41479B" points="255.691,256.747 242.534,252.79 215.933,240.278 216.551,238.787 244.207,248.749 256.309,255.255 " />
                <polygon fill="#41479B" points="255.691,256.747 267.793,263.253 295.449,273.215 296.067,271.724 269.466,259.212 256.309,255.255 " />
                <polygon fill="#41479B" points="255.254,255.692 259.211,242.535 271.723,215.934 273.214,216.552 263.252,244.208 256.746,256.31 " />
                <polygon fill="#41479B" points="255.254,255.692 248.748,267.794 238.786,295.45 240.277,296.068 252.789,269.467 256.746,256.31 " />
            </g>
            <circle fill="#F5F5F5" cx="256" cy="256.001" r="7.256" />
            <circle fill="#41479B" cx="256" cy="256.001" r="4.351" />
        </svg>
    </React.Fragment>
);