import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { formatToThousandsSeparator } from '../../../../../utils';
import { Security } from '../../../../../interfaces';
import { style } from '../../SecurityOverview/securityOverview.style';
import { InstrumentType, instrumentTypeCheck } from '../../../../../enums';

export interface MarketDataInformationProps {
  securityData?: Security;
}

export const MarketDataSpreadInformation = ({ securityData }: MarketDataInformationProps) => {
  const marketData = securityData?.marketData || null;
  const bestOrders = securityData?.marketRealtime?.bestOrders || null;

  const hasNotYieldData : boolean = (
    instrumentTypeCheck( securityData?.instrumentType?.toString(), InstrumentType.ACT )
  ) ;

  return (
    <div css={style.marketDataContainer}>
      <div css={[ hasNotYieldData && style.marketBoxTwo, style.marketBoxLeft ]}>
        <div css={style.marketBoxHeader}>
          {/* <div css={style.marketBoxHeaderLabel}></div> */}
          <div css={style.marketDoubleCol}>
            <div css={style.marketDoubleColLeft}>
              <div css={style.marketDoubleColLeftLabel}>BID</div>
              <div css={style.marketDoubleColLeftAmount}>
                {(bestOrders?.bid?.length &&
                  (parseFloat(bestOrders.bid[0].price)
                    ? formatToThousandsSeparator(parseFloat(bestOrders.bid[0].price))
                    : bestOrders.bid[0].price)) ||
                  'N/A'}
              </div>
              <div css={style.marketDoubleColLeftSubAmount}>
                {(bestOrders?.bid?.length &&
                  bestOrders.bid[0].quantity > 0 &&
                  formatToThousandsSeparator(bestOrders.bid[0].quantity)) ||
                  'N/A'}
              </div>
            </div>
            <div css={style.marketDoubleColRight}>
              <div css={style.marketDoubleColLeftLabel}>ASK</div>
              <div css={style.marketDoubleColLeftAmount}>
                {(bestOrders?.ask?.length &&
                  (parseFloat(bestOrders.ask[0].price)
                    ? formatToThousandsSeparator(parseFloat(bestOrders.ask[0].price))
                    : bestOrders.ask[0].price)) ||
                  'N/A'}
              </div>
              <div css={style.marketDoubleColLeftSubAmount}>
                {(bestOrders?.ask?.length &&
                  bestOrders.ask[0].quantity > 0 &&
                  formatToThousandsSeparator(bestOrders.ask[0].quantity)) ||
                  'N/A'}
              </div>
            </div>
          </div>
        </div>
      </div>

      { (!hasNotYieldData) ?
        <div css={style.marketBoxCenter}>
          <div css={style.marketBoxHeader}>
            <div css={style.marketBoxHeaderLabel}>SPREAD</div>
            <div css={style.marketDoubleCol}>
              <div css={style.marketDoubleColLeft}>
                <div css={style.marketDoubleColLeftLabel}>RELATIVE</div>
                <div css={style.marketDoubleColLeftAmount}>
                  {securityData?.marketRealtime?.spreadRelative
                    ? `${securityData?.marketRealtime?.spreadRelative.toFixed(3)} %`
                    : 'N/A'}
                </div>
              </div>
              <div css={style.marketDoubleColRight}>
                <div css={style.marketDoubleColLeftLabel}>ABSOLUTE</div>
                <div css={style.marketDoubleColLeftAmount}>
                  {securityData?.marketRealtime?.spreadAbsolute
                    ? `${securityData?.marketRealtime?.spreadAbsolute.toFixed(3)}`
                    : 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>
        : <></>
      }

      <div css={[style.marketBoxRight, hasNotYieldData && style.marketBoxTwo]}>
        <div css={style.marketBoxHeader}>
          <div css={style.marketDoubleCol}>
            { (!hasNotYieldData) ?
              <div css={style.marketDoubleColLeft}>
                <div css={style.marketDoubleColLeftLabel}>YTM</div>
                <div css={style.marketDoubleColLeftAmount}>
                  {marketData?.yieldToMaturity ? `${marketData?.yieldToMaturity.toFixed(3)} %` : 'N/A'}
                </div>
                <div css={style.marketDoubleColLeftSubAmount}>
                </div>
              </div>
              : <></>
            }
            <div css={[style.marketDoubleColRight, hasNotYieldData && style.marketOneCol]}>
              <div css={style.marketDoubleColLeftLabel} style={{ whiteSpace: 'nowrap' }}>
                THRESHOLDS
              </div>
              <div css={style.marketDoubleColLeftAmount}>
                {securityData?.marketData?.thresholdsLowerFloor && securityData?.marketData?.thresholdsUpperFloor
                  ? `${securityData.marketData.thresholdsLowerFloor} /  ${securityData.marketData.thresholdsUpperFloor}`
                  : 'N/A'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
