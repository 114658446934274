import * as React from "react";
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/react";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { style } from "./index.style";
import {
  Text,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import { TradingItemCard } from "../TradingItemCard";
import { useState } from "react";
import { fetchData, getMembers } from "../../utils/helper";
import Loader from "../../core/components/Loader/loader.component";

export interface MainContainerProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const MainContainer: React.FunctionComponent<MainContainerProps> = (
  props: MainContainerProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [expend, setExpend] = useState<boolean>(false);
  const [members, setMembers] = useState<any[]>([]);

  const isListing: boolean = getFieldValue<boolean>(
    fields,
    "display listing members",
    false
  );
  const isTrading: boolean = getFieldValue<boolean>(
    fields,
    "display trading members",
    false
  );
  const isLuxSEPartner: boolean = getFieldValue<boolean>(
    fields,
    "display LuxSE Partners",
    false
  );

  const isIndependentSoftwareVendor: boolean = getFieldValue<boolean>(
    fields,
    "display independent software vendors",
    false
  );

  const fetchElements = async (id: string) => {
    try {
      const result: any = await fetchData(id);
      const res = await getMembers(result);
      if (isListing) {
        setMembers(
          res.filter((res: any) => res["Is listing member"] === "1") || []
        );
      } else if (isTrading) {
        setMembers(
          res.filter((res: any) => res["Is trading member"] === "1") || []
        );
      } else if (isLuxSEPartner) {
        setMembers(
          res.filter((res: any) => res["Is LuxSE Partner"] === "1") || []
        );
      }
      else if (isIndependentSoftwareVendor) {
        setMembers(
          res.filter((res: any) => res["Is independent software vendor"] === "1") || []
        );
      }

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    fetchElements("877380A7-A456-4E75-B56C-C6FE8D79BB88");
  }, []);

  React.useEffect(() => {
    setExpend(members.length < 9);
  }, [members]);

  return (
    <div css={style.container}>
      <div css={style.title}>
        <Text field={fields && (fields.title as SitecoreGenericTextField)} />
      </div>
      <Loader loading={loading}>
        <div css={style.center}>
          <div css={style.rows}>
            {members
              .slice(0, expend ? members.length : 8)
              .map((member: any, index: number) => (
                <TradingItemCard member={member} key={`index-${index}`} />
              ))}
          </div>
          {members && members.length > 8 && (
            <div css={style.rowButton}>
              <Button
                label={`${expend ? "Reduce" : "Show all"} entries`}
                onClick={() => setExpend(!expend)}
              />
            </div>
          )}
        </div>
      </Loader>
    </div>
  );
};
