export enum MarketPostTradeEnum {
	O_O = "Guaranteed",
	O_N = "Pass-through",
	N_N = "Bilateral",
}

export function marketPostTradeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(MarketPostTradeEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(MarketPostTradeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get MarketPostTradeEnum label for '" + value + "'", e);
	}
		
	return value;
}