import * as React from "react";
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";

export interface OAMSearchComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const OAMSearchComponent: React.FunctionComponent<OAMSearchComponentProps> = (
  props: OAMSearchComponentProps
): React.ReactElement => {
  const { fields } = props;

  const headingValue: string = getFieldValue<string>(fields, "heading", "");

  return (
    <div className="OAMSearchComponent-box">
      <p>OAMSearchComponent Component</p>
      <Text field={fields && fields.heading as SitecoreGenericTextField} />
      <span>Raw value: {headingValue}</span>
    </div>
  );
};
