import config from "../temp/config";

export const BACKBOURSE_ENDPOINT = process.env.BACKBOURSE_ENDPOINT  || config.backbourseEndpoint;
export const DOWNLOAD_ENDPOINT = process.env.DOWNLOAD_ENDPOINT || config.downloadEndpoint;
export const DOCUMENTS_TYPE_TO_PREVIEW = process.env.DOCUMENT_TYPE_TO_PREVIEW || config.documentsTypeToPreview;
export const ECB_COLLATERAL = process.env.ECB_COLLATERAL || config.ecbCollateralEndpoint;

export const RISERS_AND_FALLERS_REPORT_LINK = process.env.RISERS_AND_FALLERS_REPORT_LINK || config.risersAndFallersReportLink;
export const TOP_TRADED_SECURITIES_REPORT_LINK = process.env.TOP_TRADED_SECURITIES_REPORT_LINK || config.topTradedSecuritiesReportLink;
export const MARKET_CAPITALISATION_REPORT_LINK = process.env.DOCUMENT_TYPE_TO_PREVIEW || config.marketCapitalisationReportLink;
