
/** @jsx jsx */

import * as React from 'react';

import {
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    RichText,
    Text,
    getFieldValue
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import {Button} from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from '../../interfaces';
import {renderLink} from "../../utils/helper";

export interface TextWithActionAndBackgroundProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const action = css`
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
`;

const title = css`
  font-weight: bold;
  font-size: 36px;
  line-height: 46px;
  color:  #ffffff;
  text-align: center;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }
`;
const content = css`
  font-size: 18px;
  line-height: 23px;
  color:  #ffffff;
  text-align: center;
  margin-top: 16px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 16px;
    line-height: 180%;
  }
`;

export const TextWithActionAndBackground: React.FunctionComponent<TextWithActionAndBackgroundProps> = (props: TextWithActionAndBackgroundProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const btnLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
    const btnLink: string = getFieldValue<string>(fields, 'btnLink', '');
    const contactLink: string = getFieldValue<string>(fields, 'contact email', '');
    const contactSubject: string = getFieldValue<string>(fields, 'contact subject', '');
    const background: any = getFieldValue<any>(fields, 'background', null);

    const parentContainer = css`
      width: 100%;
      background: url(${renderLink(background && background.src)});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin-left: auto;
      margin-right: auto;
      padding-top: 80px;
      padding-bottom: 80px;
      @media (max-width: ${breakpointMax}) {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 16px;
        padding-right: 16px;
      }
    `;
    const container = css`
      width: 100%;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    `;
    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields?.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={content}>
                    <Text
                        field={fields?.content as SitecoreGenericTextField}
                    />
                </div>
                <div css={action}>
                    {
                        contactLink ? (
                            <Button
                                label={btnLabel}
                                iconLeft={'paper-plane'}
                                onClick={() => window.open(`mailto:${contactLink}?subject=${contactSubject}`)}
                            />
                        ) : (
                            <Button label={btnLabel} onClick={() => btnLink ? window.location.href = btnLink : console.log} iconLeft={'paper-plane'}/>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
