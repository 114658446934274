
export enum SecurityCategoryEnum {
	FUND = 'Funds',
	CERTIFICATE = 'Certificates',
	DEBT = 'Bonds',
	WARRANT= 'Warrants',
	EQUITY= 'Equities',
}

function cleanName(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try{
		value = value.toString().toUpperCase();
		value = value.replace(/[^a-zA-Z ]/g, "");
		value = value.replace(/\s/g, "");
	}catch (e){
	}

	return value;
}

export function securityCategoryLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(SecurityCategoryEnum).indexOf( cleanName(value) );
  		if( index >= 0) 
			return Object.values(SecurityCategoryEnum)[index];
	}catch (e){
		console.error("Failed to get SecurityCategoryEnum label for '" + value + "'", e);
	}
	
	console.error("SecurityCategoryEnum label for '" + value + "' - '" + cleanName(value) + "' doest exist");

	return value;
}