
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React from "react";
import {
  Tr,
  Td,
  Icon,
  Button,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { style } from "./index.style";
import { OAMDocument } from "../deposit-row/useDetails";
import {cssfApprovalsStyle} from "../../../CssfApprovals/cssf-approvals-view.style";
import {DOWNLOAD_ENDPOINT} from "../../../../../backbourse";
import {canUseDOM} from "../../../../../predicates";

export interface DocumentRowProps {
  document: OAMDocument,
  isFirstRow: boolean,
  isSecondLevel: boolean
}

export const DocumentRow = ({
  document,
  isFirstRow,
  isSecondLevel
}: DocumentRowProps) => {
    const downloadPath = DOWNLOAD_ENDPOINT;

    const redirectToLocation = (uri: string) => {
        if (canUseDOM) {
            window.open(uri);
        }
    };


    return (
    <Tr key={document.url} css={[style.subRow, isFirstRow && style.firstSubRow]} >
        <Td></Td>
      <Td colSpan={3}>
        <div css={[style.alignCenter, isSecondLevel && style.level2]}>
          <span css={style.icon} >
            <Icon icon="file-pdf" />
          </span>
            {document.obsolete && <span style={{fontWeight: 'bold'}}>(Obsolete)&nbsp;</span> } {document.name}
        </div>
      </Td>
        {/*<Td colSpan={3}>{document.name}</Td>*/}
        <Td colSpan={2}>
            <div css={style.data}>
                <span>Size</span>
                <span>{document.size}&nbsp;Kb</span>
            </div>
        </Td>
        <Td>
            <Button css={cssfApprovalsStyle.buttonUnderList}
                    onClick={()=>redirectToLocation (downloadPath + document?.url)}
                    label={"Download"}
                    iconRight='download'/>
        </Td>
    </Tr >

  )
};




