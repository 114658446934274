import React, { useState } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Icon, Td, Button, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import RoundTag from '../RoundTag/roundTag.component';
import {formatDate} from "../../../utils/date";

export function OfficialListRow(props: any) {
  const row = props.rowData;

  const [style, setStyle] = useState({ display: 'none' });

  return (
    <Tr
      onRowClick={() => window.open(row?.downloadUrl, '_blank')}
      onMouseEnter={(e: any) => {
        setStyle({ display: 'flex' });
      }}
      onMouseLeave={(e: any) => {
        setStyle({ display: 'none' });
      }}
    >
      <Td>
        <RoundTag borderSize={2} textColor='#425563' small={true} padding={true}>
          <Icon icon={'file-pdf'} />
        </RoundTag>
      </Td>
      <Td> {formatDate(row?.date)} </Td>
      <Td>
        <Button
          css={{
            'align-items': 'center',
            'justify-content': 'center',
            height: '35px',
          }}
          style={style}
          iconLeft='download'
          iconRight=''
          label='Download'
          onRowClick={() => window.open(row?.downloadUrl, '_blank')}
          variant='primary'
        />
      </Td>
    </Tr>
  );
}

export default OfficialListRow;
