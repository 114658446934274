import { breakpoint, breakpointMax } from '../../../../style';

import { css } from '@emotion/react';

export const style = {
  type: css`
    margin: 45px 120px;
    h3 {
      font-weight: 700;
      font-size: 32px;
      line-height: 41px;
      color: #354450;
    }
    &:not(:last-child) {
      border-bottom: 2px solid #e3e6e8;
      padding-bottom: 2rem;
    }
    > * {
      margin-bottom: 1rem;
    }
    @media (max-width: ${breakpointMax}) {
      margin: 2rem 0;
      h3 {
        margin-left: 14px;
        margin-right: 14px;
      }
    }
  `,
  issuerOverview: css``,
  overviewButton: css`
    margin-top: 1em;

    @media (max-width: ${breakpointMax}) {
      width: 100%;
      text-align: center;

      > button {
        margin: auto;
      }
    }
  `,
  mobileContainer: css`
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;

    > div {
      width: 100%;
    }
  `,
};
