
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';

export interface MarketStatisticsDataProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
      width: 100%;
    `;

export const MarketStatisticsData: React.FunctionComponent<MarketStatisticsDataProps> = (props: MarketStatisticsDataProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div css={container}>
            <p>MarketStatisticsData Component</p>
            <Text
                field={fields && fields.heading as SitecoreGenericTextField}
            />
            <span>Raw value: {headingValue}</span>
        </div>
    );
}