/** @jsx jsx */

import * as React from "react";

import {
  Button,
  Pagination,
  Table,
  Td,
  Tr,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { NUMBER_OF_ROW_IN_PAGE } from "../../../../constants";
import { useEffect, useState } from "react";

import IndexDetailsBasketHeader from "./header";
import IndexDetailsRow from "./row";
import SecuritySearchCard from "../../../UI/list/security/card-mobile";
import { genericStyle } from "../../../UI/generic/generic.style";
import { jsx } from "@emotion/react";
import { style } from "../index.style";
import { IndiceBasket } from "../../../../../interfaces/indice";

interface IndiceBasketProps {
  basket?: IndiceBasket[];
  partialDisplay?: boolean;
  handleClick?: () => void;
}

export function IndexDetailsBasketTable(
  {basket, partialDisplay = false, handleClick}: IndiceBasketProps) {

  const basketSize = basket?.length;
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [basketSliced, setBasketSliced] = useState<IndiceBasket[]>([]);


  const onPaginationUpdate = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
  };

  useEffect(() => {
    createPage()
  }, [currentPageNumber]);

  const createPage = () => {
    let basketSlicedTmp : IndiceBasket[] = [];

    if(basket?.length){
      basketSlicedTmp = basket;

      if (partialDisplay) {
        basketSlicedTmp = basketSlicedTmp?.slice(0, 4);
      } else if (basketSlicedTmp?.length > NUMBER_OF_ROW_IN_PAGE) {
        let startIndex = 0;
        let endIndex = NUMBER_OF_ROW_IN_PAGE;
        if (currentPageNumber > 0) {
          endIndex = (currentPageNumber + 1) * NUMBER_OF_ROW_IN_PAGE;
          startIndex = endIndex - NUMBER_OF_ROW_IN_PAGE;
        }
        basketSlicedTmp = basketSlicedTmp?.slice(startIndex, endIndex);
      }
    }
    
    setBasketSliced( basketSlicedTmp );
  };

  return (
    <div css={partialDisplay ? style.basketContainer2 : style.basketContainer}>
      <h2>Basket</h2>
      <div css={genericStyle.desktopDisplay}>
        <Table>
          <IndexDetailsBasketHeader />
          <tbody>
            {basketSliced?.length ? 
              basketSliced.map((basketItem: IndiceBasket) => (
                <IndexDetailsRow row={basketItem} />
              ))
              :
              <Tr>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
              </Tr>
            }
          </tbody>
        </Table>
      </div>
      <div css={[genericStyle.mobileDisplay]}>
        {basketSliced?.length? 
          basketSliced.map((basketItem: any) => (
            <SecuritySearchCard
              key={`card-security-basket-${basketItem?.id}`}
              card={basketItem?.security}
            />
          ))
          : "no data"}
      </div>

      {partialDisplay && (
        <Button
          css={style.buttonBasket}
          onClick={() => handleClick()}
          label={"See all " + basketSize + " securities"}
          iconLeft="plus-circle"
        />
      )}
      {!partialDisplay && basketSize > NUMBER_OF_ROW_IN_PAGE && (
        <Pagination
          initialPage={0}
          onPageChange={(d: any) => {
            onPaginationUpdate(d.selected);
          }}
          total={
            basketSize / NUMBER_OF_ROW_IN_PAGE > 200
              ? 200
              : basketSize / NUMBER_OF_ROW_IN_PAGE
          }
        />
      )}
    </div>
  );
}

export default IndexDetailsBasketTable;
