import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from '../../SecurityOverview/securityOverview.style';
import { Security } from '../../../../../interfaces';
import { SecurityMarketTradingATT } from './trading-att';
import { SecurityMarketTradingDefault } from './trading-default';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export interface SecurityMarketProps {
  securityData?: Security;
}

export const SecurityMarketTrading: React.FunctionComponent<SecurityMarketProps> =
   ({ securityData }: SecurityMarketProps) => {

  return (
    <div css={style.securityOverviewTopRightStart}>
      <div css={style.flexRow}>
        <div css={style.blocTitle}>Trading</div>
        <SecurityMarketTradingDefault securityData={securityData} />

        <SecurityMarketTradingATT  securityData={securityData}/>
      </div>
    </div>
  );
};
