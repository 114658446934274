
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import {PressArticleComponent} from "../../core/components/Resources/ResourcesPress/PressArticle";

export interface PressArticleProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const PressArticle: React.FunctionComponent<PressArticleProps> = (props: PressArticleProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    return (
        <PressArticleComponent />
    );
}
    