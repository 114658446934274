import * as React from "react";
import { css } from "@emotion/react";

export const style = {
  textTag: (selected: boolean) => css`
    background-color:${selected ? '#22AA5F' : '#FFFFFF'};
    border-radius: 46px;
    border: solid 1px ${selected ? '#22AA5F' : '#d9e1e2'};
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 17px;
    padding: 4px 16px;
    margin-top: 8px;
    color: ${selected ? '#FFFFFFFF' : '#425563'};
  `,
  textTagIcon: css`
    color: #22AA5F;
    margin-left: 8px;
    cursor: pointer;
  `,
  textTagText: css`
    display: flex;
    flex-shrink: 0;
  `,
};
