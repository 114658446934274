
/** @jsx jsx */

import * as React from 'react';

import {Button, Icon} from "@bdl-cmn-shared-packages-npm/design-system";
import { ComponentFields, ComponentParams, ComponentRendering, Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from '../../interfaces';
import avt from "../TeamMembers/avatar.png";
import {renderLink} from "../../utils/helper";

export interface ManagementMemberProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
  }
`;
const left = css`
  width: 40%;
  padding: 64px;
  padding-top: 32px;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const right = css`
  width: 60%;
  padding: 64px;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding: 16px;
  }
`;
const col = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  max-width: 340px;
  padding: 32px;
  align-items: center;
  @media (max-width: ${breakpointMax}) {
    min-width: 100%;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 24px;
    margin-right: 0;
    margin-top: 16px;
  }
`;

const avatar = css`
  width: 160px;
  height: 160px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  text-align: center;
  
  img {
    text-align: center;
    width: 100%;
  }
`;

const name = css`
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  text-align: center;
  color: #CB1234;
  margin-top: 22px;
  margin-bottom: 8px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #CB1234;
  }
`;

const role = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #425563;
  }
`;
const row = css`
  margin-bottom: 24px;
  @media (max-width: ${breakpointMax}) {
   
  }
`;
const rightTitle = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  margin-bottom: 24px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  }
`;
const rowTop = css`
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  margin-bottom: 6px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  }
`;
const rowBottom = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  }
`;

export const ManagementMember: React.FunctionComponent<ManagementMemberProps> = (props: ManagementMemberProps): React.ReactElement => {
    const { fields } = props;

    const isFirst: string = getFieldValue<string>(fields, 'is first', '');
    const isLast: string = getFieldValue<string>(fields, 'is last', '');

    const container = css`
      width: 100%;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      border: 1px solid #D9E1E2;
      border-top: ${isLast ? 'none' : 'px solid #D9E1E2'};
      border-bottom: ${isFirst ? 'none' : 'px solid #D9E1E2'};
      margin-left: auto;
      margin-right: auto;
      display: flex;
      @media (max-width: ${breakpointMax}) {
        flex-direction: column;
      }
    `;
    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={left}>
                    <div css={col}>
                        <div css={avatar}>
                            <img src={renderLink((fields && fields[`member image`] as any).value?.src)} alt={"image"} />
                        </div>
                        <div css={name}>
                            <Text
                                field={fields && fields['member fullname'] as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={role}>
                            <Text
                                field={fields && fields['member role'] as SitecoreGenericTextField}
                            />
                        </div>
                    </div>
                </div>
                <div css={right}>
                    <div css={rightTitle}>
                        <Text
                            field={fields && fields['list heading text'] as SitecoreGenericTextField}
                        />
                    </div>
                    {
                        new Array(20).fill(0).map((arr: any, index: number) => {
                                if (fields && fields[`row ${index + 1} title`] && (fields && fields[`row ${index + 1} title`] as any).value) {
                                    return (
                                        <div css={row}>
                                            <div css={rowTop}>
                                                { (fields && fields[`row ${index + 1} title`] as any).value }
                                            </div>
                                            <div css={rowBottom}>
                                                { (fields && fields[`row ${index + 1} value`] as any).value }
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        )
                    }
                </div>
            </div>
        </div>
    );
}
