
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface PeaceJusticeAndStrongInstitutionsSvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const PeaceJusticeAndStrongInstitutionsSvg: React.FunctionComponent<PeaceJusticeAndStrongInstitutionsSvgProps> = ({ size: { width, height }, style }: PeaceJusticeAndStrongInstitutionsSvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 49 50'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>16 - Peace and Justice Strong Institutions</title>
            <path d='M0.615952 3.22981V5.35137H0.634594L2.06263 3.87113H2.08128V13.1329H4.11334V1.03369H2.49142L0.615952 3.22981Z' fill='white'/>
            <path d='M11.2349 10.2244V8.43838C11.2349 6.45852 10.4482 5.45927 8.98658 5.45927C8.12901 5.45927 7.61074 5.85076 7.30873 6.43988H7.29009V3.83361C7.29009 3.10282 7.52126 2.58455 8.25205 2.58455C8.98285 2.58455 9.19911 3.01333 9.19911 3.76277V4.56814H11.0559V3.92683C11.0559 2.14085 10.4668 0.895508 8.2185 0.895508C6.0783 0.895508 5.27293 2.26762 5.27293 4.16173V10.0491C5.27293 11.9209 6.09322 13.2781 8.23714 13.2781C10.396 13.2743 11.2349 12.0961 11.2349 10.2244ZM9.21775 10.3996C9.21775 11.1677 8.93065 11.6114 8.23714 11.6114C7.55854 11.6114 7.29009 11.149 7.29009 10.3996V8.18857C7.32364 7.50997 7.61074 7.08119 8.25205 7.08119C8.94557 7.08119 9.21402 7.50997 9.21402 8.29297V10.3996H9.21775Z' fill='white'/>
            <path d='M15.1499 1.02637H16.3729C17.2043 1.02637 17.5064 1.44024 17.5064 2.12629V3.1591C17.5064 3.84516 17.2043 4.26649 16.3729 4.26649H15.9739V5.92942H15.1499V1.02637ZM15.9739 1.70124V3.63263H16.2797C16.563 3.63263 16.6861 3.49467 16.6861 3.22622V2.09646C16.6861 1.82801 16.563 1.69751 16.2797 1.69751H15.9739V1.70124Z' fill='white'/>
            <path d='M17.9053 1.02637H19.874V1.69751H18.733V3.09945H19.5496V3.76313H18.733V5.25455H19.874V5.9257H17.9053V1.02637Z' fill='white'/>
            <path d='M20.2282 5.92942L21.1529 1.02637H22.0925L23.0246 5.92942H22.1782L22.0254 5.03457H21.1492L21.0037 5.92942H20.2282ZM21.261 4.37461H21.9172L21.5929 2.40966H21.5854L21.261 4.37461Z' fill='white'/>
            <path d='M23.3378 4.66522V2.29385C23.3378 1.52577 23.6995 0.970215 24.5682 0.970215C25.478 0.970215 25.7315 1.4773 25.7315 2.20064V2.77857H24.9784V2.13353C24.9784 1.83151 24.8777 1.65627 24.5794 1.65627C24.2849 1.65627 24.1618 1.86507 24.1618 2.16335V4.79572C24.1618 5.094 24.2849 5.3028 24.5794 5.3028C24.8777 5.3028 24.9784 5.11637 24.9784 4.82555V3.92323H25.7315V4.76962C25.7315 5.47059 25.4407 5.99259 24.5682 5.99259C23.6995 5.98886 23.3378 5.42212 23.3378 4.66522Z' fill='white'/>
            <path d='M26.2163 1.02637H28.185V1.69751H27.044V3.09945H27.8606V3.76313H27.044V5.25455H28.185V5.9257H26.2163V1.02637Z' fill='white'/>
            <path d='M29.5421 5.20596L29.1245 6.31334H28.6622L28.7629 5.14258H29.5421V5.20596Z' fill='white'/>
            <path d='M33.1066 1.02619V4.81441C33.1066 5.52283 32.7972 5.98518 31.9731 5.98518C31.1193 5.98518 30.8881 5.51538 30.8881 4.89271V3.91582H31.6674V4.90762C31.6674 5.15371 31.7532 5.29912 31.9694 5.29912C32.1931 5.29912 32.2789 5.15371 32.2789 4.90016V1.02246H33.1066V1.02619Z' fill='white'/>
            <path d='M36.0373 1.02637V4.73629C36.0373 5.49691 35.7129 5.98908 34.874 5.98908C34.0053 5.98908 33.6436 5.49691 33.6436 4.73629V1.02637H34.4676V4.81458C34.4676 5.10541 34.5832 5.2993 34.874 5.2993C35.1648 5.2993 35.2804 5.10541 35.2804 4.81458V1.02637H36.0373Z' fill='white'/>
            <path d='M36.5369 4.87402V4.21779H37.305V4.88893C37.305 5.16485 37.4281 5.32145 37.7114 5.32145C37.9724 5.32145 38.088 5.1462 38.088 4.88893V4.7025C38.088 4.41913 37.9724 4.24762 37.7189 4.00899L37.2342 3.52428C36.7644 3.06194 36.5481 2.77857 36.5481 2.21555V2.04777C36.5481 1.44001 36.9023 0.970215 37.704 0.970215C38.528 0.970215 38.8524 1.37663 38.8524 2.08505V2.48401H38.0992V2.05895C38.0992 1.77558 37.9687 1.64136 37.7077 1.64136C37.4765 1.64136 37.3162 1.77186 37.3162 2.04777V2.14844C37.3162 2.42435 37.4691 2.58095 37.7077 2.81212L38.2372 3.33412C38.6846 3.78901 38.9008 4.06492 38.9008 4.60556V4.84419C38.9008 5.51533 38.5392 5.99259 37.7002 5.99259C36.8613 5.98886 36.5369 5.51906 36.5369 4.87402Z' fill='white'/>
            <path d='M39.3147 1.02637H41.5071V1.69751H40.8285V5.92942H39.997V1.70124H39.3184V1.02637H39.3147Z' fill='white'/>
            <path d='M41.962 1.02637H42.786V5.92942H41.962V1.02637Z' fill='white'/>
            <path d='M43.3378 4.66522V2.29385C43.3378 1.52577 43.6995 0.970215 44.5683 0.970215C45.478 0.970215 45.7316 1.4773 45.7316 2.20064V2.77857H44.9784V2.13353C44.9784 1.83151 44.8777 1.65627 44.5795 1.65627C44.2849 1.65627 44.1619 1.86507 44.1619 2.16335V4.79572C44.1619 5.094 44.2849 5.3028 44.5795 5.3028C44.8777 5.3028 44.9784 5.11637 44.9784 4.82555V3.92323H45.7316V4.76962C45.7316 5.47059 45.4408 5.99259 44.5683 5.99259C43.6995 5.98886 43.3378 5.42212 43.3378 4.66522Z' fill='white'/>
            <path d='M46.2498 1.02637H48.2185V1.69751H47.0776V3.09945H47.8941V3.76313H47.0776V5.25455H48.2185V5.9257H46.2498V1.02637Z' fill='white'/>
            <path d='M15.0194 13.2781L15.9441 8.375H16.8837L17.8158 13.2781H16.9694L16.8165 12.3832H15.9403L15.7949 13.2781H15.0194ZM16.0522 11.7232H16.7084L16.384 9.7583H16.3766L16.0522 11.7232Z' fill='white'/>
            <path d='M18.9232 10.1983V13.2781H18.2297V8.375H19.0388L19.9262 11.1863V8.375H20.6122V13.2781H19.8889L18.9232 10.1983Z' fill='white'/>
            <path d='M23.6249 9.69118V11.9619C23.6249 12.715 23.3154 13.2781 22.4317 13.2781H21.1603V8.375H22.4317C23.3154 8.375 23.6249 8.93429 23.6249 9.69118ZM22.3311 12.6069C22.6704 12.6069 22.8009 12.4056 22.8009 12.1073V9.53831C22.8009 9.24748 22.6704 9.04614 22.3311 9.04614H21.9843V12.6032H22.3311V12.6069Z' fill='white'/>
            <path d='M24.956 12.2231V11.5669H25.7241V12.2381C25.7241 12.514 25.8471 12.6706 26.1305 12.6706C26.3915 12.6706 26.5071 12.4953 26.5071 12.2381V12.0516C26.5071 11.7683 26.3915 11.5967 26.1379 11.3581L25.6532 10.8734C25.1834 10.4111 24.9672 10.1277 24.9672 9.56467V9.39689C24.9672 8.78913 25.3214 8.31934 26.123 8.31934C26.947 8.31934 27.2714 8.72575 27.2714 9.43417V9.83313H26.5182V9.40807C26.5182 9.1247 26.3877 8.99048 26.1267 8.99048C25.8956 8.99048 25.7352 9.12098 25.7352 9.39689V9.49756C25.7352 9.77347 25.8881 9.93007 26.1267 10.1612L26.6562 10.6832C27.1036 11.1381 27.3199 11.414 27.3199 11.9547V12.1933C27.3199 12.8645 26.9582 13.3417 26.1193 13.3417C25.2841 13.338 24.956 12.8682 24.956 12.2231Z' fill='white'/>
            <path d='M27.6256 8.375H29.818V9.04987H29.1394V13.2818H28.308V9.04987H27.6294V8.375H27.6256Z' fill='white'/>
            <path d='M31.0969 11.0297V13.2781H30.2729V8.375H31.4735C32.305 8.375 32.607 8.78887 32.607 9.47493V10.1051C32.607 10.6345 32.4206 10.944 31.9508 11.0521L32.7375 13.2781H31.8688L31.0969 11.0297ZM31.0969 9.04987V10.5749H31.3729C31.6562 10.5749 31.7793 10.4294 31.7793 10.1684V9.4451C31.7793 9.17664 31.6562 9.04614 31.3729 9.04614H31.0969V9.04987Z' fill='white'/>
            <path d='M33.129 12.0143V9.64297C33.129 8.87489 33.5056 8.31934 34.3743 8.31934C35.2505 8.31934 35.6271 8.87489 35.6271 9.64297V12.0143C35.6271 12.775 35.2505 13.338 34.3743 13.338C33.5056 13.338 33.129 12.775 33.129 12.0143ZM34.7994 12.1448V9.51248C34.7994 9.21419 34.6689 9.00539 34.3743 9.00539C34.0835 9.00539 33.9567 9.21419 33.9567 9.51248V12.1448C33.9567 12.4431 34.0872 12.6519 34.3743 12.6519C34.6726 12.6519 34.7994 12.4394 34.7994 12.1448Z' fill='white'/>
            <path d='M36.8613 10.1983V13.2781H36.1678V8.375H36.9769L37.8642 11.1863V8.375H38.5503V13.2781H37.827L36.8613 10.1983Z' fill='white'/>
            <path d='M40.34 10.6607H41.5108V13.2781H40.918V12.7412C40.8098 13.1104 40.5787 13.3341 40.1312 13.3341C39.4228 13.3341 39.0984 12.7711 39.0984 12.0104V9.63907C39.0984 8.87099 39.4675 8.31543 40.3363 8.31543C41.2461 8.31543 41.5145 8.82252 41.5145 9.54586V9.97837H40.7614V9.47874C40.7614 9.17673 40.6458 9.00148 40.3475 9.00148C40.0492 9.00148 39.9224 9.21028 39.9224 9.50857V12.1409C39.9224 12.4392 40.0455 12.648 40.3288 12.648C40.5973 12.648 40.7353 12.4951 40.7353 12.1782V11.3095H40.3438V10.6607H40.34Z' fill='white'/>
            <path d='M15.1499 15.3999H15.9739V20.303H15.1499V15.3999Z' fill='white'/>
            <path d='M17.2528 17.2232V20.303H16.5593V15.3999H17.3684L18.2558 18.2112V15.3999H18.9418V20.303H18.2222L17.2528 17.2232Z' fill='white'/>
            <path d='M19.4452 19.2475V18.5913H20.2133V19.2625C20.2133 19.5384 20.3363 19.695 20.6197 19.695C20.8807 19.695 20.9963 19.5197 20.9963 19.2625V19.076C20.9963 18.7927 20.8807 18.6212 20.6271 18.3825L20.1424 17.8978C19.6726 17.4355 19.4564 17.1521 19.4564 16.5891V16.4213C19.4564 15.8135 19.8106 15.3438 20.6122 15.3438C21.4362 15.3438 21.7606 15.7502 21.7606 16.4586V16.8575H21.0075V16.4325C21.0075 16.1491 20.877 16.0149 20.616 16.0149C20.3848 16.0149 20.2245 16.1454 20.2245 16.4213V16.522C20.2245 16.7979 20.3773 16.9545 20.616 17.1857L21.1454 17.7077C21.5928 18.1625 21.8091 18.4385 21.8091 18.9791V19.2177C21.8091 19.8889 21.4474 20.3661 20.6085 20.3661C19.7696 20.3624 19.4452 19.8926 19.4452 19.2475Z' fill='white'/>
            <path d='M22.1149 15.3999H24.3072V16.071H23.6286V20.303H22.7972V16.0748H22.1186V15.3999H22.1149Z' fill='white'/>
            <path d='M24.7621 15.3999H25.5861V20.303H24.7621V15.3999Z' fill='white'/>
            <path d='M26.041 15.3999H28.2334V16.071H27.5548V20.303H26.7233V16.0748H26.0447V15.3999H26.041Z' fill='white'/>
            <path d='M31.082 15.3999V19.1098C31.082 19.8704 30.7577 20.3626 29.9187 20.3626C29.05 20.3626 28.6883 19.8704 28.6883 19.1098V15.3999H29.5123V19.1881C29.5123 19.4789 29.6279 19.6728 29.9187 19.6728C30.2096 19.6728 30.3251 19.4789 30.3251 19.1881V15.3999H31.082Z' fill='white'/>
            <path d='M31.5332 15.3999H33.7256V16.071H33.047V20.303H32.2155V16.0748H31.5332V15.3999Z' fill='white'/>
            <path d='M34.1805 15.3999H35.0045V20.303H34.1805V15.3999Z' fill='white'/>
            <path d='M35.5526 19.0388V16.6674C35.5526 15.8993 35.9292 15.3438 36.7979 15.3438C37.6742 15.3438 38.0507 15.8993 38.0507 16.6674V19.0388C38.0507 19.7994 37.6742 20.3624 36.7979 20.3624C35.9292 20.3624 35.5526 19.7957 35.5526 19.0388ZM37.223 19.1693V16.5369C37.223 16.2386 37.0925 16.0298 36.7979 16.0298C36.5071 16.0298 36.3803 16.2386 36.3803 16.5369V19.1693C36.3803 19.4675 36.5108 19.6763 36.7979 19.6763C37.0925 19.6726 37.223 19.4638 37.223 19.1693Z' fill='white'/>
            <path d='M39.2849 17.2232V20.303H38.5914V15.3999H39.4005L40.2879 18.2112V15.3999H40.9739V20.303H40.2506L39.2849 17.2232Z' fill='white'/>
            <path d='M41.4773 19.2475V18.5913H42.2454V19.2625C42.2454 19.5384 42.3684 19.695 42.6518 19.695C42.9128 19.695 43.0284 19.5197 43.0284 19.2625V19.076C43.0284 18.7927 42.9128 18.6212 42.6592 18.3825L42.1745 17.8978C41.7047 17.4355 41.4885 17.1521 41.4885 16.5891V16.4213C41.4885 15.8135 41.8427 15.3438 42.6443 15.3438C43.4683 15.3438 43.7927 15.7502 43.7927 16.4586V16.8575H43.0396V16.4325C43.0396 16.1491 42.9091 16.0149 42.6481 16.0149C42.4169 16.0149 42.2566 16.1454 42.2566 16.4213V16.522C42.2566 16.7979 42.4094 16.9545 42.6481 17.1857L43.1775 17.7077C43.6249 18.1625 43.8412 18.4385 43.8412 18.9791V19.2177C43.8412 19.8889 43.4795 20.3661 42.6406 20.3661C41.8017 20.3624 41.4773 19.8926 41.4773 19.2475Z' fill='white'/>
            <path d='M34.7696 25.5044C34.7696 25.5044 34.2662 26.1755 34.4116 26.828C34.5608 27.4805 35.3028 27.872 35.3028 27.872C35.3028 27.872 35.8061 27.2009 35.6607 26.5484C35.5153 25.8922 34.7696 25.5044 34.7696 25.5044Z' fill='white'/>
            <path d='M33.9493 29.3336C33.9493 29.3336 34.4527 28.6625 34.3072 28.01C34.1581 27.3575 33.4161 26.9697 33.4161 26.9697C33.4161 26.9697 32.909 27.6409 33.0582 28.2934C33.2036 28.9421 33.9493 29.3336 33.9493 29.3336Z' fill='white'/>
            <path d='M36.0224 28.6138C36.0224 28.6138 36.5556 29.1507 37.1633 29.0985C37.7711 29.0463 38.2222 28.4199 38.2222 28.4199C38.2222 28.4199 37.6891 27.883 37.0813 27.9352C36.4735 27.9911 36.0224 28.6138 36.0224 28.6138Z' fill='white'/>
            <path d='M36.8949 25.743C36.4027 26.2426 36.4698 27.13 36.4698 27.13C36.4698 27.13 37.3423 27.2046 37.8345 26.7049C38.3266 26.2053 38.2595 25.3179 38.2595 25.3179C38.2595 25.3179 37.3908 25.2433 36.8949 25.743Z' fill='white'/>
            <path d='M35.8061 30.6088C36.4102 30.6796 36.9806 30.1614 36.9806 30.1614C36.9806 30.1614 36.5668 29.5275 35.9627 29.4529C35.3587 29.3821 34.7882 29.9004 34.7882 29.9004C34.7882 29.9004 35.2021 30.538 35.8061 30.6088Z' fill='white'/>
            <path d='M36.3468 41.1196H31.824C31.4698 41.1196 31.1827 41.4067 31.1827 41.7572C31.1827 42.1114 31.4698 42.3985 31.824 42.3985H36.3468C36.701 42.3985 36.9881 42.1114 36.9881 41.7572C36.9881 41.403 36.701 41.1196 36.3468 41.1196Z' fill='white'/>
            <path d='M36.3468 48.1704H31.824C31.4698 48.1704 31.1827 48.4575 31.1827 48.8117C31.1827 49.1659 31.4698 49.453 31.824 49.453H36.3468C36.701 49.453 36.9881 49.1659 36.9881 48.8117C36.9881 48.4575 36.701 48.1704 36.3468 48.1704Z' fill='white'/>
            <path d='M16.0075 39.5649C15.8993 39.5314 15.7949 39.5016 15.6943 39.4829C15.6943 39.4866 15.8098 39.5202 16.0075 39.5649Z' fill='white'/>
            <path d='M36.179 42.8459H31.9881C31.7867 42.8459 31.6227 43.01 31.6227 43.2113V44.479L28.8859 44.781L24.8889 44.6878V42.2456C25.4184 42.1785 25.9515 42.0704 26.4773 41.9212C30.3251 40.8362 31.1641 37.6557 31.0783 35.4559C30.9963 33.215 31.7122 32.3463 32.7413 31.9585C33.0358 31.8467 33.3266 31.7758 33.5951 31.7311C33.6063 32.2829 33.7181 32.9205 33.9642 33.6848C34.0574 33.6811 34.1469 33.6736 34.2364 33.6625C33.9754 32.8832 33.8673 32.2419 33.8598 31.6938C34.393 31.6304 34.7733 31.6677 34.7733 31.6677C34.7733 31.6677 34.4116 31.4738 33.9046 31.0487C34.0761 29.9861 34.7099 29.3373 35.3624 28.6699C35.7204 28.3045 36.0895 27.9242 36.3803 27.4731L36.1529 27.3276C35.877 27.7564 35.5153 28.1256 35.1685 28.4835C34.5347 29.1323 33.8934 29.7959 33.6734 30.8511C33.4683 30.6647 33.2446 30.4447 33.0134 30.1874C32.0664 29.1248 30.8434 29.9041 29.1022 31.526C27.3609 33.1479 25.1685 33.2038 23.0283 31.0115C20.2058 28.1218 17.7413 28.1367 16.0522 28.3008C17.2081 28.793 17.6108 29.8705 18.1961 32.6147C18.8076 35.4932 20.355 36.7945 22.4691 36.9772C21.2946 37.391 21.2349 38.0547 19.6577 39.1434C18.4273 39.9936 16.6973 39.7251 15.9963 39.5648C17.2826 39.9675 18.8113 41.3172 20.176 41.787C20.9702 42.0592 21.8091 42.2307 22.6667 42.2978V44.6356L15.519 44.4716V46.1009L28.8896 45.7952L31.6264 46.0972V47.3649C31.6264 47.5663 31.7905 47.7303 31.9918 47.7303H36.1827C36.3841 47.7303 36.5481 47.5663 36.5481 47.3649V43.2076C36.5444 43.01 36.3803 42.8459 36.179 42.8459ZM23.3378 42.3351C23.6361 42.3388 23.9344 42.3314 24.2327 42.3127V44.6766L23.3378 44.658V42.3351Z' fill='white'/>
            <path d='M15.1275 45.8327C15.1275 46.1757 14.8889 46.4553 14.5906 46.4553H14.2699C13.9754 46.4553 13.733 46.1757 13.733 45.8327V44.7067C13.733 44.3636 13.9754 44.084 14.2699 44.084H14.5906C14.8852 44.084 15.1275 44.3636 15.1275 44.7067V45.8327Z' fill='white'/>

        </svg>
    );
};
