import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { ColumnGraph } from '@bdl-cmn-shared-packages-npm/design-system';
import ReactApexChart from 'react-apexcharts';

export interface ColumnChartProps {
    data: any[];
    labelField: string;
    serieField: string;
    labelValueFunction?: (value: string) => string;
    type?: string;
    source?: string;
}

export const ColumnChart = (
    {
        data,
        labelField,
        serieField,
        labelValueFunction,
        type,
        source,

    }: ColumnChartProps) => {


    const [series, setSeries] = useState<number[]>([]);
    const [trades, setTrades] = useState<number[]>([]);
    const [maxNumber, setMaxNumber] = useState<number>(0);
    const [minNumber, setMinNumber] = useState<number>(0);
    const [labels, setLabels] = useState<string[]>([]);
    const [ready, setReady] = useState<boolean>(false);

    let options;

    useEffect(() => {
        if (data) {
            let labelsTmp: any[] = [];
            let seriesTmp: number[] = [];
            let tradesTmp: number[] = [];


            if (source === "top traded securities"){

                let tmp:any[]=[];
                Object.keys(data).forEach((key: any) => {
                    
                    if(data[key].name.trim().length>20){
                        data[key].name.split(" ",3).map((item:any, index:any) => {
                            if(item.length>1){
                                tmp.push(item);
                            }
                        });
                        labelsTmp.push(tmp);
                        tmp=[];
                    }else{
                        labelsTmp.push(data[key].name);
                    }
                    
                    //labelsTmp.push([data[key].name,data[key].isinCode]);
                    if(data[key].turnover!==null)
                        seriesTmp.push(data[key].turnover?.toFixed(2));
                    tradesTmp.push(data[key].numberOfTransaction);
                });

                setMaxNumber(Math.max(...seriesTmp));
                setMinNumber(Math.min(...seriesTmp));

                setSeries(seriesTmp);
                setLabels(labelsTmp);
                setTrades(tradesTmp);
                setReady(true);
            }else{

                if(type==="MTD"){
                    data.sort((a, b) => b.mtdVariation - a.mtdVariation);
                    data?.forEach((elem: any) => {
                        labelsTmp.push(elem.description);
                        seriesTmp.push(Number(Number(elem.mtdVariation)?.toFixed(2)));
                    });
                }else{
                    data.sort((a, b) => b.ytdVariation - a.ytdVariation);
                    data?.forEach((elem: any) => {
                        labelsTmp.push(elem.description);
                        seriesTmp.push(Number(Number(elem.ytdVariation)?.toFixed(2)));
                    });
                }

                setMaxNumber(Math.max(...seriesTmp));
                setMinNumber(Math.min(...seriesTmp));

                setSeries(seriesTmp);


                setLabels(labelsTmp);

                setReady(true);
                }
        }
    }, data);


    if (source === "top traded securities") {
        options = {
            series: [{
                name: type +" (EUR)",
                type: 'column',
                data: series,
            }, {
                name: 'Trades',
                type: 'line',
                data: trades,
            }],
            chart: {
                type: 'line',
                toolbar: {
                    show: false,
                },
            },
            dataLabels:{
                enabled:true,
                enabledOnSeries:[1]
            },
            xaxis: {
                categories: labels,
                labels:{
                    rotate: -45
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        enabled: true,
                        position: 'bottom',
                        //orientation: 'vertical',
                        columnWidth: '8%',
                        offsetY: 0,
                        style: {
                            fontSize: '12px',
                            fontFamily: 'Mont',
                            fontWeight: 700,
                            colors:['#425563'],
                            lineHeight: '150px',
                        },
                    }
                },
                line:{
                    horizontal: false,
                    dataLabls:{
                        enabled: true,
                        position: 'left',
                    }
                }
            },
            stroke: {
                width: [0, 5],
            },
            //labels: labels,
            yaxis: [
                {
                    title: {
                        text: 'Turnover (EUR)',
                        style: {
                            fontFamily: 'Mont',
                            fontWeight: 700,
                            fontSize: '12px',
                        }
                    }
                },
                {
                    opposite: true,
                    title: {
                        text: "Trades",
                        style: {
                            fontFamily: 'Mont',
                            fontWeight: 700,
                            fontSize: '12px',
                        }
                    },
                }
            ],
            colors: ['#22AA5F', '#425563'],
        };
    } else {
        options = {
            series: [
                {
                    name: type,
                    data: series,
                    //data: [10.43,9.84,-8.67,-13,-16]
                }
            ],
            title: {
                text: 'High ' +Number(maxNumber).toLocaleString('en-US').replace(/,/g, ' ') + ' - Low ' + Number(minNumber).toLocaleString('en-US').replace(/,/g, ' '),
                align: 'Top',
                style: {
                    fontFamily: 'Mont',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '18px'
                }
            },
            xaxis: {
                categories: labels,
                //decimalsInFloat: 0,
                
            },
            dataLabels:{
                enabled:true,
            },
            yaxis: [
                {
                    title: {
                        text: 'Variations',
                        style: {
                            fontFamily: 'Mont',
                            fontWeight: 700,
                            fontSize: '12px',
                        }
                    },
                },
                
            ],
            colors: [
                function ({ value, seriesIndex, w }: any) {
                    if(value===0){
                        return '#FF0000';
                    }
                    else if (value < 0) {
                        return '#CB1234'
                    } else {
                        return '#22AA5F'
                    }
                }
            ],
            chart: {
                toolbar: {
                    show: true,
                },
            },
            stroke: {
                width: 5,
                curve: 'straight',
            },
        };

    }




    return (
        ready ?
            <>
                <ReactApexChart
                    options={options}
                    series={options.series}
                    height="90%"
                    width="100%"
                    type={source === "top traded securities" ? 'line' : 'bar'}
                />
            </>
            : <></>
    );
};

