
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const URY: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M503.172,88.276H8.828C3.953,88.276,0,92.228,0,97.103v317.793c0,4.875,3.953,8.828,8.828,8.828 h494.345c4.875,0,8.828-3.953,8.828-8.828V97.103C512,92.228,508.047,88.276,503.172,88.276z" />
            <rect x="185.38" y="125.55" fill="#0038A8" width="326.62" height="37.27" />
            <rect y="162.82" fill="#FFFFFF" width="512" height="37.27" />
            <rect x="185.38" y="200.09" fill="#0038A8" width="326.62" height="37.27" />
            <rect y="237.36" fill="#FFFFFF" width="512" height="37.27" />
            <rect y="274.641" fill="#0038A8" width="512" height="37.27" />
            <rect y="311.91" fill="#FFFFFF" width="512" height="37.27" />
            <rect y="349.18" fill="#0038A8" width="512" height="37.27" />
            <polygon fill="#FCD116" points="63.976,111.642 78.743,179.975 101.85,170.403 " />
            <polygon fill="#7B3F00" points="63.976,111.642 90.296,175.189 78.743,179.975 " />
            <polygon fill="#FCD116" points="121.404,250.289 106.636,181.956 83.529,191.528 " />
            <polygon fill="#7B3F00" points="121.404,250.289 95.083,186.742 106.636,181.956 " />
            <polygon fill="#FCD116" points="162.013,152.252 93.681,167.019 103.252,190.126 " />
            <polygon fill="#7B3F00" points="162.013,152.252 98.466,178.572 93.681,167.019 " />
            <polygon fill="#FCD116" points="23.367,209.679 91.699,194.912 82.127,171.805 " />
            <polygon fill="#7B3F00" points="23.367,209.679 86.913,183.359 91.699,194.912 " />
            <polygon fill="#FCD116" points="121.404,111.642 83.529,170.403 106.636,179.975 " />
            <polygon fill="#7B3F00" points="121.404,111.642 95.083,175.189 83.529,170.403 " />
            <polygon fill="#FCD116" points="63.976,250.289 101.85,191.528 78.743,181.956 " />
            <polygon fill="#7B3F00" points="63.976,250.289 90.296,186.742 101.85,191.528 " />
            <polygon fill="#FCD116" points="162.013,209.679 103.252,171.805 93.681,194.912 " />
            <polygon fill="#7B3F00" points="162.013,209.679 98.466,183.359 103.252,171.805 " />
            <polygon fill="#FCD116" points="23.367,152.252 82.127,190.126 91.699,167.019 " />
            <polygon fill="#7B3F00" points="23.367,152.252 86.913,178.572 82.127,190.126 " />
            <polygon fill="#FCD116" points="92.69,105.931 80.184,174.712 105.195,174.712 " />
            <polygon fill="#7B3F00" points="92.69,105.931 92.69,174.712 80.184,174.712 " />
            <polygon fill="#FCD116" points="92.69,256 105.195,187.219 80.184,187.219 " />
            <polygon fill="#7B3F00" points="92.69,256 92.69,187.219 105.195,187.219 " />
            <polygon fill="#FCD116" points="167.724,180.966 98.943,168.46 98.943,193.471 " />
            <polygon fill="#7B3F00" points="167.724,180.966 98.943,180.966 98.943,168.46 " />
            <polygon fill="#FCD116" points="17.655,180.966 86.436,193.471 86.436,168.46 " />
            <polygon fill="#7B3F00" points="17.655,180.966 86.436,180.966 86.436,193.471 " />
            <polygon fill="#FCD116" points="145.747,127.908 88.268,167.701 105.954,185.387 " />
            <polygon fill="#7B3F00" points="145.747,127.908 97.111,176.544 88.268,167.701 " />
            <polygon fill="#FCD116" points="39.633,234.023 97.111,194.23 79.425,176.544 " />
            <polygon fill="#7B3F00" points="39.633,234.023 88.268,185.387 97.111,194.23 " />
            <polygon fill="#FCD116" points="145.747,234.023 105.954,176.544 88.268,194.23 " />
            <polygon fill="#7B3F00" points="145.747,234.023 97.111,185.387 105.954,176.544 " />
            <polygon fill="#FCD116" points="39.633,127.908 79.425,185.387 97.111,167.701 " />
            <polygon fill="#7B3F00" points="39.633,127.908 88.268,176.544 79.425,185.387 " />
            <circle fill="#FCD116" cx="92.69" cy="180.97" r="25.01" />
        </svg>
    </React.Fragment>
);
