/** @jsx jsx */

import * as React from 'react';
import { css, jsx } from "@emotion/react";
import { ResponsiveChoropleth, ChoroplethBoundFeature, ChoroplethEventHandler } from '@nivo/geo';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpointMax } from '../../../style';

const geoMapContainer = css`
    width: 100%;
    position: relative;
    height: 500px;

    @media (max-width: ${breakpointMax}) {
        height: 600px;
    }

    @media (max-width: 480px) {
        height: 250px;
    }

    @media (max-width: 320px) {
        height: 200px;
    }
`;
const graphLabel = css`
    color: #008C3D;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2px;
`;
const graphDetails = css`
    color: #657F93;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
`;

const tooltipStyle = css`
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 8px;
    border: solid 1px rgba(0, 0, 0, .25);
    display: flex;
    flex-direction: column;
`;

const detailsStyle = css`
    color: #657F93;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
`;

export const GeoMapDataComponent = ({ data, isRed }: { data: any; isRed: boolean }) => {
    const worldcountries = require('./world-countries.json');
    const parsedData = JSON.parse(data);
    const [projectionScale, setProjectionScale] = React.useState(200);

    React.useEffect(() => {
        const updateScale = () => {
            if (window.innerWidth < 330) {
              setProjectionScale(40);
            } else if (window.innerWidth < 480) {
              setProjectionScale(50);
            } else if (window.innerWidth < 768) {
              setProjectionScale(120);
            } else {
              setProjectionScale(100);
            }
          };
        window.addEventListener('resize', updateScale);
        updateScale();
        return () => window.removeEventListener('resize', updateScale);
    }, []);

    const countryNameMap = parsedData.reduce((acc: { [key: string]: { name: string; details: any; url: string } }, country: any) => {
        acc[country.id] = {
            name: country.name,
            details: country.details,
            url: country.url
          };
        return acc;
    }, {});

    const choroplethData = parsedData.map((country: any) => ({
        id: country.id,
        value: country.details,
        name: countryNameMap[country.id]?.name || 'Not Found',
    }));

    const getCountryDetails = (id: string) => {
        const details = countryNameMap[id]?.details || {};
        const detailElements = []; 
        const url = countryNameMap[id]?.url?.url;

        for (const [key, value] of Object.entries(details)) {
            detailElements.push(<div key={key}>{value}</div>);
          }
      
        return (
            <div css={detailsStyle}>
            {detailElements}
          </div>
          );
      };

    const handleCountryClick = (feature: ChoroplethBoundFeature & { id: string; value: number; }) => {
        const countryId = feature.id;
        const url = countryNameMap[countryId]?.url?.url;
        if (url) {
            const fullUrl = url.startsWith('http') ? url : `https://${url}`;
            window.location.href = fullUrl;
        } else {
            console.log(feature); 
        }
    };

    return (
        <section css={geoMapContainer}>
            {
                data && !!data?.length ? (
                    <ResponsiveChoropleth
                        data={choroplethData}
                        domain={[0, 99999]}
                        projectionType='mercator'
                        features={worldcountries.features}
                        projectionScale={projectionScale}
                        projectionTranslation={[0.5, 0.5]}
                        projectionRotation={[ 0, 0, 0 ]}
                        margin={{ top: 40, right: 20, bottom: 0, left: 0 }}
                        colors= {[isRed ? "#CA1234" : "#008C3D"]}	
                        unknownColor='#a9c3cb'
                        tooltip={({ feature }: { feature: ChoroplethBoundFeature & { id: string; value: number; } }): React.ReactElement => (
                            <div css={tooltipStyle}>
                                <div css={graphLabel}>{countryNameMap[feature.id]?.name}</div>
                                <div css={graphDetails}>{getCountryDetails(feature.id)}</div>
                            </div>
                        )}
                        onClick={handleCountryClick}
                    />
                ) : (
                    <p>Empty map data</p>
                )
            }
        </section>
    );
};