
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import {ResourcesForms} from "../../core/components/Resources/ResourcesForms";

export interface FormsRessourcesProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}
export const FormsRessources: React.FunctionComponent<FormsRessourcesProps> = (props: FormsRessourcesProps): React.ReactElement => {
    const { fields } = props;

    const title: string = getFieldValue<string>(fields, 'title', '');
    const description: string = getFieldValue<string>(fields, 'description', '');
    const isH1: boolean = getFieldValue<boolean>(fields, 'is H1', false);

    return (
        <ResourcesForms title={title} description={description} isH1={isH1} />
    );
}
    