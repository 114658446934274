/** @jsx jsx */
import {jsx} from "@emotion/react";

import React from "react";
import {style} from "../../../Search/results/filters/stats/index.style";
import {formatToThousandsSeparator} from "../../../../../utils";
import ConceptualBanner from "../../../ConceptualBanner/conceptualBanner.component";
import { Tag } from '@bdl-cmn-shared-packages-npm/design-system';
import {TYPE_DATE, TYPE_NEWS} from "..";


export function MarketNewsTagFilter(props: any) {
    const filterType =props.filterType;
    const filterDate =props.filterDate;

    return (
        <ConceptualBanner
            reduceOnScroll={false}
            renderLeft={
                <div css={style.resultsWrapper}>
                    <div css={style.header}>
                        <span css={style.emphasizedText}>
                            {props?.nbResults ? formatToThousandsSeparator(props?.nbResults) : 0}
                        </span>
                        &nbsp;result{props?.nbResults > 1 && "s"} in&nbsp; <span css={style.emphasizedText}><h1>{props.label}</h1></span>
                    </div>

                    <div css={style.tagsWrapper}>
                        {filterType ? (<Tag label={filterType}  onClose={() => {props.handelClick(TYPE_NEWS)}}/>): ''}
                        {filterDate ? (<Tag label={filterDate}  onClose={() => {props.handelClick(TYPE_DATE)}}/>): ''}
                    </div>
                </div>
            }
            renderRight={() => null}>

        </ConceptualBanner>
    );
}
