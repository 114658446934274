import { css } from "@emotion/react";
import { breakpointMax } from "../../style";

export const card = css`
    background: #ffffff;
    border: solid 1px #e3e6e8;
    border-radius: 8px;
    height: 100%;
    box-shadow: 0 8px 16px rgb(164 188 194 / 31%);
`;

export const cardContainer = css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 32px 32px 0;
    @media (max-width: ${breakpointMax}) {
        padding: 8px 8px 0;
    }
`;

export const cellStyle = css`
    height: auto !important;
    border-bottom: none !important;
    padding: 0px !important;
    color: #354450;
`;

export const cellOverflow = css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    height: 30px;
    -webkit-box-orient: vertical;
    color: #253845;

    @media (max-width: ${breakpointMax}) {
      -webkit-line-clamp: 3;
      height: 42px;
    }
`;