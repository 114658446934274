import * as React from 'react';

import { Button, Icon, InputField } from '@bdl-cmn-shared-packages-npm/design-system';
import {
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { SitecoreGenericTextField } from '../../interfaces';
import { Suggestion } from '../../core/components/Search/input/suggestion';
import { canUseDOM } from '../../predicates';
import hexa from './fullhexa.svg';
import hexas from './hexas.svg';
import { renderLink } from '../../utils/helper';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';

/** @jsx jsx */

export interface HomeTopComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
  history: any;
}

const style = {
  topContainer: css`
    width: 100%;
    margin: auto;
    margin-top: 96px;
    background: url(${renderLink(hexas)});
    background-repeat: no-repeat;
    background-position: left;
    @media (max-width: ${breakpointMax}) {
      padding: 16px;
      margin-top: 60px;
      margin-bottom: 48px;
    }
  `,
  container: css`
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin: auto;
    display: flex;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  left: css`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  leftContent: css`
    max-width: 500px;
  `,
  right: css`
    display: flex;
    flex-direction: column;
    width: 30%;
  `,
  rightHexa: css`
    background: url(${renderLink(hexa)});
    background-repeat: no-repeat;
    background-size: 800px;
    position: absolute;
    height: 100%;
    width: -webkit-fill-available;
    z-index: -1;
    margin-top: 64px;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  redTitle: css`
    font-weight: 600;
    color: #ca1234;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0.5em;
    @media (max-width: ${breakpointMax}) {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    
    h1 {
      font-weight: 600;
      color: #ca1234;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 0.5em;
      @media (max-width: ${breakpointMax}) {
        font-size: 32px;
        font-weight: 500;
        //font-size: 16px;
        line-height: 20px;
      }
    }
  `,
  title: css`
    font-size: 48px;
    line-height: 62px;
    font-weight: 800;
    color: rgb(66, 85, 99);
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
    }
  `,
  input: (timer: boolean) => css`
    opacity: ${timer ? 1 : 0};
    transition: opacity .3s ease-in-out;
    margin-top: 2em;
    display: flex;
    background-color: #e8ecee;
    border-radius: 8px;
    padding: 16px;

    label {
      font-family: 'Mont', sans serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    }

    .MuiInputBase-input {
      border: none !important;
      color: inherit;
      background: #ffffff;
      border-radius: 8px;
    }

    button {
      margin-left: 1em;
    }

    @media (max-width: ${breakpointMax}) {
      flex-direction: column;

      > div > div > div > div {
        &:last-of-type {
          background: #1acb69;
          color: #ffffff;
          position: relative;
          margin-left: -6px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          width: 54px;
        }
      }

      button {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  `,
  inputSearch: css`
    position: relative;
  `,
  suggestion: css`
    position: absolute;
    width: 100%;
    top: 30px;
  `,
  suggestionMobile: css`
    position: absolute;
    width: 100%;
    top: 30px;
  `,
  advanced: css`
    margin-top: 2em;
    margin-bottom: 120px;
    font-size: 1em;
    color: #22AA5F;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      color: #0D8E46;
    }

    span {
      font-weight: 600;
      margin-left: 4px;
      text-decoration: underline;
    }
  `,
  hideMobile: css`
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
    width: 115%;
  `,
  hideDesktop: css`
    @media (min-width: ${breakpoint}) {
      display: none;
    }
    width: 100%;
  `,
  mobileBtn: css`
    margin-top: 16px;
    margin-bottom: 8px;

    width: 100%;

    button {
      width: 100%;
    }
  `,
  results: css`
    position: fixed;
    height: 100%;
    left: 0;
    right: 0;
    margin-top: 10px;
    background: #ffffff;
    border-top: solid 1px rgba(0, 0, 0, 0.2);
    z-index: 3;
    overflow: auto;
  `,
  resultsCards: css`
    height: 100%;
    overflow: auto;
    margin-bottom: 80px;
  `,
  resultsBtn: css`
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    left: 0;

    button {
      width: 100%;
    }
  `,
};

export const HomeTopComponent: React.FunctionComponent<any> = (props: HomeTopComponentProps): React.ReactElement => {
  const { fields, params, rendering, history } = props;
  const isOamPage: boolean = canUseDOM && window?.location?.pathname?.split('/')?.pop() == 'oam-search' ? true : false;
  const isH1: boolean = getFieldValue<boolean>(fields, 'is H1', false);
  const [visibilityTimer, setVisibilityTimer] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [suggestionOpen, setSuggestionOpen] = React.useState<boolean>(false);

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (!isOamPage && event.target.value.length >= 3) {
      setSuggestionOpen(true);
    } else {
      setSuggestionOpen(false);
    }
  };

  const redirectToSearchEngine = () => {
    if (canUseDOM) {
      if (isOamPage) {
        window.location.href = `/oam-search-details?q=${search}`;
      } else {
        window.location.href = `/search?q=${search}`;
      }
    }
  };

  React.useState(() => {
    setTimeout(() => {
      setVisibilityTimer(true);
    });
  });

  return (
    <div css={style.topContainer}>
      <div css={style.container}>
        <div css={style.left}>
          <div css={style.leftContent}>
            <div css={style.redTitle}>
              <Text tag={isH1 ? 'h1' : 'div'} field={fields && (fields.redTitle as SitecoreGenericTextField)} />
            </div>
            <div css={style.title}>
              <Text field={fields && (fields.title as SitecoreGenericTextField)} />
            </div>
            <div css={[style.hideDesktop, { position: 'relative' }]}>
              <div css={style.input(visibilityTimer)}>
                <InputField
                  helperText=''
                  placeholder={isOamPage ? 'Search oam by issuer, ...' : 'Search by ISIN, issuer, ...'}
                  textError=''
                  type='text'
                  width='100%'
                  onChange={inputChangeHandler}
                  onKeyPress={(ev: any) => {
                    if (ev.key === 'Enter' && search.length > 0) {
                      redirectToSearchEngine();
                    }
                  }}
                  onClickTrailingIcon={redirectToSearchEngine}
                  trailingIcon={'search'}
                />
              </div>
              <div css={style.suggestionMobile}>
                <Suggestion open={suggestionOpen} onClose={() => setSuggestionOpen(false)} textInput={search} />
              </div>
              <div css={style.advanced} onClick={redirectToSearchEngine}>
                <Icon icon={'search'} /> <span>Advanced search</span>
              </div>
            </div>
            <div css={[style.hideMobile, style.inputSearch]}>
              <div css={style.input(visibilityTimer)}>
                <InputField
                  helperText=''
                  placeholder={isOamPage ? 'Search oam by issuer, ...' : 'Search by ISIN, issuer, ...'}
                  textError=''
                  type='text'
                  width='100%'
                  onChange={inputChangeHandler}
                  onKeyPress={(ev: any) => {
                    if (ev.key === 'Enter' && search.length > 0) {
                      redirectToSearchEngine();
                    }
                  }}
                />
                <Button
                  css={{ 'align-items': 'center' }}
                  iconLeft='search'
                  label='Search'
                  onClick={redirectToSearchEngine}
                  variant='primary'
                />
              </div>
              <div css={style.advanced} onClick={redirectToSearchEngine}>
                <Icon icon={'search'} /> <span>Advanced search</span>
              </div>
              <div css={style.suggestion}>
                <Suggestion open={suggestionOpen} onClose={() => setSuggestionOpen(false)} textInput={search} />
              </div>
            </div>
          </div>
        </div>
        <div css={style.right}>
          <div css={style.rightHexa} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(HomeTopComponent);
