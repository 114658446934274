/*
*   e-Listing - core - components - country flag icon - aut
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const AUT: JSX.Element = (
    <React.Fragment>
        <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
            <rect y='85.337' style={{fill: '#F0F0F0'}} width='512' height='341.326'/>
            <g>
                <rect y='85.337' style={{fill: '#D80027'}} width='512' height='113.775'/>
                <rect y='312.888' style={{fill: '#D80027'}} width='512' height='113.775'/>
            </g>
        </svg>
    </React.Fragment>
)
