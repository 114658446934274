
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const QAT: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M238.345,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h229.517V423.725z" />
            <path fill="#8A1538" d="M503.172,88.277h-335.8c0.233,0,0.465,0.034,0.689,0.104l53.386,16.312 c2.223,0.679,2.222,3.828-0.002,4.505l-46.335,14.108c-2.225,0.678-2.226,3.826-0.002,4.505l46.34,14.162 c2.223,0.679,2.222,3.828-0.002,4.504l-46.336,14.109c-2.225,0.678-2.226,3.826-0.002,4.505l46.34,14.162 c2.223,0.679,2.222,3.828-0.002,4.504l-46.322,14.103c-2.226,0.679-2.226,3.829,0,4.506l46.322,14.103 c2.225,0.678,2.226,3.826,0.002,4.505l-46.34,14.162c-2.223,0.68-2.222,3.829,0.002,4.505l46.336,14.108 c2.225,0.676,2.226,3.826,0.002,4.505l-46.34,14.162c-2.223,0.68-2.222,3.828,0.002,4.505l46.336,14.108 c2.225,0.676,2.226,3.826,0.002,4.504l-46.34,14.162c-2.223,0.68-2.222,3.829,0.002,4.505l46.335,14.109 c2.225,0.676,2.226,3.826,0.002,4.504l-46.339,14.161c-2.223,0.68-2.222,3.828,0.002,4.505l46.322,14.105 c2.226,0.676,2.226,3.828,0,4.504l-46.322,14.105c-2.225,0.676-2.226,3.826-0.002,4.504l46.34,14.162 c2.223,0.679,2.222,3.828-0.002,4.504l-53.386,16.255c-0.222,0.068-0.454,0.103-0.686,0.103h335.799 c4.875,0,8.828-3.953,8.828-8.828V97.104C512,92.229,508.047,88.277,503.172,88.277z" />
        </svg>
    </React.Fragment>
);
