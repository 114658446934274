export enum IndexDescriptionEnum {
	INDICE_43="The Lux General Index is a weighted capitalisation index which comprises all the listed Luxembourg shares. It is a weighted index reflecting the moves in the prices of the constituent shares.",
	INDICE_45="The LuxX index is a basket index with a twofold computation, ie the Price LuxX index and the Return LuxX index. The Price index is published as of 4 January 1999. The Return LuxX index is similar to the Price index except that it takes into account the stripped net dividends. In so doing, it enables the investors to assess their return on investment. The Return index has been published since 31 March 1999.",
	INDICE_46="The LuxX index is a basket index with a twofold computation, ie the Price LuxX index and the Return LuxX index. The Price index is published as of 4 January 1999. The Return LuxX index is similar to the Price index except that it takes into account the stripped net dividends. In so doing, it enables the investors to assess their return on investment. The Return index has been published since 31 March 1999.",
	INDICE_62="The Lux GDRs India and the Lux GDRs Taiwan are weighted capitalisation indices comprised of all the GDRs of the respective country that are listed on the Luxembourg Stock Exchange. These indices reflect movements in the prices of the constituent securities. Except for the distinction by country, the index entry requirements for the index constituents are identical.",
	INDICE_63="The Lux GDRs India and the Lux GDRs Taiwan are weighted capitalisation indices comprised of all the GDRs of the respective country that are listed on the Luxembourg Stock Exchange. These indices reflect movements in the prices of the constituent securities. Except for the distinction by country, the index entry requirements for the index constituents are identical.",
	INDICE_67="The Lux RI Fund Index is a basket index representing Luxembourg-domiciled funds operating in the area of responsible finance.",
	INDICE_1000000="A composite total return index made up of the CUFE-CNI High grade Unlabelled Green Bond Index and CUFE-CNI High grade Labelled Green Bond Index.",
	INDICE_1000001="A total return index representing unlabelled green securities listed on the inter-bank and stock markets in Shenzhen and Shanghai since 2007.",
	INDICE_1000002="A total return index representing labelled green securities listed on the inter-bank and stock markets of Shenzhen and Shanghai since 2016.",
	INDICE_1000003="Use of proceeds of bonds shall be in line with all four of the previously mentioned green bond standards (PBOC Green Bond Endorsed Project Catalogue (2015), NDRC Green Bond Guidelines, ICMA Green Bond Principles (2015), CBI Climate Bonds Standard). Meanwhile, at least 50% of the proceeds should be used in green projects. In the case of insufficient information of uses of proceeds, the industries in which the issuers operate, main business scope, major products and revenues from main business shall be considered as references for the purpose of identification. If an issuer's main business is in a green industry, and revenues from its main business account for at least 50% of its total revenues, the bond shall be included in this index.",
	INDICE_1000004="Use of proceeds of the bonds shall be in line with at least one of the previously mentioned four green bond standards (PBOC Green Bond Endorsed Project Catalogue (2015), NDRC Green Bond Guidelines, ICMA Green Bond Principles (2015), CBI Climate Bonds Standard). Meanwhile, at least 50% of the proceeds should be used in green projects. In the case of insufficient information of uses of proceeds, the industries in which the issuers operate, main business scope, major products and revenues from main business shall be considered as references for the purpose of identification. If an issuer's main business is in a green industry, and revenues from its main business account for at least 50% of its total revenues, the bond shall be included in this index.",
	INDICE_1000005="Use of proceeds should be invested in climate-aligned green projects, or, the investment in climate-aligned green projects should account for 100% of the proceeds. In the case not all proceeds are invested in projects and proceeds reused to repay loan or make up for the operating capital, the main operations, major products and income from main operations shall be the reference. If an issuer's income from main operations in green industries and climate-aligned industries accounts for at least 95%, the bond is identified as a climate-aligned bond. If part of the fund is used to repay loan or make up for the operating capital and the rest is invested in projects, and the issuer's income from main operations in green industries and climate-aligned industries accounts for at least 95% and investment in projects are in green industries and climate-aligned industries, the bond shall be identified as a constituent.",
	INDICE_950117="",
	INDICE_950118="",
	INDICE_1000007="The Green ESG Bond Issuance Index is based on the ESG evaluation system self-developed by the International Institute of Green Finance, CUFE, the six categories of green industries specified in the \"Green Bond Endorsed Projects Catalogue (2021 Edition)\" issued by the Green Finance Committee of China Society of Finance and Banking and the 5 types of projects stipulated in the \"Notice on Clarifying Carbon-Neutral Bonds Mechanism\" of the National Association of Financial Market Institutional Investors. It is aimed to evaluate and screen the ESG performance and fundraising projects of the newly issued bonds every week in the national interbank bond market, the Shanghai Stock Exchange and the Shenzhen Stock Exchange. After the screening, the yield data in the primary market of the selected samples are extracted which will then be re-classified and averaged with specific weights to form a green ESG bond issuance index. On the premise of sufficient sample size, the sub-indices of carbon neutral bonds, green bonds and ESG bond series are calculated in the same way with their corresponding index curves publicly displayed.",
	INDICE_1000008="The index includes green bonds that publicly issued in the China Interbank Bond Market with issuance size of no less than CNY 300 million and time to maturity of no less than 1 month. Eligible constituents of the index are aligned with the following green bond standard: Green Bond Endorsed Projects Catalogue (2021 Edition), jointly announced by the People\u2019s Bank of China (PBOC), the National Development and Reform Commission (NDRC) and the China Securities Regulatory Commission (CRSC).",
	INDICE_RDM="The average bond yields indices of the Luxembourg Stock Exchange reflect the evolution of the bonds listed on the markets operated by the Luxembourg Stock Exchange in their respective currencies. In addition to the distinction by currency, the Luxembourg Stock Exchange applies a number of selection criteria related to average bond yields to constitute the samples of securities.",
}

export function getIndiceDescription(id: string): string {
	if( id == null || id === "" || ! id.length )
        return null;
	try {
		let index = Object.keys(IndexDescriptionEnum).indexOf("INDICE_" + id.toString().toUpperCase());
  		return index >= 0 ? Object.values(IndexDescriptionEnum)[index] : null;
	}catch (e){
		console.error("Failed to get IndexDescriptionEnum for '" + id + "'", e);
	}
		
	return null;
}