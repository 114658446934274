import * as React from "react";

import { breakpoint, breakpointMax } from "../../../../../style";
import { css, jsx } from "@emotion/react";
import {
  fetchData,
  getTransformPdfLink,
  renderLink,
} from "../../../../../utils/helper";
import {
  rootPage,
  twoColsWithAd,
  twoColsWithAdLeft,
  twoColsWithAdRight,
} from "../../shared.style";

import { DiscoverAdContent } from "../../../../../sitecore-components/DiscoverAd";
import Loader from "../../../Loader/loader.component";
import { useLocation } from "react-router-dom";
import { File } from "@sitecore-jss/sitecore-jss-react";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { canUseDOM } from "../../../../../predicates";
import { formatDate } from "../../../../../utils/date";

/** @jsx jsx */

const banner = (background?: string) => css`
  width: 100%;
  min-height: 275px;
  background: url(${background}), #425563;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #425563;
`;
const container = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1264px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 165px;
`;
const containerTop = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  color: #ffffff;
`;
const containerBottom = css`
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
  align-items: center;
  padding-bottom: 64px;
  padding-top: 16px;
`;

const avatar = (img?: string) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #425563;
  margin-right: 16px;
`;

export const PressArticleComponent: React.FunctionComponent =
  (): React.ReactElement => {
    const location = useLocation();
    const id = location.pathname.split("/").pop().split("___")[0].toUpperCase();
    const [press, setPress] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(true);

    const fetchPress = async (id: string) => {
      try {
        const req: any = await fetchData(id, false);
        const result = { ...req };
        if (result && result.Author) {
          const author = await fetchData(result.Author, false);
          result.author = author;
          if (
            author &&
            author?.Picture?.match(/{([^}]+)}/) &&
            author?.Picture?.match(/{([^}]+)}/)[1]
          ) {
            //console.clear();
            const picture: any = await fetchData(
              author?.Picture?.match(/{([^}]+)}/)[1],
              false
            );
            result.imageLink = renderLink(picture.ItemMedialUrl);
          }
        }
        if (
          result &&
          result.Document &&
          result.Document.match(/{([^}]+)}/) &&
          result.Document.match(/{([^}]+)}/)[1]
        ) {
          const document: any = await getTransformPdfLink(
            result.Document.match(/{([^}]+)}/)[1]
          );
          result.documentLink = `${renderLink(document)}`;
        }
        if (
          result &&
          result.Background &&
          result.Background.match(/{([^}]+)}/) &&
          result.Background.match(/{([^}]+)}/)[1]
        ) {
          const picture: any = await fetchData(
            result.Background.match(/{([^}]+)}/)[1],
            false
          );
          result.background = renderLink(picture.ItemMedialUrl);
        }

        setLoading(false);
        setPress(result);
      } catch (err) {
        console.error(err);
      }
    };

    React.useEffect(() => {
      fetchPress(id);
    }, [id]);

    return (
      <div>
        <Loader loading={loading}>
          <div css={banner(press?.background)}>
            <div css={container}>
              <div css={containerTop}>{press && press.Title}</div>
              <div css={containerBottom}>
                {press && press.imageLink && (
                  <div css={avatar(press.imageLink)} />
                )}
                {press &&
                  press.author &&
                  (press.author["First name"] || press.author["Last name"]) && (
                    <>
                      By {press?.author["First name"]}{" "}
                      {press?.author["Last name"]}{" "}
                      {press.Date
                        ? `, ${formatDate(press.Date, "MMM. MM YYYY")}`
                        : ""}
                    </>
                  )}
              </div>
            </div>
          </div>
          <div css={rootPage}>
            <div css={twoColsWithAd}>
              <div css={twoColsWithAdLeft}>
                {press && (
                  <div
                    dangerouslySetInnerHTML={{ __html: press.Description }}
                  />
                )}
              </div>
              {
                press && press.documentLink && (
                  <div>
                    <Button
                      label={"Download article"}
                      onClick={() =>
                        canUseDOM && window.open(press.documentLink, "_blank")
                      }
                      iconLeft={"box"}
                    />
                  </div>
                )
                /*
              <div css={twoColsWithAdRight}>
                  <DiscoverAdContent
                      title={"The new bond trading destination"}
                      subTitle={"Competitive spreads, Fils prices, Quality retail-size trading"}
                      vertical={true}
                  />
              </div>
              */
              }
            </div>
          </div>
        </Loader>
      </div>
    );
  };
