
/** @jsx jsx */
import { InputField } from "@bdl-cmn-shared-packages-npm/design-system";
import { jsx } from "@emotion/react"
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LevelOneClassification, levelOneClassificationLabels, LevelTwoClassification, levelTwoClassificationLabels } from "../../../../../../enums";
import { Checkbox } from "@bdl-cmn-shared-packages-npm/design-system";

import style from "./index.style";
import inputStyle from "../input.style";

type SubTypeMapping = {
  [key in LevelOneClassification]: LevelTwoClassification[]
}

const subTypeMapping: SubTypeMapping = {
  CGEN: [
    LevelTwoClassification.CCOM,
    LevelTwoClassification.CCOD,
    LevelTwoClassification.CCOS,
    LevelTwoClassification.CENE,
    LevelTwoClassification.CHEC,
    LevelTwoClassification.CIND,
    LevelTwoClassification.CMAT,
    LevelTwoClassification.CTEC,
    LevelTwoClassification.CUTI
  ],
  FGEN: [
    LevelTwoClassification.FBAN,
    LevelTwoClassification.FCOF,
    LevelTwoClassification.FFIS,
    LevelTwoClassification.FINS,
    LevelTwoClassification.FREE,
  ],
  SGEN: [
    LevelTwoClassification.SAGE,
    LevelTwoClassification.SQSO,
    LevelTwoClassification.SSOV,
    LevelTwoClassification.SSUP,
  ]
};

interface Selection {
  levelOneClassifications: LevelOneClassification[];
  levelTwoClassifications: LevelTwoClassification[];
};

export interface IssuerClassificationFilterProps {
  selection: Selection
  onSelectionChange: (values: Selection) => void;
}


type SubOption = {
  value: LevelTwoClassification
  selected: boolean;
};

type Option = {
  value: LevelOneClassification
  selected: boolean,
  children: SubOption[]
};

function unique(array: string[]): string[] {
  const set = array.reduce((acc, value) => {
    acc[value] = value;
    return acc;
  }, {} as any);

  return Object.values(set);
}

export function IssuerClassificationFilter({
  selection,
  onSelectionChange
}: IssuerClassificationFilterProps) {

  const [filter, setFilter] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const handleLevelOneSelectionChange = (classification: LevelOneClassification, value: boolean) => {
    const res = {
      levelOneClassifications: value ?
        [...selection.levelOneClassifications, classification] :
        selection.levelOneClassifications.filter(v => v !== classification),
      levelTwoClassifications: selection.levelTwoClassifications.filter(v => !subTypeMapping[classification].includes(v))
    };
    onSelectionChange(res)
  }

  const handleLevelTwoSelectionChange = (classification: LevelTwoClassification, value: boolean) => {
    let levelOneClassifications: LevelOneClassification[];
    let levelTwoClassifications: LevelTwoClassification[];

    const parentClassification: LevelOneClassification = Object.keys(subTypeMapping)
      .find((key: LevelOneClassification) => subTypeMapping[key].includes(classification)) as LevelOneClassification;

    const isParentSelected = selection.levelOneClassifications.includes(parentClassification);

    if (isParentSelected) {
      levelOneClassifications = selection.levelOneClassifications.filter(l => l !== parentClassification);
      levelTwoClassifications = unique([...selection.levelTwoClassifications, ...subTypeMapping[parentClassification]])
        .filter(c => c !== classification) as LevelTwoClassification[];
    } else {
      const parentWillContainsAll = subTypeMapping[parentClassification].every(c => c === classification || selection.levelTwoClassifications.includes(c));
      if (parentWillContainsAll) {
        levelOneClassifications = [...selection.levelOneClassifications, parentClassification];
        levelTwoClassifications = selection.levelTwoClassifications.filter(two => !subTypeMapping[parentClassification].includes(two));
      } else {
        levelOneClassifications = selection.levelOneClassifications.filter(c => c !== parentClassification);
        levelTwoClassifications = value ?
          [...selection.levelTwoClassifications, classification] :
          selection.levelTwoClassifications.filter(c => c !== classification);
      }
    }

    onSelectionChange({
      levelOneClassifications,
      levelTwoClassifications,
    })
  }

  const options: Option[] = useMemo(() => {
    const filteredLevelTwo = Object.values(LevelTwoClassification)
      .filter((value: LevelTwoClassification) => levelTwoClassificationLabels[value].toUpperCase().includes(filter.toUpperCase()));

    const filteredLevelOne = Object.values(LevelOneClassification)
      .filter((value: LevelOneClassification) => levelOneClassificationLabels[value].toUpperCase().includes(filter.toUpperCase()) || subTypeMapping[value].find(levelTwo => filteredLevelTwo.includes(levelTwo)));

    return filteredLevelOne.map((levelOneClassification) => {
      const selected =
        selection.levelOneClassifications.includes(levelOneClassification) ||
        subTypeMapping[levelOneClassification].every(v => selection.levelTwoClassifications.includes(v));

      const children: SubOption[] = subTypeMapping[levelOneClassification]
        .filter(v => filteredLevelTwo.includes(v))
        .map((levelTwoClassification) => {
          return {
            value: levelTwoClassification,
            selected: selected || selection.levelTwoClassifications.includes(levelTwoClassification)
          }
        });

      return {
        value: levelOneClassification,
        selected,
        children
      };
    })
  }, [selection, filter]);

  const label = useMemo(() => {
    const number = selection.levelOneClassifications.reduce((acc, key) => acc + subTypeMapping[key].length, 0)
      + selection.levelTwoClassifications.length;

    return number == 0 ?
      "Issuer type" :
      number === 1 ?
        levelTwoClassificationLabels[selection.levelTwoClassifications[0]] :
        `${number} elements selected`;
  }, [selection]);

  const ref = useRef(null);
  useEffect(() => {
    if (open) {
      const clickOutside = (event: any) => {
        if (!ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    }
  }, [open]);

  return (
    <div ref={ref} css={style.container} onClick={(e) => e.stopPropagation()}>
      <div css={inputStyle.inputWrapper}>
        <InputField
          onFocus={() => setOpen(true)}
          value={open ? filter : null}
          onClick={(e) => e.stopPropagation()}
          onChange={(e: any) => setFilter(e.target.value)}
          placeholder={open ? "Issuer type" : label}
        />
      </div>
      {
        open && (
          <>
            <div css={style.panel} onClick={(e) => e.stopPropagation()}>
              {
                options.map(
                  ({ children, selected, value }) => (
                    <React.Fragment key={value}>
                      <Checkbox
                        label={levelOneClassificationLabels[value]}
                        onClick={() => handleLevelOneSelectionChange(value, !selected)}
                        checked={selected}
                      />
                      <div css={style.children}>
                        {
                          children.map(({ selected, value }) => (
                            <Checkbox
                              key={value}
                              label={levelTwoClassificationLabels[value]}
                              onClick={() => handleLevelTwoSelectionChange(value, !selected)}
                              checked={selected}
                            />
                          ))
                        }
                      </div>
                    </React.Fragment>
                  )

                )
              }
            </div>
          </>
        )
      }
    </div>
  );
}