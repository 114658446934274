
/*
*   e-Listing - core - components - country flag icon - fin
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const FIN: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.334' style={{fill: '#F0F0F0'}} width='512' height='341.337'/>
        <polygon style={{fill: '#2E52B2'}} points='512,234.666 512,277.329 183.652,277.329 183.652,426.666 140.978,426.666 140.978,277.329   0,277.329 0,234.666 140.978,234.666 140.978,85.329 183.652,85.329 183.652,234.666 '/>
    </svg>
);
