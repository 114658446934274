/** @jsx jsx */

import { Pagination, Table, Tbody } from '@bdl-cmn-shared-packages-npm/design-system';
import { breakpoint, breakpointMax } from '../../../../../style';
import { css, jsx } from '@emotion/react';

import EmptyCard from '../../../Search/results/searchMobile/resultsCards/emptyCard';
import { Loader } from '../../Loader';
import React from 'react';
import { style } from './index.style';

export interface ListViewProps<T> {
  data: T[];
  onPaginationUpdate: (pageNumber: number) => void;
  pageNumber: number;
  totalResultSize?: number;
  itemPerPage?: number;
  loadingPage?: boolean;
  Card: (data: T, index: number) => JSX.Element;
  Empty?: () => JSX.Element;
}

export const SearchMobile = ({ 
  data, 
  onPaginationUpdate, 
  pageNumber = 0,
  totalResultSize, 
  itemPerPage = 20,
  loadingPage, 
  Card, 
  Empty,
}: ListViewProps<any>) => {
  let nbPage = 0;
  if( totalResultSize > itemPerPage )
    nbPage = Math.trunc(totalResultSize / itemPerPage) + (totalResultSize % itemPerPage > 0 ? 1: 0);

  return data?.length ? (
    <div css={style.mobile}>
      {data.map(Card)}
      {loadingPage && <Loader />}

      {nbPage > 0 && (
        <div
          css={css`
            @media (max-width: ${breakpointMax}) {
              ul {
                columns: 2;
                padding: 0;
                display: block !important;
                li {
                  margin-bottom: 5px;
                }
              }
            }
          `}
        >
          <Pagination
            id={"searchMobilePagination"}
            initialPage={pageNumber}
            onPageChange={(d: any) => {
              onPaginationUpdate(d.selected);
            }}
            total={ nbPage }
          />
        </div>
      )}
    </div>
  ) : (
    <>
      {
        Empty ? 
          <>{Empty()}</>
          :
          <EmptyCard />
      }
    </>
  );
};
