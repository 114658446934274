
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const JAM: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#009B3A" d="M503.172,88.276H8.828C3.953,88.276,0,92.228,0,97.103v317.793c0,4.875,3.953,8.828,8.828,8.828 h494.345c4.875,0,8.828-3.953,8.828-8.828V97.103C512,92.228,508.047,88.276,503.172,88.276z" />
            <path fill="#FED100" d="M512,97.103c0-4.875-3.953-8.828-8.828-8.828h-39.501L256,224.337L48.329,88.276H8.828 C3.953,88.276,0,92.228,0,97.103v22.836L207.671,256L0,392.061v22.836c0,4.875,3.953,8.828,8.828,8.828h39.501L256,287.663 l207.671,136.061h39.501c4.875,0,8.828-3.953,8.828-8.828V392.06L304.329,256L512,119.939V97.103z" />
            <g>
                <polygon fill="#000000" points="512,119.939 512,392.061 304.329,256 " />
                <polygon fill="#000000" points="0,392.061 0,119.939 207.671,256 " />
            </g>
        </svg>
    </React.Fragment>
);
