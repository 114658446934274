/** @jsx jsx */
import { Button, Loader, ToggleButtonGroup, ToggleItem } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import React from "react";
import { useEffect, useState } from "react";
import { GET_TOP_TRADED_SECURITIES_BY_MONTH_REPORT, GET_TOP_TRADED_SECURITIES_BY_YEAR_REPORT } from "../../../graphql/queries/market.queries";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import { breakpointMax } from "../../../style";
import WidgetCard from "../WidgetCard";
import TopTradedSecuritiesChart from "./Charts/TopTradedSecuritiesChart";
import RisersAndFallersHome from "./Home/risers-fallers-home";
import { Security } from "./Home/top-traded-securities";
import TopTradedSecuritiesTable from "./View/top-traded-securities-table";
import { LuxseReport } from "../../../interfaces/luxse-report";
import { GET_LUXSE_REPORTS } from "../../../graphql/queries/luxse-reports.queries";


export function TopTradedSecuritiesView(props: any) {

  const [target1, setTarget1] = useState<string>('Graph');
  const [target2, setTarget2] = useState<string>('Graph');

  const [target, setTarget] = useState<string>('Monthly');

  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [yearsLoading, setYearsLoading] = useState<boolean>(true);
  const [monthsLoading, setMonthsLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  let [topTradedSharesByMonth, setTopTradedSharesByMonth] = useState<Security[]>([]);
  let [topTradedBondsByMonth, setTopTradedBondsByMonth] = useState<Security[]>([]);

  let [topTradedSharesByYear, setTopTradedSharesByYear] = useState<Security[]>([]);
  let [topTradedBondsByYear, setTopTradedBondsByYear] = useState<Security[]>([]);

  const currentYear=new Date().getFullYear().toString();
  const currentMonth=String(new Date().getMonth() + 1).padStart(2, '0');


  const [selectedMonth, setSelectedMonth] = useState<string>(currentMonth+"/"+currentYear);

  const [selectedYear, setSelectedYear] = useState<string>(currentYear);


  const selectBoxStyle = {
    selectContainer: css`
      width: 310px;
      position: relative;
      //border-top: 1px solid #ccc;
      padding-top: 2px;
      padding-right:10px;
      @media (max-width: ${breakpointMax}) {
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
      }
    `,
    selectBox: css`
        width: 100%;
        height: 40px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        text-align: left; /* Aligns text to the left side */
    `,
    label: css`
        position: absolute;
        background: white;
        top: -10px;
        padding: 0 2px;
        color: var(--branding-medium-grey, #A4BCC2);
        font-size: 12px;
        font-family: Mont;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    `,
    option:css`
        font-size: 14px;
        font-family: Mont;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color:#425563;
    `,

  };

  const cssStyle = {
    titleContainer: css`
  width: 100%;
  max-width: 1280px;
  //padding-left: 40px;
  //padding-right: 40px;
  margin-right: auto;
  margin-bottom: 0px;
  padding-bottom:0px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${breakpointMax}) {
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  }
`,
    parentContainer: css`
    width:85%;

    padding-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    margin: auto;
    padding-top:80px;

    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
`,
    mainTitle: css`
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 30px;
    text-align: center;
    line-height: 61px;
    color: #425563;
    width: 100%;

    text-align: left;
    @media (max-width: ${breakpointMax}) {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 24px;

    text-align: left;
    }
`,
    mainDescription: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    color: #354450;
    margin-bottom: 24px;
    @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.01em;
    }
`,
  };

  const handleOptionChangeMonth = (event: any) => {
    setSelectedMonth(event.target.value);
  }

  const handleOptionChangeYear = (event: any) => {
    setSelectedYear(event.target.value);
  }


  const client = luxseGatewayGraphqlClient;

  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];

  const [yearData, setYearData] = useState<any>([]);

  const [monthData, setMonthData] = useState<any>([]);

  const tmpYear:any[]=[];
  const tmpMonth:any[]=[];
  
  function initializeYear(){
      for (let i = 0; i < 3; i++) {
        tmpYear?.push({
          name: Number(currentYear)-i,
          value: Number(currentYear)-i
        });
      }
      setYearData(tmpYear);
  }

  function initializeMonth(){
    const endDate=new Date(monthNames[Number(currentMonth)-1]+" "+String(currentYear));

    const startDate=new Date(monthNames[Number(currentMonth)-1]+" "+String(Number(currentYear)-2));
    var currentDate = startDate;

    while (currentDate <= endDate) {
      var month = currentDate.getMonth()+1;
      var year = currentDate.getFullYear();
      tmpMonth?.push({
        name: monthNames[Number(month)-1]+" "+String(year),
        value: String(month).padStart(2, '0')+"/"+String(year)
      });
      
      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    
    setMonthData(tmpMonth.reverse());
  }

  useEffect(()=>{
    initializeYear();
    initializeMonth();
  },[]);

  const style = {
    displayViewMode: css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 32px 0;

    
        @media (max-width: ${breakpointMax}) {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 24px 8px;

          margin-bottom:50px;
          & > div {
            display: flex;
            flex-direction: column;
            width: 100%;
            & > div {
              border-left: 1px solid #e3e6e8;
              height: 50px;
              font-size: 16px;
            }
            & div {
              width: 100%;
            }
          }
        }
    
        > div:first-of-type {
          border-right: 1px solid #e3e6e8;
        }
    
        div {
          display: flex;
          align-items: center;
          padding-top: 2px;
          padding-bottom: 2px;
          height:37px;
          font-size: 14px;
        }
      `,
    title: css`
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #354450;
        flex: none;
        order: 0;
        flex-grow: 0;
        padding-right: 8px;
        @media (max-width: ${breakpointMax}) {
            margin-bottom: 14px;
        }
      `,
    container: css`
        width:85%;
        padding-left: 40px;
        padding-right: 40px;

        padding-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        border: solid 1px #d9dee2;
        border-radius: 8px;
        position: relative;
        font-family: 'Mont';
        color:#425563;
    
        h1 {
            font-weight: 700;
            font-size: 48px;
            line-height: 61px;
            
        }

        h3{
          font-weight: 700;
          font-size: 32px;
          line-height: 41px;
          border-bottom: solid 1px #d9dee2;
          width:100%;
          padding-bottom:7px;
          padding-top:7px;
        }

        h4{
          font-weight: 700;
          font-size: 24px;
          line-height: 31px;
          width:100%;
          padding-bottom:7px;
          padding-top:7px;
          margin-bottom:7px;
        }

        @media (max-width: ${breakpointMax}) {
          width: 95%;
          padding-left: 0px;
          padding-right: 0px;
          border:0;
          border-radius:0px;
        }
      `,
    displayParams: css`
        display: flex;
        flex-direction:row;
        justify-content: space-around;
        align-items: center;
        margin-right:auto;
        margin-left:auto;
        @media (max-width: ${breakpointMax}) {
          flex-direction:column;
          //margin-top:100px;
        }
      `,
      displayTypeName:css`
      margin-top:3rem;
      text-align:center;
      @media (max-width: ${breakpointMax}) {
        text-align:left;
        padding-left:8px;
      }
      `,
      displayOtherReports:css`
        padding:160px 113px; 
        background: var(--ligt-grey, #F6F8F9);
        width:100%;
        @media (max-width: ${breakpointMax}) {
          padding-left: 0;
          padding-bottom: 0;
          padding-right: 0;
          padding-top: 40px;
        }
      `,
      loaderWrapper: css`
        position:absolute;
        animation: spin 2s linear infinite;
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
    `,
  };

  const fetchTopTradedSecurityByMonth = async (month: string) => {
    try {
      if (!dataLoading)
        setDataLoading(true);
      const resultsShares: any = await client.query({
        query: GET_TOP_TRADED_SECURITIES_BY_MONTH_REPORT,
        variables: {
          type: "ACT",
          date: month,
        },
      });

      const resultsBonds: any = await client.query({
        query: GET_TOP_TRADED_SECURITIES_BY_MONTH_REPORT,
        variables: {
          type: "OBL",
          date: month,
        },
      });


      topTradedSharesByMonth = resultsShares?.data?.topTradedSecuritiesByMonth;

      setTopTradedSharesByMonth({ ...topTradedSharesByMonth });

      topTradedBondsByMonth = resultsBonds?.data?.topTradedSecuritiesByMonth;

      setTopTradedBondsByMonth({ ...topTradedBondsByMonth });


      setDataLoading(false);

    } catch (e) {
      console.error('Failed to get top traded securities data', e);
      setDataLoading(false);
    }
  }

  const fetchTopTradedSecurityByYear = async (year: string) => {
    try {
      if (!loading)
        setLoading(true);
      const resultsShares: any = await client.query({
        query: GET_TOP_TRADED_SECURITIES_BY_YEAR_REPORT,
        variables: {
          type: "ACT",
          date: year,
        },
      });

      const resultsBonds: any = await client.query({
        query: GET_TOP_TRADED_SECURITIES_BY_YEAR_REPORT,
        variables: {
          type: "OBL",
          date: year,
        },
      });


      topTradedSharesByYear = resultsShares?.data?.topTradedSecuritiesByYear;

      setTopTradedSharesByYear({ ...topTradedSharesByYear });

      topTradedBondsByYear = resultsBonds?.data?.topTradedSecuritiesByYear;

      setTopTradedBondsByYear({ ...topTradedBondsByYear });


      setLoading(false);

    } catch (e) {
      console.error('Failed to get top traded securities data', e);
      setLoading(false);
    }
  }

  const getSelectedMonth=(data:string)=>{
    console.log("Data ",data);
    const getMonth=data.split("/")
    if(monthNames.includes(getMonth[0])){
      return getMonth[0].charAt(0).toUpperCase()+getMonth[1]
    }else{
      return monthNames[Number(getMonth[0])-1].toString().charAt(0).toUpperCase()+monthNames[Number(getMonth[0])-1].toString().slice(1)+" "+getMonth[1]
    }
  }

  const fetchPdfData = async () => {
    let report : LuxseReport = null;
    try {
      if (!dataLoading)
        setPdfLoading(true);

      const splitDate=selectedMonth.split("/");

      const results: any = await client.query({
        query: GET_LUXSE_REPORTS,
        variables: {
          query: "",
          fromDate : splitDate[0] + "-01-" + splitDate[1],
          toDate : splitDate[0] + "-02-" + splitDate[1],
          usePageable: true,
          page: 0,
          size: 1,
          types: ["MARKET_STATISTICS"],
          subTypes: ["TOP_TRADED"],
          sorts: ["date,ASC"]
        },
      });
      if( results?.data?.luxseReports?.resultSize > 0){
        report=results?.data?.luxseReports?.resultList[0];
      }
      
      downloadFile(report);
      setPdfLoading(false);
    } catch (e) {
      console.error("Failed to get reportfor '" + selectedMonth + "'", e);
      setPdfLoading(false);
    }
  }

  function downloadFile(report: LuxseReport) {
    if(report != null){
      const downloadLink = document.createElement("a");

      downloadLink.href = report.downloadUrl;
      downloadLink.download = report.name;
      downloadLink.click();
    }else{
      console.error("Failed to get pdf for '" + selectedMonth + "': Report is null");
    }
  }


  useEffect(() => {
    fetchTopTradedSecurityByMonth(selectedMonth);
    fetchTopTradedSecurityByYear(selectedYear);
  }, [selectedMonth, selectedYear]);

  return (
    <>
      <div css={cssStyle.parentContainer}>
        <div css={cssStyle.titleContainer}>
          <div css={cssStyle.mainTitle}>
            Top traded securities
          </div>
          <div css={cssStyle.mainDescription}>
            Consult the top traded securities at the Luxembourg Stock Exchange.
          </div>
        </div>
      </div>
      <div css={style.container}>
        <h1>Performances</h1>
        
        <div css={[style.displayViewMode,css`justify-content: start; padding: 32px 0;`]}>
          <span css={style.title}>Display</span>
          <ToggleButtonGroup onChange={(elem: any) => setTarget(elem)}>

            <ToggleItem selected={target === 'Monthly'} value='Monthly'>
                Monthly performances
            </ToggleItem>
            <ToggleItem value='Yearly' selected={target === 'Yearly'}>
                Yearly performances
            </ToggleItem>
          </ToggleButtonGroup>
        </div>

        {target === 'Monthly' &&
        <>
        <h3>Monthly</h3>
        
        <div css={style.displayParams}>
          <div css={style.displayViewMode}>
            <span css={style.title}>View mode </span>
            <ToggleButtonGroup onChange={(elem: any) => setTarget1(elem)}>
              <ToggleItem selected={target1 === 'Graph'} value='Graph'>
                Graph
              </ToggleItem>

              <ToggleItem value='Table' selected={target1 === 'Table'}>
                Data table
              </ToggleItem>
            </ToggleButtonGroup>
          </div>
          <div css={selectBoxStyle.selectContainer}>
            <label css={selectBoxStyle.label}>Period</label>
            <select value={selectedMonth} onChange={handleOptionChangeMonth} css={selectBoxStyle.selectBox}>
              {monthData?.map((x: any) => (
                <option value={x.value} css={selectBoxStyle.option}>{x.name}</option>
              ))
              }
            </select>
          </div>
          <div css={[{ marginTop: "0px" },pdfLoading?style.loaderWrapper:'',]}>
        <Loader loading={pdfLoading}>
          {!pdfLoading ?
            <Button
              onClick={(e) => fetchPdfData()}
              wired={true}
              iconLeft="file-pdf"
              label={`Monthly report`}
              css={css`margin-right:auto;`}
              />:<></>
            }
          </Loader>
          </div>
        </div>

        <h4 css={style.displayTypeName}>Shares</h4>

        <div
          css={css`
            margin-left: 30px;
            margin-right: 30px;
            @media (max-width: ${breakpointMax}) {
                margin:0px;
                padding:0px;
            }`}
        >
          {target1 === 'Graph' &&
            <WidgetCard
              icon={""}
              label={''}
              loader={dataLoading}
              isHover={false}
            >
              <TopTradedSecuritiesChart data={topTradedSharesByMonth} type="Turnover" />
              
            </WidgetCard>
          }
          {target1 === 'Table' &&
            <Loader loading={dataLoading}>
              <TopTradedSecuritiesTable data={topTradedSharesByMonth} type="E" />
            </Loader>
          }
        </div>


        <h4 css={style.displayTypeName}>Bonds</h4>

        <div
          css={css`
                    margin-left: 30px;
                    margin-right: 30px;
                    @media (max-width: ${breakpointMax}) {
                        margin:0px;
                    }`}
        >
          
          {target1 === 'Graph' &&
            <WidgetCard
              icon={""}
              label={''}
              loader={dataLoading}
              isHover={false}
            >
              <TopTradedSecuritiesChart data={topTradedBondsByMonth} type="Turnover" />
            </WidgetCard>
          }
          {target1 === 'Table' &&
            <Loader loading={dataLoading}>
              <TopTradedSecuritiesTable data={topTradedBondsByMonth} type="B" />
            </Loader>
          }
        </div>
        </>
        }

        {target === 'Yearly' &&
          <>
            <h3>Yearly</h3>
            <div css={style.displayParams}>
              <div css={style.displayViewMode}>
                <span css={style.title}>View mode </span>
                <ToggleButtonGroup onChange={(elem: any) => setTarget2(elem)}>
                  <ToggleItem selected={target2 === 'Graph'} value='Graph'>
                    Graph
                  </ToggleItem>

                  <ToggleItem value='Table' selected={target2 === 'Table'}>
                    Data table
                  </ToggleItem>
                </ToggleButtonGroup>
              </div>
              <div css={selectBoxStyle.selectContainer}>
                <label css={selectBoxStyle.label}>Period</label>
                <select value={selectedYear} onChange={handleOptionChangeYear} css={selectBoxStyle.selectBox}>
                  {yearData?.map((x: any) => (
                    <option value={x.value}>{x.name}</option>
                  ))
                  }
                </select>
              </div>
            </div>

            <h4 css={style.displayTypeName}>Shares</h4>

            <div
              css={css`
                    margin-left: 30px;
                    margin-right: 30px;
                    @media (max-width: ${breakpointMax}) {
                        margin:0px;
                        //margin-top:2.2rem;                        
                    }
        `}
            >
              {target2 === 'Graph' &&
                <WidgetCard
                  icon={""}
                  label={''}
                  loader={loading}
                  isHover={false}
                >
                  <TopTradedSecuritiesChart data={topTradedSharesByYear} type="Turnover" />
                </WidgetCard>
              }
              {target2 === 'Table' &&
                <Loader loading={loading}>
                  <TopTradedSecuritiesTable data={topTradedSharesByYear} type="E" />
                </Loader>
              }
            </div>

            <h4 css={style.displayTypeName}>Bonds</h4>

            <div
              css={css`
                    margin-left: 30px;
                    margin-right: 30px;
                    @media (max-width: ${breakpointMax}) {
                        margin:0px;
                    }`}
            >
              {target2 === 'Graph' &&
                <WidgetCard
                  icon={""}
                  label={''}
                  loader={loading}
                  isHover={false}
                >
                  <TopTradedSecuritiesChart data={topTradedBondsByYear} type="Turnover" />
                </WidgetCard>
              }
              {target2 === 'Table' &&
                <Loader loading={loading}>
                  <TopTradedSecuritiesTable data={topTradedBondsByYear} type="B" />
                </Loader>
              }
            </div>
          </>
        }
      </div>
      <div css={style.displayOtherReports}>
        <RisersAndFallersHome isDisplayed={true} from="top-traded-securities" />
      </div>
    </>
  )
}

export default TopTradedSecuritiesView