
/** @jsx jsx */

import * as React from 'react';

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    RichText,
    Text,
    getFieldValue,
    Link
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from '../../interfaces';
import arrow from './arrow.png';
import {renderLink} from "../../utils/helper";

export interface JobRequirementsProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  display: flex;
  padding-top: 80px;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
    padding-top: 40px;
  }
`;
const left = css`
  width: 40%;
  padding-right: 110px;
  img {
    max-width: 100%;
  }

  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const right = css`
  width: 60%;
  padding-right: 160px;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding: 0;
  }
`;
const sectionTitle = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #253645;
  }
`;
const section = css`
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #354450;
  margin-top: 20px;
  margin-bottom: 48px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #354450;
  }
`;
const skill = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #253645;
  margin-top: 32px;
  display: flex;
  
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  li {
    list-style: none;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    padding-left: 40px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #253645;
  }
  
  svg {
    color: #008C3D;
  }
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #253845;
    margin-top: 24px;
  }
`;
const icon = css`
  width: 30px;
  min-width: 30px;
`;
const text = css`
  width: cacl(100% - 30px);
`;

const image = css`
  width: 100%;
`;

const applicationText = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #253645;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #253645;
  }
`;

const applicationButton = css`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  @media (max-width: ${breakpointMax}) {
    display: flex;
    justify-content: center;
    
    button {
      font-size: 12px;
    }
  }
`;

const actions = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 68px;
  margin-bottom: 80px;
  button {
    font-size: 12px;
    margin-bottom: 8px;
  }
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;

export const JobRequirements: React.FunctionComponent<JobRequirementsProps> = (props: JobRequirementsProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const buttonLink: string = getFieldValue<string>(fields, 'application link', '');
    const image: any = getFieldValue<any>(fields, 'image', null);
    const isTechnical: string = getFieldValue<string>(fields, 'technical knowledge', '');
    const isResponsibilities: string = getFieldValue<string>(fields, 'responsibilities', '');
    const isProfile: string = getFieldValue<string>(fields, 'profile', '');
    const isApplication: string = getFieldValue<string>(fields, 'application', '');
    const isSubText: string = getFieldValue<string>(fields, 'application subtext', '');
    const linkLabel: string = getFieldValue<string>(fields, 'Careers link label', '');

    return (
        <div css={container}>
            <div css={left}>
                <div css={image}>
                    <img src={renderLink(image?.src)} alt={"image"} />
                </div>
            </div>
            <div css={right}>
                <div css={sectionTitle}>
                    <Text
                        field={fields && fields['section 1 title'] as SitecoreGenericTextField}
                    />
                </div>
                {
                    isResponsibilities && (
                        <div css={skill}>
                            <RichText
                                field={fields && fields.responsibilities as SitecoreGenericTextField}
                            />
                        </div>
                    )
                }
                <div css={section}>
                    <Text
                        field={fields && fields['section 2 title'] as SitecoreGenericTextField}
                    />
                </div>
                {
                    isProfile && (
                    <div css={skill}>
                        <RichText
                            field={fields && fields.profile as SitecoreGenericTextField}
                        />
                    </div>
                    )
                }
                <div css={section}>
                    <Text
                        field={fields && fields['section 3 title'] as SitecoreGenericTextField}
                    />
                </div>
                {
                    isTechnical && (
                        <div css={skill}>
                            <RichText
                                field={fields && fields['technical knowledge'] as SitecoreGenericTextField}
                            />
                        </div>
                    )
                }
                <div css={section}>
                    <Text
                        field={fields && fields['section 4 title'] as SitecoreGenericTextField}
                    />
                </div>
                {
                    isApplication && (
                            <div css={applicationText}>
                                <Text
                                    field={fields && fields.application as SitecoreGenericTextField}
                                />
                            </div>
                    )
                }

                <div css={applicationButton}>
                    <Button label={'Apply now'} onClick={() => buttonLink ? window.location.href = buttonLink : console.log} />
                </div>
                {
                    isSubText && (
                        <div css={applicationText}>
                            <Text
                                field={fields && fields['application subtext'] as SitecoreGenericTextField}
                            />
                        </div>
                    )
                }

                {
                    fields[`Careers link page`]?.value?.href && (
                        <div css={actions}>
                            <Link target={(fields['Careers link page']?.value?.linktype === "external" ? "_blank" : "_self")} title={linkLabel} field={fields[`Careers link page`]} css={{textDecoration: 'none'}}>
                                <Button label={linkLabel} iconLeft={'arrow-left'} wired variant={'secondary'}/>
                            </Link>
                            {
                                /* <Button label={'Graduate Program job offer'} iconRight={'arrow-right'} wired variant={'secondary'}/> */
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}
