import gql from "graphql-tag";

export const documentBaseFragment = gql`
  fragment documentBaseFragment on Document {
    id
    name
    description
    publishDate
    referenceDate
    categories
    complement
    downloadUrl
    documentTypeCode
    documentPublicTypeCode
    hostMemberStates
    deleted
    obsolete
  }
`;

export const documentSearchItemFragment = gql`
  ${documentBaseFragment}
  fragment documentSearchItemFragment on Document {
    ...documentBaseFragment
    supplementDocuments {
      ...documentBaseFragment
      incorporedByReferenceDocuments {
        ...documentBaseFragment
      }
    }
    incorporedByReferenceDocuments {
      ...documentBaseFragment
    }
  }
`;

export const documentSearchFilterFragment = gql`
  fragment documentSearchFilterFragment on LuxseSearchDocumentsFilters {
    dateRanges {
      name
      fromDate
      toDate
      count
    }
    types {
      name
      count
    }
    subTypes {
      name
      count
    }
  }
`;

export const documentSearchFullFragment = gql`
  ${documentSearchItemFragment}
  ${documentSearchFilterFragment}
  fragment documentSearchFullFragment on LuxseSearchDocumentsResults {
    totalHits
    documents {
      ...documentSearchItemFragment
    }
    filters {
      ...documentSearchFilterFragment
    }
  }
`;

export const documentSearchNoFilterFragment = gql`
  ${documentSearchItemFragment}
  fragment documentSearchNoFilterFragment on LuxseSearchDocumentsResults {
    totalHits
    documents {
      ...documentSearchItemFragment
    }
  }
`;

export const documentSearchEmptyFragment = gql`
  fragment documentSearchEmptyFragment on LuxseSearchDocumentsResults {
    totalHits
  }
`;

export const documentListFragment = gql`
  ${documentSearchItemFragment}
  fragment documentListFragment on DocumentsList {
    totalSize: totalResultSize
    data: documents {
      ...documentSearchItemFragment
    }
  }
`;

export const SEARCH_LATEST_FNS = gql`
  ${documentBaseFragment}
  query SearchLatestFNS(
    $page : Int,
    $size : Int,
    $sort: String,
    $searchTerm: String!,
  ){
    searchLatestFNS(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort,
    ){
      totalHits
      documents {
        ...documentBaseFragment
      }
    }
  }
`;

export const SEARCH_CSSF_APPROVAL = gql`
  ${documentBaseFragment}
  query SearchCSSFApproval(
    $page : Int,
    $size : Int,
    $sort: String,
    $searchTerm: String!,
  ){
    searchLatestS7(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort,
    ){
      totalHits
      documents {
        ...documentBaseFragment
      }
    }
  }
`;