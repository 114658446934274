
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const HND: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001"  xmlSpace="preserve">
            <g>
                <path fill="#00bce4" d="M512,200.093H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,200.093L512,200.093z" />
                <path fill="#00bce4" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V311.909h512v102.988 C512,419.773,508.047,423.725,503.172,423.725z" />
            </g>
            <rect y="200.091" fill="#ffffff" width="512" height="111.81" />
            <g>
                <path fill="#00bce4" d="M257.277,241.579l3.159,9.473l9.985,0.077c1.293,0.01,1.828,1.66,0.789,2.428l-8.033,5.932 l3.012,9.521c0.389,1.233-1.013,2.252-2.066,1.501L256,264.704l-8.124,5.806c-1.052,0.751-2.455-0.268-2.066-1.501l3.012-9.521 l-8.033-5.932c-1.039-0.768-0.504-2.418,0.789-2.428l9.985-0.077l3.159-9.473C255.133,240.353,256.867,240.353,257.277,241.579z" />
                <path fill="#00bce4" d="M167.104,267.002l3.159,9.473l9.985,0.077c1.293,0.01,1.828,1.66,0.789,2.428l-8.033,5.932 l3.012,9.521c0.39,1.233-1.013,2.252-2.066,1.501l-8.124-5.806l-8.124,5.806c-1.052,0.751-2.455-0.268-2.066-1.501l3.012-9.521 l-8.033-5.932c-1.039-0.768-0.504-2.418,0.789-2.428l9.985-0.077l3.159-9.473C164.96,265.776,166.695,265.776,167.104,267.002z" />
                <path fill="#00bce4" d="M167.104,216.155l3.159,9.473l9.985,0.077c1.293,0.01,1.828,1.66,0.789,2.428l-8.033,5.932 l3.012,9.521c0.39,1.233-1.013,2.252-2.066,1.501l-8.124-5.806l-8.124,5.806c-1.052,0.751-2.455-0.268-2.066-1.501l3.012-9.521 l-8.033-5.932c-1.039-0.768-0.504-2.418,0.789-2.428l9.985-0.077l3.159-9.473C164.96,214.93,166.695,214.93,167.104,216.155z" />
                <path fill="#00bce4" d="M347.449,267.002l3.159,9.473l9.985,0.077c1.293,0.01,1.828,1.66,0.789,2.428l-8.033,5.932 l3.012,9.521c0.39,1.233-1.013,2.252-2.066,1.501l-8.124-5.806l-8.124,5.806c-1.052,0.751-2.455-0.268-2.066-1.501l3.012-9.521 l-8.033-5.932c-1.041-0.768-0.504-2.418,0.789-2.428l9.985-0.077l3.159-9.473C345.305,265.776,347.04,265.776,347.449,267.002z" />
                <path fill="#00bce4" d="M347.449,216.155l3.159,9.473l9.985,0.077c1.293,0.01,1.828,1.66,0.789,2.428l-8.033,5.932 l3.012,9.521c0.39,1.233-1.013,2.252-2.066,1.501l-8.124-5.806l-8.124,5.806c-1.052,0.751-2.455-0.268-2.066-1.501l3.012-9.521 l-8.033-5.932c-1.041-0.768-0.504-2.418,0.789-2.428l9.985-0.077l3.159-9.473C345.305,214.93,347.04,214.93,347.449,216.155z" />
            </g>
        </svg>
    </React.Fragment>
);
