
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import IndexListViewContent from "../../core/components/Index/index-simple/index-simple-view.component";

export const IndexListView: React.FunctionComponent = (): React.ReactElement => {
    return (
        <IndexListViewContent />
    );
}
    