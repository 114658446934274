import * as React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { SearchInput } from "./input";
import { DataType } from "../../../enums";
import { style } from "./index.style";
import Header from "../Header/header.component";
import { MobileMenuContext } from "../../app/";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Footer from "../Footer/footer.component";
import {
  GET_SEARCH_DOCUMENTS_RESULTS,
  GET_SEARCH_INDEXES_RESULTS,
  GET_SEARCH_ISSUERS_RESULTS,
  GET_SEARCH_PAGES_RESULTS,
  GET_SEARCH_PROGRAMMES_RESULTS,
  GET_SEARCH_RESULTS,
  GET_SEARCH_SECURITIES_LUXXPRIME_RESULTS,
  GET_SEARCH_SECURITIES_RESULTS,
} from "../../../graphql";
import { SearchResultsDto } from "../../../interfaces/search/search";

import { Query } from "react-apollo";
import { SearchResults } from "./results";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import useFilters from "./useFilters";
import { Filters } from "./useFilters";
import { canUseDOM } from "../../../predicates";
import Utils from "../../utils/utils";

export interface SearchContextProps {
  goToDetail: (url: string) => void;
}

export type TabValue = keyof typeof DataType;

export const SearchContext = React.createContext<Partial<SearchContextProps>>(
  {}
);

const DEFAULT_ITEMS_NUMBER = 20;

export interface SearchProps {
  goToDetail: (url: string) => void;
  testGraphqlClient?: ApolloClient<NormalizedCacheObject>;
}

export const searchPageLink = (params: string) => {
  return `/search${params ? "?" + params : ""}`;
};

export const goToSearchPage = (params: string) => {
  if (canUseDOM) window.location.href = searchPageLink(params);
};

export const SearchContent: React.FunctionComponent<any> = ({
  goToDetail,
  testGraphqlClient,
}: any) => {
  const mobileMenu = React.useContext(MobileMenuContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<SearchResultsDto>(null);

  const [itemsNumber, setItemsNumber] = useState<number>(DEFAULT_ITEMS_NUMBER);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);

  const [sort, setSort] = useState<any>({});
  const [filters, setFilters] = useFilters();

  const client = luxseGatewayGraphqlClient;

  const search = async () => {
    try {
      if (!loading) setLoading(true);

      const results: any = await client.query({
        query: getQuery(filters?.dataType),
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          ...filters,
          searchTerm: filters.q,
          issuerIdFilter: filters.issuerIds
            ? filters.issuerIds.map((id) => parseInt(id))
            : null,
          size: itemsNumber,
          page: currentPageNumber,
          sort: sort && sort.field ? `${sort.field},${sort.dir}` : "",
          sortBy: sort && sort.field ? sort.field : "maturityDate",
          sortDir: sort && sort.dir ? sort.dir : "DESC",
          sdgs: filters.sdgs?.length ? filters.sdgs : null,
          eligibleProjectCategories: filters.eligibleProjectCategories.length
            ? filters.eligibleProjectCategories
            : null,
        },
      });

      setData(results?.data || null);
    } catch (e) {
      console.error("Failed to search", e);
      setData(null);
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    search();
  }, [filters, currentPageNumber, sort]);

  const onTabChange = (dataType: TabValue) => {
    setData(null);
    setLoading(true);
    setCurrentPageNumber(0);
    setSort({});
    setItemsNumber(DEFAULT_ITEMS_NUMBER);
    setFilters({
      ...filters,
      dataType,
    });
  };

  const onPaginationUpdate = (pageNumber: number) => {
    Utils.moveToTop();
    setCurrentPageNumber(pageNumber);
  };

  const onFiltersChange = (filters: Filters) => {
    setLoading(true);
    setCurrentPageNumber(0);
    setFilters(filters);
  };

  const onSearchInputUpdate = (textInput: string) => {}; // search(textInput, itemsNumber);

  const getQuery = (dataTypeStr: string) => {
    let query = GET_SEARCH_RESULTS;
    try {
      const dataType: DataType = dataTypeStr as DataType;

      if (dataType)
        switch (dataType) {
          case DataType.securities:
            query = GET_SEARCH_SECURITIES_RESULTS;
            break;
          case DataType.luxXPrime:
            query = GET_SEARCH_SECURITIES_LUXXPRIME_RESULTS;
            break;
          case DataType.issuers:
            query = GET_SEARCH_ISSUERS_RESULTS;
            break;
          case DataType.programmes:
            query = GET_SEARCH_PROGRAMMES_RESULTS;
            break;
          case DataType.documents:
            query = GET_SEARCH_DOCUMENTS_RESULTS;
            break;
          case DataType.indices:
            query = GET_SEARCH_INDEXES_RESULTS;
            break;
          case DataType.pages:
            query = GET_SEARCH_PAGES_RESULTS;
            break;
          default:
            console.error("Unknown search type '" + dataTypeStr + "'");
            break;
        }
    } catch (error) {
      console.error("Error when try to get query '" + dataTypeStr + "'", error);
    }

    return query;
  };

  return (
    <div css={style.search}>
      <Header
        onSwitchMenu={mobileMenu.actionDispatch}
        onSearchQueryUpdate={onSearchInputUpdate}
        routeTextInput={filters.q}
      />
      <SearchInput
        onSearchInputUpdate={onSearchInputUpdate}
        routeTextInput={filters.q}
        redirect={false}
      />
      <SearchResults
        loading={loading}
        onSearchQueryUpdate={onSearchInputUpdate}
        testGraphqlClient={testGraphqlClient}
        restData={data}
        onTabChange={onTabChange}
        currentPageNumber={currentPageNumber}
        itemsNumber={itemsNumber}
        onPaginationUpdate={onPaginationUpdate}
        filters={filters}
        onFiltersChange={onFiltersChange}
        sort={sort}
        onSortChange={setSort}
      />
      <Footer />
    </div>
  );
};

export const Search = withRouter(SearchContent);
