import { css } from '@emotion/react';

export const IndexSimpleStyle = {
  indexHeader: css`
    flex-direction: column;
    display: flex;
    padding-left: 11%;
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(90deg, #253645 11.63%, rgba(37, 54, 69, 0) 85.53%), url(iStock-975599364.jpg);
    p {
      margin: 0px
    }
  `,
  indexHeaderText: css`
    width: 584px;
    height: 26px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  `,
  indexHeaderText2: css`
    width: 584px;
    height: 183px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-bottom: 16%;
  `,

  indexHeaderText3: css`
    width: 584px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #ffffff;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  `,

  indexTable: css`
    width: 100%;
    th {
      background: #e8ecee;
      padding: 0px 32px;
      height: 40px;
    }
  `,

  button_chevron: css`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    width: 8px;
    height: 12px;
    color: #22AA5F;
    border: none;
  `,

  fromBolg: css`
    width: 810px;
    height: 18px;

    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1em;

    /* Brand/Grey 500 */

    color: #354450;
  `,

  welcomeLuxPrime: css`
    width: 810px;
    height: 41px;

    /* Text/Title/BoldMediumLarge */

    font-family: 'Mont';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 41px;
    /* identical to box height */

    text-align: center;

    /* UI/Action/mainAction */

    color: #22AA5F;
  `,
  name: css`
    width: 81px;
    height: 18px;

    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    color: #8daeb7;
  `,
  text: css`
    width: 117px;
    height: 18px;

    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    text-decoration-line: underline;

    /* Font/Body */

    color: #354450;
  `,
  lastRead: css`
    width: 62px;
    height: 18px;

    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    /* Brand/Grey 600 */

    color: #354450;

    /* Inside auto layout */

    flex: none;
    order: 5;
    flex-grow: 0;
  `,
  lastDate: css`
    width: 108px;
    height: 18px;

    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    /* Brand/Grey 600 */

    color: #354450;

    /* Inside auto layout */

    flex: none;
    order: 7;
    flex-grow: 0;
  `,
  textBlog: css`
    color: #8daeb7;
  `,
  LoaderPart: css`
    padding-left: 32px;
    padding-right: 32px;
    margin: 40px 0;
    align-items: center;
    display: flex;
    width: 100%;
  `,

  indexContainer: css`
    margin-bottom: 50px;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    border-left: solid 1px #d9dee2;
    border-right: solid 1px #d9dee2;
    position: relative;

    h1 {
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 60px;
    }
  `,

  clickToTop: css`
    position: fixed;
    right: 50px;
    bottom: 50px;

    > button {
      background-color: #F8FAFA !important;
      color: #22AA5F !important;
      transition: 0.5s ease all;
      
      :hover{
        background-color: #22AA5F !important;
        color: #F8FAFA !important;
      }
      :active,:focus{
        background-color: #075128 !important;
        color: #F8FAFA !important;
      }
    }
  `,
};
