import { css } from '@emotion/react';

export const style = {
  tabContentWrapper: css`
    padding-top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  tabs: css`
  position: relative;
  align-items: flex-start;
  display: flex;
  justify-content: left;
  > div {
    > div {
      padding-top: 0;
      //overflow: auto;
      display: flex;
      :first-of-type {
        background-color: #253645;
        justify-content: center;
        overflow: hidden;
        color: white;

        > div {
          padding: 16px 32px;

          &:hover {
            color: #22AA5F;
          }
        }
      }
    }
  }
  `,

  tabEmpty: css`
    opacity: 0.5;
  `,

  listView: css`
    //toDo max height
    //max-height: 876px;
    //overflow: auto;
    th {
      background-color: #f8fafc;
      position: sticky;
      top: 0;
      z-index: 100;
    }
  `,
  pageControls: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,
  pageNumberOfItem: css`
    align-items: center;
    display: flex;

    .label {
      margin-right: 15px;
    }
  `,

  noDataMessage: css`
    margin: 8rem;
  `,
  customizer: css`
    position: absolute;
    top: -0.5rem;
    right: 2rem;

    > div > div:first-child {
      /* "Customize" label */
      color: white;
    }
  `,
};
