/** @jsx jsx */

import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { getValue, renderLink } from "../../utils/helper";

import { SitecoreGenericTextField } from "../../interfaces";
import avt from "./avatar.png";

export interface TeamMembersProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
`;
const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
  }
`;
const cols = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;

const col = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  max-width: 340px;
  padding: 32px;
  align-items: center;
  @media (max-width: ${breakpointMax}) {
    min-width: 100%;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 24px;
    margin-right: 0;
    margin-top: 16px;
  }
`;

const title = css`
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  margin-bottom: 76px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 32px;
  }
`;

const avatar = css`
  width: 160px;
  height: 160px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  text-align: center;

  img {
    object-fit: contain;
    text-align: center;
    width: inherit;
  }
`;

const name = css`
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  text-align: center;
  color: #cb1234;
  margin-top: 22px;
  margin-bottom: 8px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #cb1234;
  }
`;

const role = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #354450;
  height: 45px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #425563;
  }
`;

const contact = css`
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  text-align: center;
  color: #354450;
  margin-top: 10px;

  svg {
    color: #ca1234;
    margin-right: 6px;
  }
`;

const action = css`
  margin-top: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpointMax}) {
    button {
      font-size: 12px;
    }
  }
`;

export const TeamMembers: React.FunctionComponent<TeamMembersProps> = (
  props: TeamMembersProps
): React.ReactElement => {
  const { fields } = props;
  const mailToSubject: string = getFieldValue<string>(
    fields,
    "mail subject",
    "Contact from website"
  );
  return (
    <div css={parentContainer}>
      <div css={container}>
        <div css={title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={cols}>
          {new Array(10).fill(0).map((arr: any, index: number) => {
            if (
              fields &&
              fields[`member ${index + 1} name`] &&
              (fields && (fields[`member ${index + 1} name`] as any)).value
            ) {
              return (
                <div css={col}>
                  <div css={avatar}>
                    <img
                      src={renderLink(
                        getValue(fields, `member ${index + 1} image`) &&
                          getValue(fields, `member ${index + 1} image`).src
                      )}
                      alt={"image"}
                    />
                  </div>
                  <div css={name}>
                    <Text
                      field={
                        fields &&
                        (fields[
                          `member ${index + 1} name`
                        ] as SitecoreGenericTextField)
                      }
                    />
                  </div>
                  <div css={role}>
                    <Text
                      field={
                        fields &&
                        (fields[
                          `member ${index + 1} role`
                        ] as SitecoreGenericTextField)
                      }
                    />
                  </div>
                  {(fields && (fields[`member ${index + 1} phone`] as any))
                    .value && (
                    <div css={contact}>
                      <Icon icon={"phone"} />
                      <Text
                        field={
                          fields &&
                          (fields[
                            `member ${index + 1} phone`
                          ] as SitecoreGenericTextField)
                        }
                      />
                    </div>
                  )}
                  <div css={action}>
                    <Button
                      css={css`
                        &:hover {
                          background-color: #0d8e46 !important;
                        }
                        background-color: #22aa5f !important;
                      `}
                      iconLeft={"paper-plane"}
                      label={"Contact"}
                      onClick={() =>
                        getValue(fields, `member ${index + 1} mail`)
                          ? window.open(
                              `mailto:${getValue(
                                fields,
                                `member ${index + 1} mail`
                              )}?subject=${mailToSubject}`
                            )
                          : console.log()
                      }
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
