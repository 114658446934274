import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { style } from "./banner.style";
import RoundTag from "../../RoundTag/roundTag.component";
import ConceptualBanner from "../../ConceptualBanner/conceptualBanner.component";
import { Security } from "../../../../interfaces";
import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  computeLabel,
  instrumentSubTypeLabel,
  instrumentTypeLabel,
  SecurityStatus,
} from "../../../../enums";
import PriceColumn from "../../UI/list/security/price";
import luxxprimeLogo from "./luxXPrime.svg";
import { renderLink } from "../../../../utils/helper";
import { BrokerModal } from "./brokers-modal";
import { GreenCard } from "../../../../interfaces/green-card";
import { genericStyle } from "../../UI/generic/generic.style";
import { computeCategoryLabel } from "../../UI/list/security/row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { canUseDOM } from "../../../../predicates";
import {  LgxSlbOverallStatusBond, lgxSlbOverallStatusBondLabel } from "../../../../enums/lgx-slb-overall-status-bond";
import Tag from "../../Tag/tag.component";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countries = require("i18n-iso-countries");
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export interface Row {
  label: string;
  action: () => void;
}

export interface SecurityBannerProps {
  security: Security;
  greenCard: GreenCard;
}

const SecurityBanner = ({ security, greenCard }: SecurityBannerProps) => {
  const [copied, setCopied] = React.useState<boolean>(false);
  const slbOverallStatusLabel = greenCard && greenCard.slbOverallStatus ? lgxSlbOverallStatusBondLabel(greenCard.slbOverallStatus) : null;
  const slbOverallStatusEnum = greenCard && greenCard.slbOverallStatus ? LgxSlbOverallStatusBond[greenCard.slbOverallStatus as keyof typeof LgxSlbOverallStatusBond] : null;
  return (
    <ConceptualBanner
      green={security.lgxDisplay}
      lgxDisplay={security.lgxDisplay}
      renderLeft={
        <>
          <div css={style.roundTag}>
            <RoundTag green={security.lgxDisplay} borderSize={3} minSize={39}>
              {computeCategoryLabel(security?.instrumentType)}
            </RoundTag>
          </div>
          <div css={style.info}>
            <div css={style.securityNameText}>{security.instrumentName}</div>
            <div css={[genericStyle.mobileDisplay]}>{security.isin}</div>
            <div css={style.subtitle}>
              <StatusBanner security={security} />
              {security.lgxDisplay ? (
                <>
                  {
                  security?.tags?.includes("RBDL_EUGB") ? (
                    <div css={style.tag}>
                      <div>EU Green Bond</div>
                    </div>
                  ) :
                  security?.tags?.includes("RBDL_GRBD") ? (
                    <div css={style.tag}>
                      <div>Green bond</div>
                    </div>
                  ) : security?.tags?.includes("RBDL_SOCI") ? (
                    <div css={style.tag}>
                      <div>Social bond</div>
                    </div>
                  ) : security?.tags?.includes("RBDL_SUST") ? (
                    <div css={style.tag}>
                      <div>Sustainability bond</div>
                    </div>
                  ) : security?.tags?.includes("RBDL_SLB") ? (
                    <div css={style.tag}>
                      <div>Sustainability-linked bond</div>
                    </div>
                  ) : security?.tags?.includes("RBDL_ESG") ||
                    security?.tags?.includes("RBDL_ESG8") ? (
                    <div css={style.tag}>
                      <div>Article 8 Fund</div>
                    </div>
                  ) : security?.tags?.includes("RBDL_IMP9") ? (
                    <div css={style.tag}>
                      <div>Article 9 Fund</div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              {security.lgxDisplay && greenCard?.genderBond ? (
                <div css={[style.tag, style.genderTag]}>
                  <div css={style.tagIcon}>
                    <FontAwesomeIcon icon={["fas", "person-half-dress"]} />
                    <span>Gender focus</span>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {security?.blockchainType ? (
                <div css={style.tag}>
                  <div css={style.tagIcon}>
                    <FontAwesomeIcon icon={["fas", "circle-nodes"]} />
                    <span>Security Token</span>
                  </div>
                </div>
              ) : (
                <></>
              )}
               {security.lgxDisplay && slbOverallStatusLabel ? (
                <div   css={[style.tag, 
                LgxSlbOverallStatusBond.ALL_SPT_MET == slbOverallStatusEnum ? 
                style.success :  LgxSlbOverallStatusBond.ALL_SPT_MISSED == slbOverallStatusEnum ? 
                style.danger : style.warning
                ]}>
                  {slbOverallStatusLabel}
                </div>
              ) : (
                <></>
              )}
              <div
                css={[
                  css`
                    white-space: nowrap;
                  `,
                  genericStyle.desktopDisplay,
                ]}
              >
                <div
                  css={[
                    style.isin,
                    css`
                      display: inline-block;
                      margin-right: 14px;
                    `,
                  ]}
                >
                  {security.isin}
                </div>
                <div
                  onClick={() => {
                    canUseDOM && navigator.clipboard.writeText(security.isin);
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 2000);
                  }}
                  css={css`
                    display: inline-block;
                    cursor: pointer;
                    color: #22aa5f;
                  `}
                >
                  {copied ? (
                    "copied!"
                  ) : (
                    <FontAwesomeIcon
                      icon={["far", "fa-copy"] as any}
                      color="#22AA5F "
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      }
      renderRight={(scrolled: boolean) =>
        security.marketData?.lastPrice ? (
          <div css={style.lastPrice}>
            <PriceColumn {...security.marketData?.lastPrice} color={"white"} />
          </div>
        ) : null
      }
    >
      <div css={style.metadata}>
        {security?.tags?.includes("LuxXPrime") ? (
          <div css={[style.metadata, style.metadataLuxxprime]} id="luxXPrime">
            <img src={renderLink(luxxprimeLogo)} alt={"image"} />
          </div>
        ) : (
          <></>
        )}
        <div>{computeLabel(security.marketRule)}</div>
        {security?.instrumentType ? (
          <div>{instrumentTypeLabel(security.instrumentType)}</div>
        ) : (
          <></>
        )}
        {security?.instrumentSubtype &&
        security?.instrumentSubtype?.toString() !=
          security?.instrumentType?.toString() ? (
          <div>{instrumentSubTypeLabel(security.instrumentSubtype)}</div>
        ) : (
          <></>
        )}
        <div>{security.currency}</div>

        {<Actions security={security} />}
      </div>
    </ConceptualBanner>
  );
};

export default SecurityBanner;

const Actions = ({ security }: { security: Security }) => {
  const [brokerModal, setBrokerModal] = React.useState<"buy" | "sell" | "none">(
    "none"
  );

  return (
    <>
      {(security?.status === SecurityStatus.ADMI ||
        security?.status === SecurityStatus.NEGO ||
        security?.status === SecurityStatus.COTE) &&
      security?.brokers?.length > 0 ? (
        <div css={style.actions}>
          <Button
            label={"Buy"}
            iconLeft={"arrow-down"}
            onClick={() => {
              setBrokerModal("buy");
            }}
          />
          <Button
            label={"Sell"}
            iconLeft={"arrow-up"}
            variant="secondary"
            style={{ border: "1px solid #fff" }}
            onClick={() => {
              setBrokerModal("sell");
            }}
          />
          {brokerModal !== "none" ? (
            <BrokerModal
              type={brokerModal}
              brokers={security.brokers}
              onClose={(): void => {
                setBrokerModal("none");
              }}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {/*
        <Button label={'Compare'} wired iconLeft={'times'} />
        <Button label={'Add to watchlist'} iconLeft={'copy'} />
        <Button label={'Trade now'} iconLeft={'sync'} />
        */}
    </>
  );
};

const StatusBanner = ({ security }: { security: Security }) => {
  const status = security?.status;
  if (!status) return <></>;

  let icon = "";
  let label = security.status.toString();
  let backgroundColor = "#fff";
  let color = "#425563";

  switch (status) {
    case SecurityStatus.COTE:
      icon = "check";
      label = "Listed";
      color = "#fff";
      backgroundColor = "#22AA5F";
      break;
    case SecurityStatus.NCOT:
      label = "Listed to be confirmed";
      color = "#fff";
      backgroundColor = "#f18700";
      break;
    case SecurityStatus.ADMI:
    case SecurityStatus.NEGO:
      if(security.marketData?.halted){
        icon = "";
        label = "Halted";
        color = "#fff";
        backgroundColor = "#F18700";
      } else { 
        icon = "check";
        label = "Tradable";
        color = "#fff";
        backgroundColor = "#22AA5F";
      }
      break;
    case SecurityStatus.RETR:
      icon = "";
      label = "Delisted";
      color = "#fff";
      backgroundColor = "#b5b5b5";
      break;
    case SecurityStatus.SUSP:
      icon = "close";
      label = "Suspended";
      color = "#fff";
      backgroundColor = "#b5b5b5";
      break;
    case SecurityStatus.PUBL:
      label = "Public offer";
      break;
    case SecurityStatus.FONG:
      label = "";
      break;
  }

  return label ? (
    <div css={[style.tag, { color: color, backgroundColor: backgroundColor }]}>
      {icon && (
        <div css={{ marginRight: "0.5em" }}>
          <Icon icon={icon} />
        </div>
      )}
      <div>{label}</div>
    </div>
  ) : (
    <></>
  );
};

function LuxXPrimeBanner() {
  return (
    <div css={style.luxXPrimeBanner}>
      <div css={style.luxXPrimeContainer}>
        <div css={style.luxXPrimeLogo}>
          <img src={renderLink(luxxprimeLogo)} alt={"image"} />
        </div>
        <div css={style.luxXPrimeContent}>
          <div css={style.luxXPrimeTitle}>The new bond trading destination</div>
          <div css={style.luxXPrimeSubTitle}>
            Competitive spreads, Firms prices, Quality retail-size trading
          </div>
        </div>
        <div css={style.luxXPrimeButton}>
          <Button
            variant="secondary"
            label={"Discover"}
            onClick={() => (window.location.href = "/discover-lgx")}
            iconRight="chevron-right"
          />
        </div>
      </div>
    </div>
  );
}
