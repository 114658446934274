/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { useState } from 'react';
import { style } from "./index.style";
import SearchResult from '../../../../../../interfaces/search/search-result';
import {isExperienceEditorActive, Text} from "@sitecore-jss/sitecore-jss-react";

export interface MobileResultsProps {
  data?: SearchResult;
  dataType?: string;
  source?: any[];
  onSwitchTab?: (tab: string) => void;
}

export const MobileResults = (props: MobileResultsProps): JSX.Element => {
  const {
    data,
    dataType,
    source,
    onSwitchTab
  } = props;

  const tab = css`
    color: #22AA5F;
    min-width: 40%;
    min-height: 50px;
    display: flex;
    align-items: center;
  `;
  const tabHeader = css`
    padding-top: 0;
    display: flex;
    flex-direction: row;
    overflow: auto;
    overflow-y: hidden;
    padding-bottom: 0;
  `;
  const container = css`
    width: 100%;

    .timeline-component-part {
      display: ${isExperienceEditorActive() ? 'flex' : 'none'};
    }
  `;
  const titleSelected = css`
    color: #22AA5F;
    background-color: #ffffff;
    padding-left: 8px;
    min-height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 2px #22AA5F;
    width: 100%;
  `;
  const title = css`
    background-color: #ffffff;
    min-height: 57px;
    justify-content: center;
    display: flex;
    align-items: center;
    padding-left: 8px;
    color: #354450;
    width: 100%;
  `;
  const tabMobile = css`
    display: flex;
    position: absolute;
    right: 0;
    height: 52px;
    width: 30px!important;
    pointer-events: none;
    background: linear-gradient(270deg, rgba(37, 54, 69, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  `;

  return (
    <div css={style.container}>
        <div css={container}>
          <div css={tabHeader}>
            {
              source.map((item: any, index: number) => {
                return (
                  <div css={tab} onClick={() => {onSwitchTab(item.dataType);}} key={`loop-map-${index}`}>
                    <div css={item.dataType === dataType ? titleSelected : title}>
                      {item.label} ({(data as any)[item.data] ? (data as any)[item.data].totalHits : "0"})
                    </div>
                  </div>
                )
              })
            }
            <div css={tabMobile} />
          </div>
        </div>
    </div>
  );
}


export default MobileResults;
