
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const GIB: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M503.172,423.724H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.792C512,419.772,508.047,423.724,503.172,423.724z" />
            <g>
                <path fill="#DA000c" d="M503.172,423.724H8.828c-4.875,0-8.828-3.953-8.828-8.828V308.965h512v105.931 C512,419.772,508.047,423.724,503.172,423.724z" />
                <polygon fill="#DA000c" points="353.103,211.862 353.103,194.207 335.448,194.207 335.448,150.069 344.275,150.069 344.275,132.414 291.31,132.414 291.31,150.069 300.137,150.069 300.137,194.207 273.655,194.207 273.655,132.414 282.482,132.414 282.482,114.759 229.517,114.759 229.517,132.414 238.344,132.414 238.344,194.207 211.862,194.207 211.862,150.069 220.689,150.069 220.689,132.414 167.724,132.414 167.724,150.069 176.551,150.069 176.551,194.207 158.896,194.207 158.896,211.862 167.724,211.862 167.724,282.483 158.896,282.483 158.896,300.138 353.103,300.138 353.103,282.483 344.275,282.483 344.275,211.862 " />
            </g>
            <g>
                <path fill="#000000" d="M273.655,291.31h-35.31V256c0-9.751,7.904-17.655,17.655-17.655l0,0 c9.751,0,17.655,7.904,17.655,17.655V291.31z" />
                <path fill="#000000" d="M255.999,167.724c-4.875,0-8.828,3.953-8.828,8.828v26.483h17.655v-26.483 C264.827,171.677,260.874,167.724,255.999,167.724z" />
                <path fill="#000000" d="M317.792,176.552c-4.875,0-8.828,3.953-8.828,8.828v17.655h17.655V185.38 C326.62,180.504,322.669,176.552,317.792,176.552z" />
                <path fill="#000000" d="M194.206,176.552c-4.875,0-8.828,3.953-8.828,8.828v17.655h17.655V185.38 C203.034,180.504,199.081,176.552,194.206,176.552z" />
                <path fill="#000000" d="M326.62,291.31h-35.31v-26.483c0-9.751,7.904-17.655,17.655-17.655l0,0 c9.751,0,17.655,7.904,17.655,17.655V291.31z" />
                <path fill="#000000" d="M220.689,291.31h-35.31v-26.483c0-9.751,7.904-17.655,17.655-17.655l0,0 c9.751,0,17.655,7.904,17.655,17.655V291.31z" />
            </g>
            <path fill="#F8D80E" d="M280.275,322.207c0-1.474-0.737-2.854-1.965-3.672l-17.897-11.93v-3.737l15.215-7.608 c2.182-1.09,3.064-3.742,1.974-5.922c-1.095-2.185-3.737-3.064-5.922-1.974l-15.681,7.841l-15.681-7.84 c-2.173-1.09-4.832-0.211-5.922,1.974c-1.09,2.182-0.207,4.832,1.974,5.922l15.215,7.607v3.737l-17.897,11.932 c-1.228,0.818-1.965,2.197-1.965,3.671s0.737,2.854,1.965,3.672l17.897,11.932v37.362H233.93v6.759c0,2.44,1.974,4.414,4.414,4.414 c1.689,0,3.159-0.948,3.901-2.345h9.341v8.828h-9.418c-0.762-1.319-2.189-2.207-3.823-2.207c-2.44,0-4.414,1.974-4.414,4.414v6.621 h17.655v4.414c0,2.44,1.974,4.414,4.414,4.414s4.414-1.974,4.414-4.414v-4.414v-26.483v-37.362l17.897-11.932 C279.538,325.06,280.275,323.681,280.275,322.207z M255.999,330.143l-11.905-7.936l11.905-7.935l11.905,7.935L255.999,330.143z" />
        </svg>
    </React.Fragment>
);
