
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const EGY: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#C8102E" d="M512,200.093H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,200.093L512,200.093z" />
            <path fill="#000000" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V311.909h512v102.988 C512,419.773,508.047,423.725,503.172,423.725z" />
            <rect y="200.091" fill="#FFFFFF" width="512" height="111.81" />
            <path fill="#C09300" d="M284.186,238.571c0-4.377-4.289-7.469-8.441-6.084l-7.804,2.601l-1.895-11.444 c-1.133-6.826-6.983-11.78-13.906-11.78h-7.299l-7.634,9.396h10.109l-2.829,13.972l-8.233-2.744 c-4.153-1.385-8.441,1.707-8.441,6.084v47.475l11.363-11.363l-5.354,16.061h-6.008v9.396h56.373v-9.396h-6.008l-5.354-16.061 l11.363,11.363v-47.476H284.186z M251.303,290.743h-7.574l5.208-13.909l2.366,3.021L251.303,290.743L251.303,290.743z M260.697,290.743v-10.888l2.366-3.021l5.208,13.909H260.697z M256,273.714c0,0-14.534-9.836-13.946-28.186 c0,0,8.956-1.468,13.946-9.396c4.991,7.927,13.947,9.396,13.947,9.396C270.534,263.879,256,273.714,256,273.714z" />
        </svg>
    </React.Fragment>
);
