import React, {Component} from 'react';
/** @jsx jsx */
import {css,jsx} from '@emotion/react';
import {Tr, Th } from '@bdl-cmn-shared-packages-npm/design-system';

export class OAMSearchHeaderEN extends Component {
    render() {
        return (
                <Tr>
                    <Th>Published</Th>
                    <Th>Information</Th>
                    <Th>Issuer</Th>
                    <Th>ID</Th>
                    <Th>Publication</Th>
                    <Th>Reference date / period</Th>
                    <Th></Th>
                </Tr>
        );
    }
}

export default OAMSearchHeaderEN;
