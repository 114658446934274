export enum PageNumberOfItem {
  ONE = "1",
  TWO = "2",
  THREE = "3"
}

// TODO: uncomment when dealing with real data
// export enum PageNumberOfItem {
//   TEN = "10",
//   TWENTY_FIVE = "25",
//   FIFTY = "50",
//   HUNDRED = "100"
// }
