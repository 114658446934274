
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import MarketDataView from "../../core/components/DataMarket/MarketData/market-data-view.component";

export interface DataMarketProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
      width: 100%;
    `;

export const DataMarket: React.FunctionComponent<DataMarketProps> = (props: DataMarketProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div css={container}>
           <MarketDataView/>
        </div>
    );
}
