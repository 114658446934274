import * as React from "react";

import { Document, Security } from "../../../../interfaces";
import {
  Icon,
  Row,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpoint, breakpointMax } from "../../../../style";
import { css, jsx } from "@emotion/react";

import { Density } from "../../UI/list/customizer/customizer.component";
import { DocumentSearchResultDto } from "../../../../interfaces/search/search";
import { DocumentsListView } from "../../UI/list/document";
import { DownloadDocument } from "../../UI/list/document/row";
import { GET_SEARCH_DOCUMENTS_ONLY_RESULTS } from "../../../../graphql";
import Loader from "../../Loader/loader.component";
import { Query } from "react-apollo";
import RoundTag from "../../RoundTag/roundTag.component";
import SearchDocument from "../../../../interfaces/search/search-document";
import SearchFilterParams from "../../../../interfaces/search/search-filter-params";
import SecuritySearchCard, {
  DocumentSearchCard,
} from "../../UI/list/document/card-mobile";
import { formatDate } from "../../../../utils/date";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { searchSortData } from "../../Search/results/dataMapper";
import { setDocumentType } from "../../../../utils/documentsType";
import { style } from "./index.style";
import { useState } from "react";
import Utils from "../../../utils/utils";
import { genericStyle } from "../../UI/generic/generic.style";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import MobileFilterContainer from "../../Search/results/searchMobile/mobileFilterContainer";
import FilterButton from "../../Search/results/searchMobile/filterButton";
import { DocumentFilters } from "../../UI/list/document/document-filters";

/** @jsx jsx */

export interface Row {
  label: string;
  action: () => void;
}

export interface SecurityDocumentsProps {
  label?: string;
  children?: any;
  securityData?: Security;
  rows?: Row[];
}

const defaultSearchFilters: SearchFilterParams = {
  documentTypes: [],
  documentSubTypes: [],
  excludeDocumentTypes: [],
  excludeDocumentSubTypes: ["D318"],
};

const SecurityDocuments: React.FunctionComponent<SecurityDocumentsProps> = ({
  securityData,
}: SecurityDocumentsProps) => {
  const density: Density = "medium";
  const selectedCols: any = null;

  const [filters, setFilters] =
    React.useState<SearchFilterParams>(defaultSearchFilters);
  const [itemsNumber, setItemsNumber] = useState<number>(20);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [sort, setSort] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const switchFilter = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const onPaginationUpdate = (pageNumber: number) => {
    Utils.moveToTop();
    setCurrentPageNumber(pageNumber);
  };

  const onFiltersChange = (filtersTmp: SearchFilterParams) => {
    setCurrentPageNumber(0);
    setFilters(filtersTmp);
  };

  React.useEffect(() => {
    setCurrentPageNumber(0);
  }, [filters]);

  return (
    <div css={style.documentList}>
      <Query<{ luxseDocumentsSearch: DocumentSearchResultDto }, any>
        client={luxseGatewayGraphqlClient}
        query={GET_SEARCH_DOCUMENTS_ONLY_RESULTS}
        variables={{
          ...filters,
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: searchTerm ? searchTerm : "",
          size: itemsNumber,
          page: currentPageNumber,
          sort: sort && sort.field ? `${sort.field},${sort.dir}` : "",
          securityId: securityData.id,
        }}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, error, data }): React.ReactNode => {
          return (
            <Loader
              loading={loading && !data?.luxseDocumentsSearch}
              padding={"5em 0 !important"}
            >
              <div css={genericStyle.desktopDisplay}>
                <DocumentsListView
                  data={data.luxseDocumentsSearch}
                  loadingPage={loading}
                  density={density}
                  onPaginationUpdate={onPaginationUpdate}
                  currentPageNumber={currentPageNumber}
                  totalResultSize={data?.luxseDocumentsSearch?.totalHits || 0}
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  sortData={searchSortData}
                  sort={sort}
                  onSortChange={setSort}
                  selectedCols={selectedCols}
                  fastLaneUrls={securityData.fastLaneUrls}
                />
              </div>
              <div css={genericStyle.mobileDisplay}>
                <div css={{ padding: "2em", width: "100%" }}>
                  <SearchMobile
                    onPaginationUpdate={onPaginationUpdate}
                    pageNumber={currentPageNumber}
                    data={data?.luxseDocumentsSearch?.documents}
                    totalResultSize={data?.luxseDocumentsSearch?.totalHits}
                    Card={(card) => (
                      <DocumentSearchCard
                        key={`card-documents-${card?.id}`}
                        card={card}
                        fastLaneUrls={securityData.fastLaneUrls}
                      />
                    )}
                  />
                </div>
                <FilterButton onSwitchFilter={switchFilter} open={menuIsOpen} />
                <MobileFilterContainer open={menuIsOpen}>
                  <DocumentFilters
                    filterCount={data?.luxseDocumentsSearch?.filters}
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    fullWidth
                  />
                </MobileFilterContainer>
              </div>
            </Loader>
          );
        }}
      </Query>
    </div>
  );
};

export default SecurityDocuments;
