/* eslint-disable */
import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useState, useEffect } from "react";
import { luxseGatewayGraphqlClient } from "../../../../../../luxse-gateway-graphql-client";
import { GET_LUXXPRIME_NEWEST_SECURITIES_QUERY } from "../../../../../../graphql/queries/luxxprime.queries";
import { style } from "../../../index.style";
import Loader from "../../../../Loader/loader.component";
import EmptyResults from "../../../../UI/list/base/empty";
import { formatDate } from "../../../../../../utils/date";
import { securityDetailPageLink } from "../../../../SecurityCard";
import Utils from "../../../../../utils/utils";

export const LuxXPrimeNoticeNewestSecurities = () => {
  const client = luxseGatewayGraphqlClient;
  const limitOffset = 20;
  const [limit, setLimit] = useState<number>(20);

  const [data, setData] = useState<any[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const getData = async () => {
    try {
      let results: any = await client.query({
        query: GET_LUXXPRIME_NEWEST_SECURITIES_QUERY,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          limit: limit,
        },
      });

      setDataLoading(false);

      if (results?.data?.newestLuxXPrimeSecurities) {
        let tmpData: any[] = [];
        results.data.newestLuxXPrimeSecurities.forEach((element: any) => {
          tmpData.push(element);
        });

        setData(tmpData);
      }
    } catch (e) {
      console.error("Failed to get newest securities", e);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    setDataLoading(true);
    getData();
  }, [limit]);

  return (
    <div css={style.container}>
      <div css={style.content}>
        <Loader loading={dataLoading}>
          {data && data?.length > 0 ? (
            <>
              <div css={[style.contentBody, style.contentBodyList]}>
                {data.map((security: any, index: number) => {
                  if (!security) return <></>;

                  return (
                    <a
                      css={[style.row, style.rowClickable]}
                      href={securityDetailPageLink(security.isin, security.id)}
                      title={`${security.instrumentName} - ${security.isin}`}
                    >
                      <div css={style.rowIcon}>
                        <div css={style.rowIconRound}>
                          <Icon icon="play" />
                        </div>
                      </div>

                      <div css={style.rowContent}>
                        <div css={style.rowHeader}>
                          <div css={style.rowTitle}>
                            {security.instrumentName}
                          </div>
                          <div css={style.rowSubTitle}>
                            {security.isin}
                            {security.issuedNominalAmount
                              ? ` ${security.currency} ${security.issuedNominalAmount}`
                              : ""}
                          </div>
                        </div>
                        <div css={style.rowDate}>
                          <div css={style.rowTitle}>New as of</div>
                          <div css={style.rowSubTitle}>
                            {formatDate(security.luxxprimeDate, "DD/MM/yyyy")}
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>

              {data?.length === limit ? (
                <div css={style.contentButton}>
                  <Button
                    label="Load more ..."
                    onClick={() => {
                      setLimit(limit + limitOffset);
                    }}
                    variant="primary"
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div css={style.emptyResult}>
              <EmptyResults />
            </div>
          )}
        </Loader>
      </div>
    </div>
  );
};
