/** @jsx jsx */
import { jsx } from "@emotion/react";
import { DEFAULT_OAM_FILTERS, OAMFilters, UseFilters } from "../useFilters";
import { TagFilters } from "../../Search/results/filters/stats";
import React from "react";
import { FilterTag } from "../../Search/results/useTagFilters";
import { Country } from "../../../../enums";
import { formatDate } from "../../../../utils/date";
import { OAMTypeDepo, OAMTypeDepoLabel } from "../../../../enums/oam-type-depo";


function computeDisplayedFilter(key: keyof OAMFilters, value: any) {
  switch (key) {
    case "oam_paysEmet":
      return Country[value as keyof typeof Country];
    case "publicationDate":
      if( value.fromDate)
        return `${formatDate(value.fromDate)}${value.toDate? (" - " + formatDate(value.toDate) ) : ""}`;
      else
        return null;
    case "oam_idTypeDepo":
      return OAMTypeDepoLabel(value as OAMTypeDepo, "EN");
    case "hide_canceled":
      return value ? "Hide canceled" : null;
    case "lang":
      return null;
    default:
      return value;
  }
}
interface OAMBannerProps {
  totalResultSize: number;
  filters: OAMFilters
  onFilterChange: (aKey: keyof OAMFilters) => (value: OAMFilters[typeof aKey]) => void
  onReset: () => void
}


export default function OAMBanner({ totalResultSize, filters, onFilterChange, onReset }: OAMBannerProps) {
  const filterTags: FilterTag<OAMFilters>[] = React.useMemo(() => {
    if (!filters) {
      return [];
    }

    return Object.keys(filters)
      .reduce((acc, key: keyof OAMFilters) => {
        // @ts-ignore
        if (filters[key] !== DEFAULT_OAM_FILTERS[key]) {
          let valueLabel = computeDisplayedFilter(key, filters[key]);
          if( valueLabel )
            acc.push({
              label: key,
              name: key,
              value: valueLabel
            })
        }
        return acc;
      }, [] as FilterTag<OAMFilters>[])
  }, [filters]);

  return (
    <TagFilters
      filters={filterTags}
      nbResults={totalResultSize || 0}
      onFilterClear={(key) => onFilterChange(key)(DEFAULT_OAM_FILTERS[key])}
      onReset={onReset}
      searchTerm={filters?.oam_libEmet || null}
      loader={false} />
  );
}