
/** @jsx jsx */
import { jsx } from "@emotion/react"
import * as React from 'react';
import { InputField, OptionsData, RadioButton } from "@bdl-cmn-shared-packages-npm/design-system";
import style from "./index.style";

export interface ReferenceYearFilterProps {
  selection: any;
  onSelectionChange: (values: any) => void;
  data: any
  lang: string
}

export function ReferenceYearFilter({ selection, onSelectionChange, data, lang }: ReferenceYearFilterProps) {

  const [filter, setFilter] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);
  const getPlaceholder = () => {
    return lang?.toUpperCase() == "FR" ? "Année de référence" : "Reference year";
  }

  const label = React.useMemo(() => {
    return selection ? selection : getPlaceholder();
  }, [selection]);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const clickOutside = (event: any) => {
        if (!ref.current.contains(event.target)) {
          setOpen(false);
          setFilter("");
        }
      }
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    }
  }, [open]);

  const values: OptionsData[] = React.useMemo(() => {
    let years : OptionsData[] = [{
      value: null,
      name: lang === "EN" ? "All" : "Tous",
    }];
    for (let i = new Date().getFullYear(); i >= 2008; i--) {
      years.push({
        value: i.toString(),
        name: i.toString(),
      })
    }

    return years.filter((value: OptionsData) => value.name.toUpperCase().includes(filter.toUpperCase()));
  }, [data, filter, lang]);

  const handleSelectionChange = (value: OptionsData) => {
    onSelectionChange(value);
  }

  return (
    <>
      <div ref={ref} css={style.container} onClick={(e) => e.stopPropagation()}>
        <div css={style.inputWrapper}>
          <InputField
            onFocus={() => setOpen(true)}
            value={open ? filter : null}
            onClick={(e) => e.stopPropagation()}
            onChange={(e: any) => setFilter(e.target.value)}
            placeholder={open ? getPlaceholder() : label}
          />
        </div>
        {
          open && (
            <>
              <div css={style.panel} onClick={(e) => e.stopPropagation()}>
                { values.map( (value : OptionsData) => {
                    if( !value )
                      return <></>;
                    
                    return (
                      <React.Fragment key={`ref-year-${value.value}`}>
                        <RadioButton
                          label={value.name}
                          onClick={() => handleSelectionChange(value)}
                          checked={selection == value.value}
                        />
                      </React.Fragment>
                    );
                  })
                }
              </div>
            </>
          )
        }
      </div>
    </>
  )
}
