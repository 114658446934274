
export enum LgxExternalReview {
	NONE = 'None',
	PREISSUANCE = 'Pre-issuance',
	POSTISSUANCE = 'Post-issuance'
}

function cleanName(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try{
		value = value.toString().toUpperCase();
		value = value.replace(/[^a-zA-Z ]/g, "");
		value = value.replace(/\s/g, "");
	}catch (e){
	}

	return value;
}

export function lgxExternalReviewLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(LgxExternalReview).indexOf( cleanName(value) );
  		if( index >= 0) 
			return Object.values(LgxExternalReview)[index];
	}catch (e){
		console.error("Failed to get LgxExternalReview label for '" + value + "'", e);
	}
	
	console.error("LgxExternalReview label for '" + value + "' - '" + cleanName(value) + "' doest exist");
		
	return value;
}