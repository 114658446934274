import * as React from "react";

import { breakpoint, breakpointMax } from "../../../style";

import { css } from "@emotion/react";

export const style = {
  buttomLabel: css`
    font-weight: 700;
    line-height: 26px;
    color: #253645;
  `,
  sustainableTabs: css`
    max-width: 780px;
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  `,
  header: css`
    font-size: 20px;
    line-height: 31px;
    font-weight: 700;
    color: #ffffff;
  `,
  row: css`
    max-width: 780px;
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      width: 95%;
    }
  `,
  letter: css`
    border: solid 2px #008c3d;
    border-radius: 20px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 800;
    margin-right: 8px;
  `,
  switcher: css`
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    @media (max-width: ${breakpointMax}) {
      width: 95%;
    }
  `,
  container: css`
    width: 100%;
    border-radius: 8px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
  `,
  tab: css`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #e9e9e9;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 16px;
  `,
  tabSelected: css`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 2px #22aa5f;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 16px;
    color: #22aa5f;
  `,
  parentRows: css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  `,
  parentRow: css`
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #e8faf0;
    height: 85px;
    :hover {
      background-color: #b8f0d4;
    }
    padding: 32px;
    justify-content: space-between;
    @media (max-width: ${breakpointMax}) {
      padding: 22px;
    }
  `,
  childRow: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding-right: 32px;
    padding-left: 32px;
    justify-content: space-between;
    :hover {
      background-color: #f6f8f9;
    }
    @media (max-width: ${breakpointMax}) {
      padding-right: 22px;
      padding-left: 22px;
    }
  `,
  childRowLarge: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    padding-top: 32px;
    padding-bottom: 32px;
    justify-content: space-between;
  `,
  quadratin: css`
    height: 1px;
    width: 24px;
    background: #d9e1e2;
    margin-right: 8px;
  `,
  subChild: css`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 16px;
    justify-content: space-between;
    color: #22aa5f;
  `,
  child: css`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 32px;
  `,
  full: css`
    width: 100%;
  `,
  value: css`
    border: solid 1px #22aa5f;
    color: #22aa5f;
    border-radius: 8px;
    padding: 10px 8px 8px 8px;
    font-size: 14px;
    text-decoration: none;
    background-color: #ffffff;
    svg {
      margin-right: 5px;
    }
    :hover {
      color: #ffffff;
      background-color: #22aa5f;
    }
    display: flex;
  `,
  parentRowLeft: css`
    display: flex;
    align-items: center;
    width: 85%;
    margin-top: -6px;
    svg {
      color: #22aa5f;
      margin-right: 8px;
    }
  `,
  parentContainer: css`
    display: flex;
    flex-direction: column;
  `,
  label: css`
    font-weight: 700;
  `,
  description: css`
    margin-top: 16px;
    font-weight: 400;
    padding-right: 16px;
    line-height: 150%;
    color: #253845;
  `,
  icon: css`
    color: #22aa5f;
    margin-right: 8px;
    font-size: 24px;
    font-weight: 900;
    align-items: center;
    justify-content: center;
    display: flex;
  `,
};
