
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { MenuWithChild } from "@bdl-cmn-shared-packages-npm/design-system";
import { InputDateRangePicker, InputDateRangePickerProps } from "../../../../input-date-range-picker";


export type AdmissionDateFilterProps = Pick<InputDateRangePickerProps, "fromDate" | "toDate" | "onSelect">

export function AdmissionDateFilter(props: AdmissionDateFilterProps) {
  return (
    <MenuWithChild
      icon="calendar-alt"
      label="Date of admission to trading"
      width="100%;"
      expanded={false}
      arrows={false}>
      <InputDateRangePicker
        {...props}
        placeholder="Date of admission to trading"
      />
    </MenuWithChild>
  );
}
