
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const JEY: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#F5F5F5" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <path fill="#FF4B55" d="M512,97.104c0-4.875-3.953-8.828-8.828-8.828h-23.389L256,234.894L32.216,88.277H8.828 C3.953,88.277,0,92.229,0,97.104v12.279l223.784,146.618L0,402.618v12.279c0,4.875,3.953,8.828,8.828,8.828h23.389L256,277.108 l223.784,146.617h23.389c4.875,0,8.828-3.953,8.828-8.828v-12.279L288.216,256.001L512,109.384V97.104z" />
            <path fill="#FFE15A" d="M282.557,126.861c0,0-2.795-4.193-6.988-1.398c0,0,4.849,3.495,1.572,7.164 c-3.467,3.883-8.233,0.085-8.532-2.698c1.271-0.097,2.276-1.148,2.276-2.444c0-1.361-1.102-2.463-2.463-2.463 c-1.361,0-2.463,1.102-2.463,2.463c0,1.062,0.676,1.959,1.618,2.305c-0.601,2.382-6.849,4.205-8.65,0.26 c-2.028-4.441,1.18-8.212,5.504-7.688c0,0-0.83-5.503-6.682-3.189c0,0,2.446-6.29-1.747-10.483 c-4.193,4.193-1.747,10.483-1.747,10.483c-5.853-2.315-6.682,3.189-6.682,3.189c4.324-0.524,7.531,3.245,5.504,7.688 c-1.801,3.945-8.049,2.122-8.65-0.26c0.942-0.345,1.618-1.242,1.618-2.305c0-1.361-1.102-2.463-2.463-2.463 s-2.463,1.102-2.463,2.463c0,1.297,1.005,2.347,2.276,2.444c-0.299,2.783-5.066,6.581-8.532,2.698 c-3.276-3.669,1.572-7.164,1.572-7.164c-4.193-2.795-6.988,1.398-6.988,1.398c-1.398-5.591-6.988-4.193-6.988-4.193 c5.591,6.988,9.702,21.404,9.702,21.404h47.687c0,0,4.11-14.415,9.702-21.404C289.545,122.668,283.954,121.27,282.557,126.861z" />
            <path fill="#FF4B55" d="M279.843,144.072c-7.714-2.719-15.779-4.078-23.843-4.078s-16.129,1.359-23.843,4.078 C219.65,198.871,256,213.518,256,213.518S292.35,198.871,279.843,144.072z" />
            <g>
                <path fill="#82AFFF" d="M238.561,163.568c0,0-1.146-0.216-2.143,0.582c0,0-0.349-1.378,1.444-1.71 C239.657,162.106,238.561,163.568,238.561,163.568z" />
                <path fill="#82AFFF" d="M237.181,161.825c0,0-0.908-0.732-2.163-0.5c0,0,0.344-1.379,2.082-0.824 C238.838,161.055,237.181,161.825,237.181,161.825z" />
                <path fill="#82AFFF" d="M237.932,162.539c0,0-1.252-0.216-2.342,0.582c0,0-0.381-1.378,1.579-1.71 C239.129,161.078,237.932,162.539,237.932,162.539z" />
                <path fill="#82AFFF" d="M259.623,166.2c0,0-1.165,0.055-1.95,1.062c0,0-0.658-1.26,1.01-1.998 C260.35,164.525,259.623,166.2,259.623,166.2z" />
                <path fill="#82AFFF" d="M257.914,164.698c0,0-1.053-0.502-2.22,0.014c0,0,0.015-1.422,1.834-1.284 C259.347,163.566,257.914,164.698,257.914,164.698z" />
                <path fill="#82AFFF" d="M258.773,165.344c0,0-1.269,0.079-2.144,1.107c0,0-0.69-1.252,1.141-2.029 C259.601,163.645,258.773,165.344,258.773,165.344z" />
                <path fill="#82AFFF" d="M272.931,166.92c0,0-0.436,0-1.217,0.735c0,0-0.559-1.25,1.087-1.864 C274.449,165.177,272.931,166.92,272.931,166.92z" />
                <path fill="#82AFFF" d="M272.095,165.203c0,0-0.983-0.544-2.135-0.116c0,0,0.097-1.366,1.838-1.129 C273.539,164.197,272.095,165.203,272.095,165.203z" />
                <path fill="#82AFFF" d="M272.883,165.874c0,0-1.225,0.003-2.125,0.94c0,0-0.59-1.245,1.215-1.886 C273.778,164.288,272.883,165.874,272.883,165.874z" />
                <path fill="#82AFFF" d="M236.644,154.92c0,0-1.053-0.7-2.387-0.329c0,0,0.238-1.519,2.17-1.091 C238.36,153.928,236.644,154.92,236.644,154.92z" />
                <path fill="#82AFFF" d="M236.557,152.298c0,0-0.68-1.069-2.131-1.163c0,0,0.939-1.441,2.588-0.335 C238.664,151.904,236.557,152.298,236.557,152.298z" />
                <path fill="#82AFFF" d="M236.563,153.69c0,0-1.11-0.82-2.577-0.518c0,0,0.33-1.603,2.408-1.011 C238.473,152.755,236.563,153.69,236.563,153.69z" />
            </g>
            <path fill="#FFE15A" d="M269.568,158.486c1.844,0.05,4.035-0.681,4.384-2.591c0.401-2.195-1.479-3.305-4.002-3.206 c-1.842,0.073-6.507,1.131-9.848,1.013c-3.305-0.116-3.395-3.386,1.611-3.039c7.424,0.515,8.603-0.448,8.603-0.448 c-3.986,0.747-5.58-0.648-9.815-0.648c-4.235,0-5.048,2.933-3.338,4.534c1.916,1.794,8.868,0.598,10.712,0.249 c1.844-0.349,4.182-0.367,4.367,0.93c0.165,1.163-2.923,1.1-5.111,1.016c-3.482-0.134-8.55,0.811-13.058,1.044 c-3.756,0.194-4.279-0.865-5.134-1.95c0.2-0.361,0.314-0.764,0.314-1.19c0-0.215-0.034-0.423-0.088-0.625 c0.284-0.311,0.582-0.809,0.718-1.618c0,0-0.961-0.169-1.687,0.198c-0.582-0.459-1.353-0.746-2.206-0.746 c-0.854,0-1.624,0.287-2.206,0.746c-0.726-0.366-1.687-0.198-1.687-0.198c0.137,0.81,0.435,1.306,0.718,1.618 c-0.054,0.201-0.088,0.408-0.088,0.625c0,0.782,0.376,1.489,0.983,1.996c0.292,0.245,0.51,0.567,0.584,0.941l0.105,0.531 c0.046,0.233,0.196,0.415,0.391,0.526c-3.096-0.319-4.562-2.265-5.314-3.347c-0.849-1.22-1.378-3.919-2.674-4.218l-0.764,4.07 c0,0,1.637,3.786,4.683,5.331c3.438,1.743,4.086,1.794,5.215,2.292c0.132,0.598-2.126,0.963-4.418,0.499 c-1.6-0.324-3.756-2.648-4.984-2.482l1.431,3.179c3.886,2.458,7.283,2.51,10.197,1.993c2.059-0.365,2.842-2.042,2.978-2.76 c0.841-0.156,1.757-0.33,4.595-1.027c1.188-0.292,2.557-0.446,3.919-0.51c0.384,0.876,1.262,1.998,4.285,2.104 c0,0,0.73,1.163-1.76,0.93c-2.492-0.233-3.13-1.009-4.593-0.843l1.304,3.102c0,0,3.189-1.096,7.108-0.697c0,0,0.73,0.2,1.163-0.431 c0.431-0.631,0.233-0.996-0.167-1.561c-0.224-0.318-0.354-1.348-0.424-2.191c1.436,0.361,5.841-0.533,7.433-0.399 c1.594,0.132,1.827,1.993,1.461,2.624c-0.365,0.631-3.703,0.092-3.703,0.092l1.121,3.064c0,0,0.337-0.162,1.187-0.963 c0.692-0.651,3.521-0.431,3.521-0.93v-5.676c0-0.269-0.234-0.479-0.502-0.456C270.846,159.527,269.568,158.486,269.568,158.486z" />
            <path fill="#FF4B55" d="M244.679,156.469c0.094,0.183,0.163,0.382,0.201,0.593l0.094,0.531 c0.033,0.186,0.18,0.321,0.35,0.321h1.448c0.17,0,0.317-0.135,0.35-0.321l0.094-0.531c0.038-0.211,0.107-0.409,0.201-0.593H244.679z " />
            <g>
                <path fill="#82AFFF" d="M246.047,157.518c-0.334,0-0.595,0.041-0.559,0.505l0.135,1.985 c0.023,0.303,0.206,0.534,0.424,0.534c0.217,0,0.402-0.231,0.424-0.534l0.135-1.985 C246.641,157.559,246.38,157.518,246.047,157.518z" />
                <path fill="#82AFFF" d="M241.736,183.172c0,0-0.935-0.177-1.748,0.474c0,0-0.285-1.124,1.178-1.396 C242.631,181.981,241.736,183.172,241.736,183.172z" />
                <path fill="#82AFFF" d="M240.61,181.751c0,0-0.74-0.597-1.764-0.408c0,0,0.28-1.126,1.698-0.673 C241.962,181.122,240.61,181.751,240.61,181.751z" />
                <path fill="#82AFFF" d="M241.224,182.333c0,0-1.022-0.177-1.91,0.474c0,0-0.311-1.124,1.288-1.396 C242.2,181.141,241.224,182.333,241.224,182.333z" />
                <path fill="#82AFFF" d="M258.916,185.32c0,0-0.95,0.045-1.59,0.865c0,0-0.537-1.028,0.824-1.63 C259.512,183.954,258.916,185.32,258.916,185.32z" />
                <path fill="#82AFFF" d="M257.523,184.095c0,0-0.858-0.409-1.811,0.011c0,0,0.012-1.16,1.496-1.047 C258.692,183.171,257.523,184.095,257.523,184.095z" />
                <path fill="#82AFFF" d="M258.222,184.621c0,0-1.035,0.065-1.749,0.903c0,0-0.563-1.022,0.93-1.655 C258.898,183.235,258.222,184.621,258.222,184.621z" />
                <path fill="#82AFFF" d="M269.771,185.906c0,0-0.355,0-0.993,0.599c0,0-0.457-1.02,0.887-1.519 C271.009,184.485,269.771,185.906,269.771,185.906z" />
                <path fill="#82AFFF" d="M269.09,184.506c0,0-0.802-0.444-1.742-0.094c0,0,0.079-1.114,1.5-0.92 C270.268,183.685,269.09,184.506,269.09,184.506z" />
                <path fill="#82AFFF" d="M269.732,185.053c0,0-0.999,0.002-1.734,0.767c0,0-0.482-1.015,0.991-1.538 C270.462,183.759,269.732,185.053,269.732,185.053z" />
                <path fill="#82AFFF" d="M240.172,176.118c0,0-0.858-0.57-1.947-0.268c0,0,0.194-1.24,1.771-0.89 C241.572,175.31,240.172,176.118,240.172,176.118z" />
                <path fill="#82AFFF" d="M240.102,173.979c0,0-0.555-0.873-1.738-0.949c0,0,0.766-1.175,2.111-0.274 C241.82,173.658,240.102,173.979,240.102,173.979z" />
                <path fill="#82AFFF" d="M240.107,175.115c0,0-0.906-0.669-2.102-0.422c0,0,0.269-1.308,1.964-0.824 C241.665,174.352,240.107,175.115,240.107,175.115z" />
            </g>
            <path fill="#FFE15A" d="M267.028,179.028c1.504,0.041,3.292-0.555,3.576-2.113c0.327-1.79-1.206-2.696-3.265-2.614 c-1.503,0.06-5.308,0.922-8.033,0.826c-2.696-0.095-2.77-2.762,1.314-2.479c6.056,0.42,7.017-0.365,7.017-0.365 c-3.251,0.609-4.552-0.529-8.007-0.529s-4.117,2.392-2.723,3.699c1.563,1.463,7.234,0.488,8.738,0.203s3.412-0.3,3.563,0.759 c0.136,0.948-2.385,0.897-4.169,0.829c-2.841-0.109-6.974,0.662-10.652,0.851c-3.064,0.158-3.49-0.706-4.188-1.59 c0.163-0.295,0.256-0.622,0.256-0.97c0-0.175-0.029-0.344-0.073-0.509c0.232-0.254,0.474-0.66,0.586-1.32 c0,0-0.785-0.138-1.376,0.161c-0.474-0.374-1.103-0.608-1.8-0.608s-1.325,0.234-1.8,0.608c-0.591-0.299-1.376-0.161-1.376-0.161 c0.111,0.66,0.354,1.066,0.586,1.32c-0.044,0.164-0.073,0.333-0.073,0.509c0,0.638,0.308,1.215,0.802,1.628 c0.238,0.2,0.416,0.462,0.476,0.768l0.086,0.434c0.038,0.19,0.16,0.338,0.319,0.429c-2.526-0.26-3.721-1.847-4.334-2.73 c-0.692-0.995-1.124-3.197-2.182-3.441l-0.623,3.319c0,0,1.336,3.089,3.82,4.349c2.804,1.422,3.332,1.463,4.254,1.869 c0.108,0.488-1.734,0.786-3.604,0.406c-1.305-0.265-3.063-2.159-4.066-2.025l1.167,2.593c3.17,2.005,5.941,2.047,8.318,1.625 c1.679-0.298,2.318-1.665,2.429-2.251c0.685-0.127,1.432-0.269,3.748-0.838c0.97-0.238,2.086-0.364,3.198-0.416 c0.313,0.715,1.03,1.631,3.495,1.716c0,0,0.596,0.948-1.436,0.758c-2.033-0.19-2.553-0.823-3.746-0.687l1.064,2.53 c0,0,2.601-0.894,5.799-0.569c0,0,0.596,0.162,0.948-0.352c0.352-0.515,0.19-0.813-0.136-1.273c-0.183-0.259-0.288-1.1-0.346-1.788 c1.171,0.295,4.765-0.435,6.062-0.327c1.301,0.108,1.49,1.625,1.192,2.141c-0.298,0.515-3.021,0.075-3.021,0.075l0.915,2.499 c0,0,0.275-0.132,0.969-0.786c0.564-0.531,2.872-0.352,2.872-0.758v-4.63c0-0.22-0.191-0.391-0.409-0.372 C268.071,179.876,267.028,179.028,267.028,179.028z" />
            <path fill="#FF4B55" d="M246.727,177.381c0.076,0.15,0.134,0.312,0.163,0.483l0.076,0.434 c0.026,0.152,0.147,0.262,0.285,0.262h1.181c0.138,0,0.258-0.11,0.285-0.262l0.076-0.434c0.031-0.171,0.087-0.334,0.163-0.483 H246.727z" />
            <g>
                <path fill="#82AFFF" d="M247.842,178.238c-0.273,0-0.484,0.033-0.456,0.413l0.11,1.619c0.019,0.248,0.168,0.436,0.346,0.436 c0.179,0,0.328-0.188,0.346-0.436l0.11-1.619C248.327,178.271,248.115,178.238,247.842,178.238z" />
                <path fill="#82AFFF" d="M245.267,198.754c0,0-0.7-0.132-1.309,0.355c0,0-0.213-0.842,0.883-1.045 C245.937,197.861,245.267,198.754,245.267,198.754z" />
                <path fill="#82AFFF" d="M244.424,197.689c0,0-0.555-0.447-1.321-0.306c0,0,0.21-0.843,1.271-0.504 C245.436,197.219,244.424,197.689,244.424,197.689z" />
                <path fill="#82AFFF" d="M244.883,198.125c0,0-0.765-0.132-1.43,0.355c0,0-0.233-0.842,0.964-1.045 C245.614,197.232,244.883,198.125,244.883,198.125z" />
                <path fill="#82AFFF" d="M258.13,200.362c0,0-0.712,0.033-1.191,0.648c0,0-0.402-0.77,0.617-1.22 C258.575,199.339,258.13,200.362,258.13,200.362z" />
                <path fill="#82AFFF" d="M257.087,199.445c0,0-0.643-0.307-1.356,0.009c0,0,0.01-0.868,1.12-0.785 C257.963,198.753,257.087,199.445,257.087,199.445z" />
                <path fill="#82AFFF" d="M257.611,199.839c0,0-0.775,0.049-1.309,0.676c0,0-0.422-0.765,0.696-1.239 S257.611,199.839,257.611,199.839z" />
                <path fill="#82AFFF" d="M266.259,200.801c0,0-0.266,0-0.744,0.449c0,0-0.342-0.764,0.664-1.138 C267.185,199.737,266.259,200.801,266.259,200.801z" />
                <path fill="#82AFFF" d="M265.748,199.753c0,0-0.6-0.332-1.304-0.071c0,0,0.06-0.834,1.123-0.69 C266.631,199.137,265.748,199.753,265.748,199.753z" />
                <path fill="#82AFFF" d="M266.229,200.162c0,0-0.748,0.002-1.299,0.575c0,0-0.361-0.76,0.742-1.152 S266.229,200.162,266.229,200.162z" />
                <path fill="#82AFFF" d="M244.096,193.473c0,0-0.643-0.427-1.458-0.201c0,0,0.146-0.928,1.326-0.666 C245.144,192.867,244.096,193.473,244.096,193.473z" />
                <path fill="#82AFFF" d="M244.043,191.871c0,0-0.415-0.653-1.301-0.711c0,0,0.574-0.879,1.58-0.205 C245.33,191.63,244.043,191.871,244.043,191.871z" />
                <path fill="#82AFFF" d="M244.046,192.722c0,0-0.679-0.501-1.574-0.316c0,0,0.201-0.979,1.471-0.617 C245.213,192.15,244.046,192.722,244.046,192.722z" />
            </g>
            <path fill="#FFE15A" d="M264.204,195.65c1.126,0.031,2.465-0.416,2.678-1.582c0.245-1.341-0.903-2.018-2.444-1.958 c-1.126,0.044-3.974,0.691-6.015,0.619c-2.018-0.071-2.073-2.068,0.984-1.856c4.534,0.314,5.255-0.274,5.255-0.274 c-2.434,0.457-3.409-0.396-5.995-0.396s-3.083,1.791-2.039,2.77c1.17,1.096,5.417,0.365,6.542,0.152 c1.126-0.213,2.554-0.224,2.668,0.568c0.102,0.71-1.785,0.672-3.122,0.62c-2.127-0.082-5.221,0.495-7.976,0.638 c-2.294,0.118-2.614-0.529-3.136-1.191c0.122-0.221,0.192-0.466,0.192-0.726c0-0.131-0.021-0.258-0.054-0.381 c0.173-0.191,0.355-0.494,0.439-0.989c0,0-0.587-0.103-1.031,0.12c-0.355-0.28-0.825-0.456-1.347-0.456s-0.992,0.175-1.347,0.456 c-0.444-0.224-1.031-0.12-1.031-0.12c0.084,0.494,0.266,0.798,0.439,0.989c-0.033,0.122-0.054,0.249-0.054,0.381 c0,0.478,0.231,0.909,0.6,1.219c0.179,0.149,0.311,0.346,0.356,0.575l0.064,0.324c0.028,0.142,0.12,0.253,0.238,0.321 c-1.891-0.195-2.786-1.384-3.245-2.044c-0.519-0.745-0.842-2.393-1.633-2.577l-0.467,2.485c0,0,1.001,2.313,2.86,3.256 c2.1,1.065,2.495,1.096,3.185,1.4c0.082,0.365-1.299,0.588-2.698,0.305c-0.978-0.199-2.294-1.618-3.044-1.516l0.874,1.942 c2.374,1.501,4.448,1.533,6.228,1.217c1.258-0.223,1.736-1.247,1.818-1.685c0.513-0.095,1.073-0.202,2.806-0.628 c0.726-0.179,1.561-0.273,2.394-0.311c0.235,0.535,0.771,1.22,2.616,1.284c0,0,0.446,0.71-1.075,0.568 c-1.522-0.142-1.912-0.616-2.805-0.515l0.797,1.895c0,0,1.948-0.67,4.341-0.426c0,0,0.446,0.121,0.71-0.264 c0.264-0.385,0.142-0.609-0.102-0.953c-0.137-0.194-0.216-0.824-0.259-1.338c0.877,0.221,3.567-0.326,4.54-0.244 c0.973,0.082,1.116,1.217,0.893,1.602s-2.262,0.056-2.262,0.056l0.684,1.871c0,0,0.205-0.099,0.725-0.588 c0.423-0.397,2.151-0.264,2.151-0.568v-3.467c0-0.164-0.142-0.292-0.307-0.278C264.984,196.287,264.204,195.65,264.204,195.65z" />
            <path fill="#FF4B55" d="M249.003,194.419c0.057,0.113,0.099,0.234,0.122,0.362l0.057,0.324 c0.02,0.114,0.11,0.196,0.213,0.196h0.884c0.104,0,0.193-0.083,0.213-0.196l0.057-0.324c0.023-0.128,0.065-0.25,0.122-0.362H249.003 z" />
            <path fill="#82AFFF" d="M249.838,195.06c-0.204,0-0.363,0.024-0.342,0.309l0.083,1.212c0.014,0.185,0.126,0.327,0.259,0.327 c0.134,0,0.245-0.141,0.259-0.327l0.083-1.212C250.201,195.084,250.042,195.06,249.838,195.06z" />
        </svg>
    </React.Fragment>
);