import { css } from '@emotion/react';

export const style = {
  parentContainer: css`
    width: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
    background: #f0f5f5;
  `,
  container: css`
    width: 100%;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
  `,
  title: css`
    font-weight: bold;
    font-size: 48px;
    line-height: 61px;
    color: #253645;
    margin-bottom: 16px;
  `,
  description: css`
    font-weight: bold;
    font-size: 32px;
    line-height: 41px;
    color: #253645;
  `,
  graph: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    > div:first-of-type {
      margin-top: 40px;
      align-items: stretch;
    }
  `,
};
