/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import {
    Text,
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    getFieldValue,
    RichText
} from '@sitecore-jss/sitecore-jss-react';
import {Button, Icon} from "@bdl-cmn-shared-packages-npm/design-system";

import { SitecoreGenericTextField } from '../../interfaces';
import {renderLink} from "../../utils/helper";

export interface GreenDoubleColumnsComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
      width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
    `;
const title = css`
  line-height: 62px;
  width: 100%;
  color: #FFFFFF;
  font-size: 48px;
  font-weight: 800;
    `;
const items = css`
      width: 100%;
  display: flex;
  color: #ffffff;
    `;
const itemLeft = css`
      width: 100%;
    `;
const itemTitle = css`
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
    `;
const itemRight = css`
      width: 100%;
    `;
const actions = css`
  margin-top: 20px;
`;
const itemText = css`
      width: 100%;
  color: #FFFFFF;
  line-height: 150%;
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  padding-right: 16px;
    `;
const value = css`
      color: #FFFFFF;
  font-weight: 800;
  font-size: 32px;
  line-height: 42px;
    `;
const subtitle = css`
  width: 100%;
  text-align: center;
  margin-top: 24px;
  display: flex;
  font-weight: 600;
  margin-bottom: 24px;
  justify-content: center;
  color: #FFFFFF;
    `;
const item = css`
  margin: 16px;
  display: flex;
  flex-direction: column;
  width: calc(33% - 32px);
  color: #FFFFFF;
    `;

export const GreenDoubleColumnsComponent: React.FunctionComponent<GreenDoubleColumnsComponentProps> = (props: GreenDoubleColumnsComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const bg: any = getFieldValue<any>(fields, 'background', false);
    const buttonLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
    const buttonLink: string = getFieldValue<string>(fields, 'btnLink', '');
    const iconLeft: string = getFieldValue<string>(fields, 'btnIcon', '');

    const parentContainer = css`
      background: url(${renderLink(bg && bg.src)});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      padding-top: 96px;
      padding-bottom: 96px;
    `;

    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={subtitle}>
                    <Text
                        field={fields && fields.subTitle as SitecoreGenericTextField}
                    />
                </div>
                <div css={items}>
                    <div css={itemLeft}>
                        <div css={itemTitle}>
                            <Text
                                field={fields && fields.titleLeft as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={itemText}>
                            <Text
                                field={fields && fields.textLeft as SitecoreGenericTextField}
                            />
                        </div>
                    </div>
                    <div css={itemRight}>
                        <div css={itemTitle}>
                            <Text
                                field={fields && fields.titleRight as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={itemText}>
                            <Text
                                field={fields && fields.textRight as SitecoreGenericTextField}
                            />
                        </div>
                    </div>
                </div>
                <div css={actions}>
                    <Button label={buttonLabel} onClick={() => buttonLink ? window.location.href = buttonLink : console.debug} iconLeft={iconLeft as any} />
                </div>
            </div>
        </div>
    );
}
