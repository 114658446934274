import * as React from "react";
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/react";
export interface HexagonVectorZeroProps {
  className?: string;
  size?: any;
  theme?: 'green' | 'red' | undefined;
  style?: React.CSSProperties;
}

export const Hexagon: React.FunctionComponent<HexagonVectorZeroProps> = (
  props: HexagonVectorZeroProps
): React.ReactElement => {
  const { className, size, style, theme } = props;
  const renderColor = () => {
    if (theme === 'green') {
      return '#005E2E'
    } else if (theme === 'red') {
      return '#CA1234'
    } else {
      return '#A4BCC2';
    }
  }
  return (
    <svg
      {...size}
      viewBox="0 0 602 384"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g opacity="0.5" clipPath="url(#clip0_276_2732)">
        <path
          d="M532.82 261.872V623.118C532.82 627.457 530.378 631.561 526.444 633.825L213.421 813.973C209.148 816.434 203.842 816.434 199.57 813.973L-113.444 633.825C-117.379 631.561 -119.82 627.457 -119.82 623.118V261.872C-119.82 257.534 -117.379 253.429 -113.444 251.166L199.579 71.0268C203.852 68.5663 209.157 68.5663 213.421 71.0268L526.434 251.166C530.378 253.439 532.82 257.534 532.82 261.872Z"
          stroke="url(#paint0_linear_276_2732)"
          strokeWidth="80"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="-65.5006"
          y1="162.134"
          x2="81.4994"
          y2="77.1343"
          stroke={renderColor()}
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_276_2732"
          x1="206.5"
          y1="69.1814"
          x2="206.5"
          y2="815.819"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={renderColor()} />
          <stop offset="1" stopColor={renderColor()} stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_276_2732">
          <rect
            width="791"
            height="384"
            fill="white"
            transform="translate(-189)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
