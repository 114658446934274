
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const BHS: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <g>
                <path fill="#00A9CE" d="M512,200.093H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,200.093L512,200.093z" />
                <path fill="#00A9CE" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V311.909h512v102.988 C512,419.773,508.047,423.725,503.172,423.725z" />
            </g>
            <rect y="200.091" fill="#FDDA25" width="512" height="111.81" />
            <path fill="#000000" d="M3.256,421.591l202.841-161.015c2.946-2.338,2.946-6.812,0-9.151L3.256,90.411 C1.311,92.03,0,94.376,0,97.104v317.793C0,417.626,1.311,419.972,3.256,421.591z" />
        </svg>
    </React.Fragment>
);
