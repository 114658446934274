import { css } from "@emotion/react";

export const style = {
  selectorContainer: css`
    display: flex;
    justify-content: space-between;
  `,
  tabSelector: css`
    width: inherit;
    flex-grow: 0;
  `,
  tab: css`
    > div {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  `,
  displayChooser: css`
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    padding: 15px 0;
  `,
  displayChooserDiv: css`
    display: flex;
    margin: auto;
  `,
  displayChooserText: css`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #354450;
    display: flex;
    flex-grow: 0;
    padding-right: 8px;
  `,
};

export default style;
