import * as React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { style } from "./index.style";
import { formatToThousandsSeparator } from "../../../../utils";
import RoundTag from "../../RoundTag/roundTag.component";
import { SecuritySummary as SecuritySummaryModel } from "../../../../interfaces";
import { Link } from "react-router-dom";
import ChartOptions from "./chart-option";
import GraphCard from "../../GraphCard/graphCard.component";
import { goToSecurityDetailPage, securityDetailPageLink } from "..";

export interface SecurityCardProps {
  security?: SecuritySummaryModel;
  index?: number
}

export function SecuritySummary({ security, index }: SecurityCardProps) {
  const bestOrders = security?.marketRealtime?.bestOrders || null;
  const graphData = {
    pricesHistory: security?.marketData?.pricesHistory || []
  };

  return security ? (
    <a css={style.linkContainer}
       href={ securityDetailPageLink(security.isin, security.id)}>
      <div css={style.securitySummary}>
        <div css={style.header}>
          <div css={style.roundedTag}>
            <RoundTag size={16}>B</RoundTag>
          </div>

          <div css={style.name}>
            <div css={style.title}>
              {security.instrumentName}
            </div>

            <div css={style.subTitle}>
              {security.isin}
            </div>
          </div>
        </div>
        
        <div css={style.market}>
          { bestOrders?.bid?.length || bestOrders?.ask?.length ?
            <div css={style.marketRealTimeCol}>
              <div css={style.marketRealTimeColLeft}>
                <div css={style.marketRealTimeColLabel}>
                  BID
                </div>
                <div css={style.marketRealTimeColAmount}>
                  { bestOrders?.bid?.length && 
                    ( parseFloat(bestOrders.bid[0].price) ? 
                      formatToThousandsSeparator(parseFloat(bestOrders.bid[0].price)) 
                      : 
                      bestOrders.bid[0].price
                    ) || 'N/A'
                  }
                </div>
                <div css={style.marketRealTimeColSubAmount}>
                  {
                    bestOrders?.bid?.length && 
                    bestOrders.bid[0].quantity > 0 && 
                    formatToThousandsSeparator(bestOrders.bid[0].quantity) || 'N/A'
                  }
                </div>
              </div>
              <div css={style.marketRealTimeColRight}>
                <div css={style.marketRealTimeColLabel}>
                  ASK
                </div>
                <div css={style.marketRealTimeColAmount}>
                  {
                    bestOrders?.ask?.length && (parseFloat(bestOrders.ask[0].price) ? formatToThousandsSeparator(parseFloat(bestOrders.ask[0].price)) : bestOrders.ask[0].price) || 'N/A'
                  }
                </div>
                <div css={style.marketRealTimeColSubAmount}>
                  {
                    bestOrders?.ask?.length && bestOrders.ask[0].quantity > 0 && formatToThousandsSeparator(bestOrders.ask[0].quantity) || 'N/A'
                  }
                </div>
              </div>
            </div>
            : <></>
          }
        </div>

        <div css={style.graph}>
            <GraphCard key={`graph-related-security-${index}`}
                       noBorder={true}
                       noMargin={true}
                       noLabel={true}
                       floatingData={true}
                       data={graphData}
                       noDataLabel={true}
                       chartOptions={ChartOptions}
                       noChartGradient={true}
            />
        </div>
      </div>
    </a>
  ) : <></>
}
