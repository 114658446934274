/** @jsx jsx */

import * as React from 'react';

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { ComponentFields, ComponentParams, ComponentRendering, Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from '../../interfaces';
import {canUseDOM} from "../../predicates";
import {getValue} from "../../utils/helper";

export interface CurrentOffersProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
`;
const container = css`
  width: 100%;
  padding: 80px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  @media (max-width: ${breakpointMax}) {
   padding: 16px;
  }
`;
const cols = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;

const col = css`
  width: 564px;
  min-width: 564px;
  background: #FFFFFF;
  border: 1px solid #E3E6E8;
  box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
  border-radius: 16px;
  margin-right: 19px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  padding-bottom: 80px;
  
  @media (max-width: ${breakpointMax}) {
    min-width: 100%;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 24px;
    margin-right: 0;
    margin-top: 16px;
  }
`;

const title = css`
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  margin-bottom: 76px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 32px;
  }
`;

const name = css`
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #CB1234;
  margin-bottom: 8px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #CB1234;
  }
`;

const role = css`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #425563;
  }
`;

const description = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  margin-top: 24px;
  margin-bottom: 24px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  }
`;

const action = css`
  margin-top: 10px;
  display: flex;
  @media (max-width: ${breakpointMax}) {
    button {
      font-size: 12px;
    }
  }
`;

const actionButton = css`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  margin-left: 16px;
  @media (max-width: ${breakpointMax}) {
    margin-top: 16px;
    justify-content: flex-start;
    button {
      font-size: 12px;
    }
  }
`;

export const CurrentOffers: React.FunctionComponent<CurrentOffersProps> = (props: CurrentOffersProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={cols}>
                    {
                        new Array(10).fill(0).map((arr: any, index: number) => {
                            if (fields && fields[`offer ${index + 1} title`] && (fields && fields[`offer ${index + 1} title`] as any).value) {
                            return (
                                <div css={col} key={`offer-${index}`}>
                                    <div css={name}>
                                        <Text
                                            field={fields && fields[`offer ${index + 1} title`] as SitecoreGenericTextField}
                                        />
                                    </div>
                                    <div css={role}>
                                        <Text
                                            field={fields && fields[`offer ${index + 1} role`] as SitecoreGenericTextField}
                                        />
                                    </div>
                                    <div css={description}>
                                        <Text
                                            field={fields && fields[`offer ${index + 1} description`] as SitecoreGenericTextField}
                                        />
                                    </div>
                                    <div css={action}>
                                        <Button
                                            label={(fields && fields[`offer ${index + 1} button label`] && (fields && fields[`offer ${index + 1} button label`] as any).value)} iconRight={'arrow-right'}
                                            onClick={() =>
                                                fields && fields[`offer ${index + 1} button label`] && ((fields && fields[`offer ${index + 1} button label`] as any).value) ?
                                                    window.location.href = ((fields && fields[`offer ${index + 1} button link`] as any).value) :
                                                    console.debug()
                                        }
                                        />
                                    </div>
                                </div>
                            )
                            }
                        })
                    }
                </div>
            </div>
            <div css={actionButton}>
                <Button label={(fields && fields[`more link label`] && (fields && fields[`more link label`] as any).value)} onClick={() => canUseDOM && window.open((fields && fields[`more link`] && (fields && fields[`more link`] as any).value), '_blank')} iconRight={'arrow-right'} />
            </div>
        </div>
    );
}
