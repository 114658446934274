import { breakpointMax } from "../../../../../style";
import { css, jsx } from "@emotion/react";

const lint = jsx; // linter hack

export const style = {
  roundTag: css`
    > div {
      width: 1.5em;
      height: 1.5em;
      font-size: 35px;
      margin-right: 0.5rem;
    }
  `,
  bannerContainer: css`
    font-size: 1.5em;
    display: flex;
    @media (max-width: ${breakpointMax}) {
      font-size: 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `,
  lastPrice: css`
    font-size: 1.5em;
    @media (max-width: ${breakpointMax}) {
      font-size: 18px;
      > div {
        justify-content: center;
        align-items: center;
      }
    }
  `,
  info: css`
    display: flex;
    flex-direction: column;
    color: white;
    gap: 10px;
    font-size: 36px;
    margin-left: 20px;
    @media (max-width: ${breakpointMax}) {
      margin-top: 16px;
      font-size: 24px;
    }
  `,
  country: css`
    display: flex;
    gap: 0.5em;
    align-content: center;
    font-size: 1.2em;
  `,
  securityNameText: css``,
  subtitle: css`
    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: center;
  `,
  isin: css``,
  metadata: css`
    display: flex;
    align-items: stretch;
    align-content: center;

    > * {
      padding: 1em;
      border-right: 1px solid #253645;
      text-align: center;
      vertical-align: middle;
      line-height: 2.25em;
    }
  `,

  actions: css`
    padding: 0.5em;
    border-right: none;
    margin-left: auto;
    display: flex;
    gap: 1em;
  `,
};
