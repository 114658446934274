/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import SustainableTabs from "../../core/components/SustainableTabs/sustainableTabs.component";
import { style } from "../../core/components/SustainableTabs/sustainableTabs.style";
import { renderLink } from "../../utils/helper";
import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
import Utils from "../../core/utils/utils";
import { SEARCH_LGX_DATA_COUNTER } from "../../graphql";

interface LuxseSearchStringFilterCount {
  name: string;
  count: number;
  __typename: string;
}
export interface LuxseSearchSecurityFilters {
  totalHits: number;
  filters: {
    lgxSustainableBonds?: LuxseSearchStringFilterCount[];
    lgxSustainableFunds?: LuxseSearchStringFilterCount[];
    climateAlignedOnly: number;
    genderBondOnly: number;
    __typename?: string;
  };
  __typename?: string;
}
function countElements(jsonObj: LuxseSearchSecurityFilters): {
  bondsCount: number;
  fundsCount: number;
} {
  const bondsCount =
    jsonObj.filters.lgxSustainableBonds?.reduce(
      (total, bond) => total + bond.count,
      0
    ) || 0;
  const fundsCount =
    jsonObj.filters.lgxSustainableFunds?.reduce(
      (total, fund) => total + fund.count,
      0
    ) || 0;

  return { bondsCount, fundsCount };
}

export interface SustainableTabsComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}
const title = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 32px;
`;
export const SustainableTabsComponent: React.FunctionComponent<SustainableTabsComponentProps> =
  (props: SustainableTabsComponentProps): React.ReactElement => {
    const { fields } = props;
    const [selected, setSelected] = React.useState(0);
    const background: any = getFieldValue<any>(fields, "background", null);

    const [counter, setCounter] = React.useState(undefined);
    const [lgxData, setLgxdata] = React.useState(undefined);

    const getqueryData = async () => {
      const client = luxseGatewayGraphqlClient;
      const results: any = await client.query({
        query: SEARCH_LGX_DATA_COUNTER,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          page: 0,
          size: 0,
          sort: "listingDate,asc",
          statuses: ["ADMI", "NEGO", "COTE"],
          statusesOperator: "OR",
          lgxOnly: true,
        },
      });

      if (results && results.data) {
        const data = results.data.luxseSecuritiesSearch;
        console.log("res request", data);
        setLgxdata(data);
        const counts = countElements(data);
        setCounter(counts);
        console.log("counts", counts);
      }
    };

    React.useEffect(() => {
      getqueryData();
    }, []);
    const blocs = [];

    for (let i = 1; i < 6; i++) {
      if (
        fields &&
        fields[`bloc ${i} content`] &&
        fields[`bloc ${i} content`].value
      ) {
        const data = JSON.parse(fields && fields[`bloc ${i} content`].value);
        try {
          blocs.push(data);
        } catch (e) {
          console.log("invalid json");
        }
      }
    }

    const parentContainer = css`
      width: 100%;
      display: flex;
      padding-top: 96px;
      padding-bottom: 96px;
      background-image: url(${renderLink(background && background.src)});
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    `;
    const container = css`
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 780px;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      align-items: center;
    `;

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={style.header} onClick={() => setSelected(0)}>
            <Text
              field={fields && (fields.title as SitecoreGenericTextField)}
            />
          </div>

          <div css={title}>
            <Text field={fields && (fields.role as SitecoreGenericTextField)} />
          </div>
          {blocs.map((bloc: any, index: number) => (
            <div css={style.row} key={`block-${index}`}>
              <SustainableTabs
                data={bloc}
                total={index === 0 ? counter?.bondsCount : counter?.fundsCount}
                lgxData={lgxData}
                index={index}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };
