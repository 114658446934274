
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {style} from "../LinkedArticle/index.style";
import avatar from "../LinkedArticle/Ellipse.png";
import {renderLink} from "../../utils/helper";

export interface ArticleHeaderWithBackgroundProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}
const container = css`
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  color: #FFFFFF;
`;
const title = css`
  margin-bottom: 32px;
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  color: #FFFFFF;
`;

export const ArticleHeaderWithBackground: React.FunctionComponent<ArticleHeaderWithBackgroundProps> = (props: ArticleHeaderWithBackgroundProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const background: any = getFieldValue<any>(fields, 'background', 0);
    const topContainer = css`
      width: 100%;
      background: url(${renderLink(background && background.src)}) no-repeat;
      background-color: #f0f0f0;
      background-size: cover;
      min-height: 340px;
      max-height: 340px;
      display: flex;
      padding-bottom: 96px;
      position: relative;
    `;
    return (
        <div css={topContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={style.author}>
                    <div css={style.avatar}>
                        <img src={renderLink(avatar)} alt={"image"} />
                    </div>
                    <div css={style.separator}>
                        By
                    </div>
                    <Text
                        field={fields && fields.author as SitecoreGenericTextField}
                    />
                    <div css={style.separator}>
                        inse
                    </div>
                    <div css={style.relatedSubject}>
                        <Text
                            field={fields && fields.relatedSubject as SitecoreGenericTextField}
                        />
                    </div>
                    <div css={style.separator}>
                        •
                    </div>
                    <Text
                        field={fields && fields.timeToRead as SitecoreGenericTextField}
                    />
                    <div css={style.separator}>
                        •
                    </div>
                    <Text
                        field={fields && fields.date as SitecoreGenericTextField}
                    />
                </div>
            </div>
        </div>
    );
}
