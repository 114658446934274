
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface GenderEqualitySvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const GenderEqualitySvg: React.FunctionComponent<GenderEqualitySvgProps> = ({ size: { width, height }, style }: GenderEqualitySvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 39 51'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>5 - Gender Equality</title>
            <path d='M4.56746 4.85915C3.76582 4.85915 3.17671 5.14252 2.85606 5.69808H2.83741V2.68167H6.3348V1.02246H0.98059V7.83827H2.83368V7.56981C2.83368 6.85766 3.15434 6.46243 3.72481 6.46243C4.35121 6.46243 4.60102 6.89121 4.60102 7.55117V10.4781C4.60102 11.1194 4.36985 11.5668 3.74345 11.5668C3.04621 11.5668 2.7964 11.1045 2.7964 10.3886V8.87108H0.939575V10.2432C0.939575 12.0814 1.67037 13.2596 3.74345 13.2596C5.79416 13.2596 6.63309 12.1709 6.63309 10.493V7.53253C6.63682 5.62723 5.6562 4.85915 4.56746 4.85915Z' fill='white'/>
            <path d='M12.1328 8.21484H14.1014V8.88599H12.9605V10.2879H13.777V10.9516H12.9605V12.443H14.1014V13.1142H12.1328V8.21484Z' fill='white'/>
            <path d='M15.4176 13.4495V13.1177C14.7614 13.0021 14.478 12.5174 14.478 11.8537V9.48233C14.478 8.71425 14.8546 8.15869 15.7234 8.15869C16.5996 8.15869 16.9762 8.71425 16.9762 9.48233V11.8537C16.9762 12.3906 16.7897 12.8082 16.3684 13.0095L16.9762 13.1251V13.7403L15.4176 13.4495ZM16.1484 12.0252V9.3481C16.1484 9.04982 16.0179 8.84102 15.7234 8.84102C15.4325 8.84102 15.3058 9.04982 15.3058 9.3481V12.0252C15.3058 12.316 15.4363 12.5323 15.7234 12.5323C16.0179 12.5323 16.1484 12.316 16.1484 12.0252Z' fill='white'/>
            <path d='M19.9031 8.21484V11.9248C19.9031 12.6854 19.5787 13.1776 18.7398 13.1776C17.871 13.1776 17.5093 12.6854 17.5093 11.9248V8.21484H18.3334V12.0031C18.3334 12.2939 18.4489 12.4878 18.7398 12.4878C19.0306 12.4878 19.1462 12.2939 19.1462 12.0031V8.21484H19.9031Z' fill='white'/>
            <path d='M20.3132 13.1179L21.2379 8.21484H22.1775L23.1097 13.1179H22.2633L22.1104 12.223H21.2342L21.0888 13.1179H20.3132ZM21.3498 11.5631H22.006L21.6816 9.59814H21.6742L21.3498 11.5631Z' fill='white'/>
            <path d='M23.5198 8.21484H24.3438V12.4468H25.3281V13.1179H23.5198V8.21484Z' fill='white'/>
            <path d='M25.7681 8.21484H26.5921V13.1179H25.7681V8.21484Z' fill='white'/>
            <path d='M27.0358 8.21484H29.2282V8.88599H28.5496V13.1179H27.7182V8.88971H27.0396V8.21484H27.0358Z' fill='white'/>
            <path d='M31.2714 11.2536V13.1179H30.4474V11.2536L29.5302 8.21484H30.3915L30.8911 10.0717H30.8986L31.3982 8.21484H32.1849L31.2714 11.2536Z' fill='white'/>
            <path d='M13.3781 3.30816H14.5489V5.92561H13.956V5.38869C13.8479 5.75782 13.6167 5.98153 13.1693 5.98153C12.4609 5.98153 12.1365 5.41852 12.1365 4.65789V2.28653C12.1365 1.51845 12.5056 0.962891 13.3744 0.962891C14.2841 0.962891 14.5526 1.46997 14.5526 2.19332V2.62583H13.7994V2.1262C13.7994 1.82419 13.6838 1.64895 13.3855 1.64895C13.0873 1.64895 12.9605 1.85775 12.9605 2.15603V4.78839C12.9605 5.08668 13.0835 5.29548 13.3669 5.29548C13.6354 5.29548 13.7733 5.14261 13.7733 4.82568V3.95692H13.3818V3.30816H13.3781Z' fill='white'/>
            <path d='M15.0932 1.02246H17.0619V1.6936H15.9172V3.09554H16.7338V3.75922H15.9172V5.25065H17.0582V5.92179H15.0895V1.02246H15.0932Z' fill='white'/>
            <path d='M18.2699 2.84573V5.92552H17.5764V1.02246H18.3855L19.2729 3.8338V1.02246H19.959V5.92552H19.2356L18.2699 2.84573Z' fill='white'/>
            <path d='M22.9717 2.33864V4.60934C22.9717 5.36251 22.6622 5.92552 21.7785 5.92552H20.5034V1.02246H21.7748C22.6585 1.02246 22.9717 1.57802 22.9717 2.33864ZM21.6741 5.25438C22.0134 5.25438 22.1439 5.05303 22.1439 4.75475V2.18577C22.1439 1.89494 22.0134 1.6936 21.6741 1.6936H21.3274V5.25065H21.6741V5.25438Z' fill='white'/>
            <path d='M23.5198 1.02246H25.4885V1.6936H24.3438V3.09554H25.1603V3.75922H24.3438V5.25065H25.4847V5.92179H23.5161V1.02246H23.5198Z' fill='white'/>
            <path d='M26.8196 3.6772V5.92552H25.9955V1.02246H27.1961C28.0276 1.02246 28.3296 1.43633 28.3296 2.12239V2.75251C28.3296 3.28197 28.1432 3.59144 27.6734 3.69957L28.4601 5.92552H27.5914L26.8196 3.6772ZM26.8196 1.6936V3.21858H27.0955C27.3788 3.21858 27.5019 3.07317 27.5019 2.81217V2.08883C27.5019 1.82037 27.3788 1.68987 27.0955 1.68987H26.8196V1.6936Z' fill='white'/>
            <path d='M38.7025 18.7031H31.2453C31.1931 18.7031 31.1521 18.7441 31.1521 18.7963V21.2758C31.1521 21.328 31.1969 21.3728 31.2453 21.3728H34.2133L30.9172 24.6688C29.258 23.3638 27.1663 22.5846 24.8956 22.5846C19.5116 22.5846 15.1268 26.9656 15.1268 32.3534C15.1268 37.3124 18.8404 41.4138 23.6316 42.0365V44.8925H20.6898C20.6339 44.8925 20.5928 44.9336 20.5928 44.9858V47.6405C20.5928 47.6927 20.6339 47.7337 20.6898 47.7337H23.6316V50.545C23.6316 50.5972 23.6726 50.6383 23.7248 50.6383H26.3796C26.4318 50.6383 26.4765 50.5972 26.4765 50.545V47.7337H29.4183C29.4705 47.7337 29.5116 47.6927 29.5116 47.6405V44.9858C29.5116 44.9336 29.4705 44.8925 29.4183 44.8925H26.4765V41.9917C31.1111 41.2311 34.657 37.2005 34.657 32.3571C34.657 30.2654 33.997 28.3303 32.8747 26.7382L36.126 23.4869V26.2609C36.126 26.3131 36.1708 26.3541 36.223 26.3541H38.6987C38.7509 26.3541 38.7957 26.3131 38.7957 26.2609V18.8038C38.7994 18.7479 38.7547 18.7031 38.7025 18.7031ZM24.8956 39.5421C20.9321 39.5421 17.7069 36.3168 17.7069 32.3534C17.7069 28.3899 20.9321 25.1647 24.8956 25.1647C28.8591 25.1647 32.0843 28.3899 32.0843 32.3534C32.0843 36.3168 28.8591 39.5421 24.8956 39.5421Z' fill='white'/>
            <path d='M29.3587 29.4004H20.6376C20.5854 29.4004 20.5444 29.4377 20.5444 29.4861V31.5965C20.5444 31.645 20.5854 31.6823 20.6376 31.6823H29.3587C29.4146 31.6823 29.4556 31.645 29.4556 31.5965V29.4861C29.4556 29.4377 29.4146 29.4004 29.3587 29.4004Z' fill='white'/>
            <path d='M29.3587 33.1699H20.6376C20.5854 33.1699 20.5444 33.2109 20.5444 33.2594V35.3698C20.5444 35.4182 20.5854 35.4555 20.6376 35.4555H29.3587C29.4146 35.4555 29.4556 35.4182 29.4556 35.3698V33.2594C29.4556 33.2109 29.4146 33.1699 29.3587 33.1699Z' fill='white'/>
            <path d='M38.7025 18.7031H31.2453C31.1931 18.7031 31.1521 18.7441 31.1521 18.7963V21.2758C31.1521 21.328 31.1969 21.3728 31.2453 21.3728H34.2133L30.9172 24.6688C29.258 23.3638 27.1663 22.5846 24.8956 22.5846C19.5116 22.5846 15.1268 26.9656 15.1268 32.3534C15.1268 37.3124 18.8404 41.4138 23.6316 42.0365V44.8925H20.6898C20.6339 44.8925 20.5928 44.9336 20.5928 44.9858V47.6405C20.5928 47.6927 20.6339 47.7337 20.6898 47.7337H23.6316V50.545C23.6316 50.5972 23.6726 50.6383 23.7248 50.6383H26.3796C26.4318 50.6383 26.4765 50.5972 26.4765 50.545V47.7337H29.4183C29.4705 47.7337 29.5116 47.6927 29.5116 47.6405V44.9858C29.5116 44.9336 29.4705 44.8925 29.4183 44.8925H26.4765V41.9917C31.1111 41.2311 34.657 37.2005 34.657 32.3571C34.657 30.2654 33.997 28.3303 32.8747 26.7382L36.126 23.4869V26.2609C36.126 26.3131 36.1708 26.3541 36.223 26.3541H38.6987C38.7509 26.3541 38.7957 26.3131 38.7957 26.2609V18.8038C38.7994 18.7479 38.7547 18.7031 38.7025 18.7031ZM24.8956 39.5421C20.9321 39.5421 17.7069 36.3168 17.7069 32.3534C17.7069 28.3899 20.9321 25.1647 24.8956 25.1647C28.8591 25.1647 32.0843 28.3899 32.0843 32.3534C32.0843 36.3168 28.8591 39.5421 24.8956 39.5421Z' fill='white'/>
            <path d='M29.3587 29.4004H20.6376C20.5854 29.4004 20.5444 29.4377 20.5444 29.4861V31.5965C20.5444 31.645 20.5854 31.6823 20.6376 31.6823H29.3587C29.4146 31.6823 29.4556 31.645 29.4556 31.5965V29.4861C29.4556 29.4377 29.4146 29.4004 29.3587 29.4004Z' fill='white'/>
            <path d='M29.3587 33.1699H20.6376C20.5854 33.1699 20.5444 33.2109 20.5444 33.2594V35.3698C20.5444 35.4182 20.5854 35.4555 20.6376 35.4555H29.3587C29.4146 35.4555 29.4556 35.4182 29.4556 35.3698V33.2594C29.4556 33.2109 29.4146 33.1699 29.3587 33.1699Z' fill='white'/>

        </svg>
    );
};
