
import { useCallback, useEffect, useState } from "react";
import moment from "moment-timezone";
import { Country } from "../../../enums";
import { OAMTypeDepo } from "../../../enums/oam-type-depo";
import { formatDate } from "../../../utils/date";
import {canUseDOM} from "../../../predicates";
import { GET_OAM_SUBMISSIONS_SEARCH } from "../../../graphql";
import {luxseGatewayGraphqlClient} from "../../../luxse-gateway-graphql-client";

export type OAM_LANGUAGE = "EN" | "FR";

export interface OAMFilters {
  oam_idTypeDepo: OAMTypeDepo | null;
  oam_libEmet: string;
  oam_codifTiersDecla: string;
  oam_codifInstr: string;
  publicationDate: {
    fromDate: moment.Moment | null;
    toDate: moment.Moment | null;
  };
  oam_paysEmet: keyof Country | null;
  oam_anRef: string | null
  lang: OAM_LANGUAGE;
  hide_canceled: boolean;
}

export interface OAMResults {
  resultsCount: number;
  deposits: OAMResultsDeposit[]
}

export interface OAMResultsDeposit {
  dtDebPerRef: number;
  dtFinPerRef: number;
  dtPubli: number;
  idSoumis: number;
  libLgTiersDecla: string;
  libTypeDepo: string;
  listeActionDepo: string;
}

export const DEFAULT_OAM_FILTERS: OAMFilters = {
  oam_idTypeDepo: null,
  oam_libEmet: canUseDOM ? new URLSearchParams(window.location.search).get('q') : '',
  oam_codifTiersDecla: "",
  oam_codifInstr: "",
  publicationDate: {
    fromDate: null,
    toDate: null,
  },
  oam_paysEmet: null,
  oam_anRef: null,
  hide_canceled: false,
  lang: "EN",
}

export const DEFAULT_RESULTS: OAMResults = {
  resultsCount: 0,
  deposits: [],
};


export interface UseFilters {
  filters: OAMFilters,
  onFilterChange: (aKey: keyof OAMFilters) => (value: OAMFilters[typeof aKey]) => void
  onReset: () => void
  loading: boolean,
  search: () => void
  results: OAMResults
}
export default function useFilters(): UseFilters {
  const [filters, setFilters] = useState<OAMFilters>(DEFAULT_OAM_FILTERS);
  const [loading, setLoading] = useState<boolean>(true);
  const [results, setResults] = useState<OAMResults | null>(null);
  const client = luxseGatewayGraphqlClient;

  const doSearch = useCallback(async (filters: OAMFilters) => {
    setLoading(true);
    const { publicationDate, ...restFilters } = filters;

    try {
      const response = await client.query({
        query: GET_OAM_SUBMISSIONS_SEARCH,
        variables: {
          depositType: restFilters.oam_idTypeDepo ?parseInt(restFilters.oam_idTypeDepo.substring(2)) : null,
          issuerName: restFilters.oam_libEmet,
          referenceYear: restFilters.oam_anRef ? parseInt(restFilters.oam_anRef) : null,
          cssfCode: restFilters.oam_codifTiersDecla,
          isin: restFilters.oam_codifInstr,
          publicationStartDate: publicationDate.fromDate ? formatDate(publicationDate.fromDate, "YYYY-MM-DD") : null,
          publicationEndDate: publicationDate.toDate ? formatDate(publicationDate.toDate, "YYYY-MM-DD") : null,
          countryCodeIso: restFilters.oam_paysEmet,
        },
      });

      const data = response.data.oamSubmissionsSearch;

      setResults({
        resultsCount: data.totalHits,
        deposits: data.submissions.map((submission: any) => ({
          dtDebPerRef:  moment(submission.referenceStartDate).subtract(2, 'h'),
          dtFinPerRef:  moment(submission.referenceEndDate).subtract(2, 'h'),
          dtPubli: moment(submission.publicationDate).subtract(2, 'h'),
          idSoumis: submission.submissionId,
          libLgTiersDecla: submission.issuerName,
          libTypeDepo: submission.submissionTypeLabel,
          listeActionDepo: submission.actionsList,
        })),
      });
      setLoading(false);
    } catch (e) {
      console.error("Failed to get OAM search: ", e.message);
      setResults(DEFAULT_RESULTS);
      setLoading(false);
    }
  }, [client]);

  const search = useCallback(() => {
    setLoading(true);
    setFilters(filters => ({
      ...filters,
    }));
  }, []);

  const onFilterChange = useCallback((aKey: keyof OAMFilters) => (value: OAMFilters[typeof aKey]) => {
    setLoading(true);
    setFilters(filters => ({
      ...filters,
      [aKey]: value,
    }));
  }, []);

  const onReset = useCallback(() => {
    setLoading(true);
    setFilters(DEFAULT_OAM_FILTERS);
  }, []);

  useEffect(() => {
    setResults(null);
    setLoading(true);
    doSearch(filters);
  }, [filters, doSearch]);

  return {
    filters,
    onFilterChange,
    onReset,
    loading,
    search,
    results,
  };
}