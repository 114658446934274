/** @jsx jsx */
import { css,jsx } from '@emotion/react';

import { style } from './index.style';
import * as React from 'react';
import { formatToThousandsSeparator } from '../../../../../../utils';
import { FilterTag } from '../../useTagFilters';
import ConceptualBanner from '../../../../ConceptualBanner/conceptualBanner.component';
import { Icon, Link, Tag } from '@bdl-cmn-shared-packages-npm/design-system';
import { lgxSustainableBondLabel } from '../../../../../../enums/lgx-sustainable-bond';
import { lgxSustainableFundLabel } from '../../../../../../enums/lgx-sustainable-fund';
import { lgxStandardLabel } from '../../../../../../enums/lgx-standard';
import { lgxExternalReviewLabel } from '../../../../../../enums/lgx-external-review';
import { lgxExternalReportingLabel } from '../../../../../../enums/lgx-external-reporting';
import { lgxSustainableDevelopmentGoalLabel } from '../../../../../../enums/lgx-sustainable-development-goal';
import { lgxEligibleCategoryLabel } from '../../../../../../enums/lgx-eligible-category';
import { issuerSubTypeLabel } from '../../../../../../enums/issuer-subtype';
import { issuerTypeLabel } from '../../../../../../enums/issuer-type';
import { lgxSlbKpiThemeLabel } from '../../../../../../enums/lgx-slb-kpi-theme';
import { documentTypeLabel } from '../../../../../../enums/document-type';
import { documentSubTypeLabel } from '../../../../../../enums/document-sub-type';
import { programmeTypeLabel } from '../../../../../../enums/programme-type';
import {
  countryLabel,
  couponTypeLabel,
  currencyLabel,
  currencyLongLabel,
  levelOneClassificationLabel,
  levelTwoClassificationLabel,
  marketRuleLabel,
} from '../../../../../../enums';
import { indexTypeLabel } from '../../../../../../enums/index-type';
import { indexLatestPriceDateLabel } from '../../../../../../enums/index-latest-price-date';
import { programmeStartDateLabel } from '../../../../../../enums/programme-start-date';
import { programmeEndDateLabel } from '../../../../../../enums/programme-end-date';
import { eligibleCategoryLabel } from '../../../../UI/list/luxXPrime/eligible-category-filter';
import { securityCategoryLabel } from '../../../../../../enums/security-category';
import { setSustainableClassificationLabel } from '../../../../../../graphql/workers/sustainable-classification.worker';
import { SUSTAINABLE_CLASSIFICATIONS } from '../../../useFilters';
import { periodicityCodeLabel } from '../../../../../../graphql';
import Loader from '../../../../Loader/loader.component';
import { getIssuerNameForLuxXPrime } from '../../../../UI/list/luxXPrime/issuer-name-filter';

const splitFilterValues = (valueToSplit: string): string[] => {
  let values = valueToSplit != null ? valueToSplit.split(',') : [];
  values = values.filter((split) => split);
  return values;
};

const getFilterLabel = (section: string, name: string, value: string): string => {
  switch (section?.toLowerCase()) {
    case 'securities': {
      switch (name) {
        case 'categories':
        case 'category':
          return securityCategoryLabel(value);
        case 'securityMarketRules':
          return marketRuleLabel(value);
        case 'lgxSustainableBonds':
          return lgxSustainableBondLabel(value);
        case 'lgxSustainableFunds':
          return lgxSustainableFundLabel(value);
        case 'lgxStandards':
          return lgxStandardLabel(value);
        case 'lgxExternalReviews':
          return lgxExternalReviewLabel(value);
        case 'lgxExternalReportings':
          return lgxExternalReportingLabel(value);
        case 'lgxSdgs':
          return lgxSustainableDevelopmentGoalLabel(value);
        case 'lgxEligibleCategories':
          return lgxEligibleCategoryLabel(value);
        case 'lgxSlbKpiThemes':
          return lgxSlbKpiThemeLabel(value);
      }
      break;
    }

    case 'issuers': {
      switch (name) {
        case 'issuerTypes':
          return issuerTypeLabel(value);
        case 'issuerSubTypes':
          return issuerSubTypeLabel(value);
        case 'country':
          return countryLabel(value);
      }
      break;
    }

    case 'programmes': {
      switch (name) {
        case 'programmeTypes':
          return programmeTypeLabel(value);
        case 'programmeStartDate':
          return programmeStartDateLabel(value);
        case 'programmeEndDate':
          return programmeEndDateLabel(value);
      }
      break;
    }

    case 'documents': {
      switch (name) {
        case 'documentTypes':
          return documentTypeLabel(value);
        case 'documentSubTypes':
          return documentSubTypeLabel(value);
      }
      break;
    }

    case 'indices': {
      switch (name) {
        case 'indexTypes':
          return indexTypeLabel(value);
        case 'indexCurrencies':
          return currencyLabel(value);
        case 'indexLatestPriceDate':
          return indexLatestPriceDateLabel(value);
      }
      break;
    }

    case 'luxxprime': {
      switch (name) {
        case 'eligibleProjectCategories':
          return eligibleCategoryLabel(value);
        case 'sdgs':
          return lgxSustainableDevelopmentGoalLabel(value);
        case 'sustainableClassification':
          return setSustainableClassificationLabel(value as SUSTAINABLE_CLASSIFICATIONS);
        case 'couponType':
          return couponTypeLabel(value);
        case 'levelOneClassifications':
          return levelOneClassificationLabel(value);
        case 'levelTwoClassifications':
          return levelTwoClassificationLabel(value);
        case 'currencies':
          return currencyLongLabel(value);
        case 'periodicityFilter':
          return periodicityCodeLabel(value);
        case 'issuerIds':
          return getIssuerNameForLuxXPrime(value);
      }
    }
  }

  return value;
};

export interface TagFiltersProps<T> {
  nbResults: number;
  filters: FilterTag<T>[];
  onFilterClear: (key: keyof T, value: string) => void;
  onReset: () => void;
  label?: string;
  searchTerm: string;
  loader:boolean;
}

export function TagFilters<T>({ nbResults, filters, onFilterClear, onReset, label, searchTerm,loader }: TagFiltersProps<T>) {
  // use this filterLang for oam search
  const filterLang: any = filters.find((x: any) => x.name === 'lang');

  return (
    <ConceptualBanner
      reduceOnScroll={false}
      renderLeft={
        <div css={style.resultsWrapper}>
          <h1 css={style.header}>
            <span css={style.emphasizedText}>{formatToThousandsSeparator(nbResults)}</span>
            &nbsp; {filterLang?.value === 'FR' ? 'résultat' : 'result'}
            {nbResults > 1 && 's'}
            {searchTerm && (
              <>
                &nbsp;{filterLang?.value === 'FR' ? 'trouvé pour' : 'found for'} &nbsp;
                <span css={style.emphasizedText}>"{searchTerm}"</span>
              </>
            )}
            {label && (
              <>
                &nbsp;in&nbsp;
                <span css={style.emphasizedText}>{label}</span>
              </>
            )}
          </h1>
          {<Loader loading={loader} centered css={{height:20}}>
            <div css={[style.tagsWrapper,css`flex-wrap: wrap;`]}>
              {filters?.map((filter) => {
                const values = splitFilterValues(filter.value);
                const tags = [];
                if (values != null && values.length)
                  for (let index = 0; index < values.length; index++) {
                    if (values[index] != null && values[index].length)
                      tags.push(
                        <Tag
                          key={filter.name.toString()}
                          label={getFilterLabel(label, filter.name.toString(), values[index])}
                          onClose={() => onFilterClear(filter.name, values[index])}
                        />
                      );
                  }
                return tags;
              })}
            </div>
          </Loader>}
        </div>
      }
      renderRight={() =>
        filters?.length > 0 ? (
          <div css={style.resetLink}>
            <Link href='#' onClick={onReset}>
              <div css={style.closeBTN}>
                <Icon icon={'plus'} />
              </div>
              {filterLang?.value === 'FR' ? 'Réinitialiser tous les filtres' : 'Reset all filters'}
            </Link>
          </div>
        ) : null
      }
    ></ConceptualBanner>
  );
}
