import * as React from "react";

import { css, jsx } from "@emotion/react";
import { Density } from "../../UI/list/customizer/customizer.component";
import { DocumentFilters } from "../../UI/list/document/document-filters";
import DocumentSearchCard from "../../UI/list/document/card-mobile";
import { DocumentSearchResultDto } from "../../../../interfaces/search/search";
import { DocumentsListView } from "../../UI/list/document";
import FilterButton from "../../Search/results/searchMobile/filterButton";
import Loader from "../../../../core/components/Loader/loader.component";
import MobileFilterContainer from "../../Search/results/searchMobile/mobileFilterContainer";
import { Query } from "react-apollo";
import { Row } from "@bdl-cmn-shared-packages-npm/design-system";
import SearchFilterParams from "../../../../interfaces/search/search-filter-params";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import { genericStyle } from "../../UI/generic/generic.style";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { searchSortData } from "../../Search/results/dataMapper";
import { useState } from "react";
import Utils from "../../../utils/utils";
import {
  GET_SEARCH_DOCUMENTS_ONLY_RESULTS,
  ProgrammeDetail,
} from "../../../../graphql";

/** @jsx jsx */

export interface Row {
  label: string;
  action: () => void;
}

export interface DocumentsProps {
  label?: string;
  children?: any;
  programmeData?: ProgrammeDetail;
  rows?: Row[];
  filters?: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
}

const ProgrammeDocuments: React.FunctionComponent<DocumentsProps> = ({
  programmeData,
  filters,
  onFiltersChange,
}: DocumentsProps) => {
  const selectedCols: any = null;
  const density: Density = "medium";

  const [itemsNumber, setItemsNumber] = useState<number>(20);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [sort, setSort] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<string>("");

  const onPaginationUpdate = (pageNumber: number) => {
    Utils.moveToTop();
    setCurrentPageNumber(pageNumber);
  };

  React.useEffect(() => {
    setCurrentPageNumber(0);
  }, [filters]);

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const switchFilter = () => {
    setMenuIsOpen(!menuIsOpen);
  };
  return (
    <Query<{ luxseDocumentsSearch: DocumentSearchResultDto }, any>
      client={luxseGatewayGraphqlClient}
      query={GET_SEARCH_DOCUMENTS_ONLY_RESULTS}
      variables={{
        ...filters,
        token: Utils.getCookie("Gztoken-Dsm"),
        searchTerm: searchTerm ? searchTerm : "",
        size: itemsNumber,
        page: currentPageNumber,
        sort: sort && sort.field ? `${sort.field},${sort.dir}` : "",
        programmeId: programmeData.id,
      }}
      fetchPolicy="network-only"
      errorPolicy="all"
    >
      {({ loading, error, data }): React.ReactNode => {
        return (
          <Loader
            loading={loading && !data?.luxseDocumentsSearch}
            padding={"5em 0 !important"}
          >
            <div css={genericStyle.desktopDisplay}>
              <DocumentsListView
                data={data.luxseDocumentsSearch}
                loadingPage={loading}
                density={density}
                onPaginationUpdate={onPaginationUpdate}
                currentPageNumber={currentPageNumber}
                totalResultSize={data?.luxseDocumentsSearch?.totalHits || 0}
                filters={filters}
                onFiltersChange={onFiltersChange}
                sortData={searchSortData}
                sort={sort}
                onSortChange={setSort}
                selectedCols={selectedCols}
                fastLaneUrls={programmeData.fastLaneUrls}
              />
            </div>
            <div css={genericStyle.mobileDisplay}>
              <div css={{ padding: "2em", width: "100%" }}>
                <SearchMobile
                  onPaginationUpdate={onPaginationUpdate}
                  pageNumber={currentPageNumber}
                  data={data?.luxseDocumentsSearch?.documents}
                  totalResultSize={data?.luxseDocumentsSearch?.totalHits}
                  Card={(card) => (
                    <DocumentSearchCard
                      key={`card-documents-${card?.id}`}
                      card={card}
                      fastLaneUrls={programmeData.fastLaneUrls}
                    />
                  )}
                />
              </div>
              <FilterButton onSwitchFilter={switchFilter} open={menuIsOpen} />
              <MobileFilterContainer open={menuIsOpen}>
                <DocumentFilters
                  filterCount={data?.luxseDocumentsSearch?.filters}
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  fullWidth
                />
              </MobileFilterContainer>
            </div>
          </Loader>
        );
      }}
    </Query>
  );
};

export default ProgrammeDocuments;
