import SitecoreContextFactory from "../lib/SitecoreContextFactory";

import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import {
  Placeholder,
  VisitorIdentification,
  RouteData
} from "@sitecore-jss/sitecore-jss-react";
import { SitecoreContext } from "../interfaces";

import { ImageFieldValue } from "@sitecore-jss/sitecore-jss-react/types/components/Image";
import {canUseDOM} from "../predicates";
import { WEBSITE_DOMAIN } from "../core/constants";

export interface LayoutProps {
  route: RouteData;
}

export const Layout: React.FunctionComponent<LayoutProps> = ({
  route
}: LayoutProps): React.ReactElement => {
  /*const sitecoreContext: SitecoreContext = SitecoreContextFactory.getSitecoreContext() as SitecoreContext;
  const hasPathname: boolean =
    !!sitecoreContext &&
    !!sitecoreContext.routerRouteLocation &&
    !!sitecoreContext.routerRouteLocation.pathname;*/
  const hasPageDescription: boolean =
    !!route.fields &&
    !!route.fields.pageDescription &&
    !!route.fields.pageDescription.value;
  const hasPageTitle: boolean =
    !!route.fields &&
    !!route.fields.pageTitle &&
    !!route.fields.pageTitle.value;
  const hasPageImage: boolean =
    !!route.fields &&
    !!route.fields.pageImage &&
    !!route.fields.pageImage.value &&
    !!(route.fields.pageImage.value as ImageFieldValue).src &&
    (route.fields.pageImage.value as ImageFieldValue).src !== "";
  const mustNoIndex: boolean =
    !!route.fields &&
    !!route.fields.noIndex &&
    !!route.fields.noIndex.value &&
    route.fields.noIndex.value === 1;
  const metas: { [key: string]: string } = {
    title: hasPageTitle
      ? (route.fields!.pageTitle!.value as string)
      : `${
          route.fields && route.fields.pageTitle && route.fields.pageTitle.value
            ? "- " + route.fields.pageTitle.value
            : ""
        }`,
    description: hasPageDescription
      ? (route.fields!.pageDescription!.value as string)
      : ""
  };
  // TODO: TO UPDATE
  const websiteBaseUrl: string = WEBSITE_DOMAIN;
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>
            {`${
              route.fields &&
              route.fields.pageTitle &&
              route.fields.pageTitle.value
                ? route.fields.pageTitle.value
                : ""
            }`}
          </title>
          <link
            rel="canonical"
            href={`${websiteBaseUrl}${
            //  hasPathname ? sitecoreContext.routerRouteLocation!.pathname : ""
               ""
            }`}
          />
          <meta name="description" content={metas.description} />

          <meta
            property="og:url"
            content={canUseDOM && window.location.href}
          />
          <meta property="og:type" content="website" />
          <meta property="og:sitename" content="Luxembourg Stock Exchange" />
          <meta
            property="og:title"
            content={hasPageTitle ? metas.title : "Luxembourg Stock Exchange"}
          />
          <meta property="og:description" content={metas.description} />
          {hasPageImage ? (
            <meta
              property="og:image"
              content={(route.fields!.pageImage.value as ImageFieldValue).src}
            />
          ) : null}

          <meta
            property="twitter:url"
            content={canUseDOM && window.location.href}
          />
          <meta property="twitter:site" content="@LuxembourgSE" />
          <meta property="twitter:card" content="summary large image" />
          <meta
            property="twitter:title"
            content={hasPageTitle ? metas.title : "Luxembourg Stock Exchange"}
          />
          <meta property="twitter:description" content={metas.description} />
          {hasPageImage ? (
            <meta
              property="twitter:image"
              content={(route.fields!.pageImage.value as ImageFieldValue).src}
            />
          ) : null}
          {mustNoIndex ? (
            <meta property="robots" content="noindex, follow" />
          ) : null}
          <script type="application/ld+json">
            {/* TODO TO BE UPDATED */}
            {`
                    {
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "name" : "Luxembourg Stock Exchange",
                        "url": "${websiteBaseUrl}",
                        "contactPoint" : [
                        { "@type" : "ContactPoint",
                            "telephone" : ": +352-477936-292",
                            "contactType" : "customer service",
                            "email" : "lgx@bourse.lu"
                        } ], 
                        "sameAs": [
                            "https://twitter.com/LuxembourgSE",
                            "https://www.linkedin.com/company/luxembourg-stock-exchange/",
                            "https://fr.wikipedia.org/wiki/Bourse_de_Luxembourg",
                            "https://www.youtube.com/channel/UCWC9sfhZT5JxmrXjJjkuyLA"
                        ]     
                    }
                `}
          </script>
        </Helmet>
      </HelmetProvider>
      <VisitorIdentification />
      <main className="container">
        <Placeholder name="jss-main" rendering={route} />
      </main>
    </React.Fragment>
  );
};
