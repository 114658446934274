/** @jsx jsx */

import React, { Component } from "react";
import { Td, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import { genericTradingDataStyle } from "../../DataMarket/TradingData/Shared/generic-trading-data.style";
import { sharedStyle } from "../../../../styles/shared";
import { style } from "../market-statistics.style";
import RoundTag from "../../RoundTag/roundTag.component";
import { canUseDOM } from "../../../../predicates";
export class GenericMarketCapDataRow extends Component<any, any> {

    render() {

        const data = this.props.rowsData;
        const cols = this.props.columns;
        let compteur = 1;
        

        const goToSecurityDetailPage = (isin: string, id: string) => {
            if (canUseDOM) window.open(`/security/${isin}/${id}`, '_self');
        };

        return data?.length > 0 ? data.map((rd: any) => {
            compteur = compteur + 1;
            return (
                <tr
                    css={style.tableRow} key={compteur}
                    onClick={() => goToSecurityDetailPage(rd["isinCode"], rd["id"])}>
                    {
                        cols.map(({ accessor }: any) => {
                            const ac = accessor.replace(',', '');
                            const tData = rd[ac] ? rd[ac] : "——";
                            return <td key={accessor}>
                                {
                                    ac === "price" || ac === "nbSecuritiesIssued" || ac === "marketCap" ?
                                        <p css={ac === "marketCap" ? css`font-weight:bold; text-align:right;` : ac === "price" || ac === "nbSecuritiesIssued" ? css`text-align:right;` : ''}> 
                                        {ac === "marketCap" ? Number(tData).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, ' ') : Number(tData).toLocaleString('en-US').replace(/,/g, ' ')}
                                        </p> :
                                        ac === "type" ?
                                            <div css={sharedStyle.cardContainerBodyDoubleLeft}>
                                                <RoundTag small textColor={'#425563'}>
                                                    E
                                                </RoundTag>
                                            </div>
                                            : <p>{tData}</p>
                                }
                            </td>
                        })
                    }
                </tr>

            );
        }
        ) : ''

    }
}

export default GenericMarketCapDataRow;
