
/** @jsx jsx */

import * as React from 'react';

import {Button, Icon} from "@bdl-cmn-shared-packages-npm/design-system";
import {
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    RichText,
    Text,
    getFieldValue
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from '../../interfaces';
import bg from './hexa.svg'
import {renderLink} from "../../utils/helper";

export interface HexaLabelsWithTextComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const parentContainer = css`
      width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;

  @media (max-width: ${breakpointMax}) {
    padding-top: 46px;
    padding-bottom: 46px;
  }
    `;
const container = css`
      width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
    `;
const title = css`
      width: 100%;
  text-align: center;
  color: #354450;
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 32px;

  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }
    `;
const items = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const itemText = css`
  width: 100%;

  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
`;
const disclaimer = css`
  width: 100%;
  border-radius: 16px;
  background: #F0F5F5;
  margin-top: 48px;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  padding: 16px 32px;
  @media (max-width: ${breakpointMax}) {
    width: calc(100% - 32px);
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    margin-left: 16px;
    margin-right: 16px;
  }
`;
const action = css`
  width: 100%;
  margin-top: 48px;
  text-align: center;
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpointMax}) {
    button {
      width: calc(100% - 32px);
    }
  }
`;

export const HexaLabelsWithTextComponent: React.FunctionComponent<HexaLabelsWithTextComponentProps> = (props: HexaLabelsWithTextComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const btnLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
    const btnLink: string = getFieldValue<string>(fields, 'btnLink', '');
    const twoCols: number = getFieldValue<number>(fields, 'display on two columns', 0);

    const itemContainer = css`
      margin: 16px;
      display: flex;
      align-items: center;
      width: ${twoCols ? 'calc(50% - 32px)' : 'calc(33% - 32px)'};
      @media (max-width: ${breakpointMax}) {
        width: 100%;
      }
    `;
    const item = css`
      display: flex;
      align-items: center;
    `;

    const itemIcon = css`
      background: url(${renderLink(bg)});
      background-size: 48px;
      background-position: center;
      width: 48px;
      min-width: 48px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      color: #ca1234;
    `;
    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={items}>
                    {
                        new Array(9).fill(0).map((arr: any, index: number) => {
                            if (fields && fields[`text${index + 1}`] && (fields && fields[`text${index + 1}`] as any).value){
                                return (
                                    <div css={itemContainer} key={`items-${index}`}>
                                        <div css={itemIcon}>
                                            <Icon icon={getFieldValue<string>(fields, `icon${index + 1}`, '')} />
                                        </div>
                                        <div css={itemText}>
                                            <Text
                                                field={fields[`text${index + 1}`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                {
                    fields && fields.disclaimer && (fields.disclaimer as any).value && (
                        <div css={disclaimer}>
                            <RichText
                                field={fields && fields.disclaimer as SitecoreGenericTextField}
                            />
                        </div>
                    )
                }
                {
                    btnLabel && (
                        <div css={action}>
                            <Button label={btnLabel} onClick={() => btnLink ? window.location.href = btnLink : console.debug} iconLeft={'paper-plane'}/>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
