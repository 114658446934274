import { css } from "@emotion/react";

export const style = {
  container: css`
    display:flex;
    gap: 1rem;
    align-items: center;
  `,
  tagWrapper: css`
    width: 3rem;
    display: flex;
    justify-content: flex-end;
  `,
};
