import * as React from "react";

import { breakpoint, breakpointMax } from "../../style";

import { css } from "@emotion/react";

export const style = {
  container: css`
    width: 100%;
    background: #f6f9f9;
    border-radius: 8px;
    padding: 24px;
    max-width: 440px;
    margin-left: 12px;
    margin-right: 12px;
    display: flex;
    margin-bottom: 24px;
    cursor: pointer;
    @media (max-width: ${breakpointMax}) {
      margin-bottom: 16px;
    }
  `,
  left: css`
    width: 120px;
    min-width: 120px;
    min-height: 120px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
    }
    @media (max-width: ${breakpointMax}) {
      width: 80px;
      min-height: 76px;
      height: 76px;

      img {
        max-width: 100%;
      }
    }
  `,
  right: css`
    padding-left: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  label: css`
    padding-bottom: 16px;
    color: #253845;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 16px;
      padding-bottom: 4px;
      line-height: 20px;
    }
  `,
  description: css`
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #253845;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
      padding-bottom: 8px;
    }
  `,
  tags: css`
    gap: 8px;
    display: flex;
    flex-direction: column;
  `,
};
