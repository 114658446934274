/** @jsx jsx */
import React, { useState } from "react";
import {css, jsx} from '@emotion/react';
import { canUseDOM } from "../../../../../predicates";
import { InputDateRangePicker } from "../../../input-date-range-picker";
import PriceColumn, { PriceColumnProps } from "../../../UI/list/security/price";
import { style } from "../index.style";
import { Tab, Tabs } from "@bdl-cmn-shared-packages-npm/design-system";
import { areDateRangesEqual, chartOptions, computeFormatFromRange, rangeSelections, RangeDate } from "./utils";
import ReactApexChart from "react-apexcharts";
import { PriceInformation } from "../../../../../interfaces";
import { getEndOfDay, getStartOfDay, parseToMoment } from "../../../../../utils/date";

interface IndexCardProps {
    pricesHistory: PriceInformation[];
    priceColumn: PriceColumnProps;
}

export function IndexChart({pricesHistory, priceColumn}: IndexCardProps) {
    const [maxPrice, setMaxPrice] = useState<number>();
    const [minPrice, setMinPrice] = useState<number>();

    const defaultData = (): any => {
        const graphOptions = chartOptions;
    
        graphOptions.series =  [
          {
            name: '',
            data: []
          },
        ];
    
        return graphOptions;
    }

    const [dateRange, setDateRange] = React.useState<RangeDate>(() => ({
        fromDate: getStartOfDay().subtract(1, "year"),
        toDate: getEndOfDay()
    }));

    const prices = React.useMemo(() => {
        let maxPriceTmp : number = null;
        let minPriceTmp : number = null;
        let data = defaultData();
        const format = computeFormatFromRange(dateRange);
    
        const { fromDate, toDate } = dateRange;
        data.options.xaxis.labels.format = format;

        let categories : any[] = [];
        let serieData : any[] = [];

        if (pricesHistory) {
            pricesHistory.forEach( price => {
                let date = parseToMoment(price.date);
                if (!(fromDate && toDate) 
                    || (date.isAfter(fromDate) && !toDate)
                    || (!fromDate && date.isBefore(toDate))
                    || (date.isAfter(fromDate) && date.isBefore(toDate))
                ) {
                    let value = price.amount;
            
                    if( value != null && value != undefined){
                        categories.push(date.toISOString());
                        serieData.push( [ 
                            date.toDate(),
                            value
                        ]);
                    
                        if( minPriceTmp == null || value < minPriceTmp )
                            minPriceTmp = value;
                            
                        if( maxPriceTmp == null || value > maxPriceTmp )
                            maxPriceTmp = value;
                    }
                }
            });
        }
    
        data.options.xaxis.categories = categories;
        data.series = [
          {
            name: "Price",
            data: serieData
          }
        ];

        setMinPrice(minPriceTmp);
        setMaxPrice(maxPriceTmp);
    
        return data;
    }, [dateRange]);

    if( ! pricesHistory ) return <></>;

    return (
        <div css={style.chartContainer}>
            <h2 css={style.blocTitle}>Chart</h2>
            
            <div css={style.priceColumn}>
                <PriceColumn {...priceColumn} isChart={true} />
            </div>

            <div css={style.chart}>
                <div css={style.dataBottom}>
                    High {maxPrice?.toFixed(5)} - Low {minPrice?.toFixed(5)}
                </div>
                <ReactApexChart
                    options={prices.options}
                    series={prices.series}
                    type="area"
                    height={(canUseDOM && window.screen.width > 390) ? 500 : 300}
                    width={"100%"}
                />
                <div css={[style.priceContainer, {marginTop: "20px"}]}>
                    <InputDateRangePicker
                        fromDate={dateRange.fromDate}
                        toDate={dateRange.toDate}
                        onSelect={(fromDate, toDate) => setDateRange({ fromDate, toDate })}
                        placeholder="Date selection" />

                    <div css={style.tabSelector}>
                        <Tabs>
                            { rangeSelections.map(({ label, dateRange: range }) => (
                                <div css={style.tab}>
                                    <Tab label={label}
                                        onTabChange={() => setDateRange(range)}
                                        selected={areDateRangesEqual(dateRange, range)}
                                    />
                                </div>
                            ))}
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}