export enum IssuerTypeEnum {
	CGEN = 'Corporate',
	FGEN = 'Financial',
	SGEN = 'SSA',
}

export function issuerTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(IssuerTypeEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(IssuerTypeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get IssuerTypeEnum label for '" + value + "'", e);
	}
		
	return value;
}