/** @jsx jsx */
import { jsx } from "@emotion/react";
import { style } from "./index.style";
import { DEFAULT_OAM_FILTERS, UseFilters } from "../useFilters";
import { MenuWithChild } from "@bdl-cmn-shared-packages-npm/design-system";
import InformationFilter from "./information-filter";
import StatusFilter from "./status-filter";
import { DateRangeFilter } from "./date-range-filter";

interface OAMColumnFiltersProps extends Pick<UseFilters, "filters" | "onFilterChange"> {
}

export default function OAMColumnFilters({ filters, onFilterChange }: OAMColumnFiltersProps) {
  const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    let found = false;
    let parent = event.currentTarget;
    let head = parent.firstChild.firstChild;
    let target = event.target as Element;

    do {
      if (head === target) found = true;

      target = target.parentElement;
    } while (!found && parent !== target && target !== null);

    if (found) parent.classList.toggle("open");
  };
  
  return (
    <div css={style.container}>
      <div css={style.title}>Filter your search result</div>

      <div css={style.parentMenu}>

        <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
          <MenuWithChild
            icon="exchange"
            label="OAM"
            width="100%;"
            expanded
            arrows={true}
          >
            <div
              css={[style.subRow, style.filterMenu]}
              onClick={handleFilterMenuClick}
            >
              <InformationFilter
                lang={filters.lang}
                selection={filters?.oam_idTypeDepo || DEFAULT_OAM_FILTERS.oam_idTypeDepo}
                onSelectionChange={onFilterChange("oam_idTypeDepo")} />
            </div>

            <div
              css={[style.subRow, style.filterMenu]}
              onClick={handleFilterMenuClick}
            >
              <StatusFilter 
                lang={filters.lang}
                selected={filters?.hide_canceled} 
                onSelectedChange={onFilterChange("hide_canceled")} 
              />
            </div>

            <div
              css={[style.subRow, style.filterMenu]}
              onClick={handleFilterMenuClick}
            >
              <DateRangeFilter
                lang={filters.lang}
                selection={filters?.publicationDate || DEFAULT_OAM_FILTERS.publicationDate}
                onSelectionChange={onFilterChange("publicationDate")} 
              />
            </div>

          </MenuWithChild>
        </div>
      </div>
    </div>
  );
}