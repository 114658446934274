import * as React from "react";
/** @jsx jsx */
import {jsx} from "@emotion/react";
import {Table, Thead, Tr, Th, Tbody} from "@bdl-cmn-shared-packages-npm/design-system";
import ChinaBondsRow from "./row";
import EmptyResults from "../UI/list/base/empty";
import SearchSecurity from "../../../interfaces/search/search-security";

export const ChinaBondsTable = (props: any) => {
    const bonds: SearchSecurity[] = props?.bonds;

    return (
        <>
        { bonds?.length ?
            <Table>
                <Thead>
                    <Tr>
                        <Th>Securities</Th>
                        <Th>Listing date</Th>
                        <th css={{width: "15%"}}>Bid | Ask</th>
                        <th css={{width: "15%"}}>Last Price</th>
                    </Tr>
                </Thead>
                <Tbody>
                    {bonds?.map((bond: SearchSecurity) => {
                        return <ChinaBondsRow row={bond}/>
                    })}
                </Tbody>
            </Table>
            : 
            <div css={{margin:"auto"}}>
                <EmptyResults label="No result found for your criteria"/>
            </div>
        }
        </>
    )

}
export default ChinaBondsTable;