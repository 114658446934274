
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const DOM: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <g>
                <path fill="#002D62" d="M211.862,220.691H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h203.034c4.875,0,8.828,3.953,8.828,8.828v114.759C220.69,216.738,216.737,220.691,211.862,220.691z" />
                <path fill="#002D62" d="M503.172,423.725H300.138c-4.875,0-8.828-3.953-8.828-8.828V300.139 c0-4.875,3.953-8.828,8.828-8.828h203.034c4.875,0,8.828,3.953,8.828,8.828v114.759C512,419.773,508.047,423.725,503.172,423.725z" />
            </g>
            <g>
                <path fill="#CE1126" d="M503.172,220.691H300.138c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h203.034c4.875,0,8.828,3.953,8.828,8.828v114.759C512,216.738,508.047,220.691,503.172,220.691z" />
                <path fill="#CE1126" d="M211.862,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V300.139c0-4.875,3.953-8.828,8.828-8.828 h203.034c4.875,0,8.828,3.953,8.828,8.828v114.759C220.69,419.773,216.737,423.725,211.862,423.725z" />
            </g>
            <g>
                <path fill="#006300" d="M250.932,281.172c0,0,5.274,1.931,4.586,8.552l2.132-0.04c0,0-1.345-7.651-2.457-8.568 S250.932,281.172,250.932,281.172z" />
                <path fill="#006300" d="M256.218,280.15c-2.237,0-6.147-0.003-10.259-1.113c0.917-0.954,2.184-2.876,2.184-6.227 c0-5.248-3.616-1.9-3.616-1.9s-2.378,2.001-0.94,7.35c-1.402-0.535-2.793-1.225-4.119-2.109c0.825-1.036,2.471-3.45,3.427-7.278 c0,0-6.026-1.191-7.674,3.347c-1.01-1.281-1.874-2.722-2.595-4.317c1.549,0.019,3.71-1.003,3.71-6.901c0,0-3.935,0.007-4.986,3.42 c-0.414-1.427-0.729-2.956-0.961-4.572c1.571,0.456,5.633,0.783,5.633-8.032c0,0-4.877,0.009-6.006,4.329 c-0.062-1.105-0.086-2.247-0.075-3.425c2.368-1.238,5.636-3.965,5.297-9.56c0,0-2.249,0.51-4.311,2.239 c0.406-2.121,0.238-5.226-3.015-7.817c0,0-2.989,5.29,1.377,9.585c-0.38,0.534-0.711,1.142-0.984,1.817 c-0.981-1.494-2.554-2.883-5.088-3.727c0,0-1.166,7.085,5.449,10.049c0.157,4.138,0.804,7.794,1.916,10.973 c-1.895-1.669-3.428-3.967-3.428-3.967c0-7.872-6.453-8.071-6.453-8.071c-1.205,8.985,5.439,9.348,5.439,9.348l3.638,3.97 c-7.872-1.312-7.872,5.248-7.872,5.248c5.248,1.312,7.872-1.312,7.872-1.312l2.838,1.459c-5.034,0.147-6.774,5.101-6.774,5.101 c6.327,1.055,10.953-0.433,12.526-1.057c6.372,4.455,14.226,4.461,17.852,4.461v-1.311L256.218,280.15L256.218,280.15z" />
                <path fill="#006300" d="M255.772,280.15c2.237,0,6.147-0.003,10.259-1.113c-0.917-0.954-2.184-2.876-2.184-6.227 c0-5.248,3.616-1.9,3.616-1.9s2.378,2.001,0.94,7.35c1.402-0.535,2.793-1.225,4.119-2.109c-0.825-1.036-2.471-3.45-3.427-7.278 c0,0,6.026-1.191,7.674,3.347c1.01-1.281,1.874-2.722,2.595-4.317c-1.549,0.019-3.71-1.003-3.71-6.901c0,0,3.935,0.007,4.988,3.42 c0.414-1.427,0.729-2.956,0.961-4.572c-1.571,0.456-5.633,0.783-5.633-8.032c0,0,4.877,0.009,6.006,4.329 c0.062-1.105,0.086-2.247,0.075-3.425c-2.368-1.238-5.636-3.965-5.297-9.56c0,0,2.249,0.51,4.311,2.239 c-0.406-2.121-0.238-5.226,3.015-7.817c0,0,2.989,5.29-1.377,9.585c0.38,0.534,0.711,1.142,0.984,1.817 c0.981-1.494,2.554-2.883,5.088-3.727c0,0,1.166,7.085-5.449,10.049c-0.157,4.138-0.804,7.794-1.916,10.973 c1.895-1.669,3.428-3.967,3.428-3.967c0-7.872,6.453-8.071,6.453-8.071c1.205,8.985-5.439,9.348-5.439,9.348l-3.638,3.97 c7.872-1.312,7.872,5.248,7.872,5.248c-5.248,1.312-7.872-1.312-7.872-1.312l-2.838,1.459c5.034,0.147,6.774,5.101,6.774,5.101 c-6.327,1.055-10.953-0.433-12.526-1.057c-6.372,4.455-14.226,4.461-17.852,4.461L255.772,280.15L255.772,280.15L255.772,280.15z" />
            </g>
            <path fill="#002D62" d="M233.863,222.956c-4.614,2.957-8.681,6.722-11.958,11.167c1.408,1.038,2.816,2.077,4.224,3.115 c2.892-3.923,6.481-7.245,10.551-9.856L233.863,222.956z" />
            <path fill="#CE1126" d="M256.014,257.067h18.367v10.496c0,2.899-2.349,5.248-5.248,5.248h-7.872 c-2.156,0-4.052,1.054-5.248,2.66L256.014,257.067L256.014,257.067z" />
            <polygon fill="#002D62" points="259.95,245.259 259.95,230.827 279.63,230.827 274.382,236.075 274.382,250.507 259.95,250.507 " />
            <rect x="267.82" y="250.501" fill="#FFFFFF" width="6.56" height="6.56" />
            <path fill="#CE1126" d="M256.014,257.067h-18.367v10.496c0,2.899,2.349,5.248,5.248,5.248h7.872 c2.156,0,4.052,1.054,5.248,2.66L256.014,257.067L256.014,257.067z" />
            <path fill="#FFFFFF" d="M252.735,273.127c1.309,0.418,2.439,1.233,3.25,2.307h0.061c0.812-1.075,1.941-1.888,3.25-2.307 v-10.813h-6.56L252.735,273.127L252.735,273.127z" />
            <polygon fill="#002D62" points="252.079,245.259 252.079,230.827 232.399,230.827 237.647,236.075 237.647,250.507 252.079,250.507 " />
            <rect x="237.65" y="250.501" fill="#FFFFFF" width="6.56" height="6.56" />
            <polygon fill="#002D62" points="244.207,237.387 244.207,241.323 248.143,243.947 248.143,241.323 " />
            <polygon fill="#FFFFFF" points="248.143,241.323 248.143,245.259 253.391,247.883 253.391,246.571 " />
            <polygon fill="#002D62" points="267.822,237.387 267.822,241.323 263.886,243.947 263.886,241.323 " />
            <polygon fill="#FFFFFF" points="263.886,241.323 263.886,245.259 258.638,247.883 258.638,246.571 " />
            <path fill="#CE1126" d="M263.932,266.25h-15.829c-3.598,0-6.515-2.937-6.515-6.56v-20.291l14.525,9.795l14.333-9.795v20.291 C270.446,263.314,267.53,266.25,263.932,266.25z" />
            <g>
                <polygon fill="#002D62" points="246.831,242.635 241.583,238.699 241.583,253.131 246.831,255.755 " />
                <polygon fill="#002D62" points="265.198,242.635 270.446,238.699 270.446,253.131 265.198,255.755 " />
                <rect x="248.14" y="255.761" fill="#002D62" width="15.744" height="6.56" />
            </g>
            <polygon fill="#CE1126" points="248.143,243.947 256.014,247.883 263.886,243.947 263.886,255.755 248.143,255.755 " />
            <g>
                <path fill="#FFFFFF" d="M261.919,245.187v13.192c0,1.085-0.883,1.967-1.967,1.967h-7.872c-1.085,0-1.967-0.883-1.967-1.967 v-13.138l-3.936-2.636v15.774c0,3.255,2.648,5.903,5.903,5.903h7.872c3.255,0,5.903-2.648,5.903-5.903v-15.863L261.919,245.187z" />
                <path fill="#FFFFFF" d="M263.293,254.351c-2.21,0.199-4.697,0.422-7.278,1.32c-2.58-0.898-5.068-1.121-7.278-1.32 c-3.698-0.332-5.892-0.655-7.148-2.886v3.787c1.929,1.254,4.372,1.484,6.913,1.713c2.281,0.205,4.639,0.416,7.049,1.329 l0.465,0.175l0.465-0.175c2.409-0.913,4.767-1.123,7.049-1.329c2.543-0.228,4.99-0.455,6.919-1.711v-3.798 C269.192,253.696,266.996,254.019,263.293,254.351z" />
            </g>
            <polygon fill="#EAC102" points="258.638,241.979 256.671,241.979 256.671,240.011 255.359,240.011 255.359,241.979 253.391,241.979 253.391,243.291 255.359,243.291 255.359,249.195 256.671,249.195 256.671,243.291 258.638,243.291 " />
            <rect x="248.7" y="233.999" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -98.2351 247.1415)" fill="#000000" width="1.018" height="16.304" />
            <polygon fill="#EAC102" points="241.278,234.221 242.719,237.103 244.16,235.662 " />
            <rect x="250.323" y="232.894" transform="matrix(0.8083 -0.5888 0.5888 0.8083 -93.8377 193.8998)" fill="#000000" width="1.019" height="16.305" />
            <polygon fill="#EAC102" points="244.227,231.984 245.204,235.055 246.851,233.855 " />
            <rect x="247.395" y="235.365" transform="matrix(0.5725 -0.8199 0.8199 0.5725 -93.6819 307.3604)" fill="#000000" width="1.018" height="16.304" />
            <polygon fill="#EAC102" points="238.71,237.109 240.633,239.694 241.8,238.024 " />
            <rect x="262.312" y="233.987" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 277.4449 599.1993)" fill="#000000" width="1.018" height="16.304" />
            <polygon fill="#EAC102" points="270.745,234.221 269.304,237.103 267.863,235.662 " />
            <rect x="260.688" y="232.889" transform="matrix(-0.8083 -0.5888 0.5888 -0.8083 330.3971 589.6631)" fill="#000000" width="1.019" height="16.305" />
            <polygon fill="#EAC102" points="267.796,231.984 266.819,235.055 265.173,233.855 " />
            <rect x="263.629" y="235.379" transform="matrix(-0.5725 -0.8199 0.8199 -0.5725 215.6856 599.5198)" fill="#000000" width="1.018" height="16.304" />
            <polygon fill="#EAC102" points="273.313,237.109 271.39,239.694 270.223,238.024 " />
            <path fill="#FFFFFF" d="M255.349,272.437c0,0-5.074-5.449-4.089-10.451c0.791-4.023,3.192-8.458,5.071-9.537 c0,0,2.657-0.164,1.991,2.144c-0.666,2.308-1.478,2.903-1.24,5.178C257.491,263.709,258.126,268.375,255.349,272.437z" />
            <path fill="#002D62" d="M256.888,252.478c-0.317-0.042-0.558-0.028-0.558-0.028c-1.879,1.079-4.28,5.514-5.071,9.537 c-0.448,2.275,0.361,4.638,1.373,6.543C251.991,260.282,254.399,257.356,256.888,252.478z" />
            <path fill="#CE1126" d="M257.08,259.773c-0.237-2.275,0.574-2.87,1.24-5.178c0.467-1.615-0.693-2.019-1.432-2.116 c0,0-1.694,3.892-1.803,6.503c-0.096,2.297,0.492,11.644,0.264,13.458C258.126,268.375,257.491,263.709,257.08,259.773z" />
            <path fill="#FFFFFF" d="M253.639,254.424c1.057-1.031,2.543-1.492,3.999-1.242c-0.69-2.221-1.38-4.442-2.07-6.664 c-1.454-0.249-2.941,0.212-3.999,1.242C252.258,249.982,252.949,252.203,253.639,254.424z" />
            <path fill="#FFFFFF" d="M257.638,253.182c1.057-1.031,2.543-1.492,3.999-1.242c-0.69-2.221-1.38-4.442-2.07-6.664 c-1.454-0.249-2.941,0.212-3.999,1.242C256.257,248.739,256.947,250.96,257.638,253.182z" />
            <path fill="#002D62" d="M250.022,224.355c-7.122-0.164-14.156,1.587-20.37,5.07c-0.855-1.526-1.711-3.052-2.567-4.577 c7.033-3.944,14.996-5.926,23.058-5.74C250.102,220.857,250.061,222.606,250.022,224.355z" />
            <path fill="#002D62" d="M278.167,222.956c4.614,2.957,8.681,6.722,11.958,11.167c-1.408,1.038-2.816,2.077-4.224,3.115 c-2.892-3.923-6.481-7.245-10.551-9.856L278.167,222.956z" />
            <path fill="#002D62" d="M262.008,224.355c7.122-0.164,14.156,1.587,20.37,5.07c0.855-1.526,1.711-3.052,2.567-4.577 c-7.033-3.944-14.996-5.926-23.058-5.74C261.928,220.857,261.969,222.606,262.008,224.355z" />
            <path fill="#002D62" d="M266.51,222.956c-6.871-1.88-14.121-1.88-20.991,0c-0.461-1.687-0.924-3.374-1.385-5.062 c7.777-2.127,15.983-2.127,23.761,0C267.434,219.581,266.972,221.268,266.51,222.956z" />
            <g>
                <path fill="#CE1126" d="M238.432,281.758c-5.663,1.479-11.199,3.474-16.529,5.985c0.746,1.582,1.492,3.165,2.237,4.747 c5.143-2.424,10.489-4.337,15.96-5.742L238.432,281.758z" />
                <path fill="#CE1126" d="M273.598,281.758c5.663,1.479,11.199,3.474,16.529,5.985c-0.746,1.582-1.492,3.165-2.237,4.747 c-5.143-2.424-10.489-4.337-15.96-5.742L273.598,281.758z" />
            </g>
            <g>
                <path fill="#CE1126" d="M277.119,285.816c-13.799-3.313-28.184-3.313-41.983,0c-0.408-1.702-0.817-3.402-1.225-5.102 c14.603-3.506,29.83-3.506,44.433,0C277.937,282.414,277.528,284.116,277.119,285.816z" />
                <path fill="#CE1126" d="M256.014,287.242l-3.618,0.905c-0.828,0.207-1.63-0.419-1.63-1.272v-1.887 c0-0.853,0.802-1.48,1.63-1.272l3.618,0.905V287.242z" />
                <path fill="#CE1126" d="M256.014,284.618l3.618-0.905c0.828-0.207,1.63,0.419,1.63,1.272v1.887 c0,0.853-0.802,1.48-1.63,1.272l-3.618-0.905V284.618z" />
            </g>
            <path fill="#CE1126" d="M256.296,287.242h-0.563c-0.569,0-1.031-0.461-1.031-1.031v-0.563c0-0.569,0.461-1.031,1.031-1.031 h0.563c0.569,0,1.031,0.461,1.031,1.031v0.563C257.326,286.781,256.865,287.242,256.296,287.242z" />
        </svg>
    </React.Fragment>
);
