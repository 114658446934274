
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';

export interface TwoColumnsDisplayerProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const row = css`
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E6EEF2;
`;

const left = css`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #425563;
  width: 50%;
`;
const right = css`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
  color: #354450;
  width: 50%;
`;

export const TwoColumnsDisplayer: React.FunctionComponent<TwoColumnsDisplayerProps> = (props: TwoColumnsDisplayerProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div css={container}>
            {
                new Array(8).fill(0).map((arr: any, index: number) => {
                    if (fields && fields[`label ${index + 1}`] && (fields && fields[`label ${index + 1}`] as any).value) {
                        return (
                            <div css={row}>
                                <div css={left}>
                                    <Text
                                        field={fields[`label ${index + 1}`] as SitecoreGenericTextField}
                                    />
                                </div>
                                <div css={right}>
                                    <Text
                                        field={fields[`value ${index + 1}`] as SitecoreGenericTextField}
                                    />
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div>
    );
}
