import { breakpoint, breakpointMax } from "../../../../style";

import { css } from "@emotion/react";

export const style = {
  header: css`
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-content: center;
    flex-shrink: 0;
  `,

  hidden: css`
    display: none;
    padding: 0;
    gap: 0;
  `,

  title: css`
    color: #354450;
  `,
  container: css`
    padding: 3rem;
    background: #e8ecee;
    display: flex;
    gap: 2rem;

    .DayPicker {
      z-index: 30;
    }

    @media (max-width: ${breakpointMax}) {
      padding: 2rem;
      flex-wrap: wrap;
      justify-content: center;
    }
  `,
  form: css`
    width: 100%;
    display: flex;
    gap: 2rem;
    @media (max-width: ${breakpointMax}) {
      flex-wrap: wrap;
      justify-content: center;
    }
  `,
  formWrapper: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #d9dee2;
    padding: 1rem;
    gap: 1rem;
  `,
  actions: css`
    flex-shrink: 0;
    flex-grow: 1;

    @media (max-width: ${breakpointMax}) {
      text-align: -webkit-center;
    }
  `,
  row: css`
    width: 100%;
    display: flex;
    gap: 1rem;
    @media (max-width: ${breakpointMax}) {
      flex-wrap: wrap;
    }
  `,

  inputField: css`
    width: 100%;

    > div {
      background-color: white;
    }
  `,

  moreCriteriaButton: css`
    > button {
      color: #354450;
      background: transparent;
      border: none !important;
      box-shadow: none;
      text-decoration: underline;
      &:hover {
        background: transparent;
        color: black;
        box-shadow: none;
      }
    }
  `,
  label: css`
    label {
      background-color: white;
      padding: 0 0.25rem;
      border-radius: 4px;
      background: transparent;
    }
  `,
  selectbox: css`
    width: 100%;
    position: relative;
    @media (max-width: ${breakpointMax}) {
      height: 56px;
    }
    > div {
      position: absolute;
      width: 100%;

      > div > div {
        background-color: white;
      }
    }
  `,
  selectboxTop: css`
    > div {
      z-index: 35;
      width: 100%;
    }
  `,
  datePicker: css`
    > div > div:first-child {
      z-index: 35;
      width: 100%;

      > div > div > div > div > div {
        min-height: 55px !important;
      }
    }

    > div > div:last-child {
      top: inherit;
      left: inherit;
      right: 0;
      z-index:50;

      input {
        color: #0000008a;
      }
    }
  `,

  lang: css`
    display: flex;
    gap: 0.5rem;
    margin-top: 1.5rem;
    color: #22aa5f;

    > * {
      text-decoration: underline;
      cursor: pointer;
    }
  `,
  selectedLang: css`
    color: #005e2f;
  `,
};
