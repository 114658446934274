import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import {Icon, Table} from "@bdl-cmn-shared-packages-npm/design-system";
import {MarketdataModel} from "../../market-data-view.component";
import {NewIssuerRow} from "./new-issuer-row.component";

export const NewIssuerView = (props: any) => {
    const newIssuers = props.data?.allData;
    return (
        <Table>
            {/*{cssfApprovals?.totalHits > 5 && (<div css={sharedStyle.gradientTop}/>)}*/}
            <tbody>
            {newIssuers?.length > 0 ? newIssuers?.map((newIssuerRow: MarketdataModel) =>
                    (<NewIssuerRow newIssuerRowData={newIssuerRow}/>))
                : "no data"}
            </tbody>
        </Table>
    )
}
export default NewIssuerView;