import { css } from '@emotion/react';

export const style = {
  dropdown: css`
    cursor: pointer;
    text-align: center;
  `,
  parentContainerLabel: (bold = false) => css`
    margin-right: 0.5em;
    color: #354450;
    font-weight: ${bold? '700' : 'inherit'};
  `,
  parentContainer: css`
    display: flex;
    align-items: center;
    svg {
      color: #22AA5F;
      font-size: 12px;
    }
  `,
  bigContainer: css`
    display: flex;
    margin-left: -200px;
    svg {
      color: #22AA5F;
      font-size: 12px;
    }
  `,
  dropdownOpen: css`
    z-index: 40;
    cursor: pointer;
    background: #ffffff;
    position: absolute;
    margin-left: -650px;
    margin-top: 20px;
    border-radius: 0.5em;
    box-shadow: rgb(0 0 0 / 25%) 0 2px 8px;
    border: 1px solid #e3e6e8;
  `,
  dropdownOpenNude: css`
    cursor: pointer;
    background: transparent;
    position: absolute;
    margin-left: -650px;
    margin-top: 20px;
    //padding: 1em;
    z-index: 40;
  `,
  dropdownOpenLight: css`
    z-index: 40;
    cursor: pointer;
    min-width: 160px;
    max-width: 380px;
    margin-left: -40px;
    position: absolute;
    margin-top: 28px;
    background: #ffffff;
    border: 1px solid #d9e1e2;
    box-sizing: border-box;
    box-shadow: 0 16px 24px rgba(37, 54, 69, 0.15);
    border-radius: 16px;
    padding: 10px;
    text-align: left;
  `,
  dropdownTarget: css`
    background: #ffffff;
    width: 17px;
    height: 17px;
    z-index: 41;
    border-right: none !important;
    border-bottom: none !important;
    transform: rotate(45deg);
    position: absolute;
    margin-left: 80px;
    margin-top: 20px;
    border-radius: 2px;
    border: 1px solid #e3e6e8;
  `,
  dropdownTargetMask: css`
    background: #ffffff;
    width: 38px;
    height: 22px;
    position: absolute;
    margin-left: 73px;
    z-index: 2;
  `,

  dropdownTargetBig: css`
    background: #ffffff;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    position: absolute;
    margin-left: 690px;
    margin-top: -10px;
    border-radius: 2px;
    border: 1px solid #e3e6e8;
    box-shadow: rgb(0 0 0 / 25%) 0 2px 8px;
  `,
  dropdownTargetMaskBig: css`
    background: #ffffff;
    width: 38px;
    height: 22px;
    position: absolute;
    margin-left: 683px;
    z-index: 2;
  `,
  dropdownItemsContainer: css`
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
  `,
  dropdownRom: css`
    font-weight: 600;
    font-size: 16px;
    color: #354450;
    transition: all 0.1s ease-in-out;
    padding: 12px;
    border-radius: 8px;
    text-decoration: none;
    &:hover {
      background: #f6f8f9;
      transition: all 0.1s ease-in-out;
    }
  `,
};
