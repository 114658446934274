
export enum LgxSustainableFund {
	ESG = 'Article 8 funds',
	IMPACT = 'Article 9 funds',
}

export function lgxSustainableFundLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(LgxSustainableFund).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(LgxSustainableFund)[index] : value;
	}catch (e){
		console.error("Failed to get LgxSustainableFund label for '" + value + "'", e);
	}
		
	return value;
}