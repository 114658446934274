
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const BRB: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <path fill="#00267F" d="M170.667,423.721H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.101c0-4.875,3.953-8.828,8.828-8.828 h161.839V423.721z" />
            <rect x="170.67" y="88.277" fill="#FFC726" width="170.67" height="335.45" />
            <path fill="#00267F" d="M503.172,423.721H341.333V88.273h161.839c4.875,0,8.828,3.953,8.828,8.828v317.792 C512,419.769,508.047,423.721,503.172,423.721z" />
            <path fill="#000000" d="M326.62,207.253c0,0-24.564,0-42.987,18.423h12.281c0,0-11.898,21.493-18.039,58.339l-12.665-2.068 v-56.454l12.281,4.021l-21.492-52.965l-21.493,52.965l12.281-4.095v56.527l-12.665,2.068c-6.141-36.845-18.039-58.339-18.039-58.339 h12.281c-18.422-18.422-42.986-18.422-42.986-18.422c30.704,36.845,30.704,98.255,30.704,98.255l30.704-5.117v41.963h18.423v-41.963 l30.704,5.117C295.915,305.509,295.915,244.1,326.62,207.253z" />
        </svg>
    </React.Fragment>
);
