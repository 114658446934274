import React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";

import ChinaBondsView from ".";

/** @jsx jsx */

export default function ChinaBondsSSEView(props: any) {
    return <ChinaBondsView tags={["RBDL_SSE", "RBDL_SZSE"]} label="SSE"/>
}
