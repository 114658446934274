
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Icon, Button } from '@bdl-cmn-shared-packages-npm/design-system';
import ICalendarLink from "react-icalendar-link";
import {
    childrenBloc,
    letterBloc,
    childrenBlocLeft,
    childrenBlocTitle,
    childrenBlocSubTitle,
    tagContainer,
    childrenBlocRight,
    childrenBlocTitleRight,
    childrenBlocDescription,
    childrenBlocDocument,
    childrenBlocDocumentLink,
    tag, rootPage
} from "../../shared.style";
import { formatDate } from '../../../../../utils/date';

export interface EventItemRowProps {
    data: any,
    key?: string,
    index: number,
}

export const EventItemRow: React.FunctionComponent<EventItemRowProps> = (props:EventItemRowProps): React.ReactElement => {
    const { data = [], key, index } = props;
    
    const ics = {
        title: data.Title,
        description: data.Description,
        startTime: formatDate(data['Start date'], "MM/DD/YYYY HH:mm:ss"),
        endTime: data['End date'] ? formatDate(data['End date'], "MM/DD/YYYY hh:mm a Z") : formatDate(data['Start date'], "MM/DD/YYYY hh:mm a Z"),
        location: data.Location,
    }
        return (
        <div css={letterBloc}>
            <div css={rootPage}>

                <div css={childrenBloc}>
                    <div css={childrenBlocLeft}>
                        <div css={childrenBlocTitle}>
                            { data.Label }
                        </div>
                        <div css={childrenBlocSubTitle}>
                            Meet us there
                        </div>
                        <div css={tagContainer}>
                            {
                                data && data.Speakers && data.Speakers.map((data: any, index: number) => (
                                    <div css={tag}  key={`tag-key-${index}`} >
                                        { data.label }
                                    </div>
                                ))
                            }
                        </div>
                        <div css={childrenBlocSubTitle}>
                            Types
                        </div>
                        <div css={tagContainer}>
                            {
                                data && data.Types && data.Types.map((thematic: any, index: number) => (
                                    <div css={tag}  key={`tag-thematics-${index}`} >
                                        { thematic.label }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div css={childrenBlocRight}>
                        <div css={childrenBlocTitleRight}>
                            { data.Title }
                        </div>
                        <div css={childrenBlocDescription}>
                            <div
                                dangerouslySetInnerHTML={{__html: data.Description}}
                            />
                        </div>
                        {data.HideCalendarButton === "" ? (
                        <div css={childrenBlocDocumentLink(false)}>
                            <ICalendarLink event={ics} >
                                <Button label={'Add to my calendar'} iconLeft={'calendar-plus'} wired={true} />
                            </ICalendarLink>
                        </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
