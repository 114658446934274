/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { ResourcesHeader } from '../ResourcesHeader';
import Loader from '../../Loader/loader.component';
import { fetchData, getDataWithChild } from '../../../../utils/helper';
import { EventItemRow } from './EventItemRow';
import {
  resourceContent,
  rowContainer,
  rowLabel,
  empty,
  tabsContainer,
  headerDouble,
  headerDoubleRight,
  headerDoubleLeft,
  rootPage,
  childContainer,
} from '../shared.style';
import { Tabs, Tab } from '@bdl-cmn-shared-packages-npm/design-system';
import { EventComponent } from '../../Event/event.component';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { getCurrentMoment, parseToMoment } from '../../../../utils/date';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as unknown as number);
}

export const ResourcesEvents = ({ fields, isH1 }: any) => {
  const title: string = getFieldValue<string>(fields, 'title', '');
  const description: string = getFieldValue<string>(fields, 'description', '');
  const pastLabel: string = getFieldValue<string>(fields, 'past events label', '');
  const nextLabel: string = getFieldValue<string>(fields, 'next events label', '');

  const tabs = [nextLabel, pastLabel];
  const [selectedTab, setSelectedTab] = React.useState<string>(tabs[0]);

  const [eventMap, setEventMap] = useState([]);
  const [events, setEvents] = React.useState<any>({});
  const [lazyEvents, setLazyEvents] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<any>({
    [nextLabel]: true,
    [pastLabel]: true,
  });

  const identifyEvents = useCallback(
    () =>
      eventMap.reduce(
        (prev, curr) => {
          if (!isValidDate(new Date(curr.ItemName))) throw new Error('Not a valid date.');
          if (
            new Date(curr.ItemName).getMonth() === new Date().getMonth() &&
            new Date(curr.ItemName).getFullYear() === new Date().getFullYear()
          ) {
            prev[pastLabel].push(curr);
            prev[nextLabel].push(curr);
          } else {
            prev[new Date() < new Date(curr.ItemName) ? nextLabel : pastLabel].push(curr);
          }
          return prev;
        },
        { [nextLabel]: [], [pastLabel]: [] }
      ),
    [eventMap, nextLabel, pastLabel]
  );

  useEffect(() => {
    fetchData('3FE49801-613A-4AD0-B8C6-83B906ABFBD7')
      .then((res) => setEventMap(res))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (eventMap.length !== 0) {
      try {
        // perfect scenario : all item names are month names and years eg: April 2024
        const map = identifyEvents();
        loadEvents(map[nextLabel].splice(0, 2), nextLabel);
        loadEvents(map[pastLabel].splice(0, 2), pastLabel);
        setLazyEvents({ [nextLabel]: map[nextLabel], [pastLabel]: map[pastLabel] });
      } catch (e) {
        // anything else
        loadEvents(eventMap.splice(0, 2));
        setLazyEvents(eventMap);
      }
    }
  }, [eventMap]);

  const loadEvents = (payload: any[], label?: string) => {
    getDataWithChild(payload, ['Speakers', 'Types'])
      .then((res) => {
        if (label) {
          setEvents((prev: any) => ({
            ...prev,
            [label]: (prev[label] || []).concat(
              res.map((it: any) => ({
                label: it.label,
                events: it.children.filter((event: any) =>
                  label === pastLabel
                    ? parseToMoment(event['End date'])?.isBefore(getCurrentMoment())
                    : parseToMoment(event['End date'])?.isAfter(getCurrentMoment())
                ),
              }))
            ),
          }));
          setIsLoading((prev: any) => ({ ...prev, [label]: false }));
        } else {
          dissociateEvents(res);
        }
      })
      .catch();
  };

  const dissociateEvents = (data: any) => {
    const past: any = [];
    const future: any = [];
    const now = getCurrentMoment();

    data.forEach((d: any) => {
      const tmpPast: any = {
        label: d.label,
        events: d.children.filter((event: any) => parseToMoment(event['End date'])?.isBefore(now)),
      };
      const tmpFuture: any = {
        label: d.label,
        events: d.children.filter((event: any) => parseToMoment(event['End date'])?.isAfter(now)),
      };
      if (tmpPast.events.length > 0) {
        past.push(tmpPast);
      }
      if (tmpFuture.events.length > 0) {
        future.push(tmpFuture);
      }
    });
    setEvents((prev: any) => ({
      [nextLabel]: (prev[nextLabel] || []).concat(future),
      [pastLabel]: (prev[pastLabel] || []).concat(past),
    }));
    setIsLoading({ [nextLabel]: false, [pastLabel]: false });
  };

  const loadLazyContent = useCallback(() => {
    if (!Array.isArray(lazyEvents)) {
      const _payload = { ...lazyEvents };
      loadEvents(_payload[nextLabel].splice(0, 2), nextLabel);
      loadEvents(_payload[pastLabel].splice(0, 2), pastLabel);
      setLazyEvents({ [nextLabel]: _payload[nextLabel], [pastLabel]: _payload[pastLabel] });
    } else {
      const _payload = [...lazyEvents];
      loadEvents(_payload.splice(0, 2));
      setLazyEvents(_payload);
    }
  }, [lazyEvents, nextLabel, pastLabel]);

  return (
    <>
      <div css={rootPage}>
        <div css={headerDouble}>
          <div css={headerDoubleLeft}>
            <ResourcesHeader title={title} description={description} isH1={isH1} />
          </div>
        </div>
      </div>

      <div css={tabsContainer}>
        {
          <Tabs tabPosition={'center'} padding={'1em 2em'}>
            {tabs &&
              tabs.map((tab: string) => (
                <Tab label={tab} onTabChange={() => setSelectedTab(tab)} selected={selectedTab === tab}>
                  <Loader loading={isLoading[tab]} centered css={{ minHeight: 400 }}>
                    <div css={resourceContent('column')}>
                      {events && events[tab] && (
                        <InfiniteScroll
                          dataLength={events[tab].length}
                          next={loadLazyContent}
                          hasMore={!Array.isArray(lazyEvents) ? lazyEvents[tab].length !== 0 : lazyEvents.length !== 0}
                          loader={!Array.isArray(lazyEvents) ? <div css={empty}>Loading...</div> : <></>}
                        >
                          {events[tab].length > 0 ? (
                            events[tab].map((data: any, index: number) => (
                              <div
                                key={`letter-key-${index}`}
                                css={rowContainer(index % 2 == 0 ? 'transparent' : '#F6F8F9')}
                              >
                                <div css={childContainer}>
                                  <div css={rootPage}>
                                    <div css={rowLabel}>{data.label}</div>
                                  </div>
                                  {data &&
                                    data.events &&
                                    data.events.map((child: any, index: number) => (
                                      <EventItemRow data={child} key={`letter-key-${index}`} index={index} />
                                    ))}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div css={empty}>The search did not return any results</div>
                          )}
                        </InfiniteScroll>
                      )}
                    </div>
                  </Loader>
                </Tab>
              ))}
          </Tabs>
        }
      </div>
    </>
  );
};
