/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, {useEffect, useState} from 'react';
import { style } from "./index.style";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
export interface MobileFilterContainerProps {
  open: boolean;
  children: any;
}
const source: string[] = ['Documents','Issuers','Programmes','Securities','Indices']

export const MobileFilterContainer = (props: MobileFilterContainerProps): JSX.Element => {
  const {
    open,
    children,
  } = props;

  useEffect(() => {

  }, [open]);

  const container = css`
    position: fixed;
    margin-left: ${open ? '0' : '-100%'};
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: #354450;
    transition: all .1s ease-in-out;
    z-index: 14;
    padding: 1em;
    overflow: auto;
  `
  return (
    <div css={container}>
      {children}
    </div>
  );
}


export default MobileFilterContainer;
