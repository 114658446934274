/** @jsx jsx */

import { breakpoint, breakpointMax } from "../../../style";
import { css, jsx } from "@emotion/react";

import navBg from "./ResourcesHeader/nav.png";
import { renderLink } from "../../../utils/helper";

export const childrenBloc = css`
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  @media (max-width: ${breakpointMax}) {
    padding-top: 16px;
    flex-direction: column;
  }
`;
export const tagContainer = css`
  display: flex;
  flex-wrap: wrap;
`;
export const tag = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #e3e6e8;
  border-radius: 8px;
  flex-wrap: nowrap;
  margin-bottom: 8px;
  margin-right: 8px;
  color: #354450;
`;
export const childrenBlocLeft = css`
  width: 280px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
export const childrenBlocRight = css`
  width: calc(100% - 280px);
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #253645;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    margin-top: 16px;
  }
`;
export const childrenBlocTitle = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 8px;
  }
`;
export const childrenBlocTitleRight = css`
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  color: #1a2934;
  margin-bottom: 24px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
`;
export const childrenBlocSubTitle = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #425563;
  margin-top: 16px;
  margin-bottom: 4px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
`;
export const letterBloc = css`
  padding-right: 80px;
  padding-left: 32px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  @media (max-width: ${breakpointMax}) {
    padding: 0;
  }
`;
export const childrenBlocDescription = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #253645;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
`;
export const childrenBlocDocument = css`
  margin-top: 24px;
  max-width: 100%;

  img {
    max-width: 100%;
  }
`;
export const childrenBlocDocumentLink = (
  isGreen?: boolean,
  isLarge?: boolean
) => css`
  display: flex;
  color: ${isGreen ? "#22AA5F" : "#425563"};
  margin-top: 24px;
  gap: 16px;
  align-self: ${isGreen && !isLarge
    ? !isLarge
      ? "inherit"
      : "flex-start"
    : "flex-start"};

  button {
    padding: 16px 32px;
  }
  
  a {
    text-decoration: none;
  }
`;
export const resourceContent = (flexDirection = "column", gap?: boolean) => css`
  display: flex;
  color: #8daeb7;
  width: 100%;
  flex-direction: ${flexDirection};
  gap: ${gap ? "20px" : "0"};
  @media (max-width: ${breakpointMax}) {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
`;
export const letterIndicatorBig = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #253645;
  margin-bottom: 40px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }
`;
export const letterIndicator = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #253645;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }
`;
export const letterIndicatorSmall = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #1a2934;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }
`;
export const rowContainer = (color: string) => css`
  background-color: ${color};
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
export const childContainer = (color: string) => css`
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${breakpointMax}) {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const headerDouble = css`
  display: flex;
  padding-right: 80px;
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    padding-right: 0;
    flex-direction: column;
    width: 100%;
  }
`;

export const mobileCenterPage = css`
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
export const rootPage = (backgroundColor = "transparent") => css`
  display: flex;
  flex-direction: column;
  //padding-right: 80px;
  ///padding-left: 32px;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${backgroundColor};
  @media (max-width: ${breakpointMax}) {
    padding-left: 16px;
    padding-right: 16px;

    button {
      font-size: 14px;
    }
  }
`;
export const twoColsWithAd = css`
  display: flex;
  padding-top: 80px;
  padding-bottom: 80px;
  max-width: 820px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
export const twoColsWithAdLeft = css`
  display: flex;
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
export const twoColsWithAdRight = css`
  display: flex;
  width: 30%;
  justify-content: flex-end;
  padding-left: 32px;

  & > div {
    max-width: 280px;
  }
  @media (max-width: ${breakpointMax}) {
    padding-left: 0;
    justify-content: center;
    width: 100%;
  }
`;
export const headerDoubleLeft = css`
  display: flex;
  width: 60%;
  padding-bottom: 120px;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
export const headerDoubleRight = css`
  display: flex;
  width: 40%;
  justify-content: flex-end;
  @media (max-width: ${breakpointMax}) {
    padding-left: 0;
    justify-content: center;
    width: 100%;
    padding-top: 40px;

    & > div {
      background: #f8fafc;
      max-width: 100%;
    }
  }
`;
export const tabsContainer = css`
  @media (max-width: ${breakpointMax}) {
    padding-top: 40px;
  }
`;

export const rowLabel = css`
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #354450;
  padding-top: 80px;
  padding-bottom: 40px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    padding-top: 40px;
    padding-bottom: 20px;
  }
`;

export const empty = css`
  display: flex;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
  justify-content: center;
  width: 100%;
`;
export const childrenBlocDocumentLabel = css`
  svg {
    color: #22aa5f;
    margin-right: 16px;
  }
`;
export const content = css`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    padding: 0;
  }
`;
export const topBloc = (padding?: boolean) => css`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  justify-content: flex-start;
  padding-bottom: ${padding ? "160px" : "0"};
`;
export const titleStyle = css`
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  display: flex;
  align-items: center;
  color: #354450;
  margin-top: 80px;

  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    display: flex;
    align-items: center;
  }
`;
export const descriptionStyle = css`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
  margin-top: 40px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    margin-top: 20px;
  }
`;
export const selectors = css`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 40px;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
export const childPart = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  max-width: 100%;
  flex-wrap: wrap;
`;
export const selectorLabel = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #354450;
  margin-right: 40px;
`;
export const selector = css`
  display: flex;
  align-content: center;
  justify-content: center;
  @media (max-width: ${breakpointMax}) {
    flex-wrap: wrap;
  }
`;
export const selectorRight = css`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: 80px;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    align-items: center;
    margin-top: 24px;
  }
`;
export const selectorButtons = (margin: boolean) => css`
  display: flex;
  @media (max-width: ${breakpointMax}) {
    flex-wrap: wrap;
    margin-top: ${margin ? "24px" : "0"};
  }
`;
export const selectorLetters = css`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
export const button = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d1d5d8;
  border-left: none;
  cursor: pointer;
  @media (max-width: ${breakpointMax}) {
    border-left: 1px solid #d1d5d8;
  }
`;

export const firstButton = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d1d5d8;
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;
`;

export const firstButtonSelected = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background: #e8faf0;
  box-shadow: inset 0 0 0 1px #22aa5f;
  border: solid 1px #22aa5f;
  border-radius: 4px 0px 0px 4px;
  cursor: pointer;
`;

export const buttonSelected = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background: #e8faf0;
  box-shadow: inset 0 0 0 1px #22aa5f;
  border: solid 1px #22aa5f;
  cursor: pointer;
`;

export const buttonLetter = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d1d5d8;
  cursor: pointer;
  border-radius: 4px;
`;
export const buttonLetterDisabled = css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 8px 16px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d1d5d8;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0.3;
`;

export const buttonSelectedLetter = css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 8px 16px;
  gap: 8px;
  cursor: pointer;
  border: solid 1px transparent;
  border-bottom: solid 2px #22aa5f;
`;
export const bannerForm = css`
  width: 100%;
  //width: calc(100% + 212px);
  //margin-left: -180px;
  background-image: url(${renderLink(navBg)});
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  padding: 24px 80px;
  @media (max-width: ${breakpointMax}) {
    margin-bottom: 32px;
    padding: 16px;
  }
`;

export const bannerFormTop = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const bannerFormTopLeft = css`
  width: 100%;
  display: flex;
  color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;

  span {
    color: #22aa5f;
    margin-right: 4px;
  }
`;

export const bannerFormTopRight = css`
  width: 100%;
  display: flex;
  color: #22aa5f;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-decoration-line: underline;
  justify-content: flex-end;
  cursor: pointer;
  svg {
    margin-right: 4px;
  }
`;

export const bannerFormTags = css`
  width: 100%;
  min-height: 80px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: ${breakpointMax}) {
    flex-wrap: wrap;
  }
`;

export const bannerFormTag = css`
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`;
