/** @jsx jsx */

import * as React from "react";

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  RichText,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import { renderLink } from "../../utils/helper";
import { canUseDOM } from "../../predicates";

const lint = jsx; // linter hack

export interface BannerBrandmarksTextProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const BannerBrandmarksText: React.FunctionComponent<BannerBrandmarksTextProps> =
  (props: BannerBrandmarksTextProps): React.ReactElement => {
    const { fields } = props;

    const buttonLabel: string = getFieldValue<string>(
      fields,
      "buttonLabel",
      ""
    );
    const buttonLabel2: string = getFieldValue<string>(
      fields,
      "buttonLabel2",
      ""
    );
    const buttonLabel3: string = getFieldValue<string>(
      fields,
      "buttonLabel3",
      ""
    );
    const mailToSubject: string = getFieldValue<string>(
      fields,
      "mailToSubject",
      ""
    );
    const mailTo: string = getFieldValue<string>(fields, "mailTo", "");
    const buttonDownloadLink: any = getFieldValue<any>(
      fields,
      "downloadPath",
      ""
    );
    const buttonDownloadLink2: any = getFieldValue<any>(
      fields,
      "buttonDownloadLink2",
      ""
    );
    const buttonDownloadLink3: any = getFieldValue<any>(
      fields,
      "buttonDownloadLink3",
      ""
    );
    const buttonLink: string = getFieldValue<string>(fields, "buttonLink", "");
    const buttonLink2: string = getFieldValue<string>(
      fields,
      "buttonLink2",
      ""
    );
    const buttonLink3: string = getFieldValue<string>(
      fields,
      "buttonLink3",
      ""
    );
    const linkpath1: string = getFieldValue<string>(fields, "linkPath1", "");
    const linkpath2: string = getFieldValue<string>(fields, "linkPath2", "");
    const linkpath3: string = getFieldValue<string>(fields, "linkPath3", "");
    const buttonIcon: string = getFieldValue<string>(fields, "buttonIcon", "");
    const buttonIcon2: string = getFieldValue<string>(
      fields,
      "buttonIcon2",
      ""
    );
    const buttonIcon3: string = getFieldValue<string>(
      fields,
      "buttonIcon3",
      ""
    );

    const isWhiteText = Number(getFieldValue<number>(fields, "whiteText", 0));
    const isGreenHeadingTitle = Number(getFieldValue<number>(fields, "isGreenHeadingTitle", 0));
    const isGreenText = Number(getFieldValue<number>(fields, "greenText", 0));
    const isBlueText = Number(getFieldValue<number>(fields, "blueText", 0));
    const isDarkGreenBackground = Number(
      getFieldValue<number>(fields, "isDarkGreenBackground", 0)
    );
    const isGreenBackground = Number(
      getFieldValue<number>(fields, "isGreenBackground", 0)
    );
    const isHeadingTitle: string = getFieldValue<string>(
      fields,
      "headingTitle",
      ""
    );
    const button1IsGray = Number(
      getFieldValue<number>(fields, "button1Gray", 0)
    );
    const button2IsGray = Number(
      getFieldValue<number>(fields, "button2Gray", 0)
    );
    const button3IsGray = Number(
      getFieldValue<number>(fields, "button3Gray", 0)
    );

    const gradientType = getFieldValue<any>(fields, "gradientColor", null);
    const smallImage: any = getFieldValue<any>(fields, "smallImage", null);

    const isWhiteButton = () => {
      if (gradientType === "apple500" || gradientType === "peach500") {
        return "white";
      }
      return false;
    };

    const style = {
      containerDoubleTitle: css`
        color: ${isWhiteText ? "#FFFFFF" : isGreenText ? "#22AA5F" : isBlueText ? "#425563" : "#425563"};
        ${isGreenBackground && "color: #cce8d8"};
        ${isDarkGreenBackground && "color: #cce8d8"};
        font-size: 3em;
        font-weight: 800;
        margin-bottom: 24px;

        @media (max-width: ${breakpointMax}) {
          font-weight: 800;
          font-size: 32px;
          line-height: 41px;
        }
      `,
      containerDoubleHeadingTitle: css`
        color: ${isWhiteText ? "#FFFFFF" : isGreenHeadingTitle ? "#008C3D" : isGreenText ? "#22AA5F" : isBlueText ? "#425563" : "#CA1234"};
        ${isGreenBackground && "color: #cce8d8"};
        ${isDarkGreenBackground && "color: #cce8d8"};
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        @media (max-width: ${breakpointMax}) {
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
        }
      `,
      containerDoubleSubTitle: css`
        color: ${isWhiteText ? "#FFFFFF" : isGreenText ? "#22AA5F" : isBlueText ? "#425563" : "#425563"};
        ${isGreenBackground && "color: #cce8d8"};
        ${isDarkGreenBackground && "color: #cce8d8"};
        font-weight: 600;
        font-size: 1em;
        line-height: 150%;

        p {
          color: ${isWhiteText ? "#FFFFFF" : isGreenText ? "#22AA5F" : isBlueText ? "#425563" : "#425563"} !important;
        }

        @media (max-width: ${breakpointMax}) {
          font-weight: 600;
          font-size: 14px;
          line-height: 180%;
          letter-spacing: 0.01em;
        }
      `,
      containerDoubleButton: css`
        margin-top: 2em;

        button {
          font-weight: 600;
          font-size: 16px;
        }
      `,
      headImg: css`
        margin-bottom: 8px;

        img {
          height: 38px;
        }

        @media (max-width: ${breakpointMax}) {
          img {
            height: 30px;
          }
        }
      `,
    };

    const triggerAction = (link1: string, link2: string, link3: any) => {
      if (canUseDOM) {
        if (link1) {
          window.open(renderLink(link1), "_self");
        } else if (link2) {
          window.open(renderLink(link2), "_self");
        } else if (link3) {
          window.open(renderLink(link3?.href));
        }
      }
    };

    return (
      <>
                  {smallImage?.src && (
                    <div css={style.headImg}>
                      <img alt=" " src={renderLink(smallImage.src)} />
                    </div>
                  )}
                  {isHeadingTitle && (
                    <div css={style.containerDoubleHeadingTitle}>
                      <Text
                        field={
                          fields &&
                          (fields.headingTitle as SitecoreGenericTextField)
                        }
                      />
                    </div>
                  )}
                  <div css={style.containerDoubleTitle}>
                    <Text
                      field={
                        fields && (fields.title as SitecoreGenericTextField)
                      }
                    />
                  </div>
                  <div css={style.containerDoubleSubTitle}>
                    <RichText
                      field={
                        fields && (fields.subTitle as SitecoreGenericTextField)
                      }
                    />
                  </div>
                  {buttonLabel && !mailTo && (
                    <div css={style.containerDoubleButton}>
                      <Button
                        variant={
                          isWhiteButton()
                            ? "thirdary"
                            : !button1IsGray
                              ? "primary"
                              : "thirdary"
                        }
                        label={buttonLabel}
                        onClick={() =>
                          triggerAction(
                            buttonDownloadLink && buttonDownloadLink.src,
                            buttonLink,
                            linkpath1
                          )
                        }
                        iconRight={buttonIcon ? "" : "chevron-right"}
                        iconLeft={(buttonIcon as any)}
                      />
                    </div>
                  )}
                  {buttonLabel && mailTo && (
                    <div css={style.containerDoubleButton}>
                      <Button
                        variant={
                          isWhiteButton()
                            ? "thirdary"
                            : !button1IsGray
                              ? "primary"
                              : "secondary"
                        }
                        label={buttonLabel}
                        onClick={() =>
                          canUseDOM &&
                          window.open(
                            `mailto:${mailTo}?subject=${mailToSubject || "Request a demo"
                            }`
                          )
                        }
                        iconLeft={(buttonIcon as any) || "paper-plane"}
                      />
                    </div>
                  )}
                  {buttonLabel2 && (
                    <div css={style.containerDoubleButton}>
                      <Button
                        variant={
                          isWhiteButton()
                            ? "thirdary"
                            : !button2IsGray
                              ? "primary"
                              : "secondary"
                        }
                        label={buttonLabel2}
                        onClick={() =>
                          triggerAction(
                            buttonDownloadLink2?.src,
                            buttonLink2,
                            linkpath2
                          )
                        }
                        iconRight={buttonIcon2 ? "" : "chevron-right"}
                        iconLeft={(buttonIcon2 as any)}
                      />
                    </div>
                  )}
                  {buttonLabel3 && (
                    <div css={style.containerDoubleButton}>
                      <Button
                        variant={
                          isWhiteButton()
                            ? "thirdary"
                            : !button3IsGray
                              ? "primary"
                              : "secondary"
                        }
                        label={buttonLabel3}
                        onClick={() =>
                          triggerAction(
                            buttonDownloadLink3?.src,
                            buttonLink3,
                            linkpath3
                          )
                        }
                        iconRight={buttonIcon3 ? "" : "chevron-right"}
                        iconLeft={(buttonIcon3 as any)}
                      />
                    </div>
                  )}
        </>
    );
  };