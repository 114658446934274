import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Tr, Td, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from '../base/index.style';
import Props from '../base/props';
import SearchSecurity from '../../../../../interfaces/search/search-security';

import { data as dataMapper } from '../../../Search/results/dataMapper';
import { formatDate } from '../../../../../utils/date';
import Type from '../type';
import { currencyLabel } from '../../../../../enums';
import { checkClimateAligned, computeCategoryLabel, RenderMarket, SearchSecurityGreen } from '../security/row';
import { BestOrders } from '../../../../../interfaces';
import { formatToThousandsSeparator } from '../../../../../utils';
import { goToSecurityDetailPage } from '../../../SecurityCard';

const SearchBidAsk = ({ bestOrders }: { bestOrders: BestOrders }) => {
  let bidPrice = 'N/A';
  let bidQuantity = 'N/A';
  let askPrice = 'N/A';
  let askQuantity = 'N/A';

  if (bestOrders?.bid?.length && bestOrders.bid[0]) {
    if (bestOrders.bid[0].price) bidPrice = formatToThousandsSeparator(parseFloat(bestOrders.bid[0].price));
    if (bestOrders.bid[0].quantity) bidQuantity = formatToThousandsSeparator(bestOrders.bid[0].quantity);
  }

  if (bestOrders?.ask?.length && bestOrders.ask[0]) {
    if (bestOrders.ask[0].price) askPrice = formatToThousandsSeparator(parseFloat(bestOrders.ask[0].price));
    if (bestOrders.ask[0].quantity) askQuantity = formatToThousandsSeparator(bestOrders.ask[0].quantity);
  }

  return (
    <div css={style.cellSplitTwo}>
      <div css={style.cellSplitLeft}>
        <div>{bidPrice}</div>
        <div css={style.subLabel}>{bidQuantity}</div>
      </div>
      <div css={[style.cellSplitRight, style.cellSplitSeparator]}>
        <div>{askPrice}</div>
        <div css={style.subLabel}>{askQuantity}</div>
      </div>
    </div>
  );
};

export const SecurityLuxXPrimeRow = ({ row, rowKey, selectedCols }: Props<SearchSecurity>) => {
  const onRowClickHandlerSecurity = (isin: any, id: any) => {
    goToSecurityDetailPage(isin, id);
  };

  if (!row) return <></>;

  return (
    <Tr css={style.actionsRow} 
        onRowClick={() => onRowClickHandlerSecurity(row.isinCode, row.id)} 
        key={rowKey? rowKey : row.id}>
      <Td>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Type isGreen={row.lgxDisplay || checkClimateAligned( row.issuers)} label={computeCategoryLabel(row.category)} />
          <SearchSecurityGreen security={row} />
        </div>
      </Td>
      <Td>
        <RenderMarket markets={row.marketRule} />
      </Td>
      <Td>{row.issuers?.length > 0 && row.issuers[0] ? row.issuers[0].name : 'N/A'}</Td>
      <Td>
        <div>{row.name}</div>
        <div css={style.subLabel}>{row.isinCode}</div>
      </Td>
      <Td>{row.currency}</Td>
      <Td>{row && row?.interestRate ? `${row.interestRate} %` : 'N/A'}</Td>
      <Td>{formatDate(row.finalMaturityDate)}</Td>
      <Td>{row?.marketData?.yieldToMaturity ? `${row.marketData.yieldToMaturity} %` : '-'}</Td>
      <Td>
        <SearchBidAsk bestOrders={row.marketRealtime?.bestOrders} />
      </Td>
      <Td>{row?.marketData?.dayVolume ? row.marketData.dayVolume : '-'}</Td>
      <Td>
        {row?.marketData?.lastPrice
          ? `${row.marketData.lastPrice.amount} ${row.marketData.lastPrice.marker} ${currencyLabel(
              row.marketData.lastPrice.currency
            )}`
          : 'N/A'}
      </Td>
      <Td>
        {row?.marketData?.lastPrice ? (
          <span style={{ color: row?.marketData?.lastPrice?.changePercent < 0 ? '#CB1234' : '#22AA5F' }}>
            <Icon icon={row?.marketData?.lastPrice?.changePercent < 0 ? 'chevron-down' : 'chevron-up'} />
            {`${row?.marketData?.lastPrice?.changePercent} %`}
          </span>
        ) : (
          'N/A'
        )}
      </Td>

      {selectedCols ? (
        selectedCols['securities']?.map((col: string, index: number) => {
          return (
            col.toLowerCase() !== 'id' && (
              <Td key={`col-selected-${index}`}>
                {dataMapper.securities[col] && dataMapper.securities[col].render((row as any)[col])}
              </Td>
            )
          );
        })
      ) : (
        <></>
      )}
    </Tr>
  );
};
