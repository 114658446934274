import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { ResourcesHeader } from "../ResourcesHeader";
import Loader from "../../Loader/loader.component";
import {
  fetchData,
  getDataWithChild,
  getDataWithoutChild,
} from "../../../../utils/helper";
import { FormItemRow } from "./FormItemRow";
import {
  bannerForm,
  bannerFormTag,
  bannerFormTags,
  bannerFormTop,
  bannerFormTopLeft,
  bannerFormTopRight,
  empty,
  mobileCenterPage,
  resourceContent,
  rootPage,
} from "../shared.style";
import Tag from "../../Tag/tag.component";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";

export const ResourcesForms = (props: any) => {
  const { title, description, isH1 } = props;
  const [allForms, setAllForms] = React.useState<any[]>([]);
  const [forms, setForms] = React.useState<any[]>([]);
  const [thematics, setThematics] = React.useState<any[]>([]);
  const [categories, setCategories] = React.useState<any[]>([]);
  const [isLoadingForms, setIsLoadingForms] = React.useState<boolean>(true);
  const [selectedCategories, setSelectedCategories] = React.useState<any[]>([]);

  const fetchSubData = async (id: string, name?: string) => {
    try {
      const request: any = await fetchData(id, true);
      const result = await getDataWithoutChild(request);
      return result;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchResourcesForms = async (id: string) => {
    setIsLoadingForms(true);
    const filledData = [];
    try {
      const data: any = await fetchData(id);
      for (const elem of data || []) {
        if (elem.HasChildren === "True") {
          const subRequest = await fetchSubData(elem.ItemID, elem.label);
          filledData.push({
            ...elem,
            child: subRequest,
            categories: subRequest.map((sub: any) => sub.categories[0]),
            thematics: subRequest.map((sub: any) => sub.thematics[0]),
          });
        } else {
          filledData.push(elem);
        }
      }
      setAllForms(filledData);
      setForms(filledData);
      setIsLoadingForms(false);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchGlossaryCategories = async (id: string) => {
    try {
      const result: any = await fetchData(id);
      const data = await getDataWithChild(result, ["Categories", "Thematics"]);
      setCategories(data.map((d: any) => d.label));
      //setSelectedCategories(data.map((d:any) => d.label));
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    fetchGlossaryCategories("07BA70DD-3DA8-4FB7-ABC5-0A2CE5CDA0DB");
    fetchResourcesForms("894E0CB1-FC69-4928-B194-7E763BDA18A4");
  }, []);

  const filterThematic = (theme: string) => {
    if (theme === "all") {
      setForms(allForms);
    } else {
      const newThematicsLetters = allForms.filter((t: any) =>
        t.thematics.map((p: any) => p.label).includes(theme)
      );
      //const newThematicsCategories = allForms.filter((t: any) => t.thematics && t.thematics.map((p: any) => p.label).includes(theme));
      setForms(newThematicsLetters);
      //setForms(newThematicsCategories);
    }
  };
  const filterCategories = (targetCategories: string[]) => {
    if (targetCategories.length > 0) {
      const result = allForms.filter((currentSource) =>
        currentSource?.categories.find((category: any) =>
          targetCategories.includes(category)
        )
      );
      setForms(result);
    } else {
      setSelectedCategories([]);
      setForms(allForms);
    }
  };

  const mergeSelected = (label: string) => {
    if (selectedCategories.includes(label)) {
      const data = selectedCategories.filter(
        (category: any) => category !== label
      );
      setSelectedCategories(data);
      if (data.length === 0) {
        filterCategories([]);
      }
    } else {
      setSelectedCategories(selectedCategories.concat(label));
    }
  };

  React.useEffect(() => {
    if (selectedCategories.length > 0) {
      filterCategories(selectedCategories);
    }
  }, [selectedCategories]);

  return (
    <>
      <div css={rootPage}>
        <ResourcesHeader
          title={title}
          description={description}
          onChangeThematic={(thematic: string) => filterThematic(thematic)}
          thematics={thematics.map((t: any) => t && t.label)}
          categories={categories}
          onSelectCategories={(categories: string[]) => filterCategories([])}
          counter={forms.length}
          withPadding
          isH1={isH1}
        />
      </div>
      {categories && !!categories.length && (
        <div css={bannerForm}>
          <div css={rootPage}>
            <div css={bannerFormTop}>
              <div css={bannerFormTopLeft}>
                <span>
                  {forms.reduce((acc, a) => acc + a.child?.length, 0)}
                </span>{" "}
                form
                {forms.reduce((acc, a) => acc + a.child.length, 0) > 1
                  ? "s"
                  : ""}
              </div>
              <div
                css={bannerFormTopRight}
                onClick={() => filterCategories([])}
              >
                <Icon icon={"times-circle"} /> Reset all filters
              </div>
            </div>
            <div css={bannerFormTags}>
              {categories.map((category: string) => (
                <div
                  key={category}
                  css={bannerFormTag}
                  onClick={() => mergeSelected(category)}
                >
                  <Tag
                    label={category}
                    selected={selectedCategories.includes(category)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Loader loading={isLoadingForms} centered css={{ minHeight: 400 }}>
        <div css={resourceContent}>
          <div css={mobileCenterPage}>
            {forms.length > 0 ? (
              forms.map((data: any, index: number) => (
                <FormItemRow
                  label={data?.label}
                  data={data}
                  key={`letter-key-${index}`}
                  index={index}
                />
              ))
            ) : (
              <div css={empty}>The search did not return any results</div>
            )}
          </div>
        </div>
      </Loader>
    </>
  );
};
