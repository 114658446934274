/** @jsx jsx */

import * as React from "react";

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";

import {CarousselContent} from "../CarousselContent";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from "../../interfaces";
import { canUseDOM } from "../../predicates";

export interface CarousselProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: all .600s ease-in-out;
  @media (min-width: ${breakpoint}) {
    min-height: 550px;
  }
  @media (max-width: ${breakpointMax}) {
    height: auto;
    min-height: auto;
  }
`;
const hider = css`
  width: 100%;
  position: absolute;
  height: 25px;
  background: #ffffff;
  margin-bottom: 25px;
  bottom: 0;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const hideScroll = css`
  width: 100%;
  height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  transition: all .600s ease-in-out;
`;

export const Caroussel: React.FunctionComponent<CarousselProps> = (
  props: CarousselProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const [position, setPosition] = useState<number>(1);
  const [itemCounter, setItemsCounter] = useState<number>(0);
  const isGreen: number = getFieldValue<number>(fields, "isGreen", 0);
  const loop: number = getFieldValue<number>(fields, "loop", 1);

  useEffect(() => {
    if (canUseDOM) {
      setItemsCounter(
        document.getElementsByClassName("caroussel-content").length
      );
    }
  }, []);

  const navigate = (direction: string) => {
    if (direction === "next") {
      if (position < itemCounter) {
        setPosition(position + 1);
      } else {
        setPosition(1);
      }
    } else {
      if (position === 1) {
        setPosition(itemCounter);
      } else {
        setPosition(position - 1);
      }
    }
  };

  useEffect(() => {
    if (loop) {
      const timer = setTimeout(() => {
        navigate('next');
      }, 6000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [itemCounter]);

  useEffect(() => {
    if (document.getElementsByClassName("caroussel-content")[position - 1] && canUseDOM) {
      let elemPosition: any = document.getElementsByClassName("caroussel-content")[position - 1].getBoundingClientRect();
      if (position === 1) {
        (document.getElementsByClassName("caroussel-content")[0] as any).style.marginLeft = '0px';
      } else {
        (document.getElementsByClassName("caroussel-content")[0] as any).style.marginLeft = `-${elemPosition.left * (position - 1) }px`;
      }

      if (loop) {
        const timer = setInterval(() => {
          navigate('next');
        }, 6000);
        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [position]);

  return (
    <>
      <div css={parentContainer}>
        <div css={hideScroll}>
          {new Array(6).fill(0).map((arr: any, index: number) => {
            if (fields && fields[`description ${index + 1}`] && (fields && fields[`description ${index + 1}`] as any).value) {
              return (
                <CarousselContent
                    className={"caroussel-content"}
                    itemCounter={itemCounter}
                    selected={position}
                    rendering={rendering}
                    fields={{
                      heading: {
                        value: ((fields && fields[`heading ${index + 1}`] && (fields && fields[`heading ${index + 1}`] as any).value) || null),
                      },
                      title: {
                        value: ((fields && fields[`title ${index + 1}`] && (fields && fields[`title ${index + 1}`] as any).value) || null),
                      },
                      description: {
                        value: ((fields && fields[`description ${index + 1}`] && (fields && fields[`description ${index + 1}`] as any).value) || null),
                      },
                      author: {
                        value: ((fields && fields[`author ${index + 1}`] && (fields && fields[`author ${index + 1}`] as any).value) || null),
                      },
                    }}
                    isGreen={!!isGreen}
                    imageLeft={((fields && fields[`left image ${index + 1}`] && (fields && fields[`left image ${index + 1}`] as any).value) && ((fields && fields[`left image ${index + 1}`] as any).value.src))}
                    imageCenter={((fields && fields[`center image ${index + 1}`] && (fields && fields[`center image ${index + 1}`] as any).value) && ((fields && fields[`center image ${index + 1}`] as any).value.src))}
                />
              )
            }
          })
          }
          <div css={hider} />
        </div>
      </div>
      {/*
      <div style={{ display: "flex" }}>
        <div onClick={() => navigate("back")}>
          <Icon icon={"arrow-left"} />
        </div>
        <div onClick={() => navigate("next")}>
          <Icon icon={"arrow-right"} />
        </div>
        position : {position}
      </div>
      */}
    </>
  );
};
