import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import {Table} from "@bdl-cmn-shared-packages-npm/design-system";
import CssfApprovalsHeader from "./cssf-approvals-header.component";
import CssfApprovalsRow from "./cssf-approvals-row.component";

export const CssfApprovalsTable = (props: any) => {
    const cssfApprovalsList: any[] = props?.cssfApprovalsData;

    return (

        <Table>
            <CssfApprovalsHeader/>
            <tbody>
            {cssfApprovalsList?.map((x: any) => {
               return <CssfApprovalsRow row={x}/>
                })
            }
            </tbody>

        </Table>

    )

}
export default CssfApprovalsTable;