
/*
*   e-Listing - core - components - country flag icon - svk
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const SVK: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.337' style={{fill: '#F0F0F0'}} width='512' height='341.326'/>
            <rect y='196.641' style={{fill: '#0052B4'}} width='512' height='118.717'/>
            <rect y='315.359' style={{fill: '#D80027'}} width='512' height='111.304'/>
            <path style={{fill: '#F0F0F0'}} d='M129.468,181.799v85.136c0,48.429,63.267,63.267,63.267,63.267S256,315.362,256,266.935v-85.136  H129.468z'/>
            <path style={{fill: '#D80027'}} d='M146.126,184.294v81.941c0,5.472,1.215,10.64,3.623,15.485c23.89,0,59.599,0,85.97,0  c2.408-4.844,3.623-10.012,3.623-15.485v-81.941H146.126z'/>
            <polygon style={{fill: '#F0F0F0'}} points='221.301,241.427 199.876,241.427 199.876,227.144 214.16,227.144 214.16,212.861   199.876,212.861 199.876,198.577 185.593,198.577 185.593,212.861 171.311,212.861 171.311,227.144 185.593,227.144   185.593,241.427 164.167,241.427 164.167,255.711 185.593,255.711 185.593,269.994 199.876,269.994 199.876,255.711   221.301,255.711 '/>
            <path style={{fill: '#0052B4'}} d='M169.232,301.658c9.204,5.783,18.66,9.143,23.502,10.636c4.842-1.494,14.298-4.852,23.502-10.636  c9.282-5.833,15.79-12.506,19.484-19.939c-4.075-2.883-9.047-4.583-14.418-4.583c-1.956,0-3.856,0.232-5.682,0.657  c-3.871-8.796-12.658-14.94-22.884-14.94c-10.227,0-19.013,6.144-22.884,14.94c-1.827-0.425-3.728-0.657-5.682-0.657  c-5.372,0-10.344,1.701-14.418,4.583C153.443,289.152,159.95,295.825,169.232,301.658z'/>
    </svg>
);