
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const BIH: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#002395" d="M503.172,423.724H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.792C512,419.772,508.047,423.724,503.172,423.724z" />
            <polygon fill="#FECB00" points="441.378,88.276 441.378,423.724 105.931,88.276 " />
            <g>
                <path fill="#FFFFFF" d="M207.353,213.176l5.088,15.254l16.079,0.125c1.246,0.01,1.762,1.599,0.76,2.339l-12.936,9.553 l4.851,15.331c0.376,1.187-0.977,2.17-1.991,1.446l-13.082-9.351l-13.082,9.351c-1.013,0.724-2.366-0.258-1.991-1.446l4.851-15.331 l-12.936-9.553c-1.002-0.74-0.486-2.329,0.76-2.339l16.079-0.125l5.088-15.254C205.287,211.995,206.958,211.995,207.353,213.176z" />
                <path fill="#FFFFFF" d="M170.571,176.395l5.088,15.254l16.079,0.125c1.246,0.01,1.762,1.6,0.76,2.339l-12.936,9.553 l4.851,15.331c0.376,1.187-0.977,2.17-1.991,1.446l-13.082-9.351l-13.082,9.351c-1.013,0.725-2.366-0.258-1.991-1.446l4.851-15.331 l-12.936-9.553c-1.002-0.74-0.486-2.329,0.76-2.339l16.079-0.125l5.088-15.254C168.505,175.213,170.177,175.213,170.571,176.395z" />
                <path fill="#FFFFFF" d="M133.79,139.613l5.088,15.254l16.079,0.125c1.246,0.01,1.762,1.599,0.76,2.339l-12.936,9.553 l4.851,15.331c0.376,1.187-0.977,2.17-1.991,1.446l-13.082-9.351l-13.082,9.351c-1.013,0.725-2.366-0.258-1.991-1.446l4.851-15.331 l-12.936-9.553c-1.002-0.74-0.486-2.329,0.76-2.339l16.079-0.125l5.088-15.254C131.724,138.431,133.396,138.431,133.79,139.613z" />
                <path fill="#FFFFFF" d="M97.008,102.832l5.088,15.254l16.079,0.125c1.246,0.01,1.762,1.599,0.76,2.339L106,130.102 l4.851,15.331c0.376,1.187-0.977,2.17-1.991,1.446l-13.082-9.351l-13.082,9.351c-1.013,0.724-2.366-0.258-1.991-1.446l4.851-15.331 L72.62,120.55c-1.002-0.74-0.486-2.329,0.76-2.339l16.079-0.125l5.088-15.254C94.942,101.65,96.613,101.65,97.008,102.832z" />
                <path fill="#FFFFFF" d="M354.479,360.303l5.088,15.254l16.079,0.125c1.246,0.01,1.762,1.599,0.76,2.339l-12.936,9.553 l4.851,15.331c0.376,1.187-0.977,2.171-1.99,1.446L353.249,395l-13.082,9.351c-1.013,0.725-2.366-0.258-1.991-1.446l4.851-15.331 l-12.936-9.553c-1.002-0.74-0.486-2.329,0.76-2.339l16.079-0.125l5.088-15.254C352.413,359.121,354.085,359.121,354.479,360.303z" />
                <path fill="#FFFFFF" d="M317.698,323.521l5.088,15.254l16.079,0.125c1.246,0.01,1.762,1.599,0.76,2.339l-12.936,9.553 l4.851,15.331c0.376,1.187-0.977,2.171-1.99,1.446l-13.082-9.351l-13.082,9.351c-1.013,0.724-2.366-0.258-1.991-1.446l4.851-15.331 l-12.936-9.553c-1.002-0.74-0.486-2.329,0.76-2.339l16.079-0.125l5.088-15.254C315.632,322.339,317.304,322.339,317.698,323.521z" />
                <path fill="#FFFFFF" d="M280.915,286.74l5.088,15.254l16.079,0.125c1.246,0.01,1.762,1.599,0.76,2.339l-12.936,9.553 l4.851,15.331c0.376,1.187-0.977,2.171-1.991,1.445l-13.082-9.351l-13.082,9.351c-1.013,0.724-2.366-0.258-1.991-1.445 l4.851-15.331l-12.936-9.553c-1.002-0.74-0.486-2.329,0.76-2.339l16.079-0.125l5.088-15.254 C278.85,285.559,280.521,285.559,280.915,286.74z" />
                <path fill="#FFFFFF" d="M244.134,249.958l5.088,15.254l16.079,0.125c1.246,0.01,1.762,1.599,0.76,2.339l-12.936,9.553 l4.851,15.331c0.376,1.187-0.977,2.171-1.991,1.446l-13.082-9.351l-13.082,9.351c-1.013,0.724-2.366-0.258-1.991-1.446 l4.851-15.331l-12.936-9.553c-1.002-0.74-0.486-2.329,0.76-2.339l16.079-0.125l5.088-15.254 C242.068,248.776,243.74,248.776,244.134,249.958z" />
                <path fill="#FFFFFF" d="M41.944,88.276l6.83,5.044l-4.851,15.331c-0.375,1.187,0.977,2.17,1.991,1.447l13.081-9.351 l13.082,9.351c1.013,0.724,2.366-0.258,1.99-1.447l-4.85-15.331l6.833-5.044H41.944z" />
                <path fill="#FFFFFF" d="M412.427,412.464l-16.079-0.125l-5.087-15.254c-0.394-1.182-2.066-1.182-2.46,0l-5.087,15.254 l-16.079,0.125c-1.246,0.01-1.762,1.6-0.76,2.339l12.083,8.921h22.151l12.082-8.921 C414.189,414.063,413.673,412.473,412.427,412.464z" />
            </g>
        </svg>
    </React.Fragment>
);
