import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { style } from "./banner.style";
import RoundTag from "../../RoundTag/roundTag.component";
import { CountryFlagIcon } from "../../CountryFlagIcon/countryFlagIcon.component";
import ConceptualBanner from "../../ConceptualBanner/conceptualBanner.component";
import { Country, levelOneClassificationLabels } from "../../../../enums";
import { IssuerDetail } from "../../../../interfaces/search/search-issuer";
import { issuerTypeLabel } from "../../../../enums/issuer-type";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export interface Row {
  label: string;
  action: () => void;
}

export interface IssuerBannerProps {
  green?: boolean;
  issuer: IssuerDetail;
}

const IssuerBanner = ({ green, issuer }: IssuerBannerProps) => {
  const type = issuer.type ? issuer.type : issuer.level1Classification;
  const country = issuer.country ? issuer.country : issuer.countryCode2;


  return (
    <>
      <ConceptualBanner
        green={false}
        renderLeft={
          <>
            <div
              css={css`
                > div {
                  min-height: 39px;
                  min-width: 39px;
                  font-size: 28px !important;
                  color: #ffffff;
                  background-color:  rgb(203 18 52 / 15%);
                }
              `}
            >
              <RoundTag
                green={issuer.statistics.nbGreens > 0}
                borderSize={2}
                size={14}
              >
                I
              </RoundTag>
            </div>
            <div css={style.info}>
              <div css={style.issuerNameText}>{issuer.name}</div>
              <div css={style.market}>
                <CountryFlagIcon
                  code={country}
                  size={{ width: 21, height: 21 }}
                />
                <span>
                  {country == "TW"
                    ? Country.TW
                    : countries.getName(country, "en", { select: "official" })}
                </span>
              </div>
            </div>
          </>
        }
      >
        <>
          <div css={style.metadata}>
            {type ? <div>{issuerTypeLabel(type)}</div> : <></>}
            <div>{`${issuer.statistics?.nbProgs || 0} programmes`}</div>
            <div>{`${issuer.statistics?.nbSecurities || 0} securities`}</div>
            <div>{`${issuer.statistics?.nbBonds || 0} bonds`}</div>
            <div>{`${issuer.statistics?.nbWarrants || 0} warrants`}</div>
            <div>{`${issuer.statistics?.nbEquities || 0} equities`}</div>
            <div>{`${
              issuer.statistics?.nbCertificates || 0
            } certificates`}</div>
            <div>{`${issuer.statistics?.nbGreens || 0} LGX Labelled`}</div>
            {/*<div>{`${
              issuer.statistics?.caiAsPerCBI
                ? issuer.statistics?.nbSecurities - issuer.statistics?.nbGreens
                : 0
            } LGX Others`}</div>*/}
          </div>
        </>
      </ConceptualBanner>
      {/*issuer.climateAligned ? (
        <div css={style.cai}>
          <div css={style.caiTitle}>Climate Aligned Issuer by CBI</div>
          <div css={style.caiRow}>
            <CAIProgressBar percent={issuer.climateAlignedAlignmentPercent} />
          </div>
          <div css={style.caiRow}>
            <div css={style.caiLabel}>% of alignment</div>
            <div css={style.caiValue}>
              {issuer && issuer.climateAlignedAlignmentPercent ? `${issuer.climateAlignedAlignmentPercent.toFixed(2)}%` : ''}
            </div>
          </div>
          <div css={style.caiRow}>
            <div css={style.caiLabel}>Year of evaluation</div>
            <div css={style.caiValue}>{issuer.climateAlignedEvaluationYear}</div>
          </div>
          <div css={style.caiRow}>
            <div css={style.caiLabel}>Level of alignment</div>
            <div css={style.caiValue}>{issuer.climateAlignedAlignmentLevel}</div>
          </div>
          <div css={style.caiRow}>
            <div css={style.caiLabel}>Sectors</div>
            <div css={style.caiValue}>
              {issuer.cbiSectors?.map((element, index) => (
                <>{`${element} ${index != issuer.cbiSectors.length - 1 ? ', ' : ''}`}</>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )*/}
    </>
  );
};

export const CAIProgressBar = ({ percent }: { percent: number }) => {
  const width: string = percent ? percent.toString() + "%" : "0px";

  const style = {
    container: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 8px;
      gap: 15px;
      width: 100px;
      height: 10px;
      background: #e8faf0;
      border-radius: 8px;
      color: #ffffff;
      position: relative;
    `,
    progress: css`
      background: #22aa5f;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    `,
  };
  return (
    <div css={style.container}>
      <div css={[style.progress, { width: percent + "%" }]} />
    </div>
  );
};

export default IssuerBanner;
