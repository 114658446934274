/**@jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import RoundTag from '../../../RoundTag/roundTag.component';
import { Tooltip } from '@bdl-cmn-shared-packages-npm/design-system';

const Type = ({ label, title, isGreen }: { label: string, isGreen?: boolean, title?: string }) => {
  return label ? (
    <div
      id="letter-round"
      css={css`
        > div {
          min-height: 30px;
          min-width: 30px;
        }
      `}
    >
      <Tooltip content={title}
               maxWidth="400px"
               tooltipPosition="top">
        <RoundTag green={isGreen} borderSize={2} textColor={'#425563'} size={14}>
          {label}
        </RoundTag>
      </Tooltip>

    </div>
  ) : (
    <div>N/A</div>
  );
};

export default Type;
