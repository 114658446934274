import * as React from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/react';

import { DefaultSitecoreProps } from '../../interfaces/default-sitecore';
import useFallers from '../../core/hooks/luxxprime/useFallers';
import LuxXPrimeSecurityTable from '../../core/components/LuxXPrime/Trade/LuxXPrimeSecurityTable';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-react';

/** @jsx jsx */
export const LuxXPrimeFallersList: React.FunctionComponent<DefaultSitecoreProps> = (
  props: DefaultSitecoreProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const limit = React.useMemo(() => {
    const l = getFieldValue<number>(fields, 'limit');
    return l > 0 ? l : 10;
  }, [fields]);
  const { data, isLoading} = useFallers(limit);

  return <LuxXPrimeSecurityTable data={data}
    isLoading={isLoading}
    limit={limit}
    icon={<Icon icon={"arrow-trend-down"} type='fas' />}
    title={"Fallers securities"}
    rowClass="fallers" />
};
