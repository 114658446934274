
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface LifeOnLandSvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const LifeOnLandSvg: React.FunctionComponent<LifeOnLandSvgProps> = ({ size: { width, height }, style }: LifeOnLandSvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 41 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>15 - Life on Land</title>
            <path d='M0.392225 2.21858V4.34014H0.410868L1.83891 2.8599H1.85755V12.1216H3.88962V0.0224609H2.26769L0.392225 2.21858Z' fill='white'/>
            <path d='M8.7256 3.85915C7.92395 3.85915 7.33484 4.14252 7.01419 4.69808H6.99554V1.68167H10.4929V0.0224609H5.13872V6.83827H6.99181V6.56981C6.99181 5.85766 7.31247 5.46243 7.88294 5.46243C8.50934 5.46243 8.75915 5.89121 8.75915 6.55117V9.47809C8.75915 10.1194 8.52798 10.5668 7.90158 10.5668C7.20434 10.5668 6.95826 10.1045 6.95826 9.3886V7.87108H5.10143V9.24692C5.10143 11.0851 5.83223 12.2633 7.90158 12.2633C9.95229 12.2633 10.7912 11.1746 10.7912 9.49673V6.53626C10.7949 4.62723 9.81434 3.85915 8.7256 3.85915Z' fill='white'/>
            <path d='M21.1528 35.5703C21.2274 35.5703 21.2833 35.63 21.2833 35.7045V40.0744C21.2833 40.149 21.2237 40.2086 21.1528 40.2086H19.3855C19.3109 40.2086 19.2513 40.149 19.2513 40.0744V35.7045C19.2513 35.63 19.3109 35.5703 19.3855 35.5703H21.1528Z' fill='white'/>
            <path d='M40.5675 42.0806H11.4325C11.3579 42.0806 11.2983 42.144 11.2983 42.2185V44.0008C11.2983 44.0716 11.3579 44.1313 11.4325 44.1313H40.5675C40.6383 44.1313 40.7017 44.0716 40.7017 44.0008V42.2185C40.7017 42.144 40.642 42.0806 40.5675 42.0806Z' fill='white'/>
            <path d='M40.5675 45.6187H11.4325C11.3579 45.6187 11.2983 45.6783 11.2983 45.7492V47.5351C11.2983 47.606 11.3579 47.6656 11.4325 47.6656H40.5675C40.6383 47.6656 40.7017 47.606 40.7017 47.5351V45.7492C40.7017 45.6783 40.642 45.6187 40.5675 45.6187Z' fill='white'/>
            <path d='M35.0231 30.7344C34.6577 30.4511 33.6883 29.7836 32.5026 29.7836C31.6562 29.7836 30.8695 30.1155 30.1611 30.7792C30.1126 30.8276 30.0828 30.891 30.0828 30.9619V31.1222C30.0828 31.2005 30.1089 31.2751 30.1648 31.3198C30.2282 31.3683 30.2953 31.3869 30.3699 31.3683C30.3848 31.3646 30.4071 31.3608 30.4258 31.3534C30.478 31.3347 30.5302 31.3012 30.5712 31.2602C30.7241 31.1558 31.1417 31.0178 31.7382 31.0178C32.3572 31.0178 33.569 31.1819 34.7397 32.2743L34.7658 32.2967C34.8329 32.3638 34.9224 32.4011 35.0194 32.4011C35.1126 32.4011 35.2058 32.3638 35.2655 32.2967L35.2878 32.2706C36.4661 31.1744 37.6778 31.0103 38.293 31.0103C38.8933 31.0103 39.3109 31.152 39.4638 31.2527C39.5048 31.2937 39.557 31.3273 39.6241 31.3496C39.6502 31.3571 39.7248 31.372 39.7957 31.3534C39.9038 31.3235 39.9784 31.2266 39.9784 31.1147V30.9731C39.9784 30.9059 39.9485 30.8388 39.9001 30.7941C39.1879 30.1155 38.3937 29.7725 37.5324 29.7725C36.3654 29.7836 35.3997 30.4361 35.0231 30.7344Z' fill='white'/>
            <path d='M33.2147 19.12C33.1513 19.1498 33.1066 19.2057 33.088 19.2728L33.0581 19.3698C33.0358 19.4481 33.0507 19.5264 33.0954 19.5898C33.1402 19.6494 33.2035 19.683 33.2744 19.6867C33.2893 19.6867 33.3005 19.6904 33.3117 19.6867C33.3639 19.6867 33.4086 19.6755 33.4534 19.6494C33.4795 19.6457 33.5317 19.6308 33.6286 19.6308C33.7591 19.6308 34.9336 19.6718 35.7091 20.9917L35.7203 21.0104C35.7613 21.0887 35.8285 21.1409 35.9068 21.1595C35.9291 21.167 35.9739 21.1744 36 21.1744C36.0522 21.1744 36.1081 21.1595 36.1454 21.1371L36.164 21.1297C36.5891 20.9171 37.029 20.809 37.4653 20.809C38.1998 20.809 38.6808 21.111 38.7927 21.2341C38.815 21.2751 38.8523 21.3124 38.8896 21.3422C38.9157 21.3571 38.9567 21.3795 38.9828 21.3869C38.9977 21.3907 39.0126 21.3907 39.0276 21.3944C39.035 21.3944 39.0462 21.3944 39.0537 21.3944C39.0947 21.3944 39.132 21.3832 39.1692 21.3646C39.2289 21.3347 39.2736 21.2788 39.2923 21.2154L39.3184 21.1297C39.3258 21.1073 39.3296 21.0849 39.3296 21.0588C39.3296 21.0141 39.3184 20.9693 39.296 20.9321C38.7293 19.9477 37.909 19.7426 37.3199 19.7426C36.8799 19.7426 36.4959 19.8582 36.2722 19.9403C36.0037 19.5823 35.3288 18.8403 34.3072 18.8403C33.9493 18.8441 33.5801 18.9373 33.2147 19.12Z' fill='white'/>
            <path d='M27.0589 23.6018L27.0664 23.7323C27.0701 23.8144 27.1148 23.8852 27.1819 23.9299C27.2379 23.9672 27.3125 23.9784 27.3796 23.9598C27.3945 23.956 27.4094 23.9523 27.4206 23.9449C27.469 23.9225 27.5138 23.8927 27.5473 23.8516C27.6704 23.7622 28.0693 23.5981 28.6585 23.5981C29.2252 23.5981 30.0865 23.751 30.9627 24.4706L30.9814 24.493C31.0447 24.5452 31.123 24.5787 31.2051 24.5787H31.2274C31.3207 24.5713 31.4027 24.5302 31.4549 24.4706L31.4735 24.4482C32.4429 23.4191 33.4907 23.2663 34.0276 23.2663C34.4116 23.2663 34.6801 23.3446 34.7882 23.4042C34.8292 23.4378 34.8777 23.4676 34.9411 23.4825C34.9597 23.4862 35.0007 23.4937 35.0492 23.49C35.0679 23.4862 35.0902 23.4825 35.1089 23.4751C35.2133 23.4378 35.2804 23.3371 35.2729 23.2252L35.2655 23.1097C35.2617 23.0425 35.2282 22.9792 35.176 22.9381C34.5981 22.4497 33.9717 22.2036 33.3117 22.2036C32.2863 22.2036 31.4698 22.8076 31.1305 23.1059C30.821 22.8934 30.0679 22.4609 29.1879 22.4609C28.4124 22.4609 27.7189 22.7815 27.1223 23.4154C27.0813 23.4676 27.0552 23.5347 27.0589 23.6018Z' fill='white'/>
            <path d='M12.3162 25.9431C12.3162 24.1981 13.7293 22.785 15.4743 22.785C15.5414 22.785 15.6085 22.7925 15.6756 22.7962C15.8434 20.5889 17.6816 18.8477 19.9336 18.8477C22.2938 18.8477 24.2066 20.7604 24.2066 23.1206C24.2066 23.788 24.05 24.4181 23.774 24.9849C25.9142 25.4584 27.5138 27.3637 27.5138 29.6456C27.5138 32.2817 25.3736 34.4219 22.7375 34.4219C22.5436 34.4219 16.3654 34.3846 16.3654 34.3846C14.4974 34.3846 12.9836 32.8708 12.9836 31.0028C12.9836 30.1042 13.3341 29.2914 13.9045 28.6873C12.9575 28.1355 12.3162 27.1139 12.3162 25.9431Z' fill='white'/>
            <path d='M15.1723 0.0258789H15.9963V4.2578H16.9806V4.92894H15.1723V0.0258789Z' fill='white'/>
            <path d='M17.4318 0.0258789H18.2558V4.92894H17.4318V0.0258789Z' fill='white'/>
            <path d='M18.8785 0.0258789H20.8099V0.69702H19.7025V2.09896H20.5265V2.76264H19.7025V4.92521H18.8785V0.0258789Z' fill='white'/>
            <path d='M21.2349 0.0258789H23.2036V0.69702H22.0626V2.09896H22.8792V2.76264H22.0626V4.25407H23.2036V4.92521H21.2349V0.0258789Z' fill='white'/>
            <path d='M15.1685 10.8688V8.49747C15.1685 7.72938 15.5451 7.17383 16.4139 7.17383C17.2901 7.17383 17.6667 7.72938 17.6667 8.49747V10.8688C17.6667 11.6295 17.2901 12.1925 16.4139 12.1925C15.5451 12.1887 15.1685 11.6257 15.1685 10.8688ZM16.8389 10.9956V8.36324C16.8389 8.06495 16.7084 7.85615 16.4139 7.85615C16.1231 7.85615 15.9963 8.06495 15.9963 8.36324V10.9956C15.9963 11.2939 16.1268 11.5027 16.4139 11.5027C16.7084 11.5027 16.8389 11.2939 16.8389 10.9956Z' fill='white'/>
            <path d='M18.8151 9.05325V12.133H18.1216V7.22998H18.9307L19.8181 10.0413V7.22998H20.5041V12.133H19.7808L18.8151 9.05325Z' fill='white'/>
            <path d='M22.2864 7.22998H23.1104V11.4619H24.0947V12.133H22.2864V7.22998Z' fill='white'/>
            <path d='M24.3184 12.133L25.2431 7.22998H26.1827L27.1149 12.133H26.2685L26.1156 11.2382H25.2394L25.094 12.133H24.3184ZM25.3513 10.5782H26.0075L25.6831 8.61328H25.6794L25.3513 10.5782Z' fill='white'/>
            <path d='M28.1439 9.05325V12.133H27.4504V7.22998H28.2595L29.1469 10.0413V7.22998H29.833V12.133H29.1096L28.1439 9.05325Z' fill='white'/>
            <path d='M32.8307 8.54616V10.8169C32.8307 11.57 32.5213 12.133 31.6376 12.133H30.3662V7.22998H31.6376C32.5213 7.22998 32.8307 7.78554 32.8307 8.54616ZM31.5369 11.4619C31.8762 11.4619 32.0067 11.2606 32.0067 10.9623V8.39329C32.0067 8.10246 31.8762 7.90112 31.5369 7.90112H31.1902V11.4582H31.5369V11.4619Z' fill='white'/>

        </svg>
    );
};
