import gql from "graphql-tag";

export const GET_SEARCH_SUGGESTION_RESULTS = gql`
    query GetLuxseSearchResults($searchRequest: String!) {
        luxseSuggestionSearch(searchRequest: $searchRequest) {
            results {
                id
                type
                properties {
                    title
                    subTitle
                    altTitle
                    type
                    subType
                    country
                    tags
                    startDate
                    endDate
                    numbers{
                        name
                        value
                    }
                    complements{
                        name
                        value
                    }
                }
            }
        }
    }
`;