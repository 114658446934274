import { css } from "@emotion/react";
export const style = {
  container: css`
    width: 100%;
    min-height: 100px;
    position: relative;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
  `,
  title: css`
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    text-align: center;
    color: #354450;
  `,
  cards: css`
    display: flex;
    width: 100%;
    margin: 32px auto auto;
  `,
  card: css`
    display: flex;
    max-width: 600px;
    flex-direction: column;
    padding: 40px 80px;
  `,
  separator: css`
    width: 24px;
  `,
  actions: css`
    width: 100%;
    margin-top: 24px;
  `,
  cardTitle: css`
    font-weight: 800;
    font-size: 32px;
    line-height: 41px;
    color: #cb1234;
    margin-bottom: 8px;
  `,
  heading: css`
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #425563;
    margin-bottom: 24px;
  `,
  contentTop: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #354450;
  `,
  contentBottom: css`
    margin-top: 40px;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #354450;
  `,
};
