import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { useRef, useState } from "react";

import { SitecoreGenericTextField } from "../../interfaces";
import { canUseDOM } from "../../predicates";

/** @jsx jsx */

export interface DropdownBlocsComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  display: flex;
`;
const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 56px;
  background-color: #f0f5f5;
  margin-top: 60px;
  padding-top: 96px;
  margin-bottom: 60px;
  @media (max-width: ${breakpointMax}) {
    padding: 12px;
  }
`;
const top = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 24px 32px;
`;
const icon = css`
  color: #22aa5f;
  font-size: 24px;
  line-height: 36px;
  @media (max-width: ${breakpointMax}) {
    font-size: 14px;
  }
`;
const title = css`
  color: #354450;
  font-size: 20px;
  font-weight: 800;
  @media (max-width: ${breakpointMax}) {
    font-size: 16px;
    font-weight: 600;
  }
`;
const mainTitle = css`
  color: #354450;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 32px;
`;
const contactUs = css`
  align-self: center;
  margin-top: 40px;
`;
const textContent = css`
  color: #354450;
  font-size: 20px;
  font-weight: 600;
  height: auto;
  transition: all 0.1s ease-in-out;

  @media (max-width: ${breakpointMax}) {
    font-size: 14px;
    line-height: 180%;
    font-weight: 400;
  }
`;
const defaultblocStyle = {
  background: "#ffffff",
  border: "solid 1px #d9e1e2",
  borderRadius: 8,
  marginBottom: 16,
  transition: "all 0.2s ease-in-out",
  zIndex: "10",
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "space-between",
};

export const DropdownBlocsComponent: React.FunctionComponent<DropdownBlocsComponentProps> =
  (props: DropdownBlocsComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const [expend, setExpend] = useState(-1);
    const tab = useRef(null);
    const contactUsEmail: string = getFieldValue<string>(
      fields,
      "contact us email",
      ""
    );
    const contactUsSubject: string = getFieldValue<string>(
      fields,
      "contact us subject",
      ""
    );

    const getWrapperStyle = (index: number) => {
      return index === expend
        ? { height: "100%", padding: "24px 32px" }
        : { height: 0, paddingLeft: 24, paddingRight: 24 };
    };

    const getBlocStyle = (index: number) => {
      return index === expend
        ? {
            ...defaultblocStyle,
            cursor: "default",
          }
        : {
            ...defaultblocStyle,
            filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1))",
          };
    };

    return (
      <div css={parentContainer}>
        <div css={container}>
          {fields && fields.title && (fields.title as any).value && (
            <div css={mainTitle}>
              <Text
                field={fields && (fields.title as SitecoreGenericTextField)}
              />
            </div>
          )}
          {new Array(10).fill(0).map((arr: any, index: number) => {
            if (
              fields &&
              fields[`topic${index + 1}`] &&
              (fields && (fields[`topic${index + 1}`] as any)).value
            ) {
              return (
                <div style={getBlocStyle(index)}>
                  <div
                    css={top}
                    onClick={() => {
                      setExpend(index === expend ? -1 : index);
                    }}
                  >
                    <div css={title}>
                      <Text
                        field={
                          fields[
                            `topic${index + 1}`
                          ] as SitecoreGenericTextField
                        }
                      />
                    </div>
                    <div css={icon}>
                      <Icon
                        icon={expend === index ? "chevron-up" : "chevron-down"}
                      />
                    </div>
                  </div>
                  <div css={textContent} style={getWrapperStyle(index)}>
                    <div
                      ref={tab}
                      css={css`
                        opacity: ${expend === index ? "1" : "0"};

                        > div {
                          line-height: 150%;
                          padding: 24px 0px;
                        }
                      `}
                    >
                      {expend === index && (
                        <RichText
                          field={
                            fields[
                              `text${index + 1}`
                            ] as SitecoreGenericTextField
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          })}
          {contactUsEmail && (
            <div css={contactUs}>
              <Button
                label={"Contact us"}
                iconLeft={"paper-plane"}
                onClick={() =>
                  canUseDOM &&
                  window.open(
                    `mailto:${contactUsEmail}?subject='${contactUsSubject}`
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  };
