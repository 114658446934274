import React, { useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Query } from "react-apollo";
import { GET_SEARCH_SUGGESTION_RESULTS } from "../../../../graphql";
import { SearchSuggestionItemDto } from "../../../../interfaces/search/search-suggestion";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { useDebounce } from "use-debounce/lib";
import { Link } from "react-router-dom";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import Loader from "../../Loader/loader.component";
import Type from "../../UI/list/type";
import { SuggestionTypeEnum } from "../../../../enums/suggestion-type";
import { IssuerSuggestion } from "../../UI/list/issuer/suggestion";
import { style } from "./suggestion.style";
import { SecuritySuggestion } from "../../UI/list/security/suggestion";
import { ProgrammeSuggestion } from "../../UI/list/programme/suggestion";
import { IndexSuggestion } from "../../UI/list/index/suggestion";
import Utils from "../../../utils/utils";

export interface SuggestionProps {
  textInput: string;
  open: boolean;
  onClose: () => void;
}

export interface SuggestionItemProps {
  suggestion?: SearchSuggestionItemDto;
}

export function suggestionTypeLabel(
  suggestion: SearchSuggestionItemDto
): string {
  return suggestion?.type ? suggestion.type : "";
}

export function getSuggestionTitle(
  suggestion: SearchSuggestionItemDto,
  defaultValue: any
): string {
  return suggestion?.properties?.title
    ? suggestion.properties.title
    : defaultValue;
}

export function getSuggestionNumber(
  suggestion: SearchSuggestionItemDto,
  field: string,
  defaultValue: number
): number {
  if (!field || !suggestion?.properties?.numbers) return defaultValue;

  const number = suggestion.properties.numbers.find((i) => i.name === field);
  return number ? number.value : defaultValue;
}

export function getSuggestionComplement(
  suggestion: SearchSuggestionItemDto,
  field: string
): any {
  if (!field || !suggestion?.properties?.complements) return null;

  const complement = suggestion.properties.complements.find(
    (i) => i.name === field
  );
  return complement ? complement.value : null;
}

export const Suggestion = ({ textInput, open, onClose }: SuggestionProps) => {
  const [searchRequest] = useDebounce(textInput, 300);

  if (!open) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        css={style.overlayCss}
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      />
      <Query<any>
        query={GET_SEARCH_SUGGESTION_RESULTS}
        client={luxseGatewayGraphqlClient}
        variables={{ searchRequest, token: Utils.getCookie("Gztoken-Dsm") }}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, error, data }): React.ReactNode => {
          return (
            <div
              css={style.wrapperCss}
              style={{ display: open ? "block" : "none" }}
            >
              <Loader loading={loading}>
                <div css={style.closeBtnCss} onClick={onClose}>
                  <Icon icon="times" />
                </div>
                <div css={style.body}>
                  {data?.luxseSuggestionSearch?.results?.map(
                    (item: SearchSuggestionItemDto) => (
                      <SuggestionItem suggestion={item} />
                    )
                  ) || (
                    <div css={[style.itemCss, style.itemNoResult]}>
                      No results
                    </div>
                  )}
                </div>
              </Loader>
            </div>
          );
        }}
      </Query>
    </React.Fragment>
  );
};

export const SuggestionItem = ({ suggestion }: SuggestionItemProps) => {

  console.log('suggestion', suggestion)
  if (!suggestion || !suggestion.type || !suggestion.id) {
    return <></>;
  }

  try {
    switch (suggestion.type as SuggestionTypeEnum) {
      case SuggestionTypeEnum.Issuer:
        return (
          <div css={style.itemCss}>
            <IssuerSuggestion suggestion={suggestion} />
          </div>
        );
      case SuggestionTypeEnum.Security:
        return (
          <div css={style.itemCss}>
            <SecuritySuggestion suggestion={suggestion} />
          </div>
        );
      case SuggestionTypeEnum.Programme:
        return (
          <div css={style.itemCss}>
            <ProgrammeSuggestion suggestion={suggestion} />
          </div>
        );
      case SuggestionTypeEnum.Index:
        return (
          <div css={style.itemCss}>
            <IndexSuggestion suggestion={suggestion} />
          </div>
        );
      case SuggestionTypeEnum.Page:
        return <></>;
      default:
        break;
    }
  } catch (e) {
    console.error("Failed to create suggestion for ", suggestion, e);
    return <></>;
  }

  return (
    <div css={style.itemCss}>
      <Type label={suggestionTypeLabel(suggestion)} />
      <Link
        key={suggestion.id}
        to={`/search?q=${suggestion.id}`}
        css={style.itemDefault}
      >
        {getSuggestionTitle(suggestion, suggestion.id)}
      </Link>
    </div>
  );
};
