
export enum LgxSlbOverallStatusBond {
	ALL_SPT_MISSED = 'Target(s) missed',
	ALL_SPT_MET = 'Target(s) met',
	SPT_PARTIALLY_MISSED = 'Targets partially missed',
	INTERMEDIARY_SPT_MISSED = 'Targets partially missed',
}

export function lgxSlbOverallStatusBondLabel(value: string): string {
	return LgxSlbOverallStatusBond[value as keyof typeof lgxSlbOverallStatusBondLabel] || null;
}