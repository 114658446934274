
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const VNM: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#DA251D" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <path fill="#FFCD00" d="M260.119,155.97l23.609,70.79l74.621,0.578c4.172,0.032,5.902,5.357,2.546,7.836l-60.029,44.329 l22.509,71.147c1.259,3.978-3.271,7.27-6.666,4.843L256,312.1l-60.71,43.393c-3.394,2.426-7.924-0.865-6.666-4.843l22.509-71.147 l-60.029-44.329c-3.357-2.478-1.626-7.804,2.546-7.836l74.621-0.578l23.609-70.79C253.201,152.012,258.799,152.012,260.119,155.97z" />
        </svg>
    </React.Fragment>
);
