
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const KOR: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <path fill="#CD2E3A" d="M305.009,182.533c-40.563-27.041-95.35-15.986-122.374,24.506 c-13.555,20.211-8.045,47.674,12.235,61.195c20.265,13.521,47.64,8.03,61.161-12.252c13.521-20.281,40.914-25.704,61.178-12.254 c20.298,13.521,25.757,40.984,12.217,61.195C356.468,264.363,345.537,209.575,305.009,182.533" />
            <path fill="#0F64CD" d="M182.634,207.04c-13.555,20.211-8.045,47.674,12.235,61.195c20.265,13.521,47.64,8.03,61.161-12.252 c13.521-20.281,40.914-25.704,61.178-12.254c20.298,13.521,25.757,40.984,12.217,61.195 c-27.006,40.632-81.775,51.549-122.338,24.507C166.561,302.391,155.593,247.603,182.634,207.04" />
            <g>
                <path fill="#000000" d="M349.92,149.191l16.035,24.102c1.347,2.025,0.802,4.759-1.219,6.112l-4.066,2.723 c-2.029,1.358-4.775,0.812-6.129-1.22l-16.055-24.096c-1.351-2.027-0.803-4.766,1.223-6.119l4.086-2.728 C345.825,146.61,348.568,147.159,349.92,149.191z" />
                <path fill="#000000" d="M374.66,186.352l16.087,24.087c1.358,2.034,0.803,4.786-1.237,6.134l-4.084,2.699 c-2.026,1.34-4.754,0.789-6.103-1.23l-16.078-24.062c-1.354-2.026-0.81-4.767,1.217-6.122l4.074-2.724 C370.563,183.779,373.305,184.324,374.66,186.352z" />
                <path fill="#000000" d="M367.089,137.733l40.829,61.273c1.352,2.028,0.803,4.768-1.225,6.12l-4.102,2.734 c-2.028,1.353-4.769,0.804-6.121-1.224l-40.843-61.269c-1.353-2.029-0.803-4.771,1.227-6.122l4.115-2.739 C362.998,135.157,365.737,135.705,367.089,137.733z" />
                <path fill="#000000" d="M384.211,126.293l16.07,24.149c1.354,2.034,0.798,4.78-1.241,6.127l-4.086,2.7 c-2.028,1.34-4.757,0.789-6.105-1.234l-16.082-24.117c-1.353-2.028-0.804-4.769,1.224-6.121l4.099-2.732 C380.117,123.712,382.859,124.261,384.211,126.293z" />
                <path fill="#000000" d="M408.967,163.532l16.045,24.099c1.35,2.026,0.803,4.762-1.22,6.115l-4.075,2.724 c-2.029,1.357-4.775,0.809-6.127-1.223l-16.045-24.099c-1.349-2.026-0.803-4.762,1.22-6.115l4.075-2.724 C404.868,160.952,407.614,161.499,408.967,163.532z" />
                <path fill="#000000" d="M132.721,293.983l40.824,61.207c1.352,2.027,0.806,4.767-1.222,6.12l-4.088,2.73 c-2.028,1.354-4.769,0.807-6.123-1.222l-40.824-61.207c-1.352-2.027-0.806-4.767,1.222-6.12l4.088-2.73 C128.626,291.407,131.368,291.955,132.721,293.983z" />
                <path fill="#000000" d="M115.582,305.432l16.028,24.041c1.351,2.026,0.806,4.761-1.217,6.116l-4.066,2.722 c-2.027,1.357-4.771,0.812-6.126-1.217l-16.047-24.035c-1.354-2.027-0.808-4.768,1.22-6.122l4.086-2.728 C111.487,302.856,114.229,303.403,115.582,305.432z" />
                <path fill="#000000" d="M140.351,342.606l16.046,24.102c1.35,2.026,0.803,4.763-1.222,6.115l-4.078,2.726 c-2.029,1.356-4.772,0.809-6.126-1.221l-16.056-24.097c-1.351-2.027-0.803-4.766,1.222-6.119l4.088-2.73 C136.255,340.026,138.998,340.575,140.351,342.606z" />
                <path fill="#000000" d="M98.442,316.877l40.798,61.21c1.351,2.026,0.804,4.764-1.219,6.118l-4.077,2.726 c-2.028,1.356-4.771,0.81-6.125-1.22l-40.822-61.202c-1.353-2.028-0.804-4.769,1.224-6.122l4.102-2.734 C94.349,314.301,97.09,314.849,98.442,316.877z" />
                <path fill="#000000" d="M121.294,210.443l40.818-61.257c1.353-2.03,4.095-2.578,6.124-1.224l4.087,2.729 c2.027,1.353,2.573,4.093,1.222,6.12l-40.834,61.223c-1.35,2.023-4.081,2.573-6.108,1.231l-4.071-2.695 C120.494,215.222,119.94,212.475,121.294,210.443z" />
                <path fill="#000000" d="M104.147,199.01l40.825-61.269c1.353-2.03,4.097-2.578,6.126-1.222l4.077,2.726 c2.024,1.353,2.57,4.089,1.22,6.116l-40.814,61.272c-1.353,2.03-4.095,2.579-6.124,1.224l-4.088-2.729 C103.343,203.776,102.796,201.037,104.147,199.01z" />
                <path fill="#000000" d="M86.99,187.626l40.829-61.33c1.353-2.031,4.098-2.58,6.127-1.224l4.077,2.726 c2.024,1.353,2.57,4.087,1.222,6.114l-40.804,61.339c-1.351,2.031-4.094,2.581-6.123,1.228l-4.102-2.734 C86.189,192.393,85.641,189.654,86.99,187.626z" />
                <path fill="#000000" d="M338.493,355.19l16.047-24.035c1.355-2.029,4.099-2.574,6.126-1.217l4.066,2.723 c2.023,1.354,2.567,4.09,1.217,6.116l-16.028,24.04c-1.353,2.029-4.095,2.577-6.123,1.223l-4.086-2.728 C337.685,359.958,337.139,357.218,338.493,355.19z" />
                <path fill="#000000" d="M363.243,318.142l16.073-24.154c1.352-2.03,4.094-2.58,6.123-1.227l4.096,2.73 c2.029,1.353,2.577,4.096,1.223,6.124l-16.107,24.116c-1.351,2.023-4.082,2.571-6.109,1.227l-4.062-2.692 C362.445,322.917,361.891,320.173,363.243,318.142z" />
                <path fill="#000000" d="M355.626,366.699l16.057-24.098c1.352-2.029,4.093-2.578,6.122-1.225l4.104,2.737 c2.027,1.352,2.575,4.09,1.225,6.119l-16.047,24.1c-1.351,2.029-4.09,2.579-6.12,1.228l-4.115-2.739 C354.824,371.47,354.274,368.728,355.626,366.699z" />
                <path fill="#000000" d="M380.403,329.464l16.066-24.042c1.354-2.025,4.092-2.571,6.119-1.22l4.102,2.734 c2.029,1.353,2.577,4.096,1.221,6.124l-16.065,24.042c-1.353,2.025-4.092,2.571-6.118,1.22l-4.103-2.734 C379.594,334.237,379.047,331.493,380.403,329.464z" />
                <path fill="#000000" d="M372.771,378.082l16.075-24.056c1.35-2.019,4.077-2.569,6.103-1.23l4.086,2.7 c2.039,1.348,2.595,4.097,1.239,6.131l-16.063,24.088c-1.352,2.028-4.093,2.575-6.121,1.224l-4.099-2.732 C371.962,382.853,371.415,380.11,372.771,378.082z" />
                <path fill="#000000" d="M397.554,340.97l16.035-24.085c1.353-2.031,4.098-2.58,6.127-1.223l4.072,2.722 c2.025,1.354,2.57,4.093,1.218,6.119l-16.049,24.053c-1.349,2.024-4.083,2.573-6.11,1.229l-4.06-2.69 C396.754,345.747,396.201,343.002,397.554,340.97z" />
            </g>
        </svg>
    </React.Fragment>
);
