
/** @jsx jsx */

import * as React from 'react';

import { ComponentFields, ComponentParams, ComponentRendering, Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";

import {CountryFlagIcon} from "../../core/components/CountryFlagIcon/countryFlagIcon.component";
import Loader from "../../core/components/Loader/loader.component";
import {SitecoreGenericTextField} from "../../interfaces";
import {canUseDOM} from "../../predicates";
import {style} from "../../core/components/MarketStatusBox/marketStatusBox.style";

export interface LanguageSwitcherProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #D9E1E2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  right: 20%;
  cursor: pointer;
  z-index: 5;
  margin-top: -1px;
  @media (max-width: ${breakpointMax}) {
    left: 0;
    right: 0;
    margin-left: 40px;
    margin-right: 40px;
  }
`;
const left = css`
  
`;
const right = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #22AA5F;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
`;

export const LanguageSwitcher: React.FunctionComponent<LanguageSwitcherProps> = (props: LanguageSwitcherProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const link: string = getFieldValue<string>(fields, 'link to page', '');
    const isFrench: boolean = getFieldValue<boolean>(fields, 'is french', false);

    return (
        <div css={container} onClick={() => link ? canUseDOM && (window.location.href = link) : console.log }>
            <div css={left}>
                <CountryFlagIcon code={isFrench ? 'US' : 'FR'} size={{ height: 20, width: 30 }} />
            </div>
            <div css={right}>
                <Text field={fields && (fields.label as SitecoreGenericTextField)} />
            </div>
        </div>
    );
}
    