
export enum LgxExternalReporting {
	COMMITTEDTOREPORT = "Committed to report",
	USEOFPROCEEDSREPORT = "Use of proceeds report",
	IMPACTREPORT = "Impact report",
	REPORTRECEIVED = "Report received"
}

export function lgxExternalReportingLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(LgxExternalReporting).indexOf(value.toString().toUpperCase());
		return index >= 0 ? Object.values(LgxExternalReporting)[index] : value;
	}catch (e){
		console.error("Failed to get LgxExternalReporting label for '" + value + "'", e);
	}
		
	return value;
}