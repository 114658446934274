import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Text, ComponentFields, Link } from "@sitecore-jss/sitecore-jss-react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { style } from "./courseContainer.style";
import { FormBuilder } from "../FormBuilder/formBuilder.component";
import {
  generatePostUrl,
  generateUrl,
  getLinkUrl,
  transformLink,
} from "../../../utils/helper";
import { canUseDOM } from "../../../predicates";

export interface CourseContainerProps {
  fields?: any;
  match?: any;
  id?: string | number;
  dateFrom?: string;
  dateTo?: string;
  itemName: string;
  icon?: string;
  title?: string;
  icon1?: string;
  icon2?: string;
  content?: string;
  offer?: string;
  price?: string;
  full?: boolean;
  button1Label?: string;
  button2Label?: string;
  button1Link?: any;
  button2Link?: any;
  customDate?: string;
}

const formDate = [
  {
    mandatory: true,
    placeholder: "Name *",
    requiredLabel: "* Required",
    value: "",
  },
  {
    mandatory: true,
    placeholder: "Last name *",
    requiredLabel: "* Required",
    value: "",
  },
  {
    mandatory: true,
    placeholder: "E-mail *",
    requiredLabel: "* Required",
    value: "",
  },
];

export const CourseContainerContent: React.FunctionComponent<any> = ({
  dateFrom,
  dateTo,
  icon,
  price,
  title,
  content,
  offer,
  full,
  button1Label,
  button2Label,
  button1Link,
  button2Link,
  icon1,
  icon2,
  customDate,
}: CourseContainerProps): React.ReactElement => {
  const instance = axios.create();
  const [visibleForm, setVisibleForm] = React.useState<boolean>(false);
  const toggleRegistrationModal = () => {
    setVisibleForm(!visibleForm);
  };

  const sendRequest = async (data: any) => {
    /*if(canUseDOM) {
      window.open(`mailto:ayb@bourse.lu?subject=Subscription to ${title}&body=Subscription do ${title}`)
    }*/
    /* const postData = {
            ItemName: 'Attendee test',
        ItemId: '07FBC468-BA7D-4EC5-B7BD-8D7B92DD5166'{
                'First name': 'test',
                'last name' : 'last name',
                'Email': 'email@emlail.com',
            }
        }
        const request = await instance.post(generatePostUrl(`/sitecore/Content/bdl-port-luxse-ssr/Content/LGX/Courses/${itemName}/Attendees`), postData, { timeout: 0 });
    */
  };
  return (
    <div css={style.container}>
      {visibleForm && (
        <FormBuilder
          onClickOutside={() => setVisibleForm(false)}
          title={"Your informations *"}
          buttonLabel={"Send your request"}
          headerTitle={"Subscribe to the course"}
          mandatory
          rows={formDate}
          buttonAction={sendRequest}
        />
      )}
      <div css={style.left} style={{ opacity: full ? "0.5" : "1" }}>
        <div css={style.icon}>
          <Icon icon={icon} />
        </div>
      </div>
      <div css={style.center} style={{ opacity: full ? "0.5" : "1" }}>
        <div css={style.date}>
          {customDate || `${dateFrom} ${dateTo ? `- ${dateTo}` : ""}`}{" "}
        </div>
        <div css={style.title}>{title}</div>
        <div css={style.content}>{content}</div>
        {price && <div css={style.price}>{price}</div>}
        {offer && (
          <div css={style.tagContent}>
            <Icon icon={"tag"} /> {offer}
          </div>
        )}
      </div>
      <div css={style.right}>
        {full ? (
          <div css={style.full}>This session is full</div>
        ) : (
          <div css={style.paddingRight}>
            <div css={style.actionTop}>
              {button1Label && button1Link && (
                <Button
                  label={button1Label}
                  iconRight={icon1 as any}
                  onClick={async () => {
                    const link = await getLinkUrl(button1Link);
                    const action = button1Link.includes("internal")
                      ? "_self"
                      : "_blank";
                    if (link) {
                      canUseDOM && window.open(link, action);
                    }
                  }}
                />
              )}
            </div>
            {button2Label && button2Link && (
              <div
                css={style.actionBottom}
                onClick={async () => {
                  const link = await getLinkUrl(button2Link);
                  const action = button2Link.includes("internal")
                    ? "_self"
                    : "_blank";
                  if (link) {
                    canUseDOM && window.open(link, action);
                  }
                }}
              >
                <Icon icon={icon2} /> {button2Label}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const CourseContainer = withRouter(CourseContainerContent);
