import * as React from 'react';
import { useEffect, useState } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from '../SecurityOverview/securityOverview.style';
import moment from 'moment';
import SecuritySustainableDoc from './keyDocument-item';
import { GreenDoc } from '../../../../interfaces/green-card';
import { Tag } from '@bdl-cmn-shared-packages-npm/design-system';

interface PreIssuanceDocs {
  visible: boolean;
  framework: GreenDoc;
  secondPartyOpinion: GreenDoc;
  others: GreenDoc[];
}

const basePreIssuanceDocs: PreIssuanceDocs = {
  visible: false,
  framework: null,
  secondPartyOpinion: null,
  others: [],
};

interface PostIssuanceDocs {
  visible: boolean;
  report: GreenDoc;
  dedicated: GreenDoc;
  verification: GreenDoc;
  externalReview: GreenDoc;
  others: GreenDoc[];
}

const basePostIssuanceDocs: PostIssuanceDocs = {
  visible: false,
  report: null,
  dedicated: null,
  verification: null,
  externalReview: null,
  others: [],
};

const blocRowSpace = css`
  color: #354450;
  align-items: center;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: solid 1px #e6eef2;
  cursor: pointer;
  :hover {
    background-color: #f8fafc;
  }
`;

const blocRowKey = css`
  font-family: 'Mont';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  > div {
    font-size: 16px;
    font-weight: 500;
  }
  color: #354450;
  @media (max-width: 720px) {
    font-size: 1em;
    line-height: 1em;
  }
`;

const blocRowValue = css`
  width: 50%;
  margin-left: 30px;
`;

const alldocuments = css`
  margin-top: 20px;
  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    cursor: pointer;
    color: #22aa5f;
  }
`;

const SecuritySustainableKeyDocuments = (
  { postIssuanceDocs, preIssuanceDocs }: 
  { postIssuanceDocs: GreenDoc[], preIssuanceDocs: GreenDoc[], }) => {

  return (
    <div css={style.securityOverviewTopRightStart}>
      <div css={style.flexRow}>
        <div css={style.blocTitle}>Key documents</div>
        <SecuritySustainableSectionDocuments
          documents={preIssuanceDocs}
          label={"Pre Issuance"}
        />
        <SecuritySustainableSectionDocuments
          documents={postIssuanceDocs}
          label={"Post Issuance"}
        />
      </div>
    </div>
  );
};

const SecuritySustainableSectionDocuments = (
  { documents, label }: 
  { documents: GreenDoc[], label: String, }) => {

  if( ! documents || ! documents.length )
    return <></>;

  return (
    <div>
      <div css={[style.blocRowKey, {margin: "2em 0"}]}>{label}</div>
      <div>
        {documents.map((document, index) => (
          <div
            css={[
              blocRowSpace,
              css`
                border-top: ${index === 0 ? 'solid 1px #e6eef2' : 'none'};
              `,
            ]}
            onClick={() => {
              if (document.downloadUrl && document.downloadUrl.length > 0)
                window.location.href = document.downloadUrl;
            }}
          >
            <div css={style.tagStyle}>{'Document'}</div>
            <div css={blocRowValue}>
              <SecuritySustainableDoc doc={document}></SecuritySustainableDoc>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default SecuritySustainableKeyDocuments;
