
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue, Link } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import {renderLink} from "../../utils/helper";
import {breakpointMax} from "../../style";

export interface BlogAdProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  padding: 80px 16px;
  @media (max-width: ${breakpointMax}) {
    padding: 10px;
  }
`;
const container = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 780px;
  border-radius: 16px;
  background: #253845;
  min-height: 208px;
  display: flex;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column-reverse;
  }
`;
const left = css`
  width: calc(100% - 340px);
  padding: 26px 50px;
  display: flex;
  color: #FFFFFF;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding: 24px 32px;
  }
`;
const right = (image: string) => css`
  width: 100%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background: url(${renderLink(image)});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 208px;
  @media (max-width: ${breakpointMax}) {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 0;
  }
`;
const rightContainer = css`
  width: 340px;
  height: 100%;
  min-height: 208px;
  
  :hover {
    opacity: .4;
  }
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
  transition: all 150ms ease-in-out;
`;
const heading = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
`;
const title = css`
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 24px;
`;
const button = css`
  width: 260px;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    button {
      font-size: 12px;
      width: 100%;
    }
  }
`;

export const BlogAd: React.FunctionComponent<BlogAdProps> = (props: BlogAdProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const buttonLabel: string = getFieldValue<string>(fields, 'button label', '');
    const buttonIcon: string = getFieldValue<string>(fields, 'button icon', '');
    const image: any = getFieldValue<any>(fields, 'image', null);

    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={left}>
                    <div css={heading}>
                        <Text
                            field={fields && fields.heading as SitecoreGenericTextField}
                        />
                    </div>
                    <div css={title}>
                        <Text
                            field={fields && fields.title as SitecoreGenericTextField}
                        />
                    </div>
                    <div css={button}>
                        <Link target={(fields['button link']?.value?.linktype === "external" ? "_blank" : "_self")} title={fields['button label']?.value} field={fields['button link']} css={{textDecoration: 'none'}}>
                            <Button label={buttonLabel} iconRight={buttonIcon as any} />
                        </Link>
                    </div>
                </div>
                {
                    image?.src && (
                        <Link target={(fields['button link']?.value?.linktype === "external" ? "_blank" : "_self")} title={fields['button label']?.value} field={fields['button link']} css={{textDecoration: 'none'}}>
                            <div css={rightContainer}>
                                <div css={right(image.src)} />
                            </div>
                        </Link>
                    )
                }
            </div>
        </div>
    );
}
    