/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import {
    Text,
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    getFieldValue,
    RichText
} from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {useEffect} from "react";

export interface CorporateGovernanceReportProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const topContainer = css`
  width: 100%;
  margin-top: 48px;
  margin-bottom: 48px;
`;

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`;
const title = css`
  font-weight: bold;
  font-size: 24px;
  line-height: 61px;
  color: #1A2934;
`;
const left = css`
  width: 60%;
  padding-right: 18px;
`;
const right = css`
  width: 40%;
`;
const card = css`
  padding: 32px;
  background: #F6F8F9;
  border-radius: 16px;
`;
const cardTitle = css`
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #1A2934;

`;
const cardContent = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
`;
const documents = css`
  width: 100%;
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  cursor: pointer;
`;
const document = css`
  width: 100%;
  display: flex;
  border: 1px solid #22AA5F;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
  min-height: 46px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const CorporateGovernanceReport: React.FunctionComponent<CorporateGovernanceReportProps> = (props: CorporateGovernanceReportProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const content = css`
      width: 100%;
    `;

    return (
        <div css={topContainer}>
            <div css={container}>
                <div css={left}>
                    <div css={title} data-title="anchor">
                        <Text
                            field={fields && fields.title as SitecoreGenericTextField}
                        />
                    </div>
                    <div css={content}>
                        <RichText
                            field={fields && fields.content as SitecoreGenericTextField}
                        />
                    </div>
                    <div css={documents}>
                        {
                            new Array(10).fill(0).map((arr: any, index: number) => {
                                if (fields && fields[`document${index + 1}DownloadLink`] && (fields && fields[`document${index + 1}DownloadLink`] as any).value || fields && fields[`document${index + 1}Link`] && (fields && fields[`document${index + 1}Link`] as any).value) {
                                    return (
                                        <div css={document} key={`document-${index}`}
                                             onClick={() => window.location.href = (fields && fields[`document${index + 1}DownloadLink`] as any).value?.src || (fields && fields[`document${index + 1}Link`] as any).value}
                                        >
                                            <Text
                                                field={fields[`document${index + 1}`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                <div css={right}>
                    <div css={card}>
                        <div css={cardTitle}>
                            <Text
                                field={fields[`card title`] as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={cardContent}>
                            <Text
                                field={fields[`card content`] as SitecoreGenericTextField}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
