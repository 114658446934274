import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from './dropdown.style';
import { useEffect, useRef, useState } from 'react';
import {transformLink} from "../../../utils/helper";
import { Link } from '@sitecore-jss/sitecore-jss-react';

export interface Row {
  label: string;
  href?: string;
  action: () => void;
  pageLink?: any;
}
export interface DropdownProps {
  label?: string;
  nude?: boolean;
  bold?: boolean;
  noPointer?: boolean;
  children?: any;
  header?: any;
  rows?: Row[];
}

const Dropdown: React.FunctionComponent<DropdownProps> = ({ label, rows, children, nude, header, bold, noPointer = false }: DropdownProps) => {
  const parentRef = useRef(null);
  const [elemWidth, setElemWidth] = useState(null);
  const [isVisible, setVisible] = useState(false);

  useOnClickOutside(parentRef, () => setVisible(false));

  function useOnClickOutside(ref: any, handler: any) {
    useEffect(() => {
      const listener = (event: any) => {
        if (!parentRef.current || parentRef.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      document.addEventListener('scroll', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
        document.addEventListener('scroll', listener);
      };
    }, [parentRef]);
  }

  useEffect(() => {
    if (parentRef.current) {
      const parentWidth = parentRef.current.offsetWidth;
      setElemWidth(parentWidth);
    }
  }, [parentRef]);

  const background = css`
    position: fixed;
    z-index: 20;
    background: ${isVisible ? 'rgba(66, 85, 99, 0.6)' : 'transparent'};
    backdrop-filter: ${isVisible ? 'blur(8px)' : 'none'};
    left: 0;
    top: 95px;
    right: 0;
    bottom: 0;
    transition: all 0.2s ease-in-out;
    pointer-events: ${isVisible ? 'default' : 'none'};
  `;

  return (
    <React.Fragment>
      <div css={background} />
      <div css={style.dropdown} ref={parentRef} onClick={() => setVisible(!isVisible)}>
        <div css={style.parentContainer}>
          <div css={style.parentContainerLabel(bold)}>{label}</div>
          <div
            style={{
              transform: isVisible ? 'rotate(-180deg)' : 'none',
              transition: 'all .3s ease-in-out',
            }}
          >
            <Icon icon={'chevron-down'} />
          </div>
        </div>
        <div
          css={style.dropdownTarget}
          style={{
            opacity: isVisible ? 1 : 0,
            transition: 'all .3s ease-in-out',
            visibility: isVisible ? 'visible' : 'hidden',
          }}
        />
        <div
          css={style[rows && rows.length > 0 ? 'dropdownOpenLight' : nude ? 'dropdownOpenNude' : 'dropdownOpen']}
          style={{
            opacity: isVisible ? 1 : 0,
            transition: 'all .3s ease-in-out',
            visibility: isVisible ? 'visible' : 'hidden',
            flexDirection: 'column',
          }}
        >
          {header}
          <div css={style.dropdownItemsContainer}>
            {rows
              ? rows.map((row: Row, index: number) => (
                    <Link key={`row-action-${index}`} target={'_self'} title={row.label} field={transformLink(row.pageLink)} css={style.dropdownRom}>
                      {row.label}
                    </Link>
                ))
              : children}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dropdown;
