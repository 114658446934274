import * as React from "react";
import { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-react";
import { useLocation } from "react-router-dom";

import PdfViewer from "../../core/components/PdfViewer/pdf-viewer.component";

export interface PdfViewerProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const PdfViewerComponent: React.FunctionComponent<PdfViewerProps> = 
  (props: PdfViewerProps): React.ReactElement => {

  const { fields, params, rendering } = props;
  const location = useLocation();

  const path = location?.pathname ? location.pathname.split('/').filter((elem: string) => elem !== '') : [];
  const search = new URLSearchParams(location?.search);
  const id = path?.length >= 1 && path[1] ? path[1] : search ? search.get("id") : "";

  return (
    <PdfViewer id={id}/>
  );
}
