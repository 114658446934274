export enum IndexTypeEnum {
	RDM = "Average bond yield indices",
	IALU = "LuxSE indices",
	IOPC = "Sustainable indices",
	LGX = "LGX",
	CHINA = "Chinese domestic indices",
}

export function indexTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(IndexTypeEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(IndexTypeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get IndexTypeEnum label for '" + value + "'", e);
	}
		
	return value;
}