/** @jsx jsx */

import * as React from "react";
import { InputField } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import { useState, useEffect } from "react";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import { GET_SEARCH_PAGE_RESULTS } from "../../../graphql";
import Utils from "../../utils/utils";

export interface SearchBlogComponentProps {
  onChange: (e: any) => void;
  onSetValue?: (e: any) => void;
  value?: string;
}

const container = css`
  background: #e8ecee;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 80px;
`;

const input = css`
  background: #ffffff;
`;

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

const filterByBlogContext = (data: any) => {
  const { results } = data;
  //const filteredData = results.filter((res: any) => res.path.split('/')?.[1] === 'blog' && res.path.split('/').length > 2);
  return {
    totalHits: data.totalHits,
    results: data.results,
  };
};

export const onBlogSearch = async (term: any, onChange?: (e: any) => void) => {
  const client = luxseGatewayGraphqlClient;
  const results: any = await client.query({
    query: GET_SEARCH_PAGE_RESULTS,
    variables: {
      token: Utils.getCookie("Gztoken-Dsm"),
      searchTerm: term,
      size: 10,
      page: 0,
      sections: ["blog"],
      tags: ["article"],
    },
  });
  const data = results?.data?.luxseSitePagesSearch;

  if (onChange) {
    onChange(filterByBlogContext(data));
  } else {
    return filterByBlogContext(data);
  }
};

export const SearchBlogComponent = ({
  onChange,
  onSetValue,
  value,
}: SearchBlogComponentProps) => {
  const [text, setText] = React.useState<string>(value);
  const searchRequest = useDebounce(text, 300);
  React.useEffect(() => {
    if (searchRequest) {
      onBlogSearch(searchRequest, onChange);
    }
  }, [searchRequest]);
  return (
    <div css={container}>
      <div css={input}>
        <InputField
          placeholder={"Search something in the blog"}
          value={text}
          autoFocus={true}
          onChange={(e: any) => {
            setText(e.target.value);
            onSetValue(e.target.value);
          }}
          width="100%"
          background={"#ffffff"}
          color={"#ffffff"}
        />
      </div>
    </div>
  );
};
