import {
    Button,
    InputField,
    Pagination,
} from "@bdl-cmn-shared-packages-npm/design-system";
import React, {useEffect, useState} from "react";
import {css, jsx} from "@emotion/react";

import Loader from "../Loader/loader.component";
import RTS27Table from "./rts27-regulation-files-table.component";
import {genericStyle} from "../UI/generic/generic.style";
import Utils from "../../utils/utils";
import {rts27Style} from "./rts27-regulation-view.style";
import {luxseGatewayGraphqlClient} from "../../../luxse-gateway-graphql-client";
import {GET_LUXSE_REPORTS} from "../../../graphql/queries/luxse-reports.queries";


/** @jsx jsx */

export class RTS27Model {
    allData?: any;
    filterData?: any;
    displayData?: any;
    totalHits?: number;
}

const totalItemByPage = 20;

export function RTS27RegulationFilesView(props: any) {
    const [rts27, setRts27] = useState<RTS27Model>();

    const onPaginationUpdate = (data: any, pageNumber: number) => {
        let result: RTS27Model = new RTS27Model();
        result = Object.assign({}, rts27);
        result.displayData = createPage(rts27?.filterData, pageNumber);
        setRts27(result);
    };

    function createPage(data: any, currentPage: number): any {
        let startIndex = 0;
        let endIndex = totalItemByPage;

        if (currentPage > 0) {
            endIndex = (currentPage + 1) * totalItemByPage;
            startIndex = endIndex - totalItemByPage;
        }
        return data?.slice(startIndex, endIndex);
    }

    const fetchRTS27 = async () => {
        const {data} = await luxseGatewayGraphqlClient.query({
            query: GET_LUXSE_REPORTS,
            variables: {
                query: null,
                ids: [],
                types: ["RTS27"],
                subTypes: [],
                fromDate: null,
                toDate: null,
                usePageable: false,
                page: 0,
                size: 0,
                sorts: ["date,DESC"]
            },
        });

        const rts27Model: RTS27Model = new RTS27Model();
        rts27Model.allData = data.luxseReports.resultList;
        rts27Model.filterData = data.luxseReports.resultList;
        rts27Model.totalHits = data.luxseReports.resultList.length;
        rts27Model.displayData = createPage(data.luxseReports.resultList, 0);
        setRts27(rts27Model);
    };

    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let reslt: RTS27Model = new RTS27Model();
        const searchString = event.target.value.toLowerCase();
        const filteredCssfApprovals = rts27?.allData?.filter((data: any) => {
            return data?.name?.toLowerCase()?.includes(searchString);
        });

        reslt = Object.assign({}, rts27);
        reslt.filterData = filteredCssfApprovals;
        reslt.totalHits = filteredCssfApprovals?.length || 0;
        reslt.displayData = createPage(filteredCssfApprovals, 0);
        setRts27(reslt);
    };

    useEffect(() => {
        fetchRTS27().catch((error) => {
            Utils.logError(error);
            //TODO to change by error technic page
            // goToNotFoundPage();
        });
    }, []);

    return (
        <>
            <div css={rts27Style.container}>
                <Loader loading={rts27?.totalHits ? false : true}>
                    <div css={rts27Style.filterContainer}>
                        <span css={rts27Style.textPosition}>Filter:</span>
                        <div css={rts27Style.inputStyle}>
                            <InputField
                                helperText=""
                                onChange={inputChangeHandler}
                                placeholder="Date, name"
                                textError=""
                                type="text"
                                width="100%"
                            />
                        </div>
                    </div>

                    <div css={rts27Style.resultContainer}>
                        <p css={rts27Style.textPosition}>
              <span css={rts27Style.resultText}>
                {rts27?.totalHits}&nbsp;
              </span>
                            <span>document</span>
                        </p>
                    </div>

                    <div css={rts27Style.container2}>
                        <div css={genericStyle.desktopDisplay}>
                            <RTS27Table rts27Data={rts27?.displayData}/>
                        </div>
                        <div css={[genericStyle.mobileDisplay]}>
                            <div
                                css={css`
                                  margin: 2em;
                                `}
                            >
                                {rts27?.displayData.map((it: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            css={css`
                                              background: #ffffff;
                                              border: 1px solid #d9dee2;
                                              border-radius: 8px;
                                              filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
                                              margin-bottom: 16px;
                                            `}
                                        >
                                            <div
                                                css={css`
                                                  padding: 16px;
                                                  border-bottom: solid 1px #d9dee2;
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                      font-weight: 700;
                                                      font-size: 14px;
                                                      line-height: 150%;
                                                      letter-spacing: 0.01em;
                                                      color: #425563;
                                                    `}
                                                >
                                                    Document name
                                                </div>
                                                <div
                                                    css={css`
                                                      font-size: 14px;
                                                      line-height: 18px;
                                                      letter-spacing: 0.01em;
                                                      color: #54666f;
                                                      margin-top: 8px;
                                                    `}
                                                >
                                                    {it.name}
                                                </div>
                                            </div>
                                            <div
                                                css={css`
                                                  padding: 16px;
                                                  border-bottom: solid 1px #d9dee2;
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                      font-weight: 700;
                                                      font-size: 14px;
                                                      line-height: 150%;
                                                      letter-spacing: 0.01em;
                                                      color: #425563;
                                                    `}
                                                >
                                                    Publication date
                                                </div>
                                                <div
                                                    css={css`
                                                      font-size: 14px;
                                                      line-height: 18px;
                                                      letter-spacing: 0.01em;
                                                      color: #54666f;
                                                      margin-top: 8px;
                                                    `}
                                                >
                                                    {new Date(it.date).getDate()}/
                                                    {new Date(it.date).getMonth() + 1}/
                                                    {new Date(it.date).getFullYear()}
                                                </div>
                                            </div>
                                            {it?.downloadUrl && (
                                                <div
                                                    css={css`
                                                      padding: 16px;
                                                    `}
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            window.open(it.downloadUrl, "_blank")
                                                        }
                                                        iconRight="download"
                                                        label={"Download"}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {rts27?.totalHits > totalItemByPage && (
                            <Pagination
                                initialPage={0}
                                onPageChange={(d: any) => {
                                    onPaginationUpdate(rts27, d.selected);
                                }}
                                total={
                                    rts27?.totalHits / totalItemByPage > 200
                                        ? 200
                                        : rts27?.totalHits / totalItemByPage
                                }
                            />
                        )}
                    </div>
                </Loader>
            </div>
        </>
    );
}

export default RTS27RegulationFilesView;
