import * as React from "react";
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import ColumnGraph from "../../core/components/ColumnGraph/columnGraph";
import { jsx } from "@emotion/react";

export interface RisersFallersStatisticsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const RisersFallersStatistics: React.FunctionComponent<RisersFallersStatisticsProps> =
  (props: RisersFallersStatisticsProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, "heading", "");

    return (
      <div>
          <Text
              field={fields && fields.heading as SitecoreGenericTextField}
          />
        <ColumnGraph label={"ok"} />
      </div>
    );
  };
