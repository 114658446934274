
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import Header from '../../core/components/Header/header.component';
import {SitecoreGenericTextField} from "../../interfaces";
import {jsx} from "@emotion/react";

export interface MainHeaderProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const MainHeader: React.FunctionComponent<MainHeaderProps> = (props: MainHeaderProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const hideBreadcrumb: boolean = getFieldValue<boolean>(fields, 'hide Breadcrumb', false);
    const isReadOnlyBreadCrumb: boolean = getFieldValue<boolean>(fields, 'disable breadcrumb navigation', false);

    return (
        <>
            <Header hideBreadcrumb={hideBreadcrumb} isReadOnlyBreadCrumb={isReadOnlyBreadCrumb} rendering={rendering}/>
        </>
    );
}
