export enum IndexLatestPriceDateEnum {
	CURRENT_YEAR = 'Current year',
	YEAR_MINUS_ONE = 'One year ago',
	YEAR_MINUS_TWO = 'Two years ago',
	LESS_THAN_TWO_YEARS = 'Over two years ago'
}

export function indexLatestPriceDateSort(name1: string, name2:string): number {
	let index1 = null;
	try {
		index1 = Object.keys(IndexLatestPriceDateEnum).indexOf(name1.toString().toUpperCase());
	}catch (e){
		index1 = null;
	}
	if( index1 == null ){
		console.error("Failed to get IndexLatestPriceDateEnum label for '" + name1 + "'");
		return -1;
	}

	let index2 = null;
	try {
		index2 = Object.keys(IndexLatestPriceDateEnum).indexOf(name2.toString().toUpperCase());
	}catch (e){
		index2 = null;
	}
	if( index2 == null ){
		console.error("Failed to get IndexLatestPriceDateEnum label for '" + name2 + "'");
		return 1;
	}

	return index1 - index2;
}

export function indexLatestPriceDateLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(IndexLatestPriceDateEnum).indexOf(value.toString().toUpperCase());
		return index >= 0 ? Object.values(IndexLatestPriceDateEnum)[index] : value;
	}catch (e){
		console.error("Failed to get IndexLatestPriceDateEnum label for '" + value + "'", e);
	}
		
	return value;
}