import { css } from '@emotion/react';

export const style = {
  resultsWrapper: css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  `,
  closeBTN: css`
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    color: #1a2934;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    svg {
      margin-right: 1px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
    }
  `,
  resetLink: css`
    a {
      color: white;
      font-size: 16px;
      font-weight: 500;
      &:hover {
        color: #22AA5F;
      }
    }
  `,
  header: css`
    font-size: 24px;
    color: white;
    font-weight: bold;
  `,
  tagsWrapper: css`
    display: flex;
    gap: 1rem;
    min-height: 2rem;
    align-items: center;
    > div {
      border-radius: 255px;
      background: white;
      font-size: 17px;
      font-weight: 500;
      height: 2rem;
      inline-size: min-content;
      white-space: nowrap;
      
      color: #354450;
      
      svg {
        color: #A4BCC2!important;}
      > div {
        color: #22AA5F;
      }
    }
  `,
  emphasizedText: css`
    color: #22AA5F;
  `,
};
