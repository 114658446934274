/** @jsx jsx */

import * as React from "react";

import {
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { fetchData, renderLink } from "../../utils/helper";

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { CourseContainer } from "../../core/components/CourseContainer/courseContainer.component";
import Loader from "../../core/components/Loader/loader.component";
import { SitecoreGenericTextField } from "../../interfaces";
import { canUseDOM } from "../../predicates";
import { formatDate } from "../../utils/date";

export interface LGXCoursesComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding: 16px;
  }
`;
const left = css`
  width: 65%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const right = css`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 16px;

  :hover {
  }

  @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding: 0;
  }
`;

const cardBigTitle = css`
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
  }
`;
const cardBigDescription = css`
  font-weight: 600;
  font-size: 16px;
  margin-top: 16px;
  line-height: 20px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;

    letter-spacing: 0.01em;
  }
`;

const cardAction = css`
  text-align: center;
  margin-top: 16px;

  button {
    background-color: #354450;
  }

  @media (max-width: ${breakpointMax}) {
    button {
      font-size: 14px;
      line-height: 180%;
    }
  }
`;

const cardWhite = css`
  //width: calc(50% - 32px);
  width: 100%;
  background: #ffffff;
  margin: 16px;
  padding: 48px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 16px 24px rgba(164, 188, 194, 0.2);
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
    margin: 0;
    margin-top: 16px;
  }
`;
const titleCard = css`
  color: #ffffff;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin-top: 220px;
  margin-bottom: 24px;
`;
const description = css`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
`;
export const LGXCoursesComponent: React.FunctionComponent<LGXCoursesComponentProps> =
  (props: LGXCoursesComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const [isLoadingCourses, setIsLoadingCourses] =
      React.useState<boolean>(true);
    const [courses, setCourses] = React.useState<any[]>([]);
    const displaySessions: boolean = getFieldValue<boolean>(
      fields,
      "display special sessions",
      false
    );
    const btnLabel: string = getFieldValue<string>(
      fields,
      "card bottom button label",
      ""
    );
    const btnLink: string = getFieldValue<string>(
      fields,
      "card bottom button link",
      ""
    );
    const cardBackground: any = getFieldValue<any>(
      fields,
      "card top image",
      null
    );
    const mainTitle: any = getFieldValue<any>(fields, "main title", null);

    const gradientType = getFieldValue<any>(fields, "gradientColor", null);

    const getBackground = () => {
      if (gradientType === "apple100") {
        return `linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)`;
      }
      if (gradientType === "apple500") {
        return `linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)`;
      }
      if (gradientType === "gradientGreen") {
        return `linear-gradient(83deg, #166F3E 1.21%, rgba(46, 157, 71, 0.94) 41.77%, rgba(199, 214, 77, 0.75) 65.16%, rgba(199, 214, 77, 0.00) 90.12%)`;
      }
      if (gradientType === "peach100") {
        return `linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)`;
      }
      if (gradientType === "peach500") {
        return `linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)`;
      }
      if (gradientType === "gradientPeach") {
        return `linear-gradient(83deg, rgba(248, 159, 121, 0.60) 0.17%, rgba(248, 159, 121, 0.00) 100%)`;
      }
      else {
        return "#f0f5f5";
      }
    };

    const title = css`
      width: 100%;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto;
      font-weight: 800;
      font-size: 32px;
      line-height: 41px;
      color: ${gradientType === "" ? "#354450" : "#ffffff"};
      margin-bottom: 32px;
        @media (max-width: ${breakpointMax}) {
         font-weight: 700;
         font-size: 24px;
         line-height: 31px;
         text-align: center;
         margin-top: 24px;
        }
    `;

    const parentContainer = css`
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      background: ${getBackground()};
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      padding-top: 120px;
      padding-bottom: 80px;
        @media (max-width: ${breakpointMax}) {
          padding-top: 32px;
        }
    `;
    const card = css`
      width: 100%;
      margin: 16px;
      margin-top: 0;
      padding: 48px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      min-height: 440px;
      cursor: pointer;
      @media (max-width: ${breakpointMax}) {
        padding: 16px;
        margin: 0;
        margin-top: 16px;
      }
      background-image: linear-gradient(
          0deg,
          #008c3d 28.05%,
          rgba(0, 140, 61, 0) 100%
        ),
        url(${renderLink(cardBackground?.src)});

      :hover {
        background-image: linear-gradient(
            0deg,
            rgba(0, 140, 61, 0.9),
            rgba(0, 140, 61, 0.9)
          ),
          url(${renderLink(cardBackground.src)});
      }
    `;

    const fetchCourses = async (id: string) => {
      try {
        const result: any = await fetchData(id);
        console.log("courses =>", result);
        setCourses(result || []);
        setIsLoadingCourses(false);
      } catch (err) {
        console.error(err);
      }
    };

    React.useEffect(() => {
      fetchCourses("E91AD820-4FC6-4173-9A1C-CCA4ECC34754");
    }, []);

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={left}>
            <div css={title}>{mainTitle}</div>
            <Loader loading={isLoadingCourses}>
              {courses.map((course: any, index: number) => (
                <React.Fragment key={`course-${index}`}>
                  <CourseContainer
                    customDate={course["Custom date label"]}
                    dateFrom={
                      course["Date from"]
                        ? formatDate(course["Date from"], "DD/MM/YYYY")
                        : ""
                    }
                    dateTo={
                      course["Date to"]
                        ? formatDate(course["Date to"], "DD/MM/YYYY")
                        : ""
                    }
                    title={course.Title}
                    content={course.Content}
                    offer={course["Offer label"]}
                    icon={course.Icon}
                    id={course.ItemID}
                    itemName={course.ItemName}
                    price={course.Price}
                    button1Label={course["Button 1 label"]}
                    button1Link={course["Button 1 link"]}
                    button2Label={course["Button 2 label"]}
                    button2Link={course["Button 2 link"]}
                    icon1={course["Button 1 icon"]}
                    icon2={course["Button 2 icon"]}
                  />
                </React.Fragment>
              ))}
            </Loader>
          </div>
          {displaySessions && (
            <div css={right}>
              <div css={title}>
                <Text
                  field={
                    fields &&
                    (fields["card top title"] as SitecoreGenericTextField)
                  }
                />
              </div>
              <div css={card}>
                <div css={titleCard}>Intense course session</div>
                <div css={description}>
                  <RichText
                    field={
                      fields &&
                      (fields[
                        "card top description"
                      ] as SitecoreGenericTextField)
                    }
                  />
                </div>
              </div>
              {fields &&
                fields["card bottom title"] &&
                fields &&
                fields["card bottom title"].value && (
                  <div css={cardWhite}>
                    <div css={cardBigTitle}>
                      <Text
                        field={
                          fields &&
                          (fields[
                            "card bottom title"
                          ] as SitecoreGenericTextField)
                        }
                      />
                    </div>
                    <div css={cardBigDescription}>
                      <RichText
                        field={
                          fields &&
                          (fields[
                            "card bottom description"
                          ] as SitecoreGenericTextField)
                        }
                      />
                    </div>
                    <div css={cardAction}>
                      {btnLabel && (
                        <Button
                          iconLeft={"paper-plane"}
                          label={btnLabel}
                          onClick={() =>
                            canUseDOM &&
                            (btnLink
                              ? window.open(btnLink, "_blank")
                              : console.log)
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    );
  };
