/** @jsx jsx */

import { css, jsx } from "@emotion/react";

import { Collapse } from "react-collapse";
import { CountryFlagIcon } from "../../../CountryFlagIcon/countryFlagIcon.component";
import { DownloadDocument } from "./row";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import Props from "../base/props";
import React from "react";
import SearchDocument from "../../../../../interfaces/search/search-document";
import { documentSubTypeLabel } from "../../../../../enums/document-sub-type";
import { documentTypeLabel } from "../../../../../enums/document-type";
import { formatDate } from "../../../../../utils/date";
import { style } from "../base/index.style";
import { style as mobileStyle } from "./card-mobile.style";

export const DocumentSearchCard = (
  props: Props<SearchDocument>
): JSX.Element => {
  const { card, fastLaneUrls } = props;
  
  const displayFastLane =
    (card?.documentTypeCode === "D003" || card?.documentTypeCode === "D090") &&
    fastLaneUrls?.length > 0;

  const [openFastLane, setOpenFastLane] = React.useState<boolean>(false);
  const [openSup, setOpenSup] = React.useState<boolean>(false);
  const [openRef, setOpenRef] = React.useState<boolean>(false);

  if (!card) return <></>;
  const hasLinkedDocs = card.incorporedByReferenceDocuments?.length > 0;

  return (
    <div
      css={css`
        background: #ffffff;
        border: 1px solid #d9dee2;
        border-radius: 8px;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
        margin-bottom: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 8px;
          border-bottom: solid 1px #d9dee2;
          padding: 16px;
          flex-wrap: wrap;
        `}
      >
        {card.categories?.length ? (
          card.categories.map((category: string, index: number) => (
            <>
              {documentTypeLabel(category) && (
                <div
                  css={[
                    style.market,
                    css`
                      white-space: unset;
                    `,
                  ]}
                  key={`document-card-${card.id}-category-${category}`}
                >
                  {documentTypeLabel(category)}
                </div>
              )}
            </>
          ))
        ) : (
          <></>
        )}
        {documentSubTypeLabel(card?.documentPublicTypeCode) && (
          <div css={[style.market]}>
            {documentSubTypeLabel(card?.documentPublicTypeCode)}
          </div>
        )}
      </div>
      <div
        css={css`
          padding: 16px;
          border-bottom: solid 1px #d9dee2;
          font-weight: 800;
          font-size: 19px;
          line-height: 26px;
          color: #425563;
        `}
      >
        <div css={style.subLabel}>{card.description}</div>
        <div>
          {card.publishDate ? `${formatDate(card.publishDate)} - ` : ""}
          {card.complement}
        </div>
        {hasLinkedDocs ? (
          <div
            css={[
              style.subLabel,
              css`
                margin-top: 8px;
              `,
            ]}
          >
            {card.supplementDocuments?.length
              ? `${card.supplementDocuments?.length} supplement(s)`
              : ""}
            {card.incorporedByReferenceDocuments?.length
              ? `${card.supplementDocuments?.length ? " | " : ""}${
                  card.incorporedByReferenceDocuments?.length
                } document(s) incorpored by reference`
              : ""}
            { displayFastLane ? `${
                card.supplementDocuments?.length ||
                card.incorporedByReferenceDocuments?.length
                  ? " | "
                  : ""
              }${fastLaneUrls?.length} FastLane URL(s)`
              : ""}
          </div>
        ) : (
          <></>
        )}
      </div>
      {card?.hostMemberStates && card?.hostMemberStates?.length > 0 ? (
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            Host member state
          </div>
          <div>
            {card.hostMemberStates.map((isoCode: string) => (
              <CountryFlagIcon
                key={`document-card-${card.id}-host-${isoCode}`}
                code={isoCode}
                size={{ height: 20, width: 30 }}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        css={css`
          padding: 16px;
          border-bottom: solid 1px #d9dee2;
        `}
      >
        <div
          css={css`
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #425563;
          `}
        >
          Publication date
        </div>
        <div
          css={css`
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.01em;
            color: #54666f;
            margin-top: 8px;
          `}
        >
          {card.publishDate ? `${formatDate(card.publishDate)}` : ""}
        </div>
      </div>
      {card.downloadUrl && (
        <div
          css={css`
            padding: 16px;
          `}
        >
          <DownloadDocument
            documentId={card.id}
            documentUrl={card.downloadUrl}
            documentTypes={card.categories}
            showDownload={true}
            showPreview={false}
          />
        </div>
      )}
      {displayFastLane && (
        <>
          <div css={mobileStyle.collapseHead}>
            <div css={css` width: 90%;`}>FastLane URLs ({fastLaneUrls?.length}{" "}URL(s))</div>
            <div css={mobileStyle.collapseIcon}
                 onClick={() => setOpenFastLane(!openFastLane)}>
              <Icon icon={openFastLane ? "chevron-up" : "chevron-down"} />
            </div>
          </div>
          <Collapse isOpened={openFastLane}>
            {fastLaneUrls.map((url : string) => (
              <div key={`document-fastlane-${url}`} css={mobileStyle.collapseBody}>
                <div>
                  <div css={[style.market, css`  white-space: unset; `]}>
                    <Icon icon={"link"} />
                  </div>
                </div>

                <div css={css `margin-top: 8px;`}>
                  <div css={style.bold}>Additionnal web information link</div>
                  <div css={style.labelLink}>
                    <a target="_blank" href={url}>
                      {url}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Collapse>
        </>
      )}
      {card.supplementDocuments?.length > 0 && (
        <>
          <div
            css={css`
              padding: 16px;
              display: flex;
              background: #d3f2e1;
              color: #54666f;
            `}
          >
            <div
              css={css`
                width: 90%;
              `}
            >
              Supplements ({card.supplementDocuments?.length} document(s))
            </div>
            <div
              css={css`
                width: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                  color: #22aa5f !important;
                }
              `}
              onClick={() => setOpenSup(!openSup)}
            >
              <Icon icon={openSup ? "chevron-up" : "chevron-down"} />
            </div>
          </div>
          <Collapse isOpened={openSup}>
            {card.supplementDocuments.map((doc) => (
              <div
                key={`document-card-${card.id}-supldoc-${doc?.id}`}
                css={css`
                  padding: 16px;
                  display: flex;
                  background: #e8faf0;
                  border-top: 1px solid #22aa5f;
                  color: #54666f;
                  flex-direction: column;
                  border-width: 1px 0px 0px 5px;
                  border-style: solid;
                  border-color: #22aa5f;
                  padding-left: 32px;
                `}
              >
                <div>
                  <div
                    css={[
                      style.market,
                      css`
                        white-space: unset;
                      `,
                    ]}
                  >
                    Supplement
                  </div>
                </div>

                <div
                  css={css`
                    margin-top: 16px;
                  `}
                >
                  Prospectus Supplement{" "}
                  {doc.description ? `- ${doc.description}` : ""}
                </div>
                <div
                  css={[
                    style.subLabel,
                    css`
                      margin-top: 8px;
                    `,
                  ]}
                >
                  {doc.incorporedByReferenceDocuments?.length} Doc. Inc. Ref.
                </div>
                <div
                  css={css`
                    margin-top: 8px;
                  `}
                >
                  <DownloadDocument
                    documentId={doc.id}
                    documentUrl={doc.downloadUrl}
                    documentTypes={doc.categories}
                    showDownload={true}
                    showPreview={false}
                  />
                </div>
              </div>
            ))}
          </Collapse>
        </>
      )}
      {card.incorporedByReferenceDocuments?.length > 0 && (
        <>
          <div
            css={css`
              padding: 16px;
              display: flex;
              background: #d3f2e1;
              color: #54666f;
            `}
          >
            <div
              css={css`
                width: 90%;
              `}
            >
              Doc. Inc. Ref. ({card.incorporedByReferenceDocuments?.length}{" "}
              document(s))
            </div>
            <div
              css={css`
                width: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                  color: #22aa5f !important;
                }
              `}
              onClick={() => setOpenRef(!openRef)}
            >
              <Icon icon={openRef ? "chevron-up" : "chevron-down"} />
            </div>
          </div>
          <Collapse isOpened={openRef}>
            {card.incorporedByReferenceDocuments.map((doc) => (
              <div
                key={`document-card-${card.id}-refedoc-${doc?.id}`}
                css={css`
                  padding: 16px;
                  display: flex;
                  background: #e8faf0;
                  border-top: 1px solid #22aa5f;
                  color: #54666f;
                  flex-direction: column;
                  border-width: 1px 0px 0px 5px;
                  border-style: solid;
                  border-color: #22aa5f;
                  padding-left: 32px;
                `}
              >
                <div>
                  <div
                    css={[
                      style.market,
                      css`
                        white-space: unset;
                      `,
                    ]}
                  >
                    Document
                  </div>
                </div>

                <div
                  css={css`
                    margin-top: 16px;
                  `}
                >
                  Document incorporated by reference - {doc.description}
                </div>

                <div
                  css={css`
                    margin-top: 8px;
                  `}
                >
                  {doc.publishDate ? `${formatDate(doc.publishDate)} - ` : ""}
                  {doc.complement}
                </div>
                <div
                  css={css`
                    margin-top: 8px;
                  `}
                >
                  <DownloadDocument
                    documentId={doc.id}
                    documentUrl={doc.downloadUrl}
                    documentTypes={doc.categories}
                    showDownload={true}
                    showPreview={false}
                  />
                </div>
              </div>
            ))}
          </Collapse>
        </>
      )}
    </div>
  );
};

export default DocumentSearchCard;
