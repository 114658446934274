/** @jsx jsx */
/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import { Icon, Table, Tbody, Td, Th, Thead, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import { genericTradingDataStyle } from "../../DataMarket/TradingData/Shared/generic-trading-data.style";
import { sharedStyle } from "../../../../styles/shared";
import { style } from "../market-statistics.style";
import WidgetCard from "../../WidgetCard";
import axios from "axios";
import MarketCapDataModel from "../Interfaces/MarketCapModel";
import { BrowserRouter } from "react-router-dom";
import TopTradedSecuritiesComponent from "../Base/TopTradedSecuritiesComponent";
import { GET_TOP_TRADED_SECURITIES_BY_MONTH_REPORT, GET_TOP_TRADED_SECURITIES_BY_YEAR_REPORT } from "../../../../graphql/queries/market.queries";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";


export class TopTradedSecuritiesModel {
    topLuxSharesCap?: MarketCapDataModel[];
    topIssuedShares?: MarketCapDataModel[];
    topLuxxComponent?: MarketCapDataModel[];
}
export class Security {
    id: string;
    name?: string;
    isin?: string;
    turnover?: number;
    currency?: string;
    numberOfTransaction: number;

    constructor(id: string, name: string, isin: string, turnover: number, currency: string, numberOfTransaction: number) {
        this.id = id;
        this.name = name;
        this.isin = isin;
        this.turnover = turnover;
        this.currency = currency;
        this.numberOfTransaction = numberOfTransaction;
    }
}

export function TopTradedSecuritiesHome(props: any) {

    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [loader, setLoader] = useState<boolean>(true);

    const client = luxseGatewayGraphqlClient;

    let [topShareByMonth, setTopShareByMonth] = useState<Security>();
    let [topBondByMonth, setTopBondByMonth] = useState<Security>();

    let [topShareByYear, setTopShareByYear] = useState<Security>();
    let [topBondByYear, setTopBondByYear] = useState<Security>();

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const isDisplayed = props.isDisplayed;
    const from = props.from;


    let year: string = new Date().getFullYear().toString();
    let month: string = String(new Date().getMonth() + 1).padStart(2, '0');
    
    console.log(month + year);



    const fetchTopTradedSecurityByMonth = async () => {
        try {
            if (!loader)
                setLoader(true);

            const resultsShares: any = await client.query({
                query: GET_TOP_TRADED_SECURITIES_BY_MONTH_REPORT,
                variables: {
                    type: "ACT",
                    date: month + "/" + year,
                },
            });

            const resultsBonds: any = await client.query({
                query: GET_TOP_TRADED_SECURITIES_BY_MONTH_REPORT,
                variables: {
                    type: "OBL",
                    date: month + "/" + year,
                },
            });


            topShareByMonth = resultsShares?.data?.topTradedSecuritiesByMonth[0];

            setTopShareByMonth({ ...topShareByMonth });

            topBondByMonth = resultsBonds?.data?.topTradedSecuritiesByMonth[0];

            setTopBondByMonth({ ...topBondByMonth });


            setLoader(false);


        } catch (e) {
            console.error('Failed to get top traded securities data', e);
            setLoader(false);
        }
    }


    const fetchTopTradedSecurityByYear = async () => {
        try {
            if (!dataLoading)
                setDataLoading(true);

            const resultsShares: any = await client.query({
                query: GET_TOP_TRADED_SECURITIES_BY_YEAR_REPORT,
                variables: {
                    type: "ACT",
                    date: year,
                },
            });

            const resultsBonds: any = await client.query({
                query: GET_TOP_TRADED_SECURITIES_BY_YEAR_REPORT,
                variables: {
                    type: "OBL",
                    date: year,
                },
            });

            topShareByYear = resultsShares?.data?.topTradedSecuritiesByYear[0];

            setTopShareByYear({ ...topShareByYear });

            topBondByYear = resultsBonds?.data?.topTradedSecuritiesByYear[0];

            setTopBondByYear({ ...topBondByYear });


            setDataLoading(false);


        } catch (e) {
            console.error('Failed to get top traded securities dat', e);
            setDataLoading(false);
        }
    }

    useEffect(() => {
        fetchTopTradedSecurityByMonth();
        fetchTopTradedSecurityByYear();
    }, []);




    return (
        <>
            {isDisplayed ?
                <div css={[style.marketCapContent,from==="risers-fallers"?css`width:100%;background: var(--ligt-grey, #F6F8F9);margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;`:'']}>
                    <div css={[sharedStyle.row]}>
                        <WidgetCard
                            loader={loader}
                            icon='stairs'
                            iconType={'fas'}
                            label='Top traded securities'
                            isHover={false}
                            link={
                                <div css={sharedStyle.link_container}>
                                    <BrowserRouter forceRefresh={true}>
                                        <a css={sharedStyle.link_data_market} href={'/market-overview/market-statistics/top-traded-securities'}
                                        >
                                            Consult top traded securities &nbsp;
                                            <Icon icon={'chevron-right'} />
                                        </a>
                                    </BrowserRouter>
                                </div>
                            }
                        >
                            {!loader ? (
                                <TopTradedSecuritiesComponent
                                    type="Monthly"
                                    topShare={topShareByMonth}
                                    topBond={topBondByMonth}
                                    date={month + "/" + year}
                                    title="Top traded securities in "
                                />
                            ) : (
                                <div>No data available</div>
                            )}
                        </WidgetCard>
                        <WidgetCard
                            loader={dataLoading}
                            icon='stairs'
                            iconType={'fas'}
                            label='Top traded securities'
                            isHover={false}
                            link={
                                <div css={sharedStyle.link_container}>
                                    <BrowserRouter forceRefresh={true}>
                                        <a css={sharedStyle.link_data_market} href={'/market-overview/market-statistics/top-traded-securities'}
                                        >
                                            Consult top traded securities &nbsp;
                                            <Icon icon={'chevron-right'} />
                                        </a>
                                    </BrowserRouter>
                                </div>
                            }
                        >
                            {!dataLoading ? (
                                <TopTradedSecuritiesComponent
                                    type="Yearly"
                                    topShare={topShareByYear}
                                    topBond={topBondByYear}
                                    date={year}
                                    title="Top traded securities in "
                                />
                            ) : (
                                <div>No data available</div>
                            )}
                        </WidgetCard>

                    </div>
                </div>
                :
                <WidgetCard
                    loader={loader}
                    icon='stairs'
                    iconType={'fas'}
                    label='Top traded securities'
                    isHover={false}
                    link={
                        <div css={sharedStyle.link_container}>
                            <BrowserRouter forceRefresh={true}>
                                <a css={sharedStyle.link_data_market} href={'/market-overview/market-statistics/top-traded-securities'}
                                >
                                    Consult top traded securities &nbsp;
                                    <Icon icon={'chevron-right'} />
                                </a>
                            </BrowserRouter>
                        </div>
                    }
                >
                    {!loader ? (
                        <TopTradedSecuritiesComponent
                            type="Monthly"
                            topShare={topShareByMonth}
                            topBond={topBondByMonth}
                            date={month + "/" + year}
                            title="Top traded securities in "
                        />
                    ) : (
                        <div>No data available</div>
                    )}
                </WidgetCard>
            }
        </>
    );
}

export default TopTradedSecuritiesHome;
