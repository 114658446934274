import React, { useEffect, useRef, useState } from 'react';
import { fetchData, getDataWithChild } from '../../../utils/helper';

interface DictionaryComponentProps {
    text: string;
}

const DictionaryComponent: React.FC<DictionaryComponentProps> = ({ text }) => {

    const [dictionaryItems, setDictionaryItems] = React.useState<{ [key: string]: string }>({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [processedText, setProcessedText] = useState<string>('');


    const getDictionaryItems = async (id: string) => {
        try {
            const result = await fetchData(id);
            const dataReq = await getDataWithChild(result);
            const items: { [key: string]: string } = {};

            if (dataReq && dataReq.length > 0) {
                for (const d of dataReq) {
                    if (d.children && d.children.length > 0) {
                        for (const item of d.children) {
                            const key = item.Key; 
                            const phrase = item.Phrase;
                            if (key && phrase) {
                                items[key] = phrase;
                            } 
                        }
                    } else {
                        console.warn('No children found for item:', d);
                    }
                }
            } else {
                console.warn('No data found:', dataReq);
            }
            setDictionaryItems(items);
            setIsLoading(false);
        } catch (err) {
            console.error('Error fetching dictionary items:', err);
        }
    };

    React.useEffect(() => {
        getDictionaryItems('504AE189-9F36-4C62-9767-66D73D6C3084');
    }, []);

     useEffect(() => {
        if (!isLoading) {
            setProcessedText(replaceKeysWithPhrases(text, dictionaryItems));
        }
    }, [isLoading, text, dictionaryItems]);

    const replaceKeysWithPhrases = (text: string, dictionary: { [key: string]: string }) => {
        return text.replace(/\[([^\]]+)\]/g, (match, key) => dictionary[key] || key);
    };

    return <div>{replaceKeysWithPhrases(text, dictionaryItems)}</div>;
    
}
export default DictionaryComponent;