import * as React from "react";

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Link,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import hexa from "./hexa.svg";
import { renderLink } from "../../utils/helper";

/** @jsx jsx */
const lint = jsx; // linter hack
export interface TwoColsWithBackgroundAndHexagonProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  margin: auto;
  padding-top: 48px;
  padding-bottom: 48px;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
  }
`;
const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-image: url(${renderLink(hexa)});
  background-repeat: no-repeat;
  padding-top: 96px;
  background-size: 600px;
  background-position: center top;
  @media (max-width: ${breakpointMax}) {
    background-size: 100%;
    flex-direction: column;
  }
`;
const blocs = css`
  width: 100%;
  display: flex;
  min-height: 500px;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const blocRight = css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f6f9f9;
  border-radius: 24px;
  margin-left: 10px;
  padding: 24px;

  :hover {
    background: #e8ecee;
  }

  cursor: pointer;
  @media (max-width: ${breakpointMax}) {
    margin: 0;
  }
`;
const blocRightTitle = css`
  margin-bottom: 24px;
  font-weight: 800;
  font-size: 24px;
  color: #354450;
  line-height: 31px;
  text-align: center;
  max-width: 80%;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    max-width: 100%;
    text-align: center;
  }
`;
const blocRightImage = css`
  margin-bottom: 24px;
  font-weight: 800;
  font-size: 24px;
  color: #354450;
  line-height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    width: 100%;
    max-width: 290px;
    //filter: drop-shadow(5px 5px 5px #222);
  }
`;
const title = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 62px;
  margin-bottom: 64px;
  display: flex;
  //margin-top: 24px;
  justify-content: center;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
  }
`;

const actions = css`
  margin-top: 40px;
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
`;

const blocLeftTitle = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  text-align: center;
  max-width: 80%;
  display: flex;
  margin: auto;
  justify-content: center;
  color: #ffffff;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }
`;

export const TwoColsWithBackgroundAndHexagon: React.FunctionComponent<TwoColsWithBackgroundAndHexagonProps> =
  (props: TwoColsWithBackgroundAndHexagonProps): React.ReactElement => {
    const { fields } = props;
    const cardLeftBackground: any = getFieldValue<any>(
      fields,
      "cardLeftBackground",
      null
    );
    const cardRightBackground: any = getFieldValue<any>(
      fields,
      "cardRightBackground",
      null
    );
    const btnLabel: string = getFieldValue<string>(fields, "btnLabel", "");

    const blocLeft = css`
      width: 100%;
      display: flex;
      flex-direction: column;
      background: linear-gradient(180deg, transparent 10%, #425563 70%),
        url(${renderLink(cardLeftBackground && cardLeftBackground.src)});
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 24px;
      padding: 24px;
      margin-right: 10px;
      cursor: pointer;
      text-decoration-color: transparent;

      > div,
      a,
      button {
        text-decoration-color: transparent;
      }

      :hover {
        background: linear-gradient(180deg, transparent 10%, #425563 90%),
          url(${renderLink(cardLeftBackground && cardLeftBackground.src)});
        background-size: cover;
        background-repeat: no-repeat;
      }

      @media (max-width: ${breakpointMax}) {
        margin-bottom: 16px;
        margin-right: 0;
      }
    `;

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={title}>
            <Text
              field={fields && (fields.title as SitecoreGenericTextField)}
            />
          </div>
          <div css={blocs}>
            {fields?.["button link"]?.value && (
              <Link
                css={blocLeft}
                target={
                  fields["button link"]?.value?.linktype === "external"
                    ? "_blank"
                    : "_self"
                }
                title={btnLabel || " "}
                field={fields["button link"]}
              >
                <div css={blocLeftTitle}>
                  <Text
                    field={
                      fields &&
                      (fields.cardLeftText as SitecoreGenericTextField)
                    }
                  />
                </div>
                <div css={actions}>
                  <Button label={btnLabel} iconRight={"chevron-right"} />
                </div>
              </Link>
            )}
            {fields?.["cardRightBackground"]?.value?.src && (
              <div
                css={blocRight}
                onClick={() => {
                  fields &&
                  fields[`cardRightDocument`] &&
                  (fields && (fields[`cardRightDocument`] as any)).value &&
                  (fields && (fields[`cardRightDocument`] as any)).value.src
                    ? (window.location.href = (
                        fields && (fields[`cardRightDocument`] as any)
                      ).value.src)
                    : console.log();
                }}
              >
                <div css={blocRightTitle}>
                  <Text
                    field={
                      fields &&
                      (fields.cardRightText as SitecoreGenericTextField)
                    }
                  />
                </div>
                <div css={blocRightImage}>
                  <img
                    src={renderLink(
                      cardRightBackground && cardRightBackground.src
                    )}
                    alt={"image"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
