
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
    childrenBloc,
    childrenBlocLeft, childrenBlocRight,
    childrenBlocSubTitle,
    childrenBlocTitle, letterBloc, letterIndicator, rootPage, tag,
    tagContainer
} from "../../shared.style";

export interface GlossaryItemRowProps {
    data: any,
    keyData: string,
    index: number,
}

export const GlossaryItemRow: React.FunctionComponent<GlossaryItemRowProps> = (props: GlossaryItemRowProps): React.ReactElement => {
    const { data = [], keyData, index } = props;

    return (
        <div key={keyData}  css={letterBloc} style={{backgroundColor: index % 2 == 0 ? '#ffffff' : '#F6F8F9'}}>
            <div css={rootPage}>
                <div css={letterIndicator}>
                    { data.label }
                </div>
                {
                    data.children.map((child: any, index: number) => (
                        <div css={childrenBloc}  key={`child-key-${index}`} >
                            <div css={childrenBlocLeft}>
                                <div css={childrenBlocTitle}>
                                    { child.Label }
                                </div>
                                <div css={childrenBlocSubTitle}>
                                    Categories
                                </div>
                                <div css={tagContainer}>
                                    {
                                        child && child.Categories && child.Categories.map((data: any, index: number) => (
                                            <div css={tag}  key={`tag-key-${index}`} >
                                                { data.label }
                                            </div>
                                        ))
                                    }
                                </div>
                                <div css={childrenBlocSubTitle}>
                                    Thematics
                                </div>
                                <div css={tagContainer}>
                                    {
                                        child && child.Thematics && child.Thematics.map((thematic: any, index: number) => (
                                            <div css={tag}  key={`tag-thematics-${index}`} >
                                                { thematic.label }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div css={childrenBlocRight}>
                                <div
                                    dangerouslySetInnerHTML={{__html: child.Description}}
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}
