/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react';
import { style } from './index.style';
import SearchResult from '../../../../../interfaces/search/search-result';
import { Filters } from '../../useFilters';
import useTagFilters from '../useTagFilters';
import ResultsIndicator from './resultsIndicator';
import MobileResults from './mobileResults';
import ResultsCards from './resultsCards';
import Loader from '../../../Loader/loader.component';
import FilterButton from './filterButton';
import MobileFilterContainer from './mobileFilterContainer';
import { SecurityFilters } from '../../../UI/list/security/security-filters';
import { DataType } from '../../../../../enums';
import { LuxXPrimeFilters } from '../../../UI/list/luxXPrime/luxXPrime-filters';
import { IssuerFilters } from '../../../UI/list/issuer/issuer-filters';
import { ProgrammeFilters } from '../../../UI/list/programme/programme-filters';
import { DocumentFilters } from '../../../UI/list/document/document-filters';
import { IndexFilters } from '../../../UI/list/index/index-filters';
import { ItemCard } from '../../../../../sitecore-components/TradingItemCard';

export interface SearchResultsTabsProps {
  data: SearchResult;
  onPaginationUpdate: (pageNumber: number) => void;
  onTabChange: (tabValue: string) => void;
  currentPageNumber: number;
  filters: Filters;
  onFiltersChange: (filters: Filters) => void;
  sort: any;
  onSortChange: (sort: any) => void;
  loading: boolean;
}

export const SearchResultsTabsContentMobile = (props: SearchResultsTabsProps): JSX.Element => {
  const {
    data,
    onPaginationUpdate,
    onTabChange,
    currentPageNumber,
    filters,
    onFiltersChange,
    sort,
    onSortChange,
    loading,
  } = props;

  const source: any[] = [
    { label: 'LuxXPrime', data: 'luxXPrime', dataType: 'luxXPrime' },
    { label: 'Issuers', data: 'issuers', dataType: 'issuers' },
    { label: 'Securities', data: 'securities', dataType: 'securities' },
    { label: 'Programmes', data: 'programmes', dataType: 'programmes' },
    { label: 'Documents', data: 'documents', dataType: 'documents' },
    { label: 'Indices', data: 'indexes', dataType: 'indices' },
  ];

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [nbResults, setNbResults] = useState<number>(0);
  const [category, setCategory] = useState<string>('');

  const tagFilters = useTagFilters(filters);

  React.useEffect(() => {

    let sourceItem : any = null;
    source.forEach(item => {
      if( filters?.dataType === item.dataType )
        sourceItem = item;

    });

    if (data && sourceItem) {
      setNbResults( (data as any)[sourceItem.data]?.totalHits );
      setCategory( sourceItem.label );

      //setSortedData((data as any)[dataType][dataType]);
    } else {
      console.error('NO DATA TYPE');
    }
  }, [data]);



  const switchTab = (tab: string) => {
    onTabChange(tab);
  };
  const switchFilter = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <div css={style.tabs}>
      {data ? (
        <div css={style.mobilePart}>
          <Loader loading={loading}>
            <ResultsIndicator category={category} 
                              filters={filters}
                              resultsNumber={nbResults} />
            <MobileResults data={data} 
                           source={source}
                           dataType={filters?.dataType} 
                           onSwitchTab={switchTab} />
            <ResultsCards 
              data={data} 
              dataType={filters?.dataType}
              onPaginationUpdate={onPaginationUpdate}
              currentPageNumber={currentPageNumber}
            />
            <FilterButton onSwitchFilter={switchFilter} open={menuIsOpen} />
            <MobileFilterContainer open={menuIsOpen}>
              {filters?.dataType?.toLowerCase() == DataType.securities ? (
                <SecurityFilters
                  filterCount={data?.securities?.filters}
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  fullWidth
                />
              ) : filters?.dataType?.toLowerCase() == DataType.luxXPrime.toLowerCase() ? (
                <LuxXPrimeFilters
                  filterCount={data?.luxXPrime?.filters}
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  fullWidth
                />
              ) : filters?.dataType?.toLowerCase() == DataType.issuers ? (
                <IssuerFilters
                  filterCount={data?.issuers?.filters}
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  fullWidth
                />
              ) : filters?.dataType?.toLowerCase() == DataType.programmes ? (
                <ProgrammeFilters
                  filterCount={data?.programmes?.filters}
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  fullWidth
                />
              ) : filters?.dataType?.toLowerCase() == DataType.documents ? (
                <DocumentFilters
                  filterCount={data?.documents?.filters}
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  fullWidth
                />
              ) : filters?.dataType?.toLowerCase() == DataType.indices ? (
                <IndexFilters
                  filterCount={data?.indexes?.filters}
                  filters={filters}
                  onFiltersChange={onFiltersChange}
                  fullWidth
                />
              ) : (
                <></>
              )}
            </MobileFilterContainer>
          </Loader>
        </div>
      ) : (
        <div css={style.noDataMessage}>Data will be displayed when you have filled in the search field</div>
      )}
    </div>
  );
};

export default SearchResultsTabsContentMobile;
