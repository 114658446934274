/** @jsx jsx */
/* eslint-disable */
import React, { Component } from "react";
import { css, jsx } from "@emotion/react";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpointChartMax, breakpointMax } from "../../../../style";
import { canUseDOM } from "../../../../predicates";


export class RiserAndFaller extends Component<any, any> {

    render() {
        const data = this.props.data;
        const type = this.props.type;

        const style = {
            info: css`
            display:flex;
            justify-content: space-around;
            flex-wrap: wrap;
            width:100%;
            align-items:center;
            text-align: center;
            cursor: pointer;
            :hover {
                background: #f6f8f9;
                transition: all 0.1s ease-in-out;
            }
            @media (max-width: ${breakpointMax}) {
                margin-bottom:0;
                padding-bottom:0;
                justify-content: center;
            }
        `,
            issuer: css`
            display:block;
            flex-direction: column;
            //flex-wrap: wrap;
            margin-top:10px;
            //margin-bottom:auto;
            > p {
                margin:0px;
            }
            margin-left:auto;
            margin-right:1rem;

            @media (max-width: ${breakpointMax}) {
                margin-top:0px;
                margin-bottom:0px;
                padding:0px;
                margin-left:auto;
                margin-right:auto;
            }
        `,
            td: css`
            font-weight: 500;
            font-size: 40px;
            line-height: 51px;
            text-align: center;
            
            @media (max-width: ${breakpointMax}) {
                margin-right:auto;
            }
        `,
            chevron: css`
            font-weight: 400;
            font-size: 24px;
            line-height: 24px; 
            @media (max-width: ${breakpointMax}) {
                margin-left:auto;
            }
        `,
            arrow: css`
            color:"#22AA5F";
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            
        `,
            issuerName: css`
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            color: #425563;
            width:100%
            //line-break:anywhere;
            //overflow-wrap: anywhere;
            margin: 0 auto;
        `,
            isin: css`
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
            color:#A4B6C2;
            padding-top:2px;
            @media (max-width: ${breakpointMax}) {
                margin:0px;
            }
        `,
            turnoverStyle: css`
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
            color:#A4B6C2;
            margin-right:auto;
            margin-bottom:5px;
            @media (max-width: ${breakpointMax}) {
                margin:auto;
            }
        `,
            arrowParent: css`
            margin-top:25px;
            cursor: pointer;
            @media (max-width: ${breakpointMax}) {
                margin-top:0px;
                //visibility: hidden;
                //margin-top:10px;
            }
        `,
            date: css`
                font-weight:bold;
            `,
            variation: css`
                display: flex;
                align-items: center;
                text-align:center;
                >p:nth-child(1){
                    padding-right:4rem;
                }
                >p{
                    text-align:center;
                }
                @media (max-width: ${breakpointMax}) {
                    margin:auto;
                    padding-bottom:10px;
                    height: 41px;
                    width: 100%;
                    //position: absolute;
                    text-align:center;
                    >p:nth-child(1){
                        padding-right:1rem;
                    }
                }
            `
        };


        const goToSecurityDetailPage = (isin: string, id: string) => {
            if (canUseDOM) window.open(`/security/${isin}/${id}`, '_self');
        };

        return (
            <>
                {data && (data?.variation !== 0) ? (
                    <div css={style.info} onClick={() => goToSecurityDetailPage(data.isinCode, data.id)}>
                        <div css={[style.variation, css``]}>
                            {type === "riser" ? (
                                <p css={style.chevron} style={{ color: "#22AA5F" }}><Icon icon={'chevron-up'} /></p>
                            ) : (
                                <p css={style.chevron} style={{ color: "#CB1231" }}><Icon icon={'chevron-down'} /></p>
                            )}
                            <p css={[style.td, type === "riser" ? css`color: #22AA5F;` : css`color: var(--brand-red-lux-se-500, #CB1231);`]}>
                                {type === "riser" ? "+" : ""}
                                {data?.variation.toString().replace('.', ',')} %
                            </p>
                        </div>

                        <div css={style.issuer}>
                            <p css={style.issuerName}>{data?.name}</p>
                            <p css={style.isin}>{data?.isinCode}</p>
                        </div>

                        <div css={style.arrowParent}>
                            <p css={style.arrow} style={{ color: "#22AA5F" }}><Icon icon={"arrow-right"} /></p>
                        </div>
                    </div>
                ) : (
                    <div>No results available</div>
                )
                }
            </>
        );
    }
}

export default RiserAndFaller;
