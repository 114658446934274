import * as React from "react";

import { Button, Card, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import { TradingItemCard } from "../TradingItemCard";
import hexa from "./hexa.svg";
import hexaGreen from "./hexagreen.svg";
import { style } from "./index.style";

/** @jsx jsx */

export interface ListingProcessProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const ListingProcess: React.FunctionComponent<ListingProcessProps> = (
  props: ListingProcessProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const minHeightContainer: number = getFieldValue<number>(
    fields,
    "minHeightContainer",
    0
  );
  const isGreen: number = getFieldValue<number>(fields, "use green color", 0);
  const iconCard1: string = getFieldValue<string>(fields, "iconCard1", "");
  const iconCard2: string = getFieldValue<string>(fields, "iconCard2", "");
  const iconCard3: string = getFieldValue<string>(fields, "iconCard3", "");
  const iconCard4: string = getFieldValue<string>(fields, "iconCard4", "");
  const buttonText: string = getFieldValue<string>(fields, "buttonText", "");
  const buttonLink: string = getFieldValue<string>(fields, "buttonLink", "");
  const buttonIcon: string = getFieldValue<string>(fields, "buttonIcon", "");
  const buttonText2: string = getFieldValue<string>(fields, "buttonText2", "");
  const buttonLink2: string = getFieldValue<string>(fields, "buttonLink2", "");
  const buttonIcon2: string = getFieldValue<string>(fields, "buttonIcon2", "");
  const greyBackground: boolean = getFieldValue<boolean>(
    fields,
    "greyBackground",
    false
  );

  const icons: any = {
    iconCard1,
    iconCard2,
    iconCard3,
    iconCard4,
  };

  const embedStyle = {
    card: css`
      width: 100%;
      background: #ffffff;
      padding: 0.5em;
      border-radius: 8px;
      box-shadow: 0px 16px 24px rgba(164, 188, 194, 0.2);
    `,
    cardContent: css`
      display: flex;
      flex-direction: column;
      padding: 10px;
      height: 100%;
      min-height: ${minHeightContainer}px;
    `,
    cardContentTop: css`
      display: flex;
      flex-direction: column;
      padding: 10px;
      height: 100%;
      min-height: ${minHeightContainer}px;
    `,
  };

  const parentContainer = css`
    width: 100%;
    padding-bottom: 24px;
    min-height: 100px;
    position: relative;
    background-color: ${greyBackground ? "#f0f5f5" : "transparent"};

    @media (max-width: ${breakpointMax}) {
      padding: 0;
      margin: 0;
    }
  `;

  return (
    <div css={parentContainer}>
      <div css={style.background} />
      <div css={style.container}>
        <div css={style.title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        {fields && fields.subTitle?.value && (
          <div css={style.subTitle}>
            <Text
              field={fields && (fields.subTitle as SitecoreGenericTextField)}
            />
          </div>
        )}

        <div css={style.cards}>
          {new Array(4).fill(0).map((arr: any, index: number) => {
            if (
              fields &&
              fields[`titleCard${index + 1}`] &&
              (fields && (fields[`titleCard${index + 1}`] as any)).value
            ) {
              return (
                <div css={style.card} key={`issuer-${index}`}>
                  <div css={embedStyle.card}>
                    <div css={embedStyle.cardContent}>
                      <div css={embedStyle.cardContentTop}>
                        <div
                          css={isGreen ? style.iconCardGreen : style.iconCard}
                          style={{
                            backgroundImage: `url(${
                              isGreen ? hexaGreen : hexa
                            })`,
                          }}
                        >
                          <Icon icon={icons[`iconCard${index + 1}`]} />
                        </div>
                        <div css={style.titleCard}>
                          <Text
                            field={
                              fields[
                                `titleCard${index + 1}`
                              ] as SitecoreGenericTextField
                            }
                          />
                        </div>
                        <div css={style.textCard}>
                          <Text
                            field={
                              fields[
                                `textCard${index + 1}`
                              ] as SitecoreGenericTextField
                            }
                          />
                        </div>
                      </div>
                      {fields &&
                        fields[`buttonLabelCard${index + 1}`] &&
                        (
                          fields &&
                          (fields[`buttonLabelCard${index + 1}`] as any)
                        ).value && (
                          <div
                            css={style.actionCard}
                            onClick={() =>
                              fields &&
                              fields[`buttonLinkCard${index + 1}`] &&
                              (
                                fields &&
                                (fields[`buttonLinkCard${index + 1}`] as any)
                              ).value
                                ? (window.location.href =
                                    fields &&
                                    fields[`buttonLinkCard${index + 1}`] &&
                                    (
                                      fields &&
                                      (fields[
                                        `buttonLinkCard${index + 1}`
                                      ] as any)
                                    ).value)
                                : console.log
                            }
                          >
                            <span>
                              {fields &&
                                fields[`buttonLabelCard${index + 1}`] &&
                                (
                                  fields &&
                                  (fields[`buttonLabelCard${index + 1}`] as any)
                                ).value}
                            </span>{" "}
                            <Icon icon={"chevron-right"} />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        {(buttonText || buttonText2) && (
          <div css={style.actions}>
            {buttonText && (
              <Button
                style={{ marginRight: "16px" }}
                label={buttonText}
                onClick={() =>
                  buttonLink ? (window.location.href = buttonLink) : console.log
                }
                iconRight={(buttonIcon as any) || "chevron-right"}
              />
            )}
            {buttonText2 && (
              <Button
                variant={"secondary"}
                label={buttonText2}
                onClick={() =>
                  buttonLink2
                    ? (window.location.href = buttonLink2)
                    : console.log
                }
                iconRight={(buttonIcon2 as any) || "chevron-right"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
