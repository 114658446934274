import * as React from "react";

/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState, useEffect } from "react";
import { luxseGatewayGraphqlClient } from "../../../../../luxse-gateway-graphql-client";
import { GET_LUXXPRIME_LATEST_NOTICES_QUERY } from "../../../../../graphql/queries/luxxprime.queries";
import { formatDate, parseToMomentFormat } from "../../../../../utils/date";
import { securityDetailPageLink } from "../../../SecurityCard";
import { style } from "../../index.style";
import Loader from "../../../Loader/loader.component";
import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { canUseDOM } from "../../../../../predicates";
import Utils from "../../../../utils/utils";

interface NoticeInterface {
  title?: string;
  link?: string;
  date?: Date;
  dateLabel?: string;
  name?: string;
  subName?: string;
}

export const LuxXPrimeLatestNoticeCardsView = () => {
  const client = luxseGatewayGraphqlClient;

  const [data, setData] = useState<NoticeInterface[]>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const getData = async () => {
    try {
      const results: any = await client.query({
        query: GET_LUXXPRIME_LATEST_NOTICES_QUERY,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          limit: 5,
        },
      });

      setDataLoading(false);

      if (results && results.data) {
        let tmpData: NoticeInterface[] = [];

        if (results.data.luxXPrimeLatestFNS)
          results.data.luxXPrimeLatestFNS.forEach((document: any) => {
            if (document)
              tmpData.push({
                title: "FNS Publication",
                //link: "/luxxprime-trade/notices",
                link: securityDetailPageLink(document.isin, document.id),
                date: document.publishDate,
                dateLabel: document.publishDate
                  ? formatDate(document.publishDate, "DD/MM/yyyy hh:mm:ss")
                  : "-",
                name: document
                  ? document.issuers
                      .map((i: any): string => (i.name !== "n/a" ? i.name : ""))
                      .join(", ")
                  : "-",
                subName: document.documentPublicTypeTranslatableField?.value,
              });
          });

        if (results.data.newestLuxXPrimeSecurities)
          results.data.newestLuxXPrimeSecurities.forEach((m: any) => {
            if (m)
              tmpData.push({
                title: "New LuxXPrime Security",
                link: securityDetailPageLink(m.isin, m.id),
                date: m.luxxprimeDate,
                dateLabel: m.luxxprimeDate
                  ? "New since " +
                    formatDate(m.luxxprimeDate, "DD/MM/yyyy hh:mm:ss")
                  : "-",
                subName:
                  m.isin !== "n/a" &&
                  m.currency !== "n/a" &&
                  m.issuedNominalAmount !== "n/a"
                    ? `${m.isin} ${m.currency} ${m.issuedNominalAmount}`
                    : "-",
                name: m.instrumentName || "-",
              });
          });

        if (results.data.removedLuxXPrimeSecurities)
          results.data.removedLuxXPrimeSecurities.forEach((m: any) => {
            if (m) {
              const date =
                m.luxxprimeEndDate !== ""
                  ? m.luxxprimeEndDate
                  : m.delistingDate !== ""
                  ? m.delistingDate
                  : "-";

              tmpData.push({
                title: "Removed LuxXPrime Security",
                link:
                  m.status !== "Removed" && m.status !== "Delisted"
                    ? securityDetailPageLink(m.isin, m.id)
                    : "",
                date: date,
                dateLabel: date ? formatDate(date, "DD/MM/yyyy hh:mm:ss") : "-",
                subName:
                  m.isin !== "n/a" &&
                  m.currency !== "n/a" &&
                  m.issuedNominalAmount !== "n/a"
                    ? `${m.isin} ${m.currency} ${m.issuedNominalAmount}`
                    : "-",
                name: m.instrumentName || "-",
              });
            }
          });

        if (results.data.suspensionsLuxXPrimeSecurities)
          results.data.suspensionsLuxXPrimeSecurities.forEach((m: any) => {
            if (m)
              tmpData.push({
                title: "Suspended LuxXPrime Security",
                link: securityDetailPageLink(m.isin, m.id),
                date: m.suspensionDate,
                dateLabel:
                  m.suspensionDate !== "n/a"
                    ? `New since ${formatDate(
                        m.suspensionDate,
                        "DD/MM/yyyy hh:mm:ss"
                      )}`
                    : "-",
                subName: m.isin || "-",
                name: m.instrumentName || "-",
              });
          });

        if (tmpData)
          tmpData = tmpData
            .sort((a: NoticeInterface, b: NoticeInterface): number => {
              const ad: string = parseToMomentFormat(
                  a.date,
                  "DD-MM-YYYY"
                ).format("YYYYMMDD"),
                bd: string = parseToMomentFormat(b.date, "DD-MM-YYYY").format(
                  "YYYYMMDD"
                );

              return ad > bd ? 1 : ad < bd ? -1 : 0;
            })
            .reverse()
            .slice(0, 3);

        setData(tmpData);
      }
    } catch (e) {
      console.error("Failed to get top traded data", e);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div css={style.container}>
      <div css={style.title}>Latest notices</div>
      <div css={style.content}>
        <Loader loading={dataLoading}>
          <div css={style.contentBody}>
            {data && data?.length > 0 ? (
              data.map((card: NoticeInterface, index: number) => (
                <a
                  css={style.card}
                  href={card.link ? card.link : ""}
                  title={`${card.title} - ${card.name}`}
                >
                  <div css={style.cardContent}>
                    <div css={style.cardTitle}>{card.title}</div>
                    <div css={style.cardDate}>{card.dateLabel}</div>
                    <div css={style.cardSubName}>{card.subName}</div>
                    <div css={style.cardName}>{card.name}</div>
                  </div>
                  <div css={style.cardArrow}>
                    <Icon icon="chevron-right" />
                  </div>
                </a>
              ))
            ) : (
              <></>
            )}
          </div>
        </Loader>
        <div css={style.contentButton}>
          <Button
            label="View latest notices"
            onClick={() => {
              if (canUseDOM)
                window.location.href = `/market-overview/retail-sized-bond-trading/notices`;
            }}
            variant="primary"
          />
        </div>
      </div>
    </div>
  );
};
