import React from 'react';

/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Thead, Tr, Th } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from '../base/index.style';
import * as dataMapper from '../../../Search/results/dataMapper';

const thStyle = css`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px #d9dee2;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 2em;
  padding-right: 2em;
  text-align: left;
`;

export default function SecurityLuxXPrimeHeader({ selectedCols }: any) {
  return (
    <Thead>
      <Tr>
        <th
          css={[
            thStyle,
            css`
              width: 7%;
            `,
          ]}
        >
          Type
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 10%;
            `,
          ]}
        >
          Market/Listing
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 11%;
            `,
          ]}
        >
          Issuer
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 12%;
            `,
          ]}
        >
          Security
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 5%;
            `,
          ]}
        >
          CCY
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 7%;
            `,
          ]}
        >
          Coupon
        </th>
        <Th>Maturity</Th>
        <th
          css={[
            thStyle,
            css`
              width: 5%;
            `,
          ]}
        >
          Yield
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 12%;
            `,
          ]}
        >
          <div css={style.cellSplitTwo}>
            <div css={style.cellSplitLeft}>Bid</div>
            <div css={[style.cellSplitRight, style.cellSplitSeparator]}>Ask</div>
          </div>
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 5%;
            `,
          ]}
        >
          Volume
        </th>
        <Th>Last Price</Th>
        <th
          css={[
            thStyle,
            css`
              width: 10%;
            `,
          ]}
        >
          Vari. (24h)
        </th>
        {selectedCols ? (
          selectedCols['securities'].map(
            (col: string, index: number) =>
              col.toLowerCase() !== 'id' && (
                <Th key={`col-${index}`}>{(dataMapper as any).data['securities'][col].header}</Th>
              )
          )
        ) : (
          <></>
        )}
      </Tr>
    </Thead>
  );
}
