import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

export interface SecurityCardProps {
  fields?: any;
  match?: any;
  id?: string | number;
}

//align-items and justify content should not be needed
//if it was the default value in the design system Flex comp
const securityCardMenuHeader = css`
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #253645;
  text-transform: lowercase;
`;

const securityCardMenuContainer = css`
  width: 100%;
  height: calc(100% - 80px);
  padding: 1rem;
  background: #425563;
`;

const tradingHomeTemplate = css`
  width: calc(100% - 286px);
  height: 100%;
`;

const tradingHomeTemplateHeader = css`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: solid 1px rgba(0, 0, 0, 0.05);
  border-right: solid 1px rgba(0, 0, 0, 0.05);
  align-items: center;
  height: 80px;
  font-size: 1rem;
  background: #ffffff;
`;

const tradingHomeTemplateContainer = css`
  width: 100%;
  height: 100%;
  padding: 1rem;
  font-size: 1rem;
`;
const args = {
  chartHeight: 400,
  series: [
    {
      name: 'Net Profit',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: 'Revenue',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: 'Free Cash Flow',
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ],
  labels: ['TEAM A', 'TEAM B', 'TEAM C', 'TEAM D'],
  legend: false,
  yAxisTitle: 'Y axis Title',
  xAxis: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
  displayLabels: false,
};

const circleArgs = {
  chartHeight: 400,
  series: [67, 84, 97, 61],
  xAxis: ['23'],
  labels: ['TEAM A', 'TEAM B', 'TEAM C', 'TEAM D'],
  legend: false,
  displayLabels: false,
};
const areaArgs = {
  chartHeight: 400,
  series: [
    {
      name: 'series1',
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: 'series2',
      data: [11, 32, 45, 32, 34, 52, 41],
    },
  ],
  xAxis: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan'],
  stacked: true,
  legend: false,
  displayLabels: false,
};
const donutArgs = {
  chartHeight: 400,
  series: [44, 55, 13, 33],
  labels: ['Apple', 'Mango', 'Orange', 'Watermelon'],
  legend: true,
  displayLabels: false,
};
const lineArgs = {
  chartHeight: 400,
  series: [
    {
      name: 'Series A',
      data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
    },
    {
      name: 'Series B',
      data: [20, 29, 37, 36, 44, 45, 50, 58],
    },
  ],
  xAxis: [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021],
  stacked: true,
  displayLabels: false,
  legend: true,
};
type IndiceMarketData = {
  indexInformation: {
    id: string;
    indexName: string;
    codeType: string;
    currency: string;
    basket?: any[];
    securities: any[];
    indexPrice?: any;
    security?: any;
  };
};

type IndiceMarketVariables = {
  id?: string;
};

export const TradingHomeTemplate: React.FunctionComponent<any> = ({
  fields,
  match,
}: SecurityCardProps): React.ReactElement => {
  useEffect(() => {}, []);

  return <div style={{ display: 'flex' }}>ok</div>;
};

export const SecurityCard = withRouter(TradingHomeTemplate);
