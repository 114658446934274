
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const MYS: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <rect y="85.331" fill="#FFFFFF" width="512" height="341.337" />
            <g>
                <rect y="127.994" fill="#CC0000" width="512" height="42.663" />
                <rect y="213.331" fill="#CC0000" width="512" height="42.663" />
                <rect y="298.657" fill="#CC0000" width="512" height="42.663" />
                <rect y="383.994" fill="#CC0000" width="512" height="42.663" />
            </g>
            <rect y="85.331" fill="#0032A0" width="256" height="183.797" />
            <g>
                <path fill="#FFD100" d="M140.919,219.429c-23.308,0-42.202-18.895-42.202-42.202s18.895-42.202,42.202-42.202 c7.267,0,14.104,1.838,20.075,5.072c-9.365-9.158-22.173-14.811-36.307-14.811c-28.686,0-51.941,23.255-51.941,51.941 s23.256,51.941,51.941,51.941c14.133,0,26.942-5.653,36.307-14.811C155.024,217.591,148.186,219.429,140.919,219.429z" />
                <polygon fill="#FFD100" points="152.811,147.545 160.304,163.213 177.224,159.303 169.647,174.929 183.255,185.721 166.312,189.54 166.36,206.907 152.811,196.041 139.262,206.907 139.309,189.54 122.366,185.721 135.974,174.929 128.395,159.303 145.317,163.213 " />
            </g>
        </svg>
    </React.Fragment>
);
