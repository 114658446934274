import * as React from 'react';

import { Icon, Table, Td, Th, Thead, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import { css, jsx } from '@emotion/react';

import { formatDate } from '../../../../../../utils/date';
import { sharedStyle } from '../../../../../../styles/shared';
import { tradingStyle } from '../../trading-data.style';
import { goToSecurityDetailPage, isSecurityClickable } from '../../../../SecurityCard';
import {setSuspensionReason} from "../../../../../../graphql";

/** @jsx jsx */

export const LatestSuspensionsRow = (props: any) => {
  const row = props.suspensionsRow;
  if( !row )
    return <></>;
  const clickable = isSecurityClickable(row?.status);

  return (
    <Tr onRowClick={() => { if(clickable) goToSecurityDetailPage(row.isin, row.id)}}
        css={[sharedStyle.tableRow, !clickable && sharedStyle.tableRowNoClickable]}>
      <Td>
        <div>
          <div css={tradingStyle.fnsTitle}>{row.instrumentName}</div>
          <div css={tradingStyle.fnsText}>
            {`${row.isin} ${row.currency} ${row.issuedNominalAmount}`}
          </div>
        </div>
      </Td>
      <Td>
        <div css={sharedStyle.rowBond}>{setSuspensionReason(row.suspensionReason)}</div>
        <div css={sharedStyle.span}>{formatDate(row.suspensionDate, 'DD/MM/YY HH:mm:ss')}</div>
      </Td>
    </Tr>
  );
};
export default LatestSuspensionsRow;
