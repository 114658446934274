
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';

export interface ZtestComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
    width: 100%;
  border: solid 2px red;
  padding: 32px;
  `;

export const ZtestComponent: React.FunctionComponent<ZtestComponentProps> = (props: ZtestComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    return (
        <div css={container}>
            test
        </div>
    );
}
