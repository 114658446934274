/** @jsx jsx */
import {jsx} from "@emotion/react";
import {InputField, Pagination} from "@bdl-cmn-shared-packages-npm/design-system";
import React, {useEffect, useState} from "react";
import {BACKBOURSE_ENDPOINT} from "../../../backbourse";
import CssfApprovalsTable from "./cssf-approvals-table.component";
import Loader from "../Loader/loader.component";
import SearchDocument from "../../../interfaces/search/search-document";
import SecuritySearchCard from "../UI/list/document/card-mobile";
import axios from "axios";
import {cssfApprovalsStyle} from "./cssf-approvals-view.style";
import {formatDate} from "../../../utils/date";
import {genericStyle} from "../UI/generic/generic.style";
import Utils from "../../utils/utils";
import {goToNotFoundPage} from "../404";
import {GET_LATEST_CSSF_APPROVAL} from "../../../graphql";
import {luxseGatewayGraphqlClient} from "../../../luxse-gateway-graphql-client";


export class CssfApprovalsModel {
    allData?: any;
    filterData?: any;
    displayData?: any;
    totalHits?: number;
}

const totalItemByPage = 10;

export function CssfApprovalsView(props: any) {
    const instance = axios.create();
    const [cssfApprovals, setCssfApprovals] = useState<CssfApprovalsModel>();
    const [cssfApprovalsLoader, setCssfApprovalsLoader] = useState<boolean>(true);
    const client = luxseGatewayGraphqlClient;

    const onPaginationUpdate = (data: any, pageNumber: number) => {
        let result: CssfApprovalsModel = new CssfApprovalsModel();
        result = Object.assign({}, cssfApprovals);
        result.displayData = createPage(cssfApprovals?.filterData, pageNumber);
        setCssfApprovals(result);
        //setCurrentPageNumber(pageNumber);
    };

    function createPage(data: any, currentPage: number): any {
        let startIndex = 0;
        let endIndex = totalItemByPage;

        if (currentPage > 0) {
            endIndex = (currentPage + 1) * totalItemByPage;
            startIndex = endIndex - totalItemByPage;
        }
        return data?.slice(startIndex, endIndex);
    }

    const fetchCSSFApprovals = async () => {
        const cssfApprovalsModel: CssfApprovalsModel = new CssfApprovalsModel();
        console.log('where am i  1 ? ')

        try {
        const dta = await client.query({
            query: GET_LATEST_CSSF_APPROVAL,
            variables: {
                pageNumber: 0,
                pageSize: 0,
            },
        });
        cssfApprovalsModel.allData = dta.data.latestCssfApproval;
        cssfApprovalsModel.filterData = dta.data.latestCssfApproval;
        cssfApprovalsModel.totalHits = dta.data.latestCssfApproval.length;
        cssfApprovalsModel.displayData = createPage(dta.data.latestCssfApproval, 0);
        setCssfApprovals(cssfApprovalsModel);
        setCssfApprovalsLoader(false);

        console.log('where am i  2 ? ')
        } catch (e) {
            console.error("Failed to get CSSF Approvals", e);

            setCssfApprovalsLoader(false);
            //goToNotFoundPage();
        }
    };

    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let reslt: CssfApprovalsModel = new CssfApprovalsModel();
        const searchString = event.target.value.toLowerCase();
        // get filtered data by name and date
        const filteredCssfApprovals = cssfApprovals?.allData?.filter(
            (data: any) => {
                return (
                    formatDate(data?.publishDate, "DD/MM/YYYY").includes(event.target.value) ||
                    data?.complement?.toLowerCase()?.includes(searchString)

                );
            }
        );

        reslt = Object.assign({}, cssfApprovals);
        reslt.filterData = filteredCssfApprovals;
        reslt.displayData = createPage(filteredCssfApprovals, 0);
        reslt.totalHits = filteredCssfApprovals?.length;
        setCssfApprovals(reslt);
    };

    useEffect(() => {
        fetchCSSFApprovals();
    }, []);

    return (
        <>
            <div css={cssfApprovalsStyle.container}>
                <Loader loading={cssfApprovalsLoader}>
                    <div css={cssfApprovalsStyle.filterContainer}>
                        <span css={cssfApprovalsStyle.textPosition}>Filter:</span>
                        <div css={cssfApprovalsStyle.inputStyle}>
                            <InputField
                                helperText=""
                                onChange={inputChangeHandler}
                                placeholder="Date, name"
                                textError=""
                                type="text"
                                width="100%"
                            />
                        </div>
                    </div>

                    <div css={cssfApprovalsStyle.resultContainer}>
                        <p css={cssfApprovalsStyle.textPosition}>
                            <span css={cssfApprovalsStyle.resultText}>{cssfApprovals?.totalHits}&nbsp;</span>
                            <span>document{cssfApprovals?.totalHits > 1 && "s"} </span>
                        </p>
                    </div>

                    <div css={cssfApprovalsStyle.container2}>
                        <div css={genericStyle.desktopDisplay}>
                            <CssfApprovalsTable cssfApprovalsData={cssfApprovals?.displayData}/>
                        </div>
                        <div css={genericStyle.mobileDisplay}>
                            <div css={{padding: "2em", width: "100%"}}>
                                {cssfApprovals?.displayData?.map((data: any) => {
                                    const card = {
                                        documentTypeCode: data.documentTypeCode,
                                        documentPublicTypeCode: data.documentPublicTypeCode,
                                        description: data.description,
                                        complement: data.complement,
                                        publishDate: data.publishDate,
                                        hostMemberStates: data.hostMemberStates,
                                        incorporedByReferenceDocuments: data.incorporedByReferenceDocuments.length > 0 ? data.incorporedByReferenceDocuments : undefined,
                                        downloadUrl: data.downloadUrl,
                                    };
                                    return (
                                        <SecuritySearchCard
                                            key={`card-security-${data.id}`}
                                            card={card as SearchDocument}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        {cssfApprovals?.totalHits > totalItemByPage &&
                            <Pagination
                                initialPage={0}
                                onPageChange={(d: any) => {
                                    onPaginationUpdate(cssfApprovals, d.selected);
                                }}
                                total={
                                    cssfApprovals?.totalHits / totalItemByPage > 200 ? 200 : cssfApprovals?.totalHits / totalItemByPage
                                }
                            />
                        }
                    </div>
                </Loader>
            </div>
        </>
    );
}

export default CssfApprovalsView;
