/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { css, jsx } from "@emotion/react";

import { IndexSimpleStyle } from "./index-simple.styles";
import Loader from "../../Loader/loader.component";
import { genericStyle } from "../../UI/generic/generic.style";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { IndexSearchResultDto } from "../../../../interfaces/search/search";
import { SEARCH_INDEXES_PRICE_WITHOUT_FILTERS } from "../../../../graphql";
import { SearchTable } from "../../UI/list/base";
import IndexHeader from "../../UI/list/index/header";
import IndexRow from "../../UI/list/index/row";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import IndexSearchCard from "../../UI/list/index/card-mobile";
import Utils from "../../../utils/utils";

export function IndexListViewContent(props: any) {
  const client = luxseGatewayGraphqlClient;

  const [loadingIALU, setLoadingIALU] = useState<boolean>(true);
  const [indicesIALU, setIndicesIALU] = useState<IndexSearchResultDto>(null);

  const [loadingIOPC, setLoadingIOPC] = useState<boolean>(true);
  const [indicesIOPC, setIndicesIOPC] = useState<IndexSearchResultDto>(null);

  const fetchIndices = async (type: string) => {
    let indicesTmp: IndexSearchResultDto = null;

    try {
      type === "IALU" ? setLoadingIALU(true) : setLoadingIOPC(true);

      const result: any = await client.query({
        query: SEARCH_INDEXES_PRICE_WITHOUT_FILTERS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          types: [type],
          sort: "",
          size: 100,
          page: 0,
        },
      });

      indicesTmp = result?.data?.luxseIndexesSearch || null;
    } catch (e) {
      console.error("Failed to search indices '" + type + "'", e);
    }

    if (type === "IALU") {
      setIndicesIALU(indicesTmp);
      setLoadingIALU(false);
    } else {
      setIndicesIOPC(indicesTmp);
      setLoadingIOPC(false);
    }
  };

  useEffect(() => {
    setLoadingIALU(false);
    fetchIndices("IALU");

    setLoadingIOPC(false);
    fetchIndices("IOPC");
  }, []);

  return (
    <div css={IndexSimpleStyle.indexContainer}>
      <div>
        <h2>LuxSE indices</h2>
        <div css={IndexSimpleStyle.LoaderPart}>
          <Loader loading={loadingIALU}>
            <div css={genericStyle.desktopDisplay}>
              <SearchTable
                loadingPage={false}
                onPaginationUpdate={null}
                pageNumber={0}
                data={indicesIALU?.indexes}
                totalResultSize={indicesIALU?.totalHits}
                density={"medium"}
                Header={() => <IndexHeader selectedCols={null} />}
                Row={(row) => (
                  <IndexRow
                    key={`index-ialu-${row?.id}`}
                    row={row}
                    selectedCols={null}
                  />
                )}
              />
            </div>
            <div css={genericStyle.mobileDisplay}>
              <SearchMobile
                onPaginationUpdate={null}
                pageNumber={0}
                data={indicesIALU?.indexes}
                totalResultSize={indicesIALU?.totalHits}
                Card={(card) => (
                  <IndexSearchCard
                    key={`card-index-ialu-${card?.id}`}
                    card={card}
                  />
                )}
              />
            </div>
          </Loader>
        </div>
      </div>

      <div>
        <h2>LuxSE sustainable indices</h2>
        <div css={IndexSimpleStyle.LoaderPart}>
          <Loader loading={loadingIOPC}>
            <div css={genericStyle.desktopDisplay}>
              <SearchTable
                loadingPage={false}
                onPaginationUpdate={null}
                pageNumber={0}
                data={indicesIOPC?.indexes}
                totalResultSize={indicesIOPC?.totalHits}
                density={"medium"}
                Header={() => <IndexHeader selectedCols={null} />}
                Row={(row) => (
                  <IndexRow
                    key={`index-iopc-${row?.id}`}
                    row={row}
                    selectedCols={null}
                  />
                )}
              />
            </div>
            <div css={genericStyle.mobileDisplay}>
              <SearchMobile
                onPaginationUpdate={null}
                pageNumber={0}
                data={indicesIOPC?.indexes}
                totalResultSize={indicesIOPC?.totalHits}
                Card={(card) => (
                  <IndexSearchCard
                    key={`card-index-iopc-${card?.id}`}
                    card={card}
                  />
                )}
              />
            </div>
          </Loader>
        </div>
      </div>
    </div>
  );
}

export default IndexListViewContent;
