
/*
*   e-Listing - core - components - country flag icon - nld
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const NLD: JSX.Element = (
    <React.Fragment>
        <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
            <rect y='85.337' style={{fill: '#FFF'}} width='512' height='341.326'/>
            <rect y='85.337' style={{fill: '#AE1C28'}} width='512' height='113.775'/>
            <rect y='312.888' style={{fill: '#21468B'}} width='512' height='113.775'/>
        </svg>
    </React.Fragment>
);
