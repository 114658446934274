import { css } from "@emotion/react";
import { breakpointMarketCap, breakpointMarketCapMax, breakpointMax } from "../../../style";

export const style = {

  desktopDisplay: css`
    font-family: "Mont", "Fontfabric-Notdef", sans-serif !important;
    width: 100%;
    @media (max-width: ${breakpointMarketCapMax}) {
      display: none;
    }
  `,
  mobileDisplay: css`
    font-family: "Mont", "Fontfabric-Notdef", sans-serif !important;
    width: 100%;
    @media (min-width: ${breakpointMarketCap}) {
      display: none;
    }
  `,
  marketCapTitle: css`
  width: 100%;
  @media (min-width: ${breakpointMarketCap}) {
    display: none;
  }
`,

  barChart: css`
  width: 100%;
  @media (max-width: ${breakpointMarketCapMax}) {
    display: none;
  }
  `,

  displaySettings: css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 0;

  @media (max-width: ${breakpointMax}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 8px;
    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      & > div {
        border-left: 1px solid #e3e6e8;
        height: 50px;
        font-size: 16px;
      }
      & div {
        width: 100%;
      }
    }
  }

  > div:first-of-type {
    border-right: 1px solid #e3e6e8;
  }

  svg {
    color: #22aa5f;
    margin-right: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 16px;
  }

  img {
    height: 16px;
    margin-right: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  div {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
  }
`,
  settingsText: css`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #354450;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-right: 8px;
  @media (max-width: ${breakpointMax}) {
    margin-bottom: 14px;
  }
  `,

  tableRow: css`
    border-bottom: 0.5px solid #e3e6e8;
    color: black;
    cursor: pointer;
    margin-top:10px;
    &:last-of-type {
      border-bottom: none;
    }
    td {
      border-bottom: solid 0.5px #d9dee2;
      font-size: 0.8rem;
      padding-left: 1.5rem;
      padding-right: 1rem;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      text-align:left;
    }
    :hover {
      background-color: #f4f6f7 !important;
    }
  `,

  tr:css`
    border-bottom: 1px solid #D9DEE2;
    th {
      text-align:left;
      border-bottom: solid 0.5px #d9dee2;
      font-size: 0.8rem;
      padding-left: 1.5rem;
      padding-right: 1rem;
      padding-top: 1em;
      padding-bottom: 1em;
      background-color:#F8FAFC;
      margin:0;
    }
  `,
  thead:css`
    border-radius: 8px;
    border-spacing: 0px;
  `,

  table: css`
    width:100%;
    border-spacing:0;
    border-collapse: collapse;
  `,

  marketCapLabel: css`
    font-weight: 700;
    font-size: 48px;
    font-family: Mont;
    line-height: 41px;
    text-align: center;
    color: #425563;
    margin-bottom: 5px;
    @media (max-width: ${breakpointMax}) {
      font-size: 32px;
      line-height: normal;
    }
  `,
  marketCapHeader: css`
    width: 100%;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      padding: 1em;
    }
  `,

  rowLabel: css`
    font-weight: 700;
    font-size: 45px;
    line-height: 41px;
    text-align: left;
    color: #253845;
    margin-bottom: 5px;
    margin-top: 32px;
    margin-right: 12px;

    @media (max-width: ${breakpointMax}) {
      padding-bottom: 16px;
    }
  `,
  header: css`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    //align-items: left;
    padding: 2em;
    padding-left:3em;
    @media (max-width: ${breakpointMax}) {
      padding: 0em;
    }
  `,
  marketCapContent:css`
    background: #ffffff;
    width: 80%;
    margin-left:auto;
    margin-right:auto;
    padding-top:2rem;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      padding: 0em;
      width:95%;
    }
  `,
  mainContent: css`
    background: #ffffff;
    border: solid 1px #e3e6e8;
    border-radius: 8px;
    min-height: 100%;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    box-shadow: 0 8px 16px rgb(164 188 194 / 31%);
    @media (max-width: ${breakpointMax}) {
      padding: 0em;
      border : 0;
      border-radius: 0px;
      box-shadow: none;
    }
  `,
  container: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    min-height: 100%;
    padding: 1em;
    @media (max-width: ${breakpointMax}) {
      padding: 0em;
    }
  `,

  main: css`
    display:flex;
    flex-direction: row;
  `,
  containerTab: css`
    > div:first-child > div:first-child {
      justify-content: center;
      @media (max-width: ${breakpointMax}) {
        display: flex;
        flex-direction: column;
      }
    }

    > div:first-child > div:first-child > div {
      padding: 15px;
      @media (max-width: ${breakpointMax}) {
        text-align: center;
      }
    }
  `,
  left: css`
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-self: center;
    @media (max-width: ${breakpointMax}) {
      align-items: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      padding-right: 0;
    }
  `,
  right: css`
    width: 50%;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
    }
  `,
  bloc: css`
    max-width: calc(50% - 32px);
    display: inline-flex;
    flex-direction: column;
    margin-right: 16px;
    margin-bottom: 32px;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      max-width: 100%;
      margin-left: 45px;
      margin-right: 45px;
      margin-bottom: 60px;
      align-items: center;
    }
  `,
  title: css`
    color: #253645;
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    width: 100%;
    svg {
      margin-right: 8px;
    }
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 16px;
      margin-top: 16px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  `,
  icon: css`
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    @media (max-width: ${breakpointMax}) {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      align-self: unset;
    }
  `,
  hexa: css`
    opacity: 0.3;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  hoverIcon: css`
    position: absolute;
    color: #cb1234;
    @media (max-width: ${breakpointMax}) {
      margin: 0px;
    }
  `,
  description: css`
    color: #354450;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.01em;
    margin-top: 4px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  `,
  top: css`
    margin-bottom: 32px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      margin-top: 5px;
    }
  `,
  bottom: css`
    color: #354450;
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    text-align: right;
    margin: 10px 100px 0px 32px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0px;
    }
  `,
  content: css`
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    @media (max-width: ${breakpointMax}) {
      margin-left: auto;
      margin-right: auto;
    }
  `,
  contentBody: css`
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    display: inline-flex;
    align-self: center;
    align-items: center;
    display: flex;
    justify-content: center;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }w
  `,
  contentBodyList: css`
    display: block;
  `,
  contentButton: css`
    width: 100%;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;

    button {
      margin: auto;
    }
  `,
  action: css`
    margin-top: 32px;
  `,
  card: css`
    background: #ffffff;
    border: solid 1px #e3e6e8;
    border-radius: 8px;
    min-height: 100%;
    width: 80%;
    box-shadow: 0 8px 16px rgb(164 188 194 / 31%);
    //position: relative;
    //display: flex;
    //padding-bottom: 16px;
  `,
  pagination: css`
  width: 100%;
  padding: 1em;

  @media (max-width: ${breakpointMax}) {
  ul {
      columns: 2;
      padding: 0;
      display: block !important;
      li {
      margin-bottom: 5px;
      }
  }
  }
`,
  cardContent: css`
    line-height: 26px;
    color: #354450;
    text-align: left;
    font-size: 16px;

    position: relative;
    display: flex!important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  `,
  cardTitle: css`    
    color: #22AA5F;
    font-weight: 800;
  `,
  cardDate: css`
    margin-bottom: 15px;
  `,
  cardName: css`
    font-weight: 800;
  `,
  cardSubName: css`
    font-size: 14px;
    line-height: 16px;
    color: #657f93;
  `,
  cardArrow: css`
    position: relative;
    display: flex!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;

    font-size: 25px;
    line-height: 25px;
    color: #657f93;
  `,

  emptyResult: css`
    width: 100%;

    > div {
      margin-left: auto;
      margin-right: auto;
      margin-top: 1em;
      margin-bottom: 1em;
      @media (max-width: ${breakpointMax}) {
        width: 100%;
      }
    }
  `,
  row: css`
    display: flex;
    width: 100%;
    padding: 1.5em 2em;
    text-align: left;
    font-size: 0.813em;
    border-bottom: 1px solid rgb(217, 222, 226);

    &:first-child {
      border-top: 1px solid rgb(217, 222, 226);
    }
    transition: all 0.5s ease;

    text-decoration: none;
    color: rgb(101, 127, 147);
    @media (max-width: ${breakpointMax}) {
      padding: 15px;
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  `,
  rowClickable: css`
    cursor: pointer;
    &:hover{
      background: rgb(248, 250, 252);
    }
  `,
  rowIcon: css`
    width: 10%;

    position: relative;
    display: flex!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  rowIconRound: css`
    padding: 2px;
    margin-top: -6px;

    width: 30px;
    height: 30px;
    align-items: center;
    display: flex;
    border-radius: 50px;
    border: solid 2px red;
    justify-content: center;
    border: 2px solid red;
    color: rgb(66, 85, 99);

    svg {
      font-size: 16px;
    }
  `,
  rowContent: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    @media (max-width: ${breakpointMax}) {
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }
  `,
  rowHeader: css`
    width: 65%;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin-bottom: 10px;
    }
  `,
  rowTitle: css`
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: rgb(101, 127, 147);
  `,
  rowSubTitle: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgb(101, 127, 147);
  `,
  rowDate: css`
    width: 35%;
    color: rgb(101, 127, 147);
    font-size: 14px;
    line-height: 20px;

    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
}