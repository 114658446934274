import { css } from "@emotion/react";
export const style = {
  container: css`
    width: 100%;
    min-height: 100px;
    position: relative;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin: auto;
    margin-top: 96px;
  `,
  title: css`
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    text-align: center;
    color: #354450;
  `,
  partners: css`
    display: flex;
    width: 100%;
    margin: 32px auto auto;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
  `,
  partnerLogo: css`
    display: flex;
    justify-content: center;
    min-width: 220px;
    margin-top: 16px;
    margin-bottom: 16px;

    svg {
      margin-right: 16px;
    }
  `,
  partner: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `,
  actions: css`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 56px;
  `,
};
