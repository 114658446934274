/** @jsx jsx */

import { Icon, Menu, MenuWithChild, ToggleButton } from '@bdl-cmn-shared-packages-npm/design-system';
import { breakpoint, breakpointMax } from '../../../../../style';
import { css, jsx } from '@emotion/react';
import { programmeEndDateLabel, programmeEndDateSort } from '../../../../../enums/programme-end-date';
import { programmeStartDateLabel, programmeStartDateSort } from '../../../../../enums/programme-start-date';

import CountFilter from '../../../../../interfaces/search/search-filter-count';
import CountItem from '../../../Search/results/filters/count-item';
import SearchFilterParams from '../../../../../interfaces/search/search-filter-params';
import SearchProgrammeFilters from '../../../../../interfaces/search/search-programme-filters';
import { programmeTypeLabel } from '../../../../../enums/programme-type';
import { style } from '../../../Search/results/filters/index.style';
import { useState } from 'react';

export interface ProgrammeFiltersProps {
  filterCount?: SearchProgrammeFilters;
  filters?: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  fullWidth?: boolean;
}

const toggleCss = css`
  align-items: flex-start;
  -webkit-align-items: flex-start;
`;

function computeValues(values: CountFilter[], selection?: any): CountFilter[] {
  if (!selection || (values && !!values.length)) {
    return values;
  }
  return [{ name: selection, count: -1 }];
}

export function ProgrammeFilters({ filterCount, filters, onFiltersChange, fullWidth }: ProgrammeFiltersProps) {
  const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    let found = false;
    let target = event.target as Element;
    let parent = event.currentTarget;
    let head = parent.firstChild.firstChild;

    do {
      if (head == target) found = true;

      target = target.parentElement;
    } while (!found && parent != target && target != null);

    if (found) parent.classList.toggle('open');
  };

  const handleFilterChange = (key: keyof SearchFilterParams, value: any): void => {
    let newFilters: SearchFilterParams = { ...filters };

    if (newFilters[key] instanceof Array && filters[key] instanceof Array) {
      try {
        // @ts-ignore
        let array: any[] = filters[key] != null ? Object.assign([], filters[key]) : [];
        if (array != null && array.includes(value))
          array = array.filter((v) => {
            return v !== value;
          });
        else array.push(value);
        array = array.filter((v) => v);
        if (array == null || !array.length) delete newFilters[key];
        else {
          // @ts-ignore
          newFilters[key] = array;
        }
      } catch (e) {
        console.error('Failed to updated filter[' + key + ']', filters[key], ' with value', value, e);
      }
    } else {
      if (filters[key] === value) {
        delete newFilters[key];
      } else {
        // @ts-ignore
        newFilters[key] = value;
      }
    }
    onFiltersChange(newFilters);
  };

  const closeBtn = css`
    width: 38px;
    height: 38px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    svg {
      color: #22aa5f;
      width: 25px !important;
      height: 25px !important;
      margin: 0px !important;
    }
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `;

  const [open, setOpen] = useState(true);

  return (
    <div
      css={[
        fullWidth ? style.searchResultsFiltersFullWidth : style.searchResultsFilters,
        css`
          position: relative;
          transition: all 0.1s ease-in-out;
          margin-left: ${open ? '0' : '-17%'};
          padding-right: ${open ? '16px' : '60px'};
        `,
      ]}
    >
      <div css={closeBtn} onClick={() => setOpen(!open)}>
        <Icon icon={open ? 'arrow-left' : 'arrow-right'}></Icon>
      </div>
      <div css={style.title}>Filter your search result</div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild icon='folder-tree' label='Types' width='100%;' expanded={true} arrows={false}>
          {computeValues(filterCount.programmeTypes, filters.programmeTypes) &&
            computeValues(filterCount.programmeTypes, filters.programmeTypes)
              .sort((first, second) => second.count - first.count)
              .map((item) => {
                if (item.count === -1) return <div css={style.menuNoItem}>No filter available</div>;

                return (
                  <div css={[style.menuItem, style.menuItemRow]}>
                    <Menu
                      key={item.name}
                      width='100%;'
                      selected={filters.programmeTypes && filters.programmeTypes.includes(item.name)}
                      onSelect={() => handleFilterChange('programmeTypes', item.name)}
                      childLabel={
                        (<CountItem name={programmeTypeLabel(item.name)} count={item.count} />) as unknown as string
                      }
                    />
                  </div>
                );
              })}
        </MenuWithChild>
      </div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild icon='calendar' label='Date' width='100%;' expanded={false} arrows={false}>
          <div css={[style.subRow, style.filterMenu]} onClick={handleFilterMenuClick}>
            <MenuWithChild icon='calendar' label='Start date' width='100%;' expanded={false} arrows={false}>
              {computeValues(filterCount && filterCount.startDateRanges, filters.programmeStartDate) &&
                computeValues(filterCount && filterCount.startDateRanges, filters.programmeStartDate)
                  .sort((first, second) => programmeStartDateSort(first.name, second.name))
                  .map((item) => {
                    if (item.count === -1) return <div css={style.menuNoItem}>No filter available</div>;

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={item.name}
                          width='100%;'
                          selected={filters.programmeStartDate === item.name}
                          onSelect={() => handleFilterChange('programmeStartDate', item.name)}
                          childLabel={
                            (
                              <CountItem name={programmeStartDateLabel(item.name)} count={item.count} />
                            ) as unknown as string
                          }
                        />
                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>

          <div css={[style.subRow, style.filterMenu]} onClick={handleFilterMenuClick}>
            <MenuWithChild icon='calendar' label='End date' width='100%;' expanded={false} arrows={false}>
              {computeValues(filterCount && filterCount.endDateRanges, filters.programmeEndDate) &&
                computeValues(filterCount && filterCount.endDateRanges, filters.programmeEndDate)
                  .sort((first, second) => programmeEndDateSort(first.name, second.name))
                  .map((item) => {
                    if (item.count === -1) return <div css={style.menuNoItem}>No filter available</div>;

                    return (
                      <div css={style.menuItem}>
                        <Menu
                          key={item.name}
                          width='100%;'
                          selected={filters.programmeEndDate === item.name}
                          onSelect={() => handleFilterChange('programmeEndDate', item.name)}
                          childLabel={
                            (
                              <CountItem name={programmeEndDateLabel(item.name)} count={item.count} />
                            ) as unknown as string
                          }
                        />
                      </div>
                    );
                  })}
            </MenuWithChild>
          </div>
        </MenuWithChild>
      </div>
    </div>
  );
}
