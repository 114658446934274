import * as React from 'react';

import { ComponentParams, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import {breakpoint, breakpointMax} from "../../../style";
import { css, jsx } from "@emotion/react";

import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';

/** @jsx jsx */




export interface HowToListCardProps {
    fields: any;
    data: any;
    label: string;
    title: string;
    disabled?: boolean;
    selected?: number;
    index: number;
    params?: ComponentParams;
    rendering: ComponentRendering;
    onSelect: (obj:any, index: number) => void;
}

const parentContainer = css`
  background: #F6F8F9;
  box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
  width: 100%;
  display: flex;
  flex-direction: row;
  //opacity: .4;
  align-items: center;
  margin-bottom: 80px;
  @media (max-width: ${breakpointMax}) {
    border: 0;
    background: #F6F8F9;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #22AA5F;
    margin-left: 8px;
    margin-right: 8px;
    @media (max-width: ${breakpointMax}) {
      font-size: 12px;
      word-break: break-all;
      line-height: 150%;
      flex-wrap: wrap;
    }
  }
`;
const container = css`
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 32px;
  display: inline-block;
  color: #354450;
  font-size: 24px;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
    font-size: 12px;
  }
`;
const titleLabel = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
  margin-right: 8px;
  flex: none;

  @media (max-width: ${breakpointMax}) {
    font-size: 12px;
    flex-wrap: wrap;
  }
`;
const preLabel = css`
  flex: none;
  @media (max-width: ${breakpointMax}) {
    font-size: 12px;
    flex-wrap: wrap;
  }
`;
const label = css`
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
  display: flex;
  align-items: center;
  padding-right: 30px;
  @media (max-width: ${breakpointMax}) {
    font-size: 12px;
    padding-right: 0;
    flex-wrap: wrap;
  }
`;

export const HowToListBanner : React.FunctionComponent<any> = (props: HowToListCardProps): React.ReactElement => {

    const { data, onSelect, selected, disabled, title, index } = props;

    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={titleLabel}>
                    Your choice:
                </div>
                Market - <span>{ data && data[0] && data[0].headerLabel }</span>
                / Issuer - <span>{ data && data[1] && data[1].headerLabel }</span>
                / Product - <span>{ data && data[2] && data[2].headerLabel }</span>
            </div>
        </div>
    );
}
