/** @jsx jsx */
/* eslint-disable */
import { Button, Grid, Pagination, Table, Tbody, Th, Thead, ToggleButtonGroup, ToggleItem, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import React, { Component, useEffect, useState } from "react";
import { style } from "../market-statistics.style";
import Loader from "../../Loader/loader.component";
import GenericMarketCapDataRow from "../Base/GenericMarketCapDataRow";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import MarketCapCard from "../Cards/MarketCapCard";
import EmptyResults from "../../UI/list/base/empty";
import { MarketCapPaginationModel } from "../market-capitalisation-view.component";
import { css, jsx } from "@emotion/react";
import { sharedStyle } from "../../../../styles/shared";
import SortIcon from "../Base/SortIcon";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { GET_MARKET_CAPITALISATION_LUXX_COMPONENTS } from "../../../../graphql/queries/market.queries";
import { MarketCapLuxComponents } from "../Home/market-cap-home";


export function LuxXComponentView(props: any) {

    //LuxX Component
    const [luxComponentData, setLuxComponentData] = useState<MarketCapLuxComponents>({
        totalMarketCap:0,
        listMarketCap: []
    });

    const columns = [
        {label:"Type", accessor:"type"},
        { label: "ISIN", accessor: "isinCode" },
        { label: "Name", accessor: "description" },
        { label: "Currency", accessor: "currency" },
        { label: "Market", accessor: "market" },
        { label: "Price", accessor: "price" },
        { label: "Issued shares", accessor: "nbSecuritiesIssued" },
        { label: "Market cap", accessor: "marketCap" },
    ];
    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("asc");

    const [dataLoading, setDataLoading] = useState<boolean>(true);

    let [totalIssuedShares,setTotalIssuedShares]=useState<Number>(0);
    let [totalMarketCap,setTotalMarketCap]=useState<Number>(0);

    const client = luxseGatewayGraphqlClient;

    const fetchLuxXComponentData = async () => {
        try {
          if( !dataLoading)
            setDataLoading(true);
            luxComponentData.listMarketCap = [];
          const results: any = await client.query({
            query: GET_MARKET_CAPITALISATION_LUXX_COMPONENTS,
          });

          totalMarketCap=results?.data?.marketCapitalisationForLuxXComponents?.totalMarketCap;
    
          results?.data?.marketCapitalisationForLuxXComponents?.listMarketCap?.forEach((element: any) => {
            luxComponentData.listMarketCap.push(element);
            totalIssuedShares=totalIssuedShares+element.nbSecuritiesIssued;
            });
            setLuxComponentData(luxComponentData);
            setTotalIssuedShares(totalIssuedShares);
            setTotalMarketCap(totalMarketCap);

            setDataLoading(false);
        } catch (e) {
            console.error('Failed to get luxX component data', e);
            setDataLoading(false);
        }
      }

    const handleSortingChange = (accessor:any) => {
        const sortOrder =
        accessor === sortField && order === "asc" ? "desc" : "asc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };

    const handleSorting = (sortField:any, sortOrder:any) => {
        if(sortField){
            if (sortField === "price" || sortField === "nbSecuritiesIssued" || sortField === "marketCap") {
                luxComponentData.listMarketCap.sort((a:any, b:any) => {
                 return (
                  (Number(a[sortField])-Number(b[sortField])) * (sortOrder === "asc" ? 1 : -1)
                 );
                });
            }else{
                luxComponentData.listMarketCap.sort((a:any, b:any) => {
                    return (
                     a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                      numeric: true,
                     }) * (sortOrder === "asc" ? 1 : -1)
                    );
                   });
            }
            setLuxComponentData({...luxComponentData});
        }
    };


    useEffect(() => {
        fetchLuxXComponentData();
    }, []);


    return (
        <>
            <div>
                <div css={style.header}>
                    <h1 css={style.rowLabel}>LuxX Components</h1>
                </div>
                <div css={style.container}>
                    <Loader loading={dataLoading}>
                        {luxComponentData && luxComponentData?.listMarketCap?.length > 0 && !dataLoading?
                            <>
                                <div css={[style.desktopDisplay]}>
                                    <table css={style.table}>
                                        <thead css={style.thead}>
                                            <tr css={style.tr}>
                                            { columns.map(({ label, accessor }) => {
                                                return accessor.replace(',','') === "type" ? <th key={accessor}>
                                                    {label}
                                                </th>
                                                    :
                                                    <th key={accessor} onClick={() => handleSortingChange(accessor)} style={{ textAlign: label === "Price" || label === "Issued shares" || label === "Market cap" ? 'right' : 'left' }}>
                                                        {label}
                                                        <SortIcon />
                                                    </th>;
                                            })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <GenericMarketCapDataRow rowsData={luxComponentData.listMarketCap} columns={columns}/>
                                        </tbody>
                                        <tfoot>
                                            <tr css={[style.tableRow,css`background-color:#F0F5F5;`]}>
                                                <td>Total</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <p css={css`text-align:right;`}>{Number(totalIssuedShares).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, ' ')}</p>
                                                </td>
                                                <td>
                                                    <p css={css`font-weight:bold; text-align:right;`}>{Number(totalMarketCap).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, ' ')}</p>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div css={style.mobileDisplay}>
                                    <SearchMobile
                                        onPaginationUpdate={(d: any) => {
                                        }}
                                        pageNumber={0}
                                        data={luxComponentData?.listMarketCap}
                                        totalResultSize={10}
                                        Card={(card) => <MarketCapCard card={card} />}
                                    />
                                </div>
                            </>
                            :
                            <div>
                                <EmptyResults />
                            </div>
                        }
                    </Loader>
                </div>
            </div>
        </>
    );
}

export default LuxXComponentView;