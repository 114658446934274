
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useReadingTime } from "react-reading-time-estimator";
import { SitecoreGenericTextField } from '../../interfaces';
import {fetchData, fetchFullData} from "../../utils/helper";
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { useEffect, useState } from "react";

export interface BLogArticleProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  `;

const indicatorText = css`
  margin-top: 20px;
  font-family: 'Mont',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #425563;
`;

const renderChild = (data: any) => {
    return (
        <div>
            {data.ItemName}
            {
                data.child && renderChild(data.child)
            }
        </div>
    )
}

export const BLogArticle: React.FunctionComponent<BLogArticleProps> = (props: BLogArticleProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const [response, setResponse] = useState<any>(null);
    /* dev test */
    useEffect(() => {
        const data = fetchFullData('DCA687E3-19BD-4D68-9D55-0DE7F212EBDE').then((response: any) => {
            setResponse(response?.data);
        })
    }, [rendering])

    const textContent: string = getFieldValue<string>(fields, 'Content', '');

    const regex = /(<([^>]+)>)/ig;
    const newText = textContent.replace(regex, '');

    const {
        text, // 1 min read
        minutes, // 1
        words, // 168
        time, // 0.5309090909090909
    } = useReadingTime(newText);

    return (
        <div css={container}>
            <div css={indicatorText}>
                <Icon icon={'clock'}/> {text}
            </div>
            <Text
                tag={'h1'}
                field={fields && fields['Title'] as SitecoreGenericTextField}
            />
            <RichText
                field={fields && fields['Content'] as SitecoreGenericTextField}
            />
        </div>
    );
}
