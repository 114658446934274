import { breakpointMax } from "../../../../style";

import { css } from "@emotion/react";

export const style = {
  container: css`
    background-color: rgb(26, 40, 52);
    color: white;
    display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 20%;
    max-width: 20%;
    position: relative;
    padding: 16px;
    transition: all 0.1s ease-in-out 0s;

    @media (max-width: ${breakpointMax}) {
      width: 100%;
      height: 100vh;
      overflow: auto;
    }
  `,
  parentMenu: css`
    > div {
      border: 1px solid rgb(164, 182, 194);
      border-radius: 6px;
      background-color: rgb(26, 40, 52);
      color: white;
    }
  `,
  title: css`
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 8px;
  `,
  row: css`
    width: 100%;
    margin-bottom: 16px;

    > div {
      border: 1px solid #a4b6c2;
      border-radius: 6px;
      background-color: #1a2834;
      color: white;
    }
  `,
  subRow: css`
    width: 100%;
    margin-bottom: 16px;

    > div {
      border: 1px solid #253845;
      border-radius: 6px;
      background-color: #253845;
      color: white;
    }
  `,
  toggleRow: css`
    width: 100%;
    display: flex;

    margin-bottom: 16px;
    border: 1px solid #253845;
    border-radius: 6px;
    background-color: #253845;

    color: white;
    box-shadow: 0 8px 8px rgb(0 0 0 / 15%);
    border-radius: 0.5rem;
    padding: 0.5rem;
  `,
  toggleRowNoBorder: css`
    box-shadow: none;
  `,
  toggleTitle: css`
    width: 80%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-size: 1rem;
  `,
  toggleIcon: css`
    width: 30px;

    svg {
      color: #a4bcc2 !important;
    }
  `,
  toggleLabel: css`
    width: 80%;
  `,
  toggleCount: css`
    width: 20%;
    font-size: 0.875rem;
    text-align: right;
  `,
  toggleButton: css`
    width: 20%;
    padding: 0.5rem;
    cursor: pointer;
  `,
  filterMenu: css`
    > div:first-child {
      position: relative;

      > div:first-child {
        width: -webkit-calc(100% - 0.4em);
        width: -moz-calc(100% - 0.4em);
        width: calc(100% - 0.4em);
      }

      > div:first-child:after {
        position: absolute;
        right: 0.5em;
        top: 0.4em;
        font-size: 1.5rem;
        color: #22aa5f;
        content: "\\203A";
        transition: transform 0.15s ease-out;
      }
    }

    cursor: pointer;

    &&.open > div:first-child > div:first-child:after {
      transform: rotate(90deg);
    }
  `,
  menuItem: css`
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;

    > div {
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      > div {
        width: 100%;
      }
    }

    svg {
      color: #a4bcc2 !important;
    }
  `,
  menuNoItem: css`
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-style: italic;
    font-size: 0.875rem;
  `,
  menuItemRow: css`
    width: 100%;

    > div:hover {
      background: #253845;
    }
  `,
};
