
/*
*   e-Listing - core - components - country flag icon - mlt
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const MLT: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.331' style={{fill: '#F0F0F0'}} width='512' height='341.326'/>
        <rect x='256' y='85.331' style={{fill: '#D80027'}} width='256' height='341.337'/>
        <polygon style={{fill: '#ACABB1'}} points='208.231,138.671 208.231,117.337 186.899,117.337 186.899,138.671 165.565,138.671   165.565,160.003 186.899,160.003 186.899,181.337 208.231,181.337 208.231,160.003 229.565,160.003 229.565,138.671 '/>
    </svg>
);
