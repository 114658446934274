/** @jsx jsx */
import { css } from "@emotion/react";

export const style = {
  container: css`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    > div {
      > div {
        > div {
          > div {
            > div  {
              > div {
              height: auto!important;
              }
            }
          }
        }  
      }
    }
  }`,
  searchLoading: css`
    padding: 5em;
    min-height: 50vh;
    svg {
      max-width: 180px !important;
      width: 180px !important;
      max-height: 180px !important;
      height: 180px !important;
    }
  `,

  searchResultsData: css`
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    background-color: #F4F6F7;
  `
};
