
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import {
    Text,
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    getFieldValue,
    isExperienceEditorActive,
    RichText
} from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';

export interface FreeTextComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const FreeTextComponent: React.FunctionComponent<FreeTextComponentProps> = (props: FreeTextComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const isExperienceEditorActivated: boolean = isExperienceEditorActive();
    const fContent: string = getFieldValue<string>(fields, 'content', '');
    const isCentered: number = getFieldValue<number>(fields, 'isCentered', 0);

    const container = css`
      width: 100%;
      max-width: ${isCentered ? '1200px' : '100%'};
      margin-left: auto;
      margin-right: auto;
    `;

    return (
        <div css={container}>
            {
                fContent.length > 0 || isExperienceEditorActivated ? (
                    <RichText
                        field={fields && fields.content as SitecoreGenericTextField}
                    />
                ) : null
            }
        </div>
    );
}
