import { breakpoint, breakpointMax } from "../../style";

import background from './background.svg';
import {css} from "@emotion/react";

export const style = {
    parentContainer: css`
      width: 100%;
    `,
    topContainer: css`
      width: 100%;
      min-height: 240px;
      background: url(${background});
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
    `,
    topContainerChild: css`
      width: 100%;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
    `,
    topContainerChildLeft: css`
      width: 120px;
      min-width: 120px;
      height: 120px;
      min-height: 120px;
      background: #FFFFFF;
      border-radius: 8px;
      margin-right: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      
      img {
        width: 100%;
      }
    `,
    topContainerChildRight: css`
      width: calc(100% - 152px);
      display: flex;
      flex-direction: column;
    `,
    topContainerChildRightTitle: css`
      width: calc(100% - 152px);
      display: flex;
      flex-direction: column;
      font-weight: 700;
      font-size: 48px;
      line-height: 61px;
      color: #FFFFFF;
      @media (max-width: ${breakpointMax}) {
        font-size: 28px;
        width: auto;
        line-height: 40px;
      }
    `,
    topContainerChildRightDescription: css`
      width: calc(100% - 152px);
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      @media (max-width: ${breakpointMax}) {
        font-size: 14px;
        width: auto;
        line-height: 18px;
      }
    `,
    container: css`
      width: 100%;
      max-width: 1280px;
      margin: auto;
      margin-bottom: 96px;
      display: flex;
      padding-left: 40px;
      padding-right: 40px;
      @media (max-width: ${breakpointMax}) {
        flex-direction: column;
      }
    `,
    left: css`
     width: 100%;
      @media (max-width: ${breakpointMax}) {
        width: 100%;
      }
    `,
    right: css`
     width: 35%;
      padding-left: 24px;
      padding-right: 24px;
      max-width: 300px;
      margin: auto;
      @media (max-width: ${breakpointMax}) {
        width: 100%;
        margin-top: 40px;
        max-width: 100%;
      }
    `,
    action: css`
    margin-top: 48px;
    `,
    title: css`
     font-weight: 800;
      color: #354450;
      font-size: 20px;
      line-height: 26px;
      margin-top: 16px;
      margin-bottom: 24px;
    `,
    text: css`
      margin-top: 38px;
     color: #354450;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    `,
    icon: css`
      margin-top: 12px;
    `,
    contactSection: css`
    width: 100%;
    max-width: 1280px;
    margin: auto;
    margin-bottom: 0px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      width: calc(215% - 120px);
    }
    `,
    contactSectionTitle: css`
    font-weight: 800;
    color: #354450;
    font-size: 20px;
    line-height: 26px;
    margin-top: 36px;
    margin-bottom: 10px;
    `,
    contactName: css`
    margin-top: 38px;
    color: #354450;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    `,
    contactDescription: css`
    margin-top: 10px;
    color: #354450;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    `,
    contactColumnOne: css`
      width: 30%;
      flex: 1;
      @media (max-width: ${breakpointMax}) {
        flex-direction: column;
    }
    `,
    contactColumnTwo: css`
      width: 30%;
      flex: 1;
      @media (max-width: ${breakpointMax}) {
        flex-direction: column;
    }
    `,
    contactColumnThree: css`
      width: 30%;
      flex: 1;
      @media (max-width: ${breakpointMax}) {
        flex-direction: column;
    }
    `,
    textContact: css`
      margin-top: 12px;
     color: #354450;
      font-weight: 600;
      font-size: 16px;
      a {
        color: #22AA5F;
        :hover {
          color: #1A844A;
          text-decoration: none;
        }
      }  
    `,
}