
/*
*   e-Listing - core - components - country flag icon - dnk
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const DNK: JSX.Element = (
    <React.Fragment>
        <svg viewBox="0 0 37 28">
            <path fill="#c8102e" d="M0,0H37V28H0Z"/>
            <path fill="#fff" d="M0,12H12V0H16V12H37V16H16V28H12V16H0Z"/>
        </svg>
    </React.Fragment>
)
