/** @jsx jsx */

import MarketCapDataModel from "./Interfaces/MarketCapModel";
import React, { useEffect, useState } from "react";
import { style } from "./market-statistics.style";
import LuxSharesView from "./View/lux-shares-view";
import LuxXComponentView from "./View/luxx-component-view";

import { css, jsx } from "@emotion/react";
import { RisersAndFallersHome } from "./Home/risers-fallers-home";
import { Button, Loader } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpointMax } from "../../../style";
import TopTradedSecuritiesHome from "./Home/top-traded-securities";
import { sharedStyle } from "../../../styles/shared";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import { GET_LUXSE_REPORTS } from "../../../graphql/queries/luxse-reports.queries";
import { LuxseReport } from "../../../interfaces/luxse-report";

export class MarketCapPaginationModel {
  allData?: MarketCapDataModel[];
  displayData?: MarketCapDataModel[];
  totalHits?: number;
}

export function MarketCapitalisationView(props: any) {

  const client = luxseGatewayGraphqlClient;
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const fetchPdfData = async () => {
    let report : LuxseReport = null;
    try {
      if (!dataLoading)
        setDataLoading(true);

      const results: any = await client.query({
        query: GET_LUXSE_REPORTS,
        variables: {
          query: "",
          usePageable: true,
          page: 0,
          size: 1,
          types: ["MARKET_STATISTICS"],
          subTypes: ["MARKET_CAP"],
          sorts: ["date,DESC"]
        },
      });
      if( results?.data?.luxseReports?.resultSize > 0){
        report=results?.data?.luxseReports?.resultList[0];
      }

      downloadFile(report);
      setDataLoading(false);
    } catch (e) {
      console.error('Failed to get lux components data', e);
      setDataLoading(false);
    }
  }
  const cssStyle = {
    displayOtherReports: css`
        padding:160px 113px; 
        background: var(--ligt-grey, #F6F8F9);
        width:100%;
        @media (max-width: ${breakpointMax}) {
          padding-left: 0;
          padding-bottom: 0;
          padding-right: 0;
          padding-top: 40px;
        }
      `,
    titleContainer: css`
    width: 100%;
    max-width: 1280px;
    //padding-left: 40px;
    //padding-right: 40px;
    margin-right: auto;
    margin-bottom: 0px;
    padding-bottom:0px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    }
`,
    parentContainer: css`
      width:85%;

      padding-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      margin: auto;
      padding-top:80px;

      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
`,
    mainTitle: css`
      font-weight: 700;
      font-size: 48px;
      margin-bottom: 30px;
      text-align: center;
      line-height: 61px;
      color: #425563;
      width: 100%;

      text-align: left;
      @media (max-width: ${breakpointMax}) {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 24px;

      text-align: left;
      }
    `,
    mainDescription: css`
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      color: #354450;
      margin-bottom: 34px;
      @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.01em;
      }
    `,
    loaderWrapper: css`
      position:absolute;
      animation: spin 2s linear infinite;
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `,
  };

  function downloadFile(report: LuxseReport) {
    if(report != null){
      const downloadLink = document.createElement("a");

      downloadLink.href = report.downloadUrl;
      downloadLink.download = report.name;
      downloadLink.click();
    }
  }

  return (
    <>
      <div css={cssStyle.parentContainer}>
        <div css={cssStyle.titleContainer}>
          <div css={cssStyle.mainTitle}>
            Market Capitalisation
          </div>
          <div css={cssStyle.mainDescription}>
            Market capitalisation – also known as market cap – is the total monetary amount of a company’s shares which indicates the total worth of the company.
          </div>
        </div>
        <div css={[{ marginTop: "0px" },dataLoading?cssStyle.loaderWrapper:'',]}>
        <Loader loading={dataLoading}>
          {!dataLoading ?
            <Button
              onClick={(e) => fetchPdfData()}
              wired={true}
              iconLeft="file-pdf"
              label={`Market Capitalisation reports`}
              css={css`margin-right:auto;`}
            />:<></>
          }
        </Loader>
        </div>
      </div>
      <div css={style.mainContent}>
        <LuxSharesView />
        <LuxXComponentView />
      </div>

      <div css={[style.marketCapContent, cssStyle.displayOtherReports]}>
        <div css={[sharedStyle.row]}>
          <RisersAndFallersHome isDisplayed={false} />
          <TopTradedSecuritiesHome isDisplayed={false} />
        </div>
      </div>
    </>

  )
}

export default MarketCapitalisationView;