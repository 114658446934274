
/*
*   e-Listing - core - components - country flag icon - rou
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const ROU: JSX.Element = (
    <React.Fragment>
        <svg version='1.1' id='Roumania_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
            <rect y='85.331' style={{fill: '#FCD116'}} width='512' height='341.326'/>
            <rect y='85.331' style={{fill: '#002B7F'}} width='170.663' height='341.337'/>
            <rect x='341.337' y='85.331' style={{fill: '#CE1126'}} width='170.663' height='341.337'/>
        </svg>
    </React.Fragment>
);