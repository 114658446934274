import * as React from 'react';

const ChartOptions: any = {
  chart: {
    type: 'area',
    animations: { enabled: false },
    parentHeightOffset: -15,
    toolbar: { show: false },
    width: '100%',
    height: '100%',
  },
  stroke: { colors: ['#22AA5F'], width: 1.8 },
  dataLabels: { enabled: false },
  grid: {
    show: false,
    padding: {
      left: 0,
      right: 0,
    },
  },
  crosshairs: { show: false },
  legend: { show: false },
  tooltip: { enabled: false },
  fill: {
    type: 'gradient',
    colors: ['#22AA5F'],
  },
  xaxis: {
    labels: { show: false },
    axisBorder: { show: false },
    axisTicks: { show: false },
  },
  yaxis: {
    labels: { show: false },
    axisBorder: { show: false },
    axisTicks: { show: false },
  },
};

export default ChartOptions;
