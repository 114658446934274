/** @jsx jsx */

import * as React from "react";

import { css, jsx } from "@emotion/react";

import { Component } from "react";
import { formatDate } from "../../../../utils/date";
import { goToSecurityDetailPage } from "../../SecurityCard";
import { marketNewsStyle } from "../MarketNews/index.style";
import PriceColumn from "../../UI/list/security/price";
import { AskColumn, BidColumn } from "../../UI/list/security/best-order";
import SearchSecurity from "../../../../interfaces/search/search-security";
import { computeCategoryTitle } from "../../UI/list/security/row";

export const TradingNewsBBOCard = ({row}: {row: SearchSecurity}) => {
  if(!row)
      return <></>;
  return (
    <div
      css={{ width: "100%" }}
      onClick={() => goToSecurityDetailPage(row.isinCode, row?.id)}
    >
      <div
        css={css`
          background: #ffffff;
          border: 1px solid #d9dee2;
          border-radius: 8px;
          filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
          margin-bottom: 16px;
        `}
      >
        { row?.category ?
            <div  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    border-bottom: solid 1px #d9dee2;
                    padding: 16px;
                    flex-wrap: wrap;
                  `}>
              <div css={css`
                  display: flex;
                  gap: 8px;
                `}>
                <div css={marketNewsStyle.tag}>{ computeCategoryTitle(row?.category)}</div>
              </div>
            </div>
            : <></>
        }
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            ISIN
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            {row?.isinCode}
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            Security
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            {row?.name}
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            Currency
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            <div>{row?.currency}</div>
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            Listing date
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            {formatDate(row?.listingDate)}
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            Last
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            <PriceColumn {...row?.marketData?.lastPrice} showChange={true} showDate={false}/>
          </div>
        </div>
        <div
          css={css`
            border-bottom: solid 1px #d9dee2;
            display: flex;
          `}
        >
          <div
            css={css`
              width: 50%;
              border-right: solid 1px #d9dee2;
              padding: 16px;
            `}
          >
            <div
              css={css`
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #425563;
              `}
            >
              Bid
            </div>
            <div
              css={css`
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #54666f;
                margin-top: 8px;
              `}
            >
              <BidColumn bestOrders={row?.marketRealtime?.bestOrders} />
            </div>
          </div>
          <div
            css={css`
              width: 50%;
              padding: 16px;
            `}
          >
            <div
              css={css`
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #425563;
              `}
            >
              Ask
            </div>
            <div
              css={css`
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #54666f;
                margin-top: 8px;
              `}
            >
              <AskColumn bestOrders={row?.marketRealtime?.bestOrders} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TradingNewsBBOCard;
