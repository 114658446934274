import * as React from "react";

import { worldmap } from "./worldmap";
import { getIconSVGDraw } from "./draw";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { Country } from "../../../enums";
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export type Size = {
  width: number | string;
  height: number | string;
};

export interface CountryFlagIconProps {
  size: Size;
  code: String;
  css?: any;
}

export class CountryFlagIcon extends React.PureComponent<CountryFlagIconProps> {
  render(): JSX.Element {
    const {
      size: { width, height },
      code,
      css
    } = this.props;

    return getIconSVGDraw(
      countries.isValid(code)
        ? countries.alpha2ToAlpha3(code)
        : countries.getAlpha3Code(code, "en", { select: "official" }),
      worldmap
    ) ? (
      <svg
        css={css}
        aria-hidden="true"
        role="img"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        style={{ width, height }}
      >
        <title>{code.toUpperCase()} - {code == "TW" ? Country.TW : countries.getName(code, "en", { select: "official" })}</title>
        {getIconSVGDraw(
          countries.isValid(code)
            ? countries.alpha2ToAlpha3(code)
            : countries.getAlpha3Code(code, "en", { select: "official" }),
          worldmap
        )}
      </svg>
    ) : (
      <div style={{ opacity: ".3", paddingLeft: 6 }} 
           title={`${code.toUpperCase()} - ${code == "TW" ? Country.TW : countries.getName(code, "en", { select: "official" })}`} >
        <Icon icon={"flag"} />
      </div>
    );
  }
}
