
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import Dropzone from 'react-dropzone'
import { SitecoreGenericTextField } from '../../interfaces';
import { SelectBox, InputField, Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import cloud from './cloud.svg';
import {useState} from "react";
import {renderLink} from "../../utils/helper";

export interface ContactProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  background-color: #F6F8F9;
  padding-top: 40px;
  padding-bottom: 80px;
`;
const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  
  .MuiInputBase-formControl {
    background-color: #ffffff;
  }
`;
const topInfo = css`
  width: 66%;
  margin-bottom: 64px;
`;
const triple = css`
  width: 100%;
  display: flex;
`;
const part = css`
  width: 33%;
`;
const filesPart = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const disclaimer = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #354450;
  margin-top: 16px;
  text-align: center;
`;
const miniDisclaimer = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #354450;
  margin-bottom: 32px;
  margin-top: 16px;
`;
const dragLabel = css`
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #354450;
  margin-top: 16px;
`;
const dragLabelOr = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #354450;
  margin-top: 16px;
`;
const dragButton = css`
  margin-top: 16px;
`;
const removeFile = css`
  cursor: pointer;
`;
const uploadedItem = css`
  background: #FFFFFF;
  border: 1px solid #D9E1E2;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  
  svg {
    margin-left: 8px;
    margin-right: 8px;
    color: #22AA5F;
    font-size: 12px;
  }
  
  &:first-of-type {
    margin-top: 40px;
    margin-bottom: 8px;
  }
`;
const dropZone = css`
  width: 100%;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px dashed #A4BCC2;
  height: 320px;
  max-width: 360px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const actions = css`
  width: 100%;
`;
const cloudPart = css`
  background-image: url(${renderLink(cloud)});
  background-repeat: no-repeat;
  width: 120px;
  height: 80px;
`;
const input = css`
  width: 100%;
  margin-bottom: 48px;
  padding-right: 40px;
`;
const inputLast = css`
  width: 100%;
  padding-right: 40px;
`;
const title = css`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #253645;
  margin-bottom: 24px;
`;
const authorizedFiles: string[] = ["doc","docx","pdf","txt","csv","xlsx","xls","xml"];
export const Contact: React.FunctionComponent<ContactProps> = (props: ContactProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const [refusedFiles, setRefusedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const onDrop = (acceptedFiles: any) => {
        let errorFiles: any = refusedFiles;
        let currentFiles: any = [...files];

        acceptedFiles.map((file : any) => {
            file && file.size < 52428800 ?
                authorizedFiles.includes(file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase()) ?
                    currentFiles.push(file) :
                    errorFiles.push({name: file.name, error: "unauthorized file type"})
                : errorFiles.push({name: file.name, error: "file too large ("+file.size / 1000000+" M)"})
        });
        setFiles(currentFiles);
        setRefusedFiles(errorFiles);
    };

    const remove = (name: string) => {
        setFiles(files.filter((f: any) => f.path !== name));
    }
    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={topInfo}>
                    <SelectBox
                        data={[
                            {
                                name: 'Market data services',
                                value: '01'
                            },
                        ] as any}
                        rest={''}
                        maxHeight="100%"
                        placeholder="Select items"
                    />
                </div>
                <div css={triple}>
                    <div css={part}>
                        <div css={title}>
                            Your informations *
                        </div>
                        <div css={input}>
                            <InputField
                                helperText=""
                                placeholder="placeholder"
                                textError=""
                                type="text"
                                width="100%"
                            />
                        </div>
                        <div css={input}>
                            <InputField
                                helperText=""
                                placeholder="placeholder"
                                textError=""
                                type="text"
                                width="100%"
                            />
                        </div>
                        <div css={input}>
                            <InputField
                                helperText=""
                                placeholder="placeholder"
                                textError=""
                                type="text"
                                width="100%"
                            />
                        </div>
                        <div css={input}>
                            <InputField
                                helperText=""
                                placeholder="placeholder"
                                textError=""
                                type="text"
                                width="100%"
                            />
                        </div>
                        <div css={inputLast}>
                            <InputField
                                helperText=""
                                placeholder="placeholder"
                                textError=""
                                type="text"
                                width="100%"
                            />
                        </div>
                    </div>
                    <div css={part}>
                        <div css={title}>
                            Optional data
                        </div>
                        <div css={input}>
                            <InputField
                                helperText=""
                                placeholder="placeholder"
                                textError=""
                                type="text"
                                width="100%"
                            />
                        </div>
                        <div css={input}>
                            <InputField
                                helperText=""
                                placeholder="placeholder"
                                textError=""
                                type="text"
                                width="100%"
                            />
                        </div>
                        <div css={input}>
                            <InputField
                                helperText=""
                                placeholder="placeholder"
                                textError=""
                                type="text"
                                width="100%"
                            />
                        </div>
                    </div>
                    <div css={part}>
                        <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <div css={dropZone}>
                                            <input {...getInputProps()} />
                                            <div css={cloudPart} />
                                            <div css={dragLabel}>
                                                Drag & drop files here **
                                            </div>
                                            <div css={dragLabelOr}>
                                                or
                                            </div>
                                            <div css={dragButton}>
                                                <Button label={'Browse files'} wired={true}/>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <div css={filesPart}>
                            {files.map((file: any, index: number) => (
                                <div css={uploadedItem} key={`file-${index}`}>
                                    <Icon icon={'check'} /> {file.path} <div css={removeFile} onClick={() => remove(file.path)}><Icon icon={'times'} /></div>
                                </div>
                            ))}
                        </div>

                        <div css={disclaimer}>
                            ** Maximum 5mo per document
                        </div>
                    </div>
                </div>
                <div css={miniDisclaimer}>
                    * Required
                </div>
                <div css={actions}>
                    <Button label={'Send your request'} />
                </div>
            </div>
        </div>
    );
}
