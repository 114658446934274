/** @jsx jsx */
/* eslint-disable */
import { Grid, Pagination, Table, Tbody, Th, Thead, ToggleButtonGroup, ToggleItem, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import React, { Component, useEffect, useState } from "react";
import { style } from "../market-statistics.style";
import Loader from "../../Loader/loader.component";
import GenericMarketCapDataRow from "../Base/GenericMarketCapDataRow";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import MarketCapCard from "../Cards/MarketCapCard";
import EmptyResults from "../../UI/list/base/empty";
import { MarketCapPaginationModel } from "../market-capitalisation-view.component";
import { css, jsx } from "@emotion/react";
import SortIcon from "../Base/SortIcon";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { GET_RISERS_FALLERS_STATISTICS } from "../../../../graphql/queries/market.queries";
import GenericRisersAndFallersDataRow from "../Base/GenericRisersAndFallersDataRow";
import RisersFallersCard from "../Cards/RisersFallersCard";
import { Security } from "../Home/top-traded-securities";
import GenericTopTradedSecuritiesDataRow from "../Base/GenericTopTradedSecuritiesDataRow";
import TopTradedSecurityCard from "../Cards/TopTradedSecurityCard";

export class TopTradedSecuritiesModel {
    displayData?: Security[];
    totalHits?: number;
}

export function TopTradedSecuritiesTable(props: any) {

    const type=props.type;
    const data=props.data;


    const [topTradedSecurities, setTopTradedSecurities] = useState<TopTradedSecuritiesModel>({
        displayData: data,
        totalHits: Object.keys(data).length
    });


    const columns = [
        { label: "Type", accessor: "type," },
        { label: "ISIN", accessor: "isinCode," },
        { label: "Description", accessor: "name," },
        { label: "Currency", accessor: "currency," },
        { label: "Turnover (EUR)", accessor: "turnover," },
        { label: "Trades", accessor: "numberOfTransaction," },
    ];
    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("asc");
    const [dt,setDt]=useState<any[]>();

    


    const handleSortingChange = (accessor:any) => {
        const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };



    const handleSorting = (sortField:any, sortOrder:any) => {



        if(sortField){
            const sortName=sortField.replace(',','');

            if (sortName === "turnover" || sortName === "numberOfTransaction") {

                topTradedSecurities.displayData=Object.values(topTradedSecurities.displayData).sort((a:any, b:any) => {
                 return (
                  (Number(a[sortName])-Number(b[sortName])) * (sortOrder === "asc" ? 1 : -1)
                 );
                }).reduce((ac:any, item:any, index:any) => {
                    ac[index] = item;
                    return ac;
                }, {});

            }else{
                topTradedSecurities.displayData=Object.values(topTradedSecurities.displayData).sort((a:any, b:any) => {
                    return (
                     a[sortName].toString().localeCompare(b[sortName].toString(), "en", {
                      numeric: true,
                     }) * (sortOrder === "asc" ? 1 : -1)
                    );
                   }).reduce((ac:any, item:any, index:any) => {
                    ac[index] = item;
                    return ac;
                }, {});
            }
            setTopTradedSecurities({...topTradedSecurities});
        }
    };





    return (
        <>
            <div css={style.container}>
                    {topTradedSecurities && topTradedSecurities?.totalHits > 0 ?
                        <>
                            <div css={[style.desktopDisplay]}>
                                <table css={style.table}>
                                        <thead css={style.thead}>
                                            <tr css={style.tr}>
                                            { columns.map(({ label, accessor }) => {
                                                return accessor.replace(',','')==="type"?
                                                <th key={accessor}>
                                                {label}
                                            </th>
                                                : <th key={accessor} onClick={() => handleSortingChange(accessor)}>
                                                    {label}
                                                    <SortIcon/>
                                                </th>;
                                            })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <GenericTopTradedSecuritiesDataRow rowsData={topTradedSecurities.displayData} columns={columns} type={type}/>
                                        </tbody>
                                </table>
                            </div>
                            <div css={style.mobileDisplay}>
                                <SearchMobile
                                    onPaginationUpdate={(d: any) => {
                                    }}
                                    pageNumber={0}
                                    data={Object.values(topTradedSecurities?.displayData)}
                                    totalResultSize={10}
                                    Card={(card) => <TopTradedSecurityCard card={card} type={type} />}
                                />
                            </div>
                            
                        </>
                        :
                        <div>
                            <EmptyResults />
                        </div>
                    }
            </div>
        </>
    );
}

export default TopTradedSecuritiesTable;