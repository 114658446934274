import { css } from "@emotion/react";

export const style = {
  row: css`
    border-bottom: 1px solid #D9DEE2;
  `,
  rowClickable: css`
    color: inherit;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background: #F8FAFC;
    }
  `,
  cellMultipleLine: css`
    display: block !important;
    height: inherit !important;
    > div {
      width: 100%;
    }
  `,
  title: css`
    font-weight: 700;
    line-height: 1.5;
    font-size: 14px;
    color:#253845;
  `,
  subTitle: css`
    font-weight: 600;
    line-height: 1.5;
    font-size: 14px;
    color: #354450;
  `,
  
  tag: css`
    justify-content: center;
    border: solid 1px #E3E6E8;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 4px 8px;
    margin: 0px 3px;
    line-height: 1.2;
    font-size: 12px;
  `,
  country: css`
    text-transform: uppercase;
    line-height: 1.5;
    font-size: 14px;
    display:flex;
  `,
  countryFlag: css`
    width: 32px;
  `,
  tagInfo: css`
    line-height: 1.5;
    font-size: 14px;
  `,
};
