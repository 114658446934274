import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { WEBSITE_TITLE } from "../core/constants";

export type LayoutServiceContextDataDot = {
  pageEditing?: boolean;
  language?: string;
  pageState?: "preview" | "edit" | "normal";
  visitorIdentificationTimestamp?: number;
  site?: {
    name?: string;
  };
  [key: string]: any;
};

export interface NotFoundProps {
  context: LayoutServiceContextDataDot | null;
}

export const NotFound: React.FunctionComponent<NotFoundProps> = (
  props: NotFoundProps
): React.ReactElement => {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{`404 Not Found - ${WEBSITE_TITLE}`}</title>
        </Helmet>
        <main></main>
      </HelmetProvider>
    </React.Fragment>
  );
};
