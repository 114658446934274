import * as React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { SitecoreContext } from "../../interfaces";
import { IssuerCard } from "../../core/components/IssuerCard";

export interface IssuerCardParams {
  id: string;
}

export interface IssuerCardContentProps
  extends RouteComponentProps<IssuerCardParams> {
  sitecoreContext: SitecoreContext;
}

export function IssuerCardContentCore(props: IssuerCardContentProps) {
  const {
    sitecoreContext: { route },
    match
  } = props;

  const path = props?.location?.pathname ? props?.location.pathname.split('/').filter((elem: string) => elem !== '') : [];
  const search = new URLSearchParams(props?.location?.search);
  const id = path?.length >= 2 && path[2] ? path[2] : search ? search.get("id") : "";
  
  return (
      <IssuerCard id={id} />
  );
}

export const IssuerCardContent = withRouter(
  withSitecoreContext()(IssuerCardContentCore)
);
