
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const TUN: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#E70013" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <path fill="#FFFFFF" d="M257.002,167.725c48.718,0,88.205,39.594,88.205,88.276c0,48.823-39.487,88.276-88.205,88.276 c-48.823,0-88.346-39.453-88.346-88.276C168.655,207.319,208.179,167.725,257.002,167.725" />
            <g>
                <path fill="#E70013" d="M273.043,305.467c-27.327,0-49.493-22.165-49.493-49.461c0-27.23,22.166-49.395,49.493-49.395 c9.295,0,17.981,2.604,25.405,7.102c1.112,0.674,2.282-0.782,1.355-1.695c-11.138-10.974-26.389-17.771-43.235-17.771 c-34.2,0-61.892,27.692-61.892,61.761c0,34.135,27.691,61.825,61.892,61.825c16.838,0,32.083-6.792,43.219-17.763 c0.927-0.913-0.243-2.369-1.355-1.696C291.01,302.868,282.33,305.467,273.043,305.467z" />
                <path fill="#E70013" d="M268.52,221.388l13.644,18.526l21.945-6.912c2.841-0.895,5.186,2.342,3.452,4.762l-13.402,18.701 l13.355,18.735c1.729,2.425-0.625,5.656-3.464,4.754l-21.928-6.968l-13.692,18.49c-1.772,2.395-5.572,1.154-5.591-1.825 l-0.149-23.007l-21.816-7.307c-2.825-0.946-2.819-4.943,0.008-5.881l21.835-7.252l0.207-23.007 C262.951,220.219,266.754,218.989,268.52,221.388z" />
            </g>
        </svg>
    </React.Fragment>
);
