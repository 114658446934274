/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from "react";
import { SearchSuggestionItemDto } from '../../../../../interfaces/search/search-suggestion';
import { formatDate } from '../../../../../utils/date';
import RoundTag from '../../../RoundTag/roundTag.component';
import { getSuggestionComplement, getSuggestionTitle } from '../../../Search/input/suggestion';
import { style } from '../../../Search/input/suggestion.style';
import { securityDetailPageLink } from '../../../SecurityCard';

export const SecuritySuggestion = ({ suggestion }: { suggestion: SearchSuggestionItemDto }) => {
    if(!suggestion)
        return <></>;

    const getSecurityType = (suggestion: SearchSuggestionItemDto) => {

        let label = "S";
        let green = false;

        switch ( suggestion.properties?.type ){
            case 'WAR':
                label = "W";
                break;
            case "OBL":
                label = "B";
                green = true;
                break;
            case "CER":
                label = "C";
                break;
            case "OPC":
                label = "E";
                green = true;
                break;
            case 'ACT':
                label = "B";
                green = true;
            default:
                break;
        }

        return (<RoundTag small textColor={"#425563"} green={green}>{label}</RoundTag>);
    }

    const getSecurityAmount = (suggestion: SearchSuggestionItemDto) => {
        let amount = getSuggestionComplement(suggestion, "latestPriceAmount") 
        
        if ( amount ){
            return (<>
                <div css={[style.cardPercentage,
                    getSuggestionComplement(suggestion, "previousDayPercentVariation") < 0 ? 
                        style.cardPercentageNegative : style.cardPercentagePositive
                ]}>
                    {amount 
                        + ' ' + getSuggestionComplement(suggestion, "latestPriceMarker") 
                        + ' ' + getSuggestionComplement(suggestion, "latestPriceCurrency")}
                </div>
                <div css={style.cardDate}>
                    {formatDate(getSuggestionComplement(suggestion, "latestPriceDate"))}
                </div>
            </>);
        }

        return <></>;
    }

    let title = getSuggestionTitle(suggestion, suggestion.id);

    return (
        <a css={style.card} title={title}
           href={securityDetailPageLink(getSuggestionComplement(suggestion, "isinCode"), suggestion.id)}>
            <div css={style.cardHeader}>
                {getSecurityType(suggestion)}
            </div>
            <div css={style.cardBody}>
                <div css={style.cardBodyLeft}>
                    <div css={style.cardTitle}>
                        {title}
                    </div>
                    <div css={style.cardSubTitle}>
                        {getSuggestionComplement(suggestion, "isinCode")}
                    </div>
                </div>
                <div css={style.cardBodyRight}>
                    { getSecurityAmount( suggestion) }
                </div>
            </div>
        </a>
    );
};