import gql from "graphql-tag";
import { securitySearchEmptyFragment, securitySearchNoFilterFragment } from "../search-security.queries";


export const SEARCH_RENMINBI_BONDS = gql`
  ${securitySearchNoFilterFragment}
  query SearchRenminbiBonds(
    $page : Int,
    $size : Int,
    $sort: String!,
    $searchTerm: String!,
  ){
    searchRenminbiBonds(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort,
    ){
      ...securitySearchNoFilterFragment
    }
  }
`;

export const SEARCH_LGX_SUSTAINABLE_BONDS = gql`
  ${securitySearchNoFilterFragment}
  query SearchLGXSustainableBonds(
    $page : Int,
    $size : Int,
    $sort: String!,
    $searchTerm: String!,
  ){
    searchLGXSustainableBonds(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort,
    ){
      ...securitySearchNoFilterFragment
    }
  }
`;

export const SEARCH_EQUITIES_WITH_BBO = gql`
  ${securitySearchNoFilterFragment}
  query SearchEquitiesWithBBO(
    $page : Int,
    $size : Int,
    $sort: String!,
    $searchTerm: String!,
  ){
    searchEquitiesWithBBO(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort,
    ){
      ...securitySearchNoFilterFragment
    }
  }
`;

export const SEARCH_FIXED_INCOME_WITH_BBO = gql`
  ${securitySearchNoFilterFragment}
  query SearchFixedIncomeWithBBO(
    $page : Int,
    $size : Int,
    $sort: String!,
    $searchTerm: String!,
  ){
    searchFixedIncomeWithBBO(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort,
    ){
      ...securitySearchNoFilterFragment
    }
  }
`;

export const SEARCH_FUNDS_WITH_BBO = gql`
  ${securitySearchNoFilterFragment}
  query SearchFunds(
    $page : Int,
    $size : Int,
    $sort: String!,
    $searchTerm: String!,
  ){
    searchFundsWithBBO(
      searchTerm: $searchTerm,
      page: $page,
      size: $size,
      sort: $sort,
    ){
      ...securitySearchNoFilterFragment
    }
  }
`;