import { css } from '@emotion/react';

export const containerCss = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  svg {
    color: #354450;
  }
`;

export const resultCss = css`
  position: relative;
  display: block;
  min-width: 80%;
  min-height: 800px;
  width: 100%;
  background: #e8ecee;
  > div > table {
    overflow-x: auto;
  }
`;
