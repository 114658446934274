import * as React from "react";
import { useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
    Row,
    Tag,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    ToggleButtonGroup,
    ToggleItem
} from "@bdl-cmn-shared-packages-npm/design-system";
import { style } from '../SecurityOverview/securityOverview.style';
import { Security } from "../../../../interfaces";
import { reviewerLabel } from "../../../../enums/reviewer";
import { standardAndPrincipleLabel } from "../../../../enums/standard-and-principle";
import SecuritySustainableKeyDocuments from "./keyDocuments";
import { GreenBondPreissuanceSLBDataKPI, GreenCard } from "../../../../interfaces/green-card";
import { slbTypeOfChangeLabel } from "../../../../enums/slb-type-of-change";
import { formatDate } from "../../../../utils/date";

export interface Row {
    label: string,
    action: () => void;
}

export interface SecuritySustainableDataProps {
    securityData: Security,
    greenCard: GreenCard
}

const SecuritySustainableDataSlb = ({
    greenCard,
    securityData
}: SecuritySustainableDataProps) => {
    const preIssuanceInfo = greenCard?.preIssuanceInfo;
    const useOfProceeds = preIssuanceInfo?.useOfProceeds;
    const sustainabilityLinkedBonds = preIssuanceInfo?.sustainabilityLinkedBonds;

    const [kpiDisplay, setKpiDisplay] = React.useState<number>(1);

    useEffect(() => {

    }, [greenCard, kpiDisplay]);

    return (
        <div css={style.securityOverview}>
            <div css={style.securityOverviewTop}>
                <div css={style.securityOverviewTopLeftStart}>
                    <div css={style.flexRow}>
                        <div css={style.blocTitle}>
                            Sustainability linked bond
                        </div>
                        <div css={[style.blocRowSpace, style.blocRowSpaceFull]}>
                            <div css={style.blocRowKeyFull}>What is a Sustainability-Linked Bond?</div>
                            <div css={style.blocRowValueFull}>
                                Sustainability-Linked Bonds ("SLBs") are any type of bond instrument for which the financial and/or structural characteristics can vary depending on whether the issuer achieves predefined Sustainability/ ESG objectives. Those objectives are (i) measured through predefined Key Performance Indicators (KPIs) and (ii) assessed against predefined Sustainability Performance Targets (SPTs). The proceeds of SLBs are intended to be used for general purposes, hence the use of proceeds is not a determinant in its categorisation. Thus, please note that SLB are not to be confused with Sustainability Bonds (i.e. Use-of-Proceeds bonds as currently defined by the Sustainability Bond Guidelines).
                            </div>
                        </div>
                        <div css={style.blocRowSpace}>
                            <div css={style.blocRowKey}>
                                Standards & Principles
                            </div>
                            <div css={style.blocRowValue}>
                                { greenCard?.alignmentWithStandardAndPrinciples?.length ?
                                    greenCard?.alignmentWithStandardAndPrinciples
                                        .map(element => {
                                            if(! element)
                                                return <></>; 

                                            let label = standardAndPrincipleLabel(element);
                                            if (label === "-")
                                                return <></>; 
                                            
                                            return (
                                                <div css={{marginBottom: "5px", fontSize: "18px"}}>
                                                    <Tag label={standardAndPrincipleLabel(element)} />
                                                </div>
                                            );
                                        })
                                    : "None"
                                }
                            </div>
                        </div>
                        <div css={style.blocRowSpace}>
                            <div css={style.blocRowKey}>
                                Second opinion provider
                            </div>
                            <div css={style.blocRowValue}>
                                { preIssuanceInfo?.externalReviews?.length ? 
                                    preIssuanceInfo.externalReviews
                                        .map(element => {
                                            if( ! element.reviewer)
                                                return <></>;

                                            return (
                                                <div css={{marginBottom: "5px", fontSize: "18px"}}>
                                                    <Tag label={reviewerLabel(element.reviewer)} />
                                                </div>
                                            );
                                        })
                                    : <div>None</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <SecuritySustainableKeyDocuments 
                    postIssuanceDocs={greenCard?.postIssuanceDocs}
                    preIssuanceDocs={greenCard?.preIssuanceDocs} />
            </div>
            <div css={[style.securityOverviewTop, {flexDirection: "column"}]}>
                <div css={style.flexRow}>
                    <div css={style.descriptionCardTitle}>Key Performance Indicators</div>
                    <div>
                        <ToggleButtonGroup onChange={(index: any) => {setKpiDisplay(index)}}>
                            { sustainabilityLinkedBonds?.keyPerformanceIndicators
                                .map((kpi: GreenBondPreissuanceSLBDataKPI, index: number) => (
                                    <ToggleItem selected={kpiDisplay == (index + 1)} value={index + 1}>
                                        {`KPI ${index + 1}`}
                                    </ToggleItem>
                                ))
                            }
                        </ToggleButtonGroup>
                        { sustainabilityLinkedBonds?.keyPerformanceIndicators
                            .map((kpi: GreenBondPreissuanceSLBDataKPI, index: number) => {
                                if( (index + 1) != kpiDisplay)
                                    return <></>;

                                return (
                                    <div css={style.blocTab}>
                                        <div css={[style.blocRowSpace, style.blocRowSpaceNoBorderTop]}>
                                            <div css={style.blocRowKeyAlt}>KPI Name</div>
                                            <div css={style.blocRowValueAlt}>
                                                {kpi?.indicator}
                                            </div>
                                        </div>
                                        <div css={style.blocRowSpace}>
                                            <div css={style.blocRowKeyAlt}>KPI Unit</div>
                                            <div css={style.blocRowValueAlt}>
                                                {kpi?.unit}
                                            </div>
                                        </div>
                                        <div css={[style.blocRowSpace, style.blocRowSpaceFull]}>
                                            <div css={style.blocRowKeyFull}>KPI Definition</div>
                                            <div css={style.blocRowValueFull}>
                                                {kpi?.definition}
                                            </div>
                                        </div>
                                        <div css={[style.blocRowSpace, style.blocRowSpaceFull]}>
                                            <div css={style.blocRowKeyFull}>KPI Assessment</div>
                                            <div css={style.blocRowValueFull}>
                                                {kpi?.assessment}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div css={style.flexRow}>
                    <div css={style.descriptionCardTitle}>Potential change</div>
                    <div>
                        <Table density="medium">
                            <Thead>
                                <Tr>
                                    <Th>Type of change</Th>
                                    <Th>Change value</Th>
                                    <Th>Date</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                { sustainabilityLinkedBonds?.potentialChange?.length > 0 ?
                                    <>
                                        { sustainabilityLinkedBonds?.potentialChange.map((change: any, index: number) => (
                                            <Tr key={`potentialChange-${index}`}>
                                                <Td>{slbTypeOfChangeLabel(change.typeOfChange)}</Td>
                                                <Td>{change.value}</Td>
                                                <Td>{change.date? formatDate(change.date, 'DD/MM/YYYY') : "N/A"}</Td>
                                            </Tr>
                                        ))}
                                    </> :
                                    <Tr>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                        <Td>-</Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </div>
                </div>
                <div css={style.flexRow}>
                    <div css={style.descriptionCardTitle}>Reporting & Verification Commitments</div>
                    <div>
                        <div css={[style.blocRowSpace, style.blocRowSpaceFull]}>
                            <div css={style.blocRowKeyFull}>Description</div>
                            <div css={style.blocRowValueFull}>
                                {sustainabilityLinkedBonds?.commitments?.description}
                            </div>
                        </div>
                        <div css={style.blocRowSpace}>
                            <div css={style.blocRowKeyAlt}>Reporting Frequency</div>
                            <div css={style.blocRowValueAlt}>
                                {sustainabilityLinkedBonds?.commitments?.reportingFrequency}
                            </div>
                        </div>
                        <div css={style.blocRowSpace}>
                            <div css={style.blocRowKeyAlt}>Verification Frequency</div>
                            <div css={style.blocRowValueAlt}>
                                {sustainabilityLinkedBonds?.commitments?.verificationFrequency}
                            </div>
                        </div>
                    </div>
                </div>
                { sustainabilityLinkedBonds?.generalComment ?
                    <div css={style.flexRow}>
                        <div css={style.descriptionCardTitle}>General comment</div>
                        <div>
                            {sustainabilityLinkedBonds?.generalComment}
                        </div>
                    </div>
                    : <></>
                }
            </div>
        </div>
    )
};

export default SecuritySustainableDataSlb;