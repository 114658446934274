/** @jsx jsx */

import * as React from "react";

import {
  ComponentParams,
  ComponentRendering,
  Text,
  Link,
} from "@sitecore-jss/sitecore-jss-react";
import { css, jsx } from "@emotion/react";

import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { OfferingMenu } from "../../core/components/Header/header.component";
import adImg from "./ad.png";
import greenHexa from "./greenexa.svg";
import greyHexa from "./greyhexa.svg";
import redHexa from "./redhexa.svg";
import { renderLink, transformLink } from "../../utils/helper";
import { useState } from "react";
import whiteHexa from "./whiteexa.svg";
import { style } from "../../core/components/Header/header.style";

export interface BigMenuComponentProps {
  fields: Array<OfferingMenu>;
  params?: ComponentParams;
  rendering: ComponentRendering;
  onClickOutside?: () => void;
}

const backgroundMapper: { green: any; gray: any; red: any; white: any } = {
  green: greenHexa,
  gray: greyHexa,
  red: redHexa,
  white: whiteHexa,
};

const container = css`
  width: 900px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(37, 54, 69, 0.15);
  border-radius: 16px;
  border: 1px solid #d9e1e2;
  margin-left: 32px;
  margin-top: 8px;
  position: relative;
  z-index: 2;

  * {
    transition: background 150ms ease-in-out;
  }
`;
const left = css`
  display: flex;
  width: 33%;
  flex-direction: column;
  background: #f0f5f5;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`;

const selected = css`
  position: relative;
  width: 4px;
  background: transparent;
  height: calc(100% + 32px);
`;

const selectedItemRight = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`;

const selectedItemSub = css`
  display: flex;
  flex-direction: column;
  font-weight: normal;
  font-size: 14px;
  color: #425563;
  margin-top: 4px;
  text-align: left;
`;

const item = css`
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 24px;
  padding-right: 16px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  position: relative;
  overflow: hidden;

  &:first-of-type {
    margin-top: 32px;
  }

  &:last-of-type {
    margin-bottom: 32px;
  }

  cursor: pointer;

  &:hover {
    background: #425563 !important;

    > div:nth-child(2) {
      background-image: url(${renderLink(whiteHexa)}) !important;
    }

    > div {
      > div:nth-child(2) {
        display: flex;
      }

      > div {
      }
    }
  }
`;

const itemSelected = css`
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 24px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  position: relative;
  overflow: hidden;

  &:first-of-type {
    margin-top: 32px;
  }

  &:last-of-type {
    margin-bottom: 32px;
  }

  cursor: pointer;
  background: #ffffff;

  > div {
    > div:nth-child(2) {
      display: flex;
    }

    > div:first-child {
      color: #22aa5f !important;
    }
  }
`;

const itemHexa = css`
  width: 48px !important;
  min-width: 48px !important;
  height: 53px !important;
  max-height: 53px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  font-size: 18px;
`;
const itemData = css`
  cursor: pointer;
  text-align: left;
  margin-left: 16px;
`;
const itemDataTop = css`
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: #354450;
`;
const itemDataBottom = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #425563;
  display: none;
`;

const right = css`
  display: flex;
  width: 67%;
  flex-direction: column;
`;

const selectedItem = css`
  display: flex;
  margin: 12px;
  padding: 20px;
  align-items: flex-start;
  cursor: default;
  // &:hover {
  //   background: #f6f8f9;
  //   border-radius: 16px;
  // }
`;
const selectedItemRightTitle = css`
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
`;
const menuList = css`
  border-top: solid 1px #e3e6e8;
  padding: 32px;
  height: 65%;
  display: flex;
`;
const menu = css`
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 150%;
  color: #354450;
  margin: 8px 0;
  cursor: pointer;
  padding: 8px 16px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  display: flex;

  &:hover {
    background: #f0f5f5;
    border-radius: 8px;
  }
`;
const col = css`
  width: 50%;
`;

export const BigMenuComponent: React.FunctionComponent<BigMenuComponentProps> =
  (props: BigMenuComponentProps): React.ReactElement => {
    const { fields } = props;
    const [selectedMenu, setSelectedMenu] = useState<any>(fields[0]);
    let nbr = 0;

    const displaySubElem = (items: any) => {
      return items.map((item: any, index: number) => (
        <Link
          key={`col-${index}-${item.title}`}
          target={"_self"}
          title={item.label}
          field={transformLink(item.pageLink)}
          css={menu}
        >
          {item.label}
        </Link>
      ));
    };
    return (
      <div css={container}>
        <div css={left}>
          {fields.map((elem: any, index: number) => (
            <div
              css={
                selectedMenu.cardTitle === elem.cardTitle ? itemSelected : item
              }
              onMouseEnter={() => setSelectedMenu(elem)}
              key={`menu-${index}-${elem.cardTitle}`}
            >
              <div
                css={itemHexa}
                style={{
                  background: `url(${
                    selectedMenu.cardTitle === elem.cardTitle
                      ? greyHexa
                      : elem.isGreen
                      ? greenHexa
                      : elem.isGrey
                      ? greyHexa
                      : redHexa
                  })`,
                  color:
                    selectedMenu.cardTitle === elem.cardTitle ||
                    elem.cardTitle === "LGX Services"
                      ? "#22AA5F"
                      : elem.isGreen
                      ? "#22AA5F"
                      : elem.isGrey
                      ? "inherit"
                      : "rgb(203, 18, 52)",
                }}
              >
                <Icon icon={elem.hexagonIcon} />
              </div>
              <div css={itemData}>
                <div css={itemDataTop}>
                  <Text field={{ value: elem.cardTitle }} />
                </div>
                <div css={itemDataBottom}>
                  <Text field={{ value: elem.cardSubTitle }} />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div css={right}>
          {selectedMenu !== null && typeof selectedMenu !== "undefined" && (
            <>
              <div css={selectedItem}>
                <div
                  css={itemHexa}
                  style={{
                    background: `url(${
                      selectedMenu.isGreen
                        ? greenHexa
                        : selectedMenu.isGrey
                        ? greenHexa
                        : redHexa
                    })`,
                    color:
                      selectedMenu.cardTitle === "LGX Services"
                        ? "#22AA5F"
                        : selectedMenu.isGreen
                        ? "#22AA5F"
                        : selectedMenu.isGrey
                        ? "inherit"
                        : "rgb(203, 18, 52)",
                  }}
                >
                  <Icon icon={selectedMenu.hexagonIcon} />
                </div>
                <div css={selectedItemRight}>
                  <div css={selectedItemRightTitle}>
                    <Text field={{ value: selectedMenu.cardTitle }} />
                  </div>
                  <div css={selectedItemSub}>
                    <Text field={{ value: selectedMenu.cardSubTitle }} />
                  </div>
                </div>
              </div>
              <div css={menuList}>
                {selectedMenu?.links
                  .reduce((cols: any, elem: number) => {
                    cols[
                      Math.floor(
                        nbr / Math.round(selectedMenu?.links.length / 2)
                      )
                    ] =
                      cols[
                        Math.floor(
                          nbr / Math.round(selectedMenu?.links.length / 2)
                        )
                      ] || [];
                    cols[
                      Math.floor(
                        nbr / Math.round(selectedMenu?.links.length / 2)
                      )
                    ].push(elem);
                    nbr++;
                    return cols;
                  }, [])
                  .map((column: any, index: number) => (
                    <div key={`sub-col-${index}`} css={col}>
                      {displaySubElem(column)}
                    </div>
                  ))}
              </div>
              {/* <div css={ad}>
              <div css={adSide}>
                <div css={adSideBg} />
              </div>
               <div css={adSide}>
                <div css={adElem}>
                  <div css={adElemTitle}>Changing the way you use sustainability data</div>
                  <div css={adElemLink} onClick={() => (window.location.href = '/lgx-datahub')}>
                    Discover LGX Datahub
                  </div>
                </div>
              </div> 
            </div>*/}
            </>
          )}
        </div>
      </div>
    );
  };
