
import 'react-day-picker/lib/style.css';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import DayPicker from 'react-day-picker';
import moment from "moment-timezone";
import { baseDateFormat } from '../../constants';
import styles from "./stylesheet";
import { InputField } from '@bdl-cmn-shared-packages-npm/design-system';
import { formatDate, parseToMoment } from '../../../utils/date';

export type CalendarPosition = 'top' | 'bottom';

export interface InputDatePickerProps {
    selection: moment.Moment | null;
    placeholder?: string;
    authorizeFuture?: boolean;
    onSelect?: (selection: moment.Moment | null) => void;
}

export interface InputDatePickerState {
    isDatePickerOpen: boolean;
}

export function InputDatePicker(props: InputDatePickerProps) {
    const {
        selection,
        placeholder,
        authorizeFuture,
        onSelect,
    } = props;

    const [isDatePickerOpen, setIsDatePickerOpen] = React.useState<boolean>(false);

    const inputRef = React.useRef<HTMLInputElement>(null);
    const calendarsRef = React.useRef<HTMLDivElement>(null);

    const onDayClick = React.useCallback((day: Date): void => {

        if (!!onSelect) {
            onSelect(day ? parseToMoment(day).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : null);
        }
    }, [onSelect, selection]);

    React.useEffect(() => {
        const onOutsideClick = (e: MouseEvent): void => {
            const predicate: boolean = (
                isDatePickerOpen &&
                inputRef.current !== null &&
                calendarsRef.current !== null &&
                !inputRef.current.contains(e.target as Node) &&
                !calendarsRef.current.contains(e.target as Node)
            );

            if (predicate) {
                setIsDatePickerOpen(false);
            }
        };

        document.addEventListener('click', onOutsideClick, false);

        return function cleanup() {
            document.removeEventListener('click', onOutsideClick, false);
        }
    }, [isDatePickerOpen]);

    return (
        <div
            ref={inputRef}
            css={styles.container}
        >
            <div
                css={styles.inputWrapper}
                onClick={(): void => {
                    setIsDatePickerOpen((isDatePickerOpen) => {
                        return !isDatePickerOpen;
                    });
                }}
            >
                <InputField value={selection ? `${formatDate(selection, baseDateFormat)}` : placeholder || 'Pick a date range'} />
            </div>
            {
                isDatePickerOpen ? (
                    <div
                        css={styles.datePicker}
                    >
                        <div>
                            <div ref={calendarsRef}>
                                <DayPicker
                                    numberOfMonths={1}
                                    selectedDays={selection ? selection.toDate() : undefined}
                                    toMonth={!authorizeFuture ? new Date() : undefined}
                                    onDayClick={onDayClick}
                                />
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    );
}