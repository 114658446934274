import { css } from "@emotion/react";

export const style = {
  backdrop: css`
    background-color: rgba(66, 85, 99, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      width: 3em;
      position: relative;
      top: 5em;
      font-size: 4em;
      > svg {
        color: #12CA64;
      }
    }
  `
}
