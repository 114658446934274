/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import * as React from "react";
import { Form } from "@sitecore-jss/sitecore-jss-react-forms";
import { getUrlApiKey, getUrlByEnv } from "../../utils/helper";
import { breakpointMax } from "../../style";
import { useEffect, useCallback, useState } from "react";
//import SitecoreFormComponent from "../../core/components/Form/SiteCoreForm";

const styleContainer = css`
  padding-left: 20px;
  padding-right: 20px;
  gap: 24px;
  display: flex;
  flex-direction: column;

  p {
    font-weight: 600;
    line-height: 30px;
    font-size: 24px;
  }

  fieldset {
    border: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0;
    margin: 8px 0;
  }

  input,
  textarea {
    border-radius: 4px;
    border: solid 1px #d9dee2;
    min-height: 40px;
    padding: 16px;
    display: flex;
    align-items: center;
  }

  input[type="submit"] {
    box-shadow: 0 5px 0.625em rgba(66, 85, 99, 0.25) !important;
    border-radius: 6px !important;
    color: #ffffff !important;
    background-color: #22aa5f !important;
  }

  button {
    align-items: center;
    background-color: rgb(34, 170, 95);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(66, 85, 99, 0.25) 0 5px 10px 0;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    min-height: 56px;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    transition: 0.5s ease all;

    :hover {
      background-color: #0d8e46;
      box-shadow: 0 5px 0.625em rgba(66, 85, 99, 0.25);
      color: #ffffff;
    }

    :active,
    :focus {
      background-color: #075128 !important;
      color: #f8fafa !important;
    }
  }

  @media (max-width: ${breakpointMax}) {
  }
`;
export const FormComponent: React.FC<any> = ({ fields }: any) => {
  const [submitStatus, setSubmitStatus] = useState<any>(null);
  const [submited, setSubmited] = useState<boolean>(false);

  useEffect(() => {
    const labels = document.querySelectorAll("label");
    let maxWidth = 0;

    labels.forEach((label) => {
      maxWidth = Math.max(maxWidth, label.offsetWidth);
    });

    labels.forEach((label) => {
      label.style.width = `${maxWidth}px`;
    });
  }, []);

  const CustomErrorComponent = (errorMessage: any) => {
    return (
      <>
        {errorMessage?.fieldErrors?.map((e: any) => (
          <div style={{ color: "red" }}>{JSON.stringify(e)}</div>
        ))}
        {errorMessage?.formErrors?.map((e: any) => (
          <div style={{ color: "red" }}>{JSON.stringify(e)}</div>
        ))}
      </>
    );
  };

  const CustomValidationErrorsComponent = (data: any) => {
    return (
      <>
        {data?.errors?.map((e: any) => (
          <div style={{ color: "red" }}>{JSON.stringify(e)}</div>
        ))}
      </>
    );
  };

  const handleError = (error: any) => {
    console.error("Erreur lors de la soumission du formulaire :", error);
    setSubmitStatus({ isSuccess: false, errorMessage: error.message });
  };

  const handleRedirect = (url: string) => {
    console.log("Redirection après la soumission du formulaire :", url);
  };

  const handleSubmit = async (formSubmission: any) => {
    console.log("SUBMIT", formSubmission);
    /* try {
       const result = await formSubmission.submit();
       console.log("Réponse de la soumission du formulaire :", result);
       setSubmitStatus({ isSuccess: true });
     } catch (error) {
       handleError(error);
     }*/
  };

  const customTrackerFetcher = async (url: string, data: any) => {
    console.log("URL de suivi personnalisée :", url);
    console.log("Données de suivi personnalisées :", data);
    return Promise.resolve();
  };

  const customFormFetcher = async (url: string, data: any) => {
    console.log('Sent')
    console.log("URL de récupération de formulaire personnalisée :", url);
    console.log("Données de récupération de formulaire personnalisées :", data);
  };

  return (
    <div css={styleContainer}>
      {submited ? (
        <div>Votre formulaire à bien été envoyé!</div>
      ) : (
        <Form
          // @ts-ignore
          form={fields}
          sitecoreApiHost={"https://dev.luxse.com"}
          sitecoreApiKey={getUrlApiKey()}
          onRedirect={handleRedirect}
          errorComponent={CustomErrorComponent as any}
          fieldValidationErrorsComponent={
            CustomValidationErrorsComponent as any
          }
          // @ts-ignore
          onSubmit={handleSubmit as unknown as any}
          trackerFetcher={customTrackerFetcher as any}
          formFetcher={customFormFetcher as any}
        />
      )}

      {submitStatus && (
        <div>
          {submitStatus?.isSuccess
            ? "Formulaire soumis avec succès!"
            : `Erreur lors de la soumission du formulaire: ${submitStatus?.errorMessage}`}
        </div>
      )}
    </div>
  );
};
