import { ApolloError } from "apollo-client";
import gql from "graphql-tag";
import { securityLuxXPrimeSearchItemFragment } from "./search/search-luxxprime.queries";

export const GET_LUXXPRIME_LAST_TRADED_QUERY =  gql`
    ${securityLuxXPrimeSearchItemFragment}
    query GetLastTradedSecuritiesList($limit: Int) {
        lastTradedLuxXPrimeSecurities(limit: $limit) {
            ...securityLuxXPrimeSearchItemFragment
        }
    }
`;

export const GET_LUXXPRIME_MOST_TRADED_QUERY = gql`
    ${securityLuxXPrimeSearchItemFragment}
    query GetMostTradedSecuritiesList($limit: Int) {
        mostTradedLuxXPrimeSecurities(limit: $limit) {
            ...securityLuxXPrimeSearchItemFragment
        }
    }
`;

export const GET_LUXXPRIME_MOST_VIEWED_QUERY = gql`
    ${securityLuxXPrimeSearchItemFragment}
    query GetMostViewedLuxXPrimeSecurities($limit: Int) {
        mostViewedLuxXPrimeSecurities(limit: $limit) {
            ...securityLuxXPrimeSearchItemFragment
        }
    }
`;

export const GET_LUXXPRIME_RISERS_QUERY = gql`
    ${securityLuxXPrimeSearchItemFragment}
    query GetRisersSecuritiesList($limit: Int) {
        risersLuxXPrimeSecurities(limit: $limit) {
            ...securityLuxXPrimeSearchItemFragment
        }
    }
`;

export const GET_LUXXPRIME_FALLERS_QUERY = gql`
    ${securityLuxXPrimeSearchItemFragment}
    query GetFallersSecuritiesList($limit: Int) {
        fallersLuxXPrimeSecurities(limit: $limit) {
            ...securityLuxXPrimeSearchItemFragment
        }
    }
`;

export const GET_LUXXPRIME_LATEST_NOTICES_QUERY = gql`
    query GetLuxXPrimeLatestNotices($limit: Int) {
        luxXPrimeLatestFNS(limit: $limit) {
            id
            publishDate
            description
            documentTypeTranslatableField {
                key
                value
            }
            documentPublicTypeTranslatableField {
                key
                value
            }
            issuers {
                name
            }
            depositor {
                name
            }
        }
        newestLuxXPrimeSecurities(limit: $limit) {
            id
            isin
            currency
            luxxprimeDate
            instrumentName
            issuedNominalAmount
        }
        removedLuxXPrimeSecurities(limit: $limit) {
            id
            isin
            currency
            status
            luxxprimeDate
            luxxprimeEndDate
            instrumentName
            issuedNominalAmount
        }
        suspensionsLuxXPrimeSecurities(limit: $limit) {
            id
            isin
            currency
            suspensionDate
            suspensionReason
            instrumentName
            issuedNominalAmount
        }
    }
`;

export const GET_LUXXPRIME_LATEST_FNS_QUERY = gql`
    query GetLuxXPrimeLatestFNS($limit: Int) {
        luxXPrimeLatestFNS(limit: $limit) {
            id
            publishDate
            description
            downloadUrl
            documentTypeTranslatableField {
                key
                value
            }
            documentPublicTypeTranslatableField {
                key
                value
            }
            issuers {
                name
            }
            depositor {
                name
            }
        }
    }  
`;
  
export const GET_LUXXPRIME_LATEST_SUSPENSIONS_QUERY = gql`
    query GetLuxXPrimeSuspensionsSecurities($limit: Int) {
        suspensionsLuxXPrimeSecurities(limit: $limit) {
            id
            isin
            currency
            suspensionDate
            suspensionReason
            instrumentName
            issuedNominalAmount
            issuers {
                name
            }
        }
    }
`;

export const GET_LUXXPRIME_NEWEST_SECURITIES_QUERY = gql`
    query GetLuxXPrimeNewestSecurities($limit: Int) {
        newestLuxXPrimeSecurities(limit: $limit) {
            id
            isin
            currency
            luxxprimeDate
            instrumentName
            issuedNominalAmount
            issuers {
                name
            }
        }
    }
`;

export const GET_LUXXPRIME_UPCOMING_REMOVALS_QUERY = gql`
    query GetLuxXPrimeRemovedSecurities($limit: Int) {
        removedLuxXPrimeSecurities(limit: $limit) {
            id
            isin
            currency
            status
            luxxprimeDate
            luxxprimeEndDate
            delistingDate
            tags
            instrumentName
            issuedNominalAmount
            issuers {
                name
            }
        }
    }
`;