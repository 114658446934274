
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const CIV: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <path fill="#009A44" d="M341.333,88.273h161.839c4.875,0,8.828,3.953,8.828,8.828v317.793c0,4.875-3.953,8.828-8.828,8.828 H341.333V88.273z" />
            <rect x="170.67" y="88.277" fill="#FFFFFF" width="170.67" height="335.45" />
            <path fill="#FF8200" d="M8.828,88.273h161.839v335.448H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.1 C0,92.225,3.953,88.273,8.828,88.273z" />
        </svg>
    </React.Fragment>
);
