/** @jsx jsx */

import * as React from 'react';

import { ComponentParams, ComponentRendering, Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from '../../interfaces';

export interface BoardOfMembers {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
`;
const container = css`
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
  }
`;
const title = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #253645;
  margin-bottom: 56px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #253645;
  }
`;
const tableHeader = css`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #E3E6E8;
`;
const tableRow = css`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #E3E6E8;
`;
const left = (bold?: boolean) => css`
  width: calc(100% - 160px);
  font-weight: ${bold ? '700' : '400'};
  font-size: 13px;
  line-height: 17px;
  color: #54666F;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
`;
const right = (bold?: boolean) => css`
  width: 160px;
  font-weight: ${bold ? '700' : '400'};
  font-size: 13px;
  line-height: 17px;
  color: #54666F;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
`;
const headerLeft = css`
  width: calc(100% - 160px);
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  color: #54666F;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
`;
const headerRight = css`
  width: 160px;
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  color: #54666F;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
`;
const note = css`
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  color: #54666F;
  padding-top: 32px;
`;

export const BoardOfMembers: React.FunctionComponent<BoardOfMembers> = (props: BoardOfMembers): React.ReactElement => {
    const { fields, params, rendering } = props;

    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={tableHeader}>
                    <div css={headerLeft}>
                        <Text
                            field={fields && fields['column left label'] as SitecoreGenericTextField}
                        />
                    </div>
                    <div css={headerRight}>
                        <Text
                            field={fields && fields['column right label'] as SitecoreGenericTextField}
                        />
                    </div>
                </div>
                {
                    new Array(40).fill(0).map((arr: any, index: number) => {
                            if (
                                fields && fields[`row ${index + 1} name`] && (fields && fields[`row ${index + 1} name`] as any).value ||
                                fields && fields[`row ${index + 1} role`] && (fields && fields[`row ${index + 1} role`] as any).value
                            ) {

                                const leftBold: boolean = getFieldValue<boolean>(fields, `row ${index + 1} left bold`, false);
                                const rightBold: boolean = getFieldValue<boolean>(fields, `row ${index + 1} right bold`, false);
                                return (
                                    <div css={tableRow}>
                                        <div css={left(leftBold)}>
                                            <Text
                                                field={fields && fields[`row ${index + 1} name`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                        <div css={right(rightBold)}>
                                            <Text
                                                field={fields && fields[`row ${index + 1} role`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        }
                    )
                }
                <div css={note}>
                    <Text
                        field={fields && fields['* indicator'] as SitecoreGenericTextField}
                    />
                </div>
            </div>
        </div>
    );
}
