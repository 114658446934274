import { css } from "@emotion/react";

export const style = {
  noContent: css`
    padding: 2em;
    width: 100%;
    text-align: center;
    font-size: 2em;
    font-style: italic;
    font-weight: 500;
  `,
  pageLoading: css`
    padding: 5em;
    svg {
      max-width: 180px !important;
      width: 180px !important;
      max-height: 180px !important;
      height: 180px !important;
    }
  `,
  tabsContainer: css`
      width: 100%;
      @media (max-width: 720px) {
        > div > div {
          justify-content: flex-start;
          overflow-x: scroll;
        }
      }
    `,

  tabContent: css` 
    min-height: 100vh;
    h3 {
      font-size: 3em;
      color: #354450;
      margin: 0 0 1rem 0;
    }
  `,

  tabContentList: css`
    width: 100%;
    margin-top: -1em;
  `,

  tabMargin: css`
    margin: 2rem;
  `,

  header: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `
}