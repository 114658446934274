/** @jsx jsx */

import * as React from 'react';
import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { css, jsx } from '@emotion/react';
import hexaBottum from './assets/hexa.svg';
import hexaTop from './assets/hexa_top.svg';
import topLines from './assets/top_lines.svg';
import patternLeft from './assets/pattern_left.svg';
import hexaFull from './assets/hexa_full.svg';
import { breakpoint, breakpointMax } from '../../style';
import { renderLink } from '../../utils/helper';
import { 
    ComponentParams, 
    ComponentRendering, 
    getFieldValue, 
    Text,
 } from '@sitecore-jss/sitecore-jss-react';
import { canUseDOM } from '../../predicates';
import { SitecoreGenericTextField } from '../../interfaces';

export interface BannerComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const BannerComponent: React.FC<BannerComponentProps> = (
    props: BannerComponentProps
): React.ReactElement => {
    const { fields } = props;

    const buttonLabel: string = getFieldValue<string>(fields, "buttonLabel", "");
    const buttonLink: string = getFieldValue<string>(fields, "buttonLink", "");
    const background: any = getFieldValue<any>(fields, "backgroundImage", null);
    const gradientType = getFieldValue<any>(fields, "gradientColor", null);
    const displaySubtitleOnMobile = Boolean(getFieldValue<boolean>(fields, "displaySubtitleOnMobile", false)
    );

  const triggerAction = () => {
    if (buttonLink) {
      window.location.href = buttonLink;
    }
  };
    
    const bg = fields && fields.backgroundImage && fields.backgroundImage.value.src
      ? `, url(${renderLink(background && background.src)}) no-repeat`
      : "";
    
    const getBackground = () => {
        if (bg && !gradientType) {
          return `url(${renderLink(background && background.src)}) no-repeat`;
        }
  
        if (bg) {
          if (gradientType === "apple100") {
            return `linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)${bg}`;
          }
          if (gradientType === "apple500") {
            return `linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)${bg}`;
          }
          if (gradientType === "gradientGreen") {
            return `linear-gradient(83deg, #166F3E 1.21%, rgba(46, 157, 71, 0.94) 41.77%, rgba(199, 214, 77, 0.75) 65.16%, rgba(199, 214, 77, 0.00) 90.12%)${bg}`;
          }
          if (gradientType === "peach100") {
            return `linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)${bg}`;
          }
          if (gradientType === "peach500") {
            return `linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)${bg}`;
          }
          if (gradientType === "gradientPeach") {
            return `linear-gradient(83deg, rgba(248, 159, 121, 0.60) 0.17%, rgba(248, 159, 121, 0.00) 100%)${bg}`;
          }
        }
  
        if (gradientType === "apple100") {
          return `linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)`;
        }
        if (gradientType === "apple500") {
          return `linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)`;
        }
        if (gradientType === "gradientGreen") {
          return `linear-gradient(83deg, #166F3E 1.21%, rgba(46, 157, 71, 0.94) 41.77%, rgba(199, 214, 77, 0.75) 65.16%, rgba(199, 214, 77, 0.00) 90.12%)`;
        }
        if (gradientType === "peach100") {
          return `linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)`;
        }
        if (gradientType === "peach500") {
          return `linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)`;
        }
        if (gradientType === "gradientPeach") {
          return `linear-gradient(83deg, rgba(248, 159, 121, 0.60) 0.17%, rgba(248, 159, 121, 0.00) 100%)`;
        }
        return bg;
        
      };

    const _breakpoint = '1304px';

    const style = {
    mainContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    @media (max-width: ${breakpoint}) {
      flex-direction: column;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      height: auto;
    }
    `,

    container: css`
    position: relative;
    background: ${getBackground()};
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 180px;
    width: 100%;
    max-width: 1456px;
    border-radius: 8px;
    overflow: hidden;
    @media (max-width: ${_breakpoint}) {
      height: ${displaySubtitleOnMobile ? "250px" : "200px"};
      width: 65%;
    }
    @media (max-width: ${breakpoint}) {
      width: 85%;
      height: ${displaySubtitleOnMobile ? "250px" : "200px"};
    }
    @media (max-width: 598px) {
      height: ${displaySubtitleOnMobile ? "380px" : "250px"};
      flex-direction: column;
    }
    :hover {
      cursor: pointer;
    }
  `,
    infoContainer: css`
    z-index: 1;
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: normal;
    gap: 20px;
    @media (max-width: ${_breakpoint}) {
      width: 65%;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 5%;
      padding-top: 5%;
      padding-bottom: 5%;
      justify-content: center;
      gap: 10px;
    }
    @media (max-width: 598px) {
      width: 100%;
      padding-top: 10%;
      padding-bottom: unset;
      padding-left: 10%;
      padding-right: 10%;
    }
  `,
    logoContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    min-width: 26%;
    height: 100%;
    padding-left: 40px;
    @media (max-width: ${_breakpoint}) {
      width: 100%;
      justify-content: flex-start;
      height: 50%;
      padding-left: 0;
    }
  `,

   logo: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      position: absolute;
      max-height: 100%;
      max-width: 100%;
    }
    @media (width < ${breakpoint}) {
      position: absolute;
      max-height: 50%;
      max-width: 45%;
      padding-top: 20px;
    }
    
     @media (width = ${breakpointMax}) {
      position: absolute;
      max-height: 50%;
      max-width: 28%;
      padding-top: 35px;
    }

    @media (min-width: ${breakpoint}) and (max-width: ${_breakpoint}) {
      position: absolute;
      max-height: 50%;
      max-width: 25%;
      padding-top: 45px;
    }
  `,
  
    descriptionContainer: css`
    width: 70%;
    min-width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    padding-right: 25px;
    margin-top: 20px;
    @media (min-width: ${breakpoint}) and (max-width: ${_breakpoint}) {
      width: 90%;
      padding: 0;
      margin-top: 40px;
    }
    @media (max-width: 859px) {
      font-size: 26px;
      font-weight: 700;
      line-height: 32px;
    }
    @media (max-width: ${breakpoint}) {
      font-size: 23px;
      font-weight: 700;
      line-height: 29px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      width: 100%;
    }
  `,
    title: css`
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 20px;
    text-align: left;
    color: #ffffff;
    @media (max-width: ${breakpointMax}) {
        font-weight: 800;
        font-size: 23px;
        line-height: 29px;
        margin-bottom: ${displaySubtitleOnMobile ? "5px" : "20px"};
    }
    
    @media (min-width: ${breakpoint}) and (max-width: ${_breakpoint}) {
      padding-left: 0;
      margin-bottom: ${displaySubtitleOnMobile ? "5px" : "20px"};
    }
    `,

    subtitle: css`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
    color: #ffffff;
    @media (max-width: ${_breakpoint}) {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 0;
        display: ${displaySubtitleOnMobile ? "block" : "none"};
    }
    `,

    actionContainer: css`
    z-index: 1;
    width: 20%;
    min-width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    button {
      color: rgba(53, 68, 80, 1);
      font-family: Mont;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }
    @media (min-width: 1841px) {
      width: 35%;
    }
    @media (min-width: ${breakpoint}) and (max-width: ${_breakpoint}) {
      width: 45%;
    }
    @media (width < ${breakpoint}) {
      width: 100%;
      padding-left: 10%;
      // padding-bottom: 10%;
    }
    
    @media (width = ${breakpointMax}) {
      width: 65%;
      padding-left: 10%;
      // padding-bottom: 10%;
    }

  `,
    hexaBottom: css`
    z-index: 1;
    position: absolute;
    bottom: -15px;
    left: 24%;
    @media (max-width: 598px) {
      display: none;
    }
  `,
    hexaFull: css`
    z-index: 1;
    position: absolute;
    top: 15%;
    left: 40%;
    @media (min-width: 598px) {
      display: none;
    }
  `,
    hexaTop: css`
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 12%;
  `,
    topLines: css`
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 46%;
    @media (max-width: 598px) {
      display: none;
    }
  `,
    patternLeft: css`
    z-index: 1;
    position: absolute;
    bottom: -7px;
    left: 0%;
  `,
    };

    return (
        <div css={style.mainContainer}>
            <div css={style.container} onClick={() => triggerAction()}>
                <div css={style.patternLeft}>
                    <img src={renderLink(patternLeft)} alt={'image'} />
                </div>
                <div css={style.topLines}>
                    <img src={renderLink(topLines)} alt={'image'} />
                </div>
                <div css={style.hexaBottom}>
                    <img src={renderLink(hexaBottum)} alt={'image'} />
                </div>
                <div css={style.hexaFull}>
                    <img src={renderLink(hexaFull)} alt={'image'} />
                </div>
                <div css={style.hexaTop}>
                    <img src={renderLink(hexaTop)} alt={'image'} />
                </div>
                <div css={style.infoContainer}>
                    <div css={style.logoContainer}>
                        <div css={style.logo}>
                            <img
                                src={renderLink((fields && (fields.icon as any)).value.src)}
                                alt="image"
                            />
                        </div>
                    </div>
                    <div css={style.descriptionContainer}>
                        <div css={style.title}>
                            <Text field={fields && (fields.title as SitecoreGenericTextField)} />
                        </div>
                        <div css={style.subtitle}>
                            <Text field={fields && (fields.subtitle as SitecoreGenericTextField)} />
                        </div>
                    </div>
                </div>
                <div css={style.actionContainer}>
                    <Button label={buttonLabel} variant='thirdary' iconRight={'chevron-right'} />
                </div>
            </div>
        </div>
    );
};

