/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Button, Tab, Tabs } from "@bdl-cmn-shared-packages-npm/design-system";
import React, { useEffect, useState } from "react";

import { IndexDetailsBasketTable } from "./basket/table";
import Loader from "../../Loader/loader.component";
import { canUseDOM } from "../../../../predicates";
import { style } from "./index.style";
import { IndexMeta } from "./meta";
import { IndiceSummary } from "../../../../interfaces/indice";
import { GET_INDICE_QUERY } from "../../../../graphql";
import { goToNotFoundPage } from "../../404";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { IndexBanner } from "./banner";
import { IndexReferenceData } from "./reference-data";
import { IndexChart } from "./chart";
import { PriceColumnProps } from "../../UI/list/security/price";
import { PriceInformation } from "../../../../interfaces";
import { getIndiceDescription } from "../../../../enums/index-description";
import { IndexTypeEnum } from "../../../../enums/index-type";
import Utils from "../../../utils/utils";

export interface IndiceCardProps {
  fields?: any;
  match?: any;
  id: string;
}

export const indexDetailPageLink = (id: string) => {
  return `/market-overview/luxse-index/${id}`;
};

export const goToIndexDetailPage = (id: string) => {
  if (canUseDOM) {
    window.location.href = indexDetailPageLink(id);
  }
};

export enum IndexCardTab {
  OVERVIEW = "OVERVIEW",
  BASKET = "BASKET",
}

export const IndexDetailsView: React.FunctionComponent<any> = ({
  id,
  fields,
}: IndiceCardProps): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const [indice, setIndice] = useState<IndiceSummary>(null);
  const [documentUrl, setDocumentUrl] = useState<string>(null);

  const [pricesHistory, setPricesHistory] = useState<PriceInformation[]>([]);
  const [priceColumn, setPriceColumn] = useState<PriceColumnProps>(null);
  const [indiceDescription, setIndiceDescription] = useState<string>(null);
  const [currentTab, setCurrentTab] = useState<IndexCardTab>(
    IndexCardTab.OVERVIEW
  );

  const client = luxseGatewayGraphqlClient;

  const fetchIndice = async () => {
    let indiceTmp: IndiceSummary = null;
    let priceColumnTmp: PriceColumnProps = null;
    let pricesHistoryTmp: PriceInformation[] = [];
    try {
      if (!loading) setLoading(true);

      const results: any = await client.query({
        query: GET_INDICE_QUERY,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          id: id,
        },
      });

      indiceTmp = results?.data?.indexInformation || null;
      priceColumnTmp = {
        amount: indiceTmp?.indexPrice?.lastPrice?.amount,
        marker: indiceTmp?.indexPrice?.lastPrice?.marker || "",
        currency: indiceTmp?.currency || "",
        changePercent: indiceTmp?.indexPrice?.lastPrice?.changePercent,
        date: indiceTmp?.indexPrice?.lastPrice?.date,
      };
      pricesHistoryTmp = indiceTmp?.indexPrice?.pricesHistory || null;
    } catch (e) {
      console.error("Failed to get indice '" + id + "'", e);
    }

    if (!indiceTmp) {
      goToNotFoundPage();
    } else {
      if (fields[indiceTmp.indexName]) {
        const field = fields[indiceTmp.indexName];
        if (field.value?.src && typeof field.value?.src === "string")
          setDocumentUrl(field.value?.src);
        else if (field.src && typeof field.src === "string")
          setDocumentUrl(field.src);
        else if (field.value && typeof field.value === "string")
          setDocumentUrl(field.value);
      }

      setPriceColumn(priceColumnTmp);
      setPricesHistory(pricesHistoryTmp);
      setIndiceDescription(
        getIndiceDescription(
          indiceTmp?.codeType === IndexTypeEnum.RDM ? "RDM" : id
        )
      );
      setIndice(indiceTmp);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchIndice();
  }, []);

  return (
    <Loader loading={loading}>
      <IndexMeta indice={indice} />
      <IndexBanner indice={indice} priceColumn={priceColumn} />

      <Tabs tabPosition={"center"} padding={"1em 2em"}>
        <Tab
          label="Overview"
          selected={currentTab === IndexCardTab.OVERVIEW}
          onClickAction={() => setCurrentTab(IndexCardTab.OVERVIEW)}
          onTabChange={() => setCurrentTab(IndexCardTab.OVERVIEW)}
        >
          {documentUrl || indiceDescription ? (
            <div css={style.descriptionContainer}>
              <h2 css={style.blocTitle}>Description</h2>
              {indiceDescription ? (
                <div css={style.textContainer}>
                  <p>{indiceDescription}</p>
                </div>
              ) : (
                <></>
              )}
              {documentUrl ? (
                <Button
                  css={style.button}
                  onClick={() =>
                    canUseDOM ? (window.location.href = documentUrl) : ""
                  }
                  label={indice?.indexName}
                  iconLeft="file-pdf"
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          <div css={style.chartPriceContainer}>
            <IndexChart
              pricesHistory={pricesHistory}
              priceColumn={priceColumn}
            />

            <IndexReferenceData indice={indice} />
          </div>

          {indice?.basket?.length && (
            <div css={style.basketContainer}>
              <IndexDetailsBasketTable
                basket={indice?.basket}
                partialDisplay={true}
                handleClick={() => setCurrentTab(IndexCardTab.BASKET)}
              />
            </div>
          )}
        </Tab>
        <Tab
          label="Basket"
          selected={currentTab === IndexCardTab.BASKET}
          onClickAction={() => setCurrentTab(IndexCardTab.BASKET)}
          onTabChange={() => setCurrentTab(IndexCardTab.BASKET)}
          disabled={!indice?.basket?.length}
        >
          <IndexDetailsBasketTable
            basket={indice?.basket}
            partialDisplay={false}
          />
        </Tab>
      </Tabs>
    </Loader>
  );
};

export default IndexDetailsView;
