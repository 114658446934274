import * as React from 'react';

import { breakpoint, breakpointMax } from '../../../style';

import { css } from '@emotion/react';

export const style = {
  noContent: css`
    padding: 2em;
    width: 100%;
    text-align: center;
    font-size: 2em;
    font-style: italic;
    font-weight: 500;
  `,
  pageLoading: css`
    padding: 5em;
    svg {
      max-width: 180px !important;
      width: 180px !important;
      max-height: 180px !important;
      height: 180px !important;
    }
  `,
  tabsContainer: css`
    width: 100%;
    > div > div:last-of-type {
      padding-top: 0px;
    }

    @media (max-width: ${breakpointMax}) {
      > div > div {
        justify-content: flex-start;
      }

      > div > div:first-of-type {
        overflow-x: auto
        ;

        > div {
          width: 33%;
          text-align: center;
        }
      }
    }
  `,
};
