
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import {
    Text,
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    getFieldValue,
    RichText
} from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {LuxXPrimeDiscover} from "../LuxXPrimeDiscover";
import {DiscoverAd} from "../DiscoverAd";

export interface ArticleComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
`;
const container = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
`;
const article = css`
  display: flex;
  width: 100%;
`;
const content = css`
  display: flex;
  width: 100%;
`;
const ad = css`
  display: flex;
  min-width: 33%;
  justify-content: center;
`;
const adContent = css`
  display: flex;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
`;

export const ArticleComponent: React.FunctionComponent<ArticleComponentProps> = (props: ArticleComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const hasLuxXPrimeAd: number = getFieldValue<number>(fields, 'hasLuxXPrimeAd', 0);

    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={article}>
                    <div css={content}>
                        <RichText
                            field={fields && fields.content as SitecoreGenericTextField}
                        />
                    </div>
                    {
                        hasLuxXPrimeAd && (
                            <div css={ad}>
                                <div css={adContent}>
                                    <DiscoverAd
                                        title={'The new bond trading destination'}
                                        subTitle={'Competitive spreads, Firms prices, Quality retail-size trading'}
                                        vertical/>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
