
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const TWN: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <rect y="85.337" fill="#D80027" width="512" height="341.326" />
            <rect y="85.337" fill="#0052B4" width="256" height="170.663" />
            <polygon fill="#F0F0F0" points="186.435,170.669 162.558,181.9 175.272,205.025 149.345,200.064 146.059,226.256 128,206.993 109.94,226.256 106.655,200.064 80.728,205.024 93.442,181.899 69.565,170.669 93.442,159.438 80.728,136.313 106.655,141.273 109.941,115.081 128,134.344 146.06,115.081 149.345,141.273 175.273,136.313 162.558,159.438 " />
            <circle fill="#0052B4" cx="128" cy="170.674" r="29.006" />
            <path fill="#F0F0F0" d="M128,190.06c-10.692,0-19.391-8.7-19.391-19.391c0-10.692,8.7-19.391,19.391-19.391 c10.692,0,19.391,8.7,19.391,19.391C147.391,181.36,138.692,190.06,128,190.06z" />
        </svg>
    </React.Fragment>
);