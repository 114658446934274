/** @jsx jsx */
/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import { Button, Icon, Table, Tbody, Td, Th, Thead, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import { genericTradingDataStyle } from "../../DataMarket/TradingData/Shared/generic-trading-data.style";
import { sharedStyle } from "../../../../styles/shared";
import { style } from "../market-statistics.style";
import WidgetCard from "../../WidgetCard";
import MarketCapDataModel from "../Interfaces/MarketCapModel";
import { tradingStyle } from "../../DataMarket/MarketData/trading-data.style";
import MarketCapBarChart from "../Charts/MarketCapBarChart";
import { BrowserRouter } from "react-router-dom";
import LuxSharesCard from "../Cards/LuxSharesCard";
import { GET_MARKET_CAPITALISATION_LUXX_COMPONENTS, GET_MARKET_CAPITALISATION_LUX_SHARES, GET_MARKET_CAPITALISATION_STATISTICS } from "../../../../graphql/queries/market.queries";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { canUseDOM } from "../../../../predicates";

export class MarketCapLuxComponents {
    totalMarketCap:Number;
    listMarketCap:MarketCapDataModel[];
}


export class TopMarketCapModel {
    topLuxSharesCap?: MarketCapDataModel[];
    topIssuedShares?: MarketCapDataModel[];
    topLuxxComponent?: MarketCapLuxComponents;
}
export class TopLuxSharesModel {
    bdlMarket?: number;
    euroMTF?: number;
    totalMarketCap?: number;
}

export function MarketCapHome(props: any) {

    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [loader, setLoader] = useState<boolean>(true);
    const [luxXComponent, setLuxXComponent] = useState<MarketCapLuxComponents>({totalMarketCap:0,listMarketCap:[]});

    const [luxShares, setLuxShares] = useState<TopLuxSharesModel>({
        bdlMarket: 0,
        euroMTF: 0,
        totalMarketCap: 0
    });


    const client = luxseGatewayGraphqlClient;



    const fetchLuxSharesData = async () => {
        try {
            if (!loader)
                setLoader(true);

            const results: any = await client.query({
                query: GET_MARKET_CAPITALISATION_STATISTICS,
            });

            console.log(results);


            luxShares.bdlMarket = results?.data?.marketCapitalisationStatistics?.nbBdlMarket;
            luxShares.euroMTF = results?.data?.marketCapitalisationStatistics?.nbEuroMTF;
            luxShares.totalMarketCap = Number((results?.data?.marketCapitalisationStatistics?.totalMarketCap / 1000000000)?.toFixed(2));

            setLuxShares(luxShares);

            setLoader(false);
        } catch (e) {
            console.error('Failed to get market capitalisation statistics data', e);
            setLoader(false);
        }
    }


    const fetchluxXComponentData = async () => {
        try {
            if (!dataLoading)
                setDataLoading(true);

            const results: any = await client.query({
                query: GET_MARKET_CAPITALISATION_LUXX_COMPONENTS,
            });


            luxXComponent.totalMarketCap=results?.data?.marketCapitalisationForLuxXComponents?.totalMarketCap;


            results?.data?.marketCapitalisationForLuxXComponents?.listMarketCap?.map((element: MarketCapDataModel) => {
                luxXComponent.listMarketCap.push(element);
            });
            setLuxXComponent(luxXComponent);

            setDataLoading(false);
        } catch (e) {
            console.error('Failed to get lux components data', e);
            setDataLoading(false);
        }
    }

    useEffect(() => {
        fetchluxXComponentData();
        fetchLuxSharesData();
    }, []);


    return (
        <>
            <div css={[style.marketCapContent, css`
                    margin-top:1rem;
                `]}>
                <div css={style.marketCapHeader}>
                    <div css={style.marketCapLabel}>Market capitalisation</div>
                </div>
                <div css={[sharedStyle.row, css`margin-bottom:5px;`]}>
                    <WidgetCard
                        loader={loader}
                        icon='stairs'
                        iconType={'fas'}
                        label='Lux shares'
                        isHover={false}
                        link={
                            <div css={sharedStyle.link_container}>
                                <BrowserRouter forceRefresh={true}>
                                    <a css={sharedStyle.link_data_market} href={'/market-overview/market-statistics/market-capitalisation'}
                                    >
                                        Consult all Lux shares data &nbsp;
                                        <Icon icon={'chevron-right'} />
                                    </a>
                                </BrowserRouter>
                            </div>
                        }
                    >
                        {!loader ? (
                            <LuxSharesCard data={luxShares} />
                        ) : (
                            <div css={tradingStyle.emptyResult}>No data available</div>
                        )}

                    </WidgetCard>
                    <WidgetCard
                        loader={dataLoading}
                        icon='stairs'
                        iconType={'fas'}
                        label='LuxX components'
                        isHover={false}
                        link={
                            <div css={sharedStyle.link_container}>
                                <BrowserRouter forceRefresh={true}>
                                    <a css={sharedStyle.link_data_market} href={'/market-overview/market-statistics/market-capitalisation'}
                                    >
                                        Consult LuxX components details &nbsp;
                                        <Icon icon={'chevron-right'} />
                                    </a>
                                </BrowserRouter>
                            </div>
                        }
                    >
                        {!dataLoading ? (
                            <MarketCapBarChart sortedData={luxXComponent} />
                        ) : (
                            <div css={tradingStyle.emptyResult}>No data available</div>
                        )}
                    </WidgetCard>
                </div>
                <div css={css`width:100%;margin-top:5px;padding-bottom:20px;margin-bottom:20px;`}>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                if (canUseDOM)
                                    window.location.href = "/market-overview/market-statistics/market-capitalisation";
                            }}
                            wired={false}
                            iconRight="chevron-right"
                            label={`See all market capitalisation data`}
                            css={css`margin-right:auto;margin-left:auto;`}
                        />
                </div>
            </div>
        </>
    );
}

export default MarketCapHome;
