export enum SlbTypeOfChangeEnum {
	COUPON_STEP_UP = "Coupon step-up", //
	COUPON_STEP_DOWN = "Coupon step-down", //
	CHANGE_IN_MATURITY = "Change in maturity", //
	INCREASE_IN_REDEMPTION_PRICE = "Increase in redemption price", //
	DECREASE_IN_REDEMPTION_PRICE = "Decrease in redemption price", //
	PREMIUM_PAYMENT = "Premium payment", //
	OTHER_CHANGE = "Other" //
}

export function slbTypeOfChangeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(SlbTypeOfChangeEnum).indexOf(value.toString().toUpperCase());
		return index >= 0 ? Object.values(SlbTypeOfChangeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get SlbTypeOfChangeEnum label for '" + value + "'", e);
	}
		
	return value;
}