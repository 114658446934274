export enum MarketTransfersEnum {
	MFX = "BDL Market",
	MFL = "Euro-MTF",
	SOL = "Lux SOL",
}

export function marketTransfersLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(MarketTransfersEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(MarketTransfersEnum)[index] : value;
	}catch (e){
		console.error("Failed to get MarketTransfersEnum label for '" + value + "'", e);
	}
		
	return value;
}