import React from "react";
import { TradingNewsBBOCard } from "./card-bbo";
import { TradingNewsGenericCard } from "./card-generic";
import { TradingNewsFilterEnum } from ".";
import { jsx } from "@emotion/react";
import { marketNewsStyle } from "../MarketNews/index.style";

/** @jsx jsx */

const TradingNewsCard = (props: any) => {
  const type = props?.type;
  const isBBOType: boolean = (TradingNewsFilterEnum.equities === type || TradingNewsFilterEnum.fixedIncome === type || TradingNewsFilterEnum.funds === type );
  return (
    <div css={marketNewsStyle.container}>
      {isBBOType? 
          props.row?.data?.map((x: any) => {
            return <TradingNewsBBOCard row={x} key={`generic-bbo-${type}-${x?.id}`}/>;
          })
        : props.row?.data?.map((x: any) => {
            return <TradingNewsGenericCard row={x} key={`generic-card-${type}-${x?.id}`}/>;
          })}
    </div>
  );
};

export default TradingNewsCard;
