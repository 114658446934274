
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const SRB: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M0,311.908h512v102.988c0,4.875-3.953,8.828-8.828,8.828H8.828c-4.875,0-8.828-3.953-8.828-8.828 V311.908z" />
            <path fill="#C6363C" d="M8.828,88.276h494.345c4.875,0,8.828,3.953,8.828,8.828v102.988H0V97.103 C0,92.228,3.953,88.276,8.828,88.276z" />
            <rect y="200.09" fill="#0C4076" width="512" height="111.81" />
            <polygon fill="#FFE15A" points="173.021,200.07 109.462,200.07 106.814,184.18 175.669,184.18 " />
            <polygon fill="#FFD350" points="108.8,196.097 109.462,200.07 173.021,200.07 173.683,196.097 " />
            <g>
                <rect x="139.92" y="147.1" fill="#FFE15A" width="2.648" height="10.593" />
                <rect x="137.27" y="149.75" fill="#FFE15A" width="7.945" height="2.648" />
                <path fill="#FFE15A" d="M173.228,189.476c-0.3,0-0.604-0.052-0.903-0.158c-1.375-0.499-2.086-2.017-1.587-3.393l5.61-15.481 c0.278-0.768,0.212-1.613-0.18-2.319c-0.209-0.375-0.695-1.035-1.624-1.234c-6.839-1.453-20.096-3.897-32.023-3.897h-2.558 c-11.927,0-25.184,2.444-32.026,3.9c-0.926,0.196-1.414,0.856-1.622,1.231c-0.392,0.706-0.458,1.551-0.179,2.319l5.608,15.481 c0.499,1.376-0.212,2.894-1.587,3.393c-1.374,0.491-2.895-0.215-3.392-1.588l-5.61-15.481c-0.8-2.209-0.609-4.648,0.526-6.694 c1.097-1.978,2.973-3.38,5.149-3.843c7.047-1.5,20.718-4.017,33.131-4.017h2.558c12.413,0,26.084,2.516,33.128,4.013 c2.179,0.466,4.055,1.867,5.152,3.846c1.135,2.046,1.326,4.484,0.527,6.694l-5.611,15.481 C175.328,188.807,174.31,189.476,173.228,189.476z" />
                <path fill="#FFE15A" d="M141.251,187.159c-1.462,0-2.648-1.184-2.648-2.648v-21.517c0-1.464,1.186-2.648,2.648-2.648 s2.648,1.184,2.648,2.648v21.517C143.9,185.974,142.714,187.159,141.251,187.159z" />
                <path fill="#FFE15A" d="M159.559,189.476c-0.179,0-0.36-0.018-0.541-0.054c-1.432-0.298-2.353-1.699-2.055-3.132 l3.212-15.481c0.418-2.017-0.236-3.784-0.608-4.094c-3.672-1.347-11.039-3.722-17.594-3.722h-1.465 c-6.554,0-13.921,2.375-17.73,3.791c-0.235,0.241-0.889,2.007-0.472,4.024l3.212,15.481c0.298,1.432-0.622,2.835-2.055,3.132 c-1.434,0.277-2.836-0.623-3.13-2.056l-3.212-15.481c-0.8-3.859,0.428-8.801,3.808-10.063c5.063-1.882,12.565-4.125,19.578-4.125 h1.465c7.014,0,14.516,2.242,19.576,4.122c3.383,1.265,4.611,6.207,3.811,10.066l-3.212,15.481 C161.889,188.618,160.787,189.476,159.559,189.476z" />
            </g>
            <polygon fill="#FFD350" points="107.476,188.152 175.007,188.152 175.669,184.18 106.814,184.18 " />
            <path fill="#C6363C" d="M90.924,200.07c-1.462,0-2.648,1.186-2.648,2.648v92.486c0,29.365,23.713,53.17,52.966,53.17 s52.966-23.805,52.966-53.17v-92.486c0-1.462-1.186-2.648-2.648-2.648L90.924,200.07L90.924,200.07z" />
            <path fill="#FFFFFF" d="M146.538,287.464l5.297-13.241l-10.593,2.648l-10.593-2.648l5.297,13.241l-13.241,45.021 c0,0,7.945,5.297,18.538,5.297s18.538-5.297,18.538-5.297L146.538,287.464z" />
            <polygon fill="#FFE15A" points="122.061,298.135 117.388,299.304 127.667,280.803 123.037,278.232 110.552,300.704 98.869,300.704 98.869,306.001 104.559,306.001 97.215,311.876 100.523,316.014 106.762,311.022 104.301,318.404 109.326,320.079 114.163,305.568 123.346,303.272 " />
            <path fill="#FFFFFF" d="M122.703,226.552l-12.089-6.045c-2.22-1.11-3.372-3.614-2.77-6.022l0.684-2.735 c0.474-1.898-0.961-3.736-2.918-3.736c-0.914,0-1.778,0.415-2.348,1.129c-6.273,7.841-9.69,17.583-9.69,27.625v55.991 c0,0,11.059-2.211,21.048-16.742c1.834-2.668,4.853-4.247,8.072-4.412l-5.286,15.857L128,290.111l9.269-11.03L122.703,226.552z" />
            <polygon fill="#FFE15A" points="160.422,298.135 165.095,299.304 154.816,280.803 159.446,278.232 171.93,300.704 183.614,300.704 183.614,306.001 177.923,306.001 185.268,311.876 181.96,316.014 175.721,311.022 178.182,318.404 173.156,320.079 168.32,305.568 159.137,303.272 " />
            <path fill="#FFFFFF" d="M159.779,226.552l12.089-6.045c2.22-1.11,3.372-3.614,2.77-6.022l-0.684-2.735 c-0.474-1.898,0.961-3.736,2.918-3.736c0.914,0,1.778,0.415,2.348,1.129c6.273,7.841,9.69,17.583,9.69,27.625v55.991 c0,0-11.059-2.211-21.048-16.742c-1.834-2.668-4.853-4.247-8.072-4.412l5.286,15.857l-10.593,2.648l-9.269-11.03L159.779,226.552z" />
            <g>
                <polygon fill="#FFE15A" points="125.352,208.014 112.11,208.014 116.083,213.311 125.352,215.959 120.055,218.607 128,218.607 " />
                <polygon fill="#FFE15A" points="157.131,208.014 170.372,208.014 166.4,213.311 157.131,215.959 162.428,218.607 154.483,218.607 " />
            </g>
            <path fill="#FFFFFF" d="M158.996,205.938l-12.458,2.076l-5.297,10.593l-5.297-10.593l-12.458-2.076 c-1.428-0.238-2.524,1.245-1.876,2.54l6.39,12.778l-5.297,5.297h18.538h18.538l-5.297-5.297l6.389-12.778 C161.519,207.182,160.424,205.699,158.996,205.938z" />
            <circle fill="#FFBE50" cx="141.24" cy="185.34" r="3.972" />
            <ellipse fill="#0C4076" cx="141.19" cy="194.82" rx="3.139" ry="2.207" />
            <ellipse fill="#C6363C" cx="125.52" cy="194.82" rx="3.139" ry="2.207" />
            <g>
                <ellipse fill="#0C4076" cx="109.85" cy="194.82" rx="3.139" ry="2.207" />
                <ellipse fill="#0C4076" cx="172.52" cy="194.82" rx="3.139" ry="2.207" />
            </g>
            <ellipse fill="#C6363C" cx="156.85" cy="194.82" rx="3.139" ry="2.207" />
            <g>
                <circle fill="#FFBE50" cx="159.65" cy="185.34" r="3.972" />
                <circle fill="#FFBE50" cx="174.17" cy="185.34" r="3.972" />
                <circle fill="#FFBE50" cx="122.86" cy="185.34" r="3.972" />
                <circle fill="#FFBE50" cx="108.33" cy="185.34" r="3.972" />
            </g>
            <path fill="#0C4076" d="M145.803,162.994c0.455-0.781,0.735-1.678,0.735-2.648c0-2.925-2.371-5.297-5.297-5.297 s-5.297,2.371-5.297,5.297c0,0.97,0.279,1.867,0.735,2.648H145.803z" />
            <path fill="#C6363C" d="M141.241,280.842c-10.953,0-19.862-8.909-19.862-19.862v-35.752h39.724v35.752 C161.103,271.934,152.194,280.842,141.241,280.842z" />
            <path fill="#A54B50" d="M159.779,226.552v34.428c0,10.238-8.3,18.538-18.538,18.538s-18.538-8.3-18.538-18.538v-34.428 H159.779 M162.428,223.904h-2.648h-37.076h-2.648v2.648v34.428c0,11.682,9.504,21.186,21.186,21.186 c11.682,0,21.186-9.504,21.186-21.186v-34.428V223.904L162.428,223.904z" />
            <g>
                <path fill="#FFFFFF" d="M137.269,279.08c1.281,0.28,2.607,0.437,3.972,0.437s2.691-0.158,3.972-0.437v-52.529h-7.945 C137.269,226.551,137.269,279.08,137.269,279.08z" />
                <rect x="122.7" y="248.4" fill="#FFFFFF" width="37.08" height="7.945" />
            </g>
        </svg>
    </React.Fragment>
);
