export enum OAMCategoryEn {
  DANU = "Deleted",
  DFSM = "Source file modified",
  DINI = "Initial document",
  DLCM = "Title addition modified",
  DLGM = "Language modified",
  DTDM = "Document type modified",
  IANU = "Cancelled",
  IINI = "Initial period",
  IPRM = "Reference period modified",
  IRLI = "Replace filing(s)",
  IRPI = "Replaced by filing n°",
  SIZEUNIT= "Kb",
  OBSOLETE = "Obsolete"
}

export enum OAMCategoryFr {
  DANU = "Supprimé",
  DFSM = "Fichier source modifié",
  DINI = "Document initial",
  DLCM = "Libellé complémentaire modifié",
  DLGM = "Langue modifié",
  DTDM = "Type de document",
  IANU = "Annulé",
  IINI = "Période initiale",
  IPRM = "Période de référence modifiée",
  IRLI = "Remplace le(s) dépôt(s)",
  IRPI = "Remplacé par le dépôt n°",
  SIZEUNIT= "Ko",
  OBSOLETE = "Obsolète"
}

export function OAMCategoryLabel(value: string, lang: string): string {
	if( lang?.toUpperCase() === "FR")
    return OAMCategoryLabelFr(value);
  else
    return OAMCategoryLabelEn(value);
}

export function OAMCategoryLabelEn(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(OAMCategoryEn).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(OAMCategoryEn)[index] : value;
	}catch (e){
		console.error("Failed to get OAMCategoryEn label for '" + value + "'", e);
	}
		
	return value;
}

export function OAMCategoryLabelFr(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(OAMCategoryFr).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(OAMCategoryFr)[index] : value;
	}catch (e){
		console.error("Failed to get OAMCategoryFr label for '" + value + "'", e);
	}
		
	return value;
}