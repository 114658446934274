import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import { Text, ComponentFields } from "@sitecore-jss/sitecore-jss-react";
import { SitecoreGenericTextField } from "../../interfaces";

import {
  Menu,
  MenuWithChild,
  Box,
  Flex,
  Card,
  CategoryLabel,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Grid,
  Row,
  Col,
  Td,
  Tr,
  Checkbox,
  Table,
  Thead,
  Th,
  Tbody,
  TrGroup
} from "@bdl-cmn-shared-packages-npm/design-system";

export interface NavigationProps {
  fields: any;
}

//align-items and justify content should not be needed
//if it was the default value in the design system Flex comp
const navigationMenuHeader = css`
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #253645;
  text-transform: lowercase;
`;

const navigationMenuContainer = css`
  width: 100%;
  height: calc(100% - 80px);
  padding: 1rem;
  background: #425563;
`;

const navigationContent = css`
  width: calc(100% - 286px);
  height: 100%;
`;

const navigationContentHeader = css`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: solid 1px rgba(0, 0, 0, 0.05);
  border-right: solid 1px rgba(0, 0, 0, 0.05);
  align-items: center;
  height: 80px;
  font-size: 1rem;
  background: #ffffff;
`;

const navigationContentContainer = css`
  width: 100%;
  height: 100%;
  padding: 1rem;
  background: #d9e1e2;
  font-size: 1rem;
`;

export const Navigation: React.FunctionComponent<NavigationProps> = ({
  fields
}: NavigationProps): React.ReactElement => {
  return (
    <Flex>
      <Box width={286}>
        <Flex height={80} fontSize="1rem" css={navigationMenuHeader}>
          <Text field={fields && fields.heading as SitecoreGenericTextField} />
        </Flex>
        <div css={navigationMenuContainer}>
          <CategoryLabel>Category 1</CategoryLabel>
          <MenuWithChild label={"List Item"} icon={"user"}>
            <Menu childLabel={"Menu 1"} />
            <Menu childLabel={"Menu 2"} />
            <Menu childLabel={"Menu 3"} selected />
            <Menu childLabel={"Menu 4"} />
            <Menu childLabel={"Menu 5"} />
          </MenuWithChild>
          <CategoryLabel>Category 2</CategoryLabel>
          <MenuWithChild label={"List Item 2"} icon={"user"} />
          <MenuWithChild label={"List Item 3"} icon={"user"} />
          <MenuWithChild label={"List Item 4"} icon={"user"} />
          <MenuWithChild label={"List Item 5"} icon={"user"} />
        </div>
      </Box>
      <div css={navigationContent}>
        <div css={navigationContentHeader}>
          <Breadcrumb>
            <BreadcrumbItem icon="home" link="/" root />
            <BreadcrumbItem label="Level 1" link="/test" />
            <BreadcrumbItem label="Level 2" link="/test2" />
            <BreadcrumbItem label="Level 3" />
          </Breadcrumb>
        </div>
        <div css={navigationContentContainer}>
          <Grid>
            <Row>
              <Col mobile={12}>
                <Table density="low" sortable>
                  <Thead>
                    <Tr>
                      <Th>TheadCol 1</Th>
                      <Th>TheadCol 2</Th>
                      <Th>TheadCol 3</Th>
                      <Th>TheadCol 4</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>apple</Td>
                      <Td>orange</Td>
                      <Td>banana</Td>
                      <Td>potatoes</Td>
                    </Tr>
                    <TrGroup>
                      <Tr trigger>
                        <Td>1 - 1 click to expand</Td>
                        <Td>1 - 2 click to expand</Td>
                        <Td>1 - 3 click to expand</Td>
                        <Td>1 - 4 click to expand</Td>
                      </Tr>
                      <Tr child>
                        <Td>sub 1 - 1</Td>
                        <Td>sub 1 - 2</Td>
                        <Td>sub 1 - 3</Td>
                        <Td>sub 1 - 4</Td>
                      </Tr>
                      <Tr child>
                        <Td>sub 1 - 1</Td>
                        <Td>sub 1 - 2</Td>
                        <Td>sub 1 - 3</Td>
                        <Td>sub 1 - 4</Td>
                      </Tr>
                    </TrGroup>
                    <Tr highlighted onRowClick={function noRefCheck() {}}>
                      <Td>rock</Td>
                      <Td>paper</Td>
                      <Td>scissor</Td>
                      <Td>hole</Td>
                    </Tr>
                    <Tr selected>
                      <Td>archive</Td>
                      <Td>documents</Td>
                      <Td>comment</Td>
                      <Td>post</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Checkbox noMargin />
                      </Td>
                      <Td>preset</Td>
                      <Td>pull</Td>
                      <Td>push</Td>
                    </Tr>
                  </Tbody>
                </Table>{" "}
              </Col>
            </Row>
            <Row>
              <Col mobile={12} desktop={6}>
                <Card css={css("min-height: 300px")}>
                  First column (desktop)
                </Card>
              </Col>
              <Col mobile={12} desktop={6}>
                <Card css={css("min-height: 300px")}>
                  Second column (desktop)
                </Card>
              </Col>
            </Row>
            <Row>
              <Col mobile={12}>
                <Button
                  iconLeft=""
                  iconRight=""
                  label="Click me"
                  onClick={() => {
                    console.log("clicked");
                  }}
                />
              </Col>{" "}
            </Row>
          </Grid>
        </div>
      </div>
    </Flex>
  );
};
