import * as React from "react";
import { css } from "@emotion/react";

export const style = {

  yieldCalculatorWrapper: css`
    padding: 3em;
    background-color: #354450;
    width: 100%;
  `,
  yieldCalculatorHeader: css`
    padding: 3em;
    background-color: #E3E6E8;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;
`,
  yieldCalculatorHeaderTitle: css`
    font-size: 2rem;
    font-weight: bold;
  `,
  yieldCalculatorForm: css`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; 
  `,
  yieldCalculatorInput: css`
  background-color: white;
  width: calc(50% - 0.5rem);
  flex-grow: 1;
  `,
  errored: css`
    .MuiFormControl-root {
      label {
        color: red!important;
      }
      .MuiInputBase-root {
        > fieldset, > fieldset:hover {
          border-color: red!important;
          legend {
            color: red!important;
          }
        }
      }
    }
  `
}