/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import MarketCapHome from "../../core/components/MarketStatistics/Home/market-cap-home";
import RisersAndFallersHome from "../../core/components/MarketStatistics/Home/risers-fallers-home";
import TopTradedSecuritiesHome from "../../core/components/MarketStatistics/Home/top-traded-securities";
import { breakpointMax } from "../../style";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { SitecoreGenericTextField } from "../../interfaces";


const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  margin-bottom: 0px;
  padding-bottom:0px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const parentContainer = css`
  width: 100%;
  margin: auto;
  margin-bottom:0px;
  padding-bottom:0px;
  padding-top: 80px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
`;
const mainTitle = css`
  font-weight: 800;
  font-size: 48px;
  margin-bottom: 40px;
  text-align: center;
  color: #354450;
  width: 100%;

  text-align: left;
  @media (max-width: ${breakpointMax}) {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 24px;

    text-align: left;
  }
`;
const mainDescription = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: #354450;
  margin-bottom: 84px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.01em;
  }
`;

export interface MarketStatisticsComponentProps {
    fields: any;
  }

  export const MarketStatisticsComponent: React.FunctionComponent<MarketStatisticsComponentProps> = (
    props: MarketStatisticsComponentProps
  ): React.ReactElement => {
    const { fields } = props;
    return (
        <>
            <div css={parentContainer}>
                <div css={container}>
                    <div css={mainTitle}>
                        <Text field={fields && (fields.mainTitle as SitecoreGenericTextField)} />
                    </div>
                    <div css={mainDescription}>
                        <Text field={fields && (fields.mainDescription as SitecoreGenericTextField)} />
                    </div>
                </div>
            </div>
            <RisersAndFallersHome isDisplayed={true} />
            <TopTradedSecuritiesHome isDisplayed={true} />
            <MarketCapHome />
        </>
    );
}
