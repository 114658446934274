
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const VIR: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M503.172,423.724H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.792C512,419.772,508.047,423.724,503.172,423.724z" />
            <polygon fill="#0083C7" points="399.057,290.007 402.279,301.309 413.476,268.033 382.419,284.408 394.092,285.781 356.818,329.578 356.495,328.65 386.265,267.007 392.235,277.132 394.671,242.107 368.753,265.79 380.394,264.172 350.624,325.815 349.695,326.138 360.825,269.714 369.157,278.003 362.672,243.498 343.572,272.957 354.428,268.452 342.428,329.292 338.511,337.405 334.29,340.235 332.553,349.037 317.792,366.382 329.316,365.454 327.225,376.054 331.301,373.322 331.695,378.212 338.697,369.985 345.135,379.586 349.542,357.241 355.357,350.409 354.949,345.343 358.867,337.231 " />
            <path fill="#339541" d="M130.918,256.929c1.266-5.692,0.481-11.025-1.687-14.308c-3.357,2.056-6.327,6.553-7.593,12.245 c-0.586,2.636-0.691,5.172-0.463,7.486l-6.969-10.391c2.838-2.314,5.281-6.276,6.39-11.261c1.266-5.692,0.481-11.025-1.687-14.308 c-3.357,2.056-6.327,6.553-7.593,12.245c-0.674,3.031-0.732,5.932-0.343,8.505l-4.712-7.025c0.236-3.567-0.86-7.906-3.489-12.041 c-3.129-4.92-7.455-8.136-11.31-8.925c-0.919,3.827,0.16,9.108,3.289,14.027c2.659,4.181,6.172,7.027,9.537,8.303l4.318,7.168 c-2.166-1.443-4.818-2.621-7.85-3.295c-5.692-1.266-11.025-0.481-14.308,1.687c2.056,3.356,6.553,6.327,12.245,7.593 c4.985,1.109,9.61,0.576,12.909-1.014l6.457,10.718c-2.001-1.187-4.341-2.167-6.978-2.754c-5.692-1.266-11.025-0.481-14.308,1.687 c2.056,3.356,6.553,6.327,12.245,7.593c4.771,1.062,9.214,0.618,12.485-0.811l5.945,9.868c-1.838-0.992-3.884-1.843-6.202-2.358 c-5.692-1.266-11.025-0.481-14.308,1.687c2.056,3.356,6.553,6.327,12.245,7.593c4.562,1.014,8.838,0.667,12.068-0.61l5.734,9.517 c-1.647-0.806-3.421-1.513-5.415-1.956c-5.692-1.266-11.025-0.481-14.308,1.687c2.056,3.356,6.553,6.327,12.245,7.593 c4.348,0.967,8.469,0.723,11.645-0.407l5.223,8.668c-1.437-0.634-2.964-1.188-4.639-1.561c-5.692-1.266-11.025-0.481-14.308,1.687 c2.056,3.356,6.553,6.327,12.245,7.593c4.147,0.922,8.082,0.733,11.193-0.265l16.588,27.533l7.045-4.482l-17.907-26.695 c2.222-2.397,4.062-5.879,4.984-10.026c1.266-5.692,0.481-11.025-1.687-14.308c-3.357,2.056-6.327,6.553-7.593,12.244 c-0.373,1.675-0.518,3.293-0.553,4.862l-5.636-8.403c2.369-2.398,4.338-6.027,5.304-10.375c1.266-5.692,0.481-11.025-1.687-14.308 c-3.357,2.056-6.327,6.553-7.593,12.245c-0.444,1.994-0.554,3.9-0.523,5.732l-6.188-9.226c2.526-2.386,4.653-6.111,5.668-10.671 c1.266-5.692,0.481-11.025-1.687-14.308c-3.357,2.056-6.327,6.553-7.593,12.245c-0.515,2.317-0.612,4.531-0.493,6.615l-6.418-9.567 C127.573,265.535,129.857,261.699,130.918,256.929z" />
            <g>
                <path fill="#F4C53D" d="M203.034,256l-8.828-52.965h-1.366c-8.013,0-13.673-7.851-11.138-15.454l0.029-0.085 c2.025-6.076-1.192-12.653-7.231-14.784L53.293,129.934c-6.87-2.425-13.341,4.484-10.471,11.181l13.144,30.669 c3.782,8.823,10.336,16.173,18.67,20.936l33.623,19.213c10.044,5.739,21.411,8.758,32.979,8.758h0.002l3.016,6.031 C153.229,244.665,171.569,256,191.631,256H203.034z" />
                <path fill="#F4C53D" d="M308.965,256l8.828-52.965h1.366c8.013,0,13.673-7.851,11.138-15.454l-0.029-0.085 c-2.025-6.076,1.192-12.653,7.231-14.784l121.158-42.762c6.889-2.432,13.377,4.497,10.499,11.212l-13.123,30.621 c-3.782,8.823-10.336,16.173-18.67,20.936l-33.623,19.213c-10.044,5.739-21.411,8.758-32.979,8.758h-0.003l-3.016,6.031 C358.77,244.665,340.431,256,320.369,256H308.965z" />
                <path fill="#F4C53D" d="M291.31,194.207l-21.897-51.093c-2.782-6.492-9.165-10.7-16.227-10.7h-6.014l-8.828,8.828h-8.828 l-8.828,8.828h12.319c3.123,0,5.229,3.193,3.999,6.065l-16.318,38.073h70.622V194.207z" />
                <path fill="#F4C53D" d="M229.517,282.483L211.862,256l-16.168,16.168c-6.783,6.783-11.894,15.053-14.927,24.153 l-4.215,12.644l-8.828,8.828H156.25c-3.788,0-7.252,2.141-8.946,5.528l-2.489,4.979c-1.642,3.284,0.746,7.147,4.417,7.147h18.492 l5.886-11.77l28.143-14.072c0.849-0.425,1.623-0.983,2.294-1.654L229.517,282.483z" />
                <path fill="#F4C53D" d="M282.482,282.483L300.137,256l16.168,16.168c6.783,6.783,11.894,15.053,14.927,24.153l4.215,12.644 l8.828,8.828h11.474c3.788,0,7.252,2.141,8.946,5.528l2.489,4.979c1.642,3.284-0.746,7.147-4.417,7.147h-18.492l-5.885-11.77 l-28.143-14.072c-0.849-0.425-1.623-0.983-2.294-1.654L282.482,282.483z" />
                <path fill="#F4C53D" d="M229.517,291.31l-21.454,35.756c-2.894,4.824-1.842,11.038,2.479,14.638l32.579,27.149 c7.459,6.217,18.295,6.217,25.754,0l32.579-27.149c4.322-3.602,5.374-9.815,2.479-14.638l-21.452-35.756H229.517z" />
            </g>
            <path fill="#FFFFFF" d="M255.999,185.379c0,0-26.483,8.828-61.793,0c0,0,0,17.655,0,35.31s8.828,70.621,61.793,88.276 c52.965-17.655,61.793-70.621,61.793-88.276s0-35.31,0-35.31C282.482,194.207,255.999,185.379,255.999,185.379z" />
            <path fill="#162667" d="M255.999,185.379c0,0-26.483,8.828-61.793,0c0,0,0,17.655,0,35.31h123.586c0-17.655,0-35.31,0-35.31 C282.482,194.207,255.999,185.379,255.999,185.379z" />
            <g>
                <path fill="#A60032" d="M212.664,277.163V220.69h-9.63v39.294C205.536,265.732,208.679,271.552,212.664,277.163z" />
                <path fill="#A60032" d="M231.924,220.69h-9.63v67.758c2.916,2.889,6.104,5.639,9.63,8.199V220.69z" />
                <path fill="#A60032" d="M299.335,277.163c3.985-5.612,7.128-11.432,9.63-17.18V220.69h-9.63V277.163z" />
                <path fill="#A60032" d="M251.184,220.69h-9.63v81.932c3.016,1.626,6.23,3.105,9.63,4.449V220.69z" />
                <path fill="#A60032" d="M289.705,220.69h-9.63v75.958c3.526-2.56,6.713-5.31,9.63-8.199V220.69z" />
                <path fill="#A60032" d="M270.445,302.622V220.69h-9.63v86.381C264.215,305.727,267.429,304.248,270.445,302.622z" />
            </g>
            <polygon fill="#339541" points="163.184,324.98 156.916,328.837 167.448,346.32 174.493,341.839 " />
            <g>
                <polygon fill="#0083C7" points="358.641,337.697 353.103,326.621 342.955,326.621 342.429,329.292 338.511,337.405 334.29,340.235 332.553,349.037 317.792,366.382 329.316,365.454 327.225,376.054 331.301,373.322 331.695,378.212 338.697,369.985 345.135,379.586 349.542,357.241 355.357,350.409 354.949,345.343 " />
                <polygon fill="#0083C7" points="84.493,220.69 61.793,220.69 63.83,226.802 50.443,273.655 37.056,226.802 39.094,220.69 16.394,220.69 22.506,228.839 41.131,294.027 39.094,300.138 61.793,300.138 59.756,294.027 78.381,228.839 " />
                <polygon fill="#0083C7" points="469.122,226.365 472.906,220.69 469.122,220.69 453.99,220.69 450.206,220.69 453.99,226.365 453.99,294.464 450.206,300.138 453.99,300.138 469.122,300.138 472.906,300.138 469.122,294.464 " />
            </g>
        </svg>
    </React.Fragment>
);
