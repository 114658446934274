import * as React from 'react';

import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { SitecoreGenericTextField } from '../../interfaces';
import { renderLink } from '../../utils/helper';

/** @jsx jsx */








export interface CardsWithBackgroundProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  display: flex;

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const action = css`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const CardsWithBackground: React.FunctionComponent<CardsWithBackgroundProps> = (
  props: CardsWithBackgroundProps
): React.ReactElement => {
  const { fields } = props;

  const icon1: string = getFieldValue<string>(fields, 'icon1', '');
  const icon2: string = getFieldValue<string>(fields, 'icon2', '');
  const parentBackground: any = getFieldValue<any>(fields, 'parentBackground', false);
  const backgroundCard1: any = getFieldValue<any>(fields, 'backgroundCard1', false);
  const backgroundCard2: any = getFieldValue<any>(fields, 'backgroundCard2', false);
  const btnLabel1: string = getFieldValue<string>(fields, 'btnLabel1', '');
  const btnLabel2: string = getFieldValue<string>(fields, 'btnLabel2', '');
  const btnLink1: string = getFieldValue<string>(fields, 'btnLink1', '');
  const btnLink2: string = getFieldValue<string>(fields, 'btnLink2', '');
  const btnFile1: any = getFieldValue<any>(fields, 'btnFile1', null);
  const btnFile2: any = getFieldValue<any>(fields, 'btnFile2', null);
  const greyBackground: number = getFieldValue<number>(fields, 'greyBackground', 0);
  const totalCards: number =
    fields && fields.cardsList && (fields && (fields.cardsList as Array<any>)).length
      ? (fields && (fields.cardsList as Array<any>)).length
      : 0;

  const renderCardStyle = (background: any) =>
    css`
      width: 50%;
      padding: 48px;
      padding-top: ${background && background.src ? '214px' : '48px'};
      border-radius: 25px;
      background-color: #ffffff;
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      background-image: linear-gradient(transparent 10%, rgb(0 0 0 / 50%) 90%),
        url(${renderLink(background && background.src)});
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.3));
      cursor: pointer;
      :hover {
        background-image: linear-gradient(0deg, #425563 28.05%, rgba(0, 140, 61, 0) 128.05%),
          url(${renderLink(background && background.src)});
      }
      @media (max-width: ${breakpointMax}) {
        width: calc(100% - 32px);
        margin-left: auto;
        margin-right: auto;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 40px;
        padding-top: ${background && background.src ? '160px' : '48px'};
      }
    `;

  const parentContainer = css`
    width: 100%;
    margin: auto;
    padding-top: 48px;
    padding-bottom: 48px;
    background-image: url(${renderLink(parentBackground && parentBackground.src)});
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    @media (max-width: ${breakpointMax}) {
      background-color: #f6f8f9;
    }
  `;
  const mainTitle = css`
    color: ${parentBackground && parentBackground.src ? '#FFFFFF' : '#425563'};
    font-weight: 800;
    font-size: 48px;
    margin-bottom: 64px;
    text-align: center;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      margin-bottom: 40px;
    }
  `;
  const card1 = css`
    ${renderCardStyle(backgroundCard1)};
  `;
  const card2 = css`
    ${renderCardStyle(backgroundCard2)};
  `;
  const title = css`
    color: ${backgroundCard1 && backgroundCard1.src ? '#FFFFFF' : '#425563'};
    font-weight: 800;
    font-size: 24px;
    line-height: 46px;
    margin-top: 24px;
    margin-bottom: 24px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
    }
  `;
  const description = css`
    color: ${backgroundCard1 && backgroundCard1.src ? '#FFFFFF' : '#425563'};
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.01em;
    }
  `;

  return (
    <div css={parentContainer}>
      <div css={mainTitle}>
        <Text field={fields && (fields.mainTitle as SitecoreGenericTextField)} />
      </div>
      <div css={container}>
        <div css={card1}>
          <div css={title}>
            <Text field={fields && (fields.title1 as SitecoreGenericTextField)} />
          </div>
          <div css={description}>
            <Text field={fields && (fields.description1 as SitecoreGenericTextField)} />
          </div>
          <div css={action}>
            <Button
              label={btnLabel1}
              onClick={() =>
                btnLink1 || (fields && (fields[`btnFile1`] as any)).value?.src
                  ? (window.location.href = (fields && (fields[`btnFile1`] as any)).value?.src || btnLink1)
                  : console.debug
              }
              iconLeft={'box'}
            />
          </div>
        </div>
        <div css={card2}>
          <div css={title}>
            <Text field={fields && (fields.title2 as SitecoreGenericTextField)} />
          </div>
          <div css={description}>
            <Text field={fields && (fields.description2 as SitecoreGenericTextField)} />
          </div>
          <div css={action}>
            <Button
              label={btnLabel2}
              onClick={() =>
                btnLink2 || (fields && (fields[`btnFile2`] as any)).value?.src
                  ? (window.location.href = (fields && (fields[`btnFile2`] as any)).value?.src || btnLink2)
                  : console.debug
              }
              iconLeft={'box'}
            />
          </div>
        </div>
      </div>
      <div>
        {fields &&
          fields.cardsList &&
          (fields.cardsList as Array<any>).map((item: any, index: number): React.ReactNode => {
            return (
              <div>
                <Text field={item.fields.description as SitecoreGenericTextField} />
              </div>
            );
          })}
      </div>
    </div>
  );
};
