import { ApolloError } from "apollo-client";
import gql from "graphql-tag";

export const greenCardFragment = gql`
    fragment greenCardFragment on LgxInformationForWebsite {
        genderBond
        pub
        idVal
        isin
        alignmentWithStandardAndPrinciples
        slbOverallStatus
        preIssuanceInfo {
            mgmtOfProceedsDescription
            proceedsManagementMethod
            reportingDescription
            evaluationAndSelectProcessDescription
            approvingCommittee
            sustainabilityLinkedBonds{
              generalComment
              keyPerformanceIndicators{
                indicator
                unit
                definition
                baseline
                assessment
                theme
                sustainableDevelopmentGoals
                primaryEUEnvironmentalObjective
                sustainabilityPerformanceTargets{
                  value
                  date
                  assessment
                }
              }
              potentialChange{
                value
                otherTypeOfChange
                date
                typeOfChange
              }
              triggerEvent{
                description
                trigger
                date
              }
              commitments{
                description
                reportingFrequency
                verificationFrequency
              }
            }
            externalReviews{
              reviewType
              reviewer
              otherReviewer
              alignementStandardAndPrinciples
            }
            useOfProceeds{
                useOfProceedsDescription
                targetsPopulation
                otherTargetPopulation
                contributedSdgs
                eligibleCategories
                eligibleSubCategories
                eligibleCategoryMapping
                hasNaturalGas
                hasNuclearEnergy
            }
        }
        postIssuanceDocs{
            downloadUrl
            otherType
            refUtcDt
            type
            section
        }
        preIssuanceDocs{
            downloadUrl
            otherType
            refUtcDt
            type
            section
        }
    }
`;

export const SEARCH_GREEN_DATA =  gql`
query GetLuxseSearchResults(
    $page : Int,
    $size : Int,
    $sort: String!
    ) {
    luxseIssuersSearch(
        searchTerm: "",
        page: $page,
        size: $size,
        sort: $sort,
        lgxOnly : true,
    ) {
        totalHits,
        filters {
            issuerTypes {
                name
                count
                issuerSubTypes{
                    name
                    count
                }
            }
            countries {
                name
                count
            }
            regions {
                name
                count
            }
            lgxOnly
            climateAlignedOnly
        },
        issuers {
            id
            name
            url
            type: issuerType
            subType: issuerSubType
            oldType: type
            country: countryCode2
            statistics {
                nbEquities
                nbProgs
                nbBonds
                nbGreens
                nbWarrants
                nbFundClasses
                nbCertificates
                nbSecurities
                alignmentPercent
                managementCompanyName
                caiAsPerCBI
            }
            lgxFirstListingDate
        }
    }
}
`;
