import * as React from "react";

import {
  Button,
  Card,
  Col,
  DonutGraph,
  Grid,
  Icon,
  Row,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  ToggleButtonGroup,
  ToggleItem,
  Tr,
} from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import {
  GET_FALLERS_SECURITIES_LIST_YEARLY,
  GET_LAST_TRADED_SECURITIES_LIST,
  GET_LAST_TRADED_SECURITIES_THUNBNAIL,
  GET_MOST_TRADED_SECURITIES_LIST_YEARLY,
  GET_RISERS_SECURITIES_LIST_YEARLY_FULL,
} from "../../graphql";
import { GreenDataContext, MobileMenuContext } from "../../core/app";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { useEffect, useRef, useState } from "react";

import { BACKBOURSE_ENDPOINT } from "../../backbourse";
import GraphCard from "../../core/components/GraphCard/graphCard.component";
import Loader from "../../core/components/Loader/loader.component";
import MarketStatusComponent from "../../core/components/MarketStatusBox/marketStatusBox.component";
import { Query } from "react-apollo";
import RoundTag from "../../core/components/RoundTag/roundTag.component";
import axios from "axios";
import { formatDate } from "../../utils/date";
import { formatToThousandsSeparator } from "../../utils";
import logo from "../LuxXPrimeDiscover/luxXPrime.svg";
import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
import moment from "moment";
import Utils from "../../core/utils/utils";

/** @jsx jsx */

export interface MarketCapitalisationProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export interface GQLData {
  currency: string;
  id: string;
  instrumentName: string;
  interestRate: string | number;
  isin: string;
  listingDate: string;
  maturityDate: string;
}

const instance = axios.create();

const style = {
  topContainer: css`
    width: 100%;
    position: relative;
  `,
  action: css`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 160px;
  `,
  cardContainerInfo: css`
    color: #354450;
    font-size: 14px;
    line-height: 17px;
    margin-top: 16px;
    margin-bottom: 48px;

    span {
      font-weight: bold;
    }

    @media (max-width: ${breakpointMax}) {
      margin-top: 48px;
    }
  `,
  itemGreen: css`
    text-decoration: underline;
    color: #22aa5f;
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    svg {
      margin-left: 16px;
    }

    @media (max-width: ${breakpointMax}) {
      text-align: center;
      font-size: 14px;
      margin-bottom: 30px;
    }
  `,
  itemLink: css`
    text-decoration: underline;
    color: #354450;
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    svg {
      margin-left: 16px;
    }

    @media (max-width: ${breakpointMax}) {
      text-align: center;
      font-size: 14px;
      margin-bottom: 30px;
    }
  `,
  gradientTop: css`
    width: 100%;
    height: 8px;
    background: linear-gradient(180deg, #f1f1f1, rgba(217, 225, 226, 0));
  `,
  marketTitle: css`
    margin-top: 160px;
    margin-bottom: 24px;
    font-size: 48px;
    font-weight: bold;
    line-height: 64px;
    text-align: center;
    color: #354450;
  `,
  side: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  absoluteLabel: css`
    position: absolute;
    margin-top: -70px;
    margin-left: -20px;
    font-weight: 400;
    font-size: 14px;
    color: #425563;
  `,
  sideGreen: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #22aa5f;
    @media (max-width: ${breakpointMax}) {
      margin-bottom: 5px;
    }
  `,
  sideOrange: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #f18700;
    @media (max-width: ${breakpointMax}) {
      margin-bottom: 5px;
    }
  `,
  sideIndicator: css`
    display: flex;
    align-items: center;
  `,
  sideInfo: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  `,
  issuer: css`
    color: #354450;
    font-size: 24px;
    line-height: 31px;
    font-weight: bold;
    text-align: center;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  type: css`
    color: #354450;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-align: center;
  `,
  isin: css`
    color: #425563;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin-top: 8px;
  `,
  dataValue: css`
    font-size: 48px;
    display: flex;
    width: 100%;
    white-space: nowrap;

    span {
      font-size: 24px;
      padding-top: 20px;
      padding-left: 16px;
    }

    @media (max-width: ${breakpointMax}) {
      font-size: 40px;
    }
  `,
  dataIndicator: css`
    font-size: 24px;
    width: 60px;
  `,
  dataIndicatorRight: css`
    color: #22aa5f;
    font-size: 16px;
  `,
  titleCard: css`
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #1a2934;
    width: 100%;
    font-weight: 700;

    @media (max-width: ${breakpointMax}) {
      margin-bottom: 12px;
    }
  `,
  dataCard: css`
    display: flex;
    width: 100%;
    border-bottom: solid 1px #e3e6e8;
    padding: 24px 24px 16px;

    &:last-of-type {
      border-bottom: none;
    }

    @media (max-width: ${breakpointMax}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      padding-top: 18px;
    }
  `,
  gradientBottom: css`
    width: 100%;
    height: 8px;
    background: linear-gradient(-180deg, #f1f1f1, rgba(217, 225, 226, 0));
  `,

  tdHeight: css`
    height: 56px;
  `,
  table: css`
    width: 100%;
    border: none;
    border-collapse: collapse;
    color: #54666f;
    font-weight: 600;

    table {
      width: 100%;
    }

    td,
    th {
      height: 28px;
      width: 50%;
      vertical-align: middle;
    }

    td {
      border-bottom: solid 1px #e3e6e8;
      font-size: 14px;
    }

    th {
      text-align: left;
      color: #354450;
      font-weight: 800;
      font-size: 14px;
    }

    thead {
      width: calc(100% - 18px);
    }

    tr td:first-of-type {
      padding-left: 0;
    }

    thead th {
      text-align: left;
    }

    th {
      height: auto;
      margin-bottom: 8px;
    }
  `,
  tableNewSecurity: css`
    width: 65%;
    height: 56px !important;
  `,
  status: css`
    width: 35%;
    height: 56px !important;
  `,

  tableNewSecurityTh: css`
    width: 65%;
  `,
  statusTh: css`
    width: 35%;
  `,
  tableSecurity: css`
    width: 43%;
  `,
  tableDate: css`
    width: 20%;
  `,
  tableYield: css`
    width: 15%;
  `,
  tablePrice: css`
    width: 25%;
  `,
  greyContainer: css`
    position: absolute;
    z-index: -1;
    margin-top: 120px;
    width: 100%;
    height: calc(100% - 215px);
    background: #f6f9f9;
  `,
  container: css`
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin: auto;
    margin-bottom: 60px;
    width: 100%;
  `,
  rowBond: css`
    display: flex;
    align-items: center;

    > div {
      margin-right: 12px;
    }
  `,
  double: css`
    display: flex;
    flex-direction: column;
  `,
  doubleTop: css``,
  doubleBottom: css`
    color: #22aa5f;
    margin-top: 4px;
  `,
  cardContainerTop: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
  `,
  cardContainerBody: css`
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 100px;

    .apexcharts-legend {
      right: 24px !important;
    }

    text {
      font-size: 8px;
    }
  `,
  cardContainerBodyTop: css``,
  cardContainerBodyBottom: css`
    text-align: right;
  `,
  cardContainerBodyDoubleContainer: css`
    display: flex;
  `,
  cardContainerBodyDoubleLeft: css`
    display: flex;
    align-items: center;
    margin-right: 12px;
  `,
  cardContainerBodyDoubleRight: css``,
  cardContainerBodyDouble: css`
    display: flex;
    flex-direction: column;
    color: #54666f;
    font-weight: 600;
    font-size: 14px;

    span {
      color: #425563;
      font-weight: 600;
      margin-top: 6px;
      font-size: 14px;
    }
  `,
  span: css`
    color: #425563;
    font-weight: 600;
    font-size: 14px;
    margin-top: 8px;
  `,
  cardContainerBodyTag: css`
    font-size: 14px;
    display: flex;
    justify-content: center;
  `,
  cardContainerBodyLabel: css`
    font-size: 24px;
    color: #354450;
    font-weight: 800;
    text-align: center;
    margin-bottom: 8px;
  `,
  cardContainerBodyRate: css`
    font-size: 48px;
    color: #22aa5f;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
    text-align: center;
  `,
  cardContainerBodyIsin: css`
    font-size: 14px;
    font-weight: 600;
    color: #425563;
    text-align: center;
    margin-bottom: 32px;
  `,
  cardContainerBodyText: css`
    font-size: 14px;
    padding-left: 38px;
    color: #354450;
    line-height: 17px;
    font-weight: 600;
    margin-bottom: 32px;
  `,
  cardContainerBodyTags: css`
    font-size: 14px;
    display: flex;
    justify-content: center;

    > div {
      margin-left: 8px;
      margin-right: 8px;
    }
  `,
  cardContainerFooter: css`
    color: #22aa5f;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  buttons: css`
    display: flex;
    justify-content: center;
    margin-top: 64px;

    button {
      margin-left: 12px;
      margin-right: 12px;
    }
  `,
  cardContainerTitle: css`
    img {
      width: 100%;
      max-width: 140px;
    }

    svg {
      color: #a4bcc2;
      font-size: 20px;
      margin-right: 4px;
    }

    font-weight: 800;
    line-height: 26px;
    color: #354450;
  `,
  cardContainerActions: css`
    font-size: 12px;
    font-weight: 600 !important;
    color: #54666f;

    > div > div {
      padding-left: 16px;
      padding-right: 16px;
    }
  `,
};

const renderField = (field: string) => {
  return field === "icmagreen" ? "green" : field;
};

export const MarketCapitalisation: React.FunctionComponent<MarketCapitalisationProps> =
  (props: MarketCapitalisationProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const marketStatus: any = Number(
      getFieldValue<number>(fields, "market is open", 0)
    );
    const marketLabel: string = getFieldValue<string>(
      fields,
      "market label",
      ""
    );
    const marketDetails: string = getFieldValue<string>(
      fields,
      "market details",
      ""
    );
    const [securityFilter, setSecurityFilter] = useState(
      GET_LAST_TRADED_SECURITIES_LIST
    );
    const [newListings, setNewListings] = useState([]);
    const [greenBonds, setGreenBonds] = useState([]);
    const [lastSecurities, setLastSecurities] = useState([]);
    const [loaderGreen, setLoaderGreen] = useState(true);
    const [loaderLast, setLoaderLast] = useState(true);
    const [objectTarget, setObjectTarget] = useState<string>(
      "lastTradedLuxXPrimeSecurities"
    );

    const card = css`
      background: #ffffff;
      border: solid 1px #e3e6e8;
      border-radius: 8px;
      height: 100%;
      box-shadow: 0 8px 16px rgb(164 188 194 / 31%);
    `;
    const cardContainer = css`
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 32px 32px 0;
    `;

    const setSwitchTab = (tab: string) => {
      switch (tab) {
        case "top": {
          setSecurityFilter(GET_LAST_TRADED_SECURITIES_LIST);
          setObjectTarget("lastTradedLuxXPrimeSecurities");
        }
        case "risers": {
          setSecurityFilter(GET_RISERS_SECURITIES_LIST_YEARLY_FULL);
          setObjectTarget("yearlyRisersLuxXPrimeSecurities");
        }
        case "fallers": {
          setSecurityFilter(GET_FALLERS_SECURITIES_LIST_YEARLY);
          setObjectTarget("yearlyFallersLuxXPrimeSecurities");
        }
        default: {
          setSecurityFilter(GET_LAST_TRADED_SECURITIES_LIST);
          setObjectTarget("lastTradedLuxXPrimeSecurities");
        }
      }
    };

    const fetchNewSecurities = async (url: string) => {
      let res: any = await axios.get(url);
      res = res.data;
      setNewListings(res);
    };
    const fetchGreen = async (url: string) => {
      // console.clear();
      if (typeof window !== "undefined") {
        if (
          !window.localStorage.getItem("greenBonds") ||
          (window.localStorage.getItem("greenBonds") &&
            JSON.parse(window.localStorage.getItem("greenBonds")).length === 0)
        ) {
          setLoaderGreen(true);
          const dt: any = await instance.get(url, { timeout: 0 });
          setGreenBonds(dt.data.slice(0, 20));
          //greenDataContext.actionDispatch(dt.data.slice(0, 20));
          window.localStorage.setItem(
            "greenBonds",
            JSON.stringify(dt.data.slice(0, 20))
          );
          setLoaderGreen(false);
        } else {
          setLoaderGreen(false);
          setGreenBonds(JSON.parse(window.localStorage.getItem("greenBonds")));
        }
      }
    };

    const fetchLatestSecurities = async (url: string) => {
      setLoaderLast(true);
      const dta: any = await instance.get(url, { timeout: 0 });
      setLastSecurities(dta.data.slice(0, 20));
      setLoaderLast(false);
    };
    const greenDataContext = React.useContext(GreenDataContext);
    useEffect(() => {
      fetchNewSecurities(`${BACKBOURSE_ENDPOINT}/new-listings`);
      fetchGreen(`${BACKBOURSE_ENDPOINT}/green-bonds-cards`);
      fetchLatestSecurities(`${BACKBOURSE_ENDPOINT}/last-trades`);
    }, []);

    return (
      <>
        <div css={style.topContainer}>
          <div css={style.greyContainer} />
          <div css={style.container}>
            <Grid>
              <Row>
                <Col mobile={12} tablet={6} desktop={6}>
                  <div css={card}>
                    <div css={cardContainer}>
                      <div css={style.cardContainerTop}>
                        <div
                          css={style.cardContainerTitle}
                          style={{ marginBottom: 14 }}
                        >
                          <Icon type={"far"} icon={"trophy"} /> Risers and
                          fallers
                        </div>
                      </div>
                      <div css={style.cardContainerBody}>
                        <Query<any>
                          client={luxseGatewayGraphqlClient}
                          query={GET_RISERS_SECURITIES_LIST_YEARLY_FULL}
                          variables={{
                            limit: 2,
                            token: Utils.getCookie("Gztoken-Dsm"),
                          }}
                          //pollInterval={30000}
                          fetchPolicy="network-only"
                        >
                          {({ loading, error, data }): React.ReactNode => {
                            return (
                              <Loader loading={loading}>
                                <>
                                  <div css={style.titleCard}>
                                    Monthly performances
                                  </div>
                                  {data &&
                                    data.yearlyRisersLuxXPrimeSecurities &&
                                    data.yearlyRisersLuxXPrimeSecurities
                                      .slice(0, 1)
                                      .map((riser: any, index: number) => (
                                        <div
                                          css={style.dataCard}
                                          key={`data-map-${index}`}
                                        >
                                          <div
                                            css={
                                              riser &&
                                              riser.marketData &&
                                              riser.marketData.ytdVariation &&
                                              riser.marketData.ytdVariation >= 0
                                                ? style.sideGreen
                                                : style.sideOrange
                                            }
                                          >
                                            <div css={style.dataIndicator}>
                                              <Icon
                                                icon={
                                                  riser &&
                                                  riser.marketData &&
                                                  riser.marketData
                                                    .ytdVariation &&
                                                  riser.marketData
                                                    .ytdVariation >= 0
                                                    ? "chevron-up"
                                                    : "chevron-down"
                                                }
                                              />
                                            </div>
                                            <div css={style.side}>
                                              <div css={style.dataValue}>
                                                {riser &&
                                                riser.marketData &&
                                                riser.marketData.ytdVariation &&
                                                riser.marketData.ytdVariation >=
                                                  0
                                                  ? "+"
                                                  : "-"}
                                                {riser.marketData?.ytdVariation.toFixed(
                                                  4
                                                )}{" "}
                                                {
                                                  riser.marketData
                                                    ?.tradeIndicator
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div css={style.side}>
                                            <div css={style.sideInfo}>
                                              <div css={style.issuer}>
                                                {
                                                  riser.instrumentName.split(
                                                    " "
                                                  )[0]
                                                }
                                              </div>
                                              <div css={style.isin}>
                                                {riser.isin}
                                              </div>
                                            </div>
                                            <div css={style.dataIndicatorRight}>
                                              <Icon icon={"chevron-right"} />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                </>
                              </Loader>
                            );
                          }}
                        </Query>
                        <Query<any>
                          client={luxseGatewayGraphqlClient}
                          query={GET_FALLERS_SECURITIES_LIST_YEARLY}
                          variables={{
                            limit: 2,
                            token: Utils.getCookie("Gztoken-Dsm"),
                          }}
                          //pollInterval={30000}
                          fetchPolicy="network-only"
                        >
                          {({ loading, error, data }): React.ReactNode => {
                            return (
                              <Loader loading={loading}>
                                <>
                                  {data &&
                                    data.yearlyFallersLuxXPrimeSecurities &&
                                    data.yearlyFallersLuxXPrimeSecurities
                                      .slice(0, 1)
                                      .map((riser: any, index: number) => (
                                        <div
                                          css={style.dataCard}
                                          key={`data-map-${index}`}
                                        >
                                          <div
                                            css={
                                              riser &&
                                              riser.marketData &&
                                              riser.marketData.ytdVariation &&
                                              riser.marketData.ytdVariation >= 0
                                                ? style.sideGreen
                                                : style.sideOrange
                                            }
                                          >
                                            <div css={style.dataIndicator}>
                                              <Icon
                                                icon={
                                                  riser &&
                                                  riser.marketData &&
                                                  riser.marketData
                                                    .ytdVariation &&
                                                  riser.marketData
                                                    .ytdVariation >= 0
                                                    ? "chevron-up"
                                                    : "chevron-down"
                                                }
                                              />
                                            </div>
                                            <div css={style.side}>
                                              <div css={style.dataValue}>
                                                {riser.marketData?.ytdVariation.toFixed(
                                                  4
                                                )}{" "}
                                                {
                                                  riser.marketData
                                                    ?.tradeIndicator
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div css={style.side}>
                                            <div css={style.sideInfo}>
                                              <div css={style.issuer}>
                                                {
                                                  riser.instrumentName.split(
                                                    " "
                                                  )[0]
                                                }
                                              </div>
                                              <div css={style.isin}>
                                                {riser.isin}
                                              </div>
                                            </div>
                                            <div css={style.dataIndicatorRight}>
                                              <Icon icon={"chevron-right"} />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                </>
                              </Loader>
                            );
                          }}
                        </Query>
                        <div css={style.cardContainerInfo}>
                          Top risers and fallers in <span>november 2021</span>
                        </div>
                        <div css={style.cardContainerFooter}>
                          <a css={style.itemGreen} href="">
                            Consult last stock's risers and fallers statistics{" "}
                            <Icon icon={"chevron-right"} />
                          </a>
                          <a css={style.itemLink} href="">
                            See all stock's risers and fallers at LuxSE{" "}
                            <Icon icon={"chevron-right"} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col mobile={12} tablet={6} desktop={6}>
                  <div css={card}>
                    <div css={cardContainer}>
                      <div css={style.cardContainerTop}>
                        <div
                          css={style.cardContainerTitle}
                          style={{ marginBottom: 14 }}
                        >
                          <Icon type={"far"} icon={"trophy"} /> Risers and
                          fallers
                        </div>
                      </div>
                      <div css={style.cardContainerBody}>
                        <Query<any>
                          client={luxseGatewayGraphqlClient}
                          query={GET_RISERS_SECURITIES_LIST_YEARLY_FULL}
                          variables={{
                            limit: 2,
                            token: Utils.getCookie("Gztoken-Dsm"),
                          }}
                          //pollInterval={30000}
                          fetchPolicy="network-only"
                        >
                          {({ loading, error, data }): React.ReactNode => {
                            return (
                              <Loader loading={loading}>
                                <>
                                  <div css={style.titleCard}>
                                    Yearly performances
                                  </div>
                                  {data &&
                                    data.yearlyRisersLuxXPrimeSecurities &&
                                    data.yearlyRisersLuxXPrimeSecurities
                                      .slice(0, 1)
                                      .map((riser: any, index: number) => (
                                        <div
                                          css={style.dataCard}
                                          key={`data-map-${index}`}
                                        >
                                          <div
                                            css={
                                              riser &&
                                              riser.marketData &&
                                              riser.marketData.ytdVariation &&
                                              riser.marketData.ytdVariation >= 0
                                                ? style.sideGreen
                                                : style.sideOrange
                                            }
                                          >
                                            <div css={style.dataIndicator}>
                                              <Icon
                                                icon={
                                                  riser &&
                                                  riser.marketData &&
                                                  riser.marketData
                                                    .ytdVariation &&
                                                  riser.marketData
                                                    .ytdVariation >= 0
                                                    ? "chevron-up"
                                                    : "chevron-down"
                                                }
                                              />
                                            </div>
                                            <div css={style.side}>
                                              <div css={style.dataValue}>
                                                {riser &&
                                                riser.marketData &&
                                                riser.marketData.ytdVariation &&
                                                riser.marketData.ytdVariation >=
                                                  0
                                                  ? "+"
                                                  : "-"}
                                                {riser.marketData?.ytdVariation.toFixed(
                                                  4
                                                )}{" "}
                                                {
                                                  riser.marketData
                                                    ?.tradeIndicator
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div css={style.side}>
                                            <div css={style.sideInfo}>
                                              <div css={style.issuer}>
                                                {
                                                  riser.instrumentName.split(
                                                    " "
                                                  )[0]
                                                }
                                              </div>
                                              <div css={style.isin}>
                                                {riser.isin}
                                              </div>
                                            </div>
                                            <div css={style.dataIndicatorRight}>
                                              <Icon icon={"chevron-right"} />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                </>
                              </Loader>
                            );
                          }}
                        </Query>
                        <Query<any>
                          client={luxseGatewayGraphqlClient}
                          query={GET_FALLERS_SECURITIES_LIST_YEARLY}
                          variables={{
                            limit: 2,
                            token: Utils.getCookie("Gztoken-Dsm"),
                          }}
                          //pollInterval={30000}
                          fetchPolicy="network-only"
                        >
                          {({ loading, error, data }): React.ReactNode => {
                            return (
                              <Loader loading={loading}>
                                <>
                                  {data &&
                                    data.yearlyFallersLuxXPrimeSecurities &&
                                    data.yearlyFallersLuxXPrimeSecurities
                                      .slice(0, 1)
                                      .map((riser: any, index: number) => (
                                        <div
                                          css={style.dataCard}
                                          key={`data-map-${index}`}
                                        >
                                          <div
                                            css={
                                              riser &&
                                              riser.marketData &&
                                              riser.marketData.ytdVariation &&
                                              riser.marketData.ytdVariation >= 0
                                                ? style.sideGreen
                                                : style.sideOrange
                                            }
                                          >
                                            <div css={style.dataIndicator}>
                                              <Icon
                                                icon={
                                                  riser &&
                                                  riser.marketData &&
                                                  riser.marketData
                                                    .ytdVariation &&
                                                  riser.marketData
                                                    .ytdVariation >= 0
                                                    ? "chevron-up"
                                                    : "chevron-down"
                                                }
                                              />
                                            </div>
                                            <div css={style.side}>
                                              <div css={style.dataValue}>
                                                {riser.marketData?.ytdVariation.toFixed(
                                                  4
                                                )}{" "}
                                                {
                                                  riser.marketData
                                                    ?.tradeIndicator
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div css={style.side}>
                                            <div css={style.sideInfo}>
                                              <div css={style.issuer}>
                                                {
                                                  riser.instrumentName.split(
                                                    " "
                                                  )[0]
                                                }
                                              </div>
                                              <div css={style.isin}>
                                                {riser.isin}
                                              </div>
                                            </div>
                                            <div css={style.dataIndicatorRight}>
                                              <Icon icon={"chevron-right"} />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                </>
                              </Loader>
                            );
                          }}
                        </Query>
                        <div css={style.cardContainerInfo}>
                          Top risers and fallers in <span>november 2021</span>
                        </div>
                        <div css={style.cardContainerFooter}>
                          <a css={style.itemGreen} href="">
                            Consult last stock's risers and fallers statistics{" "}
                            <Icon icon={"chevron-right"} />
                          </a>
                          <a css={style.itemLink} href="">
                            See all stock's risers and fallers at LuxSE{" "}
                            <Icon icon={"chevron-right"} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col mobile={12} tablet={6} desktop={6}>
                  <div css={card}>
                    <div css={cardContainer}>
                      <div css={style.cardContainerTop}>
                        <div
                          css={style.cardContainerTitle}
                          style={{ marginBottom: 14 }}
                        >
                          <Icon type={"far"} icon={"trophy"} /> Top traded
                          securities
                        </div>
                      </div>
                      <div css={style.cardContainerBody}>
                        <Query<any>
                          client={luxseGatewayGraphqlClient}
                          query={GET_MOST_TRADED_SECURITIES_LIST_YEARLY}
                          variables={{
                            limit: 2,
                            token: Utils.getCookie("Gztoken-Dsm"),
                          }}
                          //pollInterval={30000}
                          fetchPolicy="network-only"
                        >
                          {({ loading, error, data }): React.ReactNode => {
                            return (
                              <Loader loading={loading}>
                                <>
                                  <div css={style.titleCard}>
                                    Monthly performances
                                  </div>
                                  {data &&
                                    data.lastYearMostTradedLuxXPrimeSecurities &&
                                    data.lastYearMostTradedLuxXPrimeSecurities
                                      .slice(0, 2)
                                      .map((riser: any, index: number) => (
                                        <div
                                          css={style.dataCard}
                                          key={`data-map-${index}`}
                                        >
                                          <div
                                            css={
                                              riser &&
                                              riser.marketData &&
                                              riser.marketData.lastPrice &&
                                              riser.marketData.lastPrice &&
                                              riser.marketData.lastPrice
                                                .amount >= 0
                                                ? style.sideGreen
                                                : style.sideOrange
                                            }
                                          >
                                            <div css={style.side}>
                                              <div css={style.absoluteLabel}>
                                                Turnover
                                              </div>
                                              <div css={style.dataValue}>
                                                {riser &&
                                                riser.marketData &&
                                                riser.marketData.lastPrice &&
                                                riser.marketData.lastPrice &&
                                                riser.marketData.lastPrice
                                                  .amount >= 0
                                                  ? "+"
                                                  : "-"}
                                                {
                                                  riser.marketData?.lastPrice
                                                    ?.amount
                                                }{" "}
                                                {
                                                  riser.marketData
                                                    ?.tradeIndicator
                                                }
                                              </div>
                                            </div>
                                          </div>
                                          <div css={style.side}>
                                            <div css={style.sideInfo}>
                                              <div css={style.type}>Shares</div>
                                              <div css={style.issuer}>
                                                {
                                                  riser.instrumentName.split(
                                                    " "
                                                  )[0]
                                                }
                                              </div>
                                              <div css={style.isin}>
                                                {riser.isin}
                                              </div>
                                            </div>
                                            <div css={style.dataIndicatorRight}>
                                              <Icon icon={"chevron-right"} />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                </>
                              </Loader>
                            );
                          }}
                        </Query>
                        <div css={style.cardContainerInfo}>
                          Top traded securities in <span>november 2021</span>
                        </div>
                        <div css={style.cardContainerFooter}>
                          <a css={style.itemGreen} href="">
                            Consult top traded securities statistics{" "}
                            <Icon icon={"chevron-right"} />
                          </a>
                          <a css={style.itemLink} href="">
                            See all top traded securities at LuxSE{" "}
                            <Icon icon={"chevron-right"} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col mobile={12} tablet={6} desktop={6}>
                  <div css={card}>
                    <div css={cardContainer}>
                      <div css={style.cardContainerTop}>
                        <div
                          css={style.cardContainerTitle}
                          style={{ marginBottom: 14 }}
                        >
                          <Icon type={"far"} icon={"trophy"} /> Top traded
                          securities
                        </div>
                      </div>
                      <div css={style.cardContainerBody}>
                        <Query<any>
                          client={luxseGatewayGraphqlClient}
                          query={GET_FALLERS_SECURITIES_LIST_YEARLY}
                          variables={{
                            limit: 2,
                            token: Utils.getCookie("Gztoken-Dsm"),
                          }}
                          //pollInterval={30000}
                          fetchPolicy="network-only"
                        >
                          {({ loading, error, data }): React.ReactNode => {
                            return (
                              <Loader loading={loading}>
                                <>
                                  <div css={style.titleCard}>
                                    Yearly performances
                                  </div>

                                  {data &&
                                    data.lastYearMostTradedLuxXPrimeSecurities &&
                                    data.lastYearMostTradedLuxXPrimeSecurities
                                      .slice(0, 2)
                                      .map((riser: any, index: number) => (
                                        <div
                                          css={style.dataCard}
                                          key={`data-map-${index}`}
                                        >
                                          <div
                                            css={
                                              riser &&
                                              riser.marketData &&
                                              riser.marketData.lastPrice &&
                                              riser.marketData.lastPrice &&
                                              riser.marketData.lastPrice
                                                .amount >= 0
                                                ? style.sideGreen
                                                : style.sideOrange
                                            }
                                          >
                                            <div css={style.side}>
                                              <div css={style.absoluteLabel}>
                                                Turnover
                                              </div>
                                              <div css={style.dataValue}>
                                                {riser &&
                                                riser.marketData &&
                                                riser.marketData.lastPrice &&
                                                riser.marketData.lastPrice &&
                                                riser.marketData.lastPrice
                                                  .amount >= 0
                                                  ? "+"
                                                  : "-"}
                                                {
                                                  riser.marketData?.lastPrice
                                                    ?.amount
                                                }{" "}
                                                {riser.marketData?.amount}
                                              </div>
                                            </div>
                                          </div>
                                          <div css={style.side}>
                                            <div css={style.sideInfo}>
                                              <div css={style.type}>Shares</div>
                                              <div css={style.issuer}>
                                                {
                                                  riser.instrumentName.split(
                                                    " "
                                                  )[0]
                                                }
                                              </div>
                                              <div css={style.isin}>
                                                {riser.isin}
                                              </div>
                                            </div>
                                            <div css={style.dataIndicatorRight}>
                                              <Icon icon={"chevron-right"} />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                </>
                              </Loader>
                            );
                          }}
                        </Query>
                        <div css={style.cardContainerInfo}>
                          Top traded securities in <span>november 2021</span>
                        </div>
                        <div css={style.cardContainerFooter}>
                          <a css={style.itemGreen} href="">
                            Consult top traded securities statistics{" "}
                            <Icon icon={"chevron-right"} />
                          </a>
                          <a css={style.itemLink} href="">
                            See all top traded securities at LuxSE{" "}
                            <Icon icon={"chevron-right"} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
        <div css={style.topContainer}>
          <MarketStatusComponent
            marketStatus={marketStatus}
            marketLabel={marketLabel}
            marketDetails={marketDetails}
          />
        </div>
      </>
    );
  };
