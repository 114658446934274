import * as React from "react";

import { Col, Grid, Row } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
  Placeholder,
} from "@sitecore-jss/sitecore-jss-react";
import { css, jsx } from "@emotion/react";

import { DiscoverAd } from "../DiscoverAd";
import MarketStatusComponent from "../../core/components/MarketStatusBox/marketStatusBox.component";
import { SitecoreGenericTextField } from "../../interfaces";
import { style } from "./index.style";

/** @jsx jsx */

export interface OpeningAndClosingDaysProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const OpeningAndClosingDays: React.FunctionComponent<OpeningAndClosingDaysProps> =
  (props: OpeningAndClosingDaysProps): React.ReactElement => {
    const { fields, rendering } = props;

    const isH1: boolean = getFieldValue<boolean>(fields, 'is H1', false);
    const hide: boolean = getFieldValue<boolean>(fields, 'hide component', false);

    const marketStatus: any = Number(
      getFieldValue<number>(fields, "market is open", 0)
    );
    const marketLabel: string = getFieldValue<string>(
      fields,
      "market label",
      ""
    );
    const marketDetails: string = getFieldValue<string>(
      fields,
      "market details",
      ""
    );

    return (
      <div css={style.container}>
        <div css={style.hexaLight} />
        <div css={style.halfHexa} />
        <Grid>
          <Row>
            <Col mobile={12} tablet={9} desktop={8}>
              <div css={style.topContainer}>
                <div css={style.leftContainer} />
                <div css={style.title}>
                  <Text
                      tag={isH1 ? 'h1' : 'div'}
                      field={fields && (fields.title as SitecoreGenericTextField)}
                  />
                </div>
                <div css={style.description}>
                  <Text
                    field={
                      fields && (fields.description as SitecoreGenericTextField)
                    }
                  />
                </div>
                <div css={style.hoursTitle}>
                  <Text
                    field={
                      fields && (fields.hoursTitle as SitecoreGenericTextField)
                    }
                  />
                </div>
                <div css={style.hoursContainer}>
                  <div css={style.hoursContainerLeft}>
                    <div css={style.hoursRange}>
                      <Text
                        field={
                          fields &&
                          (fields.hoursRange as SitecoreGenericTextField)
                        }
                      />
                    </div>
                    <div css={style.daysRange}>
                      <Text
                        field={
                          fields &&
                          (fields.daysRange as SitecoreGenericTextField)
                        }
                      />
                    </div>
                  </div>
                    {
                        !hide && (
                            <div css={style.hoursContainerRight}>
                                <MarketStatusComponent
                                    embed
                                    marketStatus={marketStatus}
                                    marketLabel={marketLabel}
                                    marketDetails={marketDetails}
                                />
                            </div>
                        )
                    }
                </div>
              </div>
            </Col>
            <Col mobile={12} tablet={3} desktop={4}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div css={style.adContainer}>
                    <Placeholder name='discover-ad-section' rendering={rendering} />
                    {
                        /*
                         <DiscoverAd
                    title={discoverTitle}
                    subTitle={discoverDescription}
                    buttonLabel={discoverButtonLabel}
                    vertical
                    link={discoverButtonLink}
                  />
                         */
                    }

                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };
