
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const CAN: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <g>
                <path d="M503.172,423.722H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.101c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.77,508.047,423.722,503.172,423.722z" fill="#F5F5F5"></path>
                <rect x="114.76" y="88.278" width="282.48" height="335.45" fill="#F5F5F5"></rect>
            </g>
            <g>
                <path d="M8.828,88.274C3.953,88.274,0,92.226,0,97.101v317.793c0,4.875,3.953,8.828,8.828,8.828h105.931 V88.274H8.828z" fill="#FF0000"></path>
                <path d="M503.172,88.274H397.241v335.448h105.931c4.875,0,8.828-3.953,8.828-8.828V97.101 C512,92.226,508.047,88.274,503.172,88.274z" fill="#FF0000"></path>
                <path d="M309.569,294.758l52.383-29.932l-12.02-6.01c-3.371-1.686-5.301-5.326-4.802-9.063l3.911-29.322 l-22.177,6.452c-4.899,1.426-9.983-1.588-11.085-6.569l-2.124-9.6l-20.823,24.37c-2.887,3.378-8.386,0.798-7.633-3.582 l8.893-51.708l-13.615,3.724c-3.977,1.088-8.177-0.722-10.116-4.36l-14.337-26.871v-0.04l-0.01,0.02l-0.011-0.02v0.04 l-14.337,26.871c-1.941,3.637-6.141,5.447-10.118,4.36l-13.616-3.724l8.893,51.708c0.753,4.378-4.747,6.959-7.634,3.582 l-20.823-24.37l-2.124,9.6c-1.102,4.982-6.186,7.994-11.085,6.569l-22.177-6.452l3.911,29.322c0.499,3.736-1.431,7.377-4.802,9.063 l-12.02,6.01l52.383,29.933c5.426,3.101,7.804,9.677,5.615,15.53l-4.478,11.977l44.885-3.832c2.484-0.212,4.598,1.788,4.525,4.279 l-1.414,48.044h8.828L259,322.711c-0.073-2.492,2.041-4.491,4.525-4.279l44.906,3.834l-4.478-11.977 C301.766,304.435,304.143,297.858,309.569,294.758z" fill="#FF0000"></path>
            </g>
        </svg>
    </React.Fragment>
);