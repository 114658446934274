import { ABW } from './icons/abw';
import { ALB } from './icons/alb';
import { AND } from './icons/and';
import { ANT } from './icons/ant';
import { ARE } from './icons/are';
import { ARG } from './icons/arg';
import { ASM } from './icons/asm';
import { AUS } from './icons/aus';
import { AUT } from './icons/aut';
import { BDI } from './icons/bdi';
import { BEL } from './icons/bel';
import { BGR } from './icons/bgr';
import { BHS } from './icons/bhs';
import { BIH } from './icons/bih';
import { BLR } from './icons/blr';
import { BLZ } from './icons/blz';
import { BMU } from './icons/bmu';
import { BOL } from './icons/bol';
import { BRA } from './icons/bra';
import { BRB } from './icons/brb';
import { CAN } from './icons/can';
import { CHE } from './icons/che';
import { CHL } from './icons/chl';
import { CHN } from './icons/chn';
import { CIV } from './icons/civ';
import { COG } from './icons/cog';
import { COL } from './icons/col';
import { CRI } from './icons/cri';
import { CUW } from './icons/cuw';
import { CYM } from './icons/cym';
import { CYP } from './icons/cyp';
import { CZE } from './icons/cze';
import { DEU } from './icons/deu';
import { DOM } from './icons/dom';
import { DNK } from './icons/dnk';
import { ECU } from './icons/ecu';
import { EGY } from './icons/egy';
import { ESP } from './icons/esp';
import { EST } from './icons/est';
import { FIN } from './icons/fin';
import { FRA } from './icons/fra';
import { GBR } from './icons/gbr';
import { GIB } from './icons/gib';
import { GGY } from './icons/ggy';
import { GRC } from './icons/grc';
import { GTM } from './icons/gtm';
import { HND } from './icons/hnd';
import { HKG } from './icons/hkg';
import { HRV } from './icons/hrv';
import { HUN } from './icons/hun';
import { IDN } from './icons/idn';
import { IMN } from './icons/imn';
import { IND } from './icons/ind';
import { IRL } from './icons/irl';
import { IRN } from './icons/irn';
import { ISL } from './icons/isl';
import { ISR } from './icons/isr';
import { ITA } from './icons/ita';
import { JAM } from './icons/jam';
import { JEY } from './icons/jey';
import { JPN } from './icons/jpn';
import { KAZ } from './icons/kaz';
import { KOR } from './icons/kor';
import { KWT } from './icons/kwt';
import { LBN } from './icons/lbn';
import { LIE } from './icons/lie';
import { LKA } from './icons/lka';
import { LTU } from './icons/ltu';
import { LUX } from './icons/lux';
import { LVA } from './icons/lva';
import { MAR } from './icons/mar';
import { MCO } from './icons/mco';
import { MEX } from './icons/mex';
import { MKD } from './icons/mkd';
import { MLT } from './icons/mlt';
import { MYS } from './icons/mys';
import { NGA } from './icons/nga';
import { NLD } from './icons/nld';
import { NOR } from './icons/nor';
import { NZL } from './icons/nzl';
import { OMN } from './icons/omn';
import { PAK } from './icons/pak';
import { PAN } from './icons/pan';
import { PER } from './icons/per';
import { PHL } from './icons/phl';
import { POL } from './icons/pol';
import { PRT } from './icons/prt';
import { PRY } from './icons/pry';
import { QAT } from './icons/qat';
import { ROU } from './icons/rou';
import { RUS } from './icons/rus';
import { SAU } from './icons/sau';
import { SEN } from './icons/sen';
import { SGP } from './icons/sgp';
import { SLV } from './icons/slv';
import { SMR } from './icons/smr';
import { SRB } from './icons/srb';
import { SUR } from './icons/sur';
import { SVK } from './icons/svk';
import { SVN } from './icons/svn';
import { SWE } from './icons/swe';
import { TGO } from './icons/tgo';
import { THA } from './icons/tha';
import { TJK } from './icons/tjk';
import { TTO } from './icons/tto';
import { TUN } from './icons/tun';
import { TUR } from './icons/tur';
import { TWN } from './icons/twn';
import { UKR } from './icons/ukr';
import { URY } from './icons/ury';
import { USA } from './icons/usa';
import { VCT } from './icons/vct';
import { VEN } from './icons/ven';
import { VGB } from './icons/vgb';
import { VIR } from './icons/vir';
import { VNM } from './icons/vnm';
import { XKX } from './icons/xkx';
import { ZAF } from './icons/zaf';
import { CountryISOCodeAplha3 } from './iso-3.type';

export type TWorldMap = { [ key in CountryISOCodeAplha3 ]?: JSX.Element };

export const worldmap: TWorldMap = {
    ABW,
    ALB,
    AND,
    ANT,
    ARE,
    ARG,
    ASM,
    AUS,
    AUT,
    BEL,
    BDI,
    BGR,
    BHS,
    BIH,
    BLR,
    BLZ,
    BMU,
    BOL,
    BRA,
    BRB,
    CAN,
    CHE,
    CHL,
    CHN,
    CIV,
    COG,
    COL,
    CRI,
    CUW,
    CYM,
    CYP,
    CZE,
    DEU,
    DOM,
    DNK,
    ECU,
    EGY,
    ESP,
    EST,
    FIN,
    FRA,
    GBR,
    GGY,
    GIB,
    GRC,
    GTM,
    HKG,
    HND,
    HRV,
    HUN,
    IDN,
    IMN,
    IND,
    IRL,
    IRN,
    ISL,
    ISR,
    ITA,
    JAM,
    JEY,
    JPN,
    KAZ,
    KOR,
    KWT,
    LBN,
    LIE,
    LKA,
    LTU,
    LUX,
    LVA,
    MAR,
    MCO,
    MEX,
    MKD,
    MLT,
    MYS,
    NGA,
    NLD,
    NOR,
    NZL,
    OMN,
    PAK,
    PAN,
    PER,
    PHL,
    POL,
    PRT,
    PRY,
    QAT,
    ROU,
    RUS,
    SAU,
    SEN,
    SGP,
    SLV,
    SMR,
    SRB,
    SUR,
    SVK,
    SVN,
    SWE,
    UKR,
    URY,
    USA,
    TGO,
    THA,
    TJK,
    TTO,
    TUN,
    TUR,
    TWN,
    VCT,
    VEN,
    VGB,
    VIR,
    VNM,
    XKX,
    ZAF
}