/** @jsx jsx */
import { jsx } from '@emotion/react';
import { style } from './empty.style';
import hexagon from '../../../Header/hexaBoldGrey.svg';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { renderLink } from '../../../../../utils/helper';

interface EmptyResultsProps {
  children?: React.ReactNode;
  label?: string;
  icon?: string;
}

export default function EmptyResults({
  label = "We don't have data related to your criterias",
  icon = 'info-circle',
  children,
}: EmptyResultsProps) {
  return (
    <div css={style.container}>
      <div css={style.hexagon}>
        <img src={renderLink(hexagon)} alt='' />
        <div css={style.icon}>
          <Icon icon={icon} />
        </div>
      </div>
      <div css={style.label}>{label}</div>
      {children}
    </div>
  );
}
