/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
  Icon,
  Pagination,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from "../../interfaces";
import RoundTag from "../../core/components/RoundTag/roundTag.component";

export interface MarketDataProductsIndicesProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
`;
const title = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #253645;
  margin-bottom: 48px;
  margin-top: 48px;
`;

const rowUp = css`
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  color: #54666f;
`;
const pagination = css`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 48px;
`;
const iconIndice = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  width: 37px;
  height: 24px;
  background: #ffffff;
  border: 2px solid #cb1234;
  border-radius: 20px;
  font-size: 14px;
`;
const rowDown = css`
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #54666f;
`;

export const MarketDataProductsIndices: React.FunctionComponent<MarketDataProductsIndicesProps> =
  (props: MarketDataProductsIndicesProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, "heading", "");

    return (
      <div css={container}>
          <div css={title}>LuxSE indices</div>
        <Table>
          <Thead>
            <Th />
            <Th>Name</Th>
            <Th>Last</Th>
            <Th>Change</Th>
            <Th>Prev. close</Th>
            <Th>Month Hi / Lo</Th>
            <Th>Year Hi / lo</Th>
          </Thead>
          <Tbody>
            {new Array(4).fill(0).map((arr: any, index: number) => (
              <Tr>
                <Td>
                  <div css={iconIndice}>
                    <Icon icon={"chart-bar"} />
                  </div>
                </Td>
                <Td>
                  <div css={rowUp}>LuxSEsustainability indices</div>
                  <div css={rowDown}>LU0916824781
                  </div>
                </Td>
                <Td>
                  <div css={rowUp}>1685.1929</div>
                  <div css={rowDown}>16/03/2022 09:00:00</div>
                </Td>
                <Td>0%</Td>
                <Td>1685.1929</Td>
                <Td>
                  <div css={rowUp}>1769.0765</div>
                  <div css={rowDown}>1558.2214</div>
                </Td>
                <Td>
                  <div css={rowUp}>1822.5302</div>
                  <div css={rowDown}>1558.2214</div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <div css={pagination}>
          <Pagination
            initialPage={0}
            onPageChange={(d: any) => {
              //onPaginationUpdate(d);
            }}
            total={18}
          />
        </div>
      </div>
    );
  };
