/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentParams,
  ComponentRendering,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../../style";
import { css, jsx } from "@emotion/react";
import Select from "react-select";
import { canUseDOM } from "../../../predicates";
import hexa from "./hexa.png";
import hexaGreen from "./hexaGreen.png";
import hexaGrey from "./hexaGrey.svg";

const lint = jsx; // linter hack
/** @jsx jsx */

export interface HowToListCardProps {
  fields: any;
  data: any;
  lgxBox?: boolean;
  title: string;
  infoLink: string;
  disabled?: boolean;
  selected?: number;
  excluded?: any[];
  index: number;
  number?: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
  onSelect: (obj: any, index: number) => void;
  onSelectLgx: (obj: boolean) => void;
}

const style = {
  card: (disabled?: boolean) => css`
    background: #ffffff;
    mix-blend-mode: normal;
    border: 1px solid #d9e1e2;
    box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
    border-radius: 16px;
    width: 100%;
    margin-left: 12px;
    margin-right: 12px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    max-width: 33%;
    opacity: ${disabled ? ".4" : "1"};
    @media (max-width: ${breakpointMax}) {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 16px;
    }
  `,
  header: css`
    display: flex;
    align-items: center;
  `,
  headerTop: css`
    display: flex;
    flex-direction: column;
  `,
  hexa: (isGreen: boolean, disabled?: boolean) => css`
    height: 60px;
    width: 60px;
    min-width: 60px;
    background: url(${isGreen
      ? hexaGreen
      : disabled === true
      ? hexaGrey
      : hexa});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: ${isGreen ? "#008C3D" : disabled === true ? "#425563" : "#cb1234"};
  `,
  lgxBox: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 8px;
    background: #f6f8f9;
    margin-top: 16px;
    margin-bottom: 16px;
  `,
  lgxBoxRow: (disabled: boolean) => css`
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    align-items: center;
    width: 100%;

    label {
      margin-left: 8px;
    }

    div {
      justify-content: center;
      display: flex;
    }

    a {
      color: ${disabled ? "#425563" : "#22aa5f"};

      svg {
        margin-left: 8px;
      }
    }

    & > div {
      display: flex;
      width: 100%;
      background: transparent;

      & > div {
        margin-left: 8px;
        background: transparent;
        margin-right: 8px;
      }
    }
  `,
  lgxBoxRowLeft: (disabled: boolean) => css`
    display: flex;
    width: 100%;
    max-width: 60px;
    background: url(${disabled === true ? hexaGrey : hexaGreen}) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 60px;
    color: ${disabled ? "#425563" : "#22aa5f"};
  `,
  lgxBoxRowRight: (disabled: boolean) => css`
    display: flex;
    width: 100%;
    min-width: calc(100% - 60px);
    justify-content: flex-start;
    padding-left: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: ${disabled ? "#425563" : "#22aa5f"};
  `,
  line: css`
    height: 1px;
    background: #d9e1e2;
    width: 100%;
  `,
  title: (disabled?: boolean, selected?: boolean) => css`
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: ${disabled || selected ? "#425563" : "#cb1234"};
    margin-top: 16px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
    }
  `,
  instruction: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
    margin-top: 8px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.01em;
    }
  `,
  rows: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 400px;
    margin-top: 32px;
  `,
  row: (disabled: boolean) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #d9e1e2;
    border-radius: 49px;
    margin-top: 8px;
    margin-bottom: 8px;
    cursor: ${disabled ? "not-allowed" : "pointer"};

    &:hover {
      background: ${disabled ? "#ffffff" : "#22aa5f"};
      color: ${disabled ? "#5E798D" : "#ffffff"};
    }

    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      color: #354450;
    }
  `,
  rowSelected: (disabled: boolean) => css`
    cursor: ${disabled ? "not-allowed" : "pointer"};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 8px;
    background: #22aa5f;
    border-radius: 49px;
    margin-top: 8px;
    margin-bottom: 8px;
    color: #ffffff;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      color: #354450;
    }
  `,
  bottom: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
  `,
  action: css`
    display: flex;
    padding: 8px 16px;
    gap: 8px;
    width: 100%;
    border: 1px solid #425563;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  `,
};

export const HowToListCard: React.FunctionComponent<any> = (
  props: HowToListCardProps
): React.ReactElement => {
  const {
    lgxBox,
    data,
    onSelect,
    onSelectLgx,
    selected,
    disabled,
    title,
    number,
    index,
    infoLink,
    excluded,
  } = props;
  const [lgxData, setLgxData] = React.useState<any>();
  const [lastStep, setLastStep] = React.useState<any>();

  const onChangeEvent = (data: any, index: number, selectorIndex: number) => {
    if (data) {
      setLastStep(data[selectorIndex - 1]);
      if (data) {
        setLgxData({
          label: data?.label,
          index: data?.value,
        });
      }
    } else {
      setLgxData(undefined);
    }
  };
  return (
    <div css={style.card(disabled)}>
      <div css={style.headerTop}>
        <div css={style.header}>
          {selected === undefined ? (
            <div css={style.hexa(false, disabled)}>{index + 1}.</div>
          ) : (
            <div css={style.hexa(true)}>
              <Icon icon="check" />
            </div>
          )}
          <div css={style.line} />
        </div>
        <div css={style.title(disabled, selected !== undefined)}>{title}</div>
      </div>

      {data && !!lgxBox === false ? (
        <div css={style.rows}>
          {data.map((item: any, idx: number) => (
            <div
              css={
                selected === idx
                  ? style.rowSelected(disabled)
                  : style.row(disabled)
              }
              key={`index-${idx}`}
              onClick={() =>
                disabled ? console.debug() : onSelect(item, index)
              }
            >
              {item.label}
            </div>
          ))}
        </div>
      ) : (
        <div className={"inputWrapperFix"} style={{ marginTop: "32px" }}>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#22AA60" : "#b3b3b3",
                boxShadow: state.isFocused ? "0 0 0 1px #22AA60" : "none",
                "&:hover": {
                  borderColor: "#22AA60",
                  boxShadow: "0 0 0 1px #22AA60",
                },
                "&:focus": {
                  borderColor: "#22AA60",
                  boxShadow: "0 0 0 1px #22AA60",
                },
              }),
              indicatorSeparator: (baseStyles) => ({
                ...baseStyles,
                display: "none",
              }),
              dropdownIndicator: (baseStyles) => ({
                ...baseStyles,
                color: disabled ? "#425563" : "#22AA60",
                "&:hover": {
                  color: disabled ? "#425563" : "#22AA60",
                },
              }),
              menuList: (baseStyles) => ({
                ...baseStyles,
                maxHeight: "fit-content",
              })
            }}
            isDisabled={disabled}
            isClearable={false}
            isSearchable={false}
            options={data
              .filter((d: any) => !excluded.includes(d.index - 1))
              .map((it: any) => ({
                label: it.label,
                value: it.index,
              }))}
            onChange={(d: any) => onChangeEvent(data, index, d.value)}
          />
        </div>
      )}

      {!!lgxBox && (
        <>
          <div css={style.lgxBox}>
            <div css={style.lgxBoxRow}>
              <div css={style.lgxBoxRowLeft(disabled)}>
                <Icon icon={"leaf"} />
              </div>
              <div css={style.lgxBoxRowRight(disabled)}>
                Is your product sustainable and eligible for display on LGX?
              </div>
            </div>
            <div css={style.lgxBoxRow(disabled)}>
              <div>
                <input
                  onClick={() => onSelectLgx(true)}
                  type="radio"
                  id="yes"
                  name="lgx"
                  value="yes"
                  checked
                  disabled={disabled}
                />
                <label htmlFor="yes">yes</label>
              </div>
              <div>
                <input
                  onClick={() => onSelectLgx(false)}
                  type="radio"
                  id="no"
                  name="lgx"
                  value="no"
                  checked
                  disabled={disabled}
                />
                <label htmlFor="no">no</label>
              </div>
            </div>
            <div css={style.lgxBoxRow(disabled)}>
              <a href={"/discover-lgx"} title={"More info on LGX"}>
                More info on LGX <Icon icon={"chevron-right"} />
              </a>
            </div>
          </div>
          <Button
            css={css`
              margin-top: 10px;
              background-color: ${disabled || lgxData === undefined
                ? "#425563"
                : "#22aa5f"} !important;

              &:hover {
                background-color: ${disabled || lgxData === undefined
                  ? "#425563"
                  : "#0d8e46"} !important;
              }
            `}
            variant={"primary"}
            label={"Validate"}
            disabled={disabled || lgxData === undefined}
            onClick={() =>
              disabled || lgxData === undefined
                ? console.debug("locked")
                : onSelect(lastStep, index)
            }
          />
        </>
      )}

      <div css={style.bottom}>
        {infoLink && (
          <div
            css={style.action}
            onClick={() => canUseDOM && window.open(infoLink, "_blank")}
          >
            <div>More information</div>
            <Icon icon={"arrow-right"} />
          </div>
        )}
      </div>
    </div>
  );
};
