import * as React from 'react';
/** @jsx jsx */
import { Icon, ToggleButtonGroup, ToggleItem } from '@bdl-cmn-shared-packages-npm/design-system';
import { breakpoint, breakpointMax } from '../../../../style';
import { css, jsx } from '@emotion/react';

import AllSecurities from './AllSecurities';
import LGXMarketData from './LGX';
import { MarketDataHeader } from './Header';
import { TradingDataView } from '../TradingData/trading-data-view.component';
import lgxLogo from './lgxlogo.png';
import { renderLink } from '../../../../utils/helper';
import { tradingStyle } from './trading-data.style';
import { useState } from 'react';

export class MarketdataModel {
  allData: any = [];
  startIndex: number;
  endIndex: number;
  currentData: any = [];
  totalHits: number;
  loader = true;
  label = '';
}


export const MarketDataView = () => {
  const [target, setTarget] = useState<string>('All securities');

  return (
    <div css={tradingStyle.mainContent}>

      <MarketDataHeader />
      {/*<ActionBanner />*/}

      <div css={tradingStyle.displaySettings}>
        <span css={tradingStyle.settingsText}>Display for</span>
        <ToggleButtonGroup onChange={(elem: any) => setTarget(elem)}>
          <ToggleItem selected={target === 'All securities'} value='All securities'>
            <Icon icon={'chart-area'} /> All securities
          </ToggleItem>

          <ToggleItem value='greenx' selected={target === 'greenx'}>
            <img src={renderLink(lgxLogo)} alt={"image"} /> Luxembourg Green Exchange
          </ToggleItem>

          <ToggleItem value='tradingDataView' selected={target === 'tradingDataView'}>
            <Icon icon={'exchange'} /> Trading data
          </ToggleItem>
        </ToggleButtonGroup>
      </div>
      <div
        css={css`
          margin-left: 80px;
          margin-right: 80px;
          @media (max-width: ${breakpointMax}) {
            margin-left: 0px;
            margin-right: 0px;
          }
        `}
      >
        {target === 'All securities' && <AllSecurities />}
        {target === 'greenx' && <LGXMarketData />}
        {target === 'tradingDataView' && <TradingDataView />}
      </div>
    </div>
  );
};
export default MarketDataView;
