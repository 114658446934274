/** @jsx jsx */
import { jsx } from '@emotion/react';
import { formatDate } from '../../../../utils/date';
import PriceColumn from "../../UI/list/security/price";

export const searchSortData: any = {
  securities : {
    nameAsc : { name: "Name (asc)", field: "name", dir:"ASC", default: false},
    nameDesc : { name: "Name (desc)", field: "name", dir:"DESC", default: false},
    issuerNameAsc : { name: "Issuer (asc)", field:"issuerName", dir:"ASC", default: false},
    issuerNameDesc : { name: "Issuer (desc)", field:"issuerName", dir:"DESC", default: false},
    listingDateAsc : { name: "Listing date (asc)", field:"listingDate", dir:"ASC", default: false},
    listingDateDesc : { name: "Listing date (desc)", field:"listingDate", dir:"DESC", default: true}
  },
  issuers : {
    nameAsc : { name: "Name (asc)", field: "name", dir:"ASC", default: false},
    nameDesc : { name: "Name (desc)", field: "name", dir:"DESC", default: false},
    nbSecuritiesAsc : { name: "Securities count (asc)", field:"nbSecurities", dir:"ASC", default: false},
    nbSecuritiesDesc : { name: "Securities count (desc)", field:"nbSecurities", dir:"DESC", default: true},
    firstListingDateAsc : { name: "First listing date (asc)", field:"firstListingDate", dir:"ASC", default: false},
    firstListingDateDesc : { name: "First listing date (desc)", field:"firstListingDate", dir:"DESC", default: false}
  },
  programmes : {
    nameAsc : { name: "Name (asc)", field: "name", dir:"ASC", default: false},
    nameDesc : { name: "Name (desc)", field: "name", dir:"DESC", default: false},
    issuerNameAsc : { name: "Issuer (asc)", field:"issuerName", dir:"ASC", default: false},
    issuerNameDesc : { name: "Issuer (desc)", field:"issuerName", dir:"DESC", default: false},
    startDateAsc : { name: "Start date (asc)", field:"startDate", dir:"ASC", default: false},
    startDateDesc : { name: "Start date (desc)", field:"startDate", dir:"DESC", default: true},
    endDateAsc : { name: "End date (asc)", field:"endDate", dir:"ASC", default: false},
    endDateDesc : { name: "End date (desc)", field:"endDate", dir:"DESC", default: false},
    nbSecuritiesAsc : { name: "Securities count (asc)", field:"nbSecurities", dir:"ASC", default: false},
    nbSecuritiesDesc : { name: "Securities count (desc)", field:"nbSecurities", dir:"DESC", default: false}
  },
  documents : {
    nameAsc : { name: "Name (asc)", field: "complement", dir:"ASC", default: false},
    nameDesc : { name: "Name (desc)", field: "complement", dir:"DESC", default: false},
    publishDateAsc : { name: "Publication date (asc)", field: "publishDate", dir:"ASC", default: false},
    publishDateDesc : { name: "Publication date (desc)", field: "publishDate", dir:"DESC", default: true}
  },
  indices : {
    nameAsc : { name: "Name (asc)", field: "name", dir:"ASC", default: false},
    nameDesc : { name: "Name (desc)", field: "name", dir:"DESC", default: false},
    latestPriceDateAsc : { name: "Latest price date (asc)", field: "latestPriceDate", dir:"ASC", default: false},
    latestPriceDateDesc : { name: "Latest price date (desc)", field: "latestPriceDate", dir:"DESC", default: true}
  },
  luxXPrime : {
    nameAsc : { name: "Name (asc)", field: "name_sort", dir:"ASC", default: false},
    nameDesc : { name: "Name (desc)", field: "name_sort", dir:"DESC", default: false},
    couponAsc : { name: "Coupon (asc)", field:"interestRate", dir:"ASC", default: false},
    couponDesc : { name: "Coupon (desc)", field:"interestRate", dir:"DESC", default: false},
    maturityDateAsc : { name: "Maturity date (asc)", field:"maturityDate", dir:"ASC", default: false},
    maturityDateDesc : { name: "Maturity date (desc)", field:"maturityDate", dir:"DESC", default: true}
  }
}


const stringRenderer = (value: any) => value ? value.toString() : "N/A";

export const data: any = {
  securities: {
    chgSuspUtcDt: { header: "Suspension Date", render: formatDate },
    delistingDate: { header: "Delisting Date", render: formatDate },
    mnemoCode: { header: "Mnemo Code", render: stringRenderer },
    tradingCode: { header: "Trading Code", render: stringRenderer },
    commonCode: { header: "Common Code", render: stringRenderer },
    marketRegulation: { header: "Market Regulation", render: stringRenderer },
    sustainableCard: { header: "Sustainable Card", render: stringRenderer },
    denomination: { header: " Denomination", render: stringRenderer },
    tags: { header: "Tags", render: stringRenderer },
    standAlone: { header: "Standalone", render: stringRenderer },
    latestPrice: {
      header: "Prices Summary",
      render: (value: any) => <PriceColumn {...value} />
    },
    greenCard: { header: "Green Card", render: stringRenderer },
    currency: { header: "Currency", render: stringRenderer },
    finalMaturityDate: { header: "Final Maturity Date", render: formatDate },
    delistingReason: { header: "Delisting Reason", render: stringRenderer },
    amountIssued: { header: "amount Issued", render: stringRenderer },
    category: { header: "Category", render: stringRenderer },
    subCategory: { header: "Sub-category", render: stringRenderer },
    cdDelistingReason: { header: "Code Delisting Reason", render: stringRenderer },
    status: { header: "Status", render: stringRenderer },
    luxXPrime: { header: "LGX", render: (value: any) => !!value ? "Yes" : "No" },
    greenX: { header: "GreenX", render: (value: any) => !!value ? "Yes" : "No" },
  },
  issuers: {
    nbEquities: { header: "Equities Number", render: stringRenderer },
    nbProgs: { header: "Programmes Number", render: stringRenderer },
    nbBonds: { header: "Bonds Number", render: stringRenderer },
    managementCompanyName: { header: "Management Company Name", render: stringRenderer },
    alignmentPercent: { header: "Alignment Percent", render: stringRenderer },
    nbWarrants: { header: "Warrants Number", render: stringRenderer },
    url: { header: "Url", render: stringRenderer },
    nbFundClasses: { header: "Fund Classes Number", render: stringRenderer },
    nbSecurities: { header: "Securities Number", render: stringRenderer },
    codifLEI: { header: "LEI Code", render: stringRenderer },
    caiAsPerCBI: { header: "Ca As Per CBI", render: stringRenderer },
  },
  programmes: {
    activeSecuritiesCount: { header: "Active Securities Count", render: stringRenderer },
    currency: { header: "Currency", render: stringRenderer },
    maxIssuedCode: { header: "Max Issued Code", render: stringRenderer },
    type: { header: "Type", render: stringRenderer },
    amountIssued: { header: "Amount Issued", render: stringRenderer },
    url: { header: "Url", render: stringRenderer },
  },
  documents: {
    ssType: { header: "Sub Type", render: stringRenderer },
    previewUrl: { header: "previewUrl", render: stringRenderer },
    downloadUrl: { header: "downloadUrl", render: stringRenderer },
    obsolete: { header: "Obsolete", render: stringRenderer },
    description: { header: "Description", render: stringRenderer },
    docIncorporesParRef: { header: "Document Incorporate Part Ref", render: stringRenderer },
    language: { header: "Language", render: stringRenderer },
    externalDownloadUrl: { header: "External Download URL", render: stringRenderer },
    hostMemberStates: { header: "Host Member State", render: stringRenderer },
    typePublic: { header: "Type Public", render: stringRenderer },
    oam: { header: "Xam", render: stringRenderer },
    s7: { header: "S7", render: stringRenderer },
    publishingAuthority: { header: "Publishing Authority", render: stringRenderer },
    format: { header: "Format", render: stringRenderer },
    fns: { header: "Fns", render: stringRenderer },
    parentId: { header: "Parent Id", render: stringRenderer },
    supplements: { header: "Supplements", render: stringRenderer },
    deleted: { header: "Deleted", render: stringRenderer },
    size: { header: "Size", render: stringRenderer },
    referenceDate: { header: "Reference Date", render: formatDate },
  },
  indices: {
    
  }
};

data.luxXPrime = { ...data.securities };
