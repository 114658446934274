
export enum CouponType {
	STR = 'Fixed rate',
	FRN = 'Floating rate',
	CPZ = 'Zero coupon'
}

export function couponTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(CouponType).indexOf( value );
  		if( index >= 0) 
			return Object.values(CouponType)[index];
	}catch (e){
		console.error("Failed to get CouponType label for '" + value + "'", e);
	}
	
	console.error("CouponType label for '" + value + "' - '" + value + "' doest exist");
		
	return value;
}