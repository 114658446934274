/** @jsx jsx */

import * as React from "react";
import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
  Link,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx, keyframes } from "@emotion/react";
import { SitecoreGenericTextField } from "../../interfaces";
import { renderLink } from "../../utils/helper";
import { useState } from "react";
import { canUseDOM } from "../../predicates";

export interface PartnersProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const style = {
  container: css`
    display: flex;
    flex-direction: column;
    padding: 48px;
    padding-top: 80px;
    padding-bottom: 40px;
    width: 100%;
    justify-content: center;
    @media (max-width: ${breakpointMax}) {
      padding-top: 40px;
      padding-bottom: 20px;
      padding-left: 16px;
      padding-right: 16px;
    }
  `,
  containerTitle: css`
    font-size: 1.25em;
    color: #425563;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;

    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  `,
  containerSubTitle: css`
    font-size: 16px;
    color: #354450;
    width: 100%;
    max-width: 900px;
    text-align: center;
    line-height: 150%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  `,
  separator: css`
    height: 1px;
    background: #d9e1e2;
    width: 80%;
    margin: auto;
    margin-top: 3em;
  `,
  button: css`
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 32px;
  `,
  link: css`
    text-decoration: none;
    @media (max-width: ${breakpointMax}) {
      width: calc(50% - 8px);
    }
  `,
  containerImages: css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    gap: 16px;

    img {
      width: 100px;
      margin: 10px;
    }

    @media (max-width: ${breakpointMax}) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      min-height: 170px;
      img {
        width: 100px;
      }
    }
  `,
  partnerBox: css`
    box-shadow: 0px 16px 25px 0px rgba(37, 56, 69, 0.15);
    border-radius: 4px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 16px 32px;

    img {
      max-height: 76px;
    }

    &:hover {
      box-shadow: 0px 16px 25px 0px rgba(37, 56, 69, 0.3);

      img {
        transform: scale(1.05);
        transition: all 150ms ease-in-out;
      }
    }

    transition: all 150ms ease-in-out;
  `,
  navigationButtons: css`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  `,
  buttonLeft: css`
  width: 30px;
  height: 60px;
  transform: scale(0.7);
  display: flex;
  background: #ffffff;
  margin-left: -40px;
  margin-right: -40px;
  margin-top: -85px;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.20);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-out;
  :hover {
    background: #f6f8f9;
  }
  :disabled {
    background-color: #ffffff !important;
    :hover {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.20);
    }
  }
  svg {
    color: #22aa5f;
    width: 20px !important;
    height: 20px !important;
  }
  `,

  buttonRight: css`
  width: 30px;
  height: 60px;
  transform: scale(0.7);
  display: flex;
  background: #ffffff;
  margin-left: -40px;
  margin-right: -40px;
  margin-top: -85px;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.20);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-out;
  :hover {
    background: #f6f8f9;
  }
  :disabled {
    background-color: #ffffff !important;
    :hover {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.20);
    }
  }
  svg {
    color: #22aa5f;
    width: 20px !important;
    height: 20px !important;
  }
`,
};

export const Partners: React.FunctionComponent<PartnersProps> = (
  props: PartnersProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const btnLabel: string = getFieldValue<string>(fields, "button label", "");
  const btnLinkPage: any = getFieldValue<any>(fields, "button link page", "");
  const btnLink: any = getFieldValue<any>(fields, "button link", "");
  const imageHeight: number = getFieldValue<number>(fields, "imageHeight", 0);
  const imageWidth: number = getFieldValue<number>(fields, "imageWidth", 0);

  const [currentIndex, setCurrentIndex] = useState(0);

  const itemsPerPage = 6;
  
  const handleNext = () => {
  const remainingPartners = partnerArray.length - currentIndex;
  const newIndex = Math.min(currentIndex + itemsPerPage, remainingPartners);
  setCurrentIndex(newIndex);
  };

  const handlePrev = () => {
  const newIndex = currentIndex - itemsPerPage;
  setCurrentIndex(Math.max(newIndex, 0));
  };

  const {
    partner1: { value: partner1Value },
    partner2: { value: partner2Value },
    partner3: { value: partner3Value },
    partner4: { value: partner4Value },
    partner5: { value: partner5Value },
    partner6: { value: partner6Value },
    partner7: { value: partner7Value },
    partner8: { value: partner8Value },
    partner9: { value: partner9Value },
    partner10: { value: partner10Value },
    partnerMultiLink1: { value: partnerMultiLink1Value },
    partnerMultiLink2: { value: partnerMultiLink2Value },
    partnerMultiLink3: { value: partnerMultiLink3Value },
    partnerMultiLink4: { value: partnerMultiLink4Value },
    partnerMultiLink5: { value: partnerMultiLink5Value },
    partnerMultiLink6: { value: partnerMultiLink6Value },
    partnerMultiLink7: { value: partnerMultiLink7Value },
    partnerMultiLink8: { value: partnerMultiLink8Value },
    partnerMultiLink9: { value: partnerMultiLink9Value },
    partnerMultiLink10: { value: partnerMultiLink10Value },
  } = fields;

  const partnerArray = [
    {
      image: partner1Value?.src || null,
      link: partnerMultiLink1Value || null,
    },
    {
      image: partner2Value?.src || null,
      link: partnerMultiLink2Value || null,
    },
    {
      image: partner3Value?.src || null,
      link: partnerMultiLink3Value || null,
    },
    {
      image: partner4Value?.src || null,
      link: partnerMultiLink4Value || null,
    },
    {
      image: partner5Value?.src || null,
      link: partnerMultiLink5Value || null,
    },
    {
      image: partner6Value?.src || null,
      link: partnerMultiLink6Value || null,
    },
    {
      image: partner7Value?.src || null,
      link: partnerMultiLink7Value || null,
    },
    {
      image: partner8Value?.src || null,
      link: partnerMultiLink8Value || null,
    },
    {
      image: partner9Value?.src || null,
      link: partnerMultiLink9Value || null,
    },
    {
      image: partner10Value?.src || null,
      link: partnerMultiLink10Value || null,
    },
  ];

  const filledPartners = partnerArray.filter(
    (partner) => partner.image && partner.link
  ).length;

  const triggerAction = (link1: string, link2: any) => {
    if (canUseDOM) {
      if (link1) {
        window.open(renderLink(link1), "_self");
      } else if (link2) {
        window.open(renderLink(link2), "_self");
      } 
    }
  };

  return (
    <div css={style.container}>
      <div css={style.containerTitle}>
        <Text field={fields && (fields.title as SitecoreGenericTextField)} />
      </div>
      {fields.subTitle && Boolean(fields.subTitle.value) && (
        <div css={style.containerSubTitle}>
          <Text field={fields.subTitle as SitecoreGenericTextField} />
        </div>
      )}
      <div css={style.containerImages}>
        {partnerArray.slice(currentIndex, Math.min(currentIndex + itemsPerPage, partnerArray.length)).map((partner, index) => {
          {
            const imageWithParams = `${partner.image}?h=${imageHeight}&w=${imageWidth}`;

            return (
              <>
                {partner.image && (
                  <Link
                    key={index}
                    target={
                      partner.link?.value
                        ?.linktype === "external"
                        ? "_blank"
                        : "_self"
                    }
                    title={`partner-${index}`}
                    field={partner.link}
                    css={style.link}
                  >
                    <div css={style.partnerBox}>
                      <img
                        alt={"image"}
                        src={renderLink(imageWithParams)}
                        key={`partner-${index}`}
                        loading="lazy"
                      />
                    </div>
                  </Link>
                ) 
                }
              </>
            );
          }
        })}
      </div>

      {filledPartners > itemsPerPage ? (
        <div css={style.navigationButtons}>
          <Button
            css={style.buttonLeft}
            iconRight={'chevron-left'}
            variant="thirdary"
            onClick={handlePrev}
            disabled={currentIndex === 0}
          />
          <Button
            css={style.buttonRight}
            iconRight={'chevron-right'}
            variant="thirdary"
            onClick={handleNext}
            disabled={currentIndex >= partnerArray.length - itemsPerPage}
          />
        </div>
      ) :null}

      {btnLabel && (
        <div css={style.button}>
          <Button
            iconRight={"chevron-right"}
            label={btnLabel}
            onClick={() =>
              triggerAction(
                btnLink,
                btnLinkPage
              )
            }
          />
        </div>
      )}
    </div>
  );
};
