export enum IssuerSubTypeEnum {
	CGEN = 'Corporate',
	CCOM = 'Communications',
	CCOD = 'Consumer Discretionary',
	CCOS = 'Consumer Staples',
	CENE = 'Energy',
	CHEC = 'Health Care',
	CIND = 'Industrials',
	CMAT = 'Materials',
	CTEC = 'Technology',
	CUTI = 'Utilities',

	FGEN = 'Financial',
	FASM = 'Asset management',
	FBAN = 'Banking',
	FSPF = 'Specialty finance',
	FFIS = 'Financial services',
	FINS = 'Insurance',
	FREE = 'Real estate',
	FFUT = 'Funds & Trusts',
	FCOF = 'Consumer Finance',
	FCMF = 'Commercial Finance',

	SGEN = 'SSA',
	SSOV = 'Sovereigns',
	SQSO = 'Quasi-sovereigns',
	SSUP = 'Supranationals',
	SAGE = 'Agencies',
}

export function issuerSubTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(IssuerSubTypeEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(IssuerSubTypeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get IssuerSubTypeEnum label for '" + value + "'", e);
	}
		
	return value;
}