import { css, jsx } from '@emotion/react';
import * as React from 'react';
/** @jsx jsx */

import { Component } from 'react';
import { Table } from '@bdl-cmn-shared-packages-npm/design-system';
import OfficialListRow from './Official-list-row.component';
import OfficialListHeader from './official-list-header.component';
import { styleOfficialList } from './official-list.styles';

export class OfficialListTableView extends Component<any, any> {
  render() {
    const officialList = this.props.officialListData;

    return (
      <div css={styleOfficialList.tableContent}>
        <Table>
          <OfficialListHeader />
          <tbody>
            {officialList?.length > 0 ? officialList.map((ol: any) => <OfficialListRow rowData={ol} />) : 'no data'}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default OfficialListTableView;
