export enum DocumentSubTypeEnum {
    XAO2 = "Accusé de réception (OAM)",
    D003 = "Admission document",
    D004 = "Registration document Supplement",
    D005 = "Registration document",
    D006 = "Securities note",
    D007 = "Summary note",
    D008 = "Summary & Securities note",
    D010 = "Prospectus",
    D011 = "Prospectus Signé",
    D012 = "Descriptive note (Full exemption)",
    D013 = "Securities note  - Base prospectus",
    D020 = "Prospectus",
    D030 = "Prospectus Action signé",
    D035 = "Addendum",
    D040 = "Prospectus",
    D050 = "Prospectus OPC signé",
    D051 = "Simplified Prospectus",
    D052 = "Prospectus OPC simplifié signé",
    D055 = "Key Information Document",
    D060 = "Base Prospectus",
    D061 = "Prospectus de base Programme signé",
    D070 = "Information notice",
    D080 = "Unitary Prospectus",
    D081 = "Signed Unitary prospectus",
    D090 = "Final terms (Pricing supplement)",
    D091 = "Signed final terms",
    D110 = "Exchange offer",
    D120 = "Exchange offer Supplement",
    D121 = "Supplément offre échange signé",
    D130 = "Prospectus Supplement",
    D131 = "Supplément de prospectus signé",
    D140 = "Erratum",
    D150 = "Tender offer Prospectus",
    D205 = "Accord Approbation",
    D206 = "Accord Approbation signé",
    D210 = "Accusé Admission",
    D215 = "Accusé Approbation",
    D220 = "Actes notariés",
    D230 = "Autre Document",
    D240 = "Autre Lettre",
    D245 = "Autre Lettre CSSF",
    D250 = "Certificat Cotation",
    D260 = "Certificat de conformité",
    D266 = "Check-list schema",
    D270 = "Codes commun - ISIN et certificat éligibilité",
    D274 = "Commentaire Admission",
    D275 = "Commentaire Approbation",
    D276 = "Commentaire préliminaire",
    D289 = "Corporate document",
    D290 = "Notice - Press release",
    D295 = "Confirmation de Cotation MTN",
    D300 = "Contrat ou Agreement",
    D310 = "Demandes de dérogation Admission",
    D315 = "Demandes de dérogation Approbation",
    D317 = "Letter of undertaking - EuroMTF",
    D318 = "", //"Document incorporated by reference",
    D319 = "Letter of undertaking - XLUX",
    D320 = "Engagement Collectif",
    D321 = "Engagement Collectif SOL",
    D325 = "Formulaire de demande Admission à la cote",
    D326 = "Formulaire de demande SOL",
    D330 = "Garantie",
    D345 = "Lettre Accompagnement Demande Admission",
    D350 = "Lettre Admission",
    D355 = "Lettre Annulation de procédure",
    D356 = "Lettre Approbation CSSF",
    D365 = "Lettre Confirmation Cotation",
    D370 = "Lettre de remboursement",
    D375 = "Lettre de retrait",
    D385 = "Lettre Rappel Document Manquant",
    D390 = "Lettre Rappel Dossier En Cours",
    D395 = "Lettre Suspension Cotation",
    D396 = "Letter - Transfer",
    D397 = "Letter - Confirmation Fastlane",
    D400 = "Lettres engagement - Questionnaire",
    D403 = "Lettre personnes de contact",
    D410 = "Memo remarque explication renseignement divers",
    D415 = "Message e-Listing",
    D416 = "Ext. confirmation - Closing done",
    D417 = "Ext. confirmation - No material change",
    D418 = "LuxSE confirmation - Approval sent",
    D420 = "Note pour Comité - Signalétique",
    D421 = "Note pour Comité - Signalétique signée",
    D435 = "Premier cours de cotation",
    D445 = "Promesse de paiement",
    D455 = "Publication",
    D480 = "Annual notice",
    D485 = "Quarterly report",
    D490 = "Semi-annual report",
    D495 = "Interim financial statement",
    D500 = "Annual report",
    D505 = "Règlement de gestion",
    D520 = "Réponses aux commentaires Approbation",
    D540 = "Issuer's resolutions",
    D545 = "Sans Commentaire Approbation",
    D550 = "Specimen Titre - Certificat imprimeur",
    D555 = "Articles of association",
    D560 = "Statut Garant",
    D565 = "Taux intérêt",
    D570 = "Motif de clôture",
    D580 = "Note interne",
    D590 = "Memo remarque ou explication ou rens. divers tiers",
    D600 = "Traduction du résumé",
    D735 = "Formulaire de demande admission",
    D740 = "Déclaration Emetteur",
    D745 = "Formulaire de souscription - autre information",
    D750 = "Formulaire de souscription - info réglementée",
    D755 = "Enregistrement de mail",
    D765 = "Communiqué - Avis de Presse Finesti",
    D805 = "LGX Déclaration d'admission",
    D810 = "KYC form - Regulated Market / Euro MTF",
    D811 = "Scoring Matrix",
    D815 = "KYC form - SOL",
    D820 = "Certificat d'incorporation notarié",
    D821 = "Shareholders' register",
    D822 = "IdCards and Passports",
    D823 = "Proof of Residence ",
    D824 = "Authorised Signatures - Power - Specimen",
    D825 = "Extrait d'immat. au registre de commerce/sociétés",
    D826 = "Proof of valid  LEI",
    D830 = "Organigramme signé",
    D835 = "Avis compliance",
    D836 = "Compliance Notice",
    D840 = "Autre document compliance",
    D845 = "Contrat MyBourse",
    D901 = "LEI Authorization Letter",
    D902 = "LEI Application Form",
    D903 = "LEI Code",
    D904 = "Reporting CSSF – RTS18",
    D905 = "LEI Request Form",
    D906 = "Universal Registration Document",
    D907 = "Supplement to Universal Registration Document",
    D908 = "Universal registration document (URD) - Amendment",
    D988 = "Reporting - LCH Dividends",
    D989 = "Letter - Termination of contract",
    D990 = "Lettre Annulation de dossier",
    D991 = "Pièce jointe",
    D992 = "Fichier retraits",
    D993 = "Contrat FIRST",
    D994 = "FNS Public Service / CSSF Filing - Request Form",
    D995 = "OAM Deposit Services - Request Form",
    D996 = "Annexe B OAM",
    D997 = "Annexe C OAM",
    AFAE = "Annual financial and audit reports",
    HYFR = "Half yearly fcial & audit reports / ltd reviews",
    PATG = "Payments to governments",
    HOMS = "Home Member State",
    INIF = "Inside Information / Ad Hoc Information",
    MSNO = "Major shareholding notifications",
    ADIP = "Acquisition or disposal of the issuer's own shares",
    TNVC = "Total number of voting rights and capital",
    CRAS = "Changes in rights att. to classes of shares or sec",
    QFRE = "Quarterly financial report",
    ATTC = "Amendment to the terms and conditions",
    COSO = "Consent Solicitation",
    DIVD = "Dividends",
    ERCS = "Early redemption / Cancellation / Delisting",
    EXOF = "Exchange offer / Tender offer",
    FIRP = "Financial report",
    GEME = "General Meeting",
    MATS = "Managers' transactions",
    MAEX = "Maturity Extension",
    MERG = "Merger / Acquisition",
    NMCH = "Name Change",
    NWIS = "New issues",
    PRER = "Press release",
    PUOF = "Public Offer",
    STNO = "Stabilisation Notice",
    STBB = "Stock Buy-back",
    SUOI = "Substitution of the Issuer",
    SUSP = "Suspension",
    SUSF = "Suspension lifted",
    TABI = "Takeover Bid",
    DELI = "Delisting",
    GNAN = "General announcement",
    PUBA = "Public Auction",
    SEME = "Stock Exchange Members",
    WOME = "Withdrawal of membership",
    XOA1 = "OAM Filing",
    BBPR = "Buy-back programmes",
    BBTR = "Buy-back transactions",
    SBPR = "Stabilisation measures",
    SBMS = "Stabilisation measures",
    SBTR = "Stabilisation transactions", 
    SBOU = "Stabilisation outcome",
    VOBA = "Voluntary basis",
}

export function documentSubTypeLabel(value: string): string {
    if (value == null || value === "" || !value.length)
        return "";

    try {
        let index = Object.keys(DocumentSubTypeEnum).indexOf(value.toString().toUpperCase());
        return index >= 0 ? Object.values(DocumentSubTypeEnum)[index] : value;
    } catch (e) {
        console.error("Failed to get DocumentSubTypeEnum label for '" + value + "'", e);
    }

    return value;
}