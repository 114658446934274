import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import style from './price.style';
import { BestOrder, BestOrders } from '../../../../../interfaces';
import { formatToThousandsSeparator } from '../../../../../utils';

export interface BestOrdersColumnProps {
  bestOrders?: BestOrders
}

export function BestOrderColumn({ bestOrder } : {bestOrder: BestOrder}) {
  return (
    <div css={style.container}>
      <div css={style.info}>
        { bestOrder ?
          <>
            <div>{formatToThousandsSeparator(parseFloat(bestOrder.price))}</div>
            <div css={style.subLabel}>{formatToThousandsSeparator(bestOrder.quantity)}</div>
          </>
          :
          <>-</>
        }
      </div>
    </div>
  );
}

export function BidAskColumn({ bestOrders }: {bestOrders?: BestOrders}) {
  let askOrder : BestOrder = null;
  let bidOrder : BestOrder = null;
  
  if( bestOrders?.ask && bestOrders?.ask[0])
    askOrder = bestOrders?.ask[0];
  if( bestOrders?.bid && bestOrders?.bid[0])
    bidOrder = bestOrders?.bid[0];

  return(
    <div css={style.cellSplitTwo}>
      <div css={style.cellSplitLeft}>
        <BestOrderColumn bestOrder={bidOrder}/>
      </div>
      <div css={[style.cellSplitRight, style.cellSplitSeparator]}>
        <BestOrderColumn bestOrder={askOrder}/>
      </div>
    </div>
  );
}

export function AskColumn({ bestOrders }: {bestOrders?: BestOrders}) {
  let askOrder : BestOrder = null;
  
  if( bestOrders?.ask && bestOrders?.ask[0])
    askOrder = bestOrders?.ask[0];

  return <BestOrderColumn bestOrder={askOrder}/>
}

export function BidColumn({ bestOrders }: {bestOrders?: BestOrders}) {
  let bidOrder : BestOrder = null;
  
  if( bestOrders?.bid && bestOrders?.bid[0])
    bidOrder = bestOrders?.bid[0];

  return <BestOrderColumn bestOrder={bidOrder}/>
}