/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  Text,
  Placeholder,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import { trackEvent } from "../../utils/tracking";
import { canUseDOM } from "../../predicates";

export interface BlogTagsContainerProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  gap: 6px;
  min-height: 40px;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
`;
const tagStyle = css`
  background: #ffffff;
  border: 1px solid #e8ecee;
  border-radius: 32px;
  padding: 8px 12px;
  gap: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #425563;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  align-self: start;
  cursor: pointer;

  &:hover {
    border: 1px solid #22aa5f;
  }

  transition: all 150ms ease-in-out;
`;

export const BlogTagsContainer: React.FunctionComponent<BlogTagsContainerProps> =
  (props: BlogTagsContainerProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const onClickTag = async (url: string, id: string) => {
      /*console.log('on click tag', url, id)
    try {
        const event = await trackEvent('Downloads', id);
        console.log('event', event)*/
      if (canUseDOM) {
        window.open(url, "_self");
      } /*
        } catch (err) {
            console.log('Error on tag click', err);
        }*/
    };
    return (
      <div css={container}>
        {fields?.tags?.map((tag: any, index: number) => (
          <div
            css={tagStyle}
            onClick={() => onClickTag(`/blog/search?tag=${tag?.name}`, tag?.id)}
          >
            {tag?.name}
          </div>
        ))}
      </div>
    );
  };
