import { css } from "@emotion/react";

export const marketNewsStyle = {
  container: css`
    align-items: center;
    gap: 32px;
    background: #ffffff;
    border-bottom: 1px solid #d9dee2;
    display: contents;
  `,
  tabContainer: css`
    width: inherit;
  `,
  title1: css`
    margin-bottom: 8px;
    font-family: "Mont";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 150%;
    color: #54666f;
  `,
  title2: css`
    margin-bottom: 16px;
    font-family: "Mont";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #253645;
  `,

  underList: css`
    background: #d3f2e1 !important;
  `,
  buttonUnderList: css`
    background: #e8faf0 !important;
    color: #22aa5f !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;
  `,

  listCountry: css``,
  title3: css`
    margin-top: 116px;
    //width: 105px;
    //height: 20px;
    display: inline;
    font-family: "Mont";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    /* Table/Data */

    color: #54666f;
  `,

  statusRow2: css`
    margin-bottom: 12px;
    font-family: "Mont";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #54666f;
  `,
  dateRow2: css`
    margin-top: 25px;
    font-family: "Mont";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #54666f;
  `,

  row: css`
    width: 100%;
    margin-bottom: 16px;

    > div:first-child {
      position: relative;

      border: 1px solid #a4b6c2;
      border-radius: 6px;
      background-color: #1a2834;
      color: white;

      > div:first-child {
        width: -webkit-calc(100% - 0.4em);
        width: -moz-calc(100% - 0.4em);
        width: calc(100% - 0.4em);
      }

      > div:first-child:after {
        position: absolute;
        right: 0.5em;
        top: 0.4em;
        font-size: 1.5rem;
        color: #22aa5f;
        content: "\\203A";
        transition: transform 0.15s ease-out;
      }

      cursor: pointer;
    }

    &&.open > div:first-child > div:first-child:after {
      transform: rotate(90deg);
    }
  `,

  rowNoClickable: css`
    cursor: default !important;
    background-color: #e8ecee !important;

    &:hover {
      background-color: #e8ecee;
    }

    > div:first-child {
      cursor: default;
      background-color: #e8ecee;

      &:hover {
        background-color: #e8ecee;
      }
    }
  `,

  status: css`
    line-height: 15px;
    color: #354450;
    margin-bottom: 8px;
    display: flex;
    gap: 5px;
    margin-bottom: 8px;
  `,

  tag: css`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
    border: solid 1px #e3e6e8;
    background: #ffffff;
    border-radius: 999em;
    padding: 8px 16px;
    font-weight: bold;
    width: fit-content;
  `,

  amount: css`
    font-family: "Mont";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #354450;
  `,

  date: css`
    color: #354450;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  `,

  product: css`
    color: #354450;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  `,

  name: css`
    color: #354450;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,

  childMenu: css`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: #425563;
    margin-bottom: 2px;
  `,
  childMenuSelected: css`
    > div {
      background-color: #364956 !important;
    }
  `,

  title: css`
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 8px;
    color: white;

    &:hover {
      color: white;
    }
  `,

  searchResultsFilters: css`
    background-color: #1a2834;
    color: white;
    display: flex;
    flex-direction: column;
    //width: 17.875rem;
    padding: 16px;

    width: 20%;
    min-width: 20%;
    max-width: 20%;
    transition: all 0.1s ease-in-out;

    svg {
      margin-right: 12px;
    }

    .css-alnabd-SDGsList {
      svg {
        margin-right: 0;
      }
    }
  `,
  searchResultsFilters2: css`
    background-color: white;
    color: black;
  `,

  search: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  tabContentWrapper: css`
    padding-top: 0;
    display: flex;
    flex-direction: column;
    width: inherit;
  `,
  tabs: css`
    width: inherit;
    position: relative;
    align-items: flex-start;
    display: flex;
    justify-content: left;

    > div {
      > div {
        padding-top: 0;
        //overflow: auto;
        display: flex;

        :first-of-type {
          justify-content: left;
          overflow: hidden;
          color: white;

          > div {
            &:hover {
              color: #22aa5f;
            }
          }
        }
      }
    }
  `,
  menuItem: css`
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;

    > div > div {
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      > div {
        width: 100%;
      }
    }
  `,
  menuNoItem: css`
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-style: italic;
    font-size: 0.875rem;
  `,
  menuItemRow: css`
    width: 100%;

    > div > div:hover {
      background: #253845;
    }
  `,
};
