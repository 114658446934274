
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const PAN: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <path fill="#DA121A" d="M256,256.001h247.172c4.875,0,8.828-3.953,8.828-8.828V97.104c0-4.875-3.953-8.828-8.828-8.828 H264.828c-4.875,0-8.828,3.953-8.828,8.828V256.001z" />
            <g>
                <path fill="#072357" d="M8.828,423.725h238.345c4.875,0,8.828-3.953,8.828-8.828V256.001H8.828 c-4.875,0-8.828,3.953-8.828,8.828v150.069C0,419.773,3.953,423.725,8.828,423.725z" />
                <path fill="#072357" d="M131.4,134.488l8.416,25.234l26.6,0.206c3.444,0.026,4.872,4.422,2.101,6.467l-21.398,15.801 l8.023,25.362c1.038,3.284-2.7,5.999-5.502,3.997L128,196.088l-21.641,15.468c-2.802,2.003-6.54-0.714-5.502-3.997l8.023-25.362 l-21.398-15.801c-2.771-2.046-1.343-6.441,2.101-6.467l26.6-0.206l8.416-25.234C125.689,131.221,130.311,131.221,131.4,134.488z" />
            </g>
            <path fill="#DA121A" d="M387.4,302.212l8.416,25.234l26.6,0.206c3.444,0.026,4.872,4.422,2.101,6.467l-21.398,15.801 l8.023,25.362c1.038,3.284-2.7,5.999-5.502,3.997L384,363.812l-21.641,15.468c-2.802,2.003-6.54-0.714-5.502-3.997l8.023-25.362 l-21.398-15.801c-2.771-2.046-1.343-6.441,2.101-6.467l26.6-0.206l8.416-25.234C381.689,298.945,386.311,298.945,387.4,302.212z" />
        </svg>
    </React.Fragment>
);
