
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const PHL: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M3.256,421.59L256,256L3.256,90.41C1.311,92.029,0,94.375,0,97.103v317.793 C0,417.625,1.311,419.971,3.256,421.59z" />
            <path fill="#BF0D3E" d="M3.256,421.59c1.53,1.274,3.425,2.134,5.571,2.134h494.345c4.875,0,8.828-3.953,8.828-8.828V256H256 L3.256,421.59z" />
            <path fill="#0032A0" d="M3.256,90.41c1.53-1.274,3.425-2.134,5.571-2.134h494.345c4.875,0,8.828,3.953,8.828,8.828V256H256 L3.256,90.41z" />
            <g>
                <circle fill="#FED141" cx="92.69" cy="256" r="28.69" />
                <polygon fill="#FED141" points="92.69,256 81.931,212.966 92.69,198.621 103.448,212.966 " />
                <polygon fill="#FED141" points="92.69,256 103.448,299.034 92.69,313.379 81.931,299.034 " />
                <polygon fill="#FED141" points="92.69,256 49.655,266.759 35.31,256 49.655,245.241 " />
                <polygon fill="#FED141" points="92.69,256 135.724,245.241 150.069,256 135.724,266.759 " />
                <polygon fill="#FED141" points="92.69,256 115.512,217.963 133.263,215.426 130.727,233.177 " />
                <polygon fill="#FED141" points="92.69,256 69.867,294.037 52.116,296.574 54.653,278.823 " />
                <polygon fill="#FED141" points="92.69,256 54.653,233.177 52.116,215.426 69.867,217.963 " />
                <polygon fill="#FED141" points="92.69,256 130.727,278.823 133.263,296.574 115.512,294.037 " />
                <path fill="#FED141" d="M23.658,131.694l1.496,11.16l-10.076,5.028c-1.304,0.651-1.03,2.588,0.405,2.85l11.076,2.026 l1.668,11.136c0.216,1.442,2.143,1.779,2.836,0.495l5.35-9.908l11.106,1.854c1.438,0.241,2.354-1.489,1.348-2.543l-7.77-8.15 l5.195-9.99c0.673-1.293-0.689-2.699-2.003-2.068l-10.152,4.872l-7.895-8.029C25.222,129.389,23.465,130.25,23.658,131.694z" />
                <path fill="#FED141" d="M28.102,348.906l-1.89,11.101l-11.114,1.805c-1.439,0.234-1.752,2.165-0.461,2.841l9.973,5.227 l-1.717,11.128c-0.222,1.441,1.517,2.335,2.56,1.316l8.053-7.87l10.054,5.073c1.302,0.657,2.69-0.722,2.044-2.028l-4.996-10.091 l7.93-7.993c1.026-1.035,0.146-2.781-1.298-2.57l-11.142,1.633l-5.152-10.013C30.279,347.17,28.345,347.468,28.102,348.906z" />
                <path fill="#FED141" d="M202.734,239.555l-6.481,9.208l-10.81-3.15c-1.399-0.408-2.513,1.201-1.639,2.367l6.755,9.009 l-6.337,9.308c-0.82,1.205,0.365,2.761,1.745,2.29l10.655-3.64l6.894,8.903c0.893,1.153,2.739,0.505,2.717-0.952l-0.17-11.258 l10.598-3.806c1.372-0.493,1.327-2.449-0.066-2.878l-10.761-3.318l-0.344-11.255C205.448,238.924,203.573,238.362,202.734,239.555z " />
            </g>
        </svg>
    </React.Fragment>
);
