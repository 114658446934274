import gql from "graphql-tag";

export const pageSearchItemFragment = gql`
  fragment pageSearchItemFragment on LuxseSearchPageResultItem {
    id
    path
    type
    title
    subTitle
    description
    imageSrc
    tags
    categories
    author
    modificationDate
    publicationDate
  }
`;

export const pageSearchFilterFragment = gql`
  fragment pageSearchFilterFragment on LuxseSearchPageFilters {
    types {
      name
      count
    }
    tags {
      name
      count
    }
    categories {
      name
      count
    }
    authors {
      name
      count
    }
  }
`;

export const pageSearchFullFragment = gql`
  ${pageSearchItemFragment}
  ${pageSearchFilterFragment}
  fragment pageSearchFullFragment on LuxseSearchPageResults {
    totalHits
    results {
      ...pageSearchItemFragment
    }
    filters {
      ...pageSearchFilterFragment
    }
  }
`;

export const pageSearchNoFilterFragment = gql`
  ${pageSearchItemFragment}
  fragment pageSearchNoFilterFragment on LuxseSearchPageResults {
    totalHits
    results {
      ...pageSearchItemFragment
    }
  }
`;

export const GET_SEARCH_PAGE_RESULTS = gql`
  ${pageSearchFullFragment}
  query GetLuxseSearchPageResults(
    $searchTerm: String!
    $page: Int
    $size: Int
    $sort: String
    $types: [String!]
    $typesOperator: String
    $tags: [String!]
    $tagsOperator: String
    $categories: [String!]
    $categoriesOperator: String
    $authors: [String!]
    $authorsOperator: String
    $fromDate: Date
    $toDate: Date
  ) {
    luxseSitePagesSearch(
      searchTerm: $searchTerm
      page: $page
      size: $size
      sort: $sort
      types: $types
      typesOperator: $typesOperator
      tags: $tags
      tagsOperator: $tagsOperator
      categories: $categories
      categoriesOperator: $categoriesOperator
      authors: $authors
      authorsOperator: $authorsOperator
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ...pageSearchFullFragment
    }
  }
`;
