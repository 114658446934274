
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const ZAF: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <polygon fill="#000000" points="0,150.069 0,361.931 141.241,256 " />
            <path fill="#E03C31" d="M70.621,88.276l158.897,123.586H512V97.103c0-4.875-3.953-8.828-8.828-8.828H70.621V88.276z" />
            <path fill="#001489" d="M70.621,423.724l158.897-123.586H512v114.759c0,4.875-3.953,8.828-8.828,8.828H70.621V423.724z" />
            <path fill="#007749" d="M56.889,88.276H8.828C3.953,88.276,0,92.228,0,97.103v36.21L154.974,256L0,378.69v36.206 c0,4.875,3.953,8.828,8.828,8.828h48.056L224.147,291.31H512v-70.62H224.148L56.889,88.276z" />
            <polygon fill="#FFFFFF" points="85.333,88.276 56.889,88.276 224.148,220.69 512,220.69 512,203.034 230.291,203.034 " />
            <polygon fill="#FFB81C" points="0,133.313 0,155.831 126.53,256 0,356.173 0,378.69 154.974,256 " />
            <polygon fill="#FFFFFF" points="224.147,291.31 56.884,423.724 85.329,423.724 230.289,308.966 512,308.966 512,291.31 " />
        </svg>
    </React.Fragment>
);
