import * as React from 'react';
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
/** @jsx jsx */
import hexa from './hexa.png';
import { style } from './index.style';
import { css, jsx, keyframes } from '@emotion/react';
import { Icon, Button } from '@bdl-cmn-shared-packages-npm/design-system';
import { SitecoreGenericTextField } from '../../interfaces';
import { renderLink } from '../../utils/helper';

export interface LuxXPrimeDoubleTradingProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const LuxXPrimeDoubleTrading: React.FunctionComponent<LuxXPrimeDoubleTradingProps> = (
  props: LuxXPrimeDoubleTradingProps
): React.ReactElement => {
  const { fields } = props;

  const btnLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
  const btnLink: string = getFieldValue<string>(fields, 'btnLink', '');
  const invert = Number(getFieldValue<number>(fields, 'invert', 0));
  const greyBackground = Number(getFieldValue<number>(fields, 'greyBackground', 0));
  const image: any = getFieldValue<any>(fields, 'image', { src: '' });

  return (
    <div css={style.parentContainer} style={{ background: greyBackground ? '#F0F5F5' : 'transparent' }}>
      <div css={invert ? style.containerRevert : style.container}>
        <div css={invert ? style.leftRevert : style.left}>
          {image && image.src && <img src={renderLink(image.src)} alt={"image"} />}
        </div>
        <div css={invert ? style.rightRevert : style.right}>
          {fields && fields.heading && (
            <div css={style.heading}>
              <Text field={fields && (fields.heading as SitecoreGenericTextField)} />
            </div>
          )}
          <div css={style.title}>
            <Text field={fields && (fields.title as SitecoreGenericTextField)} />
          </div>
          <div css={style.description}>
            <Text field={fields && (fields.description as SitecoreGenericTextField)} />
          </div>
          {btnLabel && (
            <div css={style.action}>
              <Button
                label={btnLabel}
                onClick={() => (btnLink ? (window.location.href = btnLink) : console.log)}
                iconRight={'chevron-right'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
