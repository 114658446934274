import * as React from "react";

import { breakpoint, breakpointMax } from "../../style";

import HalfHexa from "./HalfHexa.svg";
import HexaLight from "./HexaLight.svg";
import { css } from "@emotion/react";
import hexaBG from "./hexaBg.svg";
import { renderLink } from "../../utils/helper";

export const style = {
  container: css`
    width: 100%;
    margin: auto;
    padding-left: 155px;
    margin-top: 80px;
    @media (max-width: ${breakpointMax}) {
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      margin: auto;
      margin-top: 48px;
    }
  `,
  leftContainer: css`
    background: url(${renderLink(hexaBG)});
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -150px;
    left: 0;
    width: 70%;
    z-index: -1;
    height: 100%;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  hexaLight: css`
    background: url(${renderLink(HexaLight)});
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 85px;
    width: 74px;
    left: 51px;
    z-index: 3;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  halfHexa: css`
    background: url(${renderLink(HalfHexa)});
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 154px;
    width: 134px;
    left: 46%;
    top: 60px;
    z-index: 3;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  topContainer: css`
    width: 100%;
  `,
  title: css`
    color: #354450;
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 32px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    
    h1 {
      color: #354450;
      font-weight: 800;
      font-size: 32px;
      margin-bottom: 32px;
      @media (max-width: ${breakpointMax}) {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }
    }
  `,
  description: css`
    color: #354450;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    width: calc(100% - 122px);
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  `,
  hoursTitle: css`
    color: #354450;
    font-weight: 800;
    margin-top: 48px;
    font-size: 24px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
    }
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  `,
  hoursContainer: css`
    border: solid 1px #e3e6e8;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    background: #ffffff;
    margin-top: 24px;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  hoursContainerLeft: css`
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: flex-start;
    @media (max-width: ${breakpointMax}) {
      border-bottom: solid 1px #e3e6e8;
      width: 100%;
    }
  `,
  hoursContainerRight: css`
    display: flex;
    width: 50%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-left: solid 1px #e3e6e8;
    padding-left: 24px;
    @media (max-width: ${breakpointMax}) {
      border-left: none;
      width: 100%;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  `,
  hoursRange: css`
    color: #354450;
    font-weight: 800;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 24px;
    padding-left: 24px;
    line-height: 30px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 8px;
    }
  `,
  daysRange: css`
    padding-left: 24px;
    color: #425563;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    font-weight: 800;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
    }
  `,
  adContainer: css`
    width: 90%;
    max-width: 400px;
    display: flex;
    justify-content: center;
  `,
};
