/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from "react";
import { issuerSubTypeLabel } from '../../../../../enums/issuer-subtype';
import { issuerTypeLabel } from '../../../../../enums/issuer-type';
import { SearchSuggestionItemDto } from '../../../../../interfaces/search/search-suggestion';
import { CountryFlagIcon } from '../../../CountryFlagIcon/countryFlagIcon.component';
import { issuerDetailPageLink } from '../../../IssuerCard';
import RoundTag from '../../../RoundTag/roundTag.component';
import { getSuggestionComplement, getSuggestionNumber, getSuggestionTitle } from '../../../Search/input/suggestion';
import { style } from '../../../Search/input/suggestion.style';

export const IssuerSuggestion = ({ suggestion }: { suggestion: SearchSuggestionItemDto }) => {
    if( ! suggestion )
        return <></>;

    let title = getSuggestionTitle(suggestion, suggestion.id);

    return (
        <a css={style.card} title={title}
           href={issuerDetailPageLink(getSuggestionComplement(suggestion, "url"), suggestion.id)}>
            <div css={style.cardHeader}>
                <RoundTag small textColor={"#425563"} green={!!suggestion?.properties?.numbers?.find(elem => elem.name === "nbGreens" && elem.value > 0)}>I</RoundTag>
                { suggestion?.properties?.type ?
                    <div css={style.cardTagType}>
                        {issuerTypeLabel(suggestion.properties.type)}
                    </div>
                    : <></>
                }
                { suggestion?.properties?.subType ?
                    <div css={style.cardTagType}>
                        {issuerSubTypeLabel(suggestion.properties.subType)}
                    </div>
                    : <></>
                }
            </div>
            <div css={style.cardBody}>
                <div css={style.cardTitle}>
                    {title}
                </div>
            </div>
            <div css={style.cardFooter}>
                { suggestion?.properties?.country ?
                        <div css={[style.cardTagCountry, style.cardFooterSeparator]}>
                            <div css={style.cardTagCountryFlag}>
                                <CountryFlagIcon
                                    code={suggestion.properties.country}
                                    size={{ height: 20, width: 30 }}
                                />
                            </div>
                            {suggestion.properties.country}
                        </div>
                        : <></>
                }
                <div css={style.cardTagInfo}>
                    {getSuggestionNumber(suggestion, "nbSecurities", 0)} securities
                </div>
            </div>
        </a>
    );
};