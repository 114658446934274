
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const TJK: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#006600" d="M0,311.908h512v102.988c0,4.875-3.953,8.828-8.828,8.828H8.828c-4.875,0-8.828-3.953-8.828-8.828 V311.908z" />
            <path fill="#CC0000" d="M8.828,88.276h494.345c4.875,0,8.828,3.953,8.828,8.828v102.988H0V97.103 C0,92.228,3.953,88.276,8.828,88.276z" />
            <rect y="200.09" fill="#FFFFFF" width="512" height="111.81" />
            <g>
                <path fill="#F8C300" d="M227.069,287.535c3.67-1.849,5.817-4.897,6.966-7.097c6.575,4.296,12.209,2.837,15.145,1.463 c2.865-1.342,5.224-3.531,6.798-5.762c1.575,2.231,3.934,4.42,6.798,5.762c2.935,1.374,8.569,2.83,15.145-1.463 c1.148,2.2,3.295,5.247,6.966,7.097c2.116,1.066,4.448,1.601,6.979,1.601c2.6,0,5.408-0.563,8.408-1.687l-1.457-3.886 c-4.771,1.789-8.819,1.886-12.025,0.286c-4.488-2.237-5.986-7.17-5.998-7.211l-0.875-3.063l-2.447,2.038 c-4.318,3.596-8.789,4.478-12.936,2.53c-4.211-1.972-6.484-5.963-6.484-7.16v-17.907c0.898-1.975,2.879-3.358,5.186-3.358 c0.793,0,1.577,0.172,2.329,0.511l2.568,1.159l-0.388-2.792c-0.814-5.866-5.875-10.289-11.77-10.289 c-5.896,0-10.956,4.423-11.77,10.289l-0.388,2.792l2.568-1.159c0.751-0.339,1.536-0.511,2.329-0.511 c2.307,0,4.289,1.383,5.186,3.358v17.907c0,1.197-2.272,5.188-6.484,7.16c-4.145,1.947-8.618,1.068-12.936-2.53l-2.429-2.012 l-0.893,3.029c-0.014,0.049-1.513,4.982-6.001,7.219c-3.209,1.601-7.255,1.505-12.025-0.286l-1.457,3.886 c2.999,1.124,5.809,1.687,8.408,1.687C222.62,289.135,224.952,288.602,227.069,287.535z M248.716,246.605 c-0.248,0-0.495,0.01-0.742,0.032c1.389-3.096,4.495-5.219,8.003-5.219c3.507,0,6.614,2.123,8.003,5.219 c-3.226-0.284-6.278,1.292-8.003,3.789C254.385,248.118,251.724,246.605,248.716,246.605z" />
                <path fill="#F8C300" d="M220.051,300.727l1.315,3.935c8.821-2.944,21.438-4.633,34.612-4.633 c13.174,0,25.791,1.689,34.612,4.633l1.315-3.935c-9.228-3.082-22.324-4.849-35.927-4.849 C242.375,295.879,229.279,297.645,220.051,300.727z" />
                <path fill="#F8C300" d="M255.978,291.73c2.163,0.007,4.068-1.279,5.359-3.01c-0.858-0.53-2.004-0.84-3.302-1.001 c0.001-0.047,0.017-0.09,0.017-0.138c0-1.779-0.84-3.315-2.074-4.149c-1.235,0.834-2.074,2.37-2.074,4.149 c0,0.047,0.015,0.09,0.017,0.138c-1.297,0.161-2.443,0.47-3.302,1.001C251.908,290.451,253.814,291.735,255.978,291.73z" />
                <path fill="#F8C300" d="M236.935,293.69c2.12-0.426,3.731-2.067,4.649-4.021c-0.947-0.348-2.132-0.422-3.434-0.32 c-0.009-0.046-0.002-0.092-0.012-0.139c-0.355-1.742-1.485-3.08-2.862-3.65c-1.043,1.065-1.559,2.738-1.203,4.48 c0.01,0.047,0.033,0.086,0.043,0.132c-1.238,0.417-2.3,0.949-3.034,1.641C232.693,293.251,234.816,294.129,236.935,293.69z" />
                <path fill="#F8C300" d="M276.68,285.559c-1.377,0.57-2.507,1.908-2.862,3.65c-0.01,0.047-0.003,0.092-0.012,0.139 c-1.302-0.102-2.487-0.028-3.434,0.32c0.918,1.955,2.529,3.595,4.649,4.021c2.119,0.439,4.242-0.439,5.854-1.878 c-0.735-0.691-1.796-1.224-3.034-1.641c0.011-0.046,0.034-0.085,0.043-0.132C278.239,288.297,277.722,286.624,276.68,285.559z" />
                <path fill="#F8C300" d="M255.424,209.15l-1.398,4.191l-4.417,0.034c-0.572,0.004-0.809,0.734-0.349,1.074l3.553,2.624 l-1.332,4.212c-0.172,0.545,0.448,0.996,0.914,0.664l3.594-2.569l3.594,2.569c0.466,0.332,1.086-0.118,0.914-0.664l-1.333-4.212 l3.553-2.624c0.46-0.34,0.223-1.069-0.349-1.074l-4.417-0.034l-1.398-4.191C256.372,208.607,255.605,208.607,255.424,209.15z" />
                <path fill="#F8C300" d="M230.138,216.065l-1.398,4.191l-4.417,0.034c-0.572,0.004-0.809,0.734-0.349,1.074l3.553,2.624 l-1.333,4.212c-0.172,0.545,0.448,0.996,0.914,0.664l3.594-2.569l3.594,2.569c0.466,0.332,1.086-0.118,0.914-0.664l-1.333-4.212 l3.553-2.624c0.46-0.34,0.223-1.069-0.349-1.074l-4.417-0.034l-1.398-4.191C231.086,215.522,230.319,215.522,230.138,216.065z" />
                <path fill="#F8C300" d="M211.553,234.737l-1.398,4.191l-4.417,0.034c-0.572,0.004-0.809,0.734-0.349,1.074l3.553,2.624 l-1.332,4.212c-0.172,0.545,0.448,0.996,0.914,0.664l3.594-2.569l3.594,2.569c0.466,0.332,1.086-0.118,0.914-0.664l-1.332-4.212 l3.553-2.624c0.46-0.34,0.223-1.069-0.349-1.074l-4.417-0.034l-1.398-4.191C212.501,234.195,211.734,234.195,211.553,234.737z" />
                <path fill="#F8C300" d="M204.724,259.979l-1.398,4.191l-4.417,0.034c-0.572,0.004-0.809,0.734-0.349,1.074l3.553,2.624 l-1.333,4.212c-0.172,0.545,0.448,0.996,0.914,0.664l3.594-2.569l3.594,2.569c0.466,0.332,1.086-0.118,0.914-0.664l-1.333-4.212 l3.553-2.624c0.46-0.34,0.223-1.069-0.349-1.074l-4.417-0.034l-1.398-4.191C205.672,259.436,204.905,259.436,204.724,259.979z" />
                <path fill="#F8C300" d="M281.862,216.065l1.398,4.191l4.417,0.034c0.572,0.004,0.809,0.734,0.349,1.074l-3.553,2.624 l1.333,4.212c0.172,0.545-0.448,0.996-0.914,0.664l-3.594-2.569l-3.594,2.569c-0.466,0.332-1.086-0.118-0.914-0.664l1.333-4.212 l-3.553-2.624c-0.46-0.34-0.223-1.069,0.349-1.074l4.417-0.034l1.398-4.191C280.914,215.522,281.681,215.522,281.862,216.065z" />
                <path fill="#F8C300" d="M300.447,234.737l1.398,4.191l4.417,0.034c0.572,0.004,0.809,0.734,0.349,1.074l-3.553,2.624 l1.333,4.212c0.172,0.545-0.448,0.996-0.914,0.664l-3.594-2.569l-3.594,2.569c-0.466,0.332-1.086-0.118-0.914-0.664l1.333-4.212 l-3.553-2.624c-0.46-0.34-0.223-1.069,0.349-1.074l4.417-0.034l1.398-4.191C299.499,234.195,300.266,234.195,300.447,234.737z" />
                <path fill="#F8C300" d="M307.276,259.979l1.398,4.191l4.417,0.034c0.572,0.004,0.809,0.734,0.349,1.074l-3.553,2.624 l1.332,4.212c0.172,0.545-0.448,0.996-0.914,0.664l-3.594-2.569l-3.594,2.569c-0.466,0.332-1.086-0.118-0.914-0.664l1.332-4.212 l-3.553-2.624c-0.46-0.34-0.223-1.069,0.349-1.074l4.417-0.034l1.398-4.191C306.328,259.436,307.095,259.436,307.276,259.979z" />
            </g>
        </svg>
    </React.Fragment>
);
