
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import CssfApprovalsView from "../../core/components/CssfApprovals/cssf-approvals-view.component";

export const CssfApprovalsComponent: React.FunctionComponent = (): React.ReactElement => {
    return (
        <CssfApprovalsView/>
    );
}
    