import gql from "graphql-tag";
import { securityMarketFragment } from "../securities-market.queries";

export const preciousMetalFragment = gql`
    ${securityMarketFragment}
    fragment preciousMetalFragment on PreciousMetal {
        id
        name: instrumentName
        listingDate
        delistingDate
        delistingReason
        currency
        maturityDate
        status
        tags
        marketRule
        commonCode
        standalone
        marketData{
          ...securityMarketFragment
        }
    }
`;

export const GET_PRECIOUS_METALS_QUERY = gql`
    ${preciousMetalFragment}
    query GetPreciousMetal{
        preciousMetals{
            ...preciousMetalFragment
        }
    }
`;