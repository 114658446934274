
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {Button, Icon} from "@bdl-cmn-shared-packages-npm/design-system";
import hexa from "../ListingProcess/hexa.svg";
import {renderLink} from "../../utils/helper";

export interface DoublePartsComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const style = {
    parentContainer: css`
      display: flex;
      width: 100%;
    `,
    container: css`
      display: flex;
      width: 100%;
      height: 100%;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      margin: 60px auto;
    `,
    leftPart: css`
        display: flex;
        width: 50%;
        flex-direction: column;
        align-items: center;
    `,
    leftPartCard: css`
        width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    `,
    rightPart: css`
        display: flex;
        width: 50%;
        flex-wrap: wrap;
        height: 100%;
      justify-content: space-between;
    `,
    cardTitle: css`
        font-weight: 800;
        font-size: 32px;
        line-height: 41px;
        color: #cb1234;
        margin-bottom: 28px;
    `,
    heading: css`
        font-weight: 800;
        font-size: 20px;
        line-height: 26px;
        color: #425563;
        margin-bottom: 24px;
    `,
    contentTop: css`
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #354450;
    `,
    contentBottom: css`
        margin-top: 40px;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #354450;
    `,
    cardContent: css`
        display: flex;
        flex-direction: column;
        padding: 10px;
        height: 100%;
        width: 100%;
        max-width: 250px;
    `,
    iconCard: css`
      color: #cb1234;
        img {
            width: 44px;
            height: 48px;
      }
      margin-bottom: 16px;
    `,
    iconCardSvg: css`
        position: absolute;
        margin-top: -36px;
        margin-left: 16px;
    `,
    titleCard: css`
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        color: #354450;
        margin-bottom: 16px;
    `,
    textCard: css`
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #354450;
    `,
    actions: css`
        width: 100%;
        margin-top: 40px;
        display: flex;
    `,
}

export const  DoublePartsComponent: React.FunctionComponent<DoublePartsComponentProps> = (props: DoublePartsComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const btnLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
    const btnLink: string = getFieldValue<string>(fields, 'btnLink', '');
    const iconBloc1: string = getFieldValue<string>(fields, 'iconBloc1', '');
    const iconBloc2: string = getFieldValue<string>(fields, 'iconBloc2', '');
    const iconBloc3: string = getFieldValue<string>(fields, 'iconBloc3', '');
    const iconBloc4: string = getFieldValue<string>(fields, 'iconBloc4', '');
    const backgroundColor: string = getFieldValue<string>(fields, 'backgroundColor', '#FFFFFF');
    const invert: boolean = getFieldValue<boolean>(fields, 'invertSides', false);

    return (
        <div css={style.parentContainer} style={{backgroundColor}}>
            <div css={style.container} style={{flexDirection: invert ? 'row-reverse': 'row'}}>
                <div css={style.leftPart}>
                    <div css={style.leftPartCard}>
                        <div css={style.cardTitle}>
                            <Text
                                field={fields && fields.title as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.contentTop}>
                            <Text
                                field={fields && fields.description as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.actions}>
                            <Button iconRight={'chevron-right'} label={btnLabel} onClick={() => btnLink ? window.location.href = btnLink : console.debug}  />
                        </div>
                    </div>
                </div>
                <div css={style.rightPart}>
                    <div css={style.cardContent}>
                        <div css={style.iconCard}>
                            <img src={renderLink(hexa)} alt={"image"} />
                            <div css={style.iconCardSvg}>
                                <Icon icon={iconBloc1} />
                            </div>
                        </div>
                        <div css={style.titleCard}>
                            <Text
                                field={fields && fields.titleBloc1 as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.textCard}>
                            <Text
                                field={fields && fields.descriptionBloc1 as SitecoreGenericTextField}
                            />
                        </div>
                    </div>
                    <div css={style.cardContent}>
                        <div css={style.iconCard}>
                            <img src={renderLink(hexa)} alt={"image"} />
                            <div css={style.iconCardSvg}>
                                <Icon icon={iconBloc2} />
                            </div>
                        </div>
                        <div css={style.titleCard}>
                            <Text
                                field={fields && fields.titleBloc2 as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.textCard}>
                            <Text
                                field={fields && fields.descriptionBloc2 as SitecoreGenericTextField}
                            />
                        </div>
                    </div>
                    <div css={style.cardContent}>
                        <div css={style.iconCard}>
                            <img src={renderLink(hexa)} alt={"image"} />
                            <div css={style.iconCardSvg}>
                                <Icon icon={iconBloc3} />
                            </div>
                        </div>
                        <div css={style.titleCard}>
                            <Text
                                field={fields && fields.titleBloc3 as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.textCard}>
                            <Text
                                field={fields && fields.descriptionBloc3 as SitecoreGenericTextField}
                            />
                        </div>
                    </div>
                    <div css={style.cardContent}>
                        <div css={style.iconCard}>
                            <img src={renderLink(hexa)} alt={"image"} />
                            <div css={style.iconCardSvg}>
                                <Icon icon={iconBloc4} />
                            </div>
                        </div>
                        <div css={style.titleCard}>
                            <Text
                                field={fields && fields.titleBloc4 as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.textCard}>
                            <Text
                                field={fields && fields.descriptionBloc4 as SitecoreGenericTextField}
                            />
                        </div>
                    </div>
                </div>
                <Text
                    field={fields && fields.heading as SitecoreGenericTextField}
                />
            </div>
        </div>
    );
}
