
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import bg from './headerBg.svg';

import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {breakpointMax} from "../../style";

export interface BlogCategoryHeaderProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1232px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const topContainer = css`
  width: calc(100% - 64px);
  margin-left: 32px;
  background: url(${bg});
  background-size: 100%;
  background-repeat: no-repeat;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    margin-left: 0;
    background: none;
  }
`;

const title = css`
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;
  padding-top: 80px;
  color: #253845;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 32px;
    line-height: 41px;
    color: #253845;
  }
`;

const content = css`
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #425563;
  margin-top: 8px;
  max-width: 600px;
  padding-bottom: 80px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #425563;
  }
`;

export const BlogCategoryHeader: React.FunctionComponent<BlogCategoryHeaderProps> = (props: BlogCategoryHeaderProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    return (
        <div css={topContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={content}>
                    <Text
                        field={fields && fields.content as SitecoreGenericTextField}
                    />
                </div>
            </div>
        </div>
    );
}
    