/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  Text,
  isExperienceEditorActive,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Link,
  Placeholder,
} from "@sitecore-jss/sitecore-jss-react";

export interface BlogImagesProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = (isExp = false) => css`
  width: 100%;
  display: flex;
  max-width: 1220px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  gap: 40px;
  min-height: ${isExp ? "40px" : "0px"};
  border: ${isExp ? "dashed 2px #a2a2a2" : "none"};
`;

export const BlogImages: React.FunctionComponent<BlogImagesProps> = (
  props: BlogImagesProps
): React.ReactElement => {
  const { fields, rendering } = props;

  return (
    <div css={container(isExperienceEditorActive())}>
      <Placeholder name="images-container" rendering={rendering} />
    </div>
  );
};
