import * as React from "react";
import {css} from "@emotion/react";
import { breakpoint } from "../../../../style";

export const style = {
  securityIndices: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 0px 80px;
    
    @media (max-width: ${breakpoint}) {
      flex-direction: column;
      padding: 10px;
    }
  `,
  securityIndicesTop: css`
    width: 100%;
    display: flex;
    border-bottom: solid 1px #e3e6e8;

    &:last-child {
      border-bottom: none;
    }
    @media (max-width: 720px) {
      flex-direction: column;
    }
  `,
  blocTitle: css`
    font-weight: 700;
    font-size: 28px;
    line-height: 41px;
    color: #354450;
    margin: 0px;
    margin-bottom: 25px;

    @media (max-width: 720px) {
      font-size: 1.5em;
      line-height: 2em;
      flex-direction: column;
    }

    @media screen and (min-width: 720px) and (max-width: 1080px) {
      font-size: 2em;
      line-height: 1.5em;
    }
  `,
  flexRow: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3em;

    @media (max-width: 720px) {
      padding: 1em;
    }
  `,

  securityIndicesRow: css`
    cursor: pointer;
  `,

  securityIndicesContainer: css`
    display: flex;
    margin-bottom: 3em;

    @media (max-width: 720px) {
      flex-direction: column;
    }
  `,

  securityIndicesFieldTitle: css`
    color: #253645;
    font-weight: bold;
    text-decoration: none;
    line-height: 2em;
  `,

  securityIndicesFieldSubTitle: css`
    display: flex;
    flex-direction: row;
    color: #354450;
    gap: 0.5em;
    line-height: 1.5em;
  `,
}