import * as React from "react";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  RichText,
  isExperienceEditorActive,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import { Query } from "react-apollo";
import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
import { canUseDOM } from "../../predicates";
import { GET_INDICEMARKET_QUERY } from "../../graphql/queries/indices.queries";
import { Box, Flex, Loader } from "@bdl-cmn-shared-packages-npm/design-system";
import Utils from "../../core/utils/utils";

export interface IndexDescriptionProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

type BasketItem = {
  id: number;
  isinCode: null | string;
  securityName: null | string;
  weight: null | number;
  numberOfShares: null | number;
  __typename: string;
};

//TODO: TO BE FURTHER DEFINED and MOVED TO TYPE DEF FILES
type security = any[];

type IndiceMarketData = {
  id: string;
  indexName: string;
  codeType: string;
  currency: string;
  basket?: BasketItem[];
  securities: security[];
  indexPrice?: any;
  security?: any;
};

type IndiceMarketVariables = {
  id: string;
  token?: string;
};

//TODO: to delete, component created only as demo to show how to fetch graphql data
//Helpful also to test if we are in client or server side rendering
export const IndexDescription: React.FunctionComponent<IndexDescriptionProps> =
  (props: IndexDescriptionProps): React.ReactElement => {
    const { fields } = props;
    const description: string = getFieldValue<string>(
      fields,
      "description",
      ""
    );
    const isExperienceEditorActivated: boolean = isExperienceEditorActive();

    return (
      <Flex>
        <Box margin={20}>
          <Query<IndiceMarketData, IndiceMarketVariables>
            client={luxseGatewayGraphqlClient}
            query={GET_INDICEMARKET_QUERY}
            variables={{ id: "100006", token: Utils.getCookie("Gztoken-Dsm") }}
            fetchPolicy="network-only"
            errorPolicy="all"
          >
            {({ loading, data }) => {
              return loading ? (
                <Loader loading={loading} spin={loading} />
              ) : (
                <>
                  <div style={{ fontWeight: 700 }}>
                    Solactive LGX Green Bond Impact Index
                  </div>
                  <div>
                    ISIN{" "}
                    {(data && data.security && data.security.isin) ||
                      "DE000SL0AYQ0"}
                  </div>
                  <div style={{ fontWeight: 700 }}>Description</div>
                  <div style={{ color: "red" }}>
                    {canUseDOM ? (
                      <p>Client-side rendering</p>
                    ) : (
                      <p>Server-side rendering</p>
                    )}
                  </div>
                  <div>
                    {description.length > 0 || isExperienceEditorActivated ? (
                      <div>
                        <RichText
                          field={
                            fields &&
                            (fields.description as SitecoreGenericTextField)
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </>
              );
            }}
          </Query>
        </Box>
      </Flex>
    );
  };
