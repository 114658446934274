import { css } from "@emotion/react";

export const style = {
  linkContainer: css`
    text-decoration: none;
    width: 100%;
    &:hover {
      background-color: #E5E5E5;
    }
    border: solid 1px rgba(37, 54, 69, 0.07);
    box-shadow: 0 16px 24px rgb(37 54 69 / 7%);
    border-radius: 5px;
    margin-left: 1em;
    margin-right: 1em;
    width: 100%;

    @media (max-width: 720px) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1em;
    }

    @media (min-width: 719px) {
      width: 33%;
      margin-left: 10px;
      margin-right: 10px;

      &:first-of-type {
        margin-left: 0px;
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
        margin-right: 0px;
      }
    }
  `,

  securitySummary: css`
    display: flex;
    align-items: center;
  `,

  header: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 0.7rem;
    
    @media (min-width: 719px) {
      width: 35%;
    }
  `,
  roundedTag: css`
    > div {
      height: 25px;
      width: 25px;
      color: #253645;
      font-size: 0.6rem;
    }
  `,
  name: css`
    font-size 1.2rem;
    color: #354450;
  `,

  title: css`
    font-size: 1.2rem;
    color: #253645;
    font-weight: bold;
  `,
  subTitle: css`
    font-size: 1rem;
    color: #354450;
  `,

  container: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 0.7rem;

    @media (min-width: 719px) {
      width: 35%;
    }
  `,

  info: css`
    display:flex;
    flex-direction: row;
    color: #354450;
    gap: 0.5em;

    > *:not(:last-child)::after {
      content: "-";
      position: relative;
      right: -0.25rem;
    }
  `,



  market: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.7em;
    gap: 0.7rem;

    @media (min-width: 719px) {
      width: 25%;
    }
  `,

  marketRealTimeCol: css`
    display: flex;
  `,
  marketRealTimeColLeft: css`
    display: flex;
    flex-direction: column;
    padding-right: 0.7em;
    text-align: right;
  `,
  marketRealTimeColRight: css`
    display: flex;
    flex-direction: column;
    padding-left: 0.7em;
    text-align: left;
    border-left: solid 1px #e3e6e8;
  `,

  marketRealTimeColLabel: css`
    padding-bottom: 0.5em;
    color: #354450;
    opacity: 0.7;
    font-weight: 500;
    font-size: 14px;
  `,
  marketRealTimeColAmount: css`
    color: #354450;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 0.5em;

    @media (max-width: 720px) {
      padding-bottom: 0;
    }
  `,
  marketRealTimeColSubAmount: css`
    color: #354450;
    padding-bottom: 0.5em;
    font-weight: 500;
    font-size: 14px;
  `,
  graph: css `
    width: 100%;
    @media (min-width: 719px) {
      width: 40%;
    }
  `,
}