import * as React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Icon, InputField, Button } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from './searchOverlay.style';
import searchLogo from './searchLogo.svg';
import { useEffect, useRef, useState } from 'react';
import { Suggestion } from '../Search/input/suggestion';
import { renderLink } from '../../../utils/helper';
import { searchPageLink } from '../Search';

export interface Row {
  label: string;
  action: () => void;
}
export interface SearchOverlayProps {
  label?: string;
  children?: any;
  rows?: Row[];
  onClose?: () => void;
  onSearch: () => void;
  textInput: string;
  onTextInputChange: (value: string) => void;
}

const SearchOverlay: React.FunctionComponent<SearchOverlayProps> = ({
  onClose,
  onSearch,
  textInput = '',
  onTextInputChange,
}: SearchOverlayProps) => {
  const parentRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [suggestionOpen, setSuggestionOpen] = React.useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  const formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch();
    onClose();
  };

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onTextInputChange(event.target.value);
    if (event.target.value.length >= 3) {
      setSuggestionOpen(true);
    } else {
      setSuggestionOpen(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementsByTagName('input') && document.getElementsByTagName('input')[1] && document.getElementsByTagName('input')[1].setAttribute('autofocus', 'true');
      document.getElementsByTagName('input') && document.getElementsByTagName('input')[1] && document.getElementsByTagName('input')[1].focus();
    }, 500);
  }, []);

  return (
    <div css={style.searchOverlay} ref={parentRef}>
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          zIndex: -1,
          background: visible ? 'rgb(66 85 99 / 50%)' : 'transparent',
          display: visible ? 'flex' : 'none',
        }}
        onClick={onClose}
      ></div>
      <div css={style.header} style={{ marginTop: visible ? '0' : '-125px' }}>
        <div css={style.headerLeft}>
          <img src={renderLink(searchLogo)} alt='logo bourse' />
        </div>
        <form onSubmit={formSubmitHandler}>
          <div css={style.headerCenter}>
            <InputField
              placeholder={'Search by ISIN, Issuer...'}
              value={textInput}
              onChange={inputChangeHandler}
              width='364px'
            />
            <div css={style.headerSuggestion}>
              <Suggestion open={suggestionOpen} onClose={() => setSuggestionOpen(false)} textInput={textInput} />
            </div>
            <div css={style.headerCenterButton}>
              <Button label={'Search'} iconRight={'search'} type='submit' />
            </div>
          </div>
          <div css={style.headerCenterAdvanced}>
            <a title="Go to advanced search" href={searchPageLink("")}>
              <Icon icon={'search'} /> <span>Advanced search</span>
            </a>
          </div>
        </form>
        <div css={style.headerRight}>
          <div
            css={style.headerRightAction}
            onClick={() => {
              setVisible(false);
              setTimeout(() => {
                if (onClose) {
                  onClose();
                }
              }, 1000);
            }}
          >
            <Icon icon={'times'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;
