
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const BLZ: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <g>
                <path fill="#CE1126" d="M512,123.586H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,123.586L512,123.586z" />
                <path fill="#CE1126" d="M0,388.414h512v26.483c0,4.875-3.953,8.828-8.828,8.828H8.828c-4.875,0-8.828-3.953-8.828-8.828 V388.414z" />
            </g>
            <rect y="123.59" fill="#003F87" width="512" height="264.83" />
            <circle fill="#FFFFFF" cx="256" cy="256" r="114.76" />
            <g>
                <path fill="#9DD7FF" d="M233.979,316.344l-17.704,2.951c-2.231,0.372-4.263-1.348-4.263-3.612v-6.661h21.966 L233.979,316.344L233.979,316.344z" />
                <path fill="#9DD7FF" d="M277.913,316.344l17.704,2.951c2.231,0.372,4.263-1.348,4.263-3.612v-6.661h-21.966v7.322H277.913z" />
            </g>
            <g>
                <path fill="#965032" d="M252.285,197.248h-1.53c-1.837,0-3.489-1.023-4.312-2.664l-2.708-5.417l2.456-1.23l2.708,5.417 c0.354,0.708,1.066,1.148,1.856,1.148h1.53V197.248z" />
                <rect x="219.894" y="195.376" transform="matrix(0.4472 0.8944 -0.8944 0.4472 300.3374 -92.4574)" fill="#965032" width="10.149" height="2.746" />
                <path fill="#965032" d="M260.98,239.462l-7.502-7.501c-1.63-1.63-2.566-3.886-2.566-6.193v-2.525 c0-0.926-0.22-1.856-0.634-2.685l-1.87-3.74c-0.39-0.779-1.174-1.266-2.047-1.266h-6.457c-1.92,0-3.645-1.066-4.503-2.782 l-2.649-5.299l2.456-1.23l2.649,5.299c0.39,0.779,1.174,1.266,2.047,1.266h6.457c1.92,0,3.645,1.066,4.503,2.782l1.87,3.74 c0.605,1.212,0.925,2.563,0.925,3.915v2.525c0,1.583,0.642,3.132,1.761,4.247l2.816,2.817V220.04c0-3.468-1.194-6.868-3.361-9.578 c-2.554-3.197-3.961-7.204-3.961-11.294v-10.615h2.745v10.615c0,3.468,1.194,6.868,3.361,9.578 c2.555,3.197,3.961,7.204,3.961,11.294v19.422L260.98,239.462L260.98,239.462z" />
                <path fill="#965032" d="M254.448,232.361h-4.076c-2.514,0-4.789-1.583-5.665-3.936l-1.032-2.782l2.574-0.958l1.032,2.782 c0.478,1.287,1.719,2.148,3.091,2.148h4.076L254.448,232.361L254.448,232.361z" />
                <rect x="257.837" y="183.26" transform="matrix(0.915 0.4035 -0.4035 0.915 103.7082 -87.3814)" fill="#965032" width="2.747" height="38.301" />
                <polygon fill="#965032" points="271.963,215.553 258.692,215.553 258.692,212.808 269.218,212.808 269.218,199.537 271.963,199.537 " />
            </g>
            <g>
                <path fill="#289400" d="M246.793,228.825H235.81c-3.033,0-5.492-2.458-5.492-5.492l0,0c0-3.033,2.458-5.492,5.492-5.492 h10.984c3.033,0,5.492,2.458,5.492,5.492l0,0C252.285,226.367,249.826,228.825,246.793,228.825z" />
                <path fill="#289400" d="M272.421,228.825h-10.984c-3.033,0-5.492-2.458-5.492-5.492l0,0c0-3.033,2.458-5.492,5.492-5.492 h10.984c3.033,0,5.492,2.458,5.492,5.492l0,0C277.913,226.367,275.453,228.825,272.421,228.825z" />
                <path fill="#289400" d="M283.404,217.842H268.76c-3.033,0-5.492-2.458-5.492-5.492l0,0c0-3.033,2.458-5.492,5.492-5.492 h14.644c3.033,0,5.492,2.458,5.492,5.492l0,0C288.895,215.383,286.437,217.842,283.404,217.842z" />
                <path fill="#289400" d="M287.065,203.198h-25.628c-3.033,0-5.492-2.458-5.492-5.492l0,0c0-3.033,2.458-5.492,5.492-5.492 h25.628c3.033,0,5.492,2.458,5.492,5.492l0,0C292.557,200.738,290.098,203.198,287.065,203.198z" />
                <path fill="#289400" d="M272.421,188.553h-7.322c-3.033,0-5.492-2.458-5.492-5.492l0,0c0-3.033,2.458-5.492,5.492-5.492 h7.322c3.033,0,5.492,2.458,5.492,5.492l0,0C277.913,186.094,275.453,188.553,272.421,188.553z" />
                <path fill="#289400" d="M224.827,195.875h-3.661c-3.033,0-5.492-2.458-5.492-5.492l0,0c0-3.033,2.458-5.492,5.492-5.492 h3.661c3.033,0,5.492,2.458,5.492,5.492l0,0C230.318,193.417,227.859,195.875,224.827,195.875z" />
                <path fill="#289400" d="M250.455,192.214h-10.984c-3.033,0-5.492-2.458-5.492-5.492l0,0c0-3.033,2.458-5.492,5.492-5.492 h10.984c3.033,0,5.492,2.458,5.492,5.492l0,0C255.945,189.756,253.487,192.214,250.455,192.214z" />
                <path fill="#289400" d="M246.793,210.519h-21.966c-3.033,0-5.492-2.458-5.492-5.492l0,0c0-3.033,2.458-5.492,5.492-5.492 h21.966c3.033,0,5.492,2.458,5.492,5.492l0,0C252.285,208.061,249.826,210.519,246.793,210.519z" />
                <path fill="#289400" d="M310.862,287.055H201.029c-4.044,0-7.322,3.278-7.322,7.322c0,4.044,3.278,7.322,7.322,7.322h29.289 c0,4.044,3.278,7.322,7.322,7.322h36.611c4.044,0,7.322-3.278,7.322-7.322h29.289c4.044,0,7.322-3.278,7.322-7.322 C318.184,290.334,314.906,287.055,310.862,287.055z" />
            </g>
            <path fill="#BE7855" d="M328.938,222.071c-0.274-0.55-1.701,0.103-2.593-0.228c-11.06-4.111-25.91-11.601-35.616-16.707 l-1.949-3.085l-18.398-8.941c-1.171-0.569-2.582-0.131-3.225,1l-0.907,1.594c-0.692,1.217-0.235,2.765,1.006,3.411l17.518,9.114 l4.95-0.366l19.241,9.892c0,0,13.073,6.687,13.768,7.178s1.094,3.242,2.39,3.012C326.053,227.78,330.122,224.45,328.938,222.071z" />
            <g>
                <path fill="#FFD83C" d="M207.589,292.547c0,0,1.22,5.416,0.076,6.026c-1.144,0.61-7.246,0.61-7.093-0.686 c0.152-1.297,0.992-5.873,0.992-5.873L207.589,292.547z" />
                <path fill="#FFD83C" d="M217.733,289.648c0,0-0.992,4.042,0.992,4.195c1.983,0.152,13.195,0.076,13.501-0.228 s0.686-1.068,0.686-1.068l-10.374-2.899L217.733,289.648L217.733,289.648z" />
            </g>
            <path fill="#EAF3F5" d="M206.35,239.233c0,0-2.727,6.483-3.719,28.946c-0.264,5.976,0.458,4.576-2.517,23.797l3.53,1.843 c1.028,0.536,2.262,0.498,3.254-0.104l3.436-2.082c0,0-1.678-21.508,3.814-33.865c0,0,2.899,5.34,2.899,9.305 c0,3.966-0.152,23.034-0.152,23.034s6.407,1.068,9.305,0.763c0,0-1.831-41.378-3.661-51.122L206.35,239.233z" />
            <g>
                <path fill="#965032" d="M206.864,236.487c0,0-1.087,0.801-0.515,2.745c0,0,8.352,3.604,16.189,0.515 c0,0,0.858-1.259-0.228-2.803L206.864,236.487z" />
                <path fill="#965032" d="M195.691,216.657c-4.753,1.767-11.975,6.178-11.517,9.687s2.944,2.653,3.72,1.791 s3.002-3.626,3.698-4.117s11.937-6.263,11.937-6.263l29.765-15.304c0,0,0.228-1.487-1.545-2.689 C231.748,199.762,213.539,210.021,195.691,216.657z" />
            </g>
            <path fill="#FFD83C" d="M209.724,199.419l-0.172,1.259c0,0-0.458,8.066,2.174,10.24c0,0,0.572,1.658-0.343,2.46 s-9.953,5.377-9.953,5.377s-2.346,4.863-2.289,7.608c0,0-2.231-0.858-2.002-1.945c0.228-1.087,1.545-3.947,1.144-5.32 s-1.888-0.972-1.888-0.972s-0.744,0.801,0.458,1.315l-0.401,1.716l-4.863,2.86l1.087,1.315c0,0,0.057,10.068,6.006,11.383 c0,0,3.146,0.228,6.865-6.865c0,0,2.002,3.546,1.315,6.636c0,0,6.521,3.546,15.445,0.458l0.115-6.178c0,0,1.373,9.496,6.063,8.58 c0,0,12.994-5.002,10.83-6.903c-5.034-4.424-11.113-0.685-11.113-0.685c-0.295-6.426-0.832-14.738-1.663-15.637 c-1.373-1.487-5.606-0.686-6.445-2.593s-0.61-5.262,0.305-9.153c0.915-3.89-3.737-6.636-3.737-6.636L209.724,199.419z" />
            <path fill="#464655" d="M209.553,200.677c0,0,4.672,1.182,8.333-0.267l1.602,3.966h0.915c0,0,2.206-7.34-0.723-7.254 c0,0-0.011-2.383-5.126-2.283C210.161,194.925,208.638,197.779,209.553,200.677z" />
            <g>
                <path fill="#965032" d="M304.906,292.547c0,0-1.22,5.416-0.076,6.026s7.246,0.61,7.093-0.686s-0.992-5.873-0.992-5.873 L304.906,292.547z" />
                <path fill="#965032" d="M294.762,289.648c0,0,0.992,4.042-0.992,4.195c-1.983,0.152-13.195,0.076-13.501-0.228 c-0.306-0.305-0.686-1.068-0.686-1.068l10.374-2.899L294.762,289.648L294.762,289.648z" />
            </g>
            <path fill="#EAF3F5" d="M306.146,239.233c0,0,2.727,6.483,3.719,28.946c0.264,5.976-0.458,4.576,2.517,23.797l-3.53,1.843 c-1.028,0.536-2.262,0.498-3.254-0.104l-3.436-2.082c0,0,1.678-21.508-3.814-33.865c0,0-2.899,5.34-2.899,9.305 c0,3.966,0.152,23.034,0.152,23.034s-6.407,1.068-9.305,0.763c0,0,1.831-41.378,3.661-51.122L306.146,239.233z" />
            <path fill="#BE7855" d="M305.631,236.487c0,0,1.087,0.801,0.515,2.745c0,0-8.352,3.604-16.189,0.515 c0,0-0.858-1.259,0.228-2.803L305.631,236.487z" />
            <path fill="#965032" d="M302.771,199.419l0.172,1.259c0,0,0.458,8.066-2.174,10.24c0,0-0.572,1.658,0.343,2.46 s9.953,5.377,9.953,5.377s2.345,4.863,2.289,7.608c0,0,2.231-0.858,2.002-1.945c-0.228-1.087-1.545-3.947-1.144-5.32 s1.888-0.972,1.888-0.972s0.744,0.801-0.458,1.315l0.401,1.716l4.863,2.86l-1.087,1.315c0,0-0.057,10.068-6.006,11.383 c0,0-3.146,0.228-6.865-6.865c0,0-2.002,3.546-1.315,6.636c0,0-6.521,3.546-15.445,0.458l-0.115-6.178c0,0-1.373,9.496-6.063,8.58 c0,0-12.994-5.002-10.83-6.903c5.034-4.424,11.113-0.685,11.113-0.685c0.295-6.426,0.832-14.738,1.663-15.637 c1.373-1.487,5.605-0.686,6.445-2.593s0.61-5.262-0.305-9.153c-0.915-3.89,3.737-6.636,3.737-6.636L302.771,199.419z" />
            <path fill="#464655" d="M302.942,200.677c0,0-4.672,1.182-8.333-0.267l-1.602,3.966h-0.915c0,0-2.206-7.34,0.723-7.254 c0,0,0.011-2.383,5.125-2.283C302.334,194.925,303.858,197.779,302.942,200.677z" />
            <path fill="#EAF3F5" d="M224.461,203.734c-0.382-0.929,0.258-1.184,0.258-1.184s4.63-2.541,5.169-2.498 c0.89,0.072,1.982,1.636,1.982,2.972c0,1.335,1.739,4.801,5.314,5.663c0,0-4.462,6.063-10.655,5.235 C226.529,213.92,226.944,209.772,224.461,203.734z" />
            <path fill="#9DD7FF" d="M230.95,232.139c-1.372,0-2.484,1.186-2.484,2.649v30.3c0,7.322,3.433,32.95,27.458,40.271 c24.025-7.322,27.458-32.95,27.458-40.271v-30.3c0-1.463-1.112-2.649-2.484-2.649H230.95L230.95,232.139z" />
            <path fill="#FFE15A" d="M283.383,265.089v-30.3c0-1.463-1.112-2.649-2.484-2.649h-24.974v25.628l24.135,24.135 C282.732,274.849,283.383,268.256,283.383,265.089z" />
            <path fill="#EAF3F5" d="M228.466,265.089v-30.3c0-1.463,1.112-2.649,2.484-2.649h24.974v25.628l-24.135,24.135 C229.117,274.849,228.466,268.256,228.466,265.089z" />
            <polygon fill="#8C5A28" points="239.732,281.118 246.085,287.837 263.781,287.837 267.532,283.029 263.217,284.755 248.916,284.755 " />
            <path fill="#FFFFFF" d="M246.575,272.448l-0.97,9.78l-4.414-1.685C241.191,280.543,243.222,275.829,246.575,272.448z" />
            <g>
                <rect x="255.28" y="268.57" fill="#8C5A28" width="0.652" height="16.651" />
                <rect x="261.32" y="269.98" fill="#8C5A28" width="0.652" height="15.245" />
                <rect x="249.27" y="269.98" fill="#8C5A28" width="0.652" height="15.245" />
            </g>
            <g>
                <path fill="#FFFFFF" d="M257.814,283.646h-4.403c-0.299,0-0.542-0.243-0.542-0.542v-2.43c0-0.299,0.243-0.542,0.542-0.542 h4.403c0.299,0,0.542,0.243,0.542,0.542v2.43C258.355,283.403,258.113,283.646,257.814,283.646z" />
                <path fill="#FFFFFF" d="M257.814,278.961h-4.403c-0.299,0-0.542-0.243-0.542-0.542v-2.43c0-0.299,0.243-0.542,0.542-0.542 h4.403c0.299,0,0.542,0.243,0.542,0.542v2.43C258.355,278.718,258.113,278.961,257.814,278.961z" />
                <path fill="#FFFFFF" d="M257.814,274.646h-4.403c-0.299,0-0.542-0.243-0.542-0.542v-2.43c0-0.299,0.243-0.542,0.542-0.542 h4.403c0.299,0,0.542,0.243,0.542,0.542v2.43C258.355,274.403,258.113,274.646,257.814,274.646z" />
                <path fill="#FFFFFF" d="M263.479,283.646h-3.662c-0.299,0-0.542-0.243-0.542-0.542v-2.43c0-0.299,0.243-0.542,0.542-0.542 h3.662c0.299,0,0.542,0.243,0.542,0.542v2.43C264.02,283.403,263.778,283.646,263.479,283.646z" />
                <path fill="#FFFFFF" d="M263.479,279.332h-3.662c-0.299,0-0.542-0.243-0.542-0.542v-1.879c0-0.299,0.243-0.542,0.542-0.542 h3.662c0.299,0,0.542,0.243,0.542,0.542v1.879C264.02,279.09,263.778,279.332,263.479,279.332z" />
                <path fill="#FFFFFF" d="M263.479,275.057h-3.662c-0.299,0-0.542-0.243-0.542-0.542v-1.404c0-0.299,0.243-0.542,0.542-0.542 h3.662c0.299,0,0.542,0.243,0.542,0.542v1.404C264.02,274.815,263.778,275.057,263.479,275.057z" />
                <path fill="#FFFFFF" d="M251.427,283.646h-3.662c-0.299,0-0.542-0.243-0.542-0.542v-2.43c0-0.299,0.243-0.542,0.542-0.542 h3.662c0.299,0,0.542,0.243,0.542,0.542v2.43C251.969,283.403,251.726,283.646,251.427,283.646z" />
                <path fill="#FFFFFF" d="M251.427,279.332h-3.662c-0.299,0-0.542-0.243-0.542-0.542v-1.879c0-0.299,0.243-0.542,0.542-0.542 h3.662c0.299,0,0.542,0.243,0.542,0.542v1.879C251.969,279.09,251.726,279.332,251.427,279.332z" />
                <path fill="#FFFFFF" d="M251.427,275.057h-3.662c-0.299,0-0.542-0.243-0.542-0.542v-1.404c0-0.299,0.243-0.542,0.542-0.542 h3.662c0.299,0,0.542,0.243,0.542,0.542v1.404C251.969,274.815,251.726,275.057,251.427,275.057z" />
            </g>
            <g>
                <polygon fill="#CE1126" points="261.318,269.984 261.318,271.954 258.418,270.951 " />
                <polygon fill="#CE1126" points="249.274,269.984 249.274,271.954 246.375,270.951 " />
                <polygon fill="#CE1126" points="255.308,268.568 255.308,270.538 252.408,269.535 " />
            </g>
            <path fill="#4173CD" d="M277.716,287.055h-43.584c0.649,1.235,1.361,2.457,2.159,3.661h39.264 C276.356,289.513,277.066,288.29,277.716,287.055z" />
            <path fill="#289400" d="M275.557,290.717h-39.265l0,0c1.734,2.61,3.86,5.089,6.408,7.322h26.447 C271.697,295.806,273.822,293.326,275.557,290.717L275.557,290.717z" />
            <path fill="#FFE15A" d="M242.701,298.039c3.513,3.079,7.826,5.676,13.224,7.322c5.399-1.645,9.71-4.244,13.224-7.322H242.701 z" />
            <path fill="#BE7855" d="M251.222,259.918c0,0-0.268,0.094-0.556,0.061c-0.223-0.096-0.679-0.11-0.404-0.956 c0.134-0.419,0.414-1.011,0.211-1.163c-0.164-0.191-0.342-0.397-0.533-0.617c-0.381-0.44-0.809-0.938-1.278-1.48 c-0.962-1.06-2.102-2.279-3.432-3.48c-0.663-0.602-1.348-1.225-2.046-1.857c-0.722-0.608-1.388-1.29-2.174-1.856 c-0.445-0.234-1.01-0.351-1.504-0.534c-0.482-0.194-0.785-0.561-1.138-0.872c-0.691-0.638-1.37-1.266-2.028-1.874 c-1.336-1.196-2.509-2.382-3.512-3.402c-0.973-0.973-1.76-1.818-2.314-2.435c-0.529-0.587-0.495-1.48,0.073-2.028l1.626-1.569 c0.568-0.548,1.462-0.55,2.029-0.001c0.596,0.576,1.414,1.393,2.35,2.4c0.983,1.039,2.127,2.254,3.274,3.631 c0.584,0.68,1.186,1.382,1.8,2.094c0.298,0.364,0.653,0.679,0.831,1.167c0.166,0.5,0.263,1.069,0.48,1.522 c0.538,0.806,1.195,1.496,1.778,2.24c0.607,0.718,1.204,1.426,1.782,2.11c1.153,1.372,2.33,2.554,3.356,3.553 c0.525,0.488,1.006,0.934,1.433,1.33c0.213,0.198,0.413,0.383,0.598,0.555c0.146,0.209,0.747-0.051,1.17-0.169 c0.855-0.245,0.853,0.212,0.941,0.438c0.022,0.289-0.081,0.554-0.081,0.554L251.222,259.918z" />
            <path fill="#965032" d="M233.292,262.801c-0.282,0-0.567-0.086-0.813-0.268c-0.609-0.45-0.74-1.309-0.289-1.92l13.043-17.694 c0.449-0.608,1.303-0.744,1.92-0.289c0.609,0.45,0.74,1.309,0.289,1.92l-13.043,17.694 C234.129,262.607,233.713,262.801,233.292,262.801z" />
            <polygon fill="#FFFFFF" points="243.742,241.75 250.531,246.25 253.428,242.207 247.098,237.097 " />
            <path fill="#965032" d="M261.781,240.01l17.396,21.452c0,0,2.445,0.111,2.112-1.834l-18.007-21.119L261.781,240.01z" />
            <path fill="#EAF3F5" d="M257.891,242.955c0,0,4.891-0.889,6.947-3.946l2.779,3.279c0,0-4.557,3.223-5.002,6.724 C262.615,249.013,258.892,248.013,257.891,242.955z" />
            <g>
                <path fill="#965032" d="M276.676,240.843c-0.32,0-0.627-0.173-0.787-0.476c-0.495-0.938-0.58-1.785-0.254-2.521 c0.457-1.032,1.532-1.334,1.652-1.365c0.484-0.108,0.96,0.167,1.084,0.639c0.121,0.472-0.16,0.956-0.63,1.08 c-0.099,0.031-0.391,0.16-0.483,0.372c-0.087,0.209-0.014,0.553,0.205,0.969c0.229,0.434,0.063,0.972-0.373,1.202 C276.957,240.812,276.816,240.843,276.676,240.843z" />
                <path fill="#965032" d="M262.384,254.079c0-0.32-0.173-0.627-0.476-0.787c-0.938-0.495-1.785-0.58-2.521-0.254 c-1.032,0.457-1.334,1.532-1.365,1.652c-0.108,0.484,0.167,0.96,0.639,1.084c0.472,0.121,0.956-0.16,1.08-0.63 c0.031-0.099,0.16-0.391,0.372-0.483c0.209-0.087,0.553-0.014,0.969,0.205c0.434,0.23,0.972,0.063,1.202-0.373 C262.353,254.36,262.384,254.219,262.384,254.079z" />
            </g>
            <polygon fill="#EAF3F5" points="277.268,238.843 260.892,254.923 264.745,259.221 280.751,243.067 " />
            <path fill="#FFD83C" d="M194.088,215.665c1.097-0.254,2.059,1.22,1.068,2.289c-0.992,1.068-3.857,2.997-4.729,2.823 C188.902,220.47,187.834,217.113,194.088,215.665z" />
            <path fill="#965032" d="M318.343,217.209c-1.017-0.254-1.909,1.22-0.99,2.289c0.919,1.068,3.576,2.997,4.385,2.823 C323.152,222.014,324.143,218.658,318.343,217.209z" />
            <path fill="#464655" d="M280.899,232.139c1.372,0,2.484,1.186,2.484,2.649v30.3c0,7.322-3.433,32.95-27.458,40.271 c-24.025-7.322-27.458-32.95-27.458-40.271v-30.3c0-1.463,1.112-2.649,2.484-2.649H280.899 M280.899,230.308h-49.947 c-2.379,0-4.314,2.009-4.314,4.48v30.3c0,1.749,0.236,10.979,4.893,20.914c4.951,10.561,13.203,17.86,23.863,21.11l0.534,0.162 l0.534-0.162c10.66-3.249,18.912-10.549,23.863-21.11c4.657-9.934,4.893-19.164,4.893-20.914v-30.3 C285.213,232.319,283.278,230.308,280.899,230.308L280.899,230.308z" />
            <path fill="#EAF3F5" d="M299.879,305.79c-7.779-2.607-16.126-1.405-23.998,3.847c-10.061,6.707-16.73,6.707-19.936,6.707 s-9.875,0-19.936-6.707c-7.87-5.248-16.22-6.453-23.998-3.848v7.848c4.902-2.343,11.811-3.324,19.936,2.091 c11.906,7.937,20.38,7.937,23.998,7.937c3.618,0,12.092,0,23.998-7.937c8.125-5.42,15.034-4.437,19.936-2.092V305.79z" />
            <path fill="#965032" d="M255.945,347.464c-51.477,0-93.357-41.881-93.357-93.357s41.881-93.357,93.357-93.357 s93.357,41.881,93.357,93.357S307.422,347.464,255.945,347.464z M255.945,164.41c-49.459,0-89.697,40.236-89.697,89.697 s40.238,89.697,89.697,89.697s89.697-40.236,89.697-89.697S305.404,164.41,255.945,164.41z" />
            <g>
                <path fill="#289400" d="M153.817,261.886c0,0-0.382-11.441,10.602-11.441c0,0,0.228,4.5-3.737,6.483 C157.475,258.531,153.817,261.886,153.817,261.886z" />
                <path fill="#289400" d="M175.023,261.886c0,0,0.382-11.441-10.602-11.441c0,0-0.228,4.5,3.737,6.483 C171.366,258.531,175.023,261.886,175.023,261.886z" />
                <path fill="#289400" d="M357.999,250.445c0,0,0.382,11.441-10.602,11.441c0,0-0.228-4.5,3.737-6.483 C354.341,253.799,357.999,250.445,357.999,250.445z" />
                <path fill="#289400" d="M336.793,250.445c0,0-0.382,11.441,10.602,11.441c0,0,0.228-4.5-3.737-6.483 C340.449,253.799,336.793,250.445,336.793,250.445z" />
                <path fill="#289400" d="M261.628,358.256c0,0-11.441,0.382-11.441-10.602c0,0,4.5-0.228,6.483,3.737 C258.274,354.598,261.628,358.256,261.628,358.256z" />
                <path fill="#289400" d="M261.628,337.05c0,0-11.441-0.382-11.441,10.602c0,0,4.5,0.228,6.483-3.737 C258.274,340.707,261.628,337.05,261.628,337.05z" />
                <path fill="#289400" d="M250.188,154.075c0,0,11.441-0.382,11.441,10.602c0,0-4.5,0.228-6.483-3.737 C253.541,157.732,250.188,154.075,250.188,154.075z" />
                <path fill="#289400" d="M250.188,175.281c0,0,11.441,0.382,11.441-10.602c0,0-4.5-0.228-6.483,3.737 C253.541,171.623,250.188,175.281,250.188,175.281z" />
                <path fill="#289400" d="M187.763,332.399c0,0-8.36-7.82-0.594-15.586c0,0,3.343,3.02,1.942,7.226 C187.979,327.441,187.763,332.399,187.763,332.399z" />
                <path fill="#289400" d="M202.759,317.405c0,0-7.82-8.36-15.586-0.594c0,0,3.02,3.343,7.226,1.942 C197.8,317.619,202.759,317.405,202.759,317.405z" />
                <path fill="#289400" d="M324.052,179.932c0,0,8.36,7.82,0.594,15.586c0,0-3.343-3.02-1.942-7.226 C323.837,184.89,324.052,179.932,324.052,179.932z" />
                <path fill="#289400" d="M309.056,194.926c0,0,7.82,8.36,15.586,0.594c0,0-3.02-3.343-7.226-1.942 C314.015,194.711,309.056,194.926,309.056,194.926z" />
                <path fill="#289400" d="M332.142,324.309c0,0-7.82,8.36-15.586,0.594c0,0,3.02-3.343,7.226-1.942 C327.184,324.095,332.142,324.309,332.142,324.309z" />
                <path fill="#289400" d="M317.147,309.314c0,0-8.36,7.82-0.594,15.586c0,0,3.343-3.02,1.942-7.226 C317.361,314.272,317.147,309.314,317.147,309.314z" />
                <path fill="#289400" d="M179.674,188.021c0,0,7.82-8.36,15.586-0.594c0,0-3.02,3.343-7.226,1.942 C184.632,188.235,179.674,188.021,179.674,188.021z" />
                <path fill="#289400" d="M194.669,203.016c0,0,8.36-7.82,0.594-15.586c0,0-3.343,3.02-1.942,7.226 C194.455,198.058,194.669,203.016,194.669,203.016z" />
                <path fill="#289400" d="M163.778,300.519c0,0-4.73-10.424,5.417-14.627c0,0,1.933,4.069-0.972,7.42 C165.873,296.02,163.778,300.519,163.778,300.519z" />
                <path fill="#289400" d="M183.37,292.404c0,0-4.025-10.716-14.173-6.513c0,0,1.511,4.245,5.934,4.559 C178.706,290.704,183.37,292.404,183.37,292.404z" />
                <path fill="#289400" d="M348.038,211.811c0,0,4.73,10.424-5.417,14.627c0,0-1.933-4.07,0.972-7.42 C345.942,216.31,348.038,211.811,348.038,211.811z" />
                <path fill="#289400" d="M328.446,219.927c0,0,4.025,10.716,14.173,6.513c0,0-1.511-4.245-5.934-4.559 C333.109,221.627,328.446,219.927,328.446,219.927z" />
                <path fill="#289400" d="M300.261,348.296c0,0-10.424,4.73-14.627-5.417c0,0,4.069-1.933,7.42,0.972 C295.763,346.2,300.261,348.296,300.261,348.296z" />
                <path fill="#289400" d="M292.146,328.704c0,0-10.716,4.025-6.513,14.173c0,0,4.245-1.511,4.559-5.934 C290.447,333.366,292.146,328.704,292.146,328.704z" />
                <path fill="#289400" d="M211.555,164.034c0,0,10.424-4.73,14.627,5.417c0,0-4.07,1.933-7.42-0.972 C216.053,166.13,211.555,164.034,211.555,164.034z" />
                <path fill="#289400" d="M219.67,183.627c0,0,10.716-4.025,6.513-14.173c0,0-4.245,1.511-4.559,5.934 C221.369,178.964,219.67,183.627,219.67,183.627z" />
                <path fill="#289400" d="M222.125,352.674c0,0-10.716-4.025-6.513-14.173c0,0,4.245,1.511,4.559,5.934 C220.425,348.011,222.125,352.674,222.125,352.674z" />
                <path fill="#289400" d="M230.24,333.082c0,0-10.424-4.73-14.627,5.417c0,0,4.07,1.933,7.42-0.972 C225.741,335.178,230.24,333.082,230.24,333.082z" />
                <path fill="#289400" d="M289.691,159.657c0,0,10.716,4.025,6.513,14.173c0,0-4.245-1.511-4.559-5.934 C291.39,164.319,289.691,159.657,289.691,159.657z" />
                <path fill="#289400" d="M281.576,179.249c0,0,10.424,4.73,14.627-5.417c0,0-4.069-1.933-7.42,0.972 C286.075,177.153,281.576,179.249,281.576,179.249z" />
                <path fill="#289400" d="M352.416,289.949c0,0-4.025,10.716-14.173,6.513c0,0,1.511-4.245,5.934-4.559 C347.754,291.648,352.416,289.949,352.416,289.949z" />
                <path fill="#289400" d="M332.825,281.834c0,0-4.73,10.424,5.417,14.627c0,0,1.933-4.069-0.972-7.42 C334.92,286.332,332.825,281.834,332.825,281.834z" />
                <path fill="#289400" d="M159.399,222.381c0,0,4.025-10.716,14.173-6.513c0,0-1.511,4.245-5.934,4.559 C164.061,220.683,159.399,222.381,159.399,222.381z" />
                <path fill="#289400" d="M178.991,230.497c0,0,4.73-10.424-5.417-14.627c0,0-1.933,4.07,0.972,7.42 C176.896,225.998,178.991,230.497,178.991,230.497z" />
                <path fill="#289400" d="M156.895,281.693c0,0-2.606-11.147,8.167-13.29c0,0,1.102,4.369-2.401,7.087 C159.828,277.689,156.895,281.693,156.895,281.693z" />
                <path fill="#289400" d="M177.693,277.556c0,0-1.858-11.296-12.63-9.153c0,0,0.653,4.458,4.93,5.63 C173.452,274.979,177.693,277.556,177.693,277.556z" />
                <path fill="#289400" d="M354.921,230.637c0,0,2.606,11.147-8.167,13.29c0,0-1.102-4.369,2.401-7.087 C351.988,234.641,354.921,230.637,354.921,230.637z" />
                <path fill="#289400" d="M334.122,234.775c0,0,1.858,11.296,12.63,9.153c0,0-0.653-4.458-4.93-5.63 C338.364,237.351,334.122,234.775,334.122,234.775z" />
                <path fill="#289400" d="M281.435,355.179c0,0-11.147,2.606-13.29-8.167c0,0,4.369-1.102,7.087,2.401 C277.432,352.246,281.435,355.179,281.435,355.179z" />
                <path fill="#289400" d="M277.298,334.38c0,0-11.296,1.858-9.153,12.63c0,0,4.458-0.653,5.63-4.93 C274.722,338.622,277.298,334.38,277.298,334.38z" />
                <path fill="#289400" d="M230.381,157.152c0,0,11.147-2.606,13.29,8.167c0,0-4.369,1.102-7.087-2.401 C234.384,160.085,230.381,157.152,230.381,157.152z" />
                <path fill="#289400" d="M234.518,177.951c0,0,11.296-1.858,9.153-12.63c0,0-4.458,0.653-5.63,4.93 C237.094,173.709,234.518,177.951,234.518,177.951z" />
                <path fill="#289400" d="M203.945,344.228c0,0-9.725-6.039-3.623-15.171c0,0,3.869,2.31,3.314,6.709 C203.189,339.325,203.945,344.228,203.945,344.228z" />
                <path fill="#289400" d="M215.727,326.596c0,0-9.301-6.674-15.403,2.458c0,0,3.615,2.69,7.467,0.494 C210.906,327.774,215.727,326.596,215.727,326.596z" />
                <path fill="#289400" d="M307.87,168.102c0,0,9.725,6.039,3.623,15.171c0,0-3.869-2.31-3.314-6.709 C308.627,173.007,307.87,168.102,307.87,168.102z" />
                <path fill="#289400" d="M296.089,185.734c0,0,9.301,6.674,15.403-2.458c0,0-3.615-2.69-7.467-0.494 C300.91,184.556,296.089,185.734,296.089,185.734z" />
                <path fill="#289400" d="M343.972,308.128c0,0-6.039,9.725-15.171,3.623c0,0,2.309-3.869,6.709-3.314 C339.067,308.885,343.972,308.128,343.972,308.128z" />
                <path fill="#289400" d="M326.339,296.346c0,0-6.674,9.301,2.458,15.403c0,0,2.69-3.615,0.494-7.467 C327.516,301.166,326.339,296.346,326.339,296.346z" />
                <path fill="#289400" d="M167.844,204.203c0,0,6.039-9.725,15.171-3.623c0,0-2.31,3.869-6.709,3.314 C172.749,203.446,167.844,204.203,167.844,204.203z" />
                <path fill="#289400" d="M185.477,215.985c0,0,6.674-9.301-2.458-15.403c0,0-2.69,3.615-0.494,7.467 C184.299,211.164,185.477,215.985,185.477,215.985z" />
                <path fill="#289400" d="M174.201,317.641c0,0-6.674-9.301,2.458-15.403c0,0,2.69,3.615,0.494,7.467 C175.378,312.82,174.201,317.641,174.201,317.641z" />
                <path fill="#289400" d="M191.833,305.859c0,0-6.039-9.725-15.171-3.623c0,0,2.31,3.869,6.709,3.314 C186.928,305.102,191.833,305.859,191.833,305.859z" />
                <path fill="#289400" d="M337.615,194.69c0,0,6.674,9.301-2.458,15.403c0,0-2.69-3.615-0.494-7.467 C336.437,199.511,337.615,194.69,337.615,194.69z" />
                <path fill="#289400" d="M319.983,206.472c0,0,6.039,9.725,15.171,3.623c0,0-2.31-3.869-6.709-3.314 C324.888,207.229,319.983,206.472,319.983,206.472z" />
                <path fill="#289400" d="M317.383,337.872c0,0-9.301,6.674-15.403-2.458c0,0,3.615-2.69,7.467-0.494 C312.562,336.695,317.383,337.872,317.383,337.872z" />
                <path fill="#289400" d="M305.602,320.24c0,0-9.725,6.039-3.623,15.171c0,0,3.869-2.31,3.315-6.709 C304.845,325.145,305.602,320.24,305.602,320.24z" />
                <path fill="#289400" d="M194.433,174.458c0,0,9.301-6.674,15.403,2.458c0,0-3.615,2.69-7.467,0.494 C199.254,175.636,194.433,174.458,194.433,174.458z" />
                <path fill="#289400" d="M206.214,192.091c0,0,9.725-6.039,3.623-15.171c0,0-3.869,2.31-3.314,6.709 C206.971,187.186,206.214,192.091,206.214,192.091z" />
                <path fill="#289400" d="M241.602,357.41c0,0-11.296-1.858-9.153-12.63c0,0,4.458,0.653,5.63,4.93 C239.025,353.168,241.602,357.41,241.602,357.41z" />
                <path fill="#289400" d="M245.739,336.611c0,0-11.147-2.606-13.29,8.167c0,0,4.369,1.102,7.087-2.401 C241.735,339.544,245.739,336.611,245.739,336.611z" />
                <path fill="#289400" d="M270.214,154.92c0,0,11.296,1.858,9.153,12.63c0,0-4.458-0.653-5.63-4.93 C272.791,159.162,270.214,154.92,270.214,154.92z" />
                <path fill="#289400" d="M266.077,175.719c0,0,11.147,2.606,13.29-8.167c0,0-4.369-1.102-7.087,2.401 C270.081,172.786,266.077,175.719,266.077,175.719z" />
                <path fill="#289400" d="M357.153,270.472c0,0-1.858,11.296-12.63,9.153c0,0,0.653-4.458,4.93-5.63 C352.912,273.048,357.153,270.472,357.153,270.472z" />
                <path fill="#289400" d="M336.355,266.335c0,0-2.606,11.147,8.167,13.29c0,0,1.102-4.369-2.401-7.087 C339.286,270.338,336.355,266.335,336.355,266.335z" />
                <path fill="#289400" d="M154.662,241.859c0,0,1.858-11.296,12.63-9.153c0,0-0.653,4.458-4.93,5.63 C158.904,239.283,154.662,241.859,154.662,241.859z" />
                <path fill="#289400" d="M175.461,245.996c0,0,2.606-11.147-8.167-13.29c0,0-1.102,4.369,2.401,7.087 C172.528,241.993,175.461,245.996,175.461,245.996z" />
            </g>
        </svg>
    </React.Fragment>
);
