import * as React from "react";

import { breakpointMax } from "../../../../style";

import { css, jsx } from "@emotion/react";

const lint = jsx; // linter hack

export const style = {
  modalOverlay: css`
    position: fixed;

    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(39, 59, 76, 0.5);
    overflow: hidden !important;
    z-index: 9999 !important;

    justify-content: center;
    align-items: center;
  `,
  modalBox: css`
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: auto;
  `,
  modalBoxContent: css`
    flex: none !important;
    flex-direction: column;

    width: 714px;
    padding: 2em;
    border: 1px solid #d1d5d8;
    border-radius: 20px;
    background-color: #fff;
    color: #354450;

    @media (max-width: ${breakpointMax}) {
      padding: 16px;
      padding-top: 0;
      width: 310px;
    }
  }`,
  modalCloseButton: css`
    position: absolute;
    top: 25px;
    right: 25px;
    background-color: transparent;
    appearance: none !important;
    border: 0 !important;
    cursor: pointer;
    font-size: 24px;
    color: #354450;
    transition: all 0.5s ease;

    @media (max-width: ${breakpointMax}) {
      display: none !important;
    }

    &:focus {
      outline: 0 !important;
    }

    &:hover {
      color: #9fa0a1;
    }
  `,
  modalHead: css`
    width: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
    padding-bottom: 1.5em;
  `,
  modalTitle: css`
    font-weight: 700;
    font-size: 32px;
    margin-left: 10px;
    line-height: 1.2em;
    padding-top: 5px;
  `,
  iconCircle: css`
    padding: 7px;
    background-color: #354450;
    border-radius: 32px;
    width: 45px;
    height: 45px;
    font-size: 24px;
    justify-content: center;
    align-items: center;
  `,
  iconCircleGreen: css`
    background-color: rgba(18, 202, 100, 0.3);
  `,
  iconCircleRed: css`
    background-color: rgba(203, 18, 52, 0.3);
  `,
  modalHeadLine: css`
    width: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
    padding-bottom: 1em;

    font-weight: 600;
    font-size: 20px;
  `,
  modalBrokers: css`
    width: 100%;
    display: flex;

    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
  `,
  modalBrokersItem: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 64px;
    gap: 10px;
    border: 1px solid #d9dee2;
    box-shadow: 0px 16px 24px rgba(37, 54, 69, 0.15);
    margin-bottom: 1em;
    transition: all 1s ease;

    &:hover {
      background-color: #e3e6e8;
    }
  `,
  modalBrokersItemImg: css`
    display: block;
    color: #354450;

    > img {
      display: block;
      height: auto;
      max-height: 70px;
      color: #354450;
    }
  `,
  modalBrokersItemIcon: css`
    display: block;
    color: #354450;
  `,
  modalDisclaimer: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
  `,
  modalDisclaimerButton: css`
    display: block;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #22aa5f;
    transition: all 0.5s ease;
    background: none;
    border: none;
    pointer: cursor;

    &:hover,
    &:focus {
      color: #0d8e46;
    }
  `,
  modalDisclaimerContent: css`
    flex: none;
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
    text-align: left;
    padding: 10px 0px;

    > p {
      margin: 0;
      font-size: 12px;
      line-height: normal;
    }
  `,
};
