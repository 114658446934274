import 'isomorphic-fetch';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher, NormalizedCacheObject } from 'apollo-cache-inmemory';

import introspectionQueryResultData from '../temp/GraphQLFragmentTypes.json';

import { BatchHttpLink } from 'apollo-link-batch-http';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';

export const GraphQLClientFactory = (endpoint: string, ssrMode: boolean, initialCacheState: NormalizedCacheObject | null): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    ssrMode,
    ssrForceFetchDelay: 100,
    link: createPersistedQueryLink().concat(new BatchHttpLink({ uri: endpoint, credentials: 'include' })),
    cache: new InMemoryCache()
  })
}