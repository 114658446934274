/* eslint-disable */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { DOWNLOAD_ENDPOINT, DOCUMENTS_TYPE_TO_PREVIEW } from "../../backbourse";
import { canUseDOM } from "../../predicates";
import { DOCUMENT_PREVIEW_LINK } from "../constants";

export default class Utils {
  static logError(error: any) {
    console.clear();
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("error.response : ", error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error("error.request : ", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("error.message :", error.message);
    }
    console.error("error.config :", error.config);
  }

  /**
   * this function use config DOCUMENT_TYPE_TO_PREVIEW param
   * to resolve action (preview or download document)
   * @param id
   * @param url
   * @param type
   */
  static documentAction = (id: string, url: string, types: string[]) => {
    let preview = false;
    const typesToPreview: string[] =
      DOCUMENTS_TYPE_TO_PREVIEW?.toLowerCase()?.split(",");

    if (types) {
      types.forEach((type) => {
        if (
          typesToPreview?.length &&
          typesToPreview.includes(type.toLowerCase())
        )
          preview = true;
      });
    }

    if (canUseDOM && id && preview) {
      window.location.href = DOCUMENT_PREVIEW_LINK + id;
    } else {
      canUseDOM
        ? window.open(DOWNLOAD_ENDPOINT + url)
        : console.error("Can't use DOM");
    }
  };

  static moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  static getCookie = (cookieName: string) => {
    const cookies = canUseDOM && document.cookie.split("; ");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const [name, value] = cookie.split("=");

      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }

    return null;
  };
}
