/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Thead, Tr, Th } from '@bdl-cmn-shared-packages-npm/design-system';
import { data as dataMapper } from '../../../Search/results/dataMapper';

const thStyle = css`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px #d9dee2;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 2em;
  padding-right: 2em;
  text-align: left;
`;

const IssuerHeader = ({ selectedCols }: any) => {
  return (
    <Thead>
      <Tr>
        <th
          css={[
            thStyle,
            css`
              width: 5%;
            `,
          ]}
        >
          Type
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 25%;
            `,
          ]}
        >
          Classification
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 25%;
            `,
          ]}
        >
          Issuer
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 25%;
            `,
          ]}
        >
          Country
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 10%;
            `,
          ]}
        >
          LGX Labelled
        </th>
        {/*<th
          css={[
            thStyle,
            css`
              width: 10%;
            `,
          ]}
        >
          LGX Others
        </th>*/}
        {selectedCols['issuers'].map(
          (col: string, index: number) =>
            col.toLowerCase() !== 'id' && <Th key={`col-${index}`}>{dataMapper['issuers'][col].header}</Th>
        )}
      </Tr>
    </Thead>
  );
};

export default IssuerHeader;
