/** @jsx jsx */

import * as React from "react";

import {
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import {
  FormBuilder,
  formBuilderType,
} from "../../core/components/FormBuilder/formBuilder.component";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";

import { BACKBOURSE_ENDPOINT } from "../../backbourse";
import { GET_LUXXPRIME_PP_REPORTS } from "../../graphql";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import Loader from "../../core/components/Loader/loader.component";
import { SitecoreGenericTextField } from "../../interfaces";
import Utils from "../../core/utils/utils";
import axios from "axios";
import { canUseDOM } from "../../predicates";
import checkbox from "./checkbox.svg";
import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
import { renderLink } from "../../utils/helper";
import {
  SUBMIT_RTS_12_POST_TRADE,
  SUBMIT_RTS_12_PRE_TRADE,
} from "../../graphql/queries/rts.queries";
import { GET_LUXSE_REPORTS } from "../../graphql/queries/luxse-reports.queries";

export interface RtsComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const topContainer = css`
  width: 100%;
  position: relative;
  @media (max-width: ${breakpointMax}) {
    padding-top: 45px !important;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 45px !important;
  }

  a {
    color: #22aa5f;
    text-decoration: none;
  }
`;
// Pre- and Post-trade data report
const formPreAndPostTradeData = [
  {
    mandatory: true,
    placeholder: "Name *",
    requiredLabel: "* Required",
    value: "",
  },
  {
    mandatory: true,
    placeholder: "Last name *",
    requiredLabel: "* Required",
    value: "",
  },
  {
    mandatory: true,
    placeholder: "E-mail *",
    requiredLabel: "* Required",
    value: "",
  },
  {
    mandatory: true,
    label:
      "By completing this form, I agree with the collection and processing of my personal data by LuxSE for the purpose of monitoring who has access to the post trade and pre trade data published by LuxSE. Find the full external communication policy of LuxSE on www.luxse.com/GDPR.",
    type: formBuilderType.checkbox,
    value: false,
  },
  {
    mandatory: false,
    label:
      "By ticking this box, I hereby agree to receive commercial information or solicitation regarding data services provided by LuxSE in accordance with the external communication policy of LuxSE on www.luxse.com/GDPR.",
    type: formBuilderType.checkbox,
    value: false,
  },
];

enum modalTypeEnum {
  preTrade = "Pre-trade data",
  postTrade = "Post-trade data",
}

//TODO move to utils class
export enum notificationStatusEnum {
  error = "error",
  success = "success",
  info = "info",
  warning = "warning",
}

export class NotificationModel {
  type: notificationStatusEnum;
  message?: "";
  isResponseCatch: boolean;
}

export const RtsComponent: React.FunctionComponent<RtsComponentProps> = (
  props: RtsComponentProps
): React.ReactElement => {
  const isCorporateActionsPage: boolean =
    canUseDOM &&
    window?.location?.pathname?.split("/")?.pop() == "corporate-actions";

  const { fields, params, rendering } = props;
  const isH1: boolean = getFieldValue<boolean>(fields, "is H1", false);
  // tag={isH1 ? 'h1' : 'div'}
  const withRedCheckbox: number = getFieldValue<number>(
    fields,
    "withRedCheckbox",
    0
  );
  const hideForSummary: number = getFieldValue<number>(
    fields,
    "hide for summary",
    0
  );
  const splitDocs: number = getFieldValue<number>(fields, "split documents", 0);
  const hasPaddingTop: number = getFieldValue<number>(
    fields,
    "has padding top",
    0
  );
  const hasPaddingBottom: number = getFieldValue<number>(
    fields,
    "has padding bottom",
    0
  );
  const fontSize: string = getFieldValue<string>(fields, "font size", "48px");
  const fontWeight: string = getFieldValue<string>(
    fields,
    "font weight",
    "bold"
  );
  const titleColor: string = getFieldValue<string>(
    fields,
    "title color",
    "#425563"
  );
  const [modalType, setModalType] = React.useState<modalTypeEnum>();
  const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
  const [responseNotification, setResponseNotification] =
    React.useState<NotificationModel>();
  const [corporationActionUrl, setCorporationActionUrl] =
    React.useState<string>();

  const documents = css`
    width: 100%;
    display: flex;
    margin-top: 32px;
    flex-direction: ${splitDocs ? "row" : "column"};
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column !important;
    }
  `;
  const row = css`
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #354450;
  `;
  const title = css`
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    line-height: 61px;
    color: ${titleColor};
    @media (max-width: ${breakpointMax}) {
      font-weight: bold !important;
      font-size: 32px !important;
      line-height: 100% !important;
      margin-bottom: 45px !important;
    }

    h1 {
      font-weight: ${fontWeight};
      font-size: ${fontSize};
      line-height: 61px;
      color: ${titleColor};
      @media (max-width: ${breakpointMax}) {
        font-weight: bold !important;
        font-size: 32px !important;
        line-height: 100% !important;
        margin-bottom: 45px !important;
      }
    }
  `;
  const container = css`
    width: 100%;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;

    h2 {
      margin-top: 16px;
      margin-bottom: 16px;
      font-weight: ${fontWeight || "46px"};
      font-size: ${fontSize || "bold"};
      line-height: 61px;
      color: ${titleColor || "#425563"};
      @media (max-width: ${breakpointMax}) {
        font-size: 28px;
      }
    }

    @media (max-width: ${breakpointMax}) {
      margin-left: 20px;
      margin-right: 20px;
      width: unset;
    }
  `;
  const document = css`
    width: ${splitDocs ? "calc(50% - 8px)" : "100%"};
    display: flex;
    border: 1px solid #22aa5f;
    box-sizing: border-box;
    border-radius: 8px;
    align-items: center;
    min-height: 46px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 16px;
    cursor: pointer;
    position: relative;
    @media (max-width: ${breakpointMax}) {
      width: 100% !important;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    :hover {
      background: #22aa5f;

      > div {
        color: white;
      }
    }
  `;
  const documentIcon = css`
    padding-left: 8px;
    padding-right: 8px;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `;
  const documentIconRight = css`
    padding-left: 8px;
    padding-right: 8px;
    position: absolute;
    right: 0;
    color: #22aa5f;
  `;
  let content;
  if (withRedCheckbox) {
    content = css`
      width: 100%;
      line-height: 150%;
      color: #354450;

      li {
        margin-bottom: 16px;
        margin-left: 28px;
      }

      ul {
        list-style-type: none;
        padding-inline-start: 0;
      }

      ul li:before {
        content: "";
        display: block;
        position: relative;
        top: 22px;
        left: -27px;
        width: 18px;
        height: 18px;
        background-size: 18px;
        background-image: url(${renderLink(checkbox)});
        background-repeat: no-repeat;
      }

      @media (max-width: ${breakpointMax}) {
        font-size: 14px;
        line-height: 180%;
        table {
          width: 100% !important;
        }
      }
    `;
  } else {
    content = css`
      line-height: 150%;
      width: 100%;
      color: #354450;

      li {
        margin-bottom: 16px;
      }

      @media (max-width: ${breakpointMax}) {
        line-height: 180%;
        font-size: 14px;
        table {
          width: 100% !important;
        }
      }
    `;
  }

  const cards = css`
    width: 100%;
    display: flex;
    max-width: 780px;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 80px;
  `;

  const instance = axios.create();

  const client = luxseGatewayGraphqlClient;
  const downloadUrl: string = getFieldValue<string>(
    fields,
    "download url",
    "https://dl.luxse.com/dl/"
  );
  const isPLP: boolean = getFieldValue<boolean>(
    fields,
    "is PLP request",
    false
  );
  const [plpReportsLoading, setPlpReportsLoading] = useState<boolean>(false);
  const [plpReports, setPlpReports] = useState<any[]>([]);

  const getPlpReports = async (limit: number) => {
    try {
      const results: any = await client.query({
        query: GET_LUXSE_REPORTS,
        variables: {
          query: "",
          usePageable: true,
          page: 0,
          size: limit,
          types: ["PLP"],
          sorts: ["date,DESC"]
        },
      });

      let data = [];
      if(results?.data?.luxseReports?.resultSize > 0){
        data=results?.data?.luxseReports?.resultList;
      }

      setPlpReports(data);
      setPlpReportsLoading(false);
    } catch (e) {
      console.error("Failed to get PLP reports", e);
      setPlpReportsLoading(false);
    }
  };

  const sendRequest = async (data: any) => {
    let query = null;
    let path = "";
    if (modalType === modalTypeEnum.preTrade) {
      query = SUBMIT_RTS_12_PRE_TRADE;
      path = "rts12PreTrade";
    }

    if (modalType === modalTypeEnum.postTrade) {
      query = SUBMIT_RTS_12_POST_TRADE;
      path = "rts12PostTrade";
    }

    try {
      if (query != null) {
        const response = await client.query({
          query: query,
          variables: {
            token: Utils.getCookie("Gztoken-Dsm"),
            firstName: data[0]?.value,
            lastName: data[1]?.value,
            email: data[2]?.value,
          },
        });

        const code = response?.data[path]?.code;
        const message = response?.data[path]?.message;

        const notification: NotificationModel = new NotificationModel();
        notification.type =
          code === "OK"
            ? notificationStatusEnum.success
            : notificationStatusEnum.error;
        notification.message = message;
        notification.isResponseCatch = true;
        setResponseNotification(notification);

        setTimeout(() => {
          setResponseNotification(null);
          if (code === "OK") {
            setVisibleModal(false);
          } else {
            console.error(
              "Failed to submit request '" + modalType + "'",
              notification
            );
          }
        }, 3000);
      }
    } catch (e) {
      console.error("Failed to submit request '" + modalType + "'", e);
    }
  };

  const fetchCorporateActions = async (url: string) => {
    const dta: any = await instance.get(url, { timeout: 0 });
    setCorporationActionUrl(dta.data);
  };

  useEffect(() => {
    if (isCorporateActionsPage) {
      fetchCorporateActions(
        `${BACKBOURSE_ENDPOINT}/reports/corporate-actions`
      ).catch((error) => Utils.logError(error));
    }
  }, []);

  function buildAction(fields: any, index: number) {
    if (
      fields[`document${index + 1}TriggerForm`] &&
      fields[`document${index + 1}TriggerForm`]?.value
    ) {
      setModalType(fields[`document${index + 1}`]?.value);
      setVisibleModal(true);
    } else {
      if (
        canUseDOM &&
        fields[`document${index + 1}SpecificProcess`] &&
        fields[`document${index + 1}SpecificProcess`]?.value
      ) {
        window.location.href = corporationActionUrl;
      } else {
        window.location.href =
          (fields && (fields[`document${index + 1}DownloadLink`] as any)).value
            ?.src ||
          (fields && (fields[`document${index + 1}Link`] as any)).value;
      }
    }
  }

  useEffect(() => {
    if (isPLP) {
      setPlpReportsLoading(true);
      getPlpReports(9);
    }
  }, [fields]);

  return (
    <div
      css={topContainer}
      style={{
        paddingTop: hasPaddingTop ? 80 : 0,
        paddingBottom: hasPaddingBottom ? 80 : 0,
      }}
    >
      {visibleModal && (
        <FormBuilder
          onClickOutside={() => setVisibleModal(false)}
          title={"Your information *"}
          buttonLabel={"Send your request"}
          headerTitle={
            modalType === modalTypeEnum.preTrade
              ? "Tell us who you are before downloading our Pre-trade data"
              : "Tell us who you are before downloading our Post-trade data"
          }
          mandatory
          rows={formPreAndPostTradeData}
          buttonAction={sendRequest}
          notification={responseNotification}
        />
      )}
      <div css={container}>
        {fields &&
          fields.title &&
          fields.title.value &&
          (hideForSummary ? (
            <div css={title} data-title="anchor">
              <Text
                tag={isH1 ? "h1" : "div"}
                field={fields && (fields.title as SitecoreGenericTextField)}
              />
            </div>
          ) : (
            <h2 css={title} data-title="anchor">
              <Text
                field={fields && (fields.title as SitecoreGenericTextField)}
              />
            </h2>
          ))}

        {fields && fields.content && fields.content.value && (
          <div css={content}>
            <RichText
              field={fields && (fields.content as SitecoreGenericTextField)}
            />
          </div>
        )}
        <div css={documents}>
          {isPLP ? (
            <Loader loading={plpReportsLoading}>
              <div css={cards}>
                {plpReports &&
                  plpReports.map((plpReport: any, index: number) => { 
                    return plpReport ? (
                      <div
                        css={document}
                        key={`document-${index}`}
                        onClick={() =>
                          canUseDOM &&
                          window.open(
                            `${plpReport?.downloadUrl}`,
                            "_blank"
                          )
                        }
                      >
                        <div css={documentIcon}>
                          <Icon icon={"file-pdf"} />
                        </div>
                        <div css={row}>{plpReport?.label}</div>
                        <div css={documentIconRight}>
                          <Icon icon={"file-export"} />
                        </div>
                      </div> ): <></>
                    }
                  )}
              </div>
            </Loader>
          ) : (
            <>
              {new Array(10).fill(0).map((arr: any, index: number) => {
                if (
                  (fields &&
                    fields[`document${index + 1}DownloadLink`] &&
                    (
                      fields &&
                      (fields[`document${index + 1}DownloadLink`] as any)
                    ).value) ||
                  (fields &&
                    fields[`document${index + 1}Link`] &&
                    (fields && (fields[`document${index + 1}Link`] as any))
                      .value) ||
                  (fields &&
                    fields[`document${index + 1}TriggerForm`] &&
                    (
                      fields &&
                      (fields[`document${index + 1}TriggerForm`] as any)
                    ).value) ||
                  (fields &&
                    fields[`document${index + 1}SpecificProcess`] &&
                    (
                      fields &&
                      (fields[`document${index + 1}SpecificProcess`] as any)
                    ).value)
                ) {
                  return (
                    <div
                      css={document}
                      key={`document-${index}`}
                      onClick={() => buildAction(fields, index)}
                    >
                      {fields &&
                        fields[`document${index + 1}Icon`] &&
                        (fields && (fields[`document${index + 1}Icon`] as any))
                          .value && (
                          <div css={documentIcon}>
                            <Icon
                              icon={
                                fields &&
                                fields[`document${index + 1}Icon`] &&
                                (
                                  fields &&
                                  (fields[`document${index + 1}Icon`] as any)
                                ).value
                              }
                            />
                          </div>
                        )}
                      <div css={row}>
                        <Text
                          field={
                            fields[
                              `document${index + 1}`
                            ] as SitecoreGenericTextField
                          }
                        />
                      </div>
                      {/* <div css={documentIconRight}>
                        <Icon icon={"file-export"} />
                      </div> */}
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
