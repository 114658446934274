/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import * as React from 'react';
import {
    DashboardTradingDataView
} from "../../core/components/DataMarket/TradingData/dashbord-trading-data-view.component";


export const TradingData: React.FunctionComponent = (): React.ReactElement => {
    return (
        <DashboardTradingDataView/>
    );
}
    