
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface ReducedInequalitiesSvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const ReducedInequalitiesSvg: React.FunctionComponent<ReducedInequalitiesSvgProps> = ({ size: { width, height }, style }: ReducedInequalitiesSvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 43 51'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>10 - Reduced Inequality</title>
            <path d='M1.79046 3.86362V13.1328H3.82625V1.02246H2.2006L0.325134 3.21858V5.34386H0.340049L1.77182 3.86362H1.79046Z' fill='white'/>
            <path d='M11.2535 10.0082V4.1469C11.2535 2.25279 10.3065 0.876953 8.2334 0.876953C6.17896 0.876953 5.2319 2.25279 5.2319 4.1469V10.0045C5.2319 11.8799 6.17896 13.2744 8.2334 13.2744C10.3102 13.2744 11.2535 11.8799 11.2535 10.0082ZM7.27143 10.3289V3.82625C7.27143 3.09545 7.53988 2.57718 8.23712 2.57718C8.95301 2.57718 9.21774 3.09545 9.21774 3.82625V10.3289C9.21774 11.0597 8.94928 11.5779 8.23712 11.5779C7.53988 11.5779 7.27143 11.0597 7.27143 10.3289Z' fill='white'/>
            <path d='M15.9739 3.66956V5.91788H15.1499V1.01855H16.3505C17.182 1.01855 17.484 1.43242 17.484 2.11848V2.74861C17.484 3.27806 17.2976 3.58753 16.8278 3.69566L17.6145 5.92161H16.7457L15.9739 3.66956ZM15.9739 1.6897V3.21468H16.2498C16.5332 3.21468 16.6562 3.06926 16.6562 2.80826V2.08492C16.6562 1.81647 16.5332 1.68597 16.2498 1.68597H15.9739V1.6897Z' fill='white'/>
            <path d='M18.006 1.01855H19.9747V1.6897H18.8338V3.09163H19.6503V3.75532H18.8338V5.24674H19.9747V5.91788H18.006V1.01855Z' fill='white'/>
            <path d='M22.7897 2.33101V4.6017C22.7897 5.35487 22.4802 5.91788 21.5966 5.91788H20.3251V1.01855H21.5966C22.4765 1.01855 22.7897 1.57411 22.7897 2.33101ZM21.4959 5.24674C21.8352 5.24674 21.9657 5.0454 21.9657 4.74712V2.17814C21.9657 1.88731 21.8352 1.68597 21.4959 1.68597H21.1491V5.24301H21.4959V5.24674Z' fill='white'/>
            <path d='M25.7241 1.01855V4.72847C25.7241 5.4891 25.3997 5.98127 24.5608 5.98127C23.692 5.98127 23.3304 5.4891 23.3304 4.72847V1.01855H24.1544V4.80677C24.1544 5.0976 24.2699 5.29148 24.5608 5.29148C24.8516 5.29148 24.9672 5.0976 24.9672 4.80677V1.01855H25.7241Z' fill='white'/>
            <path d='M26.2685 4.65399V2.28262C26.2685 1.51454 26.6301 0.958984 27.4989 0.958984C28.4087 0.958984 28.6622 1.46607 28.6622 2.18941V2.76734H27.909V2.1223C27.909 1.82028 27.8084 1.64504 27.5101 1.64504C27.2155 1.64504 27.0925 1.85384 27.0925 2.15212V4.78449C27.0925 5.08277 27.2155 5.29157 27.5101 5.29157C27.8084 5.29157 27.909 5.10514 27.909 4.81432V3.912H28.6622V4.75839C28.6622 5.45936 28.3714 5.98136 27.4989 5.98136C26.6301 5.97763 26.2685 5.41461 26.2685 4.65399Z' fill='white'/>
            <path d='M29.1842 1.01855H31.1529V1.6897H30.0119V3.09163H30.8285V3.75532H30.0119V5.24674H31.1529V5.91788H29.1842V1.01855Z' fill='white'/>
            <path d='M34.1283 2.33101V4.6017C34.1283 5.35487 33.8188 5.91788 32.9351 5.91788H31.6637V1.01855H32.9351C33.8188 1.01855 34.1283 1.57411 34.1283 2.33101ZM32.8345 5.24674C33.1738 5.24674 33.3043 5.0454 33.3043 4.74712V2.17814C33.3043 1.88731 33.1738 1.68597 32.8345 1.68597H32.4877V5.24301H32.8345V5.24674Z' fill='white'/>
            <path d='M15.1499 8.14746H15.9739V13.0505H15.1499V8.14746Z' fill='white'/>
            <path d='M17.2043 9.967V13.0468H16.5071V8.14746H17.3162L18.2036 10.9588V8.14746H18.8896V13.0505H18.1663L17.2043 9.967Z' fill='white'/>
            <path d='M19.3669 8.14746H21.3356V8.8186H20.1947V10.2205H21.0112V10.8842H20.1947V12.3756H21.3356V13.0468H19.3669V8.14746Z' fill='white'/>
            <path d='M22.5772 13.3826V13.0508C21.9209 12.9352 21.6376 12.4505 21.6376 11.7868V9.41544C21.6376 8.64735 22.0142 8.0918 22.8829 8.0918C23.7591 8.0918 24.1357 8.64735 24.1357 9.41544V11.7868C24.1357 12.3237 23.9493 12.7413 23.528 12.9427L24.1357 13.0582V13.6735L22.5772 13.3826ZM23.308 11.9583V9.28121C23.308 8.98292 23.1775 8.77412 22.8829 8.77412C22.5921 8.77412 22.4653 8.98292 22.4653 9.28121V11.9583C22.4653 12.2491 22.5958 12.4654 22.8829 12.4654C23.1775 12.4617 23.308 12.2454 23.308 11.9583Z' fill='white'/>
            <path d='M26.9881 8.14746V11.8574C26.9881 12.618 26.6637 13.1102 25.8248 13.1102C24.956 13.1102 24.5943 12.618 24.5943 11.8574V8.14746H25.4183V11.9357C25.4183 12.2265 25.5339 12.4204 25.8248 12.4204C26.1156 12.4204 26.2312 12.2265 26.2312 11.9357V8.14746H26.9881Z' fill='white'/>
            <path d='M27.2491 13.0471L28.1737 8.14404H29.1133L30.0455 13.0471H29.1991L29.0462 12.1522H28.17L28.0246 13.0471H27.2491ZM28.2819 11.4923H28.9381L28.6137 9.52734H28.6063L28.2819 11.4923Z' fill='white'/>
            <path d='M30.3885 8.14746H31.2126V12.3794H32.1969V13.0505H30.3885V8.14746Z' fill='white'/>
            <path d='M32.566 8.14746H33.39V13.0505H32.566V8.14746Z' fill='white'/>
            <path d='M33.7666 8.14746H35.959V8.8186H35.2804V13.0505H34.4489V8.8186H33.7703V8.14746H33.7666Z' fill='white'/>
            <path d='M36.343 8.14746H37.1671V13.0505H36.343V8.14746Z' fill='white'/>
            <path d='M37.6368 8.14746H39.6055V8.8186H38.4646V10.2205H39.2811V10.8842H38.4646V12.3756H39.6055V13.0468H37.6368V8.14746Z' fill='white'/>
            <path d='M39.9299 11.9917V11.3355H40.698V12.0066C40.698 12.2825 40.821 12.4391 41.1044 12.4391C41.3654 12.4391 41.481 12.2639 41.481 12.0066V11.8202C41.481 11.5368 41.3654 11.3653 41.1119 11.1267L40.6271 10.642C40.1573 10.1796 39.9411 9.89624 39.9411 9.33323V9.16545C39.9411 8.55769 40.2953 8.08789 41.0969 8.08789C41.921 8.08789 42.2453 8.4943 42.2453 9.20273V9.60169H41.4922V9.17663C41.4922 8.89326 41.3617 8.75903 41.1007 8.75903C40.8695 8.75903 40.7092 8.88953 40.7092 9.16545V9.26612C40.7092 9.54203 40.862 9.69863 41.1007 9.9298L41.6301 10.4518C42.0776 10.9067 42.2938 11.1826 42.2938 11.7232V11.9619C42.2938 12.633 41.9321 13.1103 41.0932 13.1103C40.2543 13.1065 39.9299 12.6367 39.9299 11.9917Z' fill='white'/>
            <path d='M15.7875 28.7773L10.1424 34.4187L15.7875 40.0637V28.7773Z' fill='white'/>
            <path d='M36.2125 40.0637L41.8576 34.4187L36.2125 28.7773V40.0637Z' fill='white'/>
            <path d='M31.6525 24.2324L26.0075 18.5874L20.3661 24.2324H31.6525Z' fill='white'/>
            <path d='M20.3661 44.6274L26.0075 50.2725L31.6525 44.6274H20.3661Z' fill='white'/>
            <path d='M32.1298 29.1504H19.874V32.5508H32.1298V29.1504Z' fill='white'/>
            <path d='M32.1298 36.1523H19.874V39.7094H32.1298V36.1523Z' fill='white'/>
        </svg>
    );
};
