import * as React from "react";
import { css, jsx } from "@emotion/react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { issuerDetailPageLink } from "..";
import { WEBSITE_DOMAIN, WEBSITE_NAME, WEBSITE_TITLE } from "../../../constants";
import { IssuerDetail } from "../../../../interfaces/search/search-issuer";

/** @jsx jsx */

export interface IssuerCardProps {
  issuer: IssuerDetail;
}

export function IssuerMeta({
  issuer
}: IssuerCardProps) {

  if( ! issuer ) return <></>;

  let title = WEBSITE_TITLE + " - " + issuer.name + " | " + WEBSITE_NAME;
  let url = WEBSITE_DOMAIN + issuerDetailPageLink(issuer.url, issuer.id);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href={url}/>
        <title>{title}</title>
        <meta property="og:url" content={url}/>
        <meta property="og:title" content={title}/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={title}/>
      </Helmet>
    </HelmetProvider>
  );
}
