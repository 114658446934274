/** @jsx jsx */

import * as React from 'react';

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { SitecoreGenericTextField } from '../../interfaces';
import hexa from './hexa.png';
import { renderLink } from '../../utils/helper';

export interface ContactUsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  padding-top: 46px;
  padding-bottom: 96px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${breakpointMax}) {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 36px;
    padding-bottom: 0px;
  }
`;
const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
`;
const title = css`
  font-weight: bold;
  font-size: 32px;
  line-height: 61px;
  color: #354450;
  text-align: center;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
`;
const sideBorder = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e3e6e8;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    margin-top: 16px;
  }
`;
const hexagonRight = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const data = css`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
`;
const dataBold = css`
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  margin-bottom: 8px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
`;
const hexagon = css`
  align-items: center;
  background-image: url(${renderLink(hexa)});
  background-repeat: no-repeat;
  background-size: cover;
  height: 110px;
  width: 100px;
  min-width: 100px;
  display: flex;
  font-size: 32px;
  justify-content: center;
  color: #ca1234;
  margin-right: 32px;
  @media (max-width: ${breakpointMax}) {
    margin-right: 0;
  }
`;

export const ContactUs: React.FunctionComponent<ContactUsProps> = (props: ContactUsProps): React.ReactElement => {
  const { fields, params, rendering } = props;
  const oneCol: number = getFieldValue<number>(fields, 'display on one column', 0);

  const card = css`
    background: #ffffff;
    border: 1px solid #d9e1e2;
    box-sizing: border-box;
    box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
    border-radius: 16px;
    padding: 32px;
    display: flex;
    max-width: ${oneCol ? '500px' : '900px'};
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    flex-direction: ${oneCol ? 'column' : 'row'};
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `;

  const side = css`
    width: ${oneCol ? '100%' : '50%'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      align-items: center;
    }
  `;

  const sideCol = css`
    width: ${oneCol ? '100%' : '50%'};
    display: flex;
    align-content: center;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;
    }
  `;
  return (
    <div css={parentContainer}>
      <div css={container}>
        <div css={title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={card}>
          <div css={sideCol}>
            <div css={hexagon}>
              <Icon icon={fields[`icon`] && (fields && (fields[`icon`] as any)).value} />
            </div>
            <div css={hexagonRight}>
              <div css={dataBold}>
                <Text field={fields['sub title'] as SitecoreGenericTextField} />
              </div>
              <div css={data}>
                <Text field={fields['schedule line 1'] as SitecoreGenericTextField} />
              </div>
              <div css={data}>
                <Text field={fields['schedule line 2'] as SitecoreGenericTextField} />
              </div>
            </div>
          </div>
          <div css={side}>
            <div css={sideBorder}>
              <div css={data}>
                <Text field={fields && (fields.tel as SitecoreGenericTextField)} />
              </div>
              <div css={data}>
                <Text field={fields && (fields.fax as SitecoreGenericTextField)} />
              </div>
              <div css={data}>
                <Text field={fields && (fields.email as SitecoreGenericTextField)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
