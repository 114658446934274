import { css } from '@emotion/react';

export default {
  inputWrapper: css`
    background-color: white;
    border-radius: 4px;

    color: #253645;
    height: 40px;
    legend > span {
        display:none;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, 4px) scale(0.75);
    }

    .MuiOutlinedInput-input {
      //padding: 0px!important;
    }

    .MuiFormControl-root, .MuiOutlinedInput-root {
      & > div {
        min-height: 0!important;
        height: 40px;
      }
    }
    .MuiInputLabel-outlined {
      z-index: 1;
      transform: translate(14px, 13px) scale(1);
      pointer-events: none;
    }

    .inputWrapperFix {
      svg {
        margin-right: 0;
      }
      
      > div > div > div > div > div {
        .MuiOutlinedInput-input {
          padding: 18.5px 14px;
          padding-top: 30px;
        }
      }

      > div > div:nth-child(2) {
        background-color: white;
        z-index: 10;
      }
    }
  `
}
