
/** @jsx jsx */
import { jsx } from "@emotion/react"
import * as React from 'react';
import { Checkbox, InputField, Loader, MenuWithChild, OptionsData } from "@bdl-cmn-shared-packages-npm/design-system";
import { CurrencyLong } from "../../../../../../enums";
import { CurrencyFilter as CurrencyFilterModel } from "../../../../../../interfaces/search/search-filter-params";
import { LuxXPrimeFilterResultsData } from "../../../../../../graphql";
import style from "./index.style";
import inputStyle from "../input.style";

type CurrencyLongKey = keyof typeof CurrencyLong;

export interface CurrencyFilterProps {
  selection: CurrencyFilterModel;
  onSelectionChange: (values: any) => void;
  data: LuxXPrimeFilterResultsData | null
}

export function CurrencyFilter(props: CurrencyFilterProps) {
  const {
    selection,
    onSelectionChange,
    data
  } = props;

  return (
    <MenuWithChild
      icon="coins"
      label="Currency"
      width="100%;"
      expanded={false}
      arrows={false}>
      {data?.securitiesByTag ?
        <CurrencyFilterValues
          selection={selection}
          onSelectionChange={onSelectionChange}
          data={data}
        /> : <Loader />}
    </MenuWithChild>
  );
}

interface CurrencyFilterValuesProps extends CurrencyFilterProps {
  data: LuxXPrimeFilterResultsData;
}

function CurrencyFilterValues({ selection, onSelectionChange, data }: CurrencyFilterValuesProps) {
  const [filter, setFilter] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const label = React.useMemo(() => {
    const number = selection? selection.length : 0;

    return number == 0 ?
      "Currency" :
      number === 1 ?
      CurrencyLong[selection[0]] :
        `${number} elements selected`;
  }, [selection]);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const clickOutside = (event: any) => {
        if (!ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    }
  }, [open]);

  const values: OptionsData[] = React.useMemo(() => {
    const currencies = data.securitiesByTag.reduce((acc, { currency }) => {
      if (currency !== null) {
        acc[currency] = currency
      }
      return acc;
    }, {} as any);

    const uniqueValues = Object.values(currencies) as (CurrencyLongKey)[];
    return uniqueValues
      .sort((a, b) => CurrencyLong[a].localeCompare(CurrencyLong[b]))
      .map(key => ({
        name: CurrencyLong[key],
        value: key,
      }))
      .filter((value: OptionsData) => value.name.toUpperCase().includes(filter.toUpperCase()));
  }, [data, filter]);

  const handleSelectionChange = (currency: OptionsData) => {
    onSelectionChange(currency.value);
  }

  return (
    <>
      <div ref={ref} css={style.container} onClick={(e) => e.stopPropagation()}>
        <div css={inputStyle.inputWrapper}>
          <InputField
            onFocus={() => setOpen(true)}
            value={open ? filter : null}
            onClick={(e) => e.stopPropagation()}
            onChange={(e: any) => setFilter(e.target.value)}
            placeholder={open ? "Currency" : label}
          />
        </div>
        {
          open && (
            <>
              <div css={style.panel} onClick={(e) => e.stopPropagation()}>
                { values.map( (value : OptionsData) => {
                    if( !value )
                      return <></>;
                    
                    return (
                      <React.Fragment key={`currency-${value.value}-${value.name}`}>
                        <Checkbox
                          label={value.name}
                          onClick={() => handleSelectionChange(value)}
                          checked={selection?.includes(value.value as CurrencyLongKey)}
                        />
                      </React.Fragment>
                    );
                  })
                }
              </div>
            </>
          )
        }
      </div>
    </>
  )
}
