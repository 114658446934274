import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import SearchSecurity from "../../../interfaces/search/search-security";
import Props from "../UI/list/base/props";
import { goToSecurityDetailPage, isSecurityClickable } from "../SecurityCard";
import { sharedStyle } from "../../../styles/shared";
import { genericTradingDataStyle } from "../DataMarket/TradingData/Shared/generic-trading-data.style";
import { BidAskColumn } from "../UI/list/security/best-order";
import PriceColumn from "../UI/list/security/price";
import { Td, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import { formatDate } from "../../../utils/date";

export const ChinaBondsRow = ({row}: Props<SearchSecurity>) => {

    if( ! row )
        return <></>;

    const clickable = isSecurityClickable(row.status);

    return (
        <>
            <Tr onRowClick={() => { if(clickable) goToSecurityDetailPage(row.isinCode, row.id)}}
                css={[sharedStyle.tableRow, !clickable && sharedStyle.tableRowNoClickable]}
                title={row?.status == "NCOT"? "Listing to be confirmed": ""}>
                <Td>
                    <div css={sharedStyle.cardContainerBodyDoubleRight}>
                        <p css={genericTradingDataStyle.title1}>{row.name}</p>
                        <p css={genericTradingDataStyle.title2}>
                            {`${row?.isinCode} ${row?.currency}`}
                        </p>
                    </div>
                </Td>
                <Td>
                    <div css={sharedStyle.cardContainerBodyDoubleRight}>
                        <p css={genericTradingDataStyle.title2}>
                            {row?.listingDate ? formatDate(row?.listingDate) : "N/A"}
                        </p>
                    </div>
                </Td>
                <Td>
                    <div css={{padding: "2em"}}>
                        <BidAskColumn bestOrders={row?.marketRealtime?.bestOrders}/>
                    </div>
                </Td>
                <Td>
                    <div css={{padding: "2em", textAlign: "center"}}>
                        { row?.marketData?.lastPrice ? 
                            <PriceColumn {...row.marketData?.lastPrice} color={'rgb(53, 68, 80);'}/> 
                            : 'N/A'
                        }
                    </div>
                </Td>
            </Tr>
        </>
    );
};

export default ChinaBondsRow;