/** @jsx jsx */
import React, { Component, useState } from "react";
import { DonutChart } from "../Utils/DonutChart";
import { css, jsx } from "@emotion/react";



export class MarketCapBarChart extends Component<any, any> {

    render() {
        const graphColors: string[] = [
            "#008C3D",
            "#005E2F",
            "#B3E2EE",
            "#66C4DC",
            "#009CC4",
            "#FAD4A3",
            "#F7B766",
            "#F18700",
            "#22AA5F",
          ];
        const sortedData = this.props.sortedData;
        const loading = this.props.loading;
        
        return (
                    <DonutChart
                        chartName={"topLuxSharesCap"}
                        data={sortedData}
                        graphColors={graphColors}
                        labelField={"description"}
                        serieField={"marketCap"}
                        labelValueFunction={(value: string) => value}
                        displayLabels={true}
                        totalDisplay={true}
                        totalLabelBefore={""}
                    />
        )
    }
}

export default MarketCapBarChart;