import { breakpoint, breakpointMax } from '../../../style';

import { css } from '@emotion/react';
import hexa from './hexa.png';
import hexaGreen from './hexaGreen.svg';
import { renderLink } from '../../../utils/helper';

export const style = {
  container: css`
    display: flex;
    align-items: center;
    background: #ffffff;
    padding: 32px 80px;
    border: 1px solid #e3e6e8;
    box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
    border-radius: 16px;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
    @media (max-width: ${breakpointMax}) {
      padding: 16px;
      flex-direction: column;
    }
  `,
  left: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 32px;
    width: 130px;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  full: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #1A2934;
    height: 100%;
    justify-content: center;
  `,
  paddingRight: css`
    display: flex;
    flex-direction: column;
    padding-left:60px;
    padding-left: 32px;
  `,
  icon: css`
    background-image: url(${renderLink(hexaGreen)});
    background-repeat: no-repeat;
    background-size: cover;
    height: 110px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #008c46;
    svg {
      width: 36px !important;
      height: 36px !important;
    }
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  center: css`
    display: flex;
    flex-direction: column;
    width: calc(100% - 320px);
    padding-right: 8px;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  right: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      display: block;
    }
  `,
  date: css`
    font-weight: 600;
    font-size: 20px;
    color: #a4cbb2;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.01em;
    }
  `,
  smalldate: css`
    font-weight: 600;
    font-size: 16px;
    color:#354450;
    margin-bottom: 8px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.01em;
    }
  `,
  price: css`
    font-weight: 800;
    font-size: 16px;
    margin-top: 16px;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.01em;
    }
  `,
  miniPrice: css`
    font-weight: 700;
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.01em;
    }
  `,
  title: css`
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    color: #008c3d;
    margin-top: 8px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 22px;
      line-height: 130%;
      letter-spacing: 0.01em;
    }
  `,
  content: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #354450;
    margin-top: 16px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.01em;
    }
  `,
  tagContent: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #f18700;
    margin-top: 24px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.01em;
    }
  `,
  actionTop: css`
    button {
      white-space: nowrap;
    }
    @media (max-width: ${breakpointMax}) {
      margin-top: 16px;
      button {
        font-size: 14px;
      }
    }
  `,
  actionBottom: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #22AA5F;
    :hover {
      color: #008c46;
    }
    margin-top: 24px;
    cursor: pointer;
    svg {
      margin-right: 8px;
    }
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      display: flex;
      align-items: center;
      text-align: right;
      text-decoration-line: underline;
    }
  `,
};
