/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { style } from './overview.style';
import { SearchTable } from '../../UI/list/base';
import SecurityHeader from '../../UI/list/security/header';
import { SecurityRow } from '../../UI/list/security/row';
import { Button } from '@bdl-cmn-shared-packages-npm/design-system';
import { IssuerCardTab } from '..';
import ProgrammeHeader from '../../UI/list/programme/header';
import ProgrammeRow from '../../UI/list/programme/row';
import DocumentHeader from '../../UI/list/document/header';
import DocumentRow from '../../UI/list/document/row';
import Loader from '../../Loader/loader.component';
import { genericStyle } from '../../UI/generic/generic.style';
import { SearchMobile } from '../../UI/list/base/index-mobile';
import SecuritySearchCard from '../../UI/list/security/card-mobile';
import { ProgrammeSearchCard } from '../../UI/list/programme/card-mobile';
import { DocumentSearchResultDto, ProgrammeSearchResultDto, SecuritySearchResultDto } from '../../../../interfaces/search/search';
import DocumentSearchCard from '../../UI/list/document/card-mobile';

const buttonStyle = css`
  box-shadow: none !important;
  background-color: transparent !important;
  color: #22aa5f !important;
  border: solid 1px #22aa5f !important;
  margin-top: 30px !important;
  :hover {
    background-color: #f4f6f7 !important;
  }
`;

export interface Row {
  label: string;
  action: () => void;
}

export interface IssuerOverviewProps {
  programmes?: ProgrammeSearchResultDto;
  programmesLoading?: boolean;
  securities?: SecuritySearchResultDto;
  securitiesLoading?: boolean;
  notices?: DocumentSearchResultDto;
  noticesLoading?: boolean;
  onTabChange: (tab: IssuerCardTab) => void;
}

const IssuerOverview: React.FunctionComponent<IssuerOverviewProps> = ({
  programmes,
  programmesLoading = false,
  securities,
  securitiesLoading = false,
  notices,
  noticesLoading = false,
  onTabChange,
}: IssuerOverviewProps) => {
  return (
    <div css={style.issuerOverview}>
      <div css={style.type}>
        <h3>Programmes</h3>
        <Loader loading={programmesLoading}>
          <div css={genericStyle.desktopDisplay}>
            <SearchTable
              onPaginationUpdate={() => {}}
              pageNumber={0}
              data={programmes?.programmes}
              totalResultSize={0}
              Header={() => <ProgrammeHeader />}
              Row={(row) => <ProgrammeRow key={`row-o-programmes-${row?.id}`} row={row} />}
            />
          </div>
          <div css={[genericStyle.mobileDisplay, style.mobileContainer]}>
            <SearchMobile
              onPaginationUpdate={() => {}}
              pageNumber={0}
              data={programmes?.programmes}
              totalResultSize={0}
              Card={(card) => <ProgrammeSearchCard key={`card-o-programmes-${card?.id}`} card={card} />}
            />
          </div>

          {programmes?.totalHits > 5 && (
            <div css={style.overviewButton}>
              <Button
                css={buttonStyle}
                onClick={() => onTabChange(IssuerCardTab.PROGRAMMES)}
                wired={false}
                iconLeft='plus-circle'
                label={`See all ${programmes?.totalHits} programmes`}
              />
            </div>
          )}
        </Loader>
      </div>

      <div css={style.type}>
        <h3>Securities</h3>
        <Loader loading={securitiesLoading}>
          <div css={genericStyle.desktopDisplay}>
            <SearchTable
              onPaginationUpdate={() => {}}
              pageNumber={0}
              data={securities?.securities}
              totalResultSize={0}
              Header={() => <SecurityHeader />}
              Row={(row) => <SecurityRow key={`row-o-security-${row?.id}`} row={row} />}
            />
          </div>
          <div css={[genericStyle.mobileDisplay, style.mobileContainer]}>
            <SearchMobile
              onPaginationUpdate={() => {}}
              pageNumber={0}
              data={securities?.securities}
              totalResultSize={0}
              Card={(card) => <SecuritySearchCard key={`card-o-security-${card?.id}`} card={card} />}
            />
          </div>

          {securities?.totalHits > 5 && (
            <div css={style.overviewButton}>
              <Button
                css={buttonStyle}
                onClick={() => onTabChange(IssuerCardTab.SECURITIES)}
                wired={false}
                iconLeft='plus-circle'
                label={`See all ${securities?.totalHits} securities`}
              />
            </div>
          )}
        </Loader>
      </div>

      <div css={style.type}>
        <h3>Notices</h3>
        <Loader loading={noticesLoading}>
          <div css={genericStyle.desktopDisplay}>
            <SearchTable
              onPaginationUpdate={() => {}}
              pageNumber={0}
              data={notices?.documents}
              totalResultSize={0}
              Header={() => <DocumentHeader />}
              Row={(row) => <DocumentRow key={`row-o-notices-${row?.id}`} row={row} />}
            />
          </div>
          <div css={[genericStyle.mobileDisplay, style.mobileContainer]}>
            <SearchMobile
              onPaginationUpdate={() => {}}
              pageNumber={0}
              data={notices?.documents}
              totalResultSize={0}
              Card={(card) => <DocumentSearchCard key={`card-o-notices-${card?.id}`} card={card} />}
            />
          </div>

          {notices?.totalHits > 5 && (
            <div css={style.overviewButton}>
              <Button
                css={buttonStyle}
                onClick={() => onTabChange(IssuerCardTab.DOCUMENTS)}
                wired={false}
                iconLeft='plus-circle'
                label={`See all ${notices?.totalHits} notices`}
              />
            </div>
          )}
        </Loader>
      </div>
    </div>
  );
};

export default IssuerOverview;
