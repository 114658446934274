
/*
*   e-Listing - core - components - country flag icon - chn
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const CHN: JSX.Element = (
        <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
            <rect y='85.331' style={{fill: '#D80027'}} width='512' height='341.337'/>
            <g>
                <polygon style={{fill: '#FFDA44'}} points='178.923,189.567 193.579,234.674 241.008,234.674 202.637,262.552 217.294,307.661    178.923,279.782 140.552,307.661 155.208,262.552 116.837,234.674 164.266,234.674  '/>
                <polygon style={{fill: '#FFDA44'}} points='271.3,339.593 256.91,329.138 242.522,339.592 248.017,322.678 233.628,312.223    251.414,312.223 256.91,295.307 262.408,312.223 280.193,312.223 265.804,322.677  '/>
                <polygon style={{fill: '#FFDA44'}} points='308.837,287.927 291.052,287.927 285.556,304.841 280.06,287.928 262.273,287.927    276.664,277.472 271.166,260.557 285.556,271.01 299.945,260.557 294.449,277.472  '/>
                <polygon style={{fill: '#FFDA44'}} points='308.837,224.063 294.449,234.518 299.944,251.432 285.556,240.979 271.166,251.434    276.664,234.517 262.273,224.065 280.061,224.063 285.556,207.147 291.052,224.063  '/>
                <polygon style={{fill: '#FFDA44'}} points='271.3,172.397 265.805,189.313 280.193,199.766 262.408,199.767 256.91,216.684    251.414,199.766 233.629,199.768 248.018,189.312 242.522,172.396 256.91,182.851  '/>
            </g>
        </svg>
);