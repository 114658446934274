
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const IRN: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#239F40" d="M512,200.092H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,200.092L512,200.092z" />
            <path fill="#DA0000" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V311.909h512v102.988 C512,419.772,508.047,423.725,503.172,423.725z" />
            <g>
                <rect y="200.088" fill="#FFFFFF" width="512" height="111.81" />
                <path fill="#FFFFFF" d="M1.389,194.176h0.077c0.767,0,1.389-0.621,1.389-1.389v-3.008c0-0.767,0.621-1.389,1.389-1.389 h5.827c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h11.502c0.767,0,1.389-0.621,1.389-1.389v-5.938 c0-0.767-0.621-1.389-1.389-1.389h-5.79c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h2.935 c0.767,0,1.389,0.621,1.389,1.389v0.151c0,0.767-0.621,1.389-1.389,1.389h-5.792c-0.767,0-1.389-0.621-1.389-1.389v-3.081 c0-0.767-0.621-1.389-1.389-1.389H1.389c-0.767,0-1.389,0.621-1.389,1.389v5.938C0,193.553,0.621,194.176,1.389,194.176z M7.137,194.176h0.079c0.767,0,1.389-0.621,1.389-1.389v-0.079c0-0.767-0.621-1.389-1.389-1.389H7.137 c-0.767,0-1.389,0.621-1.389,1.389v0.079C5.748,193.553,6.37,194.176,7.137,194.176z M1.389,182.531h34.423 c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389H1.389c-0.767,0-1.389,0.621-1.389,1.389v0.152 C0,181.91,0.621,182.531,1.389,182.531z M35.811,194.176h-5.79c-0.767,0-1.389-0.621-1.389-1.389v-5.938 c0-0.767,0.621-1.389,1.389-1.389h8.648c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767,0.621-1.389,1.389-1.389h0.077 c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.079c0.767,0,1.389-0.621,1.389-1.389v-8.94 c0-0.767,0.621-1.389,1.389-1.389h0.077c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.115 c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.042c0.767,0,1.389,0.621,1.389,1.389v11.797 c0,0.767-0.621,1.389-1.389,1.389H41.447c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-5.79 c-0.767,0-1.389,0.621-1.389,1.389v0.151c0,0.767,0.621,1.389,1.389,1.389h2.933c0.767,0,1.389,0.621,1.389,1.389v0.079 C37.201,193.553,36.578,194.176,35.811,194.176z" />
                <path fill="#FFFFFF" d="M58.618,194.176h0.115c0.767,0,1.389-0.621,1.389-1.389v-3.008c0-0.767,0.621-1.389,1.389-1.389 h5.754c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h11.502c0.767,0,1.389-0.621,1.389-1.389v-5.938 c0-0.767-0.621-1.389-1.389-1.389h-5.79c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h2.935 c0.767,0,1.389,0.621,1.389,1.389v0.151c0,0.767-0.621,1.389-1.389,1.389H72.9c-0.767,0-1.389-0.621-1.389-1.389v-3.081 c0-0.767-0.621-1.389-1.389-1.389H58.618c-0.767,0-1.389,0.621-1.389,1.389v5.938C57.23,193.553,57.852,194.176,58.618,194.176z M64.331,194.176h0.077c0.767,0,1.389-0.621,1.389-1.389v-0.079c0-0.767-0.621-1.389-1.389-1.389h-0.077 c-0.767,0-1.389,0.621-1.389,1.389v0.079C62.943,193.553,63.564,194.176,64.331,194.176z M58.618,182.531h34.388 c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389H58.618c-0.767,0-1.389,0.621-1.389,1.389v0.152 C57.23,181.91,57.852,182.531,58.618,182.531z M93.006,194.176h-5.827c-0.767,0-1.389-0.621-1.389-1.389v-5.938 c0-0.767,0.621-1.389,1.389-1.389h8.648c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767,0.621-1.389,1.389-1.389h0.115 c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.115c0.767,0,1.389-0.621,1.389-1.389v-8.94 c0-0.767,0.621-1.389,1.389-1.389h0.042c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.115 c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.077c0.767,0,1.389,0.621,1.389,1.389v11.797 c0,0.767-0.621,1.389-1.389,1.389H98.607c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-5.754 c-0.767,0-1.389,0.621-1.389,1.389v0.151c0,0.767,0.621,1.389,1.389,1.389h2.935c0.767,0,1.389,0.621,1.389,1.389v0.079 C94.394,193.553,93.773,194.176,93.006,194.176z" />
                <path fill="#FFFFFF" d="M115.814,194.176h0.077c0.767,0,1.389-0.621,1.389-1.389v-3.008c0-0.767,0.621-1.389,1.389-1.389 h5.792c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h11.538c0.767,0,1.389-0.621,1.389-1.389v-5.938 c0-0.767-0.621-1.389-1.389-1.389h-5.79c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h2.935 c0.767,0,1.389,0.621,1.389,1.389v0.151c0,0.767-0.621,1.389-1.389,1.389h-5.827c-0.767,0-1.389-0.621-1.389-1.389v-3.081 c0-0.767-0.621-1.389-1.389-1.389h-11.502c-0.767,0-1.389,0.621-1.389,1.389v5.938 C114.424,193.553,115.047,194.176,115.814,194.176z M121.526,194.176h0.077c0.767,0,1.389-0.621,1.389-1.389v-0.079 c0-0.767-0.621-1.389-1.389-1.389h-0.077c-0.767,0-1.389,0.621-1.389,1.389v0.079C120.137,193.553,120.759,194.176,121.526,194.176 z M115.814,182.531h34.424c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-34.424 c-0.767,0-1.389,0.621-1.389,1.389v0.152C114.424,181.91,115.047,182.531,115.814,182.531z M150.238,194.176h-5.827 c-0.767,0-1.389-0.621-1.389-1.389v-5.938c0-0.767,0.621-1.389,1.389-1.389h8.646c0.767,0,1.389-0.621,1.389-1.389v-3.081 c0-0.767,0.621-1.389,1.389-1.389h0.079c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.115 c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.115c0.767,0,1.389,0.621,1.389,1.389v8.94 c0,0.767,0.621,1.389,1.389,1.389h0.042c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.077 c0.767,0,1.389,0.621,1.389,1.389v11.797c0,0.767-0.621,1.389-1.389,1.389h-11.54c-0.767,0-1.389-0.621-1.389-1.389v-3.008 c0-0.767-0.621-1.389-1.389-1.389h-5.79c-0.767,0-1.389,0.621-1.389,1.389v0.151c0,0.767,0.621,1.389,1.389,1.389h2.973 c0.767,0,1.389,0.621,1.389,1.389v0.079C151.627,193.553,151.005,194.176,150.238,194.176z" />
                <path fill="#FFFFFF" d="M173.007,194.176h0.077c0.767,0,1.389-0.621,1.389-1.389v-3.008c0-0.767,0.621-1.389,1.389-1.389 h5.79c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h11.54c0.767,0,1.389-0.621,1.389-1.389v-5.938 c0-0.767-0.621-1.389-1.389-1.389h-5.827c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h2.935 c0.767,0,1.389,0.621,1.389,1.389v0.151c0,0.767-0.621,1.389-1.389,1.389h-5.79c-0.767,0-1.389-0.621-1.389-1.389v-3.081 c0-0.767-0.621-1.389-1.389-1.389h-11.502c-0.767,0-1.389,0.621-1.389,1.389v5.938 C171.619,193.553,172.241,194.176,173.007,194.176z M178.72,194.176h0.077c0.767,0,1.389-0.621,1.389-1.389v-0.079 c0-0.767-0.621-1.389-1.389-1.389h-0.077c-0.767,0-1.389,0.621-1.389,1.389v0.079C177.332,193.553,177.953,194.176,178.72,194.176z M173.007,182.531h34.388c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-34.388 c-0.767,0-1.389,0.621-1.389,1.389v0.152C171.619,181.91,172.241,182.531,173.007,182.531z M207.395,194.176h-5.827 c-0.767,0-1.389-0.621-1.389-1.389v-5.938c0-0.767,0.621-1.389,1.389-1.389h8.721c0.767,0,1.389-0.621,1.389-1.389v-3.081 c0-0.767,0.621-1.389,1.389-1.389h0.077c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.042 c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.115c0.767,0,1.389,0.621,1.389,1.389v8.94 c0,0.767,0.621,1.389,1.389,1.389h0.079c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.077 c0.767,0,1.389,0.621,1.389,1.389v11.797c0,0.767-0.621,1.389-1.389,1.389h-11.507c-0.767,0-1.389-0.621-1.389-1.389v-3.008 c0-0.767-0.621-1.389-1.389-1.389h-5.827c-0.767,0-1.389,0.621-1.389,1.389v0.151c0,0.767,0.621,1.389,1.389,1.389h2.935 c0.767,0,1.389,0.621,1.389,1.389v0.079C208.783,193.553,208.162,194.176,207.395,194.176z" />
                <path fill="#FFFFFF" d="M230.2,194.176h0.079c0.767,0,1.389-0.621,1.389-1.389v-3.008c0-0.767,0.621-1.389,1.389-1.389h5.79 c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h11.54c0.767,0,1.389-0.621,1.389-1.389v-5.938 c0-0.767-0.621-1.389-1.389-1.389h-5.827c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h2.97 c0.767,0,1.389,0.621,1.389,1.389v0.151c0,0.767-0.621,1.389-1.389,1.389h-5.79c-0.767,0-1.389-0.621-1.389-1.389v-3.081 c0-0.767-0.621-1.389-1.389-1.389H230.2c-0.767,0-1.389,0.621-1.389,1.389v5.938C228.812,193.553,229.433,194.176,230.2,194.176z M235.95,194.176h0.042c0.767,0,1.389-0.621,1.389-1.389v-0.079c0-0.767-0.621-1.389-1.389-1.389h-0.042 c-0.767,0-1.389,0.621-1.389,1.389v0.079C234.561,193.553,235.183,194.176,235.95,194.176z M230.2,182.531h34.425 c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389H230.2c-0.767,0-1.389,0.621-1.389,1.389v0.152 C228.812,181.91,229.433,182.531,230.2,182.531z M264.626,194.176h-5.827c-0.767,0-1.389-0.621-1.389-1.389v-5.938 c0-0.767,0.621-1.389,1.389-1.389h8.684c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767,0.621-1.389,1.389-1.389h0.077 c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.079c0.767,0,1.389-0.621,1.389-1.389v-8.94 c0-0.767,0.621-1.389,1.389-1.389h0.077c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.077 c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.079c0.767,0,1.389,0.621,1.389,1.389v11.797 c0,0.767-0.621,1.389-1.389,1.389H270.26c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-5.827 c-0.767,0-1.389,0.621-1.389,1.389v0.151c0,0.767,0.621,1.389,1.389,1.389h2.97c0.767,0,1.389,0.621,1.389,1.389v0.079 C266.014,193.553,265.393,194.176,264.626,194.176z" />
                <path fill="#FFFFFF" d="M287.395,194.176h0.079c0.767,0,1.389-0.621,1.389-1.389v-3.008c0-0.767,0.621-1.389,1.389-1.389 h5.79c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h11.54c0.767,0,1.389-0.621,1.389-1.389v-5.938 c0-0.767-0.621-1.389-1.389-1.389h-5.792c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h2.899 c0.767,0,1.389,0.621,1.389,1.389v0.151c0,0.767-0.621,1.389-1.389,1.389h-5.754c-0.767,0-1.389-0.621-1.389-1.389v-3.081 c0-0.767-0.621-1.389-1.389-1.389h-11.54c-0.767,0-1.389,0.621-1.389,1.389v5.938C286.006,193.553,286.628,194.176,287.395,194.176 z M293.144,194.176h0.042c0.767,0,1.389-0.621,1.389-1.389v-0.079c0-0.767-0.621-1.389-1.389-1.389h-0.042 c-0.767,0-1.389,0.621-1.389,1.389v0.079C291.756,193.553,292.377,194.176,293.144,194.176z M287.395,182.531h34.424 c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-34.424c-0.767,0-1.389,0.621-1.389,1.389v0.152 C286.006,181.91,286.628,182.531,287.395,182.531z M321.82,194.176h-5.79c-0.767,0-1.389-0.621-1.389-1.389v-5.938 c0-0.767,0.621-1.389,1.389-1.389h8.646c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767,0.621-1.389,1.389-1.389h0.079 c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.077c0.767,0,1.389-0.621,1.389-1.389v-8.94 c0-0.767,0.621-1.389,1.389-1.389h0.079c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.077 c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.079c0.767,0,1.389,0.621,1.389,1.389v11.797 c0,0.767-0.621,1.389-1.389,1.389h-11.505c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-5.826 c-0.767,0-1.389,0.621-1.389,1.389v0.151c0,0.767,0.621,1.389,1.389,1.389h2.97c0.767,0,1.389,0.621,1.389,1.389v0.079 C323.209,193.553,322.586,194.176,321.82,194.176z" />
                <path fill="#FFFFFF" d="M344.589,194.176h0.079c0.767,0,1.389-0.621,1.389-1.389v-3.008c0-0.767,0.621-1.389,1.389-1.389 h5.826c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h11.505c0.767,0,1.389-0.621,1.389-1.389v-5.938 c0-0.767-0.621-1.389-1.389-1.389h-5.792c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h2.973 c0.767,0,1.389,0.621,1.389,1.389v0.151c0,0.767-0.621,1.389-1.389,1.389h-5.865c-0.767,0-1.389-0.621-1.389-1.389v-3.081 c0-0.767-0.621-1.389-1.389-1.389h-11.502c-0.767,0-1.389,0.621-1.389,1.389v5.938 C343.201,193.553,343.822,194.176,344.589,194.176z M350.302,194.176h0.115c0.767,0,1.389-0.621,1.389-1.389v-0.079 c0-0.767-0.621-1.389-1.389-1.389h-0.115c-0.767,0-1.389,0.621-1.389,1.389v0.079C348.913,193.553,349.535,194.176,350.302,194.176 z M344.589,182.531h34.424c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-34.424 c-0.767,0-1.389,0.621-1.389,1.389v0.152C343.201,181.91,343.822,182.531,344.589,182.531z M379.015,194.176h-5.79 c-0.767,0-1.389-0.621-1.389-1.389v-5.938c0-0.767,0.621-1.389,1.389-1.389h8.646c0.767,0,1.389-0.621,1.389-1.389v-3.081 c0-0.767,0.621-1.389,1.389-1.389h0.079c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.077 c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.079c0.767,0,1.389,0.621,1.389,1.389v8.94 c0,0.767,0.621,1.389,1.389,1.389h0.115c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.04 c0.767,0,1.389,0.621,1.389,1.389v11.797c0,0.767-0.621,1.389-1.389,1.389h-11.502c-0.767,0-1.389-0.621-1.389-1.389v-3.008 c0-0.767-0.621-1.389-1.389-1.389h-5.754c-0.767,0-1.389,0.621-1.389,1.389v0.151c0,0.767,0.621,1.389,1.389,1.389h2.899 c0.767,0,1.389,0.621,1.389,1.389v0.079C380.403,193.553,379.782,194.176,379.015,194.176z" />
                <path fill="#FFFFFF" d="M401.82,194.176h0.042c0.767,0,1.389-0.621,1.389-1.389v-3.008c0-0.767,0.621-1.389,1.389-1.389 h5.792c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h11.538c0.767,0,1.389-0.621,1.389-1.389v-5.938 c0-0.767-0.621-1.389-1.389-1.389h-5.752c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h2.933 c0.767,0,1.389,0.621,1.389,1.389v0.151c0,0.767-0.621,1.389-1.389,1.389h-5.826c-0.767,0-1.389-0.621-1.389-1.389v-3.081 c0-0.767-0.621-1.389-1.389-1.389H401.82c-0.767,0-1.389,0.621-1.389,1.389v5.938C400.43,193.553,401.053,194.176,401.82,194.176z M407.532,194.176h0.079c0.767,0,1.389-0.621,1.389-1.389v-0.079c0-0.767-0.621-1.389-1.389-1.389h-0.079 c-0.767,0-1.389,0.621-1.389,1.389v0.079C406.143,193.553,406.765,194.176,407.532,194.176z M401.82,182.531h34.462 c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389H401.82c-0.767,0-1.389,0.621-1.389,1.389v0.152 C400.43,181.91,401.053,182.531,401.82,182.531z M436.281,194.176h-5.865c-0.767,0-1.389-0.621-1.389-1.389v-5.938 c0-0.767,0.621-1.389,1.389-1.389h8.648c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767,0.621-1.389,1.389-1.389h0.115 c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.077c0.767,0,1.389-0.621,1.389-1.389v-8.94 c0-0.767,0.621-1.389,1.389-1.389h0.042c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.115 c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.079c0.767,0,1.389,0.621,1.389,1.389v11.797 c0,0.767-0.621,1.389-1.389,1.389h-11.54c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-5.79 c-0.767,0-1.389,0.621-1.389,1.389v0.151c0,0.767,0.621,1.389,1.389,1.389h3.008c0.767,0,1.389,0.621,1.389,1.389v0.079 C437.671,193.553,437.048,194.176,436.281,194.176z" />
                <path fill="#FFFFFF" d="M459.013,194.176h0.079c0.767,0,1.389-0.621,1.389-1.389v-3.008c0-0.767,0.621-1.389,1.389-1.389 h5.79c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h11.54c0.767,0,1.389-0.621,1.389-1.389v-5.938 c0-0.767-0.621-1.389-1.389-1.389h-5.754c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h2.935 c0.767,0,1.389,0.621,1.389,1.389v0.151c0,0.767-0.621,1.389-1.389,1.389h-5.865c-0.767,0-1.389-0.621-1.389-1.389v-3.081 c0-0.767-0.621-1.389-1.389-1.389h-11.502c-0.767,0-1.389,0.621-1.389,1.389v5.938 C457.625,193.553,458.247,194.176,459.013,194.176z M464.726,194.176h0.079c0.767,0,1.389-0.621,1.389-1.389v-0.079 c0-0.767-0.621-1.389-1.389-1.389h-0.079c-0.767,0-1.389,0.621-1.389,1.389v0.079C463.338,193.553,463.959,194.176,464.726,194.176 z M459.013,182.531h34.388c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-34.388 c-0.767,0-1.389,0.621-1.389,1.389v0.152C457.625,181.91,458.247,182.531,459.013,182.531z M493.401,194.176h-5.79 c-0.767,0-1.389-0.621-1.389-1.389v-5.938c0-0.767,0.621-1.389,1.389-1.389h8.721c0.767,0,1.389-0.621,1.389-1.389v-3.081 c0-0.767,0.621-1.389,1.389-1.389h0.004c0.767,0,1.389,0.621,1.389,1.389v8.94c0,0.767,0.621,1.389,1.389,1.389h0.152 c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.004c0.767,0,1.389,0.621,1.389,1.389v8.94 c0,0.767,0.621,1.389,1.389,1.389h0.079c0.767,0,1.389-0.621,1.389-1.389v-8.94c0-0.767,0.621-1.389,1.389-1.389h0.151 c0.767,0,1.389,0.621,1.389,1.389v11.797c0,0.767-0.621,1.389-1.389,1.389H499.11c-0.767,0-1.389-0.621-1.389-1.389v-3.008 c0-0.767-0.621-1.389-1.389-1.389h-5.864c-0.767,0-1.389,0.621-1.389,1.389v0.151c0,0.767,0.621,1.389,1.389,1.389h2.933 c0.767,0,1.389,0.621,1.389,1.389v0.079C494.79,193.553,494.168,194.176,493.401,194.176z" />
                <path fill="#FFFFFF" d="M1.389,203.035h22.963c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389 H1.389c-0.767,0-1.389,0.621-1.389,1.389v3.081C0,202.414,0.621,203.035,1.389,203.035z M30.022,203.035h22.927 c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389H30.022c-0.767,0-1.389,0.621-1.389,1.389v3.081 C28.633,202.414,29.255,203.035,30.022,203.035z" />
                <path fill="#FFFFFF" d="M58.618,203.035h22.927c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389 H58.618c-0.767,0-1.389,0.621-1.389,1.389v3.081C57.23,202.414,57.852,203.035,58.618,203.035z M87.179,203.035h23 c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389h-23c-0.767,0-1.389,0.621-1.389,1.389v3.081 C85.79,202.414,86.412,203.035,87.179,203.035z" />
                <path fill="#FFFFFF" d="M115.814,203.035h22.963c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389 h-22.963c-0.767,0-1.389,0.621-1.389,1.389v3.081C114.424,202.414,115.047,203.035,115.814,203.035z M144.41,203.035h22.963 c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389H144.41c-0.767,0-1.389,0.621-1.389,1.389v3.081 C143.022,202.414,143.644,203.035,144.41,203.035z" />
                <path fill="#FFFFFF" d="M173.007,203.035h22.963c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389 h-22.963c-0.767,0-1.389,0.621-1.389,1.389v3.081C171.619,202.414,172.241,203.035,173.007,203.035z M201.568,203.035h23 c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389h-23c-0.767,0-1.389,0.621-1.389,1.389v3.081 C200.179,202.414,200.8,203.035,201.568,203.035z" />
                <path fill="#FFFFFF" d="M230.2,203.035h22.965c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389 H230.2c-0.767,0-1.389,0.621-1.389,1.389v3.081C228.812,202.414,229.433,203.035,230.2,203.035z M258.797,203.035h22.965 c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389h-22.965c-0.767,0-1.389,0.621-1.389,1.389v3.081 C257.409,202.414,258.03,203.035,258.797,203.035z" />
                <path fill="#FFFFFF" d="M287.395,203.035h22.965c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389 h-22.965c-0.767,0-1.389,0.621-1.389,1.389v3.081C286.006,202.414,286.628,203.035,287.395,203.035z M316.03,203.035h22.927 c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389H316.03c-0.767,0-1.389,0.621-1.389,1.389v3.081 C314.641,202.414,315.263,203.035,316.03,203.035z" />
                <path fill="#FFFFFF" d="M344.589,203.035h22.965c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389 h-22.965c-0.767,0-1.389,0.621-1.389,1.389v3.081C343.201,202.414,343.822,203.035,344.589,203.035z M373.224,203.035h22.925 c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389h-22.925c-0.767,0-1.389,0.621-1.389,1.389v3.081 C371.836,202.414,372.457,203.035,373.224,203.035z" />
                <path fill="#FFFFFF" d="M401.82,203.035h22.927c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389 H401.82c-0.767,0-1.389,0.621-1.389,1.389v3.081C400.43,202.414,401.053,203.035,401.82,203.035z M430.417,203.035h22.965 c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389h-22.965c-0.767,0-1.389,0.621-1.389,1.389v3.081 C429.028,202.414,429.65,203.035,430.417,203.035z" />
                <path fill="#FFFFFF" d="M459.013,203.035h22.965c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389 h-22.965c-0.767,0-1.389,0.621-1.389,1.389v3.081C457.625,202.414,458.247,203.035,459.013,203.035z M487.612,203.035h23 c0.767,0,1.389-0.621,1.389-1.389v-3.081c0-0.767-0.621-1.389-1.389-1.389h-23c-0.767,0-1.389,0.621-1.389,1.389v3.081 C486.222,202.414,486.844,203.035,487.612,203.035z" />
                <path fill="#FFFFFF" d="M510.611,317.826h-0.077c-0.767,0-1.389,0.621-1.389,1.389v3.008c0,0.767-0.621,1.389-1.389,1.389 h-5.827c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-11.502c-0.767,0-1.389,0.621-1.389,1.389v5.939 c0,0.767,0.621,1.389,1.389,1.389h5.79c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-2.935 c-0.767,0-1.389-0.621-1.389-1.389v-0.151c0-0.767,0.621-1.389,1.389-1.389h5.792c0.767,0,1.389,0.621,1.389,1.389v3.081 c0,0.767,0.621,1.389,1.389,1.389h11.538c0.767,0,1.389-0.621,1.389-1.389v-5.939C512,318.448,511.379,317.826,510.611,317.826z M504.863,317.826h-0.079c-0.767,0-1.389,0.621-1.389,1.389v0.079c0,0.767,0.621,1.389,1.389,1.389h0.079 c0.767,0,1.389-0.621,1.389-1.389v-0.079C506.252,318.448,505.63,317.826,504.863,317.826z M510.611,329.471h-34.423 c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h34.423c0.767,0,1.389-0.621,1.389-1.389v-0.152 C512,330.092,511.379,329.471,510.611,329.471z M476.189,317.826h5.79c0.767,0,1.389,0.621,1.389,1.389v5.939 c0,0.767-0.621,1.389-1.389,1.389h-8.648c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767-0.621,1.389-1.389,1.389h-0.077 c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.079c-0.767,0-1.389,0.621-1.389,1.389v8.94 c0,0.767-0.621,1.389-1.389,1.389h-0.077c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.115 c-0.767,0-1.389,0.621-1.389,1.389v8.94c0,0.767-0.621,1.389-1.389,1.389h-0.042c-0.767,0-1.389-0.621-1.389-1.389v-11.797 c0-0.767,0.621-1.389,1.389-1.389h11.502c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h5.79 c0.767,0,1.389-0.621,1.389-1.389v-0.151c0-0.767-0.621-1.389-1.389-1.389h-2.933c-0.767,0-1.389-0.621-1.389-1.389v-0.079 C474.799,318.448,475.422,317.826,476.189,317.826z" />
                <path fill="#FFFFFF" d="M453.382,317.826h-0.115c-0.767,0-1.389,0.621-1.389,1.389v3.008c0,0.767-0.621,1.389-1.389,1.389 h-5.754c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-11.502c-0.767,0-1.389,0.621-1.389,1.389v5.939 c0,0.767,0.621,1.389,1.389,1.389h5.79c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-2.935 c-0.767,0-1.389-0.621-1.389-1.389v-0.151c0-0.767,0.621-1.389,1.389-1.389h5.792c0.767,0,1.389,0.621,1.389,1.389v3.081 c0,0.767,0.621,1.389,1.389,1.389h11.502c0.767,0,1.389-0.621,1.389-1.389v-5.939C454.77,318.448,454.148,317.826,453.382,317.826z M447.669,317.826h-0.077c-0.767,0-1.389,0.621-1.389,1.389v0.079c0,0.767,0.621,1.389,1.389,1.389h0.077 c0.767,0,1.389-0.621,1.389-1.389v-0.079C449.057,318.448,448.436,317.826,447.669,317.826z M453.382,329.471h-34.388 c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h34.388c0.767,0,1.389-0.621,1.389-1.389v-0.152 C454.77,330.092,454.148,329.471,453.382,329.471z M418.994,317.826h5.827c0.767,0,1.389,0.621,1.389,1.389v5.939 c0,0.767-0.621,1.389-1.389,1.389h-8.648c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767-0.621,1.389-1.389,1.389h-0.115 c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.115c-0.767,0-1.389,0.621-1.389,1.389v8.94 c0,0.767-0.621,1.389-1.389,1.389h-0.042c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.115 c-0.767,0-1.389,0.621-1.389,1.389v8.94c0,0.767-0.621,1.389-1.389,1.389h-0.077c-0.767,0-1.389-0.621-1.389-1.389v-11.797 c0-0.767,0.621-1.389,1.389-1.389h11.575c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h5.754 c0.767,0,1.389-0.621,1.389-1.389v-0.151c0-0.767-0.621-1.389-1.389-1.389h-2.935c-0.767,0-1.389-0.621-1.389-1.389v-0.079 C417.605,318.448,418.227,317.826,418.994,317.826z" />
                <path fill="#FFFFFF" d="M396.186,317.826h-0.077c-0.767,0-1.389,0.621-1.389,1.389v3.008c0,0.767-0.621,1.389-1.389,1.389 h-5.792c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-11.538c-0.767,0-1.389,0.621-1.389,1.389v5.939 c0,0.767,0.621,1.389,1.389,1.389h5.79c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-2.935 c-0.767,0-1.389-0.621-1.389-1.389v-0.151c0-0.767,0.621-1.389,1.389-1.389h5.827c0.767,0,1.389,0.621,1.389,1.389v3.081 c0,0.767,0.621,1.389,1.389,1.389h11.502c0.767,0,1.389-0.621,1.389-1.389v-5.939C397.576,318.448,396.953,317.826,396.186,317.826 z M390.474,317.826h-0.077c-0.767,0-1.389,0.621-1.389,1.389v0.079c0,0.767,0.621,1.389,1.389,1.389h0.077 c0.767,0,1.389-0.621,1.389-1.389v-0.079C391.863,318.448,391.241,317.826,390.474,317.826z M396.186,329.471h-34.425 c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h34.425c0.767,0,1.389-0.621,1.389-1.389v-0.152 C397.576,330.092,396.953,329.471,396.186,329.471z M361.762,317.826h5.827c0.767,0,1.389,0.621,1.389,1.389v5.939 c0,0.767-0.621,1.389-1.389,1.389h-8.646c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767-0.621,1.389-1.389,1.389h-0.079 c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.115c-0.767,0-1.389,0.621-1.389,1.389v8.94 c0,0.767-0.621,1.389-1.389,1.389H350.3c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.042 c-0.767,0-1.389,0.621-1.389,1.389v8.94c0,0.767-0.621,1.389-1.389,1.389h-0.077c-0.767,0-1.389-0.621-1.389-1.389v-11.797 c0-0.767,0.621-1.389,1.389-1.389h11.54c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h5.79 c0.767,0,1.389-0.621,1.389-1.389v-0.151c0-0.767-0.621-1.389-1.389-1.389h-2.973c-0.767,0-1.389-0.621-1.389-1.389v-0.079 C360.373,318.448,360.995,317.826,361.762,317.826z" />
                <path fill="#FFFFFF" d="M338.993,317.826h-0.077c-0.767,0-1.389,0.621-1.389,1.389v3.008c0,0.767-0.621,1.389-1.389,1.389 h-5.79c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-11.54c-0.767,0-1.389,0.621-1.389,1.389v5.939 c0,0.767,0.621,1.389,1.389,1.389h5.827c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-2.935 c-0.767,0-1.389-0.621-1.389-1.389v-0.151c0-0.767,0.621-1.389,1.389-1.389h5.79c0.767,0,1.389,0.621,1.389,1.389v3.081 c0,0.767,0.621,1.389,1.389,1.389h11.502c0.767,0,1.389-0.621,1.389-1.389v-5.939C340.381,318.448,339.759,317.826,338.993,317.826 z M333.28,317.826h-0.077c-0.767,0-1.389,0.621-1.389,1.389v0.079c0,0.767,0.621,1.389,1.389,1.389h0.077 c0.767,0,1.389-0.621,1.389-1.389v-0.079C334.668,318.448,334.047,317.826,333.28,317.826z M338.993,329.471h-34.388 c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h34.388c0.767,0,1.389-0.621,1.389-1.389v-0.152 C340.381,330.092,339.759,329.471,338.993,329.471z M304.605,317.826h5.827c0.767,0,1.389,0.621,1.389,1.389v5.939 c0,0.767-0.621,1.389-1.389,1.389h-8.721c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767-0.621,1.389-1.389,1.389h-0.077 c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.042c-0.767,0-1.389,0.621-1.389,1.389v8.94 c0,0.767-0.621,1.389-1.389,1.389h-0.115c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.079 c-0.767,0-1.389,0.621-1.389,1.389v8.94c0,0.767-0.621,1.389-1.389,1.389h-0.077c-0.767,0-1.389-0.621-1.389-1.389v-11.797 c0-0.767,0.621-1.389,1.389-1.389h11.502c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h5.827 c0.767,0,1.389-0.621,1.389-1.389v-0.151c0-0.767-0.621-1.389-1.389-1.389h-2.935c-0.767,0-1.389-0.621-1.389-1.389v-0.079 C303.217,318.448,303.838,317.826,304.605,317.826z" />
                <path fill="#FFFFFF" d="M281.8,317.826h-0.079c-0.767,0-1.389,0.621-1.389,1.389v3.008c0,0.767-0.621,1.389-1.389,1.389 h-5.79c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-11.54c-0.767,0-1.389,0.621-1.389,1.389v5.939 c0,0.767,0.621,1.389,1.389,1.389h5.827c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-2.97 c-0.767,0-1.389-0.621-1.389-1.389v-0.151c0-0.767,0.621-1.389,1.389-1.389h5.79c0.767,0,1.389,0.621,1.389,1.389v3.081 c0,0.767,0.621,1.389,1.389,1.389h11.54c0.767,0,1.389-0.621,1.389-1.389v-5.939C283.188,318.448,282.567,317.826,281.8,317.826z M276.05,317.826h-0.042c-0.767,0-1.389,0.621-1.389,1.389v0.079c0,0.767,0.621,1.389,1.389,1.389h0.042 c0.767,0,1.389-0.621,1.389-1.389v-0.079C277.439,318.448,276.817,317.826,276.05,317.826z M281.8,329.471h-34.425 c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389H281.8c0.767,0,1.389-0.621,1.389-1.389v-0.152 C283.188,330.092,282.567,329.471,281.8,329.471z M247.374,317.826h5.827c0.767,0,1.389,0.621,1.389,1.389v5.939 c0,0.767-0.621,1.389-1.389,1.389h-8.684c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767-0.621,1.389-1.389,1.389h-0.077 c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.079c-0.767,0-1.389,0.621-1.389,1.389v8.94 c0,0.767-0.621,1.389-1.389,1.389h-0.077c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.077 c-0.767,0-1.389,0.621-1.389,1.389v8.94c0,0.767-0.621,1.389-1.389,1.389h-0.079c-0.767,0-1.389-0.621-1.389-1.389v-11.797 c0-0.767,0.621-1.389,1.389-1.389h11.502c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h5.827 c0.767,0,1.389-0.621,1.389-1.389v-0.151c0-0.767-0.621-1.389-1.389-1.389h-2.97c-0.767,0-1.389-0.621-1.389-1.389v-0.079 C245.986,318.448,246.607,317.826,247.374,317.826z" />
                <path fill="#FFFFFF" d="M224.605,317.826h-0.079c-0.767,0-1.389,0.621-1.389,1.389v3.008c0,0.767-0.621,1.389-1.389,1.389 h-5.79c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-11.54c-0.767,0-1.389,0.621-1.389,1.389v5.939 c0,0.767,0.621,1.389,1.389,1.389h5.792c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-2.899 c-0.767,0-1.389-0.621-1.389-1.389v-0.151c0-0.767,0.621-1.389,1.389-1.389h5.754c0.767,0,1.389,0.621,1.389,1.389v3.081 c0,0.767,0.621,1.389,1.389,1.389h11.54c0.767,0,1.389-0.621,1.389-1.389v-5.939C225.994,318.448,225.372,317.826,224.605,317.826z M218.856,317.826h-0.042c-0.767,0-1.389,0.621-1.389,1.389v0.079c0,0.767,0.621,1.389,1.389,1.389h0.042 c0.767,0,1.389-0.621,1.389-1.389v-0.079C220.244,318.448,219.623,317.826,218.856,317.826z M224.605,329.471H190.18 c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h34.424c0.767,0,1.389-0.621,1.389-1.389v-0.152 C225.994,330.092,225.372,329.471,224.605,329.471z M190.18,317.826h5.79c0.767,0,1.389,0.621,1.389,1.389v5.939 c0,0.767-0.621,1.389-1.389,1.389h-8.646c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767-0.621,1.389-1.389,1.389h-0.079 c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.077c-0.767,0-1.389,0.621-1.389,1.389v8.94 c0,0.767-0.621,1.389-1.389,1.389h-0.079c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389H175.9 c-0.767,0-1.389,0.621-1.389,1.389v8.94c0,0.767-0.621,1.389-1.389,1.389h-0.079c-0.767,0-1.389-0.621-1.389-1.389v-11.797 c0-0.767,0.621-1.389,1.389-1.389h11.505c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h5.826 c0.767,0,1.389-0.621,1.389-1.389v-0.151c0-0.767-0.621-1.389-1.389-1.389h-2.97c-0.767,0-1.389-0.621-1.389-1.389v-0.079 C188.791,318.448,189.414,317.826,190.18,317.826z" />
                <path fill="#FFFFFF" d="M167.411,317.826h-0.079c-0.767,0-1.389,0.621-1.389,1.389v3.008c0,0.767-0.621,1.389-1.389,1.389 h-5.826c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389h-11.505c-0.767,0-1.389,0.621-1.389,1.389v5.939 c0,0.767,0.621,1.389,1.389,1.389h5.792c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-2.973 c-0.767,0-1.389-0.621-1.389-1.389v-0.151c0-0.767,0.621-1.389,1.389-1.389h5.865c0.767,0,1.389,0.621,1.389,1.389v3.081 c0,0.767,0.621,1.389,1.389,1.389h11.502c0.767,0,1.389-0.621,1.389-1.389v-5.939C168.799,318.448,168.178,317.826,167.411,317.826 z M161.698,317.826h-0.115c-0.767,0-1.389,0.621-1.389,1.389v0.079c0,0.767,0.621,1.389,1.389,1.389h0.115 c0.767,0,1.389-0.621,1.389-1.389v-0.079C163.087,318.448,162.465,317.826,161.698,317.826z M167.411,329.471h-34.424 c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h34.424c0.767,0,1.389-0.621,1.389-1.389v-0.152 C168.799,330.092,168.178,329.471,167.411,329.471z M132.985,317.826h5.79c0.767,0,1.389,0.621,1.389,1.389v5.939 c0,0.767-0.621,1.389-1.389,1.389h-8.646c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767-0.621,1.389-1.389,1.389h-0.079 c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.077c-0.767,0-1.389,0.621-1.389,1.389v8.94 c0,0.767-0.621,1.389-1.389,1.389h-0.079c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.115 c-0.767,0-1.389,0.621-1.389,1.389v8.94c0,0.767-0.621,1.389-1.389,1.389h-0.041c-0.767,0-1.389-0.621-1.389-1.389v-11.797 c0-0.767,0.621-1.389,1.389-1.389h11.502c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h5.754 c0.767,0,1.389-0.621,1.389-1.389v-0.151c0-0.767-0.621-1.389-1.389-1.389h-2.899c-0.767,0-1.389-0.621-1.389-1.389v-0.079 C131.597,318.448,132.218,317.826,132.985,317.826z" />
                <path fill="#FFFFFF" d="M110.18,317.826h-0.042c-0.767,0-1.389,0.621-1.389,1.389v3.008c0,0.767-0.621,1.389-1.389,1.389 h-5.792c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389H87.254c-0.767,0-1.389,0.621-1.389,1.389v5.939 c0,0.767,0.621,1.389,1.389,1.389h5.752c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-2.933 c-0.767,0-1.389-0.621-1.389-1.389v-0.151c0-0.767,0.621-1.389,1.389-1.389H95.9c0.767,0,1.389,0.621,1.389,1.389v3.081 c0,0.767,0.621,1.389,1.389,1.389h11.505c0.767,0,1.389-0.621,1.389-1.389v-5.939C111.57,318.448,110.947,317.826,110.18,317.826z M104.468,317.826h-0.079c-0.767,0-1.389,0.621-1.389,1.389v0.079c0,0.767,0.621,1.389,1.389,1.389h0.079 c0.767,0,1.389-0.621,1.389-1.389v-0.079C105.857,318.448,105.235,317.826,104.468,317.826z M110.18,329.471H75.719 c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h34.462c0.767,0,1.389-0.621,1.389-1.389v-0.152 C111.57,330.092,110.947,329.471,110.18,329.471z M75.719,317.826h5.865c0.767,0,1.389,0.621,1.389,1.389v5.939 c0,0.767-0.621,1.389-1.389,1.389h-8.648c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767-0.621,1.389-1.389,1.389h-0.115 c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.077c-0.767,0-1.389,0.621-1.389,1.389v8.94 c0,0.767-0.621,1.389-1.389,1.389h-0.042c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.115 c-0.767,0-1.389,0.621-1.389,1.389v8.94c0,0.767-0.621,1.389-1.389,1.389h-0.079c-0.767,0-1.389-0.621-1.389-1.389v-11.797 c0-0.767,0.621-1.389,1.389-1.389h11.54c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h5.79 c0.767,0,1.389-0.621,1.389-1.389v-0.151c0-0.767-0.621-1.389-1.389-1.389h-3.005c-0.767,0-1.389-0.621-1.389-1.389v-0.079 C74.329,318.448,74.952,317.826,75.719,317.826z" />
                <path fill="#FFFFFF" d="M52.986,317.826h-0.079c-0.767,0-1.389,0.621-1.389,1.389v3.008c0,0.767-0.621,1.389-1.389,1.389 h-5.79c-0.767,0-1.389-0.621-1.389-1.389v-3.008c0-0.767-0.621-1.389-1.389-1.389H30.022c-0.767,0-1.389,0.621-1.389,1.389v5.939 c0,0.767,0.621,1.389,1.389,1.389h5.754c0.767,0,1.389-0.621,1.389-1.389v-0.152c0-0.767-0.621-1.389-1.389-1.389h-2.935 c-0.767,0-1.389-0.621-1.389-1.389v-0.151c0-0.767,0.621-1.389,1.389-1.389h5.865c0.767,0,1.389,0.621,1.389,1.389v3.081 c0,0.767,0.621,1.389,1.389,1.389h11.502c0.767,0,1.389-0.621,1.389-1.389v-5.939C54.375,318.448,53.753,317.826,52.986,317.826z M47.274,317.826h-0.079c-0.767,0-1.389,0.621-1.389,1.389v0.079c0,0.767,0.621,1.389,1.389,1.389h0.079 c0.767,0,1.389-0.621,1.389-1.389v-0.079C48.662,318.448,48.041,317.826,47.274,317.826z M52.986,329.471H18.599 c-0.767,0-1.389,0.621-1.389,1.389v0.152c0,0.767,0.621,1.389,1.389,1.389h34.388c0.767,0,1.389-0.621,1.389-1.389v-0.152 C54.375,330.092,53.753,329.471,52.986,329.471z M18.599,317.826h5.79c0.767,0,1.389,0.621,1.389,1.389v5.939 c0,0.767-0.621,1.389-1.389,1.389H15.67c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767-0.621,1.389-1.389,1.389h-0.004 c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389H9.956c-0.767,0-1.389,0.621-1.389,1.389v8.94 c0,0.767-0.621,1.389-1.389,1.389H7.175c-0.767,0-1.389-0.621-1.389-1.389v-8.94c0-0.767-0.621-1.389-1.389-1.389h-0.08 c-0.767,0-1.389,0.621-1.389,1.389v8.94c0,0.767-0.621,1.389-1.389,1.389h-0.15c-0.767,0-1.389-0.621-1.389-1.389v-11.797 c0-0.767,0.621-1.389,1.389-1.389h11.502c0.767,0,1.389,0.621,1.389,1.389v3.008c0,0.767,0.621,1.389,1.389,1.389h5.864 c0.767,0,1.389-0.621,1.389-1.389v-0.151c0-0.767-0.621-1.389-1.389-1.389H18.6c-0.767,0-1.389-0.621-1.389-1.389v-0.079 C17.21,318.448,17.832,317.826,18.599,317.826z" />
                <path fill="#FFFFFF" d="M510.611,308.966h-22.963c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389 h22.963c0.767,0,1.389-0.621,1.389-1.389v-3.081C512,309.587,511.379,308.966,510.611,308.966z M481.978,308.966h-22.927 c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389h22.927c0.767,0,1.389-0.621,1.389-1.389v-3.081 C483.367,309.587,482.745,308.966,481.978,308.966z" />
                <path fill="#FFFFFF" d="M453.382,308.966h-22.927c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389 h22.927c0.767,0,1.389-0.621,1.389-1.389v-3.081C454.77,309.587,454.148,308.966,453.382,308.966z M424.821,308.966h-23 c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389h23c0.767,0,1.389-0.621,1.389-1.389v-3.081 C426.21,309.587,425.589,308.966,424.821,308.966z" />
                <path fill="#FFFFFF" d="M396.186,308.966h-22.963c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389 h22.963c0.767,0,1.389-0.621,1.389-1.389v-3.081C397.576,309.587,396.953,308.966,396.186,308.966z M367.59,308.966h-22.963 c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389h22.963c0.767,0,1.389-0.621,1.389-1.389v-3.081 C368.979,309.587,368.356,308.966,367.59,308.966z" />
                <path fill="#FFFFFF" d="M338.993,308.966H316.03c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389 h22.963c0.767,0,1.389-0.621,1.389-1.389v-3.081C340.381,309.587,339.759,308.966,338.993,308.966z M310.432,308.966h-23 c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389h23c0.767,0,1.389-0.621,1.389-1.389v-3.081 C311.821,309.587,311.2,308.966,310.432,308.966z" />
                <path fill="#FFFFFF" d="M281.8,308.966h-22.965c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389 H281.8c0.767,0,1.389-0.621,1.389-1.389v-3.081C283.188,309.587,282.567,308.966,281.8,308.966z M253.203,308.966h-22.965 c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389h22.965c0.767,0,1.389-0.621,1.389-1.389v-3.081 C254.591,309.587,253.97,308.966,253.203,308.966z" />
                <path fill="#FFFFFF" d="M224.605,308.966H201.64c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389 h22.965c0.767,0,1.389-0.621,1.389-1.389v-3.081C225.994,309.587,225.372,308.966,224.605,308.966z M195.97,308.966h-22.927 c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389h22.927c0.767,0,1.389-0.621,1.389-1.389v-3.081 C197.359,309.587,196.737,308.966,195.97,308.966z" />
                <path fill="#FFFFFF" d="M167.411,308.966h-22.965c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389 h22.965c0.767,0,1.389-0.621,1.389-1.389v-3.081C168.799,309.587,168.178,308.966,167.411,308.966z M138.776,308.966h-22.925 c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389h22.925c0.767,0,1.389-0.621,1.389-1.389v-3.081 C140.164,309.587,139.543,308.966,138.776,308.966z" />
                <path fill="#FFFFFF" d="M110.18,308.966H87.254c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389 h22.927c0.767,0,1.389-0.621,1.389-1.389v-3.081C111.57,309.587,110.947,308.966,110.18,308.966z M81.583,308.966H58.618 c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389h22.965c0.767,0,1.389-0.621,1.389-1.389v-3.081 C82.972,309.587,82.35,308.966,81.583,308.966z" />
                <path fill="#FFFFFF" d="M52.986,308.966H30.022c-0.767,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389 h22.965c0.767,0,1.389-0.621,1.389-1.389v-3.081C54.375,309.587,53.753,308.966,52.986,308.966z M24.388,308.966H1.389 c-0.768,0-1.389,0.621-1.389,1.389v3.081c0,0.767,0.621,1.389,1.389,1.389h23c0.767,0,1.389-0.621,1.389-1.389v-3.081 C25.778,309.587,25.156,308.966,24.388,308.966z" />
            </g>
            <g>
                <path fill="#DA0000" d="M248.698,214.274c2.618,0.698,5.414-0.071,7.334-2.026c2.898,2.934,7.647,3.004,10.651,0.141 c1.956-1.886,2.759-4.75,2.06-7.334c-2.548,4.121-7.925,5.448-12.046,2.863c-0.21-0.141-0.489-0.28-0.663-0.42 c-3.875,2.863-9.357,2.095-12.291-1.745c-0.106-0.211-0.314-0.49-0.455-0.698C242.239,209.108,244.683,213.296,248.698,214.274z" />
                <path fill="#DA0000" d="M264.966,295.855c9.386-5.153,17.219-13.272,21.514-23.966c7.314-18.33,2.708-39.02-11.283-52.44 c-0.481-0.461-1.212,0.156-0.863,0.724c13.799,22.503,8.363,52.074-12.99,68.278l1.021-65.367c0.014-0.887-0.382-1.732-1.071-2.291 l-3.473-2.807c-1.064-0.86-2.584-0.855-3.642,0.01l-3.422,2.797c-0.683,0.558-1.073,1.398-1.059,2.28l0.992,65.413 c-21.356-16.196-26.804-45.794-13.026-68.312c0.348-0.568-0.383-1.185-0.863-0.723c-13.964,13.42-18.599,34.109-11.286,52.438 c4.295,10.692,12.127,18.812,21.516,23.965c-5.975,2.048-12.255,3.134-18.605,2.952c-0.671-0.019-0.806,0.925-0.163,1.119 c8.068,2.449,16.366,2.617,24.168,0.868l3.063,5.943c0.212,0.41,0.799,0.412,1.012,0.001l3.084-5.941 c7.802,1.746,16.103,1.575,24.177-0.874c0.642-0.194,0.508-1.138-0.163-1.119C277.246,298.988,270.951,297.902,264.966,295.855z" />
                <path fill="#DA0000" d="M303.171,235.086c-4.804-8.975-12.782-15.778-22.279-19.111c-0.595-0.209-1.059,0.577-0.573,0.978 c20.966,17.271,25.169,48.032,8.956,70.302c-0.839,1.118-1.747,2.305-2.62,3.281C306.56,279.851,313.927,254.99,303.171,235.086z" />
                <path fill="#DA0000" d="M222.755,287.255c-16.214-22.27-12.044-53.03,8.918-70.301c0.487-0.402,0.022-1.186-0.573-0.978 c-9.485,3.334-17.408,10.135-22.243,19.11c-10.824,19.904-3.386,44.765,16.552,55.449 C224.467,289.559,223.559,288.373,222.755,287.255z" />
            </g>
        </svg>
    </React.Fragment>
);
