
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import {ResourcesEvents} from "../../core/components/Resources/ResourcesEvents";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";

export const EventRessources: React.FunctionComponent = (props: any): React.ReactElement => {
    const { fields } = props;
    const isH1: boolean = getFieldValue<boolean>(fields, 'is H1', false);
    return (
        <ResourcesEvents fields={fields} isH1={isH1} />
    );
}