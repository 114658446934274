
/*
*   e-Listing - core - components - country flag icon - svk
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const LIE: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.34' style={{fill: '#D80027'}} width='512' height='341.326'/>
        <polygon style={{fill: '#0052B4'}} points='512,85.334 512,252.024 0,256.175 0,85.334 '/>
        <path style={{fill: '#FFDA44'}} d='M211.478,204.06c0-12.295-9.966-22.261-22.261-22.261c-5.703,0-10.901,2.146-14.84,5.672v-13.093  h7.421v-14.84h-7.421v-7.421h-14.84v7.421h-7.421v14.84h7.421v13.093c-3.939-3.526-9.138-5.672-14.84-5.672  c-12.295,0-22.261,9.966-22.261,22.261c0,6.591,2.867,12.512,7.421,16.589v13.093h74.203v-13.093  C208.611,216.572,211.478,210.652,211.478,204.06z'/>
    </svg>
);