/** @jsx jsx */

import React, { useState } from 'react';
import { css, jsx } from '@emotion/react';

import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import Props from '../base/props';
import SearchSecurity from '../../../../../interfaces/search/search-security';
import Type from '../type';
import { formatDate } from '../../../../../utils/date';
import { goToSecurityDetailPage } from '../../../SecurityCard';
import { style } from '../base/card-mobile.style';
import PriceColumn from '../security/price';
import { checkClimateAligned, computeCategoryLabel, RenderMarket } from '../security/row';
import { formatToThousandsSeparator } from '../../../../../utils';

export const LuxXPrimeSearchCard = (props: Props<SearchSecurity>): JSX.Element => {
  const { card } = props;

  if (!card) return <></>;

  const onRowClickHandlerSecurity = (isin: any, id: any) => {
    goToSecurityDetailPage(isin, id);
  };

  const bestOrders = card.marketRealtime?.bestOrders;

  return (
    <div css={style.container}>
      <div css={style.top}>
        <div css={style.left}>
          <Type isGreen={card.lgxDisplay || checkClimateAligned( card.issuers)} label={computeCategoryLabel(card.category)} />
        </div>
        <div css={style.right}>
          <RenderMarket markets={card.marketRule} />
        </div>
      </div>
      <div onClick={() => onRowClickHandlerSecurity(card.isinCode, card.id)} 
           css={style.title}>
        {card.name || '-'}
      </div>
      <div css={{ width: '100%' }}>
        <div css={style.label}>{card.issuers?.length > 0 ? card.issuers[0].name : '-'}</div>
      </div>
      <div css={style.table}>
        <div css={style.row}>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>ISIN</div>
            <div css={style.colBottom}>{card.isinCode || '-'}</div>
          </div>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>Market/Listing</div>
            <div css={style.colBottom}>
              {card?.marketRule ? <div css={style.market}>{card?.marketRule}</div> : '-'}
            </div>
          </div>
        </div>
        <div css={style.row}>
          <div css={[style.col]}>
            <div css={style.colTop}>Listing date</div>
            <div css={style.colBottom}>{formatDate(card.listingDate)}</div>
          </div>
        </div>
        <div css={style.row}>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>Amount</div>
            <div css={style.colBottom}>{card.amountIssued || '-'}</div>
          </div>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>CCY</div>
            <div css={style.colBottom}>
              <div css={style.colBottom}>{card.currency || '-'}</div>
            </div>
          </div>
        </div>
        <div css={style.row}>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>Last Price</div>
            <div css={style.colBottom}>
              {card?.marketData?.lastPrice ? <PriceColumn {...card.marketData?.lastPrice} /> : 'N/A'}
            </div>
          </div>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTop}>Vari. 24h</div>
            <div css={style.colBottom}>
              {card?.marketData?.lastPrice?.changePercent ?
                <>
                  <Icon icon={card.marketData.lastPrice.changePercent < 0 ? 'chevron-down' : 'chevron-up'}/> 
                  {` ${card.marketData.lastPrice.changePercent} %`}
                </>
                : '-'
              }
            </div>
          </div>
        </div>

        <div
          css={[
            style.row,
            css`
              background: #e8faf0;
            `,
          ]}
        >
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTopRight}>Coupon</div>
            <div css={style.colBottomRight}>{(card?.interestRate && `${card.interestRate} %`) || '-'}</div>
          </div>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTopLeft}>Yield</div>
            <div css={style.colBottomLeft}>
              { card?.marketData?.yieldToMaturity ? 
                `${card.marketData.yieldToMaturity} %` 
                : '-'
              }
            </div>
          </div>
        </div>
        <div
          css={[
            style.row,
            css`
              background: #e8faf0;
            `,
          ]}
        >
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTopRight}>BID</div>
            <div css={style.colBottomRight}>
              {bestOrders?.bid?.length && bestOrders.bid[0] ?
                <>
                  <div>{formatToThousandsSeparator(parseFloat(bestOrders.bid[0].price))}</div>
                  <div>{bestOrders.bid[0].quantity}</div>
                </>
                : "-"
              }
            </div>
          </div>
          <div css={[style.col, style.colTwo]}>
            <div css={style.colTopLeft}>ASK</div>
            <div css={style.colBottomLeft}>
              {bestOrders?.ask?.length && bestOrders.ask[0] ?
                <>
                  <div>{formatToThousandsSeparator(parseFloat(bestOrders.ask[0].price))}</div>
                  <div>{bestOrders.ask[0].quantity}</div>
                </>
                : "-"
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LuxXPrimeSearchCard;
