export const formatToThousandsSeparator = (
  value: number,
  symbol: string = ",",
  defaultZeroValue?: string
): string => {
  const [left, ...rest] = value.toString().split(".");
  const tvalue: string = [
    left.replace(/\B(?=(\d{3})+(?!\d))/g, symbol),
    ...rest,
  ].join(".");

  return !!defaultZeroValue && parseInt(tvalue) === 0
    ? defaultZeroValue
    : tvalue;
};

export const toFixedWithoutRounding = (
  value: number,
  precision: number = 1
): number => {
  const decimal: number = Math.pow(10, precision);

  return Math.trunc(value * decimal) / decimal;
};
