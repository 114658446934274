import { css } from "@emotion/react";

export const style = {
  tabContentWrapper: css`
    padding-top: 0;
    display: flex;
    flex-direction:column;
    width: 100%;
  `,

  container: css`
    width: 100%;
    display: flex;
    //padding-top: 48px;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
  `
};
