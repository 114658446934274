import * as React from "react";
import {css, jsx} from "@emotion/react";

import {Helmet, HelmetProvider} from "react-helmet-async";
import {
    DOCUMENT_PREVIEW_LINK,
    WEBSITE_DOMAIN,
    WEBSITE_NAME,
    WEBSITE_TITLE
} from "../../../constants";
import {DocumentInfoModel} from "../pdf-viewer.component";

/** @jsx jsx */

export interface PdfViewerMetaProps {
    document: DocumentInfoModel;
}

export function PdfViewerMeta({
                                  document
                              }: PdfViewerMetaProps) {

    if (!document) return <></>;

    const title = WEBSITE_TITLE + " - " + document?.name + " | " + WEBSITE_NAME;

    return (
        <HelmetProvider>
            <Helmet>
                <meta name="robots" content="noindex, follow"/>
                <meta name="googlebot" content="noindex"/>
                <link rel="canonical" href={WEBSITE_DOMAIN + DOCUMENT_PREVIEW_LINK + document.id}/>
                <title>{title}</title>
                <meta property="og:url" content={WEBSITE_DOMAIN + DOCUMENT_PREVIEW_LINK + document.id}/>
                <meta property="og:title" content={title}/>
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content={title}/>
            </Helmet>
        </HelmetProvider>
    );
}
