import React, {Component} from 'react';
/** @jsx jsx */
import {css,jsx} from '@emotion/react';
import {Th, Thead, Tr} from "@bdl-cmn-shared-packages-npm/design-system";

export class OfficialListHeader extends Component<any, any>{

    render() {
        return (
            <Thead>
                <Tr>
                    <Th></Th>
                    <Th>Publication</Th>
                    <Th>Action</Th>
                </Tr>
            </Thead>
        );
    }
}
export default OfficialListHeader;
