
/*
*   e-Listing - core - components - country flag icon - nld
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const NOR: JSX.Element = (
    <React.Fragment>
        <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 22 16' xmlSpace='preserve'>
            <rect  width="22" height="16" fill="#ba0c2f"/>
            <g fill="#fff">
                <rect width="4" height="16" x="6"/>
                <rect width="22" height="4" y="6"/>
            </g>
            <g fill="#00205b">
                <rect width="2" height="16" x="7"/>
                <rect width="22" height="2" y="7"/>
            </g>
        </svg>
    </React.Fragment>
);
