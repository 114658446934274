/** @jsx jsx */
import { jsx } from "@emotion/react";
import { OAM_LANGUAGE, UseFilters } from "../../useFilters";
import { Menu, MenuWithChild } from "@bdl-cmn-shared-packages-npm/design-system";
import { OAMTypeDepo, OAMTypeDepoLabel } from "../../../../../enums/oam-type-depo";
import { style } from "../index.style";

interface InformationFilterProps {
  lang?: OAM_LANGUAGE,
  selection: OAMTypeDepo | null
  onSelectionChange: (value: OAMTypeDepo | null) => void
}

export default function InformationFilter({ lang = "EN", selection, onSelectionChange }: InformationFilterProps) {
  return (
    <MenuWithChild
      icon="file-alt"
      label="Information"
      width="100%;"
      expanded
      arrows={true}
    >
      {
        Object.values(OAMTypeDepo).map((typeDepo: OAMTypeDepo) => (
          <div css={style.menuItem}>
            <Menu
              key={typeDepo}
              width="100%;"
              arrows={false}
              selected={selection === typeDepo}
              onSelect={() => onSelectionChange(typeDepo)}
              childLabel={OAMTypeDepoLabel(typeDepo, lang)}/>
          </div>
        ))
      }
    </MenuWithChild>
  );
}