import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Security } from "../../../../../interfaces";
import { style } from '../../SecurityOverview/securityOverview.style';
import PriceInformation from "../prices-information/price-information.component";
import { formatDate } from "../../../../../utils/date";

export interface MarketDataInformationProps {
  securityData?: Security;
  label?: string;
}

export const MarketDataGlobalInformation = ({
  securityData, label
}: MarketDataInformationProps) => {
  const marketData = securityData?.marketData || null;
  const negative = marketData?.previousDayChangePercent < 0;

  return (
    <div css={style.blocTitle}>
      {label ? label : "Market"}
      <div css={style.blocTitleRight}>
        <div css={style.blocTitleRightContent}>
          <div css={style.blocTitleRightTop} style={{ color: !negative ? '#22AA5F' : 'red' }}>
            <PriceInformation price={marketData?.lastPrice} negative={negative} />
          </div>
          <div css={style.blocTitleRightBottom}>
            {marketData?.lastPrice?.date && formatDate(marketData?.lastPrice?.date, 'DD/MM/YYYY HH:mm:ss')}
          </div>
        </div>
      </div>
    </div>
  );
}