import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Tr, Td, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { formatToThousandsSeparator } from '../../../../../utils';
import { style } from '../base/index.style';
import Props from '../base/props';
import SearchProgramme from '../../../../../interfaces/search/search-programme';
import { data as dataMapper } from '../../../Search/results/dataMapper';
import { formatDate } from '../../../../../utils/date';
import { goToProgrammeDetailPage } from '../../../ProgrammeCard';
import SearchIssuer from '../../../../../interfaces/search/search-issuer';
import ReactTooltip from 'react-tooltip';

export const computeTypeLabel = (type: string) => {
  switch (type) {
    case 'AUTR':
      return 'Other';
    case 'CP':
      return 'Commercial paper';
    case 'ECP':
      return 'Euro commercial paper';
    case 'MTN':
      return 'Medium term note';
    case 'WAR':
      return 'Warrants programme';
    default:
      return type;
  }
};

const ProgrammeRow = ({ row, selectedCols }: Props<SearchProgramme>) => {
  
  if( !row?.id )
    return <></>;
  
  return (
    <Tr onRowClick={() => goToProgrammeDetailPage(row.url, row.id)} css={style.actionsRow} key={row.id}>
      <Td>
        <div css={[style.market]}>{computeTypeLabel(row.type)}</div>
      </Td>
      <Td>
        { ! row.issuers?.length ?
          <div css={[style.align, style.noIssuer]}>No issuer</div>
          :
          <>
            { (row.issuers?.length == 1) ?
              <div css={style.align}>{row.issuers[0].name}</div>
              :
              <>
                <div css={[style.align, style.multipleIssuer]} data-tip="" data-for={`programme-${row.id}-multi-issuer`}>
                  <span>Multiple issuers <Icon icon={'chevron-down'}/></span>
                </div>
                <ReactTooltip
                  id={`programme-${row.id}-multi-issuer`}
                  css={style.tooltip}
                  data-html={true}
                  insecure={true}
                  multiline={true}
                  place="bottom"
                  effect="solid">
                  <ul css={style.tooltipList}>
                    { row.issuers.map((issuer: SearchIssuer, index: number) => (
                      <li>{issuer.name}</li>
                    ))}
                  </ul>
                </ReactTooltip>
              </>
            }
          </>
        }
      </Td>
      <Td>{row.name}</Td>
      <Td>{formatDate(row.startDate)}</Td>
      <Td>{formatDate(row.endDate)}</Td>
      <Td>{row.activeSecurities}</Td>
      <Td>{row.amountIssued ? `${formatToThousandsSeparator(row.amountIssued)} ${row.amountCurrency}` : 'N/A'}</Td>
      {selectedCols ? (
        selectedCols['programmes']?.map((col: string, index: number) => (
          <Td key={`col-selected-${index}`}>{dataMapper['programmes'][col].render((row as any)[col])}</Td>
        ))
      ) : (
        <></>
      )}
    </Tr>
  );
};

export default ProgrammeRow;
