import {rules} from "./conditions";

export const mergeConditions = (data: any) => {
    if (data[0] === 1){ // First column
        if (data[1] === 1){ // second column, first item
            if (data[2] === 1){ //third column, first item
                // console.log('condition 1');
                return { rule: rules[0], condition: 10};
            }
            if (data[2] === 2){
                // console.log('condition 2');
                return { rule: rules[1], condition: 2};
            }
            if (data[2] === 3){
                // console.log('condition 3');
                return { rule: rules[2], condition: 3};
            }
            if (data[2] === 4){
                // console.log('condition 4');
                return { rule: rules[3], condition: 4};
            }
            if (data[2] === 5){
                // console.log('condition 5');
                return { rule: rules[4], condition: 5};
            }
            if (data[2] === 6){
                // console.log('condition 6');
                return { rule: rules[5], condition: 6};
            }
            if (data[2] === 7){
                // console.log('condition 7');
                return { rule: rules[6], condition: 7};
            }
            if (data[2] === 8){
                // console.log('condition 8');
                return { rule: rules[7], condition: 8};
            }
            if (data[2] === 9){
                // console.log('condition 9');
                return { rule: rules[8], condition: 9};
            }
        }
        if (data[1] === 2){
            if (data[2] === 1){
                // console.log('condition 10');
                return { rule: rules[9], condition: 10};
            }
            if (data[2] === 2){
                // console.log('condition 11');
                return { rule: rules[10], condition: 11};
            }
            if (data[2] === 3){
                // console.log('condition 12');
                return { rule: rules[11], condition: 12};
            }
            if (data[2] === 4){
                // console.log('condition 13');
                return { rule: rules[12], condition: 13};
            }
            if (data[2] === 5){
                // console.log('condition 14');
                return { rule: rules[13], condition: 14};
            }
            if (data[2] === 6){
                // console.log('condition 15');
                return { rule: rules[14], condition: 15};
            }
            if (data[2] === 7){
                // console.log('condition 16');
                return { rule: rules[15], condition: 16};
            }
            if (data[2] === 8){
                // console.log('condition 17');
                return { rule: rules[16], condition: 17};
            }
            if (data[2] === 9){
                // console.log('condition 18');
                return { rule: rules[17], condition: 18};
            }
        }
        if (data[1] === 3){
            if (data[2] === 1){
                // console.log('condition 19');
                return { rule: rules[18], condition: 19};
            }
            if (data[2] === 2){
                // console.log('condition 20');
                return { rule: rules[19], condition: 20};
            }
            if (data[2] === 3){
                // console.log('condition 21');
                return { rule: rules[20], condition: 21};
            }
            if (data[2] === 4){
                // console.log('condition 22');
                return { rule: rules[21], condition: 22};
            }
            if (data[2] === 5){
                // console.log('condition 23');
                return { rule: rules[22], condition: 23};
            }
            if (data[2] === 6){
                // console.log('condition 24');
                return { rule: rules[23], condition: 24};
            }
            if (data[2] === 7){
                // console.log('condition 25');
                return { rule: rules[24], condition: 25};
            }
            if (data[2] === 8){
                // console.log('condition 26');
                return { rule: rules[25], condition: 26};
            }
            if (data[2] === 9){
                // console.log('condition 27');
                return { rule: rules[26], condition: 27};
            }
        }
        if (data[1] === 4){
            if (data[2] === 1){
                // console.log('condition 28');
                return { rule: rules[27], condition: 28};
            }
            if (data[2] === 2){
                // console.log('condition 29');
                return { rule: rules[28], condition: 29};
            }
            if (data[2] === 3){
                // console.log('condition 30');
                return { rule: rules[29], condition: 30};
            }
            if (data[2] === 4){
                // console.log('condition 31');
                return { rule: rules[30], condition: 31};
            }
            if (data[2] === 5){
                // console.log('condition 32');
                return { rule: rules[31], condition: 32};
            }
            if (data[2] === 6){
                // console.log('condition 33');
                return { rule: rules[32], condition: 33};
            }
            if (data[2] === 7){
                // console.log('condition 34');
                return { rule: rules[33], condition: 34};
            }
            if (data[2] === 8){
                // console.log('condition 35');
                return { rule: rules[34], condition: 35};
            }
            if (data[2] === 9){
                // console.log('condition 36');
                return { rule: rules[35], condition: 36};
            }
        }
    }
    if (data[0] === 2){
        if (data[1] === 1){
            if (data[2] === 1){
                // console.log('condition 37');
                return { rule: rules[36], condition: 37};
            }
            if (data[2] === 2){
                // console.log('condition 38');
                return { rule: rules[37], condition: 38};
            }
            if (data[2] === 3){
                // console.log('condition 39');
                return { rule: rules[38], condition: 39};
            }
            if (data[2] === 4){
                // console.log('condition 40');
                return { rule: rules[39], condition: 40};
            }
            if (data[2] === 5){
                // console.log('condition 41');
                return { rule: rules[40], condition: 41};
            }
            if (data[2] === 6){
                // console.log('condition 42');
                return { rule: rules[41], condition: 42};
            }
            if (data[2] === 7){
                // console.log('condition 43');
                return { rule: rules[42], condition: 43};
            }
            if (data[2] === 8){
                // console.log('condition 44');
                return { rule: rules[43], condition: 44};
            }
            if (data[2] === 9){
                // console.log('condition 45');
                return { rule: rules[44], condition: 45};
            }
        }
        if (data[1] === 2){
            if (data[2] === 1){
                // console.log('condition 46');
                return { rule: rules[45], condition: 46};
            }
            if (data[2] === 2){
                // console.log('condition 47');
                return { rule: rules[46], condition: 47};
            }
            if (data[2] === 3){
                // console.log('condition 48');
                return { rule: rules[47], condition: 48};
            }
            if (data[2] === 4){
                // console.log('condition 49');
                return { rule: rules[48], condition: 49};
            }
            if (data[2] === 5){
                // console.log('condition 50');
                return { rule: rules[49], condition: 50};
            }
            if (data[2] === 6){
                // console.log('condition 51');
                return { rule: rules[50], condition: 51};
            }
            if (data[2] === 7){
                // console.log('condition 52');
                return { rule: rules[51], condition: 52};
            }
            if (data[2] === 8){
                // console.log('condition 53');
                return { rule: rules[52], condition: 53};
            }
            if (data[2] === 9){
                // console.log('condition 54');
                return { rule: rules[53], condition: 54};
            }
        }
        if (data[1] === 3){
            if (data[2] === 1){
                // console.log('condition 55');
                return { rule: rules[54], condition: 55};
            }
            if (data[2] === 2){
                // console.log('condition 56');
                return { rule: rules[55], condition: 56};
            }
            if (data[2] === 3){
                // console.log('condition 57');
                return { rule: rules[56], condition: 57};
            }
            if (data[2] === 4){
                // console.log('condition 58');
                return { rule: rules[57], condition: 58};
            }
            if (data[2] === 5){
                // console.log('condition 59');
                return { rule: rules[58], condition: 59};
            }
            if (data[2] === 6){
                // console.log('condition 60');
                return { rule: rules[59], condition: 60};
            }
            if (data[2] === 7){
                // console.log('condition 61');
                return { rule: rules[60], condition: 61};
            }
            if (data[2] === 8){
                // console.log('condition 62');
                return { rule: rules[61], condition: 62};
            }
            if (data[2] === 9){
                // console.log('condition 63');
                return { rule: rules[62], condition: 63};
            }
        }
        if (data[1] === 4){
            if (data[2] === 1){
                // console.log('condition 64');
                return { rule: rules[63], condition: 64};
            }
            if (data[2] === 2){
                // console.log('condition 65');
                return { rule: rules[64], condition: 65};
            }
            if (data[2] === 3){
                // console.log('condition 66');
                return { rule: rules[65], condition: 66};
            }
            if (data[2] === 4){
                // console.log('condition 67');
                return { rule: rules[66], condition: 67};
            }
            if (data[2] === 5){
                // console.log('condition 68');
                return { rule: rules[67], condition: 68};
            }
            if (data[2] === 6){
                // console.log('condition 69');
                return { rule: rules[68], condition: 69};
            }
            if (data[2] === 7){
                // console.log('condition 70');
                return { rule: rules[69], condition: 70};
            }
            if (data[2] === 8){
                // console.log('condition 71');
                return { rule: rules[70], condition: 71};
            }
            if (data[2] === 9){
                // console.log('condition 72');
                return { rule: rules[71], condition: 72};
            }
        }
    }if (data[0] === 3){
        if (data[1] === 1){
            if (data[2] === 1){
                // console.log('condition 73');
                return { rule: rules[72], condition: 73};
            }
            if (data[2] === 2){
                // console.log('condition 74');
                return { rule: rules[73], condition: 74};
            }
            if (data[2] === 3){
                // console.log('condition 75');
                return { rule: rules[74], condition: 75};
            }
            if (data[2] === 4){
                // console.log('condition 76');
                return { rule: rules[75], condition: 76};
            }
            if (data[2] === 5){
                // console.log('condition 77');
                return { rule: rules[76], condition: 77};
            }
            if (data[2] === 6){
                // console.log('condition 78');
                return { rule: rules[77], condition: 78};
            }
            if (data[2] === 7){
                // console.log('condition 79');
                return { rule: rules[78], condition: 79};
            }
            if (data[2] === 8){
                // console.log('condition 80');
                return { rule: rules[79], condition: 80};
            }
            if (data[2] === 9){
                // console.log('condition 81');
                return { rule: rules[80], condition: 81};
            }
        }
        if (data[1] === 2){
            if (data[2] === 1){
                // console.log('condition 82');
                return { rule: rules[81], condition: 82};
            }
            if (data[2] === 2){
                // console.log('condition 83');
                return { rule: rules[82], condition: 83};
            }
            if (data[2] === 3){
                // console.log('condition 84');
                return { rule: rules[83], condition: 84};
            }
            if (data[2] === 4){
                // console.log('condition 85');
                return { rule: rules[84], condition: 85};
            }
            if (data[2] === 5){
                // console.log('condition 86');
                return { rule: rules[85], condition: 86};
            }
            if (data[2] === 6){
                // console.log('condition 87');
                return { rule: rules[86], condition: 87};
            }
            if (data[2] === 7){
                // console.log('condition 88');
                return { rule: rules[87], condition: 88};
            }
            if (data[2] === 8){
                // console.log('condition 89');
                return { rule: rules[88], condition: 89};
            }
            if (data[2] === 9){
                // console.log('condition 90');
                return { rule: rules[89], condition: 90};
            }
        }
        if (data[1] === 3){
            if (data[2] === 1){
                // console.log('condition 91');
                return { rule: rules[90], condition: 91};
            }
            if (data[2] === 2){
                // console.log('condition 92');
                return { rule: rules[91], condition: 92};
            }
            if (data[2] === 3){
                // console.log('condition 93');
                return { rule: rules[92], condition: 93};
            }
            if (data[2] === 4){
                // console.log('condition 94');
                return { rule: rules[93], condition: 94};
            }
            if (data[2] === 5){
                // console.log('condition 95');
                return { rule: rules[94], condition: 95};
            }
            if (data[2] === 6){
                // console.log('condition 96');
                return { rule: rules[95], condition: 96};
            }
            if (data[2] === 7){
                // console.log('condition 97');
                return { rule: rules[96], condition: 97};
            }
            if (data[2] === 8){
                // console.log('condition 98');
                return { rule: rules[97], condition: 98};
            }
            if (data[2] === 9){
                // console.log('condition 99');
                return { rule: rules[98], condition: 99};
            }
        }
        if (data[1] === 4){
            if (data[2] === 1){
                // console.log('condition 100');
                return { rule: rules[99], condition: 100};
            }
            if (data[2] === 2){
                // console.log('condition 101');
                return { rule: rules[100], condition: 101};
            }
            if (data[2] === 3){
                // console.log('condition 102');
                return { rule: rules[101], condition: 102};
            }
            if (data[2] === 4){
                // console.log('condition 103');
                return { rule: rules[102], condition: 103};
            }
            if (data[2] === 5){
                // console.log('condition 104');
                return { rule: rules[103], condition: 104};
            }
            if (data[2] === 6){
                // console.log('condition 105');
                return { rule: rules[104], condition: 105};
            }
            if (data[2] === 7){
                // console.log('condition 106');
                return { rule: rules[105], condition: 106};
            }
            if (data[2] === 8){
                // console.log('condition 107');
                return { rule: rules[106], condition: 107};
            }
            if (data[2] === 9){
                // console.log('condition 108');
                return { rule: rules[107], condition: 108};
            }
        }
    }
}
