import * as React from "react";
import { css } from "@emotion/react";

export const style = {
  container: css`
   
    width: 100%;
    background-color: white;
    padding: 3em;
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    padding: 2em;
    border: 1px solid #D9DEE2;
    border-radius: 8px;
    position: relative;
    gap: 1rem;
    margin-bottom: 3rem;
  `,
  headerItem: css`
   display: flex;
   flex-direction: column;
   gap: 1rem;
`,
  headerItemLabel: css`
    color: #354450;
  `,
  headerItemValue: css`
    color: #422563;
    font-size: 1.5rem;
  ` ,
  ytm: css`
    border: 1px solid #D9DEE2;
    display: flex;
    position: absolute;
    bottom: -3.5rem;
    left: 30%;
    padding: 1rem;
    width: 40%;
    height: 5rem;
    background-color: white;
    box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
    border-radius: 8px;
    justify-content: center;
 `,
  ytmIcon: css`
    margin-right: 1rem;
    margin-top: 5px;
    color: #FF3056;
    font-size: 2em;
    font-weight: bold;
 `,
  ytmItem: css`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
 `,
  ytmItemLabel: css`
    color: #657F93;
  `,
  ytmItemValue: css`
    font-size: 1.2em;
    font-weight: bold;
    color: #273B4C;
  `
}