/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import SecurityHeader from '../security/header';
import { SecurityRow } from '../security/row';
import { SearchTable } from '../base';
import { LuxXPrimeFilters } from './luxXPrime-filters';
import { SecuritySearchResultDto } from '../../../../../interfaces/search/search';
import SearchFilterParams from '../../../../../interfaces/search/search-filter-params';
import { containerCss, resultCss } from '../index.style';
import SecurityLuxXPrimeHeader from './header';
import { SecurityLuxXPrimeRow } from './row';
import { InputSort } from '../base/sort';
import { Loader } from '../../Loader';
import SearchResults from '../../../../../interfaces/search/search-result';
import LuxXPrimeEmptyResults from './empty';

export interface LuxXPrimeListViewProps {
  selectedCols: any;
  data: SecuritySearchResultDto;
  searchResults?: SearchResults;
  loadingPage?: boolean;
  onPaginationUpdate: (data: any) => void;
  currentPageNumber: number;
  density?: 'low' | 'medium' | 'high';
  totalResultSize?: number;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  sort: any;
  sortData: any;
  onSortChange: (sort: any) => void;
}

export const getPaddingSize = (density: 'low' | 'medium' | 'high') => {
  if (density == 'low') {
    return '24px';
  } else if (density === 'medium') {
    return '16px';
  }
  return '8px';
};

export const LuxXPrimeListView = ({
  data,
  searchResults,
  selectedCols,
  loadingPage,
  density,
  onPaginationUpdate,
  currentPageNumber,
  totalResultSize,
  filters,
  onFiltersChange,
  sort,
  sortData,
  onSortChange,
}: LuxXPrimeListViewProps) => {
  return (
    <div css={containerCss}>
      <LuxXPrimeFilters filterCount={data?.filters} filters={filters} onFiltersChange={onFiltersChange} />
      <div css={resultCss}>
        {loadingPage && <Loader />}
        <InputSort sort={sort} baseValues={sortData ? sortData['luxXPrime'] : {}} onSortChange={onSortChange} />
        <SearchTable
          data={data?.securities}
          density={density}
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          totalResultSize={totalResultSize}
          Header={() => <SecurityLuxXPrimeHeader selectedCols={selectedCols} />}
          Row={(row) => <SecurityLuxXPrimeRow row={row} selectedCols={selectedCols} key={`search-luxxprime-r-${row?.id}`}/>}
          Empty={() => <div css={{marginTop: "70px"}}><LuxXPrimeEmptyResults searchResults={searchResults}/></div>}
        />
      </div>
    </div>
  );
};
