/** @jsx jsx */
import { jsx } from "@emotion/react";
import {
  MenuWithChild,
  Menu,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { useMemo } from "react";
import moment from "moment-timezone";
import { OAM_LANGUAGE } from "../useFilters";
import { DocumentDateEnum } from "../../../../enums/document-date";
import { style } from "./index.style";
import { getCurrentMoment } from "../../../../utils/date";


const DATE_RANGE_SORT = [
  DocumentDateEnum.ONE_DAY_AGO,
  DocumentDateEnum.ONE_WEEK_AGO,
  DocumentDateEnum.TWO_WEEKS_AGO,
  DocumentDateEnum.MORE_THAN_TWO_WEEKS,
];

type Data = moment.Moment | null;

interface Selection {
  fromDate: Data;
  toDate: Data;
}

function isEqualDateRange(first: Selection, second: Selection): boolean {
  return isEqual(first?.fromDate, second?.fromDate) && isEqual(first?.toDate, second?.toDate);
}

function isEqual(first: moment.Moment, second: moment.Moment) {
  // Handle special cases such as "filter is undefined so it should match de null date"
  return (!first && !second) || first === second;
}

export function computeLabel(key: DocumentDateEnum, lang: string): string {
    switch (key) {
        case DocumentDateEnum.ONE_DAY_AGO:
            return lang === 'EN' ? "One day ago" : "Il y a un jour";
        case DocumentDateEnum.ONE_WEEK_AGO:
            return lang === 'EN' ? "One week ago" : "Il y a une semaine";
        case DocumentDateEnum.TWO_WEEKS_AGO:
            return lang === 'EN' ? "Two weeks ago" : "Il y a deux semaines";
        case DocumentDateEnum.MORE_THAN_TWO_WEEKS:
        default:
            return lang === 'EN' ? "More than two weeks" : "Plus de deux semaines";
    }
}

export interface DateRangeFilterProps {
  lang?: OAM_LANGUAGE
  selection: Selection
  onSelectionChange: (selection: Selection) => void
}

export function DateRangeFilter({ lang = "EN", selection, onSelectionChange }: DateRangeFilterProps) {
  const options = useMemo(() => {
    const now = getCurrentMoment();
    return {
      [DocumentDateEnum.ONE_DAY_AGO] : {
        fromDate: now.subtract(1,'d').startOf("day"),
        toDate:  now.endOf("day"),
      },
      [DocumentDateEnum.ONE_WEEK_AGO]: {
        fromDate: now.subtract(7,'d').startOf("day"),
        toDate:  now.subtract(1,'d').startOf("day")
      },
      [DocumentDateEnum.TWO_WEEKS_AGO]: {
        fromDate: now.subtract(14,'d').startOf("day"),
        toDate:  now.subtract(7,'d').startOf("day")
      },
      [DocumentDateEnum.MORE_THAN_TWO_WEEKS]: {
        fromDate: null,
        toDate: now.subtract(14,'d').startOf("day")
      }
    }
  }, []);

    return (
        <MenuWithChild
            icon="calendar"
            label={lang == "EN" ? "Range date" : "Dates"}
            width="100%;"
            expanded
            arrows={false}
        >
            {
              Object.entries(options)
                  .map(([key, dateRange]) => {
                      return (
                        <div css={style.menuItem}>
                          <Menu
                            key={key}
                            width="100%;"
                            selected={isEqualDateRange(selection, dateRange)}
                            onSelect={() => onSelectionChange(dateRange)}
                            childLabel={computeLabel(key as DocumentDateEnum, lang)}/>
                        </div>
                      )
                  })
            }
        </MenuWithChild>
    );
}
