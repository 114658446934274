/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import {Icon} from "@bdl-cmn-shared-packages-npm/design-system";

export interface SimpleRedTickComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}
const item = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;
const icon = css`
  color: #cb1234;
  font-size: 16px;
  margin-right: 8px;
`;
const text = css`
  color: #354450;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
`;

export const SimpleRedTickComponent: React.FunctionComponent<SimpleRedTickComponentProps> =
  (props: SimpleRedTickComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const isItem1: string = getFieldValue<string>(fields, "item1", "");
    const isItem2: string = getFieldValue<string>(fields, "item2", "");
    const isItem3: string = getFieldValue<string>(fields, "item3", "");
    const isItem4: string = getFieldValue<string>(fields, "item4", "");
    const isItem5: string = getFieldValue<string>(fields, "item5", "");
    const isItem6: string = getFieldValue<string>(fields, "item6", "");
    const isItem7: string = getFieldValue<string>(fields, "item7", "");
    const isItem8: string = getFieldValue<string>(fields, "item8", "");
    const isItem9: string = getFieldValue<string>(fields, "item9", "");
    const isItem10: string = getFieldValue<string>(fields, "item10", "");
    const hasMarginTop: boolean = getFieldValue<boolean>(fields, "hasMarginTop", false);
    const hasMarginBottom: boolean = getFieldValue<boolean>(fields, "hasMarginBottom", false);

    const container = css`
      width: 100%;
      display: flex;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      margin-bottom: ${hasMarginBottom ? '48px' : '16px'};
      margin-top: ${hasMarginTop ? '48px' : '16px'};
    `;

    return (
        <div css={container}>
            {isItem1 && (
                <div css={item}>
                    <div css={icon}>
                        <Icon icon={'check'} />
                    </div>
                    <div css={text}>
                        <Text
                            field={fields && fields.item1 as SitecoreGenericTextField}
                        />
                    </div>
                </div>
            )}
            {isItem2 && (
                <div css={item}>
                    <div css={icon}>
                        <Icon icon={'check'} />
                    </div>
                    <div css={text}>
                        <Text
                            field={fields && fields.item3 as SitecoreGenericTextField}
                        />
                    </div>
                </div>
            )}
            {isItem3 && (
                <div css={item}>
                    <div css={icon}>
                        <Icon icon={'check'} />
                    </div>
                    <div css={text}>
                        <Text
                            field={fields && fields.item3 as SitecoreGenericTextField}
                        />
                    </div>
                </div>
            )}
            {isItem4 && (
                <div css={item}>
                    <div css={icon}>
                        <Icon icon={'check'} />
                    </div>
                    <div css={text}>
                        <Text
                            field={fields && fields.item4 as SitecoreGenericTextField}
                        />
                    </div>
                </div>
            )}
            {isItem5 && (
                <div css={item}>
                    <div css={icon}>
                        <Icon icon={'check'} />
                    </div>
                    <div css={text}>
                        <Text
                            field={fields && fields.item5 as SitecoreGenericTextField}
                        />
                    </div>
                </div>
            )}
            {isItem6 && (
                <div css={item}>
                    <div css={icon}>
                        <Icon icon={'check'} />
                    </div>
                    <div css={text}>
                        <Text
                            field={fields && fields.item6 as SitecoreGenericTextField}
                        />
                    </div>
                </div>
            )}
            {isItem7 && (
                <div css={item}>
                    <div css={icon}>
                        <Icon icon={'check'} />
                    </div>
                    <div css={text}>
                        <Text
                            field={fields && fields.item7 as SitecoreGenericTextField}
                        />
                    </div>
                </div>
            )}
            {isItem8 && (
                <div css={item}>
                    <div css={icon}>
                        <Icon icon={'check'} />
                    </div>
                    <div css={text}>
                        <Text
                            field={fields && fields.item8 as SitecoreGenericTextField}
                        />
                    </div>
                </div>
            )}
            {isItem9 && (
                <div css={item}>
                    <div css={icon}>
                        <Icon icon={'check'} />
                    </div>
                    <div css={text}>
                        <Text
                            field={fields && fields.item9 as SitecoreGenericTextField}
                        />
                    </div>
                </div>
            )}
            {isItem10 && (
                <div css={item}>
                    <div css={icon}>
                        <Icon icon={'check'} />
                    </div>
                    <div css={text}>
                        <Text
                            field={fields && fields.item10 as SitecoreGenericTextField}
                        />
                    </div>
                </div>
            )}
        </div>
    );
  };
