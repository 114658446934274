
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import {BreadcrumbComponent} from "../../core/components/Breadcrumb";

export const Breadcrumb: React.FunctionComponent = (props: any): React.ReactElement => {
    const { fields } = props;
    return (
        <BreadcrumbComponent fields={fields}/>
    );
}
    