/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import hexa from "./icon.svg";
import bg from "./bg.png";
import { useEffect, useState } from "react";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { TimeToReadIndicator } from "../../core/components/TimeToReadIndicator";
import { renderLink } from "../../utils/helper";
import { breakpointMax } from "../../style";

export interface BlogArticlesCornerProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  background: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
`;
const hexaIcon = css`
  background: url(${hexa});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 110px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
`;
const articleImage = (image: string) => css`
  background: url(${image});
  background-repeat: no-repeat;
  background-color: #e8ecee;
  background-position: center center;
  border-radius: 16px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  height: 257px;
  width: 100%;
  color: #ffffff;

  &:hover {
    opacity: 0.6;
  }

  transition: 150ms ease-in-out;
`;

const container = css`
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const articlesContainer = css`
  width: 100%;
  display: flex;
  gap: 120px;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const articlePart = css`
  width: 50%;
  text-decoration: navajowhite;
  //min-width: 50%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    min-width: 100%;
  }

  &:hover {
    .main-image {
      opacity: 0.6;
    }

    .main-title {
      color: #a4b6c2;
    }
  }

  transition: 150ms ease-in-out;
`;
const articlePartTitle = css`
  font-weight: 800;
  font-size: 36px;
  line-height: 46px;
  color: #e8ecee;
  margin-top: 32px;
  margin-bottom: 24px;

  &:hover {
    color: #a4b6c2;
  }

  transition: 150ms ease-in-out;
`;
const articlePartMeta = css`
  color: #e8ecee;
`;
const title = css`
  font-weight: 800;
  font-size: 36px;
  line-height: 46px;
  margin-top: 40px;
  color: #ffffff;
`;
const description = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 16px;
  color: #ffffff;
  margin-bottom: 40px;
`;

export const BlogArticlesCorner: React.FunctionComponent<BlogArticlesCornerProps> =
  (props: BlogArticlesCornerProps): React.ReactElement => {
    const { fields } = props;

    const icon: string = getFieldValue<string>(fields, "icon", "");
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
      if (fields && fields.articles && !loaded) {
        setLoaded(true);
      }
    }, [fields]);

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={hexaIcon}>
            <Icon icon={icon} />
          </div>
          <div css={title}>
            <Text
              field={fields && (fields?.title as SitecoreGenericTextField)}
            />
          </div>
          <div css={description}>
            <Text
              field={
                fields && (fields?.description as SitecoreGenericTextField)
              }
            />
          </div>

          <div css={articlesContainer}>
            {fields &&
              fields.articles &&
              fields.articles.map((article: any) => (
                <a
                  css={articlePart}
                  href={`${article?.url?.split("/").slice(0, 4).join("/")}`}
                >
                  <div
                    css={articleImage(
                      renderLink(article?.fields["main image"]?.value?.src)
                    )}
                    className="main-image"
                  />
                  <div css={articlePartTitle} className="main-title">
                    {article?.fields?.title?.value}
                  </div>
                  <div css={articlePartMeta}>
                    <TimeToReadIndicator
                      data={article?.fields?.content?.value}
                      date={article?.fields["Creation Date"]?.value}
                      color={"#E8ECEE"}
                    />
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    );
  };
