/** @jsx jsx */

import * as React from 'react';

import {
  ComponentParams,
  ComponentRendering,
  Placeholder, 
  RouteData
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
// import hexa from "./hexa.svg";

export interface MeetOurExpertsContainerProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
    route: RouteData;
}

const parentContainer = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  display: flex;
  position: relative;
  height: auto;
  @media (max-width: ${breakpointMax}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const container = css`
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 40px;
  background-color: #f6f9f9;
  margin-top: 60px;
  margin-bottom: 60px;
  height: auto;
  // display: flow;
  display: table-cell;

  @media (max-width: ${breakpointMax}) {
    padding: 16px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;
const hexaGb = css`
  background-repeat: no-repeat;
  background-size: cover;
  width: 600px;
  position: absolute;
  z-index: -1;
  right: 75%;
  bottom: 0;
  display: flex;
  // height: 300px;
`;

export const MeetOurExpertsContainer: React.FunctionComponent<MeetOurExpertsContainerProps> = (
    props: MeetOurExpertsContainerProps
    ): React.ReactElement => {
    const { rendering } = props;

    return (
        <div css={parentContainer}>
            <div css={hexaGb} />
            <div css={container}>
            <>
                <Placeholder name="jss-expert-cards" rendering={rendering} />
            </>
            </div>  
        </div>
    );
}
