/** @jsx jsx */

import {
  GET_LUXXPRIME_FILTER_DATA,
  LuxXPrimeFilterResults,
} from "../../../../../graphql";
import {
  Icon,
  MenuWithChild,
} from "@bdl-cmn-shared-packages-npm/design-system";
import React, { useState } from "react";
import { css, jsx } from "@emotion/react";

import { AdmissionDateFilter } from "./admission-date-filter";
import { CountryFilter } from "./country-filter";
import { CouponFilter } from "./coupon-filter";
import { CouponTypeFilter } from "./coupon-type";
import { CurrencyFilter } from "./currency-filter";
import { EligibleCategoryFilter } from "./eligible-category-filter";
import { IssuerClassificationFilter } from "./issuer-classification-filter";
import { MaturityDateFilter } from "./maturity-date-filter";
import { PeriodicityFilter } from "./periodicity-filter";
import { Query } from "react-apollo";
import { SDGS } from "../../../Search/useFilters";
import { SDGsList } from "./sdgs-list";
import SearchFilterParams from "../../../../../interfaces/search/search-filter-params";
import { SustainableClassificationFilter } from "./sustainable-classification";
import { YieldFilter } from "./yield-filter";
import { breakpointMax } from "../../../../../style";
import { luxseGatewayGraphqlClient } from "../../../../../luxse-gateway-graphql-client";
import { style } from "../../../Search/results/filters/index.style";
import { IssuerNameFilter } from "./issuer-name-filter";
import SearchIssuerFilters from "../../../../../interfaces/search/search-issuer-filters";
import useTagFilters from "../../../Search/results/useTagFilters";
import Utils from "../../../../utils/utils";

export interface LuxXPrimeFiltersProps {
  filterCount?: any;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  fullWidth?: boolean;
}

export function LuxXPrimeFilters({
  filters,
  onFiltersChange,
  fullWidth,
}: LuxXPrimeFiltersProps) {
  const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    let found = false;
    const parent = event.currentTarget;
    const head = parent.firstChild.firstChild;
    let target = event.target as Element;

    do {
      if (head == target) found = true;

      target = target.parentElement;
    } while (!found && parent != target && target != null);

    if (found) parent.classList.toggle("open");
  };

  const handleFilterChange = (
    key: keyof SearchFilterParams,
    value: any
  ): void => {
    const newFilters: SearchFilterParams = { ...filters };
    if (newFilters[key] instanceof Array && filters[key] instanceof Array) {
      try {
        // @ts-ignore
        let array: any[] =
          filters[key] != null ? Object.assign([], filters[key]) : [];
        if (array != null && array.includes(value))
          array = array.filter((v) => {
            return v !== value;
          });
        else array.push(value);
        array = array.filter((v) => v);
        if (array == null || !array.length) delete newFilters[key];
        else {
          // @ts-ignore
          newFilters[key] = array;
        }
      } catch (e) {
        console.error(
          "Failed to updated filter[" + key + "]",
          filters[key],
          " with value",
          value,
          e
        );
      }
    } else {
      if (filters[key] === value) {
        delete newFilters[key];
      } else {
        // @ts-ignore
        newFilters[key] = value;
      }
    }
    onFiltersChange(newFilters);
  };
  const closeBtn = css`
    width: 38px;
    height: 38px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;

    svg {
      color: #22aa5f;
      width: 25px !important;
      height: 25px !important;
      margin: 0px !important;
    }

    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `;

  const [open, setOpen] = useState(true);

  return (
    <div
      css={[
        fullWidth
          ? style.searchResultsFiltersFullWidth
          : style.searchResultsFilters,
        css`
          position: relative;
          transition: all 0.1s ease-in-out;
          margin-left: ${open ? "0" : "-17%"};
          padding-right: ${open ? "16px" : "60px"};
        `,
      ]}
    >
      <div css={closeBtn} onClick={() => setOpen(!open)}>
        <Icon icon={open ? "arrow-left" : "arrow-right"}></Icon>
      </div>
      <div css={style.title}>Filter your search result</div>
      <Query
        query={GET_LUXXPRIME_FILTER_DATA}
        client={luxseGatewayGraphqlClient}
        variables={{ token: Utils.getCookie("Gztoken-Dsm") }}
        fetchPolicy="network-only"
        errorPolicy="all"
      >
        {({ loading, data }: LuxXPrimeFilterResults) => (
          <>
            <div
              css={[style.row, style.filterMenu]}
              onClick={handleFilterMenuClick}
            >
              <MenuWithChild
                icon="folder-tree"
                label="Issuer information"
                width="100%;"
                expanded={false}
                arrows={false}
              >
                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <IssuerNameFilter
                    selection={filters.issuerIds}
                    onSelectionChange={(value) =>
                      handleFilterChange("issuerIds", value)
                    }
                    data={data || null}
                  />
                </div>

                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <IssuerClassificationFilter
                    selection={{
                      levelOneClassifications: filters.levelOneClassifications,
                      levelTwoClassifications: filters.levelTwoClassifications,
                    }}
                    onSelectionChange={(value) =>
                      onFiltersChange({
                        ...filters,
                        ...value,
                      })
                    }
                  />
                </div>
              </MenuWithChild>
            </div>

            <div
              css={[style.row, style.filterMenu]}
              onClick={handleFilterMenuClick}
            >
              <MenuWithChild
                icon="exchange-alt"
                label="Trading informations"
                width="100%;"
                expanded={false}
                arrows={false}
              >
                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <CouponTypeFilter
                    selection={filters.couponType}
                    onSelectionChange={(value) =>
                      handleFilterChange("couponType", value)
                    }
                  />
                </div>

                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <CouponFilter
                    value={{
                      from: filters.interestRateFilterFrom,
                      to: filters.interestRateFilterTo,
                    }}
                    onValueChange={(values) =>
                      onFiltersChange({
                        ...filters,
                        interestRateFilterFrom: values.from,
                        interestRateFilterTo: values.to,
                      })
                    }
                  />
                </div>

                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <CurrencyFilter
                    selection={filters.currencies}
                    onSelectionChange={(value) =>
                      handleFilterChange("currencies", value)
                    }
                    data={data || null}
                  />
                </div>

                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <YieldFilter
                    value={{
                      from: filters.yieldFilterFrom,
                      to: filters.yieldFilterTo,
                    }}
                    onValueChange={(values) =>
                      onFiltersChange({
                        ...filters,
                        yieldFilterFrom: values.from,
                        yieldFilterTo: values.to,
                      })
                    }
                  />
                </div>

                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <CountryFilter
                    selection={filters.countryFilter}
                    onSelectionChange={(value) =>
                      handleFilterChange("countryFilter", value)
                    }
                    data={data || null}
                  />
                </div>
              </MenuWithChild>
            </div>

            <div
              css={[style.row, style.filterMenu]}
              onClick={handleFilterMenuClick}
            >
              <MenuWithChild
                icon="calendar"
                label="Periods"
                width="100%;"
                expanded={false}
                arrows={false}
              >
                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <AdmissionDateFilter
                    fromDate={filters.firstTradingDateFilterFrom}
                    toDate={filters.firstTradingDateFilterTo}
                    onSelect={(
                      firstTradingDateFilterFrom,
                      firstTradingDateFilterTo
                    ) =>
                      onFiltersChange({
                        ...filters,
                        firstTradingDateFilterFrom,
                        firstTradingDateFilterTo,
                      })
                    }
                  />
                </div>

                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <MaturityDateFilter
                    fromDate={filters.maturityDateFilterFrom}
                    toDate={filters.maturityDateFilterTo}
                    onSelect={(
                      maturityDateFilterFrom,
                      maturityDateFilterTo,
                      includePerpetualFilter
                    ) => {
                      onFiltersChange({
                        ...filters,
                        maturityDateFilterFrom,
                        maturityDateFilterTo,
                        includePerpetualFilter,
                      });
                    }}
                    includePerpetualFilter={filters.includePerpetualFilter}
                    onIncludePerpetualFilterChange={(value) =>
                      handleFilterChange("includePerpetualFilter", value)
                    }
                  />
                </div>

                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <PeriodicityFilter
                    selection={filters.periodicityFilter}
                    onSelectionChange={(values) =>
                      handleFilterChange("periodicityFilter", values)
                    }
                    data={data || null}
                  />
                </div>
              </MenuWithChild>
            </div>

            <div
              css={[style.row, style.filterMenu]}
              onClick={handleFilterMenuClick}
            >
              <MenuWithChild
                icon="calendar"
                label="UN Sustainable Development Goals"
                width="100%;"
                expanded={false}
                arrows={false}
              >
                <SDGsList
                  sdgs={SDGS}
                  onSelect={(sdgs) => handleFilterChange("sdgs", sdgs)}
                  selected={filters.sdgs}
                  deligate={true}
                />
              </MenuWithChild>
            </div>

            <div
              css={[style.row, style.filterMenu]}
              onClick={handleFilterMenuClick}
            >
              <MenuWithChild
                icon="leaf"
                label="LGX information"
                width="100%;"
                expanded={false}
                arrows={false}
              >
                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <SustainableClassificationFilter
                    selection={filters.sustainableClassification}
                    onSelectionChange={(values) =>
                      handleFilterChange("sustainableClassification", values)
                    }
                  />
                </div>

                <div
                  css={[style.subRow, style.filterMenu]}
                  onClick={handleFilterMenuClick}
                >
                  <MenuWithChild
                    icon="leaf"
                    label="Eligible categories"
                    width="100%;"
                    expanded={false}
                    arrows={false}
                  >
                    <EligibleCategoryFilter
                      selection={filters.eligibleProjectCategories}
                      onSelectionChange={(values) =>
                        handleFilterChange("eligibleProjectCategories", values)
                      }
                    />
                  </MenuWithChild>
                </div>
              </MenuWithChild>
            </div>
          </>
        )}
      </Query>
    </div>
  );
}
