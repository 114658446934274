
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {renderLink} from "../../utils/helper";

export interface TableWithImagesProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 48px;
`;
const title = css`
  font-weight: bold;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const table = css`
  border: solid 2px;
  border-collapse: collapse;
  width: 100%;
  margin-left: 10px;
  td {
    border: solid 1px;
    padding: 10px;
  } 
  img {
    max-height: 60px;
  }
`;

export const TableWithImages: React.FunctionComponent<TableWithImagesProps> = (props: TableWithImagesProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div css={container}>
            <table css={title}>
                <Text
                    field={fields && fields.title as SitecoreGenericTextField}
                />
            </table>
            <table css={table}>
                {
                    new Array(20).fill(0).map((arr: any, index: number) => {
                        if (fields && fields[`row ${index + 1} content`] && (fields && fields[`row ${index + 1} content`] as any).value) {
                            return (
                                <tr>
                                    <td>
                                        <Text
                                            field={fields[`row ${index + 1} content`] as SitecoreGenericTextField}
                                        />
                                    </td>
                                    <td>
                                        {fields[`row ${index + 1} image`] && (fields && fields[`row ${index + 1} image`] as any).value && (fields && fields[`row ${index + 1} image`] as any).value.src && (
                                            <img src={renderLink((fields && fields[`row ${index + 1} image`] as any).value.src)} alt={"image"} />
                                        )}
                                    </td>
                                </tr>
                            )
                        }
                    })
                }
            </table>
        </div>
    );
}
