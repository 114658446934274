import * as React from "react";

import {
  SEARCH_INDEXES_PRICE_WITHOUT_FILTERS,
  SEARCH_LGX_SECURITIES_WITHOUT_FILTERS,
  SEARCH_LGX_SECURITIES_WITH_FILTERS,
} from "../../../../../graphql";
import { css, jsx } from "@emotion/react";
import { useEffect, useState } from "react";
import ChartOptions from "./chart-option";
import ColumnChart from "../../../Charts/column";
import CountFilter from "../../../../../interfaces/search/search-filter-count";
import { CountryFlagIcon } from "../../../CountryFlagIcon/countryFlagIcon.component";
import GraphCard from "../../../GraphCard/graphCard.component";
import { EMPTY_DATA_PLACEHOLDER } from "../../../../constants";
import { IndiceSummary } from "../../../../../interfaces/indice";
import { SEARCH_GREEN_DATA } from "../../../../../graphql/queries/green.queries";
import SearchIssuer from "../../../../../interfaces/search/search-issuer";
import SearchSecurity from "../../../../../interfaces/search/search-security";
import { Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import WidgetCard from "../../../WidgetCard";
import { getIssuerType } from "../../../UI/list/issuer/row";
import { goToSecurityDetailPage } from "../../../SecurityCard";
import { issuerTypeLabel } from "../../../../../enums/issuer-type";
import { lgxSlbKpiThemeLabel } from "../../../../../enums/lgx-slb-kpi-theme";
import { lgxStandardShortLabel } from "../../../../../enums/lgx-standard";
import { luxseGatewayGraphqlClient } from "../../../../../luxse-gateway-graphql-client";
import { securityTypeLabel } from "../../../../../enums/security-type";
import { sharedStyle } from "../../../../../styles/shared";
import { style } from "./index.style";
import { tradingStyle } from "../trading-data.style";
import { useHistory } from "react-router-dom";
import { goToIssuerDetailPage } from "../../../IssuerCard";
import { lgxEligibleCategoryLabel } from "../../../../../enums/lgx-eligible-category";
import { getNewListingStatusLabel } from "../../../../../sitecore-components/HomeDataComponent";
import { formatDate, getCurrentMoment } from "../../../../../utils/date";
import Type from "../../../UI/list/type";
import { computeCategoryLabel } from "../../../UI/list/security/row";
import { computePriceStyle } from "../../../../utils/compute-price-style";
import { currencyLabel } from "../../../../../enums";
import GenericLink from "../../TradingData/Shared/generic-link.component";
import { DonutChart } from "../../../Charts/donut";
import { goToIndexDetailPage } from "../../../Index/Index-details";
import Utils from "../../../../utils/utils";

/** @jsx jsx */

export const LGXMarketData = (props: any) => {
  const history = useHistory();
  const [issuerLoading, setIssuerLoading] = useState<boolean>(true);
  const [issuers, setIssuers] = useState<any[]>([]);
  const [issuerRegions, setIssuerRegions] = useState<any[]>([]);
  const [issuerTypes, setIssuerTypes] = useState<any[]>([]);

  const [securityLoading, setSecurityLoading] = useState<boolean>(true);
  const [securities, setSecurities] = useState<any[]>([]);
  const [securityTypes, setSecurityTypes] = useState<any[]>([]);
  const [securityStandards, setSecurityStandards] = useState<any[]>([]);
  const [securityKpiThemes, setSecurityKpiThemes] = useState<any[]>([]);
  const [securityCategories, setSecurityCategories] = useState<any[]>([]);

  const [tradableSecuritiesLoading, setTradableSecuritiesLoading] =
    useState<boolean>(true);
  const [tradableSecurities, setTradableSecurities] = useState<
    SearchSecurity[]
  >([]);

  const [indexLoading, setIndexLoading] = useState<boolean>(true);
  const [indexes, setIndexes] = useState<any[]>([]);

  const client = luxseGatewayGraphqlClient;

  const getIndexData = async () => {
    try {
      const result: any = await client.query({
        query: SEARCH_INDEXES_PRICE_WITHOUT_FILTERS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: 2,
          page: 0,
          sort: "latestPriceDate,asc",
          ids: ["45", "67"],
          idsOperator: "OR",
        },
      });

      if (result.data?.luxseIndexesSearch?.indexes) {
        setIndexes(result.data.luxseIndexesSearch.indexes);
      }
    } catch (e) {
      console.error("Failed to Get indices", e);
    }

    setIndexLoading(false);
  };

  const getIssuerData = async () => {
    const results: any = await client.query({
      query: SEARCH_GREEN_DATA,
      variables: {
        token: Utils.getCookie("Gztoken-Dsm"),
        page: 0,
        size: 5,
        sort: "",
      },
    });

    if (results && results.data) {
      const data = results.data.luxseIssuersSearch;

      setIssuerRegions(data?.filters?.regions || null);
      setIssuerTypes(data?.filters?.issuerTypes || null);

      setIssuers(data?.issuers || null);
    }

    setIssuerLoading(false);
  };

  const getSecurityData = async () => {
    const results: any = await client.query({
      query: SEARCH_LGX_SECURITIES_WITH_FILTERS,
      variables: {
        token: Utils.getCookie("Gztoken-Dsm"),
        page: 0,
        size: 5,
        sort: "lgxPubDate,desc",
        statuses: ["ADMI", "NEGO", "COTE"],
        statusesOperator: "OR",
        listingDateTo: getCurrentMoment(),
      },
    });

    if (results && results.data) {
      const data = results.data.luxseSecuritiesSearch;
      console.log(
        "data?.filters?.type",
        data?.filters?.types,
        data?.filters?.types.filter((f: any) => f.name !== "CAI")
      );
      setSecurityTypes(
        data?.filters?.types?.filter((f: any) => f.name !== "CAI") || null
      );
      setSecurityStandards(data?.filters?.lgxStandards || null);
      setSecurityKpiThemes(data?.filters?.lgxSlbKpiThemes || null);
      setSecurityCategories(data?.filters?.lgxEligibleCategories || null);

      setSecurities(data?.securities || null);
    }

    setSecurityLoading(false);
  };

  const getTradableSecuritiesData = async () => {
    const results: any = await client.query({
      query: SEARCH_LGX_SECURITIES_WITHOUT_FILTERS,
      variables: {
        token: Utils.getCookie("Gztoken-Dsm"),
        page: 0,
        size: 5,
        sort: "listingDate,desc",
        statuses: ["ADMI", "NEGO", "COTE"],
        statusesOperator: "OR",
        listingDateTo: getCurrentMoment(),
      },
    });

    if (results && results.data) {
      const data = results.data.luxseSecuritiesSearch;

      setTradableSecurities(data?.securities || null);
    }

    setTradableSecuritiesLoading(false);
  };

  useEffect(() => {
    try {
      getSecurityData();
    } catch (e) {
      console.error("Failed to get securities data", e);
      setSecurityLoading(false);
    }

    try {
      getTradableSecuritiesData();
    } catch (e) {
      console.error("Failed to get tradable securities data", e);
      setTradableSecuritiesLoading(false);
    }

    try {
      getIssuerData();
    } catch (e) {
      console.error("Failed to get issuers data", e);
      setIssuerLoading(false);
    }

    try {
      getIndexData();
    } catch (e) {
      console.error("Failed to get indexes data", e);
      setIndexLoading(false);
    }
  }, []);

  const graphColors: string[] = [
    "#22AA5F",
    "#52D28C",
    "#05964B",
    "#074A25",
    "#009CC4",
    "#66C4DC",
    "#B3E2EE",
    "#F18700",
    "#F7B766",
    "#FBDBB3",
  ];

  return (
    <div css={tradingStyle.mainContent}>
      <div css={sharedStyle.row}>
        <WidgetCard
          icon="chart-area"
          label="LGX Securities per type"
          loader={securityLoading}
          link={
            <GenericLink
              pathname={"/search?lgxOnly=true"}
              data={null}
              label={"LGX securities"}
              totalHits={null}
            />
          }
        >
          <DonutChart
            chartName={"securityTypes"}
            data={securityTypes}
            graphColors={graphColors}
            labelField={"name"}
            serieField={"count"}
            labelValueFunction={(value: string) => securityTypeLabel(value)}
            displayLabels={true}
            totalDisplay={true}
            totalLabelAfter={"instruments"}
          />
        </WidgetCard>
        <WidgetCard
          icon="chart-area"
          label="New LGX displays"
          loader={securityLoading}
          link={
            <GenericLink
              pathname={"/search?lgxOnly=true"}
              data={null}
              label={"LGX securities"}
              totalHits={null}
            />
          }
        >
          <table
            css={[
              sharedStyle.table,
              css`
                tbody {
                  display: unset;
                }

                td {
                  height: 100% !important;
                }
              `,
            ]}
          >
            <thead
              style={{
                background: "#F8FAFC",
                color: "#253645",
                fontSize: "0.8rem",
                textAlign: "left",
              }}
            >
              <tr>
                <th css={[sharedStyle.statusTh, { width: "65%" }]}>Security</th>
                <th css={[sharedStyle.statusTh, { width: "35%" }]}>Type</th>
              </tr>
            </thead>
            <tbody css={securities.length > 5 && sharedStyle.gradientTop}>
              {securities.length > 0 ? (
                securities.map((security: SearchSecurity) => (
                  <tr
                    css={[style.row, style.rowClickable]}
                    onClick={() =>
                      goToSecurityDetailPage(security.isinCode, security.id)
                    }
                  >
                    <td css={[sharedStyle.tableNewSecurity, { width: "65%" }]}>
                      <div css={{ marginRight: "15px" }}>
                        <Type
                          isGreen={security.lgxDisplay}
                          label={computeCategoryLabel(security.category)}
                        />
                      </div>
                      <div css={style.cellMultipleLine}>
                        <div css={style.subTitle}>{security.isinCode}</div>
                        <div css={style.title}>{security.name}</div>
                      </div>
                    </td>
                    <td css={[sharedStyle.status, { width: "35%" }]}>
                      {security?.tags?.includes("RBDL_GRBD") ? (
                        <div css={style.tag}>
                          <div>Green bond</div>
                        </div>
                      ) : security?.tags?.includes("RBDL_SOCI") ? (
                        <div css={style.tag}>
                          <div>Social bond</div>
                        </div>
                      ) : security?.tags?.includes("RBDL_SUST") ? (
                        <div css={style.tag}>
                          <div>Sustainability bond</div>
                        </div>
                      ) : security?.tags?.includes("RBDL_SLB") ? (
                        <div css={style.tag}>
                          <div>Sustainability-linked bond</div>
                        </div>
                      ) : security?.tags?.includes("RBDL_ESG") ||
                        security?.tags?.includes("RBDL_ESG8") ? (
                        <div css={style.tag}>
                          <div>Article 8 Fund</div>
                        </div>
                      ) : security?.tags?.includes("RBDL_IMP9") ? (
                        <div css={style.tag}>
                          <div>Article 9 Fund</div>
                        </div>
                      ) : (
                        <>N/A</>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td css={sharedStyle.status}>-</td>
                  <td css={sharedStyle.tableNewSecurity}>-</td>
                  <td css={sharedStyle.status}>-</td>
                </tr>
              )}
            </tbody>
            {securities.length > 7 && <div css={sharedStyle.gradientBottom} />}
          </table>
        </WidgetCard>
        <WidgetCard
          icon="chart-area"
          label="New LGX issuers"
          loader={issuerLoading}
          link={
            <GenericLink
              pathname={"/search?lgxOnly=true&dataType=issuers"}
              data={null}
              label={"LGX issuers"}
              totalHits={null}
            />
          }
        >
          <table css={sharedStyle.table}>
            <thead
              style={{
                background: "#F8FAFC",
                color: "#253645",
                fontSize: "0.8rem",
                textAlign: "left",
              }}
            >
              <tr>
                <th css={sharedStyle.tableNewSecurityTh}>Issuer</th>
                <th css={sharedStyle.statusTh}>Type</th>
                <th css={sharedStyle.statusTh}>Country</th>
              </tr>
            </thead>
            <tbody css={issuers.length > 5 && sharedStyle.gradientTop}>
              {issuers.length > 0 ? (
                issuers.map((issuer: SearchIssuer) => (
                  <tr
                    css={[style.row, style.rowClickable]}
                    onClick={() => goToIssuerDetailPage(issuer.url, issuer.id)}
                  >
                    <td css={[sharedStyle.tableNewSecurity, style.title]}>
                      {issuer.name}
                    </td>
                    <td css={sharedStyle.status}>
                      <div css={style.tag}>
                        {getIssuerType(issuer.type, issuer.oldType)}
                      </div>
                    </td>
                    <td css={sharedStyle.status}>
                      <div css={style.countryFlag}>
                        <CountryFlagIcon
                          code={issuer.country}
                          size={{ height: 20, width: 30 }}
                        />
                      </div>
                      {issuer.country}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td css={sharedStyle.tableNewSecurity}>-</td>
                  <td css={sharedStyle.status}>-</td>
                  <td css={sharedStyle.status}>-</td>
                </tr>
              )}
            </tbody>
          </table>
        </WidgetCard>
      </div>
      <div css={sharedStyle.rowLabel}>Focus on LGX Bonds</div>
      <div css={sharedStyle.row}>
        <WidgetCard
          icon="chart-area"
          label="Issuers by Region"
          loader={issuerLoading}
          link={
            <GenericLink
              pathname={"/search?lgxOnly=true&dataType=issuers"}
              data={null}
              label={"LGX issuers"}
              totalHits={null}
            />
          }
        >
          <DonutChart
            chartName={"issuerRegions"}
            data={issuerRegions}
            graphColors={graphColors}
            labelField={"name"}
            serieField={"count"}
            labelValueFunction={(value: string) => value}
            displayLabels={true}
            totalDisplay={true}
            totalLabelAfter={"issuers"}
          />
        </WidgetCard>
        <WidgetCard
          icon="chart-area"
          label="Issuers by Sector"
          loader={issuerLoading}
          link={
            <GenericLink
              pathname={"/search?lgxOnly=true&dataType=issuers"}
              data={null}
              label={"LGX issuers"}
              totalHits={null}
            />
          }
        >
          <DonutChart
            chartName={"issuerTypes"}
            data={issuerTypes}
            graphColors={graphColors}
            labelField={"name"}
            serieField={"count"}
            labelValueFunction={(value: string) => issuerTypeLabel(value)}
            displayLabels={true}
            totalDisplay={true}
            totalLabelAfter={"issuers"}
          />
        </WidgetCard>
      </div>
      <div css={sharedStyle.row}>
        <WidgetCard
          icon="chart-area"
          label="GSSS bonds per standards"
          loader={securityLoading}
          link={
            <GenericLink
              pathname={"/search?lgxOnly=true"}
              data={null}
              label={"LGX securities"}
              totalHits={null}
            />
          }
        >
          <ColumnChart
            data={securityStandards}
            labelField={"name"}
            serieField={"count"}
            serieName={"GSSS Bonds"}
            labelValueFunction={(value) => lgxStandardShortLabel(value)}
          />
        </WidgetCard>
        <WidgetCard
          icon="chart-area"
          label="Top project categories for GSS bonds"
          loader={securityLoading}
          link={
            <GenericLink
              pathname={"/search?lgxOnly=true"}
              data={null}
              label={"LGX securities"}
              totalHits={null}
            />
          }
        >
          <table css={sharedStyle.table}>
            <thead>
              <tr>
                <th css={sharedStyle.tableNewSecurityTh}>Category</th>
                <th css={sharedStyle.statusTh}>Count</th>
              </tr>
            </thead>
            <tbody>
              {securityCategories.length > 0 ? (
                securityCategories
                  .sort((first, second) => second.count - first.count)
                  .map((category: CountFilter) => (
                    <tr css={style.row}>
                      <td css={sharedStyle.tableNewSecurity}>
                        <div css={style.tag}>
                          {lgxEligibleCategoryLabel(category.name)}
                        </div>
                      </td>
                      <td css={sharedStyle.status}>{category.count}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td css={sharedStyle.tableNewSecurity}>-</td>
                  <td css={sharedStyle.status}>-</td>
                </tr>
              )}
            </tbody>
          </table>
        </WidgetCard>
        <WidgetCard
          icon="chart-area"
          label="SLBs by KPI themes"
          loader={securityLoading}
          link={
            <GenericLink
              pathname={"/search?lgxOnly=true"}
              data={null}
              label={"LGX securities"}
              totalHits={null}
            />
          }
        >
          <DonutChart
            chartName={"securityKpiThemes"}
            data={securityKpiThemes}
            graphColors={graphColors}
            labelField={"name"}
            serieField={"count"}
            labelValueFunction={(value: string) => lgxSlbKpiThemeLabel(value)}
            displayLabels={true}
            totalDisplay={true}
            totalLabelAfter={"instruments"}
          />
        </WidgetCard>
      </div>
      <div css={sharedStyle.rowLabel}>Other Specialized Services</div>
      <div css={sharedStyle.row}>
        <WidgetCard
          icon="chart-area"
          label="Indices"
          link=""
          loader={indexLoading}
        >
          <>
            {indexes && indexes?.length > 0 ? (
              <table css={{ width: "100%" }}>
                <tbody>
                  {indexes.map((index: IndiceSummary) => (
                    <Tr onRowClick={() => goToIndexDetailPage(index.id)}>
                      <GraphCard
                        key={`graph-${index.id}`}
                        heightChart={200}
                        chartOptions={ChartOptions}
                        data={index.indexPrice}
                        label={index.indexName}
                      />
                    </Tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>No data available</div>
            )}
          </>
        </WidgetCard>
        <WidgetCard
          icon="chart-area"
          label="New tradable LGX securities"
          loader={tradableSecuritiesLoading}
          link={
            <GenericLink
              pathname={"/search?lgxOnly=true"}
              data={null}
              label={"LGX securities"}
              totalHits={null}
            />
          }
        >
          <table
            css={[
              sharedStyle.table,
              css`
                tbody {
                  display: unset;
                }

                td {
                  height: 100% !important;
                }
              `,
            ]}
          >
            <thead>
              <tr>
                <th css={sharedStyle.statusTh}>Status</th>
                <th css={sharedStyle.tableNewSecurityTh}>Security</th>
                <th css={sharedStyle.statusTh}>Maturity</th>
                <th css={sharedStyle.statusTh}>Yield</th>
                <th css={sharedStyle.statusTh}>Price/Vari. (24h)</th>
              </tr>
            </thead>
            <tbody>
              {tradableSecurities.length > 0 ? (
                tradableSecurities.map((security: SearchSecurity) => (
                  <tr
                    css={[style.row, style.rowClickable]}
                    onClick={() =>
                      goToSecurityDetailPage(security.isinCode, security.id)
                    }
                  >
                    <td css={[sharedStyle.status, style.cellMultipleLine]}>
                      <div css={style.title}>
                        {getNewListingStatusLabel(security)}
                      </div>
                      <div css={style.subTitle}>
                        {formatDate(security.listingDate)}
                      </div>
                    </td>
                    <td
                      css={[
                        sharedStyle.tableNewSecurity,
                        style.cellMultipleLine,
                      ]}
                    >
                      <div css={style.subTitle}>{security.isinCode}</div>
                      <div css={style.title}>{security.name}</div>
                    </td>
                    <td css={sharedStyle.status}>
                      {security?.finalMaturityDate
                        ? formatDate(security.finalMaturityDate)
                        : "N/A"}
                    </td>
                    <td css={sharedStyle.status}>
                      {security.marketData?.yieldToMaturity
                        ? security.marketData?.yieldToMaturity +
                          security.marketData?.tradeIndicator
                        : EMPTY_DATA_PLACEHOLDER}
                    </td>
                    <td css={[sharedStyle.status, style.cellMultipleLine]}>
                      {security?.marketData?.lastPrice ? (
                        <>
                          <div css={style.subTitle}>
                            {`${security.marketData.lastPrice.amount} ${
                              security.marketData.lastPrice.marker
                            } ${currencyLabel(
                              security.marketData.lastPrice.currency
                            )}`}
                          </div>
                          <div css={style.subTitle}>
                            {security.marketData?.lastPrice?.changePercent
                              ? computePriceStyle(
                                  security.marketData?.lastPrice?.changePercent,
                                  ""
                                )
                              : EMPTY_DATA_PLACEHOLDER}
                          </div>
                        </>
                      ) : (
                        EMPTY_DATA_PLACEHOLDER
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td css={sharedStyle.status}>-</td>
                  <td css={sharedStyle.tableNewSecurity}>-</td>
                  <td css={sharedStyle.status}>-</td>
                  <td css={sharedStyle.status}>-</td>
                  <td css={sharedStyle.status}>-</td>
                </tr>
              )}
            </tbody>
          </table>
        </WidgetCard>
      </div>
    </div>
  );
};
export default LGXMarketData;
