export enum MarketTradingTypeEnum {
	MFX = "Multiple call auction",
	MCD = "Continuous",
	MCC = "Continuous",
}

export function marketTradingTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(MarketTradingTypeEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(MarketTradingTypeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get MarketTradingTypeEnum label for '" + value + "'", e);
	}
		
	return value;
}