/** @jsx jsx */

import * as React from "react";
import { useState } from "react";
import {
  ComponentParams,
  ComponentRendering,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import checkIcon from "./check.svg";
import { fetchData, getDataWithChild } from "../../utils/helper";

export interface TabsContainerComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const title = css`
  color: #253845;
  font-weight: 700;
  font-size: 48px;
  font-style: normal;
  line-height: normal;
  text-wrap: balance;

  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 32px;
    line-height: normal;
    text-align: left;
  }
`;
const paragraph = css`
  margin-top: 24px;
  margin-bottom: 24px;
  color: #425563;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 0.16px;

  span,
  a {
    color: #22aa5f;
    text-decoration: underline;
    cursor: pointer;
  }
  a {
    margin-left: 5px;
    margin-right: 5px;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    list-style: none;
    background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    padding-left: 40px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.01em;
    font-size: 16px;
    margin-right: 8px;
  }
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.14px;

  }
`;
const tabsContent = css`
  display: flex;
  flex-direction: column;
`;
const tabsContentTitle = (selected: boolean) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  border-left: ${selected ? "solid 1px #495966" : "solid 1px #22AA5F"};
  padding-bottom: 0;
  margin-bottom: 16px;
  height: 32px;
  @media (max-width: ${breakpointMax}) {
    border-bottom: ${selected ? "solid 1px #495966" : "solid 1px #22AA5F"};
    border-left: none;
    padding-left: 5px;
    padding-right: 5px;
    height: auto;
  }
`;

const parentContainer = css`
  width: 100%;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: ${breakpointMax}) {
    // padding-left: 16px;
    // padding-right: 16px;
    padding-top: 5px;
    padding-bottom: 40px;
  }
`;
const container = css`
  width: 100%;
  max-width: 1140px;
  padding: 64px;
  gap: 32px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: inherit;
  align-items: flex-start;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    > div > div:first-of-type {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
const tabsContainer = css`
  padding-top: 80px;
  width: 25%;
  flex-direction: column;
  gap: 10px;
  @media (max-width: ${breakpointMax}) {
    padding-top: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
`;
const tabsContainerResults = css`
  display: flex;
  width: 70%;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const tabTitle = (selected: boolean) => css`
  color: ${selected ? "#495966" : "#22AA5F"};
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 275px;
  @media (max-width: ${breakpointMax}) {
    max-width: none;
    text-wrap: nowrap;
    height: 38px;
  }
`;

export const TabsContainerComponent: React.FunctionComponent<TabsContainerComponentProps> =
  (props: TabsContainerComponentProps): React.ReactElement => {
    const { fields } = props;

    const [selectedItems, setSelectedItems] = useState(1);

    const [dictionaryItems, setDictionaryItems] = React.useState<{ [key: string]: string }>( {});
    const [isLoading, setIsLoading] = React.useState(true);


    const getDictionaryItems = async (id: string) => {
      try {
        const result = await fetchData(id);
        const dataReq = await getDataWithChild(result);
        console.log('Raw data with children:', dataReq);
        const items: { [key: string]: string } = {};

        if (dataReq && dataReq.length > 0) {
          for (const d of dataReq) {
            if (d.children && d.children.length > 0) {
              for (const item of d.children) {
                const key = item.Key;
                const phrase = item.Phrase;
                if (key && phrase) {
                  items[key] = phrase;
                }
              }
            }
          }
        } else {
          console.warn('No data found:', dataReq);
        }
        setDictionaryItems(items);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching dictionary items:', err);
      }
    };

    React.useEffect(() => {
      getDictionaryItems('504AE189-9F36-4C62-9767-66D73D6C3084');
    }, []);

    const replaceKeysWithPhrases = (text: string, dictionary: { [key: string]: string }) => {
      return text.replace(/\[([^\]]+)\]/g, (match, key) => dictionary[key] || key);
    };

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={tabsContainer}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(
              (item: number) => {
                if (
                  fields &&
                  fields[`tab ${item} title`] &&
                  fields[`tab ${item} title`].value
                ) {
                  return (
                    <div css={tabsContentTitle(selectedItems === item)}>
                      <div
                        css={tabTitle(selectedItems === item)}
                        onClick={() => setSelectedItems(item)}
                      >
                        {(fields[`tab ${item} title`] as any)?.value}
                      </div>
                    </div>
                  );
                }
              }
            )}
          </div>
          <div css={tabsContainerResults}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(
              (item: number) => {
                if (
                  fields &&
                  fields[`tab ${item} title`] &&
                  fields[`tab ${item} title`].value &&
                  selectedItems === item
                ) {
                  const transformedContent = replaceKeysWithPhrases(
                    (fields[`tab ${item} content`] as any)?.value,
                    dictionaryItems
                  );
                  return (
                    <div css={tabsContent}>
                      <div css={title}>
                        {(fields[`tab ${item} title`] as any)?.value}
                      </div>
                      <div css={paragraph}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: transformedContent,
                          }}
                        />
                      </div>
                    </div>
                  );
                }
              }
            )}
          </div>
        </div>
      </div>
    );
  };
