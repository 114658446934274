export enum DocumentGreenTypeEnum {
	FRAMEWORK="Framework",
	FRAMEWORK_ANNEX="Framework annex",
	INVESTOR_PRESENTATION="Investor presentation",
	NEWS="News",
	ICMA_INFO_TEMPLATE="ICMA Information Template",
	OTHER_DOC="Other",
	OPINION="Second Opinion",
	CERTIFICATION="Certification",
	VERIFICATION="Verification",
	RATING="Rating",
	AUDIT="Audit",
	ICMA_EXTERNAL_REVIEW="ICMA External Review Form",
	OTHER_PRE_ISSUANCE_REVIEW="Other",
	DEDICATED="Dedicated Report",
	REPORT_ANNUAL_BOND="Annual Report",
	SUSTAINABILITY="Sustainability Report",
	OTHER_POST_ISSUANCE_REVIEW="Other",
	INVESTMENT_POLICY="Investment policy & objectives",
	FACTSHEET="Factsheet",
	ESG_POLICY="ESG policy",
	FUND_STRATEGY="Fund strategy",
	EXCLUSIONS_POLICY="Exclusions policy",
	ENGAGEMENT_POLICY="Engagement policy",
	VOTING_POLICY="Voting policy",
	KIID="KIID",
	PROSPECTUS="Prospectus",
	INVESTMENT_AND_ENGAGEMENT_POLICY="Engagement & voting policy",
	INVESTMENT_POLICY_AND_STRATEGY="Investment policy & strategy",
	RESPONSIBLE_INVESTMENT_REPORTING="Responsible investment reporting",
	IMPACT_REPORTING="Impact Reporting",
	ANNUAL_REPORT="Annual report",
	SEMI_ANNUAL_REPORT="Semi-annual report",
	RATING_REPORT="Rating report",
	OTHER_FUND_DOC="Other",
	FACTSHEET_CBI="Factsheet Cbi",
}

export function documentGreenTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(DocumentGreenTypeEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(DocumentGreenTypeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get DocumentGreenTypeEnum label for '" + value + "'", e);
	}
		
	return value;
}