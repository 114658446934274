import * as React from "react";
import { css, jsx } from "@emotion/react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { securityDetailPageLink } from "..";
import { Security } from "../../../../interfaces";
import { GreenCard } from "../../../../interfaces/green-card";
import { WEBSITE_DOMAIN, WEBSITE_NAME, WEBSITE_TITLE } from "../../../constants";

/** @jsx jsx */

export interface SecurityCardProps {
  security: Security;
  greenCard: GreenCard;
}

export function SecurityMeta({
  security,
  greenCard
}: SecurityCardProps) {

  if( ! security ) return <></>;

  let title = WEBSITE_TITLE + " - " + security.instrumentName + " - " + security.isin + " | " + WEBSITE_NAME;
  let url = WEBSITE_DOMAIN + securityDetailPageLink(security.isin, security.id);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href={url}/>
        <title>{title}</title>
        <meta property="og:url" content={url}/>
        <meta property="og:title" content={title}/>
        <meta name="twitter:title" content={title} />
      </Helmet>
    </HelmetProvider>
  );
}
