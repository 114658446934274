
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const BMU: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <rect y="85.334" fill="#D80027" width="512" height="341.337" />
            <rect y="85.334" fill="#F0F0F0" width="256" height="170.663" />
            <g>
                <polygon fill="#D80027" points="144,85.33 112,85.33 112,154.663 0,154.663 0,186.663 112,186.663 112,255.997 144,255.997 144,186.663 256,186.663 256,154.663 144,154.663 " />
                <polygon fill="#D80027" points="0,85.329 0,100.412 57.377,138.663 80,138.663 " />
            </g>
            <g>
                <polygon fill="#2E52B2" points="0,107.951 0,138.663 46.069,138.663 " />
                <polygon fill="#2E52B2" points="96,85.331 96,134.244 22.628,85.331 " />
            </g>
            <polygon fill="#D80027" points="256,85.329 256,100.412 198.623,138.663 176,138.663 " />
            <g>
                <polygon fill="#2E52B2" points="256,107.951 256,138.663 209.931,138.663 " />
                <polygon fill="#2E52B2" points="160,85.331 160,134.244 233.372,85.331 " />
            </g>
            <polygon fill="#D80027" points="0,85.329 0,100.412 57.377,138.663 80,138.663 " />
            <g>
                <polygon fill="#2E52B2" points="0,107.951 0,138.663 46.069,138.663 " />
                <polygon fill="#2E52B2" points="96,85.331 96,134.244 22.628,85.331 " />
            </g>
            <polygon fill="#D80027" points="256,85.329 256,100.412 198.623,138.663 176,138.663 " />
            <g>
                <polygon fill="#2E52B2" points="256,107.951 256,138.663 209.931,138.663 " />
                <polygon fill="#2E52B2" points="160,85.331 160,134.244 233.372,85.331 " />
            </g>
            <polygon fill="#D80027" points="0,255.997 0,240.915 57.377,202.663 80,202.663 " />
            <g>
                <polygon fill="#2E52B2" points="0,233.376 0,202.663 46.069,202.663 " />
                <polygon fill="#2E52B2" points="96,255.994 96,207.082 22.628,255.994 " />
            </g>
            <polygon fill="#D80027" points="256,255.997 256,240.915 198.623,202.663 176,202.663 " />
            <g>
                <polygon fill="#2E52B2" points="256,233.376 256,202.663 209.931,202.663 " />
                <polygon fill="#2E52B2" points="160,255.994 160,207.082 233.372,255.994 " />
            </g>
            <path fill="#F3F3F3" d="M332.058,189.214v81.623c0,39.76,103.884,39.76,103.884,0v-81.623H332.058z" />
            <path fill="#6DA544" d="M332.058,270.837L332.058,270.837c0,39.76,51.942,51.942,51.942,51.942s51.942-12.182,51.942-51.942 v0.001H332.058V270.837z" />
            <polygon fill="#A2001D" points="384,238.188 359.884,248.576 359.884,270.837 384,285.678 408.116,270.837 408.116,248.576 " />
            <rect x="359.88" y="226.312" fill="#338AF3" width="48.228" height="22.261" />
        </svg>
    </React.Fragment>
);