import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
    Text,
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import { style } from "./index.style";
import { Icon, Button, Card } from "@bdl-cmn-shared-packages-npm/design-system";
import {TradingItemCard} from "../TradingItemCard";

export interface WWDCoreValuesProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const WWDCoreValues: React.FunctionComponent<WWDCoreValuesProps> = (props: WWDCoreValuesProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const cardLeftButtonLabel: string = getFieldValue<string>(fields, 'cardLeftButtonLabel', '');
    const cardRightButtonLabel: string = getFieldValue<string>(fields, 'cardRightButtonLabel', '');
    const cardLeftButtonLink: string = getFieldValue<string>(fields, 'cardLeftButtonLink', '');
    const cardRightButtonLink: string = getFieldValue<string>(fields, 'cardRightButtonLink', '');


    return (
        <div css={style.container}>
            <div css={style.title}>
                <Text
                    field={fields && fields.title as SitecoreGenericTextField}
                />
            </div>
            <div css={style.cards}>
                <Card>
                    <div css={style.card}>
                        <div css={style.cardTitle}>
                            <Text
                                field={fields && fields.cardLeftTitle as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.heading}>
                            <Text
                                field={fields && fields.cardLeftHeading as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.contentTop}>
                            <Text
                                field={fields && fields.cardLeftContentTop as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.contentBottom}>
                            <Text
                                field={fields && fields.cardLeftContentBottom as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.actions}>
                            <Button iconRight={'chevron-right'} label={cardLeftButtonLabel} onClick={() => console.log('go to', cardLeftButtonLink)} />
                        </div>
                    </div>
                </Card>
                <div css={style.separator} />
                <Card>
                    <div css={style.card}>
                        <div css={style.cardTitle}>
                            <Text
                                field={fields && fields.cardRightTitle as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.heading}>
                            <Text
                                field={fields && fields.cardRightHeading as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.contentTop}>
                            <Text
                                field={fields && fields.cardRightContentTop as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.contentBottom}>
                            <Text
                                field={fields && fields.cardRightContentBottom as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={style.actions}>
                            <Button iconRight={'chevron-right'} label={cardRightButtonLabel} onClick={() => console.log('go to', cardRightButtonLink)} />
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};
