/** @jsx jsx */

import * as React from "react";

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import {
  Tab,
  Tabs,
} from "@bdl-cmn-shared-packages-npm/design-system/src/components/tabs/tabs.component";
import { css, jsx } from "@emotion/react";

import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from "../../interfaces";
import hexa from "../TimelineComponent/hexa.svg";
import { renderLink } from "../../utils/helper";
import { useState } from "react";

export interface MembershipApplicationTabsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const timelineBlocContentText = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
`;

const timelineBloc = css`
  position: relative;
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  min-height: 200px;
`;
const timelineBlocIndicator = css`
  width: 160px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
`;
const timelineBlocIndicatorHexa = css`
  height: 48px;
  width: 48px;
  margin-top: 64px;
  background: url(${renderLink(hexa)});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #354450;
  z-index: 2;
  font-weight: 800;
`;
const timelineBlocContent = css`
  width: calc(100% - 160px);
  margin-left: 160px;
  margin-top: 64px;
  margin-bottom: 64px;
  height: 100%;
`;
const timelineBlocContentSmall = css`
  width: 260px;
  margin-left: 160px;
  height: 100%;
`;
const timelineBlocContentFlex = css`
  width: calc(100% - 260px);
  margin-left: 24px;
  margin-top: 64px;
  margin-bottom: 64px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-right: 16px;
`;
const timelineBlocContentElem = css`
  width: 50%;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  padding-left: 8px;
  padding-right: 8px;
  color: #354450;
  a {
    color: #22aa5f;
  }
`;
const letter = css`
  font-weight: 800;
  font-size: 18px;
`;
const itemContent = css`
  margin-top: 4px;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
`;
const itemLink = css`
  line-height: 150%;
`;
const timelineBar = css`
  width: 1px;
  background-color: #c9e1e2;
  height: 100%;
  position: absolute;
`;
const timelineBarShort = css`
  width: 1px;
  background-color: #c9e1e2;
  height: calc(100% - 40px);
  position: absolute;
  margin-top: 40px;
`;
const timelineBlocParent = css`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  &:nth-child(odd) {
    background: #f0f5f5;
  }
`;
export const MembershipApplicationTabs: React.FunctionComponent<MembershipApplicationTabsProps> =
  (props: MembershipApplicationTabsProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const [selectedItems, setSelectedItems] = useState(0);
    const parentContainer = css`
      width: 100%;
      background-size: cover;
      margin-left: auto;
      margin-right: auto;
      padding-top: 80px;
      padding-bottom: 80px;
    `;
    const container = css`
      width: 100%;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    `;
    const isGreen: number = getFieldValue<number>(fields, "isGreen", 0);
    const timelineBlocContentTitle = css`
      font-weight: 800;
      font-size: 24px;
      line-height: 31px;
      color: ${isGreen ? "#22AA5F" : "#cb1234"};
      margin-bottom: 16px;
      padding-top: 56px;
    `;

    return (
      <div css={parentContainer}>
        <div css={container}>
          <Tabs>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {new Array(10).fill(0).map((arr: any, index: number) => {
                const number: string = getFieldValue<string>(
                  fields,
                  `row ${index + 1} number`,
                  ""
                );
                const label: string = getFieldValue<string>(
                  fields,
                  `row ${index + 1} label`,
                  ""
                );
                const item1: string = getFieldValue<string>(
                  fields,
                  `row ${index + 1} item 1`,
                  ""
                );
                const item2: string = getFieldValue<string>(
                  fields,
                  `row ${index + 1} item 2`,
                  ""
                );
                const item3: string = getFieldValue<string>(
                  fields,
                  `row ${index + 1} item 3`,
                  ""
                );
                const item4: string = getFieldValue<string>(
                  fields,
                  `row ${index + 1} item 4`,
                  ""
                );
                const item5: string = getFieldValue<string>(
                  fields,
                  `row ${index + 1} item 5`,
                  ""
                );
                const item6: string = getFieldValue<string>(
                  fields,
                  `row ${index + 1} item 6`,
                  ""
                );
                const item7: string = getFieldValue<string>(
                  fields,
                  `row ${index + 1} item 7`,
                  ""
                );
                const item8: string = getFieldValue<string>(
                  fields,
                  `row ${index + 1} item 8`,
                  ""
                );
                const itemList = [
                  item1,
                  item2,
                  item3,
                  item4,
                  item5,
                  item6,
                  item7,
                  item8,
                ];
                if (label) {
                  return (
                    <div css={timelineBlocParent}>
                      <div css={timelineBloc}>
                        <div css={timelineBlocIndicator}>
                          <div css={timelineBar} />
                          <div css={timelineBlocIndicatorHexa}>{number}</div>
                        </div>
                        <div css={timelineBlocContentSmall}>
                          <div css={timelineBlocContentTitle}>{label}</div>
                        </div>
                        <div css={timelineBlocContentFlex}>
                          {new Array(8)
                            .fill(0)
                            .map((arr: any, index: number) => {
                              if (itemList[index] !== "") {
                                return (
                                  <div css={timelineBlocContentElem}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: itemList[index],
                                      }}
                                    />
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </Tabs>
        </div>
      </div>
    );
  };
