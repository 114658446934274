
import i18n from 'i18next';
import fetchBackend from 'i18next-fetch-backend';

import { initReactI18next   } from 'react-i18next';

import config from '../temp/config';

export function i18nInit(language?: string, dictionary?: any): Promise<any> {
    return new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void): void => {
        const initOptions: i18n.InitOptions & { useCookie: boolean } = {
            debug: false,
            lng: language,
            fallbackLng: false,
            load: 'currentOnly',
            interpolation: {
                escapeValue: false
            },
            useCookie: false
        };

        if (dictionary && language) {
            initOptions.resources = {
                [ language ]: { translation: dictionary }
            };

            i18n
                .use(initReactI18next)
                .init(initOptions, (error: any): void => {
                    // get from base sitecore jss app code
                    if(error) { console.log(error); }

                    resolve();
                });
        }
        else {
            //const dictionaryServicePath = `${(process.env.SITECORE_API_HOST || config.sitecoreApiHost)}/sitecore/api/jss/dictionary/${(process.env.SITECORE_JSS_APP_NAME || config.jssAppName)}/{{lng}}?sc_apikey=${(process.env.SITECORE_API_KEY || config.sitecoreApiKey)}&sc_site=bdl-port-luxse-ssr`;

            try {
                initOptions.backend = {
                    //loadPath: dictionaryServicePath,
                    parse: (data: any): any => {
                        const parsedData = JSON.parse(data);

                        return parsedData?.phrases ? parsedData.phrases : parsedData;
                    }
                };

                i18n
                    //.use(fetchBackend)
                    .use(initReactI18next)
                    .init(initOptions, (error: any): void => {
                        // get from base sitecore jss app code
                        if(error) { console.log(error); }

                        resolve();
                    });
            } catch (err) {
                console.log('I18N ERR', err)
            }
        }
    });
}
