import { css } from "@emotion/react";
import hexa from './Icon.svg';
import {renderLink} from "../../../../../../../utils/helper";
export const style = {
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0 0;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    border: 1px solid #D9DEE2;
    background-color: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 16px;
  `,
  top: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px!important;
    height: 110px;
    color: #22AA5F;
    font-size: 32px;
    background: url(${renderLink(hexa)});
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 60px;
  `,
  bottom: css`
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #253845;
    margin-top: 32px;
    margin-bottom: 80px;
  `,
};
