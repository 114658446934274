import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Icon, Table, Td, Th, Thead, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import { sharedStyle } from '../../../../../../styles/shared';

export const LuxXPrimeHeader = () => {
  return (
    <Thead>
      <Tr>
        <Th css={sharedStyle.tableNewSecurity}>Security</Th>
        <Th css={sharedStyle.listingDateTh}>Maturity</Th>
        <Th css={sharedStyle.bidTh}>Yield</Th>
        <Th css={sharedStyle.askTh}>Price/Vari.(24h)</Th>
      </Tr>
    </Thead>
  );
};
export default LuxXPrimeHeader;
