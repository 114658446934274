import { css, jsx } from "@emotion/react";

import Props from "../../UI/list/base/props";
import React from "react";
import { formatDate } from "../../../../utils/date";
import { formatToThousandsSeparator } from "../../../../utils";
import { goToSecurityDetailPage } from "../../SecurityCard";
import { style } from "../../Index/Index-details/index.style";

/** @jsx jsx */

export const RenderType = ({ label }: any) => {
  return label ? <div css={style.market}>{label}</div> : <div>"N/A"</div>;
};

const MarketNewsGenericMobileRow = ({ row }: Props<any>) => {

  if( ! row ) return <></>;

  const clickable = ["ADMI", "COTE", "NEGO", "FONG"].includes(row?.status);

  return (
    <div
      css={{
        width: "100%", 
        background: clickable ? "#ffffff" : "#e8ecee",
        cursor: clickable ? "pointer" : "default"
      }}
      onClick={() => {
        if (clickable)
          goToSecurityDetailPage(row?.isinCode, row?.id);
      }}
    >
      <div
        css={css`
          border: 1px solid #d9dee2;
          border-radius: 8px;
          filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
          margin-bottom: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-bottom: solid 1px #d9dee2;
            padding: 16px;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 8px;
            `}
          >
            <RenderType label={row?.label} />
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            ISIN
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            {row?.isinCode}
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            Security
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            {row?.name}
            {row.amount ? (
              <>
                {` | ${row?.currency} ${formatToThousandsSeparator(
                  row?.amount
                )}`}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            Status
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            <div>{row.statusLabel}</div>
            <div>{formatDate(row?.date, "DD/MM/YYYY")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketNewsGenericMobileRow;
