import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from './graphCard.style';
import ReactApexChart from 'react-apexcharts';
import { useState } from 'react';
import { parseToMoment } from '../../../utils/date';

export interface GraphCardProps {
  fields?: any;
  noBorder?: boolean;
  noMargin?: boolean;
  onSwitchMenu?: () => void;
  data?: any;
  noDataLabel?: boolean;
  floatingData?: boolean;
  label?: string;
  noLabel?: boolean;
  heightChart?: number;
  chartOptions?: any;
  noChartGradient?: boolean;
}

const smoothCurve = (prices: any[]): number[] => {
  let series: number[] = [];

  if( !prices?.length )
    return series;

  const lastDate = prices[prices.length - 1]?.date;

  if( !lastDate )
    return series;

  const lastPriceDate = parseToMoment(lastDate);
  const lastMonthDate = parseToMoment(lastDate).subtract(6, 'months');

  prices.forEach((price: any): void => {
    const priceDate = parseToMoment(price?.date);

    if (priceDate?.isSameOrAfter(lastMonthDate) && priceDate?.isSameOrBefore(lastPriceDate)) {
      series = [...series, parseFloat(price.amount)];
    }
  });

  return series;
};

const getDailyChange = (data: any): number => {
  if (data?.dailyChange) return data.dailyChange;

  if (data?.lastPrice?.changePercent) return data.lastPrice.changePercent;

  return null;
};

const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

const GraphCard: React.FunctionComponent<GraphCardProps> = ({
  fields,
  onSwitchMenu,
  noBorder = false,
  noMargin = false,
  label,
  noLabel = false,
  data,
  noDataLabel = false,
  floatingData = false,
  heightChart = 0,
  noChartGradient = false,
  chartOptions,
}: GraphCardProps) => {
  const [isSearch, setIsSearch] = useState(false);
  const [dailyChange, setDailyChange] = useState(null);

  return (
    <div css={[style.graphCard, noBorder && style.graphCardNoBorder, noMargin && style.graphCardNoMargin]}>
      {!noLabel && (
        <div css={style.labels}>
          <div css={style.labelsTop}>{label}</div>
          <div css={style.labelsBottom}>Index</div>
        </div>
      )}
      {data ? (
        <div css={[style.data, floatingData && style.dataFloating]}>
          {getDailyChange(data) ? (
            <div css={style.dataTop} style={{ color: getDailyChange(data) < 0 ? '#CB1234' : '#22AA5F' }}>
              {getDailyChange(data) >= 0 && '+'} {getDailyChange(data)} %
            </div>
          ) : (
            <></>
          )}
          <div css={style.dataBottom}>{data?.lastPrice?.amount}</div>
        </div>
      ) : (
        <></>
      )}
      <div
        css={[
          style.charts,
          heightChart === 0 && style.chartsMaxHeight,
          noLabel && style.chartsNoLabel,
          noLabel && floatingData && style.chartsFullWidth,
        ]}
      >
        {!noChartGradient && <div css={style.chartGradient} />}
        {canUseDOM && data?.pricesHistory.length > 0 ? (
          <ReactApexChart
            options={chartOptions}
            series={[
              {
                name: 'price',
                data: smoothCurve(data?.pricesHistory),
              },
            ]}
            type='area'
            height={heightChart != 0 ? heightChart : '100%'}
          />
        ) : noDataLabel ?
            <></>
          : <div>no Data</div>
        }
      </div>
    </div>
  );
};

export default GraphCard;
