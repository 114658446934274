import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {canUseDOM} from "../../../predicates";
import {breakpointMax} from "../../../style";
import XTwitter from "../../icons/x-twitter";

export interface SocialSharingProps {
    url?: string;
    quote?: string;
}

const container = css`
  display: flex;
  align-self: flex-end;
  margin-left: 32px;
  max-width: 200px;
  svg {
    margin-right:16px;
    color: #22AA5F;

    &:hover {
      text-decoration: none;
      color: #1A844A;
    }
  }
  
  div {
    cursor: pointer;
    color: #22AA5F;
  }
  @media (max-width: ${breakpointMax}) {
    margin-left: 0;
  }
`;
const SocialSharing = ({
                           url,
                           quote,
}: any) => {

    const [copied, setCopied] = React.useState<boolean>(false);
    return (
        <div css={container}>
            <TwitterShareButton url={url} title={quote}><XTwitter style={{
                  fill: "#22AA5F",
                  fontSize: "14px",
                  verticalAlign: "middle",
            }} /></TwitterShareButton>
            <LinkedinShareButton url={url} title={quote}><FontAwesomeIcon icon={["fab", 'fa-linkedin'] as any}/></LinkedinShareButton>
            <WhatsappShareButton url={url} title={quote}><FontAwesomeIcon icon={["fab", 'fa-whatsapp'] as any} /></WhatsappShareButton>
            <FacebookShareButton url={url} quote={quote}><FontAwesomeIcon icon={["fab", 'fa-facebook-f'] as any} /></FacebookShareButton>
            <div onClick={() => {
                canUseDOM && navigator.clipboard.writeText(url);
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            }}>
                {copied ? <div>copied!</div>: <FontAwesomeIcon icon={["far", 'fa-link'] as any} />}</div>
        </div>
    );
};

export default SocialSharing;
