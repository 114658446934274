
/** @jsx jsx */

import * as React from 'react';

import {
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    RichText,
    Text,
    getFieldValue,
    Link
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import {Button} from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from '../../interfaces';

export interface TextWithButtonActionComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const action = css`
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: ${breakpointMax}) {
   button {
     width: 100%;
   }
  }
`;

const title = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  color:  #425563;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 24px;
  }
`;
const content = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color:  #425563;
  margin-top: 46px;
  margin-bottom: 46px;
  @media (max-width: ${breakpointMax}) {  
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
`;

export const TextWithButtonActionComponent: React.FunctionComponent<TextWithButtonActionComponentProps> = (props: TextWithButtonActionComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const isCentered: number = getFieldValue<number>(fields, 'isCentered', 0);
    const btnLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
    const isBackground: number = getFieldValue<number>(fields, 'greyBackground', 0);
    const noMarginTop: number = getFieldValue<number>(fields, 'no margin top', 0);
    const noMarginBottom: number = getFieldValue<number>(fields, 'no margin bottom', 0);

    const parentContainer = css`
      width: 100%;
      background: ${isBackground ? '#F0F4F4' : 'transparent'};
      margin-left: auto;
      margin-right: auto;
      padding-top: ${noMarginTop ? '0' : '80px'};
      padding-bottom: ${noMarginBottom ? '0' : '80px'};
      @media (max-width: ${breakpointMax}) {
       padding-top: 40px;
        padding-bottom: 40px;
      }
    `;
    const container = css`
      width: 100%;
      max-width: ${isCentered ? '1200px' : '100%'};
      margin-left: auto;
      margin-right: auto;
      @media (max-width: ${breakpointMax}) {
        margin: 0;
        padding-left: 16px;
        padding-right: 16px;
      }
    `;
    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={content}>
                    <RichText
                        field={fields && fields.content as SitecoreGenericTextField}
                    />
                </div>
                {
                    btnLabel && (
                        <div css={action}>
                            <Link target={(fields.btnLink?.value?.linktype === "external" ? "_blank" : "_self")} title={btnLabel} field={fields['btnLink']} css={{textDecoration: 'none'}}>
                                <Button label={btnLabel} iconLeft={'paper-plane'} />
                            </Link>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
