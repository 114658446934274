
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import {SearchInputContent} from "../../core/components/Search/input";

export const SearchInputHeader: React.FunctionComponent = (): React.ReactElement => {
    return (
        <SearchInputContent />
    );
}
    