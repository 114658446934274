import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {Button, Icon} from "@bdl-cmn-shared-packages-npm/design-system";
import hexa from "../ListingProcess/hexa.svg";
import {renderLink} from "../../utils/helper";

export interface BackgroundWithActionsProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}
export const  BackgroundWithActions: React.FunctionComponent<BackgroundWithActionsProps> = (props: BackgroundWithActionsProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const background: any = getFieldValue<any>(fields, 'background', 0);
    const button1Label: string = getFieldValue<string>(fields, 'button1Label', '');
    const button2Label: string = getFieldValue<string>(fields, 'button2Label', '');
    const button1Link: string = getFieldValue<string>(fields, 'button1Link', '');
    const button2Link: string = getFieldValue<string>(fields, 'button2Link', '');


    const style = {
        parentContainer: css`
      display: flex;
      flex-direction: column;
      background: url(${renderLink(background && background.src)}) no-repeat;
      background-size: cover;
      width: 100%;
    `,
        container: css`
      display: flex;
          flex-direction: column;
      width: 100%;
      height: 100%;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      margin: 60px auto;
      justify-content: center;
    `,
        title: css`
        font-weight: 800;
        font-size: 36px;
        color: #FFFFFF;
        margin-bottom: 24px;
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
    `,
        subtitle: css`
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: #FFFFFF;
    `,
        actions: css`
      width: 100%;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 64px;
      display: flex;
      
      button {
        margin-left: 8px;
        margin-right: 8px;
      }
    `,
    }

    return (
        <div css={style.parentContainer}>
            <div css={style.container}>
                <div css={style.title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={style.subtitle}>
                    <Text
                        field={fields && fields.subtitle as SitecoreGenericTextField}
                    />
                </div>
                <div css={style.actions}>
                    <Button label={button1Label} onClick={() => button1Link ? window.location.href = button1Link : console.debug} />
                    <Button label={button2Label} onClick={() => button2Link ? window.location.href = button2Link : console.debug} variant={"thirdary"} wired={true} />
                </div>
            </div>
        </div>
    );
}

