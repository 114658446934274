import React, { useEffect, useState } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";
import ChinaGraph from "../../ChinaGraph";
import { style } from "./index.style";
import Loader from "../../Loader/loader.component";
import { SEARCH_INDEXES_PRICE_WITHOUT_FILTERS } from "../../../../graphql";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { IndiceSummary } from "../../../../interfaces/indice";
import Utils from "../../../utils/utils";

export function ChinaIndexes({ indexesIds = [] }: { indexesIds: string[] }) {
  const client = luxseGatewayGraphqlClient;

  const [indexes, setIndexes] = useState<IndiceSummary[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchIndexesByIds = async () => {
    let indexesTmp = [];
    try {
      const result: any = await client.query({
        query: SEARCH_INDEXES_PRICE_WITHOUT_FILTERS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: indexesIds?.length,
          page: 0,
          sort: "latestPriceDate,asc",
          ids: indexesIds,
          idsOperator: "OR",
        },
      });

      if (result.data?.luxseIndexesSearch?.indexes) {
        indexesTmp = result.data.luxseIndexesSearch.indexes;
      }
    } catch (e) {
      console.error("Failed to Get indices", e);
    }

    setIndexes(indexesTmp);
    setLoading(false);
  };

  useEffect(() => {
    fetchIndexesByIds();
  }, []);

  return (
    <div css={style.graph}>
      <Loader centered loading={loading}>
        <>
          {indexes?.map((indice) => {
            return indice ? (
              <ChinaGraph
                key={`graph-china-index-${indice.id}`}
                id={indice.id}
                data={indice.indexPrice}
                label={indice.indexName}
              />
            ) : (
              <></>
            );
          })}
        </>
      </Loader>
    </div>
  );
}

export default ChinaIndexes;
