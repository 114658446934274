import axios from "axios";
import {getUrlByEnv} from "./helper";
const instance = axios.create();
///blog-data/search/:params

const fetchBlogData = async(params: any) => {
    const url = `${getUrlByEnv()}/blog-data/search/${params}`;
    try {
        const result: any = await instance.get(url, {timeout: 0});
        const { data } = result;
        return data;
    } catch(err) {
        console.error('blog request api error =>', err);
        return false;
    }
}

export const getArticle = async (id: string) => {
    //const {authors, categories, tags} = props;
    const url = `${getUrlByEnv()}/blog-data/articles/${id}`;

    try {
        const result: any = await instance.get(url, {timeout: 0});
        const { data } = result;
        return data;
    } catch(err) {
        console.error('helper request api error =>', err);
        return false;
    }
}
export const getAuthors = async () => {
    //const {authors, categories, tags} = props;
    const url = `${getUrlByEnv()}/blog-data/search/authors`;

    try {
        const result: any = await instance.get(url, {timeout: 0});
        const { data } = result;
        return data;
    } catch(err) {
        console.error('helper request api error =>', err);
        return false;
    }
}
export const getArticles = async (params? : any) => {
    //const {authors, categories, tags} = props;
    const url = `${getUrlByEnv()}/blog-data/search/articles${params || ''}`;

    try {
        const result: any = await instance.get(url, {timeout: 0});
        const { data } = result;
        return data;
    } catch(err) {
        console.error('helper request api error =>', err);
        return false;
    }
    /*const url = `https://grp-weu-dev-scor-cm.apps.dev.luxse.cloud/sitecore/api/search/`;
    try {
        const result: any = await instance.get(url, {
            params: {
                //authors,
                //categories,
                //tags,
                sortascending: false,
            }
        });
        console.clear();

        console.log('response', result)
        //const { data } = result;
        return result;
    } catch(err) {
        console.error('helper request api error =>', err);
        return false;
    }*/
}
//http://localhost:50800/sitecore/api/search/articles?
// authors=Julie Vichy&
// categories=finance,bond&
// tags=Tag 1&
// sortby=views&
// sortascending=true&
// skip=0&
// take=3