import { breakpoint, breakpointMax } from '../../style';

import { css } from '@emotion/react';

export const style = {
  container: css`
    width: 100%;
    position: relative;
    padding-bottom: 48px;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    margin: auto auto;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    @media (max-width: ${breakpointMax}) {
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 16px;
      padding-right: 16px;
      margin: 0;
    }
  `,
  title: css`
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    text-align: center;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 32px;
      line-height: 31px;
      text-align: center;
    }
  `,
  subTitle: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 31px;
    color: #425563;
    margin-top: 24px;
    text-align: center;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.01em;
      text-align: center;
    }
  `,
  cards: css`
    display: flex;
    width: 100%;
    margin: 80px auto auto;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: ${breakpointMax}) {
      padding-top: 40px;
      padding-bottom: 40px;
      margin: 0;
    }
  `,
  card: css`
    display: flex;
    max-width: 260px;
    width: 100%;
    min-height: 100%;
    flex-direction: column;
    margin: 12px;

    > div {
      height: 100%;
    }

    @media (max-width: ${breakpointMax}) {
      max-width: 100%;
      margin: 0;
      margin-bottom: 24px;
    }
  `,
  iconCard: css`
    display: flex;
    color: #cb1234;
    margin-bottom: 16px;
    height: 50px;
    width: 46px;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    @media (max-width: ${breakpointMax}) {
      margin-bottom: 16px;
      background-size: cover;
      height: 60px;
      width: 60px;
    }
  `,
  iconCardGreen: css`
    display: flex;
    color: #008c46;
    margin-bottom: 16px;
    height: 50px;
    width: 46px;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    @media (max-width: ${breakpointMax}) {
      margin-bottom: 16px;
      background-size: cover;
      height: 60px;
      width: 60px;
    }
  `,
  iconCardSvg: css`
    position: absolute;
    margin-top: -36px;
    margin-left: 14px;
  `,
  titleCard: css`
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #354450;
    margin-top: 16px;
    margin-bottom: 40px;
    @media (max-width: ${breakpointMax}) {
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0.01em;
    }
  `,
  textCard: css`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.01em;
    }
  `,
  actions: css`
    width: 100%;
    justify-content: center;
    margin-top: 80px;
    display: flex;

    button {
      &:last-of-type {
        margin-left: 16px;
      }
    }

    @media (max-width: ${breakpointMax}) {
      margin-top: 0;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      height: 120px;
  }
      button {
        &:last-of-type {
          margin: 0;
        }
      }
    }
  `,
  actionCard: css`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    span {
      margin-right: 8px;
    }
    border-radius: 0.5em;
    cursor: pointer;
    color: #22AA5F;
  `,
  background: css`
    background: linear-gradient(180deg, #a4bcc2 0%, rgba(164, 188, 194, 0) 100%);
    opacity: 0.15;
  `,
};
