/** @jsx jsx */

import * as React from 'react';

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { Button } from '@bdl-cmn-shared-packages-npm/design-system';
import { SitecoreGenericTextField } from '../../interfaces';
import hexa from '../TimelineComponent/hexa.svg';
import { renderLink } from '../../utils/helper';

export interface TimelineRowProps {
  fields: any;
  isGreen: boolean;
  params?: ComponentParams;
  rendering: ComponentRendering;
}
const timelineBlocContentText = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-size: 14px;
    line-height: 180%;
  }
  li {
    margin-bottom: 16px;
    margin-left: 16px;
  }
`;
const action = css`
  display: flex;
  margin-top: 24px;
`;

const timelineBloc = css`
  position: relative;
  width: 100%;
  max-width: 933px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  min-height: 200px;
  align-items: center;
`;
const timelineBlocIndicator = css`
  width: 160px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: baseline;
  @media (max-width: ${breakpointMax}) {
    width: 100px;
  }
`;
const timelineBlocIndicatorLast = css`
  width: 160px;
  height: 50%;
  top: 0;
  display: flex;
  justify-content: center;
  position: absolute;

  @media (max-width: ${breakpointMax}) {
    width: 100px;
  }
`;
const timelineBlocIndicatorFirst = css`
  width: 160px;
  height: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: baseline;
  @media (max-width: ${breakpointMax}) {
    width: 100px;
  }
`;
const timelineBlocIndicatorHexa = css`
  font-weight: bold;
  height: 48px;
  width: 48px;
  background: url(${renderLink(hexa)});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #354450;
  z-index: 2;
`;
const timelineBlocIndicatorHexaFirst = css`
  font-weight: bold;
  height: 48px;
  width: 48px;
  margin-top: 0;
  background: url(${renderLink(hexa)});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #354450;
  z-index: 2;
`;
const timelineBlocContent = css`
  width: calc(100% - 160px);
  margin-left: 160px;
  margin-bottom: 64px;
  height: 100%;
  align-self: flex-start;
  @media (max-width: ${breakpointMax}) {
    width: calc(100% - 100px);
    margin-left: 100px;
  }
`;
const timelineBar = css`
  width: 1px;
  background-color: #c9e1e2;
  height: calc(100% + 26px);
  position: absolute;
  z-index: 1;
`;
const timelineBarShort = css`
  width: 1px;
  background-color: #c9e1e2;
  height: calc(100% - 40px);
  position: absolute;
  margin-top: 40px;
`;
const timelineBlocParent = css`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  &:nth-child(odd) {
    background: #f8fafc;
  }
  @media (max-width: ${breakpointMax}) {
    padding-right: 16px;
  }
`;

export const TimelineRow: React.FunctionComponent<TimelineRowProps> = (props: TimelineRowProps): React.ReactElement => {
  const { fields } = props;
  const isGreen: number = getFieldValue<number>(fields, 'isGreen', 0);
  const isLast: number = getFieldValue<number>(fields, 'isLast', 0);
  const isFirst: number = getFieldValue<number>(fields, 'isFirst', 0);
  const number: string = getFieldValue<string>(fields, 'number', '...');
  const buttonLabel: string = getFieldValue<string>(fields, 'buttonLabel', '');
  const buttonLink: string = getFieldValue<string>(fields, 'buttonLink', '');
  const buttonIcon: string = getFieldValue<string>(fields, 'buttonIcon', '');
  const timelineBlocContentTitle = css`
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    color: ${isGreen ? '#008C46' : '#cb1234'};
    margin-bottom: 16px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
    }
  `;

  return (
    <div css={timelineBlocParent} className={'parent'}>
      <div css={timelineBloc}>
        <div css={isLast ? timelineBlocIndicatorLast : isFirst ? timelineBlocIndicatorFirst : timelineBlocIndicator}>
          <div css={timelineBar} className={'indicator'} style={{display:isLast?'none':'inherit'}}/>
          <div css={isFirst ? timelineBlocIndicatorHexaFirst : timelineBlocIndicatorHexa} className={`index`}>
            {number}
          </div>
        </div>
        <div css={timelineBlocContent}>
         {(fields && fields.title && Boolean(fields.title.value)) && <div css={timelineBlocContentTitle}>
            <Text field={fields && (fields.title as SitecoreGenericTextField)} />

          </div>}
          <div css={timelineBlocContentText}>
            <RichText field={fields && (fields.description as SitecoreGenericTextField)} />
          </div>
          {buttonLabel && buttonLink && (
            <div css={action}>
              <Button
                label={buttonLabel}
                onClick={() => (buttonLink ? (window.location.href = buttonLink) : console.log)}
                iconRight={buttonIcon as any}
                variant={'primary'}
              />
            </div>
          )}
          {fields &&
            fields.documents &&
            !!fields.documents.length &&
            fields.documents.map((document: any, index: number) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ border: 'solid 1px', height: '500px', marginBottom: 30, width: '100%' }}>
                  {document.documentImage}
                </div>
                <div>{document.documentLink}</div>
                <div>{document.documentTitle}</div>
                <div>{document.documentDescription}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
