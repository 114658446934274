import { css } from "@emotion/react";

export const style = {
  container: css`
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
    margin-bottom: 48px;

    .apexcharts-zoomin-icon,
    .apexcharts-zoomout-icon,
    .apexcharts-zoom-icon,
    .apexcharts-pan-icon,
    .apexcharts-selected {
      border: solid 1px #d1d5d8;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px !important;
      height: 37px !important;
      transform: scale(1) !important;
      margin-right: 8px;

      svg {
        width: 14px !important;
      }
    }
    .apexcharts-reset-icon,
    .apexcharts-menu-icon {
      display: none;
    }
    .apexcharts-toolbar {
      top: -35px !important;
    }
    .apexcharts-zoom-icon {
      svg {
        width: 22px !important;
      }
    }
  `,
};
