import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import { Td, Tr} from "@bdl-cmn-shared-packages-npm/design-system";
import {formatDate} from "../../../../../../utils/date";
import { goToSecurityDetailPage } from "../../../../SecurityCard";
import { style } from "../index.style";
import {EMPTY_DATA_PLACEHOLDER} from "../../../../../constants";
import SearchSecurity from "../../../../../../interfaces/search/search-security";
import PriceColumn from "../../../../UI/list/security/price";

export const LgxSustainableBondsRow = ({row} : {row : SearchSecurity}) => {
    if(!row)
        return <></>;

    return (
        <Tr onRowClick={() => goToSecurityDetailPage(row.isinCode, row.id)}>
            <Td css={[style.title, css`font-weight: bold`]}>{row.name}</Td>
            <Td css={style.title}>{formatDate(row.finalMaturityDate)}</Td>
            <Td css={style.title}>
                {
                    row.marketData?.lastPrice?.changePercent &&
                    row.marketData?.lastPrice?.changePercent + "%" 
                    || EMPTY_DATA_PLACEHOLDER
                }
            </Td>
            <Td css={style.title}>
                {   
                    row.marketData?.lastPrice?.changePercent  &&
                        <PriceColumn {...row.marketData?.lastPrice} showDate={false} showChange={true}/>
                    || EMPTY_DATA_PLACEHOLDER
                }
            </Td>
        </Tr>
    )
}
export default LgxSustainableBondsRow;