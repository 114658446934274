import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  RichText,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { SitecoreGenericTextField } from "../../interfaces";

const lint = jsx; // linter hack

export interface TwoColsBlocWithTitleAndSubTitleProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const TwoColsBlocWithTitleAndSubTitle: React.FunctionComponent<TwoColsBlocWithTitleAndSubTitleProps> =
  (props: TwoColsBlocWithTitleAndSubTitleProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const noMarginTop: number = getFieldValue<number>(fields, "noMarginTop", 0);

    const style = {
      parentContainer: css`
        display: flex;
        flex-direction: column;
        background: #ffffff;
        justify-content: center;
        width: 100%;
        padding: 80px;
        ${noMarginTop && `padding-top: 0`};
      `,
      container: css`
        display: flex;
        max-width: 1000px;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      `,
      containerTexts: css`
        display: flex;
        flex-direction: column;
        max-width: 1000px;
        justify-content: center;
        //margin: auto;
      `,
      containerTextsTitle: css`
        font-weight: 800;
        font-size: 48px;
        line-height: 61px;
        color: #354450;
        margin-bottom: 34px;
      `,
      containerTextsContent: css`
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 64px;
        color: #425563;
      `,
      author: css`
        color: #ffffff;
        font-weight: 800;
        font-size: 16px;
        margin-top: 32px;
      `,
      role: css`
        color: #ffffff;
        font-weight: 800;
        font-size: 16px;
        margin-top: 8px;
      `,
      actions: css`
        margin-top: 16px;
      `,
      cols: css`
        width: 100%;
        display: flex;
      `,
      col: css`
        display: flex;
        width: 50%;
        flex-direction: column;
        padding-right: 24px;
      `,
      colTitle: css`
        font-weight: 800;
        font-size: 24px;
        line-height: 31px;
        color: #354450;
        margin-bottom: 32px;
      `,
      colDescription: css`
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #354450;
      `,
    };

    return (
      <div css={style.parentContainer}>
        <div css={style.container}>
          <div css={style.containerTexts}>
            <div css={style.containerTextsTitle}>
              <Text
                field={fields && (fields.title as SitecoreGenericTextField)}
              />
            </div>
            <div css={style.containerTextsContent}>
              <RichText
                field={fields && (fields.subTitle as SitecoreGenericTextField)}
              />
            </div>
            <div css={style.cols}>
              <div css={style.col}>
                <div css={style.colTitle}>
                  <Text
                    field={fields["column 1 title"] as SitecoreGenericTextField}
                  />
                </div>
                <div css={style.colDescription}>
                  <Text
                    field={
                      fields["column 1 content"] as SitecoreGenericTextField
                    }
                  />
                </div>
              </div>
              <div css={style.col}>
                <div css={style.colTitle}>
                  <Text
                    field={fields["column 2 title"] as SitecoreGenericTextField}
                  />
                </div>
                <div css={style.colDescription}>
                  <Text
                    field={
                      fields["column 2 content"] as SitecoreGenericTextField
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
