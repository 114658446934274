import * as React from "react";
import { css, jsx } from "@emotion/react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { WEBSITE_DOMAIN, WEBSITE_NAME, WEBSITE_TITLE } from "../../../../constants";
import { IndiceSummary } from "../../../../../interfaces/indice";
import { indexDetailPageLink } from "..";

/** @jsx jsx */

interface IndexCardProps {
  indice: IndiceSummary;
}

export function IndexMeta({indice}: IndexCardProps) {

  if( ! indice ) return <></>;

  let title = WEBSITE_TITLE + " - " + indice.indexName + " - " + indice.isin + " | " + WEBSITE_NAME;
  let url = WEBSITE_DOMAIN + indexDetailPageLink(indice.id);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href={url}/>
        <title>{title}</title>
        <meta name="description" content={indice.indexName}/>
        <meta property="og:url" content={url}/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={indice.isin}/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={indice.isin}/>
      </Helmet>
    </HelmetProvider>
  );
}
