import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from './index.style';
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid, Col, Row, Icon } from '@bdl-cmn-shared-packages-npm/design-system';

import { SitecoreGenericTextField } from '../../interfaces';
import { SquareLink } from '../SquareLink';
import { ConceptualBannerProps } from '../../core/components/ConceptualBanner/conceptualBanner.component';
import { useLocation, useHistory } from 'react-router-dom';
export interface UsefullLinksProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
  noMarginTop?: boolean;
}

export interface LinkProps {
  link?: string;
  title?: string;
}
export const SquareLinkTmp: React.FunctionComponent<LinkProps> = ({ link, title }) => {
  const history = useHistory();
  return (
    <div css={style.linkContainer} onClick={() => (link ? (window.location.href = link) : console.log)}>
      <div css={style.linkText}>{title || 'url n/a'}</div>
      <div css={style.linkIcon}>
        <Icon icon={'chevron-right'} />
      </div>
    </div>
  );
};

export const UsefullLinks: React.FunctionComponent<UsefullLinksProps> = (
  props: UsefullLinksProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const link1Value: string = getFieldValue<string>(fields, 'link1', '');
  const link1TitleValue: string = getFieldValue<string>(fields, 'link1Title', '');
  const link2Value: string = getFieldValue<string>(fields, 'link2', '');
  const link2TitleValue: string = getFieldValue<string>(fields, 'link2Title', '');
  const link3Value: string = getFieldValue<string>(fields, 'link3', '');
  const link3TitleValue: string = getFieldValue<string>(fields, 'link3Title', '');
  const link4Value: string = getFieldValue<string>(fields, 'link4', '');
  const link4TitleValue: string = getFieldValue<string>(fields, 'link4Title', '');
  const link5Value: string = getFieldValue<string>(fields, 'link5', '');
  const link5TitleValue: string = getFieldValue<string>(fields, 'link5Title', '');
  const link6Value: string = getFieldValue<string>(fields, 'link6', '');
  const link6TitleValue: string = getFieldValue<string>(fields, 'link6Title', '');
  const link7Value: string = getFieldValue<string>(fields, 'link7', '');
  const link7TitleValue: string = getFieldValue<string>(fields, 'link7Title', '');
  const link8Value: string = getFieldValue<string>(fields, 'link8', '');
  const link8TitleValue: string = getFieldValue<string>(fields, 'link8Title', '');
  return (
    <div css={style.container}>
      <div css={style.containerBackground}>
        <div css={style.containerBackgroundHexa} />
      </div>
      <div css={style.content}>
        <div css={style.contentTitle}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={style.subContent}>
          <Grid>
            <Row>
              {link1Value && link1TitleValue && (
                <Col mobile={12} tablet={6} desktop={6}>
                  <SquareLinkTmp link={link1Value} title={link1TitleValue} />
                </Col>
              )}
              {link2Value && link2TitleValue && (
                <Col mobile={12} tablet={6} desktop={6}>
                  <SquareLinkTmp link={link2Value} title={link2TitleValue} />
                </Col>
              )}
            </Row>
            <Row>
              {link3Value && link3TitleValue && (
                <Col mobile={12} tablet={6} desktop={6}>
                  <SquareLinkTmp link={link3Value} title={link3TitleValue} />
                </Col>
              )}
              {link4Value && link4TitleValue && (
                <Col mobile={12} tablet={6} desktop={6}>
                  <SquareLinkTmp link={link4Value} title={link4TitleValue} />
                </Col>
              )}
            </Row>
            <Row>
              {link5Value && link5TitleValue && (
                <Col mobile={12} tablet={6} desktop={6}>
                  <SquareLinkTmp link={link5Value} title={link5TitleValue} />
                </Col>
              )}
              {link6Value && link6TitleValue && (
                <Col mobile={12} tablet={6} desktop={6}>
                  <SquareLinkTmp link={link6Value} title={link6TitleValue} />
                </Col>
              )}
            </Row>
            <Row>
              {link7Value && link7TitleValue && (
                <Col mobile={12} tablet={6} desktop={6}>
                  <SquareLinkTmp link={link7Value} title={link7TitleValue} />
                </Col>
              )}
              {link8Value && link8TitleValue && (
                <Col mobile={12} tablet={6} desktop={6}>
                  <SquareLinkTmp link={link8Value} title={link8TitleValue} />
                </Col>
              )}
            </Row>
          </Grid>
        </div>
      </div>
    </div>
  );
};
