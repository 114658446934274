import * as React from 'react';

import { Button, InputField } from '@bdl-cmn-shared-packages-npm/design-system';
import { ComponentParams, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../../../../style';
import { css, jsx } from '@emotion/react';

import bgHexa from './bgHexa.svg';
import hexa from './fullhexa.svg';
import hexas from './hexas.svg';
import { renderLink } from '../../../../../utils/helper';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';

/** @jsx jsx */

export interface MarketDataHeaderProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
  history: any;
}
const style = {
  hexaBackground: css`
    position: absolute;
    width: 761px;
    left: -8%;
    top: 28%;
    z-index: 0;
    img {
      width: 100%;
    }
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  topContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding-top: 164px;
    padding-bottom: 164px;
    background-size: cover !important;
    background: url(${renderLink(hexas)});
    background-repeat: no-repeat;
    background-color: #ffffff;
    position: relative;
    z-index: 2;
    overflow: hidden;
    border-top: 1px solid #e3e6e8;
    @media (max-width: ${breakpointMax}) {
      padding-top: 110px;
      padding-bottom: 130px;
    }
  `,
  container: css`
    width: 100%;
    display: flex;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  left: css`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  topTitle: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 25.56px;
    color: #cb1231;
    @media (max-width: ${breakpointMax}) {
    }
  `,
  leftContent: css``,
  right: css`
    display: flex;
    flex-direction: column;
    width: 30%;
  `,
  rightHexa: css`
    background: url(${renderLink(hexa)});
    background-repeat: no-repeat;
    background-size: 800px;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    margin-top: 64px;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  redTitle: css`
    font-weight: 600;
    color: #ca1234;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0.5em;
    @media (max-width: ${breakpointMax}) {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  `,
  title: css`
    font-size: 48px;
    line-height: 62px;
    font-weight: 800;
    color: rgb(66, 85, 99);
    color: #253845;
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      padding-top: 20px;
      padding-bottom: 40px;
    }
  `,
  input: (timer: boolean) => css`
    opacity: ${timer ? 1 : 0};
    transition: opacity .3s ease-in-out;
    margin-top: 2em;
    display: flex;
    background-color: #e8ecee;
    border-radius: 8px;
    padding: 16px;
    label {
      font-family: 'Mont', sans serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    }
    .MuiInputBase-input {
      border: none !important;
      color: inherit;
      background: #ffffff;
      border-radius: 8px;
    }
    button {
      margin-left: 1em;
    }
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      > div > div > div > div {
        &:last-of-type {
          background: #1acb69;
          color: #ffffff;
          position: relative;
          margin-left: -6px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          width: 54px;
        }
      }
      button {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  `,
  advanced: css`
    margin-top: 2em;
    margin-bottom: 120px;
    font-size: 1em;
    span {
      font-weight: 600;
      color: #22AA5F;
      margin-left: 4px;
      text-decoration: underline;
    }
  `,
  hideMobile: css`
    margin-left: 10px;
    margin-right: 10px;
    @media (min-width: ${breakpoint}) {
      display: none;
    }
  `,
  hideDesktop: css`
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
    width: 100%;
    width: 896px;
    box-shadow: 0px 16px 48px rgba(37, 54, 69, 0.1);
  `,
  mobileBtn: css`
    margin-top: 16px;
    margin-bottom: 8px;

    width: 100%;
    button {
      width: 100%;
    }
  `,
  results: css`
    position: fixed;
    height: 100%;
    left: 0;
    right: 0;
    margin-top: 10px;
    background: #ffffff;
    border-top: solid 1px rgba(0, 0, 0, 0.2);
    z-index: 3;
    overflow: auto;
  `,
  resultsCards: css`
    height: 100%;
    overflow: auto;
    margin-bottom: 80px;
  `,
  resultsBtn: css`
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    left: 0;
    button {
      width: 100%;
    }
  `,
};


export const MarketDataHeader: React.FunctionComponent<any> = (props: MarketDataHeaderProps): React.ReactElement => {
  const { fields, params, rendering, history } = props;

  const [visibilityTimer, setVisibilityTimer] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  React.useState(() => {
    setTimeout(() => {
      setVisibilityTimer(true);
    });
  });

  return (
    <div css={style.topContainer}>
      <div css={style.topTitle}>Markets & Data</div>
      <div css={style.hexaBackground}>
        <img src={renderLink(bgHexa)} alt={"image"} />
      </div>
      <div css={style.container}>
        <div css={style.leftContent}>
          <h1 css={style.title}>All the data at your fingertips</h1>
          <div css={style.hideMobile}>
            <div css={style.input(visibilityTimer)}>
              <InputField
                helperText=''
                placeholder='Search by ISIN...'
                textError=''
                type='text'
                width='100%'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                onKeyPress={(ev: any) => {
                  if (ev.key === 'Enter' && search.length > 0) {
                    window.location.href = `/search?q=${search}`;
                  }
                }}
                onClickTrailingIcon={() => (window.location.href = `/search?q=${search}`)}
                trailingIcon={'arrow-right'}
              />
            </div>
          </div>
          <div css={style.hideDesktop}>
            <div css={style.input(visibilityTimer)}>
              <InputField
                helperText=''
                placeholder='Search by ISIN, Name or document type'
                textError=''
                type='text'
                width='100%'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                onKeyPress={(ev: any) => {
                  if (ev.key === 'Enter' && search.length > 0) {
                    window.location.href = `/search?q=${search}`;
                  }
                }}
              />
              <Button
                css={{ 'align-items': 'center' }}
                iconRight='search'
                label='Search'
                onClick={() => (window.location.href = `/search?q=${search}`)}
                variant='primary'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MarketDataHeader);
