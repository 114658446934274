/** @jsx jsx */
/* eslint-disable */
import React, { Component } from "react";
import { css, jsx } from "@emotion/react";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpointChartMax, breakpointMax } from "../../../../style";
import { canUseDOM } from "../../../../predicates";


export class TopTradedSecurity extends Component<any, any> {

    render() {
    const type=this.props.type;
    const data=this.props.data;

    const style = {
        date: css`
            font-weight:bold;
        `,
        info: css`
            display:flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width:100%;
            align-items:center;
            text-align: center;
            cursor: pointer;
            :hover {
                background: #f6f8f9;
                transition: all 0.1s ease-in-out;
            }
        `,
        issuer: css`
            display:block;
            flex-direction: column;
            //flex-wrap: wrap;
            margin-top:25px;
            //margin-bottom:auto;
            > p {
                margin:0px;
            }
            margin-left:auto;
            margin-right:1rem;

            width:150px;
            overflow:hidden;
            white-space: nowrap;

            @media (max-width: ${breakpointMax}) {
                width:90%;
                margin-top:0px;
                margin-bottom:0px;
                margin-left:auto;
                margin-right:auto;
            }
        `,
        td: css`
            font-weight: 500;
            font-size: 40px;
            line-height: 51px;
            text-align: center;
            margin-left:auto;
            @media (max-width: ${breakpointMax}) {
                margin-left: 0em;
            }
        `,
        chevron: css`
            font-weight: 400;
            font-size: 24px;
            line-height: 24px; 
        `,
        arrow: css`
            color:"#22AA5F";
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            
        `,
        issuerName: css`
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            color: #425563;
            //width:100%
            //line-break:anywhere;
            //overflow-wrap: anywhere;
            margin: 0 auto;

            text-overflow: ellipsis;
            overflow: hidden;
        `,
        isin: css`
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
            color:#A4B6C2;
            padding-top:2px;
            @media (max-width: ${breakpointMax}) {
                margin:0px;
            }
        `,
        turnover: css`
            display:inline-flex;
            flex-direction: column;
            //flex-wrap: wrap;
            width:55%;
            margin-top:auto;
            margin-bottom:auto;
            @media (max-width: ${breakpointMax}) {
                margin:auto;
                width:100%;
            }
        `,
        turnoverStyle: css`
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
            color:#A4B6C2;
            margin-right:auto;
            margin-bottom:5px;
            @media (max-width: ${breakpointMax}) {
                margin:auto;
            }
        `,
        arrowParent:css`
            margin-top:25px;
            cursor: pointer;
            @media (max-width: ${breakpointMax}) {
                margin-top:0px;
                //visibility: hidden;
            }
        `,
    };

    const turnover=data?.turnover?.toFixed(0);

    const goToSecurityDetailPage = (isin: string, id: string) => {
        if (canUseDOM) window.open(`/security/${isin}/${id}`, '_self');
    };

    return (
        data && data?.isinCode ? (
          <div css={style.info} onClick={() => goToSecurityDetailPage(data.isinCode, data.id)}>
            <div css={style.turnover}>
              <div css={style.turnoverStyle}>Turnover</div>
              <div css={[style.td, type === "Shares" ? css`color: #22AA5F;` : css`color: #F18700;`]}>
                {Number(turnover).toLocaleString('en-US').replace(/,/g, ' ')} {data?.currency}
              </div>
            </div>
      
            <div css={style.issuer}>
              <p css={style.isin}>{type}</p>
              <p css={style.issuerName}>{data?.name}</p>
              <p css={style.isin}>{data?.isinCode}</p>
            </div>
      
            <div css={style.arrowParent}>
              <p css={style.arrow} style={{ color: "#22AA5F" }}><Icon icon={"arrow-right"} /></p>
            </div>
          </div>
        ) : type === "Shares" ? (
          <div>No result available on shares</div>
        ) : (
          <div>No result available on bonds</div>
        )
      );
    }
}

export default TopTradedSecurity;
