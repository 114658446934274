import * as React from "react";

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import MarketStatusComponent from "../../core/components/MarketStatusBox/marketStatusBox.component";
import { SitecoreGenericTextField } from "../../interfaces";
import hexaGreen from "./hexagreen.svg";
import hexss from "./hexas.svg";
import logo from "./white-logo.svg";
import { fetchData, getDataWithChild, renderLink } from "../../utils/helper";
import CountUpComponent from "../../core/components/CountUp/CountUpComponent";
import DictionaryComponent from "../../core/components/Dictionary/DictionaryComponent";

const lint = jsx;
/** @jsx jsx */

export interface HeaderDataActionsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const HeaderDataActions: React.FunctionComponent<HeaderDataActionsProps> =
  (props: HeaderDataActionsProps,): React.ReactElement => {
    const { fields } = props;
    const hideFilter: boolean = getFieldValue<boolean>(
      fields,
      "hide filter",
      false
    );
    const smallImage: any = getFieldValue<any>(fields, "small image", null);
    const btlLabel1: string = getFieldValue<string>(fields, "firstButton", "");
    const btlLabel2: string = getFieldValue<string>(fields, "secondButton", "");
    const btnLink1: string = getFieldValue<string>(
      fields,
      "firstButtonLink",
      ""
    );
    const btnLink2: string = getFieldValue<string>(
      fields,
      "secondButtonLink",
      ""
    );
    const background: any = getFieldValue<any>(fields, "background", null);
    const hasLogo: any = Number(getFieldValue<any>(fields, "hasLogo", 0));
    const isGreen: any = Number(getFieldValue<any>(fields, "isGreen", 0));
    const firstButtonIcon: any = getFieldValue<string>(
      fields,
      "firstButtonIcon",
      "chevron-right"
    );
    const secondButtonIcon: any = getFieldValue<string>(
      fields,
      "secondButtonIcon",
      "chevron-right"
    );
    const marketStatus: any = Number(
      getFieldValue<number>(fields, "market is open", 0)
    );
    const marketLabel: string = getFieldValue<string>(
      fields,
      "market label",
      ""
    );
    const marketDetails: string = getFieldValue<string>(
      fields,
      "market details",
      ""
    );
    const hasMarketIndicator: any = Number(
      getFieldValue<any>(fields, "hasMarketIndicator", 0)
    );
    const isH1: boolean = getFieldValue<boolean>(fields, "is H1", false);
    const hideHexa: boolean = getFieldValue<boolean>(
      fields,
      "hide hexagon",
      false
    );

    /*
    background: linear-gradient(83deg, #166F3E 1.21%, rgba(46, 157, 71, 0.94) 41.77%, rgba(199, 214, 77, 0.75) 65.16%, rgba(199, 214, 77, 0.00) 90.12%), url(https://grp-weu-dev-scor-cm.apps.dev.luxse.cloud//-/media/bdl-port-luxse-ssr/Data/Media/Images/lgx/why-lgx-header-banner.png?h=771&iar=0&w=1600&hash=BAF1AC44798B925125A2C352E198681D) no-repeat;
    background-size: cover;

     */

    const gradientType = getFieldValue<any>(fields, "gradient color", null);
    const whiteButtons = getFieldValue<boolean>(fields, "whiteButtons", false);
    const isWhiteButton = () => {
      if (gradientType === "apple500" || gradientType === "peach500" ||  whiteButtons === true) {
        return "white";
      }
      return false;
    };
    const bg =
      fields && fields.background && fields.background.value.src
        ? `, url(${renderLink(background && background.src)}) no-repeat`
        : "";
    const getBackground = () => {
      if (gradientType === "apple100") {
        return `linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)${bg}`;
      }
      if (gradientType === "apple500") {
        return `linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)${bg}`;
      }
      if (gradientType === "peach100") {
        return `linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)${bg}`;
      }
      if (gradientType === "peach500") {
        return `linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)${bg}`;
      }
      if (fields && fields.background && fields.background.value.src) {
        return hideFilter
          ? `url(${renderLink(background && background.src)}) no-repeat`
          : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
          url(${renderLink(background && background.src)}) no-repeat`;
      }
    };

    const style = {
      sustainableContainer: css`
        background: ${getBackground()};
        background-size: cover;
        width: 100%;
        padding-top: 72px;
        position: relative;
      `,
      hexas: css`
        position: absolute;
        height: 100%;
        max-height: 500px;
        background: url(${renderLink(isGreen ? hexaGreen : hexss)}) no-repeat;
        background-size: cover;
        width: 100%;
        max-width: 800px;
        z-index: 0;
        bottom: 0;
        @media (max-width: ${breakpointMax}) {
          display: none;
        }
      `,
      buttons: css`
        display: flex;
        width: 100%;
        @media (max-width: ${breakpointMax}) {
          flex-direction: column;
        }
      `,
      sustainableContainerDouble: css`
        display: flex;
        position: relative;
        z-index: 2;
        width: 100%;
        @media (max-width: ${breakpointMax}) {
          margin-top: 120px;
        }
      `,
      sustainableContainerDoubleLeft: css`
        display: flex;
        width: 25%;
        justify-content: center;

        img {
          max-width: 300px;
          width: 100%;
          padding-right: 2em;
        }

        @media (max-width: ${breakpointMax}) {
          display: none;
        }
      `,
      sustainableContainerDoubleRight: css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 75%;
        //border-left: solid 1px rgba(255, 255, 255, .35);
        padding-left: 16px;
        padding-right: 16px;
        @media (max-width: ${breakpointMax}) {
          width: 100%;
        }
      `,
      sustainableContainerBottom: css`
        display: flex;
        width: 100%;
        max-width: 1000px;
        margin-left: calc(25% + 24px);
        margin-top: 64px;
        position: relative;
        padding-bottom: 96px;
        @media (max-width: ${breakpointMax}) {
          margin-left: 0;
          margin-top: 0;
          padding-bottom: 64px;
          max-width: 100%;
          flex-direction: column;
          padding-left: 16px;
          padding-right: 16px;
          text-align: center;
        }
      `,
      sustainableContainerBottomData: css`
        display: flex;
        flex-direction: column;
        padding-right: 4em;
        width: 100%;

        &:last-child {
          padding-right: 0;
        }

        @media (max-width: ${breakpointMax}) {
          margin-top: 40px;
          padding-right: 0px;
        }
      `,
      sustainableContainerBottomDataTop: css`
        color: #ffffff;
        font-weight: 800;
        font-size: 2em;
        @media (max-width: ${breakpointMax}) {
          font-weight: 700;
          font-size: 26px;
          line-height: 31px;
        }
      `,
      sustainableContainerBottomDataBottom: css`
        color: #f6f9f9;
        font-size: 16px;
        line-height: 24px;
        margin-top: 0.5em;
        @media (max-width: ${breakpointMax}) {
          font-weight: 600;
          font-size: 15px;
          line-height: 150%;
          letter-spacing: 0.01em;
        }
      `,
      sustainableContainerDoubleRightTitle: css`
        font-weight: 800;
        color: #ffffff;
        font-size: 48px;
        line-height: 62px;
        max-width: 700px;
        @media (max-width: ${breakpointMax}) {
          font-weight: 700;
          font-size: 32px;
          line-height: 130%;
          text-align: center;
          margin-bottom: 16px;
        }

        h1 {
          font-weight: 800;
          color: #ffffff;
          font-size: 48px;
          line-height: 62px;
          max-width: 700px;
          margin: 0;
          @media (max-width: ${breakpointMax}) {
            font-weight: 700;
            font-size: 32px;
            line-height: 130%;
            text-align: center;
            margin-bottom: 16px;
          }
        }
      `,
      sustainableContainerDoubleRightTopTitle: css`
        color: #ffffff;
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        @media (max-width: ${breakpointMax}) {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          margin-bottom: 16px;
        }
      `,
      sustainableContainerDoubleRightSubTitle: css`
        font-weight: 600;
        color: #ffffff;
        font-size: 16px;
        line-height: 150%;
        max-width: 400px;
        padding-top: 32px;

        @media (max-width: ${breakpointMax}) {
          font-weight: 400;
          font-size: 15px;
          line-height: 180%;
          text-align: center;
          padding-top: 0;
        }
      `,
      sustainableContainerDoubleRightButtons: css`
        display: flex;
        margin-top: 48px;

        div {
          margin-left: 1em;

          &:first-of-type {
            margin-left: 0;
          }
        }

        @media (max-width: ${breakpointMax}) {
          flex-direction: column;

          button {
            width: 100%;

            &:first-of-type {
              margin-bottom: 16px;
            }
          }

          div {
            margin: 0;
          }
        }
      `,
      headImg: css`
        margin-bottom: 8px;

        img {
          height: 38px;
        }

        @media (max-width: ${breakpointMax}) {
          img {
            height: 30px;
          }
        }
      `,
    };

    const btnLeftAction = () => {
      if (btnLink1) {
        window.location.href = btnLink1;
      }
    };

    const [dictionaryItems, setDictionaryItems] = React.useState<{ [key: string]: string }>( {});
    const [isLoading, setIsLoading] = React.useState(true);

    const getDictionaryItems = async (id: string) => {
      try {
        const result = await fetchData(id);
        const dataReq = await getDataWithChild(result);
        const items: { [key: string]: string } = {};

        if (dataReq && dataReq.length > 0) {
          for (const d of dataReq) {
            if (d.children && d.children.length > 0) {
              for (const item of d.children) {
                const key = item.Key;
                const phrase = item.Phrase;
                if (key && phrase) {
                  items[key] = phrase;
                }
              }
            }
          }
        } else {
          console.warn('No data found:', dataReq);
        }
        setDictionaryItems(items);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching dictionary items:', err);
      }
    };

React.useEffect(() => {
  getDictionaryItems('504AE189-9F36-4C62-9767-66D73D6C3084');
}, []);

const replaceKeysWithPhrases = (text: string, dictionary: { [key: string]: string }) => {
  return text.replace(/\[([^\]]+)\]/g, (match, key) => dictionary[key] || key);
};

const data1 = replaceKeysWithPhrases(fields?.data1?.value, dictionaryItems);
const data2 = replaceKeysWithPhrases(fields?.data2?.value, dictionaryItems);
const data3 = replaceKeysWithPhrases(fields?.data3?.value, dictionaryItems);
const heading = replaceKeysWithPhrases(fields?.heading?.value, dictionaryItems); 

    return (
      <div css={style.sustainableContainer}>
        {!hideHexa && <div css={style.hexas} />}
        {hasMarketIndicator === 1 && (
          <MarketStatusComponent
            marketStatus={marketStatus}
            marketLabel={marketLabel}
            marketDetails={marketDetails}
          />
        )}
        <div css={style.sustainableContainerDouble}>
          <div css={style.sustainableContainerDoubleLeft}>
            {!!(hasLogo && Number(hasLogo) === 1) && (
              <img src={renderLink(logo as any)} alt={"image"} />
            )}
          </div>
          <div css={style.sustainableContainerDoubleRight}>
            {smallImage?.src && (
              <div css={style.headImg}>
                <img alt=" " src={renderLink(smallImage.src)} />
              </div>
            )}
            {fields?.topTitle && (
              <div css={style.sustainableContainerDoubleRightTopTitle}>
                <Text
                  field={
                    fields && (fields.topTitle as SitecoreGenericTextField)
                  }
                />
              </div>
            )}
            <div css={style.sustainableContainerDoubleRightTitle}>
              {isH1
                ? <h1>{heading}</h1>
                : <div>{heading}</div>}
            </div>
            <div css={style.sustainableContainerDoubleRightSubTitle}>
              <Text
                field={
                  fields && (fields.subHeading as SitecoreGenericTextField)
                }
              />
            </div>
            {(btlLabel1 || btlLabel2) && (
              <div css={style.sustainableContainerDoubleRightButtons}>
                {btlLabel1 && (
                  <div>
                    <Button
                      label={btlLabel1}
                      variant={isWhiteButton() ? "thirdary" : "primary"}
                      iconRight={firstButtonIcon}
                      onClick={btnLeftAction}
                    />
                  </div>
                )}
                {btlLabel2 && (
                  <div>
                    <Button
                      label={btlLabel2}
                      iconRight={secondButtonIcon}
                      variant={isWhiteButton() ? "thirdary" : "secondary"}
                      onClick={() =>
                        btnLink2
                          ? (window.location.href = btnLink2)
                          : console.debug
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div css={style.sustainableContainerBottom}>

          <div css={style.sustainableContainerBottomData}>
            <div css={style.sustainableContainerBottomDataTop}>
              <CountUpComponent value={data1} duration={2000} />
            </div>
            <div css={style.sustainableContainerBottomDataBottom}>
              <Text
                field={fields && (fields.data1Text as SitecoreGenericTextField)}
              />
            </div>
          </div>
          <div css={style.sustainableContainerBottomData}>
            <div css={style.sustainableContainerBottomDataTop}>
              <CountUpComponent value={data2} duration={2000} />
            </div>
            <div css={style.sustainableContainerBottomDataBottom}>
              <Text
                field={fields && (fields.data2Text as SitecoreGenericTextField)}
              />
            </div>
          </div>
          <div css={style.sustainableContainerBottomData}>
            <div css={style.sustainableContainerBottomDataTop}>
              <CountUpComponent value={data3} duration={2000} />
            </div>
            <div css={style.sustainableContainerBottomDataBottom}>
              <Text
                field={fields && (fields.data3Text as SitecoreGenericTextField)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
