/* eslint-disable */
import * as React from "react";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import { GET_LUXXPRIME_FALLERS_QUERY } from "../../../graphql/queries/luxxprime.queries";
import Utils from "../../utils/utils";
import { Security } from "../../../interfaces";

const useFallers = (limit: number) => {
    const [data, setData] = React.useState<Security[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const getData = React.useCallback(async () => {
        try {
            const results: any = await luxseGatewayGraphqlClient.query({
                query: GET_LUXXPRIME_FALLERS_QUERY,
                variables: {
                token: Utils.getCookie("Gztoken-Dsm"),
                limit: limit,
            },
        });
    
        setIsLoading(false);
    
        if (results?.data?.fallersLuxXPrimeSecurities) {
            const tmpData: Security[] = [];
            results.data.fallersLuxXPrimeSecurities.forEach((element: any) => {
            tmpData.push(element);
            });
    
            setData(tmpData);
        }
        } catch (e) {
            console.error("Failed to get fallers data", e);
            setIsLoading(false);
        }
      },[setIsLoading, setData]);

    React.useEffect(() => {
        getData();
    }, []);

    return { data, isLoading };
}

export default useFallers;