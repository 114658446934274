
/*
*   e-Listing - core - components - country flag icon - nld
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const CHE: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#DA291C" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <path fill="#FFFFFF" d="M353.103,220.691H291.31v-61.793c0-4.875-3.953-8.828-8.828-8.828h-52.966 c-4.875,0-8.828,3.953-8.828,8.828v61.793h-61.793c-4.875,0-8.828,3.953-8.828,8.828v52.966c0,4.875,3.953,8.828,8.828,8.828h61.793 v61.793c0,4.875,3.953,8.828,8.828,8.828h52.966c4.875,0,8.828-3.953,8.828-8.828v-61.795h61.793c4.875,0,8.828-3.953,8.828-8.828 v-52.966C361.931,224.643,357.978,220.691,353.103,220.691z" />
        </svg>
    </React.Fragment>
);
