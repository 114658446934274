import * as React from 'react';

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Link,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { Button } from '@bdl-cmn-shared-packages-npm/design-system';
import { SitecoreGenericTextField } from '../../interfaces';
import hexa from './hexaBoldBg.png';
import hexaBg from './hexaBold.svg';
import hexas from './hexas.svg';
import { fetchData, getDataWithChild, renderLink } from '../../utils/helper';
import CountUpComponent from '../../core/components/CountUp/CountUpComponent';
import DictionaryComponent from '../../core/components/Dictionary/DictionaryComponent';

/** @jsx jsx */

export interface HomeUniqueComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const style = {
  topContainer: css`
      background: url(${renderLink(hexas)});
      background-repeat: no-repeat;
      background-position: top;
      position: absolute;
      width: 100%;
      height: calc(100% + 160px);
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      margin-top: -127px;
      @media (max-width: ${breakpointMax}) {
        position: relative;
      }
    }
    `,
  container: css`
      width: 100%;
      position: relative;
      margin-top: 64px;
      background: url(${renderLink(hexaBg)});
      background-repeat: no-repeat;
      background-position-y: 126px;
      background-size: 514px;
      background: #E8ECEE;
      @media (max-width: ${breakpointMax}) {
        margin-top: 0px;
      }
    }
    `,
  hexaBg: css`
      width: 100%;
      height: 900px;
      max-width: 514px;
      position: absolute;
      pointer-events: none;
      //padding-top: 100px;
      //padding-bottom: 100px;
      margin-top: -90px;
      background: url(${renderLink(hexaBg)});
      background-repeat: no-repeat;
      background-position-y: 126px;
      background-size: 514px;
      opacity: .4;
      right: 0;
      z-index: 1;
      transform: scaleX(-1);

      @media (max-width: ${breakpointMax}) {
        display: none;
      }
    } 
    `,
  subContainer: css`
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin: auto;
    //padding-left: 8em;
    //padding-right: 8em;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
  `,
  top: css`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    z-index: 2;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column-reverse;
    }
  `,
  left: css`
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-left: 120px;
    padding-right: 120px;

    img {
      position: absolute;
      z-index: 0;
      margin-left: -120px;
      margin-top: -20px;
      height: 59%;
      max-height: 800px;
      @media (max-width: ${breakpointMax}) {
        margin: 0;
      }
    }

    @media (max-width: ${breakpointMax}) {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }
  `,
  right: css`
    width: 40%;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;

    img {
      margin-top: -120px;
      @media (max-width: ${breakpointMax}) {
        margin: 0;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  title: css`
    width: 100%;
    display: flex;
    margin-top: 32px;
    margin-bottom: 16px;
    font-weight: 800;
    font-size: 48px;
    color: #354450;

    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 32px;
      line-height: 41px;
    }
  `,
  text: css`
    width: 100%;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 32px;
    line-height: 24px;
    max-width: 400px;
    color: #354450;

    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
    }
  `,
  action: css`
    width: 100%;
    display: flex;
    button {
      font-size: 16px;
      font-weight: 600;
    }
  `,
  bottom: css`
    width: 100%;
    display: flex;
    margin-left: 120px;
    margin-top: 60px;

    @media (max-width: ${breakpointMax}) {
      margin: 0;
      margin-top: 48px;
      padding-left: 8px;
      padding-right: 8px;
      flex-direction: row;
      flex-wrap: wrap;
    }
  `,
  bloc: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      width: 40%;
      margin: 5%;
    }
  `,
  blocTop: css`
    color: #354450;
    font-size: 48px;
    margin-bottom: 16px;
    font-weight: 800;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 32px;
      line-height: 41px;
      margin-bottom: 0;
    }
  `,
  blocBottom: css`
    color: #354450;
    font-size: 16px;
    line-height: 150%;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.01em;
    }
  `,
};

export const HomeUniqueComponent: React.FunctionComponent<HomeUniqueComponentProps> = (
  props: HomeUniqueComponentProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const button1Label: string = getFieldValue<string>(fields, 'button label', '');

  const [dictionaryItems, setDictionaryItems] = React.useState<{ [key: string]: string }>( {});
    const [isLoading, setIsLoading] = React.useState(true);

    const getDictionaryItems = async (id: string) => {
      try {
        const result = await fetchData(id);
        const dataReq = await getDataWithChild(result);
        const items: { [key: string]: string } = {};

        if (dataReq && dataReq.length > 0) {
          for (const d of dataReq) {
            if (d.children && d.children.length > 0) {
              for (const item of d.children) {
                const key = item.Key;
                const phrase = item.Phrase;
                if (key && phrase) {
                  items[key] = phrase;
                }
              }
            }
          }
        } else {
          console.warn('No data found:', dataReq);
        }
        setDictionaryItems(items);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching dictionary items:', err);
      }
    };

React.useEffect(() => {
  getDictionaryItems('504AE189-9F36-4C62-9767-66D73D6C3084');
}, []);

const replaceKeysWithPhrases = (text: string, dictionary: { [key: string]: string }) => {
  return text.replace(/\[([^\]]+)\]/g, (match, key) => dictionary[key] || key);
};
  const data1figure = replaceKeysWithPhrases(fields?.data1?.value, dictionaryItems);
  const data2figure = replaceKeysWithPhrases(fields?.data2?.value, dictionaryItems);
  const data3figure = replaceKeysWithPhrases(fields?.data3?.value, dictionaryItems);
  const data4figure = replaceKeysWithPhrases(fields?.data4?.value, dictionaryItems);

  return (
    <div css={style.container}>
      <div css={style.hexaBg} />
      <div css={style.subContainer}>
        <div css={style.top}>
          <div css={style.left}>
            <div css={style.title}>
              <Text field={fields && (fields.title as SitecoreGenericTextField)} />
            </div>
            <div css={style.text}>
              <DictionaryComponent text={fields.text.value}/>
            </div>
            <div css={style.action}>
              <Link target={(fields['button link']?.value?.linktype === "external" ? "_blank" : "_self")} title={fields['button label']} field={fields['button link']} css={{ textDecoration: 'none' }}>
                <Button label={button1Label} iconRight={'chevron-right'} />
              </Link>
            </div>
          </div>
          <div css={style.right}>
            <img src={renderLink(hexa)} alt={"image"} />
          </div>
        </div>
        <div css={style.bottom}>
          <div css={style.bloc}>
            <div css={style.blocTop}>
              <CountUpComponent value={data1figure} duration={2000} />
            </div>
            <div css={style.blocBottom}>
              <Text field={fields && (fields.data1Text as SitecoreGenericTextField)} />
            </div>
          </div>
          <div css={style.bloc}>
            <div css={style.blocTop}>
              <CountUpComponent value={data2figure} duration={2000} />
            </div>
            <div css={style.blocBottom}>
              <Text field={fields && (fields.data2Text as SitecoreGenericTextField)} />
            </div>
          </div>
          <div css={style.bloc}>
            <div css={style.blocTop}>
              <CountUpComponent value={data3figure} duration={2000} />
            </div>
            <div css={style.blocBottom}>
              <Text field={fields && (fields.data3Text as SitecoreGenericTextField)} />
            </div>
          </div>
          <div css={style.bloc}>
            <div css={style.blocTop}>
              <CountUpComponent value={data4figure} duration={2000} />
            </div>
            <div css={style.blocBottom}>
              <Text field={fields && (fields.data4Text as SitecoreGenericTextField)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
