/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React from 'react';
import { style } from "./index.style";

export const EmptyCard = (): JSX.Element => {

  return (
    <div css={style.container}>
      <div css={style.top} />
      <div css={style.bottom}>
        You have to type something or add filter
      </div>
    </div>
  );
}

export default EmptyCard;
