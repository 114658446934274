
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';

export interface HomeProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const Home: React.FunctionComponent<HomeProps> = (props: HomeProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    return (
        <div className='Home-box'>
            <p>Home Component</p>
        </div>
    );
}
