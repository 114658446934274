import * as React from "react";

import { css, jsx } from "@emotion/react";

import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import Loader from "../Loader/loader.component";
import { sharedStyle } from "../../../styles/shared";
import { tradingStyle } from "../DataMarket/MarketData/trading-data.style";

/** @jsx jsx */






export interface WidgetCardProps {
  icon?: any;
  iconType?: any;
  logo?: any;
  label?: string;
  link?: any;
  loader?: boolean;
  children?: any;
  isHover?:boolean;
}

const WidgetCard: React.FunctionComponent<WidgetCardProps> = ({
  icon,
  iconType,
  label,
  logo,
  link,
  loader,
  children,
  isHover,
}: WidgetCardProps) => {
  return (
    <div css={sharedStyle.colflex1}>
      <div css={sharedStyle.card}>
        <div css={sharedStyle.cardContainer}>
          <div css={sharedStyle.cardContainerTop}>
            {logo}
            {label && (
              <div
                css={
                  label == "LGX displayed international bonds"
                    ? sharedStyle.cardContainerTitle2
                    : sharedStyle.cardContainerTitle
                }
              >
                <Icon icon={icon} type={iconType || "far"} /> {label}
              </div>
            )}
          </div>
          <div css={sharedStyle.cardContainerBody}>
            <Loader loading={loader} centered={true}>
              <div css={tradingStyle.cardContainerBodyContent}>{children}</div>
              {Boolean(link) && (
                <div css={[
                  isHover?tradingStyle.cardContainerBodyLink:tradingStyle.cardContainerBodyLinkWithNoHover]}>{link}</div>
              )}
            </Loader>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetCard;
