import {
  Button,
  Icon,
  Td,
  Tr,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import { CountryFlagIcon } from "../../../CountryFlagIcon/countryFlagIcon.component";
import Props from "../base/props";
import React from "react";
import SearchDocument from "../../../../../interfaces/search/search-document";
import { data as dataMapper } from "../../../Search/results/dataMapper";
import { documentSubTypeLabel } from "../../../../../enums/document-sub-type";
import { documentTypeLabel } from "../../../../../enums/document-type";
import { formatDate } from "../../../../../utils/date";
import { style } from "../base/index.style";
import {
  DOCUMENTS_TYPE_TO_PREVIEW,
  DOWNLOAD_ENDPOINT,
} from "../../../../../backbourse";
import { DOCUMENT_PREVIEW_LINK } from "../../../../constants";
import { canUseDOM } from "../../../../../predicates";

const lint = jsx; // linter hack

/** @jsx jsx */

export interface SearchDocumentProp {
  row: SearchDocument;
  selectedCols?: any;
  fastLaneUrls?: string[];
  rowKey?: string;
}

const computeDate = (row: any): string => {
  let date = row?.referenceDate;
  if (row?.categories?.includes("FNS") || row?.categories?.includes("OAM")) {
    date = row?.publishDate;
  }
  return date;
};

const DocumentRow = ({
  row,
  selectedCols,
  fastLaneUrls,
  rowKey,
}: SearchDocumentProp) => {
  const displayFastLane =
    (row?.documentTypeCode === "D003" || row?.documentTypeCode === "D090") &&
    fastLaneUrls?.length > 0;

  const hasLinkedDocs =
    displayFastLane ||
    row?.incorporedByReferenceDocuments?.length > 0 ||
    row?.supplementDocuments?.length > 0;
  const [openRow, setOpenRow] = React.useState<boolean>(displayFastLane);
  const onToggleRow = React.useCallback(() => {
    setOpenRow((openRow) => !openRow);
  }, []);
  const [openRef, setOpenRef] = React.useState<boolean>(false);
  const onToggleRef = React.useCallback(() => {
    setOpenRef((openRef) => !openRef);
  }, []);
  const [openSup, setOpenSup] = React.useState<boolean>(true);
  const onToggleSup = React.useCallback(() => {
    setOpenSup((openSup) => !openSup);
  }, []);
  const [openFastLane, setOpenFastLane] = React.useState<boolean>(true);
  const onToggleFastLane = React.useCallback(() => {
    setOpenFastLane((openFastLane) => !openFastLane);
  }, []);

  const handleRowClick = (
    hasLinkedDocs: boolean,
    id: string,
    url: string,
    type: string[]
  ) => {
    if (hasLinkedDocs) onToggleRow();
    //else
    //  Utils.documentAction(id, url, type);
  };

  if (!row) return <></>;

  const publicTypeLabel = "";

  return (
    <>
      <Tr
        css={[
          style.actionsRow,
          hasLinkedDocs && style.clickableRow,
          hasLinkedDocs && openRow && style.openedRow,
        ]}
        key={rowKey ? rowKey : `document-${row.id}`}
        onRowClick={() =>
          handleRowClick(hasLinkedDocs, row.id, row.downloadUrl, row.categories)
        }
      >
        <Td>
          {row.categories?.length
            ? row.categories.map((category: string, index: number) => (
                <>
                  {documentTypeLabel(category) && (
                    <div
                      css={[style.market, index > 0 && { marginTop: "5px" }]}
                    >
                      {documentTypeLabel(category)}
                    </div>
                  )}
                </>
              ))
            : "N/A"}
        </Td>
        <Td>
          {documentSubTypeLabel(row?.documentPublicTypeCode) ? (
            <div
              css={[
                style.market,
                css`
                  white-space: unset;
                `,
              ]}
            >
              {documentSubTypeLabel(row?.documentPublicTypeCode)}
            </div>
          ) : (
            "N/A"
          )}
        </Td>
        <Td>
          <div css={style.subLabel}>
            {publicTypeLabel}
            {row.description
              ? `${publicTypeLabel ? " - " : ""}${row.description}`
              : ""}
          </div>
          <div>
            {computeDate(row) ? `${formatDate(computeDate(row))} - ` : ""}
            {row.complement}
          </div>
          {hasLinkedDocs ? (
            <div css={style.subLabel}>
              {row.supplementDocuments?.length
                ? `${row.supplementDocuments?.length} supplement(s)`
                : ""}
              {row.incorporedByReferenceDocuments?.length
                ? `${row.supplementDocuments?.length ? " | " : ""}${
                    row.incorporedByReferenceDocuments?.length
                  } document(s) incorpored by reference`
                : ""}
              {displayFastLane
                ? `${
                    row.supplementDocuments?.length ||
                    row.incorporedByReferenceDocuments?.length
                      ? " | "
                      : ""
                  }${fastLaneUrls?.length} FastLane URL(s)`
                : ""}
            </div>
          ) : (
            <></>
          )}
        </Td>
        <Td>
          {row.hostMemberStates?.length > 0 &&
            row.hostMemberStates.map((isoCode: string, index: number) => (
              <CountryFlagIcon
                code={isoCode}
                size={{ height: 20, width: 30 }}
              />
            ))}
        </Td>
        <Td>
          <DownloadDocument
            documentId={row.id}
            documentUrl={row.downloadUrl}
            documentTypes={row?.categories}
            showDownload={true}
            showPreview={true}
            documentFileType={row?.format}
          />
        </Td>
        {selectedCols ? (
          selectedCols["documents"]?.map((col: string, index: number) => (
            <Td
              key={
                rowKey
                  ? `${rowKey}-col-selected-${index}`
                  : `col-selected-${index}`
              }
            >
              {dataMapper["documents"][col].render((row as any)[col])}
            </Td>
          ))
        ) : (
          <></>
        )}
        <Td>
          {hasLinkedDocs ? (
            <Icon icon={openRow ? "chevron-down" : "chevron-up"} />
          ) : (
            <></>
          )}
        </Td>
      </Tr>
      {openRow && hasLinkedDocs ? (
        <tr
          key={rowKey ? `${rowKey}-linked` : `document-${row.id}-linked`}
          css={style.subRow}
        >
          <td colSpan={6} css={{ padding: "2em !important" }}>
            {displayFastLane ? (
              <div css={[style.subRowSection, { marginBottom: "1em" }]}>
                <div
                  css={[
                    style.subRowSectionHead,
                    openRef && style.subRowSectionHeadOpen,
                  ]}
                  onClick={onToggleFastLane}
                >
                  <div css={style.subRowSectionTitle}>
                    FastLane URLs ({fastLaneUrls.length} URL(s))
                  </div>
                  <div css={style.subRowSectionIcon}>
                    <Icon icon={openFastLane ? "chevron-down" : "chevron-up"} />
                  </div>
                </div>

                <div
                  css={[
                    style.subRowSectionContent,
                    openFastLane && style.subRowSectionContentOpen,
                    !openFastLane && style.subRowSectionContentClose,
                  ]}
                >
                  {openFastLane ? (
                    <>
                      {fastLaneUrls.map((url: string, index: number) => (
                        <DocumentFastLaneRow url={url} />
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}

            {row.supplementDocuments?.length ? (
              <div css={[style.subRowSection, { marginBottom: "1em" }]}>
                <div
                  css={[
                    style.subRowSectionHead,
                    openSup && style.subRowSectionHeadOpen,
                  ]}
                  onClick={onToggleSup}
                >
                  <div css={style.subRowSectionTitle}>
                    Supplements ({row.supplementDocuments.length} document(s))
                  </div>
                  <div css={style.subRowSectionIcon}>
                    <Icon icon={openSup ? "chevron-down" : "chevron-up"} />
                  </div>
                </div>

                <div
                  css={[
                    style.subRowSectionContent,
                    openSup && style.subRowSectionContentOpen,
                    !openSup && style.subRowSectionContentClose,
                  ]}
                >
                  {openSup ? (
                    <>
                      {row.supplementDocuments.map(
                        (doc: SearchDocument, index: number) => (
                          <DocumentSupplementRow row={doc} />
                        )
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}

            {row.incorporedByReferenceDocuments?.length ? (
              <div css={style.subRowSection}>
                <div
                  css={[
                    style.subRowSectionHead,
                    openRef && style.subRowSectionHeadOpen,
                  ]}
                  onClick={onToggleRef}
                >
                  <div css={style.subRowSectionTitle}>
                    Doc. Inc. Ref. ({row.incorporedByReferenceDocuments.length}{" "}
                    document(s))
                  </div>
                  <div css={style.subRowSectionIcon}>
                    <Icon icon={openRef ? "chevron-down" : "chevron-up"} />
                  </div>
                </div>

                <div
                  css={[
                    style.subRowSectionContent,
                    openRef && style.subRowSectionContentOpen,
                    !openRef && style.subRowSectionContentClose,
                  ]}
                >
                  {openRef ? (
                    <>
                      {row.incorporedByReferenceDocuments.map(
                        (doc: SearchDocument, index: number) => (
                          <DocumentIncorporedRow row={doc} />
                        )
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </td>
        </tr>
      ) : (
        <></>
      )}
    </>
  );
};

export default DocumentRow;

export const DownloadDocument = ({
  documentId,
  documentUrl,
  documentTypes,
  documentFileType,
  showPreview,
  showDownload,
}: {
  documentId?: string;
  documentUrl: string;
  documentTypes?: string[];
  documentFileType?: string;
  showPreview?: boolean;
  showDownload?: boolean;
}) => {
  if (!documentUrl) return <></>;

  if (showPreview) {
    const typesToPreview: string[] =
      DOCUMENTS_TYPE_TO_PREVIEW?.toLowerCase()?.split(",");
    if (
      !documentId ||
      !documentTypes ||
      !typesToPreview ||
      !documentTypes.filter((value: string) =>
        typesToPreview.includes(value)
      ) ||
      (documentFileType != null && documentFileType?.toUpperCase() != "PDF")
    ) {
      showPreview = false;
    }
  }

  return (
    <>
      {showDownload || !showPreview ? (
        <div
          css={[
            `
              > button {
                min-width: 150px;

                > span > svg {
                  color: #ffffff;
                }
              }
            `,
          ]}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (canUseDOM)
                window.location.href = DOWNLOAD_ENDPOINT + documentUrl;
            }}
            wired={false}
            iconLeft="download"
            label={`Download`}
          />
        </div>
      ) : (
        <></>
      )}
      {showPreview ? (
        <div
          css={[
            css`
              > button {
                min-width: 150px;

                > span > svg {
                  color: #22aa5f !important;
                }
              }

              > button:hover {
                > span > svg {
                  color: #ffffff !important;
                }
              }
            `,
            { marginTop: showDownload ? "5px" : "0px" },
          ]}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (canUseDOM)
                window.location.href = DOCUMENT_PREVIEW_LINK + documentId;
            }}
            wired={true}
            iconLeft="eye"
            label={`Preview`}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const DocumentIncorporedRow = ({ row }: Props<SearchDocument>) => {
  if (!row) return <></>;

  return (
    <div css={style.subRowSectionItem}>
      <div css={[style.subRowSectionItemValue, { width: "30%" }]}>
        <div css={style.subLabel}>Document incorporated by reference</div>
        {row.description ? (
          <div css={style.market}>{row.description}</div>
        ) : (
          <></>
        )}
      </div>
      <div css={[style.subRowSectionItemValue, { width: "50%" }]}>
        {computeDate(row) ? `${formatDate(computeDate(row))} - ` : ""}
        {row.complement}
      </div>
      <div
        css={[style.subRowSectionItemAction, { width: "20%" }]}
        className={"action"}
      >
        <DownloadDocument
          documentId={row?.id}
          documentUrl={row?.downloadUrl}
          documentTypes={row?.categories}
          showDownload={true}
          showPreview={true}
          documentFileType={row?.format}
        />
      </div>
    </div>
  );
};

export const DocumentSupplementRow = ({ row }: Props<SearchDocument>) => {
  const hasLinkedDocs = row.incorporedByReferenceDocuments?.length > 0;
  const [openRow, setOpenRow] = React.useState<boolean>(false);
  const onToggleRow = React.useCallback(() => {
    setOpenRow((openRow) => !openRow);
  }, []);

  return (
    <>
      <div css={style.subRowSectionItem} onClick={onToggleRow}>
        <div css={[style.subRowSectionItemValue, { width: "25%" }]}>
          <div>
            {documentSubTypeLabel(row?.documentPublicTypeCode)
              ? documentSubTypeLabel(row?.documentPublicTypeCode)
              : documentSubTypeLabel(row?.documentTypeCode)}
            {row.description && row.description.length > 0
              ? ` - ${row.description}`
              : ""}
          </div>
          <div css={style.subLabel}>
            {row.incorporedByReferenceDocuments.length} Doc. Inc. Ref.
          </div>
        </div>
        <div css={[style.subRowSectionItemValue, { width: "50%" }]}>
          <div>
            {computeDate(row) ? `${formatDate(computeDate(row))} - ` : ""}
            {row.complement ? row.complement : row.name}
          </div>
          <div css={{ marginTop: "10px" }}>
            {row.hostMemberStates?.length > 0 &&
              row.hostMemberStates.map((isoCode: string, index: number) => (
                <CountryFlagIcon
                  code={isoCode}
                  size={{ height: 20, width: 30 }}
                />
              ))}
          </div>
        </div>
        <div
          css={[style.subRowSectionItemAction, { width: "20%" }]}
          className={"action"}
        >
          <DownloadDocument
            documentId={row.id}
            documentUrl={row.downloadUrl}
            documentTypes={row?.categories}
            showDownload={true}
            showPreview={true}
            documentFileType={row?.format}
          />
        </div>
        <div css={[style.subRowSectionItemAction, { width: "5%" }]}>
          {hasLinkedDocs ? (
            <Icon icon={openRow ? "chevron-down" : "chevron-up"} />
          ) : (
            <></>
          )}
        </div>
      </div>
      {hasLinkedDocs && openRow ? (
        <div css={{ borderLeft: "5px solid #22aa5f" }}>
          {row.incorporedByReferenceDocuments.map(
            (doc: SearchDocument, index: number) => (
              <DocumentIncorporedRow row={doc} />
            )
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const DocumentFastLaneRow = ({ url }: { url: string }) => {
  return (
    <div css={style.subRowSectionItem}>
      <div css={[style.subRowSectionItemIcon, { width: "10%" }]}>
        <div css={style.market}>
          <Icon icon={"link"} />
        </div>
      </div>
      <div css={[style.subRowSectionItemValue, { width: "90%" }]}>
        <div css={style.bold}>Additionnal web information link</div>
        <div css={style.labelLink}>
          <a target="_blank" href={url}>
            {url}
          </a>
        </div>
      </div>
    </div>
  );
};
