/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react';
import Props from '../base/props';
import { goToIssuerDetailPage } from '../../../IssuerCard';
import { CountryFlagIcon } from '../../../CountryFlagIcon/countryFlagIcon.component';
import { getIssuerSubType, getIssuerType } from './row';
import SearchIssuer from '../../../../../interfaces/search/search-issuer';
import { style } from './card-mobile.style';
import { Country } from '../../../../../enums';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const IssuerSearchCard = (props: Props<SearchIssuer>): JSX.Element => {
  const { card } = props;

  if (!card) return <></>;
  
  return (
    <div css={style.container}>
      <div css={style.top}>
        <div css={style.left}>I</div>
        <div css={style.subCategories}>
          { getIssuerType(card.type, card.oldType) ?
              <div css={[style.right]}>{getIssuerType(card.type, card.oldType)}</div>
              : <></>
          }
          { getIssuerSubType(card.subType, card.oldType) ?
              <div css={[style.right]}>{getIssuerSubType(card.subType, card.oldType)}</div>
              : <></>
          }
        </div>
      </div>
      <div css={style.title} 
           onClick={() => goToIssuerDetailPage(card.url, card.id)}>
        {card?.name || 'N/A'}
      </div>
      <div css={style.securities}>
        {`${card.statistics?.nbSecurities} securities`}
      </div>
      <div css={style.country}>
        <CountryFlagIcon code={card.country} size={{ width: 21, height: 21 }} />
        {card.country == "TW" ? Country.TW : countries.getName(card.country, 'en', { select: 'official' })}
      </div>
      <div css={style.row}>
        <div css={[style.col, style.colTwo]}>
          <div css={style.colTop}>LGX Labelled</div>
          <div css={style.colBottom}>{card.statistics?.nbGreens}</div>
        </div>
        {/*<div css={[style.col, style.colTwo]}>
          <div css={style.colTop}>LGX Others</div>
          <div css={style.colBottom}>
            { card.statistics?.caiAsPerCBI ?
              card.statistics?.nbSecurities - card.statistics?.nbGreens
              : 0
            }
          </div>
          </div>*/}
      </div>
    </div>
  );
};

export default IssuerSearchCard;
