import * as React from "react";

import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  RichText,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import { renderLink } from "../../utils/helper";
import tmpBg from "./background.png";

const lint = jsx; // linter hack
/** @jsx jsx */

export interface DoubleColumnsWithButtonsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const DoubleColumnsWithButtons: React.FunctionComponent<DoubleColumnsWithButtonsProps> =
  (props: DoubleColumnsWithButtonsProps): React.ReactElement => {
    const { fields } = props;

    const gradient: any = Number(getFieldValue<number>(fields, "gradient", 0));
    const topGreyGradient: any = Number(
      getFieldValue<number>(fields, "topGreyGradient", 0)
    );
    const greyGradient: any = Number(
      getFieldValue<number>(fields, "greyGradient", 0)
    );
    const invert = Number(getFieldValue<number>(fields, "invert", 0));
    const isCardTopTitle = Number(
      getFieldValue<number>(fields, "cardTopTitle", 0)
    );
    const isLargeVideo = Boolean(
      getFieldValue<boolean>(fields, "set large video", false)
    );
    const isNude: boolean = getFieldValue<boolean>(fields, "nude", false);
    const isHalf: boolean = getFieldValue<boolean>(
      fields,
      "half column size",
      false
    );
    const isWhiteText = Number(getFieldValue<number>(fields, "whiteText", 0));
    const isGreen = Number(getFieldValue<number>(fields, "isGreen", 0));
    const isDarkGreenBackground = Number(
      getFieldValue<number>(fields, "isDarkGreenBackground", 0)
    );
    const isGreenBackground = Number(
      getFieldValue<number>(fields, "isGreenBackground", 0)
    );
    const isHeadingTitle: string = getFieldValue<string>(
      fields,
      "headingTitle",
      ""
    );
    const anchorId: string = getFieldValue<string>(fields, "anchorId", "");

    const button1IsGray = Number(
      getFieldValue<number>(fields, "button 1 is Gray", 0)
    );
    const button2IsGray = Number(
      getFieldValue<number>(fields, "button 2 is Gray", 0)
    );
    const button3IsGray = Number(
      getFieldValue<number>(fields, "button 3 is Gray", 0)
    );
    const gradientType = getFieldValue<any>(fields, "gradient color", null);
    const rounded = getFieldValue<boolean>(fields, "rounded component", false);
    const smallImage: any = getFieldValue<any>(fields, "small image", null);

    const getBackground = () => {
      if (gradientType === "apple100") {
        return "linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)";
      }
      if (gradientType === "apple500") {
        return "linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)";
      }
      if (gradientType === "peach100") {
        return "linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)";
      }
      if (gradientType === "peach500") {
        return "linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)";
      }
      if (
        fields &&
        fields.componentBackground &&
        fields.componentBackground.value.src
      ) {
        return `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${fields.componentBackground.value.src}) no-repeat`;
      }
      if (isNude) {
        return "none";
      }
      if (topGreyGradient === 1) {
        return "linear-gradient(180deg, #D9E1E2 0%, #F6F9F9 100%)";
      }
      if (gradient !== 1) {
        if (greyGradient === 1) {
          return "linear-gradient(180deg, #253645 0%, #425563 100%)";
        } else {
          return "none";
        }
      }

      return "linear-gradient(270deg, #FFC300 6.1%, #FF3056 121.75%)";
    };

    const style = {
      parentContainer: css`
        width: 100%;
        max-width: ${rounded ? "1400px" : "100%"};
        padding-left: ${rounded ? "128px" : "0"};
        padding-right: ${rounded ? "128px" : "0"};
        margin-bottom: ${rounded ? "64px" : "0"};
        margin-top: ${rounded ? "64px" : "0"};
        margin-left: auto;
        margin-right: auto;

        @media (max-width: ${breakpointMax}) {
          padding-left: ${rounded ? "16px" : "0"};
          padding-right: ${rounded ? "16px" : "0"};
        }
      `,
      container: css`
        display: flex;
        flex-direction: column;
        border-radius: ${rounded ? "16px" : "0"};
        background: ${getBackground()};
        ${isGreenBackground && "background-color: #008C3D"};
        ${isDarkGreenBackground && "background-color: #005E2E"};

        a {
          color: #22aa5f;
          text-decoration: none;
        }

        background-size: cover;
        width: 100%;
        position: relative;
        padding-bottom: 40px;

        @media (max-width: ${breakpointMax}) {
          padding: 0;
          flex-direction: column;
        }
      `,
      mainContent: css`
        width: 100%;
        padding: 80px;
        padding-bottom: 0;
        max-width: 1280px;
        margin: auto;
        @media (max-width: ${breakpointMax}) {
          padding: 0;
          flex-direction: column;
        }
      `,
      containerDouble: css`
        display: flex;
        width: 100%;
        flex-direction: ${invert === 1
          ? "row-reverse"
          : isLargeVideo
          ? "column-reverse"
          : "row"};
        @media (max-width: ${breakpointMax}) {
          padding: 0;
          flex-direction: column-reverse;
        }
      `,
      containerDoubleLeft: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: ${isHalf ? "50%" : isLargeVideo ? "100%" : "50%"};
        padding-left: ${isLargeVideo ? "200px" : "40px"};
        padding-right: ${isLargeVideo ? "200px" : "40px"};
        @media (max-width: ${breakpointMax}) {
          width: 100%;
          padding-right: 16px;
          padding-left: 16px;
        }
      `,
      containerDoubleRight: css`
        display: flex;
        width: ${isHalf ? "50%" : isLargeVideo ? "100%" : "50%"};
        flex-direction: column;
        align-items: center;
        padding-left: 40px;
        padding-right: 40px;
        @media (max-width: ${breakpointMax}) {
          width: 100%;
          padding-top: 40px;
          padding-bottom: 40px;
          padding-left: 0;
          padding-right: 0;
        }
      `,
      containerDoubleTitle: css`
        color: ${isWhiteText ? "#FFFFFF" : "#425563"};
        ${isGreenBackground && "color: #cce8d8"};
        ${isDarkGreenBackground && "color: #cce8d8"};
        font-size: 3em;
        font-weight: 800;
        margin-bottom: 24px;

        @media (max-width: ${breakpointMax}) {
          font-weight: 800;
          font-size: 32px;
          line-height: 41px;
        }
      `,
      containerDoubleHeadingTitle: css`
        color: ${isWhiteText ? "#FFFFFF" : isGreen ? "#008C3D" : "#CA1234"};
        ${isGreenBackground && "color: #cce8d8"};
        ${isDarkGreenBackground && "color: #cce8d8"};
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        @media (max-width: ${breakpointMax}) {
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
        }
      `,
      containerDoubleSubTitle: css`
        color: ${isWhiteText ? "#FFFFFF" : "#425563"};
        ${isGreenBackground && "color: #cce8d8"};
        ${isDarkGreenBackground && "color: #cce8d8"};
        font-weight: 600;
        font-size: 1em;
        line-height: 150%;

        @media (max-width: ${breakpointMax}) {
          font-weight: 600;
          font-size: 14px;
          line-height: 180%;
          letter-spacing: 0.01em;
        }
      `,
      headImg: css`
        margin-bottom: 8px;

        img {
          height: 38px;
        }

        @media (max-width: ${breakpointMax}) {
          img {
            height: 30px;
          }
        }
      `,
      leftContainer: css`
        width: 100%;
        //max-width: 368px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 80px;
        position: relative;
        @media (max-width: ${breakpointMax}) {
          padding-bottom: 40px;
        }
      `,
    };

    return (
      <div css={style.parentContainer} id={anchorId}>
        <div css={style.container} id={anchorId}>
          <div css={style.mainContent}>
            <div css={style.containerDouble}>
              <div css={style.containerDoubleLeft}>
                <div css={style.leftContainer}>
                  {smallImage?.src && (
                    <div css={style.headImg}>
                      <img alt=" " src={renderLink(smallImage.src)} />
                    </div>
                  )}
                  {isHeadingTitle && (
                    <div css={style.containerDoubleHeadingTitle}>
                      <Text
                        field={
                          fields &&
                          (fields.headingTitle as SitecoreGenericTextField)
                        }
                      />
                    </div>
                  )}
                  <div css={style.containerDoubleTitle}>
                    <Text
                      field={
                        fields && (fields.title as SitecoreGenericTextField)
                      }
                    />
                  </div>
                  <div css={style.containerDoubleSubTitle}>
                    <RichText
                      field={
                        fields && (fields.subTitle as SitecoreGenericTextField)
                      }
                    />
                  </div>
                </div>
              </div>
              <div css={style.containerDoubleRight}></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
