import * as React from "react";
/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/react";
export interface VerticalLineBottomLeftProps {
  className?: string;
  size?: any;
  style?: React.CSSProperties;
}

export const VerticalLineBottomLeft: React.FunctionComponent<VerticalLineBottomLeftProps> = (
  props: VerticalLineBottomLeftProps
): React.ReactElement => {
  const { className, size, style } = props;

    return (
      <svg
        {...size}
        height="620" width="609" fill="none" 
        viewBox="0 0 609 620"
        // viewBox="0 0 602 693"
        // fill="none"
        xmlns="http://www.w3.org/2000/svg">
        style={style}
        className={className}
        <path d="M101.394 352.982V454.346" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.899" opacity="0.5" />
        <path d="M304.152 471.245V521.942" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.899" opacity="0.5" />
        <mask height="693" id="mask0_401_10449" style={{ "maskType": "luminance" }} width="609" x="0" y="-61" maskUnits="userSpaceOnUse">
          <path d="M608.305 -61H0V631.771H608.305V-61Z" fill="white" opacity="0.5"/>
        </mask>
        <g mask="url(#mask0_401_10449)" opacity="0.5">
          <path d="M101.394 488.144V631.771" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.899" />
          <path d="M33.7976 403.679V631.771" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.899" />
          <path d="M168.96 454.346V631.771" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.899" />
          <path d="M236.555 505.042V631.77" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.899" />
          <path d="M371.718 555.74V631.771" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.899" />
          <path d="M439.315 488.144V631.771" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.899" />
          <path d="M304.152 555.74V631.771" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16.899" />
        </g>
            {/* <defs>
        <clipPath id="clip0_276_2732">
          <rect
            width="791"
            height="384"
            fill="white"
            transform="translate(-189)"
          />
        </clipPath>
      </defs> */}
        </svg>

    );
};
