import gql from "graphql-tag";

export const GET_MARKET_CAPITALISATION_LUX_SHARES =  gql`
    query GetMarketCapitalisationLuxShares{
        marketCapitalisationForLuxShares{
            id
            isinCode
            price
            currency
            description
            market
            nbSecuritiesIssued
            marketCap
        }
    }
`;

export const GET_MARKET_CAPITALISATION_LUXX_COMPONENTS = gql`
    query GetMarketCapitalisationLuxXComponents{
        marketCapitalisationForLuxXComponents{
            totalMarketCap
          	listMarketCap{
                id
                isinCode
                price
                currency
                description
                market
                nbSecuritiesIssued
                marketCap
            }
            
        }
    }
`;

export const GET_MARKET_CAPITALISATION_STATISTICS = gql`
    query GetMarketCapitalisationStatistics{
        marketCapitalisationStatistics{
            nbBdlMarket
            nbEuroMTF
            totalMarketCap
        }
    }
`;


export const GET_RISERS_FALLERS_STATISTICS = gql`
    query GetRisersAndFallersStatistics{
        risersAndFallers{
            id
            isinCode
            marketVal
            description
            type

            lastPrice
            monthPrice
            yearPrice

            ytdVariation
            mtdVariation
        }
    }
`;


export const GET_MARKET_CAPITALISATION_REPORT= gql`
    query GetMarketCapitalisationReport{
        marketCapitalisationForLuxXComponents{
            id
            isinCode
            price
            currency
            description
            market
            nbSecuritiesIssued
            marketCap
        }
        marketCapitalisationForLuxShares{
            id
            isinCode
            price
            currency
            description
            market
            nbSecuritiesIssued
            marketCap
        }
    }
`;


export const GET_TOP_TRADED_SECURITIES_BY_MONTH_REPORT= gql`
    query GetTopTradedSecurityReportByMonth($type: String!,$date: String!){
        topTradedSecuritiesByMonth(type:$type,date:$date){
            id
            isinCode
            name
            turnover
            currency
            numberOfTransaction
        }
    }
`;


export const GET_TOP_TRADED_SECURITIES_BY_YEAR_REPORT= gql`
    query GetTopTradedSecurityReportByYear($type: String!,$date: String!){
        topTradedSecuritiesByYear(type:$type,date:$date){
            id
            isinCode
            name
            turnover
            currency
            numberOfTransaction
        }
    }
`;

export const GET_PDF_REPORT=gql`
    query GetPdfReport($reportName:String!,$pbiName:String,$pbiValue:String){
        getMarketStatisticsReport(reportName:$reportName,pbiName:$pbiName,pbiValue:$pbiValue)
    }
`;