
export enum LevelOneClassification {
    CGEN = 'CGEN',
    FGEN = 'FGEN',
    SGEN = 'SGEN'
}

export const levelOneClassificationLabels: { [key in LevelOneClassification]: string } = {
    CGEN: 'Corporate',
    FGEN: 'Financials',
    SGEN: 'SSA'
}

export function levelOneClassificationLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		return levelOneClassificationLabels[ value as LevelOneClassification];
	}catch (e){
		console.error("Failed to get LevelOneClassification label for '" + value + "'", e);
	}
	
	console.error("LevelOneClassification label for '" + value + "' - '" + value + "' doest exist");
		
	return value;
}