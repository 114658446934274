const types: any = {
  ADIP: "Acquisition or disposal of the issuer's own shares",
  AFAE: "Annual financial and audit reports",
  ATTC: "Amendment to the terms and conditions",
  COSO: "Consent Solicitation",
  CRAS: "Changes in rights att. to classes of shares or sec",
  D003: "Final Terms of the offer",
  D004: "Registration document Supplement",
  D005: "Registration document",
  D006: "Securities note",
  D007: "Summary note",
  D008: "Summary & Securities note",
  D010: "Prospectus",
  D020: "Prospectus",
  D035: "Addendum",
  D040: "Prospectus",
  D051: "Simplified Prospectus",
  D055: "Key Information Document",
  D060: "Base Prospectus",
  D070: "Information notice",
  D080: "Unitary Prospectus",
  D090: "Final terms (Pricing supplement)",
  D110: "Exchange offer",
  D120: "Exchange offer Supplement",
  D130: "Prospectus Supplement",
  D140: "Erratum",
  D289: "Corporate document",
  D290: "Notice - Press release",
  D318: "Document incorporated by reference",
  D455: "Publication",
  D485: "Quarterly report",
  D490: "Semi-annual report",
  D495: "Interim financial statement",
  D500: "Annual report",
  D555: "Articles of association",
  D906: "Universal Registration Document",
  DELI: "Delisting",
  DIVD: "Dividends",
  ERCS: "Early redemption / Cancellation / Delisting",
  EXOF: "Exchange offer / Tender offer",
  FIRP: "Financial report",
  GEME: "General Meeting",
  GNAN: "General announcement",
  HOMS: "Home Member State",
  HYFR: "Half yearly fcial & audit reports / ltd reviews",
  INIF: "Inside Information / Ad Hoc Information",
  MAEX: "Maturity Extension",
  MATS: "Managers' transactions",
  MERG: "Merger / Acquisition",
  MSNO: "Major shareholding notifications",
  NMCH: "Name Change",
  NWIS: "New issues",
  PATG: "Payments to governments",
  PRER: "Press release",
  PUBA: "Public Auction",
  PUOF: "Public Offer",
  QFRE: "Quarterly financial report",
  SEME: "Stock Exchange Members",
  STBB: "Stock Buy-back",
  STNO: "Stabilisation Notice",
  SUOI: "Substitution of the Issuer",
  SUSF: "Suspension lifted",
  SUSP: "Suspension",
  TABI: "Takeover Bid",
  TNVC: "Total number of voting rights and capital",
  WOME: "Withdrawal of membership",
  XOA1: "OAM Filing",
};
export const setDocumentType = (type: string) => {
  return types[type] || type;
};
