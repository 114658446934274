/** @jsx jsx */

import * as React from 'react';

import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { breakpointMax } from '../../../style';
import { css, jsx } from '@emotion/react';

import { canUseDOM } from '../../../predicates';
import { getFieldValue, Link } from '@sitecore-jss/sitecore-jss-react';
import { genericStyle } from '../UI/generic/generic.style';

const container = css`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e3e6e8;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 80px;
  @media (max-width: ${breakpointMax}) {
    padding-left: 10px;
    padding-right: 16px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      background: white;
      border-radius: 3px;
    }
  }
`;

const items = css`
  display: flex;
`;
const item = css`
  display: flex;
  align-items: center;
  
  a {
    color: #22AA5F;
    text-decoration: underline;
    font-size: 14px;
  }
  @media (max-width: ${breakpointMax}) {
    flex-wrap: nowrap;
    flex-shrink: 0;
  }
`;
const label = (isGreen: boolean, clickable: boolean) => css`
  svg {
    color: #22AA5F;
    margin-right: 16px;
  }
  display: flex;
  align-items: center;
  text-decoration-line: ${isGreen || clickable ? 'underline' : 'none'};
  color: ${isGreen || clickable ? '#22AA5F' : '#425563'};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  cursor: ${clickable ? 'pointer' : 'default'};
  //text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  &:hover {
    text-decoration: ${clickable || isGreen ? 'underline' : 'none'};
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: ${breakpointMax}) {
    svg {
      margin-right: 4px;
    }
  }
`;
const separator = css`
  svg {
    color: #a4bcc2;
    font-size: 9px;
  }
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
`;

const pathNameSearch = "/search?dataType=";

const parseRoute = (route: string) => {
  if (route && route.split('___') && route.split('___').length > 1) {
    const routeLabel = route.split('___')[1]?.replace(/-/g, ' ');
    return routeLabel?.charAt(0)?.toUpperCase() + routeLabel?.slice(1);
  }
  const routeLabel = route && route.replace(/-/g, ' ').replace('%20',' ');
  return routeLabel?.charAt(0)?.toUpperCase() + routeLabel?.slice(1);
};

const getRouteForSearchPage = (route: string) => {
  if (route === 'security') {
    return pathNameSearch + "securities";
  } else if (route === 'issuer') {
    return pathNameSearch + "issuers";
  } else if (route === 'programme') {
    return pathNameSearch + "programmes";
  }
  return '/';
}


export const BreadcrumbComponent = ({ isReadOnly = false, fields, manualBreadcrumbs = [] }: any) => {

  const isAuto: boolean = getFieldValue<boolean>(fields, 'auto mode', false);
  const isDisabled: boolean = getFieldValue<boolean>(fields, 'disable navigation', false);
  const [routes, setRoutes] = React.useState<any>([])
  const isManual = manualBreadcrumbs.length > 0;

  React.useEffect(() => {
    if (canUseDOM) {
      const routes = window.location.pathname.split('/').filter((elem: string) => elem !== '');
      setRoutes(routes);
    }
  }, []);

  let breadcrumbs: any[] = [];

  if (isManual) {
    breadcrumbs = manualBreadcrumbs;
  };

  return (
    <>
      <div css={genericStyle.desktopDisplay}>
        <div css={container}>
          <div css={items}>
            {
              isAuto ? (
                <>
                  {isDisabled ? (
                    <>
                      {
                        routes && routes.length > 0 && (
                          <>
                            <div css={item}>
                              <a href={`/`} css={label(true, true)}>
                                <Icon icon={'home'} />
                              </a>
                              <div css={separator}>
                                <Icon icon={'chevron-right'} />
                              </div>
                            </div>
                            {
                              routes.map((route: string, index: number) => (
                                <div css={item} key={`link-idx-${index}`}>
                                  {index <= routes.length - 2 && (
                                    <>
                                      {
                                        (index === 1) ? (
                                          <div css={label(false, false)}>{parseRoute(route)}</div>

                                        ) :
                                          (
                                            <><a href={getRouteForSearchPage(route)} css={label(true, true)}>
                                              {parseRoute(route)}
                                            </a><div css={separator}>
                                                <Icon icon={'chevron-right'} />
                                              </div></>
                                          )
                                      }
                                    </>
                                  )
                                  }
                                </div>
                              ))
                            }
                          </>
                        )
                      }
                    </>
                  ) :
                    <>
                      {
                        routes && routes.length > 0 && (
                          <>
                            <div css={item}>
                              <a href={`/`} css={label(true, true)}>
                                <Icon icon={'home'} />
                              </a>
                              <div css={separator}>
                                <Icon icon={'chevron-right'} />
                              </div>
                            </div>
                            {
                              routes.map((route: string, index: number) => (
                                <div css={item} key={`link-idx-${index}`}>
                                  {(index === routes.length - 1 || isReadOnly) ? (
                                    <div css={label(index === 0, false)}>{parseRoute(route)}</div>
                                  ) :
                                    (
                                      <a href={`/${routes.slice(0, index + 1).join('/')}`} css={label(index === 0, true)}>
                                        {parseRoute(route)}
                                      </a>
                                    )}

                                  {index < routes.length - 1 && (
                                    <div css={separator}>
                                      <Icon icon={'chevron-right'} />
                                    </div>
                                  )}
                                </div>
                              ))
                            }
                          </>
                        )
                      }
                    </>
                  }
                </>
              ) : isManual ? (
                <>
                  <div css={item}>
                    <a href={`/`} css={label(true, true)}>
                      <Icon icon={'home'} />
                    </a>
                    <div css={separator}>
                      <Icon icon={'chevron-right'} />
                    </div>
                  </div>
                  {breadcrumbs.map((breadcrumb, index) => (
                    <div css={item} key={`breadcrumb-${index}`}>
                      {breadcrumb.link ? (
                        <a href={breadcrumb.link} css={label(true, true)}>
                          {breadcrumb.label}
                        </a>
                      ) : (
                        <div css={label(false, false)}>{breadcrumb.label}</div>
                      )}
                      {index < breadcrumbs.length - 1 && (
                        <div css={separator}>
                          <Icon icon={'chevron-right'} />
                        </div>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div css={item}>
                    <a href={`/`} css={label(true, true)}>
                      <Icon icon={'home'} />
                    </a>
                    <div css={separator}>
                      <Icon icon={'chevron-right'} />
                    </div>
                  </div>
                  {
                    new Array(5).fill(0).map((arr: any, index: number) => {
                      if (fields && fields[`link ${index + 1} label`]?.value) {
                        return (
                          <div css={item}>
                            {
                              fields[`link ${index + 1} page link`]?.value.href ? (
                                <Link target={'_self'} title={'link'} field={fields[`link ${index + 1} page link`]} css={{ textDecoration: 'none' }}>
                                  {fields[`link ${index + 1} label`]?.value}
                                </Link>
                              ) : (
                                <div>
                                  {fields[`link ${index + 1} label`]?.value}
                                </div>
                              )
                            }
                            {fields[`link ${index + 2} label`].value && (
                              <div css={separator}>
                                <Icon icon={'chevron-right'} />
                              </div>
                            )}
                          </div>
                        )
                      }
                    })
                  }
                </>
              )
            }
          </div>
        </div>
      </div>
    </>
  )


};
