import gql from "graphql-tag";

export const issuerSearchItemFragment = gql`
  fragment issuerSearchItemFragment on Tier {
    id
    name
    url
    statistics {
      nbEquities
      nbProgs
      nbBonds
      nbGreens
      nbWarrants
      nbFundClasses
      nbCertificates
      nbSecurities
      alignmentPercent
      managementCompanyName
      caiAsPerCBI
    }
    type: issuerType
    subType: issuerSubType
    oldType: type
    country: countryCode2
    cbiSectors
    cbiSubSectors
    climateAligned
    climateAlignedAlignmentLevel
    climateAlignedAlignmentPercent
    climateAlignedEvaluationYear
  } 
`;

export const issuerSearchFilterFragment = gql`
  fragment issuerSearchFilterFragment on LuxseSearchIssuersFilters {
    lgxOnly
    climateAlignedOnly
    types {
      name
      count
    }
    issuerTypes {
      name 
      count
      issuerSubTypes{
        name
        count
      }
    }
    countries {
      name
      count
    }
  }
`;

export const issuerSearchFullFragment = gql`
  ${issuerSearchItemFragment}
  ${issuerSearchFilterFragment}
  fragment issuerSearchFullFragment on LuxseSearchIssuersResults {
    totalHits
    issuers {
      ...issuerSearchItemFragment
    }
    filters {
      ...issuerSearchFilterFragment
    }
  }
`;

export const issuerSearchNoFilterFragment = gql`
  ${issuerSearchItemFragment}
  fragment issuerSearchNoFilterFragment on LuxseSearchIssuersResults {
    totalHits
    issuers {
      ...issuerSearchItemFragment
    }
  }
`;

export const issuerSearchEmptyFragment = gql`
  fragment issuerSearchEmptyFragment on LuxseSearchIssuersResults {
    totalHits
  }
`;