import * as React from 'react';

import { CSSReset, Icon, ThemeProvider, theme } from '@bdl-cmn-shared-packages-npm/design-system';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { AuthenticationProvider } from '../hooks';
import MobileMenu from '../components/MobileMenu/mobileMenu';
import { useReducer } from 'react';

/** @jsx jsx */



//TODO: Insert ProductColorModeProvider when fixed in design system






export interface AppProps extends RouteComponentProps {
  children: React.ReactNodeArray | React.ReactNode;
}

export const MobileMenuContext = React.createContext<any>({});
export const GreenDataContext = React.createContext<any>({});

export const AppCore: React.FC<AppProps> = (props: AppProps): React.ReactElement => {
  const { children } = props;
  const reducer = (state: boolean, action: boolean) => {
    return action;
  };
  const greenReducer = (state: [], action: any) => {
    return action;
  };

  const [visible, setVisible] = useReducer(reducer, false);
  const [greenData, setGreenData] = useReducer(greenReducer, []);

  const onOverlayClick = () => {
    setVisible(!visible);
  };

  const contentOverlay = css`
    position: fixed;
    opacity: ${visible ? '1' : '0'};
    z-index: 39;
    ${!visible && 'pointer-events: none;'};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(7px);
    transition: all 0.3s ease-in-out;
    @media (min-width: ${breakpoint}) {
      display: none;
    }
  `;

  const rootStyle = css`
    //position: fixed;
    display: flex;
    overflow: auto;
    //margin-left: $ {visible ? "0" : "-300px"};
    //width: calc(100% + 300px);
    // min-width: calc(100% + 300px);
    //max-width: calc(100% + 300px);
    overflow-x: hidden;
    height: 100%;
    transition: all 0.3s ease-in-out;
  `;

  const upToTopStyle = css`
    width: 50px;
    height: 50px;
    margin: 50px;
    z-index: 5;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgb(0 0 0 / 40%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.25s ease-out;
    :hover {
      background: #f6f8f9;
    }
    svg {
      color: #22aa5f;
      width: 20px !important;
      height: 20px !important;
    }
  `;

  const [showUpButton, setshowUpButton] = React.useState(false);

  const handleVisibleButton = () => {
    const position = window.pageYOffset;

    if (position > 800) {
      if (!showUpButton) return setshowUpButton(true);
    } else if (position < 800) {
      if (showUpButton) return setshowUpButton(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);
    return () => {
      window.removeEventListener('scroll', handleVisibleButton);
    };
  });

  return (
    <AuthenticationProvider>
      <ThemeProvider theme={theme}>
        <GreenDataContext.Provider
          value={{
            greenData,
            actionDispatch: (d: any) => {
              //setGreenData(d)
              //window.localStorage.setItem("greenBonds", d);
            },
          }}
        >
          <MobileMenuContext.Provider
            value={{
              visible,
              actionDispatch: () => {
                setVisible(!visible);
              },
            }}
          >
            <CSSReset />
            <div className='app-box' css={rootStyle}>
              <div css={contentOverlay} onClick={onOverlayClick} />
              <MobileMenu visible={visible} closeMenu={onOverlayClick} />
              <main style={{ width: '100%' }}>
                <div
                  css={[
                    upToTopStyle,
                    css`
                      opacity: ${showUpButton ? '1' : '0'};
                      bottom: ${showUpButton ? '0' : '-50px'};
                    `,
                  ]}
                  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                  <Icon icon={'arrow-up'} />
                </div>
                {children}
              </main>
            </div>
          </MobileMenuContext.Provider>
        </GreenDataContext.Provider>
      </ThemeProvider>
    </AuthenticationProvider>
  );
};

export const App = withRouter(AppCore);
