import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Icon, Table, Td, Th, Thead, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import { sharedStyle } from '../../../../../../styles/shared';
import { MarketdataModel } from '../../market-data-view.component';
import LatestSuspensionsRow from './latest-suspensions-row.component';

export const LatestSuspensionsView = (props: any) => {
  const suspensions = props.suspensionsData;
  return (
    <Table>
      {/* {suspensions?.totalHits > 5 && <div css={sharedStyle.gradientTop} />} */}
      <tbody>
        {suspensions?.totalHits > 0
          ? suspensions?.currentData?.map((suspensionsRow: MarketdataModel, index: number) => (
              <LatestSuspensionsRow suspensionsRow={suspensionsRow} key={`suspension-key-${index}`} />
            ))
          : 'no data'}
      </tbody>
    </Table>
  );
};
export default LatestSuspensionsView;
