/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useState } from 'react';
import Props from '../base/props';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from './card-mobile.style';
import { goToProgrammeDetailPage } from '../../../ProgrammeCard';
import { computeTypeLabel } from './row';
import SearchProgramme from '../../../../../interfaces/search/search-programme';
import { formatToThousandsSeparator } from '../../../../../utils';
import SearchIssuer from '../../../../../interfaces/search/search-issuer';
import { Collapse } from 'react-collapse';

export const ProgrammeSearchCard = (props: Props<SearchProgramme>): JSX.Element => {
  const { card } = props;

  const [multipleIssuerOpen, setMultipleIssuerOpen] = useState<boolean>(true);
  
  if (!card) return <></>;

  return (
    <div css={style.container}>
      <div css={style.top}>
        <div css={style.left}>P</div>
        <div css={style.right}>{computeTypeLabel(card.type)}</div>
      </div>
      <div css={style.title} onClick={() => goToProgrammeDetailPage(card.url, card.id)}>
        {card?.name || 'N/A'}
      </div>
      <div css={[style.section, style.sectionFullWidth ]}>
        { ! card.issuers?.length ?
          <span css={[style.dateValue, style.noIssuer]}>No issuer</span>
          :
          <>
            { (card.issuers?.length == 1) ?
              <span css={style.dateValue}>{card.issuers[0].name}</span>
              :
              <>
                <div css={[style.dateValue, style.multipleIssuer]} onClick={() => setMultipleIssuerOpen(!multipleIssuerOpen)}>
                  <span>Multiple issuers <Icon icon={multipleIssuerOpen ? 'chevron-up' : 'chevron-down'}/></span>
                </div>
                <Collapse isOpened={! multipleIssuerOpen}>
                  <div>
                    <ul css={style.mutipleIssuerList}>
                      { card.issuers.map((issuer: SearchIssuer, index: number) => (
                        <li css={style.mutipleIssuerItem}>{issuer.name}</li>
                      ))}
                    </ul>
                  </div>
                </Collapse>
              </>
            }
          </>
        }
      </div>
      <div css={style.dateSection}>
        <span css={style.dateLabel}>Securities</span>
        <span css={style.dateValue}> {card?.activeSecurities || 'N/A'}</span>
      </div>
      <div
        css={[
          style.dateSection,
          css`
            border-top: none;
            border-bottom: none;
            border-radius: 8px;
            margin-top: 0;
          `,
        ]}
      >
        <span css={style.dateLabel}>Max. Amount</span>
        <span css={style.dateValue}>
          { card?.amountIssued ? 
            `${formatToThousandsSeparator(card.amountIssued)} ${card.amountCurrency}`
            : 'N/A' }
        </span>
      </div>
    </div>
  );
};

export default ProgrammeSearchCard;
