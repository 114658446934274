export enum DocumentTypeEnum {
	LUXSE = 'Securities documents',
	OAM = 'OAM Storage', 
	FNS = 'Notices (FNS)',
	CSSF = 'CSSF approvals',
	CSSF_REGULATED_INFORMATION = '', //'CSSF regulated informations'
}

export function documentTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		const index = Object.keys(DocumentTypeEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(DocumentTypeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get DocumentTypeEnum label for '" + value + "'", e);
	}
		
	return value;
}