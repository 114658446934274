/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import bg from "./bg.svg";
import {
  Carousel,
  CarouselItem,
} from "../../core/components/Carousel/carousel.component";
import { canUseDOM } from "../../predicates";
import { SitecoreGenericTextField } from "../../interfaces";
import { breakpointMax } from "../../style";

export interface BlogVideosCarouselProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  background-image: url(${bg}),
    linear-gradient(180deg, #f18700 0%, #f7b766 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  @media (max-width: ${breakpointMax}) {
    background-image: linear-gradient(180deg, #f18700 0%, #f7b766 100%);
  }
`;
const lint = jsx; // linter hack
const image = (image: string) => css`
  width: 100%;
  background-image: url(${image});
  height: 160px;
  //min-height: 250px;
  background-repeat: no-repeat;
  background-size: 110%;
  background-position: center;
  background-color: #d9dee2;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  transition: all 150ms ease-in-out;
`;
const container = css`
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 40px;
  padding-right: 40px;
  @media (max-width: ${breakpointMax}) {
    padding-right: 0;
    padding-left: 0;
  }
`;
const itemContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${breakpointMax}) {
    padding-left: 20px;
  }

  &:hover {
    & > .video-thumb {
      opacity: 0.6;
    }

    div {
      color: #ffffff;
    }
  }
`;

const itemDescription = css`
  min-height: 160px;
  padding-top: 32px;
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
  color: #425563;
  cursor: pointer;
  margin-bottom: 32px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #425563;
  }

  &:hover {
    color: #ffffff;

    .video-thumb {
      background: red;
      opacity: 0.6;
    }
  }

  transition: all 150ms ease-in-out;
`;
const title = css`
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 56px;
  color: #253845;
  text-align: center;

  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
    color: #253845;
  }
`;
const subTitle = css`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #425563;
  margin-top: 8px;
  margin-bottom: 64px;
  text-align: center;

  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
    color: #425563;
  }
`;

export const BlogVideosCarousel: React.FunctionComponent<BlogVideosCarouselProps> =
  (props: BlogVideosCarouselProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const videos: any = [];

    new Array(9).fill(0).map((arr: any, index: number) => {
      if (
        fields &&
        fields[`video ${index + 1} id`] &&
        fields[`video ${index + 1} id`].value
      ) {
        videos.push({
          id: fields[`video ${index + 1} id`].value,
          label: fields[`video ${index + 1} title`].value,
        });
      }
    });

    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={title}>
            <Text
              field={fields && (fields.title as SitecoreGenericTextField)}
            />
          </div>
          <div css={subTitle}>
            <Text
              field={
                fields && (fields["sub title"] as SitecoreGenericTextField)
              }
            />
          </div>
          <Carousel
            items={videos}
            renderItem={({ item, isSnapPoint }) => (
              <CarouselItem key={item.id} isSnapPoint={isSnapPoint}>
                <div css={itemContainer}>
                  <div
                    css={image(
                      `https://img.youtube.com/vi/${item.id}/hqdefault.jpg`
                    )}
                    onClick={() =>
                      canUseDOM &&
                      window.open(
                        `https://www.youtube.com/watch?v=${item.id}`,
                        "_blank"
                      )
                    }
                    className="video-thumb"
                  />
                  <div
                    css={itemDescription}
                    onClick={() =>
                      canUseDOM &&
                      window.open(
                        `https://www.youtube.com/watch?v=${item.id}`,
                        "_blank"
                      )
                    }
                  >
                    {item.label}
                  </div>
                </div>
              </CarouselItem>
            )}
          />
        </div>
      </div>
    );
  };
