import * as React from "react";

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpointMax } from "../../../style";
import { css, jsx } from "@emotion/react";
import { fetchData, getDataWithChild } from "../../../utils/helper";
import ICalendarLink from "react-icalendar-link";
import Loader from "../Loader/loader.component";
import { formatDate, getCurrentMoment, parseToMoment } from "../../../utils/date";

/** @jsx jsx */

export interface EventComponentProps {
  title?: string;
  subTitle?: string;
  description?: string;
  day?: string;
  month?: string;
  white?: boolean;
  nextEvent?: boolean;
  embed?: boolean;
  isPastEvent? : boolean;
}

const style = {
  container: (noBorder?: boolean, hidden = false) => css`
      margin-top: 80px;
      display: flex;
      max-width: 300px;
      flex-direction: column;
      visibility: ${hidden ? 'hidden' : 'visible'};
      border: ${noBorder ? "none" : "1px solid #D9E1E2"}
      border-radius: 8px;
      align-self: stretch;
    
      @media (max-width: ${breakpointMax}) {
        margin: 0;
        width: 100%;
        padding: 16px;
        max-width: 100%;
        margin-top: 0
      }
    `,
  title: css`
    margin-bottom: 48px;
    width: 100%;
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    text-align: center;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 32px;
      line-height: 41px;
      text-align: center;
      margin-top: 40px;
    }
  `,
  content: css`
    width: 100%;
    display: flex;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  left: css`
    width: 55%;
    display: flex;
    margin-left: 120px;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      margin: 0;
      width: 100%;
      padding: 16px;
    }
  `,
  picture: css`
    width: 100%;
    @media (max-width: ${breakpointMax}) {
      img {
        width: inherit;
      }
    }
  `,
  subTitle: css`
    width: 100%;
    font-weight: 800;
    font-size: 30px;
    line-height: 38px;
    margin-top: 32px;
    margin-bottom: 16px;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
    }
  `,
  description: css`
    width: 100%;
    color: #354450;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
    }
  `,
  day: css`
    color: #ca1234;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
  `,
  pastDay: css`
    color: #A4BCC2;
    font-weight: 800;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
  `,
  month: css`
    color: #253645;
    text-align: center;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  `,
  eventTitle: css`
    color: #253645;
    text-align: center;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  `,
  eventSubTitle: css`
    color: #253645;
    font-size: 16px;
    line-height: 31px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  `,
  event: css`
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  `,
  addAction: css`
    margin-top: 16px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    align-items: center;
  `,
};

export const EventComponent: React.FunctionComponent<EventComponentProps> = (
  props: EventComponentProps
): React.ReactElement => {
  const { title, subTitle, embed, description, day, month, white, nextEvent, isPastEvent } =
    props;
  const [event, setEvent] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(true);
  const [hideEvent, setHideEvent] = React.useState<boolean>(false);
  const [eventIcs, setEventIcs] = React.useState<any>(null);

  const getDataFromItemId = async (id: string) => {
    try {
      const request = await fetchData(id);
      const data = await getDataWithChild(request, ["Speakers", "Types"]);
      dissociateEvents(data);
    } catch (err) {
      console.error("resource err", err);
    }
  };
  const dissociateEvents = (data: any) => {
    const futureEvent = data.filter((events: any) =>
      events.children
          .find((event: any) => parseToMoment(event['End date']).add(100, 'days').isAfter(getCurrentMoment()))
    );

    if (futureEvent.length > 0) {
      if (futureEvent[0].children && futureEvent[0].children[0]) {
        const evt = futureEvent[0].children[0];
        setEvent(futureEvent[0].children[0]);
        setEvent({
          day: formatDate(evt.Date, "DD"),
          title: evt.Title,
          description: evt.Description,
          subTitle: "",
          month: formatDate(evt.Date, "MMMM"),
          white: true,
          isPastEvent: false,
        });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setHideEvent(true);
    }
  };
  React.useEffect(() => {
    if (nextEvent) {
      getDataFromItemId("3FE49801-613A-4AD0-B8C6-83B906ABFBD7");
    } else {
      setLoading(false);
    }
  }, [nextEvent]);

  React.useEffect(() => {
    
  }, [eventIcs]);

  const calendar = (white?: boolean, embed?: boolean) => css`
    background: ${white ? "#ffffff" : "#F8FAFC"};
    border-radius: 8px;
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    flex-grow: 1;
    opacity: ${isPastEvent ? "0.5" : ""};
    filter: ${embed
      ? "drop-shadow(0px 16px 24px rgba(37, 54, 69, 0.15))"
      : "none"};
  `; 

  return (
    <div css={style.container(false, hideEvent)}>
      <Loader loading={loading} centered>
        <div css={calendar(white, embed)}>
          <div css={isPastEvent ? style.pastDay : style.day}>{event.day || day}</div>
          <div css={style.month}>{event.month || month}</div>
          <div css={style.eventTitle}>{event.title || title}</div>
          <div css={style.eventSubTitle}
            dangerouslySetInnerHTML={{ __html: event.subTitle || subTitle || "" }}
          >
          </div>
          {
            (!embed && eventIcs) && (
              <div css={style.addAction}>
                <ICalendarLink event={eventIcs} >
                  <Button label={'Add to my calendar'} iconLeft={'calendar-plus'} variant='secondary' />
                </ICalendarLink>
              </div>
            )
          }
        </div>
      </Loader>
    </div>
  );
};