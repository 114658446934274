/** @jsx jsx */

import * as React from 'react';

import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { SitecoreGenericTextField } from '../../interfaces';
import hexa from './hexa.svg';
import { renderLink } from '../../utils/helper';

export interface TwoColumnsWithCourseProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  @media (max-width: ${breakpointMax}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
`;
const side = css`
  width: 50%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const title = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  color: #ffffff;
  @media (max-width: ${breakpointMax}) {
    font-size: 32px;
    line-height: 31px;
  }
`;
const description = css`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin-top: 16px;
  margin-bottom: 24px;
  color: #ffffff;
  @media (max-width: ${breakpointMax}) {
    font-size: 14px;
    line-height: 180%;
  }
`;
const action = css`
  width: 100%;
`;
const flex = css`
  display: flex;
  align-items: center;
`;
const flexTitle = css`
  display: flex;
  padding-left: 16px;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
`;
const card = css`
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(37, 54, 69, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 48px 24px;
  max-width: 380px;
`;
const sideContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  @media (max-width: ${breakpointMax}) {
    padding: 28px;
  }
`;
const cardTitle = css`
  font-weight: 700;
  font-size: 22px;
  line-height: 31px;
  color: #354450;
`;
const cardHeading = css`
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  color: #22AA5F;
`;
const cardDescription = css`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
  margin-top: 8px;
  margin-bottom: 8px;
  @media (max-width: ${breakpointMax}) {
    font-size: 14px;
  line-height: 180%;
  }
`;
const cardAction = css`
  margin-top: 8px;
  a {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #22AA5F;
  }
`;
const headerBigMenuItemLogo = css`
  background-image: url(${renderLink(hexa)});
  background-repeat: no-repeat;
  background-size: 100%;
  height: 64px;
  width: 58px;
  display: flex;
  justify-content: center;
  color: #22AA5F;
  align-items: center;
  font-size: 18px;
`;

export const TwoColumnsWithCourse: React.FunctionComponent<TwoColumnsWithCourseProps> = (
  props: TwoColumnsWithCourseProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const background: any = getFieldValue<any>(fields, 'background', null);
  const buttonLabel: string = getFieldValue<string>(fields, 'button label', '');
  const buttonLink: string = getFieldValue<string>(fields, 'button link', '');
  const buttonIcon: string = getFieldValue<string>(fields, 'button icon', '');
  const linkLabel: string = getFieldValue<string>(fields, 'link course label', '');
  const linkUrl: string = getFieldValue<string>(fields, 'link course url', '');

  const parentContainer = css`
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${background && background.src});
    background-size: cover;
    background-repeat: no-repeat;
    padding: 64px;
    @media (max-width: ${breakpointMax}) {
      padding: 0px;
    }
  `;
  return (
    <div css={parentContainer}>
      <div css={container}>
        <div css={side}>
          <div css={sideContainer}>
            <div css={title}>
              <Text field={fields && (fields.title as SitecoreGenericTextField)} />
            </div>
            <div css={description}>
              <Text field={fields && (fields.description as SitecoreGenericTextField)} />
            </div>
            <div css={action}>
              <Button
                variant={'primary'}
                label={buttonLabel}
                onClick={() => (buttonLink ? (window.location.href = buttonLink) : console.log)}
                iconRight={(buttonIcon as any) || 'chevron-right'}
              />
            </div>
          </div>
        </div>
        <div css={side}>
          <div css={sideContainer}>
            <div css={card}>
              <div css={flex}>
                <div css={headerBigMenuItemLogo}>
                  <Icon icon={fields && fields[`icon course`] && (fields && (fields[`icon course`] as any)).value} />
                </div>
                <div css={flexTitle}>
                  <Text field={fields['main title course'] as SitecoreGenericTextField} />
                </div>
              </div>
              <div css={cardHeading}>
                <Text field={fields['heading course'] as SitecoreGenericTextField} />
              </div>
              <div css={cardTitle}>
                <Text field={fields['title course'] as SitecoreGenericTextField} />
              </div>

              <div css={cardDescription}>
                <Text field={fields['description course'] as SitecoreGenericTextField} />
              </div>
              <div css={cardAction}>
                <a href={linkUrl}>{linkLabel}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
