/** @jsx jsx */

import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpoint, breakpointMax } from "../../../style";
import { css, jsx } from "@emotion/react";

import { HowToListBanner } from "../HowToListBanner";
import { HowToListDocument } from "../HowToListDocument";
import { Link } from "react-router-dom";
import { canUseDOM } from "../../../predicates";
import hexa from "./hexa.svg";
import hexaBG from "./hexabg.png";
import { renderLink } from "../../../utils/helper";

export interface HowToListResultProps {
  data?: any;
  fields?: any;
  selectedItems?: any;
  showLgx?: boolean;
}

const container = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    min-width: auto;
    margin: 0;
  }
`;

const containerTimelineBorder = css`
  width: 1px;
  background: #425563;
  height: 100%;
  position: absolute;
  //margin-left: 210px;
  top: 0;
  bottom: 0;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;

const containerTimelineBorderFirst = (empty?: boolean) => css`
  width: ${empty ? "0" : "1px"};
  background: #425563;
  height: 100%;
  position: absolute;
  //margin-left: 210px;
  top: 80px;
  bottom: 0;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const containerTimelineBorderLast = css`
  width: 1px;
  background: #425563;
  height: 80px;
  position: absolute;
  //margin-left: 210px;
  top: 0;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const containerTimelineHexa = css`
  width: 48px;
  height: 48px;
  position: absolute;
  z-index: 2;
  background: url(${renderLink(hexa)});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -24px;
  margin-top: 80px;

  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const containerTimelineContentRightImage = css`
  width: 200px;
  height: 200px;
  background: url(${renderLink(hexaBG)});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
`;

const containerTimeline = (color = 'inherit') => css`
  min-width: 100%;
  min-height: 160px;
  display: flex;
  position: relative;
  max-width: 1200px;
  background-color: ${color};
  margin-right: auto;
  margin-left: auto;
`;
const containerTimelineOddParent = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f6f8f9;
  padding-bottom: 40px;
`;
const containerTimelineOdd = css`
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  min-height: 160px;
  display: flex;
  position: relative;
`;
const containerTimelineContent = css`
  min-height: 160px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;

  
  @media (max-width: ${breakpointMax}) {
    margin-left: 0;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const title = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #cb1234;
  margin-top: 88px;
  @media (max-width: ${breakpointMax}) {
    margin-top: 16px;
  }
`;
const chapter = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
  margin-top: 72px;
`;
const details = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
  margin-top: 24px;
  padding-right: 40px;

  a {
    color: #22aa5f;
    font-weight: 600;
  }
`;
const prospectus = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
  margin-top: 24px;
  padding-right: 40px;

  a {
    color: #22aa5f;
  }
`;
const button = css`
  margin-top: 42px;
  margin-bottom: 80px;
`;
const containerTimelineContentLeft = css`
  //width: calc(100% - 200px);
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 210px;

  @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding-left: 0;
  }
`;
const containerTimelineContentRight = css`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 48px;

  @media (max-width: ${breakpointMax}) {
    width: 100%;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const HowToListResult: React.FunctionComponent<HowToListResultProps> = (
  props: HowToListResultProps
): React.ReactElement => {
  const { data, selectedItems, showLgx, fields } = props;

  React.useEffect(() => {
    canUseDOM && window.scrollTo(0, 0);
  }, []);

  return (
    <div css={container}>
      <HowToListBanner data={selectedItems} />
      {
        data && data.prospectus && (
          <div css={containerTimeline}>
            <div css={containerTimelineContent}>
              <div css={containerTimelineBorderFirst(data.empty)} />
              <div css={containerTimelineHexa} />
              <div css={containerTimelineContentLeft}>
                <div css={title}>{data.isSol ? 'Information notice' : data.empty ? "Find out more" : "Prospectus"}</div>
                <div css={prospectus}>
                  <div css={details} dangerouslySetInnerHTML={{ __html: !data.isSol ? data.prospectus : data.mandatoryDocuments && data.mandatoryDocuments[2] && data.mandatoryDocuments[2].coverText }} />
                </div>

                <div css={button}>
                  {!data.hideButton && (
                    <Button
                      css={css`
                        &:hover {
                          background-color: #0d8e46 !important;
                        }
                        background-color: #22aa5f !important;
                      `}
                      label={"Find out more about the e-Prospectus platform"}
                      iconRight={"arrow-up"}
                      onClick={() =>
                        canUseDOM &&
                        window.open(
                      "https://www.cssf.lu/en/Document/e-prospectus/",
                      "_blank"
                        )
                      }
                    />
                  )}
                </div>
              </div>
              {/*
               <div css={containerTimelineContentRight}>
                  <div css={containerTimelineContentRightImage} />
              </div>
               */}
            </div>
          </div>
          )
      }

      {!data.empty && (
        <div css={containerTimelineOddParent}>
          <div css={containerTimelineOdd}>

            <div css={containerTimelineContent}>
              <div css={containerTimelineBorderFirst(data.empty)} />
              <div css={containerTimelineHexa} />
              <div css={containerTimelineContentLeft}>
                <div css={title}>Mandatory documents</div>
                {data &&
                  data.mandatoryDocuments &&
                  data.mandatoryDocuments.slice(0,2).map((doc: any, index: number) => (
                    <React.Fragment key={`doc-${index}`}>
                      {index === 0 && (
                        <div css={details}>
                          {data.mandatoryText}{" "}
                          <a href={data.mandatoryLink}>bolide@luxse.com</a>
                        </div>
                      )}
                      <div css={chapter}>{`${index + 1}. ${doc.title}`}</div>
                      <HowToListDocument
                        title={
                          index === 2
                            ? doc.title
                            : index === 0
                            ? "The application form"
                            : "The letter of undertaking"
                        }
                        label={
                          index === 2
                            ? doc.buttonText
                            : index > 0
                            ? "Download letter of undertaking"
                            : "Download application form"
                        }
                        type={doc.type}
                        link={doc.link}
                        hideButton={doc?.hideButton}
                        coverText={doc.coverText}
                        image={fields && fields[doc.imageField]?.value?.src}
                      />
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
          <div css={containerTimeline}>
            <div css={containerTimelineContent}>
              <div
                  css={
                    showLgx || data.newCardTitle
                        ? containerTimelineBorder
                        : containerTimelineBorderLast
                  }
              />
              <div css={containerTimelineHexa} />
              <div css={containerTimelineContentLeft}>
                <div css={title} dangerouslySetInnerHTML={{ __html: data.rulesTextTitle}} />
                <div css={prospectus} dangerouslySetInnerHTML={{ __html: data.rulesText}} />
                <HowToListDocument
                  title={data.rulesTextTitle}
                  label={data.rulesButtonText}
                  type={"doc.type"}
                  link={data.documentLink}
                  extraSentence={data.extraSentence}
                  image={fields && fields[data.rulesImage]?.value?.src}
                />
              </div>
              {/*
                                  <div css={containerTimelineContentRight}>
                                     <div css={containerTimelineContentRightImage} />
                                 </div>
                                  */}
            </div>
          </div>
          {data && data.newCardTitle && (
            <div css={containerTimeline('#FFFFFF')}>

              <div css={containerTimelineContent}>
                <div
                    css={
                      showLgx
                          ? containerTimelineBorder
                          : containerTimelineBorderLast
                    }
                />
                <div css={containerTimelineHexa} />
                <div css={containerTimelineContentLeft}>
                  <div css={title}>
                    Guidelines for DLT Financial Instruments
                  </div>
                  <div css={chapter}></div>
                  <HowToListDocument
                    title={data.newCardTitle}
                    label={"Download Guidelines"}
                    type={""}
                    link={data.newCardLink}
                    hideButton={false}
                    image={fields && fields[data.imageField]?.value?.src}
                  />
                </div>
              </div>
            </div>
          )}
          {showLgx && (
            <div css={containerTimelineOdd}>
              <div css={containerTimelineBorderLast} />
              <div css={containerTimelineHexa} />
              <div css={containerTimelineContent}>
                <div css={containerTimelineContentLeft}>
                  <div css={title}>Luxembourg Green Exchange (LGX)</div>
                  <div css={prospectus}>
                    Is the security that you are listing green, social or
                    sustainable?
                    <br />
                    <br />
                    <a href={"/discover-lgx/how-to-join-lgx"} title={"More info on LGX"}>
                      How to join LGX
                    </a>
                    <br />
                    <br />
                    Once you have completed the applicable listing requirements
                    and process, you can then take the next step in your
                    sustainable finance journey by declaring your security as
                    green, social or sustainable and our LGX team will take over
                    the verification process from there.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div css={container}>{/* <HowToListIndicator /> */}</div>
    </div>
  );
};
