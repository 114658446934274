import gql from "graphql-tag";


export const GET_LUXSE_REPORTS = gql`
    query GetLuxseReports(
        $query: String,
        $ids: [String!],
        $types: [String!],
        $subTypes: [String!],
        $fromDate: Date,
        $toDate: Date,
        $usePageable: Boolean!,
        $page: Int!,
        $size: Int!,
        $sorts: [String!]
    ) {
        luxseReports(
            query: $query,
            ids: $ids,
            types: $types,
            subTypes: $subTypes,
            fromDate: $fromDate,
            toDate: $toDate,
            usePageable: $usePageable,
            page: $page,
            size: $size,
            sorts: $sorts
        ) {
            pageNumber
            pageSize
            resultSize
            resultTotalSize
            resultList {
                id
                type
                subType
                date
                name
                label
                documentType
                downloadUrl
            }
        }
    }
`;
