import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { style } from './results.style';
import { Security, YieldsCalculation } from "../../../../interfaces";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { formatToThousandsSeparator } from "../../../../utils";
import ReactApexChart from 'react-apexcharts';

export interface YieldCalculatorResultsProps {
    securityData: Security
    yieldsCalculation: YieldsCalculation | null;
}

const YieldCalculatorResults = ({
    securityData,
    yieldsCalculation
}: YieldCalculatorResultsProps) => {
    const currency = securityData?.currency;

    const graphProps: any = React.useMemo(() => {
        let years: string[] = [], 
            interests: string[] = [], 
            investment: string[] = [], 
            repayment: string[] = [],
            initial: string[] = [];
        
        let yieldOverYears = yieldsCalculation?.yieldOverYears ? yieldsCalculation.yieldOverYears : [];

        yieldOverYears.forEach((yieldYearData, index) => {
            years.push(yieldYearData.year.toString());
            if( index === 0 ){
                initial.push(yieldYearData.investment.toFixed(3));
                repayment.push(null);
                investment.push(null);
            }else if( index === yieldOverYears.length - 1 ){
                initial.push(null);
                repayment.push(yieldYearData.investment.toFixed(3));
                investment.push(null);
            }else {
                initial.push(null);
                repayment.push(null);
                investment.push(yieldYearData.investment.toFixed(3));
            }
            interests.push(yieldYearData.interests.toFixed(3));
        });

        if (!yieldsCalculation?.yieldOverYears) {
            years = ["First", "second", "Third"]
        }

        let graph : any = {
            options: {
                xaxis: {
                    categories: years,
                },
                yaxis: {
                    labels: {
                        formatter: function (value: any) {
                            return isNaN( value ) ? value : Math.round(Number(value));
                        },
                    },
                },
                chart: {
                    type: "bar",
                    height: 400,
                    stacked: true,
                },
                colors: [  
                    "rgba(241, 135, 0, 0.6)", 
                    "rgba(0, 156, 196, 0.6)",
                    "rgba(18, 202, 100, 0.6)",
                    "rgba(170, 170, 170, 0.6)" 
                ],
                dataLabels: {
                  enabled: false
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '60%'
                    }
                },
            },
            series: [
                { name: 'Initial investment (incl.accrued interest)', data: initial },
                { name: 'Final repayment value', data: repayment },
                { name: 'Theorical value of investment based on the buying price', data: investment },
                { name: 'Cumulative interests', data: interests },
            ],
        };

        return graph;
    }, [yieldsCalculation])

    return (
        <div css={style.container}>
            <div css={style.header}>
                <div css={style.headerItem}>
                    <div css={style.headerItemLabel}>Total cash flows in {currency}</div>
                    <div css={style.headerItemValue}>
                        { yieldsCalculation?.totalCash ?
                            formatToThousandsSeparator(Math.round( yieldsCalculation?.totalCash * 1e3 ) / 1e3 ) 
                            : "-"
                        }
                    </div>
                </div>
                <div css={style.headerItem}>
                    <div css={style.headerItemLabel}>Final Re-payment in {currency}</div>
                    <div css={style.headerItemValue}>
                        { yieldsCalculation?.totalRePayment ?
                            formatToThousandsSeparator(Math.round( yieldsCalculation?.totalRePayment * 1e3 ) / 1e3) 
                            : "-"
                        }
                    </div>
                </div>
                <div css={style.headerItem}>
                    <div css={style.headerItemLabel}>Net result in {currency}</div>
                    <div css={style.headerItemValue}>
                        { yieldsCalculation?.netResult ?
                            formatToThousandsSeparator(Math.round( yieldsCalculation?.netResult * 1e3 ) / 1e3) 
                            : "-"
                        }
                    </div>
                </div>

                <div css={style.ytm}>
                    <div css={style.ytmIcon}>
                        <Icon icon={"bolt"} />
                    </div>
                    <div css={style.ytmItem}>
                        <div css={style.ytmItemLabel}>YTM</div>
                        <div css={style.ytmItemValue}>
                            { yieldsCalculation?.averageYield ?
                                `${formatToThousandsSeparator(Math.round( yieldsCalculation?.averageYield * 1e3 ) / 1e3)} %`
                                : "-"
                            }
                        </div>
                    </div>
                </div>
            </div>

            
            <ReactApexChart
                options={graphProps.options}
                series={graphProps.series}
                type="bar"
                height={400}
            />
            {/*
            <ColumnGraph
                key={JSON.stringify(graphProps)}
                legend={true}
                chartHeight={400}
                displayLabels={false}
                labels={[]}
                stacked={true}
                graphColors={[  
                    "rgba(241, 135, 0, 0.6)", 
                    "rgba(0, 156, 196, 0.6)",
                    "rgba(18, 202, 100, 0.6)",
                    "rgba(170, 170, 170, 0.6)" 
                ]}
                {...graphProps}
            />
            */}
        </div>
    );
};

export default YieldCalculatorResults;