import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { PriceInformation as PriceInformationModel } from "../../../../../interfaces";
import { formatToThousandsSeparator } from "../../../../../utils";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";

export interface PriceInformationProps {
  price?: PriceInformationModel
  negative?: Boolean
}

export default ({
  price, negative
}: PriceInformationProps) => {
  if (!price) {
    return <div>N/A</div>;
  }
  const { currency, marker, amount } = price;
  const displayedCurrency = currency ? ( currency === "PERCENT" ? "%" : currency ) : "";

  return (<div>
    <span style={{marginRight: "5px"}}>
      <Icon icon={negative ? 'chevron-down' : 'chevron-up'} />
    </span>
    
    {`${formatToThousandsSeparator(amount)} ${marker? marker : ""} ${displayedCurrency}`}
  </div>);
}
