import { css } from '@emotion/react';
import { breakpointMax } from '../../../../../style';

export const style = {
  title: css`
    left: 0px;
    top: 8px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #253845;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  `,
  fnsTitle: css`
    //width: 375px;
    //height: 14px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 150%;
    color: #253845;
    margin: 0px;
  `,
  fnsText: css`
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #54666f;
    margin: 5px 0px 5px 0px;
    display: flex;
    gap: 8px;
  `,

  downloadIcon: css`
    width: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #22aa5f;
  `,

  fnsDate: css`
    //width: 435px;
    //height: 14px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 150%;
    color: #354450;
    margin: 0px;
  `,
  tabsContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 0px;
    gap: 24px;
    background: #425563;
    flex-wrap: wrap;
    @media (max-width: ${breakpointMax}) {
      padding-left: 8px;
      padding-right: 8px;
      gap: 8px;
    }
  `,
  btnTabs: css`
    flex: none !important;
    order: 1 !important;
    flex-grow: 0 !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    background-color: #354450 !important;
    border: 1px solid white !important;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  mainContent: css`
    background: #f0f5f5;
  `,
  tab: css`
    border: none;
  `,
  tablePart: css`
    table {
      width: 100%;
      border-spacing: 5px;
    }
  `,
  displaySettings: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 32px 0;

    @media (max-width: ${breakpointMax}) {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 8px;
      & > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
          border-left: 1px solid #e3e6e8;
          height: 50px;
          font-size: 16px;
        }
        & div {
          width: 100%;
        }
      }
    }

    > div:first-of-type {
      border-right: 1px solid #e3e6e8;
    }

    svg {
      color: #22aa5f;
      margin-right: 12px;
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 16px;
    }

    img {
      height: 16px;
      margin-right: 12px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    div {
      display: flex;
      align-items: center;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  `,
  settingsText: css`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #354450;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-right: 8px;
    @media (max-width: ${breakpointMax}) {
      margin-bottom: 14px;
    }
  `,
  settingsBtn: css`
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450 !important;
    flex: none;
    order: 1;
    flex-grow: 0;
    background-color: white !important;
    margin: 0px 3px;
  `,

  cardContainerBodyFull: css`
    width: inherit;
    height: 27.5em;
  `,

  cardContainerBodyContent: css`
    width: inherit;
    height: 460px;
    overflow-y: auto;
    overflow-x: hidden;
  `,

  cardContainerBodyLink: css`
    max-height: 4em;
    width: 100%;
    margin-top: 10px;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    :hover {
      background: #f6f8f9;
      transition: all 0.1s ease-in-out;
    }
  `,

  emptyResult: css`
    text-align: center;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 150%;
  `,
}