import { breakpoint, breakpointMax } from '../../style';

import { css } from '@emotion/react';
import hexaFade from './hexaFade.svg';
import { renderLink } from '../../utils/helper';

export const style = {
  background: css`
    width: 100%;
    background: url(${renderLink(hexaFade)});
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    min-height: 600px;
  `,
  container: css`
    width: 100%;
    height: 100%;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 96px;
    display: flex;
    flex-direction: column;
  `,
  content: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    align-items: center;
  `,
  caroussel: css`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -160px;
    @media (max-width: ${breakpointMax}) {
      img {
        max-width: 130%;
      }
    }
  `,
  left: css`
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding-right: 96px;
  `,
  right: css`
    width: 50%;
  `,
  title: css`
    color: #354450;
    font-size: 48px;
    font-weight: 800;
    line-height: 61px;
    margin-top: 36px;
    @media (max-width: ${breakpointMax}) {
      font-size: 24px;
      font-weight: 800;
      line-height: 100%;
    }
  `,
  description: css`
    color: #354450;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.01em;
    max-width: 600px;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 15px;
    margin-right: 15px;
  `,
  action: css`
    margin-top: 32px;
  `,
  logo: css`
    margin-top: 32px;
    @media (max-width: ${breakpointMax}) {
      align-items: center;
      display: flex;
      justify-content: center;
      img {
        max-width: 75%;
      }
    }
  `,
};
