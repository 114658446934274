import * as React from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Icon, Td, Tr} from '@bdl-cmn-shared-packages-npm/design-system';
import {formatDate} from '../../../../../../utils/date';
import {sharedStyle} from "../../../../../../styles/shared";
import {tradingStyle} from '../../trading-data.style';
import {documentSubTypeLabel} from '../../../../../../enums/document-sub-type';
import Utils from '../../../../../utils/utils';


export const LatestFnsRow = (props: any) => {
    const {fnsRow, index} = props;

    return (
        <Tr onRowClick={() => Utils.documentAction(fnsRow?.id, fnsRow?.downloadUrl, fnsRow?.categDoc)}
            css={sharedStyle.tableRow}>
            <Td>
                <div>
                    <div css={tradingStyle.fnsTitle}>{fnsRow?.complement}</div>
                    <div css={tradingStyle.fnsText}>{fnsRow?.description}</div>
                    <div css={tradingStyle.fnsText}>{documentSubTypeLabel(fnsRow?.documentPublicTypeCode)}</div>
                    <div css={tradingStyle.fnsDate}> {formatDate(fnsRow?.publishDate, 'DD/MM/YY HH:mm:ss')}</div>
                </div>
            </Td>
            <Td>
                <div css={tradingStyle.downloadIcon}>
                    <Icon icon={'download'}/>
                </div>
            </Td>
        </Tr>
    );
};
export default LatestFnsRow;
