import * as React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";

import { style } from "./index.style";
import { InputField, Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { SearchQuerySub } from "../shared";
import { withRouter } from "react-router-dom";
import { Suggestion } from "./suggestion";
import {sharedStyle} from "../../../../styles/shared";
import { canUseDOM } from "../../../../predicates";

export interface SearchInputProps {
  onSearchInputUpdate: (textInput: string) => void;
  routeTextInput: string;
  history: any;
}

export const SearchInputContent: React.FunctionComponent<any> = ({
  onSearchInputUpdate,
  routeTextInput,
  history,
  dataType
}: any) => {
  const [textInput, setTextInput] = React.useState<string>(routeTextInput);
  const [suggestionOpen, setSuggestionOpen] = React.useState<boolean>(false);
  const [visibilityTimer, setVisibilityTimer] = React.useState<boolean>(false);

  React.useEffect(() => {
    setTextInput(routeTextInput);
    setSuggestionOpen(false);
  }, [routeTextInput]);

  React.useState(() => {
    setTimeout(() => {
      setVisibilityTimer(true);
    });
  });

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextInput(event.target.value);
    if (event.target.value.length >= 3) {
      setSuggestionOpen(true);
    } else{
      setSuggestionOpen(false);
    }
  };

  const formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //onSearchInputUpdate(textInput);

    var url = `/search?q=${textInput}`;
    if( canUseDOM ){
      if(dataType === 'Issuer'){
        url=window.location.pathname;
        var link=window.location.pathname.split('/');
        url=link[link.length-4]+`/search?dataType=issuers&q=${textInput}`;
        window.location.href=url;
      }else if(dataType === 'Security'){
        url=window.location.pathname;
        var link=window.location.pathname.split('/');
        url=link[link.length-4]+`/search?dataType=securities&q=${textInput}`;
        window.location.href=url;
      }else if(dataType === 'Programme'){
        url=window.location.pathname;
        var link=window.location.pathname.split('/');
        url=link[link.length-4]+`/search?dataType=programmes&q=${textInput}`;
        window.location.href=url;
      }else{
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("q", textInput);
        url = window.location.pathname + '?' + searchParams.toString();
      }
    }
    history.push(url);
    setSuggestionOpen(false);
  };

  return (
      <>
        <div css={sharedStyle.hideMobile}>
          <div css={style.searchInput}>
            <form
                onSubmit={formSubmitHandler}
                css={style.searchInputForm}
                role="search"
            >
              <div css={style.searchInputWrapper}>
                <div css={style.searchInputField}>
                  <div css={style.input(visibilityTimer)}>
                    <InputField
                        placeholder="Search by ISIN, Issuer..."
                        onChange={inputChangeHandler}
                        value={textInput}
                    />
                  </div>
                  <Suggestion open={suggestionOpen} 
                              onClose={() => setSuggestionOpen(false)} 
                              textInput={textInput} />
                </div>
                <Button
                    label="Search"
                    iconRight="chevron-right"
                    css={style.searchInputButton}
                    variant="secondary"
                    type="submit"
                />
              </div>

            </form>
          </div>
        </div>
        <div css={sharedStyle.hideDesktop}>
          <form
              onSubmit={formSubmitHandler}
              css={style.searchInputForm}
              role="search"
          >
            <div css={style.searchInputWrapper}>
              <div css={style.searchInputField}>
                <div css={style.input(visibilityTimer)}>
                  <InputField
                      helperText=""
                      placeholder="Search by ISIN, issuer, ..."
                      textError=""
                      type="text"
                      width="100%"
                      value={textInput}
                      //onChange={inputChangeHandler}
                      onClickTrailingIcon={inputChangeHandler}
                      trailingIcon={'arrow-right'}
                  />
                </div>
                <Suggestion open={suggestionOpen} 
                            onClose={() => setSuggestionOpen(false)} 
                            textInput={textInput} />
              </div>
            </div>

          </form>
        </div>
      </>
  );
}

export const SearchInput = withRouter(SearchInputContent);
