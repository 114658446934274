import * as React from 'react';

import { ComponentParams, ComponentRendering } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../../style';
import { css, jsx } from '@emotion/react';

import avatar from '../../../sitecore-components/LinkedArticle/Ellipse.png';
import { renderLink } from '../../../utils/helper';

/** @jsx jsx */

export interface HomeComingNextComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const style = {
  author: css`
    display: flex;
    color: #8daeb7;
    line-height: 150%;
    font-size: 12px;
    margin-top: 24px;
    align-items: flex-start;
    @media (max-width: ${breakpointMax}) {
      flex-flow: wrap;
      justify-content: center;
    }
  `,
  authorLeft: css`
    width: 40px;
  `,
  authorRight: css`
    width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    span {
      display: flex;
      flex: none;
      padding-left: 2px;
      padding-right: 2px;
    }
  `,
  avatar: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  relatedSubject: css`
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    color: #354450;
  `,
  separator: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2px;
    padding-right: 2px;
  `,
};

export const Author: React.FunctionComponent<any> = (props: HomeComingNextComponentProps): React.ReactElement => {
  const { fields, params, rendering } = props;

  return (
    <div css={style.author}>
      <div css={style.authorLeft}>
        <div css={style.avatar}>
          <img src={renderLink(avatar)} alt={"image"} />
        </div>
      </div>
      <div css={style.authorRight}>
        <div css={style.separator}>By</div>
        <span>Julie Vichi</span>
        <div css={style.separator}>
          <span>in</span>
        </div>
        <div css={style.relatedSubject}>
          <span>Sustainable finance</span>
        </div>
        <div css={style.separator}>•</div>
        <span>1 minute read</span>
        <div css={style.separator}>•</div>
        <span> 24 Feb.</span>
      </div>
    </div>
  );
};
