/** @jsx jsx */

import React, { Component } from "react";
import { Td, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import { genericTradingDataStyle } from "../../DataMarket/TradingData/Shared/generic-trading-data.style";
import { sharedStyle } from "../../../../styles/shared";
import { style } from "../market-statistics.style";
import { Fragment } from 'react';
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { canUseDOM } from "../../../../predicates";
import RoundTag from "../../RoundTag/roundTag.component";
export class GenericRisersAndFallersDataRow extends Component<any, any> {

    render() {

        const data = this.props.rowsData;
        const cols = this.props.columns;
        let compteur = 1;


        const computeVariationStyle = (amount: number, marker: string) => {

            return (
                <Fragment>
                    {
                        <p style={{ color: amount <= 0 ? "#CB1234" : "#22AA5F", }}>
                            <span>{amount} {marker}</span>&nbsp;
                            {
                                amount <= 0 ? (
                                    <Icon icon={"chevron-down"} />
                                ) : (
                                    <Icon icon={"chevron-up"} />
                                )
                            }
                        </p>
                    }
                </Fragment>
            )
        };

        const goToSecurityDetailPage = (isin: string, id: string) => {
            if (canUseDOM) window.open(`/security/${isin}/${id}`, '_self');
        };

        return data?.length > 0 ? data.map((rd: any) => {
            compteur = compteur + 1;
            return (
                <tr
                    css={style.tableRow} key={compteur}
                    onClick={() => goToSecurityDetailPage(rd["isinCode"], rd["id"])}>
                    {
                        cols.map(({ accessor }: any) => {
                            const ac = accessor.replace(',', '');
                            const tData = rd[ac];
                            return <td key={accessor}>
                                {
                                    ac === "mtdVariation" || ac === "ytdVariation" ?
                                    computeVariationStyle(Number(Number(tData).toFixed(2)), '%') :
                                    ac === "type" ?
                                    <div css={sharedStyle.cardContainerBodyDoubleLeft}>
                                        <RoundTag small textColor={'#425563'}>
                                            E
                                        </RoundTag>
                                    </div>
                                    :
                                    <p css={ac === "lastPrice" ?css`font-weight:bold;`:''}>{tData}</p>
                                }
                            </td>
                        })
                    }
                </tr>

            );
        }
        ) : ''

    }
}

export default GenericRisersAndFallersDataRow;
