
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface IndustryInnovationAndInfrastructureSvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const IndustryInnovationAndInfrastructureSvg: React.FunctionComponent<IndustryInnovationAndInfrastructureSvgProps> = ({ size: { width, height }, style }: IndustryInnovationAndInfrastructureSvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 54 50'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>9 - Industry, Innovation and Infrastructure</title>
            <path d='M5.98808 4.0722C5.98808 2.20046 5.1678 0.843262 3.02761 0.843262C0.868773 0.843262 0.0298462 2.02149 0.0298462 3.89322V5.67548C0.0298462 7.65534 0.812844 8.6546 2.27817 8.6546C3.13574 8.6546 3.65028 8.2631 3.95602 7.67399H3.97466V10.2765C3.97466 11.0073 3.68756 11.5256 2.95677 11.5256C2.22597 11.5256 1.95751 11.0968 1.95751 10.3474V9.54572H0.100689V10.187C0.100689 11.9693 0.742001 13.2184 2.99032 13.2184C5.13052 13.2184 5.98808 11.8462 5.98808 9.95586V4.0722ZM3.97466 5.92902C3.93738 6.60762 3.65401 7.03267 3.01269 7.03267C2.31918 7.03267 2.05073 6.60389 2.05073 5.82089V3.71798C2.05073 2.9499 2.3341 2.5062 3.03134 2.5062C3.70994 2.5062 3.97466 2.96854 3.97466 3.71798V5.92902Z' fill='white'/>
            <path d='M39.9925 35.4369L32.8598 31.3206V23.0879C32.8598 22.9127 32.7666 22.7523 32.6175 22.6666L25.2424 18.4086C25.0932 18.3228 24.9068 18.3228 24.7576 18.4086L17.3826 22.6666C17.2334 22.7523 17.1402 22.9127 17.1402 23.0879V31.3243L10.0075 35.4406C9.85832 35.5264 9.76511 35.6867 9.76511 35.8619V44.378C9.76511 44.5532 9.85832 44.7136 10.0075 44.7993L17.3826 49.0573C17.4571 49.1021 17.5429 49.1207 17.6249 49.1207C17.7107 49.1207 17.7927 49.0983 17.8673 49.0573L25 44.941L32.129 49.0573C32.2036 49.1021 32.2893 49.1207 32.3714 49.1207C32.4534 49.1207 32.5392 49.0983 32.6137 49.0573L39.9888 44.7993C40.138 44.7136 40.2312 44.5532 40.2312 44.378V35.8582C40.2349 35.6867 40.1454 35.5226 39.9925 35.4369ZM24.5153 43.5316L18.5981 40.1162L24.5153 36.7009V43.5316ZM31.4019 31.6039L25.4884 35.0193V28.1848L31.4019 31.6039ZM24.5153 35.0156L18.6018 31.6002L24.5153 28.1848V35.0156ZM25.9732 35.8582L32.3751 32.1632L38.777 35.8582L32.3751 39.5532L25.9732 35.8582ZM31.8867 30.7613L25.9732 27.3459L31.8867 23.9306V30.7613ZM24.0268 27.3422L18.1134 30.7575V23.9306L24.0268 27.3422ZM17.1402 47.7896L10.7383 44.0946V36.7009L17.1402 40.3996V47.7896ZM18.1134 47.7896V40.9589L24.0268 44.3743L18.1134 47.7896ZM39.2655 44.0946L32.8635 47.7896V40.3996L39.2655 36.7009V44.0946Z' fill='white'/>
            <path d='M9.16479 1.12305H9.92915V5.67562H9.16479V1.12305Z' fill='white'/>
            <path d='M11.0514 2.81581V5.67562H10.4064V1.12305H11.1596L11.9836 3.73304V1.12305H12.6212V5.67562H11.95L11.0514 2.81581Z' fill='white'/>
            <path d='M15.3803 2.34602V4.45265C15.3803 5.14989 15.0932 5.67562 14.2729 5.67562H13.0909V1.12305H14.2729C15.0894 1.12305 15.3803 1.64132 15.3803 2.34602ZM14.1797 5.04922C14.4966 5.04922 14.6159 4.86279 14.6159 4.58688V2.20433C14.6159 1.93587 14.4966 1.74945 14.1797 1.74945H13.859V5.05295H14.1797V5.04922Z' fill='white'/>
            <path d='M18.0723 1.12305V4.56824C18.0723 5.27294 17.7703 5.72782 16.991 5.72782C16.1857 5.72782 15.8501 5.27294 15.8501 4.56824V1.12305H16.6145V4.63908C16.6145 4.90754 16.7226 5.09024 16.991 5.09024C17.2595 5.09024 17.3676 4.90754 17.3676 4.63908V1.12305H18.0723Z' fill='white'/>
            <path d='M18.5011 4.69478V4.0833H19.2133V4.70597C19.2133 4.95951 19.3289 5.10865 19.5899 5.10865C19.8322 5.10865 19.9404 4.94833 19.9404 4.70597V4.53073C19.9404 4.26973 19.8322 4.1094 19.5973 3.88569L19.1462 3.43453C18.7099 3.00575 18.5086 2.74475 18.5086 2.21902V2.06615C18.5086 1.50314 18.8367 1.06689 19.5824 1.06689C20.3468 1.06689 20.6488 1.44348 20.6488 2.09971V2.46883H19.9515V2.07733C19.9515 1.81633 19.8322 1.68956 19.5899 1.68956C19.3736 1.68956 19.2282 1.80888 19.2282 2.06615V2.15936C19.2282 2.41291 19.3699 2.56205 19.5899 2.7783L20.0783 3.26302C20.4959 3.68434 20.6973 3.94161 20.6973 4.44497V4.66496C20.6973 5.28763 20.3617 5.73132 19.5824 5.73132C18.8031 5.7276 18.5011 5.29135 18.5011 4.69478Z' fill='white'/>
            <path d='M20.8762 1.12305H22.912V1.74572H22.2819V5.67189H21.5101V1.74572H20.8762V1.12305Z' fill='white'/>
            <path d='M24.0641 3.58763V5.67562H23.2998V1.12305H24.4146C25.1864 1.12305 25.4698 1.50709 25.4698 2.14467V2.73006C25.4698 3.2185 25.2946 3.50933 24.8583 3.61L25.5891 5.67935H24.7837L24.0641 3.58763ZM24.0641 1.74572V3.16257H24.3177C24.5787 3.16257 24.6943 3.02834 24.6943 2.78599V2.11484C24.6943 1.86503 24.5787 1.74572 24.3177 1.74572H24.0641Z' fill='white'/>
            <path d='M27.2371 3.94184V5.67562H26.4728V3.94184L25.6189 1.12305H26.4168L26.8792 2.84937H26.8866L27.349 1.12305H28.0798L27.2371 3.94184Z' fill='white'/>
            <path d='M28.8777 5.0043L28.4899 6.02966H28.0612L28.1544 4.94092H28.8777V5.0043Z' fill='white'/>
            <path d='M30.082 1.12305H30.8464V5.67562H30.082V1.12305Z' fill='white'/>
            <path d='M31.9687 2.81581V5.67562H31.3236V1.12305H32.0768L32.9008 3.73304V1.12305H33.5384V5.67562H32.8673L31.9687 2.81581Z' fill='white'/>
            <path d='M34.6532 2.81581V5.67562H34.0082V1.12305H34.7614L35.5854 3.73304V1.12305H36.223V5.67562H35.5518L34.6532 2.81581Z' fill='white'/>
            <path d='M36.6927 4.50108V2.2975C36.6927 1.58534 37.0432 1.0708 37.8486 1.0708C38.6614 1.0708 39.0119 1.58907 39.0119 2.2975V4.50108C39.0119 5.20577 38.6614 5.72777 37.8486 5.72777C37.0432 5.72777 36.6927 5.20577 36.6927 4.50108ZM38.2438 4.62039V2.17818C38.2438 1.90227 38.1245 1.70838 37.8486 1.70838C37.5801 1.70838 37.4608 1.90227 37.4608 2.17818V4.62039C37.4608 4.8963 37.5801 5.09019 37.8486 5.09019C38.1245 5.09019 38.2438 4.8963 38.2438 4.62039Z' fill='white'/>
            <path d='M41.9314 1.12305L41.0663 5.67562H40.2349L39.3698 1.12305H40.1491L40.6711 4.29978H40.6786L41.2155 1.12305H41.9314Z' fill='white'/>
            <path d='M41.8233 5.67562L42.6808 1.12305H43.5533L44.4183 5.67562H43.6316L43.4899 4.84415H42.6771L42.5429 5.67562H41.8233ZM42.7815 4.23267H43.393L43.091 2.40567H43.0835L42.7815 4.23267Z' fill='white'/>
            <path d='M44.3438 1.12305H46.3796V1.74572H45.7494V5.67189H44.9776V1.74572H44.3475V1.12305H44.3438Z' fill='white'/>
            <path d='M46.7673 1.12305H47.5317V5.67562H46.7673V1.12305Z' fill='white'/>
            <path d='M48.0089 4.50108V2.2975C48.0089 1.58534 48.3594 1.0708 49.1648 1.0708C49.9776 1.0708 50.3281 1.58907 50.3281 2.2975V4.50108C50.3281 5.20577 49.9776 5.72777 49.1648 5.72777C48.3594 5.72777 48.0089 5.20577 48.0089 4.50108ZM49.56 4.62039V2.17818C49.56 1.90227 49.4407 1.70838 49.1648 1.70838C48.8963 1.70838 48.777 1.90227 48.777 2.17818V4.62039C48.777 4.8963 48.8963 5.09019 49.1648 5.09019C49.4407 5.09019 49.56 4.8963 49.56 4.62039Z' fill='white'/>
            <path d='M51.4392 2.81581V5.67562H50.7942V1.12305H51.5473L52.3713 3.73304V1.12305H53.0089V5.67562H52.3378L51.4392 2.81581Z' fill='white'/>
            <path d='M9.04178 12.8831L9.89935 8.33057H10.7718L11.6369 12.8831H10.8501L10.7084 12.0517H9.89562L9.76139 12.8831H9.04178ZM10 11.4365H10.6115L10.3095 9.60946H10.302L10 11.4365Z' fill='white'/>
            <path d='M12.5652 10.0233V12.8831H11.9202V8.33057H12.6734L13.4974 10.9406V8.33057H14.135V12.8831H13.4638L12.5652 10.0233Z' fill='white'/>
            <path d='M16.8941 9.55353V11.6602C16.8941 12.3574 16.607 12.8831 15.7867 12.8831H14.6048V8.33057H15.7867C16.6033 8.33057 16.8941 8.84511 16.8941 9.55353ZM15.6935 12.2567C16.0105 12.2567 16.1298 12.0703 16.1298 11.7944V9.41185C16.1298 9.14339 16.0105 8.95696 15.6935 8.95696H15.3729V12.2605H15.6935V12.2567Z' fill='white'/>
            <path d='M18.1022 8.33057H18.8665V12.8831H18.1022V8.33057Z' fill='white'/>
            <path d='M19.9851 10.0233V12.8831H19.3401V8.33057H20.0932L20.9172 10.9406V8.33057H21.5548V12.8831H20.8837L19.9851 10.0233Z' fill='white'/>
            <path d='M22.0284 8.33057H23.8218V8.95324H22.7964V10.2545H23.5608V10.8734H22.7964V12.8794H22.0321V8.33057H22.0284Z' fill='white'/>
            <path d='M24.9516 10.7951V12.8831H24.1872V8.33057H25.302C26.0738 8.33057 26.3572 8.71461 26.3572 9.35219V9.93758C26.3572 10.426 26.182 10.7168 25.7457 10.8175L26.4765 12.8869H25.6712L24.9516 10.7951ZM24.9516 8.95324V10.3701H25.2051C25.4661 10.3701 25.5817 10.2359 25.5817 9.9935V9.32236C25.5817 9.07255 25.4661 8.95324 25.2051 8.95324H24.9516Z' fill='white'/>
            <path d='M26.6853 12.8831L27.5429 8.33057H28.4154L29.2804 12.8831H28.4937L28.352 12.0517H27.5392L27.4049 12.8831H26.6853ZM27.6436 11.4365H28.2551L27.953 9.60946H27.9456L27.6436 11.4365Z' fill='white'/>
            <path d='M29.5228 11.9023V11.2908H30.2349V11.9135C30.2349 12.167 30.3505 12.3162 30.6115 12.3162C30.8539 12.3162 30.962 12.1558 30.962 11.9135V11.7382C30.962 11.4772 30.8539 11.3169 30.619 11.0932L30.1678 10.6421C29.7316 10.2133 29.5302 9.95227 29.5302 9.42654V9.27367C29.5302 8.71066 29.8583 8.27441 30.604 8.27441C31.3684 8.27441 31.6704 8.651 31.6704 9.30723V9.67635H30.9732V9.28113C30.9732 9.02013 30.8539 8.89336 30.6115 8.89336C30.3952 8.89336 30.2498 9.01267 30.2498 9.26994V9.36315C30.2498 9.6167 30.3915 9.76584 30.6115 9.9821L31.0999 10.4668C31.5175 10.8881 31.7189 11.1454 31.7189 11.6488V11.8687C31.7189 12.4914 31.3833 12.9351 30.604 12.9351C29.8248 12.9351 29.5228 12.4989 29.5228 11.9023Z' fill='white'/>
            <path d='M31.965 8.33057H34.0008V8.95324H33.3707V12.8794H32.5989V8.95324H31.9687V8.33057H31.965Z' fill='white'/>
            <path d='M35.1529 10.7951V12.8831H34.3885V8.33057H35.5034C36.2752 8.33057 36.5586 8.71461 36.5586 9.35219V9.93758C36.5586 10.426 36.3833 10.7168 35.9471 10.8175L36.6779 12.8869H35.8725L35.1529 10.7951ZM35.1529 8.95324V10.3701H35.4065C35.6675 10.3701 35.783 10.2359 35.783 9.9935V9.32236C35.783 9.07255 35.6675 8.95324 35.4065 8.95324H35.1529Z' fill='white'/>
            <path d='M39.2282 8.33057V11.7758C39.2282 12.4805 38.9262 12.9353 38.1469 12.9353C37.3416 12.9353 37.006 12.4805 37.006 11.7758V8.33057H37.7703V11.8466C37.7703 12.1151 37.8785 12.2978 38.1469 12.2978C38.4154 12.2978 38.5235 12.1151 38.5235 11.8466V8.33057H39.2282Z' fill='white'/>
            <path d='M39.698 11.7086V9.50502C39.698 8.79286 40.0335 8.27832 40.8389 8.27832C41.6853 8.27832 41.9202 8.74812 41.9202 9.41926V9.95617H41.223V9.3596C41.223 9.07623 41.1297 8.9159 40.8538 8.9159C40.5779 8.9159 40.4661 9.10979 40.4661 9.3857V11.8279C40.4661 12.1038 40.5816 12.2977 40.8538 12.2977C41.126 12.2977 41.223 12.1225 41.223 11.854V11.0151H41.9202V11.8018C41.9202 12.4543 41.6517 12.9353 40.8389 12.9353C40.0335 12.9353 39.698 12.4133 39.698 11.7086Z' fill='white'/>
            <path d='M42.1141 8.33057H44.1499V8.95324H43.5198V12.8794H42.748V8.95324H42.1178V8.33057H42.1141Z' fill='white'/>
            <path d='M46.7599 8.33057V11.7758C46.7599 12.4805 46.4579 12.9353 45.6786 12.9353C44.8732 12.9353 44.5377 12.4805 44.5377 11.7758V8.33057H45.302V11.8466C45.302 12.1151 45.4101 12.2978 45.6786 12.2978C45.9471 12.2978 46.0552 12.1151 46.0552 11.8466V8.33057H46.7599Z' fill='white'/>
            <path d='M47.9941 10.7951V12.8831H47.2297V8.33057H48.3445C49.1164 8.33057 49.3997 8.71461 49.3997 9.35219V9.93758C49.3997 10.426 49.2245 10.7168 48.7882 10.8175L49.519 12.8869H48.7137L47.9941 10.7951ZM47.9941 8.95324V10.3701H48.2476C48.5086 10.3701 48.6242 10.2359 48.6242 9.9935V9.32236C48.6242 9.07255 48.5086 8.95324 48.2476 8.95324H47.9941Z' fill='white'/>
            <path d='M49.8434 8.33057H51.6704V8.95324H50.6115V10.2545H51.3684V10.8734H50.6115V12.2567H51.6704V12.8794H49.8434V8.33057Z' fill='white'/>
        </svg>
    );
};
