/** @jsx jsx */
import { jsx, css } from '@emotion/react';

interface CountItemProps {
  name: string;
  count: number;
}

const containerCss = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const nameCss = css`
  text-transform: capitalize;
  flex-shrink: 0;
  flex-grow: 3;
  width: 60%;
`;

const countCss = css`
  text-align: right;
`;
export default function CountItem({ name, count }: CountItemProps) {
  return (
    <div css={containerCss}>
      <div css={nameCss}>{name}</div>
      <div css={countCss}>{`(${count})`}</div>
    </div>
  );
}
