import {css} from "@emotion/react";

export const style = {
    centeredContent: css`
      width: 100%;
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      min-height: 100%;
    `,
    inline: css`
      display: flex;
      padding-top: 8px;
      align-items: center;
    `,
    name: css`
        line-height: 61px;
      font-size: 48px;
      font-weight: 800;
    `,
    subTitle: css`
        font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    `,
    tag: css`
      padding-left: 24px;
     color: #22AA5F;
      font-size: 12px;
      font-weight: 600;
    `,
    right: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 24px;
      min-height: 120px;
      color: #FFFFFF;
    `,
}
