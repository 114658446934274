import React, {Component} from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {TradingNewsFilterEnum} from ".";
import { Thead, Tr, Th } from '@bdl-cmn-shared-packages-npm/design-system';

const  TradingNewsHeader = (props:any) => {
    const type = props?.type;
    const isBBOType: boolean = (TradingNewsFilterEnum.equities === type || TradingNewsFilterEnum.fixedIncome === type || TradingNewsFilterEnum.funds === type );
    return (
        <Thead>
            <Tr>
                <Th>Type</Th>
                <Th>Security</Th>
                <Th>Listing date</Th>
                {isBBOType ?
                    <>
                        <Th>Bid</Th>
                        <Th>Ask</Th>
                    </>    
                    : <></>
                }
                <Th>Last</Th>
            </Tr>
        </Thead>
    );
}

export default TradingNewsHeader;
