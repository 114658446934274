/** @jsx jsx */
import {jsx} from '@emotion/react';
import {sharedStyle} from "../../styles/shared";
import {Icon} from "@bdl-cmn-shared-packages-npm/design-system";
import { Fragment } from 'react';

export const computePriceStyle = (amount: number, marker: string) => {

    return (
        <Fragment>
            {
                amount != 0 ? (
                    <p style={{color: amount < 0 ? "#CB1234" : "#22AA5F",}}>
                        <span>{amount} {marker}</span>&nbsp;
                        {
                            amount < 0 ? (
                                <Icon icon={"chevron-down"}/>
                            ) : (
                                <Icon icon={"chevron-up"}/>
                            )
                        }
                    </p>
                ) : (
                    <span></span>
                )
            }
        </Fragment>
    )
}