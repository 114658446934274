import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx, keyframes } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import hexaGrey from "./hexaGrey.svg";
import hexaLightGreen from "./hexaLightGreen.svg";
import hexaLightRed from "./hexaLightRed.svg";
import logo from "./luxXPrime.svg";
import { renderLink } from "../../utils/helper";
import { style } from "./index.style";
import DictionaryComponent from "../../core/components/Dictionary/DictionaryComponent";

/** @jsx jsx */

export interface LuxXPrimeDoubleContentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const RenderData = (title: any, description: any) => (
  <div css={style.bloc}>
    <div css={style.icon}>
      <img src={renderLink(hexaGrey)} css={style.hexa} alt={"image"} />
      <div css={style.hoverIcon}>
        <Icon icon="check" />
      </div>
    </div>
    <div css={style.title}>
      <Text field={title as SitecoreGenericTextField} />
    </div>
    <div css={style.description}>
      <Text field={description as SitecoreGenericTextField} />
    </div>
  </div>
);

export const LuxXPrimeDoubleContent: React.FunctionComponent<LuxXPrimeDoubleContentProps> =
  (props: LuxXPrimeDoubleContentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const logo: any = getFieldValue<any>(fields, "logo", null);
    const btnLabel: string = getFieldValue<string>(fields, "btnLabel", "");
    const btnLink: string = getFieldValue<string>(fields, "btnLink", "");
    const btnIcon: string = getFieldValue<string>(
      fields,
      "btnIcon",
      "chevron-right"
    );
    const icon1: string = getFieldValue<string>(fields, "bloc1icon", "");
    const icon2: string = getFieldValue<string>(fields, "bloc2icon", "");
    const icon3: string = getFieldValue<string>(fields, "bloc3icon", "");
    const icon4: string = getFieldValue<string>(fields, "bloc4icon", "");
    const noLogo: boolean = getFieldValue<boolean>(fields, "noLogo", true);
    const isGreen: boolean = getFieldValue<boolean>(fields, "isGreen", false);

    const iconColor = getFieldValue<any>(fields, "iconColor", null);

    const hoverIcon = css`
    position: absolute;
    color: ${iconColor === "lightGreen" ? "#008C46" : isGreen ? "#008C46"  : "#CB1234"};
  `;

    const getIconColor = () => {
      if (iconColor === "lightGreen") {
        return hexaLightGreen;
      }
      if (iconColor === "lightRed") {
        return hexaLightRed;
      }
      else
      {
        return hexaGrey;
      }
    };

    return (
      <div css={style.container}>
        <div css={style.left}>
          <div css={style.top}>
            <Text
              field={fields && (fields.title as SitecoreGenericTextField)}
            />
          </div>
          {!noLogo && logo && logo.src && (
            <div css={style.bottom}>
              <img src={renderLink(logo && logo.src)} alt={"image"} />
            </div>
          )}
        </div>
        <div css={style.right}>
          <div css={style.content}>
            <div css={style.bloc}>
              <div css={style.icon}>
                <img src={getIconColor()} css={style.hexa} alt={"image"} />
                <div css={hoverIcon}>
                  <Icon icon={icon1} />
                </div>
              </div>
              <div css={style.title}>
                <Text
                  field={
                    fields && (fields.bloc1Title as SitecoreGenericTextField)
                  }
                />
              </div>
              <div css={style.description}>
                <DictionaryComponent text={fields.bloc1description.value} />
              </div>
            </div>
            <div css={style.bloc}>
              <div css={style.icon}>
                <img src={getIconColor()} css={style.hexa} alt={"image"} />
                <div css={hoverIcon}>
                  <Icon icon={icon2} />
                </div>
              </div>
              <div css={style.title}>
                <Text
                  field={
                    fields && (fields.bloc2Title as SitecoreGenericTextField)
                  }
                />
              </div>
              <div css={style.description}>
                <DictionaryComponent text={fields.bloc2description.value} />
              </div>
            </div>
            <div css={style.bloc}>
              <div css={style.icon}>
                <img src={getIconColor()} css={style.hexa} alt={"image"} />
                <div css={hoverIcon}>
                  <Icon icon={icon3} />
                </div>
              </div>
              <div css={style.title}>
                <Text
                  field={
                    fields && (fields.bloc3Title as SitecoreGenericTextField)
                  }
                />
              </div>
              <div css={style.description}>
                <DictionaryComponent text={fields.bloc3description.value} />
              </div>
            </div>
            <div css={style.bloc}>
              <div css={style.icon}>
                <img src={getIconColor()} css={style.hexa} alt={"image"} />
                <div css={hoverIcon}>
                  <Icon icon={icon4} />
                </div>
              </div>
              <div css={style.title}>
                <Text
                  field={
                    fields && (fields.bloc4Title as SitecoreGenericTextField)
                  }
                />
              </div>
              <div css={style.description}>
                <DictionaryComponent text={fields.bloc4description.value} />
              </div>
            </div>
          </div>
          {btnLabel && (
            <div css={style.action}>
              <Button
                label={btnLabel}
                onClick={() =>
                  btnLink ? (window.location.href = btnLink) : console.log
                }
                iconRight={btnIcon as any}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
