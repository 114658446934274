import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Icon, Table, Td, Th, Thead, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import { sharedStyle } from '../../../../../../styles/shared';
import { MarketdataModel } from '../../market-data-view.component';
import UpcomingDelistingRow from './upcoming-delisting-row.component';

export const UpcomingDelistingView = (props: any) => {
  const upcomingDelistings = props.upcomingDelistingsData;
  return (
    <Table>
      {/* {upcomingDelistings?.totalHits > 5 && <div css={sharedStyle.gradientTop} />} */}
      <tbody>
        {upcomingDelistings?.totalHits > 0
          ? upcomingDelistings?.currentData?.map((upcomingDelistingRow: MarketdataModel) => (
              <UpcomingDelistingRow upcomingDelistingRowData={upcomingDelistingRow} />
            ))
          : 'no data'}
      </tbody>
    </Table>
  );
};
export default UpcomingDelistingView;
