/** @jsx jsx */

import * as React from 'react';

import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { SitecoreGenericTextField } from '../../interfaces';
import hexa from './hexa.svg';

export interface GreenBannerWithCardsComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  background: linear-gradient(90deg, #425563 0%, #008c3d 100%);
  @media (max-width: ${breakpointMax}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: ${breakpointMax}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
const containerTop = css`
  width: 100%;
  max-width: 780px;
`;
const topTitle = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  }
`;
const title = css`
  margin-top: 8px;
  margin-bottom: 16px;
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  color: #ffffff;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 32px;
    line-height: 31px;
  }
`;
const description = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 64px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.01em;
    margin-bottom: 32px;
  }
`;
const cards = css`
  display: flex;
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const cardsLeft = css`
  width: 50%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const cardsRight = css`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    flex-direction: column;
  }
`;
const smallCardTitle = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  position: absolute;
  top: -40px;
  text-align: center;
  margin-left: 16px;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    position: relative;
    top: 0;
    margin: 0;
  }
`;
const bigCard = css`
  background: #ffffff;
  box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
  border-radius: 16px;
  padding: 40px;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  :hover {
    background: #f0f5f5;
  }
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    margin-bottom: 12px;
    justify-content: flex-start;
    align-items: baseline;
  }
`;
const rightPart = css`
  display: flex;
  width: 100%;
  align-items: center;
`;
const chevron = css`
  color: #22AA5F;
  margin-right: 20px;
  @media (max-width: ${breakpointMax}) {
    font-size: 16px;
    align-items: center;
  }
`;

const bigIcon = css`
  background-image: url(${hexa});
  background-position: center center;
  color: #22AA5F;
  margin-right: 15px;
  min-height: 50px;
  min-width: 50px;
  align-items: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpointMax}) {
    font-size: 16px;
    align-items: center;
  }
`;
const bigText = css`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`;

const bigTitle = css`
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    margin-top: 24px;
  }
`;
const bigDescription = css`
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
  line-height: 150%;
  color: #354450;
`;
const smallCard = css`
  padding: 24px;
  height: calc(50% - 8px);
  margin-left: 16px;
  margin-bottom: 16px;
  width: 100%;
  max-width: calc(50% - 16px);
  background: #ffffff;
  box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
  border-radius: 16px;
  align-items: center;
  display: flex;
  cursor: pointer;
  color: #354450;
  font-weight: 800;
  :hover {
    background: #f0f5f5;
  }
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    max-width: 100%;
    top: 0;
    margin: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    align-items: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
  }
`;
const action = css`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  button {
    background-color: transparent;
    border: 2px solid;
  }
  @media (max-width: ${breakpointMax}) {
    button {
      background-color: transparent;
      border: 2px solid;
      width: 100%;
    }
  }
`;

export const GreenBannerWithCardsComponent: React.FunctionComponent<GreenBannerWithCardsComponentProps> = (
  props: GreenBannerWithCardsComponentProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const btnLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
  const btnLink: string = getFieldValue<string>(fields, 'btnLink', '');
  const btnVariant: any = getFieldValue<any>(fields, 'button with wired style', '');
  const btnWired: number = getFieldValue<number>(fields, 'button variant style', 0);
  const bigCardLink: string = getFieldValue<string>(fields, 'bigCardLink', '');
  const smallCardLink1: string = getFieldValue<string>(fields, 'smallCardLink1', '');
  const smallCardLink2: string = getFieldValue<string>(fields, 'smallCardLink2', '');
  const smallCardLink3: string = getFieldValue<string>(fields, 'smallCardLink3', '');
  const smallCardLink4: string = getFieldValue<string>(fields, 'smallCardLink4', '');

  return (
    <div css={parentContainer}>
      <div css={container}>
        <div css={containerTop}>
          <div css={topTitle}>
            <Text field={fields && (fields.topTitle as SitecoreGenericTextField)} />
          </div>
          <div css={title}>
            <Text field={fields && (fields.title as SitecoreGenericTextField)} />
          </div>
          <div css={description}>
            <Text field={fields && (fields.description as SitecoreGenericTextField)} />
          </div>
        </div>
        <div css={cards}>
          <div css={cardsLeft}>
            <div css={bigCard} onClick={() => (bigCardLink ? (window.location.href = bigCardLink) : console.debug())}>
              <div css={bigIcon}>
                <Icon icon={'leaf'} />
              </div>
              <div css={rightPart}>
                <div css={bigText}>
                  <div css={bigTitle}>
                    <Text field={fields && (fields.bigCardTitle as SitecoreGenericTextField)} />
                  </div>
                  <div css={bigDescription}>
                    <Text field={fields && (fields.bigCardDescription as SitecoreGenericTextField)} />
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div css={cardsRight}>
            <div css={smallCardTitle}>
              <Text field={fields && (fields.smallCardTitle as SitecoreGenericTextField)} />
            </div>
            <div
              css={smallCard}
              onClick={() => (smallCardLink1 ? (window.location.href = smallCardLink1) : console.debug())}
            >
              <div css={bigIcon}>
                <Icon icon={'leaf'} />
              </div>
              <Text field={fields && (fields.smallCardText1 as SitecoreGenericTextField)} />
            </div>
            <div
              css={smallCard}
              onClick={() => (smallCardLink2 ? (window.location.href = smallCardLink2) : console.debug())}
            >
              <div css={bigIcon}>
                <Icon icon={'leaf'} />
              </div>
              <Text field={fields && (fields.smallCardText2 as SitecoreGenericTextField)} />
            </div>
            <div
              css={smallCard}
              onClick={() => (smallCardLink3 ? (window.location.href = smallCardLink3) : console.debug())}
            >
              <div css={bigIcon}>
                <Icon icon={'leaf'} />
              </div>
              <Text field={fields && (fields.smallCardText3 as SitecoreGenericTextField)} />
            </div>
            <div
              css={smallCard}
              onClick={() => (smallCardLink4 ? (window.location.href = smallCardLink4) : console.debug())}
            >
              <div css={bigIcon}>
                <Icon icon={'leaf'} />
              </div>
              <Text field={fields && (fields.smallCardText4 as SitecoreGenericTextField)} />
            </div>
          </div>
        </div>
        <div css={action}>
          <Button
            variant={btnVariant}
            wired={!!btnWired}
            label={btnLabel}
            onClick={() => (btnLink ? (window.location.href = btnLink) : console.debug)}
          />
        </div>
      </div>
    </div>
  );
};
