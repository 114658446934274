import * as React from 'react';

/** @jsx jsx */
import { css, jsx  } from '@emotion/react';

import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment-timezone';
import { style } from './index.style';
import chartOptions from './chart-options';
import { indexDetailPageLink } from '../Index/Index-details';
import { parseToMoment } from '../../../utils/date';

export interface ChinaGraphProps {
  data?: any;
  label?: string;
  id: string;
}

const ChinaGraph: React.FunctionComponent<ChinaGraphProps> = ({
  data,
  label,
  id
}: ChinaGraphProps) => {
  if(!data)
    return <></>;

  let prices : any[] = [];
  if ( data.pricesHistory )
    prices = data.pricesHistory;

  let dailyChange : number = null;
  if (data.dailyChange) 
    dailyChange = data.dailyChange;
  else if (data.lastPrice?.changePercent) 
    dailyChange = data.lastPrice.changePercent;

  let amount : number = null;
  if (data.amount) 
    amount = data.amount;
  else if (data.lastPrice?.amount) 
    amount = data.lastPrice.amount;

  const smoothCurve = (prices: any[]): number[] => {
    let series: number[] = [];
  
    if( !prices?.length )
      return series;
  
    const lastDate = prices[prices.length - 1]?.date;
  
    if( !lastDate )
      return series;
  
    const lastPriceDate = parseToMoment(lastDate);
    const lastMonthDate = parseToMoment(lastDate).subtract(6, 'months');
  
    prices.forEach((price: any): void => {
      const priceDate = parseToMoment(price?.date);
  
      if (priceDate?.isSameOrAfter(lastMonthDate) && priceDate?.isSameOrBefore(lastPriceDate)) {
        series = [...series, parseFloat(price.amount)];
      }
    });
  
    return series;
  };

  return (
    <a css={style.chinaGraph} 
       href={indexDetailPageLink(id)}
       title={label}>
      <div css={style.chartSide}>
        { prices.length > 0 ?
          <>
            <div css={style.chartNumber}>
              { dailyChange != null ?
                <div css={[style.chartChange,
                  { color: dailyChange < 0 ? '#CB1234' : '#22AA5F',}
                ]}>
                  {dailyChange >= 0 && '+'} {`${dailyChange?.toFixed(5)} %`}
                </div>
                : <></>
              }
              <div css={style.chartAmount}>
                {amount?.toFixed(5)}
              </div>
            </div>
            <div css={{paddingTop: "2em"}}>
              <ReactApexChart
                options={chartOptions}
                series={[
                  {
                    name: 'price',
                    data: smoothCurve(prices),
                  },
                ]}
                type='area'
              />
            </div>
          </>
        : <div>no Data</div>
      }
      </div>
      <div css={style.chartLabel}>
        {label}
      </div>
      <div css={style.chartIcon}>
        <Icon icon={'chevron-right'}></Icon>
      </div>
    </a>
  );
};

export default ChinaGraph;
