
/** @jsx jsx */
import { jsx } from "@emotion/react"
import * as React from 'react';
import { Menu, MenuWithChild } from "@bdl-cmn-shared-packages-npm/design-system";
import { PeriodicityCode } from "../../../../../../enums";
import { LuxXPrimeFilterResultsData, setPeriodicityCodeLabelDataValue } from "../../../../../../graphql";

type PeridiocitySelect = keyof typeof PeriodicityCode;
export interface PeriodicityFilterProps {
  data: LuxXPrimeFilterResultsData | null
  selection: PeridiocitySelect[];
  onSelectionChange: (values: PeridiocitySelect[]) => void;
}


export function PeriodicityFilter({
  selection,
  onSelectionChange,
  data
}: PeriodicityFilterProps) {
  const values = React.useMemo(() => {
    if (!data?.securitiesByTag) {
      return [];
    }

    const periodicityCodeValues = Object.values(PeriodicityCode);
    const dataObject = data.securitiesByTag.reduce((acc, { periodicityCode }) => {
      acc[periodicityCode] = periodicityCodeValues.includes(PeriodicityCode[periodicityCode]) ? periodicityCode : PeriodicityCode.INDE;
      return acc;
    }, {} as any);
    const dataValues = Object.values(dataObject) as PeriodicityCode[];

    return dataValues.sort((a: PeriodicityCode, b: PeriodicityCode) => periodicityCodeValues.indexOf(a) - periodicityCodeValues.indexOf(b));

  }, [data?.securitiesByTag])
  return (
    <MenuWithChild
      icon="clock"
      label="Coupon periodicity"
      width="100%;"
      expanded={false}
      arrows={false}>
      {
        values.map((value) => (
          <Menu
            key={value}
            width="100%;"
            selected={selection.includes(value)}
            onSelect={() => onSelectionChange(
              !selection.includes(value) ? [...selection, value] : selection.filter(v => v !== value)
            )}
            childLabel={setPeriodicityCodeLabelDataValue(PeriodicityCode[value])} />
        ))
      }

    </MenuWithChild>
  );
}