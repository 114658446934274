
export enum QuotingConvention {
	CLEAN = "Clean price",
	DIRTY = "Dirty",
	NA = "Not applicable"
}

function cleanName(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try{
		value = value.toString().toUpperCase();
		value = value.replace(/[^a-zA-Z ]/g, "");
		value = value.replace(/\s/g, "_");
	}catch (e){
	}

	return value;
}

export function quotingConventionLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(QuotingConvention).indexOf( cleanName(value) );
  		if( index >= 0) 
			return Object.values(QuotingConvention)[index];
	}catch (e){
		console.error("Failed to get QuotingConvention label for '" + value + "'", e);
	}
	
	console.error("QuotingConvention label for '" + value + "' - '" + cleanName(value) + "' doest exist");

	return value;
}

export function quotingConventionCheck( a : string, compare : string ): boolean {
	if( !a || !compare )
		return false;
	try {
		let aValue = quotingConventionLabel(a);
		
		return aValue === compare;
	}catch (e){
		console.error("Failed to check QuotingConvention for '" + a + "'-'" + compare + "'", e);
	}

	return false;
}