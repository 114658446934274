import * as React from 'react';

import { ComponentFields, Text, getFieldValue, Link } from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { SitecoreGenericTextField } from '../../interfaces';
import logo from './luxxprime.svg';
import { renderLink } from '../../utils/helper';
import { withRouter } from 'react-router-dom';

/** @jsx jsx */

export interface DiscoverAdProps {
  fields?: any;
  match?: any;
  history?: any;
  id?: string | number;
}

export interface DiscoverAdProps {
  title?: string;
  subTitle?: string;
  buttonLabel?: string;
  link?: string;
  vertical?: boolean;
}

export const DiscoverAdContent: React.FunctionComponent<any> = ({
  fields,
  title,
  subTitle,
  buttonLabel,
  vertical,
}: DiscoverAdProps): React.ReactElement => {

  const btnLabel: string = getFieldValue<string>(fields, 'buttonLabel', '');
  const verticalAlight: boolean = getFieldValue<boolean>(fields, 'vertical align', true);
  const inBlog: boolean = getFieldValue<boolean>(fields, 'in blog', true);
  const image: any = getFieldValue<any>(fields, 'image', null);

  const container = css`
    display: flex;
    width: 100%;
    padding: 16px;
    border: solid 1px #d9e1e2;
    border-radius: 8px;
    align-self: flex-start;

    @media (max-width: 720px) {
      flex-direction: column;
    }

    ${vertical && 'background: #F6F9F9!important'};
    ${verticalAlight && 'background: #FFFFFF!important'};
    ${vertical || verticalAlight && 'flex-direction: column!important'};
    ${vertical || verticalAlight && 'justify-content: center!important'};
    ${vertical || verticalAlight && 'align-items: center!important'};
    ${vertical && 'border: none!important'};
  `;
  const left = css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: ${vertical || verticalAlight? '100%' : '30%'};

    ${!vertical || verticalAlight && 'padding-right: 48px'};
    ${vertical || verticalAlight && 'justify-content: center!important'};
    ${vertical || verticalAlight && 'align-items: center!important'};
    ${vertical || verticalAlight && 'margin-bottom: 32px!important'};

    img {
      display: flex;
      ${vertical || verticalAlight && 'align-items: center!important'};
      width: 100%;
      max-width: 240px;
    }
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      padding-right: 0;
      justify-content: flex-start;

      img {
        max-width: 180px;
      }
    }
  `;
  const center = css`
    display: flex;
    flex-direction: column;
    ${inBlog ? 'justify-content: "flex-start!important;"' : vertical || verticalAlight && 'justify-content: center!important;'};
    width: ${vertical || verticalAlight? '100%' : '40%'};
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin-top: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.01em;
    }
  `;
  const right = css`
    width: ${vertical || verticalAlight? '100%' : '30%'};
    display: flex;
    //align-items: center;
    ${inBlog ? 'justify-content: "flex-start!important;"' : vertical || verticalAlight && 'justify-content: center!important;'};
    ${vertical || verticalAlight && 'margin-top: 24px!important'};
    //padding-left: 32px;
    font-size: 16px;

    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin-top: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.01em;
      padding-left: 0;
    }
  `;
  const top = css`
    display: flex;
    font-weight: 800;
    font-size: 24px;
    color: #253645;
    width: 100%;
    ${inBlog ? 'justify-content: "flex-start!important;"' : vertical || verticalAlight && 'justify-content: center!important;'};
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
    }
  `;
  const bottom = css`
    display: flex;
    font-weight: 600;
    color: #253645;
    font-size: 20px;
    padding-top: 8px;
    width: 100%;
    ${inBlog ? 'justify-content: "flex-start!important;"' : vertical || verticalAlight && 'justify-content: center!important;'};
    ${vertical || verticalAlight && 'font-size: 16px;!important'};
    ${vertical || verticalAlight && 'padding-top: 16px;!important'};
    ${vertical || verticalAlight && 'line-height: 20px;!important'};
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.01em;
    }
  `;

  return (
    <div css={container}>
      <div css={left}>
        {(buttonLabel || btnLabel) && (
              <Link
                  target={'_self'}
                  title={title}
                  field={fields['button link'] as any}
                  css={{textDecoration: 'none'}}>
                    <img src={renderLink(image?.src)} alt={"image"} />
              </Link>
        )}
      </div>
      <div css={center}>
        <div css={top}>{title ? title : <Text field={fields && (fields.title as SitecoreGenericTextField)} />}</div>
        <div css={bottom}>
          {subTitle ? subTitle : <Text field={fields && (fields.subTitle as SitecoreGenericTextField)} />}
        </div>
      </div>
      {(buttonLabel || btnLabel) && (
        <div css={right}>
          <Link
              target={'_self'}
              title={title}
              field={fields['button link'] as any}
              css={{textDecoration: 'none', display: 'flex'}}>
            <div>
              {buttonLabel || btnLabel}
            </div>
            <div css={{marginLeft: 8}}>
              <Icon icon="chevron-right" />
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export const DiscoverAd = withRouter(DiscoverAdContent);
