import * as React from "react";

import {
  Button,
  Col,
  Grid,
  Icon,
  Row,
} from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx, keyframes } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import hexa from "./hexa.svg";
import hexabg from "./hexabg.svg";
import hexaGreen from "./hexaGreen.svg"
import { renderLink } from "../../utils/helper";

/** @jsx jsx */

export interface AdvantagesOfLgxProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const style = {
  parentContainer: css`
    width: 100%;
    position: relative;
  `,
  containerNoButton: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin: auto;
    padding-top: 96px;
    padding-bottom: 0;
    @media (max-width: ${breakpointMax}) {
      padding-top: 40px;
      padding-left: 16px;
      padding-right: 16px;
    }
  `,
  title: css`
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    text-align: center;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 10px !important;
      line-height: 31px;
      margin-bottom: 0;
    }
  `,
  titleNoMargin: css`
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    text-align: center;
    color: #354450;
    margin-top: 0;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 0;
    }
  `,
  hexaBgStyle: css`
    position: absolute;
    left: 0;
    bottom: 0;
    height: 340px;
    width: 100%;
    z-index: -1;
    max-width: 400px;
    background: url(${renderLink(hexabg)});
    background-repeat: no-repeat;
    background-size: cover;
  `,
  subTitle: css`
    font-weight: 600;
    font-size: 1.25em;
    line-height: 150%;
    color: #425563;
    text-align: center;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  `,
  action: css`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 80px;

    button {
      margin-right: 16px;
    }
  `,
  items: css`
    display: flex;
    width: 100%;
    margin-top: 72px;
    flex-wrap: wrap;
    padding: 40px;
    align-items: inherit;
    justify-content: center;
    @media (max-width: ${breakpointMax}) {
      padding: 16px;
    }
  `,
  item: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 320px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;

    @media (max-width: ${breakpointMax}) {
      max-width: 100%;
      align-items: center;
    }
  `,
  headerBigMenuItemLogoSvg: css`
    opacity: 0.2;
  `,
  advTitle: css`
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 16px;
    color: #253645;

    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
    }
  `,
  text: css`
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: #354450;
    line-height: 150%;
    max-width: 280px;
    margin-top: 2em;
    text-align: center;

    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  `,
};
export const AdvantagesOfLgx: React.FunctionComponent<AdvantagesOfLgxProps> = (
  props: AdvantagesOfLgxProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const buttonLabel: string = getFieldValue<string>(fields, "buttonLabel", "");
  const buttonLink: string = getFieldValue<string>(fields, "buttonLink", "");
  const buttonLabel2: string = getFieldValue<string>(
    fields,
    "buttonLabel 2",
    ""
  );
  const buttonLink2: string = getFieldValue<string>(fields, "buttonLink 2", "");
  const hasButtonStatus: boolean = getFieldValue<boolean>(
    fields,
    "hasButton",
    false
  );
  const noMargin: number = getFieldValue<number>(fields, "no margin top", 0);
  const redIcons: number = getFieldValue<number>(fields, "redIcons", 0);
  const button1Icon: string = getFieldValue<string>(
    fields,
    "button 1 icon",
    ""
  );
  const button2Icon: string = getFieldValue<string>(
    fields,
    "button 2 icon",
    ""
  );

  const iconColor = getFieldValue<any>(fields, "iconColor", null);

  const getIconColor = () => {
    if (iconColor === "green") {
      return hexaGreen;
    }
    if (iconColor === "red") {
      return hexa;
    }
    else
    {
      return hexa;
    }
  };

  const headerBigMenuItemLogo = css`
    //width: 50px;
    //margin-right: 2em;
    background-image: url(${renderLink(getIconColor())});
    background-repeat: no-repeat;
    background-size: 100%;
    height: 110px;
    width: 100px;
    display: flex;
    justify-content: center;
    color: ${iconColor === "red" ? "#ca1234" : iconColor === "green" ? "#008c3D" : "#008c3D" };
    align-items: center;
    font-size: 2em;
    margin-left: 90px;
  `;

  const container = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin: auto;
    padding-top: ${noMargin ? "0" : "96px"};
    @media (max-width: ${breakpointMax}) {
      padding-top: 40px;
      padding-left: 16px;
      padding-right: 16px;
    }
  `;

  return (
    <div css={style.parentContainer}>
      <div css={style.hexaBgStyle} />
      <div css={hasButtonStatus ? style.containerNoButton : container}>
        <div css={noMargin ? style.titleNoMargin : style.title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        {fields && fields.subTitle && (
          <div css={style.subTitle}>
            <RichText
              field={fields && (fields.subTitle as SitecoreGenericTextField)}
            />
          </div>
        )}
        <div css={style.items}>
          {new Array(8).fill(0).map((arr: any, index: number) => {
            if (
              fields &&
              fields[`advantage${index + 1}`] &&
              (fields && (fields[`advantage${index + 1}`] as any)).value
            ) {
              return (
                <div css={style.item}>
                  <div css={headerBigMenuItemLogo}>
                    <Icon
                      icon={
                        fields &&
                        fields[`advantage${index + 1}Icon`] &&
                        (fields && (fields[`advantage${index + 1}Icon`] as any))
                          .value
                      }
                    />
                  </div>
                  <div css={style.text}>
                    {fields[`advantage${index + 1}Title`] &&
                      (fields && (fields[`advantage${index + 1}Title`] as any))
                        .value && (
                        <div css={style.advTitle}>
                          <Text
                            field={
                              fields[
                                `advantage${index + 1}Title`
                              ] as SitecoreGenericTextField
                            }
                          />
                        </div>
                      )}
                    <Text
                      field={
                        fields[
                          `advantage${index + 1}`
                        ] as SitecoreGenericTextField
                      }
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
        {!!hasButtonStatus && (
          <div css={style.action}>
            <Button
              label={buttonLabel}
              onClick={() =>
                buttonLink ? (window.location.href = buttonLink) : console.debug
              }
              variant={"primary"}
              iconRight={button1Icon as any}
            />
            {buttonLabel2 && (
              <Button
                label={buttonLabel2}
                onClick={() =>
                  buttonLink2
                    ? (window.location.href = buttonLink2)
                    : console.debug
                }
                variant={"secondary"}
                iconRight={button2Icon as any}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
