
export enum SecurityStatus {
	SUSP = 'SUSP',
	ADMI = 'ADMI',
	COTE = 'COTE',
	RETR = 'RETR',
	NEGO = 'NEGO',
	NCOT = 'NCOT',
	FONG = 'FONG',
	SUPP = 'SUPP',
	PUBL = 'PUBL',
	CLOT = 'CLOT'
}