/** @jsx jsx */
import { jsx } from "@emotion/react";
import {
  Button,
  Td,
  Tr
} from "@bdl-cmn-shared-packages-npm/design-system";
import React, { Fragment, useState } from "react";
import Props from "../../UI/list/base/props";
import { DOWNLOAD_ENDPOINT } from "../../../../backbourse";
import { CountryFlagIcon } from "../../CountryFlagIcon/countryFlagIcon.component";
import { RenderChevron } from "../../CssfApprovals/cssf-approvals-row.component";
import { cssfApprovalsStyle } from "../../CssfApprovals/cssf-approvals-view.style";
import { formatDate } from "../../../../utils/date";
import { marketNewsStyle } from "./index.style";
import { setDocumentType } from "../../../../utils/documentsType";
import { documentTypeLabel } from "../../../../enums/document-type";
import { MarketNewsDetailsModel } from ".";

const MarketNewsCSSFRow = ({ row }: Props<MarketNewsDetailsModel>) => {
  const [toggleChevron, setToggleChevron] = useState<boolean>(false);

  if( ! row )
    return <></>;

  let categories : String[] = [];
  if( row.properties?.categories ){
      row.properties.categories.forEach( (category: string) => {
          let categoryLabel = documentTypeLabel( category );
          if( categoryLabel && ! categories.includes(categoryLabel))
              categories.push(categoryLabel);
      });
  }

  return (
    <>
      <Tr trigger onRowClick={() => setToggleChevron(!toggleChevron)} >
        <td css={{ width: "65%", padding: "1em" }}>
          { categories ?
            <div css={marketNewsStyle.status}>
              { categories.map(category =>
                <div css={marketNewsStyle.tag} key={`cssf-${row.id}-category-${category}`}>{category}</div>
              )}
            </div>
            :<></>
          }
          <div css={marketNewsStyle.title2}>
            {setDocumentType(row.properties?.value_1)}
            {row?.properties?.description
              ? ` - ${row?.properties?.description}`
              : ""}
          </div>
          <div css={marketNewsStyle.title1}>{row.name}</div>

          <div css={marketNewsStyle.listCountry}>
            { row?.properties?.hostMemberStates?.map((x: any) => 
              <CountryFlagIcon code={x} size={{ height: 20, width: 30 }} key={`fns-${row.id}-flag-${x}`}/>
            )}
          </div>
        </td>
        <td css={{ width: "20%", verticalAlign: "middle" }}>
          <Button
            onClick={() => (window.location.href = DOWNLOAD_ENDPOINT + row?.properties?.downloadUrl)}
            label={"Download"}
            iconRight="download"
          />
        </td>
        <td css={{ width: "10%", verticalAlign: "middle" }}>
          {row?.properties?.docIncorporesParRef !== undefined ? (
            <div css={cssfApprovalsStyle.chevron}>
              <RenderChevron toggle={toggleChevron} />
            </div>
          ) : (
            <></>
          )}
        </td>
      </Tr>

      {row?.properties?.docIncorporesParRef !== undefined && toggleChevron === true ? (
        row.properties?.docIncorporesParRef?.map((x: any, index: number) => {
          return (
            <Fragment key={`fns-${row.id}-fragment-${index}`}>
              {index == 0 ? (
                <Tr>
                  <Td
                    colSpan={3}
                    css={cssfApprovalsStyle.docIncorporatedContainer2}
                  >
                    <p css={cssfApprovalsStyle.docIncorporatedText}>
                      {row.properties?.docIncorporesParRef?.length} document(s)
                      incorporated by reference
                    </p>
                  </Td>
                </Tr>
              ) : (
                <></>
              )}

              <Tr css={marketNewsStyle.underList}>
                <Td css={marketNewsStyle.underList}>
                  <div css={marketNewsStyle.title1}>{x.description} </div>
                  <div css={marketNewsStyle.title1}>
                    {formatDate(x?.referenceDate, "DD/MM/YYYY")} - {x?.complement}{" "}
                  </div>
                </Td>
                <Td colSpan={2} css={marketNewsStyle.underList}>
                  <Button
                    css={marketNewsStyle.buttonUnderList}
                    onClick={() =>
                      (window.location.href = DOWNLOAD_ENDPOINT + x?.downloadUrl)
                    }
                    label={"Download"}
                    iconRight="download"
                  />
                </Td>
              </Tr>
            </Fragment>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default MarketNewsCSSFRow;
