import { breakpoint, breakpointMax } from '../../style';

import { css } from '@emotion/react';

export const style = {
  container: css`
    width: 100%;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin: auto;
    min-height: 600px;
  `,
  title: css`
    width: 100%;
    max-width: 800px;
    text-align: center;
    display: flex;
    justify-content: center;
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    color: #122834;
    margin: 48px auto;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
    }
  `,
  rows: css`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 200px;
    @media (max-width: ${breakpointMax}) {
      margin-bottom: 0px;
    }
  `,
  center: css`
   display: flex;
    flex-direction: column;
    align-items: center;
  `,
  rowButton: css`
    height: 200px;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -300px;
    background: linear-gradient(to bottom, transparent 0%, #ffffff 30%);
    padding-top: 20px;
    width: 100%;
    @media (max-width: ${breakpointMax}) {
      height: 100px;
      position: static;
      margin-top: 0px;
      margin-bottom: 45px;
    }
  `,
};
