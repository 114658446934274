
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const VEN: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FCE300" d="M512,200.093H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,200.093L512,200.093z" />
            <path fill="#EF3340" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V311.909h512v102.988 C512,419.773,508.047,423.725,503.172,423.725z" />
            <rect y="200.091" fill="#003DA5" width="512" height="111.81" />
            <g>
                <path fill="#FFFFFF" d="M178.14,277.498l2.212,6.633l6.991,0.054c0.905,0.007,1.281,1.162,0.553,1.7l-5.624,4.153 l2.109,6.667c0.273,0.863-0.71,1.577-1.447,1.05l-5.688-4.066l-5.688,4.066c-0.736,0.526-1.719-0.188-1.447-1.05l2.109-6.667 l-5.624-4.153c-0.728-0.537-0.353-1.693,0.553-1.7l6.991-0.054l2.212-6.633C176.639,276.64,177.854,276.64,178.14,277.498z" />
                <path fill="#FFFFFF" d="M191.723,256.383l-1.185,6.891l6.136,3.352c0.794,0.434,0.579,1.63-0.317,1.759l-6.92,1.002 l-1.292,6.871c-0.168,0.889-1.37,1.055-1.771,0.243l-3.091-6.272l-6.935,0.895c-0.898,0.116-1.427-0.978-0.778-1.61l5.01-4.878 l-2.994-6.318c-0.387-0.818,0.489-1.659,1.29-1.237l6.187,3.257l5.085-4.8C190.806,254.917,191.876,255.491,191.723,256.383z" />
                <path fill="#FFFFFF" d="M208.913,237.903l-3.282,6.174l4.779,5.103c0.619,0.661,0.041,1.729-0.851,1.571l-6.887-1.213 l-3.377,6.123c-0.437,0.792-1.632,0.573-1.758-0.323l-0.974-6.924l-6.867-1.32c-0.889-0.171-1.049-1.375-0.235-1.772l6.284-3.066 l-0.867-6.938c-0.113-0.898,0.983-1.422,1.612-0.771l4.858,5.028l6.33-2.968C208.5,236.223,209.337,237.103,208.913,237.903z" />
                <path fill="#FFFFFF" d="M229.598,226.437l-4.824,5.062l3.228,6.202c0.418,0.803-0.427,1.676-1.244,1.284l-6.304-3.025 l-4.903,4.986c-0.634,0.646-1.726,0.111-1.606-0.786l0.928-6.931l-6.257-3.122c-0.81-0.404-0.64-1.607,0.25-1.77l6.878-1.259 l1.035-6.915c0.134-0.895,1.331-1.105,1.761-0.308l3.322,6.152l6.897-1.152C229.654,224.708,230.222,225.782,229.598,226.437z" />
                <path fill="#FFFFFF" d="M253.374,221.124l-6.004,3.584l1.448,6.84c0.188,0.886-0.861,1.501-1.542,0.905l-5.263-4.602 l-6.059,3.49c-0.785,0.452-1.693-0.354-1.336-1.187l2.751-6.429l-5.192-4.683c-0.672-0.606-0.185-1.719,0.716-1.637l6.964,0.629 l2.85-6.385c0.369-0.826,1.578-0.708,1.779,0.174l1.554,6.817l6.953,0.737C253.89,219.474,254.152,220.66,253.374,221.124z" />
                <path fill="#FFFFFF" d="M333.86,277.498l-2.212,6.633l-6.991,0.054c-0.905,0.007-1.281,1.162-0.553,1.7l5.624,4.153 l-2.109,6.667c-0.273,0.863,0.71,1.577,1.447,1.05l5.688-4.066l5.688,4.066c0.736,0.526,1.719-0.188,1.447-1.05l-2.109-6.667 l5.624-4.153c0.728-0.537,0.353-1.693-0.553-1.7l-6.991-0.054l-2.212-6.633C335.361,276.64,334.146,276.64,333.86,277.498z" />
                <path fill="#FFFFFF" d="M320.277,256.383l1.185,6.891l-6.136,3.352c-0.794,0.434-0.579,1.63,0.317,1.759l6.92,1.002 l1.292,6.871c0.168,0.889,1.371,1.055,1.771,0.243l3.091-6.272l6.935,0.895c0.898,0.116,1.427-0.978,0.778-1.61l-5.01-4.878 l2.994-6.318c0.387-0.818-0.489-1.659-1.29-1.237l-6.187,3.257l-5.085-4.8C321.194,254.917,320.124,255.491,320.277,256.383z" />
                <path fill="#FFFFFF" d="M303.087,237.903l3.282,6.174l-4.779,5.103c-0.619,0.661-0.041,1.729,0.851,1.571l6.887-1.213 l3.377,6.123c0.437,0.792,1.632,0.573,1.758-0.323l0.974-6.924l6.867-1.32c0.889-0.171,1.049-1.375,0.235-1.772l-6.284-3.066 l0.867-6.938c0.113-0.898-0.983-1.422-1.612-0.771l-4.858,5.028l-6.33-2.968C303.5,236.223,302.663,237.103,303.087,237.903z" />
                <path fill="#FFFFFF" d="M282.402,226.437l4.824,5.062l-3.228,6.202c-0.418,0.803,0.427,1.676,1.244,1.284l6.304-3.025 l4.903,4.986c0.634,0.646,1.726,0.111,1.605-0.786l-0.928-6.931l6.257-3.122c0.81-0.404,0.64-1.607-0.25-1.77l-6.878-1.259 l-1.035-6.915c-0.134-0.895-1.331-1.105-1.761-0.308l-3.322,6.152l-6.897-1.152C282.346,224.708,281.778,225.782,282.402,226.437z" />
                <path fill="#FFFFFF" d="M258.626,221.124l6.004,3.584l-1.448,6.84c-0.188,0.886,0.861,1.501,1.542,0.905l5.263-4.602 l6.059,3.49c0.785,0.452,1.693-0.354,1.336-1.187l-2.751-6.429l5.192-4.683c0.672-0.606,0.185-1.719-0.716-1.637l-6.964,0.629 l-2.85-6.385c-0.369-0.826-1.578-0.708-1.779,0.174l-1.554,6.817l-6.953,0.737C258.11,219.474,257.848,220.66,258.626,221.124z" />
            </g>
        </svg>
    </React.Fragment>
);
