
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const BDI: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#1EB53A" d="M503.172,88.225H8.828C3.953,88.225,0,92.178,0,97.053v317.792c0,4.875,3.953,8.828,8.828,8.828 h494.345c4.875,0,8.828-3.953,8.828-8.828V97.053C512,92.178,508.047,88.225,503.172,88.225z" />
            <g>
                <polygon fill="#CE1126" points="40.266,88.225 255.999,229.568 471.733,88.225 " />
                <polygon fill="#CE1126" points="471.733,423.775 255.999,282.432 40.266,423.775 " />
            </g>
            <path fill="#FFFFFF" d="M512,97.053c0-4.875-3.953-8.828-8.828-8.828h-31.438L307.696,195.698 c-13.9-11.937-31.936-19.196-51.696-19.196s-37.796,7.258-51.696,19.196L40.266,88.225H8.828C3.953,88.225,0,92.178,0,97.053v17.554 l180.058,117.968c-2.272,7.391-3.507,15.239-3.507,23.374c0,8.136,1.235,15.982,3.507,23.374L0,397.292v17.554 c0,4.875,3.953,8.828,8.828,8.828h31.438l164.037-107.472c13.9,11.937,31.936,19.197,51.696,19.197s37.796-7.258,51.696-19.197 l164.037,107.472h31.438c4.875,0,8.828-3.953,8.828-8.828v-17.554L331.941,279.324c2.272-7.391,3.507-15.239,3.507-23.374 c0-8.136-1.235-15.982-3.507-23.374l180.058-117.968L512,97.053L512,97.053z" />
            <polygon fill="#CE1126" points="250.28,230.545 238.31,230.855 244.56,220.639 238.31,210.424 250.28,210.734 255.999,200.209 261.72,210.734 273.69,210.424 267.439,220.639 273.69,230.855 261.72,230.545 255.999,241.07 " />
            <path fill="#1EB53A" d="M255.999,202.984l4.943,9.092l10.346-0.265l-5.402,8.828l5.402,8.828l-10.346-0.265l-4.943,9.092 l-4.943-9.092l-10.346,0.265l5.402-8.828l-5.402-8.828l10.346,0.265L255.999,202.984 M255.999,197.44l-2.327,4.279l-4.169,7.669 l-8.726-0.224l-4.869-0.125l2.542,4.156l4.557,7.445l-4.557,7.445l-2.542,4.156l4.869-0.125l8.726-0.224l4.169,7.669l2.327,4.279 l2.327-4.279l4.169-7.669l8.726,0.224l4.87,0.125l-2.542-4.156l-4.557-7.445l4.557-7.445l2.542-4.156l-4.87,0.125l-8.726,0.224 l-4.169-7.669L255.999,197.44L255.999,197.44z" />
            <polygon fill="#CE1126" points="217.406,289.054 205.436,289.365 211.686,279.149 205.436,268.934 217.406,269.244 223.126,258.718 228.846,269.244 240.815,268.934 234.565,279.149 240.815,289.365 228.846,289.054 223.126,299.579 " />
            <path fill="#1EB53A" d="M223.126,261.494l4.943,9.092l10.346-0.265l-5.402,8.828l5.402,8.828l-10.346-0.265l-4.943,9.092 l-4.943-9.092l-10.346,0.265l5.402-8.828l-5.402-8.828l10.346,0.265L223.126,261.494 M223.126,255.949l-2.327,4.279l-4.169,7.669 l-8.726-0.224l-4.87-0.125l2.542,4.156l4.557,7.445l-4.557,7.445l-2.542,4.156l4.87-0.125l8.726-0.224l4.169,7.669l2.327,4.279 l2.327-4.279l4.169-7.669l8.726,0.224l4.87,0.125l-2.542-4.156l-4.557-7.445l4.557-7.445l2.542-4.156l-4.87,0.125l-8.726,0.224 l-4.169-7.669L223.126,255.949L223.126,255.949z" />
            <polygon fill="#CE1126" points="283.154,289.054 271.184,289.365 277.434,279.149 271.184,268.934 283.154,269.244 288.873,258.718 294.594,269.244 306.564,268.934 300.314,279.149 306.564,289.365 294.594,289.054 288.873,299.579 " />
            <path fill="#1EB53A" d="M288.873,261.494l4.943,9.092l10.346-0.265l-5.402,8.828l5.402,8.828l-10.346-0.265l-4.943,9.092 l-4.943-9.092l-10.346,0.265l5.402-8.828l-5.402-8.828l10.346,0.265L288.873,261.494 M288.873,255.949l-2.327,4.279l-4.169,7.669 l-8.726-0.224l-4.87-0.125l2.542,4.156l4.557,7.445l-4.557,7.445l-2.542,4.156l4.87-0.125l8.726-0.224l4.169,7.669l2.327,4.279 l2.327-4.279l4.169-7.669l8.726,0.224l4.87,0.125l-2.542-4.156l-4.557-7.445l4.557-7.445l2.542-4.156l-4.87,0.125l-8.726,0.224 l-4.169-7.669L288.873,255.949L288.873,255.949z" />
        </svg>
    </React.Fragment>
);
