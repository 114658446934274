
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import MarketDataView from "../../core/components/DataMarket/MarketData/market-data-view.component";

export const MarketData: React.FunctionComponent = (): React.ReactElement => {
    return (
         <MarketDataView />
    );
}
    