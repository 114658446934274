
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const GTM: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <path fill="#4997D0" d="M170.667,423.721H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.1c0-4.875,3.953-8.828,8.828-8.828 h161.839V423.721z" />
            <rect x="170.67" y="88.277" fill="#FFFFFF" width="170.67" height="335.45" />
            <path fill="#4997D0" d="M503.172,423.721H341.333V88.273h161.839c4.875,0,8.828,3.953,8.828,8.828v317.793 C512,419.77,508.047,423.721,503.172,423.721z" />
            <polygon fill="#FFC855" points="253.773,212.107 248.711,214.639 253.368,216.561 " />
            <g>
                <path fill="#73AF00" d="M256,305.65c-28.135,0-51.023-22.89-51.023-51.023c0-17.478,8.797-33.548,23.531-42.989l1.31,2.046 c-14.034,8.993-22.411,24.299-22.411,40.943c0,26.795,21.8,48.594,48.594,48.594L256,305.65L256,305.65z" />
                <path fill="#73AF00" d="M226.798,308.833c1.944,0.396,4.943-0.117,8.023-1.551c3.081-1.436,5.402-3.402,6.349-5.145 c-1.944-0.396-4.943,0.117-8.023,1.551C230.068,305.125,227.746,307.09,226.798,308.833z" />
                <path fill="#73AF00" d="M241.172,302.138c0.396-1.944-0.117-4.943-1.551-8.023c-1.436-3.081-3.402-5.402-5.145-6.349 c-0.396,1.944,0.117,4.943,1.551,8.023C237.462,298.867,239.428,301.189,241.172,302.138z" />
                <path fill="#73AF00" d="M216.633,302.594c1.865,0.678,4.907,0.61,8.164-0.356c3.257-0.967,5.843-2.572,7.036-4.157 c-1.865-0.678-4.907-0.61-8.164,0.356C220.412,299.404,217.826,301.008,216.633,302.594z" />
                <path fill="#73AF00" d="M231.834,298.079c0.678-1.865,0.61-4.907-0.356-8.164c-0.968-3.258-2.572-5.843-4.157-7.036 c-0.678,1.865-0.61,4.907,0.356,8.164C228.644,294.301,230.249,296.887,231.834,298.079z" />
                <path fill="#73AF00" d="M207.231,294.086c1.713,1.001,4.717,1.482,8.096,1.116s6.21-1.481,7.668-2.826 c-1.713-1.001-4.717-1.482-8.096-1.116C211.52,291.626,208.689,292.741,207.231,294.086z" />
                <path fill="#73AF00" d="M222.995,292.375c1.001-1.713,1.482-4.717,1.116-8.096s-1.481-6.21-2.826-7.668 c-1.001,1.713-1.482,4.717-1.116,8.096C220.535,288.086,221.65,290.918,222.995,292.375z" />
                <path fill="#73AF00" d="M199.458,282.336c1.508,1.289,4.38,2.294,7.77,2.531s6.375-0.358,8.047-1.423 c-1.508-1.289-4.38-2.294-7.77-2.531C204.115,280.676,201.131,281.271,199.458,282.336z" />
                <path fill="#73AF00" d="M215.276,283.444c1.289-1.508,2.294-4.38,2.531-7.77c0.237-3.39-0.358-6.375-1.423-8.047 c-1.289,1.508-2.294,4.38-2.531,7.77C213.615,278.788,214.21,281.771,215.276,283.444z" />
                <path fill="#73AF00" d="M194.908,269.395c1.204,1.577,3.799,3.164,7.064,4.109s6.306,0.991,8.167,0.301 c-1.204-1.577-3.799-3.164-7.064-4.109S196.768,268.704,194.908,269.395z" />
                <path fill="#73AF00" d="M210.138,273.805c1.577-1.204,3.164-3.799,4.109-7.064c0.946-3.265,0.991-6.306,0.301-8.167 c-1.577,1.204-3.164,3.799-4.109,7.064C209.494,268.904,209.449,271.944,210.138,273.805z" />
                <path fill="#73AF00" d="M192.982,254.283c0.758,1.834,2.858,4.034,5.77,5.788c2.912,1.753,5.839,2.58,7.815,2.391 c-0.758-1.834-2.858-4.034-5.77-5.788C197.886,254.921,194.957,254.096,192.982,254.283z" />
                <path fill="#73AF00" d="M206.567,262.462c1.834-0.758,4.034-2.858,5.788-5.77c1.753-2.912,2.58-5.839,2.391-7.815 c-1.834,0.758-4.034,2.858-5.788,5.77C207.206,257.56,206.378,260.487,206.567,262.462z" />
                <path fill="#73AF00" d="M194.807,240.016c0.356,1.952,1.949,4.544,4.428,6.869c2.479,2.325,5.17,3.746,7.139,3.977 c-0.356-1.952-1.949-4.544-4.428-6.869C199.467,241.669,196.778,240.246,194.807,240.016z" />
                <path fill="#73AF00" d="M206.376,250.863c1.952-0.356,4.544-1.949,6.869-4.428c2.325-2.479,3.746-5.17,3.977-7.139 c-1.952,0.356-4.544,1.949-6.869,4.428C208.028,246.202,206.605,248.891,206.376,250.863z" />
                <path fill="#73AF00" d="M201.975,224.579c-0.408,1.941,0.085,4.943,1.501,8.033c1.416,3.09,3.368,5.423,5.105,6.382 c0.408-1.941-0.085-4.943-1.501-8.033C205.664,227.87,203.712,225.536,201.975,224.579z" />
                <path fill="#73AF00" d="M208.58,238.994c1.941,0.408,4.943-0.085,8.033-1.501c3.09-1.416,5.423-3.368,6.382-5.105 c-1.941-0.408-4.943,0.085-8.033,1.501S209.538,237.257,208.58,238.994z" />
                <path fill="#73AF00" d="M210.546,213.146c-0.915,1.76-1.247,4.785-0.714,8.141c0.533,3.357,1.785,6.13,3.201,7.519 c0.915-1.76,1.247-4.785,0.714-8.141C213.214,217.309,211.961,214.536,210.546,213.146z" />
                <path fill="#73AF00" d="M213.034,228.807c1.76,0.915,4.785,1.247,8.141,0.714c3.357-0.533,6.13-1.785,7.519-3.201 c-1.76-0.915-4.785-1.247-8.141-0.714C217.196,226.139,214.423,227.391,213.034,228.807z" />
                <path fill="#73AF00" d="M218.951,205.189c-1.164,1.607-1.938,4.548-1.905,7.947s0.864,6.325,2.059,7.908 c1.164-1.607,1.938-4.548,1.905-7.947C220.977,209.699,220.147,206.771,218.951,205.189z" />
                <path fill="#73AF00" d="M219.105,221.044c1.607,1.164,4.548,1.938,7.947,1.905s6.325-0.864,7.908-2.059 c-1.607-1.164-4.548-1.938-7.947-1.905C223.615,219.018,220.689,219.85,219.105,221.044z" />
                <path fill="#73AF00" d="M223.183,216.876c1.977,0.16,4.894-0.707,7.78-2.502c2.887-1.794,4.956-4.024,5.688-5.868 c-1.977-0.16-4.894,0.707-7.78,2.502C225.985,212.801,223.916,215.031,223.183,216.876z" />
                <path fill="#73AF00" d="M256,305.65v-2.43c26.794,0,48.594-21.799,48.594-48.594c0-16.644-8.378-31.951-22.411-40.943 l1.31-2.046c14.734,9.441,23.531,25.512,23.531,42.989C307.023,282.759,284.135,305.65,256,305.65z" />
                <path fill="#73AF00" d="M285.202,308.833c-1.944,0.396-4.943-0.117-8.023-1.551c-3.081-1.436-5.402-3.402-6.349-5.145 c1.944-0.396,4.943,0.117,8.023,1.551C281.932,305.125,284.254,307.09,285.202,308.833z" />
                <path fill="#73AF00" d="M270.828,302.138c-0.396-1.944,0.117-4.943,1.551-8.023c1.436-3.081,3.402-5.402,5.145-6.349 c0.396,1.944-0.117,4.943-1.551,8.023C274.538,298.867,272.572,301.189,270.828,302.138z" />
                <path fill="#73AF00" d="M295.367,302.594c-1.865,0.678-4.907,0.61-8.164-0.356c-3.257-0.967-5.843-2.572-7.036-4.157 c1.865-0.678,4.907-0.61,8.164,0.356C291.588,299.404,294.174,301.008,295.367,302.594z" />
                <path fill="#73AF00" d="M280.165,298.079c-0.677-1.865-0.61-4.907,0.356-8.164c0.968-3.258,2.572-5.843,4.157-7.036 c0.678,1.865,0.61,4.907-0.356,8.164C283.356,294.301,281.751,296.887,280.165,298.079z" />
                <path fill="#73AF00" d="M304.769,294.086c-1.713,1.001-4.717,1.482-8.096,1.116s-6.21-1.481-7.668-2.826 c1.713-1.001,4.717-1.482,8.096-1.116C300.48,291.626,303.311,292.741,304.769,294.086z" />
                <path fill="#73AF00" d="M289.005,292.375c-1.001-1.713-1.482-4.717-1.116-8.096s1.481-6.21,2.826-7.668 c1.001,1.713,1.482,4.717,1.116,8.096S290.35,290.918,289.005,292.375z" />
                <path fill="#73AF00" d="M312.542,282.336c-1.508,1.289-4.38,2.294-7.77,2.531c-3.39,0.237-6.374-0.358-8.047-1.423 c1.508-1.289,4.38-2.294,7.77-2.531C307.885,280.676,310.869,281.271,312.542,282.336z" />
                <path fill="#73AF00" d="M296.724,283.444c-1.289-1.508-2.294-4.38-2.531-7.77c-0.237-3.39,0.358-6.375,1.423-8.047 c1.289,1.508,2.294,4.38,2.531,7.77C298.385,278.788,297.79,281.771,296.724,283.444z" />
                <path fill="#73AF00" d="M317.092,269.395c-1.204,1.577-3.799,3.164-7.064,4.109c-3.264,0.946-6.306,0.991-8.167,0.301 c1.204-1.577,3.799-3.164,7.064-4.109S315.232,268.704,317.092,269.395z" />
                <path fill="#73AF00" d="M301.862,273.805c-1.577-1.204-3.164-3.799-4.109-7.064c-0.946-3.265-0.991-6.306-0.301-8.167 c1.577,1.204,3.164,3.799,4.109,7.064C302.506,268.904,302.551,271.944,301.862,273.805z" />
                <path fill="#73AF00" d="M319.018,254.283c-0.758,1.834-2.858,4.034-5.77,5.788c-2.912,1.753-5.839,2.58-7.815,2.391 c0.758-1.834,2.858-4.034,5.77-5.788C314.114,254.921,317.043,254.096,319.018,254.283z" />
                <path fill="#73AF00" d="M305.433,262.462c-1.834-0.758-4.034-2.858-5.788-5.77c-1.753-2.912-2.58-5.839-2.391-7.815 c1.834,0.758,4.034,2.858,5.788,5.77C304.796,257.56,305.622,260.487,305.433,262.462z" />
                <path fill="#73AF00" d="M317.193,240.016c-0.356,1.952-1.949,4.544-4.428,6.869s-5.17,3.746-7.139,3.977 c0.356-1.952,1.949-4.544,4.428-6.869S315.222,240.246,317.193,240.016z" />
                <path fill="#73AF00" d="M305.624,250.863c-1.952-0.356-4.544-1.949-6.869-4.428c-2.325-2.479-3.746-5.17-3.977-7.139 c1.952,0.356,4.544,1.949,6.869,4.428C303.972,246.202,305.395,248.891,305.624,250.863z" />
                <path fill="#73AF00" d="M310.025,224.579c0.408,1.941-0.085,4.943-1.501,8.033s-3.368,5.423-5.105,6.382 c-0.408-1.941,0.085-4.943,1.501-8.033C306.336,227.87,308.288,225.536,310.025,224.579z" />
                <path fill="#73AF00" d="M303.42,238.994c-1.941,0.408-4.943-0.085-8.033-1.501s-5.423-3.368-6.382-5.105 c1.941-0.408,4.943,0.085,8.033,1.501C300.128,235.305,302.462,237.257,303.42,238.994z" />
                <path fill="#73AF00" d="M301.454,213.146c0.915,1.76,1.247,4.785,0.714,8.141c-0.533,3.357-1.785,6.13-3.201,7.519 c-0.915-1.76-1.247-4.785-0.714-8.141S300.039,214.536,301.454,213.146z" />
                <path fill="#73AF00" d="M298.966,228.807c-1.76,0.915-4.785,1.247-8.141,0.714c-3.357-0.533-6.13-1.785-7.519-3.201 c1.76-0.915,4.785-1.247,8.141-0.714C294.804,226.139,297.577,227.391,298.966,228.807z" />
                <path fill="#73AF00" d="M293.049,205.189c1.164,1.607,1.938,4.548,1.905,7.947s-0.864,6.325-2.059,7.908 c-1.164-1.607-1.938-4.548-1.905-7.947C291.023,209.699,291.853,206.771,293.049,205.189z" />
                <path fill="#73AF00" d="M292.895,221.044c-1.607,1.164-4.548,1.938-7.947,1.905s-6.325-0.864-7.908-2.059 c1.607-1.164,4.548-1.938,7.947-1.905S291.311,219.85,292.895,221.044z" />
                <path fill="#73AF00" d="M288.817,216.876c-1.977,0.16-4.894-0.707-7.78-2.502c-2.887-1.794-4.956-4.024-5.688-5.868 c1.977-0.16,4.894,0.707,7.78,2.502C286.015,212.801,288.084,215.031,288.817,216.876z" />
            </g>
            <path fill="#966446" d="M228.146,294.349l-8.844,8.844l-4.361-8.723l13.084-13.084l4.361,4.361l-1.26,3.779 C230.52,291.344,229.5,292.995,228.146,294.349z" />
            <path fill="#B9B9B9" d="M275.997,229.053l-47.972,47.972c-1.204,1.204-1.204,3.157,0,4.361l2.18,2.18l8.723-8.723 l34.889-39.251l4.361-4.361L275.997,229.053z" />
            <path fill="#966446" d="M239.83,269.581c-0.578,0.578-0.904,1.363-0.904,2.18v3.084l34.889-39.251L239.83,269.581z" />
            <polygon fill="#A0A0A0" points="278.177,231.233 275.997,229.053 273.816,231.233 275.997,233.415 278.177,235.596 302.164,207.249 " />
            <path fill="#966446" d="M286.284,294.349l8.844,8.844l4.361-8.723l-13.084-13.084l-4.361,4.361l1.26,3.779 C283.91,291.344,284.93,292.995,286.284,294.349z" />
            <path fill="#B9B9B9" d="M238.433,229.053l47.972,47.972c1.204,1.204,1.204,3.157,0,4.361l-2.18,2.18l-8.723-8.723 l-34.889-39.251l-4.361-4.361L238.433,229.053z" />
            <path fill="#966446" d="M274.6,269.581c0.578,0.578,0.904,1.363,0.904,2.18v3.084l-34.889-39.251L274.6,269.581z" />
            <polygon fill="#A0A0A0" points="236.253,231.233 238.433,229.053 240.614,231.233 238.433,233.415 236.253,235.596 212.266,207.249 " />
            <path fill="#B9B9B9" d="M239.315,292.534l2.167,1.976c7.906-8.668,21.741-23.837,43.673-43.529 C285.156,250.982,266.986,262.198,239.315,292.534z" />
            <path fill="#FFE15A" d="M233.184,301.431L233.184,301.431c-0.778-0.71-0.833-1.916-0.124-2.694l4.644-5.092 c0.71-0.778,1.916-0.833,2.694-0.124l0,0c0.778,0.71,0.833,1.916,0.124,2.694l-4.644,5.092 C235.168,302.086,233.962,302.142,233.184,301.431z" />
            <path fill="#FFC855" d="M237.556,290.931l-1.258,0.561c-1.119,0.557-4.063,2.222-4.57,4.595 c-0.242,1.124,0.099,2.219,0.972,3.255c0.083-0.214,0.196-0.422,0.361-0.601l0.585-0.641c-0.395-0.574-0.605-1.138-0.486-1.704 c0.318-1.484,2.298-2.809,3.542-3.466l5.564,5.075l1.384-1.517L237.556,290.931z" />
            <path fill="#B9B9B9" d="M275.093,294.934l-2.167,1.976c-7.906-8.668-21.741-23.837-43.673-43.529 C229.252,253.382,247.422,264.596,275.093,294.934z" />
            <path fill="#FFE15A" d="M281.224,303.832L281.224,303.832c0.778-0.71,0.833-1.916,0.124-2.694l-4.644-5.092 c-0.71-0.778-1.916-0.833-2.694-0.124l0,0c-0.778,0.71-0.833,1.916-0.124,2.694l4.644,5.092 C279.24,304.487,280.446,304.542,281.224,303.832z" />
            <path fill="#FFC855" d="M276.852,293.329l1.258,0.561c1.119,0.557,4.063,2.222,4.57,4.595 c0.242,1.124-0.099,2.219-0.972,3.255c-0.083-0.214-0.196-0.421-0.361-0.601l-0.585-0.641c0.395-0.574,0.605-1.138,0.486-1.704 c-0.318-1.484-2.298-2.809-3.542-3.466l-5.564,5.075l-1.384-1.517L276.852,293.329z" />
            <path fill="#69B469" d="M258.161,214.684c0.17-0.422,0.269-0.879,0.269-1.362c0-2.013-1.632-3.645-3.645-3.645 c-2.013,0-3.645,1.632-3.645,3.645c0,1.199,0.587,2.254,1.482,2.919c-1.318,10.585,9.119,11.394,13.096,12.874 c6.034,2.244,13.603,6.787,13.127,19.484c-0.462,12.342-8.267,24.251-8.267,24.251l6.479-8.808 c-2.369,10.02-7.901,22.784-21.057,37.963c0,0,8.995-8.799,16.501-20.146c-5.523,12.993-11.642,25.005-11.642,25.005 s10.833-14.072,19.59-38.419C291.065,238.927,268.733,221.193,258.161,214.684z" />
            <rect x="239" y="258.267" fill="#F4E08F" width="9.719" height="4.86" />
            <g>
                <circle fill="#ECD077" cx="239" cy="263.128" r="4.86" />
                <circle fill="#ECD077" cx="239" cy="231.548" r="4.86" />
            </g>
            <path fill="#FCF0A8" d="M268.148,236.404c2.684,0,4.86-2.176,4.86-4.86s-2.176-4.86-4.86-4.86h-29.156 c2.684,0,4.86,2.176,4.86,4.86s-2.176,4.86-4.86,4.86s-4.86-2.176-4.86-4.86c0,8.157,6.826,18.019,9.027,26.727 c0,0,0.692,2.176,0.692,4.86s-2.176,4.86-4.86,4.86h29.156c2.684,0,4.86-2.176,4.86-4.86c0-8.157-6.826-18.019-9.027-26.727 L268.148,236.404L268.148,236.404z" />
            <g>
                <path fill="#F4E08F" d="M243.852,231.546c0,2.684-2.176,4.86-4.86,4.86h29.156c2.684,0,4.86-2.176,4.86-4.86H243.852z" />
                <path fill="#F4E08F" d="M243.852,263.13c0,2.684-2.176,4.86-4.86,4.86h29.156c2.684,0,4.86-2.176,4.86-4.86H243.852z" />
            </g>
        </svg>
    </React.Fragment>
);
