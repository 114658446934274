import * as React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { style } from "./brokers-modal.style";
import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { BrokerDataValue } from "../../../../graphql";

export interface BrokerModalProps {
    type: 'buy' | 'sell' | 'none';
    brokers: BrokerDataValue[];
    onClose: () => void;
}

export function BrokerModal(props: BrokerModalProps) {
    const { type, brokers, onClose } = props;

    const [showDisclaimer, setShowDisclaimerState] = React.useState<boolean>(false);

    if( ! brokers || ! brokers.length )
        return <></>;

    return (
        <div css={style.modalOverlay}>
            <div css={style.modalBox}>
                <div css={[style.modalBox, style.modalBoxContent]}>
                    <button css={style.modalCloseButton}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                                e.preventDefault();
                                onClose();
                            }}>
                        <Icon icon={'times'}/>
                    </button>
                    <div css={style.modalHead}>
                        <div css={[ style.iconCircle, type === 'buy' ? style.iconCircleGreen : style.iconCircleRed ]}>
                            <Icon icon={type === 'buy' ? 'arrow-down' : 'arrow-up'}/>
                        </div>
                        <div css={style.modalTitle}>
                            {type === 'buy' ? 'Buy' : 'Sell'}
                        </div>
                    </div>
                    <div css={style.modalHeadLine}>
                        Links for potential Brokers for your chosen financial product
                    </div>
                    <div css={style.modalBrokers}>
                        {
                            brokers.map((broker: BrokerDataValue, index: number): React.ReactNode => (
                                <a  href={broker.urls[0] ? broker.urls[0] : ''}
                                    target='_blank'
                                    css={style.modalBrokersItem}
                                    key={`broker-modal-box-brokers-item-${index}`}
                                >
                                    <div css={style.modalBrokersItemImg}>
                                        <img src={broker.logoUrl as string}
                                             alt={broker.name as string}/>
                                    </div>
                                    <div css={style.modalBrokersItemIcon}>
                                        <Icon icon={'external-link-alt'}/>
                                    </div>
                                </a>
                            ))
                        }
                    </div>

                    <div css={style.modalDisclaimer}>
                        <button css={style.modalDisclaimerButton}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                                    e.preventDefault();

                                    setShowDisclaimerState(!showDisclaimer);
                                }}>
                            {`${!showDisclaimer ? 'Read' : 'Close'} the disclaimer`}
                        </button>
                        {
                            showDisclaimer && (
                                <div css={style.modalDisclaimerContent}>
                                    <p>
                                        With the [below] trading button, LuxSE is providing a facility allowing you to trade the relevant securities quickly.<br />
                                        Please note that the brokers mentioned below (the Brokers) have not been selected or appointed by LuxSE. The list of Brokers is provided for information purposes only. The Brokers are and remain beyond LuxSE's control under any circumstances. The list of Brokers for each financial instrument may not be exhaustive.<br />
                                        The trading button facility does not constitute and is not construed as any advice, solicitation, offer, endorsement, commitment or recommendation on behalf of LuxSE to invest in any financial instrument and/ or to execute any orders or transactions through one of these Brokers. It does not represent or constitute any warranty or representation from LuxSE in connection with (i) the good standing, profitability, suitability or reliability of any financial instrument and/ or of any of the Brokers. You are choosing the relevant Broker to trade with under your full control and responsibility and at your own risks.<br />
                                        By clicking the trading button below, you will leave LuxXPrime's website. LuxSE disclaims any warranty and representation and excludes any responsibility or liability of any kind for (i) the content, service, security, accuracy or availability of the Broker's website and services and/ or (ii) any delay, failure, rejection or default of any payment, order or transaction made with or by the Broker.
                                    </p>
                                </div>
                            )
                        }
                    </div>

                    <div className='broker-modal-box__back'>
                        <Button label='Back to security'
                                onClick={(): void => {
                                    onClose();
                                }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}