import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { style } from "./disclaimer.style";
import { Security } from "../../../../interfaces";
import { MarketRule } from "../../../../enums";

export interface SecurityDisclaimerProps {
  security?: Security;
}

export const SecurityDisclaimer: React.FunctionComponent<SecurityDisclaimerProps> = ({
  security,
}: SecurityDisclaimerProps) => {

  let disclaimerAtt : boolean = security?.tags?.includes("ATT");
  let disclaimerSol : boolean = security?.marketRule === MarketRule.SOL;
  let disclaimerProfessional : boolean = ["W5", "WI", "WK", "WL", "WJ", "W6", "WM", "WN"].includes(security?.marketData?.tradingGroupCode);

  return (
    <>
      { disclaimerAtt || disclaimerSol || disclaimerProfessional ?
        <div css={style.section}>
          <div css={style.sectionTop}>
            <div css={style.flexRow}>
              <div css={style.blocTitle}>Disclaimer</div>
              
              { disclaimerSol ?
                <div css={style.blocBody}>
                  <div css={style.disclaimerSection}>
                      <em>The Information, data and prices (if any) displayed on this page (collectively the Information) are made available for general information purposes. The Information cannot be disseminated, displayed, sold or distributed, in any manner, in whole or in part, freely or against payment, without the prior written consent of the Luxembourg Stock Exchange (LuxSE).</em>
                  </div>
                  <div css={style.disclaimerSection}>
                      <em>Prices that may be displayed in this page are estimative and have been provided to LuxSE by the issuer of the relevant financial instruments (or its agents, as the case may be). The Information is not and shall not be a substitute for your own researches, investigations, verifications, checks or consultation for professional or investment advice. You are using the Information at your own risks.</em>
                  </div>
                  <div css={style.disclaimerSection}>
                      <em>LuxSE  does not give any representation or warranty and disclaims any liability or responsibility of any kind in connection with the accuracy, correctness, timeliness, fitness for a particular purpose, reliability or completeness of the Information. LuxSE does not accept any liability or responsibility of any kind for any loss or damages arising directly or indirectly from the use of, or any action refrained or taken on any Information.</em>
                  </div>
                  <div css={style.disclaimerSection}>
                      <em>The Information does not constitute and is not construed as any advice, solicitation, offer, endorsement, commitment or recommendation on behalf of LuxSE to  invest in any financial instrument. Neither does the publication of the Information by LuxSE represent or constitute any warranty or representation from LuxSE in connection with the good standing, profitability, suitability or reliability of any financial instrument and/ or of any issuer.</em>
                  </div>
                  <div css={style.disclaimerSection}>
                      <em>LuxSE cannot represent or warrant that the Information will be updated, displayed or made available in real time and/ or without any delay.</em>
                  </div>
                </div>
                :<></>
              }

              { disclaimerAtt ?
                <div css={style.blocBody}>
                  <div css={style.disclaimerSection}>
                      <em>The securities mentioned on this webpage are admitted to trading without being admitted to Luxembourg Stock Exchange's (LuxSE) official list (listing) in accordance with sections 503 or 603 of LuxSE Rules & Regulations. These securities have been admitted to trading without the consent of their issuer.</em>
                  </div>
                  <div css={style.disclaimerSection}>
                      <em>The information and data (if any) displayed on this page (collectively the Information) are made available for general information purposes. The Information cannot be disseminated, displayed, sold or distributed, in any manner, in whole or in part, freely or against payment, without LuxSE's prior written consent. The Information is not and shall not be a substitute for your own researches, investigations, verifications, checks or consultation for professional or investment advice. You are using the Information at your own risks.</em>
                  </div>
                  <div css={style.disclaimerSection}>
                      <em>Although the Information is coming from external sources that are deemed reliable in LuxSE's reasonable opinion, LuxSE does not give any representation or warranty and disclaims any liability or responsibility of any kind in connection with the accuracy, correctness, timeliness, fitness for a particular purpose, reliability or completeness of the Information. LuxSE does not accept any liability or responsibility of any kind for any loss or damages arising directly or indirectly from the use of, or any action refrained or taken on any Information.</em>
                  </div>
                  <div css={style.disclaimerSection}>
                      <em>The Information does not constitute and is not construed as any advice, solicitation, offer, endorsement, commitment or recommendation on behalf of LuxSE to invest in any financial instrument. Neither does the publication of the Information by LuxSE represent or constitute any warranty or representation from LuxSE in connection with the good standing, profitability, suitability or reliability of any financial instrument and/ or of any issuer.</em>
                  </div>
                  <div css={style.disclaimerSection}>
                      <em>LuxSE cannot represent or warrant that the Information will be updated, displayed or made available in real time and/ or without any delay.</em>
                  </div>
                </div>
                :<></>
              }

              { disclaimerProfessional ?
                <div css={style.blocBody}>
                  <div css={style.disclaimerSection}>
                    <em>The professional segments (the <b>"Professional Segments"</b>) of the securities markets operated by the Luxembourg Stock Exchange (<b>"LuxSE"</b>) are the division of LuxSE's relevant securities market, which is accessible only to Professional Clients or Qualified/Well-informed Investors, as defined under LuxSE Rules & Regulations (<b>"R&R"</b>).</em>
                  </div>
                  <div css={style.disclaimerSection}>
                    <em>Issuers specifically apply to have their financial instruments admitted to any of the Professional Segments. Access by Members to any of the trading group(s) under the relevant Professional Segment is on demand only. LuxSE does not verify and is not responsible for verifying whether clients/investors are qualified to operate in the abovementioned Professional Segments. Members shall, under their full responsibility and control, verify and ensure that orders involving instruments traded on any of the Professional Segments do not come from clients other than Professional Clients or Qualified/Well-informed Investors. Further information on the Professional Segments is available on LuxSE Trading Manual and in the R&R.</em>
                  </div>
                  <div css={style.disclaimerSection}>
                    <em>The information, data and prices (if any) displayed on this page (collectively the <b>"Information"</b>) are made available for general information purposes. LuxSE does not give any representation or warranty and disclaims any liability or responsibility of any kind in connection with the accuracy, correctness, timeliness, fitness for a particular purpose, reliability or completeness of the Information. LuxSE does not accept any liability or responsibility of any kind for any loss or damages arising directly or indirectly from the use of or any action refrained or taken on any Information. The Information does not constitute and is not construed as any advice, solicitation, offer, endorsement, commitment or recommendation of any kind on behalf of LuxSE to invest in any financial instrument. Neither does the publication of the Information by LuxSE represent or constitute any warranty or representation from LuxSE in connection with the good standing, profitability, suitability or reliability of any financial instrument and/ or of any issuer. Information should and shall not be regarded as legal accounting, investment, financial or other professional advice nor is it intended for such use.</em>
                  </div>
                </div>
                :<></>
              }

            </div>
          </div>
        </div>
        : <></>
      }
    </>
  );
};