import { breakpoint, breakpointMax } from "../../../../../style";

import { css } from "@emotion/react";

export default {
  container: css`
    display: flex;
    align-items: center;
    gap: 1em;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      align-items: flex-start;
    }
  `,
  chevron: css``,
  info: css`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
  `,
  date: css`
    color: white;
    font-size: 0.8em;
  `,
  dateGrey: (color = '#354450') => css`
    color: ${color};
    font-size: 0.8em;
    margin-top: 8px;
  `,
  change: css`
    color: '#354450';
    margin-top: 8px;
  `,
  chartDate: css`
    color: #354450;
    font-size: 0.8em;
  `,
  bold: css`
    font-weight: 700;
    line-height: 1.7;
  `,
  subLabel: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #657f93;
  `,
  cellSplitTwo: css`
    display: flex;
  `,
  cellSplitLeft: css`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 5px;
    text-align: center;
  `,
  cellSplitRight: css`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 5px;
    text-align: center;
  `,
  cellSplitSeparator: css`
    border-left: solid 1px #e3e6e8; ;
  `,
}
export const containerCss = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: -16px;
`;