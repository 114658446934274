
/*
*   e-Listing - core - components - country flag icon - ita
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const ITA: JSX.Element = (
    <svg id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <polygon style={{fill: '#F0F0F0'}} points='341.334,85.33 170.666,85.33 0,85.33 0,426.662 170.666,426.662 341.334,426.662   512,426.662 512,85.33 '/>
        <rect y='85.333' style={{fill: '#6DA544'}} width='170.663' height='341.337'/>
        <rect x='341.337' y='85.333' style={{fill: '#D80027'}} width='170.663' height='341.337'/>
    </svg>
);
