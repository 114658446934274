/** @jsx jsx */

import * as React from 'react';
import { css, jsx } from "@emotion/react";
import { Text, 
  ComponentFields, 
  ComponentParams, 
  ComponentRendering, 
  getFieldValue, 
} from "@sitecore-jss/sitecore-jss-react";
import { SitecoreGenericTextField } from '../../interfaces';
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpointMaxMobile, breakpointMax, breakpointChartMax, breakpointMarketCapMax, breakpointMinMobile, breakpointMobileSmall, breakpointDesktopSmall, breakpointDesktopSmallMin, breakpointDesktopLargest } from '../../style';
import { renderLink } from "../../utils/helper";
import hexa from './hexa.svg';
import { canUseDOM } from '../../predicates';
import moment from 'moment';

export interface BlogHighlightProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const style = {
  container: css`
  padding: 50px;
  padding-top: 15px;
  border: 1px solid #e3e6e8;
  border-radius: 16px;
  height: calc(50% - 8px);
  width: 600px;
  // max-width: calc(50% - 120px);
  background: #ffffff;
  border-radius: 16px;
  align-items: center;
  display: block;
  position: relative;
  top: 50%;
  left: 42%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: #354450;
  font-weight: 800;

  //desktop 4K
  @media (min-width: ${breakpointDesktopLargest}) {
    left: 45%;
  }

  //large desktop
  @media (max-width: ${breakpointChartMax}) {
    left: 40.5%;
  }

  //small desktop
  @media ((min-width: ${breakpointDesktopSmall}) and (max-width: ${breakpointMarketCapMax})) {
    left: 40.5%;
  }

  //tablet  
  @media ((min-width: ${breakpointMax}) and (max-width: ${breakpointDesktopSmallMin})) {
    max-width: 500px;
    transform: translate(-40%, -52%);
    left: 44%;
  }

  //mobile
  @media ((min-width: ${breakpointMinMobile}) and (max-width: ${breakpointMaxMobile})) {
    max-width: 90%;
    margin-left: 38px;
    top: 0;
    display: inline-block;
  }

  //mobile small
  @media ((min-width: 0px) and (max-width: ${breakpointMobileSmall})) {
    max-width: 90%;
    margin-left: 31px;
    top: 30px;
    display: inline-block;
  }
`,
  icon: css`
  background-image: url(${hexa});
  background-position: center center;
  margin-right: 0px;
  min-height: 50px;
  min-width: 50px;
  align-items: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }

  `,
  timeAndDate: css`
    margin-bottom: -40px;
    margin-left: 52px;
    margin-top: 20px;
  `,
  header: css`
  display: flex;
  width: 100%;
  margin-left: -23px;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`,
  headerRight: css`
  display: flex;
  flex-direction: row;
  width: calc(100% - 100px);
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    flex-direction: column;
  }
`,
  headerRightContent: css`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  width: 100%;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding-left: 0;
  }
`,
  headerRightContentSubtitle: css`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #ca1234;
  margin-bottom: 8px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ca1234;
  }
`,
  headerRightContentTitle: css`
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #253845;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 24px;
    line-height: 38px;
    color: #253845;
    margin-bottom: -10px;
  }
  &:hover {
    color: #5e798d;
  }
  transition: all 150ms ease-in-out;
`,
  timeToReadIndicators: css`
  font-style: normal;
  font-weight:600;
  font-size: 14px;
  line-height: 20px;
  color: #425563;
  
  svg {
    margin-right: 4px;
    opacity: .4;
  }
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color:  #425563;
  }
  i {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #D9DEE2;
    margin-right: 15px;
  }
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`,
};

export const BlogHighlight: React.FunctionComponent<BlogHighlightProps> = (
    props: BlogHighlightProps
    ): React.ReactElement => {
    const { fields, params, rendering } = props;

    const linkToArticle: string = getFieldValue<string>(fields, 'linkToArticle', '');
    const icon: string = getFieldValue<string>(fields, 'icon', '');
    const timeToRead: string = getFieldValue<string>(fields, 'timeToRead', '');
    const date: any = getFieldValue<any>(fields, 'date', '');

    const triggerAction = () => {
      if (linkToArticle) {
        window.location.href = linkToArticle;
    }
  };

  return (
    <div css={style.container} onClick={triggerAction} >
      <div css={style.header}>
        <div css={style.icon}>
          <Icon icon={icon as any} />
        </div>
        <div css={style.headerRight}>
          <div css={style.headerRightContent}>
            <div css={style.headerRightContentSubtitle}>
              <Text field={fields && (fields.subtitle as SitecoreGenericTextField)} />
            </div>
            <div css={style.headerRightContentTitle}>
              <Text field={fields && (fields.title as SitecoreGenericTextField)} />
            </div>
          </div>
        </div>
      </div>
      <div css={style.timeAndDate}>
        <div css={style.timeToReadIndicators}>
          <Icon icon={'book-open'} /> {timeToRead} <span><i>   </i> {moment(date).format('DD MMM YYYY.')} </span>
        </div>
      </div>
    </div>
  );
};
    