import { css } from "@emotion/react";
import { breakpointMax } from "../../../style";

export const style = {
    chinaGraph:css`
        display: flex;
        align-items: center;
        background: #ffffff;
        justify-content: space-around;
        height: 184px;
        min-width: 100%;
        margin-top: 15px;
        cursor: pointer;
        :hover {
            background: #f8fafc;
            box-shadow: 0 0.563em 1.25em -0.625em rgb(66 85 99 / 25%);
        }
        text-decoration: none;
        transition: all 0.5s ease;
    `,
    labels: css`
    `,
    labelsTop: css`
    `,
    labelsBottom: css`
    `,
    price: css`
        font-size: 20px;
        line-height: 26px;
        color: #354450;
    `,
    variation : css`
        font-size: 16px;
        line-height: 20px;
        color: #22AA5F;
    `,
    chart: css`
        width: 100%;
        display: flex;
        background: #ffffff;
        box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
        border-radius: 8px;
        padding: 32px;
        cursor: pointer;
        margin-bottom: 16px;
    `,
    chartLeft: css`
        width: 210px;
        display: flex;
        flex-direction: column;
        background: #f1f1f1;
    `,
    chartRight: css`
        width: calc(100% - 210px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 32px;
        font-weight: bold;
        font-size: 24px;
        line-height: 31px;
        color: #354450;

        svg {
            color: #22AA5F;
        }
    `,
    chartSide: css`
        position: relative;
        height: 100% !important;
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `,
    chartNumber: css `
        position: absolute;
        top: 15%;
    `,
    chartChange: css`
        font-weight: 600;
        font-size: 16px;
    `,
    chartAmount: css `
        font-weight: 600;
        font-size: 20px;
        color: #425563;
    `,
    chartLabel: css`
        color: #354450;
        font-weight: 700;
        font-size: 24px;
        Line-height: 30.67px;
        width: 60%;
        @media (max-width: ${breakpointMax}) {
            font-size: 18px;
            width: 50%
        }
    `,
    chartIcon: css`
        color: #22AA5F;
        width: 5%;
        align-items: center;
        display: flex;
        svg {
            width: 30px !important;
            height: 30px !important;
        }
    `,
}