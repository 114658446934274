import * as React from "react";
import { css } from "@emotion/react";
import hexa from './hexa.svg';
import { breakpointMax } from "../../../style";

export const style = {
  parentContainer: css`
    width: 100%;
    background: url(${hexa});
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 500px;
    @media (max-width: ${breakpointMax}) {
      background-size: 200px;
    }
  `,
  container: css`
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: ${breakpointMax}) {
      min-height: 400px;
    }
  `,
  title: css`
    font-weight: 400;
    font-size: 48px;
    line-height: 61px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #253845;
    @media (max-width: ${breakpointMax}) {
      font-size: 28px;
    }
  `,
  description: css`
    font-weight: 700;
    font-size: 32px;
    line-height: 41px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #425563;
    margin-bottom: 38px;
    margin-top: 8px;
    @media (max-width: ${breakpointMax}) {
      font-size: 20px;
      margin-bottom: 16px;
      margin-top: 0;
    }
  `,
  link: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    text-decoration-line: underline;
    margin-bottom: 15px;

    @media (max-width: ${breakpointMax}) {
      font-size: 14px;
    }
    
    a {
      color: #12CA64;
    }
  `,
};
