/** @jsx jsx */

import * as React from "react";

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import hexa from "./back.svg";
import {renderLink} from "../../utils/helper";

export interface HexaBackgroundProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 85%;
  position: absolute;
  z-index: -10;
  left: 0;
  top: 137px;
  pointer-events: none;
`;
const hexabg = css`
  z-index: -1;
  width: 100%;
`;

export const HexaBackground: React.FunctionComponent<HexaBackgroundProps> = (
  props: HexaBackgroundProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const headingValue: string = getFieldValue<string>(fields, "heading", "");

  return (
    <div css={container}>
      <img src={renderLink(hexa)} css={hexabg} alt={"image"} />
    </div>
  );
};
