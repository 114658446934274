
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import OfficialListView from "../../core/components/OfficialList/official-list-view.component";

export const OfficialList: React.FunctionComponent = (): React.ReactElement => {
    return (
        <OfficialListView/>
    );
}
    