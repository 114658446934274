import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Thead, Tr, Th } from '@bdl-cmn-shared-packages-npm/design-system';
import { data as dataMapper } from '../../../Search/results/dataMapper';

const thStyle = css`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px #d9dee2;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 2em;
  padding-right: 2em;
  text-align: left;
`;

const ProgrammeHeader = ({ selectedCols }: any) => {
  return (
    <Thead>
      <Tr>
        <Th>Type</Th>
        <th
          css={[
            thStyle,
            css`
              width: 20%;
            `,
          ]}
        >
          Issuers
        </th>
        <Th>
          <div style={{ minWidth: 300 }}>Name</div>
        </Th>
        <th
          css={[
            thStyle,
            css`
              width: 9%;
            `,
          ]}
        >
          Start Date
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 9%;
            `,
          ]}
        >
          End Date
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 8%;
            `,
          ]}
        >
          Securities
        </th>
        <Th>Max. amount</Th>
        {selectedCols ? (
          selectedCols['programmes'].map((col: string, index: number) => (
            <Th key={`col-${index}`}>{dataMapper['programmes'][col].header}</Th>
          ))
        ) : (
          <></>
        )}
      </Tr>
    </Thead>
  );
};

export default ProgrammeHeader;
