import * as React from "react";

import { css, jsx } from "@emotion/react";

import { sharedStyle } from "../../../../../../styles/shared";

/** @jsx jsx */

export const NewListingsHeader = () => {
  return (
    <thead
      style={{
        background: "#F8FAFC",
        color: "#253645",
        fontSize: "0.8rem",
        textAlign: "left",
      }}
    >
      <tr>
        <th css={sharedStyle.tableNewSecurityTh}>Security</th>
        <th css={sharedStyle.statusTh}>Status</th>
      </tr>
    </thead>
  );
};
export default NewListingsHeader;
