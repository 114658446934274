import * as React from "react";

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Link,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpoint, breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from "../../interfaces";
import brands from "./brands.svg";
import { renderLink } from "../../utils/helper";
import CountUpComponent from "../../core/components/CountUp/CountUpComponent";
import DictionaryComponent from "../../core/components/Dictionary/DictionaryComponent";
import { FormBuilder } from "../../core/components/FormBuilder/formBuilder.component";

/** @jsx jsx */

export interface HomeComingJoinComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const HomeComingJoinComponent: React.FunctionComponent<HomeComingJoinComponentProps> =
  (props: HomeComingJoinComponentProps): React.ReactElement => {
    const { fields } = props;
    const [visibleModal, setVisibleModal] = React.useState<boolean>(false);

    const button1Label: string = getFieldValue<string>(fields,"button label", "");
    const buttonLink: string = getFieldValue<string>(fields, 'button link', '');
    const brandsImage: any = getFieldValue<any>(fields,"background brands", null);
    const largePadding: boolean = getFieldValue<boolean>(fields,"largePadding", false);

    const triggerAction = () => {
      if (buttonLink) {
        window.location.href = buttonLink;
      }
    };

    const style = {
      container: (image?: any) => css`
        width: 100%;
        // min-height: 740px;
        // margin-top: 10px;
        min-height: ${largePadding ? "740px" : ""};
        padding-top: ${largePadding ? "" : "50px"};
        padding-bottom: ${largePadding ? "" : "50px"};
        background: url(${renderLink(image?.src)});
        background-repeat: no-repeat;
        background-size: 100%;
    
        @media (max-width: ${breakpointMax}) {
          background: none;
          margin-top: 0;
          min-height: 0;
        }
      `,
      content: css`
        width: 100%;
        display: flex;
        max-width: 800px;
        padding-top: 40px;
        margin: auto;
        flex-direction: column;
        @media (max-width: ${breakpointMax}) {
          padding-left: 16px;
          padding-right: 16px;
        }
      `,
      title: css`
        margin-top: 80px;
        color: #354450;
        font-size: 48px;
        font-weight: 800;
        text-align: center;
        @media (max-width: ${breakpointMax}) {
          margin-top: 40px;
          font-weight: 800;
          font-size: 32px;
          line-height: 41px;
          text-align: center;
        }
      `,
      subTitle: css`
        margin-top: 32px;
        color: #354450;
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;
        text-align: center;
        @media (max-width: ${breakpointMax}) {
          font-weight: 600;
          font-size: 14px;
          line-height: 180%;
          text-align: center;
          letter-spacing: 0.01em;
        }
      `,
      actions: css`
        margin-top: 40px;
        display: flex;
        justify-content: center;
    
        button {
          font-size: 16px;
          font-weight: 600;
        }
    
        @media (max-width: ${breakpointMax}) {
          margin-bottom: 40px;
        }
      `,
      left: css`
        width: 200px;
      `,
      right: css`
        width: 200px;
      `,
      logo1: css`
        position: absolute;
        width: 110px;
        margin-left: -20px;
      `,
      logo2: css`
        position: absolute;
        width: 70px;
        margin-top: 60px;
        margin-left: 6%;
      `,
      logo3: css`
        position: absolute;
        width: 100px;
        margin-top: 250px;
        margin-left: 22%;
      `,
      logo4: css`
        position: absolute;
        width: 110px;
        margin-top: 330px;
        margin-left: 10%;
      `,
      logo5: css`
        position: absolute;
        width: 60px;
        margin-top: 370px;
        margin-left: 45%;
      `,
      logo6: css`
        position: absolute;
        width: 110px;
        margin-top: 80px;
        margin-left: 65%;
      `,
      logo7: css`
        position: absolute;
        width: 80px;
        margin-top: 300px;
        margin-left: 55%;
      `,
      logo8: css`
        position: absolute;
        width: 100px;
        margin-top: 220px;
        margin-left: 64%;
      `,
      logo9: css`
        position: absolute;
        width: 100px;
        margin-top: 270px;
        margin-left: 49%;
      `,
      logo10: css`
        position: absolute;
        width: 100px;
        margin-top: 70px;
        margin-left: 13%;
      `,
    };

    return (
      <div css={style.container(brandsImage)}>
        {visibleModal && (
          <FormBuilder
            onClickOutside={() => setVisibleModal(false)}
            embedCode={fields?.formCode?.value}
          >
            <div id="childrenPart">
              {fields?.formCode?.value && (
                <div
                  dangerouslySetInnerHTML={{ __html: fields?.formCode?.value }}
                />
              )}
            </div>
          </FormBuilder>
        )}
        <div css={style.content}>
          <div css={style.title}>
            <DictionaryComponent text={fields.title.value}/>
          </div>
          <div css={style.subTitle}>
            <Text
              field={fields && (fields.subTitle as SitecoreGenericTextField)}
            />
          </div>
          <div css={style.actions}>
              <Button label={button1Label} iconRight={"chevron-right"} 
              onClick={() => {
                if (fields.formCode?.value) {
                  setVisibleModal(true);
                } else if (buttonLink) {
                  triggerAction();
                }
              }}
              />
          </div>
        </div>
      </div>
    );
  };
