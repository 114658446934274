
/*
*   e-Listing - core - components - country flag icon - irl
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const IRL: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.331' style={{fill: '#F0F0F0'}} width='512' height='341.326'/>
        <rect y='85.331' style={{fill: '#6DA544'}} width='170.663' height='341.337'/>
        <rect x='341.337' y='85.331' style={{fill: '#FF9811'}} width='170.663' height='341.337'/>
    </svg>
);
