/** @jsx jsx */

import * as React from 'react';

import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { SitecoreGenericTextField } from '../../interfaces';
import { renderLink } from '../../utils/helper';

export interface DoubleColumnsWithDoubleColumnsComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  @media (max-width: ${breakpointMax}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const left = css`
  width: 50%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const doubleLeft = css`
  width: 50%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const itemTitle = css`
  font-weight: 800;
  font-size: 32px;
  line-height: 41px;
  color: #354450;
  margin-bottom: 32px;
  display: flex;
  min-height: 80px;

  @media (max-width: ${breakpointMax}) {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 24px;
    min-height: 0;
  }
`;
const right = css`
  width: 50%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    margin-top: 24px;
  }
`;
const action = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  justify-content: center;
  display: flex;
`;
const title = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  margin-bottom: 32px;

  @media (max-width: ${breakpointMax}) {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
  }
`;
const description = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #354450;
  margin-bottom: 32px;

  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
  }
`;
const double = css`
  width: 100%;
  display: flex;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const item = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;
const icon = css`
  color: #22AA5F;
  margin-right: 16px;
`;
const text = css`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0.01em;
  }
`;

export const DoubleColumnsWithDoubleColumnsComponent: React.FunctionComponent<DoubleColumnsWithDoubleColumnsComponentProps> =
  (props: DoubleColumnsWithDoubleColumnsComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const imageSource: any = getFieldValue<any>(fields, 'imageSide', null);
    const btnLabel: string = getFieldValue<string>(fields, 'btnLabel', '');
    const btnLink: string = getFieldValue<string>(fields, 'btnLink', '');
    const mailToSubject: string = getFieldValue<string>(fields, 'mailToSubject', '');
    const mailTo: string = getFieldValue<string>(fields, 'mailTo', '');

    const image = css`
      background-image: url(${renderLink(imageSource && imageSource.src)});
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      max-height: 650px;
      max-width: 500px;
      display: flex;
      justify-content: flex-end;
      margin-right: 96px;
      align-items: center;

      @media (max-width: ${breakpointMax}) {
        width: 100%;
        min-height: 480px;
        background-size: 100%;
      }
    `;
    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={left}>
            <div css={image} />
          </div>
          <div css={right}>
            <div css={title}>
              <Text field={fields && (fields.title as SitecoreGenericTextField)} />
            </div>
            <div css={description}>
              <Text field={fields && (fields.description as SitecoreGenericTextField)} />
            </div>
            <div css={double}>
              <div css={doubleLeft}>
                <div css={itemTitle}>
                  <Text field={fields && (fields.titleLeft as SitecoreGenericTextField)} />
                </div>
                {new Array(10).fill(0).map((arr: any, index: number) => {
                  if (
                    fields &&
                    fields[`itemLeft${index + 1}`] &&
                    (fields && (fields[`itemLeft${index + 1}`] as any)).value
                  ) {
                    return (
                      <div css={item} key={`item-${index}`}>
                        <div css={icon}>
                          <Icon icon={'book'} />
                        </div>
                        <div css={text}>
                          <Text field={fields[`itemLeft${index + 1}`] as SitecoreGenericTextField} />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <div css={right}>
                <div css={itemTitle}>
                  <Text field={fields && (fields.titleRight as SitecoreGenericTextField)} />
                </div>
                {new Array(10).fill(0).map((arr: any, index: number) => {
                  if (
                    fields &&
                    fields[`itemRight${index + 1}`] &&
                    (fields && (fields[`itemRight${index + 1}`] as any)).value
                  ) {
                    return (
                      <div css={item} key={`item-${index}`}>
                        <div css={icon}>
                          <Icon icon={'book'} />
                        </div>
                        <div css={text}>
                          <Text field={fields[`itemRight${index + 1}`] as SitecoreGenericTextField} />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div css={action}>
              <Button
                label={btnLabel}
                iconLeft={'paper-plane'}
                onClick={() =>
                  btnLink
                    ? (window.location.href = btnLink)
                    : mailTo
                    ? window.open(`mailto:${mailTo}?subject=${mailToSubject || ''}`)
                    : console.debug
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
