import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { css, jsx } from '@emotion/react';
import * as React from 'react';
import { renderLink } from '../../../../../utils/helper';
import { useEffect, useState } from 'react';
import bannerBg from './assets/bg-img.png';
import hexaBottum from './assets/hexa.svg';
import hexaTop from './assets/hexa_top.svg';
import topLines from './assets/top_lines.svg';
import patternLeft from './assets/pattern_left.svg';
import logoHexa from './assets/Vector.svg';
import patternLogo from './assets/Group.svg';
import dashboardLogo from './assets/lgx_dashboard.svg';
import hexaFull from './assets/hexa_full.svg';
import { breakpoint } from '../../../../../style';

const _breakpoint = '1304px';

const style = {
  container: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 180px;
    width: 75%;
    max-width: 1456px;
    border-radius: 8px;
    overflow: hidden;
    @media (max-width: ${_breakpoint}) {
      height: 200px;
      width: 65%;
    }
    @media (max-width: ${breakpoint}) {
      width: 85%;
    }
    @media (max-width: 598px) {
      height: 250px;
      flex-direction: column;
    }
    :hover {
      cursor: pointer;
    }
  `,
  infoContainer: css`
    z-index: 1;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media (max-width: ${_breakpoint}) {
      width: 65%;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 5%;
      padding-top: 5%;
      padding-bottom: 5%;
      justify-content: center;
      gap: 10px;
    }
    @media (max-width: 598px) {
      width: 100%;
      padding-top: 10%;
      padding-bottom: unset;
      padding-left: 10%;
    }
  `,
  logoTitleContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100%;
    gap: 8px;
    span {
      width: 50px;
      font-family: Mont;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
    }
    @media (max-width: ${_breakpoint}) {
      width: 100%;
      justify-content: flex-start;
      height: 50%;
    }
  `,
  descriptionContainer: css`
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
    font-family: Mont;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-shadow: 0px 2px 8px rgba(11, 109, 41, 0.72);
    text-align: left;
    @media (max-width: ${_breakpoint}) {
      width: 90%;
    }
    @media (max-width: 859px) {
      font-size: 26px;
      font-weight: 700;
      line-height: 32px;
    }
    @media (max-width: 859px) {
      font-size: 23px;
      font-weight: 700;
      line-height: 29px;
    }
  `,
  actionContainer: css`
    z-index: 1;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    button {
      color: rgba(53, 68, 80, 1);
      font-family: Mont;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }
    @media (min-width: 1841px) {
      width: 35%;
    }
    @media (max-width: ${breakpoint}) {
      width: 45%;
    }
    @media (max-width: 598px) {
      width: 100%;
      padding-left: 10%;
    }
  `,
  logoContainer: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    line-height: 26px;
    width: 70px;
    height: 70px;
    overflow: hidden;
    img {
      position: absolute;
    }
    > img:first-child {
      max-height: 100%;
    }
    > img:nth-child(2) {
      max-height: 100%;
    }
    > img:last-child {
      max-height: 48%;
    }
    @media (max-width: ${_breakpoint}) {
      width: 30px;
      height: 30px;
    }
  `,
  titleContainer: css`
    display: flex;
    flex-direction: column;
    font-family: Mont;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    > span:first-child {
      font-size: 22px;
      font-weight: 700;
    }
    > span:last-child {
      font-size: 22px;
      font-weight: 100;
    }
    @media (max-width: ${_breakpoint}) {
      flex-direction: row;
      span {
        width: auto;
      }
      > span:first-child {
        font-size: 18px;
        font-weight: 700;
      }
      > span:last-child {
        font-size: 18px;
        font-weight: 100;
      }
    }
    @media (max-width: 598px) {
      width: 100%;
      padding-left: unset;
    }
  `,
  hexaBottom: css`
    z-index: 1;
    position: absolute;
    bottom: -15px;
    left: 24%;
    @media (max-width: 598px) {
      display: none;
    }
  `,
  hexaFull: css`
    z-index: 1;
    position: absolute;
    top: 15%;
    left: 40%;
    @media (min-width: 598px) {
      display: none;
    }
  `,
  hexaTop: css`
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 12%;
  `,
  topLines: css`
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 46%;
    @media (max-width: 598px) {
      display: none;
    }
  `,
  patternLeft: css`
    z-index: 1;
    position: absolute;
    bottom: -7px;
    left: 0%;
  `,
  bannerBg: css`
    position: absolute;
    right: 0px;
    height: 100%;
    @media (max-width: ${_breakpoint}) {
      img {
        height: 100%;
      }
    }
    @media (max-width: 598px) {
      height: unset;
      top: 122px;
    }
  `,
  gradient: css`
    background: linear-gradient(58deg, rgb(0, 140, 61) -6.09%, rgb(146, 194, 73) 67.48%, rgba(199, 214, 77, 0) 83.53%);
    position: absolute;
    width: 100%;
    height: 100%;
    @media (max-width: 598px) {
      background: linear-gradient(
        179.57deg,
        #008c3d 17.79%,
        #88be48 60.35%,
        rgba(165, 201, 74, 0.75) 75.36%,
        rgba(199, 214, 77, 0) 99.9%
      );
    }
  `,
};

export type PromotionBannerProps = {
  title: string;
  subTitle?: string;
  description?: string;
  actionLabel?: string;
  onActionClick?: () => void;
  logoComponent?: any;
};

/** @jsx jsx */
const PromotionBanner: React.FC<PromotionBannerProps> = ({
  title,
  subTitle,
  description,
  actionLabel,
  onActionClick,
}) => {
  return (
    <div css={style.container} onClick={onActionClick}>
      <div css={style.patternLeft}>
        <img src={renderLink(patternLeft)} alt={'image'} />
      </div>
      <div css={style.topLines}>
        <img src={renderLink(topLines)} alt={'image'} />
      </div>
      <div css={style.hexaBottom}>
        <img src={renderLink(hexaBottum)} alt={'image'} />
      </div>
      <div css={style.hexaFull}>
        <img src={renderLink(hexaFull)} alt={'image'} />
      </div>
      <div css={style.hexaTop}>
        <img src={renderLink(hexaTop)} alt={'image'} />
      </div>
      <div css={style.infoContainer}>
        <div css={style.logoTitleContainer}>
          <div css={style.logoContainer}>
            <img src={renderLink(logoHexa)} alt={'image'} />
            <img src={renderLink(patternLogo)} alt={'image'} />
            <img src={renderLink(dashboardLogo)} alt={'image'} />
          </div>
          <div css={style.titleContainer}>
            <span>{title}</span>
            <span>{subTitle}</span>
          </div>
        </div>
        <div css={style.descriptionContainer}>
          <span>{description}</span>
        </div>
      </div>
      <div css={style.actionContainer}>
        <Button label={actionLabel} variant='thirdary' iconRight={'chevron-right'} />
      </div>
      <div css={style.bannerBg}>
        <img src={renderLink(bannerBg)} alt={'image'} />
      </div>
      <div css={style.gradient}></div>
    </div>
  );
};

export default PromotionBanner;
