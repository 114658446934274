import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import avatar from './Ellipse.png';
import { style } from './index.style';
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {renderLink} from "../../utils/helper";

export interface LinkedArticleProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const LinkedArticle: React.FunctionComponent<LinkedArticleProps> = (
  props: LinkedArticleProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  return (
    <div css={style.mainContainer}>
      <div css={style.container}>
        <div css={style.heading}>
          <Text field={fields && (fields.heading as SitecoreGenericTextField)} />
        </div>
        <div css={style.title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={style.author}>
          <div css={style.avatar}>
            <img src={renderLink(avatar)} alt={"image"} />
          </div>
          <div css={style.mobile}>
            <Text field={fields && { value: 'By ' + fields.author.value }} />
          </div>
          <div css={style.separator}>in</div>
          <div css={style.relatedSubject}>
            <Text field={fields && (fields.relatedSubject as SitecoreGenericTextField)} />
          </div>
          <div css={style.separator}>•</div>
          <Text field={fields && (fields.timeToRead as SitecoreGenericTextField)} />
          <div css={style.separator}>•</div>
          <Text field={fields && (fields.date as SitecoreGenericTextField)} />
        </div>
      </div>
    </div>
  );
};
