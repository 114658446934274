/* eslint-disable */
import * as React from "react";

/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Security } from "../../../../../interfaces";
import Loader from "../../../Loader/loader.component";
import { genericStyle } from "../../../UI/generic/generic.style";
import { SearchTable } from "../../../UI/list/base";
import SecurityLuxXPrimeHeader from "../../../UI/list/luxXPrime/header";
import { SecurityLuxXPrimeRow } from "../../../UI/list/luxXPrime/row";
import { SearchMobile } from "../../../UI/list/base/index-mobile";
import SecuritySearchCard from "../../../UI/list/security/card-mobile";
import EmptyResults from "../../../UI/list/base/empty";
import { style } from "../../index.style";

interface TableProps {
    data?: Security[];
    isLoading?: boolean;
    limit?: number;
    icon?: React.ReactElement;
    title?: string;
    rowClass?: string;
}

const LuxXPrimeSecurityTable = (props: TableProps) => {
    const { data, isLoading, limit=10, icon, title, rowClass } = props;

    return (
    <div css={style.container}>
      <div css={style.title}>
       {icon} {title}
      </div>
      <div css={style.content}>
        <Loader loading={isLoading}>
          {data && data?.length > 0 ? (
            <>
              <div css={[genericStyle.desktopDisplay, style.contentBody]}>
                <SearchTable
                  data={data}
                  density={"medium"}
                  onPaginationUpdate={() => {}}
                  pageNumber={0}
                  totalResultSize={limit}
                  Header={() => <SecurityLuxXPrimeHeader selectedCols={null} />}
                  Row={(row) => (
                    <SecurityLuxXPrimeRow
                      row={row}
                      key={`card-${rowClass}-${row?.id}`}
                    />
                  )}
                />
              </div>
              <div css={genericStyle.mobileDisplay}>
                <SearchMobile
                  onPaginationUpdate={() => {}}
                  pageNumber={0}
                  data={data}
                  totalResultSize={limit}
                  Card={(card) => (
                    <SecuritySearchCard
                      key={`card-${rowClass}-m-${card?.id}`}
                      card={card}
                    />
                  )}
                />
              </div>
            </>
          ) : (
            <div css={style.emptyResult}>
              <EmptyResults />
            </div>
          )}
        </Loader>
      </div>
    </div>
  );
};

export default LuxXPrimeSecurityTable;