/** @jsx jsx */

import * as React from 'react';

import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { ComponentParams, ComponentRendering, Text } from '@sitecore-jss/sitecore-jss-react';
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from '../../interfaces';

export interface CurrentOffersCardProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const col = css`
  width: 564px;
  min-width: 564px;
  background: #FFFFFF;
  border: 1px solid #E3E6E8;
  box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
  border-radius: 16px;
  margin-right: 19px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  padding-bottom: 80px;
  
  @media (max-width: ${breakpointMax}) {
    min-width: 100%;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 24px;
    margin-right: 0;
    margin-top: 16px;
  }
`;

const name = css`
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  color: #CB1234;
  margin-bottom: 8px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #CB1234;
  }
`;

const role = css`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #425563;
  }
`;

const description = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  margin-top: 24px;
  margin-bottom: 24px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
  }
`;

const action = css`
  margin-top: 10px;
  display: flex;
  @media (max-width: ${breakpointMax}) {
    button {
      font-size: 12px;
    }
  }
`;

export const CurrentOffersCard: React.FunctionComponent<CurrentOffersCardProps> = (
    props: CurrentOffersCardProps
    ): React.ReactElement => {
    const { fields } = props;

    return (
        <div css={col}>
            <div css={name}>
                <Text
                    field={fields && fields[`title`] as SitecoreGenericTextField}
                />
            </div>
            <div css={role}>
                <Text
                    field={fields && fields[`role`] as SitecoreGenericTextField}
                />
            </div>
            <div css={description}>
                <Text
                    field={fields && fields[`description`] as SitecoreGenericTextField}
                />
            </div>
            <div css={action}>
                <Button
                    label={(fields && fields[`buttonLabel`] && (fields && fields[`buttonLabel`] as any).value)} iconRight={'arrow-right'}
                    onClick={() =>
                        fields && fields[`buttonLabel`] && ((fields && fields[`buttonLabel`] as any).value) ?
                            window.location.href = ((fields && fields[`buttonLink`] as any).value) :
                            console.debug()
                    }
                />
            </div>
        </div>
    );
}