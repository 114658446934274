
/*
*   e-Listing - core - components - country flag icon - pol
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const POL: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <g>
            <rect y='85.337' style={{fill: '#F0F0F0'}} width='512' height='341.326'/>
            <rect y='85.337' style={{fill: '#F0F0F0'}} width='512' height='170.663'/>
        </g>
        <rect y='256' style={{fill: '#D80027'}} width='512' height='170.663'/>
    </svg>
);
