import React, {Component} from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {sharedStyle} from '../../../../../styles/shared';
import {Td, Tr} from '@bdl-cmn-shared-packages-npm/design-system';
import {style} from '../index.style';
import RoundTag from '../../../RoundTag/roundTag.component';
import {formatDate} from '../../../../../utils/date';
import {computeLabel} from '../../../../../enums';
import {goToSecurityDetailPage, isSecurityClickable} from '../../../SecurityCard';
import {EMPTY_DATA_PLACEHOLDER} from "../../../../constants";
import { computeCategoryLabel } from '../../../UI/list/security/row';
import { IndiceBasket } from '../../../../../interfaces/indice';
import Props from '../../../UI/list/base/props';
import { PriceInformation, Security } from '../../../../../interfaces';

const IndexDetailsRow = ({ row, rowKey, selectedCols }: Props<IndiceBasket>) => {
    const basket = row;
    if( !basket?.security )
        return <></>;

    const security: Security = basket.security;
    const latestPrice: PriceInformation = security.marketData?.lastPrice;
    const clickable = isSecurityClickable(security.status);

    return (
        <Tr onRowClick={() => {if(clickable) goToSecurityDetailPage(security.isin, security.id);}}>
            <Td>
                <div css={sharedStyle.cardContainerBodyDoubleLeft}>
                    <RoundTag small textColor={'#425563'}>
                        {computeCategoryLabel(security.instrumentType?.toUpperCase() )}
                    </RoundTag>
                </div>
            </Td>
            <Td>
                <div css={{fontWeight: "bolder"}}>{security.instrumentName}</div>
                <div>{security.isin}</div>
            </Td>
            <Td>
                <div css={{fontWeight: "bolder"}}>
                    {latestPrice?.amount ?
                        <>
                            { latestPrice.amount }
                            { latestPrice.marker ? ` ${latestPrice.marker}` : ""}
                            { latestPrice.currency ? 
                                ` ${latestPrice.currency === "PERCENT" ? "%" : latestPrice.currency}` : ""
                            }
                        </>
                        : '-'
                    }
                </div>
                <div>{formatDate(latestPrice?.date)}</div>
            </Td>
            <Td>
                { latestPrice?.changePercent ?
                    <div style={{ color: latestPrice?.changePercent > 0 ? '#22AA5F' : 'red' }}>
                        {`${latestPrice?.changePercent} %`}
                    </div>
                    : '-'
                }
            </Td>
            <Td>{ basket?.numberOfShares || '-'}</Td>
            <Td>
                { basket?.weight ?  
                    `${(basket.weight * 100).toFixed(2)} %`
                    : '-'
                }</Td>
        </Tr>
    );
}

export default IndexDetailsRow;
