import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {sharedStyle} from "../../../styles/shared";
import { useEffect, useState } from 'react';
import { ColumnGraph } from '@bdl-cmn-shared-packages-npm/design-system';

export interface ColumnChartProps {
    data: any[];
    labelField: string;
    serieField: string;
    serieName?: string;
    labelValueFunction?: (value: string) => string;
}

const ColumnChart: React.FunctionComponent<ColumnChartProps> = (
    { 
      data, 
      labelField, 
      serieField, 
      serieName = "data", 
      labelValueFunction,
    }: ColumnChartProps ) => {

    if( ! data || ! data.length )
        return <></>;

    let labels: string[] = [];
    let xAxis: string[] = [];
    let serieData: string[] = [];

    data.forEach(( elem: any ) => {
        let label = labelValueFunction ?
            labelValueFunction( elem[labelField] ) :
            elem[labelField];

        labels.push( label );
        xAxis.push( label );
        serieData.push( elem[serieField] );
    });

    return (
        <ColumnGraph
            chartHeight={400}
            labels={labels}
            xAxis={xAxis}
            series={[
                { name: serieName, data: serieData },
            ]}
        />
    );
};

export default ColumnChart;
