import * as React from "react";
import { css, jsx } from "@emotion/react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { WEBSITE_DOMAIN, WEBSITE_NAME, WEBSITE_TITLE } from "../../../constants";

/** @jsx jsx */

export function OAMSearchMeta({}: any) {
  let title = WEBSITE_TITLE + " - OAM Search | " + WEBSITE_NAME;
  let url = WEBSITE_DOMAIN + "/oam-search-details";

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href={url}/>
        <title>{title}</title>
        <meta property="og:url" content={url}/>
        <meta property="og:title" content={title}/>
        <meta name="twitter:title" content={title} />
      </Helmet>
    </HelmetProvider>
  );
}
