/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import ColumnGraph from "../../core/components/ColumnGraph/columnGraph";
import {
  Button,
  ToggleButtonGroup,
  ToggleItem,
  ToggleButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { useState } from "react";
import MarketCapitalisation from "../../core/components/MarkerCapitalisation/marketCapitalisation.component";
import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
import {
  GET_FALLERS_SECURITIES_LIST_YEARLY,
  GET_RISERS_SECURITIES_LIST_YEARLY,
} from "../../graphql";
import Loader from "../../core/components/Loader/loader.component";
import { Query } from "react-apollo";
import Utils from "../../core/utils/utils";

export interface RisersAndFallersStatsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 48px;
`;
const title = css`
  font-weight: bold;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
`;
const description = css`
  font-size: 20px;
  line-height: 150%;
  color: #354450;
  margin-top: 32px;
  margin-bottom: 32px;
`;
const action = css`
  width: 100%;
  margin-bottom: 60px;
`;
const graphsPart = css`
  width: 100%;
  border: solid 1px #e3e6e8;
  padding: 40px 60px;
`;
const miniGraphsPart = css`
  width: 100%;
  border: solid 1px #e3e6e8;
  border-top: none;
  border-bottom: none;
  padding: 24px 60px;
`;

const graphContainer = css`
  width: 100%;
  border: solid 1px #e3e6e8;
  padding: 24px 180px;
`;
const header = css`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;
const side = css`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const viewLabel = css`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #354450;
  margin-right: 16px;
`;
const graphContainerShadow = css`
  background: #ffffff;
  border: solid 1px #e3e6e8;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 32px;
`;

const pieChartsPart = css`
  margin-top: 160px;
  margin-bottom: 160px;
`;

const titleGraph = css`
  font-weight: bold;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
`;
const miniTitleGraph = css`
  font-weight: bold;
  font-size: 32px;
  line-height: 41px;
  color: #354450;
`;

export const RisersAndFallersStats: React.FunctionComponent<RisersAndFallersStatsProps> =
  (props: RisersAndFallersStatsProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const btnLabel: string = getFieldValue<string>(
      fields,
      "button label",
      "Monthly and yearly reports"
    );
    const btnLink: string = getFieldValue<string>(fields, "button link", "");
    const btnIcon: string = getFieldValue<string>(
      fields,
      "button icon",
      "file"
    );
    const btnDownloadLink: string = getFieldValue<string>(
      fields,
      "button download link",
      ""
    );

    const [onlyLuxXPrime, setOnlyLuxXPrime] = useState<boolean>(false);
    const [graphMonthly, setGraphMonthly] = useState<boolean>(true);
    const [graphYearly, setGraphYearly] = useState<boolean>(true);

    const buttons = css`
      display: flex;

      button {
        color: #354450;
        border: solid 1px #e3e6e8;

        &:first-of-type {
          border: solid ${graphMonthly ? 2 : 1}px
            ${graphMonthly ? "#22AA5F" : "#E3E6E8"};
          background: ${graphMonthly ? "#E8FAF0" : "transparent"};
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-of-type {
          border: solid ${!graphMonthly ? 2 : 1}px
            ${!graphMonthly ? "#22AA5F" : "#E3E6E8"};
          background: ${!graphMonthly ? "#E8FAF0" : "transparent"};
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &:hover {
          color: #354450;
        }
      }
    `;

    return (
      <div css={container}>
        <div css={title}>
          <Text field={fields[`title`] as SitecoreGenericTextField} />
          Risers and fallers statistics
        </div>
        <div css={description}>
          <Text field={fields[`title`] as SitecoreGenericTextField} />
          Consult the stock's risers and fallers at the luxembourg Stock
          Exchange.
        </div>
        <div css={action}>
          <Button
            label={btnLabel}
            variant={"primary"}
            wired
            iconLeft={btnIcon as any}
          />
        </div>
        <div css={graphsPart}>
          <div css={titleGraph}>Performance</div>
        </div>
        <div css={miniGraphsPart}>
          <div css={miniTitleGraph}>Monthly</div>
        </div>
        <div css={graphContainer}>
          <div css={header}>
            <div css={side}>
              <div css={viewLabel}>View mode</div>
              <div css={buttons}>
                <Button
                  label={"Graph"}
                  variant={"primary"}
                  wired={graphMonthly}
                  noShadow
                  onClick={() => setGraphMonthly(true)}
                />
                <Button
                  label={"Data table"}
                  variant={"thirdary"}
                  wired={!graphMonthly}
                  noShadow
                  onClick={() => setGraphMonthly(false)}
                />
              </div>
            </div>
            <div css={side}>
              <ToggleButton
                label="Show luxXPrime only"
                onClick={() => setOnlyLuxXPrime(!onlyLuxXPrime)}
                isSelected={onlyLuxXPrime}
              />
            </div>
          </div>
          <div css={graphContainerShadow}>
            <Query<any>
              client={luxseGatewayGraphqlClient}
              query={GET_FALLERS_SECURITIES_LIST_YEARLY}
              variables={{ limit: 20, token: Utils.getCookie("Gztoken-Dsm") }}
              //pollInterval={30000}
              fetchPolicy="no-cache"
              errorPolicy="all"
            >
              {({ loading, error, data }): React.ReactNode => {
                return (
                  <Loader loading={loading}>
                    {graphMonthly ? (
                      <ColumnGraph label={"ok"} />
                    ) : (
                      <Table density="low" sortable>
                        <Thead>
                          <Tr>
                            <Th>Type</Th>
                            <Th>ISIN</Th>
                            <Th>Issuer</Th>
                            <Th>Last price</Th>
                            <Th>Last price M-1</Th>
                            <Th>MTD variations</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td>ss</Td>
                            <Td>dsds</Td>
                            <Td>sdsds</Td>
                            <Td>sdsds</Td>
                            <Td>sdsds</Td>
                            <Td>sdsds</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    )}
                  </Loader>
                );
              }}
            </Query>
          </div>
        </div>
        <div css={miniGraphsPart}>
          <div css={miniTitleGraph}>Yearly</div>
        </div>
        <div css={graphContainer}>
          <div css={header}>
            <div css={side}>
              <div css={viewLabel}>View mode</div>
              <div css={buttons}>
                <Button
                  label={"Graph"}
                  variant={"primary"}
                  wired={graphYearly}
                  noShadow
                  onClick={() => setGraphYearly(true)}
                />
                <Button
                  label={"Data table"}
                  variant={"thirdary"}
                  wired={!graphYearly}
                  noShadow
                  onClick={() => setGraphYearly(false)}
                />
              </div>
            </div>
            <div css={side}>
              <ToggleButton
                label="Show luxXPrime only"
                onClick={() => setOnlyLuxXPrime(!onlyLuxXPrime)}
                isSelected={onlyLuxXPrime}
              />
            </div>
          </div>
          <div css={graphContainerShadow}>
            {graphYearly ? (
              <ColumnGraph label={"ok"} />
            ) : (
              <Table density="low" sortable>
                <Thead>
                  <Tr>
                    <Th>Type</Th>
                    <Th>ISIN</Th>
                    <Th>Issuer</Th>
                    <Th>Last price</Th>
                    <Th>Last price M-1</Th>
                    <Th>MTD variations</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>ss</Td>
                    <Td>dsds</Td>
                    <Td>sdsds</Td>
                    <Td>sdsds</Td>
                    <Td>sdsds</Td>
                    <Td>sdsds</Td>
                  </Tr>
                </Tbody>
              </Table>
            )}
          </div>
        </div>
        <div css={pieChartsPart}>
          <MarketCapitalisation label={"ok"} noTitle noAction />
        </div>
      </div>
    );
  };
