/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { useState } from 'react';
import { style } from "./index.style";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
export interface FilterButtonProps {
  open: boolean;
  onSwitchFilter: () => void;
}

const source: string[] = ['Documents','Issuers','Programmes','Securities']
export const FilterButton = (props: FilterButtonProps): JSX.Element => {
  const {
    open,
    onSwitchFilter
  } = props;

  return (
    <div css={style.container} onClick={onSwitchFilter}>
      <Icon icon={open ? 'times' : 'filter'} />
    </div>
  );
}


export default FilterButton;
