
/*
*   e-Listing - core - components - country flag icon - swe
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const SWE: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.333' style={{fill: '#0052B4'}} width='512' height='341.337'/>
        <polygon style={{fill: '#FFDA44'}} points='192,85.33 128,85.33 128,223.996 0,223.996 0,287.996 128,287.996 128,426.662 192,426.662   192,287.996 512,287.996 512,223.996 192,223.996 '/>
    </svg>
);