
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { canUseDOM } from "../../../predicates";
import { style } from "./index.style";

export interface NotFoundProps { 
    title?: string;
    description?: string;
    goBack?: boolean;
    redirect?: boolean;
}

export const pageNotFoundLink = () => {
  return `/404`;
};

export const goToNotFoundPage = () => {
  if (canUseDOM) window.location.href = pageNotFoundLink();
};

export const NotFound: React.FunctionComponent<NotFoundProps> = ({
    title,
    description,
    goBack = false,
    redirect = false,
  }: NotFoundProps) => {

    if( redirect ){
      goToNotFoundPage();
      return <></>;
    }

    return (
      <div css={style.parentContainer}>
        <div css={style.container}>
          <div css={style.title}>{title}</div>
          <div css={style.description}>{description}</div>
          <div css={style.link}>
            <a href={'/'} title={"Homepage link"}>Go to the homepage</a>
          </div>
          { goBack ?
            <div css={style.link}>
              <a href={"javascript:history.back()"} title={"Back link"}>Go back</a>
            </div>
            : <></>
          }
        </div>
      </div>
    );
}
    