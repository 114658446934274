
export enum LgxSlbKpiThemeEnum {
	ESG_RATING = "ESG",
	SOCIAL = "Social",
	ENVIRONMENT = "Environment",
	GOVERNANCE = "Governance",
	MULTIPLE_THEMES = "Multiple themes",
	MIXED = "Mixed",
}

export function lgxSlbKpiThemeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(LgxSlbKpiThemeEnum).indexOf(value.toString().toUpperCase());
		return index >= 0 ? Object.values(LgxSlbKpiThemeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get LgxSlbKpiThemeEnum label for '" + value + "'", e);
	}
		
	return value;
}