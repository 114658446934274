
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import navBg from './nav.png';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import Tag from "../../Tag/tag.component";
import {
    content,
    descriptionStyle,
    titleStyle,
    selectors,
    selectorLabel,
    selectorButtons,
    selector,
    firstButtonSelected,
    firstButton,
    selectorRight,
    buttonSelected,
    button,
    childPart,
    selectorLetters,
    buttonSelectedLetter,
    buttonLetterDisabled,
    bannerForm,
    bannerFormTop,
    bannerFormTopLeft,
    bannerFormTopRight,
    bannerFormTags,
    bannerFormTag,
    buttonLetter, topBloc
} from "../shared.style";

export interface ResourcesHeaderProps {
    title: string;
    withPadding?: boolean;
    isH1?: boolean;
    counter?: number;
    description: string;
    letters?: string[];
    tabps?: string[];
    thematics?: string[];
    mode?: string[];
    categories?: any[];
    onChangeLetter?: (letter: string) => void;
    onChangeThematic?: (letter: string) => void;
    onChangeMode?: (letter: string) => void;
    selectedMode?: string;
    onSelectCategories?: (categories: string[]) => void;
}

const alpha = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];

export const ResourcesHeader: React.FunctionComponent<any> = (props: ResourcesHeaderProps): React.ReactElement => {
    const { isH1, title, description, letters, withPadding, onChangeLetter, onChangeMode, onChangeThematic, selectedMode, thematics, mode, categories, counter } = props;

    const [letterSelected, setLetterSelected] = React.useState<string>('a');
    const [modeSelected, setModeSelected] = React.useState<string>('Categories');
    const [thematicSelected, setThematicSelected] = React.useState<string>('all');

    React.useEffect(() => {
        onChangeLetter && onChangeLetter(letterSelected)
    }, [letterSelected]);

    React.useEffect(() => {
        onChangeMode && onChangeMode(modeSelected)
    }, [modeSelected]);

    React.useEffect(() => {
        onChangeThematic && onChangeThematic(thematicSelected)
    }, [thematicSelected]);

    return (
        <div css={content}>
            <div css={topBloc(withPadding)}>
                {
                    isH1 ? (
                        <h1 css={titleStyle}>
                            { title }
                        </h1>
                    ) : (
                        <div css={titleStyle}>
                            { title }
                        </div>
                    )
                }

                <div css={descriptionStyle}>
                    { description }
                </div>
            </div>

            {
                mode && !!mode.length && (
                    <div css={selectors}>
                        <div css={selector}>
                            <div css={selectorLabel}>
                                Mode
                            </div>
                            <div css={selectorButtons(false)}>
                                {
                                    mode.map((m: string, index: number) => (
                                        <div css={modeSelected === m ? firstButtonSelected : firstButton} onClick={() => setModeSelected(m)}>
                                            {m}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            {
                selectedMode !== 'Letters' && thematics && !!thematics.length && (
                    <div css={selectors}>
                        <div css={selectorRight}>
                            <div css={selectorLabel}>
                                Thematic
                            </div>
                            <div css={selectorButtons}>
                                <div css={thematicSelected === 'all' ? firstButtonSelected : firstButton} onClick={() => setThematicSelected('all')}>
                                    All
                                </div>
                                {
                                    thematics && thematics.map((thematic: string, index: number) => (
                                        <div key={`thematic-button-${index}`} css={thematicSelected === thematic ? buttonSelected : button} onClick={() => setThematicSelected(thematic)}>
                                            { thematic }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            {
                selectedMode === 'Letters' && letters && (
                    <div css={childPart}>
                        <div css={selectorLetters}>
                            {
                                alpha.map((letter: string, index: number) => {
                                    if(letters.includes(letter.toUpperCase())) {
                                        return (
                                            <div key={`alpha-key-${index}`} css={letterSelected === letter ? buttonSelectedLetter : buttonLetter} onClick={() => setLetterSelected(letter)}>
                                                {letter.toUpperCase()}
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={`alpha-key-${index}`} css={buttonLetterDisabled}>
                                                {letter.toUpperCase()}
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}