/** @jsx jsx */

import * as React from 'react';

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { SitecoreGenericTextField } from '../../interfaces';

export interface HorizontalTimelineProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}
const parentContainer = css`
  width: 100%;
  background: #f0f5f5;
  position: relative;
  padding-bottom: 60px;
`;
const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  min-height: 400px;
  padding-top: 290px;
  padding-bottom: 40px;
  @media (max-width: ${breakpointMax}) {
    padding-top: 100px;
    padding-right: 24px;
  }
`;

const line = css`
  width: 100%;
  background-color: #e3e6e8;
  height: 2px;
  position: relative;
  @media (max-width: ${breakpointMax}) {
    width: 2px;
    left: 32px;
  }
`;
const lineBg = css`
  width: 100%;
  background-color: #e3e6e8;
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 290px;
  @media (max-width: ${breakpointMax}) {
    width: 2px;
    left: 32px;
    height: calc(100% - 140px);
    margin-top: 100px;
  }
`;
const dot1 = css`
  position: absolute;
  margin-left: 2%;
  height: 15px;
  width: 15px;
  margin-top: -6px;
  border-radius: 10px;
  border: solid 1px #d9e1e2;
  background-color: #ffffff;
  @media (max-width: ${breakpointMax}) {
    margin-left: -6px;
    display: none;
  }
`;
const date = css`
  position: absolute;
  margin-left: 8%;
  height: 40px;
  width: 1px;
  margin-top: -20px;
  border-radius: 10px;
  border: solid 1px #d9e1e2;
  @media (max-width: ${breakpointMax}) {
    border: none;
  }
`;
const dateLabel = css`
  position: absolute;
  margin-top: -40px;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #425563;
  margin-left: -25px;
`;
const dot2 = css`
  position: absolute;
  margin-left: 35%;
  height: 15px;
  width: 15px;
  margin-top: -6px;
  border: solid 1px #008c3d;
  border-radius: 10px;
  background-color: #ffffff;
  @media (max-width: ${breakpointMax}) {
    margin-left: -6px;
    margin-top: 100px;
  }
`;
const dot3 = css`
  position: absolute;
  margin-left: 55%;
  height: 15px;
  width: 15px;
  margin-top: -6px;
  border-radius: 10px;
  border: solid 1px #d9e1e2;
  background-color: #ffffff;
  @media (max-width: ${breakpointMax}) {
    margin-left: -6px;
    margin-top: 250px;
  }
`;
const dot4 = css`
  position: absolute;
  margin-left: 65%;
  height: 15px;
  width: 15px;
  margin-top: -6px;
  border-radius: 10px;
  border: solid 1px #d9e1e2;
  background-color: #ffffff;
  @media (max-width: ${breakpointMax}) {
    margin-left: -6px;
    margin-top: 300px;
  }
`;
const cards = css`
  display: flex;
  padding-left: 20%;
  margin-top: 16px;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const card1 = css`
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-width: 380px;
  min-height: 289px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px;

  @media (max-width: ${breakpointMax}) {
    min-width: 0px;
    min-height: 0px;
  }
`;
const card2 = css`
  display: flex;
  flex-direction: column;
  margin-left: 20%;
  padding: 24px;
  margin-top: -200px;
  min-width: 380px;
  min-height: 289px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  position: relative;
  z-index: 2;

  @media (max-width: ${breakpointMax}) {
    margin-left: 0;
    margin-top: 130px;
    min-width: 0px;
    min-height: 0px;
  }
`;
const year = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #008c3d;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }
`;
const cardTitle = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #354450;

  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
`;
const subTitle1 = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.01em;
  }
`;
const subTitle2 = css`
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.01em;
  }
`;
const title = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
`;
const description = css`
  margin-top: 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;

  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: 0.01em;
  }
`;

export const HorizontalTimeline: React.FunctionComponent<HorizontalTimelineProps> = (
  props: HorizontalTimelineProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const headingValue: string = getFieldValue<string>(fields, 'heading', '');

  return (
    <div css={parentContainer}>
      <div css={lineBg} />
      <div css={container}>
        <div css={line}>
          <div css={dot1} />
          <div css={date}>
            <div css={dateLabel}>
              <Text field={fields['card 1 year'] as SitecoreGenericTextField} />
            </div>
          </div>
          <div css={dot2} />
          <div css={dot3} />
          <div css={dot4} />
        </div>
        <div css={cards}>
          <div css={card1}>
            <div css={year}>
              <Text field={fields['card 1 year'] as SitecoreGenericTextField} />
            </div>
            <div css={cardTitle}>
              <Text field={fields['card 1 title'] as SitecoreGenericTextField} />
            </div>
            <div css={subTitle1}>
              <Text field={fields['card 1 sub title 1'] as SitecoreGenericTextField} />
            </div>
            <div css={subTitle2}>
              <Text field={fields['card 1 sub title 2'] as SitecoreGenericTextField} />
            </div>
          </div>
          <div css={card2}>
            <div css={title}>
              <Text field={fields['card 2 title'] as SitecoreGenericTextField} />
            </div>
            <div css={description}>
              <Text field={fields['card 2 description'] as SitecoreGenericTextField} />
            </div>
          </div>
        </div>
        <Text field={fields && (fields.heading as SitecoreGenericTextField)} />
      </div>
    </div>
  );
};
