
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {HeaderContent} from "../../core/components/Header/header.component";

export interface SitecoreHeaderProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const SitecoreHeader: React.FunctionComponent<SitecoreHeaderProps> = (props: SitecoreHeaderProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const hideBreadcrumb: boolean = getFieldValue<boolean>(fields, 'Hide Breadcrumb', false);
    const isReadOnly: boolean = getFieldValue<boolean>(fields, 'disable navigation', false);

    return (
        <HeaderContent hasBreadcrumb={!hideBreadcrumb} isReadOnly={isReadOnly} />
    );
}
