
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const PAK: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <g>
                <polygon fill="#FFFFFF" points="393.508,170.516 411.02,189.391 434.383,178.57 421.842,201.057 439.355,219.933 414.092,214.955 401.553,237.443 398.48,211.879 373.217,206.901 396.58,196.081 " />
                <rect y="85.343" fill="#FFFFFF" width="512" height="341.326" />
            </g>
            <rect x="128" y="85.331" fill="#115740" width="384" height="341.337" />
            <g>
                <path fill="#FFFFFF" d="M361.909,298.793c-31.037,22.426-74.378,15.446-96.804-15.592 c-22.427-31.038-15.446-74.379,15.593-96.804c9.677-6.992,20.55-11.125,31.613-12.563c-21.283-3.183-43.777,1.613-62.598,15.211 c-38.2,27.602-46.792,80.944-19.191,119.145c27.601,38.199,80.944,46.792,119.145,19.189c18.82-13.598,30.436-33.448,34.096-54.655 C378.924,282.774,371.587,291.8,361.909,298.793z" />
                <polygon fill="#FFFFFF" points="360.58,172.889 378.064,191.731 401.386,180.929 388.867,203.376 406.35,222.22 381.131,217.252 368.612,239.702 365.545,214.181 340.325,209.212 363.648,198.41 " />
            </g>
        </svg>
    </React.Fragment>
);
