import { css } from '@emotion/react';

export default {
    inputWrapper: css`
        background-color: white;
        border-radius: 4px;
        width: 100%;

        color: #253645;
        height: 55px;
        legend > span {
            display:none;
        }

        .MuiInputLabel-outlined.MuiInputLabel-shrink {
            transform: translate(14px, 4px) scale(0.75);
        }

        .MuiOutlinedInput-input {
            //padding: 0px!important;
        }

        .MuiFormControl-root, .MuiOutlinedInput-root {
            & > div {
                min-height: 55px !important;
                height: 40px;
            }
        }

        .MuiInputLabel-outlined {
            z-index: 1;
            transform: translate(14px, 13px) scale(1);
            pointer-events: none;
        }

        .inputWrapperFix {
            svg {
                margin-right: 0;
            }
            
            > div > div > div > div > div {
                .MuiOutlinedInput-input {
                    padding: 18.5px 14px;
                    padding-top: 30px;
                }
            }

            > div > div:nth-child(2) {
                background-color: white;
                z-index: 10;
            }
        }

        label {
            padding-top: 7px;
        }
    `,
    container: css`
        position: relative;
    `,
    panel: css`
        padding: 1rem;
        background-color: white;
        border-radius: 4px;
        max-height: 250px;
        overflow-y: auto;
        width: 100%;
        min-width: 285px;
        position: absolute;
        z-index: 36;
        box-shadow: 0 5px 10px rgba(66, 85, 99, 0.25);

        svg {
            margin-right: 0;
        }
    `,
    children: css`
        margin-left: 1rem;
    `,
    clickOutside: css`
        z-index: 10;
        width : 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    `,
}