/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import hexa from "./hexa.svg";
import { SitecoreGenericTextField } from "../../interfaces";
import { breakpointMax } from "../../style";

export interface NotFoundPageProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  background: url(${hexa});
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 500px;
  @media (max-width: ${breakpointMax}) {
    background-size: 200px;
  }
`;
const container = css`
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpointMax}) {
    min-height: 400px;
  }
`;

const title = css`
  font-weight: 400;
  font-size: 48px;
  line-height: 61px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #253845;
  @media (max-width: ${breakpointMax}) {
    font-size: 28px;
  }
`;
const description = css`
  font-weight: 700;
  font-size: 32px;
  line-height: 41px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #425563;
  margin-bottom: 38px;
  margin-top: 8px;
  @media (max-width: ${breakpointMax}) {
    font-size: 20px;
    margin-bottom: 16px;
    margin-top: 0;
  }
`;

const link = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  text-decoration-line: underline;
  margin-bottom: 15px;

  @media (max-width: ${breakpointMax}) {
    font-size: 14px;
  }

  a {
    color: #22aa5f;
  }
`;

export const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = (
  props: NotFoundPageProps
): React.ReactElement => {
  const { fields } = props;

  return (
    <div css={parentContainer}>
      <div css={container}>
        <div css={title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={description}>
          <Text
            field={fields && (fields.description as SitecoreGenericTextField)}
          />
        </div>
        <div css={link}>
          <a href={"/"} title="Homepage link">
            Go to the homepage
          </a>
        </div>
      </div>
    </div>
  );
};
