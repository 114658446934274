
/*
*   e-Listing - core - components - country flag icon - esp
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const ESP: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.331' style={{fill: '#FFDA44'}} width='512' height='341.337'/>
        <g>
            <rect y='85.331' style={{fill: '#D80027'}} width='512' height='113.775'/>
            <rect y='312.882' style={{fill: '#D80027'}} width='512' height='113.775'/>
        </g>
    </svg>
);
