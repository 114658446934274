
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const HKG: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#DE2910" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <g>
                <path fill="#FFFFFF" d="M224.477,268.923c-11.128,0.115-23.624-3.449-34.573-15.518c-0.755-0.832-0.649-2.143,0.209-2.87 l0.003-0.003c0.832-0.705,2.062-0.596,2.797,0.211c16.69,18.327,37.376,15.118,49.735,10.69c-4.566,0.203-10.303-0.14-13.993-2.565 c-15.415-10.133-8.433-20.913-18.671-33.944c-10.173-12.948-19.327-12.631-33.741-10.446c-14.27,2.162-18.816-8.305-20.231-14.114 c-0.253-1.041-1.705-1.106-2.077-0.103c-17.786,48.054,35.694,107.514,90.601,64.761 C239.118,267.017,232.14,268.845,224.477,268.923z M184.41,252.616c-0.312,0.858-1.537,0.824-1.802-0.05l-2.048-6.749l-7.049-0.247 c-0.913-0.032-1.259-1.207-0.51-1.729l5.786-4.033l-1.943-6.781c-0.252-0.877,0.759-1.57,1.487-1.02l5.624,4.256l5.848-3.944 c0.757-0.511,1.729,0.236,1.429,1.099l-2.31,6.664l5.558,4.343c0.719,0.563,0.309,1.717-0.604,1.699l-7.052-0.138L184.41,252.616z" />
                <path fill="#FFFFFF" d="M263.869,236.996c8.836-6.765,20.972-11.407,36.972-8.317c1.103,0.213,1.805,1.325,1.555,2.421 l-0.001,0.004c-0.243,1.064-1.293,1.714-2.365,1.508c-24.346-4.657-38.976,10.317-46.209,21.272 c3.532-2.901,8.329-6.067,12.736-6.338c18.412-1.137,19.289,11.678,35.298,15.965c15.905,4.259,23.04-1.483,33.266-11.876 c10.122-10.289,20.038-4.639,24.653-0.839c0.826,0.681,2.027-0.137,1.723-1.163c-14.586-49.12-93.042-64.627-111.339,2.513 C253.295,247.303,257.784,241.655,263.869,236.996z M305.711,226.017c-0.265-0.874,0.736-1.582,1.472-1.042l5.686,4.172l5.789-4.03 c0.749-0.522,1.731,0.211,1.446,1.078l-2.211,6.698l5.621,4.26c0.728,0.552,0.334,1.713-0.578,1.708l-7.053-0.033l-2.314,6.663 c-0.3,0.863-1.525,0.847-1.803-0.022l-2.148-6.718l-7.052-0.142c-0.913-0.019-1.277-1.188-0.535-1.721l5.726-4.119L305.711,226.017 z" />
                <path fill="#FFFFFF" d="M234.399,241.627c-4.34-10.248-5.8-23.159,1.195-37.877c0.482-1.015,1.735-1.416,2.733-0.899 l0.004,0.002c0.969,0.501,1.335,1.681,0.87,2.667c-10.599,22.408,0.241,40.318,9.038,50.061c-1.926-4.146-3.79-9.581-2.952-13.917 c3.505-18.111,16.131-15.756,24.286-30.184c8.103-14.335,4.327-22.679-3.178-35.178c-7.43-12.374,0.521-20.561,5.355-24.079 c0.865-0.63,0.374-1.997-0.695-1.959c-51.207,1.837-85.845,73.92-25.416,108.428C241.734,254.442,237.388,248.684,234.399,241.627z M234.234,198.369c-0.913,0.038-1.347-1.108-0.64-1.685l5.462-4.462l-2.454-6.613c-0.318-0.856,0.638-1.624,1.405-1.13l5.932,3.816 l5.53-4.377c0.716-0.567,1.741,0.104,1.508,0.988l-1.796,6.82l5.871,3.907c0.76,0.506,0.439,1.688-0.472,1.74l-7.042,0.399 l-1.901,6.792c-0.246,0.879-1.47,0.939-1.801,0.088l-2.556-6.573L234.234,198.369z" />
                <path fill="#FFFFFF" d="M274.297,264.88c9.198,6.264,17.418,16.328,19.508,32.489c0.145,1.115-0.692,2.13-1.811,2.237 h-0.006c-1.086,0.104-2.033-0.69-2.175-1.771c-3.225-24.577-22.037-33.763-34.71-37.19c3.863,2.442,8.375,6.002,10.018,10.101 c6.862,17.123-5.028,21.982-4.071,38.527c0.951,16.438,8.646,21.408,21.724,27.852c12.947,6.379,10.698,17.566,8.538,23.143 c-0.386,0.999,0.767,1.881,1.645,1.27c42.052-29.276,32.134-108.631-37.355-104.915C261.193,258.08,267.964,260.567,274.297,264.88 z M297.864,301.156c0.747-0.526,1.732,0.202,1.451,1.07l-2.175,6.709l5.644,4.231c0.73,0.547,0.344,1.71-0.569,1.711l-7.053,0.004 l-2.279,6.675c-0.295,0.864-1.521,0.855-1.803-0.013l-2.184-6.707l-7.052-0.105c-0.914-0.013-1.283-1.182-0.545-1.719l5.704-4.15 l-2.08-6.74c-0.269-0.873,0.727-1.586,1.466-1.049l5.709,4.142L297.864,301.156z" />
                <path fill="#FFFFFF" d="M251.292,284.823c-3.168,10.668-10.254,21.56-25.013,28.469c-1.018,0.477-2.239-0.011-2.681-1.044 l-0.002-0.004c-0.429-1.003,0.039-2.146,1.025-2.611c22.43-10.551,25.457-31.265,24.865-44.38 c-1.151,4.424-3.169,9.804-6.573,12.617c-14.223,11.747-22.47,1.899-37.939,7.846c-15.37,5.908-17.763,14.75-19.922,29.169 c-2.136,14.274-13.479,15.535-19.447,15.176c-1.068-0.064-1.559,1.303-0.71,1.954c40.684,31.15,113.258-2.444,88.574-67.507 C253.783,270.27,253.474,277.476,251.292,284.823z M223.906,318.309c0.728,0.552,0.335,1.711-0.578,1.708l-7.052-0.031 l-2.312,6.664c-0.299,0.863-1.525,0.847-1.803-0.022l-2.151-6.718l-7.052-0.14c-0.913-0.018-1.278-1.188-0.536-1.721l5.724-4.121 l-2.046-6.75c-0.265-0.874,0.735-1.582,1.471-1.042l5.688,4.17l5.788-4.032c0.749-0.522,1.731,0.211,1.446,1.078l-2.208,6.698 L223.906,318.309z" />
            </g>
        </svg>
    </React.Fragment>
);
