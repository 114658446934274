import { breakpointMax } from "../../../style";

import { css, jsx } from "@emotion/react";

const lint = jsx; // linter hack

export const style = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: #f4f6f7;
    @media (max-width: ${breakpointMax}) {
      padding: 0px;
    }
    padding: 0px 10%;
  `,

  container2: css`
    align-items: center;
    gap: 32px;
    background: #ffffff;
    border-bottom: 1px solid #d9dee2;
    display: contents;
  `,

  filterContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    background: #ffffff;
    width: 100%;
    height: 72px;

    span {
      width: 42px;
      height: 20px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #1a2934;
    }
  `,
  resultContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border-bottom: solid 1px #d9e1e2;
    border-top: solid 1px #d9e1e2;
    -webkit-align-items: unset;
    width: 100%;
    background: #ffffff;
  `,
  resultText: css`
    color: #22aa5f;
  `,
  textPosition: css`
    margin-left: 2rem;
  `,
  inputStyle: css`
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-right: 2rem;
    width: 100%;
  `,
  underList: css`
    background: #d3f2e1;
  `,
  docIncorporatedContainer: css`
    background: #d3f2e1;
    padding: 0 !important;
  `,
  docIncorporatedContainer2: css`
    border-bottom: solid 1px #d9e1e2;
    border-top: solid 1px #d9e1e2;
    background: #d3f2e1;
    padding: 0 0 0 1.688rem !important;
  `,
  docIncorporatedText: css`
    color: #005e2f;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    font-family: "Mont";
    font-style: normal;
  `,
  buttonUnderList: css`
    background: #e8faf0 !important;
    color: #22aa5f !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px !important;

    :hover {
      background: #0d8e46 !important;
      color: #ffffff !important;
    }
  `,
  chevron: css`
    color: #22aa5f !important;
    display: flex;
    justify-content: center;
  `,
  title1: css`
    font-family: "Mont";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #54666f;
  `,
  title2: css`
    font-family: "Mont";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 150%;
    color: #54666f;
  `,
  pagination: css`
    width: 100%;
    padding: 1em;
    @media (max-width: ${breakpointMax}) {
      ul {
        columns: 2;
        padding: 0;
        display: block !important;

        li {
          margin-bottom: 5px;
        }
      }
    }
  `,
};
