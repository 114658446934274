/** @jsx jsx */
import { jsx } from "@emotion/react";
import { SitecoreContext, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import IndexDetailsView from "../../core/components/Index/Index-details";

export interface IndexDetailProps extends RouteComponentProps{
    fields: any;
    sitecoreContext: SitecoreContext;
}

export function IndexDetailsComponentCore(props: IndexDetailProps) {
    const { fields } = props;

    const path = props?.location?.pathname ? props?.location.pathname.split('/').filter((elem: string) => elem !== '') : [];
    const search = new URLSearchParams(props?.location?.search);
    const id = path?.length >= 2 && path[2] ? path[2] : search ? search.get("id") : "";

    return (
        <IndexDetailsView fields={fields} id={id}/>
    );
}

export const IndexDetailsComponent = withRouter(
    withSitecoreContext()(IndexDetailsComponentCore)
);