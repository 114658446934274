
export enum InstrumentType {
    ACT = "Equity",
    WAR = "Warrant",
    OBL = "Bond",
    CER = "Certificate",
    OPC = "Fund"
}

export function instrumentTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(InstrumentType).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(InstrumentType)[index] : value;
	}catch (e){
		console.error("Failed to get InstrumentType label for '" + value + "'", e);
	}
		
	return value;
}

export function instrumentTypeCheck( a : string, compare : string ): boolean {
	if( !a || !compare )
		return false;
	try {
		let aValue = instrumentTypeLabel(a);
		
		return aValue === compare;
	}catch (e){
		console.error("Failed to check InstrumentType for '" + a + "'-'" + compare + "'", e);
	}

	return false;
}