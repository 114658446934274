/** @jsx jsx */
import { jsx } from "@emotion/react";
import {MenuWithChild, Menu} from "@bdl-cmn-shared-packages-npm/design-system";
import {marketNewsStyle} from "../index.style";
import React from "react";
import {FilterModel} from "..";


export function MarketNewsMenuRigthFilters(props:any) {
    const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        let found = false;
        let target = event.target as Element;
        const parent = event.currentTarget;
        const head = parent.firstChild.firstChild;
    
        do {
          if (head == target) found = true;
    
          target = target.parentElement;
        } while (!found && parent != target && target != null);
    
        if (found) parent.classList.toggle('open');
    };

    return (
        <div>
            <div css={marketNewsStyle.title}>Filter your search result</div>
            <div css={marketNewsStyle.row} onClick={handleFilterMenuClick}>
                <MenuWithChild
                    icon="globe"
                    label="Type of News"
                    width="100%;"
                    expanded
                    arrows={false}
                >
                    <div css={[marketNewsStyle.menuItem, marketNewsStyle.menuItemRow]}>
                        {
                            props.marketNewsFilter?.map((x: FilterModel) => {
                                return (
                                    <div css={props.filters === x?.name && marketNewsStyle.childMenuSelected} key={`filter-${x?.type}-${x?.name}`}>
                                        <Menu css={marketNewsStyle.childMenu}
                                            childLabel={x?.name + ' (' + x?.totalHits + ')'} 
                                            selected={props.filters === x?.name}
                                            onSelect={() => {props.handelClick(x)}}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </MenuWithChild>
            </div>
        </div>
    );
}

export default MarketNewsMenuRigthFilters;
