/** @jsx jsx */

import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";

import appform from "./appform.png";
import kyc from "./kyc.png";
import { renderLink } from "../../../utils/helper";
import rules from "./rules.png";
import undertaking from "./undertaking.png";

export interface HowToListDocumentProps {
  label?: string;
  extraSentence?: string;
  title: string;
  type: string;
  link: string;
  coverText?: string;
  hideButton?: boolean;
  image?: string;
}
const document = (small?: boolean) => css`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 25px;
  width: 100%;
  max-width: 464px;
  min-height: ${small ? "auto" : "614px"};
  background: #ffffff;
  border: 1px solid #d9e1e2;
  box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
  border-radius: 16px;
  margin-top: 24px;
  margin-bottom: 80px;
`;
const documentHeader = css`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;
const documentHeaderTitle = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
`;
const documentHeaderTitleSmall = css`
  margin-top: 32px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: #354450;
`;
const documentHeaderIcon = css`
  margin-right: 32px;
  color: #22aa5f;
`;
const coverTextStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 150%;
`;
const documentImage = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    width: 100%;
  }
`;
const documentText = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #253645;
  padding-left: 50px;
  padding-right: 50px;
`;
const documentButton = css`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HowToListDocument: React.FunctionComponent<HowToListDocumentProps> =
  (props: HowToListDocumentProps): React.ReactElement => {
    const { title, type, link, label, extraSentence, coverText, image } = props;

    return (
      <div css={document(!!coverText)}>
        <div css={documentHeader}>
          <div css={documentHeaderIcon}>
            <Icon icon={"check-circle"} />
          </div>
          <div css={documentHeaderTitle}>{title}</div>
        </div>
        {coverText ? (
            <div css={coverTextStyle} dangerouslySetInnerHTML={{ __html:coverText}} />
        ) : (
          <>
            <div css={documentImage}>
              <img
                  alt={"image"}
                src={
                  //renderLink(type === 'applicationForm' ? appform : (type === "kyc" ? kyc : undertaking)
                  renderLink(image)
                }
              />
            </div>
            {/*
                            <div css={documentText}>
                            This form is required to complete the admission file. Failing this form, no approval/admission procedure may be initiated.
                        </div>
                             */}
            <div css={documentButton}>
              <Button
                label={label}
                iconLeft={"file"}
                iconRight={"arrow-down"}
                wired
                onClick={() => window.open(link, "_blank").focus()}
                css={css`
                  &:hover {
                    background-color: #22aa5f !important;
                  }
                  border-color: #22aa5f !important;
                `}
              />
              {extraSentence !== "" && (
                  <div css={documentHeaderTitleSmall} dangerouslySetInnerHTML={{ __html:extraSentence}} />
              )}
            </div>
          </>
        )}
      </div>
    );
  };
