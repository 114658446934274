
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const CUW: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#002B7F" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <rect y="317.791" fill="#F9E814" width="512" height="44.14" />
            <g>
                <path fill="#FFFFFF" d="M118.945,170.868l-8.683,26.034l-27.443,0.213c-2.632,0.02-3.723,3.379-1.606,4.942l22.077,16.302 l-8.278,26.165c-0.793,2.509,2.063,4.585,4.204,3.054l22.327-15.958l22.327,15.958c2.141,1.53,4.998-0.545,4.204-3.054 l-8.278-26.165l22.077-16.302c2.118-1.564,1.026-4.921-1.606-4.942l-27.443-0.213l-8.683-26.034 C123.309,168.371,119.778,168.371,118.945,170.868z" />
                <path fill="#FFFFFF" d="M56.393,122.826l-5.025,15.069l-15.884,0.124c-1.523,0.012-2.155,1.955-0.929,2.86l12.778,9.436 l-4.791,15.145c-0.459,1.452,1.194,2.654,2.433,1.768l12.922-9.237l12.922,9.237c1.239,0.886,2.893-0.316,2.433-1.768 l-4.791-15.145l12.778-9.436c1.225-0.905,0.594-2.849-0.929-2.86l-15.884-0.124l-5.025-15.069 C58.919,121.381,56.875,121.381,56.393,122.826z" />
            </g>
        </svg>
    </React.Fragment>
);
