import React, {Component} from 'react';
/** @jsx jsx */
import {css,jsx} from '@emotion/react';
import {Th, Thead, Tr} from "@bdl-cmn-shared-packages-npm/design-system";

export class IndexDetailsBasketHeader extends Component<any, any>{

    render() {
        return (
            <Thead>
                <Tr>
                    <Th>Type</Th>
                    <Th>Security</Th>
                    <Th>Latest price</Th>
                    <Th>Change (%)</Th>
                    <Th>Number of share</Th>
                    <Th>Weight (%)</Th>
                </Tr>
            </Thead>
        );
    }
}
export default IndexDetailsBasketHeader;
