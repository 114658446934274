
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";

export interface DoubleColsWithTicksProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1264px;
  padding-left: 32px;
  padding-right: 32px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  padding-bottom: 48px;
`;
const double = css`
  width: 50%;
  display: flex;
  flex-direction: column;
`;
const flex = css`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const title = css`
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  margin-bottom: 24px;
`;
const titlePart = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
  margin-bottom: 32px;
`;
const header = css`
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
`;
const subtitle = css`
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #425563;
  margin-bottom: 64px;
`;
const item = css`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #354450;
`;
const icon = css`
  color: #CB1234;
  font-size: 20px;
  margin-right: 8px;
`;

export const DoubleColsWithTicks: React.FunctionComponent<DoubleColsWithTicksProps> = (props: DoubleColsWithTicksProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    return (
        <div css={container}>
            <div css={header}>
                <div css={title}>
                    <Text
                        field={fields && fields.title as SitecoreGenericTextField}
                    />
                </div>
                <div css={subtitle}>
                    <Text
                        field={fields && fields.subTitle as SitecoreGenericTextField}
                    />
                </div>
            </div>
            <div css={flex}>
                <div css={double}>
                    <div css={titlePart}>
                        <Text
                            field={fields && fields.titleLeft as SitecoreGenericTextField}
                        />
                    </div>
                    {
                        new Array(6).fill(0).map((arr: any, index: number) => {
                            if (fields && fields[`tickLeft${index + 1}`] && (fields && fields[`tickLeft${index + 1}`] as any).value){
                                return (
                                    <div css={item} key={`items-${index}`}>
                                        <div css={icon}>
                                           <Icon icon={'check'} />
                                        </div>
                                        <Text
                                            field={fields[`tickLeft${index + 1}`] as SitecoreGenericTextField}
                                        />
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <div css={double}>
                    <div css={titlePart}>
                        <Text
                            field={fields && fields.titleRight as SitecoreGenericTextField}
                        />
                    </div>
                    {
                        new Array(6).fill(0).map((arr: any, index: number) => {
                            if (fields && fields[`tickRight${index + 1}`] && (fields && fields[`tickRight${index + 1}`] as any).value){
                                return (
                                    <div css={item} key={`items-${index}`}>
                                        <div css={icon}>
                                           <Icon icon={'check'} />
                                        </div>
                                        <Text
                                            field={fields[`tickRight${index + 1}`] as SitecoreGenericTextField}
                                        />
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    );
}
