import * as React from 'react';

import { css, jsx, keyframes } from '@emotion/react';
import { useEffect, useState } from 'react';

import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import Loader from "../Loader/loader.component";
import axios from "axios";
import {fetchData} from "../../../utils/helper";
import { style } from './marketStatusBox.style';
import { getCurrentMoment } from '../../../utils/date';

/** @jsx jsx */

export interface MarketStatusComponentProps {
  error?: boolean;
  embed?: boolean;
  marketStatus?: boolean;
  marketLabel?: string;
  marketDetails?: string;
}

const MarketStatusComponent: React.FunctionComponent<MarketStatusComponentProps> = ({
  embed,
  marketStatus,
  marketLabel,
  marketDetails,
}: MarketStatusComponentProps) => {
  const instance = axios.create();
  
  const [visible, setVisible] = useState(!!embed);
  const [delay, setDelay] =  useState<number>(0);
  const [startTime, setStartTime] =  useState<number>(0);
  const [loading, setLoading] =  useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<any>(null);
  useEffect(() => {
    if (!embed) {
      setTimeout(() => {
        setVisible(true);
      }, 500);
    }
  }, []);

  const getDataFromItemId = async(id: string) => {
    try {
      const data: any = await fetchData(id);
      const now = getCurrentMoment();
      const start = (data && data[0] && data[0]['Opening hour 24h format']) || false;
      const end = (data && data[0] && data[0]['Closing hour 24h format']) || false;
      setIsOpen(((now.hour() > start) && (now.hour() < end)));
      setDelay(end - now.hour());
      setStartTime(start);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };


  React.useEffect(() => {
    getDataFromItemId('3C6F7B1B-5656-4B31-81BD-0561C08B5073');
  }, []);

  return (
    <div
      css={embed ? style.embedContainer : style.container}
      style={{ right: visible ? (embed ? 'inherit' : 'calc(50% - 125px)') : -250 }}
    >
      <Loader loading={loading}>
        <div css={style.left}>
          <div css={isOpen ? style.leftIcon : style.leftIconClosed}>
            <Icon icon={isOpen ? 'check' : 'times'} />
          </div>
          <div css={isOpen ? style.rightTop : style.rightTopClosed}>
            {isOpen ? 'Market open' : 'Market closed'}
          </div>
        </div>
        <div css={style.right}>
          {
            isOpen ? (
                <div css={style.rightMiddle}>{`Will close in ${delay}${delay > 1 ? ' hours' : 'hour'}`}</div>
            ) : (
                <div css={style.rightMiddle}>{`Will open at ${startTime}`}</div>
            )
          }

          { /* <div css={style.rightBottom}>{marketDetails}</div> */ }
        </div>
      </Loader>
    </div>
  );
};

export default MarketStatusComponent;
