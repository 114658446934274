import * as React from "react";
/** @jsx jsx */
import { style } from "./index.style";
import {
  ComponentParams,
  ComponentRendering,
} from "@sitecore-jss/sitecore-jss-react";
import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter, fetchData, getMembers, renderLink } from "../../utils/helper";
import Loader from "../../core/components/Loader/loader.component";
import { canUseDOM } from "../../predicates";
import { jsx } from "@emotion/react";
import { BreadcrumbComponent } from "../../core/components/Breadcrumb";

const lint = jsx; // linter hack
export interface MemberCardDataProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const MemberCardData: React.FunctionComponent<MemberCardDataProps> = (
  props: MemberCardDataProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const location = useLocation();
  const id = location.pathname.split("/").pop().split("___")[0].toUpperCase();

  const [member, setMember] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const fetchMember = async (id: string) => {
    try {
      const result: any = await fetchData(id, false);
      const res = await getMembers([result]);
      setMember(res[0]);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    fetchMember(id);
  }, [id]);

  const extractLocations = (pathname: string) => {
    const pathSegments = pathname.split('/');
    const cleanedSegments = pathSegments.filter(segment => segment !== '');

    const path1 = `/${cleanedSegments[0]}/`;
    const path2 = `${path1}${cleanedSegments[1]}/`;
    const path1name = capitalizeFirstLetter(cleanedSegments[0]);
    const path2name = cleanedSegments[1].replace(/-/g, ' ').replace(/\b\w/, word => word.toUpperCase() + word.slice(1));

    return { path1, path2, path1name, path2name };
  };

  const { path1, path2, path1name, path2name } = canUseDOM
    ? extractLocations(window.location.pathname)
    : { path1: '', path2: '', path1name: '', path2name: '' };

  return (
    <div css={style.parentContainer}>
      {canUseDOM && path1name && path2name && member?.Name && (
        <BreadcrumbComponent
          manualBreadcrumbs={[
            { label: path1name, link: path1 },
            { label: path2name, link: path2 },
            { label: member?.Name, link: '' },
          ]}
          isAuto={false}
        />
      )}
      <div css={style.topContainer}>
        <div css={style.topContainerChild}>
          <div css={style.topContainerChildLeft}>
            <img src={renderLink(member?.logo)} alt={member?.Name} />
          </div>
          <div css={style.topContainerChildRight}>
            <div css={style.topContainerChildRightTitle}>{member?.Name}</div>
            <div css={style.topContainerChildRightDescription}>
              {member && member["Sub name"]}
            </div>
          </div>
        </div>
      </div>
      <div css={style.container}>
        <Loader loading={loading}>
          <div css={style.left}>
            <div css={style.text}>
              <div
                dangerouslySetInnerHTML={{ __html: member?.Description }}
              />
            </div>
            <div css={style.title}>{member && member["Sub title"]}</div>
            <div css={style.text}>
            <div
                dangerouslySetInnerHTML={{ __html: member?.SubDescription }}
              />
            </div>
            <div css={style.contactSectionTitle}>{member && member["Contact label"]}</div>
            <div css={style.contactSection}>

              <div css={style.contactColumnOne}>
              <div css={style.contactName}>
                  {member && member["Contact name"]}
                </div>
                <div css={style.contactDescription}>
                  {member && member["Contact description"]}
                </div>
                <div css={style.textContact}>
                  {member && <a href={`tel:${member?.Tel}`}>{member?.Tel}</a>}
                </div>
                <div css={style.textContact}>
                  {member && <a href={`mailto:${member["E-Mail"]}`}>{member["E-Mail"]}</a>}
                </div>
                {member?.LinkedIn && (
                <div css={style.icon}>
                  <a href={member?.LinkedIn}>
                    <Icon type={'fab'} icon={'linkedin'} />
                  </a>
                </div>
                )}
              </div>

              <div css={style.contactColumnTwo}>
                <div css={style.contactName}>
                  {member && member["Contact name 2"]}
                </div>
                <div css={style.contactDescription}>
                  {member && member["Contact description 2"]}
                </div>
                <div css={style.textContact}>
                  {member && <a href={`tel:${member["Tel 2"]}`}>{member["Tel 2"]}</a>}
                </div>
                <div css={style.textContact}>
                  {member && <a href={`mailto:${member["E-Mail 2"]}`}>{member["E-Mail 2"]}</a>}
                </div>
                {member?.LinkedIn2 && (
                <div css={style.icon}>
                  <a href={member?.LinkedIn2}>
                    <Icon type={'fab'} icon={'linkedin'} />
                  </a>
                </div>
                )}
              </div>

              <div css={style.contactColumnThree}>
                <div css={style.contactName}>
                  {member && member["Contact name 3"]}
                </div>
                <div css={style.contactDescription}>
                  {member && member["Contact description 3"]}
                </div>
                <div css={style.textContact}>
                  {member && <a href={`tel:${member["Tel 3"]}`}>{member["Tel 3"]}</a>}
                </div>
                <div css={style.textContact}>
                  {member && <a href={`mailto:${member["E-Mail 3"]}`}>{member["E-Mail 3"]}</a>}
                </div>
                {member?.LinkedIn3 && (
                  <div css={style.icon}>
                    <a href={member?.LinkedIn3}>
                      <Icon type={'fab'} icon={'linkedin'} />
                    </a>
              </div>
              )}
              </div>
            </div>
            
            {member?.Website && (
              <div css={style.action}>
                <Button
                  label={member && member["Website button label"]}
                  onClick={() =>
                    member?.Website
                      ? canUseDOM && window.open(member?.Website, "_blank")
                      : console.log
                  }
                />
              </div>
            )}
          </div>
        </Loader>
        {/*<div css={style.right}>
                    <DiscoverAd vertical title='The new bond destination' subTitle='Competitive spreads, Firms prices, Quality retail-size trading' buttonLabel='Discover'/>
                </div> */}
      </div>
    </div>
  );
};
