
export enum LgxStandard {
	ICMAGREEN = 'ICMA - Green Bond Principles',
	ICMASOCIAL = 'ICMA - Social Bond Guidelines',
	ICMASLB = 'ICMA - Sustainability Linked Bond Principles',
	ICMASUSTAINABLE = 'ICMA - Sustainability Bond Guidelines',
	LMAGREENLOANPRINCIPLES = 'LMA - Green Loan Principles',
	CBI = 'CBI - Climate Bonds Standard',
	EUTAXONOMY = 'EU Taxonomy',
	EUGBS = 'EU GBS (best-effort basis)',
	ASEAN = 'ASEAN',
	PBOC = 'PBOC - Green Bond Endorsed Project Catalogue',
	NDRC = 'NDRC - Guidelines for the Issue of Green Bonds',
	OWNFRAMEWORK = 'Own Framework',
	EUGREENBONDSTANDARD = 'EU Green Bond Standard',
	OTHER = 'Other',
}

export enum LgxStandardShort {
	ICMAGREEN = 'ICMA GBP',
	ICMASOCIAL = 'ICMA SBP',
	ICMASLB = 'ICMA SLB',
	ICMASUSTAINABLE = 'ICMA SBG',
	LMAGREENLOANPRINCIPLES = 'LMA GLP',
	CBI = 'CBI',
	EUTAXONOMY = 'EU Taxonomy (draft)',
	EUGBS = 'EU GBS (best-effort basis)',
	ASEAN = 'ASEAN',
	PBOC = 'PBOC',
	NDRC = 'NDRC',
	OWNFRAMEWORK = 'Own Framework',
	EUGREENBONDSTANDARD = 'EU Green Bond Standard',
	OTHER = 'Other',
}

function cleanName(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try{
		value = value.toString().toUpperCase();
		value = value.replace(/[^a-zA-Z ]/g, "");
		value = value.replace(/\s/g, "");
	}catch (e){
	}

	return value;
}

export function lgxStandardLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(LgxStandard).indexOf( cleanName(value) );
  		if( index >= 0) 
			return Object.values(LgxStandard)[index];
	}catch (e){
		console.error("Failed to get LgxStandard label for '" + value + "'", e);
	}
	
	console.error("LgxStandard label for '" + value + "' - '" + cleanName(value) + "' doest exist");

	return value;
}

export function lgxStandardShortLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(LgxStandardShort).indexOf( cleanName(value) );
  		if( index >= 0) 
			return Object.values(LgxStandardShort)[index];
	}catch (e){
		console.error("Failed to get LgxStandardShort label for '" + value + "'", e);
	}
	
	console.error("LgxStandardShort label for '" + value + "' - '" + cleanName(value) + "' doest exist");

	return value;
}