import * as React from "react";

import { breakpointMax } from "../../../../style";

import { css, jsx } from "@emotion/react";

const lint = jsx; // linter hack

export const style = {
  roundTag: css`
    > div {
      background-color: rgb(0 156 196 / 15%);
      border: solid 2px #009cc4;
      height: 39px;
      width: 39px;
      color: #ffffff;
      font-size: 28px !important;
    }
  `,
  info: css`
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1em;
  `,
  subtitle: css`
    display: flex;
    gap: 1em;
    align-content: center;
    font-size: 1em;
    line-height: 1em;

    > *:not(:last-child) {
      padding-right: 1em;
      border-right: 1px solid white;
    }

    @media (max-width: ${breakpointMax}) {
      font-size: 18px;
      position: relative;
      width: 100%;
      > *:not(:last-child) {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  `,
  programmeNameText: css`
    font-size: 1.2em;
    @media (max-width: ${breakpointMax}) {
      text-align: center;
    }
  `,
};
