import * as React from 'react';

import { breakpoint, breakpointMax } from '../../../../style';

import { css } from '@emotion/react';

export const style = {
  market: css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #e3e6e8;
    border-radius: 999em;
    padding: 8px 16px;
    font-weight: bold;
    margin-right: 5px;
    font-size: 13px;
    width: fit-content;
    svg {
      margin-right: 5px;
    }
  `,
  info: css`
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1em;
    @media (max-width: ${breakpointMax}) {
      align-items: center;
      justify-content: center;
      display: flex;
      text-align: center;
    }
  `,
  country: css`
    display: flex;
    gap: 0.5em;
    align-content: center;
    font-size: 1em;
  `,
  issuerNameText: css`
    font-size: 1.2em;
  `,
  metadata: css`
    display: flex;
    align-items: center;

    > * {
      padding: 1em;
      border-right: 1px solid #253645;
    }

    @media (max-width: ${breakpointMax}) {
      flex-direction: column;

      > div {
        width: 100%;
        text-align: center;
        border-right: none;
        border-bottom: 1px solid #253645;
      }
    }
  `,
  cai: css`
    width: 100%;
    display: flex;
    flex-direction: row;

    background-color: #253845;
    color: #d9dee2;
    border-radius: 0px 0px 25px 25px;
    align-items: center;
    border-collapse: collapse;
  `,
  caiTitle: css`
    background-color: #008c46;
    padding: 1.5em 2em;
    border-bottom-left-radius: 25px;
    border: 2px solid #008c46;
  `,
  caiRow: css`
    display: flex;
    flex-direction: column;
    padding: 1em;
    line-height: 1.2em;
  `,
  caiLabel: css`
    font-size: 12px;
  `,
  caiValue: css`
    font-size: 14px;
  `,
};
