/** @jsx jsx */

import * as React from 'react';

import { Button, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { SitecoreGenericTextField } from '../../interfaces';
import hexa from '../DoubleCardComponent/hexa.svg';
import hexaFull from './hexa-full.svg';
import { renderLink } from '../../utils/helper';
import { style } from '../LuxXPrimeDoubleTrading/index.style';

export interface PricesComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpointMax}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const title = css`
  line-height: 62px;
  width: 100%;
  text-align: center;
  color: #354450;
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 32px;
  margin-top: 96px;
  @media (max-width: ${breakpointMax}) {
    font-size: 24px;
    line-height: 31px;
    text-align: center;
  }
`;
const subTitle = css`
  line-height: 25px;
  width: 100%;
  text-align: center;
  color: #425563;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 32px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.01em;
  }
`;
const items = css`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  padding-bottom: 96px;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const bestValue = css`
  display: flex;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  color: #f18700;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  background: rgba(241, 135, 0, 0.1);
  min-height: 40px;
  position: absolute;
`;
const itemsCol = css`
  position: relative;
  width: 100%;
  padding-left: 75px;
  padding-right: 75px;
  display: flex;
  flex-direction: column;
  border: solid 1px #e3e6e8;
  box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
  border-radius: 8px;
  max-width: 500px;
  margin-left: 8px;
  margin-right: 8px;
  background: #ffffff;
  justify-content: space-between;
  @media (max-width: ${breakpointMax}) {
    margin: 0;
    margin-bottom: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
const gradiantBg = css`
  background: linear-gradient(180deg, #a4bcc2 0%, rgba(164, 188, 194, 0) 100%);
  opacity: 0.3;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 40%;
`;
const item = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
`;
const parentItem = css`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 48px;
  @media (max-width: ${breakpointMax}) {
    margin-top: 16px;
  }
`;
const cardTitle = css`
  font-weight: 700;
  color: #354450;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-top: 32px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
  }
`;
const cardSubTitle = css`
  font-weight: 400;
  color: #22AA5F;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 32px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;
const icon = css`
  color: #22AA5F;
  border: solid 1px #e3e6e8;
  border-radius: 8px;
  min-width: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin-left: 8px;
  margin-right: 16px;
`;
const text = css`
  color: #354450;
  font-size: 16px;
  line-height: 150%;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;
const action = css`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`;
const edgeText = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #354450;
`;
const cardIconParent = css`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const cardIcon = css`
  display: inline-flex;
  flex-direction: column;
  background: url(${renderLink(hexa)}) no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  color: #008c46;
  font-size: 32px;
  height: 110px;
  width: 110px;
`;

const hexa1 = css`
  position: absolute;
  width: 250px;
  top: 5%;
  left: -7%;
  img {
    width: 100%;
  }
  @media (max-width: ${breakpointMax}) {
  }
`;
const hexa2 = css`
  position: absolute;
  width: 65px;
  left: 91%;
  top: 11%;
  img {
    width: 100%;
  }
  @media (max-width: ${breakpointMax}) {
  }
`;
const hexa3 = css`
  position: absolute;
  width: 300px;
  left: 75%;
  top: 28%;
  img {
    width: 100%;
  }
  @media (max-width: ${breakpointMax}) {
  }
`;

export const PricesComponent: React.FunctionComponent<PricesComponentProps> = (
  props: PricesComponentProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  const cardBtnLabel1: string = getFieldValue<string>(fields, 'cardBtnLabel1', '');
  const mailToSubject: string = getFieldValue<string>(fields, 'mailToSubject', '');
  const mailTo: string = getFieldValue<string>(fields, 'mailTo', '');
  const parentContainer = css`
    position: relative;
    width: 100%;
  `;

  return (
    <div css={parentContainer}>
      <div css={gradiantBg} />
      <div css={hexa1}>
        <img src={renderLink(hexaFull)} alt={"image"} />
      </div>
      <div css={hexa2}>
        <img src={renderLink(hexaFull)} alt={"image"} />
      </div>
      <div css={hexa3}>
        <img src={renderLink(hexaFull)} alt={"image"} />
      </div>
      <div css={container}>
        <div css={title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={subTitle}>
          <RichText field={fields && (fields.subTitle as SitecoreGenericTextField)} />
        </div>
        <div css={items}>
          {new Array(3).fill(0).map((arr: any, index: number) => {
            if (
              fields &&
              fields[`cardTitle${index + 1}`] &&
              (fields && (fields[`cardTitle${index + 1}`] as any)).value
            ) {
              return (
                <div css={itemsCol}>
                  {!!(
                    fields &&
                    fields[`cardBestValue${index + 1}`] &&
                    (fields && (fields[`cardBestValue${index + 1}`] as any)).value
                  ) && <div css={bestValue}>Best value</div>}
                  <div css={parentItem}>
                    <div css={cardIconParent}>
                      <div css={cardIcon}>
                        <Icon
                          icon={
                            fields[`cardIcon${index + 1}`] && (fields && (fields[`cardIcon${index + 1}`] as any)).value
                          }
                        />
                      </div>
                    </div>
                    <div css={cardTitle}>
                      <Text field={fields[`cardTitle${index + 1}`] as SitecoreGenericTextField} />
                    </div>
                    <div css={cardSubTitle}>
                      <Text field={fields[`cardSubTitle${index + 1}`] as SitecoreGenericTextField} />
                    </div>
                    {new Array(10).fill(0).map((arr: any, idx: number) => {
                      if (
                        fields &&
                        fields[`cardTick${index + 1}-${idx + 1}`] &&
                        (fields && (fields[`cardTick${index + 1}-${idx + 1}`] as any)).value
                      ) {
                        return (
                          <div css={item}>
                            <div css={icon}>
                              <Icon icon={'check'} />
                            </div>
                            <div css={text}>
                              <Text field={fields[`cardTick${index + 1}-${idx + 1}`] as SitecoreGenericTextField} />
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div css={action}>
                    <Button
                      label={cardBtnLabel1}
                      iconLeft={'paper-plane'}
                      onClick={() => window.open(`mailto:${mailTo}?subject=${mailToSubject || 'Request a demo'}`)}
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};
