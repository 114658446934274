/** @jsx jsx */
/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import { css, jsx } from "@emotion/react";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpointChartMax, breakpointMax } from "../../../../style";
import RiserAndFaller from "./RiserAndFaller";
import { Security } from "../Home/risers-fallers-home";


export class RisersAndFallersComponent extends Component<any, any> {

    render() {

        const typeName: string = this.props.type + " performances";
        const riser = this.props.riser;
        const faller = this.props.faller;
        
        const date: string = this.props.date;
        const description: string = this.props.title;

        const style = {
            date: css`
                font-weight:bold;
            `,
            parent: css`
                display:flex;
                flex-direction: column;
                margin: 0px auto;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
                width:90%;
                height:100%;
                font-family: 'Mont';
                font-style: normal;
                >div:nth-child(5){
                    width:100%;
                    align-items: left;
                    text-align:left;
                    @media (max-width: ${breakpointMax}) {
                        align-items: center;
                        text-align:center;
                    }
                }
                @media (max-width: ${breakpointMax}) {
                    width:95%;
                }
            `,
            performace: css`
                font-weight:700;
                font-size:24px;
                line-height:31px;
                text-align:center;
                @media (max-width: ${breakpointMax}) {
                    margin-bottom :0px;
                    padding-bottom:0px;
                }
            `,
            bottomDescription: css`
                font-weight: 400;
                font-size: 13px;
                line-height: 17px;
                color: #425563;
                @media (max-width: ${breakpointMax}) {
                    text-align:center;
                    margin-bottom :0px;
                    padding-bottom:0px;
                }
            `,
            borderBottom: css`
                display:flex;
                flex-direction: column;
                width:100%;
                border-top: 1px solid #D9DEE2;
                @media (max-width: ${breakpointMax}) {
                    margin-bottom:0;
                    margin-top:0;
                    padding-bottom:0;
                    pdding-top:0;
                }
            `,
        };


        return (
            <>
                <div css={style.parent}>

                    <div css={style.performace}>{typeName}</div>

                    <RiserAndFaller data={riser} type="riser"/>

                    <div css={style.borderBottom}></div>

                    <RiserAndFaller data={faller} type="faller" />

                    <div css={style.bottomDescription}>{description}<span css={style.date}>{date}</span></div>

                </div>
            </>
        );
    }
}

export default RisersAndFallersComponent;
