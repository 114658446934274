
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const SLV: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <g>
                <path fill="#0F47AF" d="M512,200.093H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,200.093L512,200.093z" />
                <path fill="#0F47AF" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V311.909h512v102.988 C512,419.773,508.047,423.725,503.172,423.725z" />
            </g>
            <rect y="200.091" fill="#FFFFFF" width="512" height="111.81" />
            <g>
                <path fill="#1E5B19" d="M255.789,293.563c-21.295,0-38.621-17.325-38.621-38.621c0-9.097,3.224-17.931,9.08-24.877 l1.687,1.422c-5.521,6.548-8.561,14.877-8.561,23.455c0,20.077,16.335,36.414,36.414,36.414L255.789,293.563L255.789,293.563z" />
                <path fill="#1E5B19" d="M241.662,282.16c-0.482,0.823-0.721,1.953-0.825,3.1c-0.436-0.314-0.898-0.606-1.4-0.851 c0,0-0.759,2.674-0.166,4.599c0,0-2.013,0.249-3.861,2.103c0,0,0.637,0.45,1.73,0.919c-1.835,1.432-2.401,2.848-2.401,2.848 c10.83,5.415,16.02-2.138,16.246-2.477C248.332,284.832,241.662,282.16,241.662,282.16z" />
                <path fill="#1E5B19" d="M250.985,292.403c0.007,0.019,0.015,0.035,0.022,0.055v-0.087 C251.007,292.371,250.99,292.395,250.985,292.403z" />
                <path fill="#1E5B19" d="M231.66,274.942c-0.707,0.518-1.321,1.383-1.83,2.312c-0.253-0.424-0.535-0.839-0.87-1.229 c0,0-1.618,1.976-1.821,3.817c0,0-1.788-0.525-4.023,0.362c0,0,0.372,0.612,1.122,1.408c-2.07,0.536-3.065,1.523-3.065,1.523 c7.149,8.525,14.287,4.055,14.601,3.852C236.308,279.632,231.66,274.942,231.66,274.942z" />
                <path fill="#1E5B19" d="M235.775,286.985c-0.001,0.019,0,0.035-0.002,0.054l0.032-0.073 C235.805,286.966,235.783,286.98,235.775,286.985z" />
                <path fill="#1E5B19" d="M225.455,265.505c-0.772,0.163-1.598,0.647-2.359,1.22c-0.051-0.442-0.129-0.887-0.259-1.332 c0,0-2.061,1.024-2.91,2.461c0,0-1.274-1.095-3.438-1.194c0,0,0.078,0.641,0.401,1.572c-1.899-0.327-3.082,0.115-3.082,0.115 c2.713,9.652,10.232,8.625,10.567,8.574C227.533,271.081,225.455,265.505,225.455,265.505z" />
                <path fill="#1E5B19" d="M224.372,276.922c-0.008,0.014-0.013,0.029-0.022,0.044l0.053-0.049 C224.404,276.918,224.38,276.921,224.372,276.922z" />
                <path fill="#1E5B19" d="M222.999,254.75c-0.733,0.047-1.586,0.367-2.403,0.774c0.056-0.39,0.087-0.786,0.073-1.187 c0,0-2.09,0.639-3.183,1.782c0,0-0.896-1.103-2.822-1.452c0,0-0.076,0.566,0,1.412c-1.634-0.513-2.801-0.274-2.801-0.274 c0.228,8.702,7.231,8.721,7.543,8.717C223.591,259.838,222.999,254.75,222.999,254.75z" />
                <path fill="#1E5B19" d="M219.405,264.524c-0.011,0.012-0.019,0.023-0.03,0.035l0.058-0.035 C219.434,264.524,219.412,264.523,219.405,264.524z" />
                <path fill="#1E5B19" d="M223.814,245.843c-0.627-0.15-1.426-0.103-2.217,0.028c0.148-0.312,0.277-0.637,0.367-0.977 c0,0-1.919-0.004-3.132,0.672c0,0-0.466-1.158-1.991-1.948c0,0-0.211,0.455-0.366,1.185c-1.238-0.853-2.279-0.953-2.279-0.953 c-2.06,7.358,3.809,9.186,4.072,9.263C222.993,250.264,223.814,245.843,223.814,245.843z" />
                <path fill="#1E5B19" d="M218.27,253.112c-0.012,0.008-0.022,0.015-0.034,0.022l0.058-0.014 C218.294,253.12,218.275,253.113,218.27,253.112z" />
                <path fill="#1E5B19" d="M219.168,235.011c0,0-2.001,5.241,1.103,8.276c0,0,4.046-1.195,6.046-5.402l-1.702-0.322 l-0.874-2.621l-2.897,1.288L219.168,235.011z" />
                <path fill="#1E5B19" d="M224.802,226.586c0,0-3.135,4.652-0.8,8.311c0,0,4.212-0.248,7.113-3.893l-1.585-0.698l-0.257-2.751 l-3.113,0.598L224.802,226.586z" />
                <path fill="#1E5B19" d="M256.211,293.563v-2.207c20.078,0,36.414-16.337,36.414-36.414c0-8.577-3.04-16.907-8.561-23.455 l1.687-1.422c5.856,6.946,9.08,15.78,9.08,24.877C294.831,276.238,277.506,293.563,256.211,293.563z" />
                <path fill="#1E5B19" d="M270.338,282.16c0.482,0.823,0.721,1.953,0.825,3.1c0.436-0.314,0.898-0.606,1.4-0.851 c0,0,0.759,2.674,0.165,4.599c0,0,2.013,0.249,3.861,2.103c0,0-0.637,0.45-1.73,0.919c1.835,1.432,2.401,2.848,2.401,2.848 c-10.83,5.415-16.02-2.138-16.246-2.477C263.668,284.832,270.338,282.16,270.338,282.16z" />
                <path fill="#1E5B19" d="M261.015,292.403c-0.007,0.019-0.015,0.035-0.022,0.055v-0.087 C260.993,292.371,261.01,292.395,261.015,292.403z" />
                <path fill="#1E5B19" d="M280.34,274.942c0.707,0.518,1.321,1.383,1.829,2.312c0.253-0.424,0.535-0.839,0.87-1.229 c0,0,1.618,1.976,1.821,3.817c0,0,1.788-0.525,4.023,0.362c0,0-0.372,0.612-1.122,1.408c2.07,0.536,3.065,1.523,3.065,1.523 c-7.149,8.525-14.287,4.055-14.601,3.852C275.692,279.632,280.34,274.942,280.34,274.942z" />
                <path fill="#1E5B19" d="M276.225,286.985c0.001,0.019,0,0.035,0.002,0.054l-0.032-0.073 C276.195,286.966,276.217,286.98,276.225,286.985z" />
                <path fill="#1E5B19" d="M286.545,265.505c0.772,0.163,1.598,0.647,2.359,1.22c0.051-0.442,0.129-0.887,0.259-1.332 c0,0,2.061,1.024,2.91,2.461c0,0,1.274-1.095,3.438-1.194c0,0-0.078,0.641-0.401,1.572c1.899-0.327,3.082,0.115,3.082,0.115 c-2.713,9.652-10.232,8.625-10.567,8.574C284.467,271.081,286.545,265.505,286.545,265.505z" />
                <path fill="#1E5B19" d="M287.628,276.922c0.008,0.014,0.013,0.029,0.022,0.044l-0.053-0.049 C287.596,276.918,287.62,276.921,287.628,276.922z" />
                <path fill="#1E5B19" d="M289.001,254.75c0.733,0.047,1.586,0.367,2.403,0.774c-0.056-0.39-0.087-0.786-0.073-1.187 c0,0,2.09,0.639,3.183,1.782c0,0,0.896-1.103,2.822-1.452c0,0,0.076,0.566,0,1.412c1.634-0.513,2.801-0.274,2.801-0.274 c-0.228,8.702-7.231,8.721-7.543,8.717C288.409,259.838,289.001,254.75,289.001,254.75z" />
                <path fill="#1E5B19" d="M292.595,264.524c0.011,0.012,0.019,0.023,0.03,0.035l-0.059-0.035 C292.566,264.524,292.588,264.523,292.595,264.524z" />
                <path fill="#1E5B19" d="M288.186,245.843c0.627-0.15,1.426-0.103,2.217,0.028c-0.148-0.312-0.277-0.637-0.367-0.977 c0,0,1.919-0.004,3.132,0.672c0,0,0.466-1.158,1.991-1.948c0,0,0.211,0.455,0.366,1.185c1.238-0.853,2.279-0.953,2.279-0.953 c2.06,7.358-3.809,9.186-4.072,9.263C289.007,250.264,288.186,245.843,288.186,245.843z" />
                <path fill="#1E5B19" d="M293.73,253.112c0.012,0.008,0.022,0.015,0.034,0.022l-0.058-0.014 C293.706,253.12,293.725,253.113,293.73,253.112z" />
                <path fill="#1E5B19" d="M292.832,235.011c0,0,2.001,5.241-1.103,8.276c0,0-4.046-1.195-6.046-5.402l1.702-0.322l0.874-2.621 l2.897,1.288L292.832,235.011z" />
                <path fill="#1E5B19" d="M287.198,226.586c0,0,3.135,4.652,0.8,8.311c0,0-4.212-0.248-7.113-3.893l1.585-0.698l0.257-2.751 l3.113,0.598L287.198,226.586z" />
            </g>
            <path fill="#0F47AF" d="M256,270.346l-3.412,5.118c-0.583,0.874-0.468,2.038,0.276,2.781l3.136,3.136l3.136-3.136 c0.743-0.743,0.858-1.907,0.276-2.781L256,270.346z" />
            <path fill="#FFFFFF" d="M257.454,275.464L256,270.346l-1.454,5.118c-0.248,0.874-0.199,2.038,0.117,2.781l1.337,3.136 l1.336-3.136C257.653,277.501,257.703,276.338,257.454,275.464z" />
            <g>
                <path fill="#0F47AF" d="M256,224.553V210.76c0,0-5.241,7.54-4.414,18.574" />
                <path fill="#0F47AF" d="M232.494,224.6c-7.582,16.456,0.195,29.996,1.827,32.545c0.043,0.068,10.827-17.376,10.827-17.376 L232.494,224.6" />
            </g>
            <path fill="#FFFFFF" d="M240.477,247.315c-1.196-3.725-1.369-8.075,0.067-13.066l-4.692-5.624 c-3.855,10.388-2.26,18.058,1.599,23.562C238.398,250.664,239.45,248.97,240.477,247.315z" />
            <path fill="#FFCC00" d="M244.967,240.688c-0.329,0-0.654-0.147-0.872-0.427l-13.837-17.791 c-0.375-0.481-0.288-1.174,0.193-1.547c0.483-0.375,1.174-0.287,1.548,0.194l13.837,17.791c0.375,0.481,0.288,1.174-0.193,1.547 C245.442,240.613,245.203,240.688,244.967,240.688z" />
            <path fill="#0F47AF" d="M222.897,241.699c-4.414,44.138,30.897,33.103,33.103,28.69l-22.069-11.034l4.414-8.828 L222.897,241.699" />
            <path fill="#FFFFFF" d="M233.567,246.778l-6.151-3.69c-1.384,26.228,12.622,30.596,22.953,29.294 C241.994,271.258,233.626,265.074,233.567,246.778z" />
            <path fill="#FFCC00" d="M238.343,251.628c-0.2,0-0.402-0.054-0.584-0.168l-17.654-11.032 c-0.516-0.323-0.673-1.004-0.35-1.519c0.322-0.52,1.002-0.676,1.521-0.351l17.654,11.032c0.516,0.323,0.673,1.004,0.35,1.519 C239.07,251.445,238.71,251.628,238.343,251.628z" />
            <path fill="#0F47AF" d="M279.506,224.556c7.582,16.456-0.195,29.996-1.827,32.545c-0.043,0.068-10.827-17.376-10.827-17.376 L279.506,224.556" />
            <path fill="#FFFFFF" d="M271.523,247.271c1.196-3.725,1.369-8.075-0.067-13.066l4.692-5.624 c3.855,10.388,2.26,18.058-1.599,23.562C273.602,250.621,272.55,248.926,271.523,247.271z" />
            <path fill="#FFCC00" d="M267.033,240.645c-0.236,0-0.476-0.075-0.676-0.233c-0.481-0.373-0.568-1.067-0.193-1.547 l13.837-17.791c0.375-0.481,1.067-0.567,1.548-0.194s0.568,1.067,0.193,1.547l-13.837,17.791 C267.688,240.499,267.362,240.645,267.033,240.645z" />
            <path fill="#0F47AF" d="M289.103,241.656c4.414,44.138-30.897,33.103-33.103,28.69l22.069-11.034l-4.414-8.828 L289.103,241.656" />
            <path fill="#FFFFFF" d="M278.433,246.734l6.151-3.69c1.384,26.228-12.622,30.596-22.953,29.294 C270.006,271.214,278.374,265.031,278.433,246.734z" />
            <path fill="#FFCC00" d="M273.657,251.585c-0.367,0-0.727-0.183-0.937-0.52c-0.323-0.515-0.165-1.196,0.35-1.519 l17.654-11.032c0.519-0.326,1.198-0.168,1.521,0.351c0.323,0.515,0.166,1.196-0.35,1.519l-17.654,11.032 C274.059,251.531,273.857,251.585,273.657,251.585z" />
            <circle fill="#FFE6E6" cx="256" cy="243.861" r="8.828" />
            <circle fill="#FFFFFF" cx="256" cy="243.861" r="4.414" />
            <path fill="#FFCC00" d="M256,258.208c-0.61,0-1.103-0.493-1.103-1.103v-13.241c0-0.61,0.493-1.103,1.103-1.103 c0.61,0,1.103,0.493,1.103,1.103v13.241C257.103,257.715,256.61,258.208,256,258.208z" />
            <polygon fill="#5AA0E6" points="286.161,270.162 227.034,270.162 233.931,257.038 278.253,257.038 " />
            <path fill="#FFB750" d="M257.795,259.496h15.377l-4.783-4.783c-0.225-0.225-0.5-0.395-0.801-0.495l-1.665-0.555 c-0.6-0.2-1.259-0.111-1.785,0.239l-5.316,3.544L257.795,259.496z" />
            <path fill="#FFC455" d="M252.567,260.323h18.353l-5.709-5.709c-0.268-0.268-0.596-0.471-0.957-0.591l-1.987-0.662 c-0.716-0.238-1.503-0.134-2.131,0.286l-6.345,4.231L252.567,260.323z" />
            <path fill="#FFCD55" d="M246.183,261.242h22.163l-6.894-6.894c-0.324-0.324-0.719-0.568-1.155-0.714l-2.4-0.8 c-0.865-0.288-1.815-0.161-2.573,0.344l-7.662,5.108L246.183,261.242z" />
            <path fill="#FFD25A" d="M239.865,262.07h25.376l-7.894-7.894c-0.372-0.372-0.824-0.651-1.323-0.818l-2.748-0.916 c-0.991-0.33-2.078-0.184-2.947,0.395l-8.774,5.849L239.865,262.07z" />
            <path fill="#FFCC00" d="M229.517,263.725h33.103l-10.297-10.297c-0.484-0.484-1.075-0.85-1.726-1.066l-3.584-1.195 c-1.292-0.43-2.711-0.241-3.844,0.515l-11.445,7.629L229.517,263.725z" />
            <g>
                <path fill="#E60000" d="M258.382,245.843h-4.771l1.099-4.607h1.658c0.537,0,1.004,0.369,1.129,0.892L258.382,245.843z" />
                <path fill="#E60000" d="M258.108,246.583h-4.284c-0.351,0-0.634-0.284-0.634-0.634v-0.212c0-0.351,0.285-0.634,0.634-0.634 h4.284c0.351,0,0.634,0.285,0.634,0.634v0.212C258.742,246.298,258.457,246.583,258.108,246.583z" />
                <path fill="#E60000" d="M255.276,243.333l-1.071-0.271c-0.323-0.082-0.463-0.462-0.27-0.734l0.775-1.092L255.276,243.333z" />
            </g>
            <path fill="#0F47AF" d="M250.495,232.527c-0.052,0.405-0.105,0.811-0.157,1.216c1.715-1.041,3.607-1.665,5.662-1.665 c2.053,0.002,3.946,0.622,5.662,1.665c-0.052-0.406-0.105-0.811-0.157-1.216c-1.706-0.89-3.542-1.402-5.505-1.4 C254.038,231.125,252.202,231.636,250.495,232.527z" />
            <path fill="#5AA0E6" d="M250.563,231.999c-0.052,0.405-0.105,0.811-0.157,1.216c1.696-1.043,3.565-1.668,5.593-1.671 c2.028,0.002,3.897,0.627,5.593,1.671c-0.052-0.406-0.105-0.811-0.157-1.216c-1.687-0.897-3.499-1.405-5.437-1.406 C254.062,230.593,252.25,231.104,250.563,231.999z" />
            <path fill="#9BC800" d="M250.626,231.51c-0.052,0.405-0.105,0.811-0.157,1.216c1.679-1.046,3.527-1.676,5.53-1.675 c2.004,0,3.851,0.629,5.53,1.675c-0.052-0.405-0.105-0.811-0.157-1.216c-1.668-0.896-3.459-1.41-5.374-1.411 C254.087,230.098,252.296,230.613,250.626,231.51z" />
            <path fill="#FFE14E" d="M250.699,230.951c-0.052,0.406-0.105,0.811-0.157,1.216c1.66-1.049,3.482-1.681,5.459-1.681 c1.976,0.002,3.799,0.63,5.459,1.681c-0.052-0.405-0.105-0.811-0.157-1.216c-1.649-0.9-3.414-1.416-5.301-1.417 C254.113,229.536,252.348,230.051,250.699,230.951z" />
            <path fill="#FFB14E" d="M250.77,230.402c-0.052,0.405-0.105,0.811-0.157,1.216c1.641-1.053,3.437-1.685,5.387-1.686 c1.95,0.002,3.747,0.632,5.387,1.686c-0.052-0.405-0.105-0.811-0.157-1.216c-1.629-0.903-3.37-1.421-5.23-1.422 C254.14,228.982,252.398,229.498,250.77,230.402z" />
            <path fill="#E60000" d="M250.799,230.168c-0.036,0.284-0.073,0.568-0.109,0.852c1.619-1.054,3.391-1.693,5.311-1.693 c1.92-0.001,3.691,0.637,5.311,1.693c-0.036-0.284-0.073-0.568-0.109-0.852c-1.62-0.904-3.351-1.423-5.201-1.425 C254.151,228.741,252.419,229.263,250.799,230.168z" />
            <g>
                <path fill="#FFCC00" d="M286.897,271.449h-61.793c-0.407,0-0.781-0.224-0.973-0.582c-0.192-0.36-0.17-0.796,0.055-1.133 l30.897-46.345c0.409-0.612,1.427-0.612,1.836,0l30.897,46.345c0.225,0.339,0.247,0.774,0.055,1.133 C287.678,271.225,287.304,271.449,286.897,271.449z M227.165,269.242h57.671L256,225.991L227.165,269.242z" />
                <path fill="#FFCC00" d="M256,214.07c-0.61,0-1.103-0.493-1.103-1.103v-4.414c0-0.61,0.493-1.103,1.103-1.103 c0.61,0,1.103,0.493,1.103,1.103v4.414C257.103,213.577,256.61,214.07,256,214.07z" />
                <path fill="#FFCC00" d="M258.207,214.07h-4.414c-0.61,0-1.103-0.493-1.103-1.103s0.493-1.103,1.103-1.103h4.414 c0.61,0,1.103,0.493,1.103,1.103S258.817,214.07,258.207,214.07z" />
            </g>
            <g>
                <path fill="#0F47AF" d="M256,293.518c-0.079,0-0.161-0.009-0.239-0.025c-1.08-0.242-4.684-1.106-5.747-2.159 c-1.35-1.341-1.357-3.528-0.019-4.879c0.65-0.653,1.514-1.015,2.435-1.017h0.012c0.917,0,1.779,0.355,2.43,1.002 c1.063,1.056,1.954,4.651,2.202,5.728c0.085,0.369-0.025,0.757-0.292,1.026C256.574,293.403,256.291,293.518,256,293.518z M252.442,287.643c-0.294,0.097-0.647,0.131-0.882,0.366c-0.482,0.484-0.479,1.273,0.008,1.757c0.267,0.26,1.46,0.712,2.89,1.119 c-0.418-1.425-0.877-2.614-1.141-2.881l0.777-0.782l-0.778,0.782C253.082,287.77,252.772,287.643,252.442,287.643z" />
                <path fill="#0F47AF" d="M256,293.518c-0.291,0-0.574-0.115-0.783-0.326c-0.266-0.269-0.377-0.658-0.292-1.026 c0.248-1.078,1.14-4.672,2.202-5.728l0,0c0.654-0.649,1.433-0.943,2.442-1.002c0.921,0.002,1.785,0.364,2.434,1.017 c1.341,1.352,1.332,3.539-0.017,4.877c-1.064,1.056-4.668,1.92-5.748,2.162C256.161,293.509,256.079,293.518,256,293.518z M257.906,287.221l0.777,0.782c-0.264,0.267-0.723,1.457-1.141,2.881c1.43-0.407,2.623-0.857,2.891-1.121 c0.484-0.481,0.488-1.269,0.006-1.754c-0.233-0.235-0.544-0.366-0.876-0.366h-0.004c-0.33,0-0.64,0.127-0.874,0.36L257.906,287.221 z M257.906,287.221h0.022H257.906z" />
                <path fill="#0F47AF" d="M251.586,300.139c-0.61,0-1.103-0.493-1.103-1.103c0-5.038,4.819-7.505,5.024-7.608 c0.548-0.274,1.205-0.05,1.478,0.491c0.274,0.543,0.055,1.205-0.487,1.481c-0.039,0.02-3.807,1.976-3.807,5.635 C252.69,299.646,252.196,300.139,251.586,300.139z" />
                <path fill="#0F47AF" d="M260.414,300.139c-0.61,0-1.103-0.493-1.103-1.103c0-3.659-3.768-5.617-3.807-5.635 c-0.542-0.276-0.76-0.938-0.487-1.481c0.275-0.541,0.931-0.765,1.478-0.491c0.205,0.104,5.024,2.569,5.024,7.608 C261.517,299.646,261.024,300.139,260.414,300.139z" />
            </g>
        </svg>
    </React.Fragment>
);
