import * as React from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Td, Tr, Button} from '@bdl-cmn-shared-packages-npm/design-system';
import {formatDate} from "../../../../utils/date";
import Props from "../../UI/list/base/props";
import {marketNewsStyle} from "./index.style";
import {DOWNLOAD_ENDPOINT} from "../../../../backbourse";
import {documentTypeLabel} from '../../../../enums/document-type';
import {documentSubTypeLabel} from '../../../../enums/document-sub-type';
import { MarketNewsDetailsModel } from '.';


export const MarketNewsLatestFnsRow = ({row}: Props<MarketNewsDetailsModel>) => {
    if( !row )
        return <></>;

    let categories : String[] = [];
    if( row.properties?.categories ){
        row.properties.categories.forEach( (category: string) => {
            let categoryLabel = documentTypeLabel( category );
            if( categoryLabel && ! categories.includes(categoryLabel))
                categories.push(categoryLabel);
        });
    }

    return (
        <Tr>
            <Td>
                <div>
                    { row.label ?
                        <div css={marketNewsStyle.status}>
                            <div css={marketNewsStyle.tag}>{ row.label}</div>
                        </div>
                        : <></>
                    }
                    { categories ?
                        <div css={marketNewsStyle.status}>
                            { categories.map(category =>
                                <div css={marketNewsStyle.tag} key={`fns-${row.id}-category-${category}`}>{category}</div>
                            )}
                        </div>
                        :<></>
                    }
                    <div css={marketNewsStyle.title2}>
                        {documentSubTypeLabel(row?.properties?.typePublic)}
                        {row?.properties?.value_1 ?
                            ` - ${row?.properties?.value_1}`
                            : ""
                        }
                    </div>
                    <h3 css={marketNewsStyle.title1}>{row?.name}</h3>
                    <div css={marketNewsStyle.title2}>{formatDate(row?.date, 'DD/MM/YY HH:mm:ss')}</div>
                </div>
            </Td>
            <Td>
            </Td>
            <Td>
                <Button
                    onClick={() => (window.location.href = DOWNLOAD_ENDPOINT + row?.properties?.downloadUrl)}
                    label={"Download"}
                    iconRight='download'/>
            </Td>
        </Tr>
    );
};
export default MarketNewsLatestFnsRow;
