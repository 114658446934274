/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import * as React from 'react';
import {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Icon, ToggleButtonGroup, ToggleItem} from '@bdl-cmn-shared-packages-npm/design-system';
import {tradingStyle} from '../MarketData/trading-data.style';
import {renderLink} from '../../../../utils/helper';
import lgxLogo from './lgxlogo.png';
import AllSecurities from "../MarketData/AllSecurities";
import LGXMarketData from "../MarketData/LGX";
import {TradingDataView} from "./trading-data-view.component";
import {MarketDataHeader} from "../MarketData/Header";


export const DashboardTradingDataView = () => {
    const [target, setTarget] = useState<string>('tradingDataView');

    return (
            <div css={tradingStyle.mainContent}>
                <MarketDataHeader />

                <div css={tradingStyle.displaySettings}>
                    <span css={tradingStyle.settingsText}>Display for</span>
                    <ToggleButtonGroup onChange={(elem: any) => setTarget(elem)}>

                        <ToggleItem selected={target === 'All securities'} value='All securities'>
                            <Icon icon={'chart-area'}/> All securities
                        </ToggleItem>
                        <ToggleItem value='greenx' selected={target === 'greenx'}>
                            <img src={renderLink(lgxLogo)} alt={"image"}/> Luxembourg Green Exchange
                        </ToggleItem>
                        <ToggleItem value='tradingDataView' selected={target === 'tradingDataView'}>
                            <Icon icon={'chart-area'}/> Trading Data
                        </ToggleItem>
                    </ToggleButtonGroup>
                </div>
                {target === 'All securities' && <AllSecurities/>}
                {target === 'greenx' && <LGXMarketData/>}
                {target === 'tradingDataView' && <TradingDataView/>}
            </div>
    );
};

export const DataMarket = withRouter(DashboardTradingDataView);
