
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Icon, Button } from '@bdl-cmn-shared-packages-npm/design-system';
import {
    childrenBloc,
    childrenBlocLeft,
    letterBloc,
    letterIndicator,
    childrenBlocTitle,
    childrenBlocSubTitle,
    tagContainer,
    tag,
    childrenBlocRight,
    childrenBlocDescription,
    childrenBlocDocument,
    childrenBlocDocumentLink,
    childrenBlocDocumentLabel, rootPage, letterIndicatorSmall, letterIndicatorBig, childContainer
} from "../../shared.style";
import config from "../../../../../temp/config";
import {renderLink} from "../../../../../utils/helper";

export interface FormItemRowProps {
    data: any,
    label?: string;
    key?: string,
    index: number,
}


export const FormItemRow: React.FunctionComponent<FormItemRowProps> = (props: FormItemRowProps): React.ReactElement => {
    const { data = [], key, index, label } = props;
    return (
        <div css={letterBloc} style={{backgroundColor: index % 2 == 0 ? '#ffffff' : '#F6F8F9'}}>
            <div css={childContainer}>
                <div css={letterIndicatorBig}>
                    { label }
                </div>
                {
                    data?.child?.map((data: any, index: number) => (
                        <div css={rootPage}>
                            <div css={letterIndicatorSmall}>
                                { data.label }
                            </div>
                            <div css={childrenBloc}>
                                <div css={childrenBlocLeft}>
                                    <div css={childrenBlocTitle}>
                                        { data.DisplayName }
                                    </div>
                                    <div css={childrenBlocSubTitle}>
                                        Categories
                                    </div>
                                    <div css={tagContainer}>
                                        {
                                            data && data.categories && data.categories.map((data: any, index: number) => (
                                                <div css={tag}  key={`tag-key-${index}`} >
                                                    { data }
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        data && data.thematics && data.thematics && data.thematics.length > 0 && (
                                            <>
                                                <div css={childrenBlocSubTitle}>
                                                    Thematics
                                                </div>
                                                <div css={tagContainer}>
                                                    {
                                                        data && data.thematics && data.thematics.map((thematic: any, index: number) => (
                                                            <div css={tag}  key={`tag-thematics-${index}`} >
                                                                { thematic }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        )}
                                </div>
                                <div css={childrenBlocRight}>
                                    <div css={childrenBlocDescription}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: data.description}}
                                        />
                                    </div>
                                    <div css={childrenBlocDocument}>
                                        <img src={renderLink(`${data.documentImage}`)} alt={"image"} />
                                    </div>
                                    <div css={childrenBlocDocumentLink} onClick={() => window && window.open(`${data['Document external link'] || data.document}`, '_blank').focus() }>
                                        <div css={childrenBlocDocumentLabel}>
                                            <Button label={ data.documentTitle } iconLeft={data?.isExternal ? null : 'file-pdf'} iconRight={data?.isExternal ? 'fa-arrow-up-right-from-square' as any : 'arrow-down'} wired={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
        </div>
    );
}
