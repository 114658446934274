import * as React from "react";

import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  RichText,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import { SitecoreGenericTextField } from "../../interfaces";
import hexagon from "../AdvantagesOfLgx/hexaBold.svg";
import { getLinkedMedia, renderLink } from "../../utils/helper";
import sync from "./sync.svg";
import tmpBg from "./background.png";
import { canUseDOM } from "../../predicates";

const lint = jsx; // linter hack
/** @jsx jsx */

export interface SustainableFinanceStandardsAndLabelsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

/* Disclaimer!
  This component has undergone so many modifications that should be split into several distinct components,
  it is currently difficult to maintain
*/

export const SustainableFinanceStandardsAndLabels: React.FunctionComponent<SustainableFinanceStandardsAndLabelsProps> =
  (props: SustainableFinanceStandardsAndLabelsProps): React.ReactElement => {
    const { fields } = props;

    const buttonLabel: string = getFieldValue<string>(
      fields,
      "buttonLabel",
      ""
    );
    const buttonLabel2: string = getFieldValue<string>(
      fields,
      "buttonLabel2",
      ""
    );
    const buttonLabel3: string = getFieldValue<string>(
      fields,
      "buttonLabel3",
      ""
    );
    const mailToSubject: string = getFieldValue<string>(
      fields,
      "mailToSubject",
      ""
    );
    const mailTo: string = getFieldValue<string>(fields, "mailTo", "");
    const buttonDownloadLink: any = getFieldValue<any>(
      fields,
      "downloadPath",
      ""
    );
    const buttonDownloadLink2: any = getFieldValue<any>(
      fields,
      "buttonDownloadLink2",
      ""
    );
    const buttonDownloadLink3: any = getFieldValue<any>(
      fields,
      "buttonDownloadLink3",
      ""
    );
    const buttonLink: string = getFieldValue<string>(fields, "buttonLink", "");
    const buttonLink2: string = getFieldValue<string>(
      fields,
      "buttonLink2",
      ""
    );
    const buttonLink3: string = getFieldValue<string>(
      fields,
      "buttonLink3",
      ""
    );
    const linkpath1: string = getFieldValue<string>(fields, "link path 1", "");
    const linkpath2: string = getFieldValue<string>(fields, "link path 2", "");
    const linkpath3: string = getFieldValue<string>(fields, "link path 3", "");
    const buttonIcon: string = getFieldValue<string>(fields, "buttonIcon", "");
    const buttonIcon2: string = getFieldValue<string>(
      fields,
      "buttonIcon2",
      ""
    );
    const buttonIcon3: string = getFieldValue<string>(
      fields,
      "buttonIcon3",
      ""
    );
    const youtubeVideoUrl: string = getFieldValue<string>(
      fields,
      "youtubeVideoUrl",
      ""
    );

    const gradient: any = Number(getFieldValue<number>(fields, "gradient", 0));
    const topGreyGradient: any = Number(
      getFieldValue<number>(fields, "topGreyGradient", 0)
    );
    const greyGradient: any = Number(
      getFieldValue<number>(fields, "greyGradient", 0)
    );
    const invert = Number(getFieldValue<number>(fields, "invert", 0));
    const isCardTopTitle = Number(
      getFieldValue<number>(fields, "cardTopTitle", 0)
    );
    const isCardTitle = Number(getFieldValue<number>(fields, "cardTitle", 0));
    const isCardSubTitle = Number(
      getFieldValue<number>(fields, "cardSubTitle", 0)
    );
    const isLargeVideo = Boolean(
      getFieldValue<boolean>(fields, "set large video", false)
    );
    const isLink = Number(getFieldValue<number>(fields, "link", 0));
    const isNude: boolean = getFieldValue<boolean>(fields, "nude", false);
    const isHalf: boolean = getFieldValue<boolean>(
      fields,
      "half column size",
      false
    );
    const isOnlyOneCol = Number(getFieldValue<number>(fields, "onlyOneCol", 0));
    const isWhiteText = Number(getFieldValue<number>(fields, "whiteText", 0));
    const isGreen = Number(getFieldValue<number>(fields, "isGreen", 0));
    const videoSourceUrl = (getFieldValue<any>(fields, "autoplay video video link", undefined));
    const isVerticallyAligned = Boolean(getFieldValue<boolean>(fields, "align vertically", false));

    const isDarkGreenBackground = Number(
      getFieldValue<number>(fields, "isDarkGreenBackground", 0)
    );
    const isGreenBackground = Number(
      getFieldValue<number>(fields, "isGreenBackground", 0)
    );
    const isHeadingTitle: string = getFieldValue<string>(
      fields,
      "headingTitle",
      ""
    );
    const anchorId: string = getFieldValue<string>(fields, "anchorId", "");

    const button1IsGray = Number(
      getFieldValue<number>(fields, "button 1 is Gray", 0)
    );
    const button2IsGray = Number(
      getFieldValue<number>(fields, "button 2 is Gray", 0)
    );
    const button3IsGray = Number(
      getFieldValue<number>(fields, "button 3 is Gray", 0)
    );
    const gradientApple = getFieldValue<boolean>(
      fields,
      "gradient apple",
      false
    );
    const gradientPeach = getFieldValue<boolean>(
      fields,
      "gradient apple",
      false
    );
    const gradientType = getFieldValue<any>(fields, "gradient color", null);
    const rounded = getFieldValue<boolean>(fields, "rounded component", false);
    const smallImage: any = getFieldValue<any>(fields, "small image", null);
    const [expended, setExpended] = React.useState(0);

    const isWhiteButton = () => {
      if (gradientType === "apple500" || gradientType === "peach500") {
        return "white";
      }
      return false;
    };
    const getBackground = () => {
      if (gradientType === "apple100") {
        return "linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)";
      }
      if (gradientType === "apple500") {
        return "linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)";
      }
      if (gradientType === "peach100") {
        return "linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)";
      }
      if (gradientType === "peach500") {
        return "linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)";
      }
      if (
        fields &&
        fields.componentBackground &&
        fields.componentBackground.value.src
      ) {
        return `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${fields.componentBackground.value.src}) no-repeat`;
      }
      if (isNude) {
        return "none";
      }
      if (topGreyGradient === 1) {
        return "linear-gradient(180deg, #D9E1E2 0%, #F6F9F9 100%)";
      }
      if (gradient !== 1) {
        if (greyGradient === 1) {
          return "linear-gradient(180deg, #253645 0%, #425563 100%)";
        } else {
          return "none";
        }
      }

      return "linear-gradient(270deg, #FFC300 6.1%, #FF3056 121.75%)";
    };

    const style = {
      parentContainer: css`
        width: 100%;
        max-width: ${rounded ? "1400px" : "100%"};
        padding-left: ${rounded ? "128px" : "0"};
        padding-right: ${rounded ? "128px" : "0"};
        margin-bottom: ${rounded ? "64px" : "0"};
        margin-top: ${rounded ? "64px" : "0"};
        margin-left: auto;
        margin-right: auto;

        @media (max-width: ${breakpointMax}) {
          padding-left: ${rounded ? "16px" : "0"};
          padding-right: ${rounded ? "16px" : "0"};
        }
      `,
      container: css`
        display: flex;
        flex-direction: column;
        border-radius: ${rounded ? "16px" : "0"};
        background: ${getBackground()};
        ${isGreenBackground && "background-color: #008C3D"};
        ${isDarkGreenBackground && "background-color: #005E2E"};

        a {
          color: #22aa5f;
          text-decoration: none;
        }

        background-size: cover;
        width: 100%;
        position: relative;
        padding-bottom: 40px;

        @media (max-width: ${breakpointMax}) {
          padding: 0;
          flex-direction: column;
        }
      `,
      mainContent: css`
        width: 100%;
        padding: 80px;
        padding-bottom: 0;
        max-width: 1280px;
        margin: auto;
        display: flex;
        @media (max-width: ${breakpointMax}) {
          padding: 0;
          flex-direction: column;
        }
      `,
      containerDouble: css`
        display: flex;
        width: 100%;
        flex-direction: ${invert === 1
          ? "row-reverse"
          : isLargeVideo
            ? "column-reverse"
            : "row"};
        @media (max-width: ${breakpointMax}) {
          padding: 0;
          flex-direction: column-reverse;
        }
      `,
      containerDoubleLeft: css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: ${isHalf ? "50%" : isLargeVideo ? "100%" : "50%"};
        padding-left: ${isLargeVideo ? "200px" : "40px"};
        padding-right: ${isLargeVideo ? "200px" : "40px"};
        @media (max-width: ${breakpointMax}) {
          width: 100%;
          padding-right: 16px;
          padding-left: 16px;
        }
      `,
      containerDoubleRight: css`
        display: flex;
        width: ${isHalf ? "50%" : isLargeVideo ? "100%" : "50%"};
        flex-direction: column;
        align-items: center;
        padding-left: 40px;
        padding-right: 40px;
        @media (max-width: ${breakpointMax}) {
          width: 100%;
          padding-top: 40px;
          padding-bottom: 40px;
          padding-left: 0;
          padding-right: 0;
        }
      `,
      cardParent: css`
        max-width: ${isCardTopTitle === 1 ? "350px" : " 100%"};
        width: ${isCardTopTitle === 1 ? "inherit" : "100% "};
        display: flex;
        align-items: ${isLargeVideo ? "center" : "flex-start"};
        justify-content: ${isLargeVideo ? "center" : "flex-start"};
        height: 100%;
        @media (max-width: ${breakpointMax}) {
          align-items: center;
          justify-content: center;
          display: flex;
        }
      `,
      hexaBackground: css`
        max-width: 380px;
        width: 100%;
        display: flex;
        align-items: center;
        @media (max-width: ${breakpointMax}) {
          width: 100%;
          padding-right: 16px;
          padding-left: 16px;
        }
      `,
      videoPart: css`
        display: flex;
        width: 100%;
        min-height: ${isLargeVideo ? "660px" : "300px"};
        max-width: ${isLargeVideo ? "1200px" : "600px"};
        margin-top: auto;
        margin-bottom: ${isLargeVideo ? "80px" : "auto"};
      `,
      cardParentImg: css`
        position: absolute;
        background-image: url(${renderLink(
          /*fields.rightImage && (fields.rightImage as any).value.src*/ tmpBg
      )});
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        max-height: 450px;
        background-position: bottom;
        bottom: 0;
        margin-left: 18%;
        width: 100%;
        @media (max-width: ${breakpointMax}) {
          position: relative;
          width: 100%;
          min-height: 260px;
          margin-left: 0;
        }
      `,
      containerDoubleTitle: css`
        color: ${isWhiteText ? "#FFFFFF" : "#425563"};
        ${isGreenBackground && "color: #cce8d8"};
        ${isDarkGreenBackground && "color: #cce8d8"};
        font-size: 3em;
        font-weight: 800;
        margin-bottom: 24px;

        @media (max-width: ${breakpointMax}) {
          font-weight: 800;
          font-size: 32px;
          line-height: 41px;
        }
      `,
      containerDoubleHeadingTitle: css`
        color: ${isWhiteText ? "#FFFFFF" : isGreen ? "#008C3D" : "#CA1234"};
        ${isGreenBackground && "color: #cce8d8"};
        ${isDarkGreenBackground && "color: #cce8d8"};
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        @media (max-width: ${breakpointMax}) {
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
        }
      `,
      containerDoubleSubTitle: css`
        color: ${isWhiteText ? "#FFFFFF" : "#425563"};
        ${isGreenBackground && "color: #cce8d8"};
        ${isDarkGreenBackground && "color: #cce8d8"};
        font-weight: 600;
        font-size: 1em;
        line-height: 150%;

        @media (max-width: ${breakpointMax}) {
          font-weight: 600;
          font-size: 14px;
          line-height: 180%;
          letter-spacing: 0.01em;
        }
      `,
      containerDoubleButton: css`
        margin-top: 2em;

        button {
          font-weight: 600;
          font-size: 16px;
        }
      `,
      blocs: css`
        display: flex;
        flex-direction: column;
      `,
      bloc: css`
        display: flex;
        border-radius: 8px;
        margin-bottom: 16px;
        padding: 16px;
        padding-left: 32px;
        padding-right: 32px;
        background: #ffffff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        :hover {
          background: #f0f5f5;
        }
      `,
      blocOpen: css`
        display: flex;
        border-radius: 8px;
        margin-bottom: 8px;
        padding: 16px;
        transition: all 0.3s ease-in-out;
        background: #f0f5f5;
      `,
      blocLeft: css`
        width: 32px;
        font-size: 16px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
        }
      `,
      blocLeftExpend: css`
        width: 32px;
        font-size: 16px;

        svg {
          margin-right: 8px;
        }
      `,
      blocRight: css`
        display: flex;
        flex-direction: column;
        width: calc(100% - 32px);
      `,
      blocRightTitle: css`
        color: #22aa5f;
        font-size: 16px;
        font-weight: 800;
        line-height: 20px;
      `,
      blocRightTitleGreen: css`
        color: #354450;
        font-size: 16px;
        font-weight: 800;
        line-height: 20px;
        margin-bottom: 8px;
      `,
      blocRightText: css`
        color: #354450;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.01em;
        margin-bottom: 8px;
        @media (max-width: ${breakpointMax}) {
          min-width: unset;
        }
      `,
      blocRightLink: css`
        color: #22aa5f;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-decoration: underline;
        cursor: pointer;
      `,
      cardContent: css`
        display: flex;
        flex-direction: column;
        padding: 2em;
      `,
      imgPart: css`
        display: flex;
        align-items: center;
        width: inherit;
        justify-content: center;

        img {
          max-width: 650px;
          margin-right: 100px;
          margin-bottom: 64px;
        }

        @media (max-width: ${breakpointMax}) {
          img {
            max-width: 100%;
            margin: 0;
          }
        }
      `,
      hexa: css`
        margin-bottom: 1em;
      `,
      cardTopTitle: css`
        font-weight: 600;
        color: #005e2e;
        font-size: 1em;
        margin-bottom: 1em;
      `,
      cardTitle: css`
        color: #22aa5f;
        font-weight: 800;
        font-size: 1.5em;
        margin-bottom: 2em;
      `,
      cardSubTitle: css`
        font-weight: 600;
        color: #005e2e;
        font-size: 1em;
      `,
      cardLink: css`
        color: #22aa5f;
        font-size: 1em;
        font-weight: 600;
        margin-top: 2em;
        text-decoration: underline;
      `,
      headImg: css`
        margin-bottom: 8px;

        img {
          height: 38px;
        }

        @media (max-width: ${breakpointMax}) {
          img {
            height: 30px;
          }
        }
      `,
      leftContainer: css`
        width: 100%;
        //max-width: 368px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 80px;
        position: relative;
        @media (max-width: ${breakpointMax}) {
          padding-bottom: 40px;
        }
      `,
      headerBigMenuItemLogo: css`
        width: 50px;
        margin-right: 2em;
        display: flex;

        svg {
          position: absolute;
          margin-left: 38px;
          margin-top: 35px;
          font-size: 2em;
          color: #22aa5f;
        }
      `,
      headerBigMenuItemLogoSvg: css`
        opacity: 0.2;
      `,
    };

    const triggerAction = (link1: string, link2: string, link3: any) => {
      if (canUseDOM) {
        if (link1) {
          window.open(renderLink(link1), "_self");
        } else if (link2) {
          window.open(renderLink(link2), "_self");
        } else if (link3) {
          window.open(renderLink(link3?.href));
        }
      }
    };

    const bannerRef = React.useRef(null);

    React.useEffect(() => {
      if (anchorId) {
        const element = bannerRef.current;
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [anchorId]);

    return (
      <div css={style.parentContainer} id={anchorId} ref={bannerRef}>
        <div css={style.container} id={anchorId}>
          <div css={style.mainContent}>
            <div css={style.containerDouble}>
              <div css={style.containerDoubleLeft}>
                <div css={style.leftContainer}>

                  {smallImage?.src && (
                    <div css={style.headImg}>
                      <img alt=" " src={renderLink(smallImage.src)} />
                    </div>
                  )}
                  {isHeadingTitle && (
                    <div css={style.containerDoubleHeadingTitle}>
                      <Text
                        field={
                          fields &&
                          (fields.headingTitle as SitecoreGenericTextField)
                        }
                      />
                    </div>
                  )}
                  <div css={style.containerDoubleTitle}>
                    <Text
                      field={
                        fields && (fields.title as SitecoreGenericTextField)
                      }
                    />
                  </div>
                  <div css={style.containerDoubleSubTitle}>
                    <RichText
                      field={
                        fields && (fields.subTitle as SitecoreGenericTextField)
                      }
                    />
                  </div>
                  {buttonLabel && !mailTo && (
                    <div css={style.containerDoubleButton}>
                      <Button
                        variant={
                          isWhiteButton()
                            ? "thirdary"
                            : !button1IsGray
                              ? "primary"
                              : "thirdary"
                        }
                        label={buttonLabel}
                        onClick={() =>
                          triggerAction(
                            buttonDownloadLink && buttonDownloadLink.src,
                            buttonLink,
                            linkpath1
                          )
                        }
                        iconRight={buttonIcon ? "" : "chevron-right"}
                        iconLeft={(buttonIcon as any)}
                      />
                    </div>
                  )}
                  {buttonLabel && mailTo && (
                    <div css={style.containerDoubleButton}>
                      <Button
                        variant={
                          isWhiteButton()
                            ? "thirdary"
                            : !button1IsGray
                              ? "primary"
                              : "secondary"
                        }
                        label={buttonLabel}
                        onClick={() =>
                          canUseDOM &&
                          window.open(
                            `mailto:${mailTo}?subject=${mailToSubject || "Request a demo"
                            }`
                          )
                        }
                        iconLeft={(buttonIcon as any) || "paper-plane"}
                      />
                    </div>
                  )}
                  {buttonLabel2 && (
                    <div css={style.containerDoubleButton}>
                      <Button
                        variant={
                          isWhiteButton()
                            ? "thirdary"
                            : !button2IsGray
                              ? "primary"
                              : "secondary"
                        }
                        label={buttonLabel2}
                        onClick={() =>
                          triggerAction(
                            buttonDownloadLink2?.src,
                            buttonLink2,
                            linkpath2
                          )
                        }
                        iconRight={buttonIcon2 ? "" : "chevron-right"}
                        iconLeft={(buttonIcon2 as any)}
                      />
                    </div>
                  )}
                  {buttonLabel3 && (
                    <div css={style.containerDoubleButton}>
                      <Button
                        variant={
                          isWhiteButton()
                            ? "thirdary"
                            : !button3IsGray
                              ? "primary"
                              : "secondary"
                        }
                        label={buttonLabel3}
                        onClick={() =>
                          triggerAction(
                            buttonDownloadLink3?.src,
                            buttonLink3,
                            linkpath3
                          )
                        }
                        iconRight={buttonIcon3 ? "" : "chevron-right"}
                        iconLeft={(buttonIcon3 as any)}
                      />
                    </div>
                  )}

                  <div css={style.blocs}>
                    {new Array(10).fill(0).map((arr: any, index: number) => {
                      if (
                        fields &&
                        fields[`titleCard${index + 1}`] &&
                        (fields && (fields[`titleCard${index + 1}`] as any))
                          .value
                      ) {
                        return (
                          <div
                            css={
                              expended === index ? style.blocOpen : style.bloc
                            }
                            key={`blocs-${index}`}
                            onClick={() => setExpended(index)}
                            style={{ minWidth: 480 }}
                          >
                            <div
                              css={
                                expended === index
                                  ? style.blocLeftExpend
                                  : style.blocLeft
                              }
                            >
                              {fields &&
                                fields[`logoCard${index + 1}`] &&
                                (
                                  fields &&
                                  (fields[`logoCard${index + 1}`] as any)
                                ).value && (
                                  <Icon
                                    icon={
                                      (
                                        fields &&
                                        (fields[`logoCard${index + 1}`] as any)
                                      ).value
                                    }
                                  />
                                )}
                            </div>
                            <div css={style.blocRight}>
                              <div
                                css={
                                  expended === index
                                    ? style.blocRightTitleGreen
                                    : style.blocRightTitle
                                }
                              >
                                <Text
                                  field={
                                    fields[
                                    `titleCard${index + 1}`
                                    ] as SitecoreGenericTextField
                                  }
                                />
                              </div>
                              {expended === index && (
                                <>
                                  <div css={style.blocRightText}>
                                    <RichText
                                      field={
                                        fields[
                                        `textCard${index + 1}`
                                        ] as SitecoreGenericTextField
                                      }
                                    />
                                  </div>
                                  <div
                                    css={style.blocRightLink}
                                    onClick={() =>
                                    (window.location.href =
                                      (fields &&
                                        fields[`linkPath${index + 1}`] &&
                                        (
                                          fields &&
                                          (fields[
                                            `linkPath${index + 1}`
                                          ] as any)
                                        ).value) ||
                                      "/")
                                    }
                                  >
                                    <RichText
                                      field={
                                        fields[
                                        `linkCard${index + 1}`
                                        ] as SitecoreGenericTextField
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
              <div css={style.containerDoubleRight} style={{ justifyContent: isVerticallyAligned ? "center" : "flex-start" }}>
                {!isOnlyOneCol && (
                  <>
                    {
                      videoSourceUrl?.src ? (
                        <video autoPlay muted loop={true} style={{ width: '100%', borderRadius: 16 }}>
                          <source src={renderLink(videoSourceUrl?.src)} />
                        </video>
                      ) : (
                        <>
                          {!isNude &&
                            youtubeVideoUrl === "" &&
                            !(
                              fields &&
                              fields.hexaBackground &&
                              (fields.hexaBackground as any).value &&
                              (fields && (fields.hexaBackground as any)).value.src &&
                              (fields && (fields.hexaBackground as any)).value.src !==
                              ""
                            ) && <div css={style.cardParentImg} />}
                          <div css={style.cardParent}>
                            {youtubeVideoUrl && youtubeVideoUrl !== "" ? (
                              <iframe
                                src={youtubeVideoUrl}
                                css={style.videoPart}
                                frameBorder="0"
                              />
                            ) : fields &&
                              fields.hexaBackground &&
                              (fields && (fields.hexaBackground as any)).value &&
                              (fields && (fields.hexaBackground as any)).value.src &&
                              (fields && (fields.hexaBackground as any)).value.src !==
                              "" ? (
                              <img
                                src={renderLink(
                                  (fields.hexaBackground as any).value.src
                                )}
                                css={style.hexaBackground}
                                alt={"image"}
                              />
                            ) : (
                              <>
                                {isNude ? (
                                  <div css={style.imgPart}>
                                    <img src={renderLink(sync)} alt={"image"} />
                                  </div>
                                ) : (
                                  <div css={style.cardContent}>
                                    {isCardTopTitle === 1 && (
                                      <>
                                        <div css={style.hexa}>
                                          <div css={style.headerBigMenuItemLogo}>
                                            <img
                                              src={renderLink(hexagon)}
                                              css={style.headerBigMenuItemLogoSvg}
                                              alt={"image"}
                                            />
                                            <Icon icon={"times"} />
                                          </div>
                                        </div>
                                        <div css={style.cardTopTitle}>
                                          <Text
                                            field={
                                              fields &&
                                              (fields.cardTopTitle as SitecoreGenericTextField)
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                    {isCardTitle === 1 && (
                                      <div css={style.cardTitle}>
                                        <Text
                                          field={
                                            fields &&
                                            (fields.cardTitle as SitecoreGenericTextField)
                                          }
                                        />
                                      </div>
                                    )}
                                    {isCardSubTitle === 1 && (
                                      <div css={style.cardSubTitle}>
                                        <Text
                                          field={
                                            fields &&
                                            (fields.cardSubTitle as SitecoreGenericTextField)
                                          }
                                        />
                                      </div>
                                    )}
                                    {isLink === 1 && (
                                      <div css={style.cardLink}>
                                        <Text
                                          field={
                                            fields &&
                                            (fields.link as SitecoreGenericTextField)
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      )
                    }

                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

