import React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import { Tr, Td } from "@bdl-cmn-shared-packages-npm/design-system";
import {marketNewsStyle} from "../MarketNews/index.style";
import {genericTradingDataStyle} from "../TradingData/Shared/generic-trading-data.style";
import PriceColumn from "../../UI/list/security/price";
import { computeCategoryTitle } from "../../UI/list/security/row";
import SearchSecurity from "../../../../interfaces/search/search-security";
import { goToSecurityDetailPage } from "../../SecurityCard";
import { formatDate } from "../../../../utils/date";

export const TradingNewsGenericRow = ({row}: {row: SearchSecurity}) => {
    if(!row)
        return <></>;

    return (
        <Tr onRowClick={() => goToSecurityDetailPage(row.isinCode, row.id)}>
            <Td>
                <div css={marketNewsStyle.status}>
                    <div css={marketNewsStyle.tag}>{computeCategoryTitle(row.category)}</div>
                </div>
            </Td>
            <Td>
                <div css={genericTradingDataStyle.title1}>{row?.name}</div>
                <div css={genericTradingDataStyle.title2}>{`${row?.isinCode} ${row?.currency}`}</div>
            </Td>
            <Td css={genericTradingDataStyle.title2}>{formatDate(row.listingDate)}</Td>
            <Td>
                <PriceColumn {...row?.marketData?.lastPrice} showChange={true} showDate={false}/>
            </Td>
        </Tr>
    );
}

export default TradingNewsGenericRow;
