import * as React from "react"
import { SVGProps, memo } from "react"
const XTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 15 14"
    {...props}
  >
    <path
      d="M11.255.875h2.08L8.791 6.064l5.346 7.061H9.952l-3.28-4.282-3.749 4.282H.84l4.86-5.55L.573.874h4.291l2.962 3.914L11.255.875Zm-.731 11.007h1.152l-7.438-9.83H3l7.524 9.83Z"
    />
  </svg>
)
const Memo = memo(XTwitter)
export default Memo
