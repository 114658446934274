
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import {ResourcesPress} from "../../core/components/Resources/ResourcesPress";

export interface PressRessourcesProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const PressRessources: React.FunctionComponent<PressRessourcesProps> = (props: PressRessourcesProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const isH1: boolean = getFieldValue<boolean>(fields, 'is H1', false);

    return (
        <ResourcesPress fields={fields} isH1={isH1} />
    );
}