import * as React from "react";

import {Table, Tbody} from "@bdl-cmn-shared-packages-npm/design-system";
import {css, jsx} from "@emotion/react";

import MarketTransfersRow from "./market-transfers-row.component";
import {MarketdataModel} from "../../market-data-view.component";

/** @jsx jsx */

export const MarketTransfersView = (props: any) => {
    const marketTransfers = props.marketTransfersData;
    return (
        <Table>
            <Tbody>
                {
                    marketTransfers?.currentData?.map(
                        (marketTransfersRow: MarketdataModel) => 
                            (<MarketTransfersRow marketTransfersRow={marketTransfersRow}/>)
                    )
                }
            </Tbody>
        </Table>
    )
}

export default MarketTransfersView;