import { breakpoint, breakpointMax } from '../../../../style';

import { css } from '@emotion/react';

export const style = {
  type: css`
    margin-top: 80px;
    margin-bottom: 80px;
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 41px;
      color: #354450;
    }
  `,
  sectionHeader: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    width: 100%;
    height: 425px;
    background: linear-gradient(180deg, #e8ecee 1.66%, #ffffff 121.3%);
    border: 1px solid #e8ecee;
    border-radius: 4px;
  `,
  programmeOverview: css`
    margin-top: -1em;
  `,
  bottomContainer: css`
    margin: 45px 120px;
    @media (max-width: ${breakpointMax}) {
      margin: 2em;
    }
  `,
  issuerContainer: css`
    width: 40%;
    h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 41px;
      color: #354450;
    }
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  overviewButton: css`
    margin-top: 1em;

    @media (max-width: ${breakpointMax}) {
      width: 100%;
      text-align: center;

      > button {
        margin: auto;
      }
    }
  `,
  mobileContainer: css`
    width: 100%;
    display: flex;
    flex-direction: column;

    > div {
      width: 100%;
    }
  `,
};
