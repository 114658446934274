
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const COL: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#CE1126" d="M512,339.863H0v75.034c0,4.875,3.953,8.828,8.828,8.828h494.345c4.875,0,8.828-3.953,8.828-8.828 L512,339.863L512,339.863z" />
            <path fill="#FCD116" d="M512,256.001H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,256.001L512,256.001z" />
            <rect y="256.001" fill="#003893" width="512" height="83.86" />
        </svg>
    </React.Fragment>
);
