import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { formatDate } from '../../../../../utils/date';
import style from './price.style';

export interface PriceColumnProps {
  amount?: number,
  marker?: string,
  currency?: string,
  changePercent?: number,
  date ?: string,
  color ?: string,
  isChart?: boolean,
  showDate?: boolean,
  showChange?: boolean
}
export default function PriceColumn({ 
    amount, 
    color, 
    marker, 
    currency, 
    changePercent, 
    date, 
    isChart, 
    showDate = true, 
    showChange = false,
  }: PriceColumnProps) {
  
    return amount ?
    <div
      css={style.container}
      style={{
        color: changePercent < 0
          ? "#CB1234"
          : "#22AA5F",
      }}
    >
      <div css={style.chevron}>
        {
          changePercent < 0 ? (
            <Icon icon={"chevron-down"} />
          ) : (
            <Icon icon={"chevron-up"} />
          )
        }
      </div>
      <div css={style.info}>
        <span>{`${amount} ${marker} ${currency === 'PERCENT' ? '%' : currency}`}</span>
        { showChange ? 
            <span css={style.change}>{changePercent ? changePercent : '-'}</span>
            : <></>
        }
        { showDate ? 
          !isChart ?
            date && <span css={style.dateGrey(color)}>{formatDate(date, "DD/MM/YY HH:mm:ss")}</span>
            : <span css={style.chartDate}>{formatDate(date, "DD/MM/YY HH:mm:ss")}</span>
          : <></>
        }
      </div>
    </div>
    : <>N/A</>

}