export enum StandardAndPrincipleForEligibleCategoryMapping {
	ICMA_GBP = 'ICMA_GBP',
	ICMA_SBP = 'ICMA_SBP',
	ICMA_SBG = 'ICMA_SBG',
	CBI = 'CBI',
	PBOC = 'PBOC',
	LGX_MAPPING_ICMA_GBP = 'LGX_MAPPING_ICMA_GBP',
	LGX_MAPPING_ICMA_SBP = 'LGX_MAPPING_ICMA_SBP',
	LGX_MAPPING_ICMA_SBG = 'LGX_MAPPING_ICMA_SBG',
}

export function standardAndPrincipleForEligibleCategoryLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(StandardAndPrincipleForEligibleCategoryMapping).indexOf(value.toString().toUpperCase());
		return index >= 0 ? Object.values(StandardAndPrincipleForEligibleCategoryMapping)[index] : value;
	}catch (e){
		console.error("Failed to get StandardAndPrincipleForEligibleCategoryMapping label for '" + value + "'", e);
	}
		
	return value;
}
