
import * as React from 'react';

import { User } from '../../../interfaces';

export interface AuthenticationContext {
    connected: boolean;
    user?: User;
}

export const rAuthenticationContext: React.Context<AuthenticationContext> = React.createContext<AuthenticationContext>({ connected: false });

export const useAuthentication = (): AuthenticationContext => {
    return React.useContext<AuthenticationContext>(rAuthenticationContext);
}

export const useAuthenticationProvider = (): AuthenticationContext => {
    return { connected: false };
}

export interface AuthenticationProviderProps {
    children: React.ReactNodeArray | React.ReactNode;
}

export const AuthenticationProvider: React.FunctionComponent<AuthenticationProviderProps> = (props: AuthenticationProviderProps): React.ReactElement => {
    const { children } = props;
    const authentication = useAuthenticationProvider();

    return (
        <rAuthenticationContext.Provider value={authentication}>
            {children}
        </rAuthenticationContext.Provider>
    )
}