/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { style } from './index.style';

export interface InformationProps {
  type: string;
}

export const Information = ({ type }: InformationProps) => {
  return (
    <div css={style.container}>
      {type}
    </div>
  );
};
