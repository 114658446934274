
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import {Button} from "@bdl-cmn-shared-packages-npm/design-system";

export interface TwoColumnsWithHexagonProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const action = css`
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  
  > button {
    margin-right: 32px;
  }
`;
const container = css`
  width: 100%;
  max-width: 1320px;
  padding-left: 60px;
  padding-right: 60px;
  margin: auto;
  display: flex;
`;
const left = css`
  width: 50%;
  display: flex;
  flex-direction: column;
`;
const right = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const title = css`
  color:#425563;
  font-weight: 800;
  font-size: 24px;
  line-height: 46px;
  margin-top: 24px;
  margin-bottom: 24px;
`;
const description = css`
  color:#425563;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
`;
const titleCard = css`
  color: #FFFFFF;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  margin-top: 24px;
  margin-bottom: 24px;
`;
const descriptionCard = css`
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
`;
export const TwoColumnsWithHexagon: React.FunctionComponent<TwoColumnsWithHexagonProps> = (props: TwoColumnsWithHexagonProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const background: any = getFieldValue<any>(fields, 'background', null);
    const btnLabel1: string = getFieldValue<string>(fields, 'btnLabel1', '');
    const btnLabel2: string = getFieldValue<string>(fields, 'btnLabel2', '');
    const btnLink1: string = getFieldValue<string>(fields, 'btnLink1', '');
    const btnLink2: string = getFieldValue<string>(fields, 'btnLink2', '');
    const btnCardLabel: string = getFieldValue<string>(fields, 'btnCardLabel', '');
    const btnCardLink: string = getFieldValue<string>(fields, 'btnCardLink', '');

    const parentContainer = css`
      width: 100%;
      margin: auto;
      padding-top: 96px;
      padding-bottom: 96px;
      background: #FFFFFF;
    `;
    const card = css`
      width: 100%;
      max-width: 480px;
      padding: 48px;
      border-radius: 8px;
      background-color: #ffffff;
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      background-image: radial-gradient(145.48% 145.48% at 35.36% 71.67%, rgba(0, 0, 0, 0) 0%, #1A2934 100%), url(${background && background.src});
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.3));
  `;
    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={left}>
                    <div css={title}><Text field={fields && fields.title as SitecoreGenericTextField}/></div>
                    <div css={description}><Text field={fields && fields.description as SitecoreGenericTextField}/></div>
                    <div css={action}>
                        <Button label={btnLabel1} onClick={() => btnLink1 ? window.location.href = btnLink1 : console.log} iconLeft={'box'}/>
                        <Button label={btnLabel2} variant={'thirdary'} onClick={() => btnLink2 ? window.location.href = btnLink2 : console.log} iconLeft={'box'}/>
                    </div>
                </div>
                <div css={right}>
                    <div css={card}>
                        <div css={titleCard}>
                            <Text
                                field={fields && fields.cardTitle as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={descriptionCard}>
                            <Text
                                field={fields && fields.cardDescription as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={action}>
                            <Button label={btnCardLabel} onClick={() => btnCardLink ? window.location.href = btnCardLink : console.log} iconRight={'chevron-right'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
