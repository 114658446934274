import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Row,
  Pagination,
  Tabs,
  Tab,
} from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from '../SecurityOverview/securityOverview.style';
import { useEffect, useMemo, useState } from 'react';
import { formatDate } from '../../../../utils/date';
import { formatToThousandsSeparator } from '../../../../utils';
import { BestOrder, BestOrders, Security } from '../../../../interfaces';
import { marketRuleCheck, MarketRuleEnum, marketRuleLabel, quotingConventionLabel } from '../../../../enums';
import { setSuspensionReason } from '../../../../graphql';
import { MarketDataGlobalInformation } from './market-data-information/global-information';
import { MarketDataSpreadInformation } from './market-data-information/spread-information';
import { MarketDataGeneralInformation } from './market-data-information/general-information';
import PricesHistory from './prices-history/prices-history';
import { SecurityMarketTrading } from './trading/trading';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export interface SecurityMarketProps {
  securityData?: Security;
}

export interface OrderBookProps {
  bid?: BestOrder;
  ask?: BestOrder;
}

export const SecurityMarketSol: React.FunctionComponent<SecurityMarketProps> = 
  ({ securityData }: SecurityMarketProps) => {

  const marketData = securityData?.marketData || null;

  useEffect(() => {

  }, [securityData]);


  return (
    <div css={style.securityOverview}>
      <div css={style.securityOverviewTop}>
        <div css={style.securityOverviewTopLeftStart}>
          <div css={style.flexRow}>
            <MarketDataGlobalInformation securityData={securityData} label={"Price history"}/>

            <PricesHistory pricesHistory={marketData?.pricesHistory} security={securityData} />
          </div>
        </div>
        <div css={style.securityOverviewTopRightStart}>
          <div css={style.flexRow}>
            <MarketDataGeneralInformation securityData={securityData} />
          </div>
        </div>
      </div>
    </div>
  );
};