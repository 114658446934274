import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Tr, Th, Thead } from '@bdl-cmn-shared-packages-npm/design-system';
import { data as dataMapper } from '../../../Search/results/dataMapper';

const thStyle = css`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: solid 1px #d9dee2;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 2em;
  padding-right: 2em;
  text-align: left;
`;

const DocumentHeader = ({ selectedCols }: any) => {
  return (
    <Thead>
      <Tr>
        <th
          css={[
            thStyle,
            css`
              width: 20%;
            `,
          ]}
        >
          Type
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 15%;
            `,
          ]}
        >
          Sub type
        </th>
        <th
          css={[
            thStyle,
            css`
              width: 30%;
            `,
          ]}
        >
          Name
        </th>
        <Th>Host member states</Th>

        <Th></Th>
        {selectedCols ? (
          selectedCols['documents'].map((col: string, index: number) => (
            <Th key={`col-${index}`}>{dataMapper['documents'][col].header}</Th>
          ))
        ) : (
          <></>
        )}
        <th
          css={[
            thStyle,
            css`
              width: 5%;
            `,
          ]}
        ></th>
      </Tr>
    </Thead>
  );
};

export default DocumentHeader;
