import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Security } from "../../../../interfaces";
import SecuritySustainableDataGreen from "./sustainaible-green";
import SecuritySustainableDataSlb from "./sustainable-slb";
import { GreenCard } from "../../../../interfaces/green-card";
import SecuritySustainableDataESGFund from "./sustainable-esg-funds";
import SecuritySustainableDataImpactFund from "./sustainable-impact-fund";

export interface SecuritySustainableDataProps {
    securityData: Security,
    greenCard: GreenCard,
}

const SecuritySustainableData = ({
    greenCard,
    securityData
}: SecuritySustainableDataProps) => {

    return (
        <>
            { securityData?.tags?.includes("RBDL_SLB") ?
                <SecuritySustainableDataSlb
                    greenCard={greenCard}
                    securityData={securityData}
                />
                :
              securityData?.tags?.includes("RBDL_ESG") || securityData?.tags?.includes("RBDL_ESG8") ?
                <SecuritySustainableDataESGFund
                    greenCard={greenCard}
                    securityData={securityData}
                />
                :
              securityData?.tags?.includes("RBDL_IMP9") ?
                <SecuritySustainableDataImpactFund
                    greenCard={greenCard}
                    securityData={securityData}
                />
                :
                <SecuritySustainableDataGreen 
                    greenCard={greenCard}
                    securityData={securityData}
                />
            }
        </>
    )
};

export default SecuritySustainableData;
