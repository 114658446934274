/** @jsx jsx */
import {jsx} from "@emotion/react";
import {MenuWithChild, Menu} from "@bdl-cmn-shared-packages-npm/design-system";
import {marketNewsStyle} from "../index.style";
import React, {useMemo} from "react";
import {computeLabel} from "../../../OAMSearch/column-filters/date-range-filter";
import {DocumentDateEnum} from "../../../../../enums/document-date";
import { getCurrentMoment } from "../../../../../utils/date";

export function CalendarFilterComponent(props: any) {
    const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        let found = false;
        let target = event.target as Element;
        const parent = event.currentTarget;
        const head = parent.firstChild.firstChild;

        do {
            if (head == target) found = true;

            target = target.parentElement;
        } while (!found && parent != target && target != null);

        if (found) parent.classList.toggle('open');
    };
    const options = useMemo(() => {
        const now = getCurrentMoment();
        return {
            [DocumentDateEnum.ONE_DAY_AGO]: {
                fromDate: now.subtract(1, 'd').startOf("day"),
                toDate: now.endOf("day"),
            },
            [DocumentDateEnum.ONE_WEEK_AGO]: {
                fromDate: now.subtract(7, 'd').startOf("day"),
                toDate: now.subtract(1, 'd').startOf("day")
            },
            [DocumentDateEnum.TWO_WEEKS_AGO]: {
                fromDate: now.subtract(14, 'd').startOf("day"),
                toDate: now.subtract(7, 'd').startOf("day")
            },
            [DocumentDateEnum.MORE_THAN_TWO_WEEKS]: {
                fromDate: null,
                toDate: now.subtract(14, 'd').startOf("day")
            }
        }
    }, []);

    return (
        <div css={marketNewsStyle.row} onClick={handleFilterMenuClick}>
            <MenuWithChild
                icon="calendar"
                label="Range date"
                width="100%;"
                expanded
                arrows={false}
            >
                <div css={[marketNewsStyle.menuItem, marketNewsStyle.menuItemRow]}>
                    {
                        Object.entries(options).map(([key, dateRange]) => {
                            return (
                                <div css={props.value === key && marketNewsStyle.childMenuSelected} key={`filter-calender-${key}`}>
                                    <Menu key={key}
                                          childLabel={computeLabel(key as DocumentDateEnum, "EN")}
                                          selected={props.value === key}
                                          onSelect={() => {props.handelClick(key)}}/>
                                </div>
                            )
                        })
                    }
                </div>
            </MenuWithChild>
        </div>
    );
}
