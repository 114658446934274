import * as React from 'react';

import { breakpoint, breakpointMax } from '../../style';

import { css } from '@emotion/react';
import hexa from './hexaBold.svg';
import { renderLink } from '../../utils/helper';

export const style = {
  container: css`
    width: 100%;
    //margin-top: 96px;
    position: relative;
    padding-bottom: 48px;
  `,
  containerBackground: css`
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 250px;
    padding-top: 48px;
    padding-bottom: 48px;
    background: linear-gradient(180deg, rgba(164, 188, 194, 0.3) 0%, rgba(164, 188, 194, 0) 100%);
  `,
  containerBackgroundHexa: css`
    background-image: url(${renderLink(hexa)});
    background-repeat: no-repeat;
    margin-top: -48px !important;
    background-size: 700px;
    background-position: center top;
    width: 100%;
    height: 360px;
    margin: auto;
    position: relative;
    opacity: 0.5;
  `,
  content: css`
    width: 100%;
    margin: auto;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: ${breakpointMax}) {
      padding: 16px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  `,
  contentTitle: css`
    width: 100%;
    color: #354450;
    text-align: center;
    font-size: 32px;
    justify-content: center;
    font-weight: 800;
    margin-bottom: 32px;
    margin-top: 16px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-size: 24px;
      line-height: 31px;
    }
  `,
  subContent: css`
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: ${breakpointMax}) {
      > div > div > div > div {
        > div:first-of-type {
          font-size: 16px;
          font-weight: 600;
          color: #354450;
        }
      }
    }
  `,
  linkContainer: css`
    width: 100%;
    max-width: 400px;
    background: #ffffff;
    border-radius: 8px;
    padding: 16px 24px;
    align-items: center;
    display: flex;
    cursor: pointer;
    :hover {
      background: #f6f9f9;
    }
    @media (max-width: ${breakpointMax}) {
      margin: auto
    }
  `,
  linkText: css`
    width: calc(100% - 60px);
    font-size: 16px;
    color: #425563;
    font-weight: 600;
    @media (max-width: ${breakpointMax}) {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
  linkIcon: css`
    width: 60px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    color: #22AA5F;
  `,
};
