export enum ProgrammeTypeEnum {
	WAR = 'Warrants programme',
	ECP = 'Euro commercial paper',
	AUTR = 'Other',
	CP = 'Commercial paper',
	MTN = 'Medium term note'
}

export function programmeTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(ProgrammeTypeEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(ProgrammeTypeEnum)[index] : value;
	}catch (e){
		console.error("Failed to get ProgrammeTypeEnum label for '" + value + "'", e);
	}
		
	return value;
}