/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  Text,
  Link,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  RichText,
} from "@sitecore-jss/sitecore-jss-react";
import { SitecoreGenericTextField } from "../../interfaces";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpointMax } from "../../style";

export interface BlogContactComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  margin-top: 30px;
  margin-bottom: 30px;

  h3 {
    font-weight: 800;
    font-size: 32px;
    color: #425563;
    @media (max-width: ${breakpointMax}) {
      font-size: 24px;
    }
  }

  h2 {
    font-weight: 800;
    font-size: 36px;
    color: #253845;
    @media (max-width: ${breakpointMax}) {
      font-size: 28px;
    }
  }

  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    h4 {
      font-weight: 800;
      font-size: 20px;
      line-height: 26px;
      @media (max-width: ${breakpointMax}) {
        font-size: 20px;
      }
    }

    h3 {
      font-weight: 800;
      font-size: 24px;
      line-height: 31px;
      color: #425563;
      @media (max-width: ${breakpointMax}) {
        font-size: 24px;
      }
    }

    h2 {
      font-weight: 800;
      font-size: 28px;
      line-height: 36px;
      color: #253845;
      @media (max-width: ${breakpointMax}) {
        font-size: 28px;
      }
    }
  }
`;

export const BlogContactComponent: React.FunctionComponent<BlogContactComponentProps> =
  (props: BlogContactComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const button1Label: string = getFieldValue<string>(
      fields,
      "button label",
      ""
    );
    const icon: string = getFieldValue<string>(fields, "button icon", "");

    return (
      <section css={container}>
        <RichText
          field={fields && (fields.content as SitecoreGenericTextField)}
        />
        {button1Label && (
          <Link
            target={
              fields["button link"]?.value?.linktype === "external"
                ? "_blank"
                : "_self"
            }
            title={fields["button label"]}
            field={fields["button link"]}
            css={{ textDecoration: "none" }}
          >
            <Button label={button1Label} iconRight={icon as any} />
          </Link>
        )}
      </section>
    );
  };
