/** @jsx jsx */

import {
  Pagination,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@bdl-cmn-shared-packages-npm/design-system";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { breakpoint, breakpointMax } from "../../../../style";
import { css, jsx } from "@emotion/react";

import { DepositCard } from "./deposit-card";
import { DepositRow } from "./deposit-row";
import EmptyResults from "../../UI/list/base/empty";
import { Loader } from "../../UI/Loader";
import { OAMResultsDeposit } from "../useFilters";
import OAMSearchHeaderEN from "./oam-search-header-en.component";
import OAMSearchHeaderFR from "./oam-search-header-fr.component";
import RoundTag from "../../RoundTag/roundTag.component";
import { useStyle } from "../../UI/list/base/useStyle";

const DEFAULT_PAGE_SIZE = 20;

interface OAMDocument {
  category: string;
  name: string;
  obsolete: boolean;
  publishDate: moment.Moment;
  size: number;
}

interface ResultRow {
  deposit: OAMResultsDeposit;
  open: boolean;
  documents?: OAMDocument[];
}

export interface OAMSearchTableProps {
  data?: OAMResultsDeposit[];
  density?: "low" | "medium" | "high";
  totalResultSize?: number;
  loadingPage?: boolean;
  lang?: string;
  mobile?: boolean;
}

export const OAMSearchTable = ({
  data,
  density,
  totalResultSize,
  loadingPage,
  lang,
  mobile,
}: OAMSearchTableProps) => {
  const style = useStyle(density);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    setPageNumber(0);
  }, [data]);

  const rows = useMemo(
    () => data?.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize) || [],
    [data, pageNumber, pageSize]
  );
  if (!data?.length) {
    return <EmptyResults 
              icon="filter"
              label="You have to type something or add filter" />;
  }

  return (
    <div css={style.table}>
      {mobile ? (
        <div
          css={css`
            padding: 2em;
          `}
        >
          {rows.map((deposit) => (
            <DepositCard deposit={deposit} key={deposit.idSoumis} />
          ))}
        </div>
      ) : (
        <Table>
          <Thead>
            {lang === "FR" ? <OAMSearchHeaderFR /> : <OAMSearchHeaderEN />}
          </Thead>
          <Tbody>
            {rows.map((deposit) => (
              <DepositRow deposit={deposit} key={deposit.idSoumis} lang={lang} />
            ))}
          </Tbody>
        </Table>
      )}

      {loadingPage && <Loader />}

      {totalResultSize > pageSize && (
        <div
          css={css`
            @media (max-width: ${breakpointMax}) {
              ul {
                columns: 2;
                padding: 0;
                display: block !important;
                li {
                  margin-bottom: 5px;
                }
              }
            }
          `}
        >
          <Pagination
            initialPage={0}
            onPageChange={(d: any) => {
              setPageNumber(d.selected);
            }}
            total={totalResultSize / pageSize > 200 ? 200 : totalResultSize / pageSize}
          />
        </div>
      )}
    </div>
  );
};
