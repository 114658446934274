/** @jsx jsx */

import * as React from 'react';

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { Button } from '@bdl-cmn-shared-packages-npm/design-system';
import { SitecoreGenericTextField } from '../../interfaces';
import { renderLink } from '../../utils/helper';
import wmap from './world.png';

export interface WorldMapProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
  @media (max-width: ${breakpointMax}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
const title = css`
  text-align: center;
  font-weight: 800;
  font-size: 48px;
  line-height: 61px;
  color: #354450;
  margin-top: 60px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
  }
`;
const description = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #354450;
  margin-top: 32px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    text-align: center;
    letter-spacing: 0.01em;
  }
`;
const action = css`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;
const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${breakpointMax}) {
    padding: 16px;
  }
`;

export const WorldMap: React.FunctionComponent<WorldMapProps> = (props: WorldMapProps): React.ReactElement => {
  const { fields, params, rendering } = props;
  const btnLabel: string = getFieldValue<string>(fields, 'button label', '');
  const btnLink: string = getFieldValue<string>(fields, 'button link', '');
  const background: any = getFieldValue<any>(fields, 'main image', null);
  const isRed: number = getFieldValue<number>(fields, 'red subtitle', 0);

  const worldMap = css`
    width: 100%;
    background-image: url(${renderLink((background && background.src) || wmap)});
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    min-height: 500px;
    @media (max-width: ${breakpointMax}) {
      background-size: 100%;
      min-height: 180px;
    }
  `;

  const subTitle = css`
    text-align: center;
    font-weight: 700;
    font-size: 48px;
    line-height: 61px;
    color: ${isRed ? '#CA1234' : '#008C3D'};
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
    }
  `;

  return (
    <div css={parentContainer}>
      <div css={container}>
        <div css={worldMap} />
        <div css={title}>
          <Text field={fields && (fields.title as SitecoreGenericTextField)} />
        </div>
        <div css={subTitle}>
          <Text field={fields && (fields.subTitle as SitecoreGenericTextField)} />
        </div>
        <div css={description}>
          <Text field={fields && (fields.description as SitecoreGenericTextField)} />
        </div>
        {btnLabel && (
          <div css={action}>
            <Button label={btnLabel} onClick={() => (btnLink ? (window.location.href = btnLink) : console.log())} />
          </div>
        )}
      </div>
    </div>
  );
};
