
/*
*   e-Listing - core - components - country flag icon - ita
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const ISL: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.334' style={{fill: '#0052B4'}} width='512' height='341.337'/>
        <polygon style={{fill: '#F0F0F0'}} points='512,295.883 202.195,295.883 202.195,426.666 183.652,426.666 140.978,426.666   122.435,426.666 122.435,295.883 0,295.883 0,277.329 0,234.666 0,216.111 122.435,216.111 122.435,85.329 140.978,85.329   183.652,85.329 202.195,85.329 202.195,216.111 512,216.111 512,234.666 512,277.329 '/>
        <polygon style={{fill: '#D80027'}} points='512,234.666 512,277.329 183.652,277.329 183.652,426.666 140.978,426.666 140.978,277.329   0,277.329 0,234.666 140.978,234.666 140.978,85.329 183.652,85.329 183.652,234.666 '/>
    </svg>
);
