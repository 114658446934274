/** @jsx jsx */
/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import { css, jsx } from "@emotion/react";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpoint, breakpointChartMax, breakpointMarketCapMax, breakpointMax } from "../../../../style";
import TopTradedSecurity from "./TopTradedSecurity";


export class TopTradedSecuritiesComponent extends Component<any, any> {

    render() {
        const tp=this.props.type;

        const typeName: string = tp + " performances";
        const share = this.props.topShare;
        const bond = this.props.topBond;
        const date: string = this.props.date;
        const description: string = this.props.title;

        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];

        const style = {
            date: css`
                font-weight:bold;
            `,
            parent: css`
            display:flex;
            flex-direction: column;
            margin: 0px auto;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            width:90%;
            height:100%;
            font-family: 'Mont';
            font-style: normal;
            >div:nth-child(5){
                width:100%;
                align-items: left;
                text-align:left;
                @media (max-width: ${breakpointMax}) {
                    align-items: center;
                    text-align:center;
                }
            }
            @media (max-width: ${breakpointMax}) {
                width:95%;
            }
            `,
            performace: css`
            font-weight:700;
            font-size:24px;
            line-height:31px;
            text-align:center;
            @media (max-width: ${breakpointMax}) {
                font-size: 20px;
                line-height: 26px;
            }
            `,
            bottomDescription: css`
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
            color: #425563;
            @media (max-width: ${breakpointChartMax}) {
                text-align:center;
                margin-bottom :0px;
                padding-bottom:0px;
            }
            `,
            borderBottom: css`
                display:flex;
                flex-direction: column;
                width:100%;
                border-top: 1px solid #D9DEE2;
            `,
        };

        function writeDate(date:string){
            if(tp==="Monthly"){
                const dt=date.split("/");
                return monthNames[Number(dt[0])-1]+" "+dt[1];
            }else{
                return date;
            }
        }

        return (
            <>
                <div css={style.parent}>

                    <div css={style.performace}>{typeName}</div>

                    <TopTradedSecurity type="Shares" data={share} />

                    <div css={style.borderBottom}></div>

                    <TopTradedSecurity type="Bonds" data={bond} />

                    <div css={style.bottomDescription}>{description}<span css={style.date}>{writeDate(date)}</span></div>

                </div>
            </>
        );
    }
}

export default TopTradedSecuritiesComponent;
