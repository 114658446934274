import { css } from "@emotion/react";

export const style = {
  bold: css`
    font-weight: bold
  `,
  openRow: css`
    background-color: #D3F2E1!important; 
  `,
  firstSubRow: css`
   box-shadow: inset 0 4px 4px rgba(0, 140, 61, 0.15);
   &:hover {
    box-shadow: inset 0 4px 4px rgba(0, 140, 61, 0.15)!important;
   }
  `,
  subRow: css`
    background-color: #E8F4F0!important;
  `,
  container: css`
    width: 100%;
  `,
  icon: css`
    font-size: 1.5rem;
    margin-right: 1rem;
  `,
  alignCenter: css`
   display: flex;
   align-items: center;
  `,
  data: css`
    color: #54666F;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > span:nth-child(2) {
      font-weight:bold;
    }
  `,
  clock: css`
    &::before, &::after {
      font-size: 4px;
      content: "";
      position: relative;
      left: 0.5rem;
      border-left: 2px solid black;
    }
    &::before {
      top: -1rem;
      border-radius: 32px 32px 0 0;
    }
    &::after {
      bottom: -0.5rem;
      left: -0.5rem;
      border-radius:  0 0 32px 32px;
    }
  `,
  level2: css`
    margin-left: 3.5rem;
  `,

  chevron: css`
    display: flex;
    justify-content: flex-end;
    font-size: 1.5rem;
    color: #22AA5F;

    > * {
      transition: transform 0.15s ease-out
    }
  `,
  openChevron: css`
    > * {
      transform: rotate(180deg)
    }
  `,
};
