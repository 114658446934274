/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import * as React from 'react';

import PreciousMetalsDetailsView
    from "../../core/components/DataMarket/TradingData/PreciousMetals/PreciousMetalsDetails";

export const PreciousMetalsList: React.FunctionComponent = (): React.ReactElement => {
    return (
        <PreciousMetalsDetailsView/>
    );
}
    