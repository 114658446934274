import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import Props from "../UI/list/base/props";
import { Tr, Td, Button } from "@bdl-cmn-shared-packages-npm/design-system";
import {rts27Style} from "./rts27-regulation-view.style";
import {BACKBOURSE_ENDPOINT} from "../../../backbourse";
import {formatDate} from "../../../utils/date";



export const RTS27Row = ({row}: Props<any>) => {
    return (
       <Tr>
           <Td>{row?.name}</Td>
           <Td>{formatDate(row?.date)}</Td>
           <Td css={rts27Style.button}>
               <Button
                   onClick={() => window.open(row?.downloadUrl, "_blank")}
                   iconRight='download'
                   label={"Download"}/>
           </Td>
       </Tr>

    );
};

export default RTS27Row;