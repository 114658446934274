
/*
*   e-Listing - core - components - country flag icon - fra
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const FRA: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.331' style={{fill:'#FFFFFF'}} width='512' height='341.337'/>
        <rect y='85.331' style={{fill: '#002654'}} width='170.663' height='341.337'/>
        <rect x='341.337' y='85.331' style={{fill: '#ED2939'}} width='170.663' height='341.337'/>
    </svg>
);
