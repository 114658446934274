import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { style } from "./tag.style";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";

export interface HexaBackgroundProps {
  icon?: any;
  customStyle?: any;
  label?: string;
  selected?: boolean;
  onClose?: () => void;
}

const Tag: React.FunctionComponent<HexaBackgroundProps> = ({
  icon,
  onClose,
  label,
  selected,
  customStyle,
}: HexaBackgroundProps) => {
  return (
    <div css={{ ...style.textTag(selected), ...customStyle,}}>
      <div css={style.textTagText}>{label}</div>
      {icon && (
        <div css={style.textTagIcon} onClick={onClose}>
          <Icon icon={icon} />
        </div>
      )}
    </div>
  );
};

export default Tag;
