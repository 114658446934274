import { css } from "@emotion/react";
import { breakpoint } from "../../../../../style";

export const style = {
  container: css`
    align-self: baseline;
    padding: 3rem;
    margin: 10px 100px;
    background-color: white;
    border: 1px solid #D9DEE2;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    
    @media (max-width: ${breakpoint}) {
      margin: 10px;
      padding: 2rem;
      gap: 0;
    }
  `,

  hexagon: css`
    position : relative;

    > img {
      width: 6rem;
    }
  `,
  icon: css`
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
  > * {
    font-size: 2rem;
    color: #008C46;
  }
   
  `,
  label: css`
    font-size: 20px;
    color: #253845;

    @media (max-width: ${breakpoint}) {
      font-size: 16px;
    }

  `,
  other: css`
    font-size: 20px;
    color: #253845;
  `,

};
