import * as React from 'react';

import { breakpoint, breakpointMax } from '../../../style';

import { css } from '@emotion/react';

export const style = {
  graphCard: css`
    position: relative;
    display: flex;
    width: 100%;
    height: 170px;
    align-items: center;
    border: solid 1px #e3e6e8;
    border-radius: 3px;
    :hover {
      div:last-child {
        div:first-child {
          background: linear-gradient(90deg, #f8fafc 45%, rgba(255, 255, 255, 0) 90%);
        }
      }
    }
  `,

  graphCardNoBorder: css`
    border: none;
    border-radius: 0px;
  `,

  
  graphCardNoMargin: css`
    margin-top: 0px;
  `,


  labels: css`
    display: flex;
    flex-direction: column;
    width: 33%;
    justify-content: center;
    margin-left: 5%;
    z-index: 100;
  `,
  labelsTop: css`
    color: #354450;
    font-weight: 800;
    font-size: 24px;
    @media (max-width: ${breakpointMax}) {
      font-size: 18px;
      line-height: 100%;
    }
  `,
  labelsBottom: css`
    color: #354450;
    font-weight: 800;
    font-size: 13px;
  `,
  data: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 100;
  `,
  dataFloating : css`
    position: absolute;
    top: 20px;
    left: 25%;
  `,
  dataTop: css`
    color: #22AA5F;
    font-weight: 600;
    font-size: 16px;
  `,
  dataBottom: css`
    color: #354450;
    font-weight: 600;
    font-size: 20px;
  `,
  bannerContentRightDataBottom: css``,
  
  charts: css`
    overflow: hidden;
    height: 167px;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    margin-left: 40%;
  `,
  chartsMaxHeight: css`
    height: 100%
  `,
  chartsNoLabel: css`
    width: 80%;
    margin-left: -20%;
  `,
  chartsFullWidth: css`
    width: 100%;
    margin-left: 0;
  `,
  chartGradient: css`
    background: linear-gradient(90deg, #ffffff 45%, rgba(255, 255, 255, 0) 90%);
    height: 167px;
    position: absolute;
    width: 150px;
    pointer-events: none;
    z-index: 90;
  `,
};
