import * as React from 'react';
/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {Td, Tr} from '@bdl-cmn-shared-packages-npm/design-system';
import {formatDate} from "../../../../utils/date";
import {marketNewsStyle} from "../MarketNews/index.style";
import {genericTradingDataStyle} from "../TradingData/Shared/generic-trading-data.style";
import { goToSecurityDetailPage } from '../../SecurityCard';
import PriceColumn from '../../UI/list/security/price';
import { AskColumn, BidColumn } from '../../UI/list/security/best-order';
import SearchSecurity from '../../../../interfaces/search/search-security';
import { computeCategoryTitle } from '../../UI/list/security/row';


export const TradingNewsBBORow = ({row}: {row: SearchSecurity}) => {
    if(!row)
        return <></>;

    return (
        <Tr onRowClick={() => goToSecurityDetailPage(row.isinCode, row.id)}>
            <Td>
                { row.category ?
                    <div css={marketNewsStyle.status}>
                        <div css={marketNewsStyle.tag}>{computeCategoryTitle(row.category)}</div>
                    </div>
                    : <></>
                }
            </Td>
            <Td>
                <div css={genericTradingDataStyle.title1}>{row.name}</div>
                <div css={genericTradingDataStyle.title2}>{row.isinCode} {row.currency}</div>
            </Td>
            <Td css={genericTradingDataStyle.title2}>{formatDate(row.listingDate)}</Td>
            <Td>
                <BidColumn bestOrders={row.marketRealtime?.bestOrders} />
            </Td>
            <Td>
                <AskColumn bestOrders={row.marketRealtime?.bestOrders} />
            </Td>
            <Td>
                <PriceColumn {...row?.marketData?.lastPrice} showChange={true} showDate={false}/>
            </Td>
        </Tr>
    );
}

export default TradingNewsBBORow;
