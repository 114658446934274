/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { sharedStyle } from '../../../../../styles/shared';
import { formatDate } from '../../../../../utils/date';
import RoundTag from '../../../RoundTag/roundTag.component';
import * as React from 'react';
import { Td, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import { genericTradingDataStyle } from '../Shared/generic-trading-data.style';
import { goToSecurityDetailPage } from '../../../SecurityCard';
import SearchSecurity from '../../../../../interfaces/search/search-security';
import { AskColumn, BidColumn } from '../../../UI/list/security/best-order';
import PriceColumn from '../../../UI/list/security/price';
import Type from '../../../UI/list/type';
import { checkClimateAligned, computeCategoryLabel, computeCategoryTitle } from '../../../UI/list/security/row';

export const SecuritiesBBORow = ({row}: {row: SearchSecurity}) => {
  if(!row)
      return <></>;

  return ( 
    <Tr css={sharedStyle.tableRow} 
        onRowClick={() => goToSecurityDetailPage(row.isinCode, row.id)}>
      <Td>
        <div css={sharedStyle.cardContainerBodyDoubleContainer}>
          <div css={sharedStyle.cardContainerBodyDoubleLeft}>
            <Type isGreen={row.lgxDisplay || checkClimateAligned( row.issuers)} 
                  title={computeCategoryTitle(row.category)} 
                  label={computeCategoryLabel(row.category)} />
          </div>
          <div css={sharedStyle.cardContainerBodyDoubleRight}>
            <div css={genericTradingDataStyle.title1}>{row.name}</div>
            <div css={genericTradingDataStyle.title2}>
              {row?.isinCode} {row?.currency}{' '}
            </div>
          </div>
        </div>
      </Td>
      <Td css={genericTradingDataStyle.title2}>{formatDate(row?.listingDate)}</Td>
      <Td>
        <BidColumn bestOrders={row?.marketRealtime?.bestOrders} />
      </Td>
      <Td>
        <AskColumn bestOrders={row?.marketRealtime?.bestOrders} />
      </Td>
      <Td>
        {row?.marketData?.lastPrice ? 
          <PriceColumn {...row.marketData?.lastPrice} showChange={true} showDate={false}/> 
          : 'N/A'}
      </Td>
    </Tr>
  );
}

export default SecuritiesBBORow;
