import * as React from 'react';
import { useEffect } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Row } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from '../SecurityOverview/securityOverview.style';
import { Security } from '../../../../interfaces';
import SecuritySustainableKeyDocuments from './keyDocuments';
import { GreenCard } from '../../../../interfaces/green-card';
import { DisclaimerComponent } from '../../../../sitecore-components/DisclaimerComponent';

export interface Row {
  label: string;
  action: () => void;
}

export interface SecuritySustainableDataProps {
  securityData: Security;
  greenCard: GreenCard;
}

const SecuritySustainableDataESGFund = ({ greenCard, securityData }: SecuritySustainableDataProps) => {
  useEffect(() => {}, [greenCard]);

  return (
    <div css={style.securityOverview}>
      <div css={style.securityOverviewTop}>
        <div css={style.securityOverviewTopLeftStart}>
          <div css={style.flexRow}>
            <div css={style.blocTitle}>Article 8 Fund</div>
            <div css={[style.blocRowSpace, style.blocRowSpaceNoBorderTop, style.blocRowSpaceFull]}>
              Article 8 applies "where a financial product promotes, among other characteristics, environmental or
              social characteristics, or a combination of those characteristics, provided that the companies in which
              the investments are made follow good governance practices".
            </div>
          </div>
        </div>
        <SecuritySustainableKeyDocuments
          postIssuanceDocs={greenCard?.postIssuanceDocs}
          preIssuanceDocs={greenCard?.preIssuanceDocs}
        />
      </div>

      <DisclaimerComponent
        fields={{
          title: { value: 'Disclaimer', editable: false },
          text: {
            value: `The SFDR classification displayed on this page (the “Data”) is the result of LGX’s understanding of the sub-fund’s classification based on the latest prospectus made available to LuxSE. Although whole or part of the Data may come from sources reasonably believed to be reliable, LuxSE does not give any representation or warranty, whether express or implied, and accepts no responsibility or liability of any kind in connection with the accuracy, correctness, fitness for a particular purpose, reliability or completeness of the Data. LuxSE does not accept any liability or responsibility of any kind for any loss or damages arising directly or indirectly from the use of, or any action refrained from or taken on any Data.<br><br>
            The Data may not be up-to-date, and users are encouraged to always refer to the latest prospectus made available by the issuer, for example on the issuer’s own website, to determine a sub-fund’s SFDR classification. The Data is not tailored for specific investors’ situations and may therefore not be suitable for all users.`,
            editable: false,
          },
        }}
      ></DisclaimerComponent>
    </div>
  );
};

export default SecuritySustainableDataESGFund;
