import * as React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { style } from "./index.style";
import { Document as NoticeSummaryModel } from "../../../../interfaces/document";
import { formatDate } from "../../../../utils/date";
import { documentTypeLabel } from "../../../../enums/document-type";

export interface NoticeCardProps {
  notice: NoticeSummaryModel;
}

export function NoticeSummaryCard({ notice }: NoticeCardProps) {
  return (
    <div css={style.noticeSummary}>
      <div>
        { notice.categories?.length
          ? notice.categories.map((category: string, index: number) => (
              <>
                {documentTypeLabel(category) && (
                  <div css={[style.tag, index > 0 && { marginTop: "5px" }]}>
                    {documentTypeLabel(category)}
                  </div>
                )}
              </>
            ))
          : "N/A"}
      </div>
      <div css={style.container}>
        <div css={style.name}>
          {notice.description}
        </div>
        <div css={style.info}>
          {notice.publishDate ? `${formatDate(notice.publishDate)} - ` : ""}
          {notice.complement}
        </div>
      </div>
    </div>
  );
}
