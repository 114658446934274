/** @jsx jsx */

import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Text,
  Link,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { Hexagon } from "../../core/components/Hexa/hexa.component";
import { SitecoreGenericTextField } from "../../interfaces";
import { renderLink } from "../../utils/helper";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { VerticalLineBottomLeft } from "../../core/components/Line/verticalLineBottomLeft.component";
import { DiagonalLineTopLeft } from "../../core/components/Line/diagonalLineTopLeft.component";
import DictionaryComponent from "../../core/components/Dictionary/DictionaryComponent";

export interface BackgroundContentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const lint = jsx; // linter hack

export const BackgroundContent: React.FunctionComponent<BackgroundContentProps> =
  (props: BackgroundContentProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    let hexaColor: any;
    let color: any;
    let lineColor;

    const hideFilter: boolean = getFieldValue<boolean>(
      fields,
      "hide filter",
      false
    );
    const hexaColorThemeGreen: number = getFieldValue<number>(
      fields,
      "green hexagon color theme",
      0
    );
    const smallImage: any = getFieldValue<any>(fields, "small image", null);
    const hexaColorThemeRed: number = getFieldValue<number>(
      fields,
      "red hexagon color theme",
      0
    );
    const hexaColorThemeGrey: number = getFieldValue<number>(
      fields,
      "grey hexagon color theme",
      0
    );
    const verticalLinePattern: number = getFieldValue<number>(
      fields,
      "verticalLinePattern",
      0
    );
    const diagonalLinePattern: number = getFieldValue<number>(
      fields,
      "diagonalLinePattern",
      0
    );

    const isH1: boolean = getFieldValue<boolean>(fields, "is H1", false);
    // tag={isH1 ? 'h1' : 'div'}

    if (hexaColorThemeGreen) {
      hexaColor = "green";
      lineColor = "#ffffff";
    } else if (hexaColorThemeRed) {
      hexaColor = "red";
      lineColor = "#ca1234";
    } else if (hexaColorThemeGrey) {
      hexaColor = "grey";
      lineColor = "#ca1234";
    } else {
      lineColor = "#ffffff";
    }
    const gradientType = getFieldValue<any>(fields, "gradient color", null);
    const buttonLeftLabel = getFieldValue<string>(
      fields,
      "button left label",
      ""
    );
    const buttonRigthLabel = getFieldValue<string>(
      fields,
      "button right label",
      ""
    );
    const greyButtonLeft = getFieldValue<boolean>(
      fields,
      "button left is grey",
      false
    );
    const buttonLeftIcon = getFieldValue<string>(
      fields,
      "button left icon",
      ""
    );
    const buttonRightIcon = getFieldValue<string>(
      fields,
      "button right icon",
      ""
    );
    const greyButtonRight = getFieldValue<boolean>(
      fields,
      "button right is grey",
      false
    );

    const background: any = getFieldValue<any>(fields, "background", null);

    const bg =
      fields && fields.background && fields.background.value.src
        ? `, url(${renderLink(background && background.src)}) no-repeat`
        : "";
    const getBackground = () => {
      if (bg && !gradientType) {
        return `url(${renderLink(background && background.src)}) no-repeat`;
      }

      if (bg && hideFilter) {
        return `url(${renderLink(background && background.src)})`;
      }

      if (bg) {
        if (gradientType === "apple100") {
          return `linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)${bg}`;
        }
        if (gradientType === "apple500") {
          return `linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)${bg}`;
        }
        if (gradientType === "gradientGreen") {
          return `linear-gradient(83deg, #166F3E 1.21%, rgba(46, 157, 71, 0.94) 41.77%, rgba(199, 214, 77, 0.75) 65.16%, rgba(199, 214, 77, 0.00) 90.12%)${bg}`;
        }
        if (gradientType === "peach100") {
          return `linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)${bg}`;
        }
        if (gradientType === "peach500") {
          return `linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)${bg}`;
        }
        if (gradientType === "gradientPeach") {
          return `linear-gradient(83deg, rgba(248, 159, 121, 0.60) 0.17%, rgba(248, 159, 121, 0.00) 100%)${bg}`;
        }
        if (gradientType === "gradientGrey") {
          return `linear-gradient(90deg, #253645 11.63%, rgba(37, 54, 69, 0) 85.53%)${bg}`;
        }
      }

      if (gradientType === "apple100") {
        return `linear-gradient(82deg, rgba(34, 170, 95, 0.20) 0%, rgba(199, 214, 77, 0.10) 53.76%)`;
      }
      if (gradientType === "apple500") {
        return `linear-gradient(82deg, #166F3E 0.08%, #018C45 31.62%, #C7D64D 99.36%)`;
      }
      if (gradientType === "gradientGreen") {
        return `linear-gradient(83deg, #166F3E 1.21%, rgba(46, 157, 71, 0.94) 41.77%, rgba(199, 214, 77, 0.75) 65.16%, rgba(199, 214, 77, 0.00) 90.12%)`;
      }
      if (gradientType === "peach100") {
        return `linear-gradient(263deg, rgba(248, 159, 121, 0.00) 0%, rgba(248, 159, 121, 0.60) 100%)`;
      }
      if (gradientType === "peach500") {
        return `linear-gradient(82deg, #A21228 0.08%, #CB2035 31.62%, #F89F79 99.36%)`;
      }
      if (gradientType === "gradientPeach") {
        return `linear-gradient(83deg, rgba(248, 159, 121, 0.60) 0.17%, rgba(248, 159, 121, 0.00) 100%)`;
      }
      if (gradientType === "gradientGrey") {
        return `linear-gradient(90deg, #253645 11.63%, rgba(37, 54, 69, 0) 85.53%)`;
      }
      return bg;
      
    };

    const getBackgroundMobile = () => {
      if (gradientType === "gradientGrey") {
        return `linear-gradient(90deg, #253645 11.63%, rgba(37, 54, 69, 0) 180.53%)${bg}`;
      }
    };

    const whiteButtonLeft = getFieldValue<boolean>(
      fields,
      "button left is white",
      false
    );

    const whiteButtonRight = getFieldValue<boolean>(
      fields,
      "button right is white",
      false
    );
    
    const isWhiteButton = () => {
      if (whiteButtonLeft === true || whiteButtonRight === true) {
        return "white";
      }
      return false;
    };

    const greenText: number = getFieldValue<number>(
      fields,
      "greenText",
      0
    );

    const style = {
      container: css`
        display: flex;
        background: ${getBackground()};
        background-size: cover;
        width: 100%;
        padding: 2em 2em 6em;
        position: relative;
        overflow: hidden;

        @media (max-width: ${breakpointMax}) {
          margin: 0;
          padding-top: 64px;
          padding-bottom: 64px;
          background-position: unset;
          background: ${getBackgroundMobile()};
          background-size: cover;
          background-position: center;
        }
      `,
      hexaBg: css`
        position: absolute;
        margin-left: ${diagonalLinePattern ? "-29px" : hexaColor ? "-150px" : "-36px"	};
        top: ${diagonalLinePattern ? "1px" : hexaColor ? "none" : "67px"};
        bottom: ${hexaColor ? "-10px" : "0"};
        z-index: 0;
        @media (max-width: ${breakpointMax}) {
          display: none;
        }
      `,
      centeredContainer: css`
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        padding-left: 40px;
        padding-right: 40px;
        margin: auto;
        position: relative;

        @media (max-width: ${breakpointMax}) {
          margin-left: auto;
          margin-right: auto;
        }
      `,
      left: css`
        display: flex;
        flex-direction: column;
        width: 50%;

        @media (max-width: ${breakpointMax}) {
          width: 100%;
          padding-top: 64px;
          padding-bottom: 16px;
          text-align: center;
        }
      `,
      right: css`
        display: flex;
        width: 50%;
        @media (max-width: ${breakpointMax}) {
          display: none;
        }
      `,
      headTitle: css`
        color: ${greenText ? "#22AA5F" : "#ffffff"};
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        @media (max-width: ${breakpointMax}) {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
        }
      `,
      headImg: css`
        margin-bottom: 8px;

        img {
          height: 38px;
        }

        @media (max-width: ${breakpointMax}) {
          img {
            height: 30px;
          }
        }
      `,
      title: css`
        font-weight: 800;
        font-size: 48px;
        line-height: 61px;
        color: ${greenText ? "#22AA5F" : "#ffffff"};
        position: relative;
        z-index: 10;
        @media (max-width: ${breakpointMax}) {
          font-weight: 700;
          font-size: 32px;
          line-height: 130%;
          margin-top: 16px;
        }

        h1 {
          font-weight: 800;
          font-size: 48px;
          line-height: 61px;
          color: #ffffff;
          position: relative;
          z-index: 10;
          @media (max-width: ${breakpointMax}) {
            font-weight: 700;
            font-size: 32px;
            line-height: 130%;
            margin-top: 16px;
          }
        }
      `,
      description: css`
        padding-top: 24px;
        color: ${greenText ? "#22AA5F" : "#ffffff"};
        line-height: 30px;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.01em;
        position: relative;
        z-index: 10;

        @media (max-width: ${breakpointMax}) {
          font-weight: 400;
          font-size: 14px;
          line-height: 180%;
          margin-top: 16px;
          padding-top: 0;
        }
      `,
      verticalLine: css`
        width: 0px;
        height: 72px;
        position: relative;
        top: -30px;
        @media (max-width: ${breakpointMax}) {
          display: none;
        }
      `,
      buttons: css`
        display: flex;
        gap: 16px;
        margin-top: 48px;
      `,
    };

    return (
      <div css={style.container}>
        {hexaColor ? (
          <div css={style.hexaBg}>
          <Hexagon
            size={{ width: "900px", height: "384px" }}
            theme={hexaColor}
          />
          </div>
        ) : verticalLinePattern ? (
          <div css={style.hexaBg}>
            <VerticalLineBottomLeft
              // size={{ width: "900px", height: "384px" }}
            />
          </div>
        ) : diagonalLinePattern ? (
          <div css={style.hexaBg}>
            <DiagonalLineTopLeft>
              {/* size={{ width: "900px", height: "384px" }} */}
            </DiagonalLineTopLeft>
          </div>
        ) : null}
        <div css={style.centeredContainer}>
          <div
            css={[
              style.verticalLine,
              css`
                border: solid 1px ${lineColor} !important;
              `,
            ]}
          />
          <div css={style.left}>
            {smallImage?.src && (
              <div css={style.headImg}>
                <img alt=" " src={renderLink(smallImage.src)} />
              </div>
            )}
            {fields.headTitle && (
              <div css={style.headTitle}>
                <Text
                  field={
                    fields && (fields.headTitle as SitecoreGenericTextField)
                  }
                />
              </div>
            )}
            <div css={style.title}>
              <Text
                tag={isH1 ? "h1" : "div"}
                field={fields && (fields.title as SitecoreGenericTextField)}
              />
            </div>
            <div css={style.description}>
              <DictionaryComponent text={fields.description.value} />
            </div>
            <div css={style.buttons}>
              {(fields?.["button left"]?.value?.href ||
                fields?.["button left"]?.value?.src) && (
                  <Link
                    css={{ textDecoration: "none" }}
                    target={
                      fields["button left"]?.value?.linktype === "external"
                        ? "_blank"
                        : "_self"
                    }
                    title={buttonLeftLabel || " "}
                    field={fields["button left"]}
                  >
                    <Button
                      label={buttonLeftLabel}
                      variant={(greyButtonLeft) ? "secondary" : isWhiteButton() ? "thirdary" : "primary"}
                      iconRight={buttonLeftIcon as any}
                    />
                  </Link>
                )}
              {(fields?.["button right"]?.value?.href ||
                fields?.["button right"]?.value?.src) && (
                  <Link
                    css={{ textDecoration: "none" }}
                    target={
                      fields["button right"]?.value?.linktype === "external"
                        ? "_blank"
                        : "_self"
                    }
                    title={buttonRigthLabel || " "}
                    field={fields["button right"]}
                  >
                    <Button
                      label={buttonRigthLabel}
                      variant={greyButtonRight ? "secondary" : isWhiteButton() ? "thirdary" : "primary"}
                      iconRight={buttonRightIcon as any}
                    />
                  </Link>
                )}
            </div>
          </div>
          <div css={style.right} />
        </div>
      </div>
    );
  };
