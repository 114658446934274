import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Pagination,
  Tabs,
  Tab,
} from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from '../SecurityOverview/securityOverview.style';
import { useEffect, useMemo, useState } from 'react';
import { formatDate, formatDateTime } from '../../../../utils/date';
import { BestOrder, BestOrders, Security } from '../../../../interfaces';
import { MarketDataGlobalInformation } from './market-data-information/global-information';
import { MarketDataSpreadInformation } from './market-data-information/spread-information';
import { MarketDataGeneralInformation } from './market-data-information/general-information';
import PricesHistory from './prices-history/prices-history';
import { SecurityMarketTrading } from './trading/trading';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export interface SecurityMarketProps {
  securityData?: Security;
}

export interface OrderBookProps {
  bid?: BestOrder;
  ask?: BestOrder;
}

export const SecurityMarketDefault: React.FunctionComponent<SecurityMarketProps> = 
  ({ securityData }: SecurityMarketProps) => {

  const marketData = securityData?.marketData || null;
  const marketDataRealTime = securityData?.marketRealtime || null;
  const bestOrders: BestOrders = marketDataRealTime?.bestOrders;

  const [intradayAll, setAllIntraday] = useState<any[]>([]);
  const [intradayPageNumber, setIntradayPageNumber] = useState<number>(0);
  const intradayItemPerPage = 10;
  const [intraday, setIntraday] = useState<any[]>([]);

  const [closingPricesAll, setAllClosingPrices] = useState<any[]>([]);
  const [closingPricesPageNumber, setClosingPricesPageNumber] = useState<number>(0);
  const closingPricesItemPerPage = 10;
  const [closingPrices, setClosingPrices] = useState<any[]>([]);

  const [orderBooks, setOrderBooks] = useState<OrderBookProps[]>([]);

  const onPaginationUpdate = (pageNumber: number, type: string) => {
    if (type == 'intraday') setIntradayPageNumber(pageNumber);
    if (type == 'closingPrices') setClosingPricesPageNumber(pageNumber);
  };

  const paginationCheck = (data: any, itemPerPage: number) => {
    if (!data || data.length < 1) return false;

    return data.length > itemPerPage;
  };

  const paginationTotal = (data: any, itemPerPage: number, maxNumberPage: number) => {
    if (!data || data.length < 1 || itemPerPage == 0) return 0;

    const numberPage = data.length / itemPerPage;

    return maxNumberPage == null || numberPage <= maxNumberPage ? numberPage : maxNumberPage;
  };

  function createPage(type: string): any {
    let data = null;
    let currentPageNumber = 0;
    let itemPerPage = 0;

    if (type === 'intraday') {
      data = intradayAll;
      currentPageNumber = intradayPageNumber;
      itemPerPage = intradayItemPerPage;
    }

    if (type === 'closingPrices') {
      data = closingPricesAll;
      currentPageNumber = closingPricesPageNumber;
      itemPerPage = closingPricesItemPerPage;
    }

    if (!data || !data.length) return [];

    if (data?.length > itemPerPage) {
      let startIndex = 0;
      let endIndex = itemPerPage;

      if (currentPageNumber > 0) {
        startIndex = currentPageNumber * itemPerPage;
        endIndex = startIndex + itemPerPage;
        if (endIndex > data?.length) endIndex = data.length;
      }

      data = data?.slice(startIndex, endIndex);
    }

    return data;
  }

  useEffect(() => {
    const tmpOrderBooks: OrderBookProps[] = [];
    let tmpClosingPrices: any = [];
    let tmpIntraday: any = [];

    if (bestOrders) {
      const max = bestOrders.bid?.length > bestOrders.ask?.length ? bestOrders.bid?.length : bestOrders.ask?.length;

      if (max > 0 )
        for( let i = 0; i < max; i++){
          const orderBook : OrderBookProps = {
            ask: null,
            bid: null
          };

          if( bestOrders.bid?.length >= (i + 1) && bestOrders.bid[i] )
            orderBook.bid = bestOrders.bid[i];

          if( bestOrders.ask?.length >= (i + 1) && bestOrders.ask[i] )
            orderBook.ask = bestOrders.ask[i];

          if( ( orderBook?.ask || orderBook?.bid ) && tmpOrderBooks.length < 5)
            tmpOrderBooks.push(orderBook);
        }
    }
    setOrderBooks(tmpOrderBooks);

    if (marketData?.pricesHistory) {
      marketData.pricesHistory.forEach((price: any, index: number) =>
        tmpClosingPrices.push({
          date: formatDate(price.date, 'DD/MM/YYYY'),
          price: price.amount,
          marker: price.marker,
          currency: price.currency === 'PERCENT' ? '%' : price.currency,
          volume: price.dayVolume,
        })
      );
      tmpClosingPrices = tmpClosingPrices.reverse();
    }
    setAllClosingPrices(tmpClosingPrices);
    setClosingPrices(tmpClosingPrices?.slice(0, closingPricesItemPerPage));

    if (marketData?.intradayPrices) {
      marketData.intradayPrices.forEach((price: any) =>
        tmpIntraday.push({
          date: formatDateTime(price.date),
          price: price.amount,
          marker: price.marker,
          currency: price.currency === 'PERCENT' ? '%' : price.currency,
          volume: price.volume,
        })
      );
      tmpIntraday = tmpIntraday.reverse();
    }
    setAllIntraday(tmpIntraday);
    setIntraday(tmpIntraday?.slice(0, intradayItemPerPage));
  }, [securityData]);

  useMemo(() => {
    setIntraday(createPage('intraday'));
  }, [intradayPageNumber]);

  useMemo(() => {
    setClosingPrices(createPage('closingPrices'));
  }, [closingPricesPageNumber]);


  enum ETabs {
    CENTRAL_ORDER_BOOK = 'CENTRAL_ORDER_BOOK',
    INTRADAY = 'INTRADAY',
    HISTORICAL_CLOSING_PRICES = 'HISTORICAL_CLOSING_PRICES',
  }

  const [selectedTab, setSelectedTab] = useState<ETabs>(ETabs.CENTRAL_ORDER_BOOK);

  return (
    <div css={style.securityOverview}>
      <div css={style.securityOverviewTop}>
        <div css={style.securityOverviewTopLeftStart}>
          <div css={style.flexRow}>
            <MarketDataGlobalInformation securityData={securityData} />
            <MarketDataSpreadInformation securityData={securityData} />

            <PricesHistory pricesHistory={securityData?.marketData?.pricesHistory} security={securityData} />
            <div css={style.paddingTop}>
              <MarketDataGeneralInformation securityData={securityData} />
            </div>
          </div>
        </div>
        
        <SecurityMarketTrading securityData={securityData}/>
      </div>
      <div
        css={[
          style.securityOverviewTop,
          css`
            margin-top: 0;
             
            > div > div:first-of-type {
              > div {
                border: 1px solid #D9DEE2;
              }
            }
          `,
        ]}
      >
        <Tabs tabPosition={'left'} padding={'1em 2em'}>
          <Tab
            label='Central order book'
            selected={selectedTab === ETabs.CENTRAL_ORDER_BOOK}
            onClickAction={() => setSelectedTab(ETabs.CENTRAL_ORDER_BOOK)}
            onTabChange={() => setSelectedTab(ETabs.CENTRAL_ORDER_BOOK)}
          >
            <>
              <div css={style.desktopData}>
                <Table density='high'>
                  <Thead>
                    <Tr>
                      <Th>Number</Th>
                      <Th>Quantity</Th>
                      <Th>Bid</Th>
                      <Th>Ask</Th>
                      <Th>Quantity</Th>
                      <Th>Number</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    { orderBooks?.length > 0 ?
                      <>
                        { orderBooks.map((data: OrderBookProps, index: number) => (
                            <Tr key={`orderBooks-${index}`}>
                              <Td>{data.bid?.number || '-'}</Td>
                              <Td>{data.bid?.quantity || '-'}</Td>
                              <Td>{data.bid?.price || '-'}</Td>
                              <Td>{data.ask?.price || '-'}</Td>
                              <Td>{data.ask?.quantity || '-'}</Td>
                              <Td>{data.ask?.number || '-'}</Td>
                            </Tr>
                          ))}
                      </>
                      : 
                      <Tr key="orderBooks-0">
                        <Td>-</Td>
                        <Td>-</Td>
                        <Td>-</Td>
                        <Td>-</Td>
                        <Td>-</Td>
                        <Td>-</Td>
                      </Tr>
                    }
                  </Tbody>
                </Table>
              </div>
              <div css={style.mobileData}>
                <div css={style.mobileDataTitles}>
                  <span>Bid</span>
                  <span>Ask</span>
                </div>
                <div css={style.mobileDataContainer}>
                  { orderBooks?.length > 0 ?
                    <>
                      { orderBooks.map( (data: OrderBookProps, index: number) => (
                        <div key={`orderBooks-m-${index}`} css={style.mobileDataContainerRow}>
                          <div css={style.mobileDataContainerRowSide}>
                            <div css={style.mobileDataContainerRowSideSmall}>{data.bid?.number}</div>
                            <div css={style.mobileDataContainerRowSideLargeLeft}>
                              <div css={style.mobileDataContainerRowSideLargeTop}>{data.bid?.price}</div>
                              <div css={style.mobileDataContainerRowSideLargeBottom}>{data.bid?.quantity}</div>
                            </div>
                          </div>
                          <div css={style.mobileDataContainerRowSide}>
                            <div css={style.mobileDataContainerRowSideLargeRight}>
                              <div css={style.mobileDataContainerRowSideLargeTop}>{data.ask?.price}</div>
                              <div css={style.mobileDataContainerRowSideLargeBottom}>{data.ask?.quantity}</div>
                            </div>
                            <div css={style.mobileDataContainerRowSideSmall}>{data.ask?.number}</div>
                          </div>
                        </div>
                      ))}
                    </>
                    : 
                    <div key="orderBooks-m-0" css={style.mobileDataContainerRow}>
                      <div css={style.mobileDataContainerRowSide}>
                        <div css={style.mobileDataContainerRowSideSmall}>-</div>
                        <div css={style.mobileDataContainerRowSideLargeLeft}>
                          <div css={style.mobileDataContainerRowSideLargeTop}>-</div>
                          <div css={style.mobileDataContainerRowSideLargeBottom}>-</div>
                        </div>
                      </div>
                      <div css={style.mobileDataContainerRowSide}>
                        <div css={style.mobileDataContainerRowSideLargeRight}>
                          <div css={style.mobileDataContainerRowSideLargeTop}>-</div>
                          <div css={style.mobileDataContainerRowSideLargeBottom}>-</div>
                        </div>
                        <div css={style.mobileDataContainerRowSideSmall}>-</div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </>
          </Tab>

          <Tab
            label='Intraday'
            selected={selectedTab === ETabs.INTRADAY}
            onClickAction={() => setSelectedTab(ETabs.INTRADAY)}
            onTabChange={() => setSelectedTab(ETabs.INTRADAY)}
          >
            <div>
              <Table density='medium'>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Price</Th>
                    <Th>Volume</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {intraday && intraday.length > 0 ? (
                    <>
                      {intraday &&
                        intraday.map((data: any, index: number) => (
                          <Tr key={`intraday-${index}`}>
                            <Td>{data.date}</Td>
                            <Td>{`${data.price} ${data.marker} ${data.currency}`}</Td>
                            <Td>{data.volume ? data.volume : "-"}</Td>
                          </Tr>
                        ))}
                    </>
                  ) : (
                    <Tr key="intraday-0">
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>-</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
              {paginationCheck(intradayAll, intradayItemPerPage) ? (
                <div>
                  <Pagination
                    initialPage={0}
                    onPageChange={(d: any) => {
                      onPaginationUpdate(d.selected, 'intraday');
                    }}
                    total={paginationTotal(intradayAll, intradayItemPerPage, 500)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </Tab>

          <Tab
            label='Historical closing prices'
            selected={selectedTab === ETabs.HISTORICAL_CLOSING_PRICES}
            onClickAction={() => setSelectedTab(ETabs.HISTORICAL_CLOSING_PRICES)}
            onTabChange={() => setSelectedTab(ETabs.HISTORICAL_CLOSING_PRICES)}
          >
            <div>
              <Table density='medium'>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Price</Th>
                    <Th>Volume</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {closingPrices && closingPrices.length > 0 ? (
                    <>
                      {closingPrices.map((data: any, index: number) => (
                        <Tr key={`closingPrices-${index}`}>
                          <Td>{data.date}</Td>
                          <Td>{`${data.price} ${data.marker} ${data.currency}`}</Td>
                          <Td>{data.volume ? data.volume : "-"}</Td>
                        </Tr>
                      ))}
                    </>
                  ) : (
                    <Tr key="closingPrices-0">
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>-</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
              {paginationCheck(closingPricesAll, closingPricesItemPerPage) ? (
                <div>
                  <Pagination
                    initialPage={0}
                    onPageChange={(d: any) => {
                      onPaginationUpdate(d.selected, 'closingPrices');
                    }}
                    total={paginationTotal(closingPricesAll, closingPricesItemPerPage, 500)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
