import React, {Component} from 'react';
/** @jsx jsx */
import {css,jsx} from '@emotion/react';
import { Table, Td, Th, Thead, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import PreciousMetalsDetailsRow from "./row";
import { PreciousMetal } from '../../../../../../interfaces/precious-metal';

export const PreciousMetalsDetailsTable = ({data}: {data: PreciousMetal[]}) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Type</Th>
          <Th>Name</Th>
          <Th>Last</Th>
          <Th>Change</Th>
          <Th>Month Hi / Lo</Th>
          <Th>Year Hi / Lo</Th>
        </Tr>
      </Thead>
      <tbody>
        { data?.length > 0? 
            data.map((item: PreciousMetal) => <PreciousMetalsDetailsRow row={item} key={`precious-metal-row-${item?.id}`}/>)
          : 
            <Tr>
              <Td>-</Td>
              <Td>-</Td>
              <Td>-</Td>
              <Td>-</Td>
              <Td>-</Td>
              <Td>-</Td>
            </Tr>
        }
      </tbody>
    </Table>
  );
};
export default PreciousMetalsDetailsTable;
