
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface QualityEducationSvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const QualityEducationSvg: React.FunctionComponent<QualityEducationSvgProps> = ({ size: { width, height }, style }: QualityEducationSvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 42 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>4 - Quality Education</title>
            <path d='M4.39968 13.1252H6.32735V11.164H7.14763V9.57565H6.32735V1.02979H4.32884L0.812805 9.91494V11.164H4.39968V13.1252ZM2.59879 9.57565L4.41832 4.59802H4.43697V9.57565H2.59879Z' fill='white'/>
            <path d='M21.2976 28.1735L10.5369 21.958V42.0587L21.2976 45.3062V28.1735Z' fill='white'/>
            <path d='M22.4832 28.2145V45.3137L33.311 42.0587V21.958L22.4832 28.2145Z' fill='white'/>
            <path d='M41.7599 21.451C41.7599 20.7463 41.1894 20.1758 40.4884 20.1758C39.7837 20.1758 39.2133 20.7463 39.2133 21.451V22.8231H41.7599V21.451Z' fill='white'/>
            <path d='M39.5302 41.7043H39.2133L40.4884 46.1524L41.7599 41.7043H41.4429H41.7599V23.8184H41.4429H41.126H40.7942H40.1603H39.8471H39.5302H39.2133V41.7043H39.5302Z' fill='white'/>
            <path d='M35.6227 22.8232L34.4705 21.9097V42.6069L22.513 46.4697H21.9612H21.8494H21.2975L9.39597 43.1438V21.9097L8.24011 22.8232V43.9529L21.2453 47.551H21.8494H21.9612H22.569L34.5638 43.7441L35.6227 43.4309V22.8232Z' fill='white'/>
            <path d='M12.1328 8.21484H14.1014V8.88599H12.9605V10.2879H13.777V10.9516H12.9605V12.443H14.1014V13.1142H12.1328V8.21484Z' fill='white'/>
            <path d='M17.0657 9.53103V11.8017C17.0657 12.5549 16.7562 13.1179 15.8725 13.1179H14.6011V8.21484H15.8725C16.7562 8.21484 17.0657 8.77413 17.0657 9.53103ZM15.7718 12.4468C16.1111 12.4468 16.2416 12.2454 16.2416 11.9471V9.37816C16.2416 9.08733 16.1111 8.88599 15.7718 8.88599H15.4251V12.443H15.7718V12.4468Z' fill='white'/>
            <path d='M20.0037 8.21484V11.9248C20.0037 12.6854 19.6793 13.1776 18.8404 13.1776C17.9717 13.1776 17.61 12.6854 17.61 11.9248V8.21484H18.434V12.0031C18.434 12.2939 18.5496 12.4878 18.8404 12.4878C19.1312 12.4878 19.2468 12.2939 19.2468 12.0031V8.21484H20.0037Z' fill='white'/>
            <path d='M20.5444 11.8537V9.48233C20.5444 8.71425 20.906 8.15869 21.7748 8.15869C22.6846 8.15869 22.9381 8.66578 22.9381 9.38912V9.96704H22.1849V9.322C22.1849 9.01999 22.0843 8.84475 21.786 8.84475C21.4914 8.84475 21.3684 9.05355 21.3684 9.35183V11.9842C21.3684 12.2825 21.4914 12.4913 21.786 12.4913C22.0843 12.4913 22.1849 12.3049 22.1849 12.014V11.1117H22.9381V11.9581C22.9381 12.6591 22.6473 13.1811 21.7748 13.1811C20.906 13.1773 20.5444 12.6106 20.5444 11.8537Z' fill='white'/>
            <path d='M23.1991 13.1179L24.1238 8.21484H25.0634L25.9955 13.1179H25.1491L24.9963 12.223H24.1201L23.9746 13.1179H23.1991ZM24.2356 11.5631H24.8919L24.5675 9.59814H24.56L24.2356 11.5631Z' fill='white'/>
            <path d='M25.8911 8.21484H28.0835V8.88599H27.4049V13.1179H26.5734V8.88971H25.8948V8.21484H25.8911Z' fill='white'/>
            <path d='M28.5384 8.21484H29.3624V13.1179H28.5384V8.21484Z' fill='white'/>
            <path d='M29.9142 11.8537V9.48233C29.9142 8.71425 30.2908 8.15869 31.1596 8.15869C32.0358 8.15869 32.4124 8.71425 32.4124 9.48233V11.8537C32.4124 12.6143 32.0358 13.1773 31.1596 13.1773C30.2908 13.1773 29.9142 12.6106 29.9142 11.8537ZM31.5846 11.9842V9.35183C31.5846 9.05355 31.4541 8.84475 31.1596 8.84475C30.8688 8.84475 30.742 9.05355 30.742 9.35183V11.9842C30.742 12.2825 30.8725 12.4913 31.1596 12.4913C31.4541 12.4875 31.5846 12.2787 31.5846 11.9842Z' fill='white'/>
            <path d='M33.6465 10.0381V13.1179H32.953V8.21484H33.7621L34.6495 11.0262V8.21484H35.3356V13.1179H34.6122L33.6465 10.0381Z' fill='white'/>
            <path d='M13.0761 6.25714V5.92529C12.4198 5.80971 12.1365 5.325 12.1365 4.66131V2.28995C12.1365 1.52186 12.5131 0.966309 13.3818 0.966309C14.258 0.966309 14.6346 1.52186 14.6346 2.28995V4.66131C14.6346 5.19823 14.4482 5.61582 14.0269 5.81717L14.6346 5.93275V6.54796L13.0761 6.25714ZM13.8031 4.83283V2.15572C13.8031 1.85743 13.6726 1.64864 13.3781 1.64864C13.0873 1.64864 12.9605 1.85743 12.9605 2.15572V4.83283C12.9605 5.12365 13.091 5.33991 13.3781 5.33991C13.6764 5.33991 13.8031 5.12365 13.8031 4.83283Z' fill='white'/>
            <path d='M17.5578 1.02246V4.73238C17.5578 5.493 17.2334 5.98518 16.3945 5.98518C15.5257 5.98518 15.1641 5.493 15.1641 4.73238V1.02246H15.9881V4.81068C15.9881 5.10151 16.1037 5.29539 16.3945 5.29539C16.6853 5.29539 16.8009 5.10151 16.8009 4.81068V1.02246H17.5578Z' fill='white'/>
            <path d='M17.871 5.92552L18.7957 1.02246H19.7353L20.6674 5.92552H19.821L19.6681 5.03066H18.7919L18.6465 5.92552H17.871ZM18.9038 4.37071H19.56L19.2356 2.40203H19.2282L18.9038 4.37071Z' fill='white'/>
            <path d='M21.0663 1.02246H21.8904V5.25438H22.8747V5.92552H21.0663V1.02246Z' fill='white'/>
            <path d='M23.2588 1.02246H24.0828V5.92552H23.2588V1.02246Z' fill='white'/>
            <path d='M24.4668 1.02246H26.6592V1.6936H25.9806V5.92552H25.1491V1.6936H24.4705V1.02246H24.4668Z' fill='white'/>
            <path d='M28.6615 4.06124V5.92552H27.8375V4.06124L26.9202 1.02246H27.7815L28.2812 2.87928H28.2886L28.7882 1.02246H29.575L28.6615 4.06124Z' fill='white'/>
        </svg>
    );
};
