import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import {Table} from "@bdl-cmn-shared-packages-npm/design-system";
import RTS27Header from "./rts27-regulation-files-header.component";
import RTS27Row from "./rts27-regulation-files-row.component";

export const RTS27Table = (props: any) => {
    const rts27List: any[] = props?.rts27Data;

    return (

        <Table>
            <RTS27Header/>
            <tbody>
            {rts27List?.map((x: any) => {
               return <RTS27Row row={x}/>
                })
            }
            </tbody>

        </Table>

    )

}
export default RTS27Table;