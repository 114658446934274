import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
    Text,
    ComponentFields,
    ComponentParams,
    ComponentRendering,
    getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {renderLink} from "../../utils/helper";

export interface TwoBlocsWithBackgroundProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
  display: flex;
`;
const cardIconContainer = css`
  width: 100px;
  height: 100px;
  margin-top: 20px;
`;
const cardIcon = css`
  display: inline-flex;
  flex-direction: column;
  background-size: cover;
  align-items: center;
  justify-content: center;
  color: #CB1234;
  font-size: 32px;
`;
const action = css`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const TwoBlocsWithBackground: React.FunctionComponent<TwoBlocsWithBackgroundProps> =
    (props: TwoBlocsWithBackgroundProps): React.ReactElement => {
        const { fields, params, rendering } = props;

        const icon1: string = getFieldValue<string>(fields, "icon1", "");
        const icon2: string = getFieldValue<string>(fields, "icon2", "");
        const parentBackground: any = getFieldValue<any>(fields, 'parentBackground', false);
        const backgroundCard1: any = getFieldValue<any>(fields, 'backgroundCard1', false);
        const backgroundCard2: any = getFieldValue<any>(fields, 'backgroundCard2', false);
        const btnLabel1: string = getFieldValue<string>(fields, "btnLabel1", "");
        const btnLabel2: string = getFieldValue<string>(fields, "btnLabel2", "");
        const btnLink1: string = getFieldValue<string>(fields, "btnLink1", "");
        const btnLink2: string = getFieldValue<string>(fields, "btnLink2", "");

        const parentContainer = css`
      width: 100%;
      margin: auto;
      padding-top: 48px;
      padding-bottom: 48px;
      background-image: url(${renderLink(parentBackground && parentBackground.src)});
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
    `;
        const mainTitle = css`
      color: ${parentBackground && parentBackground.src ? '#FFFFFF' : '#425563'};
      font-weight: 800;
      font-size: 48px;
      margin-top: 64px;
      margin-bottom: 64px;
      text-align: center;
    `;
        const card1 = css`
      width: 50%;
      padding: 48px;
      border-radius: 8px;
      background-color: #ffffff;
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      background-image: url(${renderLink(backgroundCard1 && backgroundCard1.src)});
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.3));
          justify-content: space-between;
    `;
        const card2 = css`
      width: 50%;
      padding: 48px;
      border-radius: 8px;
      background-color: #ffffff;
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      background-image: url(${renderLink(backgroundCard2 && backgroundCard2.src)});
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.3));
          justify-content: space-between;
    `;
        const title = css`
      color: ${backgroundCard1 && backgroundCard1.src ? '#FFFFFF' : '#425563'};
      font-weight: 800;
      font-size: 36px;
      line-height: 46px;
      margin-top: 24px;
      margin-bottom: 24px;
    `;
        const description = css`
      color: ${backgroundCard1 && backgroundCard1.src ? '#FFFFFF' : '#425563'};
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
    `;

        return (
            <div css={parentContainer}>
                <div css={mainTitle}>
                    <Text
                        field={fields && fields.mainTitle as SitecoreGenericTextField}
                    />
                </div>
                <div css={container}>
                    <div css={card1}>
                        <div css={cardIconContainer}>
                            {
                                icon1 && (
                                    <div css={cardIcon}>
                                        <Icon icon={icon1} />
                                    </div>
                                )
                            }

                        </div>
                        <div css={title}>
                            <Text
                                field={fields && fields.title1 as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={description}>
                            <Text
                                field={fields && fields.description1 as SitecoreGenericTextField}
                            />
                        </div>
                        {btnLabel1 && (
                            <div css={action}>
                                <Button label={btnLabel1} onClick={() => btnLink1 ? window.location.href = btnLink1 : console.log} iconLeft={'box'}/>
                            </div>
                        )}

                    </div>
                    <div css={card2}>
                        <div css={cardIconContainer}>
                            {
                                icon2 && (
                                    <div css={cardIcon}>
                                        <Icon icon={icon2} />
                                    </div>
                                )
                            }

                        </div>
                        <div css={title}>
                            <Text
                                field={fields && fields.title2 as SitecoreGenericTextField}
                            />
                        </div>
                        <div css={description}>
                            <Text
                                field={fields && fields.description2 as SitecoreGenericTextField}
                            />
                        </div>
                        {btnLabel2 && (
                            <div css={action}>
                                <Button label={btnLabel2} onClick={() => btnLink2 ? window.location.href = btnLink2 : console.log} iconLeft={'box'}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };
