import * as React from 'react';

import { breakpoint, breakpointMax } from '../../../style';

import { css } from '@emotion/react';

export const style = {
  conceptualBanner: css`
    position: sticky;
    z-index: 10;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 128px;
  `,
  conceptualBannerFull: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  `,

  conceptualBannerContainer: css`
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, #101519 0%, #657f93 100%);
    width: 100%;
    height: 100%;
  `,

  conceptualBannerContainerGreen: css`
    background: linear-gradient(180deg, #1a2934 0%, #005e2e 100%);
  `,

  hexaBackgroundImage: css`
    pointer-events: none;
    position: absolute;
    height: 100%;
  `,
  hexaBackgroundImageLight: css`
    pointer-events: none;
    position: absolute;
    top: 0;
    height: 90%;
    margin-left: 50%;
    transform: translate(-50%);
  `,
  hexaBackgroundImageLightBottom: css`
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 33%;
  `,

  bannerContent: css`
    z-index: 10;
    height: 100%;
    display: flex;
    z-index: 2;

    @media (max-width: ${breakpointMax}) {
      min-height: 200px;
      height: auto;
      flex-direction: column;
      padding: 60px 10px 60px 10px;
    }
    flex-direction: row;
    align-items: flex-start;
    gap: 1em;
    padding: 2em 5rem;
    font-size: 1.5rem;
    transition: padding 0.1s, font-size 0.1s;
    flex-grow: 1;

    align-items: center;
  `,
  bannerContentSmall: css`
    font-size: 1rem;
  `,

  bannerLeft: css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      justify-content: center;
    }
    gap: 1rem;
  `,
  bannerRight: css`
    align-self: center;
    flex-shrink: 0;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,

  metadataBar: css`
    width: 100%;
    background: #425563;
    color: white;
    z-index: 3;
  `,
  hidden: css`
    display: none;
  `,
};
