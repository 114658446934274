import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import { style } from './index.style';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';

export interface TwoColsWithPointsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const TwoColsWithPoints: React.FunctionComponent<TwoColsWithPointsProps> = (
  props: TwoColsWithPointsProps
): React.ReactElement => {
  const { fields, params, rendering } = props;

  return (
    <div css={style.container}>
      <div css={style.title}>
        <Text field={fields && (fields.title as SitecoreGenericTextField)} />
      </div>
      <div css={style.cols}>
        <div css={style.col}>
          <div css={style.colTitle}>
            <Text field={fields && (fields.leftLabelTitle as SitecoreGenericTextField)} />
          </div>
          <div css={style.colValues}>
            <div css={style.colValue}>
              <div css={style.colValueIcon}>
                <Icon icon={'book'} />
              </div>
              <div css={style.colValueText}>
                <Text field={fields && (fields.col1Text1 as SitecoreGenericTextField)} />
              </div>
            </div>
            <div css={style.colValue}>
              <div css={style.colValueIcon}>
                <Icon icon={'book'} />
              </div>
              <div css={style.colValueText}>
                <Text field={fields && (fields.col1Text2 as SitecoreGenericTextField)} />
              </div>
            </div>
            <div css={style.colValue}>
              <div css={style.colValueIcon}>
                <Icon icon={'book'} />
              </div>
              <div css={style.colValueText}>
                <Text field={fields && (fields.col1Text3 as SitecoreGenericTextField)} />
              </div>
            </div>
            <div css={style.colValue}>
              <div css={style.colValueIcon}>
                <Icon icon={'book'} />
              </div>
              <div css={style.colValueText}>
                <Text field={fields && (fields.col1Text4 as SitecoreGenericTextField)} />
              </div>
            </div>
          </div>
        </div>
        <div css={style.col}>
          <div css={style.colTitle}>
            <Text field={fields && (fields.rightLabelTitle as SitecoreGenericTextField)} />
          </div>
          <div css={style.colValues}>
            <div css={style.colValue}>
              <div css={style.colValueIcon}>
                <Icon icon={'book'} />
              </div>
              <div css={style.colValueText}>
                <Text field={fields && (fields.col2Text1 as SitecoreGenericTextField)} />
              </div>
            </div>
            <div css={style.colValue}>
              <div css={style.colValueIcon}>
                <Icon icon={'book'} />
              </div>
              <div css={style.colValueText}>
                <Text field={fields && (fields.col2Text2 as SitecoreGenericTextField)} />
              </div>
            </div>
            <div css={style.colValue}>
              <div css={style.colValueIcon}>
                <Icon icon={'book'} />
              </div>
              <div css={style.colValueText}>
                <Text field={fields && (fields.col2Text3 as SitecoreGenericTextField)} />
              </div>
            </div>
            <div css={style.colValue}>
              <div css={style.colValueIcon}>
                <Icon icon={'book'} />
              </div>
              <div css={style.colValueText}>
                <Text field={fields && (fields.col2Text4 as SitecoreGenericTextField)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
