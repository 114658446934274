/** @jsx jsx */

import * as React from "react";

import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { renderLink } from "../../utils/helper";
// import tmpBg from "./background.png";
import sync from "./sync.svg";

const lint = jsx; // linter hack

export interface BannerBrandmarksVideoProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
    data: any;
}

export const BannerBrandmarksVideo: React.FunctionComponent<BannerBrandmarksVideoProps> = (
    props: BannerBrandmarksVideoProps
    ): React.ReactElement => {
    const { fields } = props;

      const youtubeVideoUrl: string = getFieldValue<string>(
        fields,
        "youtubeVideoUrl",
        ""
      );
  
      const isLargeVideo = Boolean(
        getFieldValue<boolean>(fields, "setLargeVideo", false)
      );
      const isNude: boolean = getFieldValue<boolean>(fields, "nude", false);
      const isOnlyOneCol = Number(getFieldValue<number>(fields, "onlyOneCol", 0));
      const videoSourceUrl = (getFieldValue<any>(fields, "autoplayVideoLink", undefined));
    
  const style = {
    videoPart: css`
          display: flex;
          width: 100%;
          min-height: ${isLargeVideo ? "660px" : "300px"};
          max-width: ${isLargeVideo ? "1200px" : "600px"};
          margin-top: auto;
          margin-bottom: ${isLargeVideo ? "80px" : "auto"};
        `,
    cardParentImg: css`
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
          height: 100%;
          max-height: 450px;
          background-position: bottom;
          bottom: 0;
          margin-left: 18%;
          width: 100%;
          @media (max-width: ${breakpointMax}) {
            position: relative;
            width: 100%;
            min-height: 260px;
            margin-left: 0;
          }
        `,
    cardParent: css`
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: ${isLargeVideo ? "center" : "flex-start"};
        justify-content: ${isLargeVideo ? "center" : "flex-start"};
        height: 100%;
        @media (max-width: ${breakpointMax}) {
          align-items: center;
          justify-content: center;
          display: flex;
        }
      `,
    imgPart: css`
        display: flex;
        align-items: center;
        width: inherit;
        justify-content: center;

        img {
          max-width: 650px;
          margin-right: 100px;
          margin-bottom: 64px;
        }

        @media (max-width: ${breakpointMax}) {
          img {
            max-width: 100%;
            margin: 0;
          }
        }
      `,
  };

  return (

    <>
      <div css={style.cardParent}>
      {videoSourceUrl?.src ? (
        <video autoPlay muted loop={true} style={{ width: '100%', borderRadius: 16 }}>
          <source src={renderLink(videoSourceUrl?.src)} />
        </video>
      ) : (
        <>
          {!isNude && youtubeVideoUrl === "" && (
            <div css={style.cardParentImg} />
          )}
          {youtubeVideoUrl && youtubeVideoUrl !== "" && (
            <iframe 
            src={youtubeVideoUrl} 
            css={style.videoPart} 
            frameBorder="0" />
          )}
        </>
      )}
    </div>

    </>

  );
}
