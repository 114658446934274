
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface DecentWorkAndEconomicGrowthSvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const DecentWorkAndEconomicGrowthSvg: React.FunctionComponent<DecentWorkAndEconomicGrowthSvgProps> = ({ size: { width, height }, style }: DecentWorkAndEconomicGrowthSvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 52 49'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>8 - Decent Work and Economic Growth</title>
            <path d='M6.65924 10.1651V8.84516C6.65924 7.68557 6.2491 6.84665 5.30205 6.41786C6.10369 6.02636 6.48027 5.31421 6.48027 4.31123V3.8116C6.48027 1.92122 5.49966 0.90332 3.62419 0.90332C1.76737 0.90332 0.805402 1.92122 0.805402 3.8116V4.31123C0.805402 5.29184 1.16334 6.02263 1.94634 6.41786C0.999287 6.84665 0.607788 7.68557 0.607788 8.84516V10.1651C0.607788 12.0032 1.484 13.2896 3.62419 13.2896C5.78303 13.2896 6.65924 12.0032 6.65924 10.1651ZM2.71443 3.68856C2.71443 2.95776 2.96424 2.52897 3.62419 2.52897C4.30279 2.52897 4.55261 2.95776 4.55261 3.68856V4.50884C4.55261 5.23964 4.30279 5.66842 3.62419 5.66842C2.96424 5.66842 2.71443 5.23964 2.71443 4.50884V3.68856ZM4.62345 10.3813C4.62345 11.1121 4.35499 11.6304 3.62419 11.6304C2.90831 11.6304 2.64358 11.1121 2.64358 10.3813V8.41637C2.64358 7.68557 2.91204 7.1673 3.62419 7.1673C4.35499 7.1673 4.62345 7.68557 4.62345 8.41637V10.3813Z' fill='white'/>
            <path d='M13.5906 2.33864V4.60934C13.5906 5.36251 13.2811 5.92552 12.3975 5.92552H11.126V1.02246H12.3975C13.2811 1.02246 13.5906 1.57802 13.5906 2.33864ZM12.2968 5.25438C12.6361 5.25438 12.7666 5.05303 12.7666 4.75475V2.18577C12.7666 1.89494 12.6361 1.6936 12.2968 1.6936H11.95V5.25065H12.2968V5.25438Z' fill='white'/>
            <path d='M14.1126 1.02246H16.0813V1.6936H14.9404V3.09554H15.7569V3.75922H14.9404V5.25065H16.0813V5.92179H14.1126V1.02246Z' fill='white'/>
            <path d='M16.4989 4.66131V2.28995C16.4989 1.52186 16.8606 0.966309 17.7293 0.966309C18.6391 0.966309 18.8926 1.47339 18.8926 2.19673V2.77466H18.1395V2.12962C18.1395 1.82761 18.0388 1.65236 17.7405 1.65236C17.446 1.65236 17.3229 1.86116 17.3229 2.15945V4.79181C17.3229 5.0901 17.446 5.2989 17.7405 5.2989C18.0388 5.2989 18.1395 5.11247 18.1395 4.82164V3.91933H18.8926V4.76571C18.8926 5.46668 18.6018 5.98868 17.7293 5.98868C16.8606 5.98122 16.4989 5.41821 16.4989 4.66131Z' fill='white'/>
            <path d='M19.3923 1.02246H21.361V1.6936H20.22V3.09554H21.0366V3.75922H20.22V5.25065H21.361V5.92179H19.3923V1.02246Z' fill='white'/>
            <path d='M22.5466 2.84573V5.92552H21.8531V1.02246H22.6622L23.5496 3.8338V1.02246H24.2356V5.92552H23.5123L22.5466 2.84573Z' fill='white'/>
            <path d='M24.6682 1.02246H26.8605V1.6936H26.1819V5.92552H25.3505V1.6936H24.6719V1.02246H24.6682Z' fill='white'/>
            <path d='M29.8807 2.8942L29.4184 5.92552H28.6428L27.9866 1.02246H28.7808L29.1648 4.13208H29.1723L29.5862 1.02246H30.2573L30.7122 4.13208H30.7196L31.0962 1.02246H31.7897L31.1522 5.92552H30.3729L29.8882 2.8942H29.8807Z' fill='white'/>
            <path d='M32.0731 4.66131V2.28995C32.0731 1.52186 32.4497 0.966309 33.3184 0.966309C34.1946 0.966309 34.5712 1.52186 34.5712 2.28995V4.66131C34.5712 5.42194 34.1946 5.98495 33.3184 5.98495C32.4497 5.98122 32.0731 5.41821 32.0731 4.66131ZM33.7435 4.78808V2.15572C33.7435 1.85743 33.613 1.64864 33.3184 1.64864C33.0276 1.64864 32.9008 1.85743 32.9008 2.15572V4.78808C32.9008 5.08637 33.0313 5.29517 33.3184 5.29517C33.613 5.29517 33.7435 5.08637 33.7435 4.78808Z' fill='white'/>
            <path d='M35.9135 3.6772V5.92552H35.0895V1.02246H36.2901C37.1215 1.02246 37.4236 1.43633 37.4236 2.12239V2.75251C37.4236 3.28197 37.2371 3.59144 36.7673 3.69957L37.5541 5.92552H36.6853L35.9135 3.6772ZM35.9135 1.6936V3.21858H36.1894C36.4728 3.21858 36.5958 3.07317 36.5958 2.81217V2.08883C36.5958 1.82037 36.4728 1.68987 36.1894 1.68987H35.9135V1.6936Z' fill='white'/>
            <path d='M38.7509 3.53924V5.92552H37.9269V1.02246H38.7509V3.17757L39.6495 1.02246H40.4586L39.5041 3.22977L40.481 5.92552H39.6122L38.7509 3.53924Z' fill='white'/>
            <path d='M41.6294 5.92552L42.5541 1.02246H43.4937L44.4258 5.92552H43.5794L43.4266 5.03066H42.5504L42.4049 5.92552H41.6294ZM42.6622 4.37071H43.3184L42.9941 2.40576H42.9866L42.6622 4.37071Z' fill='white'/>
            <path d='M45.399 2.84573V5.92552H44.7054V1.02246H45.5145L46.4019 3.8338V1.02246H47.088V5.92552H46.3647L45.399 2.84573Z' fill='white'/>
            <path d='M50.0969 2.33864V4.60934C50.0969 5.36251 49.7875 5.92552 48.9038 5.92552H47.6324V1.02246H48.9038C49.7875 1.02246 50.0969 1.57802 50.0969 2.33864ZM48.8031 5.25438C49.1424 5.25438 49.2729 5.05303 49.2729 4.75475V2.18577C49.2729 1.89494 49.1424 1.6936 48.8031 1.6936H48.4564V5.25065H48.8031V5.25438Z' fill='white'/>
            <path d='M11.126 8.22607H13.0947V8.89721H11.9538V10.2992H12.7703V10.9628H11.9538V12.4543H13.0947V13.1254H11.126V8.22607Z' fill='white'/>
            <path d='M13.4564 11.8615V9.49014C13.4564 8.72206 13.8181 8.1665 14.6868 8.1665C15.5966 8.1665 15.8501 8.67359 15.8501 9.39693V9.97486H15.097V9.32982C15.097 9.0278 14.9963 8.85256 14.698 8.85256C14.4034 8.85256 14.2804 9.06136 14.2804 9.35964V11.992C14.2804 12.2903 14.4034 12.4991 14.698 12.4991C14.9963 12.4991 15.097 12.3127 15.097 12.0218V11.1195H15.8501V11.9659C15.8501 12.6669 15.5593 13.1889 14.6868 13.1889C13.8181 13.1851 13.4564 12.6221 13.4564 11.8615Z' fill='white'/>
            <path d='M16.2975 11.8615V9.49014C16.2975 8.72206 16.6741 8.1665 17.5429 8.1665C18.4191 8.1665 18.7957 8.72206 18.7957 9.49014V11.8615C18.7957 12.6221 18.4191 13.1851 17.5429 13.1851C16.6741 13.1851 16.2975 12.6221 16.2975 11.8615ZM17.9679 11.992V9.35964C17.9679 9.06136 17.8374 8.85256 17.5429 8.85256C17.2521 8.85256 17.1253 9.06136 17.1253 9.35964V11.992C17.1253 12.2903 17.2558 12.4991 17.5429 12.4991C17.8374 12.4991 17.9679 12.2903 17.9679 11.992Z' fill='white'/>
            <path d='M19.959 10.0493V13.1291H19.2655V8.22607H20.0746L20.962 11.0374V8.22607H21.648V13.1291H20.9247L19.959 10.0493Z' fill='white'/>
            <path d='M22.1216 11.8615V9.49014C22.1216 8.72206 22.4982 8.1665 23.3669 8.1665C24.2431 8.1665 24.6197 8.72206 24.6197 9.49014V11.8615C24.6197 12.6221 24.2431 13.1851 23.3669 13.1851C22.4982 13.1851 22.1216 12.6221 22.1216 11.8615ZM23.792 11.992V9.35964C23.792 9.06136 23.6615 8.85256 23.3669 8.85256C23.0761 8.85256 22.9493 9.06136 22.9493 9.35964V11.992C22.9493 12.2903 23.0798 12.4991 23.3669 12.4991C23.6615 12.4991 23.792 12.2903 23.792 11.992Z' fill='white'/>
            <path d='M27.3564 9.94494H27.3341L26.7785 13.1254H26.3945L25.7793 9.94494H25.7644V13.1254H25.0858V8.22607H26.0477L26.5809 10.9889H26.5884L27.0656 8.22607H28.1059V13.1291H27.3527V9.94494H27.3564Z' fill='white'/>
            <path d='M28.5794 8.22607H29.4034V13.1291H28.5794V8.22607Z' fill='white'/>
            <path d='M29.8658 11.8615V9.49014C29.8658 8.72206 30.2275 8.1665 31.0962 8.1665C32.006 8.1665 32.2595 8.67359 32.2595 9.39693V9.97486H31.5064V9.32982C31.5064 9.0278 31.4057 8.85256 31.1074 8.85256C30.8128 8.85256 30.6898 9.06136 30.6898 9.35964V11.992C30.6898 12.2903 30.8128 12.4991 31.1074 12.4991C31.4057 12.4991 31.5064 12.3127 31.5064 12.0218V11.1195H32.2595V11.9659C32.2595 12.6669 31.9687 13.1889 31.0962 13.1889C30.2275 13.1851 29.8658 12.6221 29.8658 11.8615Z' fill='white'/>
            <path d='M34.9105 10.5118H36.0813V13.1292H35.4884V12.5923C35.3803 12.9614 35.1491 13.1851 34.7017 13.1851C33.9933 13.1851 33.6689 12.6221 33.6689 11.8615V9.49014C33.6689 8.72206 34.038 8.1665 34.9068 8.1665C35.8166 8.1665 36.085 8.67359 36.085 9.39693V9.82944H35.3318V9.32981C35.3318 9.0278 35.2163 8.85256 34.918 8.85256C34.6197 8.85256 34.4929 9.06136 34.4929 9.35964V11.992C34.4929 12.2903 34.616 12.4991 34.8993 12.4991C35.1678 12.4991 35.3057 12.3462 35.3057 12.0293V11.1605H34.9143V10.5118H34.9105Z' fill='white'/>
            <path d='M37.4459 10.8808V13.1291H36.6219V8.22607H37.8225C38.654 8.22607 38.956 8.63994 38.956 9.326V9.95613C38.956 10.4856 38.7696 10.7951 38.2998 10.9032L39.0865 13.1291H38.2177L37.4459 10.8808ZM37.4459 8.89721V10.4222H37.7218C38.0052 10.4222 38.1283 10.2768 38.1283 10.0158V9.29244C38.1283 9.02399 38.0052 8.89349 37.7218 8.89349H37.4459V8.89721Z' fill='white'/>
            <path d='M39.3363 11.8615V9.49014C39.3363 8.72206 39.7129 8.1665 40.5816 8.1665C41.4579 8.1665 41.8344 8.72206 41.8344 9.49014V11.8615C41.8344 12.6221 41.4579 13.1851 40.5816 13.1851C39.7129 13.1851 39.3363 12.6221 39.3363 11.8615ZM41.0067 11.992V9.35964C41.0067 9.06136 40.8762 8.85256 40.5816 8.85256C40.2908 8.85256 40.164 9.06136 40.164 9.35964V11.992C40.164 12.2903 40.2945 12.4991 40.5816 12.4991C40.8762 12.4991 41.0067 12.2903 41.0067 11.992Z' fill='white'/>
            <path d='M43.9411 10.0978L43.4788 13.1291H42.7032L42.047 8.22607H42.8412L43.2252 11.3357H43.2327L43.6465 8.22607H44.3177L44.7726 11.3357H44.78L45.1566 8.22607H45.8501L45.2125 13.1291H44.4333L43.9486 10.0978H43.9411Z' fill='white'/>
            <path d='M46.1074 8.22607H48.2998V8.89721H47.6212V13.1291H46.7897V8.89721H46.1111V8.22607H46.1074Z' fill='white'/>
            <path d='M50.1715 10.9666H49.3774V13.1291H48.5533V8.22607H49.3774V10.3029H50.1715V8.22607H51.003V13.1291H50.1715V10.9666Z' fill='white'/>
            <path d='M36.6517 25.5191L30.5518 31.3207C30.3206 31.5482 30.205 31.7197 30.205 32.1932V48.4535C30.205 48.6436 30.3616 48.8002 30.5518 48.8002H36.853C37.0432 48.8002 37.1998 48.6436 37.1998 48.4535V25.6645C37.1998 25.4743 37.0469 25.1239 36.6517 25.5191Z' fill='white'/>
            <path d='M19.0827 33.0767L12.9828 38.8783C12.7516 39.1095 12.636 39.2773 12.636 39.7471V48.4496C12.636 48.6397 12.7926 48.7963 12.9828 48.7963H19.2841C19.478 48.7963 19.6308 48.6397 19.6308 48.4496V33.2221C19.6271 33.032 19.478 32.6815 19.0827 33.0767Z' fill='white'/>
            <path d='M39.4221 18.4832C39.4258 18.4384 39.4071 18.3862 39.3699 18.3527C39.3363 18.3228 39.2953 18.3079 39.258 18.3042L39.2244 18.3079L39.176 18.3117L34.5078 19.3668L34.4221 19.3743C34.3885 19.3818 34.3587 19.3967 34.3326 19.4228C34.2654 19.4936 34.2654 19.6055 34.3326 19.6726L34.3848 19.7248L35.4511 20.7911L24.8657 31.3691L19.9776 26.4809C19.9217 26.425 19.8359 26.425 19.78 26.4809L18.4526 27.8083L10.6189 35.642C10.563 35.6979 10.563 35.7874 10.6189 35.8396L11.9463 37.167C12.0022 37.2229 12.088 37.2229 12.1439 37.167L19.8807 29.4302L23.4265 32.9761C23.4302 32.9798 23.4302 32.9873 23.4377 32.991L24.7651 34.3146C24.821 34.3706 24.9068 34.3706 24.9627 34.3146L36.9724 22.3049L38.091 23.4235C38.1581 23.4944 38.2737 23.4944 38.3408 23.4235C38.3743 23.39 38.3892 23.3489 38.393 23.3079V23.293L39.4183 18.5242L39.4221 18.4832Z' fill='white'/>
            <path d='M27.9008 34.0946L24.9515 37.07C24.8956 37.1148 24.8135 37.1148 24.7613 37.0626L22.088 34.337C21.5995 33.8485 21.4914 34.2438 21.4914 34.4376V48.4496C21.4914 48.6397 21.6443 48.7963 21.8382 48.7963H28.1394C28.3296 48.7963 28.4862 48.6397 28.4862 48.4496V34.2027C28.4824 34.0163 28.3333 33.6621 27.9008 34.0946Z' fill='white'/>
            <path d='M36.6517 25.5191L30.5518 31.3207C30.3206 31.5482 30.205 31.7197 30.205 32.1932V48.4535C30.205 48.6436 30.3616 48.8002 30.5518 48.8002H36.853C37.0432 48.8002 37.1998 48.6436 37.1998 48.4535V25.6645C37.1998 25.4743 37.0469 25.1239 36.6517 25.5191Z' fill='white'/>
            <path d='M19.0827 33.0767L12.9828 38.8783C12.7516 39.1095 12.636 39.2773 12.636 39.7471V48.4496C12.636 48.6397 12.7926 48.7963 12.9828 48.7963H19.2841C19.478 48.7963 19.6308 48.6397 19.6308 48.4496V33.2221C19.6271 33.032 19.478 32.6815 19.0827 33.0767Z' fill='white'/>
            <path d='M39.4221 18.4832C39.4258 18.4384 39.4071 18.3862 39.3699 18.3527C39.3363 18.3228 39.2953 18.3079 39.258 18.3042L39.2244 18.3079L39.176 18.3117L34.5078 19.3668L34.4221 19.3743C34.3885 19.3818 34.3587 19.3967 34.3326 19.4228C34.2654 19.4936 34.2654 19.6055 34.3326 19.6726L34.3848 19.7248L35.4511 20.7911L24.8657 31.3691L19.9776 26.4809C19.9217 26.425 19.8359 26.425 19.78 26.4809L18.4526 27.8083L10.6189 35.642C10.563 35.6979 10.563 35.7874 10.6189 35.8396L11.9463 37.167C12.0022 37.2229 12.088 37.2229 12.1439 37.167L19.8807 29.4302L23.4265 32.9761C23.4302 32.9798 23.4302 32.9873 23.4377 32.991L24.7651 34.3146C24.821 34.3706 24.9068 34.3706 24.9627 34.3146L36.9724 22.3049L38.091 23.4235C38.1581 23.4944 38.2737 23.4944 38.3408 23.4235C38.3743 23.39 38.3892 23.3489 38.393 23.3079V23.293L39.4183 18.5242L39.4221 18.4832Z' fill='white'/>
            <path d='M27.9008 34.0946L24.9515 37.07C24.8956 37.1148 24.8135 37.1148 24.7613 37.0626L22.088 34.337C21.5995 33.8485 21.4914 34.2438 21.4914 34.4376V48.4496C21.4914 48.6397 21.6443 48.7963 21.8382 48.7963H28.1394C28.3296 48.7963 28.4862 48.6397 28.4862 48.4496V34.2027C28.4824 34.0163 28.3333 33.6621 27.9008 34.0946Z' fill='white'/>

        </svg>
    );
};
