
/** @jsx jsx */
/* eslint-disable */
import { css,jsx } from "@emotion/react"
import * as React from 'react';
import { Checkbox, InputField, OptionsData } from "@bdl-cmn-shared-packages-npm/design-system";
import { LuxXPrimeFilterResultsData } from "../../../../../../graphql";
import style from "./index.style";


let luxxprimeIssuerNames : any = [];

export function getIssuerNamesForLuxXPrime(ids:string){
  const issuerIds=ids?.split(",");
  let issuerNames="";

  issuerIds?.map((idIssuer)=>{
    let issuerName  = getIssuerNameForLuxXPrime(idIssuer);
    if( issuerName )
      issuerNames +=  issuerName + ",";
  });
  return issuerNames;
}

export function getIssuerNameForLuxXPrime(id :string){

  if(id){
    let issuerName = luxxprimeIssuerNames[id];
    if( issuerName && issuerName[0])
      return issuerName[0].toString().replace(/,/g,' ');
  }

  return id;
}


export interface IssuerNameFilterProps {
  selection: string[];
  onSelectionChange: (values: any) => void;
  data: LuxXPrimeFilterResultsData | null
}


export function IssuerNameFilter(props: IssuerNameFilterProps) {
  const {
    selection,
    onSelectionChange,
    data
  } = props;


  return (
    <>
        <IssuerNameFilterValues
          selection={selection}
          onSelectionChange={onSelectionChange}
          data={data}
          css={css`margin:0;`}
        />
    </>
  );
}

interface IssuerNameFilterValuesProps extends IssuerNameFilterProps {
  data: LuxXPrimeFilterResultsData;
} 


function IssuerNameFilterValues({ selection, onSelectionChange, data }: IssuerNameFilterValuesProps) {

  const [filter, setFilter] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const label = React.useMemo(() => {
    const number = selection? selection.length : 0;

    return number == 0 ?
      "Issuer name" :
        `${number} elements selected`;
  }, [selection]);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const clickOutside = (event: any) => {
        if (!ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    }
  }, [open]);

  

  const values: OptionsData[] = React.useMemo(() => {
    luxxprimeIssuerNames = data?.securitiesByTag ? data?.securitiesByTag?.reduce((acc, { issuers }) => {

      const name = issuers?.map(({ name }) => name) || [];
      const id = issuers?.map(({ id }) => id) || [];
      return id.reduce((acc, id) => {
        if (acc[id]===undefined&&name) {
          acc[id] = name
        }
        return acc;
      }, acc);
    }, {} as any):[];

    const uniqueValues = Object.keys(luxxprimeIssuerNames) as string[];

    return uniqueValues
      .map(key => ({
        name: luxxprimeIssuerNames[parseInt(key)].toString(),
        value: key,
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((value: OptionsData) => value.name.includes(filter.toUpperCase()));
  }, [data, filter]);


  const handleSelectionChange = (issuer: OptionsData) => {
    onSelectionChange(issuer.value);
  }

  return (
    <>
      <div ref={ref} css={style.container} onClick={(e) => e.stopPropagation()}>
        <div css={style.inputWrapper}>
          <InputField
            onFocus={() => setOpen(true)}
            value={open ? filter.toUpperCase() : ""}
            onClick={(e) => e.stopPropagation()}
            onChange={(e: any) => {setFilter(e.target.value.toUpperCase());}}
            placeholder={open ? "Issuer Name": label}
          />
        </div>
        {
          open && (
            <>
              <div css={style.panel} onClick={(e) => e.stopPropagation()}>
                { values.map( (value : OptionsData) => {
                    if( !value )
                      return <></>;
                    
                    return (
                      <div css={style.checkBoxWrapper}>
                        <React.Fragment key={`luxxprime-issuer-name-${value.value}-${value.name}`}>
                          <Checkbox
                            label={value.name}
                            onClick={() => handleSelectionChange(value)}
                            checked={selection?.includes(value.value)}
                          />
                        </React.Fragment>
                      </div>
                    );
                  })
                }
              </div>
            </>
          )
        }
      </div>
    </>
  )
}

export { luxxprimeIssuerNames };
