import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Icon, Grid, Row, Col } from '@bdl-cmn-shared-packages-npm/design-system';
import { formatToThousandsSeparator } from '../../../../../utils';
import { Security } from '../../../../../interfaces';
import { style } from '../../SecurityOverview/securityOverview.style';
import PriceInformation from '../prices-information/price-information.component';
import { formatDate } from '../../../../../utils/date';

export interface MarketDataInformationProps {
  securityData?: Security;
}

const valueCol = css`
  width: 100px;
`;

export const MarketDataGeneralInformation = ({ securityData }: MarketDataInformationProps) => {
  const marketData = securityData?.marketData || null;
  
  const lastPriceNegative = marketData?.lastPrice?.changePercent < 0;
  const previousDayNegative = marketData?.previousDayChangePercent < 0;

  return (
    <Grid>
      <Row>
        <Col mobile={12} desktop={6}>
          <div css={style.blocTitleSmall}>Price information</div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Last price</div>
            <div css={[style.blocRowValue, valueCol]} >
              <div style={{ color: !lastPriceNegative ? '#22AA5F' : 'red' }}>
                <PriceInformation price={marketData?.lastPrice} negative={lastPriceNegative} />
              </div>
              <div>
                { marketData?.lastPrice?.date ? 
                  formatDate(marketData?.lastPrice?.date) 
                  : ""
                }
              </div>
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Day volume</div>
            <div css={[style.blocRowValue, valueCol]}>
              {marketData?.lastPrice?.dayVolume || '-'}
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Previous closing price</div>
            <div css={[style.blocRowValue, valueCol]} >
              <div style={{ color: !previousDayNegative ? '#22AA5F' : 'red' }}>
                <PriceInformation price={marketData?.previousClosingPrice} negative={previousDayNegative} />
              </div>
              <div>
                { marketData?.previousClosingPrice?.date ? 
                  formatDate(marketData?.previousClosingPrice?.date) 
                  : ""
                }
              </div>
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Change prev. closing price</div>
            <div css={[style.blocRowValue, valueCol]} style={{ color: !previousDayNegative ? '#22AA5F' : 'red' }}>
              {marketData?.previousDayChangeAbsolute == null ? '-' : marketData?.previousDayChangeAbsolute}
              {' | '}
              {marketData?.previousDayChangePercent == null ? '-' : marketData?.previousDayChangePercent + '%'}
            </div>
          </div>
        </Col>
        <Col mobile={12} desktop={6}>
          <div css={style.blocTitleSmall}>Performance</div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Daily high</div>
            <div css={[style.blocRowValue, valueCol]}>
              {(marketData?.dailyMaximum && formatToThousandsSeparator(marketData.dailyMaximum.amount)) || '-'}
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>Daily low</div>
            <div css={[style.blocRowValue, valueCol]}>
              {(marketData?.dailyMinimum && formatToThousandsSeparator(marketData.dailyMinimum.amount)) || '-'}
            </div>
          </div>
          { marketData?.yieldToMaturity ?
            <div css={style.blocRowSpace}>
              <div css={style.blocRowKey}>Yield to maturity</div>
              <div css={[style.blocRowValue, valueCol]}>
                {`${marketData?.yieldToMaturity} %`}
              </div>
            </div>
            : <></>
          }
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>52 weeks high</div>
            <div css={[style.blocRowValue, valueCol]}>
              {(marketData?.yearHighestPrice && formatToThousandsSeparator(marketData.yearHighestPrice.amount)) 
                || '-'}
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>52 weeks low</div>
            <div css={[style.blocRowValue, valueCol]}>
              {(marketData?.yearLowestPrice && formatToThousandsSeparator(marketData.yearLowestPrice.amount)) 
                || '-'}
            </div>
          </div>
          <div css={style.blocRowSpace}>
            <div css={style.blocRowKey}>YTD</div>
            <div css={[style.blocRowValue, valueCol]}>
              {marketData?.yearToDatePercent ? `${Math.round(marketData?.yearToDatePercent * 1000)/1000} %` : '-'}
              {' / '}
              {Math.round(marketData?.yearToDateAbsolute * 1000)/1000 || '-'} 
            </div>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};
