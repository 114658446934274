import * as React from "react";

import { breakpointMax } from "../../../../style";
import { css, jsx } from "@emotion/react";

import { CountryFlagIcon } from "../../CountryFlagIcon/countryFlagIcon.component";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import Loader from "../../Loader/loader.component";
import RoundTag from "../../RoundTag/roundTag.component";
import { issuerDetailPageLink } from "..";
import { issuerSubTypeLabel } from "../../../../enums/issuer-subtype";
import { issuerTypeLabel } from "../../../../enums/issuer-type";
import { cardStyle } from "./index.style";
import SearchIssuer from "../../../../interfaces/search/search-issuer";
import { Country } from "../../../../enums";
import EmptyCard from "../../Search/results/searchMobile/resultsCards/emptyCard";
import EmptyData from "../../SecurityCard/emptyData";

/** @jsx jsx */

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export interface IssuerCardsProps {
  loading?: boolean;
  issuers: SearchIssuer[];
  isGreen?: boolean;
}

export interface IssuerCardProps {
  issuer: SearchIssuer;
  isGreen?: boolean;
  isFull?: boolean;
}

const mobileStyle = css`
  @media (max-width: ${breakpointMax}) {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

export function IssuerSummaryCards({
  issuers,
  isGreen,
  loading = false,
}: IssuerCardsProps) {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <div
      css={[
        cardStyle.loaderSummary,
        css`
          box-shadow: ${issuers?.length > 0
            ? "0px 16px 24px rgba(37, 54, 69, 0.15)"
            : "none"};
          background-color: ${issuers?.length > 0 ? "#ffffff" : "#F4F6F7"};
        `,
        cardStyle.cardMinHeight,
      ]}
    >
      <Loader
        loading={loading}
        padding={"0 !important"}
        margin={"0 !important"}
      >
        {issuers?.length > 0 ? (
          <>
            {issuers.length == 1 ? (
              <IssuerSummaryCard issuer={issuers[0]} isGreen={isGreen} />
            ) : (
              <>
                <div css={cardStyle.multipleContainer}>
                  <div css={cardStyle.multipleSummary}>
                    <div
                      css={[
                        cardStyle.roundedTag,
                        css`
                          > div {
                            background-color: ${isGreen
                              ? "#e8faf0"
                              : "#fae8ea"};
                          }
                        `,
                      ]}
                    >
                      <RoundTag borderSize={3} green={isGreen} size={24}>
                        I
                      </RoundTag>
                    </div>
                    <div css={cardStyle.name}>
                      <h3>Multiple issuers</h3>
                    </div>
                  </div>
                  <div
                    css={cardStyle.multipleButton}
                    onClick={() => setOpen(!open)}
                  >
                    <div>
                      View {issuers.length} issuers{" "}
                      <Icon icon={open ? "chevron-up" : "chevron-down"} />
                    </div>
                  </div>
                </div>
                {open ? (
                  <div css={cardStyle.multipleView}>
                    {issuers.map((issuer) => {
                      return (
                        <IssuerSummaryCard
                          issuer={issuer}
                          isGreen={isGreen}
                          isFull={false}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <div
            css={[
              cardStyle.summary,
              { height: "100%", backgroundColor: "#F4F6F7" },
            ]}
          >
            <div css={cardStyle.container}>
              <EmptyData icon={"ban"} message={"No Issuer"} />
            </div>
          </div>
        )}
      </Loader>
    </div>
  );
}

export function IssuerSummaryCard({
  issuer,
  isGreen,
  isFull = true,
}: IssuerCardProps) {
  if (!issuer) return <></>;

  const type = issuer.type ? issuer.type : issuer.level1Classification;
  const subType = issuer.subType ? issuer.subType : issuer.level2Classification;
  const country = issuer.country ? issuer.country : issuer.countryCode2;

  return (
    <a
      css={[cardStyle.link, isFull && cardStyle.cardMinHeight]}
      href={issuerDetailPageLink(issuer.url, issuer.id)}
    >
      <div
        css={[
          cardStyle.summary,
          cardStyle.summaryFixHeight,
          isFull && cardStyle.cardMinHeight,
          isFull && cardStyle.cardMaxHeight,
          !isFull && cardStyle.cardBorder,
        ]}
      >
        {isFull ? (
          <div
            css={[
              cardStyle.roundedTag,
              css`
                > div {
                  background-color: ${isGreen ? "#e8faf0" : "#fae8ea"};
                }
              `,
            ]}
          >
            <RoundTag
              borderSize={3}
              green={isGreen || issuer.statistics?.nbGreens > 0}
              size={24}
            >
              I
            </RoundTag>
          </div>
        ) : (
          <></>
        )}
        <div css={[cardStyle.container, isFull && cardStyle.fullContainer]}>
          {isGreen /*|| issuer.statistics?.nbGreens > 0*/ && (
            <div css={cardStyle.info}>
              <div css={cardStyle.greenLeaf}>
                <Icon icon="leaf"></Icon> LGX
              </div>
            </div>
          )}
          <div css={cardStyle.name}>{issuer.name}</div>
          <div css={[cardStyle.info, isFull && cardStyle.fullInfo]}>
            <div css={cardStyle.tags}>
              {country ? (
                <div css={[cardStyle.tag, cardStyle.country, mobileStyle]}>
                  <CountryFlagIcon
                    code={country}
                    size={{ width: 21, height: 21 }}
                  />
                  {country == "TW"
                    ? Country.TW
                    : countries.getName(country, "en", { select: "official" })}
                </div>
              ) : (
                <></>
              )}
              {type ? (
                <div css={[cardStyle.tag, mobileStyle]}>
                  {issuerTypeLabel(type)}
                </div>
              ) : (
                <></>
              )}
              {subType ? (
                <div css={[cardStyle.tag, mobileStyle]}>
                  {issuerSubTypeLabel(subType)}
                </div>
              ) : (
                <></>
              )}
              <div css={cardStyle.infoTag}>
                ({issuer.statistics.nbSecurities} listed securities)
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}
