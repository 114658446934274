/** @jsx jsx */
import * as React from 'react';
import { css, jsx } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button, Row, Col, DonutGraph, Grid, Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import { Query } from 'react-apollo';
import { GET_FALLERS_SECURITIES_LIST_YEARLY, GET_LAST_TRADED_SECURITIES_LIST } from '../../../graphql';
import Loader from '../Loader/loader.component';

export interface MarketCapitalisationProps {
  label?: string;
  noAction?: boolean;
  noTitle?: boolean;
}

const style = {
  container: css`
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 80px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
    margin-bottom: 40px;
  `,
  action: css`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 160px;
  `,
  cardContainerTop: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
  `,
  cardContainerBody: css`
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 100px;

    .apexcharts-legend {
      right: 24px !important;
    }

    text {
      font-size: 8px;
    }
  `,
  cardContainerBodyTop: css``,
  cardContainerBodyBottom: css`
    text-align: right;
  `,
  cardContainerBodyDoubleContainer: css`
    display: flex;
  `,
  cardContainerBodyDoubleLeft: css`
    display: flex;
    align-items: center;
    margin-right: 12px;
  `,
  cardContainerBodyDoubleRight: css``,
  cardContainerBodyDouble: css`
    display: flex;
    flex-direction: column;
    color: #54666f;
    font-weight: 600;
    font-size: 14px;

    span {
      color: #425563;
      font-weight: 600;
      margin-top: 6px;
      font-size: 14px;
    }
  `,
  span: css`
    color: #425563;
    font-weight: 600;
    font-size: 14px;
    margin-top: 8px;
  `,
  cardContainerBodyTag: css`
    font-size: 14px;
    display: flex;
    justify-content: center;
  `,
  cardContainerBodyLabel: css`
    font-size: 24px;
    color: #354450;
    font-weight: 800;
    text-align: center;
    margin-bottom: 8px;
  `,
  cardContainerBodyRate: css`
    font-size: 48px;
    color: #22AA5F;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
    text-align: center;
  `,
  cardContainerBodyIsin: css`
    font-size: 14px;
    font-weight: 600;
    color: #425563;
    text-align: center;
    margin-bottom: 32px;
  `,
  cardContainerBodyText: css`
    font-size: 14px;
    padding-left: 38px;
    color: #354450;
    line-height: 17px;
    font-weight: 600;
    margin-bottom: 32px;
  `,
  cardContainerBodyTags: css`
    font-size: 14px;
    display: flex;
    justify-content: center;

    > div {
      margin-left: 8px;
      margin-right: 8px;
    }
  `,
  cardContainerFooter: css`
    color: #22AA5F;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  buttons: css`
    display: flex;
    justify-content: center;
    margin-top: 64px;

    button {
      margin-left: 12px;
      margin-right: 12px;
    }
  `,
  cardContainerTitle: css`
    img {
      width: 100%;
      max-width: 140px;
    }
    svg {
      color: #a4bcc2;
      font-size: 20px;
      margin-right: 4px;
    }
    font-weight: 800;
    line-height: 26px;
    color: #354450;
  `,
  cardContainerActions: css`
    font-size: 12px;
    font-weight: 600 !important;
    color: #54666f;

    > div > div {
      padding-left: 16px;
      padding-right: 16px;
    }
  `,
  marketTitle: css`
    margin-top: 160px;
    margin-bottom: 24px;
    font-size: 48px;
    font-weight: bold;
    line-height: 64px;
    text-align: center;
    color: #354450;
  `,
  table: css`
    width: 100%;
    border: none;
    border-collapse: collapse;
    color: #54666f;
    font-weight: 600;
    table {
      width: 100%;
    }

    td,
    th {
      height: 28px;
      width: 50%;
      vertical-align: middle;
    }
    td {
      border-bottom: solid 1px #e3e6e8;
      font-size: 14px;
    }
    th {
      text-align: left;
      color: #354450;
      font-weight: 800;
      font-size: 14px;
    }
    thead {
      width: calc(100% - 18px);
    }
    tr td:first-of-type {
      padding-left: 0;
    }
    thead th {
      text-align: left;
    }

    th {
      height: auto;
      margin-bottom: 8px;
    }
  `,
};
const cardContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px 32px 0;
`;
const card = css`
  background: #ffffff;
  border: solid 1px #e3e6e8;
  border-radius: 8px;
  height: 100%;
  box-shadow: 0 8px 16px rgb(164 188 194 / 31%);
`;

export const MarketCapitalisation: React.FunctionComponent<MarketCapitalisationProps> = ({
  noTitle,
  noAction,
}: MarketCapitalisationProps) => {
  const [securityFilter, setSecurityFilter] = useState(GET_LAST_TRADED_SECURITIES_LIST);
  const [objectTarget, setObjectTarget] = useState<string>('lastTradedLuxXPrimeSecurities');
  return (
    <div css={style.container}>
      {!noTitle && <div css={style.marketTitle}>Market capitalisation</div>}
      <Grid>
        <Row>
          <Col mobile={12} tablet={4} desktop={4}>
            <div css={card}>
              <div css={cardContainer}>
                <div css={style.cardContainerTop}>
                  <div css={style.cardContainerTitle}>
                    <Icon icon={'chart-line'} /> Top Lux shares Cap
                  </div>
                </div>
                <div css={style.cardContainerBody}>
                  <DonutGraph
                    chartHeight={218}
                    labels={['Apple', 'Mango', 'Orange', 'Watermelon']}
                    legend
                    series={[44, 55, 13, 33]}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col mobile={12} tablet={4} desktop={4}>
            <div css={card}>
              <div css={cardContainer}>
                <div css={style.cardContainerTop}>
                  <div css={style.cardContainerTitle}>
                    <Icon icon={'chart-line'} /> Top issued Shares
                  </div>
                </div>
                <div css={style.cardContainerBody}>
                  
                </div>
              </div>
            </div>
          </Col>
          <Col mobile={12} tablet={4} desktop={4}>
            <div css={card}>
              <div css={cardContainer}>
                <div css={style.cardContainerTop}>
                  <div css={style.cardContainerTitle}>
                    <Icon icon={'chart-line'} /> Top Lux shares Cap
                  </div>
                </div>
                <div css={style.cardContainerBody}>
                  <DonutGraph
                    graphColors={['#22AA5F', '#009CC4', '#66C4DC', '#F18700']}
                    chartHeight={218}
                    labels={['Apple', 'Mango', 'Orange', 'Watermelon']}
                    legend
                    series={[44, 55, 13, 33]}
                  />
                  
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
      {!noAction && (
        <div css={style.action}>
          <Button label={'See all market capitalization'} iconRight={'chevron-right'} />
        </div>
      )}
    </div>
  );
};

export default MarketCapitalisation;
