import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import axios from "axios";
import { useState, useEffect } from "react";
import { ResourcesHeader } from "../ResourcesHeader";
import Loader from "../../Loader/loader.component";
import { fetchData, getDataWithChild } from "../../../../utils/helper";
import { PressItemRow } from "./PressItemRow";
import {
  resourceContent,
  rowContainer,
  rowLabel,
  empty,
  rootPage,
  childContainer,
} from "../shared.style";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Button, ButtonProps, InputField, Pagination } from '@bdl-cmn-shared-packages-npm/design-system';
import Select from "react-select";
import Filter from "../../../icons/filter";
import { breakpointMax } from "../../../../style";

export const ResourcesPress = (props: any): any => {
  const { fields, isH1 } = props;
  const [press, setPress] = React.useState<any>(null);
  const [isLoadingPress, setIsLoadingPress] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(0); // Start from page 1
  const [perPage, setPerPage] = React.useState<number>(2); // Show 2 month articles per page
  const [filterYear, setFilterYear] = React.useState<number | null>(null); // Set filterYear to null initially
  const [total, setTotal] = useState<number>(0);

  const title: string = getFieldValue<string>(fields, "title", "");
  const description: string = getFieldValue<string>(fields, "description", "");
  const downloadLabel: string = getFieldValue<string>(fields, "first button label", "");
  const readLabel: string = getFieldValue<string>(fields, "second button label", "");
  const downloadLink: string = getFieldValue<string>(fields, "download link", "");

  const fetchPress = async (id: string) => {
    try {
      const result: any = await fetchData(id);
      const dataReq = await getDataWithChild(result);
      if (dataReq && dataReq.length > 0) {
        for (const d of dataReq) {
          const index: number = dataReq.indexOf(d);
          if (d && d.children && d.children.length > 0) {
            for (const item of d.children) {
              const subIndex: number = d.children.indexOf(item);
              if (
                item.Document &&
                item.Document.match(/{([^}]+)}/) &&
                item.Document.match(/{([^}]+)}/)[1]
              ) {
                const request = await fetchData(
                  item.Document.match(/{([^}]+)}/)[1],
                  false
                );
                dataReq[index].children[subIndex].documentUrl = request;
              }
            }
          }
        }
      }
      setPress(dataReq);
      setIsLoadingPress(false);
    } catch (err) {
      console.error(err);
    }
  };

  const filterPress = (data: any[]) => {
    if (!filterYear) return data;
    return data.filter((item) => {
      const yearFromLabel = new Date(item.label).getFullYear();
      const yearFromDate = new Date(item.Date).getFullYear();
      return yearFromLabel === filterYear || yearFromDate === filterYear;
    });
  };

  const getPaginatedData = (currentPage: number) => {
    const filteredData = filterPress(press || []);
    const startIndex = currentPage * perPage;
    const endIndex = Math.min(startIndex + perPage, filteredData.length);
    const slicedData = filteredData.slice(startIndex, endIndex);
    
    return slicedData;

  };

  const handlePageChange = (page: any) => {
    const pageNumber = page.selected;
    setCurrentPage(pageNumber);
  };

  const handleYearFilter = (year: number | null) => {
    if (year === null) {
      setFilterYear(null);
    } else {
      setFilterYear(year);
    }
    setCurrentPage(0); // Reset page to 0 on filter change
    const filteredData = filterPress(press || []);
    const totalFilteredPages = Math.ceil(filteredData.length / perPage);
    setTotal(totalFilteredPages); // Update total pages based on filtered data
    };

  useEffect(() => {
    fetchPress("A8941707-2723-4AF1-A140-A019FF340E1C");
  }, []);

  useEffect(() => {
    setTotal(Math.ceil((press || []).length / perPage)); // Use unfiltered data length for total pages
  }, [press]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);


  const topFilter = css`
    display: flex;
    align-items: center;
    padding-top: 32px;
    gap: 10px;
    margin-left: 80px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    @media (max-width: ${breakpointMax}) {
      padding-left: 15px;
    }
  `;

  const button = css`
    height: 38px;
    border-radius: 0 4px 4px 0;
    line-height: 0.15;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.20);
    text-align: center;
    align-items: center;
    justify-content: center;
  `;

  const pagination = css`
    display: flex;
    align-items: center;
    padding-left: 30%;
    flex-wrap: wrap;
    @media (max-width: ${breakpointMax}) {
      padding-left: 15px;
      padding-right: 15px;
        ul {
          columns: 2;
          padding: 0;
          display: block !important;
            li {
                  margin-bottom: 5px;
                }
            }
    }
  `;

  const resourcesHeader = css`
    @media (max-width: ${breakpointMax}) {
      padding: 0;
      padding-left: 16px;
      padding-right: 16px;
    }
  `;

  const getYearsOptions = () => {
    const years = new Set<number>();
    if (press) {
      press.forEach((item: any) => {
        const yearFromLabel = new Date(item.label).getFullYear();
        const yearFromDate = new Date(item.Date).getFullYear();
        if (!isNaN(yearFromLabel)) years.add(yearFromLabel);
        if (!isNaN(yearFromDate)) years.add(yearFromDate);
      });
    }

  let options = Array.from(years).map((year) => ({ label: year.toString(), value: year }));

  options = options.sort((a, b) => b.value - a.value);

  options.unshift({ label: "Reset", value: null });

  return options;
  };

  return (
    <div>
      <div css={resourcesHeader}>
        <ResourcesHeader title={title} description={description} isH1={isH1} />
      </div>
      <div>
        <div css={topFilter}>
          <Select
            placeholder="Select year"
            styles={{
              dropdownIndicator: (baseStyles) => ({
                ...baseStyles,
                color: "#22AA60",
                "&:hover": {
                  color: "#22AA60",
                },
              }),
              indicatorSeparator: (baseStyles) => ({
                ...baseStyles,
                display: "none",
              }),
            }}
            isClearable={false}
            isSearchable={false}
            options={getYearsOptions()}
            onChange={(option) => handleYearFilter(option ? option.value : null)}
            
          />
          {/* <Button
            label="Filter"
            iconLeft="filter"
            onClick={() => handleYearFilter(filterYear)}
            css={button}
            style={{ textAlign: 'center' }}
          >
            {iconLeft === "filter" ? (
              <Filter style={{
                fill: "red",
                fontSize: "14px",
                verticalAlign: "middle",
                marginRight: "8px",
              }} />
            ) : null}
          </Button> */}
        </div>
        <Loader loading={isLoadingPress} centered css={{ minHeight: 400 }}>
          <div css={resourceContent("column")}>
            {getPaginatedData(currentPage).length > 0 ? (
              getPaginatedData(currentPage).map((data: any, index: number) => (
                <div css={rowContainer(index % 2 === 0 ? "transparent" : "#F6F8F9")} key={index}>
                  <div css={childContainer}>
                    <div css={rowLabel}>{data.label}</div>
                    {data &&
                      data.children &&
                      data.children.map((child: any, childIndex: number) => (
                        <PressItemRow
                          downloadLink={downloadLink}
                          downloadLabel={downloadLabel}
                          readLabel={readLabel}
                          data={child}
                          key={`letter-key-${childIndex}`}
                          index={childIndex}
                        />
                      ))}
                  </div>
                </div>
              ))
            ) : (
              <div css={empty}>The search did not return any results</div>
            )}
          </div>
        </Loader>
        <div key={filterYear}>
          <div css={pagination}>
            <Pagination
              total={Math.ceil(filterPress(press || []).length / perPage)}
              initialPage={0}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              previousLabel='<'
              nextLabel='>'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

