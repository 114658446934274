import { breakpoint, breakpointMax } from "../../style";

import { css } from "@emotion/react";

export const style = {
  parentContainer: css`
    width: 100%;
    //margin-bottom: 96px;
    //margin-top: 96px;
  `,
  container: css`
    width: 100%;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 96px;
    padding-top: 96px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    @media (max-width: ${breakpointMax}) {
      padding-top: 40px;
      padding-bottom: 40px;
      flex-direction: column;
    }
  `,
  containerRevert: css`
    width: 100%;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 96px;
    padding-top: 96px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row-reverse;

    @media (max-width: ${breakpointMax}) {
      padding-top: 40px;
      padding-bottom: 40px;
      flex-direction: column;
    }
  `,
  left: css`
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding-right: 96px;

    @media (max-width: ${breakpointMax}) {
      width: 100%;
      padding: 0;
      justify-content: center;
      text-align: center;

      img {
        text-align: center;
        width: 100%;
      }
    }
  `,
  leftRevert: css`
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding-right: 0;

    @media (max-width: ${breakpointMax}) {
      width: 100%;
      padding: 0;
      justify-content: center;
      text-align: center;

      img {
        text-align: center;
        width: 100%;
      }
    }
  `,
  right: css`
    width: 60%;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  rightRevert: css`
    width: 60%;
    padding-left: 56px;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      padding-left: 0;
    }
  `,

  heading: css`
    color: #cb1234;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 8px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      margin-top: 40px;
      letter-spacing: 0.01em;
    }
  `,
  title: css`
    color: #253645;
    font-size: 48px;
    font-weight: 800;
    line-height: 61px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
    }
  `,
  hexa: css`
    opacity: 0.3;
  `,
  description: css`
    color: #354450;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.01em;
    margin-top: 16px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      letter-spacing: 0.01em;
      margin-top: 24px;
    }
  `,
  action: css`
    margin-top: 32px;
  `,
};
