
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const ANT: JSX.Element = (
    <React.Fragment>
        <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g id="flat">
                <path d="m3 11h58v42h-58z" fill="#FFFFFF" />
                <path d="m25 11h14v14h-14z" fill="#DD1017" />
                <path d="m25 39h14v14h-14z" fill="#DD1017" />
                <path d="m25 3h14v58h-14z" fill="#002588" transform="matrix(0 1 -1 0 64 0)" />
                <g fill="#FFFFFF">
                    <path d="m28 28h2v2h-2z" />
                    <path d="m28 34h2v2h-2z" />
                    <path d="m34 28h2v2h-2z" />
                    <path d="m34 34h2v2h-2z" />
                    <path d="m39 31h2v2h-2z" />
                    <path d="m23 31h2v2h-2z" />
                </g>
            </g>
        </svg>
    </React.Fragment>
);