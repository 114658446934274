/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import * as React from 'react';
import ChinaBondsCIBMView from "../../core/components/ChinaBonds/index-cibm";

export const ChinaCIBM: React.FunctionComponent = (): React.ReactElement => {

    return (
        <ChinaBondsCIBMView/>
    );
}
    