
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const MAR: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#C1272D" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <path fill="#006233" d="M297.951,277.962l67.433-49.478h-83.509L256,148.846l-25.875,79.638h-83.509l67.433,49.478 l-25.675,79.022l67.56-48.289l0.066,0.047l0.066-0.047l67.56,48.289L297.951,277.962z M311.478,246.139l-19.273,14.141 l-4.595-14.141L311.478,246.139L311.478,246.139z M234.784,271.278l8.169-25.14h26.094l8.169,25.14L256,286.846L234.784,271.278z M256,205.984l7.31,22.5h-14.622L256,205.984z M200.522,246.139h23.868l-4.595,14.141L200.522,246.139z M221.737,311.432 l7.302-22.472l11.911,8.739L221.737,311.432z M282.962,288.96l7.302,22.472L271.052,297.7L282.962,288.96z" />
        </svg>
    </React.Fragment>
);
