/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import * as React from 'react';
import TradingNewsView from "../../core/components/DataMarket/TradingNews";


export const TradingNews: React.FunctionComponent = (): React.ReactElement => {
    return (
        <TradingNewsView/>
    );
}
    