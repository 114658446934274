
/** @jsx jsx */
import { jsx } from "@emotion/react"
import { Checkbox, InputField, OptionsData, SelectBox } from "@bdl-cmn-shared-packages-npm/design-system";
import style from "./index.style";
import inputStyle from "../input.style";
import React from "react";
import { LGXEligibleCategories } from "../../../../../../enums/lgx-eligible-categories";

export interface EligibleCategoryFilterProps {
  selection: LGXEligibleCategories[]
  onSelectionChange: (value: any) => void
};

export const eligibleCategoriesItems: OptionsData[] = [
  { value: 'RENEWABLE_ENERGY_GREEN', name: 'Renewable Energy' },
  { value: 'POLLUTION_PREVENTION_GREEN', name: 'Pollution Prevention' },
  { value: 'BIODIVERSITY_CONSERVATION_GREEN', name: 'Biodiversity Conservation' },
  { value: 'SUSTAINABLE_WATER_MANAGEMENT_GREEN', name: 'Sustainable Water Management' },
  { value: 'ECOEFFICIENT_PRODUCTS_GREEN', name: 'Eco efficient Products' },
  { value: 'ENERGY_EFFICIENCY_GREEN', name: 'Energy Efficiency' },
  { value: 'SUSTAINABLE_MANAGEMENT_GREEN', name: 'Sustainable Management' },
  { value: 'CLEAN_TRANSPORTATION_GREEN', name: 'Clean Transportation' },
  { value: 'CLIMATE_CHANGE_ADAPTATION_GREEN', name: 'Climate Change Adaptation' },
  { value: 'GREEN_BUILDINGS_GREEN', name: 'Green Buildings' },
  { value: 'AFFORDABLE_INFRASTRUCTURE_SOCIAL', name: 'Affordable Infrastructure' },
  { value: 'ESSENTIAL_SERVICES_SOCIAL', name: 'Essential Services' },
  { value: 'AFFORDABLE_HOUSING_SOCIAL', name: 'Affordable Housing' },
  { value: 'EMPLOYMENT_GENERATION_SOCIAL', name: 'Employment Generation' },
  { value: 'FOOD_SECURITY_SOCIAL', name: 'Food Security' },
  { value: 'SOCIOECONOMIC_ADV_SOCIAL', name: 'Socioeconomic ADV' },
  // { value: 'ENERGY_SAVING_PBOC', name: 'Energy Saving PBOC' },
  // { value: 'POLLUTION_PREVENTION_AND_CONTROL_PBOC', name: 'Pollution Prevention And Control PBOC' },
  // { value: 'RESOURCE_CONSERVATION_AND_RECYCLING_PBOC', name: 'Resource Conservation And Recycling PBOC' },
  // // { value: 'CLEAN_TRANSPORT_PBOC', name: 'Clean Transport PBOC' },
  // { value: 'CLEAN_ENERGY_PBOC', name: 'Clean Energy PBOC' },
  // { value: 'ECOPROTECTION_AND_CLIMATE_ADAPTATION_PBOC', name: 'Eco protection And Climate Adaptation PBOC' },
  // { value: 'USEFUL_AND_AFFORDABLE_INFRASTRUCTURE_SUSTAINABLE', name: 'Useful And Affordable Infrastructure Sustainable' },
  // { value: 'FINANCIAL_SECTORDEV_SUSTAINABLE', name: 'Financial Sector dev Sustainable' },
  // { value: 'CLIMATE_CHANGE_MITIGATION_SUSTAINABLE', name: 'Climate Change Mitigation Sustainable' },
  // { value: 'CLIMATE_SMART_AGRICULTURE_SUSTAINABLE', name: 'Climate Smart Agriculture Sustainable' },
  { value: 'ICT_CBI', name: 'ICT CBI' },
  { value: 'BUILDINGS_CBI', name: 'Buildings CBI' },
  // { value: 'CIRCULAR_ECONOMY_NDRC', name: 'Circular economy NDRC' },
  // { value: 'CLEAN_AND_EFFICIENT_USE_OF_ENERGY_NDRC', name: 'Clean and efficient use of energy NDRC' },
  // { value: 'ECOLOGICAL_AGRICULTURE_AND_FORESTRY_NDRC', name: 'Ecological agriculture and forestry NDRC' },
  // { value: 'ECOLOGICAL_CIVILISATION_DEMONSTRATION_PROJECTS_NDRC', name: 'Ecological civilisation demonstration projects NDRC' },
  { value: 'ENERGY_CBI', name: 'Energy CBI' },
  // { value: 'ENERGY_SAVING_AND_ENVIRONMENTAL_PROTECTION_INDUSTRY_NDRC', name: 'Energy saving and environmental protection industry NDRC' },
  // { value: 'GREEN_URBANISATION_ENERGY_SAVING_NDRC', name: 'Green urbanisation energy saving NDRC' },
  // { value: 'GREEN_URBANISATION_TRANSPORT_NDRC', name: 'Green urbanisation transport NDRC' },
  { value: 'INDUSTRY_CBI', name: 'Industry CBI' },
  { value: 'LAND_USE_MARINE_RESOURCES_CBI', name: 'Land use marine resources CBI' },
  // { value: 'LOW_CARBON_DEMONSTRATION_NDRC', name: 'Low carbon demonstration NDRC' },
  // { value: 'LOW_CARBON_INDUSTRY_NDRC', name: 'Low carbon industry NDRC' },
  // { value: 'NEW_ENERGY_NDRC', name: 'New energy NDRC' },
  { value: 'OTHER_ELIGIBLE_CATEGORIES', name: 'Other eligible categories' },
  // { value: 'POLLUTION_PREVENTION_AND_CONTROL_NDRC', name: 'Pollution prevention and control NDRC' },
  // { value: 'TECHNOLOGY_IMPROVEMENT_NDRC', name: 'Technology improvement NDRC' },
  { value: 'TRANSPORT_CBI', name: 'Transport CBI' },
  { value: 'WASTE_POLLUTION_CONTROL_CBI', name: 'Waste pollution control CBI' },
  { value: 'WATER_CBI', name: 'Water CBI' },
  // { value: 'WATER_SAVING_AND_UNCONVENTIONAL_WATER_USE_NDRC', name: 'Water saving and unconventional water use NDRC' },
  { value: 'PRODUCTION_TECHNOLOGIES_AND_PROCESSES', name: 'Production technologies and processes' },
];

const valuesMap = eligibleCategoriesItems.reduce((acc, option) => {
  acc[option.value] = option;
  return acc;
}, {} as any);

export function eligibleCategoryLabel( value :string ){
  let category = eligibleCategoriesItems.find((v) => v.name == value);

  return category? category.name : value;
}

export function EligibleCategoryFilter({
  selection,
  onSelectionChange
}: EligibleCategoryFilterProps) {
  const [filter, setFilter] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const label = React.useMemo(() => {
    const number = selection? selection.length : 0;

    return number == 0 ?
      "Eligible categories" :
      number === 1 ?
        eligibleCategoryLabel(selection[0]) :
        `${number} elements selected`;
  }, [selection]);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const clickOutside = (event: any) => {
        if (!ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener('click', clickOutside);

      return () => document.removeEventListener('click', clickOutside);
    }
  }, [open]);

  const values: OptionsData[] = React.useMemo(() => {
    return eligibleCategoriesItems
      .filter((value: OptionsData) => value.name.toUpperCase().includes(filter.toUpperCase()));
  }, [filter]);

  const handleSelectionChange = (cat: OptionsData) => {
    onSelectionChange(cat.value);
  }

  return (
    <>
      <div ref={ref} css={style.container} onClick={(e) => e.stopPropagation()}>
        <div css={inputStyle.inputWrapper}>
          <InputField
            onFocus={() => setOpen(true)}
            value={open ? filter : null}
            onClick={(e) => e.stopPropagation()}
            onChange={(e: any) => setFilter(e.target.value)}
            placeholder={open ? "Eligible categories" : label}
          />
        </div>
        {
          open && (
            <>
              <div css={style.panel} onClick={(e) => e.stopPropagation()}>
                { values.map( (value : OptionsData) => {
                    if( !value )
                      return <></>;
                    
                    return (
                      <React.Fragment key={`eligible-${value.value}-${value.name}`}>
                        <Checkbox
                          label={value.name}
                          onClick={() => handleSelectionChange(value)}
                          checked={selection?.includes(value.value as LGXEligibleCategories)}
                        />
                      </React.Fragment>
                    );
                  })
                }
              </div>
            </>
          )
        }
      </div>
    </>
  );
}
