
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const THA: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M503.2,423.7H8.8c-4.9,0-8.8-4-8.8-8.8V97.1c0-4.9,4-8.8,8.8-8.8h494.3c4.9,0,8.8,4,8.8,8.8v317.8 C512,419.8,508,423.7,503.2,423.7z" />
            <g>
                <path fill="#EF3340" d="M503.2,423.7H8.8c-4.9,0-8.8-4-8.8-8.8v-44.1h512v44.1C512,419.8,508,423.7,503.2,423.7z" />
                <path fill="#EF3340" d="M8.8,88.3h494.3c4.9,0,8.8,4,8.8,8.8v44.1H0V97.1C0,92.2,4,88.3,8.8,88.3z" />
            </g>
            <rect y="194.2" fill="#00247D" width="512" height="123.6" />
        </svg>
    </React.Fragment>
);
