
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const NZL: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#012169" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <path fill="#FFFFFF" d="M247.172,88.277h-15.334l-81.77,53.574V88.277H105.93v53.574L24.16,88.277H8.828 C3.953,88.277,0,92.229,0,97.104v7.002l70.155,45.963H0v44.138h70.155L0,240.171v7.002c0,4.875,3.953,8.828,8.828,8.828h15.334 l81.77-53.574v53.574h44.138v-53.574l81.769,53.574h15.334c4.875,0,8.828-3.953,8.828-8.828v-7.002l-70.155-45.963H256V150.07 h-70.155L256,104.107v-7.002C256,92.229,252.047,88.277,247.172,88.277z" />
            <g>
                <polygon fill="#C8102E" points="256,158.898 141.241,158.898 141.241,88.277 114.759,88.277 114.759,158.898 0,158.898 0,185.38 114.759,185.38 114.759,256.001 141.241,256.001 141.241,185.38 256,185.38 " />
                <path fill="#C8102E" d="M97.103,150.07L4.207,89.769C1.735,91.333,0,93.962,0,97.104v0.458l80.889,52.508H97.103z" />
                <path fill="#C8102E" d="M166.283,150.07l88.374-57.366c-1.536-2.599-4.246-4.427-7.485-4.427h-1.909l-95.194,61.793H166.283 z" />
                <path fill="#C8102E" d="M89.474,194.208L1.269,251.464c1.521,2.662,4.273,4.537,7.559,4.537h1.665l95.196-61.793H89.474z" />
                <path fill="#C8102E" d="M255.86,247.866l-82.666-53.658H156.98l93.874,60.935 C253.666,253.838,255.595,251.103,255.86,247.866z" />
                <path fill="#C8102E" d="M395.474,368.114c-0.358,0-0.719-0.113-1.026-0.328L384,360.32l-10.449,7.466 c-0.306,0.215-0.668,0.328-1.026,0.328c-0.366,0-0.728-0.113-1.038-0.337c-0.612-0.448-0.875-1.241-0.647-1.965l3.875-12.242 l-10.327-7.62c-0.612-0.457-0.866-1.241-0.633-1.965c0.237-0.724,0.905-1.216,1.668-1.224l12.841-0.104l4.061-12.181 c0.242-0.715,0.918-1.207,1.677-1.207s1.436,0.491,1.677,1.207l4.061,12.181l12.841,0.104c0.763,0.009,1.431,0.5,1.668,1.224 c0.233,0.724-0.022,1.508-0.633,1.965l-10.327,7.62l3.875,12.242c0.228,0.724-0.034,1.517-0.647,1.965 C396.203,368.001,395.837,368.114,395.474,368.114z" />
            </g>
            <path fill="#FFFFFF" d="M384,331.035l4.461,13.378l14.102,0.109l-11.345,8.377l4.254,13.445L384,358.145l-11.473,8.201 l4.254-13.445l-11.345-8.377l14.102-0.109L384,331.035 M384,327.504c-1.519,0-2.869,0.972-3.35,2.414l-3.663,10.984l-11.578,0.09 c-1.519,0.011-2.861,0.994-3.331,2.44c-0.47,1.446,0.039,3.029,1.26,3.932l9.314,6.878l-3.492,11.039 c-0.458,1.449,0.062,3.029,1.291,3.922c0.619,0.449,1.347,0.674,2.076,0.674c0.719,0,1.439-0.22,2.054-0.659l9.42-6.733l9.42,6.733 c0.615,0.438,1.334,0.659,2.053,0.659c0.728,0,1.457-0.225,2.076-0.674c1.229-0.894,1.749-2.473,1.291-3.922l-3.492-11.039 l9.314-6.878c1.223-0.903,1.73-2.486,1.26-3.932c-0.47-1.445-1.811-2.429-3.331-2.44l-11.578-0.09l-3.663-10.984 C386.869,328.477,385.519,327.504,384,327.504L384,327.504z" />
            <path fill="#C8102E" d="M395.474,225.794c-0.362,0-0.719-0.113-1.026-0.328L384,217.993l-10.449,7.474 c-0.306,0.215-0.668,0.328-1.026,0.328c-0.362,0-0.728-0.113-1.038-0.337c-0.612-0.448-0.875-1.241-0.647-1.965l3.875-12.242 l-10.327-7.629c-0.612-0.457-0.866-1.25-0.633-1.965c0.237-0.724,0.909-1.216,1.668-1.224l12.841-0.095l4.061-12.181 c0.242-0.715,0.918-1.207,1.677-1.207s1.436,0.491,1.677,1.207l4.061,12.181l12.841,0.095c0.759,0.009,1.431,0.5,1.668,1.224 c0.233,0.715-0.022,1.508-0.633,1.965l-10.327,7.629l3.875,12.242c0.228,0.724-0.034,1.517-0.647,1.965 C396.203,225.682,395.837,225.794,395.474,225.794z" />
            <path fill="#FFFFFF" d="M384,188.714l4.461,13.378l14.102,0.109l-11.345,8.377l4.254,13.446L384,215.823l-11.473,8.201 l4.254-13.446l-11.345-8.377l14.102-0.109L384,188.714 M384,185.183c-1.519,0-2.869,0.972-3.35,2.414l-3.663,10.984l-11.578,0.09 c-1.519,0.011-2.861,0.994-3.331,2.44s0.039,3.029,1.26,3.932l9.314,6.878l-3.492,11.039c-0.458,1.449,0.062,3.029,1.291,3.922 c0.619,0.449,1.347,0.674,2.076,0.674c0.719,0,1.439-0.22,2.054-0.659l9.42-6.733l9.42,6.733c0.615,0.438,1.334,0.659,2.053,0.659 c0.728,0,1.457-0.225,2.076-0.674c1.229-0.894,1.749-2.473,1.291-3.922l-3.492-11.039l9.314-6.878 c1.223-0.903,1.73-2.486,1.26-3.932s-1.811-2.429-3.331-2.44l-11.578-0.09l-3.663-10.984 C386.869,186.155,385.519,185.183,384,185.183L384,185.183z" />
            <path fill="#C8102E" d="M457.267,269.932c-0.362,0-0.719-0.113-1.026-0.328l-10.447-7.474l-10.449,7.474 c-0.306,0.215-0.668,0.328-1.026,0.328c-0.362,0-0.728-0.113-1.038-0.337c-0.612-0.448-0.875-1.241-0.647-1.965l3.875-12.242 l-10.327-7.629c-0.612-0.457-0.866-1.25-0.633-1.965c0.237-0.724,0.909-1.216,1.668-1.224l12.841-0.095l4.061-12.181 c0.242-0.715,0.918-1.207,1.677-1.207s1.436,0.491,1.677,1.207l4.061,12.181l12.841,0.095c0.759,0.009,1.431,0.5,1.668,1.224 c0.233,0.715-0.022,1.508-0.633,1.965l-10.327,7.629l3.875,12.242c0.228,0.724-0.034,1.517-0.647,1.965 C457.996,269.819,457.63,269.932,457.267,269.932z" />
            <path fill="#FFFFFF" d="M445.793,232.852l4.461,13.378l14.102,0.109l-11.345,8.377l4.254,13.446l-11.473-8.201l-11.473,8.201 l4.254-13.445l-11.345-8.377l14.102-0.109L445.793,232.852 M445.793,229.321c-1.519,0-2.869,0.972-3.35,2.414l-3.663,10.984 l-11.578,0.09c-1.519,0.011-2.861,0.994-3.331,2.44s0.039,3.029,1.26,3.932l9.314,6.878l-3.492,11.039 c-0.458,1.449,0.062,3.029,1.291,3.922c0.619,0.449,1.347,0.674,2.076,0.674c0.719,0,1.439-0.22,2.053-0.659l9.42-6.733l9.42,6.733 c0.615,0.438,1.334,0.659,2.054,0.659c0.728,0,1.457-0.225,2.076-0.674c1.229-0.894,1.749-2.473,1.291-3.922l-3.492-11.039 l9.314-6.878c1.223-0.903,1.73-2.486,1.26-3.932s-1.811-2.429-3.331-2.44l-11.578-0.09l-3.663-10.984 C448.662,230.293,447.313,229.321,445.793,229.321L445.793,229.321z" />
            <path fill="#C8102E" d="M333.681,287.587c-0.362,0-0.719-0.113-1.026-0.328l-10.447-7.474l-10.449,7.474 c-0.306,0.215-0.668,0.328-1.026,0.328c-0.362,0-0.728-0.113-1.038-0.337c-0.612-0.448-0.875-1.241-0.647-1.965l3.875-12.242 l-10.327-7.629c-0.612-0.457-0.866-1.25-0.633-1.965c0.237-0.724,0.909-1.216,1.668-1.224l12.841-0.095l4.061-12.181 c0.242-0.715,0.918-1.207,1.677-1.207s1.436,0.491,1.677,1.207l4.061,12.181l12.841,0.095c0.759,0.009,1.431,0.5,1.668,1.224 c0.233,0.715-0.022,1.508-0.633,1.965l-10.327,7.629l3.875,12.242c0.228,0.724-0.034,1.517-0.647,1.965 C334.41,287.475,334.044,287.587,333.681,287.587z" />
            <path fill="#FFFFFF" d="M322.207,250.507l4.461,13.378l14.102,0.109l-11.345,8.377l4.254,13.446l-11.473-8.201l-11.473,8.201 l4.254-13.446l-11.345-8.377l14.102-0.109L322.207,250.507 M322.207,246.976c-1.519,0-2.869,0.972-3.35,2.414l-3.663,10.984 l-11.578,0.09c-1.519,0.011-2.861,0.994-3.331,2.44c-0.47,1.446,0.039,3.029,1.26,3.932l9.314,6.878l-3.492,11.039 c-0.458,1.449,0.062,3.029,1.291,3.922c0.619,0.449,1.347,0.674,2.076,0.674c0.719,0,1.439-0.22,2.053-0.659l9.42-6.733l9.42,6.733 c0.615,0.438,1.334,0.659,2.053,0.659c0.728,0,1.457-0.225,2.076-0.674c1.229-0.894,1.749-2.473,1.291-3.922l-3.492-11.039 l9.314-6.878c1.223-0.903,1.73-2.486,1.26-3.932c-0.47-1.446-1.811-2.429-3.331-2.44l-11.578-0.09l-3.663-10.984 C325.076,247.948,323.726,246.976,322.207,246.976L322.207,246.976z" />
        </svg>
    </React.Fragment>
);
