
/** @jsx jsx */
import { jsx } from "@emotion/react"
import * as React from 'react';
import { FromToFilter, FromToValueProps } from "../from-to-filter";

export interface FromToValue {
  from: number | null;
  to: number | null;
}

export type YieldFilterProps = FromToValueProps;

export function YieldFilter(props: YieldFilterProps) {

  return (
    <FromToFilter icon="tag"
      label="Yield"
      {...props}
    />
  );
}