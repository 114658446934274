
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const ECU: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#EF3340" d="M512,339.863H0v75.034c0,4.875,3.953,8.828,8.828,8.828h494.345c4.875,0,8.828-3.953,8.828-8.828 L512,339.863L512,339.863z" />
            <path fill="#FFD100" d="M512,256.001H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,256.001L512,256.001z" />
            <rect y="256.001" fill="#0072CE" width="512" height="83.86" />
            <path fill="#FFD100" d="M246.788,260.954l64.48-55.268v79.987c0,13.481-10.929,24.408-24.408,24.408l0,0 c-6.03,0-11.847-2.232-16.329-6.265l-23.743-21.368L246.788,260.954L246.788,260.954z" />
            <path fill="#EF3340" d="M280.801,292.405l-18.66-16.794v-27.815l33.775-28.95v20.221v46.607c0,4.993-4.063,9.056-9.056,9.056 C284.619,294.73,282.467,293.904,280.801,292.405z" />
            <path fill="#0072CE" d="M271.352,239.901v31.617l15.352,13.862v-46.505v-12.132l9.212-7.896v20.221v46.607 c0,4.993-4.063,9.056-9.056,9.056c-2.241,0-4.393-0.825-6.059-2.325l-18.66-16.794v-27.815L271.352,239.901z" />
            <path fill="#FFD100" d="M265.212,260.954l-64.48-55.268v79.987c0,13.481,10.929,24.408,24.408,24.408l0,0 c6.03,0,11.847-2.232,16.329-6.265l23.743-21.368V260.954z" />
            <path fill="#EF3340" d="M231.199,292.405l18.66-16.794v-27.815l-33.775-28.95v20.221v46.607c0,4.993,4.063,9.056,9.056,9.056 C227.381,294.73,229.533,293.904,231.199,292.405z" />
            <g>
                <path fill="#0072CE" d="M240.648,239.901v31.617l-15.352,13.862v-46.505v-12.132l-9.212-7.896v20.221v46.607 c0,4.993,4.063,9.056,9.056,9.056c2.241,0,4.393-0.825,6.059-2.325l18.66-16.794v-27.815L240.648,239.901z" />
                <rect x="309.04" y="205.961" fill="#0072CE" width="4.414" height="26.449" />
                <rect x="198.51" y="205.961" fill="#0072CE" width="4.414" height="26.449" />
            </g>
            <g>
                <path fill="#5A4646" d="M246.788,193.405c0,0,6.141-29.17-15.352-25.332s-21.493,9.978-46.057,22.261 c0,0,27.634,6.141,39.916,3.071c0,0,9.212,12.281,18.423,15.352h9.212L246.788,193.405z" />
                <path fill="#5A4646" d="M265.212,193.405c0,0-6.141-29.17,15.352-25.332c21.493,3.838,21.493,9.978,46.057,22.261 c0,0-27.634,6.141-39.916,3.071c0,0-9.212,12.281-18.423,15.352h-9.212L265.212,193.405z" />
                <path fill="#5A4646" d="M265.212,208.757h-18.423v-15.466c0-5.025,4.073-9.098,9.098-9.098h0.227 c5.025,0,9.098,4.073,9.098,9.098L265.212,208.757L265.212,208.757z" />
            </g>
            <circle fill="#E6E1CD" cx="256" cy="193.401" r="6.141" />
            <path fill="#FFD0AF" d="M242.823,189.439l11.546,6.166c0.969,0.518,2.162,0.34,2.937-0.437l0,0 c0.974-0.974,0.974-2.552,0-3.527l-7.448-7.448h-1.791C245.171,184.193,242.823,186.541,242.823,189.439L242.823,189.439z" />
            <path fill="#FFAF94" d="M246.827,190.334h-9.249l4.047-4.738c0.76-0.889,1.871-1.402,3.042-1.402h5.192v3.108 C249.859,188.976,248.501,190.334,246.827,190.334z" />
            <g>
                <path fill="#8CB400" d="M282.186,208.765c-2.072-2.458-2.514-5.717-1.504-8.541c2.954-0.516,6.091,0.471,8.162,2.931 c1.77,2.101,2.283,4.772,1.784,7.272c0.614,1.042,1.364,2.382,2.189,3.964c0.041-0.225,0.032-0.448,0.096-0.673 c0.724-2.546,2.743-4.348,5.135-4.958c1.713,1.778,2.483,4.373,1.759,6.918c-0.622,2.19-2.228,3.777-4.18,4.581 c0.771,1.769,1.534,3.677,2.247,5.69c0.041-0.118,0.044-0.238,0.092-0.355c1-2.451,3.206-4.019,5.65-4.362 c1.506,1.955,1.986,4.619,0.987,7.07c-0.875,2.144-2.69,3.558-4.764,4.121c1.068,4.213,1.775,8.683,1.775,13.183h-3.071 c0-3.922-0.602-7.85-1.511-11.608c-1.895,0.612-4.012,0.481-5.845-0.641c-2.236-1.37-3.416-3.778-3.361-6.223 c2.154-1.16,4.835-1.203,7.071,0.168c0.081,0.049,0.135,0.121,0.213,0.173c-0.658-1.89-1.358-3.683-2.077-5.365 c-1.843,0.871-4.012,1.005-6.002,0.071c-2.374-1.116-3.811-3.379-4.026-5.815c2.006-1.386,4.657-1.721,7.023-0.62 c-0.845-1.625-1.608-2.986-2.206-4.002C285.707,211.5,283.67,210.526,282.186,208.765z" />
                <path fill="#8CB400" d="M229.814,208.765c2.072-2.458,2.514-5.717,1.504-8.541c-2.954-0.516-6.091,0.471-8.162,2.931 c-1.77,2.101-2.283,4.772-1.784,7.272c-0.614,1.042-1.364,2.382-2.189,3.964c-0.041-0.225-0.032-0.448-0.096-0.673 c-0.724-2.546-2.743-4.348-5.135-4.958c-1.713,1.778-2.483,4.373-1.759,6.918c0.622,2.19,2.228,3.777,4.18,4.581 c-0.771,1.769-1.534,3.677-2.247,5.69c-0.041-0.118-0.044-0.238-0.092-0.355c-1-2.451-3.206-4.019-5.65-4.362 c-1.506,1.955-1.986,4.619-0.986,7.07c0.875,2.144,2.69,3.558,4.764,4.121c-1.068,4.213-1.775,8.683-1.775,13.183h3.071 c0-3.922,0.602-7.85,1.511-11.608c1.895,0.612,4.012,0.481,5.845-0.641c2.237-1.37,3.416-3.778,3.361-6.223 c-2.154-1.16-4.835-1.203-7.071,0.168c-0.081,0.049-0.135,0.121-0.213,0.173c0.658-1.89,1.358-3.683,2.077-5.365 c1.843,0.871,4.012,1.005,6.002,0.071c2.374-1.116,3.811-3.379,4.026-5.815c-2.006-1.386-4.657-1.721-7.023-0.62 c0.845-1.625,1.608-2.986,2.206-4.002C226.293,211.5,228.33,210.526,229.814,208.765z" />
            </g>
            <path fill="#FFD100" d="M256,276.307l-64.48-55.268v79.987c0,13.481,10.929,24.408,24.408,24.408l0,0 c6.03,0,11.847-2.232,16.329-6.265L256,297.801V276.307z" />
            <path fill="#EF3340" d="M221.987,307.757l18.66-16.794v-27.815l-33.775-28.95v20.221v46.607c0,4.993,4.063,9.056,9.056,9.056 C218.17,310.082,220.321,309.257,221.987,307.757z" />
            <path fill="#0072CE" d="M231.436,255.253v31.617l-15.352,13.862v-46.505v-12.132l-9.212-7.896v20.221v46.607 c0,4.993,4.063,9.056,9.056,9.056c2.241,0,4.393-0.825,6.059-2.325l18.66-16.794v-27.815L231.436,255.253z" />
            <path fill="#FFD100" d="M256,276.307l64.48-55.268v79.987c0,13.481-10.929,24.408-24.408,24.408l0,0 c-6.03,0-11.847-2.232-16.329-6.265L256,297.801V276.307z" />
            <path fill="#EF3340" d="M290.013,307.757l-18.66-16.794v-27.815l33.775-28.95v20.221v46.607c0,4.993-4.063,9.056-9.056,9.056 C293.829,310.082,291.679,309.257,290.013,307.757z" />
            <path fill="#0072CE" d="M280.564,255.253v31.617l15.352,13.862v-46.505v-12.132l9.212-7.896v20.221v46.607 c0,4.993-4.063,9.056-9.056,9.056c-2.241,0-4.393-0.825-6.059-2.325l-18.66-16.794v-27.815L280.564,255.253z" />
            <ellipse fill="#69C3FF" cx="256" cy="254.811" rx="30.705" ry="46.06" />
            <path fill="#009B64" d="M228.366,267.096h55.268l3.071-12.281l-13.993,2.333c-2.807,0.468-5.678-0.332-7.839-2.184 l-15.884-13.615c-1.419-1.217-3.228-1.886-5.097-1.886l0,0c-2.077,0-4.07,0.825-5.538,2.294l-9.988,9.988L228.366,267.096 L228.366,267.096z" />
            <path fill="#F5F5F5" d="M253.087,252.751l5.274-3.37l-9.374-8.034c-1.419-1.217-3.228-1.886-5.097-1.886l0,0 c-2.077,0-4.07,0.825-5.538,2.294l-9.988,9.988v3.071l15.352-3.071l2.714,1.357C248.558,254.164,251.085,254.03,253.087,252.751z" />
            <polygon fill="#5AA0E6" points="228.366,267.096 231.436,285.518 246.788,300.871 256,300.871 268.281,297.801 280.564,285.518 283.634,267.096 " />
            <path fill="#FFC88C" d="M256,303.941c-18.624,0-33.775-22.039-33.775-49.128s15.151-49.128,33.775-49.128 s33.775,22.039,33.775,49.128S274.624,303.941,256,303.941z M256,211.828c-15.236,0-27.634,19.284-27.634,42.987 s12.397,42.987,27.634,42.987s27.634-19.284,27.634-42.987S271.236,211.828,256,211.828z" />
            <g>
                <path fill="#E1E1E1" d="M234.339,300.595c-2.574-3.554-6.406-5.865-10.746-5.865s-8.172,2.311-10.746,5.865 c4.39,1.217,7.982,4.781,9.683,9.479c0.685,1.892,1.064,3.968,1.064,6.15C223.593,308.628,228.182,302.302,234.339,300.595z" />
                <path fill="#E1E1E1" d="M226.031,310.282c0,0-0.239,4.094-2.339,6.013l-0.088-0.072c-2.099-1.919-2.339-6.013-2.339-6.013 L226.031,310.282z" />
            </g>
            <path fill="#966446" d="M294.4,311.617h-76.8c-0.838,0-1.516-0.679-1.516-1.516v-0.038c0-0.838,0.679-1.516,1.516-1.516h76.8 c0.838,0,1.516,0.679,1.516,1.516v0.038C295.916,310.938,295.238,311.617,294.4,311.617z" />
            <path fill="#BE915A" d="M271.428,316.223h-30.856c-3.35,0-6.066-2.716-6.066-6.066v-0.151c0-3.35,2.716-6.066,6.066-6.066 h30.856c3.35,0,6.066,2.716,6.066,6.066v0.151C277.493,313.507,274.777,316.223,271.428,316.223z" />
            <path fill="#B9B9B9" d="M228.366,308.547H217.62c-0.847,0-1.535,0.687-1.535,1.535c0,0.848,0.687,1.535,1.535,1.535h10.746 V308.547z" />
            <polygon fill="#EF3340" points="256,316.223 256,344.277 243.719,340.787 249.859,316.223 " />
            <polygon fill="#0072CE" points="252.929,316.223 246.788,337.717 234.507,334.646 243.719,316.223 " />
            <polygon fill="#FFD100" points="240.648,316.223 231.436,328.505 240.648,331.575 246.788,316.223 " />
            <polygon fill="#EF3340" points="256,316.223 256,344.277 268.281,340.787 262.141,316.223 " />
            <polygon fill="#0072CE" points="259.071,316.223 265.212,337.717 277.493,334.646 268.281,316.223 " />
            <polygon fill="#FFD100" points="271.352,316.223 280.564,328.505 271.352,331.575 265.212,316.223 " />
            <g>
                <polygon fill="#0072CE" points="193.728,247.483 189.314,243.035 189.314,221.036 193.728,221.036 " />
                <polygon fill="#0072CE" points="322.646,243.035 318.232,247.483 318.232,221.036 322.646,221.036 " />
            </g>
            </svg>
    </React.Fragment>
);
