
/*
*   e-Listing - core - components - country flag icon - cze
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const CZE: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.337' style={{fill: '#0052B4'}} width='512' height='341.326'/>
        <polygon style={{fill: '#D80027'}} points='512,256 512,426.663 0,426.663 215.185,256 '/>
        <polygon style={{fill: '#F0F0F0'}} points='512,85.337 512,256 215.185,256 0,85.337 '/>
    </svg>
);
