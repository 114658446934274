export const conditions = [
    {
        title: "Where do you want to list?",
        moreLink: '/link1',
        items: [
            {
                label: 'Regulated Market (Bourse de Luxembourg)',
                index: 1,
                relatedIndex: 1,
                headerLabel: 'Regulated'
            },
            {
                label: 'Exchange Regulated Market (Euro MTF)',
                index: 2,
                relatedIndex: 10,
                headerLabel: 'Euro MTF'
            },
            {
                label: 'Securities Official List (LuxSE SOL)',
                index: 3,
                relatedIndex: 19,
                headerLabel: 'LuxSE SOL'
            }
        ]
    },{
        title: "What type of issuer are you?",
        moreLink: '/link2',
        items: [
            {
                label: 'Corporate, special purpose vehicle (SPV), credit institution  ',
                index: 1,
                relatedIndex: 1,
                headerLabel: 'Corporate, SPV, Credit Institution'
            },
            {
                label: 'EU Member State or a regional and local authority thereof, EU-recognised public international body (PIB), agency and other exempted issuer under Prospectus Regulation ',
                index: 2,
                relatedIndex: 2,
                headerLabel: 'EU Member State, regional and local authority, PIB'
            },
            {
                label: 'Sovereign (Non-EU Member State) ',
                index: 3,
                relatedIndex: 3,
                headerLabel: 'Sovereign (Non-EU Member State)'
            },
            {
                label: 'Open-ended undertaking for collective investment (UCI) under Luxembourg law',
                index: 4,
                relatedIndex: 4,
                headerLabel: 'Open-ended undertaking'
            }
        ]
    },{
        title: "What product do you want to list?",
        eligibleToLgx: true,
        eligibleText: 'Is your product sustainable and eligible for display on AllSecurities?',
        eligibleMore: '',
        moreLink: '/link3',
        items: [
            {
                label: 'Debt security',
                index: 1,
                relatedIndex: 1,
                headerLabel: 'Debt security'
            },
            {
                label: 'Asset-backed security (ABS)',
                index: 2,
                relatedIndex: 2,
                headerLabel: 'Asset-backed security (ABS)'
            },
            {
                label: 'Certificate',
                index: 3,
                relatedIndex: 3,
                headerLabel: 'Certificate'
            },
            {
                label: 'Debt and derivative securities that are exchangeable for or convertible into shares',
                index: 4,
                relatedIndex: 4,
                headerLabel: 'Debt and derivative securities that are exchangeable for or convertible into shares'
            },
            {
                label: 'Derivative/structured investment product (SIP)',
                index: 5,
                relatedIndex: 5,
                headerLabel: 'Derivative/structured investment product (SIP)'
            },
            {
                label: 'Warrant',
                index: 6,
                relatedIndex: 6,
                headerLabel: 'Warrant'
            },
            {
                label: 'Money market instrument',
                index: 7,
                relatedIndex: 7,
                headerLabel: 'Money market instrument'
            },
            {
                label: 'Share/Global depositary receipt (GDR)',
                index: 8,
                relatedIndex: 8,
                headerLabel: 'Share/Global depositary receipt (GDR)'
            },
            {
                label: 'Security token',
                index: 9,
                relatedIndex: 9,
                headerLabel: 'Security token'
            }
        ]
    },
]
