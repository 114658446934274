import { breakpoint, breakpointMax } from '../../style';

import { css } from '@emotion/react';

export const style = {
  container: css`
    width: 100%;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 96px;
    margin-top: 96px;
    display: flex;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  left: css`
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-self: center;
    @media (max-width: ${breakpointMax}) {
      align-items: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      padding-right: 0;
    }
  `,
  right: css`
    width: 50%;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
    }
  `,
  bloc: css`
    max-width: calc(50% - 32px);
    display: inline-flex;
    flex-direction: column;
    margin-right: 16px;
    margin-bottom: 32px;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      max-width: 100%;
      margin-left: 45px;
      margin-right: 45px;
      margin-bottom: 60px;
      align-items: center;
    }
  `,
  title: css`
    color: #253645;
    font-size: 20px;
    font-weight: 800;
    line-height: 26px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 16px;
      margin-top: 16px;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  `,
  icon: css`
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    @media (max-width: ${breakpointMax}) {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      align-self: unset;
    }
  `,
  hexa: css`
    opacity: 0.3;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  hoverIcon: css`
    position: absolute;
    color: #cb1234;
    @media (max-width: ${breakpointMax}) {
      margin: 0px;
    }
  `,
  description: css`
    color: #354450;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.01em;
    margin-top: 4px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 400;
      font-size: 14px;
      line-height: 180%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  `,
  top: css`
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    // display: block;
    color: #354450;
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    text-align: center;
    @media (max-width: ${breakpointMax}) {
      // margin-top: 5px;
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0px;
    }
  `,
  bottom: css`
    color: #354450;
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    text-align: center;
    // margin: -10px 100px 0px 100px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0px;
    }
  `,
  content: css`
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    @media (max-width: ${breakpointMax}) {
      margin-top: 70px;
      padding: 16px;
      margin-left: auto;
      margin-right: auto;
    }
  `,
  action: css`
    margin-top: 32px;
  `,
};
