/** @jsx jsx */
import { jsx } from "@emotion/react";

import { style } from "./index.style";
import { Loader as DSLoader } from "@bdl-cmn-shared-packages-npm/design-system";

export interface LoaderProps { }

export function Loader(props: LoaderProps) {
  return (
    <div css={style.backdrop} role="progressbar" aria-busy="true">
      <DSLoader loading spin />
    </div>
  );
}
