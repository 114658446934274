/** @jsx jsx */

import {
  Icon,
  Menu,
  MenuWithChild,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import {
  indexLatestPriceDateLabel,
  indexLatestPriceDateSort,
} from "../../../../../enums/index-latest-price-date";

import CountFilter from "../../../../../interfaces/search/search-filter-count";
import CountItem from "../../../Search/results/filters/count-item";
import SearchFilterParams from "../../../../../interfaces/search/search-filter-params";
import SearchIndexFilters from "../../../../../interfaces/search/search-index-filters";
import { breakpointMax } from "../../../../../style";
import { currencyLabel } from "../../../../../enums";
import { indexTypeLabel } from "../../../../../enums/index-type";
import { style } from "../../../Search/results/filters/index.style";
import { useState } from "react";

export interface IndexFiltersProps {
  filterCount?: SearchIndexFilters;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  fullWidth?: boolean;
}

function computeValues(values: CountFilter[], selection?: any): CountFilter[] {
  if (!selection || (values && !!values.length)) {
    return values || [];
  }
  return [{ name: selection, count: -1 }];
}

export function IndexFilters({
  filterCount,
  filters,
  onFiltersChange,
  fullWidth,
}: IndexFiltersProps) {
  const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    let found = false;
    const parent = event.currentTarget;
    const head = parent.firstChild.firstChild;
    let target = event.target as Element;

    do {
      if (head == target) found = true;

      target = target.parentElement;
    } while (!found && parent != target && target != null);

    if (found) parent.classList.toggle("open");
  };

  const handleFilterChange = (
    key: keyof SearchFilterParams,
    value: any
  ): void => {
    const newFilters: SearchFilterParams = { ...filters };

    if (newFilters[key] instanceof Array && filters[key] instanceof Array){
      try {
        // @ts-ignore
        let array: any[] =
          filters[key] != null ? Object.assign([], filters[key]) : [];
        if (array != null && array.includes(value))
          array = array.filter((v) => {
            return v !== value;
          });
        else array.push(value);
        array = array.filter((v) => v);
        if (array == null || !array.length) delete newFilters[key];
        else {
          // @ts-ignore
          newFilters[key] = array;
        }
      } catch (e) {
        console.error(
          "Failed to updated filter[" + key + "]",
          filters[key],
          " with value",
          value,
          e
        );
      }
    } else {
      if (filters[key] === value) {
        delete newFilters[key];
      } else {
        // @ts-ignore
        newFilters[key] = value;
      }
    }
    onFiltersChange(newFilters);
  };

  const closeBtn = css`
    width: 38px;
    height: 38px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    svg {
      color: #22aa5f;
      width: 25px !important;
      height: 25px !important;
      margin: 0px !important;
    }
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `;

  const [open, setOpen] = useState(true);

  return (
    <div
      css={[
        fullWidth
          ? style.searchResultsFiltersFullWidth
          : style.searchResultsFilters,
        css`
          position: relative;
          transition: all 0.1s ease-in-out;
          margin-left: ${open ? "0" : "-17%"};
          padding-right: ${open ? "16px" : "60px"};
        `,
      ]}
    >
      <div css={closeBtn} onClick={() => setOpen(!open)}>
        <Icon icon={open ? "arrow-left" : "arrow-right"}></Icon>
      </div>
      <div css={style.title}>Filter your search result</div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="user"
          label="Types"
          width="100%;"
          expanded={true}
          arrows={false}
        >
          {computeValues(filterCount && filterCount.types, filters?.indexTypes)
            .filter((item) => item.count > 0)
            .sort((first, second) => second.count - first.count)
            .map((item, index) => {
              if (item.count == -1)
                return <div css={style.menuNoItem}>No filter available</div>;

              return (
                <div css={[style.menuItem, style.menuItemRow]} key={`menu-${index}`}>
                  <Menu
                    key={item.name}
                    width="100%;"
                    selected={
                      filters?.indexTypes &&
                      filters?.indexTypes.includes(item.name)
                    }
                    onSelect={() => handleFilterChange("indexTypes", item.name)}
                    childLabel={
                      (
                        <CountItem
                          name={indexTypeLabel(item.name)}
                          count={item.count}
                        />
                      ) as unknown as string
                    }
                  />
                </div>
              );
            })}
        </MenuWithChild>
      </div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="euro-sign"
          label="Currencies"
          width="100%;"
          expanded={false}
          arrows={false}
        >
          {computeValues(
            filterCount && filterCount.currencies,
            filters?.indexCurrencies
          )
            .filter((item) => item.count > 0)
            .sort((first, second) => second.count - first.count)
            .map((item, index) => {
              if (item.count == -1)
                return <div css={style.menuNoItem}>No filter available</div>;

              return (
                <div css={[style.menuItem, style.menuItemRow]} key={`menu-2-${index}`}>
                  <Menu
                    key={item.name}
                    width="100%;"
                    selected={
                      filters?.indexCurrencies &&
                      filters?.indexCurrencies.includes(item.name)
                    }
                    onSelect={() =>
                      handleFilterChange("indexCurrencies", item.name)
                    }
                    childLabel={
                      (
                        <CountItem
                          name={currencyLabel(item.name)}
                          count={item.count}
                        />
                      ) as unknown as string
                    }
                  />
                </div>
              );
            })}
        </MenuWithChild>
      </div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="calendar"
          label="Latest price date"
          width="100%;"
          expanded={false}
          arrows={false}
        >
          {computeValues(
            filterCount && filterCount.latestPriceDateRanges,
            filters?.indexLatestPriceDate
          )
            .filter((item) => item.count > 0)
            .sort((first, second) =>
              indexLatestPriceDateSort(first.name, second.name)
            )
            .map((item, index) => {
              if (item.count == -1)
                return <div css={style.menuNoItem}>No filter available</div>;

              return (
                <div css={[style.menuItem, style.menuItemRow]} key={`menu-3-${index}`}>
                  <Menu
                    key={item.name}
                    width="100%;"
                    selected={filters?.indexLatestPriceDate === item.name}
                    onSelect={() =>
                      handleFilterChange("indexLatestPriceDate", item.name)
                    }
                    childLabel={
                      (
                        <CountItem
                          name={indexLatestPriceDateLabel(item.name)}
                          count={item.count}
                        />
                      ) as unknown as string
                    }
                  />
                </div>
              );
            })}
        </MenuWithChild>
      </div>
    </div>
  );
}
