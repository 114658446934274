/* eslint-disable */
import * as React from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Button, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import { useEffect, useState } from "react";
import { DOWNLOAD_ENDPOINT } from "../../../../../../backbourse";
import EmptyResults from "../../../../UI/list/base/empty";
import { GET_LUXXPRIME_LATEST_FNS_QUERY } from "../../../../../../graphql/queries/luxxprime.queries";
import Loader from "../../../../Loader/loader.component";
import { formatDate } from "../../../../../../utils/date";
import { luxseGatewayGraphqlClient } from "../../../../../../luxse-gateway-graphql-client";
import { style } from "../../../index.style";
import Utils from "../../../../../utils/utils";

export const LuxXPrimeNoticeLatestFNS = () => {
  const client = luxseGatewayGraphqlClient;
  const limitOffset = 20;
  const [limit, setLimit] = useState<number>(20);

  const [data, setData] = useState<any[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const downloadPath = DOWNLOAD_ENDPOINT;
  const downloadDocumentAction = (url: string) => {
    return downloadPath + url;
  };

  const getData = async () => {
    try {
      const results: any = await client.query({
        query: GET_LUXXPRIME_LATEST_FNS_QUERY,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          limit: limit,
        },
      });

      setDataLoading(false);

      if (results?.data?.luxXPrimeLatestFNS) {
        const tmpData: any[] = [];
        results.data.luxXPrimeLatestFNS.forEach((element: any) => {
          tmpData.push(element);
        });

        setData(tmpData);
      }
    } catch (e) {
      console.error("Failed to get latest FNS", e);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    setDataLoading(true);
    getData();
  }, [limit]);

  return (
    <div css={style.container}>
      <div css={style.content}>
        <Loader loading={dataLoading}>
          {data && data?.length > 0 ? (
            <>
              <div css={[style.contentBody, style.contentBodyList]}>
                {data.map((notice: any, index: number) => (
                  <a
                    css={[style.row, style.rowClickable]}
                    href={downloadDocumentAction(notice?.downloadUrl)}
                  >
                    <div css={style.rowIcon}>
                      <div css={style.rowIconRound}>
                        <Icon icon="file-pdf" />
                      </div>
                    </div>
                    <div css={style.rowContent}>
                      <div css={style.rowHeader}>
                        <div css={style.rowSubTitle}>
                          {notice?.documentPublicTypeTranslatableField?.value}
                        </div>
                        <div css={style.rowTitle}>
                          {notice?.issuers?.map(
                            (issuer: any, index: number) =>
                              `${issuer.name}${
                                index + 1 < notice.issuers.length ? ", " : ""
                              }`
                          )}
                        </div>
                      </div>
                      <div css={style.rowDate}>
                        <div css={style.rowTitle}>
                          {formatDate(
                            notice?.publishDate,
                            "DD/MM/yyyy HH:mm:ss"
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>

              {data?.length === limit ? (
                <div css={style.contentButton}>
                  <Button
                    label="Load more ..."
                    onClick={() => {
                      setLimit(limit + limitOffset);
                    }}
                    variant="primary"
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div css={style.emptyResult}>
              <EmptyResults />
            </div>
          )}
        </Loader>
      </div>
    </div>
  );
};
