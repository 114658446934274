
/** @jsx jsx */

import * as React from 'react';

import { ComponentFields, ComponentParams, ComponentRendering, Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { css, jsx } from "@emotion/react";

import {GlossaryCategories} from "../../core/components/Resources/GlossaryCategories";
import { SitecoreGenericTextField } from '../../interfaces';

export interface GlossaryProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
    //max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  `;

export const Glossary: React.FunctionComponent<GlossaryProps> = (props: GlossaryProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const title: string = getFieldValue<string>(fields, 'title', '');
    const description: string = getFieldValue<string>(fields, 'description', '');
    const isH1: boolean = getFieldValue<boolean>(fields, 'is H1', false);

    return (
        <div css={container}>
            <GlossaryCategories title={title} description={description} isH1={isH1}/>
        </div>
    );
}
    