
/** @jsx jsx */

import * as React from 'react';

import {Button, Icon} from "@bdl-cmn-shared-packages-npm/design-system";
import { ComponentParams, ComponentRendering, Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import {fetchData,getLinkUrl, renderLink} from "../../utils/helper";

import Loader from "../../core/components/Loader/loader.component";
import { SitecoreGenericTextField } from '../../interfaces';
import {canUseDOM} from "../../predicates";
import hexa from './hexa.svg';
import {style} from "../../core/components/CourseContainer/courseContainer.style";
import { formatDate, getCurrentMoment, parseToMoment } from '../../utils/date';

export interface LGXAcademyCourseProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
  }
`;
const parentContainer = css`
  width: 100%;
  background: #F0F5F5;
  padding-top: 48px;
`;
const left = css`
  width: 60%;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
  }
`;
const right = css`
  width: 40%;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  @media (max-width: ${breakpointMax}) {
    width: 100%;
    padding-left: 0;
  }
`;
const sessionLabel = css`
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
  margin-bottom: 24px;
`;
const stepTitle = css`
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #008C3D;
  display: flex;
  align-items: center;
`;
const hexaNumber = css`
  min-width: 40px;
  height: 48px;
  margin-right: 16px;
  background: url(${renderLink(hexa)});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const stepContent = css`
  display: flex;
  margin-bottom: 72px;
  flex-direction: column;
`;
const stepContentHead = css`
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 32px;
`;
const stepContenText = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #354450;
`;
const card = css`
  background: #FFFFFF;
  border: 1px solid #E3E6E8;
  box-sizing: border-box;
  box-shadow: 0 16px 24px rgba(164, 188, 194, 0.2);
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  max-width: 380px;
`;

const cardDate = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #22AA5F;
`;
const cardBigTitle = css`
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
`;
const cardBigDescription = css`
  font-weight: 600;
  font-size: 16px;
  margin-top: 16px;
  line-height: 20px;
  color: #354450;

`;
const cardDescription = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #354450;
  margin-top: 12px;
  margin-bottom: 12px;
`;
const cardOffer = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #F18700;
  margin-bottom: 12px;
`;
const cardSubscribe = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #22AA5F;
  cursor: pointer;
  
  svg {
    margin-right: 8px;
  }
`;
const action = css`
  margin-top: 24px;
  margin-bottom: 48px;
  margin-left: auto;
  margin-right: auto;
`;
const cardAction = css`
 text-align: center;
  margin-top: 16px;
`;

export const LGXAcademyCourse: React.FunctionComponent<LGXAcademyCourseProps> = (props: LGXAcademyCourseProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const [events, setEvents] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const buttonLabel: string = getFieldValue<string>(fields, 'steps button label', '');
    const whiteButtonLabel: string = getFieldValue<string>(fields, 'white card button label', '');
    const whiteButtonLink: string = getFieldValue<string>(fields, 'white card button link', '');
    const isWhiteBloc: string = getFieldValue<string>(fields, 'white card title', '');

    const fetchEvents = async(id: string) => {
        try {
            const result: any = await fetchData(id);
            dissociateEvents(result);
        } catch(err) {
            console.error(err);
        }
    }

    const dissociateEvents = (data: any) => {
        const futureEvents = data.filter((event: any) => parseToMoment(event['Date to'])?.isAfter(getCurrentMoment()))
        setEvents(futureEvents);
        setLoading(false);
    }

    React.useEffect(() => {
        fetchEvents('E91AD820-4FC6-4173-9A1C-CCA4ECC34754');
    }, []);

    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={left}>
                    {
                        new Array(8).fill(0).map((arr: any, index: number) => {
                            if (fields && fields[`step ${index + 1} title`] && (fields && fields[`step ${index + 1} title`] as any).value) {
                                return (
                                    <div css={stepContent}>
                                        <div css={stepContentHead}>
                                            <div css={hexaNumber}>
                                                {index + 1}
                                            </div>
                                            <div css={stepTitle}>
                                                <Text
                                                    field={fields[`step ${index + 1} title`] as SitecoreGenericTextField}
                                                />
                                            </div>
                                        </div>
                                        <div css={stepContenText}>
                                            <Text
                                                field={fields[`step ${index + 1} description`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                    <div css={action}>
                        <Button iconLeft={'edit'} label={buttonLabel} onClick={async () => {
                                const link = fields['steps button link']?.value;
                                const action = link?.linktype === 'mailto' || link?.linktype === 'internal' ? '_self' : '_blank';
                                if (link?.href) {
                                   canUseDOM && window.open(link.href, action );
                                }
                            }}
                        />
                    </div>
                </div>
                <div css={right}>
                    <div css={sessionLabel}>
                        <Text
                            field={fields[`sessions label`] as SitecoreGenericTextField}
                        />
                    </div>
                    <Loader loading={loading}>
                        {
                            events && events.slice(0, 3).map((event: any, index: number) => (
                                <div css={card} key={`event-${index}`}>
                                    <div css={style.smalldate}>{formatDate(event['Date from'], 'DD MMMM YYYY')} { event['Date to'] ? `- ${formatDate(event['Date to'], 'DD MMMM YYYY')}` : ''}</div>
                                    <div css={cardDate}>
                                        { event?.Title }
                                    </div>
                                    {/*<div css={cardDescription}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: event?.Content}}
                                        />
                                    </div>*/ }
                                    {
                                        event && event['Offer label'] && (
                                            <div css={cardOffer}>
                                                <Icon icon={'tag'} /> {event['Offer label']}
                                            </div>
                                        )
                                    }
                                    {event['Price'] && (
                                        <div css={style.miniPrice}>
                                            {event['Price']}
                                        </div>
                                    )}
                                    <div css={cardSubscribe} onClick={async () => {
                                        const link = await getLinkUrl(event[`Button 2 link`]);
                                        const action = event[`Button 2 link`]?.includes('internal') ? '_self' : '_blank';
                                        if (link) {
                                            window.open(link, action );
                                        }
                                    }}>
                                        <Icon icon={event[`Button 2 icon`]} /> <span>{event[`Button 2 label`]}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </Loader>

                    {
                        isWhiteBloc && (
                            <div css={card}>
                                <div css={cardBigTitle}>
                                    <Text
                                        field={fields[`white card title`] as SitecoreGenericTextField}
                                    />
                                </div>
                                <div css={cardBigDescription}>
                                    <Text
                                        field={fields[`white card description`] as SitecoreGenericTextField}
                                    />
                                </div>
                                <div css={cardAction}>
                                    <Button iconLeft={'paper-plane'} label={whiteButtonLabel} onClick={() => whiteButtonLink ? canUseDOM && window.open(whiteButtonLink, '_blank').focus() : console.log}/>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}
