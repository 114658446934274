
export enum LevelTwoClassification {
	CGEN = 'CGEN',
	CCOM = 'CCOM',
	CCOD = 'CCOD',
	CCOS = 'CCOS',
	CENE = 'CENE',
	CHEC = 'CHEC',
	CIND = 'CIND',
	CMAT = 'CMAT',
	CTEC = 'CTEC',
	CUTI = 'CUTI',
	FGEN = 'FGEN',
	FASM = 'FASM',
	FBAN = 'FBAN',
	FSPF = 'FSPF',
	FFIS = 'FFIS',
	FINS = 'FINS',
	FREE = 'FREE',
	FFUT = 'FFUT',
	SGEN = 'SGEN',
	SSOV = 'SSOV',
	SQSO = 'SQSO',
	SSUP = 'SSUP',
	SAGE = 'SAGE',
	FCOF = 'FCOF',
	FCMF = 'FCMF',
}

export const levelTwoClassificationLabels: { [key in LevelTwoClassification]: string } = {
	CGEN: 'Corporate',
	CCOM: 'Communications',
	CCOD: 'Consumer Discretionary',
	CCOS: 'Consumer Staples',
	CENE: 'Energy',
	CHEC: 'Health Care',
	CIND: 'Industrials',
	CMAT: 'Materials',
	CTEC: 'Technology',
	CUTI: 'Utilities',
	FGEN: 'Financials',
	FASM: 'Asset management',
	FBAN: 'Banking',
	FSPF: 'Specialty finance',
	FFIS: 'Financial services',
	FINS: 'Insurance',
	FREE: 'Real estate',
	FFUT: 'Funds & Trusts',
	SGEN: 'SSA',
	SSOV: 'Sovereigns',
	SQSO: 'Quasi-sovereign',
	SSUP: 'Supranationals',
	SAGE: 'Agencies',
	FCOF: 'Consumer Finance',
	FCMF: 'Commercial Finance'
}

export function levelTwoClassificationLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		return levelTwoClassificationLabels[ value as LevelTwoClassification];
	}catch (e){
		console.error("Failed to get LevelTwoClassification label for '" + value + "'", e);
	}
	
	console.error("LevelTwoClassification label for '" + value + "' - '" + value + "' doest exist");
		
	return value;
}