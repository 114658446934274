import { breakpoint, breakpointMax } from '../../../../style';

import { css } from '@emotion/react';

export const style = {
  flexRow: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3em;

    @media (max-width: ${breakpointMax}) {
      padding: 0px;
    }
  `,
  blocRowKey: css`
    font-family: 'Mont';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #354450;
    @media (max-width: 720px) {
      font-size: 1em;
      line-height: 1em;
    }
  `,
  blocRowValue: css`
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #354450;
    width: 230px;
    > div {
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      > div {
        font-size: 16px;
        font-weight: 500;
      }
    }
    @media (max-width: 720px) {
      width: 50%;
      text-align: right;
      font-size: 0.75em;
      line-height: 1em;
    }
  `,
  blocTitle: css`
    color: #354450;
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;

    @media (max-width: 720px) {
      font-size: 1.5em;
      line-height: 2em;
      flex-direction: column;
    }

    @media screen and (min-width: 720px) and (max-width: 1080px) {
      font-size: 2em;
      line-height: 1.5em;
    }
  `,
  blocRowSpace: css`
    color: #354450;
    align-items: center;
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: solid 1px #e6eef2;
    justify-content: space-between;
  `,
  textContainer: css`
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  button: css`
    box-shadow: none !important;
    background-color: transparent !important;
    color: #22aa5f !important;
    border: solid 1px #22aa5f !important;
    margin-top: 30px !important;
    :hover {
      background-color: #f4f6f7 !important;
    }
  `,
  buttonBasket: css`
    box-shadow: none !important;
    background-color: transparent !important;
    color: #22aa5f !important;
    border: solid 1px #22aa5f !important;
    margin-top: 30px !important;
    :hover {
      background-color: #f4f6f7 !important;
    }
  `,

  chartContainer: css`
    background: #ffffff;
    border: 1px solid #e3e6e8;
    flex: 1;
    padding: 24px;
    width: calc(50% - 16px);
    margin-right: 8px;
    h1 {
      color: #354450;
      font-weight: 800;
      font-size: 28px;
      margin-bottom: 1em;
      display: flex;
      justify-content: space-between;
    }
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin-left: 0;
      h1 {
        margin-top: -4px;
      }
    }
    position: relative;
  `,
  priceContainer: css`
    display: flex;
    margin-left: 8px;
    width: calc(50% - 16px);
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      margin-left: 0;
      margin-top: 16px;
    }
  `,
  pricesContainer: css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #e3e6e8;
    padding: 24px;
    width: 100%;
  `,

  basketContainer: css`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #e3e6e8;
    margin-left: 97px;
    margin-right: 97px;
    width: 100%;
    margin-top: 60px;
    //margin: 60px 90px 97px 97px;
    padding: 24px;
    max-width: calc(100% - 194px);
    margin-bottom: 40px;
    h1 {
      color: #354450;
      font-weight: 800;
      font-size: 28px;
      margin-bottom: 1em;
      display: flex;
      justify-content: space-between;
    }
    table {
      width: 100%;
      border: none;
      color: #354450 !important;
    }
    @media (max-width: ${breakpointMax}) {
      max-width: calc(100% - 40px);
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      overflow: auto;
    }
  `,
  basketContainer2: css`
    left: 0px;
    top: 1565px;
    background: #ffffff;
    h1 {
      color: #354450;
      font-weight: 800;
      font-size: 28px;
      margin-bottom: 1em;
      display: flex;
      justify-content: space-between;
    }
    table {
      width: 100%;
      border: none;
      color: #354450 !important;
    }
  `,
  selectDateContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;

    width: 322px;
    height: 57px;

    /* Generic/White */

    background: #ffffff;
    /* Brand/Grey 500 */

    border: 1px solid #a4b6c2;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
  `,
  selectDateLabel: css`
    width: 306px;
    height: 18px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #354450;
  `,
  selectDateValue: css`
    width: 306px;
    height: 23px;

    /* Input/Text/Label/Field */

    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: flex-end;
    color: #354450;
  `,

  prevTd: css`
    color: #22aa5f;
  `,

  changeTd: css`
    color: #22aa5f;
  `,
  buttonStyle: css`
    display: inline-flex;
  `,

  pricesTh: css`
    width: 458.5px;
    height: 24px;
    font-family: 'Mont';
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 22px;
    color: #425563;
    flex: none;
    order: 0;
    flex-grow: 1;
  `,

  indexTable: css`
    width: 100%;
    th {
      background: #e8ecee;
      padding: 0px 32px;
      height: 40px;
    }
  `,

  button_chevron: css`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    width: 8px;
    height: 12px;
    color: #22aa5f;
    border: none;
  `,

  image: css`
    width: 150px;
    min-width: 150px;
    img {
      max-height: 24px;
      max-width: 150px;
    }
  `,

  market: css`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
    border: solid 1px #e3e6e8;
    background: #ffffff;
    border-radius: 999em;
    padding: 8px 16px;
    font-weight: bold;
    width: fit-content;
  `,

  selectorContainer: css`
    display: flex;
    justify-content: space-between;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  tabSelector: css`
    width: inherit;
    flex-grow: 0;
    margin-left: 16px;
    @media (max-width: ${breakpointMax}) {
      margin-left: 0;
      margin-top: 8px;
    }
  `,
  tab: css`
    > div {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @media (max-width: ${breakpointMax}) {
      > div {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  `,
  priceColumn: css`
    position: absolute;
    right: 40px;
    top: 45px;
  `,
  data: css`
    float: right;
    padding-top: 59px;
    padding-right: 44px;
    @media (max-width: ${breakpointMax}) {
      padding-top: unset;
      padding-right: unset;
    }
  `,
  dataTop: css`
    color: #22aa5f;
    font-weight: 600;
    font-size: 16px;
  `,
  dataBottom: css`
    text-align: center;
    //margin-left: 433px;
    //margin-right: 400px;
    //margin-top: 32px;
    //margin-bottom: 530px;

    //font-weight: 500;
    //font-size: 14px;
    //line-height: 18px;
    /* identical to box height */

    color: #1a2934;
  `,

  chart: css`
    //position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #ffffff;
    border-radius: 3px;
  `,
  rangePart: css`
    margin-top: 24px;
  `,
  descriptionContainer: css`
    padding: 0px 97px;
    h1 {
      color: #354450;
      font-weight: 800;
      font-size: 28px;
      margin-bottom: 1em;
      display: flex;
      justify-content: space-between;
    }
    @media (max-width: ${breakpointMax}) {
      padding-left: 20px;
      padding-right: 20px;
      p {
        font-size: 16px;
      }
      h1 {
        font-size: 24px;
      }
    }
  `,
  chartPriceContainer: css`
    display: flex;
    //max-width: 1393px;
    padding-left: 97px;
    padding-right: 97px;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 60px;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
    }
  `,
};
