import React from "react";
import TradingNewsGenericRow from "./row-generic";
import TradingNewsBBORow from "./row-bbo";
import {TradingNewsFilterEnum} from ".";
import {jsx} from '@emotion/react';
import {marketNewsStyle} from "../MarketNews/index.style";
import SearchSecurity from "../../../../interfaces/search/search-security";

/** @jsx jsx */

const TradingNewsRow = ({type, row} : {type: TradingNewsFilterEnum, row: SearchSecurity}) => {
    const isBBOType: boolean = (TradingNewsFilterEnum.equities === type || TradingNewsFilterEnum.fixedIncome === type || TradingNewsFilterEnum.funds === type );

    return (
        <div css={marketNewsStyle.container}>
            { isBBOType ? 
                <TradingNewsBBORow row={row}/>
                :
                <TradingNewsGenericRow row={row}/>
            }
        </div>
    );
};

export default TradingNewsRow;