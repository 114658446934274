
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import MarketCapitalisationView from "../../core/components/MarketStatistics/market-capitalisation-view.component";

export const MarketCapitalisationComponent: React.FunctionComponent = (): React.ReactElement => {
    return (
        <MarketCapitalisationView/>
    );
}
    