export enum SecurityDelistingReasonEnum {
	ABSR = "Take-over",
	ANTI = "Cancellation of Securities",
	ANWA = "Cancellation of Warrants",
	ATCI = "No outstanding security",
	AUGM = "Increase of capital",
	CHTY = "Change of type of bonds",
	CORR = "Correction",
	COTP = "Premature quotation",
	CRUB = "Header change",
	CTER = "Listing asked by error",
	ECFI = "Final maturity",
	ECHF = "Final maturity",
	ERRA = "Error of application",
	EXAW = "Automatic exercise of warrants",
	FERC = "Closure of the sub-fund",
	FUSI = "Merger of the fund",
	LICP = "Liquidation of the sub-fund",
	LIQU = "Liquidation of the fund",
	LISO = "Liquidation / Merger of the company",
	MGSF = "Merger of the sub-fund",
	RADI = "Delisting",
	RASS = "Assimilation",
	RBA2 = "Early redemption",
	RBAT = "Early redemption",
	RCHA = "Buyback",
	RCHT = "Buyback",
	RCON = "Conversion",
	REBT = "Shares reimbursement",
	REBU = "Units reimbursement",
	RECH = "Exchange",
	RECP = "Restructuring of the sub-fund",
	REDE = "Rename to Euro",
	REOR = "Company reorganisation",
	RFDA = "Termination of the Deposit Agreement",
	RFDE = "End of the exercise of the option right",
	ROPA = "Take-over bid",
	ROPE = "Public exchange offer",
	RREG = "Grouping of securities",
	RTAI = "Listing sponsor's request",
	RTEM = "Issuer's request",
	RTES = "Company's request",
	WAEX = "Warrants fully exercised",
	DEM  = "Demande de l'émetteur avec l'accord des porteurs",
	DLSE = "Decision by LuxSE",
	LMCL = "Liquidation / Closure / Merger of the class",
}

function cleanName(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try{
		value = value.toString().toUpperCase();
		value = value.replace(/[^a-zA-Z ]/g, "");
		value = value.replace(/\s/g, "");
	}catch (e){
	}

	return value;
}

export function securityDelistingReasonLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";

	try {
		let index = Object.keys(SecurityDelistingReasonEnum).indexOf( cleanName(value) );
  		if( index >= 0) 
			return Object.values(SecurityDelistingReasonEnum)[index];
	}catch (e){
		console.error("Failed to get SecurityDelistingReasonEnum label for '" + value + "'", e);
	}
	
	console.error("SecurityDelistingReasonEnum label for '" + value + "' - '" + cleanName(value) + "' doest exist");

	return value;
}