
export enum InstrumentSubType {
    ACT = "Action",
    ADR = "American Depositary Receipt",
    ADS = "American Depositary Share",
    ATR = "Action de titrisation",
    BDR = "Bearer Depositary Receipt",
    BONU = "Bonus",
    CACT = "CACT",
    CAUT = "CAUT",
    CCOM = "CCOM",
    CCPZ = "CCPZ",
    CD = "CD",
    CDEV = "CDEV",
    CDR = "Continental Depositary Receipt",
    CFC = "CFC",
    CFRN = "CFRN",
    CI = "Certificat Immobilier",
    CIND = "CIND",
    COBL = "COBL",
    COPC = "COPC",
    CPZ = "Zero Coupon Note", // ZERO COUPON
    CSTR = "CSTR",
    CVG = "CVG",
    DR = "Depositary Receipt",
    DRSO = "Subscription right",
    DS = "Depositary Share",
    EDR = "European Depositary Receipt",
    EDS = "European Depositary Share",
    FCP = "Fonds commun de placement",
    FDR = "Fiduciary Depositary Receipt",
    FRN = "Floating Rate Note", // FLOATING RATE
    GDR = "Global Depositary Receipt",
    GDS = "Global Depositary Share",
    IDR = "International Depositary Receipt",
    OPCA = "OPCA",
    PSO = "Part sociale",
    SDR = "SDR",
    SCPZ = "Structured Product Zero Coupon Note",
    SFRN = "Structured Product Floating Rate Note",
    SICF = "SICF",
    SICR = "SICR",
    SICV = "SICV",
    SSTR = "Structured Product Fixed Rate Note",
    STPR = "Structured product",
    STR = "Fixed rate", // FIXED RATE
    WACT = "Equity Warrant",
    WAUT = "Warrant, Misc. underlyings",
    WCOM = "WCOM",
    WDEV = "WDEV",
    WIND = "WIND",
    WOBL = "Bond Warrant",
    WOPC = "WOPC"
}

export function instrumentSubTypeLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(InstrumentSubType).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(InstrumentSubType)[index] : value;
	}catch (e){
		console.error("Failed to get InstrumentSubType label for '" + value + "'", e);
	}
		
	return value;
}