/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  RichText,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import hexas from "./hexas.svg";
import { SitecoreGenericTextField } from "../../interfaces";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpointMax } from "../../style";
import { FormBuilder } from "../../core/components/FormBuilder/formBuilder.component";
import { renderLink } from "../../utils/helper";

export interface BlogSubscribeToNewsletterProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = (noBackground = false) => css`
  width: 100%;
  margin-bottom: ${noBackground ? "24px" : "0"};
  @media (max-width: ${breakpointMax}) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;
const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const top = css`
  width: 100%;
  height: 180px;
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;
const bottom = (withoutBackground = false) => css`
  width: 100%;
  ${!withoutBackground
    ? `background: linear-gradient(180deg, #F18700 0%, #F7B766 100%), url(${hexas});`
    : null}
  min-height: 215px;
  background-repeat: no-repeat;
  padding-bottom: ${withoutBackground ? "0" : "80px"};
  background-size: cover;
  @media (max-width: ${breakpointMax}) {
    background: none;
    padding-bottom: 160px;
  }
`;
const card = (noBackground = false) => css`
  width: 100%;
  display: flex;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border: 1px solid #d9dee2;
  box-shadow: ${noBackground ? "none" : "0 16px 25px rgba(37, 56, 69, 0.15)"};
  border-radius: 16px;
  position: relative;
  margin-top: -140px;
  overflow: hidden;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

const cardLeft = (image?: string) => css`
  width: 180px;
  min-width: 180px;
  height: 200px;
  background-image: url(${renderLink(image)});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 34px;
  border-radius: 16px;
  background-position: center;
  margin-left: 40px;
  margin-top: 40px;
  @media (max-width: ${breakpointMax}) {
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 10px;
  }
`;
const cardRight = css`
  width: cacl(100% - 180px);
  display: flex;
  flex-direction: column;
  padding: 40px;
  @media (max-width: ${breakpointMax}) {
    //padding: 8px;
  }
`;
const title = css`
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #354450;
  @media (max-width: ${breakpointMax}) {
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
  }
`;
const description = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #425563;
  margin-top: 14px;
  @media (max-width: ${breakpointMax}) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const inputPart = css`
  display: flex;
  align-content: center;
  margin-top: 32px;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    width: 100%;
    gap: 16px;
    //margin-bottom: 40px;
  }
`;

export const BlogSubscribeToNewsletter: React.FunctionComponent<BlogSubscribeToNewsletterProps> =
  (props: BlogSubscribeToNewsletterProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const [visibleModal, setVisibleModal] = React.useState<boolean>(false);

    const btnLabel: string = getFieldValue<string>(fields, "button label", "");
    const btnIcon: string = getFieldValue<string>(fields, "button icon", "");
    const imageLeft: any = getFieldValue<any>(fields, "left image", null);
    const noBackground: boolean = getFieldValue<boolean>(
      fields,
      "no background",
      false
    );

    return (
      <div css={parentContainer(noBackground)}>
        {visibleModal && (
          <FormBuilder
            onClickOutside={() => setVisibleModal(false)}
            embedCode={fields?.formCode?.value}
          >
            <div id="childrenPart">
              {fields?.formCode?.value && (
                <div
                  dangerouslySetInnerHTML={{ __html: fields?.formCode?.value }}
                />
              )}
            </div>
          </FormBuilder>
        )}
        <div css={container}>
          <div css={top} />
          <div css={bottom(noBackground)}>
            <div css={card(noBackground)}>
              <div css={cardLeft(imageLeft?.src)} />
              <div css={cardRight}>
                <div css={title}>
                  <Text
                    field={fields && (fields.title as SitecoreGenericTextField)}
                  />
                </div>
                <div css={description}>
                  <RichText
                    field={
                      fields && (fields.description as SitecoreGenericTextField)
                    }
                  />
                </div>
                <div css={inputPart}>
                  {fields?.formCode?.value && (
                    <Button
                      label={btnLabel}
                      iconLeft={btnIcon as any}
                      onClick={() => setVisibleModal(true)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <Text
            field={fields && (fields.heading as SitecoreGenericTextField)}
          />
        </div>
      </div>
    );
  };
