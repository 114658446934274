import * as React from "react";
/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import {Table} from "@bdl-cmn-shared-packages-npm/design-system";
import {sharedStyle} from "../../../../../../styles/shared";
import {MarketdataModel} from "../../market-data-view.component";
import NewListingsHeader from "./new-listings-header.component";
import NewListingsRow from "./new-listings-row.component";

export const NewListingsView = (props: any) => {

    const newListings = props.newListingsData;
    return (

        <Table>
            <NewListingsHeader/>
            {/*{newListings?.totalHits > 5 && (<div css={sharedStyle.gradientTop}/>)}*/}
            <tbody>
            {newListings?.totalHits > 0 ? newListings?.currentData?.map((newlistingData: MarketdataModel, index: number) =>
                    (<NewListingsRow newListingData={newlistingData} key={`listing-key-${index}`}/>))
                : "no data"}
            </tbody>
        </Table>

    )

}
export default NewListingsView;