import gql from "graphql-tag";

export const SUBMIT_RTS_12_PRE_TRADE =  gql`
    query rts12(
        $firstName: String!, 
        $lastName: String!
        $email: String!
    ){
        rts12PreTrade (
        firstName: $firstName, 
        lastName: $lastName, 
        email: $email
        ){
            code
            message
        }
    }
`;

export const SUBMIT_RTS_12_POST_TRADE =  gql`
    query rts12(
        $firstName: String!, 
        $lastName: String!
        $email: String!
    ){
        rts12PostTrade (
        firstName: $firstName, 
        lastName: $lastName, 
        email: $email
        ){
            code
            message
        }
    }
`;