/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { useReadingTime } from "react-reading-time-estimator";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import moment from "moment";

export interface TimeToReadIndicatorProps {
    data: any;
    date?: any;
    small?: boolean;
    color?: string;
}
const halfArticleInfo = (color = "#425563", small = false) => css`
  font-style: normal;
  font-weight:600;
  font-size: ${small ? '14px' : '16px'};
  line-height:  ${small ? '150%' : '20px'};
  color: ${color};
  
  svg {
    margin-right: 4px;
    opacity: .4;
  }
  
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color:  ${color};
  }
  i {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #D9DEE2;
  }
`;
export const TimeToReadIndicator: React.FunctionComponent<TimeToReadIndicatorProps> = (props: TimeToReadIndicatorProps): React.ReactElement => {
    const { data, date, color, small } = props;
    const { text } = useReadingTime(data || '');

    return (
        <div css={halfArticleInfo(color, small)}>
            <Icon icon={'book-open'}/> { text?.replace('less than a min', '1 min read')  } <span><i>•</i> { moment(date).format('DD MMM YYYY.')} </span>
        </div>
    )
}
