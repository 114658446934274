import React from "react";
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Tr, Td } from '@bdl-cmn-shared-packages-npm/design-system';
import { CountryFlagIcon } from '../../../CountryFlagIcon/countryFlagIcon.component';
import { style } from '../base/index.style';
import Props from '../base/props';
import SearchIssuer from '../../../../../interfaces/search/search-issuer';
import { data as dataMapper } from '../../../Search/results/dataMapper';
import { IssuerTypeEnum, issuerTypeLabel } from '../../../../../enums/issuer-type';
import { IssuerSubTypeEnum, issuerSubTypeLabel } from '../../../../../enums/issuer-subtype';
import RoundTag from '../../../RoundTag/roundTag.component';
import { goToIssuerDetailPage } from "../../../IssuerCard";
import { Country } from "../../../../../enums";
const countries = require('i18n-iso-countries');

export const getIssuerType = (type: string, oldType: string): string => {
  let value: string = type;

  if (!value && oldType)
    switch (oldType.toUpperCase()) {
      case 'CORPORATE':
        value = IssuerTypeEnum.CGEN;
        break;
      case 'FUND':
        value = IssuerTypeEnum.FGEN;
        break;
      case 'GOVERNMENT':
      case 'SUPRANATIONAL':
        value = IssuerTypeEnum.SGEN;
        break;
    }

  return issuerTypeLabel(value);
};

export const getIssuerSubType = (subType: string, oldType: string): string => {
  let value: string = subType;

  if (!subType && oldType)
    switch (oldType.toUpperCase()) {
      case 'FUND':
        value = IssuerSubTypeEnum.FFUT;
        break;
      case 'GOVERNMENT':
        value = IssuerSubTypeEnum.SSOV;
        break;
      case 'SUPRANATIONAL':
        value = IssuerSubTypeEnum.SSUP;
        break;
    }

  return issuerSubTypeLabel(subType);
};

const IssuerRow = ({ row, selectedCols }: Props<SearchIssuer>) => {
  if(!row)
    return <></>;

  let country = row.country ? row.country : row.countryCode2;

  return (
    <Tr onRowClick={() => goToIssuerDetailPage(row.url, row.id)} css={style.actionsRow} key={row.id}>
      <Td>
        <div
          css={css`
            > div {
              min-height: 30px;
              min-width: 30px;
              font-size: 1rem;
              color: #354450;
              background-color: ${row.statistics?.nbGreens > 0 ? '#e8faf0' : '#fae8ea'};
            }
          `}
        >
          <RoundTag green={row.statistics?.nbGreens > 0} borderSize={2} size={14}>
            I
          </RoundTag>
        </div>
      </Td>
      <Td>
        <div style={{ display: 'flex', gap: '5px' }}>
          { getIssuerType(row.type, row.oldType) ?
              <div css={[style.market]}>{getIssuerType(row.type, row.oldType)}</div>
              : <></>
          }
          { getIssuerSubType(row.subType, row.oldType) ?
              <div css={[style.market]}>{getIssuerSubType(row.subType, row.oldType)}</div>
              : <></>
          }
          
        </div>
      </Td>
      <Td>
        <div css={style.align}>{row.name}</div>
        <div css={[style.align, style.subLabel]}>{`${row.statistics?.nbSecurities} securities`}</div>
      </Td>
      <Td>
        <div css={style.market}>
          <div css={style.imageFlag}>
            <CountryFlagIcon code={country} size={{ height: 20, width: 30 }} />
          </div>
          {country == "TW" ? Country.TW : countries.getName(country, 'en', { select: 'official' })}
        </div>
      </Td>
      <Td>{row.statistics?.nbGreens}</Td>
      {/*<Td>
        { row.statistics?.caiAsPerCBI ?
            row.statistics?.nbSecurities - row.statistics?.nbGreens
            : 0
        }
      </Td>*/}
      {selectedCols['issuers'].map(
        (col: string, index: number) =>
          col.toLowerCase() !== 'id' && (
            <Td key={`col-selected-${index}`}>{dataMapper.issuers[col].render((row as any)[col])}</Td>
          )
      )}
    </Tr>
  );
};

export default IssuerRow;
