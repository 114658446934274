import * as React from 'react';

import { Card, Col, Grid, Icon, Row } from '@bdl-cmn-shared-packages-npm/design-system';
import { breakpoint, breakpointMax } from '../../../style';
import { css, jsx } from '@emotion/react';
import {
  fetchData,
  fetchNewData,
  getTransformLink,
  getTransformPdfLink,
  renderLink,
  transformLink
} from '../../../utils/helper';

import axios from 'axios';
import hexaFull from './hexa-full.svg';
import hexaLight from './HexaLight.svg';
import logo from './white-logo.svg';
import { style } from './footer.style';
import { useState } from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import XTwitter from "../../icons/x-twitter";

/** @jsx jsx */

export interface FooterProps {
  fields?: any;
  onSwitchMenu?: () => void;
}

const hexaFullStyle = css`
  position: absolute;
  width: 85px;
  left: calc(50% + -31%);
  top: 61%;
  img {
    width: 100%;
  }
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;

const hexaLightStyle = css`
  position: absolute;
  width: 90%;
  bottom: 0;
  img {
    width: 100%;
  }
  @media (max-width: ${breakpointMax}) {
    display: none;
  }
`;

const Footer: React.FunctionComponent<FooterProps> = ({ fields, onSwitchMenu }: FooterProps) => {
  const [menu, setMenu] = useState<any>();
  const [subMenu, setSubMenu] = useState<any[]>([]);
  const [socials, setSocials] = useState<any[]>([]);
  const [expendMenu, setExpendMenu] = useState<number>(-1);

  const getDataFromItemId = async (id: string) => {
    try {
      const data: any = await fetchNewData(id);
      const { root } = data;
      /*const filledData = [];
      setMenu(data);
      for (const elem of data || []) {
        if (elem.HasChildren === 'True') {
          const subRequest = await fetchSubData(elem.ItemID);
          const childArr : any[] = [];
          for (const req of (subRequest || [])) {
            const formattedData = await getChild(req);
            childArr.push(formattedData);
          }

          filledData.push({
            ...elem,
            child: childArr,
          });
        } else {
          filledData.push(elem);
        }
      }*/
      setMenu(root);
    } catch (err) {
      console.error(err);
    }
  };

  const getDataFromItemIdSubFooter = async (id: string) => {
    try {
      const data: any = await fetchData(id);

      if (data && data.length > 0) {

        for (const item of data) {
          const index: number = data.indexOf(item);
          if (item['direct page link'] && item['direct page link'].match(/{([^}]+)}/) && item['direct page link'].match(/{([^}]+)}/)[1]) {
            const id = item['direct page link'].match(/{([^}]+)}/)[1];
            const subData: any = await getTransformPdfLink(id);
            data[index]['download url'] = subData;
          }
        }
      }

      setSubMenu(data);
    } catch (err) {
      console.error(err);
    }
  };
  const getDataFromItemIdSocails = async (id: string) => {
    try {
      const data: any = await fetchData(id);
      setSocials(data);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    getDataFromItemId('7BB6B8F0-1AD9-492C-9E53-3B4534AD90EF');
    getDataFromItemIdSubFooter('AEBA519C-C588-4944-AA54-BE937E1539A2');
    getDataFromItemIdSocails('55A40F3A-24E4-4127-B64F-C14C82D62E04');
  }, []);

  return (
    <div css={style.footer}>
      <div css={style.footerContainer}>
        <div css={hexaFullStyle}>
          <img src={renderLink(hexaFull)} alt={"image"} />
        </div>
        <div css={style.footerContainerRow}>
          <Grid>
            <Row>
              {menu && menu.items &&
                !!menu.items.length &&
                menu.items.map((elem: any, index: number) => (
                  <React.Fragment key={`footer-${index}`}>
                    <Col mobile={12} tablet={6} desktop={3}>
                      <div css={style.footerCol(index)} key={`menu-${elem.label}-${index}`}>
                        <div css={style.footerTitle} onClick={() => setExpendMenu(expendMenu === index ? -1 : index)}>{elem.label}</div>
                        <div css={style.mobileCondition(expendMenu === index)}>
                          {elem?.links?.map((child: any, index: number) => (
                            <React.Fragment key={`loop-${index}`}>
                              {
                                child.externalLink ? (
                                  <a
                                    css={style.footerLabel}
                                    href={child?.link}
                                    target={child.link}
                                    key={`sub-menu-${child?.label}-${index}`}
                                  >
                                    {child?.label}
                                  </a>
                                ) : (
                                  <Link key={`col-${index}-${child.label}`} target={child.externalLink ? '_blank' : '_self'} title={child.label} field={transformLink(child.pageLink)} css={style.footerLabel}>
                                    {child.label}
                                  </Link>
                                )

                              }
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </React.Fragment>
                ))}
            </Row>
          </Grid>
        </div>

      </div>
      <div css={style.footerBottom}>
        <div css={style.footerBottomLeft}>
          <a href='/' title='home' css={style.footerLogo}>
            <img src={renderLink(logo)} alt={'image'} />
          </a>
          <div css={style.vertivalLine}></div>
          <div css={style.footerSocial}>
            {socials &&
              socials.map((social: any, index: number) => {

                return <React.Fragment key={`span-${index}`}>
                  <span key={`social-${index}`}>
                    <a href={social['link']}>
                      {social.label === 'twitter' ? <XTwitter style={{
                        fill: "white",
                        fontSize: "14px",
                        verticalAlign: "middle",
                      }} /> : <Icon type={'fab'} icon={social['label']} />}
                    </a>
                  </span>
                </React.Fragment>
              })}
          </div>
          <div css={style.footerLinks}>
            {subMenu &&
              subMenu.map((menu: any, index: number) => {
                if (menu['download url']) {
                  return (
                    <React.Fragment key={`head-menu-${index}`}>
                      <a key={`item-${index}`} href={menu['download url']} download>
                        {menu['main label']}
                      </a>
                    </React.Fragment>
                  )
                } else {
                  return (
                    <React.Fragment key={`head-menu-2-${index}`}>
                      <a key={`item-${index}`} href={menu['direct link']}>
                        {menu['main label']}
                      </a>
                    </React.Fragment>
                  )
                }
              }
              )}
          </div>
        </div>
        <div css={style.footerBottomRight}>
          <div css={hexaLightStyle}>
            <img src={renderLink(hexaLight)} alt={"image"} />
          </div>
          <span>Always in motion</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
