import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Security } from "../../../../../interfaces";
import { style } from '../../SecurityOverview/securityOverview.style';
import { setPeriodicityCodeLabelDataValue } from "../../../../../graphql";
import { formatDate } from "../../../../../utils/date";
import { dayCountConventionLabel } from "../../../../../enums";

export interface MarketDataInformationProps {
  securityData?: Security;
}

export const MarketDataYieldInformation = ({
  securityData
}: MarketDataInformationProps) => {
  const marketData = securityData?.marketData || null;
  const negative = marketData?.previousDayChangePercent < 0;

  return (
    <div>
      <div css={style.blocTitleSmall}>Security information</div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Coupon</div>
        <div css={style.blocRowValue}>
          {securityData.interestRate ?
            `${securityData.interestRate}%` :
            "N/A"}
        </div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Period</div>
        <div css={style.blocRowValue}>
          {securityData.periodicityCode ?
            setPeriodicityCodeLabelDataValue(securityData.periodicityCode) 
            : "N/A"
          }
        </div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Day convention code</div>
        <div css={style.blocRowValue}>
          { securityData.dayConventionCode ? 
            dayCountConventionLabel(securityData.dayConventionCode) 
            : "N/A"
          }
        </div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Redemption price</div>
        <div css={style.blocRowValue}>
          {securityData.redemptionPrice || "N/A"}
        </div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Currency</div>
        <div css={style.blocRowValue}>
          {securityData.currency || "N/A"}
        </div>
      </div>
      <div css={style.blocRowSpace}>
        <div css={style.blocRowKey}>Maturity date</div>
        <div css={style.blocRowValue}>{formatDate(securityData.maturityDate)}</div>
      </div>
    </div>
  );
}