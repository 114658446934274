
/*
*   e-Listing - core - components - country flag icon - prt
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const PRT: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.337' style={{fill: '#D80027'}} width='512' height='341.326'/>
        <polygon style={{fill: '#6DA544'}} points='196.641,85.337 196.641,261.565 196.641,426.663 0,426.663 0,85.337 '/>
        <circle style={{fill: '#FFDA44'}} cx='196.641' cy='256' r='64'/>
        <path style={{fill: '#D80027'}} d='M160.638,224v40.001c0,19.882,16.118,36,36,36s36-16.118,36-36V224H160.638z'/>
        <path style={{fill: '#F0F0F0'}} d='M196.638,276c-6.617,0-12-5.383-12-12v-16h24.001v16C208.638,270.616,203.254,276,196.638,276z'/>
    </svg>
);
