import * as React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

import { canUseDOM } from "../../predicates";
import { SitecoreContext } from "../../interfaces";
import { Search } from "../../core/components/Search";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { WEBSITE_NAME, WEBSITE_TITLE } from "../../core/constants";

export interface Field {
  value?: string;
  editable?: string;
}
export interface SearchContentProps {
  sitecoreContext: SitecoreContext;
}

export function SearchContentCore({ sitecoreContext }: SearchContentProps) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
          <title>{WEBSITE_TITLE} - Search | {WEBSITE_NAME}</title>
        </Helmet>
      </HelmetProvider>
      <Search
        routeSearch={canUseDOM ? window.location.search || "" : ""}
        onTriggerSearch={(qs: string): void => {
          if (sitecoreContext.routerRouteHistory) {
            sitecoreContext.routerRouteHistory.push(
              `/search?q=${qs.length > 0 ? `?${qs}` : ""}`
            );
          }
        }}
        goToDetail={(url: string): void => {
          if (sitecoreContext.routerRouteHistory) {
            sitecoreContext.routerRouteHistory.push(url);
          }
        }}
      />
    </>
  );
}

export const SearchContent = withSitecoreContext()(SearchContentCore);
