import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Icon, Table, Td, Th, Thead, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import { sharedStyle } from '../../../../../../styles/shared';
import LatestFnsRow from './latest-fns-row.component';
import { MarketdataModel } from '../../market-data-view.component';

const style = {
  table: css`
    table-layout: fixed;
    td div {
      height: 100px;
      overflow: hidden;
    }
  `,
};

export const LatestFnsView = (props: any) => {
  const fnsList = props.fnsData;
  return (
    <Table css={style.table}>
      {/*{fnsList?.totalHits > 5 && (<div css={sharedStyle.gradientTop}/>)}*/}
      <tbody>
        {fnsList?.totalHits > 0
          ? fnsList?.currentData?.map((fnsData: MarketdataModel, index: number) => (
              <LatestFnsRow fnsRow={fnsData} index={index} key={`fns-key-${index}`} />
            ))
          : 'no data'}
      </tbody>
    </Table>
  );
};

export default LatestFnsView;
