
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const TGO: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <g>
                <path fill="#006A4E" d="M512,155.367H0V97.104c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828 L512,155.367L512,155.367z" />
                <rect y="222.461" fill="#006A4E" width="512" height="67.09" />
            </g>
            <rect y="155.371" fill="#FFCE00" width="512" height="67.09" />
            <path fill="#006A4E" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828v-58.262h512v58.262 C512,419.773,508.047,423.725,503.172,423.725z" />
            <rect y="289.551" fill="#FFCE00" width="512" height="67.09" />
            <path fill="#D21034" d="M194.207,289.546H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h185.379c4.875,0,8.828,3.953,8.828,8.828v183.614C203.034,285.593,199.082,289.546,194.207,289.546z" />
            <path fill="#FFFFFF" d="M99.335,135.638L86.577,173.89l-40.322,0.312c-2.21,0.018-3.127,2.838-1.348,4.151l32.438,23.954 l-12.163,38.446c-0.666,2.108,1.734,3.851,3.531,2.566l32.806-23.448l32.806,23.448c1.799,1.286,4.199-0.458,3.531-2.566 l-12.163-38.445l32.438-23.954c1.779-1.313,0.862-4.135-1.348-4.151l-40.322-0.312L103.7,135.639 C103,133.54,100.034,133.54,99.335,135.638z" />
        </svg>
    </React.Fragment>
);
