
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const BRA: JSX.Element = (
    <React.Fragment>
        <svg id="Layer_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
            <path d="m12 27.751h104v72.497h-104z" fill="#6fa44a" />
            <path d="m64 36.667-46.782 27.333 46.782 27.333 46.782-27.333z" fill="#fed953" />
            <circle cx="64" cy="64" fill="#0b55b1" r="15.349" />
            <path d="m64.507 56.451a30.772 30.772 0 0 0 -13.407-.745 15.241 15.241 0 0 0 -2.163 5.386 30.871 30.871 0 0 1 13.337.774c6.714 1.821 12.149 5.424 15.164 9.552a15.243 15.243 0 0 0 1.84-5.942c-3.087-3.906-8.344-7.282-14.771-9.025z" fill="#f0f0f0" />
        </svg>
    </React.Fragment>
);