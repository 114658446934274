
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const XKX: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#244AA5" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z" />
            <path fill="#D0A650" d="M267.31,202.484c-5.103-0.966-11.31-7.448-9.103-11.034s-1.931-11.586-6.897-8.828 c-4.966,2.759-8.276,6.069-12.966,7.172s-8.552,4.69-4.966,8s9.103,9.931,3.862,16.828s-3.586,2.207-9.931,5.241 s-10.759,9.103-8.828,12.69c1.931,3.586,3.586,4.966-1.931,7.448s-8,0.828-12.414,1.931s-15.172,6.621-18.483,11.31 c-3.31,4.69-10.483,0.276-13.517-0.276s-9.103,1.655-10.483,5.241c-1.379,3.586,1.103,6.897,6.345,8.552 c5.241,1.655,7.448,1.931,4.966,6.345s-0.828,12.966,3.31,15.448c4.138,2.483,12.138,10.207,12.138,15.448 c0,5.241-1.103,12.966,6.621,14.069c7.724,1.103,19.586,4.414,23.724,11.586s5.241,11.31,6.069,15.724s1.655,8,4.138,12.966 s4.138,13.517,1.655,15.448c-2.483,1.931-4.414,3.31-2.483,7.034s2.345,5.103,5.931,3.034c3.586-2.069,3.586,0.138,5.517-0.276 s10.759-3.586,9.103-7.862c-1.655-4.276-5.241-14.069-0.138-19.172c5.103-5.103,12-5.931,16.414-9.379s11.724-4,17.379-9.517 c5.655-5.517,11.724-2.207,12.69,1.655s2.483,11.31,6.897,11.172s5.379-0.138,6.207-5.517c0.828-5.379,7.724-17.379,10.483-15.862 c2.759,1.517,5.241,0.276,5.379-2.069c0.138-2.345,5.379-4.414,7.586-1.103c2.207,3.31,7.034,5.931,6.897,1.793 c-0.138-4.138-5.793-3.586-5.931-8.138s4.552-7.448,8.69-9.379c4.138-1.931,1.655-6.897,2.897-9.655 c1.241-2.759,8-6.207,8.276-8.966c0.276-2.759-1.655-3.862,1.241-4.552c2.897-0.69,6.483-1.931,4.414-5.103 s-1.379-5.517,0.828-7.034c2.207-1.517,3.242-8.718,0.023-9.654c-3.22-0.935-9.035,1.766-10.489,3.531 c-1.454,1.766-7.373-5.816-10.178-7.686c-2.804-1.869-20.875-0.727-20.459-3.842c0.416-3.115,8-14.487,4.69-16.28 s-8.552-4.414-12.828-5.793s-3.31,3.31-5.655-0.414s-3.172-16.966-5.793-16.966s-4.552,5.517-5.655,3.448s0.414-16.414-5.655-16.69 s-6.207,3.31-9.655,0.69C273.793,202.622,274.287,203.803,267.31,202.484z" />
            <g>
                <path fill="#FFFFFF" d="M229.837,124.014l-4.583,13.74l-14.484,0.113c-0.794,0.007-1.123,1.02-0.484,1.491l11.651,8.604 l-4.369,13.81c-0.239,0.757,0.622,1.384,1.269,0.921l11.783-8.423l11.784,8.423c0.646,0.461,1.508-0.164,1.269-0.921l-4.369-13.81 l11.651-8.604c0.639-0.471,0.31-1.485-0.484-1.491l-14.484-0.113l-4.583-13.74C231.154,123.262,230.088,123.262,229.837,124.014z" />
                <path fill="#FFFFFF" d="M280.872,124.014l-4.583,13.74l-14.484,0.113c-0.794,0.007-1.123,1.02-0.484,1.491l11.651,8.604 l-4.369,13.81c-0.239,0.757,0.622,1.384,1.269,0.921l11.784-8.423l11.784,8.423c0.646,0.461,1.508-0.164,1.269-0.921l-4.369-13.81 l11.651-8.604c0.639-0.471,0.309-1.485-0.484-1.491l-14.484-0.113l-4.583-13.74C282.188,123.262,281.122,123.262,280.872,124.014z" />
                <path fill="#FFFFFF" d="M179.492,132.428l-4.583,13.74l-14.484,0.113c-0.794,0.007-1.123,1.02-0.484,1.491l11.651,8.604 l-4.369,13.81c-0.239,0.757,0.622,1.384,1.269,0.921l11.784-8.423l11.784,8.423c0.646,0.461,1.508-0.164,1.269-0.921l-4.369-13.81 l11.651-8.604c0.639-0.471,0.31-1.485-0.484-1.491l-14.484-0.113l-4.583-13.74C180.809,131.675,179.743,131.675,179.492,132.428z" />
                <path fill="#FFFFFF" d="M131.859,149.741l-4.583,13.74l-14.484,0.113c-0.794,0.007-1.123,1.02-0.484,1.491l11.651,8.604 l-4.369,13.81c-0.239,0.757,0.622,1.384,1.269,0.921l11.784-8.423l11.784,8.423c0.646,0.461,1.508-0.164,1.269-0.921l-4.369-13.81 l11.651-8.604c0.639-0.471,0.31-1.485-0.484-1.491l-14.484-0.113l-4.583-13.74C133.176,148.989,132.11,148.989,131.859,149.741z" />
                <path fill="#FFFFFF" d="M332.647,132.428l4.583,13.74l14.484,0.113c0.794,0.007,1.123,1.02,0.484,1.491l-11.651,8.604 l4.369,13.81c0.239,0.757-0.622,1.384-1.269,0.921l-11.784-8.423l-11.784,8.423c-0.646,0.461-1.508-0.164-1.269-0.921l4.369-13.81 l-11.651-8.604c-0.639-0.471-0.31-1.485,0.484-1.491l14.483-0.113l4.583-13.74C331.33,131.675,332.396,131.675,332.647,132.428z" />
                <path fill="#FFFFFF" d="M380.28,149.741l4.583,13.74l14.484,0.113c0.794,0.007,1.123,1.02,0.484,1.491l-11.651,8.604 l4.369,13.81c0.239,0.757-0.622,1.384-1.269,0.921l-11.784-8.423l-11.784,8.423c-0.646,0.461-1.508-0.164-1.269-0.921l4.369-13.81 l-11.651-8.604c-0.639-0.471-0.309-1.485,0.484-1.491l14.484-0.113l4.583-13.74C378.963,148.989,380.029,148.989,380.28,149.741z" />
            </g>
        </svg>
    </React.Fragment>
);
