
/** @jsx jsx */
import { jsx } from "@emotion/react"
import * as React from 'react';
import { Menu, MenuWithChild } from "@bdl-cmn-shared-packages-npm/design-system";
import { SUSTAINABLE_CLASSIFICATIONS } from "../../../../Search/useFilters";


type LabelByCLassification = {
  [key in SUSTAINABLE_CLASSIFICATIONS]: string
}


const LABEL_BY_CLASSIFICATION: LabelByCLassification = {
  "RBDL_GRBD": "Green Bonds",
  "RBDL_SUST": "Sustainability Bonds",
  "RBDL_SOCI": "Social Bonds"
}
export interface SDGsListProps {
  selection: string[];
  onSelectionChange: (values: string[]) => void;
}


export function SustainableClassificationFilter(props: SDGsListProps) {
  const {
    selection,
    onSelectionChange
  } = props;

  return (
    <MenuWithChild
      icon="leaf"
      label="Sustainable Classification"
      width="100%;"
      expanded={false}
      arrows={false}>
      {
        Object.keys(SUSTAINABLE_CLASSIFICATIONS).map((value: SUSTAINABLE_CLASSIFICATIONS) => (
          <Menu
            key={value}
            width="100%;"
            selected={selection.includes(value)}
            onSelect={() => onSelectionChange(
              !selection.includes(value) ? [...selection, value] : selection.filter(v => v !== value)
            )}
            childLabel={LABEL_BY_CLASSIFICATION[value] as string} />
        ))
      }

    </MenuWithChild>
  );
}