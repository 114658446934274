import { css } from "@emotion/react";

export default {
  datePicker: css`
    position: absolute;
    top: 48px;
    left: 0;
    background-color: white;
    border-radius: 3px;
    border: solid 1px #d9e1e2;
    box-shadow: 0px 4px 4px rgba(101, 127, 147, 0.2);
    color: black;
    z-index: 3;

    .Selectable {
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: #f0f8ff !important;
        color: green;
      }

      .DayPicker-Months {
        display: flex;
        flex-wrap: nowrap;
      }

      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: #22aa5f;
        color: white;
      }

      .DayPicker-Day {
        border-radius: 0 !important;
      }

      .DayPicker-Day--start {
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
      }

      .DayPicker-Day--end {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
      }
    }

    .DayPicker {
      overflow: hidden !important;

      .DayPicker-wrapper {
        &:focus {
          outline: 0 !important;
        }
      }
    }
  `,
  container: css`
    position: relative;

    .MuiFormControl-root,
    .MuiTextField-root {
      > div {
        min-height: 0 !important;
        height: 40px;
      }
    }
  `,
  inputWrapper: css`
    background: white;
    border-radius: 4px;
  `,
};
