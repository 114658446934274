
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import Footer from '../../core/components/Footer/footer.component';
import { SitecoreGenericTextField } from '../../interfaces';

export interface FooterProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const FooterComponent: React.FunctionComponent<FooterProps> = (props: FooterProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <Footer />
    );
}
