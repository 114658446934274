/** @jsx jsx */

import React, { Fragment, useState } from 'react';
import { css, jsx } from '@emotion/react';

import Props from '../../UI/list/base/props';
import { RisersAndFallersDataModel } from '../View/risers-fallers-table';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';
import RoundTag from '../../RoundTag/roundTag.component';
import { sharedStyle } from '../../../../styles/shared';

export const RisersFallersCard = (props: any): JSX.Element => {
  const { card } = props;
  const { type } = props;

  const computeVariationStyle = (amount: number, marker: string) => {

    return (
        <Fragment>
            {
                <p style={{ color: amount <= 0 ? "#CB1234" : "#22AA5F", }}>
                    <span>{Number(amount).toFixed(2)} {marker}</span>&nbsp;
                    {
                        amount <= 0 ? (
                            <Icon icon={"chevron-down"} />
                        ) : (
                            <Icon icon={"chevron-up"} />
                        )
                    }
                </p>
            }
        </Fragment>
    )
};

  if (!card) return <></>;

  return (
    <div css={{ width: "100%" }}>
      <div
        css={css`
          background: #ffffff;
          border: 1px solid #d9dee2;
          border-radius: 8px;
          filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
          margin-bottom: 16px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-bottom: solid 1px #d9dee2;
            padding: 16px;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              font-weight: 800;
              font-size: 20px;
              line-height: 26px;
              color: #253845;
              display:flex;
            `}
          >
            <div css={[sharedStyle.cardContainerBodyDoubleLeft, css`padding-right:5px;`]}>
              <RoundTag small textColor={'#425563'}>
                E
              </RoundTag>
            </div>
            <div>{card.description}</div>
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            ISIN
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            {card.isinCode}
          </div>
        </div>
        <div
          css={css`
            border-bottom: solid 1px #d9dee2;
            display: flex;
          `}
        >
          <div
            css={css`
              width: 50%;
              border-right: solid 1px #d9dee2;
              padding: 16px;
            `}
          >
            <div
              css={css`
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #425563;
              `}
            >
              Last price
            </div>
            <div
              css={css`
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #54666f;
                margin-top: 8px;
              `}
            >
              <div>{card.lastPrice}</div>
            </div>
          </div>
          <div
            css={css`
              width: 50%;
              padding: 16px;
            `}
          >
            <div
              css={css`
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.01em;
                color: #425563;
              `}
            >
              {type==="Month"?"Last price M-1":"Last price Y-1"}
            </div>
            <div
              css={css`
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #54666f;
                margin-top: 8px;
              `}
            >
              {type==="Month"?card.monthPrice:card.yearPrice}
            </div>
          </div>
        </div>
        <div
          css={css`
            padding: 16px;
            border-bottom: solid 1px #d9dee2;
          `}
        >
          <div
            css={css`
              font-weight: 700;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              color: #425563;
            `}
          >
            {type==="Month"?"MTD Variations":"YTD Variations"}
          </div>
          <div
            css={css`
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.01em;
              color: #54666f;
              margin-top: 8px;
            `}
          >
            {type==="Month"?computeVariationStyle(card.mtdVariation,"%"):computeVariationStyle(card.ytdVariation,"%")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RisersFallersCard;
