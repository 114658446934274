import React from 'react';
/** @jsx jsx */
import {css,jsx} from '@emotion/react';
import { sharedStyle } from '../../../../../styles/shared';
import { Table, Td, Th, Tr } from '@bdl-cmn-shared-packages-npm/design-system';
import SearchSecurity from '../../../../../interfaces/search/search-security';
import LGXSustainableBondsRow from './row';

export const LGXSustainableBondsTable = ({data}: {data: SearchSecurity[]}) => {
  return (
    <Table css={sharedStyle.table}>
      <thead
        style={{
          color: "#253645",
          fontSize: "0.8rem",
          textAlign: "left",
        }}
      >
        <Tr css={css`background: #F8FAFC !important; `}>
          <Th>Security</Th>
          <Th>Last</Th>
        </Tr>
      </thead>
      <tbody>
        { data?.length > 0? 
          data?.map((item: SearchSecurity) => <LGXSustainableBondsRow row={item} key={`lgx-sustainable-bonds-row-${item?.id}`}/>)
          :
          <Tr>
            <Td>-</Td>
            <Td>-</Td>
          </Tr>
        }
      </tbody>
    </Table>
  );
};
export default LGXSustainableBondsTable;
