/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { Component } from 'react';
import { sharedStyle } from '../../../../../styles/shared';
import { Link, BrowserRouter } from 'react-router-dom';
import { Icon } from '@bdl-cmn-shared-packages-npm/design-system';

export interface GenericMarketNewsLinkProps {
  pathname?: string;
  data: any;
  label?: string;
  totalHits: number;
}

const GenericLink: React.FunctionComponent<GenericMarketNewsLinkProps> = ({
  pathname,
  data,
  label,
  totalHits,
}: GenericMarketNewsLinkProps) => {
  return (
    <div css={sharedStyle.link_container}>
      <BrowserRouter forceRefresh={true}>
        <a css={sharedStyle.link_data_market} href={pathname}
           //state: { marketNewsFilter: data } }}
        >
          Discover all {totalHits} {label} &nbsp; &nbsp;
          <Icon icon={'chevron-right'} />
        </a>
      </BrowserRouter>
    </div>
  );
};
export default GenericLink;
