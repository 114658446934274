
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface LifeBelowWaterProps {
    size: Size;
    style?: React.CSSProperties;
}

export const LifeBelowWater: React.FunctionComponent<LifeBelowWaterProps> = ({ size: { width, height }, style }: LifeBelowWaterProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 43 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>14 - Life Below Water</title>
            <path d='M3.81877 1.04834V13.1401H1.78298V3.88577H1.76434L0.340027 5.36601H0.321384V3.24073L2.19312 1.04834H3.81877Z' fill='white'/>
            <path d='M10.0082 13.1441V11.1829H10.8285V9.59451H10.0082V1.04492H8.00968L4.49364 9.93008V11.1792H8.08052V13.1404H10.0082V13.1441ZM6.27963 9.59451L8.09916 4.61688H8.11781V9.59451H6.27963Z' fill='white'/>
            <path d='M42.1745 39.7809C41.4213 38.7593 36.5406 32.54 29.7024 32.54C23.7628 32.54 17.4131 37.9278 17.4131 37.9278C17.4131 37.9278 15.0268 33.0434 10.1461 32.5736C10.1163 32.5736 10.0865 32.5661 10.0529 32.5661H10.0231C9.92242 32.5736 9.82548 32.6705 9.83667 32.76C9.85158 32.8458 9.91497 32.939 9.91497 32.939C10.5451 33.983 12.1484 36.9547 12.1484 39.9263C12.1484 42.9054 10.5339 45.8846 9.91124 46.9248L9.87022 46.9919C9.85158 47.0218 9.8404 47.0553 9.8404 47.0963C9.8404 47.2007 9.9187 47.279 10.0194 47.2902L10.0716 47.2865C10.0865 47.2865 10.1051 47.2865 10.1238 47.2828C15.0194 46.8279 17.4168 41.9286 17.4168 41.9286C17.4168 41.9286 23.7703 47.3163 29.7062 47.3163C36.5443 47.3163 41.4213 41.0971 42.1782 40.0792C42.2006 40.0531 42.2155 40.027 42.2304 40.0083C42.2379 39.986 42.2453 39.9636 42.2453 39.9338C42.2453 39.9077 42.2379 39.8816 42.2304 39.8592C42.2118 39.8294 42.1968 39.807 42.1745 39.7809ZM33.5056 40.7093C32.4019 40.7093 31.5108 39.8145 31.5108 38.7108C31.5108 37.6072 32.4056 36.716 33.5056 36.716C34.6092 36.716 35.5041 37.6072 35.5041 38.7108C35.5041 39.8145 34.613 40.7093 33.5056 40.7093Z' fill='white'/>
            <path d='M9.98955 20.8509C9.97464 20.8471 9.96345 20.8434 9.95226 20.8434C9.84414 20.8434 9.75465 20.9329 9.75465 21.041V22.4019C9.75465 22.4579 9.77702 22.5101 9.81058 22.5436C13.1104 25.4109 16.2386 22.7226 16.962 22.0142C16.9769 21.9918 16.9918 21.9732 17.0142 21.9582C17.0477 21.9359 17.0813 21.921 17.1223 21.921C17.1745 21.921 17.223 21.9396 17.2565 21.9732C17.2602 21.9769 17.264 21.9806 17.2677 21.9806C20.9963 25.5377 24.9075 22.6555 25.7203 21.9806L25.7986 21.906C25.821 21.8949 25.8471 21.8911 25.8732 21.8911C25.8993 21.8911 25.9291 21.8949 25.9515 21.906L26.0335 21.9806C26.8464 22.6555 30.7576 25.5377 34.4862 21.9806C34.4862 21.9806 34.4899 21.9769 34.4937 21.9732C34.5272 21.9396 34.5757 21.921 34.6279 21.921C34.6689 21.921 34.7062 21.9359 34.736 21.9582C34.7584 21.9732 34.7733 21.9918 34.7919 22.0142C35.5153 22.7226 38.6361 25.4109 41.9396 22.5436C41.9731 22.5101 41.9955 22.4579 41.9955 22.4019V21.041C41.9955 20.9329 41.9098 20.8434 41.8016 20.8434C41.7867 20.8434 41.7755 20.8471 41.7606 20.8509C41.7308 20.8695 41.6972 20.8844 41.6599 20.9031C40.6458 21.4101 37.4951 22.6219 34.6391 19.9187C34.613 19.8926 34.5831 19.8702 34.557 19.8404C34.5309 19.8292 34.5048 19.8218 34.475 19.8218C34.4564 19.8218 34.434 19.8292 34.4154 19.8292C34.3743 19.8479 34.3371 19.874 34.3147 19.9075C33.4608 20.5563 29.5533 23.2036 26.0708 19.956L26.0186 19.9075C25.9813 19.874 25.9329 19.8479 25.8769 19.8479C25.8247 19.8479 25.7763 19.874 25.739 19.9075L25.6831 19.956C22.2043 23.2036 18.2893 20.5563 17.4392 19.9075C17.4168 19.874 17.3833 19.8479 17.3423 19.8292C17.3236 19.8255 17.3013 19.8218 17.2789 19.8218C17.2528 19.8218 17.223 19.8292 17.1969 19.8404C17.1708 19.8702 17.1409 19.8963 17.1148 19.9187C14.255 22.6219 11.1081 21.4139 10.0939 20.9031C10.0567 20.8844 10.0231 20.8695 9.98955 20.8509Z' fill='white'/>
            <path d='M16.9657 26.9658C16.9806 26.9435 16.9955 26.9285 17.0179 26.9099C17.0514 26.8913 17.085 26.8726 17.126 26.8726C17.1782 26.8726 17.2267 26.895 17.2602 26.9285C17.2602 26.9323 17.2677 26.936 17.2677 26.936C20.9963 30.4893 24.9075 27.6109 25.7203 26.936L25.7986 26.8614C25.821 26.854 25.8471 26.8502 25.8732 26.8502C25.903 26.8502 25.9291 26.854 25.9515 26.8614L26.0298 26.936C26.8426 27.6109 30.7539 30.4893 34.4825 26.936C34.4825 26.9323 34.4862 26.9323 34.4899 26.9285C34.5235 26.895 34.572 26.8726 34.6204 26.8726C34.6614 26.8726 34.6987 26.8875 34.7286 26.9099C34.7509 26.9248 34.7658 26.9435 34.7845 26.9658C35.5078 27.678 38.6286 30.3663 41.9321 27.4953C41.9657 27.4617 41.9918 27.4133 41.9918 27.3573V25.9927C41.9918 25.8846 41.906 25.7988 41.7979 25.7988C41.783 25.7988 41.7718 25.8025 41.7569 25.8063C41.7271 25.8212 41.6935 25.8398 41.6562 25.8547C40.642 26.3655 37.4914 27.5699 34.6353 24.8704C34.6092 24.8443 34.5794 24.8182 34.5533 24.7921C34.5272 24.7772 34.5011 24.7734 34.4713 24.7734C34.4526 24.7734 34.4303 24.7772 34.4116 24.7884C34.3669 24.7995 34.3333 24.8256 34.311 24.8629C33.4608 25.5117 29.5496 28.159 26.0671 24.9114L26.0149 24.8592C25.9776 24.8256 25.9291 24.8033 25.8732 24.8033C25.821 24.8033 25.7725 24.8219 25.7353 24.8592L25.6831 24.9114C22.2043 28.159 18.2893 25.5117 17.4392 24.8629C17.4168 24.8294 17.3833 24.7995 17.3423 24.7884C17.3236 24.7772 17.3013 24.7734 17.2789 24.7734C17.2491 24.7734 17.223 24.7772 17.1969 24.7921C17.1708 24.8182 17.1409 24.8443 17.1111 24.8704C14.2513 27.5699 11.1044 26.3655 10.0902 25.8547C10.0529 25.8361 10.0194 25.8212 9.98955 25.8063C9.97464 25.8025 9.96345 25.7988 9.95226 25.7988C9.84414 25.7988 9.75465 25.8846 9.75465 25.9927V27.3573C9.75465 27.4133 9.77702 27.4617 9.81058 27.4953C13.1141 30.3663 16.2423 27.678 16.9657 26.9658Z' fill='white'/>
            <path d='M15.1462 1.02588H15.9702V5.2578H16.9545V5.92894H15.1462V1.02588Z' fill='white'/>
            <path d='M17.3386 1.02588H18.1626V5.92894H17.3386V1.02588Z' fill='white'/>
            <path d='M18.7144 1.02588H20.6458V1.69702H19.5384V3.09896H20.3624V3.76264H19.5384V5.92521H18.7144V1.02588Z' fill='white'/>
            <path d='M21.0112 1.02588H22.9799V1.69702H21.839V3.09896H22.6555V3.76264H21.839V5.25407H22.9799V5.92521H21.0112V1.02588Z' fill='white'/>
            <path d='M24.5198 1.02588H25.7129C26.5369 1.02588 26.8128 1.40992 26.8128 2.10343V2.60306C26.8128 3.01693 26.66 3.2742 26.3207 3.38233C26.7345 3.47554 26.9359 3.75146 26.9359 4.19888V4.85138C26.9359 5.5449 26.6264 5.92894 25.8024 5.92894H24.5235V1.02588H24.5198ZM25.3475 3.0915H25.6383C25.9068 3.0915 26.0298 2.95355 26.0298 2.69255V2.09225C26.0298 1.82379 25.9068 1.69329 25.6309 1.69329H25.3475V3.0915ZM25.3475 3.72163V5.25407H25.7092C25.9851 5.25407 26.1156 5.11611 26.1156 4.85511V4.11686C26.1156 3.85586 25.9925 3.7179 25.7017 3.7179H25.3475V3.72163Z' fill='white'/>
            <path d='M27.2901 1.02588H29.2588V1.69702H28.1178V3.09896H28.9344V3.76264H28.1178V5.25407H29.2588V5.92521H27.2901V1.02588Z' fill='white'/>
            <path d='M29.7584 1.02588H30.5824V5.2578H31.5668V5.92894H29.7584V1.02588Z' fill='white'/>
            <path d='M31.8837 4.66131V2.28995C31.8837 1.52186 32.2603 0.966309 33.129 0.966309C34.0052 0.966309 34.3818 1.52186 34.3818 2.28995V4.66131C34.3818 5.42194 34.0052 5.98495 33.129 5.98495C32.2603 5.98495 31.8837 5.42194 31.8837 4.66131ZM33.5541 4.79181V2.15945C33.5541 1.86116 33.4236 1.65236 33.129 1.65236C32.8382 1.65236 32.7114 1.86116 32.7114 2.15945V4.79181C32.7114 5.0901 32.8419 5.2989 33.129 5.2989C33.4236 5.2989 33.5541 5.0901 33.5541 4.79181Z' fill='white'/>
            <path d='M36.563 2.89762L36.1007 5.92894H35.3252L34.6689 1.02588H35.4631L35.8472 4.1355H35.8546L36.2685 1.02588H36.9396L37.3945 4.1355H37.402L37.7785 1.02588H38.4721L37.8345 5.92894H37.0552L36.5705 2.89762H36.563Z' fill='white'/>
            <path d='M16.9284 10.0939L16.4661 13.1252H15.6905L15.0343 8.22217H15.8285L16.2125 11.3318H16.22L16.6339 8.22217H17.305L17.7599 11.3318H17.7673L18.1439 8.22217H18.8374L18.1999 13.1252H17.4206L16.9359 10.0939H16.9284Z' fill='white'/>
            <path d='M18.7591 13.1252L19.6838 8.22217H20.6234L21.5556 13.1252H20.7092L20.5563 12.2304H19.6801L19.5347 13.1252H18.7591ZM19.792 11.5704H20.4482L20.1238 9.60547H20.1163L19.792 11.5704Z' fill='white'/>
            <path d='M21.399 8.22217H23.5914V8.89331H22.9128V13.1252H22.0813V8.89331H21.4027V8.22217H21.399Z' fill='white'/>
            <path d='M23.9717 8.22217H25.9404V8.89331H24.7994V10.2952H25.616V10.9589H24.7994V12.4504H25.9404V13.1215H23.9717V8.22217Z' fill='white'/>
            <path d='M27.2043 10.8769V13.1252H26.3803V8.22217H27.5809C28.4124 8.22217 28.7144 8.63604 28.7144 9.32209V9.95222C28.7144 10.4817 28.528 10.7911 28.0582 10.8993L28.8449 13.1252H27.9762L27.2043 10.8769ZM27.2043 8.89331V10.4183H27.4803C27.7636 10.4183 27.8867 10.2729 27.8867 10.0119V9.28854C27.8867 9.02008 27.7636 8.88958 27.4803 8.88958H27.2043V8.89331Z' fill='white'/>

        </svg>
    );
};
