
/** @jsx jsx */
import { jsx } from "@emotion/react"
import * as React from 'react';
import { Menu, MenuWithChild } from "@bdl-cmn-shared-packages-npm/design-system";
import { setCouponTypeLabel } from "../../../../../../graphql";
import { CouponType } from "../../../../../../enums";
import { CouponTypeFilter as CouponTypeFilterModel } from "../../../../../../interfaces/search/search-filter-params";
import { style } from "../../../../Search/results/filters/index.style";

export interface CouponTypeFilterProps {
  selection: CouponTypeFilterModel;
  onSelectionChange: (values: CouponTypeFilterModel) => void;
}


export function CouponTypeFilter(props: CouponTypeFilterProps) {
  const {
    selection,
    onSelectionChange
  } = props;

  return (
    <MenuWithChild
      icon="file-chart-line"
      label="Coupon type"
      width="100%;"
      expanded={false}
      arrows={false}>
      {
        Object.keys(CouponType).map((value: keyof typeof CouponType) => (
          <div css={style.menuItem}>
            <Menu
              key={value}
              width="100%;"
              selected={selection.includes(value)}
              onSelect={() => onSelectionChange(
                !selection.includes(value) ? [...selection, value] : selection.filter(v => v !== value)
              )}
              childLabel={setCouponTypeLabel(CouponType[value])} />
          </div>
        ))
      }
    </MenuWithChild>
  );
}