import { css } from "@emotion/react";

export const style = {
  container: css`
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
  `,
};
