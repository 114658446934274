/** @jsx jsx */

import React, { useState } from "react";

import { Density } from "../../UI/list/customizer/customizer.component";
import FilterButton from "../../Search/results/searchMobile/filterButton";
import { GET_SEARCH_SECURITIES_ONLY_RESULTS } from "../../../../graphql";
import Loader from "../../Loader/loader.component";
import MobileFilterContainer from "../../Search/results/searchMobile/mobileFilterContainer";
import { Query } from "react-apollo";
import SearchFilterParams from "../../../../interfaces/search/search-filter-params";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import { SecuritiesListView } from "../../UI/list/security";
import { SecurityFilters } from "../../UI/list/security/security-filters";
import SecuritySearchCard from "../../UI/list/security/card-mobile";
import { SecuritySearchResultDto } from "../../../../interfaces/search/search";
import { genericStyle } from "../../UI/generic/generic.style";
import { jsx } from "@emotion/react";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { searchSortData } from "../../Search/results/dataMapper";
import Utils from "../../../utils/utils";

export interface ProgrammeSecuritiesProps {
  programmeId: string;
}

const defaultSearchFilters: SearchFilterParams = {
  excludeRetr: true,
  categories: [],
  lgxSustainableBonds: [],
  lgxSustainableFunds: [],
  lgxStandards: [],
  lgxSdgs: [],
  lgxEligibleCategories: [],
  lgxSlbKpiThemes: [],
};

const ProgrammeSecurities = ({ programmeId }: ProgrammeSecuritiesProps) => {
  const selectedCols: any = null;
  const density: Density = "medium";

  const [filters, setFilters] =
    React.useState<SearchFilterParams>(defaultSearchFilters);
  const [itemsNumber, setItemsNumber] = useState<number>(20);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [sort, setSort] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const switchFilter = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const onPaginationUpdate = (pageNumber: number) => {
    Utils.moveToTop();
    setCurrentPageNumber(pageNumber);
  };

  React.useEffect(() => {
    setCurrentPageNumber(0);
  }, [filters]);

  return (
    <Query<{ luxseSecuritiesSearch: SecuritySearchResultDto }, any>
      client={luxseGatewayGraphqlClient}
      query={GET_SEARCH_SECURITIES_ONLY_RESULTS}
      variables={{
        ...filters,
        token: Utils.getCookie("Gztoken-Dsm"),
        searchTerm: searchTerm ? searchTerm : "",
        size: itemsNumber,
        page: currentPageNumber,
        sort: sort && sort.field ? `${sort.field},${sort.dir}` : "",
        programmeId: programmeId,
        securityStatuses: ["ADMI", "COTE", "NEGO", "RETR"],
        securityStatusesOperator: "OR",
      }}
      fetchPolicy="network-only"
      errorPolicy="all"
    >
      {({ loading, error, data }): React.ReactNode => {
        return (
          <Loader
            loading={loading && !data?.luxseSecuritiesSearch}
            padding={"5em 0 !important"}
          >
            <div css={genericStyle.desktopDisplay}>
              <SecuritiesListView
                data={data.luxseSecuritiesSearch}
                loadingPage={loading}
                density={density}
                onPaginationUpdate={onPaginationUpdate}
                currentPageNumber={currentPageNumber}
                totalResultSize={data?.luxseSecuritiesSearch?.totalHits || 0}
                filters={filters}
                onFiltersChange={setFilters}
                sortData={searchSortData}
                sort={sort}
                onSortChange={setSort}
                selectedCols={selectedCols}
              />
            </div>
            <div css={genericStyle.mobileDisplay}>
              <div css={{ padding: "2em", width: "100%" }}>
                <SearchMobile
                  onPaginationUpdate={onPaginationUpdate}
                  pageNumber={currentPageNumber}
                  data={data?.luxseSecuritiesSearch?.securities}
                  totalResultSize={data?.luxseSecuritiesSearch?.totalHits}
                  Card={(card) => (
                    <SecuritySearchCard
                      key={`card-security-${card?.id}`}
                      card={card}
                    />
                  )}
                />
              </div>
              <FilterButton onSwitchFilter={switchFilter} open={menuIsOpen} />
              <MobileFilterContainer open={menuIsOpen}>
                <SecurityFilters
                  filterCount={data?.luxseSecuritiesSearch?.filters}
                  filters={filters}
                  onFiltersChange={setFilters}
                  fullWidth
                />
              </MobileFilterContainer>
            </div>
          </Loader>
        );
      }}
    </Query>
  );
};

export default ProgrammeSecurities;
