import * as React from 'react';

import { Text, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import {fetchData, getDataWithChild} from "../../utils/helper";
import {
    resourceContent,
    rootPage
} from "../../core/components/Resources/shared.style";
import { Button } from '@bdl-cmn-shared-packages-npm/design-system';
import {EventComponent} from "../../core/components/Event/event.component";
import Loader from "../../core/components/Loader/loader.component";
import {SitecoreGenericTextField} from "../../interfaces";
import {canUseDOM} from "../../predicates";
import { formatDate, getCurrentMoment, parseToMoment } from '../../utils/date';
import moment from 'moment';

/** @jsx jsx */

const titleStyle = css`
  font-weight: 800;
  font-size: 32px;
  line-height: 41px;
  color: #1A2834;
  margin-top: 40px;
`;
const descriptionStyle = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #354450;
`;
const container = css`
  padding-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  & > div > div {
    width: 100%;
    margin-left: 0;
  }
`;
const action = css`
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const cardsContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  height: 100%;
  align-self: stretch;
  @media (max-width: ${breakpointMax}) {
    flex-direction: column;
    gap: 0;
  }
`;

export const EventsCards = (props: any) => {
    const { fields } = props;
    const [events, setEvents] = React.useState<any>(null);
    const [isLoadingEvents, setIsLoadingEvents] = React.useState<boolean>(true);
    const buttonLabel: string = getFieldValue<string>(fields, 'button label', '');
    const buttonLink: any  = getFieldValue<any>(fields, 'button link', '');
    const cardNumber: number = getFieldValue<number>(fields, 'number of cards', 3) || 3;

    const fetchEvents = async(id: string) => {
        try {
            const result: any = await fetchData(id);
            const data = await getDataWithChild(result);
            dissociateEvents(data);
        } catch(err) {
            console.error(err);
        }
    }

    const dissociateEvents = (data: any) => {
        const eventList: any[] = [];
        const currentDate = moment().format('DD/MM/YYYY');
        data.forEach((d: any) => {
            d && d.children.forEach((child: any) => {
                const formattedDate = formatDate(child['Start date'], "DD/MM/YYYY");
                const isPastEvent = moment(formattedDate, 'DD/MM/YYYY').isBefore(moment(currentDate, 'DD/MM/YYYY'));
                eventList.push({
                    ...child,
                    formattedDate,
                    isPastEvent 
                  });
            })
        })

        setEvents(eventList);
        setIsLoadingEvents(false);
    }

    React.useEffect(() => {
        fetchEvents('3FE49801-613A-4AD0-B8C6-83B906ABFBD7');
    }, []);

    const getDateRange = (from: string, to: string) => {
        const fromMoment = parseToMoment(from);
        const toMoment = to ? parseToMoment(to) : null; 
      
        if (toMoment && fromMoment.format('DD') !== toMoment.subtract(1, 'day').format('DD')) {
          return `${fromMoment.format('DD')}${to ? ' - ' : ''}${toMoment.format('DD')}`;
        }
        return `${fromMoment.format('DD')}`; 
      };
    return (
        <div css={rootPage('#F6F8F9')}>
            <div css={container}>
                <Text
                    tag="h2"
                    css={titleStyle}
                    field={fields && fields.title as SitecoreGenericTextField}
                />
                <Text
                    tag="h2"
                    css={descriptionStyle}
                    field={fields && fields.description as SitecoreGenericTextField}
                />
                <Loader loading={isLoadingEvents} centered css={{minHeight: 400}}>
                    <div css={resourceContent('row')}>
                        <div css={cardsContainer}>
                            {
                                events && events.slice(0, cardNumber).map((child: any, index: number) => (
                                    <EventComponent
                                        title={child.Title}
                                        month={formatDate(child['End date'], 'MMMM')}
                                        subTitle={child.Description}
                                        day={getDateRange(child['Start date'], child['End date'])}
                                        embed
                                        white
                                        isPastEvent={child.isPastEvent}
                                    />
                                ))
                            }
                        </div>
                    </div>
                    <div css={action}>
                        <Button iconRight={'arrow-right'} label={buttonLabel} onClick={canUseDOM ? () => (window.location.href = buttonLink ? buttonLink: console.debug()) : null}/>
                    </div>
                </Loader>
            </div>
        </div>
    );
}
