
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const MKD: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
            <rect y="85.333" fill="#FFE600" width="512" height="341.337" />
            <g>
                <polygon fill="#D20000" points="383.875,426.662 256,252.286 282.827,426.662 " />
                <polygon fill="#D20000" points="133.565,85.33 256,252.286 230.314,85.33 " />
                <polygon fill="#D20000" points="229.171,426.662 256,252.286 128.124,426.662 " />
                <polygon fill="#D20000" points="0,85.33 0,212.9 256,252.286 28.333,85.33 " />
                <polygon fill="#D20000" points="0,426.662 18.212,426.662 256,252.286 0,291.67 " />
                <polygon fill="#D20000" points="256,252.286 512,212.9 512,85.33 483.669,85.33 " />
                <polygon fill="#D20000" points="281.686,85.33 256,252.286 378.434,85.33 " />
                <polygon fill="#D20000" points="512,426.662 512,291.671 256,252.286 493.787,426.662 " />
                <circle fill="#D20000" cx="256" cy="252.29" r="59.359" />
            </g>
            <circle fill="#FFE600" cx="256" cy="252.29" r="44.522" />
        </svg>
    </React.Fragment>
);
