import { css } from '@emotion/react';

export default {
  list: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
      padding: 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      border-radius: 4px;
      opacity: 0.5;
      cursor: pointer;
      transition: all ease 0.5s;

      :hover {
        opacity: 0.8;
      }
    }
  `,
  selected: css`
    opacity: 1 !important;
  `,
  GOAL_1: css`
    background-color: #ea1c2d;
  `,
  GOAL_2: css`
    background-color: #d19f2a;
  `,
  GOAL_3: css`
    background-color: #2d9a47;
  `,
  GOAL_4: css`
    background-color: #c22033;
  `,
  GOAL_5: css`
    background-color: #ef412a;
  `,
  GOAL_6: css`
    background-color: #00add8;
  `,
  GOAL_7: css`
    background-color: #fdb714;
  `,
  GOAL_8: css`
    background-color: #8f1838;
  `,
  GOAL_9: css`
    background-color: #f36e24;
  `,
  GOAL_10: css`
    background-color: #e01a83;
  `,
  GOAL_11: css`
    background-color: #f99d25;
  `,
  GOAL_12: css`
    background-color: #cd8b2a;
  `,
  GOAL_13: css`
    background-color: #48773c;
  `,
  GOAL_14: css`
    background-color: #007dbb;
  `,
  GOAL_15: css`
    background-color: #40ae49;
  `,
  GOAL_16: css`
    background-color: #00558a;
  `,
  GOAL_17: css`
    background-color: #1a3668;
  `,
};
/*
.sdgs-list {
    @include flexBox(row);
    flex-wrap: wrap;
    margin-bottom: -18px; // à l'ancienne frérot

    &__item {
        @include flexBox(column, center, center, auto, auto);
        margin-right: 18px;
        margin-bottom: 18px;
        padding: 5px;
        border-radius: 6px;
        background-color: red;

        &__selectable {
            cursor: pointer !important;
            opacity: 0.3;
        }

        &-selected {
            opacity: 1 !important;
        }

        GOAL_1: css`background-color: #EA1C2D`},
        GOAL_2: css`background-color: #D19F2A`},
        GOAL_3: css`background-color: #2D9A47`},
        GOAL_4: css`background-color: #C22033`},
        GOAL_5: css`background-color: #EF412A`},
        GOAL_6: css`background-color: #00ADD8`},
        GOAL_7: css`background-color: #FDB714`},
        GOAL_8: css`background-color: #8F1838`},
        GOAL_9: css`background-color: #F36E24`},
        GOAL_10: css`background-color: #E01A83`},
        GOAL_11: css`background-color: #F99D25`},
        GOAL_12: css`background-color: #CD8B2A`},
        GOAL_13: css`background-color: #48773C`},
        GOAL_14: css`background-color: #007DBB`},
        GOAL_15: css`background-color: #40AE49`},
        GOAL_16: css`background-color: #00558A`},
        GOAL_17: css`background-color: #1A3668`},
    }
}
*/
