import { ApolloError } from "apollo-client";
import gql from "graphql-tag";
import { Country, CurrencyLong, PeriodicityCode } from "../../../enums";
import { securityMarketRealtimeFragment, securityMarketSummaryFragment } from "../securities-market.queries";

export const securityLuxXPrimeSearchItemFragment = gql`
  ${securityMarketSummaryFragment}
  ${securityMarketRealtimeFragment}
  fragment securityLuxXPrimeSearchItemFragment on Security {
    id
    name: instrumentName
    isinCode: isin
    status
    delistingDate
    currency
    category: instrumentType
    subCategory: instrumentSubtype
    issuers {
      id
      name
    }
    lgxDisplay 
    listingDate
    finalMaturityDate: maturityDate
    tags
    issuedNominalAmount
    interestRate
    luxxprimeDate
    marketRule
    marketData {
      ...securityMarketSummaryFragment
    }
    marketRealtime {
      ...securityMarketRealtimeFragment
    }
  }
`;

export const securityLuxXPrimeSearchFullFragment = gql`
  ${securityLuxXPrimeSearchItemFragment}
  fragment securityLuxXPrimeSearchFullFragment on LuxXPrimeSearchResults {
    resultSize
    totalHits: totalResultSize
    securities: securitiesResult {
      ...securityLuxXPrimeSearchItemFragment
    }
  }
`;

export const securityLuxXPrimeSearchEmptyFragment = gql`
  fragment securityLuxXPrimeSearchEmptyFragment on LuxXPrimeSearchResults {
    resultSize
    totalHits: totalResultSize
  }
`;

export interface LuxXPrimeFilterResultsData {
  securitiesByTag: {
    currency: keyof typeof CurrencyLong,
    periodicityCode: keyof typeof PeriodicityCode
    issuers: {
      id: number
      name:string
      countryCode2: keyof typeof Country
    }[]
  }[]
}

export interface LuxXPrimeFilterResults {
  loading: boolean;
  error?: ApolloError;
  data: LuxXPrimeFilterResultsData
}

export const GET_LUXXPRIME_FILTER_DATA = gql`
  query GetLuxXPrimeCurrencies {
    securitiesByTag(tag: "LuxXPrime") {
      currency
      periodicityCode
      issuers {
        id
        name
        countryCode2
      }
    }
  }
`;

export const SEARCH_SORTING_LUXXPRIME =  gql`
fragment securityItemFragment on Security {
  id
  name: instrumentName
  isinCode: isin
  status
  delistingDate
  currency
  category: instrumentType
  subCategory: instrumentSubtype

  greenx: lgxDisplay
  listingDate
  finalMaturityDate: maturityDate
  tags
  issuedNominalAmount
  interestRate
  luxxprimeDate
  marketRule
  marketData {
    dayVolume
    yieldToMaturity
    tradeIndicator
    lastPrice {
      amount
      marker
      __typename
    }
    previousMonthLastPrice {
      amount
      marker
      __typename
    }
    dailyChange
    mtdVariation
    yieldToMaturity
    __typename
  }

}

query GetLuxseSearchResults
  {
    luxXPrimeSearch(
      textInput: "",
      pageNumber: 0
    ) {
      resultSize
      totalHits: totalResultSize
      securities: securitiesResult {
        ...securityItemFragment
      }
    }

  }
`;