import gql from "graphql-tag";

export const programmeSearchItemFragment = gql`
  fragment programmeSearchItemFragment on Programme {
    id
    type
    name
    url
    tags
    startDate
    endDate

    amountIssued
    amountCurrency

    activeSecurities
    #fastLaneUrls
    
    mainIssuer {
      id
      name
    }
    issuers{
      id
      name
    }
  } 
`;

export const programmeSearchFullFragment = gql`
  ${programmeSearchItemFragment}
  fragment programmeSearchFullFragment on LuxseSearchProgrammesResults {
    totalHits
    programmes {
      ...programmeSearchItemFragment
    }
    filters {
      startDateRanges {
        name
        fromDate
        toDate
        count
      }
      endDateRanges {
        name
        fromDate
        toDate
        count
      }
      programmeTypes {
        name
        count
      }
    }
  }
`;

export const programmeSearchEmptyFragment = gql`
  fragment programmeSearchEmptyFragment on LuxseSearchProgrammesResults {
    totalHits
  }
`;

export const programmeItemFragment = gql`
  fragment programmeItemFragment on Programme {
    id
    type
    name
    url
    startDate
    endDate
    currency
    amountIssued
    activeSecuritiesCount
    maxIssuedCode
    managementCompanyName
    mainIssuer {
      id
      name
    }
  } 
`;

export const programmesListFragment = gql`
  ${programmeItemFragment}
  fragment programmesListFragment on ProgrammesList {
    totalSize: totalResultSize
    data: programmes {
      ...programmeItemFragment
    }
  }
`;