
/*
*   e-Listing - core - components - country flag icon - grc
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const GRC: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 362.023 362.023' xmlSpace='preserve'>
        <rect y='60.338' style={{fill: '#F0F0F0'}} width='362.023' height='241.346'/>
        <g>
            <rect y='60.338' style={{fill: '#338AF3'}} width='362.023' height='30.168'/>
            <rect y='120.679' style={{fill: '#338AF3'}} width='362.023' height='30.168'/>
            <rect y='181.014' style={{fill: '#338AF3'}} width='362.023' height='30.168'/>
            <rect y='241.35' style={{fill: '#338AF3'}} width='362.023' height='30.168'/>
            <rect y='60.338' style={{fill: '#338AF3'}} width='133.788' height='120.676'/>
        </g>
        <g>
            <rect y='107.27' style={{fill: '#F0F0F0'}} width='133.788' height='26.814'/>
            <rect x='53.487' y='60.338' style={{fill: '#F0F0F0'}} width='26.814' height='120.676'/>
        </g>
    </svg>
);
