
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import RisersAndFallersView from "../../core/components/MarketStatistics/risers-and-fallers-view.component";

export const MarketStatisticsRisersAndFallers: React.FunctionComponent = (): React.ReactElement => {
    return (
        <RisersAndFallersView/>
    );
}
    