/** @jsx jsx */

import * as React from "react";
import { css, jsx } from "@emotion/react";

import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  ComponentParams,
  ComponentRendering,
  RichText,
  Text,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { SitecoreGenericTextField } from "../../interfaces";

const lint = jsx; // linter hack

export interface BannerBrandmarksCardProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const BannerBrandmarksCard: React.FunctionComponent<BannerBrandmarksCardProps> = (props: BannerBrandmarksCardProps): React.ReactElement => {
    const { fields } = props;

      const [expendedId, setExpendedId] = React.useState(-1);
      
      const handleExpandClick = (i: React.SetStateAction<number>) => {
        setExpendedId(expendedId === i ? -1 : i);
      };
  
      const style = {
        blocs: css`
          display: flex;
          flex-direction: column;
        `,
        bloc: css`
          display: flex;
          border-radius: 8px;
          margin-bottom: 16px;
          padding: 16px;
          padding-left: 32px;
          padding-right: 32px;
          background: #ffffff;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          transition: all 0.3s ease-in-out;
    
          :hover {
            background: #f0f5f5;
          }
        `,
        blocOpen: css`
          display: flex;
          border-radius: 8px;
          margin-bottom: 8px;
          padding: 16px;
          transition: all 0.3s ease-in-out;
          background: #f0f5f5;
        `,
        blocLeft: css`
          width: 32px;
          font-size: 16px;
          display: flex;
          align-items: center;
    
          svg {
            margin-right: 8px;
          }
        `,
        blocLeftExpend: css`
          width: 32px;
          font-size: 16px;
    
          svg {
            margin-right: 8px;
          }
        `,
        blocRight: css`
          display: flex;
          flex-direction: column;
          width: calc(100% - 32px);
        `,
        blocRightTitle: css`
          color: #22aa5f;
          font-size: 16px;
          font-weight: 800;
          line-height: 20px;
        `,
        blocRightTitleGreen: css`
          color: #354450;
          font-size: 16px;
          font-weight: 800;
          line-height: 20px;
          margin-bottom: 8px;
        `,
        blocRightText: css`
          color: #354450;
          font-size: 16px;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: 0.01em;
          margin-bottom: 8px;
          // min-width: 480px;
          @media (max-width: ${breakpointMax}) {
            min-width: unset;
          }
        `,
        blocRightLink: css`
          color: #22aa5f;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          text-decoration: underline;
          cursor: pointer;
        `,
      };
    
      const cardIndex = [{ }];

  return (
    <>
    {cardIndex.map((bloc: any, i: number) => 
      <div
        css={
          expendedId === i ? style.blocOpen : style.bloc
        }
        key={`blocs-${i}`}
        onClick={() => handleExpandClick(i)}
        style={{ minWidth: 480 }}
      >
        <div
          css={
            expendedId === i
              ? style.blocLeftExpend 
              : style.blocLeft
          }
        >
          {fields &&
            fields[`logo`] &&
            (
              fields &&
              (fields[`logo`] as SitecoreGenericTextField)
            ).value && (
              <Icon
                icon={
                  (
                    fields &&
                    (fields[`logo`] as SitecoreGenericTextField)
                  ).value
                }
              />
            )}
        </div>
        <div css={style.blocRight}>
          <div
            css={
              expendedId === i
                ? style.blocRightTitleGreen
                : style.blocRightTitle
            }
          >
            <Text
              field={
                fields[
                `title`
                ] as SitecoreGenericTextField
              }
            />
          </div>
          {expendedId === i && (
            <>
              <div css={style.blocRightText}>
                <RichText
                  field={
                    fields[
                    `text`
                    ] as SitecoreGenericTextField
                  }
                />
              </div>
              <div
                css={style.blocRightLink}
                onClick={() =>
                (window.location.href =
                  (fields &&
                    fields[`linkPath`] &&
                    (
                      fields &&
                      (fields[
                        `linkPath`
                      ] as SitecoreGenericTextField)
                    ).value) ||
                  "/")
                }
              >
                <RichText
                  field={
                    fields[
                    `linkLabel`
                    ] as SitecoreGenericTextField
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
      )}
    </>
  );
};