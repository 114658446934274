import React, { useContext, useState, useEffect } from "react";

/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Tab, Tabs } from "@bdl-cmn-shared-packages-npm/design-system";
import { style } from "./index.style";
import Loader from "../../../core/components/Loader/loader.component";
import {
  GET_ISSUER_DETAIL,
  GET_SEARCH_DOCUMENTS_ONLY_RESULTS,
  GET_SEARCH_SECURITIES_ONLY_RESULTS,
  GET_SEARCH_PROGRAMMES_ONLY_RESULTS,
} from "../../../graphql";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import IssuerBanner from "./banner/banner.component";
import IssuerOverview from "./overview/overview.component";
import IssuerSecurities from "./securities/securities.component";
import IssuerProgrammes from "./programmes/programmes.component";
import { canUseDOM } from "../../../predicates";
import { withRouter } from "react-router";
import IssuerDocuments from "./documents/index.component";
import { goToNotFoundPage, NotFound } from "../404";
import { IssuerMeta } from "./meta";
import {
  DocumentSearchResultDto,
  ProgrammeSearchResultDto,
  SecuritySearchResultDto,
} from "../../../interfaces/search/search";
import { SearchInputContent } from "../Search/input";
import { IssuerDetail } from "../../../interfaces/search/search-issuer";
import Utils from "../../utils/utils";

export enum IssuerCardTab {
  OVERVIEW = "OVERVIEW",
  SECURITIES = "SECURITIES",
  PROGRAMMES = "PROGRAMMES",
  NOTICES = "NOTICES",
  DOCUMENTS = "DOCUMENTS",
}

export interface IssuerCardProps {
  fields?: any;
  match?: any;
  id: string;
}

export const issuerDetailPageLink = (name: string, id: string) => {
  return `/issuer/${name}/${id}`;
};

export const goToIssuerDetailPage = (name: string, id: string) => {
  if (canUseDOM) window.location.href = issuerDetailPageLink(name, id);
};

export const IssuerCardContent: React.FunctionComponent<any> = ({
  id,
}: IssuerCardProps): React.ReactElement => {
  const [issuer, setIssuer] = useState<IssuerDetail>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<IssuerCardTab>(
    IssuerCardTab.OVERVIEW
  );
  const [textInput, setTextInput] = React.useState<string>("");

  const client = luxseGatewayGraphqlClient;

  const getIssuer = async () => {
    let issuer = null;
    try {
      if (!loading) setLoading(true);

      const results: any = await client.query({
        query: GET_ISSUER_DETAIL,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          idIssuer: id,
        },
      });

      issuer = results?.data?.tier || null;
    } catch (e) {
      console.error("Failed to get issuer '" + id + "'", e);
    }

    if (!issuer) goToNotFoundPage();
    else {
      setIssuer(issuer);
      setLoading(false);

      getProgrammesData();
      getSecuritiesData();
      getNoticesData();
    }
  };

  const [programmes, setProgrammes] = useState<ProgrammeSearchResultDto>(null);
  const [programmesLoading, setProgrammesLoading] = useState<boolean>(true);

  const getProgrammesData = async () => {
    let programmeTmp = null;
    try {
      setProgrammesLoading(true);

      const results: any = await client.query({
        query: GET_SEARCH_PROGRAMMES_ONLY_RESULTS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          issuerId: id,
          page: 0,
          size: 5,
          searchTerm: "",
          sort: "startDate,DESC",
          activeOnly: true,
        },
      });

      programmeTmp = results?.data?.luxseProgrammesSearch || null;
    } catch (e) {
      console.error("Failed to get programmes", e);
    }

    setProgrammesLoading(false);
    setProgrammes(programmeTmp);
  };

  const [securities, setSecurities] = useState<SecuritySearchResultDto>(null);
  const [securitiesLoading, setSecuritiesLoading] = useState<boolean>(true);

  const getSecuritiesData = async () => {
    let securitiesTmp = null;
    try {
      setSecuritiesLoading(true);

      const results: any = await client.query({
        query: GET_SEARCH_SECURITIES_ONLY_RESULTS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          issuerId: id,
          page: 0,
          size: 5,
          searchTerm: "",
          sort: "listingDate,DESC",
          securityStatuses: ["ADMI", "COTE", "NEGO"],
          securityStatusesOperator: "OR",
        },
      });

      securitiesTmp = results?.data?.luxseSecuritiesSearch || null;
    } catch (e) {
      console.error("Failed to get securities", e);
    }

    setSecuritiesLoading(false);
    setSecurities(securitiesTmp);
  };

  const [notices, setNotices] = useState<DocumentSearchResultDto>(null);
  const [noticesLoading, setNoticesLoading] = useState<boolean>(true);

  const getNoticesData = async () => {
    try {
      const results: any = await client.query({
        query: GET_SEARCH_DOCUMENTS_ONLY_RESULTS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          searchTerm: "",
          size: 5,
          page: 0,
          sort: `publishDate,desc`,
          issuerId: id,
          documentTypes: ["FNS"],
          excludeDocumentSubTypes: ["D318"],
        },
      });

      setNoticesLoading(false);

      if (results && results.data) {
        const data = results.data;
        setNotices(data?.luxseDocumentsSearch || null);
      }
    } catch (e) {
      console.error("Failed to get notices", e);
      setNoticesLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getIssuer();
  }, []);

  return (
    <>
      <SearchInputContent onSearchInputUpdate={textInput} dataType="Issuer" />
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <div css={loading ? style.pageLoading : {}}>
            <Loader loading={loading} maxHeight={"100px"}>
              {issuer ? (
                <>
                  <IssuerMeta issuer={issuer} />
                  <IssuerBanner green={issuer.climateAligned} issuer={issuer} />

                  <div css={style.tabsContainer}>
                    <Tabs tabPosition={"center"} padding={"1em 2em"}>
                      <Tab
                        label="Overview"
                        onTabChange={() =>
                          setSelectedTab(IssuerCardTab.OVERVIEW)
                        }
                        selected={selectedTab === IssuerCardTab.OVERVIEW}
                      >
                        <div css={style.tabContent}>
                          <IssuerOverview
                            onTabChange={setSelectedTab}
                            programmes={programmes}
                            programmesLoading={programmesLoading}
                            securities={securities}
                            securitiesLoading={securitiesLoading}
                            notices={notices}
                            noticesLoading={noticesLoading}
                          />
                        </div>
                      </Tab>

                      <Tab
                        label="Programmes"
                        onTabChange={() =>
                          setSelectedTab(IssuerCardTab.PROGRAMMES)
                        }
                        selected={selectedTab === IssuerCardTab.PROGRAMMES}
                      >
                        <div css={style.tabContentList}>
                          <IssuerProgrammes issuerId={issuer.id} />
                        </div>
                      </Tab>

                      <Tab
                        label="Securities"
                        onTabChange={() =>
                          setSelectedTab(IssuerCardTab.SECURITIES)
                        }
                        selected={selectedTab === IssuerCardTab.SECURITIES}
                      >
                        <div css={style.tabContentList}>
                          <IssuerSecurities issuerId={issuer.id} />
                        </div>
                      </Tab>

                      <Tab
                        label="Documents"
                        onTabChange={() =>
                          setSelectedTab(IssuerCardTab.DOCUMENTS)
                        }
                        selected={selectedTab === IssuerCardTab.DOCUMENTS}
                      >
                        <div css={style.tabContentList}>
                          <IssuerDocuments issuerData={issuer} />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </>
              ) : (
                <NotFound
                  title="404"
                  description={`Oups! Page not found`}
                  goBack={true}
                />
              )}
            </Loader>
          </div>
        </div>
      </div>
    </>
  );
};

export const IssuerCard = withRouter(IssuerCardContent);
