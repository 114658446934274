
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { Icon, Button } from '@bdl-cmn-shared-packages-npm/design-system';
import {
    childrenBloc,
    childrenBlocLeft,
    childrenBlocSubTitle,
    tagContainer,
    childrenBlocRight,
    childrenBlocDocument,
    childrenBlocDocumentLink,
    tag
} from "../../shared.style";
import {canUseDOM} from "../../../../../predicates";
import { breakpointMax } from '../../../../../style';
import { Link } from '@sitecore-jss/sitecore-jss-react';

export interface PressItemRowProps {
    data: any,
    key?: string,
    downloadLabel?: string,
    downloadLink?: string,
    readLabel?: string,
    index: number,
}

const style = {
    
childrenBlocTitle: css`
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
  color: #A4BCC2;
  margin-top: 3px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 8px;
  }
`,

childrenBlocTitleRight: css`
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  color: #000;
  margin-bottom: 24px;
  @media (max-width: ${breakpointMax}) {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
`,

letterBloc: css`
  padding-right: 180px;
//   padding-left: 32px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  @media (max-width: ${breakpointMax}) {
    padding: 0;
  }
`,

childrenBlocDescription: css`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: #425563;
  @media (max-width: ${breakpointMax}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
  }
`,
childrenBloc: css`
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  cursor: pointer;
  @media (max-width: ${breakpointMax}) {
    padding-top: 16px;
    flex-direction: column;
  }
`,
}


export const PressItemRow: React.FunctionComponent<PressItemRowProps> = (props:PressItemRowProps): React.ReactElement => {
    const { data = [], downloadLabel, readLabel, downloadLink } = props;
    const history = useHistory();
    const newLayout = data.newLayout;

    return (
        <div css={style.letterBloc}>
            <div css={style.childrenBloc} onClick={() => {
                                canUseDOM && newLayout === "1"
                                  ? window.open(`${window.location.pathname}/${data.Title.replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-').replace(/--/g, '-')}`, '_blank')
                                  : canUseDOM && window.open(`${window.location.pathname}/${data.ItemID}___${data.Title.replace(/[^a-zA-Z0-9]/g,'-').replace(/--/g,'-').replace(/--/g,'-')}`, '_blank')
                              }}>
                <div css={childrenBlocLeft}>
                    <div css={style.childrenBlocTitle}>
                        { data.Label }
                    </div>
                    {/*<div css={childrenBlocSubTitle}>
                        Speakers
                    </div>
                    <div css={tagContainer}>
                        {
                            data && data.Speakers && data.Speakers.map((data: any, index: number) => (
                                <div css={tag}  key={`tag-key-${index}`} >
                                    { data.label }
                                </div>
                            ))
                        }
                    </div>
                    <div css={childrenBlocSubTitle}>
                        Types
                    </div>
                    <div css={tagContainer}>
                        {
                            data && data.Types && data.Types.map((thematic: any, index: number) => (
                                <div css={tag}  key={`tag-thematics-${index}`} >
                                    { thematic.label }
                                </div>
                            ))
                        }
                    </div>*/}
                </div>
                <div css={childrenBlocRight} onClick={() => {
                                canUseDOM && newLayout === "1"
                                  ? window.open(`${window.location.pathname}/${data.Title.replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-').replace(/--/g, '-')}`, '_blank')
                                  : canUseDOM && window.open(`${window.location.pathname}/${data.ItemID}___${data.Title.replace(/[^a-zA-Z0-9]/g,'-').replace(/--/g,'-').replace(/--/g,'-')}`, '_blank')
                              }}>
                    <div css={style.childrenBlocTitleRight}>
                        { data.Title }
                    </div>
                    <div css={style.childrenBlocDescription}>
                        <div
                            dangerouslySetInnerHTML={{__html: data['Short description']}}
                        />
                    </div>
                    <div css={childrenBlocDocumentLink(true, true)}>
                        {
                            data['Document']?.value?.src && (
                                <Button
                                    label={downloadLabel}
                                    iconLeft="file-pdf"
                                    wired={true}
                                    onClick={() => canUseDOM && window.open(data['Document']?.value?.src, '_blank')}
                                />
                            )
                        }
                        <Button
                            label={readLabel}
                            iconRight="chevron-right"
                            variant={"thirdary"}
                            style={{
                                boxShadow: 'none', 
                                border: '1px solid rgba(34, 170, 95, 0.50)',
                                borderRadius: '4px', 
                            }}
                            onClick={() => {
                                canUseDOM && newLayout === "1"
                                  ? window.open(`${window.location.pathname}/${data.Title.replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-').replace(/--/g, '-')}`, '_blank')
                                  : canUseDOM && window.open(`${window.location.pathname}/${data.ItemID}___${data.Title.replace(/[^a-zA-Z0-9]/g,'-').replace(/--/g,'-').replace(/--/g,'-')}`, '_blank')
                              }}
                            />
                    </div>
                </div>
            </div>
        </div>
    );
}
