import * as React from "react";

import { Icon, InputField } from "@bdl-cmn-shared-packages-npm/design-system";
import { breakpoint, breakpointMax } from "../../../style";
import { css, jsx, keyframes } from "@emotion/react";
import {fetchData, fetchNewData, getLink, renderLink, transformLink} from "../../../utils/helper";

import { Collapse } from "react-collapse";
import { canUseDOM } from "../../../predicates";
import greenHexa from "./greenexa.svg";
import greyHexa from "./greyhexa.svg";
import { offeringMenu } from "../Header/header.component";
import redHexa from "./redhexa.svg";
import { type } from "os";
import { useState } from "react";
import whiteHexa from "./whiteexa.svg";
import { withRouter } from "react-router-dom";
import { Link } from "@sitecore-jss/sitecore-jss-react";

/** @jsx jsx */

export interface MobileMenuProps {
  visible?: boolean;
  closeMenu?: any;
}

const MobileMenu: React.FunctionComponent<any> = (props: MobileMenuProps) => {
  const { visible, closeMenu } = props;

  const mobileMenu = css`
    width: 85%;
    background: #e8ecee;
    height: 100%;
    max-height: 100%;
    position: fixed;
    z-index: 400;
    top: 0;
    bottom: 0;
    right: ${visible ? "0" : "-85%"};
    display: flex;
    overflow: auto;
    overflow-x: hidden;
    flex-direction: column;
    @media (min-width: ${breakpoint}) {
      display: none;
    }
  `;
  const header = css`
    width: 100%;
    background: #ffffff;
    height: 80px;
  `;
  const search = css`
    width: 100%;
    margin-top: 10%;
    margin-bottom: 5%;
    padding: 5%;
    > div > div > div > div:last-of-type {
      background: #1acb69;
      color: #ffffff;
      position: relative;
      margin-left: -6px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      width: 54px;
    }
    > div > div:first-of-type {
      background: #ffffff;
    }
  `;
  const container = css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `;

  const headerContainer = css`
    display: flex;
    width: 100%;
    padding: 16px;
    padding-left: 20px;
    align-items: center;
    justify-content: space-between;
    > svg {
      width: 16px !important;
      margin-right: 15px;
    }
  `;
  const childrenContainer = css`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
  `;
  const side = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75%;
  `;
  const closeBtn = css`
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    color: #22aa5f;
    border-radius: 50px;
    margin-bottom: 25px;
    margin-left: ${visible ? "-25px" : "0px"};
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    svg {
      width: 33px !important;
      height: 33px;
      transform: rotate(45deg);
    }
  `;
  const items = css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `;
  const itemStyle = css`
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #354450;
    padding: 16px 16px;
  `;

  const cardContainer = css`
    width: 100%;
    background: #ffffff;
    padding: 8px;
    padding-left: 30px;
  `;
  const row = css`
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #354450;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
    svg {
      color: #22aa5f;
      width: 16px !important;
      height: 16px;
    }
  `;
  const subRow = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #354450;
    background: #f4f6f7;

    text-decoration: none;
    a {
      text-decoration: none;
    }
  `;
  const childrenStyle = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
  `;

  const ParentRow: any = (props: {
    children: any;
    title: any;
    icon?: boolean;
    link?: any;
  }) => {
    const { title, icon, link, children } = props;
    const [expand, setExpand] = useState<boolean>(false);

    const toggle = () => {
      setExpand(!expand);
    };
    const action = () => {
      if (transformLink(link)?.value.href) {
        window.open(transformLink(link)?.value.href, '_self');
      } else {
        toggle();
      }
    };

    return (
      <div css={container}>
        <div css={row} onClick={action}>
          <span>{title}</span>
          {icon && (
            <div>
              <Icon icon={expand ? "chevron-up" : "chevron-down"} />
            </div>
          )}
        </div>
        <Collapse isOpened={expand}>
          <div css={childrenStyle}>{children}</div>
        </Collapse>
      </div>
    );
  };

  const hexa = {
    green: greenHexa,
    gray: greyHexa,
    red: redHexa,
    white: whiteHexa,
  };

  const Row: any = (props: {
    title: any;
    icon?: string;
    red?: boolean;
    children?: any;
    link?: any;
    background?: string;
    color?: string;
    subMenu?: boolean;
  }) => {
    const { title, icon, link, color, children, background, subMenu } = props;
    const [expand, setExpand] = useState<boolean>(false);

    const iconStyle = css`
      background-image: url(${hexa[background as "green"]});
      background-repeat: no-repeat;
      background-size: cover;
      height: 53px;
      width: 48px;
      color: ${color};
      display: flex;
      justify-content: center;
      font-size: 18px;
      align-items: center;
      margin-right: 10px;
    `;

    return (
      <Link css={subRow} title={title} field={transformLink(link)} target={'_self'}>
        <div css={headerContainer}>
          <div css={side}>
            {!link?.value?.href && icon && (
              <div css={iconStyle}>
                <Icon icon={icon} />
              </div>
            )}
            {title}
          </div>
          {subMenu && (
            <div
              style={{
                width: "10%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Icon icon={expand ? "chevron-up" : "chevron-down"} />
            </div>
          )}
        </div>
        <Collapse isOpened={expand}>{children}</Collapse>
      </Link>
    );
  };

  const [searchText, setSearchText] = useState<string>("");

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const [menuData, setMenuData] = useState<any>();
  const getDataFromItemId = async (id: string) => {
    try {
      const data = await fetchNewData(id);
      const { root } = data;
      setMenuData(root);
    } catch (err) {
      console.error(err);
    }
  };

  const [activeSection, setActiveSection] = useState("*");

  React.useEffect(() => {
    getDataFromItemId("951D2AF5-4E80-4C86-A12F-ECFDD70503F2");
  }, []);

  React.useEffect(() => {
    setActiveSection(canUseDOM && window.location.pathname);
    return () => void 0;
  }, []);

  //const isActive = (links: string[]) => links.findIndex((link) => activeSection === link) !== -1;

  return (
    <div css={mobileMenu}>
      <div
        css={closeBtn}
        onClick={() => {
          closeMenu();
        }}
      >
        <Icon icon={"plus"} />
      </div>
      <div css={search}>
        <InputField
          helperText=""
          placeholder="Search by ISIN, issuer, ..."
          textError=""
          type="text"
          width="100%"
          onChange={inputChangeHandler}
          onKeyPress={(ev: any) => {
            if (ev.key === "Enter" && searchText.length > 0) {
              window.location.href = `/search?q=${searchText}`;
            }
          }}
          onClickTrailingIcon={() =>
            (window.location.href = `/search?q=${searchText}`)
          }
          trailingIcon={"search"}
        />
      </div>

      {menuData && menuData.items && menuData.items.length > 0 &&
          menuData.items.map((menu: any, index: number) => {
            if (menu && menu.rows && menu.rows.length > 0) {
              return (
                  <React.Fragment key={`index-menu-2-${index}`}>
                    <ParentRow title={menu.mainLabel} icon>
                      {menu.rows.map((field: any, subIndex: number) => (
                          <div style={{ background: "aliceblue", width: '100%' }} key={`sub-index-menu-${index}-${subIndex}`}>
                            <ParentRow title={field.mainLabel} icon>
                              {field.links &&
                                  (field.links || []).map((it: any, thirdIndex: number) => (
                                      <React.Fragment key={`index-menu-child-${index}-${subIndex}-${thirdIndex}`}>
                                        <Row title={it.label} red link={it.pageLink} />
                                      </React.Fragment>
                                  ))}
                            </ParentRow>
                          </div>
                      ))}
                    </ParentRow>
                  </React.Fragment>

              )
            } else if (menu && menu.links && menu.links.length > 0) {
              return (
                  <React.Fragment key={`index-menu-3-${index}`}>
                    <ParentRow title={menu.mainLabel} icon>
                      {menu.links.map((it: any, subIndex: number) => (
                          <React.Fragment key={`index-menu-child-2-${index}-${subIndex}`}>
                            <Row title={it.label} red link={it.pageLink} />
                          </React.Fragment>
                      ))}
                    </ParentRow>
                  </React.Fragment>
              )
            } else {
              return (
                  <React.Fragment key={`index-menu-4-${index}`}>
                    <ParentRow
                        link={menu.directPageLink}
                        title={menu.mainLabel}
                    />
                  </React.Fragment>
              )
            }
        })}
    </div>
  );
};

export default withRouter(MobileMenu);
