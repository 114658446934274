import { breakpoint, breakpointMax } from '../../../style';

import { css } from '@emotion/react';

export const styleOfficialList = {
  parentContainer: css`
    background: #f4f6f7;
  `,
  container: css`
    width: 100%;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${breakpointMax}) {
      padding-left: 16px;
      padding-right: 16px;
    }
  `,
  button_1: css`
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    color: black !important;
    gap: 16px !important;
    max-width: 370px;
    width: 100%;
    height: 68px !important;
    background: #ffffff !important;
    box-shadow: 0px 5px 15px rgba(66, 85, 99, 0.3) !important;
    border-radius: 8px !important;
  `,
  containerButton: css`
    width: 100%;
    background: #f4f6f7;
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    padding-bottom: 64px;
    h1 {
      margin-bottom: 16px;
    }
  `,
  button: css`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    gap: 16px;
    button {
      padding-right: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    span {
      border: solid 2px red;
      padding: 5px;
      border-radius: 33px;
      width: 35px;
      height: 35px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-right: 0px;
    }
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,

  containerTab: css`
    > div:first-of-type > div:first-of-type {
      display: flex;
      align-items: center;
      padding-top: 16px;

      > div {
        padding-bottom: 16px;
      }
    }
    > div:last-of-type > div:last-of-type {
      //  padding-top: 16px;
    }

    background: #ffffff;
    box-shadow: 0px 16px 24px rgba(164, 188, 194, 0.2);
    border-radius: 8px;
    width: 100%;
  `,
  titleTab1: css`
    width: 729px;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 61px;
    color: #253845;
    margin-top: 25px;
    margin-bottom: 50px;
    margin-left: 16px;

    @media (max-width: ${breakpointMax}) {
      width: 100%;
      font-size: 32px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  `,
  tableContainer: css`
    display: flex;
    flex-direction: column;

    > div:last-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: ${breakpointMax}) {
        > ul {
          padding-left: 12px;
          padding-right: 12px;
          flex-wrap: wrap;
        }
      }
    }
    table {
      table-layout: fixed;
      width: 100%;
      border-collapse: collapse;
    }
    thead th:nth-child(1) {
      width: 10%;
    }
    thead th:nth-child(2) {
      width: 45%;
    }
    thead th:nth-child(3) {
      width: 45%;
    }
    tr td {
      div {
        font-size: 18px !important;
        color: rgb(66, 85, 99);
        border: solid 2px red;
        padding: 5px;
        border-radius: 33px;
        width: 35px;
        height: 35px;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-right: 0px;
      }
    }
  `,
  tableContent: css`
    width: 100%;
  `,
};
