/** @jsx jsx */
import { jsx } from "@emotion/react";

import { SearchTable } from "../base";
import IndexRow from "./row";
import SearchFilterParams from "../../../../../interfaces/search/search-filter-params";
import { IndexFilters } from "./index-filters";
import { IndexSearchResultDto } from "../../../../../interfaces/search/search";
import { containerCss, resultCss } from "../index.style";
import IndexHeader from "./header";
import { InputSort } from "../base/sort";
import { Loader } from "../../Loader";
import SearchResults from "../../../../../interfaces/search/search-result";
import IndexEmptyResults from "./empty";

export interface IndexesListViewProps {
  selectedCols: any;
  history?: any;
  data: IndexSearchResultDto;
  searchResults?: SearchResults;
  target?: string;
  onPaginationUpdate: (pageNumber: number) => void;
  currentPageNumber: number;
  density?: "low" | "medium" | "high";
  itemsNumber?: number;
  totalResultSize?: number;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  sort: any;
  sortData: any;
  onSortChange: (sort: any) => void;
  loadingPage?: boolean
}

export const IndexesListView = ({ 
  data,
  searchResults,
  loadingPage,
  selectedCols,
  density,
  onPaginationUpdate,
  currentPageNumber,
  totalResultSize,
  filters,
  onFiltersChange,
  sort,
  sortData,
  onSortChange,
}: IndexesListViewProps) => {

  return (
    <div css={containerCss}>
      <IndexFilters
        filterCount={data && data.filters}
        filters={filters}
        onFiltersChange={onFiltersChange}
      />
      <div css={resultCss}>
        {
          loadingPage && (
            <Loader />
          )
        }
        <InputSort 
          sort={sort}
          baseValues={sortData? sortData['indices'] : {}}
          onSortChange={onSortChange}
        />
        <SearchTable
          data={data?.indexes}
          density={density}
          onPaginationUpdate={onPaginationUpdate}
          pageNumber={currentPageNumber}
          totalResultSize={totalResultSize}
          Header={() => <IndexHeader selectedCols={selectedCols} />}
          Row={(data) => <IndexRow row={data} selectedCols={selectedCols} key={`search-indice-r-${data?.id}`}/>}
          Empty={() => <div css={{marginTop: "70px"}}><IndexEmptyResults searchResults={searchResults}/></div>}
        />
      </div>
    </div>
  );
};
