import React, { useEffect, useState, Fragment } from "react";
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Button } from "@bdl-cmn-shared-packages-npm/design-system";
import { style } from "./pdf-viewer.styles";
import { DOWNLOAD_ENDPOINT } from "../../../backbourse";
import { formatDate } from "../../../utils/date";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import { GET_DOCUMENT_BY_ID } from "../../../graphql";
import Loader from "../Loader/loader.component";
import { documentTypeLabel } from "../../../enums/document-type";
import { goToNotFoundPage, NotFound } from "../404";
import Utils from "../../utils/utils";
import { PREVIEW_MODE } from "../../constants";
import { PdfViewerMeta } from "./meta";
import { canUseDOM } from "../../../predicates";
import { Document } from "../../../interfaces";
import EmptyResults from "../UI/list/base/empty";

export interface PdfViewerProps {
  fields?: any;
  match?: any;
  id: string;
}

export class DocumentInfoModel {
  id?: string;
  name?: string;
  url?: string;
  loader?: boolean = true;
  date?: string;
  types?: string[];
  format?: string;
}

export const PdfViewer: React.FunctionComponent<any> = ({
  id,
}: PdfViewerProps): React.ReactElement => {
  const [documentInfo, setDocumentInfo] = React.useState<DocumentInfoModel>(
    new DocumentInfoModel()
  );
  const client = luxseGatewayGraphqlClient;

  const fetchDocumentInfo = async () => {
    const documentInfoModel: DocumentInfoModel = new DocumentInfoModel();
    const results: any = await client.query({
      query: GET_DOCUMENT_BY_ID,
      variables: { id: id, token: Utils.getCookie("Gztoken-Dsm") },
    });

    if (
      !results?.data?.document ||
      results?.data?.document?.deleted ||
      results?.data?.document?.obsolete
    )
      goToNotFoundPage();

    const data: Document = results.data.document;
    documentInfoModel.id = data.id;
    documentInfoModel.name = data?.complement;
    documentInfoModel.url = data?.downloadUrl;
    documentInfoModel.date = data?.publishDate;
    documentInfoModel.types = data?.categories;
    documentInfoModel.loader = false;
    documentInfoModel.format = data?.format;
    setDocumentInfo(documentInfoModel);
  };

  useEffect(() => {
    fetchDocumentInfo().catch(function (error) {
      Utils.logError(error);
      goToNotFoundPage();
    });
  }, []);

  return (
    <>
      <Loader loading={documentInfo?.loader}>
        <PdfViewerMeta document={documentInfo} />
        <div css={style.bannerContainer}>
          <div css={style.bannerInfo}>
            {documentInfo?.types?.map((type: string) => (
              <>
                {documentTypeLabel(type) && (
                  <p css={style.type}>{documentTypeLabel(type)}</p>
                )}
              </>
            ))}

            <p css={style.text1}>{documentInfo?.name}</p>
            <p css={style.pipe}></p>
            <Button
              onClick={() => {
                if (canUseDOM)
                  window.location.href = DOWNLOAD_ENDPOINT + documentInfo?.url;
              }}
              wired={false}
              iconLeft="download"
              label={`Download`}
            />
          </div>
          <div css={style.bannerDate}>
            <p css={style.text2}>
              Published : {formatDate(documentInfo?.date, "DD/MM/YY")}
            </p>
          </div>
        </div>
        <div css={style.iframeContainer}>
          {!documentInfo.format ||
          "PDF" == documentInfo.format?.toUpperCase() ? (
            <iframe
              src={DOWNLOAD_ENDPOINT + documentInfo?.url + PREVIEW_MODE}
              allowFullScreen={true}
              width="100%"
              height="980px;"
            />
          ) : (
            <div css={{ margin: "auto" }}>
              <EmptyResults label="No preview available for this document" />
            </div>
          )}
        </div>
      </Loader>
    </>
  );
};

export default PdfViewer;
