/** @jsx jsx */
/* eslint-disable */
import { Grid, Pagination, Table, Tbody, Th, Thead, ToggleButtonGroup, ToggleItem, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import React, { Component, useEffect, useState } from "react";
import { style } from "../market-statistics.style";
import Loader from "../../Loader/loader.component";
import GenericMarketCapDataRow from "../Base/GenericMarketCapDataRow";
import { SearchMobile } from "../../UI/list/base/index-mobile";
import MarketCapCard from "../Cards/MarketCapCard";
import EmptyResults from "../../UI/list/base/empty";
import { MarketCapPaginationModel } from "../market-capitalisation-view.component";
import { css, jsx } from "@emotion/react";
import SortIcon from "../Base/SortIcon";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { GET_RISERS_FALLERS_STATISTICS } from "../../../../graphql/queries/market.queries";
import GenericRisersAndFallersDataRow from "../Base/GenericRisersAndFallersDataRow";
import RisersFallersCard from "../Cards/RisersFallersCard";

export interface RisersAndFallersDataModel {
    id: string;
    isinCode: string;
    type: string;
    marketVal:string;
    description:string;
    lastPrice:number;
    yearPrice:number;
    monthPrice:number;
    ytdVariation:number;
    mtdVariation:number;
}

export class RisersAndFallersPaginationModel {
    allData?: RisersAndFallersDataModel[];
    displayData?: RisersAndFallersDataModel[];
    totalHits?: number;
}

export function RisersFallersTable(props: any) {

    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const limit = 10;

    const type=props.type;


    const [risersAndFallers, setRisersAndFallers] = useState<RisersAndFallersPaginationModel>({
        allData: [],
        displayData: [],
        totalHits: 0
    });

    const columns = [
        { label: "Type", accessor: "type," },
        { label: "ISIN", accessor: "isinCode," },
        { label: "Name", accessor: "description," },
        { label: "Last price", accessor: "lastPrice," },
        { label: type==="Month"?"Last price M-1":"Last price Y-1", accessor: type==="Month"?"monthPrice,":"yearPrice,"},
        { label:  type==="Month"?"MTD variation":"YTD variation", accessor: type==="Month"?"mtdVariation,":"ytdVariation," },
    ];
    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("asc");

    const client = luxseGatewayGraphqlClient;

    const handleSortingChange = (accessor:any) => {
        const sortOrder =
        accessor === sortField && order === "asc" ? "desc" : "asc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };

    const handleSorting = (sortField:any, sortOrder:any) => {
        if(sortField){
            const sortName=sortField.replace(',','');
            if (sortName === "lastPrice" || sortName === "mtdVariation" || sortName === "ytdVariation" || sortName === "monthPrice" || sortName === "yearPrice") {
                risersAndFallers.allData.sort((a:any, b:any) => {
                 return (
                  (Number(a[sortName])-Number(b[sortName])) * (sortOrder === "asc" ? 1 : -1)
                 );
                });
            }else{
                risersAndFallers.allData.sort((a:any, b:any) => {
                    return (
                     a[sortName].toString().localeCompare(b[sortName].toString(), "en", {
                      numeric: true,
                     }) * (sortOrder === "asc" ? 1 : -1)
                    );
                   });
            }
            risersAndFallers.displayData = createPage(0);
            setRisersAndFallers({...risersAndFallers});
        }
    };


    
    const fetchRisersFallersData = async () => {
        try {
          if( !dataLoading)
            setDataLoading(true);
            risersAndFallers.allData = [];
            risersAndFallers.displayData = [];
          const results: any = await client.query({
            query: GET_RISERS_FALLERS_STATISTICS,
          });


    
          results?.data?.risersAndFallers?.forEach((element: any) => {
            risersAndFallers.allData.push(element);
            });

            if(type=="Month"){
                risersAndFallers.allData.sort((a, b) => b.mtdVariation - a.mtdVariation);
    
              }else{
                risersAndFallers.allData.sort((a, b) => b.ytdVariation - a.ytdVariation);
              }

            risersAndFallers.totalHits = risersAndFallers.allData.length;
            risersAndFallers.displayData = createPage(0);
            setRisersAndFallers(risersAndFallers);

            setDataLoading(false);
        } catch (e) {
            console.error('Failed to get risers and fallers data', e);
            setDataLoading(false);
        }
      }
  
    useEffect(() => {
        fetchRisersFallersData();
    }, []);

    const onPaginationUpdate = (pageNumber: number) => {
        risersAndFallers.displayData = createPage(pageNumber);
        setRisersAndFallers({...risersAndFallers});
    };

    function createPage(currentPage: number): any {
        let startIndex = 0;
        let endIndex = 10;

        if (currentPage > 0) {
            endIndex = (currentPage + 1) * 10;
            startIndex = endIndex - 10;
        }

        return risersAndFallers.allData.slice(startIndex, endIndex);
    }


    return (
        <>
            <div css={style.container}>
                <Loader loading={dataLoading}>
                    {risersAndFallers && risersAndFallers?.totalHits > 0 ?
                        <>
                            <div css={[style.desktopDisplay]}>
                                <table css={style.table}>
                                        <thead css={style.thead}>
                                            <tr css={style.tr}>
                                            { columns.map(({ label, accessor }) => {
                                                return accessor.replace(',','')==="type"?
                                                <th key={accessor}>
                                                {label}
                                            </th>
                                                : <th key={accessor} onClick={() => handleSortingChange(accessor)}>
                                                    {label}
                                                    <SortIcon/>
                                                </th>;
                                            })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <GenericRisersAndFallersDataRow rowsData={risersAndFallers.displayData} columns={columns}/>
                                        </tbody>
                                </table>
                            </div>
                            <div css={style.mobileDisplay}>
                                <SearchMobile
                                    onPaginationUpdate={(d: any) => {
                                        onPaginationUpdate(d.selected);
                                    }}
                                    pageNumber={0}
                                    data={risersAndFallers?.displayData}
                                    totalResultSize={10}
                                    Card={(card) => <RisersFallersCard card={card} type={type} />}
                                />
                            </div>
                            {risersAndFallers?.totalHits < limit ? <></>:
                                <div css={style.pagination}>
                                <Pagination
                                    initialPage={0}
                                    onPageChange={(d: any) => {
                                        onPaginationUpdate(d.selected);
                                    }}
                                    total={risersAndFallers?.totalHits / limit > 200 ? 200 : Math.ceil(risersAndFallers?.totalHits / limit)}
                                />
                                </div>
                            }
                            
                        </>
                        :
                        <div>
                            <EmptyResults />
                        </div>
                    }
                </Loader>
            </div>
        </>
    );
}

export default RisersFallersTable;