import * as React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Button, InputField } from '@bdl-cmn-shared-packages-npm/design-system';
import moment from "moment-timezone";
import { style } from '../SecurityOverview/securityOverview.style';
import { style as yieldStyle } from './yield-calculator.style';
import { Security } from "../../../../interfaces";
import { PROCESS_YIELD_CALCULATION, setSecurityPeriodValue } from "../../../../graphql";
import { Mutation } from "react-apollo";
import { YieldCalculatorResponseData, YieldCalculatorDataInput } from '../../../../interfaces';
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import { InputDatePicker } from "../../input-date-picker";
import YieldCalculatorResults from "./results";
import Loader from "../../Loader/loader.component";
import { MarketDataGlobalInformation } from "../SecurityMarket/market-data-information/global-information";
import { MarketDataSpreadInformation } from "../SecurityMarket/market-data-information/spread-information";
import { MarketDataGeneralInformation } from "../SecurityMarket/market-data-information/general-information";
import { MarketDataYieldInformation } from "../SecurityMarket/market-data-information/yield-information";
import { getCurrentMoment, isValidDate } from "../../../../utils/date";

function optionalParse(value: string): number | null {
    const parsed = parseInt(value);
    return isNaN(parsed) ? null : parsed;
}

export interface YieldCalculatorProps {
    securityData?: Security;
}

const YieldCalculator = ({
    securityData
}: YieldCalculatorProps) => {
    const [buyPrice, setBuyPrice] = React.useState<number | null>(securityData?.marketData?.lastPrice?.amount || null);
    const [quantity, setQuantity] = React.useState<number | null>(null);
    const [buyDate, setBuyDate] = React.useState<moment.Moment | null>(() => getCurrentMoment());
    const [rate, setRate] = React.useState<number | null>(null);
    const [validFields, setValidFields] = React.useState({
        buyPrice: true,
        quantity: true,
        buyDate: true,
    });
    
    const [isCalculate, setCalculate] = React.useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(false);

    const isValidForm = React.useCallback(() => {
        const validFields = {
            buyPrice: !!buyPrice && buyPrice > 0,
            quantity: !!quantity && quantity > 0,
            buyDate: !!buyDate && isValidDate(buyDate),
        };

        setValidFields(validFields);

        return Object.values(validFields).every(v => !!v);
    }, [buyPrice, quantity, buyDate]);

    return (
        <Mutation<YieldCalculatorResponseData, YieldCalculatorDataInput>
            client={luxseGatewayGraphqlClient}
            mutation={PROCESS_YIELD_CALCULATION}
            onCompleted={(complete) => {
                setLoading(false);
                setCalculate(true);
            }}
            onError={(error) => {
                console.error("Failed to calculate yield", error);
                setLoading(false);
                setCalculate(false);
            }}
            >
            {(compute, { data }) => {

                const onSubmit = (e: any) => {
                    e.preventDefault();

                    if (isValidForm()) {
                        setLoading(true);

                        compute({
                            variables: {
                                yieldsData: {
                                    buyDate: buyDate.toString(),
                                    buyPrice,
                                    quantity,
                                    //rate,
                                    currencyToPercentConversion: 1,
                                    frequency: Math.min(setSecurityPeriodValue(securityData.periodicityCode), 1),
                                    dayCountConvention: securityData.dayConventionCode, // FIXME GJO Check string here ? 
                                    interestRate: securityData.interestRate,
                                    sellPrice: securityData.redemptionPrice || -1,
                                    sellDate: securityData.maturityDate || '',
                                    zeroCoupon: false,
                                }
                            }
                        });
                    }
                };

                return (
                    <div css={style.securityOverview}>
                        <div css={style.securityOverviewTop}>
                            <div css={style.securityOverviewTopLeftStart}>
                                <div css={style.flexRow}> 
                                    <MarketDataGlobalInformation  securityData={securityData}/>
                                    <MarketDataSpreadInformation  securityData={securityData}/>
                                    <div css={style.paddingTop}>
                                        <MarketDataYieldInformation securityData={securityData}/>
                                    </div>
                                    <div css={style.paddingTop}>
                                        <MarketDataGeneralInformation securityData={securityData}/>
                                    </div>
                                </div>
                            </div>
                            <div css={[style.securityOverviewTopRightStart, {backgroundColor: "#354450"}]}>
                                <div css={yieldStyle.yieldCalculatorWrapper}>
                                    <div css={yieldStyle.yieldCalculatorHeader}>
                                        <div css={yieldStyle.yieldCalculatorHeaderTitle}>
                                            Calculate bond's yield to Maturity
                                        </div>
                                        <form css={yieldStyle.yieldCalculatorForm} onSubmit={onSubmit}>
                                            <div css={[yieldStyle.yieldCalculatorInput, !validFields.buyPrice && yieldStyle.errored]}>
                                                <InputField value={buyPrice === null ? "" : buyPrice.toString()} onChange={(e: any) => setBuyPrice(Number.parseFloat(e.target.value) || null)} placeholder="Buying price" />
                                            </div>
                                            {/*<div css={[yieldStyle.yieldCalculatorInput]}>
                                                <InputField value={rate === null ? "" : rate.toString()} onChange={(e: any) => setRate(Number.parseFloat(e.target.value) || null)} placeholder="EUR/TRY rate (optional)" />
                                            </div>*/}
                                            <div css={[yieldStyle.yieldCalculatorInput, !validFields.quantity && yieldStyle.errored]}>
                                                <InputField value={quantity === null ? "" : quantity.toString()} onChange={(e: any) => setQuantity(Number.parseInt(e.target.value) || null)} placeholder="Buying quantity" />
                                            </div>
                                            <div css={[yieldStyle.yieldCalculatorInput, !validFields.buyDate && yieldStyle.errored]}>
                                                <InputDatePicker selection={buyDate} onSelect={setBuyDate} placeholder="Buying date" />
                                            </div>
                                            <Button type="submit" label="Calculate" />
                                        </form>
                                    </div>
                                    <Loader loading={isLoading}>
                                        {
                                            isCalculate ?
                                                <YieldCalculatorResults securityData={securityData} yieldsCalculation={data?.yieldsCalculation} />
                                                : <></>
                                        }
                                    </Loader>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Mutation>
    );
};

export default YieldCalculator;