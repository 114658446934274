/** @jsx jsx */
import {css, jsx} from "@emotion/react";
import * as React from 'react';
import TopTradedSecuritiesView from "../../core/components/MarketStatistics/top-traded-securities-view.component";


export const MarketStatisticsTopTradedSecurities: React.FunctionComponent = (): React.ReactElement => {

    return (
        <TopTradedSecuritiesView/>
    );
}
    