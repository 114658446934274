/** @jsx jsx */

import * as React from "react";

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Text,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";

import Loader from "../../core/components/Loader/loader.component";
import { Query } from "react-apollo";
import { SEARCH_INDEXES_PRICE_WITHOUT_FILTERS } from "../../graphql";
import { SitecoreGenericTextField } from "../../interfaces";
import bg from "./bg.png";
import { formatDate } from "../../utils/date";
import { luxseGatewayGraphqlClient } from "../../luxse-gateway-graphql-client";
import { renderLink } from "../../utils/helper";
import { useHistory } from "react-router";
import { goToIndexDetailPage } from "../../core/components/Index/Index-details";
import Utils from "../../core/utils/utils";

export interface GreenIndicesComponentProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const parentContainer = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
const cards = css`
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
  padding-bottom: 96px;
  margin-left: auto;
  margin-right: auto;
`;
const card = css`
  width: 100%;
  margin: 8px;
  max-width: 280px;
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 16px 24px rgba(37, 54, 69, 0.15);
  border: solid 1px #f0f5f5;
  height: 100%;

  :hover {
    background: #f0f5f5;
  }
`;
const cardDataTop = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #cb1234;
`;
const cardDataBottom = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #cb1234;
`;
const reqContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const GreenIndicesComponent: React.FunctionComponent<GreenIndicesComponentProps> =
  (props: GreenIndicesComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const history = useHistory();

    const background: any = getFieldValue<any>(fields, "background", null);
    const isChinese: number = getFieldValue<number>(fields, "is Chinese", 0);

    const container = css`
      width: 100%;
      background-color: ${isChinese ? "#F6F8F9" : "transparent"};
      background-image: url(${renderLink(bg)});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      margin-left: auto;
      margin-right: auto;
      padding-top: 96px;
    `;
    const title = css`
      font-weight: 800;
      font-size: 48px;
      line-height: 61px;
      text-align: center;
      color: ${isChinese ? "#253645" : "#253645"};
      padding-bottom: 24px;
      @media (max-width: ${breakpointMax}) {
        font-weight: 700;
        font-size: 32px;
        line-height: 31px;
      }
    `;
    const cardTitle = css`
      font-weight: 800;
      font-size: 20px;
      line-height: 31px;
      color: ${isChinese ? "#253645" : "#253645"};
    `;
    const description = css`
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      color: ${isChinese ? "#253645" : "#253645"};
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: ${breakpointMax}) {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
      }
    `;
    return (
      <div css={parentContainer}>
        <div css={container}>
          <div css={title}>
            <Text
              field={fields && (fields.title as SitecoreGenericTextField)}
            />
          </div>
          <div css={description}>
            <Text
              field={fields && (fields.description as SitecoreGenericTextField)}
            />
          </div>
          <div css={cards}>
            <Query<any>
              client={luxseGatewayGraphqlClient}
              query={SEARCH_INDEXES_PRICE_WITHOUT_FILTERS}
              variables={{
                token: Utils.getCookie("Gztoken-Dsm"),
                searchTerm: "Lux RI Fund Index",
                types: ["IOPC"],
                sort: "",
                size: 1,
                page: 0,
              }}
            >
              {({ loading, error, data }): React.ReactNode => {
                return (
                  <Loader loading={loading} horizontal>
                    {data?.luxseIndexesSearch?.indexes?.map(
                      (d: any, index: number) => (
                        <div
                          style={{ cursor: "pointer" }}
                          css={card}
                          key={`index-${index}`}
                          onClick={() => goToIndexDetailPage(d?.id)}
                        >
                          <div css={cardTitle}>{d?.indexName}</div>
                          <div css={cardTitle}>
                            {formatDate(
                              d?.indexPrice?.lastPrice?.date,
                              "DD/MM/YYYY"
                            )}
                          </div>
                          <div
                            css={cardDataTop}
                            style={{
                              color:
                                d?.indexPrice?.lastPrice?.changePercent < 0
                                  ? "#CB1234"
                                  : "#22AA5F",
                            }}
                          >
                            {d?.indexPrice?.lastPrice?.changePercent >= 0 &&
                              "+"}{" "}
                            {d?.indexPrice?.lastPrice?.changePercent} %
                          </div>
                          <div css={cardDataBottom}>
                            {data?.indexPrice?.lastPrice?.amount}
                          </div>
                        </div>
                      )
                    )}
                  </Loader>
                );
              }}
            </Query>
            {/*
                        new Array(3).fill(0).map((arr: any, index: number) => {
                            if (fields && fields[`cardTitle${index + 1}`] && (fields && fields[`cardTitle${index + 1}`] as any).value) {
                                return (
                                    <div css={card}>
                                        <div css={cardTitle}>
                                            <Text
                                                field={fields[`cardTitle${index + 1}`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                        <div css={cardDataTop}>
                                            <Text
                                                field={fields[`cardDataTop${index + 1}`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                        <div css={cardDataBottom}>
                                            <Text
                                                field={fields[`cardDataBottom${index + 1}`] as SitecoreGenericTextField}
                                            />
                                        </div>
                                    </div>
                                )
                            }})*/}
          </div>
        </div>
      </div>
    );
  };
