
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import RTS27RegulationFilesView
    from "../../core/components/RTS27RegulationFilesList/rts27-regulation-files-view.component";


export const RTS27RegulationFilesList: React.FunctionComponent = (): React.ReactElement => {
    return (
        <RTS27RegulationFilesView/>
    );
}
    