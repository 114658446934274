import { breakpointMax } from "../../../../style";
import { css } from "@emotion/react";

export const cardStyle = {
  cardMinHeight: css`
    min-height: 200px;
  `,
  cardMaxHeight: css`
    max-height: 200px;
  `,
  cardBorder: css`
    border-top: 1px solid #d9dee2;
  `,
  link: css`
    text-decoration: none;
    width: 100%;
    height: 100%;

    &:hover {
      background-color: #f4f6f7;
    }
  `,
  linkContainer: css`
    text-decoration: none;
    width: 100%;
    min-height: 200px;
    height: 100%;

    &:hover {
      background-color: #f4f6f7;
    }
  `,
  loaderSummary: css`
    background: #ffffff;
    border: 1px solid #d9dee2;
    box-shadow: 0px 16px 24px rgba(37, 54, 69, 0.15);
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: ${breakpointMax}) {
      min-height: 191px;
      height: unset;
    }

    > a {
      background-color: #ffffff;
      border-radius: 8px;
      height: 100%;
    }

    > div {
      background-color: #ffffff;
      border-radius: 8px;
      height: 100%;
    }
  `,

  multipleContainer: css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  multipleSummary: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    padding: 32px 32px;
    min-height: 160px;
  `,
  multipleButton: css`
    padding: 10px 32px;
    color: #22aa5f;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background-color: #f4f6f7;
    }

    min-height: 40px;
    border-top: 1px solid #d9dee2;
  `,
  multipleView: css`
    width: 100%;
  `,
  summary: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    height: 100%;
    min-height: 200px;
    padding: 32px 32px;
  `,
  summaryFixHeight: css`
    height: 200px;
  `,
  fullContainer: css`
    gap: 0.6em;
  `,
  container: css`
    display: flex;
    gap: 0.3em;
    flex-direction: column;
  `,
  greenLeaf: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #b8f0d4;
    background: #e8faf0;
    border-radius: 8px;
    padding: 4px 8px;
    color: #354450;
    font-size: 12px;

    svg {
      height: 13px;
      width: 15px;
      color: #008c46;
    }
  `,
  title: css`
    h3 {
      font-size: 1em;
      font-weight: lighter;
      color: #354450;
      margin: 0;
    }
  `,
  name: css`
    color: #253645;
    font-weight: bold;
    text-decoration: none;
    padding: 2px 16px;

    h3 {
      font-size: 24px;
      color: #354450;
      margin: 0;
    }
  `,
  cai: css`
    font-size: 0.9rem;
    color: #22aa5f;
    text-decoration: none;
    padding: 2px 12px;
  `,
  info: css`
    display: flex;
    color: #354450;
    align-items: flex-start;
    gap: 0.3em;
    font-weight: 600;
    flex-direction: row;
    padding: 2px 16px;
  `,
  fullInfo: css`
    flex-direction: column;
  `,
  roundedTag: css`
    > div {
      height: 39px;
      width: 39px;
      font-size: 1rem;
      color: #354450;
    }
  `,
  tags: css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: self-start;
    @media (max-width: ${breakpointMax}) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  `,
  tag: css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #e3e6e8;
    border-radius: 999em;
    padding: 8px 16px;
    font-weight: bold;
    font-size: 13px;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 35px;
  `,
  infoTag: css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    font-weight: bold;
    font-size: 13px;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 35px;
  `,
  country: css`
    display: flex;
    margin-right: 10px;

    svg {
      margin-right: 5px;
    }
  `,
};
