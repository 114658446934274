/** @jsx jsx */

import * as React from 'react';
import { css, jsx } from "@emotion/react";
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue, Link } from '@sitecore-jss/sitecore-jss-react';
import { SitecoreGenericTextField } from '../../interfaces';
import { fetchData, getDataWithChild } from '../../utils/helper';
import { breakpoint, breakpointDesktopLarge, breakpointDesktopLarger, breakpointDesktopLargest, breakpointDesktopMedium, breakpointDesktopXLarge, breakpointMax, breakpointMinMobile, breakpointMobileSmall } from '../../style';
import { Button, Icon, Loader } from '@bdl-cmn-shared-packages-npm/design-system';
import hexa from './hexa.svg';
import { canUseDOM } from '../../predicates';

export interface LatestPressReleasesProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const LatestPressReleases: React.FunctionComponent<LatestPressReleasesProps> = (props: LatestPressReleasesProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const [press, setPress] = React.useState<any>(null);
    const [isLoadingPress, setIsLoadingPress] = React.useState<boolean>(true);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [transformX, setTransformX] = React.useState(0); 
    const pressPerPage = 2;

    const handleLeftClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setTransformX(transformX + 623); 
        }
    };

    const handleRightClick = () => {
        if (currentIndex < press.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setTransformX(transformX - 623);
        } else if (transformX === -2492) {
          setTransformX(transformX - 623);
        } 
      };

    const fetchPress = async (id: string) => {
        try {
          const result: any = await fetchData(id);
          const dataReq = await getDataWithChild(result);
      
          if (dataReq && dataReq.length > 0) {
            const sortedData = dataReq.map((item: any) => {
              if (item && item.children && item.children.length > 0) {
                item.children.sort((a: any, b: any) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
              }
              return item;
            });
      
            const latestItems = [];
            for (const item of sortedData) {
              if (item.children) {
                latestItems.push(...item.children.slice(0, 6)); 
              } else {
                latestItems.push(item); 
              }
            }
            const finalPressReleases = latestItems.slice(0, 6); 
      
            setPress(finalPressReleases);
            setIsLoadingPress(false);
          }
        } catch (err) {
          console.error(err);
        }
      };
      
    React.useEffect(() => {
        fetchPress("A8941707-2723-4AF1-A140-A019FF340E1C")
    }, []);

    const parentContainer = css`
        width: 100%;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: flex-start;
        @media (max-width: ${breakpointMax}) {
            width: 100%;
            flex-direction: column;
        }
    `;

    const container = css`
        width: 100%;
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: 40px;
	    padding-right: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
        @media (max-width: ${breakpointMax}) {
            padding: 16px;
            padding-bottom: 30px;
        }
    `;

    const mainTitle = css`
        flex: 1 0 0;
        color: #253845;
        font-weight: 700;
        font-size: 36px;
        line-height: normal;
        @media (max-width: ${breakpointMax}) {
            font-size: 32px;
        }
    `;

    const topSection = css`
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    `;

    const navigationButtons = css`
        display: flex;
        align-items: center;
        gap: 10px;
         @media (max-width: ${breakpointMax}) {
            display: none;
        }
    `;
    const buttonArrows = css`
        width: 40px;
        height: 40px;
        bottom: 0;
        right: 0;
        display: flex;
        background: #238750 !important;
        border-radius: 50%;
        text-align: center;
        padding: 0 !important;
        box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.25s ease-out;
        :hover {
             background: #f6f8f9;
             opacity: 0.6;
        }
        :disabled {
            background-color: #238750 !important;
            opacity: 0.3;
            border-radius: 50%;
                :hover {
                    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.20);
                }
        }
        svg {
            color: #ffffff;
            width: 18px !important;
            height: 18px !important;
        }
        .css-1bh9syo {
            margin-left: 0;
        }

    `;
  
    const cards = css`
        display: flex;
        flex-direction: row;
        gap: 24px;
        position: relative;
        overflow: hidden;
        transform: translateX(${transformX}px);
        transition: transform 0.5s ease;
        justify-content: flex-start !important;
        width: inherit;
        overflow: ${transformX < 0 ? "visible" : "hidden"}; 

        ${transformX === 0 && `
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 30%;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              @media (max-width: ${breakpointMax}) {
                display: none;
                }
            }
        `}

        ${transformX === -623 && `
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: -70%;
              bottom: 0;
              width: 30%;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              @media (max-width: ${breakpointMax}) {
                display: none;
                }
              @media (min-width: ${breakpointDesktopMedium}) {
                right: -74%;
                }
              @media (min-width: ${breakpointDesktopLarge}) {
                right: -85%;
                }
              @media (min-width: ${breakpointDesktopLarger}) {
                right: -88%;
                }
              @media (min-width: ${breakpointDesktopLargest}) {
                right: -112%;
                }
              @media (min-width: ${breakpointDesktopXLarge}) {
                right: -163%;
                }
            }
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 20%;
              bottom: 0;
              width: 30%;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              @media (max-width: ${breakpointMax}) {
                display: none;
                }
              @media (min-width: ${breakpointDesktopLargest}) {
                left: 0%;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                }
            }
        `}
        ${transformX === -1246 && `
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: -124%;
              bottom: 0;
              width: 30%;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              @media (max-width: ${breakpointMax}) {
                display: none;
              } 
              @media (min-width: ${breakpointDesktopLarge}) {
                right: -137%;
             }
              @media (min-width: ${breakpointDesktopLarger}) {
                right: -141%;
              }
              @media (min-width: ${breakpointDesktopLargest}) {
                right: -164%;
              }
              @media (min-width: ${breakpointDesktopXLarge}) {
                right: -214%;
              }
            }
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 73%;
              bottom: 0;
              width: 30%;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              @media (max-width: ${breakpointMax}) {
                display: none;
              }
             @media (min-width: ${breakpointDesktopLarge}) {
                left: 72%;
              }
              @media (min-width: ${breakpointDesktopLargest}) {
                left: 46%;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              }
              @media (min-width: ${breakpointDesktopXLarge}) {
                left: 0%;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              }
            }
        `}

        ${transformX === -1869 && `
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 124.5%;
              bottom: 0;
              width: 30%;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              @media (max-width: ${breakpointMax}) {
                display: none;
              }
              @media (min-width: ${breakpointDesktopLarge}) {
                left: 124.5%;
              }
              @media (min-width: ${breakpointDesktopLargest}) {
                left: 97.5%;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              }
              @media (min-width: ${breakpointDesktopXLarge}) {
                left: 44%;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              }
            }  
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: -180%;
              bottom: 0;
              width: 30%;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              @media (max-width: ${breakpointMax}) {
                display: none;
              } 
              @media (min-width: ${breakpointDesktopLarge}) {
                right: -189%;
             }
              @media (min-width: ${breakpointDesktopLarger}) {
                right: -191%;
              }
              @media (min-width: ${breakpointDesktopLargest}) {
                right: -218%;
              }
              // @media (min-width: ${breakpointDesktopXLarge}) {
              //   right: -240%;
              // }
            }
        `}

        ${transformX === -2492 && `
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 176.5%;
              bottom: 0;
              width: 30%;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              @media (max-width: ${breakpointMax}) {
                display: none;
              }
              @media (min-width: ${breakpointDesktopLarge}) {
                left: 177.5%;
              }
              @media (min-width: ${breakpointDesktopLarger}) {
                left: 176.5%;
              }
              @media (min-width: ${breakpointDesktopLargest}) {
                left: 150.5%;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              } 
              @media (min-width: ${breakpointDesktopXLarge}) {
                left: 95.5%;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
                
              }
            }
           &::after {
              content: "";
              position: absolute;
              top: 0;
              right: -216%;
              bottom: 0;
              width: 30%;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              @media (max-width: ${breakpointMax}) {
                display: none;
              } 
              @media (min-width: ${breakpointDesktopLarge}) {
                right: -212%;
              }
              @media (min-width: ${breakpointDesktopLargest}) {
                right: unset;
              }
              @media (min-width: ${breakpointDesktopXLarge}) {
                right: -240%;
              }
            }     
        `}
        ${transformX === -3115 && `
            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 229.5%;
              bottom: 0;
              width: 30%;
              background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              @media (max-width: ${breakpointMax}) {
                display: none;
              }
              @media (min-width: ${breakpointDesktopLarge}) {
                left: 228.5%;
              }
              @media (min-width: ${breakpointDesktopLarger}) {
                left: 229.5%;
              }
              @media (min-width: ${breakpointDesktopLargest}) {
                left: 202.5%;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              }
              @media (min-width: ${breakpointDesktopXLarge}) {
                left: 148.5%;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) -12.78%, #FFF 88.19%);
              }
            }
            }  
        `}
        @media (max-width: ${breakpointMax}) {
            flex-direction: row;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            transform: none;
        }
    `;

    const card = css`
        width: 600px;
        min-width: 600px;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 24px;
        margin-top: 24px;
        border-radius: 8px;
        border: 1px solid #E1E3E5;
        background: #FFF;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
          :hover {
            opacity: 0.6;
          }
        @media (max-width: ${breakpointMax}) {
            width: 90%;
            min-width: 90%;
            height: 350px;
            margin-left: 10px;
            margin-right: 10px;
        }
        @media (min-width: ${breakpointMax}) and (max-width: ${breakpoint}) {
            min-width: 90%;
            height: 280px;  
        }
        @media (max-width: ${breakpointMinMobile}) {
            padding: 18px;
        }
    `;

    const header = css`
        display: flex;
        width: 100%;
        margin-left: auto;
    `;

    const icon = css`
        background-image: url(${hexa});
        background-position: center center;
        margin-right: 0px;
        min-height: 64px;
        min-width: 50px;
        align-items: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #008C3D;
    `;

    const title = css`
        color: #425563;
        font-weight: 700;
        font-size: 24px;
        line-height: normal;
        margin-left: 24px;
        @media (max-width: ${breakpointMax}) {
            font-size: 18px;
            margin-left: 21px;
        }
        @media (max-width: ${breakpointMobileSmall}) {
            font-size: 16px;
            margin-left: 21px;
        }
    `;

    const shortDescription = css`
        color: #253845;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        margin-top: 15px;
        align-self: flex-start;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: ${breakpointMax}) {
            font-size: 14px;
            max-height: 55%;
            margin-bottom: 5px;
        }
        @media (max-width: ${breakpointMobileSmall}) {
            font-size: 13px;
            max-height: 62%;
            margin-bottom: 7px;
        }
    `;

    const date = css`
        color: #425563;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin-top: 10px;
        @media (max-width: ${breakpointMax}) {
            font-size: 13px;
        }
    `;

    const dateSection = css`
        align-self: flex-start;
    `;

    const buttonCTA = css`
        margin-top: 25px;
        width: 100%;
        display: flex;
    `;

    const buttonLabel: string = getFieldValue<string>(fields, "buttonLabel", "");
    const buttonLink: string = getFieldValue<string>(fields, "buttonLink", "");

    return (
        <div css={parentContainer}>
            <div css={container}>
                <div css={topSection}>
                    {fields && fields.title && (fields.title as any).value && (
                        <div css={mainTitle}>
                            <Text
                                field={fields && (fields.title as SitecoreGenericTextField)}
                            />
                        </div>
                    )}
                    {isLoadingPress === false && (
                        <div css={navigationButtons}>
                            <Button
                                css={buttonArrows}
                                iconRight={'chevron-left'}
                                variant={undefined}
                                onClick={handleLeftClick}
                                disabled={currentIndex === 0}
                            />
                            <Button
                                css={buttonArrows}
                                iconRight={'chevron-right'}
                                variant="primary"
                                onClick={handleRightClick}
                                disabled={transformX === -3115}
                            />
                        </div>
                    )}
                </div>
                <Loader loading={isLoadingPress} centered css={{ minHeight: 400 }}>
                    <div css={cards} id="scrollable">
                        {press && press.length > 0 ? (
                                press.map((data: any, index: any) => (
                                    <div key={index} css={[card, index === currentIndex && card]} onClick={() => {
                                        const url = data.newLayout === "1"
                                            ? `/about-us/press-centre/${data.Title.replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-').replace(/--/g, '-')}`
                                            : `/about-us/press-centre/${data.ItemID}___${data.Title.replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-').replace(/--/g, '-')}`
                                            window.location.href = url;
                                    }}
                                    onContextMenu={(e) => {
                                      const url = data.newLayout === "1"
                                        ? `/about-us/press-centre/${data.Title.replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-').replace(/--/g, '-')}`
                                        : `/about-us/press-centre/${data.ItemID}___${data.Title.replace(/[^a-zA-Z0-9]/g, '-').replace(/--/g, '-').replace(/--/g, '-')}`;
                                      window.open(url, '_blank');
                                    }}
                                    >
                                        <div css={header}>
                                            <div css={icon}>
                                                <Icon icon={"bullhorn"} />
                                            </div>
                                            <div css={title}>
                                                {data.Title}
                                            </div>
                                        </div>
                                        <div css={shortDescription}>
                                            <div dangerouslySetInnerHTML={{ __html: data['Short description'] }} />
                                        </div>
                                        <div css={dateSection}>
                                            <div css={date}>
                                                {data.Label}
                                            </div>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <div>Loading...</div>
                        )}
                    </div>
                </Loader>
                <div css={buttonCTA}>
                    {buttonLabel && (
                        <div>
                            <Link
                                target={(fields['buttonLink']?.value?.linktype === "external" ? "_blank" : "_self")}
                                field={fields['buttonLink']}
                                css={{ textDecoration: 'none' }}
                            >
                                <Button
                                    label={buttonLabel}
                                    variant={"thirdary"}
                                    iconRight={"chevron-right"}
                                    style={{
                                        boxShadow: 'none',
                                        border: '1px solid rgba(34, 170, 95, 0.50)',
                                        borderRadius: '4px',
                                    }}
                                />
                            </Link>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
}