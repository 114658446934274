/** @jsx jsx */

import {
  Icon,
  Menu,
  MenuWithChild,
  ToggleButton,
} from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";

import CountFilter from "../../../../../interfaces/search/search-filter-count";
import CountItem from "../../../Search/results/filters/count-item";
import SearchFilterParams from "../../../../../interfaces/search/search-filter-params";
import SearchIssuerFilters from "../../../../../interfaces/search/search-issuer-filters";
import SearchIssuerFiltersType from "../../../../../interfaces/search/search-issuer-type-filters";
import { breakpointMax } from "../../../../../style";
import { issuerSubTypeLabel } from "../../../../../enums/issuer-subtype";
import { issuerTypeLabel } from "../../../../../enums/issuer-type";
import { style } from "../../../Search/results/filters/index.style";
import { useState } from "react";

export interface SecurityFiltersProps {
  filterCount?: SearchIssuerFilters;
  filters: SearchFilterParams;
  onFiltersChange: (filters: SearchFilterParams) => void;
  fullWidth?: boolean;
}

function computeTypeValues(
  values: SearchIssuerFiltersType[],
  selection?: any
): SearchIssuerFiltersType[] {
  if (!selection || (values && !!values.length)) {
    return values;
  }
  return [{ name: selection, count: -1, issuerSubTypes: [] }];
}

function computeValues(values: CountFilter[], selection?: any): CountFilter[] {
  if (!selection || (values && !!values.length)) {
    return values;
  }
  return [{ name: selection, count: -1 }];
}

export function IssuerFilters({
  filterCount,
  filters,
  onFiltersChange,
  fullWidth,
}: SecurityFiltersProps) {
  const handleFilterMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    let found = false;
    const parent = event.currentTarget;
    const head = parent.firstChild.firstChild;
    let target = event.target as Element;

    do {
      if (head == target) found = true;

      target = target.parentElement;
    } while (!found && parent != target && target != null);

    if (found) parent.classList.toggle("open");
  };

  const handleFilterChange = (
    key: keyof SearchFilterParams,
    value: any
  ): void => {
    const newFilters: SearchFilterParams = { ...filters };
    if (newFilters[key] instanceof Array && filters[key] instanceof Array) {
      try {
        // @ts-ignore
        let array: any[] =
          filters[key] != null ? Object.assign([], filters[key]) : [];
        if (array != null && array.includes(value))
          array = array.filter((v) => {
            return v !== value;
          });
        else array.push(value);
        array = array.filter((v) => v);
        if (array == null || !array.length) delete newFilters[key];
        else {
          // @ts-ignore
          newFilters[key] = array;
        }
      } catch (e) {
        console.error(
          "Failed to updated filter[" + key + "]",
          filters[key],
          " with value",
          value,
          e
        );
      }
    } else {
      if (filters[key] === value) {
        delete newFilters[key];
      } else {
        // @ts-ignore
        newFilters[key] = value;
      }
    }
    onFiltersChange(newFilters);
  };

  const closeBtn = css`
    width: 38px;
    height: 38px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;

    svg {
      color: #22aa5f;
      width: 25px !important;
      height: 25px !important;
      margin: 0px !important;
    }

    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `;

  const [open, setOpen] = useState(true);

  return (
    <div
      css={[
        fullWidth
          ? style.searchResultsFiltersFullWidth
          : style.searchResultsFilters,
        css`
          position: relative;
          transition: all 0.1s ease-in-out;
          margin-left: ${open ? "0" : "-17%"};
          padding-right: ${open ? "16px" : "60px"};
        `,
      ]}
    >
      <div css={closeBtn} onClick={() => setOpen(!open)}>
        <Icon icon={open ? "arrow-left" : "arrow-right"}></Icon>
      </div>
      <div css={style.title}>Filter your search result</div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="globe"
          label="Issuer classification"
          width="100%;"
          expanded={true}
          arrows={false}
        >
          {computeTypeValues(
            filterCount && filterCount.issuerTypes,
            filters.issuerTypes
          ) &&
            computeTypeValues(
              filterCount && filterCount.issuerTypes,
              filters.issuerTypes
            )
              .filter((item) => item.count > 0)
              .sort((first, second) => second.count - first.count)
              .map((issuerType) => {
                if (issuerType.count == -1)
                  return <div css={style.menuNoItem}>No filter available</div>;

                return (
                  <div
                    css={[style.subRow, style.filterMenu]}
                    onClick={handleFilterMenuClick}
                  >
                    <MenuWithChild
                      icon="tag"
                      label={issuerTypeLabel(issuerType.name)}
                      width="100%;"
                      expanded={false}
                      arrows={false}
                    >
                      <div css={[style.toggleRow, style.toggleRowNoBorder]}>
                        <div css={style.toggleTitle}>
                          <div css={style.toggleIcon}>
                            <Icon icon="tags" />
                          </div>
                          <div css={style.toggleLabel}>Show all</div>
                          <div
                            css={style.toggleCount}
                          >{`(${issuerType.count})`}</div>
                        </div>
                        <div css={style.toggleButton}>
                          <ToggleButton
                            isSelected={
                              filters.issuerTypes &&
                              filters.issuerTypes.includes(issuerType.name)
                            }
                            onClick={() =>
                              handleFilterChange("issuerTypes", issuerType.name)
                            }
                          />
                        </div>
                      </div>

                      {computeValues(
                        issuerType.issuerSubTypes,
                        filters.issuerSubTypes
                      ) &&
                        computeValues(
                          issuerType.issuerSubTypes,
                          filters.issuerSubTypes
                        )
                          .filter((item) => item.count > 0)
                          .sort((first, second) => second.count - first.count)
                          .map((issuerSubType) => {
                            if (issuerSubType.count == -1)
                              return (
                                <div css={style.menuNoItem}>
                                  No filter available
                                </div>
                              );

                            return (
                              <div css={style.menuItem}>
                                <Menu
                                  key={issuerSubType.name}
                                  width="100%;"
                                  selected={
                                    filters.issuerSubTypes &&
                                    filters.issuerSubTypes.includes(
                                      issuerSubType.name
                                    )
                                  }
                                  onSelect={() =>
                                    handleFilterChange(
                                      "issuerSubTypes",
                                      issuerSubType.name
                                    )
                                  }
                                  childLabel={
                                    (
                                      <CountItem
                                        name={issuerSubTypeLabel(
                                          issuerSubType.name
                                        )}
                                        count={issuerSubType.count}
                                      />
                                    ) as unknown as string
                                  }
                                />
                              </div>
                            );
                          })}
                    </MenuWithChild>
                  </div>
                );
              })}
        </MenuWithChild>
      </div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="leaf"
          label="LGX Information"
          width="100%;"
          expanded={false}
          arrows={false}
        >
          <div css={style.toggleRow}>
            <div css={style.toggleTitle}>
              <div css={style.toggleIcon}>
                <Icon icon="leaf" />
              </div>
              <div css={style.toggleLabel}>LGX</div>
              <div css={style.toggleCount}>
                {filterCount ? `(${filterCount.lgxOnly})` : ""}
              </div>
            </div>
            <div css={style.toggleButton}>
              <ToggleButton
                isSelected={filters.lgxOnly == true}
                onClick={() => handleFilterChange("lgxOnly", !filters.lgxOnly)}
              />
            </div>
          </div>

          {/*<div css={style.toggleRow}>
            <div css={style.toggleTitle}>
              <div css={style.toggleIcon}>
                <Icon icon='globe' />
              </div>
              <div css={style.toggleLabel}>Climate aligned</div>
              <div css={style.toggleCount}>{filterCount ? `(${filterCount.climateAlignedOnly})` : ''}</div>
            </div>
            <div css={style.toggleButton}>
              <ToggleButton
                isSelected={filters.climateAlignedOnly == true}
                onClick={() => handleFilterChange('climateAlignedOnly', !filters.climateAlignedOnly)}
              />
            </div>
          </div>*/}
        </MenuWithChild>
      </div>

      <div css={[style.row, style.filterMenu]} onClick={handleFilterMenuClick}>
        <MenuWithChild
          icon="globe"
          label="Country"
          width="100%;"
          expanded={false}
          arrows={false}
        >
          {computeValues(
            filterCount && filterCount.countries,
            filters.country
          ) &&
            computeValues(filterCount && filterCount.countries, filters.country)
              .sort((first, second) => second.count - first.count)
              .map((country) => {
                if (country.count == -1)
                  return <div css={style.menuNoItem}>No filter available</div>;

                return (
                  <div css={[style.menuItem, style.menuItemRow]}>
                    <Menu
                      key={country.name}
                      width="100%;"
                      selected={filters.country === country.name}
                      onSelect={() =>
                        handleFilterChange("country", country.name)
                      }
                      childLabel={
                        (
                          <CountItem
                            name={country.name.toLowerCase()}
                            count={country.count}
                          />
                        ) as unknown as string
                      }
                    />
                  </div>
                );
              })}
        </MenuWithChild>
      </div>
    </div>
  );
}
