/** @jsx jsx */
import React, { Component, useState } from "react";
import { DonutChart } from "../Utils/DonutChart";
import { css, jsx } from "@emotion/react";
import { sharedStyle } from "../../../../styles/shared";
import { BrowserRouter } from "react-router-dom";
import { Grid, Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import WidgetCard from "../../WidgetCard";
import ReactApexChart from "react-apexcharts";
import { ColumnChart } from "../Utils/ColumnChart";



export class RisersAndFallersChart extends Component<any, any> {

    render() {
        const graphColors: string[] = [
            "#CB1234",
            "#22AA5F",
        ];
        const data = this.props.data;
        const loading = this.props.loading;
        const type = this.props.type;


        console.log("Loading ",loading);


        return (
            <WidgetCard
                icon={""}
                label={''}
                loader={loading}
                isHover={false}
            >
                <ColumnChart
                    data={data}
                    labelField={"description"}
                    serieField={type==="MTD"?"mtdVariation":"ytdVariation"}
                    labelValueFunction={(value: string) => value}
                    type={type}
                />
                   
            </WidgetCard>
        )
    }
}

export default RisersAndFallersChart;