/** @jsx jsx */

import { Button, InputField, OptionsData, SelectBox } from "@bdl-cmn-shared-packages-npm/design-system";
import { UseFilters } from "../useFilters";
import { useMemo, useState } from "react";
import { InputDateRangePicker } from "../../input-date-range-picker";
import { OAMTypeDepo, OAMTypeDepoLabel } from "../../../../enums/oam-type-depo";
import { jsx } from "@emotion/react";
import { style } from "./index.style";
import { Country } from "../../../../enums";
import { CountryFilter } from "./country";
import { ReferenceYearFilter } from "./reference-year";
import { TypeDepoFilter } from "./type-depo";

interface OAMFiltersProps extends Pick<UseFilters, "filters" | "onFilterChange"> {
  onSubmit: () => void
}

const typeDepoByGroup = {
  "Periodic information": [
    OAMTypeDepo.ADIP,
    OAMTypeDepo.HYFR,
    OAMTypeDepo.PATG,
    OAMTypeDepo.QFRE,
  ],
  "Ongoing information": [
    OAMTypeDepo.INIF,
    OAMTypeDepo.MSNO,
    OAMTypeDepo.ADIP,
    OAMTypeDepo.TNVC,
    OAMTypeDepo.CRAS,
    OAMTypeDepo.MATS
  ],
  "Other information": [
    OAMTypeDepo.HOMS
  ]
};

export default function OAMFilters({ filters, onFilterChange, onSubmit }: OAMFiltersProps) {
  const [moreFields, setMoreFields] = useState<boolean>(false);

  const typeDepoSelectData = useMemo(() => ([
    {
      name: filters.lang === "EN" ? "All" : "Tous",
      value: null
    },
    ...Object.values(OAMTypeDepo).map(value => (
      {
        name: OAMTypeDepoLabel(value, filters.lang),
        value
      }
    ))
  ]), [filters.lang]);

  const yearSelectData = useMemo(() => {
    let years = [];
    for (let i = new Date().getFullYear(); i >= 2008; i--) {
      years.push({
        value: i.toString(),
        name: i.toString(),
      })
    }
    return years;
  }, []);

  return (
    <div css={style.container}>
      <div css={style.header}>
        <h2 css={style.title}>OAM Search</h2>

        <div css={style.lang}>
          {
            ['EN', 'FR'].map(lang => (
              <span
                css={filters.lang === lang ? style.selectedLang : null}
                onClick={() => onFilterChange("lang")(lang)}>
                {lang}
              </span>
            ))
          }
        </div>
      </div>

      <form css={style.form}
        onSubmit={(e: any) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div css={style.formWrapper}>
          <div css={style.row}>
            <div css={[style.inputField, style.label]}>
              <InputField
                value={filters.oam_libEmet}
                onChange={(e: any) => onFilterChange("oam_libEmet")(e.target.value)}
                placeholder={filters.lang === "EN" ? "Issuer name" : "Nom de l'émetteur"}
              />
            </div>
            <div css={[style.selectbox, style.selectboxTop, style.label]}>
              <TypeDepoFilter
                selection={filters.oam_idTypeDepo}
                onSelectionChange={(e: OptionsData) => onFilterChange("oam_idTypeDepo")(e.value)}
                data={typeDepoSelectData}
                lang={filters.lang}
              />
            </div>
          </div>

          <div css={[style.row, !moreFields && style.hidden]}>
            <div css={[style.inputField, style.label]}>
              <InputField
                value={filters.oam_codifInstr}
                onChange={(e: any) => onFilterChange("oam_codifInstr")(e.target.value)}
                placeholder={filters.lang === "EN" ? "ISIN code" : "Code ISIN"} />
            </div>

            <div css={[style.inputField, style.label]}>
              <InputField
                value={filters.oam_codifTiersDecla}
                onChange={(e: any) => onFilterChange("oam_codifTiersDecla")(e.target.value)}
                placeholder={filters.lang === "EN" ? "CSSF code" : "Code CSSF de l'émetteur"} />
            </div>

            <div css={[style.selectbox, style.label]}>
              <CountryFilter
                selection={filters.oam_paysEmet}
                onSelectionChange={(e: OptionsData) => onFilterChange("oam_paysEmet")(e.value as keyof typeof Country)}
                data={null}
                lang={filters.lang}
              />
            </div>

            <div css={[style.selectbox, style.label]}>
              <ReferenceYearFilter
                selection={filters.oam_anRef}
                onSelectionChange={(e: OptionsData) => onFilterChange("oam_anRef")(e.value || null)}
                data={null}
                lang={filters.lang}
              />
            </div>

            <div css={[style.inputField, style.label, style.datePicker]}>
              <InputDateRangePicker
                fromDate={filters.publicationDate.fromDate}
                toDate={filters.publicationDate.toDate}
                onSelect={(fromDate, toDate) => {
                  onFilterChange("publicationDate")({ fromDate, toDate });
                }}
                placeholder={filters.lang === "EN" ? "Publication date" : "Date de publication"} />
            </div>
          </div>
        </div>
        <div css={style.actions}>
          <Button 
            label={filters.lang === "EN" ? "Search OAM" : "Recherche OAM"} 
            iconRight="search" 
            onClick={() => onSubmit()}
          />
          <div css={style.moreCriteriaButton}>
            <Button
              type="button"
              label={moreFields ? 
                  (filters.lang === "EN" ? "Less criteria" : "Moins de critères") : 
                  (filters.lang === "EN" ? "More criteria" : "Plus de critères")}
              onClick={() => setMoreFields(more => !more)}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
