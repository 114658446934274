
import * as React from 'react';
import Size from '../../../../../../interfaces/size';

export interface GoodHealthAndWellBeingSvgProps {
    size: Size;
    style?: React.CSSProperties;
}

export const GoodHealthAndWellBeingSvg: React.FunctionComponent<GoodHealthAndWellBeingSvgProps> = ({ size: { width, height }, style }: GoodHealthAndWellBeingSvgProps): React.ReactElement => {
    return (
        <svg
            aria-hidden='true'
            role='img'
            viewBox='0 0 47 47'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ width, height, ...style }}
        >
            <title>3 - Good Health and Well-being</title>
            <path d='M3.56131 13.2902C5.61215 13.2902 6.50706 12.2014 6.50706 10.5235V8.74111C6.50706 7.63366 6.02604 6.92146 5.00808 6.69027C5.84706 6.4218 6.39892 5.74316 6.39892 4.72893V3.67741C6.39892 1.96589 5.57859 0.910645 3.703 0.910645C1.59624 0.910645 0.865392 2.15979 0.865392 3.94215V5.01232H2.72233V3.78182C2.72233 3.03233 2.95352 2.60352 3.5986 2.60352C4.23995 2.60352 4.47113 3.03233 4.47113 3.69232V4.99367C4.47113 5.63503 4.04232 5.97434 3.38233 5.97434H2.6142V7.52925H3.47182C4.18402 7.52925 4.47113 7.86857 4.47113 8.50992V10.5086C4.47113 11.1499 4.18775 11.5974 3.56131 11.5974C2.86403 11.5974 2.6142 11.1313 2.6142 10.4191V8.68891H0.760986V10.2774C0.760986 12.1119 1.49183 13.2902 3.56131 13.2902Z' fill='white'/>
            <path d='M45.1187 33.5003C45.1187 31.8112 44.0336 30.4427 42.393 30.4427C41.5726 30.4427 40.5845 31.3413 40.0438 31.8932C39.5069 31.3413 38.5821 30.439 37.7618 30.439C36.1211 30.439 34.9727 31.8074 34.9727 33.4966C34.9727 34.3393 35.3045 35.1037 35.8415 35.6593L40.0475 39.8728L44.2462 35.663L44.2424 35.6593C44.7831 35.1111 45.1187 34.343 45.1187 33.5003Z' fill='white'/>
            <path d='M27.2093 46.7562C27.1944 46.7562 27.1795 46.7562 27.1645 46.7562C26.8439 46.7375 26.5791 46.4989 26.5269 46.1819L24.3903 33.7389L21.1537 39.7386C21.012 40.0033 20.7175 40.1413 20.4266 40.0891C20.132 40.0369 19.9046 39.802 19.8636 39.5074L18.4914 29.9057L15.9782 35.5511C15.8663 35.801 15.6202 35.9613 15.348 35.9613H5.57856C5.19822 35.9613 4.88873 35.6518 4.88873 35.2715C4.88873 34.8911 5.19822 34.5816 5.57856 34.5816H14.9005L18.208 27.1539C18.3348 26.8742 18.6293 26.7102 18.9351 26.7512C19.2409 26.7922 19.4795 27.0308 19.5242 27.3366L20.9412 37.2403L24.133 31.3227C24.2747 31.0617 24.5618 30.92 24.8527 30.9684C25.1435 31.0169 25.371 31.2444 25.4232 31.5315L27.4032 43.0572L34.6147 19.1034C34.6967 18.8312 34.9391 18.6373 35.2225 18.615C35.5059 18.5926 35.7743 18.7455 35.8974 19.0027L39.6933 26.8705C39.8573 27.2136 39.7157 27.6274 39.3726 27.7915C39.0296 27.9556 38.6157 27.8139 38.4516 27.4708L35.4275 21.2027L27.8693 46.264C27.7835 46.5548 27.5113 46.7562 27.2093 46.7562Z' fill='white'/>
            <path d='M13.3793 3.3083H14.5501V5.92591H13.9572V5.38897C13.8491 5.75812 13.6179 5.98184 13.1704 5.98184C12.462 5.98184 12.1376 5.4188 12.1376 4.65812V2.28661C12.1376 1.51848 12.5067 0.962891 13.3755 0.962891C14.2854 0.962891 14.5538 1.47001 14.5538 2.19339V2.62593H13.8006V2.12627C13.8006 1.82424 13.685 1.64899 13.3867 1.64899C13.0884 1.64899 12.9616 1.8578 12.9616 2.1561V4.78863C12.9616 5.08693 13.0847 5.29575 13.3681 5.29575C13.6365 5.29575 13.7745 5.14287 13.7745 4.82592V3.95711H13.383V3.3083H13.3793Z' fill='white'/>
            <path d='M15.0945 4.66203V2.29052C15.0945 1.52239 15.4711 0.966797 16.3399 0.966797C17.2162 0.966797 17.5928 1.52239 17.5928 2.29052V4.66203C17.5928 5.4227 17.2162 5.98575 16.3399 5.98575C15.4711 5.98575 15.0945 5.4227 15.0945 4.66203ZM16.765 4.79254V2.16001C16.765 1.86171 16.6345 1.65289 16.3399 1.65289C16.0491 1.65289 15.9223 1.86171 15.9223 2.16001V4.79254C15.9223 5.09084 16.0528 5.29965 16.3399 5.29965C16.6345 5.29965 16.765 5.08711 16.765 4.79254Z' fill='white'/>
            <path d='M18.1335 4.66203V2.29052C18.1335 1.52239 18.5101 0.966797 19.3789 0.966797C20.2552 0.966797 20.6318 1.52239 20.6318 2.29052V4.66203C20.6318 5.4227 20.2552 5.98575 19.3789 5.98575C18.5064 5.98575 18.1335 5.4227 18.1335 4.66203ZM19.804 4.79254V2.16001C19.804 1.86171 19.6735 1.65289 19.3789 1.65289C19.0881 1.65289 18.9613 1.86171 18.9613 2.16001V4.79254C18.9613 5.09084 19.0918 5.29965 19.3789 5.29965C19.6735 5.29965 19.804 5.08711 19.804 4.79254Z' fill='white'/>
            <path d='M23.6372 2.34263V4.61347C23.6372 5.36668 23.3277 5.92973 22.4439 5.92973H21.1724V1.02637H22.4439C23.3239 1.02637 23.6372 1.58196 23.6372 2.34263ZM22.3433 5.25482C22.6826 5.25482 22.8131 5.05346 22.8131 4.75516V2.18975C22.8131 1.8989 22.6826 1.69755 22.3433 1.69755H21.9965V5.25482H22.3433Z' fill='white'/>
            <path d='M26.9185 3.76703H26.1242V5.92973H25.3002V1.02637H26.1242V3.1033H26.9185V1.02637H27.75V5.92973H26.9185V3.76703Z' fill='white'/>
            <path d='M28.3317 1.02637H30.3005V1.69755H29.1557V3.09958H29.9723V3.7633H29.1557V5.25482H30.2967V5.926H28.3279V1.02637H28.3317Z' fill='white'/>
            <path d='M30.6808 5.92582L31.6056 1.02246H32.5452L33.4774 5.92582H32.631L32.4781 5.03091H31.6019L31.4564 5.92582H30.6808ZM31.7175 4.37092H32.3737L32.0493 2.40584H32.0419L31.7175 4.37092Z' fill='white'/>
            <path d='M33.8951 1.02637H34.7191V5.25855H35.7035V5.92973H33.8951V1.02637Z' fill='white'/>
            <path d='M35.588 1.02637H37.7805V1.69755H37.1018V5.92973H36.2703V1.69755H35.5917V1.02637H35.588Z' fill='white'/>
            <path d='M39.8537 3.76703H39.0595V5.92973H38.2354V1.02637H39.0595V3.1033H39.8537V1.02637H40.6852V5.92973H39.8537V3.76703Z' fill='white'/>
            <path d='M12.0107 13.1187L12.9355 8.21533H13.8751L14.8073 13.1187H13.9609L13.808 12.2238H12.9355L12.7901 13.1187H12.0107ZM13.0436 11.5638H13.6999L13.3755 9.59872H13.368L13.0436 11.5638Z' fill='white'/>
            <path d='M15.8439 10.0387V13.1187H15.1503V8.21533H15.9595L16.8469 11.0268V8.21533H17.533V13.1187H16.8134L15.8439 10.0387Z' fill='white'/>
            <path d='M20.5832 9.5316V11.8024C20.5832 12.5556 20.2737 13.1187 19.39 13.1187H18.1185V8.21533H19.39C20.27 8.21533 20.5832 8.77465 20.5832 9.5316ZM19.2856 12.4475C19.6249 12.4475 19.7554 12.2462 19.7554 11.9479V9.37872C19.7554 9.08787 19.6249 8.88652 19.2856 8.88652H18.9388V12.4438H19.2856V12.4475Z' fill='white'/>
            <path d='M23.7378 10.0872L23.2754 13.1187H22.4998L21.8436 8.21533H22.6378L23.0219 11.3251H23.0293L23.4432 8.21533H24.1144L24.5693 11.3251H24.5768L24.9534 8.21533H25.6469L25.0093 13.1187H24.23L23.7453 10.0872H23.7378Z' fill='white'/>
            <path d='M26.072 8.21533H28.0408V8.88652H26.8998V10.2885H27.7164V10.9523H26.8998V12.4438H28.0408V13.115H26.072V8.21533Z' fill='white'/>
            <path d='M28.5554 8.21533H29.3795V12.4475H30.3639V13.1187H28.5554V8.21533Z' fill='white'/>
            <path d='M30.8113 8.21533H31.6353V12.4475H32.6197V13.1187H30.8113V8.21533Z' fill='white'/>
            <path d='M32.5117 10.2178H33.6676V10.8815H32.5117V10.2178Z' fill='white'/>
            <path d='M34.1597 8.21533H35.353C36.177 8.21533 36.4529 8.5994 36.4529 9.29295V9.79261C36.4529 10.2065 36.3001 10.4638 35.9607 10.5719C36.3746 10.6652 36.576 10.9411 36.576 11.3885V12.0411C36.576 12.7346 36.2665 13.1187 35.4424 13.1187H34.1635V8.21533H34.1597ZM34.9838 10.2848H35.2746C35.5431 10.2848 35.6662 10.1468 35.6662 9.88583V9.2855C35.6662 9.01702 35.5431 8.88652 35.2672 8.88652H34.9838V10.2848ZM34.9838 10.915V12.4475H35.3455C35.6214 12.4475 35.7519 12.3095 35.7519 12.0485V11.3102C35.7519 11.0492 35.6289 10.9113 35.338 10.9113H34.9838V10.915Z' fill='white'/>
            <path d='M37.0607 8.21533H39.0295V8.88652H37.8885V10.2885H38.7051V10.9523H37.8885V12.4438H39.0295V13.115H37.0607V8.21533Z' fill='white'/>
            <path d='M39.5405 8.21533H40.3645V13.1187H39.5405V8.21533Z' fill='white'/>
            <path d='M41.6472 10.0387V13.1187H40.9536V8.21533H41.7628L42.6502 11.0268V8.21533H43.3363V13.1187H42.6129L41.6472 10.0387Z' fill='white'/>
            <path d='M45.1634 10.5012H46.3343V13.1188H45.7414V12.5818C45.6333 12.951 45.4021 13.1747 44.9546 13.1747C44.2462 13.1747 43.9218 12.6117 43.9218 11.851V9.47948C43.9218 8.71135 44.2909 8.15576 45.1597 8.15576C46.0695 8.15576 46.338 8.66288 46.338 9.38626V9.8188H45.5848V9.31915C45.5848 9.01711 45.4692 8.84186 45.1709 8.84186C44.8726 8.84186 44.7458 9.05067 44.7458 9.34898V11.9815C44.7458 12.2798 44.8689 12.4886 45.1523 12.4886C45.4207 12.4886 45.5587 12.3357 45.5587 12.0188V11.15H45.1672V10.5012H45.1634Z' fill='white'/>

        </svg>
    );
};
