import * as React from 'react';

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Link,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { Button } from '@bdl-cmn-shared-packages-npm/design-system';
import { SitecoreGenericTextField } from '../../interfaces';
import hexas from '../HomeInformations/hexas.png';
import hexasBg from '../HomeInformations/hexas.svg';
import { renderLink } from '../../utils/helper';

/** @jsx jsx */









export interface HomeInformationsProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const style = {
  parentContainer: css`
    width: 100%;
    height: 100%;
    background: linear-gradient(358.44deg, #f8fafc 1.41%, rgba(248, 250, 252, 0) 96.08%);
  `,
  container: css`
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin: auto;
    display: flex;
    margin-top: 80px;
    margin-bottom: 40px;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
    }
  `,
  left: css`
    display: flex;
    flex-direction: column;
    width: 40%;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
    }
  `,
  containerHexa1: css`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    //max-width: 420px;
    padding-right: 80px;
    @media (max-width: ${breakpointMax}) {
      padding-right: 40px;
      padding-left: 40px;
    }
  `,

  containerHexa2: css`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-left: -64px;
    margin-top: -45px;
    max-width: 240px;
  `,
  containerHexa3: css`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    margin-top: -45px;
    max-width: 100px;
  `,
  flex: css`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `,
  img1: css`
    width: 100%;
    //max-width: 500px;
  `,
  img2: css`
    //margin-top: -32px;
    width: 100%;
    //max-width: 270px;
  `,
  img3: css`
    //margin-top: -58%;
    width: 100%;
    //max-width: 130px;
  `,
  right: css`
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 32px;
    background: url(${renderLink(hexasBg)});
    background-repeat: no-repeat;
    background-position: top;
    @media (max-width: ${breakpointMax}) {
      width: 100%;
      padding-left: 16px;
    }
  `,
  headerTitle: css`
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      color: #008c46;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
    }
  `,
  title: css`
    font-size: 48px;
    font-weight: 800;
    line-height: 62px;
    color: #354450;
    margin-top: 16px;
    margin-bottom: 16px;
    @media (max-width: ${breakpointMax}) {
      font-weight: 800;
      font-weight: 800;
      font-size: 32px;
      line-height: 41px;
    }
  `,
  text: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #354450;
    @media (max-width: ${breakpointMax}) {
      font-weight: 600;
      font-size: 14px;
      line-height: 180%;
      color: #354450;
    }
  `,
  action: css`
    margin-top: 32px;
    button {
      font-weight: 600;
      font-size: 16px;
    }
    @media (max-width: ${breakpointMax}) {
      margin-bottom: 40px;
    }
  `,
};

export const HomeInformations: React.FunctionComponent<HomeInformationsProps> = (
  props: HomeInformationsProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const button1Label: string = getFieldValue<string>(fields, 'button label', '');

  return (
    <div css={style.parentContainer}>
      <div css={style.container}>
        <div css={style.left}>
          <div css={style.containerHexa1}>
            <img src={renderLink(hexas)} css={style.img1} alt={"image"} />
          </div>
        </div>
        <div css={style.right}>
          <div css={style.headerTitle}>
            <Text field={fields && (fields.headerTitle as SitecoreGenericTextField)} />
          </div>
          <div css={style.title}>
            <Text field={fields && (fields.title as SitecoreGenericTextField)} />
          </div>
          <div css={style.text}>
            <Text field={fields && (fields.text as SitecoreGenericTextField)} />
          </div>
          <div css={style.action}>
            <Link target={(fields['button link']?.value?.linktype === "external" ? "_blank" : "_self")} title={fields['button label']} field={fields['button link']} css={{textDecoration: 'none'}}>
              <Button label={button1Label} iconRight={'chevron-right'} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
