export enum ProgrammeEndDateEnum {
	CURRENT_YEAR = "Current year",
	YEAR_PLUS_ONE = "Next year",
	YEAR_PLUS_TWO = "Next two years",
	MORE_THAN_TWO_YEARS = "In more than two years"
}

export function programmeEndDateSort(name1: string, name2:string): number {
	let index1 = null;
	try {
		index1 = Object.keys(ProgrammeEndDateEnum).indexOf(name1.toString().toUpperCase());
	}catch (e){
		index1 = null;
	}
	if( index1 == null ){
		console.error("Failed to get ProgrammeEndDateEnum label for '" + name1 + "'");
		return -1;
	}

	let index2 = null;
	try {
		index2 = Object.keys(ProgrammeEndDateEnum).indexOf(name2.toString().toUpperCase());
	}catch (e){
		index2 = null;
	}
	if( index2 == null ){
		console.error("Failed to get ProgrammeEndDateEnum label for '" + name2 + "'");
		return 1;
	}

	return index1 - index2;
}

export function programmeEndDateLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(ProgrammeEndDateEnum).indexOf(value.toString().toUpperCase());
		return index >= 0 ? Object.values(ProgrammeEndDateEnum)[index] : value;
	}catch (e){
		console.error("Failed to get ProgrammeEndDateEnum label for '" + value + "'", e);
	}
		
	return value;
}