
export enum LgxSustainableBond {
	GRBD = 'Green bonds',
	SOCI = 'Social bonds',
	SUST = 'Sustainability bonds',
	SLB = 'Sustainability-linked Bonds'
}

export function lgxSustainableBondLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(LgxSustainableBond).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(LgxSustainableBond)[index] : value;
	}catch (e){
		console.error("Failed to get LgxSustainableBond label for '" + value + "'", e);
	}
		
	return value;
}