import { breakpoint, breakpointMax } from '../style';

import { css } from '@emotion/react';

export const sharedStyle = {
  fullWidth: css`
    width: 100%;

    div {
      width: 100%;
    }
  `,
  chartContainer: css`
    display: flex;
    width: 100%;
    justify-content: space-around;
  `,
  legendContainer: css`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 16px;
  `,
  legendCol: css`
    display: flex;
    flex-direction: column;
  `,
  legendRow: css`
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    color: #354450;
  `,
  totalContainer: css`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
    font-weight: 800;
    line-height: 26px;
    color: #354450;
    font-size: 16px;
  `,
  totalContainerRight: css`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
    font-weight: 800;
    line-height: 26px;
    color: #354450;
    font-size: 16px;
  `,
  bullet: (color: string) => css`
    display: flex;
    background-color: ${color};
    height: 16px;
    width: 16px;
    border-radius: 16px;
    margin-right: 6px;
  `,
  colflex1: css`
    flex: 1;
    min-height: 477px;
    //overflow: hidden;
    padding: 0px 8px;
    max-width: 100%;
    @media (max-width: ${breakpointMax}) {
      min-width: 100%;
      margin-bottom: 16px;
    }
  `,
  equitiesColflex1: css`
    flex: 2;
    min-height: 477px;
    //overflow: hidden;
    padding: 0px 8px;
    max-width: 100%;
    @media (max-width: ${breakpointMax}) {
      min-width: 100%;
      margin-bottom: 16px;
    }
  `,
  card: css`
    background: #ffffff;
    border: solid 1px #e3e6e8;
    border-radius: 8px;
    min-height: 100%;
    width: 100%;
    box-shadow: 0 8px 16px rgb(164 188 194 / 31%);
    position: relative;
    display: flex;
    //padding-bottom: 16px;
  `,
  cardContainer: css`
    display: flex;
    flex-direction: column;
    padding: 25px;
    padding-bottom: 5px;
    width: 100%;
  `,
  cardContainerBody: css`
    font-size: 13px;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100px;
    height: 100%;
  `,
  tabMobile: css`
    display: none;
    @media (max-width: ${breakpointMax}) {
      display: flex;
      position: absolute;
      right: 0;
      height: 100px;
      width: 100px;
      opacity: 0.2;
      pointer-events: none;
      background: linear-gradient(270deg, rgba(37, 54, 69, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
    }
  `,
  iconSelected: css`
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 48px;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  tab: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    line-height: 20px;
    @media (max-width: ${breakpointMax}) {
      min-width: 40%;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  `,
  titleSelected: css`
    width: 100%;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${breakpointMax}) {
      min-width: 40%;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.01em;
    }
  `,
  icon: css`
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    margin-bottom: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: ${breakpointMax}) {
      height: 32px;
      width: 32px;
    }
  `,
  title: css`
    width: 100%;
    color: #354450;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #d9e1e2;
    background-color: #ffffff;
    @media (max-width: ${breakpointMax}) {
      min-width: 40%;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.01em;
      margin-bottom: -13px;
    }
  `,
  tabHeader: css`
    width: 100%;
    display: flex;
    padding-top: 48px;
    padding-bottom: 48px;
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row !important;
    @media (max-width: ${breakpointMax}) {
      padding-bottom: 20px;
      overflow: auto;
    }
  `,
  row: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 32px;

    &:last-of-type {
      padding-bottom: 32px;
    }

    & > div {
      margin-right: 16px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    @media (max-width: ${breakpointMax}) {
      flex-wrap: wrap;
    }
  `,
  rowLabel: css`
    font-weight: 800;
    font-size: 32px;
    line-height: 41px;
    text-align: center;
    color: #253845;
    margin-bottom: 32px;
    margin-top: 32px;
  `,
  col2: css`
    width: 60%;
  `,
  col05: css`
    width: 40%;
  `,
  col1: css`
    width: 50%;
  `,
  statistics_st: css`
    width: inherit;
    margin-left: 265px;
    margin-top: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  `,
  display_barre: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 0px;
    gap: 24px;
    position: inherit;
    width: 1591px;
    height: 84px;
    left: -17px;
    top: 585px;
    background: #425563;
  `,
  next_page: css`
    display: inline-block;
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #354450;
  `,
  current_page: css`
    margin-bottom: 12px;
    margin-top: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #22AA5F;
  `,
  chevron_right: css`
    position: relative;
    margin-top: 16.5px;
    margin-left: 10px;
    left: 0%;
    right: 25%;
    top: 0%;
    bottom: 0%;
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
    line-height: 9px;
    color: #a4bcc2;
  `,
  home_icon: css`
    width: 28px;
    height: 14px;
    margin-left: 80px;
    margin-top: 14px;
    margin-bottom: 14px;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    color: #22AA5F;
  `,
  home_barre: css`
    box-sizing: border-box;
    position: relative;
    display: flex;
    width: 2218px;
    height: 42px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    border: 1px solid #e3e6e8;
  `,
  link_data_market: css`
    text-decoration: none;
    color: #22AA5F;
    margin-top: 17px;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
  `,
  link_container: css`
    text-align: center;
  `,
  equities_card: css`
    margin-top: 115px;
    margin-left: 32px;
    background: #ffffff;
    border: solid 1px #e3e6e8;
    border-radius: 8px;
    height: 100%;
    box-shadow: 0 8px 16px rgb(164 188 194 / 31%);
  `,
  generic_card: css`
    background: #ffffff;
    margin-top: 115px;
    margin-left: 16px;
    border: solid 1px #e3e6e8;
    border-radius: 8px;
    height: 100%;
    width: 503px;
    box-shadow: 0 8px 16px rgb(164 188 194 / 31%);
  `,
  search: css`
    //display: flex;
    //flex-direction: column;
    //height: 100%;
    //background-color: #f4f6f7;
  `,
  search2: css`
    margin-left: 32px !important;
    margin-top: 115px !important;
    margin-right: 532px;
  `,
  results: css`
    display: flex;
    flex-direction: row;
    width: 100%;
  `,
  gradientTop: css`
    width: 100%;
    height: 8px;
    background: linear-gradient(180deg, #f1f1f1, rgba(217, 225, 226, 0));
  `,
  gradientBottom: css`
    width: 100%;
    height: 8px;
    background: linear-gradient(-180deg, #f1f1f1, rgba(217, 225, 226, 0));
  `,

  tdHeight: css`
    height: 56px;
  `,

  securityTh: css`
    width: 30%;
  `,
  listingDateTh: css`
    width: 20%;
  `,
  bidTh: css`
    width: 15%;
  `,
  askTh: css`
    width: 15%;
  `,
  lastTh: css`
    width: 15%;

    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      color: #ca1234;
    }
  `,

  greyContainer: css`
    position: absolute;
    z-index: -1;
    margin-top: 56px;
    width: 100%;
    height: calc(100% - 215px);
    background: #f6f9f9;
    @media (max-width: ${breakpointMax}) {
      margin-top: 0px;
      height: 100%;
    }
  `,
  orderTd: css`
    font-weight: 700;
    font-size: 13px;
    line-height: 150%;

    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 17px;
      color: #54666f;
    }
  `,

  securityTd: css`
    width: 302px;
    height: 20px;
    //span {
    //  color: #54666F;
    //  font-weight: 400;
    //  font-size: 13px;
    //  margin-top: 8px;
    //  width: 118px;
    //  height: 17px;
    //  line-height: 17px;
    //}
  `,
  listingDateTd: css`
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
  `,
  cardContainerBodyTag: css`
    font-size: 13px;
    display: flex;
    justify-content: center;
  `,
  cardContainerBodyLabel: css`
    font-size: 24px;
    color: #354450;
    font-weight: 800;
    text-align: center;
    margin-bottom: 8px;
  `,
  cardContainerBodyRate: css`
    font-size: 48px;
    color: #22AA5F;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.15);
    text-align: center;
  `,
  cardContainerBodyIsin: css`
    font-size: 13px;
    font-weight: 600;
    color: #425563;
    text-align: center;
    margin-bottom: 32px;
  `,
  cardContainerBodyText: css`
    font-size: 13px;
    padding-left: 38px;
    color: #354450;
    line-height: 17px;
    font-weight: 600;
    margin-bottom: 32px;
  `,
  cardContainerBodyTags: css`
    font-size: 13px;
    display: flex;
    justify-content: center;

    > div {
      margin-left: 8px;
      margin-right: 8px;
    }
  `,
  cardContainerFooter: css`
    color: #22AA5F;
    font-weight: 600;
    font-size: 13px;
    width: 100%;
    display: flex;
    padding-top: 2em;
    padding-bottom: 2em;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 8px;
    }
    border-radius: 0.5em;
    cursor: pointer;
    &:hover {
      background: #f6f8f9;
      transition: all 0.1s ease-in-out;
    }
  `,
  cardContainerTitle: css`
    img {
      width: 100%;
      max-width: 140px;
    }
    svg {
      color: #22AA5F;
      font-size: 20px;
      margin-right: 12px;
    }
    @media (max-width: ${breakpointMax}) {
      margin-top: 14px;
      margin-left: 12px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    line-height: 26px;
    color: #354450;
  `,
  cardContainerTitle2: css`
    img {
      width: 100%;
      max-width: 140px;
    }
    svg {
      color: #22AA5F;
      font-size: 20px;
      font-weight: 400;
      margin-right: 12px;
    }
    @media (max-width: ${breakpointMax}) {
      margin-top: 14px;
      margin-left: 12px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    line-height: 26px;
    font-size: 20px;
    color: #008c46;
  `,
  cardContainerActions: css`
    font-size: 14px;
    font-weight: 600 !important;
    color: #54666f;

    @media (max-width: ${breakpointMax}) {
      margin-top: 18px;
      width: 100%;
    }
    > div > div {
      padding-left: 16px;
      padding-right: 16px;
    }
  `,
  topContainer: css`
    width: 100%;
    position: relative;
  `,
  table: css`
    width: 100%;
    height: 100%;
    border: none;
    border-collapse: collapse !important;
    color: #54666f;
    font-weight: 600;
    table {
      width: 100%;
    }

    td,
    th {
      vertical-align: middle;
      /*
      background: "#F8FAFC",
      color: "#253645",
      fontSize: "0.8rem",
      textAlign: "left",
       */
    }
    td {
      min-height: 64px !important;
      height: auto !important;
      font-size: 13px;
    }
    > thead > th:first-of-type {
      min-width: 160px;
      @media (max-width: ${breakpointMax}) {
        min-width: 0;
        width: 100%;
      }
    }
    th {
      text-align: left;
      display: flex;
      align-items: center;
      color: #354450;
      font-weight: 800;
      font-size: 13px;
      @media (max-width: ${breakpointMax}) {
        font-weight: 800;
        font-size: 13px;
        line-height: 100%;
        color: #354450;
        width: 100%;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 8px;
        background: #e8ecee;
      }
    }
    tbody {
      max-height: 336px;
      //width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
    thead {
      width: 100%;
      display: flex;
      @media (max-width: ${breakpointMax}) {
        width: 100%;
      }
    }
    tbody {
      display: block;
    }
    tr {
      width: 100%;
      display: flex;
      border-bottom: solid 1px #d9dee2 !important;
    }
    tr:last-of-type {
      border-bottom: none !important;
    }
    tr td:first-of-type {
      padding-left: 0;
    }
    thead th {
      text-align: left;
    }
    th {
      height: auto;
      margin-bottom: 8px;
      padding: 1rem !important;
      font-size: 0.8rem;
    }
    td {
      display: flex;
      align-items: center;
      padding: 1rem !important;
      font-size: 0.8rem;
    }
  `,
  tableNewSecurity: css`
    width: 65%;
    height: 56px !important;
    @media (max-width: ${breakpointMax}) {
      width: 50%;
    }
  `,
  status: css`
    width: 35%;
    height: 56px !important;
  `,
  tableRow: css`
    border-bottom: 1px solid #e3e6e8;
    color: black;
    &:last-of-type {
      border-bottom: none;
    }
    td {
      border-bottom: solid 1px #d9dee2;
      font-size: 0.8rem;
      padding: 1rem;
    }
  `,
  tableRowNoClickable: css`
    cursor: default !important;
  `,
  tableNewSecurityTh: css`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
    width: 65%;
    @media (max-width: ${breakpointMax}) {
      width: 50%;
    }
  `,
  statusTh: css`
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 35%;
  `,
  bold: css`
    font-weight: bold;
  `,
  tableSecurity: css`
    width: 40%;
    font-weight: bold;
    @media (max-width: ${breakpointMax}) {
      width: 50%;
    }
  `,
  tableDate: css`
    color: #253845;
    width: 20%;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  tableYield: css`
    color: #253845;
    width: 18%;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  tablePrice: css`
    color: #253845;
    width: 25%;
    @media (max-width: ${breakpointMax}) {
      width: 50%;
    }
  `,
  container: css`
    max-width: 1280px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin: auto;
    @media (max-width: ${breakpointMax}) {
      padding-left: 16px;
      padding-right: 16px;
      > div > div > div:first-of-type {
        margin-top: 48px;
      }
      > div > div > div {
        margin-top: 16px;
      }
    }
  `,
  rowBond: css`
    display: flex;
    align-items: center;
    color: #253845;
    > div {
      margin-right: 12px;
    }
  `,
  hideMobile: css`
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  hideDesktop: css`
    @media (min-width: ${breakpoint}) {
      display: none;
    }
    width: 100%;
    font-size: 16px;
    button {
      width: 100%;
    }
  `,
  double: css`
    display: flex;
    flex-direction: column;
  `,
  doubleTop: css``,
  doubleBottom: css`
    color: #22AA5F;
    margin-top: 4px;
  `,
  cardContainerTop: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    font-size: 20px;
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  `,
  cardContainerBodyTop: css``,
  cardContainerBodyBottom: css`
    text-align: right;
  `,
  cardContainerBodyDoubleContainer: css`
    display: flex;
  `,
  cardContainerBodyDoubleLeft: css`
    display: flex;
    align-items: center;
    margin-right: 12px;
    @media (max-width: ${breakpointMax}) {
      margin-right: 0;
    }
  `,
  cardContainerBodyDoubleRight: css`
    //padding-top: 8px;
    //padding-bottom: 8px;
    > p:first-of-type {
      margin-bottom: 4px !important;
    }
    p:last-of-type {
      margin-top: 0px !important;
    }
  `,
  cardContainerBodyDouble: css`
    display: flex;
    flex-direction: column;
    color: #54666f;
    font-weight: 600;
    font-size: 13px;

    span {
      color: #425563;
      font-weight: 600;
      margin-top: 6px;
      font-size: 13px;
    }
  `,
  span: css`
    color: #354450;
    font-weight: 600;
    font-size: 13px;
    margin-top: 8px;
  `,
  buttons: css`
    display: flex;
    justify-content: center;
    margin-top: 64px;
    button {
      margin-left: 12px;
      margin-right: 12px;
      font-size: 16px;
      font-weight: 600;
    }
    @media (max-width: ${breakpointMax}) {
      flex-direction: column;
      button {
        margin-bottom: 16px;
      }
    }
  `,
  rowNoClickable: css`
    cursor: default !important;
    background-color: #E8ECEE !important;
    &:hover {
      background-color: #E8ECEE;
    }
  `,
};
