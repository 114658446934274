
import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import hexas from '../HomeInformations/hexas.png';
import hexasBg from './hexas.svg';
import hexa2 from '../HomeInformations/hexaBold2.png';
import hexa3 from '../HomeInformations/hexaBold3.png';
import {
    Button
} from "@bdl-cmn-shared-packages-npm/design-system";
import { SitecoreGenericTextField } from '../../interfaces';
import {renderLink} from "../../utils/helper";

export interface HomeInformationsComponentProps {
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const style = {
    container: css`
      max-width: 1280px;
      padding-left: 40px;
      padding-right: 40px;
      width: 100%;
      margin: auto;
      display: flex;
      margin-top: 80px;
    `,
    left: css`
      display: flex;
      flex-direction: column;
      width: 50%;
    `,
    containerHexa1: css`
      display: flex;
      justify-content: flex-end;
      width: 100%;
      max-width: 820px;
    `,

    containerHexa2: css`
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-left: -64px;
      margin-top: -45px;
      max-width: 240px;
    `,
    containerHexa3: css`
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;
      margin-top: -45px;
      max-width: 100px;
    `,
    flex: css`
      display: flex;
      width: 100%;
      justify-content: space-between;
    `,
    img1: css`
      width: 100%;
      //max-width: 500px;
    `,
    img2: css`
      //margin-top: -32px;
      width: 100%;
      //max-width: 270px;
    `,
    img3: css`
      //margin-top: -58%;
      width: 100%;
      //max-width: 130px;
    `,
    right: css`
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 32px;
      background: url(${renderLink(hexasBg)});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    `,
    headerTitle: css`
      font-size: 16px;
      font-weight: 600;
      color: #253645;
    `,
    title: css`
      font-size: 24px;
      font-weight: 600;
      color: #253645;
      margin-top: 16px;
      margin-bottom: 16px;
    `,
    text: css`
      font-weight: 600;
      font-size: 13px;
    `,
    action: css`
        margin-top: 32px;
    `,
}

export const HomeInformationsComponent: React.FunctionComponent<HomeInformationsComponentProps> = (props: HomeInformationsComponentProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    return (
        <div css={style.container}>
            <div css={style.left}>
                <div css={style.containerHexa1}>
                    <img src={renderLink(hexas)} css={style.img1} alt={"image"}/>
                </div>
            </div>
            <div css={style.right}>
                <div css={style.headerTitle}>
                    <Text field={fields && fields.headerTitle as SitecoreGenericTextField} />
                </div>
                <div css={style.title}>
                    <Text field={fields && fields.title as SitecoreGenericTextField} />
                </div>
                <div css={style.text}>
                    <Text field={fields && fields.text as SitecoreGenericTextField} />
                </div>
                <div css={style.action}>
                    <Button label={'Discover all securities'} iconRight={'chevron-right'}/>
                </div>
            </div>
        </div>
    );
}
