export enum DocumentDateEnum {
	ONE_DAY_AGO = 'One day ago',
	ONE_WEEK_AGO = 'One week ago',
	TWO_WEEKS_AGO = 'Two weeks ago',
	MORE_THAN_TWO_WEEKS = 'More than two weeks'
}

export function findDocumentDateByName(name: string): DocumentDateEnum {
	if( name == null || name === "" || ! name.length )
		return null;
	try {
		return name as DocumentDateEnum;
	}catch (e){
		console.error("Failed to get a DocumentDateEnum with '" + name + "'", e);
	}
	return null;
}

export function documentDateLabel(value: string): string {
	if( value == null || value === "" || ! value.length )
        return "";
	try {
		let index = Object.keys(DocumentDateEnum).indexOf(value.toString().toUpperCase());
  		return index >= 0 ? Object.values(DocumentDateEnum)[index] : value;
	}catch (e){
		console.error("Failed to get DocumentDateEnum label for '" + value + "'", e);
	}
		
	return value;
}