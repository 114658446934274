/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import {
  Text,
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  getFieldValue,
  Link,
} from "@sitecore-jss/sitecore-jss-react";

import { SitecoreGenericTextField } from "../../interfaces";
import { renderLink } from "../../utils/helper";

export interface BlogImageWithLinkProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const container = css`
  width: 100%;
`;
const imageContainer = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const imageItem = css`
  max-width: 100%;
  border-radius: 16px;
`;
export const BlogImageWithLink: React.FunctionComponent<BlogImageWithLinkProps> =
  (props: BlogImageWithLinkProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const image: any = getFieldValue<any>(fields, "image", null);

    return (
      <div css={container}>
        <Link
          target={
            fields["image link"]?.value?.linktype === "external"
              ? "_blank"
              : "_self"
          }
          title={fields["button label"]}
          field={fields["image link"]}
          css={{ textDecoration: "none" }}
        >
          <div css={imageContainer}>
            <img css={imageItem} src={renderLink(image?.src)} />
          </div>
        </Link>
      </div>
    );
  };
