import React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Props from "../../UI/list/base/props";
import {Td, Tr} from "@bdl-cmn-shared-packages-npm/design-system";
import {marketNewsStyle} from "./index.style";
import {formatDate} from "../../../../utils/date";
import { formatToThousandsSeparator } from "../../../../utils";
import { goToSecurityDetailPage, isSecurityClickable } from "../../SecurityCard";
import { documentTypeLabel } from "../../../../enums/document-type";
import { MarketNewsDetailsModel } from ".";

const MarketNewsGenericRow = ({row}: Props<MarketNewsDetailsModel>) => {

    if( ! row ) return <></>;

    let clickable = isSecurityClickable(row?.status);
    let categories : String[] = [];
    if( row.properties?.categories ){
        row.properties.categories.forEach( (category: string) => {
            let categoryLabel = documentTypeLabel( category );
            if( categoryLabel && ! categories.includes(categoryLabel))
                categories.push(categoryLabel);
        });
    }

    return (
        <Tr onRowClick={() => {
            if( clickable )
                goToSecurityDetailPage(row?.isinCode, row?.id)
        }}
            css={!clickable && marketNewsStyle.rowNoClickable}
        >
            <Td>
                { row.label ?
                    <div css={marketNewsStyle.status}>
                        <div css={marketNewsStyle.tag}>{ row.label}</div>
                    </div>
                    : <></>
                }
                { categories ?
                    <div>
                        { categories.map(category =>
                            <div css={marketNewsStyle.tag} key={`generic-${row.id}-category-${category}`}>{category}</div>
                        )}
                    </div>
                    :<></>
                }
                <div css={marketNewsStyle.title1}>{row?.name}</div>
                <div css={marketNewsStyle.title3}>
                    { row?.isinCode} 
                    { row.amount ?
                        <>
                            {` | ${row?.currency} ${formatToThousandsSeparator(row?.amount)}`}
                        </>
                        :<></>
                    }
                </div>
            </Td>
            <Td>
                <div css={marketNewsStyle.statusRow2}>{row.statusLabel}</div>
                <div css={marketNewsStyle.dateRow2}>{formatDate(row?.date, "DD/MM/YYYY")}</div>
            </Td>
            <Td></Td>
        </Tr>
    );
};

export default MarketNewsGenericRow;