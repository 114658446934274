/** @jsx jsx */
/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import { Icon, Table, Tbody, Td, Th, Thead, Tr } from "@bdl-cmn-shared-packages-npm/design-system";
import { css, jsx } from "@emotion/react";
import { genericTradingDataStyle } from "../../DataMarket/TradingData/Shared/generic-trading-data.style";
import { sharedStyle } from "../../../../styles/shared";
import { style } from "../market-statistics.style";
import WidgetCard from "../../WidgetCard";
import axios from "axios";
import MarketCapDataModel from "../Interfaces/MarketCapModel";
import { tradingStyle } from "../../DataMarket/MarketData/trading-data.style";
import MarketCapBarChart from "../Charts/MarketCapBarChart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpointMarketCapMax, breakpointMax } from "../../../../style";
import GenericLink from "../../DataMarket/TradingData/Shared/generic-link.component";
import { BrowserRouter } from "react-router-dom";
import LuxSharesCard from "../Cards/LuxSharesCard";
import RisersAndFallersComponent from "../Base/RisersAndFallersComponent";
import RisersAndFallersChart from "../Charts/RisersAndFallersChart";
import { RisersAndFallersDataModel } from "../View/risers-fallers-table";
import { GET_RISERS_FALLERS_STATISTICS } from "../../../../graphql/queries/market.queries";
import { luxseGatewayGraphqlClient } from "../../../../luxse-gateway-graphql-client";
import Loader from "../../Loader/loader.component";


export class TopMarketCapModel {
    topLuxSharesCap?: MarketCapDataModel[];
    topIssuedShares?: MarketCapDataModel[];
    topLuxxComponent?: MarketCapDataModel[];
}
export class Security {
    id?:string;
    name?: string;
    isinCode?: string;
    variation?: number;

    constructor(id:string,name: string, isinCode: string, variation: number) {
        this.id=id;
        this.name = name;
        this.isinCode = isinCode;
        this.variation = variation;
    }
}

export function RisersAndFallersHome(props: any) {


    let [risersAndFallers, setRisersAndFallers] = useState<RisersAndFallersDataModel[]>();
    const client = luxseGatewayGraphqlClient;
    const [loading, setLoading] = useState<boolean>(true);

    const [riserMonth, setRiserMonth] = useState<any>();
    const [fallerMonth, setFallerMonth] = useState<any>();
    const [riserYear, setRiserYear] = useState<any>();
    const [fallerYear, setFallerYear] = useState<any>();

    const isDisplayed = props.isDisplayed;
    const from = props.from;

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];


    let year: string = new Date().getFullYear().toString();
    let mm=String(new Date().getMonth() + 1).padStart(2, '0');
    let month: string = monthNames[Number(mm)-1] + " " + year;

    let taille: number = 0;


    const fetchRisersFallersData = async () => {
        try {
            if (!loading)
                setLoading(true);
            setRisersAndFallers([]);
            const results: any = await client.query({
                query: GET_RISERS_FALLERS_STATISTICS,
            });

            risersAndFallers = results?.data?.risersAndFallers.sort((a: any, b: any) => b.mtdVariation - a.mtdVariation);

            setRisersAndFallers({ ...risersAndFallers });

            setRiserMonth({
                id: risersAndFallers[0].id,
                name: risersAndFallers[0].description,
                isinCode: risersAndFallers[0].isinCode,
                variation: Number(Number(risersAndFallers[0].mtdVariation).toFixed(2))
            });
            taille = risersAndFallers.length;
            setFallerMonth({
                id:risersAndFallers[taille - 1].id,
                name: risersAndFallers[taille - 1].description,
                isinCode: risersAndFallers[taille - 1].isinCode,
                variation: Number(Number(risersAndFallers[taille - 1].mtdVariation).toFixed(2))

            });
            //month=risersAndFallers[0].;

            risersAndFallers = results?.data?.risersAndFallers.sort((a: any, b: any) => b.ytdVariation - a.ytdVariation);
            setRisersAndFallers({ ...risersAndFallers });
            setRiserYear({
                id: risersAndFallers[0].id,
                name: risersAndFallers[0].description,
                isinCode: risersAndFallers[0].isinCode,
                variation: Number(Number(risersAndFallers[0].ytdVariation).toFixed(2))
            });
            taille = risersAndFallers.length;
            setFallerYear({
                id: risersAndFallers[taille - 1].id,
                name: risersAndFallers[taille - 1].description,
                isinCode: risersAndFallers[taille - 1].isinCode,
                variation: Number(Number(risersAndFallers[taille - 1].ytdVariation).toFixed(2))
            });
            //year=risersAndFallers[0].;



            setLoading(false);
        } catch (e) {
            console.error('Failed to get risers and fallers data', e);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchRisersFallersData();
    }, []);



    return (
        <>
            {isDisplayed ?
                <div css={[style.marketCapContent,from==="top-traded-securities"?css`width:100%;background: var(--ligt-grey, #F6F8F9);margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0;`:'']}>
                    <div css={[sharedStyle.row]}>
                        <WidgetCard
                            loader={loading}
                            icon='trophy'
                            iconType={'fas'}
                            label='Risers and fallers'
                            isHover={false}
                            link={
                                <div css={sharedStyle.link_container}>
                                    <BrowserRouter forceRefresh={true}>
                                        <a css={sharedStyle.link_data_market} href={'/market-overview/market-statistics/risers-and-fallers'}
                                        >
                                            Consult last risers and fallers statistics &nbsp;
                                            <Icon icon={'chevron-right'} />
                                        </a>
                                    </BrowserRouter>
                                </div>
                            }
                        >
                            {!loading ?
                                <RisersAndFallersComponent
                                    type="Monthly"
                                    riser={riserMonth}
                                    faller={fallerMonth}
                                    date={month}
                                    title="Top risers and fallers in "
                                />
                                : <></>}


                        </WidgetCard>
                        <WidgetCard
                            loader={loading}
                            icon='trophy'
                            iconType={'fas'}
                            label='Risers and fallers'
                            isHover={false}
                            link={
                                <div css={sharedStyle.link_container}>
                                    <BrowserRouter forceRefresh={true}>
                                        <a css={sharedStyle.link_data_market} href={'/market-overview/market-statistics/risers-and-fallers'}
                                        >
                                            Consult last risers and fallers statistics &nbsp;
                                            <Icon icon={'chevron-right'} />
                                        </a>
                                    </BrowserRouter>
                                </div>
                            }
                        >
                            {!loading ?
                                <RisersAndFallersComponent
                                    type="Yearly"
                                    riser={riserYear}
                                    faller={fallerYear}
                                    date={year}
                                    title="Top risers and fallers in "
                                />
                                :
                                <></>}


                        </WidgetCard>

                    </div>
                </div>
                :
                <WidgetCard
                    loader={loading}
                    icon='trophy'
                    iconType={'fas'}
                    label='Risers and fallers'
                    isHover={false}
                    link={
                        <div css={sharedStyle.link_container}>
                            <BrowserRouter forceRefresh={true}>
                                <a css={sharedStyle.link_data_market} href={'/market-overview/market-statistics/risers-and-fallers'}
                                >
                                    Consult last risers and fallers statistics &nbsp;
                                    <Icon icon={'chevron-right'} />
                                </a>
                            </BrowserRouter>
                        </div>
                    }
                >
                    {!loading ?
                        <RisersAndFallersComponent
                            type="Monthly"
                            riser={riserMonth}
                            faller={fallerMonth}
                            date={month}
                            title="Top risers and fallers in "
                        />
                        : <></>}

                </WidgetCard>
            }
        </>
    );
}

export default RisersAndFallersHome;
