import React, { useState, useEffect } from "react";

/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Tab, Tabs } from "@bdl-cmn-shared-packages-npm/design-system";
import { style } from "./index.style";
import Loader from "../../../core/components/Loader/loader.component";
import { luxseGatewayGraphqlClient } from "../../../luxse-gateway-graphql-client";
import ProgrammeBanner from "./banner/banner.component";
import ProgrammeOverview from "./overview/overview.component";
import ProgrammeSecurities from "./securities/securities.component";
import { canUseDOM } from "../../../predicates";
import { withRouter } from "react-router";
import ProgrammeDocuments from "./documents/index.component";
import SearchFilterParams from "../../../interfaces/search/search-filter-params";
import { goToNotFoundPage, NotFound } from "../404";
import { ProgrammeMeta } from "./meta";
import {
  GET_PROGRAMME_DETAIL,
  GET_PROGRAMME_NOTICES,
  GET_PROGRAMME_PROSPECTUSES,
  ProgrammeDetail,
} from "../../../graphql/queries/programmes.queries";
import {
  DocumentSearchResultDto,
  SecuritySearchResultDto,
} from "../../../interfaces/search/search";
import { GET_SEARCH_SECURITIES_ONLY_RESULTS } from "../../../graphql";
import { SearchInputContent } from "../Search/input";
import Utils from "../../utils/utils";

export enum ProgrammeCardTab {
  OVERVIEW = "OVERVIEW",
  SECURITIES = "SECURITIES",
  PROSPECTUS = "PROSPECTUS",
  NOTICES = "NOTICES",
  DOCUMENTS = "DOCUMENTS",
}

export interface ProgrammeCardProps {
  fields?: any;
  match?: any;
  id: string;
}

export const programmeDetailPageLink = (name: string, id: string) => {
  return `/programme/${name}/${id}`;
};

export const goToProgrammeDetailPage = (name: string, id: string) => {
  if (canUseDOM) window.location.href = programmeDetailPageLink(name, id);
};

export const ProgrammeCardContent: React.FunctionComponent<any> = ({
  id,
}: ProgrammeCardProps): React.ReactElement => {
  const [programme, setProgramme] = useState<ProgrammeDetail>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedTab, setSelectedTab] = useState<ProgrammeCardTab>(
    ProgrammeCardTab.OVERVIEW
  );

  const [notices, setNotices] = useState<DocumentSearchResultDto>(null);
  const [loadingNotices, setLoadingNotices] = useState<boolean>(true);

  const [prospectuses, setProspectuses] =
    useState<DocumentSearchResultDto>(null);
  const [loadingProspectuses, setLoadingProspectuses] = useState<boolean>(true);

  const [securities, setSecurities] = useState<SecuritySearchResultDto>(null);
  const [loadingSecurities, setLoadingSecurities] = useState<boolean>(true);

  const defaultDocumentFilters: SearchFilterParams = {
    documentTypes: [],
    documentSubTypes: [],
    excludeDocumentTypes: [],
    excludeDocumentSubTypes: ["D318"],
  };
  const [documentFilters, setDocumentFilters] =
    React.useState<SearchFilterParams>(defaultDocumentFilters);
  const [textInput, setTextInput] = React.useState<string>("");

  const client = luxseGatewayGraphqlClient;

  const setDocumentNoticeFilters = () => {
    const filters: SearchFilterParams = defaultDocumentFilters;
    filters.documentTypes = ["FNS"];

    setDocumentFilters(filters);
  };

  const setDocumentProspectusFilters = () => {
    const filters: SearchFilterParams = defaultDocumentFilters;
    filters.documentSubTypes = ["D060"];

    setDocumentFilters(filters);
  };

  const getProgramme = async () => {
    let programme = null;
    try {
      if (!loading) setLoading(true);

      const results: any = await client.query({
        query: GET_PROGRAMME_DETAIL,
        variables: {
          programmeId: id,
          token: Utils.getCookie("Gztoken-Dsm"),
        },
      });

      programme = results?.data?.programme || null;
    } catch (e) {
      console.error("Failed to get programme '" + id + "'", e);
      setProgramme(null);
      goToNotFoundPage();
    }

    if (!programme) goToNotFoundPage();

    setProgramme(programme);
    setLoading(false);

    getNotices();
    getProspectuses();
    getSecurities();
  };

  const getNotices = async () => {
    let noticesTmp = null;
    try {
      setLoadingNotices(true);

      const results: any = await client.query({
        query: GET_PROGRAMME_NOTICES,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          programmeId: id,
          limit: 5,
          offset: 0,
        },
      });

      noticesTmp = results?.data?.programmeNotices || null;
    } catch (e) {
      console.error("Failed to get programme notices of '" + id + "'", e);
      setNotices(null);
    }

    setNotices(noticesTmp);
    setLoadingNotices(false);
  };

  const getProspectuses = async () => {
    let prospectusesTmp = null;
    try {
      setLoadingProspectuses(true);

      const results: any = await client.query({
        query: GET_PROGRAMME_PROSPECTUSES,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          programmeId: id,
          limit: 1,
          offset: 0,
        },
      });

      prospectusesTmp = results?.data?.programmeProspectuses || null;
    } catch (e) {
      console.error("Failed to get programme prospectuses of '" + id + "'", e);
      setNotices(null);
    }

    setProspectuses(prospectusesTmp);
    setLoadingProspectuses(false);
  };

  const getSecurities = async () => {
    let securitiesTmp = null;
    try {
      setLoadingSecurities(true);

      const results: any = await client.query({
        query: GET_SEARCH_SECURITIES_ONLY_RESULTS,
        variables: {
          token: Utils.getCookie("Gztoken-Dsm"),
          programmeId: id,
          page: 0,
          size: 5,
          searchTerm: "",
          sort: "listingDate,DESC",
          securityStatuses: ["ADMI", "COTE", "NEGO"],
          securityStatusesOperator: "OR",
        },
      });

      securitiesTmp = results?.data?.luxseSecuritiesSearch || null;
    } catch (e) {
      console.error("Failed to get securities", e);
    }

    setLoadingSecurities(false);
    setSecurities(securitiesTmp);
  };

  useEffect(() => {
    setLoading(true);
    getProgramme();
  }, []);

  return (
    <>
      <SearchInputContent
        onSearchInputUpdate={textInput}
        dataType="Programme"
      />
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <div css={loading ? style.pageLoading : {}}>
            <Loader loading={loading} maxHeight={"100px"}>
              {programme ? (
                <>
                  <ProgrammeMeta programme={programme} />
                  <ProgrammeBanner programme={programme} />
                  <div css={style.tabsContainer}>
                    <Tabs tabPosition={"center"} padding={"1em 2em"}>
                      <Tab
                        label="Overview"
                        onTabChange={() =>
                          setSelectedTab(ProgrammeCardTab.OVERVIEW)
                        }
                        selected={selectedTab === ProgrammeCardTab.OVERVIEW}
                      >
                        <div css={style.tabContent}>
                          <ProgrammeOverview
                            programme={programme}
                            onTabChange={setSelectedTab}
                            onNoticeDocument={setDocumentNoticeFilters}
                            notices={notices}
                            loadingNotices={loadingNotices}
                            onProspectusDocument={setDocumentProspectusFilters}
                            prospectuses={prospectuses}
                            loadingProspectuses={loadingProspectuses}
                            securities={securities}
                            loadingSecurities={loadingSecurities}
                          />
                        </div>
                      </Tab>

                      <Tab
                        label="Documents"
                        onTabChange={() =>
                          setSelectedTab(ProgrammeCardTab.DOCUMENTS)
                        }
                        selected={selectedTab === ProgrammeCardTab.DOCUMENTS}
                      >
                        <div css={style.tabContentList}>
                          <ProgrammeDocuments
                            programmeData={programme}
                            filters={documentFilters}
                            onFiltersChange={setDocumentFilters}
                          />
                        </div>
                      </Tab>

                      <Tab
                        label="Securities"
                        onTabChange={() =>
                          setSelectedTab(ProgrammeCardTab.SECURITIES)
                        }
                        selected={selectedTab === ProgrammeCardTab.SECURITIES}
                      >
                        <div css={style.tabContentList}>
                          <ProgrammeSecurities programmeId={programme.id} />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </>
              ) : (
                <NotFound
                  title="404"
                  description={`Oups! Page not found`}
                  goBack={true}
                />
              )}
            </Loader>
          </div>
        </div>
      </div>
    </>
  );
};

export const ProgrammeCard = withRouter(ProgrammeCardContent);
