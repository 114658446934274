/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { 
    Text, 
    ComponentFields, 
    ComponentParams, 
    ComponentRendering, 
    getFieldValue, 
    RichText
} from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import { Button, Icon, InputField } from "@bdl-cmn-shared-packages-npm/design-system";
import { canUseDOM } from "../../predicates";
import hexa from './hexa.svg';
import { breakpointMax } from "../../style";
import { TextField } from "@sitecore-jss/sitecore-jss-react-forms";
import { FormBuilder } from "../../core/components/FormBuilder/formBuilder.component";

export interface SubscribeToNewsletterProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const style = {
parentContainer: css`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 48px;
    padding-bottom: 40px;
    `,
container: css`
    display: flex;
    width: 780px;
    padding: 40px;
    align-items: flex-start;
    gap: 48px;
    border-radius: 16px;
    border: 1px solid #005670;
    background: #E0F9FF;
  `,
  cardLeft: css`
    background-image: url(${hexa});
    background-position: center center;
    margin-right: 0px;
    height: 110px;
    width: 100px;
    align-items: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #005670;
    @media (max-width: ${breakpointMax}) {
      display: none;
    }
  `,
  cardRight: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    flex: 1 0 0;
  `,
  cardRightTitle: css`
    font-family: Mont;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #005670;
  `,
  cardRightDescription: css`
    font-family: Mont;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #005670;
    align-self: stretch;
  `,
  inputContainer: css`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    @media (max-width: ${breakpointMax}) {
        flex-direction: column;
        width: 100%;
        gap: 16px;
        //margin-bottom: 40px;
    }
  `,

//   inputField: css`
//     display: flex;
//     padding: 12px;
//     align-items: center;
//     align-self: stretch;
//   `,
  cardRightButton: css`
    display: flex;
    justify-content: center;
  `,

};


export const SubscribeToNewsletter: React.FunctionComponent<SubscribeToNewsletterProps> = (props: SubscribeToNewsletterProps): React.ReactElement => {
    const { fields, params, rendering } = props;
    const [visibleModal, setVisibleModal] = React.useState<boolean>(false);

    const icon: string = getFieldValue<string>(fields, 'icon', '');
    const buttonIcon: string = getFieldValue<string>(fields, 'buttonIcon', '');
    const buttonLabel: string = getFieldValue<string>(fields, 'buttonLabel', '');
    // const buttonLink: any = getFieldValue<any>(fields, 'buttonLink', '');

    return (
        <div css={style.parentContainer}>
            {visibleModal && (
                <FormBuilder
                    onClickOutside={() => setVisibleModal(false)}
                    embedCode={fields?.formCode?.value}
                >
                    <div id="childrenPart">
                        {fields?.formCode?.value && (
                            <div
                                dangerouslySetInnerHTML={{ __html: fields?.formCode?.value }}
                            />
                        )}
                    </div>
                </FormBuilder>
            )}
            <div css={style.container}>
                <div css={style.cardLeft}>
                    <Icon icon={icon as any} />
                </div>
                <div css={style.cardRight}>
                    <div css={style.cardRightTitle}>
                        <Text
                            field={fields && (fields.title as SitecoreGenericTextField)}
                        />
                    </div>
                    <div css={style.cardRightDescription}>
                        <RichText
                            field={fields && (fields.description as SitecoreGenericTextField)}
                        />
                    </div>
                    <div css={style.inputContainer}>
                        {/* <div css={style.inputField}>
                            <InputField
                                placeholder="Enter email address"
                                textError=""
                                type="text"
                                width="100%"
                                // white="true"
                            />
                        </div> */}
                        <div css={style.cardRightButton}>
                            {fields?.formCode?.value && (
                                <Button
                                    label={buttonLabel}
                                    iconLeft={buttonIcon as any}
                                    onClick={() => setVisibleModal(true)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
