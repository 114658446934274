import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { marketRuleCheck, MarketRuleEnum, marketRuleLabel} from '../../../../enums';
import { Security } from '../../../../interfaces';
import { SecurityMarketDefault } from './market-default';
import { SecurityMarketSol } from './market-sol';
import EmptyResults from '../../UI/list/base/empty';

export interface SecurityMarketProps {
  securityData?: Security;
}

export const SecurityMarket: React.FunctionComponent<SecurityMarketProps> = 
  ({ securityData }: SecurityMarketProps) => {

  return (
    <>
      { marketRuleCheck( securityData?.marketRule?.toString(), MarketRuleEnum.SOL ) ?
        <>
          { ["COTE"].includes( securityData?.status?.toString() ) ?
            <SecurityMarketSol securityData={securityData}/>
            : 
            <EmptyResults label={"Not enough data"}/>
          }
        </> 
        :
        <> 
          { ["ADMI", "NEGO"].includes( securityData?.status?.toString() )?
            <SecurityMarketDefault securityData={securityData}/>
            : 
            <EmptyResults label={"Not enough data"}/>
          }
        </>
      }
    </>
  );
};
