
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';

export interface BlogRightColumnSectionProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

const container = css`
    width: 100%;
  `;

export const BlogRightColumnSection: React.FunctionComponent<BlogRightColumnSectionProps> = (props: BlogRightColumnSectionProps): React.ReactElement => {
    const { fields, params, rendering } = props;

    const headingValue: string = getFieldValue<string>(fields, 'heading', '');

    return (
        <div css={container}>
            <p>BlogRightColumnSection Component</p>
            <Text 
                field={fields && fields.heading as SitecoreGenericTextField} 
            />
            <span>Raw value: {headingValue}</span>
        </div>
    );
}
    