import * as React from "react";

import { css, jsx } from "@emotion/react";

import FilterButton from "../Search/results/searchMobile/filterButton";
import Footer from "../Footer/footer.component";
import Header from "../Header/header.component";
import Loader from "../Loader/loader.component";
import MobileFilterContainer from "../Search/results/searchMobile/mobileFilterContainer";
import MobileMenuContext from "../../hooks/menu-provider";
import OAMBanner from "./oam-banner";
import OAMColumnFilters from "./column-filters";
import OAMFilters from "./filters";
import { OAMSearchTable } from "./results";
import { genericStyle } from "../UI/generic/generic.style";
import { isCancelled } from "./results/utils";
import { style } from "./index.style";
import useFilters from "./useFilters";
import { withRouter } from "react-router-dom";
import { OAMSearchMeta } from "./meta";

/** @jsx jsx */

export const OAMSearchContent = () => {
  const {
    filters,
    onFilterChange,
    onReset,
    loading,
    search,
    results,
  } = useFilters();
  const mobileMenu = React.useContext(MobileMenuContext);

  const displayedDeposits = React.useMemo(() => {
    if (!results) {
      return null;
    }

    if (! filters?.hide_canceled) {
      return results.deposits || [];
    }

    return results.deposits?.filter((row) => !isCancelled(row)) || [];
  }, [results]);

  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);

  const switchFilter = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <div css={style.search}>
      <OAMSearchMeta/>
      
      {!menuIsOpen && (
        <>
          <Header onSwitchMenu={mobileMenu.actionDispatch} />
          <OAMFilters
            filters={filters}
            onFilterChange={onFilterChange}
            onSubmit={search}
          />
        </>
      )}

      <Loader loading={loading} padding="5rem">
        <>
          {!menuIsOpen && (
            <OAMBanner
              totalResultSize={displayedDeposits?.length || 0}
              filters={filters}
              onFilterChange={onFilterChange}
              onReset={onReset}
            />
          )}

          <div css={genericStyle.desktopDisplay}>
            <div css={style.results}>
              <OAMColumnFilters
                filters={filters}
                onFilterChange={onFilterChange}
              />
              <OAMSearchTable
                lang={filters.lang}
                loadingPage={loading}
                data={displayedDeposits}
                totalResultSize={displayedDeposits?.length || 0}
              />
            </div>
          </div>
          <div
            css={[
              genericStyle.mobileDisplay,
              css`
                margin-top: 20px;
                width: 100%;
                > div:first-of-type {
                  margin: 10px;
                }
              `,
            ]}
          >
            <FilterButton onSwitchFilter={switchFilter} open={menuIsOpen} />
            <MobileFilterContainer open={menuIsOpen}>
              <OAMColumnFilters
                filters={filters}
                onFilterChange={onFilterChange}
              />
            </MobileFilterContainer>
            <OAMSearchTable
              mobile
              lang={filters.lang}
              loadingPage={loading}
              data={displayedDeposits}
              totalResultSize={displayedDeposits?.length || 0}
            />
          </div>
        </>
      </Loader>

      <Footer />
    </div>
  );
};

export const OAMSearch = withRouter(OAMSearchContent);
