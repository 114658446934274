
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const SMR: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#FFFFFF" d="M512,256H0V97.103c0-4.875,3.953-8.828,8.828-8.828h494.345c4.875,0,8.828,3.953,8.828,8.828L512,256 L512,256z" />
            <path fill="#5EB6E4" d="M0,256h512v158.897c0,4.875-3.953,8.828-8.828,8.828H8.828c-4.875,0-8.828-3.953-8.828-8.828V256z" />
            <path fill="#B46446" d="M256.82,327.98c-1.495-0.065-36.791-1.87-54.505-26.14c-10.324-14.142-12.384-32.952-6.123-55.907 l2.529,0.69c-6.038,22.143-4.12,40.195,5.704,53.662c16.958,23.249,52.154,25.06,52.51,25.075L256.82,327.98z" />
            <g>
                <path fill="#658D5C" d="M196.167,235.756c-1.74,4.533-1.343,9.28,0.672,12.878c3.906-1.325,7.377-4.587,9.118-9.12 c1.74-4.533,1.343-9.28-0.672-12.878C201.378,227.961,197.907,231.222,196.167,235.756z" />
                <path fill="#658D5C" d="M218.275,298.424c-1.932,3.187-2.439,7.46-1.007,11.608c1.336,3.876,4.094,6.735,7.29,8.178 c-3.105-1.628-7.054-2.035-10.93-0.698c-4.148,1.43-7.2,4.463-8.52,7.948c3.187,1.931,7.459,2.439,11.608,1.007 c4.148-1.43,7.2-4.463,8.52-7.948l-0.004-0.002c1.916-3.183,2.418-7.439,0.992-11.573 C224.793,302.796,221.761,299.743,218.275,298.424z" />
                <path fill="#658D5C" d="M210.717,286.856c-2.98,2.237-5.044,6.013-5.263,10.395c-0.205,4.095,1.288,7.776,3.715,10.306 c-2.275-2.668-5.788-4.519-9.88-4.724c-4.382-0.22-8.345,1.457-10.869,4.199c2.237,2.98,6.013,5.044,10.394,5.263 c4.382,0.22,8.345-1.457,10.869-4.199c-0.001-0.001-0.002-0.002-0.002-0.002c2.963-2.241,5.015-6.003,5.235-10.37 C215.135,293.342,213.459,289.378,210.717,286.856z" />
                <path fill="#658D5C" d="M207.448,273.814c-3.604,0.947-6.941,3.665-8.797,7.639c-1.736,3.715-1.742,7.687-0.449,10.946 c-1.1-3.329-3.654-6.369-7.368-8.104c-3.976-1.857-8.278-1.801-11.649-0.214c0.947,3.604,3.665,6.941,7.639,8.797 c3.976,1.857,8.278,1.801,11.649,0.214c0,0-0.001-0.003-0.002-0.004c3.591-0.956,6.911-3.665,8.761-7.626 C209.091,281.49,209.036,277.185,207.448,273.814z" />
                <path fill="#658D5C" d="M209.302,260.872c-3.709-0.369-7.783,1.017-10.908,4.096c-2.921,2.878-4.312,6.599-4.235,10.104 c0.129-3.503-1.206-7.243-4.083-10.163c-3.08-3.126-7.132-4.572-10.845-4.259c-0.369,3.709,1.016,7.781,4.096,10.908 s7.132,4.572,10.845,4.259c0,0,0-0.003,0-0.006c3.699,0.355,7.755-1.026,10.87-4.095 C208.169,268.638,209.615,264.585,209.302,260.872z" />
                <path fill="#658D5C" d="M211.924,248.554c-3.596-0.978-7.843-0.285-11.435,2.234c-3.357,2.355-5.344,5.794-5.848,9.265 c0.707-3.434,0.009-7.342-2.344-10.698c-2.519-3.592-6.276-5.689-9.991-5.995c-0.978,3.596-0.285,7.843,2.234,11.435 c2.519,3.592,6.276,5.689,9.991,5.995c0-0.002,0-0.003,0-0.003c3.588,0.961,7.818,0.269,11.398-2.242 C209.521,256.025,211.618,252.268,211.924,248.554z" />
            </g>
            <g>
                <circle fill="#F1BF31" cx="212.97" cy="316.51" r="2.731" />
                <circle fill="#F1BF31" cx="225.52" cy="318.04" r="2.731" />
                <circle fill="#F1BF31" cx="205.76" cy="295.87" r="2.731" />
                <circle fill="#F1BF31" cx="188.39" cy="275.33" r="2.731" />
                <circle fill="#F1BF31" cx="200.52" cy="261.43" r="2.731" />
                <circle fill="#F1BF31" cx="195.92" cy="247.17" r="2.731" />
            </g>
            <path fill="#B46446" d="M256.932,327.98l-0.115-2.622c0.355-0.013,35.552-1.826,52.51-25.075 c9.824-13.465,11.742-31.519,5.704-53.662l2.529-0.69c6.26,22.955,4.2,41.766-6.123,55.907 C293.724,326.11,258.428,327.916,256.932,327.98z" />
            <g>
                <path fill="#658D5C" d="M317.586,235.756c1.74,4.533,1.343,9.28-0.672,12.878c-3.906-1.325-7.377-4.587-9.118-9.12 c-1.74-4.533-1.343-9.28,0.672-12.878C312.374,227.961,315.845,231.222,317.586,235.756z" />
                <path fill="#658D5C" d="M295.476,298.424c1.932,3.187,2.439,7.46,1.007,11.608c-1.336,3.876-4.094,6.735-7.29,8.178 c3.105-1.628,7.054-2.035,10.93-0.698c4.148,1.43,7.2,4.463,8.52,7.948c-3.187,1.931-7.459,2.439-11.608,1.007 c-4.148-1.43-7.2-4.463-8.52-7.948c0.001-0.001,0.003-0.001,0.003-0.001c-1.913-3.185-2.415-7.441-0.99-11.574 C288.958,302.796,291.991,299.743,295.476,298.424z" />
                <path fill="#658D5C" d="M303.036,286.856c2.98,2.237,5.044,6.013,5.263,10.395c0.205,4.095-1.288,7.776-3.715,10.306 c2.275-2.668,5.788-4.519,9.88-4.724c4.382-0.22,8.345,1.457,10.869,4.199c-2.237,2.98-6.013,5.044-10.395,5.263 c-4.382,0.22-8.345-1.457-10.869-4.199c0.001-0.001,0.002-0.002,0.002-0.002c-2.963-2.241-5.016-6.003-5.235-10.37 C298.617,293.342,300.294,289.378,303.036,286.856z" />
                <path fill="#658D5C" d="M306.304,273.814c3.604,0.947,6.941,3.665,8.797,7.639c1.736,3.715,1.742,7.687,0.449,10.946 c1.1-3.329,3.654-6.369,7.368-8.104c3.976-1.857,8.278-1.801,11.649-0.214c-0.947,3.604-3.665,6.941-7.639,8.797 c-3.976,1.857-8.278,1.801-11.649,0.214c0,0,0.001-0.003,0.002-0.004c-3.591-0.956-6.911-3.665-8.761-7.626 C304.66,281.49,304.716,277.185,306.304,273.814z" />
                <path fill="#658D5C" d="M304.45,260.872c3.709-0.369,7.783,1.017,10.908,4.096c2.921,2.878,4.312,6.599,4.235,10.104 c-0.129-3.503,1.206-7.243,4.083-10.163c3.08-3.126,7.132-4.572,10.845-4.259c0.369,3.709-1.016,7.781-4.096,10.908 s-7.132,4.572-10.845,4.259c0,0,0-0.003,0-0.006c-3.699,0.355-7.755-1.026-10.87-4.095 C305.583,268.638,304.137,264.585,304.45,260.872z" />
                <path fill="#658D5C" d="M301.828,248.554c3.596-0.978,7.843-0.285,11.435,2.234c3.357,2.355,5.344,5.794,5.848,9.265 c-0.707-3.434-0.009-7.342,2.344-10.698c2.519-3.592,6.276-5.689,9.991-5.995c0.978,3.596,0.285,7.843-2.234,11.435 c-2.519,3.592-6.276,5.689-9.991,5.995c0-0.002,0-0.003,0-0.003c-3.588,0.961-7.818,0.269-11.398-2.242 C304.231,256.025,302.134,252.268,301.828,248.554z" />
            </g>
            <g>
                <circle fill="#F1BF31" cx="300.79" cy="316.51" r="2.731" />
                <circle fill="#F1BF31" cx="288.22" cy="318.04" r="2.731" />
                <circle fill="#F1BF31" cx="307.99" cy="295.87" r="2.731" />
                <circle fill="#F1BF31" cx="325.36" cy="275.33" r="2.731" />
                <circle fill="#F1BF31" cx="313.24" cy="261.43" r="2.731" />
                <circle fill="#F1BF31" cx="317.84" cy="247.17" r="2.731" />
            </g>
            <path fill="#8CD7FF" d="M272.247,236.244h-31.325c-9.751,0-17.655,7.904-17.655,17.655v27.595 c0,9.752,7.904,17.655,17.655,17.655h31.325c9.75,0,17.655-7.903,17.655-17.655v-27.595 C289.902,244.148,281.998,236.244,272.247,236.244z" />
            <g>
                <polygon fill="#FFFFFF" points="261.753,260.259 261.753,263.755 258.259,263.755 258.259,260.259 255.567,260.259 255.567,263.755 252.072,263.755 252.072,260.259 249.886,260.259 249.886,267.25 251.634,269.464 251.634,295.212 262.119,295.212 262.119,269.464 263.866,267.25 263.866,260.259 " />
                <path fill="#FFFFFF" d="M257.386,249.191c1.729-0.838,2.185,2.695,2.185,2.695c3.641-1.821,2.691-4.776,1.821-6.189 c-1.165-1.894-5.825-2.622-7.791,1.311s3.35,11.724,3.35,11.724C257.24,256.328,255.128,250.284,257.386,249.191z" />
                <polygon fill="#FFFFFF" points="279.722,266.776 279.722,270.272 276.226,270.272 276.226,266.776 273.535,266.776 273.535,270.272 270.039,270.272 270.039,266.776 267.853,266.776 267.853,273.768 269.601,275.981 269.601,301.729 280.087,301.729 280.087,275.981 281.834,273.768 281.834,266.776 " />
                <path fill="#FFFFFF" d="M275.353,255.708c1.729-0.838,2.185,2.695,2.185,2.695c3.641-1.821,2.691-4.776,1.821-6.189 c-1.165-1.894-5.825-2.622-7.791,1.311c-1.966,3.933,3.35,11.724,3.35,11.724C275.208,262.845,273.097,256.801,275.353,255.708z" />
                <polygon fill="#FFFFFF" points="243.786,266.776 243.786,270.272 240.29,270.272 240.29,266.776 237.599,266.776 237.599,270.272 234.104,270.272 234.104,266.776 231.917,266.776 231.917,273.768 233.665,275.981 233.665,301.729 244.151,301.729 244.151,275.981 245.899,273.768 245.899,266.776 " />
                <path fill="#FFFFFF" d="M239.417,255.708c1.729-0.838,2.185,2.695,2.185,2.695c3.641-1.821,2.691-4.776,1.821-6.189 c-1.165-1.894-5.825-2.622-7.791,1.311c-1.966,3.933,3.35,11.724,3.35,11.724C239.273,262.845,237.161,256.801,239.417,255.708z" />
            </g>
            <path fill="#94BB79" d="M285.554,297.076c-2.334-1.154-4.926-1.864-7.706-1.864c-2.979,0-5.741,0.815-8.196,2.13 c-3.191-3.439-7.713-5.625-12.776-5.625s-9.583,2.186-12.776,5.625c-2.455-1.315-5.217-2.13-8.196-2.13 c-2.781,0-5.373,0.71-7.706,1.864c4.99,9.152,14.546,23.941,28.678,29.593C271.008,321.017,280.564,306.228,285.554,297.076z" />
            <g>
                <path fill="#F1BF31" d="M256.718,179.947l0.158-0.078C256.876,179.869,256.823,179.882,256.718,179.947z" />
                <path fill="#F1BF31" d="M256.876,179.869l0.158,0.078C256.928,179.882,256.876,179.869,256.876,179.869z" />
                <path fill="#F1BF31" d="M290.409,194.833c-0.983-5.679-8.301-6.663-16.712-7.209c-8.41-0.546-12.015-5.352-12.015-5.352 h-9.612c0,0-3.605,4.806-12.015,5.352s-15.729,1.529-16.712,7.209c-0.983,5.679,6.226,14.636,6.226,14.636l6.335-5.133 c0,0-7.755-7.427-3.933-10.158c3.775-2.697,14.782-0.829,19.684-7.303c-0.207,1.353-0.135,3.023,0.704,5.081 c1.602,3.933,1.02,8.884,1.02,8.884h3.496h3.496c0,0-0.583-4.952,1.02-8.884c0.839-2.058,0.913-3.73,0.704-5.081 c4.903,6.474,15.91,4.606,19.684,7.303c3.823,2.731-3.933,10.158-3.933,10.158l6.335,5.133 C284.183,209.469,291.392,200.512,290.409,194.833z" />
                <path fill="#F1BF31" d="M260.444,162.611h-1.821v-1.966c0-0.966-0.782-1.748-1.748-1.748s-1.748,0.782-1.748,1.748v1.966 h-1.894c-0.966,0-1.748,0.782-1.748,1.748s0.782,1.748,1.748,1.748h1.894v5.024c0,0.966,0.782,1.748,1.748,1.748 s1.748-0.782,1.748-1.748v-5.024h1.821c0.966,0,1.748-0.782,1.748-1.748S261.409,162.611,260.444,162.611z" />
            </g>
            <circle fill="#FFF0E1" cx="256.87" cy="172.88" r="3.496" />
            <g>
                <path fill="#D99F31" d="M231.827,243.22c1.408,0.703,3.052,1.311,4.733,1.311c5.791,0,8.629-3.275,8.629-9.066 s-3.494-10.158-9.284-10.158c0,0-0.653,0.011-1.699,0.171c-0.225,0.036-0.448,0.07-0.668,0.12 c-5.567,1.062-19.325,6.191-22.1,31.166c-3.879,34.912,24.467,66.41,45.439,73.4v-3.496 C256.876,326.669,207.36,288.804,231.827,243.22z" />
                <polygon fill="#D99F31" points="235.904,228.802 256.876,235.793 235.904,242.783 " />
            </g>
            <g>
                <polygon fill="#F1BF31" points="277.847,242.783 256.876,235.793 277.847,228.802 " />
                <path fill="#F1BF31" d="M214.911,257.151c2.504-22.538,14.317-27.172,19.406-28.145c0.111-0.025,0.225-0.042,0.417-0.073 c0.64-0.098,1.081-0.125,1.219-0.131c3.832,0.026,6.359,2.913,6.359,6.408c0,3.854-2.258,5.315-4.514,5.388 c0,0,1.821-3.787-1.894-4.806c-2.506-0.689-5.679,1.311-5.388,4.951c0.205,2.559,2.634,5.024,5.388,5.024 c5.791,0,10.486-4.185,10.486-9.976s-4.695-10.486-10.486-10.486c0,0-0.653,0.011-1.699,0.171c-0.225,0.036-0.448,0.07-0.668,0.12 c-5.567,1.062-19.325,6.191-22.1,31.166c-3.879,34.912,24.467,66.41,45.439,73.4v-3.496c0,0-7.427-3.714-12.505-7.321 C228.682,308.202,212.079,282.641,214.911,257.151z" />
            </g>
            <g>
                <path fill="#D99F31" d="M281.926,243.22c-1.408,0.703-3.052,1.311-4.733,1.311c-5.791,0-8.629-3.275-8.629-9.066 s3.494-10.158,9.284-10.158c0,0,0.653,0.011,1.699,0.171c0.225,0.036,0.448,0.07,0.668,0.12c5.567,1.062,19.325,6.191,22.1,31.166 c3.879,34.912-24.467,66.41-45.439,73.4v-3.496C256.876,326.669,306.392,288.804,281.926,243.22z" />
                <polygon fill="#D99F31" points="277.847,228.802 256.876,235.793 277.847,242.783 " />
            </g>
            <g>
                <path fill="#F1BF31" d="M298.84,257.151c-2.504-22.538-14.317-27.172-19.406-28.145c-0.111-0.025-0.225-0.042-0.417-0.073 c-0.64-0.098-1.081-0.125-1.219-0.131c-3.832,0.026-6.359,2.913-6.359,6.408c0,3.854,2.258,5.315,4.514,5.388 c0,0-1.821-3.787,1.894-4.806c2.506-0.689,5.679,1.311,5.388,4.951c-0.205,2.559-2.634,5.024-5.388,5.024 c-5.791,0-10.486-4.185-10.486-9.976s4.695-10.486,10.486-10.486c0,0,0.653,0.011,1.699,0.171c0.225,0.036,0.448,0.07,0.668,0.12 c5.567,1.062,19.325,6.191,22.1,31.166c3.879,34.912-24.467,66.41-45.439,73.4v-3.496c0,0,7.427-3.714,12.505-7.321 C285.07,308.202,301.673,282.641,298.84,257.151z" />
                <circle fill="#F1BF31" cx="256.87" cy="235.8" r="3.496" />
            </g>
            <path fill="#D99F31" d="M281.834,207.721c0.928-2.403-0.328-5.625-0.328-5.625c-3.222-0.109-5.461,2.239-5.461,2.239 c0.273-2.894-0.983-5.516-0.983-5.516c-4.151,0.491-5.516,2.95-5.516,2.95c-0.218-4.533-2.95-6.39-2.95-6.39 c-3.605,1.092-4.696,5.024-4.696,5.024c-0.546-4.478-5.024-6.553-5.024-6.553s-4.478,2.076-5.024,6.553c0,0-1.092-3.933-4.696-5.024 c0,0-2.731,1.857-2.95,6.39c0,0-1.365-2.457-5.516-2.95c0,0-1.256,2.622-0.983,5.516c0,0-2.239-2.348-5.461-2.239 c0,0-1.256,3.222-0.328,5.625c0,0-3.659-1.146-6.663,0.6c0,0,5.506,2.13,7.341,4.751h48.561c1.834-2.622,7.341-4.751,7.341-4.751 C285.494,206.574,281.834,207.721,281.834,207.721z" />
            <path fill="#F1BF31" d="M281.156,213.074c0,1.572-3.674,6.434-3.674,6.434s-11.914,1.658-20.606,1.658 c-8.748,0-20.689-1.704-20.689-1.704s-3.592-4.835-3.592-6.39c0-4.47,10.871-8.094,24.28-8.094 C270.285,204.979,281.156,208.604,281.156,213.074z" />
            <g>
                <ellipse fill="#D99F31" cx="256.87" cy="218.32" rx="20.977" ry="6.99" />
                <path fill="#D99F31" d="M262.009,179.541c0-2.294-1.711-5.207-5.133-3.168c-3.423-2.039-5.133,0.874-5.133,3.168 s-2.622,2.622-2.95,2.403c0,0,2.185,2.84,4.696,0.983c0,0,1.347,1.966,3.386,1.966c2.039,0,3.387-1.966,3.387-1.966 c2.513,1.857,4.696-0.983,4.696-0.983C264.631,182.162,262.009,181.834,262.009,179.541z" />
            </g>
            <g>
                <path fill="#B46446" d="M249.884,348.952c-0.196,0-0.396-0.044-0.584-0.14c-0.649-0.321-0.911-1.109-0.587-1.758 l6.99-13.981c0.324-0.642,1.111-0.911,1.758-0.584c0.649,0.321,0.911,1.109,0.587,1.758l-6.99,13.981 C250.827,348.686,250.365,348.952,249.884,348.952z" />
                <path fill="#B46446" d="M263.869,348.952c-0.481,0-0.943-0.266-1.174-0.724l-6.99-13.981 c-0.324-0.649-0.062-1.437,0.587-1.758c0.646-0.331,1.433-0.062,1.758,0.584l6.99,13.981c0.324,0.649,0.062,1.437-0.587,1.758 C264.264,348.908,264.065,348.952,263.869,348.952z" />
            </g>
            <g>
                <path fill="#FFFFFF" d="M288.333,336.718c-20.716,4.631-42.199,4.631-62.915,0c0-3.496,0-10.038,0-13.533 c20.716,4.631,42.199,4.631,62.915,0C288.333,326.681,288.333,333.224,288.333,336.718z" />
                <path fill="#FFFFFF" d="M242.749,331.624c-13.377,2.581-27.086,3.289-40.727,2.015c0.23,1.802,11.017,7.158,11.017,7.158 s-9.518,4.6-9.306,6.266c13.058,1.22,26.183,0.638,39.016-1.677V331.624L242.749,331.624z" />
                <path fill="#FFFFFF" d="M271.054,331.624c13.377,2.581,27.086,3.289,40.727,2.015c-0.23,1.802-11.017,7.158-11.017,7.158 s9.518,4.6,9.306,6.266c-13.058,1.22-26.183,0.638-39.016-1.677V331.624L271.054,331.624z" />
            </g>
        </svg>
    </React.Fragment>
);
