
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from 'react';
import { Text, ComponentFields, ComponentParams, ComponentRendering, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { SitecoreGenericTextField } from '../../interfaces';
import MarketNewsView from "../../core/components/DataMarket/MarketNews";

export interface MarketNewsProps { 
    fields: any;
    params?: ComponentParams;
    rendering: ComponentRendering;
}

export const MarketNews: React.FunctionComponent<MarketNewsProps> = (props: MarketNewsProps): React.ReactElement => {
    return (
        <MarketNewsView />
    );
}
    