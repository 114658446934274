
/*
*   e-Listing - core - components - country flag icon - cyp
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const CYP: JSX.Element = (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve'>
        <rect y='85.331' style={{fill: '#FCFCFC'}} width='512' height='341.337'/>
        <path style={{fill: '#6DA544'}} d='M352,233.88c-5.169,0-11.569,0-22.154,0c0,40.784-33.063,73.846-73.846,73.846  c-40.785,0-73.846-33.062-73.846-73.846c-7.138,0-15.016,0-22.154,0c0,43.931,29.509,80.969,69.787,92.377  c-4.415,8.389-3.743,18.951,2.564,26.822c8.285-6.64,16.864-13.515,24.141-19.346c7.277,5.831,15.855,12.705,24.141,19.346  c6.371-7.95,6.998-18.646,2.434-27.075C322.905,314.318,352,277.5,352,233.88z'/>
        <path style={{fill: '#FFDA44'}} d='M196.923,226.497c0,0,0,36.923,36.923,36.923l7.385,7.385H256c0,0,7.385-22.154,22.154-22.154  c0,0,0-14.769,14.769-14.769s22.154,0,22.154,0s-7.385-29.538,29.538-51.692l-14.769-7.385c0,0-51.692,36.923-88.615,29.538v14.769  h-14.769l-7.385-7.385L196.923,226.497z'/>
    </svg>
);
