import * as React from "react";
import { useEffect } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Row } from "@bdl-cmn-shared-packages-npm/design-system";
import { style } from '../SecurityOverview/securityOverview.style';
import { Security } from "../../../../interfaces";
import SecuritySustainableKeyDocuments from "./keyDocuments";
import { GreenCard } from "../../../../interfaces/green-card";

export interface Row {
    label: string,
    action: () => void;
}

export interface SecuritySustainableDataProps {
    securityData: Security,
    greenCard: GreenCard
}

const SecuritySustainableDataImpactFund = ({
    greenCard,
    securityData
}: SecuritySustainableDataProps) => {
    
    useEffect(() => {

    }, [greenCard, ]);

    return (
        <div css={style.securityOverview}>
            <div css={style.securityOverviewTop}>
                <div css={style.securityOverviewTopLeftStart}>
                    <div css={style.flexRow}>
                        <div css={style.blocTitle}>
                            Article 9 Fund
                        </div>
                        <div css={[style.blocRowSpace, style.blocRowSpaceNoBorderTop, style.blocRowSpaceFull]}>
                            Article 9 applies "where a financial product has sustainable investment as its objective and an index has been designated as a reference benchmark".
                        </div>
                    </div>
                </div>
                <SecuritySustainableKeyDocuments 
                    postIssuanceDocs={greenCard?.postIssuanceDocs}
                    preIssuanceDocs={greenCard?.preIssuanceDocs} />
            </div>
        </div>
    )
};

export default SecuritySustainableDataImpactFund;