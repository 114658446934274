
/*
*   e-Listing - core - components - country flag icon - bgr
*   authors: Aymeric Berettoni, Aurélien Dupays Dexemple
*/

import * as React from 'react';

export const VGB: JSX.Element = (
    <React.Fragment>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" xmlSpace="preserve">
            <path fill="#00205B" d="M503.172,423.724H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828 h494.345c4.875,0,8.828,3.953,8.828,8.828v317.792C512,419.772,508.047,423.724,503.172,423.724z" />
            <path fill="#FFFFFF" d="M247.172,88.276h-15.333l-81.77,53.573V88.276h-44.138v53.573l-81.77-53.573H8.828 C3.953,88.276,0,92.229,0,97.104v7.002l70.155,45.963H0v44.138h70.155L0,240.171v7.001C0,252.047,3.953,256,8.828,256h15.333 l81.77-53.573V256h44.138v-53.573L231.838,256h15.333c4.875,0,8.828-3.953,8.828-8.828v-7.002l-70.154-45.963h70.154v-44.138 h-70.155l70.155-45.963v-7.002C255.999,92.229,252.048,88.276,247.172,88.276z" />
            <g>
                <polygon fill="#EF3340" points="255.999,158.897 141.241,158.897 141.241,88.276 114.758,88.276 114.758,158.897 0,158.897 0,185.379 114.758,185.379 114.758,256 141.241,256 141.241,185.379 255.999,185.379 " />
                <path fill="#EF3340" d="M97.103,150.069L4.207,89.768C1.735,91.333,0,93.961,0,97.104v0.458l80.889,52.507H97.103z" />
                <path fill="#EF3340" d="M166.283,150.069l88.374-57.366c-1.536-2.599-4.246-4.427-7.485-4.427h-1.909l-95.194,61.793 H166.283z" />
                <path fill="#EF3340" d="M89.474,194.207L1.269,251.463C2.79,254.125,5.542,256,8.828,256h1.665l95.195-61.793H89.474z" />
                <path fill="#EF3340" d="M255.859,247.867l-82.666-53.659H156.98l93.873,60.934 C253.666,253.837,255.594,251.102,255.859,247.867z" />
            </g>
            <path fill="#4C8D2B" d="M387.75,171.124h-57.796c0,0,0,74.795,0,105.393c0,23.798,57.796,47.597,57.796,47.597 s57.796-23.798,57.796-47.597c0-30.599,0-105.393,0-105.393L387.75,171.124L387.75,171.124z" />
            <path fill="#EF3340" d="M353.913,251.542c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.454-1.938-0.614-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.542,1.408,0.794,1.624,0.614,2.959s-1.661,2.743-1.516,3.934 L353.913,251.542z" />
            <path fill="#FFE15A" d="M376.637,249.28c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.422 l-5.592-8.259c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.666-1.49,1.49 c0,0.305,0.114,0.572,0.27,0.807l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956 c-0.461,0.075-0.833,0.431-0.833,0.914v0.597c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.058,0.04-0.097v-0.597 c0-0.488-0.378-0.852-0.847-0.919c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.23,0.489-0.363 c1.097-0.842,2.749-2.124,3.276-1.453c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399 c-1.053,0.707-2.142,1.441-2.228,2.481c-0.025,0.297,0.194,0.554,0.49,0.582c0.015,0,0.032,0,0.045,0 c0.277,0,0.512-0.213,0.535-0.491c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127 C377.032,250.015,376.919,249.638,376.637,249.28z M367.078,251.232c-0.308-1.174-1.034-2.302-1.941-2.71 c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.666-1.49-1.49-1.49c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311 c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905c0.111,0.026,0.219,0.068,0.339,0.068s0.227-0.042,0.339-0.068l5.353,7.905 H367.078z" />
            <path fill="#FFC8B9" d="M384.066,201.155c0,0-5.383-1.593-7.791,17.247c0,0-13.174,16.751-13.599,17.742 c-0.425,0.992-0.035,8.11,1.382,7.543s3.967-3.86,3.825-6.269s0.945-3.752,2.018-4.958c2.018-2.266,13.387-12.536,13.812-14.803 C384.137,215.392,384.066,201.155,384.066,201.155z" />
            <g>
                <path fill="#FFFFFF" d="M392.425,197.579c0,0,8.322-1.098,10.766,7.402c0,0-6.835,3.754-5.985,8.641 s-0.106,27.199-0.106,27.199c-13.068,4.249-19.974-1.063-19.974-1.063s3.4-12.643,2.869-22.948c0,0-3.081-2.337-2.337-4.993 c0.744-2.656,2.656-5.631,3.294-7.862s2.337-5.95,6.481-6.269C391.574,197.366,392.425,197.579,392.425,197.579z" />
                <path fill="#FFFFFF" d="M379.534,237.279c0,0-6.516,24.649-4.249,58.221c0,0,20.328,2.691,31.378-1.346 c0,0-5.737-40.16-11.262-55.884L379.534,237.279z" />
            </g>
            <path fill="#FFC8B9" d="M383.358,183.06v10.211c0,1.121,0.909,2.03,2.03,2.03h0.182c0.793,0,1.437,0.643,1.437,1.437v2.441 c0,0,1.368,3.574,6.691-0.608v-15.435L383.358,183.06z" />
            <path fill="#8C5A28" d="M382.154,180.969c-0.33,0.825,0.018,3.063,2.053,2.904c2.543-0.199,5.613-0.213,6.304,2.603 c0.642,2.617,0.213,9.154,3.046,9.58c2.834,0.425,6.046-1.458,5.985-7.083c-0.053-4.958-3.294-10.89-7.827-9.987 c0,0-4.02-2.816-6.375,0.354C385.342,179.34,382.863,179.199,382.154,180.969z" />
            <path fill="#FFC8B9" d="M403.19,204.98c0,0,2.9,19.104,2.762,20.682c-0.141,1.629-10.27,17.282-9.704,18.983 c0.567,1.7,1.133,3.967-1.983,4.817s-2.797-4.126-1.948-6.676c0.85-2.55,7.968-16.131,7.968-17.689s-4.038-11.616-3.046-15.583 C398.232,205.546,403.19,204.98,403.19,204.98z" />
            <path fill="#EF3340" d="M341.163,191.262c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.454-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.541,1.408,0.794,1.624,0.614,2.959s-1.661,2.743-1.516,3.934 L341.163,191.262z" />
            <path fill="#FFE15A" d="M363.888,189c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.422l-5.592-8.259 c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.666-1.49,1.49c0,0.305,0.114,0.572,0.27,0.807 l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956c-0.461,0.075-0.833,0.431-0.833,0.914v0.597 c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.058,0.04-0.097v-0.597c0-0.488-0.378-0.852-0.847-0.919 c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.23,0.489-0.363c1.097-0.842,2.749-2.124,3.276-1.453 c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399c-1.053,0.707-2.142,1.441-2.228,2.481 c-0.025,0.297,0.194,0.554,0.49,0.581c0.015,0,0.032,0,0.045,0c0.277,0,0.512-0.213,0.535-0.491 c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127C364.283,189.735,364.17,189.357,363.888,189z M354.329,190.952c-0.308-1.174-1.034-2.302-1.941-2.71c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.666-1.49-1.49-1.49 c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905 c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068l5.353,7.905H354.329z" />
            <path fill="#EF3340" d="M341.163,211.356c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.454-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.542,1.408,0.794,1.624,0.614,2.959s-1.661,2.743-1.516,3.934 L341.163,211.356z" />
            <path fill="#FFE15A" d="M363.888,209.094c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.422 l-5.592-8.259c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.666-1.49,1.49 c0,0.305,0.114,0.572,0.27,0.807l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956 c-0.461,0.075-0.833,0.431-0.833,0.914v0.597c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.058,0.04-0.097v-0.597 c0-0.488-0.378-0.852-0.847-0.919c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.23,0.489-0.363 c1.097-0.842,2.749-2.124,3.276-1.453c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399 c-1.053,0.707-2.142,1.441-2.228,2.481c-0.025,0.297,0.194,0.554,0.49,0.582c0.015,0,0.032,0,0.045,0 c0.277,0,0.512-0.213,0.535-0.491c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127 C364.283,209.827,364.17,209.45,363.888,209.094z M354.329,211.045c-0.308-1.174-1.034-2.302-1.941-2.71 c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.666-1.49-1.49-1.49c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311 c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068 l5.353,7.905H354.329z" />
            <path fill="#EF3340" d="M341.163,231.449c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.454-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.541,1.408,0.794,1.624,0.614,2.959s-1.661,2.743-1.516,3.934 L341.163,231.449z" />
            <path fill="#FFE15A" d="M363.888,229.187c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.422 l-5.592-8.259c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.666-1.49,1.49 c0,0.305,0.114,0.572,0.27,0.807l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956 c-0.461,0.075-0.833,0.431-0.833,0.914v0.597c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.058,0.04-0.097v-0.597 c0-0.488-0.378-0.852-0.847-0.919c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.23,0.489-0.363 c1.097-0.842,2.749-2.124,3.276-1.453c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399 c-1.053,0.707-2.142,1.441-2.228,2.481c-0.025,0.297,0.194,0.554,0.49,0.581c0.015,0,0.032,0,0.045,0 c0.277,0,0.512-0.213,0.535-0.491c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127 C364.283,229.921,364.17,229.544,363.888,229.187z M354.329,231.138c-0.308-1.174-1.034-2.302-1.941-2.71 c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.666-1.49-1.49-1.49c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311 c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068 l5.353,7.905H354.329z" />
            <path fill="#EF3340" d="M341.163,271.636c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.454-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888s0.794,1.624,0.614,2.959s-1.661,2.743-1.516,3.934 L341.163,271.636z" />
            <path fill="#FFE15A" d="M363.888,269.374c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.422 l-5.592-8.259c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.667-1.49,1.49 c0,0.305,0.114,0.572,0.27,0.807l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956 c-0.461,0.075-0.833,0.431-0.833,0.914v0.597c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.058,0.04-0.097v-0.597 c0-0.488-0.378-0.852-0.847-0.919c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.229,0.489-0.363 c1.097-0.842,2.749-2.124,3.276-1.453c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399 c-1.053,0.707-2.142,1.441-2.228,2.481c-0.025,0.297,0.194,0.554,0.49,0.582c0.015,0,0.032,0,0.045,0 c0.277,0,0.512-0.213,0.535-0.491c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127 C364.283,270.108,364.17,269.73,363.888,269.374z M354.329,271.326c-0.308-1.174-1.034-2.302-1.941-2.71 c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.666-1.49-1.49-1.49c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311 c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068 l5.353,7.905H354.329z" />
            <path fill="#EF3340" d="M341.163,291.73c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.454-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.542,1.408,0.794,1.624,0.614,2.959 c-0.181,1.335-1.661,2.743-1.516,3.934L341.163,291.73z" />
            <path fill="#FFE15A" d="M363.888,289.467c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.421 l-5.592-8.258c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.667-1.49,1.49 c0,0.305,0.114,0.572,0.27,0.807l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956 c-0.461,0.075-0.833,0.431-0.833,0.914v0.597c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.059,0.04-0.097v-0.597 c0-0.488-0.378-0.852-0.847-0.919c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.23,0.489-0.363 c1.097-0.842,2.749-2.124,3.276-1.453c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399 c-1.053,0.707-2.142,1.441-2.228,2.481c-0.025,0.297,0.194,0.554,0.49,0.582c0.015,0,0.032,0,0.045,0 c0.277,0,0.512-0.213,0.535-0.491c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127 C364.283,290.201,364.17,289.824,363.888,289.467z M354.329,291.418c-0.308-1.174-1.034-2.302-1.941-2.71 c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.666-1.49-1.49-1.49c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311 c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068 l5.353,7.905H354.329z" />
            <path fill="#EF3340" d="M411.071,191.262c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.455-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.542,1.408,0.794,1.624,0.614,2.959s-1.661,2.743-1.516,3.934 L411.071,191.262z" />
            <path fill="#FFE15A" d="M433.795,189c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.422l-5.592-8.259 c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.666-1.49,1.49c0,0.305,0.114,0.572,0.27,0.807 l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956c-0.461,0.075-0.833,0.431-0.833,0.914v0.597 c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.058,0.04-0.097v-0.597c0-0.488-0.378-0.852-0.847-0.919 c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.23,0.489-0.363c1.097-0.842,2.749-2.124,3.276-1.453 c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399c-1.053,0.707-2.142,1.441-2.228,2.481 c-0.025,0.297,0.194,0.554,0.49,0.581c0.015,0,0.032,0,0.045,0c0.277,0,0.512-0.213,0.535-0.491 c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127C434.19,189.735,434.079,189.357,433.795,189z M424.237,190.952c-0.308-1.174-1.034-2.302-1.941-2.71c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.667-1.49-1.49-1.49 c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905 c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068l5.353,7.905H424.237z" />
            <path fill="#EF3340" d="M411.071,211.356c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.455-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.542,1.408,0.794,1.624,0.614,2.959s-1.661,2.743-1.516,3.934 L411.071,211.356z" />
            <path fill="#FFE15A" d="M433.795,209.094c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.422 l-5.592-8.259c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.666-1.49,1.49 c0,0.305,0.114,0.572,0.27,0.807l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956 c-0.461,0.075-0.833,0.431-0.833,0.914v0.597c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.058,0.04-0.097v-0.597 c0-0.488-0.378-0.852-0.847-0.919c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.23,0.489-0.363 c1.097-0.842,2.749-2.124,3.276-1.453c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399 c-1.053,0.707-2.142,1.441-2.228,2.481c-0.025,0.297,0.194,0.554,0.49,0.582c0.015,0,0.032,0,0.045,0 c0.277,0,0.512-0.213,0.535-0.491c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127 C434.19,209.827,434.079,209.45,433.795,209.094z M424.237,211.045c-0.308-1.174-1.034-2.302-1.941-2.71 c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.667-1.49-1.49-1.49c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311 c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068 l5.353,7.905H424.237z" />
            <path fill="#EF3340" d="M411.071,231.449c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.455-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.542,1.408,0.794,1.624,0.614,2.959s-1.661,2.743-1.516,3.934 L411.071,231.449z" />
            <path fill="#FFE15A" d="M433.795,229.187c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.422 l-5.592-8.259c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.666-1.49,1.49 c0,0.305,0.114,0.572,0.27,0.807l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956 c-0.461,0.075-0.833,0.431-0.833,0.914v0.597c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.058,0.04-0.097v-0.597 c0-0.488-0.378-0.852-0.847-0.919c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.23,0.489-0.363 c1.097-0.842,2.749-2.124,3.276-1.453c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399 c-1.053,0.707-2.142,1.441-2.228,2.481c-0.025,0.297,0.194,0.554,0.49,0.581c0.015,0,0.032,0,0.045,0 c0.277,0,0.512-0.213,0.535-0.491c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127 C434.19,229.921,434.079,229.544,433.795,229.187z M424.237,231.138c-0.308-1.174-1.034-2.302-1.941-2.71 c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.667-1.49-1.49-1.49c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311 c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068 l5.353,7.905H424.237z" />
            <path fill="#EF3340" d="M411.071,251.542c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.455-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.542,1.408,0.794,1.624,0.614,2.959s-1.661,2.743-1.516,3.934 L411.071,251.542z" />
            <path fill="#FFE15A" d="M433.795,249.28c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.422 l-5.592-8.259c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.666-1.49,1.49 c0,0.305,0.114,0.572,0.27,0.807l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956 c-0.461,0.075-0.833,0.431-0.833,0.914v0.597c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.058,0.04-0.097v-0.597 c0-0.488-0.378-0.852-0.847-0.919c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.23,0.489-0.363 c1.097-0.842,2.749-2.124,3.276-1.453c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399 c-1.053,0.707-2.142,1.441-2.228,2.481c-0.025,0.297,0.194,0.554,0.49,0.582c0.015,0,0.032,0,0.045,0 c0.277,0,0.512-0.213,0.535-0.491c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127 C434.19,250.015,434.079,249.638,433.795,249.28z M424.237,251.232c-0.308-1.174-1.034-2.302-1.941-2.71 c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.667-1.49-1.49-1.49c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311 c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068 l5.353,7.905H424.237z" />
            <path fill="#EF3340" d="M411.071,271.636c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.455-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.542,1.408,0.794,1.624,0.614,2.959s-1.661,2.743-1.516,3.934 L411.071,271.636z" />
            <path fill="#FFE15A" d="M433.795,269.374c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.422 l-5.592-8.259c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.667-1.49,1.49 c0,0.305,0.114,0.572,0.27,0.807l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956 c-0.461,0.075-0.833,0.431-0.833,0.914v0.597c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.058,0.04-0.097v-0.597 c0-0.488-0.378-0.852-0.847-0.919c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.229,0.489-0.363 c1.097-0.842,2.749-2.124,3.276-1.453c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399 c-1.053,0.707-2.142,1.441-2.228,2.481c-0.025,0.297,0.194,0.554,0.49,0.582c0.015,0,0.032,0,0.045,0 c0.277,0,0.512-0.213,0.535-0.491c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127 C434.19,270.108,434.079,269.73,433.795,269.374z M424.237,271.326c-0.308-1.174-1.034-2.302-1.941-2.71 c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.667-1.49-1.49-1.49c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311 c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068 l5.353,7.905H424.237z" />
            <path fill="#EF3340" d="M411.071,291.73c0,0-0.324-1.119-0.036-2.13c0.289-1.011-0.455-1.938-0.613-2.815 c-0.433-2.382,1.588-2.996,1.696-5.124c0,0,1.443,1.48,0.903,2.888c-0.542,1.408,0.794,1.624,0.614,2.959 c-0.181,1.335-1.661,2.743-1.516,3.934L411.071,291.73z" />
            <path fill="#FFE15A" d="M433.795,289.467c-1.181-1.476-3.252,0.114-4.768,1.27c-0.211,0.162-0.39,0.286-0.577,0.421 l-5.592-8.258c0.157-0.236,0.27-0.503,0.27-0.807c0-0.823-0.666-1.49-1.49-1.49s-1.49,0.667-1.49,1.49 c0,0.305,0.114,0.572,0.27,0.807l-5.769,8.519h-4.085c0,0-0.169,2.539,4.291,2.847c0.575,0.871,1.395,1.564,2.377,1.956 c-0.461,0.075-0.833,0.431-0.833,0.914v0.597c0,0.038,0.036,0.06,0.04,0.097h10.391c0.004-0.036,0.04-0.059,0.04-0.097v-0.597 c0-0.488-0.378-0.852-0.847-0.919c1.739-0.7,2.961-2.327,3.167-4.26c0.162-0.12,0.313-0.23,0.489-0.363 c1.097-0.842,2.749-2.124,3.276-1.453c0.131,0.164,0.118,0.255,0.113,0.288c-0.06,0.39-0.893,0.95-1.562,1.399 c-1.053,0.707-2.142,1.441-2.228,2.481c-0.025,0.297,0.194,0.554,0.49,0.582c0.015,0,0.032,0,0.045,0 c0.277,0,0.512-0.213,0.535-0.491c0.043-0.527,0.993-1.165,1.757-1.677c0.973-0.657,1.892-1.273,2.024-2.127 C434.19,290.201,434.079,289.824,433.795,289.467z M424.237,291.418c-0.308-1.174-1.034-2.302-1.941-2.71 c0.48-0.247,0.819-0.734,0.819-1.311c0-0.822-0.667-1.49-1.49-1.49c-0.822,0-1.49,0.666-1.49,1.49c0,0.577,0.338,1.064,0.819,1.311 c-0.907,0.407-1.634,1.535-1.941,2.71h-3.065l5.353-7.905c0.111,0.026,0.218,0.068,0.339,0.068c0.12,0,0.227-0.042,0.339-0.068 l5.353,7.905H424.237z" />
            <g>
                <path fill="#FFFFFF" d="M445.546,171.124c0,0,0,74.795,0,105.393c0,23.798-57.796,47.597-57.796,47.597 s-57.796-23.798-57.796-47.597c0-30.599,0-105.393,0-105.393h57.796L445.546,171.124 M448.946,167.724h-3.4H387.75h-57.796h-3.4 v3.4v105.393c0,11.128,10.265,23.111,30.511,35.616c14.518,8.967,28.79,14.878,29.39,15.125l1.294,0.533l1.294-0.533 c0.6-0.247,14.872-6.158,29.39-15.125c20.246-12.505,30.511-24.488,30.511-35.616V171.124L448.946,167.724L448.946,167.724z" />
                <path fill="#FFFFFF" d="M449.437,295.262l15.412,10.63c-17.901,22.963-45.794,37.772-77.1,37.772 c-31.305,0-59.198-14.81-77.1-37.772l15.412-10.63c14.498,18.089,36.756,29.703,61.687,29.703 C412.681,324.964,434.939,313.35,449.437,295.262 M448.763,290.667l-1.978,2.468c-14.48,18.067-35.998,28.429-59.034,28.429 s-44.554-10.362-59.034-28.429l-1.978-2.468l-2.604,1.796l-15.412,10.629l-2.967,2.046l2.216,2.842 c19.363,24.836,48.442,39.082,79.78,39.082s60.418-14.244,79.78-39.082l2.216-2.842l-2.967-2.046l-15.412-10.629L448.763,290.667 L448.763,290.667z" />
            </g>
            <path fill="#FFE15A" d="M387.75,343.663c31.305,0,59.198-14.81,77.1-37.772l-15.412-10.629 c-14.498,18.089-36.756,29.703-61.687,29.703s-47.189-11.614-61.687-29.703l-15.412,10.629 C328.552,328.854,356.445,343.663,387.75,343.663z" />
        </svg>
    </React.Fragment>
);
