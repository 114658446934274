import * as React from 'react';

import {
  ComponentFields,
  ComponentParams,
  ComponentRendering,
  Placeholder,
  Text,
  getFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import { breakpoint, breakpointMax } from '../../style';
import { css, jsx } from '@emotion/react';

import { TimelineRow } from '../TimelineRow';
import { canUseDOM } from '../../predicates';
import { useEffect } from 'react';

/** @jsx jsx */






export interface TimelineComponentProps {
  fields: any;
  props?: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}
const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 96px;

  @media (max-width: ${breakpointMax}) {
    padding-top: 0;
    padding-bottom: 0;
  }

  > div {
    &:first-of-type {
      > div {
        &:first-of-type {
          > div {
            &:first-of-type {
              > div {
                &:first-of-type {
                  // height: calc(100% - 40px);
                  // position: absolute;
                  // margin-top: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const TimelineComponent: React.FunctionComponent<TimelineComponentProps> = (
  props: TimelineComponentProps
): React.ReactElement => {
  const { fields, params, rendering } = props;
  const visible = (props && props.props && props.props.visible) || 0;
  const updateNumber = () => {
    setTimeout(() => {
      if (
        rendering &&
        rendering.placeholders &&
        rendering.placeholders['timeline-row'] &&
        rendering.placeholders['timeline-row'].length > 0
      ) {
        let iterator = 1;
        const parent =
          (canUseDOM &&
            document.getElementsByClassName('timeline-component-part') &&
            document.getElementsByClassName('timeline-component-part')[visible]) ||
          null;

        if (parent && parent.children) {
          Array.from(parent.children).forEach((item, idx) => {
            if (parent && parent.getElementsByClassName(`index`)[idx]) {
              parent.getElementsByClassName(`index`)[idx].innerHTML = iterator.toString();
              iterator++;
            }
          });
        }
      }
    }, 500);
  };

  updateNumber();

  useEffect(() => {
    updateNumber();
  }, [visible]);

  return (
    <div css={container} className={`timeline-component-part`}>
      <Placeholder name='timeline-row' rendering={rendering} />
    </div>
  );
};
