import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Tr, Td } from '@bdl-cmn-shared-packages-npm/design-system';
import { style } from '../base/index.style';
import Props from '../base/props';
import { data as dataMapper } from '../../../Search/results/dataMapper';
import { formatDate } from '../../../../../utils/date';
import SearchIndex from '../../../../../interfaces/search/search-index';
import { indexTypeLabel } from '../../../../../enums/index-type';
import { goToIndexDetailPage } from '../../../Index/Index-details';

const IndexRow = ({ row, rowKey, selectedCols }: Props<SearchIndex>) => {
  return (
    <Tr css={style.actionsRow} 
        key={rowKey ? rowKey : `index-${row.id}`} 
        onRowClick={() => goToIndexDetailPage(row.id)}>
      <Td>{row.codeType ? <div css={[style.market]}> {indexTypeLabel(row.codeType)}</div> : 'N/A'}</Td>
      <Td>
        <div>{row.indexName}</div>
        <div css={style.subLabel}>{row.isin}</div>
      </Td>
      <Td>
        {row.indexPrice?.lastPrice?.date ? (
          <>
            <div css={style.bold}>{row.indexPrice.lastPrice.amount}</div>
            <div>{formatDate(row.indexPrice.lastPrice.date)}</div>
          </>
        ) : (
          'N/A'
        )}
      </Td>
      <Td>
        {row.indexPrice?.lastPrice?.changePercent != null ?
          <span style={{ color: row.indexPrice?.lastPrice?.changePercent < 0 ? '#CB1234' : '#22AA5F' }}>
            {`${row.indexPrice?.lastPrice?.changePercent?.toFixed(4)} %`}
          </span>
          : "N/A"
        }
      </Td>
      <Td>
        {row.indexPrice?.lastPrice?.changeAbsolute != null ?
          <span style={{ color: row.indexPrice?.lastPrice?.changeAbsolute < 0 ? '#CB1234' : '#22AA5F' }}>
            {`${row.indexPrice?.lastPrice?.changeAbsolute?.toFixed(4)}`}
          </span>
          : "N/A"
        }
      </Td>
      <Td>
        <div css={style.bold}>
          {row.indexPrice?.monthPriceMax?.amount ? row.indexPrice.monthPriceMax.amount : 'N/A'}
        </div>
        <div>{row.indexPrice?.monthPriceMin?.amount ? row.indexPrice.monthPriceMin.amount : 'N/A'}</div>
      </Td>
      <Td>
        <div css={style.bold}>{row.indexPrice?.yearPriceMax?.amount ? row.indexPrice.yearPriceMax.amount : 'N/A'}</div>
        <div>{row.indexPrice?.yearPriceMin?.amount ? row.indexPrice.yearPriceMin.amount : 'N/A'}</div>
      </Td>
      {selectedCols ? (
        selectedCols['indices']?.map((col: string, index: number) => (
          <Td key={`col-selected-${index}`}>{dataMapper['indices'][col].render((row as any)[col])}</Td>
        ))
      ) : (
        <></>
      )}
    </Tr>
  );
};

export default IndexRow;
