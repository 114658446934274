import gql from "graphql-tag";
import { documentListFragment } from "./search/search-document.queries";
import { programmesListFragment } from "./search/search-programme.queries";
import { securityListFragment } from "./search/search-security.queries";
import { issuerSearchFullFragment, issuerSearchNoFilterFragment } from "./search/search-issuer.queries";

export const issuerSummaryFragment = gql`
  fragment issuerSummaryFragment on Tier {
    id
    name
    url
    lei
    level1Classification
    level2Classification
    country: countryCode2
    statistics {
      nbEquities
      nbProgs
      nbBonds
      nbGreens
      nbWarrants
      nbFundClasses
      nbCertificates
      nbSecurities
      alignmentPercent
      managementCompanyName
      caiAsPerCBI
    }
    cbiSectors
    cbiSubSectors
    climateAligned
    climateAlignedAlignmentLevel
    climateAlignedAlignmentPercent
    climateAlignedEvaluationYear
  } 
`;

export const GET_ISSUER_DETAIL = gql`
  ${issuerSummaryFragment}
  query GetIssuerDetails($idIssuer: String!) {
    tier(id: $idIssuer) {
      ...issuerSummaryFragment
    }
  }
`;

export const GET_ISSUER_SECURITIES_QUERY = gql`
  ${securityListFragment}
  query GetIssuerSecurities($idIssuer: String!, $limit: Int) {
    tier(id: $idIssuer) {
      id
      name
      url
      securities(limit: $limit) {
        ...securityListFragment
      }
    }
  }
`;

export const GET_ISSUER_SECURITIES = gql`
  ${securityListFragment}
  query getissuerSecurities($idIssuer: String!, $securitiesLimit: Int, $securitiesOffset: Int) {
    tier(id: $idIssuer) {
      id
      securities(limit: $securitiesLimit, offset: $securitiesOffset) {
        ...securityFragment
      }
    }
  }
`;


export const GET_ISSUER_PROGRAMMES_QUERY = gql`
  ${programmesListFragment}
  query GetIssuerProgrammes($idIssuer: String!, $limit: Int) {
    tier(id: $idIssuer) {
      id
      name
      url
      programmes(limit: $limit) {
        ...programmesListFragment
      }
    }
  }
`;

export const GET_ISSUER_NOTICES_QUERY = gql`
  ${documentListFragment}
  query GetIssuerNotices($idIssuer: String!, $limit: Int) {
    tier(id: $idIssuer) {
      id
      name
      url
      notices(limit: $limit) {
        ...documentListFragment
      }
    }
  }
`;

export const GET_ISSUER_NOTICES = gql`
  ${documentListFragment}
  query getIssuerNotices($idIssuer: String!, $noticesLimit: Int, $noticesOffset: Int) {
    tier(id: $idIssuer) {
      id
      notices(limit: $noticesLimit, offset: $noticesOffset) {
        ...documentListFragment
      }
    }
  }
`;

export const GET_SEARCH_ISSUERS_ONLY_RESULTS = gql`
  ${issuerSearchNoFilterFragment}

  query SearchIssuers(
    $searchTerm: String!
    $size: Int
    $page: Int
    $sort: String
    $lgxOnly: Boolean
    $climateAlignedOnly: Boolean
    $country: String
    $issuerTypes: [String!]
    $issuerSubTypes: [String!]
    $securityId: String
    $programmeId: String
  ) {
    luxseIssuersSearch(
      searchTerm: $searchTerm
      size: $size
      page: $page
      sort: $sort
      securityId: $securityId
      programmeId: $programmeId
      lgxOnly: $lgxOnly
      climateAlignedOnly: $climateAlignedOnly
      country: $country
      issuerTypes: $issuerTypes
      issuerSubTypes: $issuerSubTypes
    ) {
      ...issuerSearchNoFilterFragment
    }
  }
`;