/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import styles from './stylesheet';

import {
  AffordableAndCleanEnergySvg,
  CleanWaterAndSanitationSvg,
  ClimateActionSvg,
  DecentWorkAndEconomicGrowthSvg,
  GenderEqualitySvg,
  GoodHealthAndWellBeingSvg,
  IndustryInnovationAndInfrastructureSvg,
  LifeBelowWater,
  LifeOnLandSvg,
  NoPovertySvg,
  PartnershipForTheGoalssvg,
  PeaceJusticeAndStrongInstitutionsSvg,
  QualityEducationSvg,
  ReducedInequalitiesSvg,
  ResponsibleConsumptionAndProductionSvg,
  SustainableCitiesAndCommunitiesSvg,
  ZeroHungerSvg,
} from '../sdg-svgs';

export interface SDGsListProps {
  sdgs: string[];
  ratio?: number;
  selected?: string[];
  onSelect?: (sdgIds: any) => void;
  deligate?: boolean;
}

export const renderSdgIcon = (num: number, ratio = 60): React.ReactElement => {
  const sizes = { width: ratio, height: ratio };

  switch (num) {
    case 1:
      return <NoPovertySvg size={sizes} />;
    case 2:
      return <ZeroHungerSvg size={sizes} />;
    case 3:
      return <GoodHealthAndWellBeingSvg size={sizes} />;
    case 4:
      return <QualityEducationSvg size={sizes} />;
    case 5:
      return <GenderEqualitySvg size={sizes} />;
    case 6:
      return <CleanWaterAndSanitationSvg size={sizes} />;
    case 7:
      return <AffordableAndCleanEnergySvg size={sizes} />;
    case 8:
      return <DecentWorkAndEconomicGrowthSvg size={sizes} />;
    case 9:
      return <IndustryInnovationAndInfrastructureSvg size={sizes} />;
    case 10:
      return <ReducedInequalitiesSvg size={sizes} />;
    case 11:
      return <SustainableCitiesAndCommunitiesSvg size={sizes} />;
    case 12:
      return <ResponsibleConsumptionAndProductionSvg size={sizes} />;
    case 13:
      return <ClimateActionSvg size={sizes} />;
    case 14:
      return <LifeBelowWater size={sizes} />;
    case 15:
      return <LifeOnLandSvg size={sizes} />;
    case 16:
      return <PeaceJusticeAndStrongInstitutionsSvg size={sizes} />;
    case 17:
      return <PartnershipForTheGoalssvg size={sizes} />;
    default:
      return <AffordableAndCleanEnergySvg size={sizes} />;
  }
};

export function SDGsList(props: SDGsListProps) {
  const { sdgs, ratio = 48, selected, onSelect, deligate } = props;

  const isClickable = selected && onSelect;


  return (
    <div css={styles.list}>
      {sdgs.map((sdg: string) => {
        const sdgId: number = parseInt(sdg.split('_')[1], 10);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const style = styles[sdg.toUpperCase()];
        return (
          <div
            key={sdgId}
            css={[style, isClickable ? (selected?.includes(sdg) ? styles.selected : '') : styles.selected]}
            onClick={
              isClickable
                ? () => {
                    onSelect(sdg);
                  }
                : () => {
                    void 0;
                  }
            }
          >
            {renderSdgIcon(sdgId, ratio)}
          </div>
        );
      })}
    </div>
  );
}
